import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

export enum UserActionTypes {
    LOGOUT = '[User] Logout',
    LOAD_USER_MANAGEMENT = '[USER] Load User Management',
    LOAD_USER_MANAGEMENT_SUCCESS = '[USER] Load User Management Success',
    LOAD_USER_MANAGEMENT_FAIL = '[USER] Load User Management Fail',
    LOAD_USER_ROLES = '[USER] Load User Roles',
    LOAD_USER_ROLES_SUCCESS = '[USER] Load User Roles Success',
    LOAD_USER_ROLES_FAIL = '[USER] Load User Roles Fail',
    LOAD_USER = '[USER] Load User',
    LOAD_USER_SUCCESS = '[USER] Load User Success',
    LOAD_USER_FAIL = '[USER] Load User Fail',
    LOAD_USER_DETAILS = '[USER] Load User Details',
    LOAD_USER_DETAILS_SUCCESS = '[USER] Load User Details Success',
    LOAD_USER_DETAILS_FAIL = '[USER] Load User Details Fail',
    UPDATE_USER = '[USER] Update User ',
    UPDATE_USER_SUCCESS = '[USER] Update User Success',
    UPDATE_USER_FAIL = '[USER] Update User Fail',
    LOAD_USER_SUPPLIER = '[USER] Load User Supplier',
    LOAD_USER_SUPPLIER_SUCCESS = '[USER] Load User Supplier Success',
    LOAD_USER_SUPPLIER_FAIL = '[USER] Load User Supplier Fail',
    CREATE_USER_SUPPLIER = '[USER] Create Supplier',
    CREATE_USER_SUPPLIER_SUCCESS = '[USER] Create Supplier Success',
    CREATE_USER_SUPPLIER_FAIL = '[USER] Create Supplier Fail',
    UPDATE_USER_SUPPLIER = '[USER] Update User Supplier ',
    UPDATE_USER_SUPPLIER_SUCCESS = '[USER] Update User Supplier Success',
    UPDATE_USER_SUPPLIER_FAIL = '[USER] Update User Supplier Fail',
    CREATE_USER_PORTFOLIO = '[USER] Create User Portfolio',
    CREATE_USER_PORTFOLIO_SUCCESS = '[USER] Create User Portfolio Success',
    CREATE_USER_PORTFOLIO_FAIL = '[USER] Create User Portfolio Fail',
    UPDATE_USER_PORTFOLIO = '[USER] Update User Portfolio',
    UPDATE_USER_PORTFOLIO_SUCCESS = '[USER] Update User Portfolio Success',
    UPDATE_USER_PORTFOLIO_FAIL = '[USER] Update User Portfolio Fail',
    SEND_INVITE = '[USER] Send Invite',
    SEND_INVITE_SUCCESS = '[USER] Send Invite Success',
    SEND_INVITE_FAIL = '[USER] Send Invite Fail',
    LOAD_MENU_ITEMS = '[USER] Load Menu Items',
    LOAD_MENU_ITEMS_SUCCESS = '[USER] Load Menu Items Success',
    LOAD_MENU_ITEMS_FAIL = '[USER] Load Menu Items Fail',
}

export class Logout implements Action {
    readonly type = UserActionTypes.LOGOUT
}
export class LoadUserManagement implements Action {
    readonly type = UserActionTypes.LOAD_USER_MANAGEMENT
    constructor(public payload: any){}
}

export class LoadUserManagementSuccess implements Action {
    readonly type = UserActionTypes.LOAD_USER_MANAGEMENT_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadUserManagementFail implements Action {
    readonly type = UserActionTypes.LOAD_USER_MANAGEMENT_FAIL

    constructor(public payload: string) { }
}
export class LoadUserRoles implements Action {
    readonly type = UserActionTypes.LOAD_USER_ROLES
}

export class LoadUserRolesSuccess implements Action {
    readonly type = UserActionTypes.LOAD_USER_ROLES_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadUserRolesFail implements Action {
    readonly type = UserActionTypes.LOAD_USER_ROLES_FAIL

    constructor(public payload: string) { }
}

export class LoadMenuItems implements Action {
    readonly type = UserActionTypes.LOAD_MENU_ITEMS
}

export class LoadMenuItemsSuccess implements Action {
    readonly type = UserActionTypes.LOAD_MENU_ITEMS_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadMenuItemsFail implements Action {
    readonly type = UserActionTypes.LOAD_MENU_ITEMS_FAIL

    constructor(public payload: string) { }
}

export class LoadUser implements Action {
    readonly type = UserActionTypes.LOAD_USER

    constructor(public payload: any) { }
}

export class LoadUserSuccess implements Action {
    readonly type = UserActionTypes.LOAD_USER_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadUserFail implements Action {
    readonly type = UserActionTypes.LOAD_USER_FAIL

    constructor(public payload: string) { }
}

export class LoadUserDetails implements Action {
    readonly type = UserActionTypes.LOAD_USER_DETAILS
}

export class LoadUserDetailsSuccess implements Action {
    readonly type = UserActionTypes.LOAD_USER_DETAILS_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadUserDetailsFail implements Action {
    readonly type = UserActionTypes.LOAD_USER_DETAILS_FAIL

    constructor(public payload: string) { }
}

export class UpdateUser implements Action {
    readonly type = UserActionTypes.UPDATE_USER

    constructor(public payload: any) { }
}

export class UpdateUserSuccess implements Action {
    readonly type = UserActionTypes.UPDATE_USER_SUCCESS

    constructor(public payload1: Update<any>, public payload2: any) { }
}

export class UpdateUserFail implements Action {
    readonly type = UserActionTypes.UPDATE_USER_FAIL

    constructor(public payload: string) { }
}

export class LoadUserSupplier implements Action {
    readonly type = UserActionTypes.LOAD_USER_SUPPLIER

    constructor(public payload: any) { }
}

export class LoadUserSupplierSuccess implements Action {
    readonly type = UserActionTypes.LOAD_USER_SUPPLIER_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadUserSupplierFail implements Action {
    readonly type = UserActionTypes.LOAD_USER_SUPPLIER_FAIL

    constructor(public payload: string) { }
}
export class CreateUserSupplier implements Action {
    readonly type = UserActionTypes.CREATE_USER_SUPPLIER

    constructor(public payload: any) { }
}
export class CreateUserSupplierSuccess implements Action {
    readonly type = UserActionTypes.CREATE_USER_SUPPLIER_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class CreateUserSupplierFail implements Action {
    readonly type = UserActionTypes.CREATE_USER_SUPPLIER_FAIL

    constructor(public payload: string) { }
}

export class UpdateUserSupplier implements Action {
    readonly type = UserActionTypes.UPDATE_USER_SUPPLIER

    constructor(public payload: any) { }
}

export class UpdateUserSupplierSuccess implements Action {
    readonly type = UserActionTypes.UPDATE_USER_SUPPLIER_SUCCESS

    constructor(public payload1: Update<any>, public payload2: any) { }
}

export class UpdateUserSupplierFail implements Action {
    readonly type = UserActionTypes.UPDATE_USER_SUPPLIER_FAIL

    constructor(public payload: string) { }
}


export class CreateUserPortfolio implements Action {
    readonly type = UserActionTypes.CREATE_USER_PORTFOLIO

    constructor(public payload: any) { }
}

export class CreateUserPortfolioSuccess implements Action {
    readonly type = UserActionTypes.CREATE_USER_PORTFOLIO_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class CreateUserPortfolioFail implements Action {
    readonly type = UserActionTypes.CREATE_USER_PORTFOLIO_FAIL

    constructor(public payload: string) { }
}

export class UpdateUserPortfolio implements Action {
    readonly type = UserActionTypes.UPDATE_USER_PORTFOLIO

    constructor(public payload: any) { }
}

export class UpdateUserPortfolioSuccess implements Action {
    readonly type = UserActionTypes.UPDATE_USER_PORTFOLIO_SUCCESS

    constructor(public payload1: Update<any>, public payload2: any) { }
}

export class UpdateUserPortfolioFail implements Action {
    readonly type = UserActionTypes.UPDATE_USER_PORTFOLIO_FAIL

    constructor(public payload: string) { }
}
export class SendInvite implements Action {
    readonly type = UserActionTypes.SEND_INVITE

    constructor(public payload: any) { }
}

export class SendInviteSuccess implements Action {
    readonly type = UserActionTypes.SEND_INVITE_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class SendInviteFail implements Action {
    readonly type = UserActionTypes.SEND_INVITE_FAIL

    constructor(public payload: string) { }
}

export type userActionTypes = Logout | LoadUserManagement | LoadUserManagementSuccess | LoadUserManagementFail
    | LoadUserRoles | LoadUserRolesSuccess | LoadUserRolesFail | LoadUser | LoadUserSuccess | LoadUserFail |
    LoadUserDetails | LoadUserDetailsSuccess | LoadUserDetailsFail |
    UpdateUser | UpdateUserSuccess | UpdateUserFail | UpdateUserSupplier | UpdateUserSupplierSuccess | UpdateUserSupplierFail |
    UpdateUserPortfolio | UpdateUserPortfolioSuccess | UpdateUserPortfolioFail | LoadUserSupplier | LoadUserSupplierSuccess | LoadUserSupplierFail
    | CreateUserSupplier | CreateUserSupplierSuccess | CreateUserSupplierFail | CreateUserPortfolio | CreateUserPortfolioSuccess | CreateUserPortfolioFail
    | SendInvite | SendInviteSuccess | SendInviteFail | LoadMenuItems | LoadMenuItemsSuccess | LoadMenuItemsFail;
