<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>

  <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">

    <!-- Site Content -->
    <div class="dt-content">
      <div class="row">
        <!-- Page Header -->
        <div class="col-xl-4 col-sm-4 col-md-4">
          <div class="dt-page__header">
            <h3 class="dt-page__title" *ngIf="!isEditMode">{{staticMsgs.paletteDetail.createPalette}}</h3>
            <h3 class="dt-page__title" *ngIf="isEditMode">{{staticMsgs.paletteDetail.paletteDetail}}</h3>
            <nav class="mb-0 breadcrumb">
              <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/dashboard"><i class="fa fa-home" aria-hidden="true"></i>            </a>
              <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/palettelibrary">
                <span class="active breadcrumb-item" *ngIf="isEditMode || !isEditMode">{{staticMsgs.paletteLib.paletteLib}}</span>
              </a>
              <span class="active breadcrumb-item" *ngIf="!isEditMode">{{staticMsgs.paletteDetail.createPalette}}</span>
              <span class="active breadcrumb-item" *ngIf="isEditMode">{{staticMsgs.paletteDetail.paletteDetail}} / {{ sequenceNumber }} </span>
          </nav>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-md-4 d-flex justify-content-center" *ngIf="isEditMode">
          <ul class="dt-nav">
            <button  *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" (click)="editPalette()"
              ngbTooltip="Edit Style">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button  *ngIf="isEditingMode" type="button" [ngClass]="isEditingMode ? 'icon-active' : 'btn-color-primary'" class="btn mr-3 round"
            (click)="cancelPaletteEdit()"
              ngbTooltip="Cancel Edit Style">
              <i class="fa fa-times text-white" aria-hidden="true"></i>
            </button>
            <button  *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" (click)="onDeletePalette()"
            ngbTooltip="Delete">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
            <!-- <button type="button" class="btn mr-3 btn-color-primary round"
              *ngIf="!isEditingMode" ngbTooltip="Discussion">
              <i class="fa fa-comments" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal"
              data-target="#small-modal" ngbTooltip="Generate Techpack">
              <i class="fas fa-tshirt" aria-hidden="true"></i>
            </button> -->
          </ul>
        </div>
        <!-- <div class="col-xl-4 col-sm-4 col-md-4" *ngIf="isEditMode">
          <ul class="dt-nav">
            <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal" data-target="#long-modal"
              ngbTooltip="Share">
              <i class="fa fa-user-plus" aria-hidden="true" ></i>
            </button>
          </ul>
        </div> -->
        <!-- <div class="col-xl-6">

          <ul class="dt-nav">

            <li class="dt-nav__item">
              <i class="fa fa-pencil-square-o ul-icon" aria-hidden="true"></i>
            </li>

            <li class="dt-nav__item">
              <i class="fa fa-star-o ul-icon" aria-hidden="true"></i>
            </li>

            <li class="dt-nav__item">
              <i class="fa fa-share-alt ul-icon" aria-hidden="true"></i>
            </li>

            <li class="dt-nav__item">
              <i class="fa fa-trash-o ul-icon" aria-hidden="true"></i>
            </li>

            <li class="dt-nav__item">
              <i class="fa fa-trash-o ul-icon" aria-hidden="true"></i>
            </li>

          </ul>
        </div> -->
      </div>
      <!-- /page header -->


      <div class="profile-content">
        <div class="row">

          <div class="wd-300" *ngIf="isViewingMode">
            <!-- <ng-image-slider [images]="imageObject" ></ng-image-slider> -->
<div style="display: table;height: 200px;
            -o-object-fit: cover;
            object-fit: cover;
            width: 100%;" *ngIf="editedPaletteData.colourHexCodes">
<!-- <div class="palette" style="display: table-row;">
  <div *ngFor="let hexCode of editedPaletteData.colourHexCodes?.split(',')" style="display: table-cell;" class=" innerColor"
  [ngStyle]="{'background-color': hexCode}">
</div>
</div> -->
<div class="palette" style="display: table-row;">
  <div *ngFor="let hexCode of editedPaletteData.colourHexCodes?.split(',');  let first = first; let last = last" style="display: table-cell;" class=" innerColor"
  [ngStyle]="{'background-color': hexCode}"
  [ngClass]= "editedPaletteData.colourHexCodes.length === 7 ? 'rounded' : first ? 'rounded-left' : last ? 'rounded-right' : ''"
  >
</div>
</div>
</div>

<img *ngIf="!editedPaletteData.colourHexCodes" class="card-view-img" alt="" src={{thumbnail}}>



              <div class="accordion mt-5" id="accordion-example">
                <div class="card" *ngIf="editedPaletteData">
                  <div class="" id="headingOne">
                    <h5 class="mb-0">
                      <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                        data-target="#collapse-one" aria-expanded="false" aria-controls="collapse-one">
                        {{staticMsgs.paletteDetail.paletteInfo}}
                        <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                      </h4>
                    </h5>
                  </div>

                  <div id="collapse-one" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-example">
                    <div class="card-body bg-white p-4" *ngIf="editedPaletteData">
                      <div class="row">

                        <div class="col-md-5 col-sm-5 col-xl-5">
                          <p class="card_text">{{staticMsgs.paletteDetail.name}}</p>
                        </div>
                        <div class="col-md-7 col-sm-7 col-xl-7">
                          <p class="a-font">{{editedPaletteData.name}} </p>
                        </div>
                      </div>
                      <div class="row">

                        <div class="col-md-5 col-sm-5 col-xl-5">
                          <p class="card_text">{{staticMsgs.paletteDetail.type}}</p>
                        </div>
                        <div class="col-md-7 col-sm-7 col-xl-7">
                          <p class="a-font">{{editedPaletteData.status}} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="card" *ngIf="editedPaletteData">
                  <div class="" id="headingTwo">
                    <h5 class="mb-0">
                      <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                        data-target="#collapse-two" aria-expanded="false" aria-controls="collapse-one">
                        {{staticMsgs.paletteDetail.userInfo}}
                        <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                      </h4>
                    </h5>
                  </div>
                  <div id="collapse-two" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion-example">
                    <div class="card-body bg-white p-4" *ngIf="editedPaletteData.createdBy">
                      <div class="row">

                        <div class="col-md-5 col-sm-5 col-xl-5">
                          <p class="card_text">{{staticMsgs.paletteDetail.createdby}}</p>
                        </div>
                        <div class="col-md-7 col-sm-7 col-xl-7">
                          <p class="a-font">{{editedPaletteData.createdBy.firstName}}</p>
                        </div>
                      </div>
                      <div class="row">

                        <div class="col-md-5 col-sm-5 col-xl-5">
                          <p class="card_text">{{staticMsgs.paletteDetail.createdon}}</p>
                        </div>
                        <div class="col-md-7 col-sm-7 col-xl-7">
                          <p class="a-font">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            {{editedPaletteData.createdOn |date: "dd MMM yyyy"}}
                          </p>
                          <p class="a-font">
                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                            {{editedPaletteData.createdOn |date:"hh:mm a"}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <!-- Grid Item -->
          <!-- /grid item -->
          <!-- Grid Item -->
          <div [ngClass]="isViewingMode ? 'wdcacl-300' : 'w-100'" *ngIf="isViewingMode || isEditingMode">

            <!-- Card -->
            <div class="card card-shadow-none">

              <!-- Card Header -->
              <div class="card-header card-nav border-bottom d-sm-flex justify-content-sm-between" *ngIf="!isCreateMode">
                <!-- <h3 class="mb-2 mb-sm-n5">About</h3> -->

                <ul class="card-header-links nav nav-underline" role="tablist">
                  <li class="nav-item">
                    <a *ngIf="activeTab !='paletteDetailsTab'" class="nav-link active" data-toggle="tab" href="#tab-pane1"
                       [ngClass]="{ 'active':activeTab === 'paletteDetailsTab' }"
                       (click)="paletteDetailsTab('paletteDetailsTab')" role="tab"
                       aria-controls="tab-pane1" aria-selected="true">{{staticMsgs.paletteDetail.paletteDetail}} </a>
                       <a *ngIf="activeTab =='paletteDetailsTab'" class="nav-link active" data-toggle="tab" href="#tab-pane1"
                       [ngClass]="{ 'active':activeTab === 'paletteDetailsTab' }"
                       role="tab"
                       aria-controls="tab-pane1" aria-selected="true">{{staticMsgs.paletteDetail.paletteDetail}}</a>

                  </li>
                  <li class="nav-item" *ngIf="isEditMode">
                    <a *ngIf="activeTab !='docDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane2"
                       [ngClass]="{ 'active':activeTab === 'docDetailsTab' }"
                       (click)="docDetailsTab('docDetailsTab')" role="tab"
                       aria-controls="tab-pane2" aria-selected="true">{{staticMsgs.paletteDetail.document}}</a>
                       <a *ngIf="activeTab =='docDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane2"
                       [ngClass]="{ 'active':activeTab === 'docDetailsTab' }"
                        role="tab"
                       aria-controls="tab-pane2" aria-selected="true">{{staticMsgs.paletteDetail.document}}</a>
                  </li>
                </ul>
                <div class="dropdown" *ngIf="!isViewingMode">
                    <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                    </a> -->
                </div>
                <div class="dropdown" *ngIf="isViewingMode">
                  <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                  </a> -->

                  <div class="dropdown-menu dropdown-menu-right">
                    <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="editPalette()">
                      <i class="icon icon-editors icon-fw"></i>
                      Edit
                    </a> -->
                    <!-- <a class="dropdown-item" href="javascript:void(0)">
                      <i class="icon icon-trash icon-fw"></i>
                      Delete
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                      <i class="icon icon-addnew icon-fw"></i>
                      Create New
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                      <i class="fa fa-files-o mr-1" aria-hidden="true"></i>
                      Copy
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                      <i class="fa fa-floppy-o mr-1" aria-hidden="true"></i>
                      Save New Version
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                      <i class="fa fa-thumb-tack mr-1" aria-hidden="true"></i>
                      Mark As Basline Version
                    </a> -->
                  </div>
                </div>
              </div>
              <!-- /card header -->
              <!-- Card Body -->
              <div class="pb-2 mt-3">
                <!-- Tab Content-->
                <div class="tab-content">
                  <!-- Tab panel -->
                  <div id="tab-pane1" class="tab-pane card-body card-body-shadow bg-white  active"
                       [ngClass]="{ 'active':activeTab==='paletteDetailsTab'}">
                    <div *ngIf="isEditingMode">
                      <form [formGroup]="paletteForm" (ngSubmit)="onPaletteSubmit()">

                        <div class="form-row">
                          <div class="col-lg-3 col-sm-3 mb-3" *ngIf="isIDVisible">
                            <label for="sequence">{{staticMsgs.paletteDetail.id}}<span class="red_icon">*</span></label>
                            <input type="text" class="form-control" id="sequence" formControlName="sequence" placeholder="Enter ID" value="">
                          </div>
                          <div class="col-lg-3 col-sm-3 mb-3">
                            <label for="name">{{staticMsgs.paletteDetail.name1}}<span class="red_icon">*</span></label>
                            <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter Name" value="" minlength="3" maxlength="50"
                            (keypress)="characterOnly($event)" required>
                            <div *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
                              <div *ngIf="this.f.name.errors.required" class="red">
                                {{staticMsgs.paletteDetail.nameReq}}
                              </div>
                              <div *ngIf="this.f.name.errors.minlength" class="red">
                                {{staticMsgs.common.minimum3charactersrequired}}
                               </div>
                            </div>

                          </div>
                          <div class="col-lg-3 col-sm-3 mb-3">
                            <label for="status">{{staticMsgs.paletteDetail.status}}<span class="red_icon">*</span></label>
                            <select id="status" title="Select Status" formControlName="status" class="form-control selectpicker w-100">
                              <!-- <option value="" [disabled]="true" selected>
                                Select Status
                              </option> -->
                              <option value="Approved">{{staticMsgs.paletteDetail.approved}}</option>
                              <option value="In Review">{{staticMsgs.paletteDetail.inReview}}</option>
                              <option value="Dropped">{{staticMsgs.paletteDetail.dropped}}</option>
                            </select>
                            <div *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
                              <div *ngIf="this.f.status.errors.required" class="red">
                                {{staticMsgs.paletteDetail.statusReq}}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6 mb-3">
                            <label for="description">{{staticMsgs.paletteDetail.description}}</label>
                            <textarea cols="2" formControlName="description" name="description" placeholder="Enter Description" class="form-control"></textarea>
                            <div *ngIf="this.paletteForm.controls.description.errors && this.paletteForm.controls.description.value && this.paletteForm.controls.description.value.length > 500"
                              class="red">
                              {{staticMsgs.paletteDetail.descAllow}}
                          </div>
                          </div>

                        </div>

                        <div class="col-lg-12 col-sm-12">
                          <div class="dt-entry__header mt-5">
                            <!-- Entry Heading -->
                            <div class="dt-entry__heading w-100 form-row">
                              <h3 class="dt-entry__title col-sm-10">{{staticMsgs.paletteDetail.selectPalette}}<span class="red_icon">*</span></h3>
                              <div class="col-sm-2">
                                <div class="float-right d-flex">
                                  <i class="fa fa-file-export extra_icon text-white" placement="top" (click)="saveJPG()" ngbTooltip="Export"></i>
                                </div>
                              </div>
                            </div>
                            <!-- /entry heading -->
                          </div>
                          <div class="form-row">
                            <div class="col-sm-12 text-center">
                              <button type="button" class="btn btn-primary btn-esm" (click)="addColor()" placement="top"> {{staticMsgs.paletteDetail.addPalette}} </button>
                            </div>
                          </div>
                          <div class="form-row mt-3" id="sortable" cdkDropList [cdkDropListData]="colorSection" (cdkDropListDropped)="drop($event)">
                            <div class="col-sm-2 mb-3 ui-state-default" *ngFor="let colorelement of colorSection; let i = index">
                                <p class="color_name" >{{colorelement.colorHex}}</p>
                                <div class="color-section text-center mt-4 handle" name="colordiv" [ngStyle]="{'background-color': colorelement.colorbg}" cdkDrag>
                                </div>
                                <div class="color-icons" name="coloricon">
                                  <div>
                                    <i class="fa fa-eye-dropper text-secondary" #ColorInput (click)="editColor($event,i,colorelement)" placement="bottom" ngbTooltip="Edit Color"></i>
                                    <i class="fa fa-times" aria-hidden="true" (click)="removeColor(i)" placement="bottom" ngbTooltip="Delete Color"></i>
                                    <!-- <i class="icon-invert-color" #ColorInput (click)="editColor($event,i,colorelement)" placement="bottom" ngbTooltip="Edit Color"></i> -->
                                  </div>
                                </div>
                              <color-chrome (onChange)="handleChange($event,colorelement)" name="colorsketch" [ngClass]="colorelement.isopencolor ? 'cshow' : 'chide'"></color-chrome>
                              <i class="fa fa-times colorChrome" aria-hidden="true" (click)="chromeClick(colorelement)" [ngClass]="colorelement.isopencolor ? 'cshow' : 'chide'"  placement="bottom" ngbTooltip="Close"></i>

                            </div>
                            <div *ngIf="(submitted && !isColorAdded)" class="red">
                              {{staticMsgs.paletteDetail.paletteReq}}
                              </div>
                          </div>

                        </div>

                        <div class="form-row mt-10 float-right">
                          <div class="row mt-3">
                            <button type="button" *ngIf="!isEditMode" (click)="cancelPaletteCreate()"  class="btn btn-outline-primary adv-search-btn mr-1">{{staticMsgs.paletteDetail.cancel}}</button>
                            <button type="submit" *ngIf="!isEditMode" class="btn btn-primary adv-search-btn float-right"
                            [disabled]="paletteForm?.invalid || !isColorAdded">
                              {{staticMsgs.paletteDetail.create}}</button>
                            <button type="submit" *ngIf="isEditMode" class="btn btn-primary" [disabled]="paletteForm?.invalid || !isColorAdded">
                              {{staticMsgs.paletteDetail.update}}</button>
                        </div>
                        </div>
                      </form>
                    </div>

                    <div *ngIf="isViewingMode">
                      <form>
                        <div class="form-row">
                          <div class="col-lg-12 col-sm-12">
                            <div class="form-row">
                              <div class="col-sm-4 mb-3">
                                <label  class="label-view" for="this.f.sequence.value">{{staticMsgs.paletteDetail.id}}</label>
                                <p class="mt-2 viewtext">{{this.f.sequence.value}}</p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label  class="label-view" for="this.f.isO4915Number.value">{{staticMsgs.paletteDetail.name1}}</label>
                                <p class="mt-2 viewtext">{{this.f.name.value}}</p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label  class="label-view" for="this.f.status.value">{{staticMsgs.paletteDetail.status}}</label>
                                <p class="mt-2 viewtext">{{this.f.status.value | titlecase}}</p>
                              </div>
                              <div class="col-sm-8 mt-3 mb-3">
                                <label  class="label-view" for="this.f.description.value">{{staticMsgs.paletteDetail.description}}</label>
                                <p class="mt-2 viewtext">  {{this.f.description.value}}  </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-sm-12">
                          <div class="dt-entry__header mt-5">
                            <!-- Entry Heading -->
                            <div class="dt-entry__heading w-100 form-row">
                              <h3 class="dt-entry__title col-sm-10">{{staticMsgs.paletteDetail.paletteColor}}</h3>
                              <div class="col-sm-2">
                                <div class="float-right d-flex">
                                  <i class="icon icon-download extra_icon icon-fw text-white" placement="top" (click)="saveJPGView()" ngbTooltip="Export"></i>
                                </div>
                              </div>
                            </div>
                            <!-- /entry heading -->
                          </div>
                          <div class="form-row">
                          </div>
                          <div class="form-row" id="sortableView">
                            <div class="col-sm-2 mb-3 ui-state-default" *ngFor="let colorelement of colorSection; let i = index">
                              <p class="color_name">{{colorelement.colorHex}}</p>
                              <div class="color-section text-center mt-4 handle" name="colordiv" [ngStyle]="{'background-color': colorelement.colorbg}">
                              </div>
                              <div class="color-icons" name="coloricon">
                              </div>
                              <color-chrome (onChange)="handleChange($event,colorelement)" name="colorsketch" [ngClass]="colorelement.isopencolor ? 'cshow' : 'chide'"></color-chrome>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                  <!-- /tab panel -->
                  <!-- Document /tab panel -->
                  <!-- Tab panel -->
                  <div id="tab-pane2" class="tab-pane card-body card-body-shadow bg-white "
                       [ngClass]="{ 'active':activeTab === 'docDetailsTab'}">
                    <div *ngIf="isEditingMode">
                      <form [formGroup]="documentsForm" (ngSubmit)="onDocSubmit()">
                        <div formArrayName="documents">
                          <div *ngFor="let document of getControls(); let i=index">
                            <div class="card p-5" [formGroupName]="i">
                              <div class="form-row ">
                                <div class="col-sm-12 float-right">
                                  <button type="button" class="close"
                                          (click)="removeDoc(i)">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>

                              </div>

                              <div class="form-row">

                                <div class="col-lg-4 col-sm-12 mb-3">
                                  <label for="docclassification">

                                    {{staticMsgs.paletteDetail.selectClass}}<span class="red_icon">*</span>
                                  </label>
                                  <input type="text" class="form-control"
                                         id="docclassification{{i}}"
                                         formControlName="docclassification"
                                         placeholder="Classification" required />
                                  <div *ngIf="(submitted1 && document.get('docclassification').errors) || ( document.get('docclassification').invalid && (document.get('docclassification').dirty || document.get('docclassification').touched))">
                                    <div *ngIf="document.get('docclassification').errors.required"
                                         class="red">
                                         {{staticMsgs.paletteDetail.classReq}}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4 mb-3"
                                     *ngIf="document.get('docid').value">
                                     <label for="docseq">{{staticMsgs.paletteDetail.document1}}</label>
                                  <input type="text" class="form-control"
                                         [readonly]="true" id="docseq"
                                         formControlName="docseq"
                                         placeholder="Document Id" />
                                </div>

                                <div class="col-lg-4 col-sm-12">
                                  <label for="docname">
                                    {{staticMsgs.paletteDetail.name1}}<span class="red_icon">*</span>
                                  </label>
                                  <input type="text" class="form-control" id="docname"
                                         formControlName="docname" minlength="3" maxlength="50"
                                         placeholder="Document Name" (keypress)="characterOnly($event)" required />
                                         <div *ngIf="(submitted1 && document.get('docname').errors) || ( document.get('docname').invalid && (document.get('docname').dirty || document.get('docname').touched))">
                                          <div *ngIf="document.get('docname').errors.required"
                                               class="red">
                                               {{staticMsgs.paletteDetail.docReq}}
                                          </div>
                                          <div *ngIf="document.get('docname').errors.minlength" class="red">
                                            {{staticMsgs.common.minimum3charactersrequired}}
                                           </div>
                                        </div>

                                </div>

                              </div>

                              <div class="form-row mt-5">
                                <div class="col-sm-4 mb-3">
                                  <label for="docstatus">
                                    {{staticMsgs.paletteDetail.status}}<span class="red_icon">*</span>
                                  </label>
                                  <select type="text" class="form-control selectpicker"
                                          id="docstatus" formControlName="docstatus"
                                          required>
                                    <option value="" [disabled]="true" selected>
                                      {{staticMsgs.paletteDetail.selectStatus}}

                                    </option>
                                    <option value="Active">{{staticMsgs.paletteDetail.active}}</option>
                                    <option value="Inactive">{{staticMsgs.paletteDetail.inactive}}</option>
                                  </select>
                                  <div *ngIf="(submitted1 && document.get('docstatus').errors) || ( document.get('docstatus').invalid && (document.get('docstatus').dirty || document.get('docstatus').touched))">
                                    <div *ngIf="document.get('docstatus').errors.required"
                                         class="red">
                                         {{staticMsgs.paletteDetail.statusReq}}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-8 mb-3">
                                  <label for="docdescription">
                                    {{staticMsgs.paletteDetail.description}}<span class="red_icon">*</span>
                                  </label>
                                  <textarea cols="2" class="form-control"
                                            id="docdescription"
                                            formControlName="docdescription"
                                            required></textarea>
                                  <div *ngIf="(submitted1 && document.get('docdescription').errors) || ( document.get('docdescription').invalid && (document.get('docdescription').dirty || document.get('docdescription').touched))">
                                    <div *ngIf="document.get('docdescription').errors.required"
                                         class="red">
                                         {{staticMsgs.paletteDetail.descReq}}
                                    </div>
                                    <div *ngIf="document.get('docdescription').value && document.get('docdescription').value.length > 500"
                                      class="red">
                                      {{staticMsgs.paletteDetail.descAllow}}
                                  </div>
                                  </div>

                                </div>

                              </div>

                              <div class="dt-entry__header mt-5"
                                   *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                <div class="dt-entry__heading">
                                  <h3 class="dt-entry__title">
                                    {{document.get('docclassification').value}}
                                  </h3>
                                </div>
                              </div>

                              <div class="form-row"
                                   *ngIf="document.get('docclassification').value === 'Lab Reports'">

                                <div class="col-sm-4 mb-3">
                                  <label for="docreportstatus">
                                    {{staticMsgs.paletteDetail.report}}<span class="red_icon">*</span>
                                  </label>
                                  <select type="text" class="form-control selectpicker"
                                          id="docreportstatus"
                                          formControlName="docreportstatus" required>
                                    <option value="" [disabled]="true" selected>
                                      {{staticMsgs.paletteDetail.selectReport}}
                                    </option>
                                    <option value="Pass">{{staticMsgs.paletteDetail.pass}}</option>
                                    <option value="Fail">{{staticMsgs.paletteDetail.fail}}</option>
                                  </select>
                                  <div *ngIf="(submitted1 && document.get('docreportstatus').errors) || ( document.get('docreportstatus').invalid && (document.get('docreportstatus').dirty || document.get('docreportstatus').touched))">
                                    <div *ngIf="document.get('docreportstatus').errors.required"
                                         class="red">
                                         {{staticMsgs.paletteDetail.reportReq}}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4 mb-3">
                                  <label for="doclablocation">
                                    {{staticMsgs.paletteDetail.labLoc}}<span class="red_icon">*</span>
                                  </label>
                                  <input type="text" class="form-control"
                                         id="doclablocation"
                                         formControlName="doclablocation"
                                         placeholder="Lab Location" required />
                                  <div *ngIf="(submitted1 && document.get('doclablocation').errors) || ( document.get('doclablocation').invalid && (document.get('doclablocation').dirty || document.get('doclablocation').touched))">
                                    <div *ngIf="document.get('doclablocation').errors.required"
                                         class="red">
                                         {{staticMsgs.paletteDetail.labLocReq}}
                                    </div>
                                    <div *ngIf="document.get('doclablocation').value && document.get('doclablocation').value.length > 50"
                                      class="red">
                                      {{staticMsgs.paletteDetail.labLocAllow}}
                                  </div>
                                  </div>

                                </div>

                              </div>

                              <div class="dt-entry__header mt-5">
                                <div class="dt-entry__heading">
                                  <h3 class="dt-entry__title">{{staticMsgs.paletteDetail.document}}</h3>
                                </div>
                              </div>

                              <div class="form-row" [hidden]="true">
                                <input type="text" class="form-control"
                                       id="docdeletedfileids"
                                       formControlName="docdeletedfileids"
                                       placeholder="Deleted File Ids">
                              </div>

                              <div class="form-row">
                                <div class="col-xl-12">
                                  <label for="document">{{staticMsgs.common.docname}}<span
                                    class="red_icon">*</span></label>
                                  <div class="custom-dropzone" id="docupload"
                                       ngx-dropzone (change)="onSelectDoc($event,i)" >
                                    <ngx-dropzone-label>
                                      <div>
                                        <h5 class="text-light-gray mt-3">
                                          {{staticMsgs.paletteDetail.dropFile}}
                                        </h5>
                                      </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview"
                                                          *ngFor="let f of fileData[i]" [file]="f"
                                                          [removable]="true"
                                                          (removed)="onRemoveDoc(f,i)">
                                      <ngx-dropzone-label>
                                        {{ f.name }}

                                      </ngx-dropzone-label>
                                    </ngx-dropzone-preview>

                                  </div>
                                  <div *ngIf="doc_Error[i]">
                                    <span class="red">
                                      <span>
                                        {{doc_Error[i]}}
                                      </span></span>
                                    </div>
                                    <div class="col-xl-6 mt-6" *ngIf="document.get('docid').value">
                                      <div class="col-xl-6 mb-5"
                                           *ngFor="let img of editedFileData[i]; let j = index">
                                        <img src="{{img.thumbnail}}"
                                             class="img-fluid  img-fit "
                                             *ngIf="isValidImageURL(img.name)"
                                             (click)="open(i,j)">
                                        <a href="{{img.thumbnail}}" target="_blank"
                                           download>
                                          <img src="{{documentImage}}"
                                               class="img-fluid  img-fit "
                                               *ngIf="!isValidImageURL(img.name)">
                                        </a>
                                        <button type="button" class="close image-close"
                                                (click)="removeImgSection(img,i)">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                    </div>
                                </div>
                              </div>

                              <div class="form-row mt-5"
                                   *ngIf="(fileData[i]) && (!isEmptyObject(fileData[i]))">
                                <div class="col-xl-12 ">
                                  <div class="card">
                                    <div class="dt-card__body p-2">
                                      <label for="docnotes">{{staticMsgs.paletteDetail.notes}}</label>                                      <textarea cols="2" class="form-control"
                                                id="docnotes"
                                                formControlName="docnotes"></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <input type="text" class="form-control" [hidden]="true"
                                     id="docid" formControlName="docid"
                                     placeholder="Document Id" />

                            </div>

                          </div>
                        </div>

                        <div class="form-row text-center mb-7">
                          <div class="col-sm-12">
                            <button type="button" class="btn btn-secondary adv-search-btn"
                                    (click)="addNewDoc()">
                              <i class="icon icon-plus"></i>
                              {{staticMsgs.paletteDetail.addNew}}
                            </button>
                          </div>
                        </div>

                        <div class="form-row text-center mb-7" *ngIf="showButton">
                          <div class="col-sm-12">
                            <!-- <button type="submit"
                                    class="btn btn-primary adv-search-btn">
                              Update
                            </button> -->

                            <button type="submit"  *ngIf="fileData.length>0" [disabled]="documentsForm?.invalid || !isFilesAdded"
                                    class="btn btn-primary adv-search-btn">
                                    {{staticMsgs.paletteDetail.save}}
                            </button>
                            <button type="button" *ngIf="isEditMode"
                          (click)="cancelDocumentEdit()"
                          class="btn btn-primary adv-search-btn">
                          {{staticMsgs.common.cancel}}
                         </button>
                          </div>
                        </div>

                      </form>
                    </div>

                    <div *ngIf="isViewingMode">
                      <!-- /* Written by : Himanshu */ -->
                      <div class="text-center" *ngIf="paletteDataCnt == 0">
                        <h3 class="mt-4">no data available</h3>
                        <img [src]="notfoundImg" class="img-fluid w-40"><br>
                      </div>
                      <div *ngFor="let document of getControls(); let i=index">
                        <div class="dt-entry__header mt-5">

                          <div class="dt-entry__heading">
                            <h3 class="dt-entry__title">
                              {{document.get('docclassification').value}}
                            </h3>
                          </div>

                        </div>
                        <form>
                          <div class="form-row">
                            <div class="col-sm-4 mb-3">
                              <label  class="label-view" for="document.get('docseq').value}">
                                {{staticMsgs.paletteDetail.document1}}

                              </label>
                              <p class="mt-2 viewtext">
                                {{document.get('docseq').value}}
                              </p>
                            </div>
                            <div class="col-sm-4 mb-3">
                              <label  class="label-view" for="document.get('docname').value">{{staticMsgs.paletteDetail.name1}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('docname').value}}
                              </p>
                            </div>
                            <div class="col-sm-4 mb-3">
                              <label  class="label-view" for="document.get('docstatus').value">{{staticMsgs.paletteDetail.status}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('docstatus').value | titlecase}}
                              </p>
                            </div>

                            <div class="col-sm-4 mt-3 mb-3"
                                 *ngIf="document.get('docclassification').value === 'Lab Reports'">
                              <label  class="label-view" for="document.get('docreportstatus').value">
                                {{staticMsgs.paletteDetail.report}}</label>

                              <p class="mt-2 viewtext">
                                {{document.get('docreportstatus').value}}
                              </p>
                            </div>
                            <div class="col-sm-4 mt-3 mb-3"
                                 *ngIf="document.get('docclassification').value === 'Lab Reports'">
                              <label  class="label-view" for="document.get('doclablocation').value">
                                {{staticMsgs.paletteDetail.labLoc}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('doclablocation').value}}
                              </p>
                            </div>

                            <div class="col-sm-8 mt-3 mb-3">
                              <label  class="label-view" for="document.get('docdescription').value">{{staticMsgs.paletteDetail.description}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('docdescription').value}}
                              </p>
                            </div>
                            <div class="col-sm-8 mt-3 mb-3"
                                 *ngIf="document.get('docnotes').value">
                              <label class="label-view"  for="document.get('docnotes').value">{{staticMsgs.paletteDetail.docNotes}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('docnotes').value}}
                              </p>
                            </div>
                          </div>

                          <div class="form-row mt-2">
                            <div class="col-xl-3 mb-5"
                                 *ngFor="let img of editedFileData[i]; let j = index">
                              <img src="{{img.thumbnail}}" class="img-fluid  img-fit "
                                   *ngIf="isValidImageURL(img.name)"
                                   (click)="open(i,j)">
                              <a href="{{img.thumbnail}}" target="_blank"
                                 download>
                                <img src="{{documentImage}}"
                                     class="img-fluid  img-fit "
                                     *ngIf="!isValidImageURL(img.name)">
                              </a>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>

                  </div>
                  <!-- /tab panel -->

                </div>
                <!-- /tab content-->

              </div>
              <!-- /card body -->

            </div>
            <!-- /card -->

          </div>
          <!-- /grid item -->

        </div>
        <!-- /grid -->

      </div>


    </div>

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->
  </div>
  <!-- /Site Content Wrapper-->

</main>
