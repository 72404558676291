<div data-toggle="modal">
  <app-loader [showLoader]="showLoader"></app-loader>
  <div class="dt-page__header">
    <button class="close" mat-button [disabled]="ismodaldata" (click)="close()">X</button>
    <h3 class="dt-page__title" *ngIf="!data.isbomedit">Create BOM</h3>
    <h3 class="dt-page__title" *ngIf="data.isbomedit">Edit BOM</h3>
  </div>
  <mat-dialog-content class="mat-typography">
    <div id="tab-pane" class="tab-pane bg-white active">
      <svg viewBox="25 25 50 50" *ngIf="ismodaldata">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
      <form [formGroup]="BOMformGroup" [ngClass]="ismodaldata == true ? 'bom-class-1' : 'bom-class-2'"
        class="bom_modal_content">
        <div class="form-row">
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">BOM Description </label>
            <input name="component" formControlName="component" class="form-control fill"
              placeholder="MainBody,Sleeve,Polybag etc">
            <div
              *ngIf="(submitted && this.f1.component.errors) || ( this.f1.component.invalid && (this.f1.component.dirty || this.f1.component.touched))">
              <div *ngIf="this.f1.component.errors.required" class="red">
                BOM Description is required
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Material Name</label>
            <select formControlName="materialname" id="materialname" class="form-control"
              (change)="selectionChangeMaterial($event,'')">
              <option *ngFor="let data of materialDataArr" [value]="data.id">{{data.name}}</option>
            </select>
          </div><br>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Material Type</label>
            <input id="Material Type" class="form-control fill" formControlName="materialType" [editable]="false" [attr.disabled]="true">
          </div><br>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Material Id</label>
            <input id="Item no" class="form-control fill" formControlName="itemNo" [editable]="false" [attr.disabled]="true">
          </div><br>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Supplier</label>
             <!-- [attr.disabled]="(BOMformGroup.get('materialname').value) && data ? null : true" -->
            <select class="form-control" id="supplierId" formControlName="supplierId"
             >
              <option *ngFor="let data1 of supplierDropdownList" [value]="data1.id">{{data1.name}}</option>
            </select>
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Color</label>
             <!-- [attr.disabled]="(BOMformGroup.get('materialname').value) && data ? null : true" -->
            <select class="form-control" id="colourid" formControlName="colourid"
              (change)="selectionChangeColor($event,'')">
              <option *ngFor="let data2 of colorDropdownList" [value]="data2.id">{{data2.name}}</option>
            </select>
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Color Code</label>
            <div *ngIf="!isbomedit">
              <div *ngIf="!isNew">
                <div *ngIf="colorCode">
                  <img *ngIf="colorCode" class="img" [ngStyle]="{'background-color': colorCode}" alt="">
                </div>
              </div>
              <div *ngIf="isNew">
                <div *ngIf="colorCode">
                  <img *ngIf="colorCode" class="img" [ngStyle]="{'background-color': colorCode}" alt="">
                </div>
              </div>
            </div>
            <div *ngIf="data.isbomedit">
              <!-- <img
        *ngIf="colorCode.hexcode && (colorCode.classification === 'Solid' || colorCode.classification === 'Custom' )"
        class="img" [ngStyle]="{'background-color': colorCode.hexcode}" alt=""> -->
              <!-- <img *ngIf="!colorCode.hexcode  && (colorCode.classification != 'Print and Pattern')"
        class="img" alt=""> -->
            </div>
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Size Specification</label>
            <input type="text" name="size" formControlName="size" class="form-control fill"
              placeholder="Enter Size / Width" valid-number>
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Price($)</label>
            <input type="number" name="price" min="1" (change)="onKeyUpQuantity($event)" (input)="onInput('price')" formControlName="price" class="form-control fill" valid-number>
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Quantity</label>
            <input type="number" name="quantity" min="1"  (input)="onInput('quantity')" formControlName="quantity" class="form-control fill"
              (change)="onKeyUpQuantity($event)" valid-number>
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">UOM</label>
            <select formControlName="uom" name="uom" class="form-control">
            <option *ngFor="let data of uomDrodownList" [value]="data.name">{{data.name}}</option>
          </select>
            <!-- <input type="text" name="uom" formControlName="uom" class="form-control fill" valid-number> -->
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">Sub Total($)</label>
            <input name="subTotal" formControlName="subTotal" class="form-control fill" valid-number>
          </div>
          <!-- <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">BOM Description</label>
            <input name="notes" formControlName="notes" class="form-control fill">
          </div> -->
        </div>


        <div class="footer">
          <div class="row mt-5 float-right mr-3">
            <button type="submit" [disabled]="BOMformGroup.invalid" class="btn btn-outline-primary btn-sm" (click)="close()">
              Cancel
            </button>
            <button type="submit" [disabled]="BOMformGroup.invalid" class="btn btn-primary btn-sm mr-2" (click)="onBomSubmit()">
              submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</div>