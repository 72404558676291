import { Component, HostBinding, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Auth } from 'aws-amplify';
import { LoginComponent } from './components/Auth/login/login.component';
import { StorageService} from './helpers/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../tenants/main/environments/environment';
import awsconfig from '../../../aws-exports';
import { NotificationMiddlewareService } from './services/notification-middleware.service';
import {​​​​​​​​ io }​​​​​​​​ from'socket.io-client';

// var options = {
//   rememberUpgrade:true,
//   transports: ['websocket', 'polling', 'flashsocket'],
//   secure:true, 
//   rejectUnauthorized: false
// };

// const socket = io(environment.socketIoServerUrl,options);

 const socket = io(environment.socketIoServerUrl, {transports: ['websocket', 'polling', 'flashsocket']});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @HostBinding('class') classlist = 'dt-root';
  title = 'FITOS';
  isLoggedIn: false;

  constructor(private storage: StorageService,
    private notificationMiddleware: NotificationMiddlewareService,
    public translate: TranslateService){
        // set translation
        this.translate.addLangs(['en', 'es']);
        this.translate.setDefaultLang('en');
    
        const browserLang = translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    
          this.translate.use("en");
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    this.notificationMiddleware.init();
    //this.notificationMiddleware.toggleSubscription();

    const useremail = localStorage.getItem('email');  
    //set login User
    if (useremail) {  
      socket.emit('login',{userId:useremail});
    }
            
    //Get Online User
    socket.on('onlineUsers', (onlineUsers) => {      
       this.storage.storeOnlineUser(onlineUsers);
    });

  }
}
