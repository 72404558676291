import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Observable, Observer } from "rxjs";
import { AESDecryptService } from '../helpers/AESDecrypt.service';
import { DataService } from './data.service';




@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {
  userImgfile: Blob;
  imgResult: any;
  hex_rep: any = [];

  constructor(
    private aesdecryptservice: AESDecryptService,
    private dataservice: DataService,
  ) { }

  formatDate() {
    let date = new Date().toISOString();

    let year = date.split('T')[0].split('-')[0];
    let month = date.split('T')[0].split('-')[1];
    let day = date.split('T')[0].split('-')[2];
    let ymd = month + '-' + day + '-' + year;
    let time = date.split('T')[1].split('.')[0];
    return ymd + ' ' + time;
  }

  exportTechPackReport(excelData) {
    var title = 'Tech Pack-' + excelData[0].styleNumber + '-' + this.formatDate();

    console.log('excelData', excelData);

    //Add sheets
    let workbook = new Workbook();
    let worksheet: any;
    const sheetNames = ["StyleInfo",
      "BOM",
      "Colorways",
      "Measurements",
      "Pattern",
      "Construction",
      "Attachments",
      "FlatSketch"];

    sheetNames.forEach(sheetName => {

      worksheet = workbook.addWorksheet(sheetName);
      worksheet.columns = [{ key: 'A', width: 10 }, { key: 'B', width: 10 }, { key: 'C', width: 10 }, { key: 'D', width: 10 }, { key: 'E', width: 10 }, { key: 'F', width: 10 }, { key: 'G', width: 10 }, { key: 'H', width: 10 }, { key: 'I', width: 10 }, { key: 'J', width: 10 }, { key: 'K', width: 10 }];
      this.bindStyleStaticData(sheetName, worksheet, excelData);
      //Style

      if (sheetName == "StyleInfo") {

        worksheet.mergeCells('A7', 'C7');
        let A7 = worksheet.getCell('A7');
        A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        var styleImages = excelData[0]['styleImages'];

        if (styleImages?.length > 0) {
          var rowIndex = 8;

          var isStyle = false;
          var FBaseIndex = 8;
          let currentColumn = 0;
          let columnSpan = 0;
          let coverpageImg = [];
          styleImages?.forEach((StyleImage: any, index: number) => {
            if (StyleImage?.imageType == "coverpage") {
              coverpageImg.push(StyleImage);
            }
          });
          coverpageImg?.forEach((StyleImage: any, index: number) => {
            console.log("styleImages", StyleImage, index);
            if (StyleImage?.imageType == "coverpage") {

              currentColumn = currentColumn + columnSpan;

              columnSpan = 3;
              if (index > 1 && index % 3 === 0) {
                rowIndex = rowIndex + 8;
                currentColumn = 0;
              }
              let base64URL = StyleImage['image']['base64URL'];
              if (base64URL) {
                var imageId2 = workbook.addImage({
                  base64: base64URL,
                  extension: 'png',
                });
                worksheet.addImage(imageId2, {
                  tl: { col: currentColumn, row: rowIndex },
                  br: { col: currentColumn + 3, row: rowIndex + 7 },
                });
                isStyle = true;
              }
            }
          });
          if (!isStyle) {
            A7.alignment = { vertical: 'middle', horizontal: 'center' };
            A7.value = "No Data Available";
          }
        }
        else {
          A7.alignment = { vertical: 'middle', horizontal: 'center' };
          A7.value = "No data available";
        }

      }
      //Colourways
      else if (sheetName == "Colorways") {
        var stylecolourwaysData = excelData[0]['colourways'];
        this.bindcolourwaysData(worksheet, stylecolourwaysData);
      }
      //Images
      else if (sheetName == "Attachments") {
        var styleImagesData = excelData[0]['styleImages'];
        this.bindImagesData(workbook, worksheet, styleImagesData);
      }

      //BOM
      else if (sheetName == "BOM") {
        var styleBomsData = excelData[0]['boMs'];
        this.bindBOMData(worksheet, styleBomsData);
      }
      //Construction
      else if (sheetName == "Construction") {
        var constructionsData = excelData[0]['styleConstructions'];
        this.bindConstructionData(workbook, worksheet, constructionsData);
      }
      //Pattern
      else if (sheetName == "Pattern") {
        var patternData = excelData[0]['patterns'];
        this.bindPatternData(worksheet, patternData);
      }

      //Specifications
      else if (sheetName == "Measurements") {
        var specificationData = excelData[0]['stylePOMs'];
        var stichesData = excelData[0]['styleStitchess'];
        this.bindSpecificationsData(workbook, worksheet, specificationData, stichesData);
      }
      //Processing
      // else if (sheetName == "Processing") {
      //   var processingData = excelData[0]['processingPOMs'];
      //   var sewnData = excelData[0]['processingSewns'];
      //   this.bindProcessingData(workbook, worksheet, processingData, sewnData);
      // }
      // FlatSketch
      else if (sheetName == "FlatSketch") {
        var styleImagesData = excelData[0]['styleImages'];
        this.bindFlatSketch(workbook, worksheet, styleImagesData, excelData);
      }

      //worksheet.addRow([]);
    });
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      });
      fs.saveAs(blob, title + '.xlsx');
    })
  }

  bindStyleStaticData(sheetName, worksheet, excelData) {
    //Add Title
    worksheet.mergeCells('A1', 'D2');
    let titleRow1 = worksheet.getCell('A1');
    titleRow1.value = "FitOS"
    titleRow1.font = { name: 'Calibri', size: 14, bold: true, color: { argb: 'FFFFFF' } };
    titleRow1.alignment = { vertical: 'middle', horizontal: 'center' };
    titleRow1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00008B' } };
    titleRow1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    //Add Title1
    worksheet.mergeCells('E1', 'K2');
    let titleRow2 = worksheet.getCell('E1');
    titleRow2.value = sheetName.toUpperCase();
    titleRow2.font = { name: 'Calibri', size: 14, bold: true, color: { argb: 'FFFFFF' } };
    titleRow2.alignment = { vertical: 'middle', horizontal: 'center' };
    titleRow2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4500' } };
    titleRow2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    //styleName
    var styleName = excelData[0]['styleName'];
    worksheet.mergeCells('A3', 'B3');
    let A3 = worksheet.getCell('A3');
    A3.value = "STYLE NAME";
    A3.font = { name: 'Calibri', size: 10, bold: true }
    A3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A3.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    worksheet.mergeCells('C3', 'D3');
    let C3 = worksheet.getCell('C3');
    C3.font = { name: 'Calibri', size: 10 };
    C3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (styleName) {
      C3.value = styleName.toString().toUpperCase();
    }

    //styleNumber
    var styleNumber = excelData[0]['styleNumber'];
    worksheet.mergeCells('A4', 'B4');
    let A4 = worksheet.getCell('A4');
    A4.value = "STYLE NUMBER";
    A4.font = { name: 'Calibri', size: 10, bold: true }
    A4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A4.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    worksheet.mergeCells('C4', 'D4');
    let C4 = worksheet.getCell('C4');
    C4.font = { name: 'Calibri', size: 10 };
    C4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (styleNumber) {
      C4.value = styleNumber.toString().toUpperCase();
    }

    //category
    var category = excelData[0]['category'];
    worksheet.mergeCells('A5', 'B5');
    let A5 = worksheet.getCell('A5');
    A5.value = "CATEGORY";
    A5.font = { name: 'Calibri', size: 10, bold: true }
    A5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A5.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    worksheet.mergeCells('C5', 'D5');
    let C5 = worksheet.getCell('C5');
    C5.font = { name: 'Calibri', size: 10 };
    C5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (category) {
      C5.value = category.toString().toUpperCase();
    }

    //subCategory
    var subCategory = excelData[0]['subCategory'];
    worksheet.mergeCells('A6', 'B6');
    let A6 = worksheet.getCell('A6');
    A6.value = "SUB CATEGORY";
    A6.font = { name: 'Calibri', size: 10, bold: true }
    A6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A6.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    worksheet.mergeCells('C6', 'D6');
    let C6 = worksheet.getCell('C6');
    C6.font = { name: 'Calibri', size: 10 };
    C6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (subCategory) {
      C6.value = subCategory.toString().toUpperCase();
    }



    //targetGender
    var targetGender = excelData[0]['targetGender'];
    worksheet.mergeCells('E3', 'F3');
    let E3 = worksheet.getCell('E3');
    E3.value = "TARGET GENDER";
    E3.font = { name: 'Calibri', size: 10, bold: true }
    E3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    E3.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    // worksheet.mergeCells('G3', 'H3');
    let G3 = worksheet.getCell('G3');
    G3.font = { name: 'Calibri', size: 10 };
    G3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (targetGender) {
      G3.value = targetGender.toString().toUpperCase();
    }

    //ageGroup
    var ageGroup = excelData[0]['ageGroup'];
    worksheet.mergeCells('E4', 'F4');
    let E4 = worksheet.getCell('E4');
    E4.value = "AGE GROUP";
    E4.font = { name: 'Calibri', size: 10, bold: true }
    E4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    E4.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    // worksheet.mergeCells('G4', 'H4');
    let G4 = worksheet.getCell('G4');
    G4.font = { name: 'Calibri', size: 10 };
    G4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (ageGroup) {
      G4.value = ageGroup.toString().toUpperCase();
    }

    //sampleSize
    var sampleSize = excelData[0]['sampleSize'];
    worksheet.mergeCells('E5', 'F5');
    let E5 = worksheet.getCell('E5');
    E5.value = "SAMPLE SIZE";
    E5.font = { name: 'Calibri', size: 10, bold: true }
    E5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    E5.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    // worksheet.mergeCells('G5', 'H5');
    let G5 = worksheet.getCell('G5');
    G5.font = { name: 'Calibri', size: 10 };
    G5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (sampleSize) {
      G5.value = sampleSize.toString().toUpperCase();
    }

    //sizeRange
    var sizeRange = excelData[0]['sizeRange'];
    worksheet.mergeCells('E6', 'F6');
    let E6 = worksheet.getCell('E6');
    E6.value = "SAMPLE RANGE";
    E6.font = { name: 'Calibri', size: 10, bold: true }
    E6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    E6.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    // worksheet.mergeCells('G6', 'H6');
    let G6 = worksheet.getCell('G6');
    G6.font = { name: 'Calibri', size: 10 };
    G6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (sizeRange) {
      G6.value = sizeRange.toString().toUpperCase();
    }

    //fabric
    var fabric = this.aesdecryptservice.decryptData(this.dataservice.Key, excelData[0]['season']);
    worksheet.mergeCells('H3', 'I3');
    let I3 = worksheet.getCell('H3');
    I3.value = "SEASON";
    I3.font = { name: 'Calibri', size: 10, bold: true }
    I3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    I3.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    worksheet.mergeCells('J3', 'K3');
    let K3 = worksheet.getCell('J3');
    K3.font = { name: 'Calibri', size: 10 };
    K3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (fabric) {
      K3.value = fabric.toString().toUpperCase();
    }

    //technicalPackage
    // var technicalPackage = excelData[0]['technicalPackage'];
    // worksheet.mergeCells('I4', 'J4');
    // let I4 = worksheet.getCell('I4');
    // I4.value = "TECHNICAL PACKAGE";
    // I4.font = { name: 'Calibri', size: 10, bold: true }
    // I4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    // I4.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'D3D3D3' }
    // }
    // worksheet.mergeCells('K4', 'L4');
    // let K4 = worksheet.getCell('K4');
    // K4.font = { name: 'Calibri', size: 10 };
    // K4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    // if(technicalPackage)
    // {
    //   K4.value = technicalPackage.toString().toUpperCase();
    // }

    //status
    // var status = excelData[0]['status'];
    // worksheet.mergeCells('I5', 'J5');
    // let I5 = worksheet.getCell('I5');
    // I5.value = "STATUS";
    // I5.font = { name: 'Calibri', size: 10, bold: true }
    // I5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    // I5.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'D3D3D3' }
    // }
    // worksheet.mergeCells('K5', 'L5');
    // let K5 = worksheet.getCell('K5');
    // K5.font = { name: 'Calibri', size: 10 };
    // K5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    // if (status) {
    //   K5.value = status.toString().toUpperCase();
    // }

    //description
    var description = excelData[0]['description'];
    worksheet.mergeCells('H4', 'I6');
    let H4 = worksheet.getCell('H4');
    H4.value = "DESCRIPTION";
    H4.font = { name: 'Calibri', size: 10, bold: true }
    H4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    H4.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' }
    }
    worksheet.mergeCells('J4', 'K6');
    let J4 = worksheet.getCell('J4');
    J4.font = { name: 'Calibri', size: 10 };
    J4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    if (description) {
      J4.value = description.toString().toUpperCase();
    }

  }

  bindcolourwaysData(worksheet, stylecolourwaysData) {

    worksheet.mergeCells('A7', 'B7');
    let A7 = worksheet.getCell('A7');
    A7.value = "COLORS";
    A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('C7', 'D7');
    let C7 = worksheet.getCell('C7');
    C7.value = "COLOURWAY";
    C7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    C7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    C7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('E7', 'F7');
    let E7 = worksheet.getCell('E7');
    E7.value = "COMMENTS";
    E7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    E7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    E7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('G7', 'K7');
    let G7 = worksheet.getCell('G7');
    G7.value = "HEXCODE";
    G7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    G7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    G7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let rowIndex = 8;

    //Adding Data with Conditional Formatting
    if (stylecolourwaysData.length > 0) {
      stylecolourwaysData.forEach((colourways, index) => {
        rowIndex = index + rowIndex;

        // COLORS
        worksheet.mergeCells(`A${rowIndex}`, `B${rowIndex}`);
        let AIndex = worksheet.getCell(`A${rowIndex}`);
        AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        colourways['palettes']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, colourways['palettes']['name']);

        var name = colourways['palettes']['name'];
        if (name) {
          AIndex.value = name;
        }

        // colourway
        worksheet.mergeCells(`C${rowIndex}`, `D${rowIndex}`);
        let DIndex = worksheet.getCell(`C${rowIndex}`);
        DIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        colourways['colourway'] = this.aesdecryptservice.decryptData(this.dataservice.Key, colourways['colourway']);

        var colourway = colourways['colourway'];
        if (colourway) {
          DIndex.value = colourway;
        }

        // COMMENTS
        worksheet.mergeCells(`E${rowIndex}`, `F${rowIndex}`);
        let FIndex = worksheet.getCell(`E${rowIndex}`);
        FIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        colourways['comments'] = this.aesdecryptservice.decryptData(this.dataservice.Key, colourways['comments']);

        var comment = colourways['comments'];
        if (comment) {
          FIndex.value = comment;
        }

        // HEXCODE
        worksheet.mergeCells(`G${rowIndex}`, `H${rowIndex}`);
        let GIndex = worksheet.getCell(`G${rowIndex}`);
        GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        colourways['palettes']['colourHexCodes'] = this.aesdecryptservice.decryptData(this.dataservice.Key, colourways['palettes']['colourHexCodes']);

        var hexcode = colourways['palettes']['colourHexCodes'];
        var hex_arr = hexcode.split(',');
        console.log(hex_arr, 'hexarr');

        //  worksheet.getCell(`M8`).fill = {
        //   type: 'pattern',
        //   pattern:'solid',
        //   fgColor:{argb:'FF0000'}
        //   };

        for (var i = 0; i < hex_arr.length; i++) {
          var i1;
          if (i == 0) {
            i1 = 0;
          } else {
            i1 = i % 3;
          }
          var hex_rep = hex_arr[i].replace('#', '');
          // console.log(hex_rep,'hex_rep');
          // let data = { i8 j9 i10 j11
          //   'position' : i1,
          //   'color': {argb:hex_rep}
          //  };
          // this.hex_rep.push(data);

          let alp = ['I', 'J', 'K'];
          console.log('row index', alp[i] + `${rowIndex}`);
          if (i > 0 && i % 3 === 0) {
            rowIndex = rowIndex + 1;
            i1 = 0;
          }
          worksheet.getCell(alp[i1] + `${rowIndex}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: hex_rep }
            //degree: 0,
            // stops: [
            //   {position : i1,
            //   color: {argb:hex_rep}}
            // ]
          };

        }
        if (hex_arr.length > 3 && hex_arr.length < 6) {
          rowIndex = rowIndex + 1; 
        } 
        if (hex_arr.length > 6) {
          rowIndex = rowIndex + 2; 
        } 

        if (hexcode) {
          GIndex.value = hexcode;
        }



      });
    }
    else {
      const rowIndex = 9;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No data available";
    }
  }

  bindImagesData(workbook, worksheet, styleImagesData) {

    worksheet.mergeCells('A7', 'G7');
    let A7 = worksheet.getCell('A7');
    A7.value = "IMAGETYPE";
    A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('H7', 'K7');
    let G7 = worksheet.getCell('H7');
    G7.value = "DESCRIPTION";
    G7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    G7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    G7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };




    //Adding Data with Conditional Formatting
    if (styleImagesData?.length > 0) {

      var rowIndex = 0;
      styleImagesData?.forEach((styleImages, index) => {
        rowIndex = index + 8;
        var count;

        if (index == 0) {
          count = 1;
        }
        else {
          count = index + 1;
        }



        //UPLOADIMAGE
        worksheet.mergeCells(`A${rowIndex}`, `G${rowIndex}`);
        let AIndex = worksheet.getCell(`G${rowIndex}`);
        AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        // styleImages['imageType'] = this.aesdecryptservice.decryptData(this.dataservice.Key, styleImages['imageType']);
        var imageType = styleImages['imageType'];
        if (imageType) {
          AIndex.value = imageType;
        }

        //DESCRIPTION
        worksheet.mergeCells(`H${rowIndex}`, `K${rowIndex}`);
        let GIndex = worksheet.getCell(`H${rowIndex}`);
        GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        styleImages['comments'] = this.aesdecryptservice.decryptData(this.dataservice.Key, styleImages['comments']);
        var comments = styleImages['comments'];
        if (comments) {
          GIndex.value = comments;
        }

      });

      //Images
      rowIndex = rowIndex + 1;
      var FBaseIndex = 8;
      let FIndex = 0;
      let currentColumn = 0;
      let columnSpan = 0;
      let rowSpan = rowIndex;
      let styleImgData = [];
      styleImagesData?.forEach((StyleImage: any, index: number) => {
        if (StyleImage['image']?.fileType === ".png" || StyleImage['image']?.fileType === ".jpg" || StyleImage['image']?.fileType === ".jpeg") {
          styleImgData.push(StyleImage);
        }
      });
      styleImgData?.forEach((StyleImage: any, index: number) => {
        const startRow = 10;
        if (StyleImage?.imageType !== undefined) {
            let base64URL = StyleImage['image']['base64URL'];
            if (base64URL) {
              rowIndex = index + rowIndex;
              currentColumn = currentColumn + columnSpan;
              columnSpan = 4;
              if (index > 1 && index % 3 === 0) {
                rowSpan = rowSpan + 8;
                currentColumn = 0;
              }
              var imageId2 = workbook.addImage({
                base64: base64URL,
                extension: 'png',
              });
              FIndex = FBaseIndex + rowIndex;

              worksheet.addImage(imageId2, {
                tl: { col: currentColumn, row: rowSpan },
                br: { col: currentColumn + 3, row: rowSpan + 7 },
              });

            }

            // worksheet.mergeCells(`H${rowIndex}`, `K${FIndex}`);
            // let GIndex = worksheet.getCell(`H${rowIndex}`);
            // GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // GIndex.alignment = { vertical: 'middle', horizontal: 'center' };

            // var comments = StyleImage['comments'];
            // if (comments) {
            //   GIndex.value = comments;
            // }
            rowIndex = FIndex;
        }
      });


    }
    else {
      const rowIndex = 9;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No data available";

    }

  }

  bindBOMData(worksheet, styleBomsData) {

    worksheet.mergeCells('A7', 'B7');
    let A7 = worksheet.getCell('A7');
    A7.value = "COMPONENT";
    A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };


    worksheet.mergeCells('C7', 'D7');
    let C7 = worksheet.getCell('C7');
    C7.value = "MATERIALNAME";
    C7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    C7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    C7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('E7', 'F7');
    let E7 = worksheet.getCell('E7');
    E7.value = "MATERIALTYPE";
    E7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    E7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    E7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };


    // worksheet.mergeCells('G7', 'H7');
    let G7 = worksheet.getCell('G7');
    G7.value = "VENDOR";
    G7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    G7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    G7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let I7 = worksheet.getCell('H7');
    I7.value = "NAME";
    I7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    I7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    I7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let J7 = worksheet.getCell('I7');
    J7.value = "COLOR";
    J7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    J7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    J7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let K7 = worksheet.getCell('J7');
    K7.value = "SIZE";
    K7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    K7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    K7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let L7 = worksheet.getCell('K7');
    L7.value = "QUANTITY";
    L7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    L7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    L7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };



    //Adding Data with Conditional Formatting
    if (styleBomsData.length > 0) {

      styleBomsData.forEach((bom, index) => {
        const rowIndex = index + 8;

        //component
        worksheet.mergeCells(`A${rowIndex}`, `B${rowIndex}`);
        let AIndex = worksheet.getCell(`A${rowIndex}`);
        AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };


        bom['component'] = this.aesdecryptservice.decryptData(this.dataservice.Key, bom['component']);

        var component = bom['component'];
        if (component) {
          AIndex.value = component;
        }


        //materialType
        worksheet.mergeCells(`C${rowIndex}`, `D${rowIndex}`);
        let CIndex = worksheet.getCell(`C${rowIndex}`);
        CIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        bom['materialType'] = this.aesdecryptservice.decryptData(this.dataservice.Key, bom['materialType']);
        var materialType = bom['materialType'];
        if (materialType) {
          CIndex.value = materialType;
        }

        //materialName
        worksheet.mergeCells(`E${rowIndex}`, `F${rowIndex}`);
        let EIndex = worksheet.getCell(`E${rowIndex}`);
        EIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        bom['materialName'] = this.aesdecryptservice.decryptData(this.dataservice.Key, bom['materialName']);
        var materialName = bom['materialName'];
        if (materialName) {
          EIndex.value = materialName;
        }

        //vendor
        // worksheet.mergeCells(`G${rowIndex}`, `H${rowIndex}`);
        let GIndex = worksheet.getCell(`G${rowIndex}`);
        GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        if (bom['supplier']) {
          bom['supplier']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, bom['supplier']['name']);
          var vendor = bom['supplier']['name'];
          if (vendor) {
            GIndex.value = vendor;
          }
        }


        //colourName
        let IIndex = worksheet.getCell(`H${rowIndex}`);
        IIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        if (bom['colour']) {
          bom['colour']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, bom['colour']['name']);
          var colourName = bom['colour']['name']
          if (colourName) {
            IIndex.value = colourName;
          }
        }


        //colourChip
        let JIndex = worksheet.getCell(`I${rowIndex}`);
        JIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        bom['colourChip'] = this.aesdecryptservice.decryptData(this.dataservice.Key, bom['colourChip']);
        var colourChip = bom['colourChip'];
        if (colourChip) {
          JIndex.value = colourChip;
        }

        //sizeOrWidth
        let KIndex = worksheet.getCell(`J${rowIndex}`);
        KIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        bom['sizeOrWidth'] = this.aesdecryptservice.decryptData(this.dataservice.Key, bom['sizeOrWidth']);
        var sizeOrWidth = bom['sizeOrWidth'];
        if (sizeOrWidth) {
          KIndex.value = sizeOrWidth;
        }


        //quantity
        let LIndex = worksheet.getCell(`K${rowIndex}`);
        LIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        bom['quantity'] = this.aesdecryptservice.decryptData(this.dataservice.Key, bom['quantity']);
        var quantity = bom['quantity'];
        if (quantity) {
          LIndex.value = quantity;
        }
      });
    }
    else {
      const rowIndex = 9;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No data available";
    }


  }

  bindConstructionData(workbook, worksheet, constructionsData) {

    // worksheet.mergeCells('A7', 'B7');
    let A7 = worksheet.getCell('A7');
    A7.value = "NO";
    A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('B7', 'E7');
    let C7 = worksheet.getCell('B7');
    C7.value = "OPERATION";
    C7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    C7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    C7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('F7', 'H7');
    let G7 = worksheet.getCell('F7');
    G7.value = "M/C TYPE";
    G7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    G7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    G7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('I7', 'K7');
    let J7 = worksheet.getCell('I7');
    J7.value = "SEAM";
    //J7.value = "S.M.V";
    J7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    J7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    J7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    //Adding Data with Conditional Formatting
    if (constructionsData?.length > 0) {

      var rowIndex = 0;
      constructionsData?.forEach((construction, index) => {
        rowIndex = index + 8;
        var count;

        if (index == 0) {
          count = 1;
        }
        else {
          count = index + 1;
        }

        //no
        // worksheet.mergeCells(`A${rowIndex}`, `B${rowIndex}`);
        let AIndex = worksheet.getCell(`A${rowIndex}`);
        AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
        AIndex.value = count;

        //operation
        worksheet.mergeCells(`B${rowIndex}`, `E${rowIndex}`);
        let CIndex = worksheet.getCell(`C${rowIndex}`);
        CIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        construction['operation'] = this.aesdecryptservice.decryptData(this.dataservice.Key, construction['operation']);
        var operation = construction['operation'];
        if (operation) {
          CIndex.value = operation;
        }

        //mC_Type
        worksheet.mergeCells(`F${rowIndex}`, `H${rowIndex}`);
        let GIndex = worksheet.getCell(`G${rowIndex}`);
        GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        construction['mC_Type'] = this.aesdecryptservice.decryptData(this.dataservice.Key, construction['mC_Type']);
        var mC_Type = construction['mC_Type'];
        if (mC_Type) {
          GIndex.value = mC_Type;
        }

        //smv
        worksheet.mergeCells(`I${rowIndex}`, `K${rowIndex}`);
        let JIndex = worksheet.getCell(`J${rowIndex}`);
        JIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        construction['smv'] = this.aesdecryptservice.decryptData(this.dataservice.Key, construction['smv']);
        var smv = construction['smv'];
        if (smv) {
          JIndex.value = smv;
        }

      });

       //Images
       rowIndex = rowIndex + 1;
       var FBaseIndex = 6;
       let currentColumn = 0;
           let columnSpan = 0;
           let rowSpan = rowIndex;
           let FIndex = 0;
       constructionsData?.forEach((constructions: any, index: number) => {
 
         rowIndex = index + rowIndex;
 
         if (constructions['image']) {
           currentColumn = currentColumn + columnSpan;
 
           columnSpan = 3;
           if(index > 0) {
             rowSpan = rowSpan + 7;
             currentColumn = 0;
           }
           let base64URL = constructions['image']['base64URL'];
           if (base64URL) {
             var imageId2 = workbook.addImage({
               base64: base64URL,
               extension: 'png',
             });
             FIndex = FBaseIndex + rowIndex;
            //  worksheet.mergeCells(`A${rowIndex}`, `F${FIndex}`);
             worksheet.addImage(imageId2, {
               tl: { col: currentColumn, row: rowSpan },
               br: { col: currentColumn + 3, row: rowSpan + 7 },
                   });
           }
 
           worksheet.mergeCells(`G${rowIndex}`, `K${FIndex}`);
           let GIndex = worksheet.getCell(`G${rowIndex}`);
           GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
           GIndex.alignment = { vertical: 'middle', horizontal: 'center' };
 
           var operation = constructions['operation'];
           if (operation) {
             GIndex.value = operation;
           }
           rowIndex = FIndex;
 
         }
       });

    }
    else {
      const rowIndex = 9;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No data available";

    }

  }

  bindPatternData(worksheet, patternData) {
    let A7 = worksheet.getCell('A7');
    A7.value = "CUT";
    A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let B7 = worksheet.getCell('B7');
    B7.value = "PC#";
    B7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    B7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    B7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('C7', 'I7');
    let C7 = worksheet.getCell('C7');
    C7.value = "DESCRIPTION";
    C7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    C7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    C7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('J7', 'K7');
    let K7 = worksheet.getCell('J7');
    K7.value = "ALLOCATION";
    K7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    K7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    K7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    //Adding Data with Conditional Formatting
    if (patternData.length > 0) {
      patternData.forEach((pattern, index) => {

        const rowIndex = index + 8;

        //cut
        let AIndex = worksheet.getCell(`A${rowIndex}`);
        AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        pattern['cut'] = this.aesdecryptservice.decryptData(this.dataservice.Key, pattern['cut']);
        var cut = pattern['cut'];
        if (cut) {
          AIndex.value = cut;
        }

        //pc
        let BIndex = worksheet.getCell(`B${rowIndex}`);
        BIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        pattern['pc'] = this.aesdecryptservice.decryptData(this.dataservice.Key, pattern['pc']);
        var pc = pattern['pc'];
        if (pc) {
          BIndex.value = pc;
        }

        //description
        worksheet.mergeCells(`C${rowIndex}`, `I${rowIndex}`);
        let CIndex = worksheet.getCell(`C${rowIndex}`);
        CIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        pattern['description'] = this.aesdecryptservice.decryptData(this.dataservice.Key, pattern['description']);
        var description = pattern['description'];
        if (description) {
          CIndex.value = description;
        }

        //allocation
        worksheet.mergeCells(`J${rowIndex}`, `K${rowIndex}`);
        let KIndex = worksheet.getCell(`J${rowIndex}`);
        KIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        pattern['allocation'] = this.aesdecryptservice.decryptData(this.dataservice.Key, pattern['allocation']);
        var allocation = pattern['allocation'];
        if (allocation) {
          KIndex.value = allocation;
        }

      });
    }
    else {

      const rowIndex = 9;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No data available";

    }


  }

  bindSpecificationsData(workbook: any, worksheet: any, specificationdetails: any, stichesdetails: any) {
    worksheet.mergeCells('A7', 'K7');
    let A7 = worksheet.getCell('A7');
    A7.value = "POM";
    A7.font = { name: 'Calibri', size: 10, bold: true, color: { argb: 'FFFFFF' } };
    A7.alignment = { vertical: 'middle', horizontal: 'center' };
    A7.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4500' } };
    A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };


    // worksheet.mergeCells('A8', 'C8');
    let A8 = worksheet.getCell('A8');
    A8.value = "POMS";
    A8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };
    worksheet.mergeCells('B8', 'C8');
    let B8 = worksheet.getCell('B8')
    B8.value='Description';
    B8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    B8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let D8 = worksheet.getCell('D8');
    D8.value = "XS";
    D8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    D8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    D8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let E8 = worksheet.getCell('E8');
    E8.value = "S";
    E8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    E8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    E8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let F8 = worksheet.getCell('F8');
    F8.value = "M";
    F8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    F8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    F8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let G8 = worksheet.getCell('G8');
    G8.value = "L";
    G8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    G8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    G8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };




    let H8 = worksheet.getCell('H8');
    H8.value = "XL";
    H8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    H8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    H8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let I8 = worksheet.getCell('I8');
    I8.value = "XXL";
    I8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    I8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    I8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('J8', 'K8');
    let J8 = worksheet.getCell('J8');
    J8.value = "TOLERANCE";
    J8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    J8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    J8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    //Adding Data with Conditional Formatting
    var rowIndex = 0;
    if (specificationdetails?.length > 0) {
      specificationdetails?.forEach((specification: any, index: number) => {
        rowIndex = index + 9;
        //POMS
        // worksheet.mergeCells(`A${rowIndex}`, `C${rowIndex}`);
        let AIndex = worksheet.getCell(`A${rowIndex}`);
        AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        specification['poms'] = this.aesdecryptservice.decryptData(this.dataservice.Key, specification['poms']);
        var POMS = specification['poms'];
        if (POMS) {
          AIndex.value = POMS;
        }

        //POMS Description
        worksheet.mergeCells(`B${rowIndex}`, `C${rowIndex}`);
        let BIndex = worksheet.getCell(`B${rowIndex}`);
        BIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        // specification['description'] = specification['description']
        var POMSDescription = specification['description'];
        console.log(POMSDescription, 'POMSDescription');
        if (POMSDescription) {
          BIndex.value = POMSDescription;
        }

        //XS
        let DIndex = worksheet.getCell(`D${rowIndex}`);
        DIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        specification['xs'] = this.aesdecryptservice.decryptData(this.dataservice.Key, specification['xs']);
        var XS = specification['xs'];
        if (XS) {
          DIndex.value = XS;
        }

        //S
        let EIndex = worksheet.getCell(`E${rowIndex}`);
        EIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        specification['s'] = this.aesdecryptservice.decryptData(this.dataservice.Key, specification['s']);
        var S = specification['s'];
        if (S) {
          EIndex.value = S;
        }

        //M
        let FIndex = worksheet.getCell(`F${rowIndex}`);
        FIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        specification['m'] = this.aesdecryptservice.decryptData(this.dataservice.Key, specification['m']);
        var M = specification['m'];
        if (M) {
          FIndex.value = M;
        }

        //L
        let GIndex = worksheet.getCell(`G${rowIndex}`);
        GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        specification['l'] = this.aesdecryptservice.decryptData(this.dataservice.Key, specification['l']);
        var L = specification['l'];
        if (L) {
          GIndex.value = L;
        }
        //XL
        let HIndex = worksheet.getCell(`H${rowIndex}`);
        HIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        specification['xl'] = this.aesdecryptservice.decryptData(this.dataservice.Key, specification['xl']);
        var XL = specification['xl'];
        if (XL) {
          HIndex.value = XL;
        }

        //XXL
        let IIndex = worksheet.getCell(`I${rowIndex}`);
        IIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        specification['doubleXL'] = this.aesdecryptservice.decryptData(this.dataservice.Key, specification['doubleXL']);
        var doubleXL = specification['doubleXL'];
        if (doubleXL) {
          IIndex.value = doubleXL;
        }

        //TOLERANCE
        worksheet.mergeCells(`J${rowIndex}`, `K${rowIndex}`);
        let JIndex = worksheet.getCell(`J${rowIndex}`);
        JIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        specification['tolerance'] = this.aesdecryptservice.decryptData(this.dataservice.Key, specification['tolerance']);
        var tolerance = specification['tolerance'];
        if (tolerance) {
          JIndex.value = tolerance;
        }
      });
    }
    else {
      rowIndex = rowIndex + 9;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No Data Available";
    }

    if (rowIndex == 0) {
      rowIndex = rowIndex + 9;
    }
    else {
      rowIndex = rowIndex + 1;
    }
    worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
    let titleIndex = worksheet.getCell(`A${rowIndex}`);
    titleIndex.value = "STITCHES";
    titleIndex.font = { name: 'Calibri', size: 10, bold: true, color: { argb: 'FFFFFF' } };
    titleIndex.alignment = { vertical: 'middle', horizontal: 'center' };
    titleIndex.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4500' } };
    titleIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    rowIndex = rowIndex + 1;
    worksheet.mergeCells(`A${rowIndex}`, `C${rowIndex}`);
    let AStitches = worksheet.getCell(`A${rowIndex}`);
    AStitches.value = "STITCHES";
    AStitches.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    AStitches.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    AStitches.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells(`D${rowIndex}`, `G${rowIndex}`);
    let EPlacement = worksheet.getCell(`D${rowIndex}`);
    EPlacement.value = "PLACEMENT";
    EPlacement.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    EPlacement.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    EPlacement.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells(`H${rowIndex}`, `K${rowIndex}`);
    let INotes = worksheet.getCell(`H${rowIndex}`);
    INotes.value = "NOTES";
    INotes.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    INotes.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    INotes.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    if (stichesdetails?.length > 0) {
      rowIndex = rowIndex + 1;
      stichesdetails.forEach((stiches: any, indexS: number) => {
        rowIndex = indexS + rowIndex;
        //Stitches

        worksheet.mergeCells(`A${rowIndex}`, `C${rowIndex}`);
        let ARows = worksheet.getCell(`A${rowIndex}`);
        ARows.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        stiches['stitch']['isO4915Number'] = this.aesdecryptservice.decryptData(this.dataservice.Key, stiches['stitch']['isO4915Number']);
        var isO4915Number = stiches['stitch']['isO4915Number'];
        if (isO4915Number) {
          ARows.value = isO4915Number;
        }

        //Placement
        worksheet.mergeCells(`D${rowIndex}`, `G${rowIndex}`);
        let ERows = worksheet.getCell(`D${rowIndex}`);
        ERows.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        stiches['placement'] = this.aesdecryptservice.decryptData(this.dataservice.Key, stiches['placement']);
        var placement = stiches['placement'];
        if (placement) {
          ERows.value = placement;
        }

        //Notes
        worksheet.mergeCells(`H${rowIndex}`, `K${rowIndex}`);
        let IRows = worksheet.getCell(`H${rowIndex}`);
        IRows.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        stiches['notes'] = this.aesdecryptservice.decryptData(this.dataservice.Key, stiches['notes']);
        var notes = stiches['notes'];
        if (notes) {
          IRows.value = notes;
        }
      });

      //Images
      rowIndex = rowIndex + 1;
      var FBaseIndex = 6;
      let FIndex = 0;
      let currentColumn = 0;
      let rowSpan = rowIndex;
      let columnSpan = 0;

      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let titleIndexTop = worksheet.getCell(`A${rowIndex}`);
      titleIndexTop.value = "TOP VIEW FILE";
      titleIndexTop.font = { name: 'Calibri', size: 10, bold: true, color: { argb: 'FFFFFF' } };
      titleIndexTop.alignment = { vertical: 'middle', horizontal: 'center' };
      titleIndexTop.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4500' } };
      titleIndexTop.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
  
      stichesdetails?.forEach((stiches: any, imgi: number) => {
        let topviewFile = stiches['stitch']['topViewFile'];
        if (topviewFile) {
          let base64URL1 = stiches['stitch']['topViewFile']['base64URL'];
          console.log('base64URL1', base64URL1)
          currentColumn = currentColumn + columnSpan;

          columnSpan = 4;
          if (imgi > 1 && imgi % 3 === 0) {
            rowSpan = rowSpan + 8;
            currentColumn = 0;
          }
          if (base64URL1 != null) {
            let base64URL = stiches['stitch']['topViewFile']['base64URL'];
            var imageId2 = workbook.addImage({
              base64: base64URL,
              extension: 'png',
            });
            FIndex = FBaseIndex + rowIndex;
            // worksheet.mergeCells(`A${rowIndex}`, `F${FIndex}`);
            worksheet.addImage(imageId2, {
              tl: { col: currentColumn, row: rowSpan },
              br: { col: currentColumn + 3, row: rowSpan + 7 },
            });
          }
        }
        // rowIndex = FIndex;
      });

      rowIndex = rowIndex + 9;
      var FBaseIndex = 6;
      let FIndexBtm = 0;
      let currentColumnBtm = 0;
      let rowSpanBtm = rowIndex + 1;
      let columnSpanBtm = 0;

      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let titleIndexBtm = worksheet.getCell(`A${rowIndex}`);
      titleIndexBtm.value = "BOTTOM VIEW FILE";
      titleIndexBtm.font = { name: 'Calibri', size: 10, bold: true, color: { argb: 'FFFFFF' } };
      titleIndexBtm.alignment = { vertical: 'middle', horizontal: 'center' };
      titleIndexBtm.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4500' } };
      titleIndexBtm.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

      stichesdetails?.forEach((stiches: any, imgi: number) => {
        rowIndex = imgi + rowIndex;

        let bottomViewFile = stiches['stitch']['bottomViewFile'];
        if (bottomViewFile) {
          
          let base64URL1 = stiches['stitch']['bottomViewFile']['base64URL'];
          console.log('base64URL1', base64URL1)
          if (base64URL1 != null) {
            currentColumnBtm = currentColumnBtm + columnSpanBtm;

            columnSpanBtm = 4;
            if ((imgi > 1 && imgi % 3 === 0)) {
              rowSpanBtm = rowSpanBtm + 8;
              currentColumnBtm = 0;
            }
            let base64URL = stiches['stitch']['bottomViewFile']['base64URL'];
            var imageId2 = workbook.addImage({
              base64: base64URL,
              extension: 'png',
            });
            FIndexBtm = FBaseIndex + rowIndex;
            // worksheet.mergeCells(`A${rowIndex}`, `F${FIndex}`);
            worksheet.addImage(imageId2, {
              tl: { col: currentColumnBtm, row: rowSpanBtm },
              br: { col: currentColumnBtm + 3, row: rowSpanBtm + 7 },
            });
          }
        }
        rowIndex = FIndexBtm;
      });

    }
    else {
      rowIndex = rowIndex + 1;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No Data Available";
    }

  }

  bindProcessingData(workbook, worksheet, processingdetails, sewnData) {
    worksheet.mergeCells('A7', 'K7');
    let A7 = worksheet.getCell('A7');
    A7.value = "POM";
    A7.font = { name: 'Calibri', size: 10, bold: true, color: { argb: 'FFFFFF' } };
    A7.alignment = { vertical: 'middle', horizontal: 'center' };
    A7.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4500' } };
    A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };


    worksheet.mergeCells('A8', 'E8');
    let A8 = worksheet.getCell('A8');
    //A8.value = "";
    A8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('F8', 'G8');
    let G8 = worksheet.getCell('F8');
    G8.value = "ORIGINAL";
    G8.alignment = { vertical: 'middle', horizontal: 'center' };
    G8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    G8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    G8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('H8', 'I8');
    let I8 = worksheet.getCell('H8');
    I8.value = "AFTER WASH";
    I8.alignment = { vertical: 'middle', horizontal: 'center' };
    I8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    I8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    I8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('J8', 'K8');
    let K8 = worksheet.getCell('J8');
    K8.value = "AFTER DRY";
    K8.alignment = { vertical: 'middle', horizontal: 'center' };
    K8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    K8.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    K8.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };


    worksheet.mergeCells('A9', 'E9');
    let A9 = worksheet.getCell('A9');
    A9.value = "POMS";
    A9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A9.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A9.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let G9 = worksheet.getCell('F9');
    G9.value = "Length";
    G9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    G9.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    G9.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };


    let H9 = worksheet.getCell('G9');
    H9.value = "Width";
    H9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    H9.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    H9.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let I9 = worksheet.getCell('H9');
    I9.value = "Length";
    I9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    I9.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    I9.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let J9 = worksheet.getCell('I9');
    J9.value = "Width";
    J9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    J9.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    J9.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let K9 = worksheet.getCell('J9');
    K9.value = "Length";
    K9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    K9.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    K9.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let L9 = worksheet.getCell('K9');
    L9.value = "Width";
    L9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    L9.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    L9.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };


    //Adding Data with Conditional Formatting
    var rowIndex = 0;
    if (processingdetails?.length > 0) {
      processingdetails.forEach((process, index) => {
        rowIndex = index + 10;
        //POMS
        worksheet.mergeCells(`A${rowIndex}`, `E${rowIndex}`);
        let AIndex = worksheet.getCell(`A${rowIndex}`);
        AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        process['poms'] = this.aesdecryptservice.decryptData(this.dataservice.Key, process['poms']);
        var POMS = process['poms'];
        if (POMS) {
          AIndex.value = POMS;
        }

        //originalLength
        let originalLength = worksheet.getCell(`F${rowIndex}`);
        originalLength.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        process['originalLength'] = this.aesdecryptservice.decryptData(this.dataservice.Key, process['originalLength']);
        var origLength = process['originalLength'];
        if (origLength) {
          originalLength.value = origLength;
        }

        //originalWidth
        let originalWidth = worksheet.getCell(`G${rowIndex}`);
        originalWidth.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        process['originalWidth'] = this.aesdecryptservice.decryptData(this.dataservice.Key, process['originalWidth']);
        var origWidth = process['originalWidth'];
        if (origWidth) {
          originalWidth.value = origWidth;
        }


        //afterwashLength
        let afterwashLength = worksheet.getCell(`H${rowIndex}`);
        afterwashLength.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        process['afterwashLength'] = this.aesdecryptservice.decryptData(this.dataservice.Key, process['afterwashLength']);
        var washLength = process['afterwashLength'];
        if (washLength) {
          afterwashLength.value = washLength;
        }


        //afterwashWidth
        let afterwashWidth = worksheet.getCell(`I${rowIndex}`);
        afterwashWidth.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        process['afterwashWidth'] = this.aesdecryptservice.decryptData(this.dataservice.Key, process['afterwashWidth']);
        var washWidth = process['afterwashWidth'];
        if (washWidth) {
          afterwashWidth.value = washWidth;
        }

        //afterdryLength
        let afterdryLength = worksheet.getCell(`J${rowIndex}`);
        afterdryLength.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        process['afterdryLength'] = this.aesdecryptservice.decryptData(this.dataservice.Key, process['afterdryLength']);
        var dryLength = process['afterdryLength'];
        if (dryLength) {
          afterdryLength.value = dryLength;
        }


        //afterdryWidth
        let afterdryWidth = worksheet.getCell(`K${rowIndex}`);
        afterdryWidth.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        process['afterdryWidth'] = this.aesdecryptservice.decryptData(this.dataservice.Key, process['afterdryWidth']);
        var dryWidth = process['afterdryWidth'];
        if (dryWidth) {
          afterdryWidth.value = dryWidth;
        }
      });
    }
    else {
      rowIndex = rowIndex + 10;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No data available";
    }


    if (rowIndex == 0) {
      rowIndex = rowIndex + 10;
    }
    else {
      rowIndex = rowIndex + 1;
    }

    //SEWN

    worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
    let titleIndex = worksheet.getCell(`A${rowIndex}`);
    titleIndex.value = "SEWN";
    titleIndex.font = { name: 'Calibri', size: 10, bold: true, color: { argb: 'FFFFFF' } };
    titleIndex.alignment = { vertical: 'middle', horizontal: 'center' };
    titleIndex.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4500' } };
    titleIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    rowIndex = rowIndex + 1;
    worksheet.mergeCells(`A${rowIndex}`, `E${rowIndex}`);
    let APOMS = worksheet.getCell(`A${rowIndex}`);
    //APOMS.value = "";
    APOMS.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    APOMS.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    APOMS.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells(`F${rowIndex}`, `G${rowIndex}`);
    let GOriginal = worksheet.getCell(`F${rowIndex}`);
    GOriginal.value = "ORIGINAL";
    GOriginal.alignment = { vertical: 'middle', horizontal: 'center' };
    GOriginal.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    GOriginal.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    GOriginal.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells(`H${rowIndex}`, `I${rowIndex}`);
    let GWash = worksheet.getCell(`H${rowIndex}`);
    GWash.value = "AFTER WASH";
    GWash.alignment = { vertical: 'middle', horizontal: 'center' };
    GWash.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    GWash.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    GWash.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells(`J${rowIndex}`, `K${rowIndex}`);
    let KDry = worksheet.getCell(`J${rowIndex}`);
    KDry.value = "AFTER DRY";
    KDry.alignment = { vertical: 'middle', horizontal: 'center' };
    KDry.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    KDry.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    KDry.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    rowIndex = rowIndex + 1;
    worksheet.mergeCells(`A${rowIndex}`, `E${rowIndex}`);
    let ASewnPOMS = worksheet.getCell(`A${rowIndex}`);
    ASewnPOMS.value = "POMS";
    ASewnPOMS.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    ASewnPOMS.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    ASewnPOMS.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let GIndex = worksheet.getCell(`F${rowIndex}`);
    GIndex.value = "Length";
    GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    GIndex.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    GIndex.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };


    let HIndex = worksheet.getCell(`G${rowIndex}`);
    HIndex.value = "Width";
    HIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    HIndex.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    HIndex.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let Iindex = worksheet.getCell(`H${rowIndex}`);
    Iindex.value = "Length";
    Iindex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    Iindex.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    Iindex.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let JIndex = worksheet.getCell(`I${rowIndex}`);
    JIndex.value = "Width";
    JIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    JIndex.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    JIndex.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let KIndex = worksheet.getCell(`J${rowIndex}`);
    KIndex.value = "Length";
    KIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    KIndex.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    KIndex.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    let LIndex = worksheet.getCell(`K${rowIndex}`);
    LIndex.value = "Width";
    LIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    LIndex.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    LIndex.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };


    if (sewnData?.length > 0) {
      rowIndex = rowIndex + 1;
      sewnData?.forEach((sewn, index) => {
        rowIndex = index + rowIndex;

        //POMS
        worksheet.mergeCells(`A${rowIndex}`, `E${rowIndex}`);
        let AIndex = worksheet.getCell(`A${rowIndex}`);
        AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        sewn['poms'] = this.aesdecryptservice.decryptData(this.dataservice.Key, sewn['poms']);
        var POMS = sewn['poms'];
        if (POMS) {
          AIndex.value = POMS;
        }

        //originalLength
        let originalLength = worksheet.getCell(`F${rowIndex}`);
        originalLength.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        sewn['originalLength'] = this.aesdecryptservice.decryptData(this.dataservice.Key, sewn['originalLength']);
        var origLength = sewn['originalLength'];
        if (origLength) {
          originalLength.value = origLength;
        }

        //originalWidth
        let originalWidth = worksheet.getCell(`G${rowIndex}`);
        originalWidth.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        sewn['originalWidth'] = this.aesdecryptservice.decryptData(this.dataservice.Key, sewn['originalWidth']);
        var origWidth = sewn['originalWidth'];
        if (origWidth) {
          originalWidth.value = origWidth;
        }


        //afterwashLength
        let afterwashLength = worksheet.getCell(`H${rowIndex}`);
        afterwashLength.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        sewn['afterwashLength'] = this.aesdecryptservice.decryptData(this.dataservice.Key, sewn['afterwashLength']);
        var washLength = sewn['afterwashLength'];
        if (washLength) {
          afterwashLength.value = washLength;
        }


        //afterwashWidth
        let afterwashWidth = worksheet.getCell(`I${rowIndex}`);
        afterwashWidth.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        sewn['afterwashWidth'] = this.aesdecryptservice.decryptData(this.dataservice.Key, sewn['afterwashWidth']);
        var washWidth = sewn['afterwashWidth'];
        if (washWidth) {
          afterwashWidth.value = washWidth;
        }

        //afterdryLength
        let afterdryLength = worksheet.getCell(`J${rowIndex}`);
        afterdryLength.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        sewn['afterdryLength'] = this.aesdecryptservice.decryptData(this.dataservice.Key, sewn['afterdryLength']);
        var dryLength = sewn['afterdryLength'];
        if (dryLength) {
          afterdryLength.value = dryLength;
        }


        //afterdryWidth
        let afterdryWidth = worksheet.getCell(`K${rowIndex}`);
        afterdryWidth.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        sewn['afterdryWidth'] = this.aesdecryptservice.decryptData(this.dataservice.Key, sewn['afterdryWidth']);
        var dryWidth = sewn['afterdryWidth'];
        if (dryWidth) {
          afterdryWidth.value = dryWidth;
        }
      });


    }
    else {
      rowIndex = rowIndex + 1;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No data available";
    }
  }

  bindFlatSketch(workbook, worksheet, styleImagesData, excelData) {

    worksheet.mergeCells('A7', 'F7');
    let A7 = worksheet.getCell('A7');
    A7.value = "GARMENT TYPE";
    A7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    A7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    A7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    worksheet.mergeCells('G7', 'K7');
    let G7 = worksheet.getCell('G7');
    G7.value = "SILHOUETTE";
    G7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    G7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00008B' }
    };
    G7.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 10
    };

    var rowIndex = 8;

    //GARMENTTYPE
    worksheet.mergeCells(`A${rowIndex}`, `F${rowIndex}`);
    let AIndex = worksheet.getCell(`F${rowIndex}`);
    AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    excelData[0]['garmentType'] = this.aesdecryptservice.decryptData(this.dataservice.Key, excelData[0]['garmentType']);
    var garmentType = excelData[0]['garmentType'];
    if (garmentType) {
      AIndex.value = garmentType;
    }

    //SILHOUETTE
    worksheet.mergeCells(`G${rowIndex}`, `K${rowIndex}`);
    let GIndex = worksheet.getCell(`G${rowIndex}`);
    GIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    excelData[0]['silhouette'] = this.aesdecryptservice.decryptData(this.dataservice.Key, excelData[0]['silhouette']);
    var silhouette = excelData[0]['silhouette'];
    if (silhouette) {
      GIndex.value = silhouette;
    }

    if (styleImagesData?.length > 0) {
      //Images
      rowIndex = rowIndex + 1;
      var FBaseIndex = 8;
      let FIndex = 0;
      let currentColumn = 0;
      let columnSpan = 0;
      let rowSpan = rowIndex;
      styleImagesData?.forEach((StyleImage: any, index: number) => {
        if (StyleImage?.imageType == "flatSketches") {
          rowIndex = index + rowIndex;
          currentColumn = currentColumn + columnSpan;
          columnSpan = 4;
          if (index > 1 && index % 3 === 0) {
            rowSpan = rowSpan + 8;
            currentColumn = 0;
          }
          if (StyleImage['image']) {
            let base64URL = StyleImage['image']['base64URL'];
            if (base64URL) {
              var imageId2 = workbook.addImage({
                base64: base64URL,
                extension: 'png',
              });
              FIndex = FBaseIndex + rowIndex;

              worksheet.addImage(imageId2, {
                tl: { col: currentColumn, row: rowSpan },
                br: { col: currentColumn + 3, row: rowSpan + 7 },
              });

            }
            rowIndex = FIndex;
          }
        }
      });
    }
    else {
      const rowIndex = 9;
      worksheet.mergeCells(`A${rowIndex}`, `K${rowIndex}`);
      let AIndex = worksheet.getCell(`A${rowIndex}`);
      AIndex.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      AIndex.alignment = { vertical: 'middle', horizontal: 'center' };
      AIndex.value = "No Images available";
    }
  }

}
