import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";


export enum MoodBoardActionTypes {
    LOAD_MOODBOARDS = '[Moodboard] Load Moodboards',
    LOAD_MOODBOARDS_SUCCESS = '[Moodboard] Load Moodboards Success',
    LOAD_MOODBOARDS_FAIL = '[Moodboard] Load Moodboards Fail',
    LOAD_MOODBOARD = '[Moodboard] Load Moodboard',
    LOAD_MOODBOARD_SUCCESS = '[Moodboard] Load Moodboard Success',
    LOAD_MOODBOARD_FAIL = '[Moodboard] Load Moodboard Fail',
    CREATE_MOODBOARD = '[Moodboard] Create Moodboard',
    CREATE_MOODBOARD_SUCCESS = '[Moodboard] Create Moodboard Success',
    CREATE_MOODBOARD_FAIL = '[Moodboard] Create Moodboard Fail',
    UPDATE_MOODBOARD = '[Moodboard] Update Moodboard',
    UPDATE_MOODBOARD_SUCCESS = '[Moodboard] Update Moodboard Success',
    UPDATE_MOODBOARD_FAIL = '[Moodboard] Update Moodboard Fail',
    DELETE_MOODBOARD = '[Moodboard] Delete Moodboard',
    DELETE_MOODBOARD_SUCCESS = '[Moodboard] Delete Moodboard Success',
    DELETE_MOODBOARD_FAIL = '[Moodboard] Delete Moodboard Fail'
}

export class LoadMoodBoards implements Action {
    readonly type = MoodBoardActionTypes.LOAD_MOODBOARDS
    constructor(public payload1: string, public payload2: any) { }
}

export class LoadMoodBoardsSuccess implements Action {
    readonly type = MoodBoardActionTypes.LOAD_MOODBOARDS_SUCCESS

    constructor(public payload1: any, public payload2: any, public payload3: any) { }
}

export class LoadMoodBoardsFail implements Action {
    readonly type = MoodBoardActionTypes.LOAD_MOODBOARDS_FAIL

    constructor(public payload: string) { }
}

export class LoadMoodBoard implements Action {
    readonly type = MoodBoardActionTypes.LOAD_MOODBOARD

    constructor(public payload: string) { }
}

export class LoadMoodBoardSuccess implements Action {
    readonly type = MoodBoardActionTypes.LOAD_MOODBOARD_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadMoodBoardFail implements Action {
    readonly type = MoodBoardActionTypes.LOAD_MOODBOARD_FAIL

    constructor(public payload: string) { }
}
export class CreateMoodBoard implements Action {
    readonly type = MoodBoardActionTypes.CREATE_MOODBOARD

    constructor(public payload: any) { }
}

export class CreateMoodBoardSuccess implements Action {
    readonly type = MoodBoardActionTypes.CREATE_MOODBOARD_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class CreateMoodBoardFail implements Action {
    readonly type = MoodBoardActionTypes.CREATE_MOODBOARD_FAIL

    constructor(public payload: string) { }
}
export class UpdateMoodBoard implements Action {
    readonly type = MoodBoardActionTypes.UPDATE_MOODBOARD

    constructor(public payload: any) { }
}

export class UpdateMoodBoardSuccess implements Action {
    readonly type = MoodBoardActionTypes.UPDATE_MOODBOARD_SUCCESS

    constructor(public payload1: Update<any>, public payload2: any) { }
}

export class UpdateMoodBoardFail implements Action {
    readonly type = MoodBoardActionTypes.UPDATE_MOODBOARD_FAIL

    constructor(public payload: string) { }
}
export class DeleteMoodBoard implements Action {
    readonly type = MoodBoardActionTypes.DELETE_MOODBOARD

    constructor(public payload: string) { }
}

export class DeleteMoodBoardSuccess implements Action {
    readonly type = MoodBoardActionTypes.DELETE_MOODBOARD_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class DeleteMoodBoardFail implements Action {
    readonly type = MoodBoardActionTypes.DELETE_MOODBOARD_FAIL

    constructor(public payload: string) { }
}

export type moodBoardActionTypes = LoadMoodBoards | LoadMoodBoardsSuccess | LoadMoodBoardsFail |
    LoadMoodBoard | LoadMoodBoardSuccess | LoadMoodBoardFail | CreateMoodBoard | CreateMoodBoardSuccess |
    CreateMoodBoardFail | UpdateMoodBoard | UpdateMoodBoardSuccess | UpdateMoodBoardFail | DeleteMoodBoard
    | DeleteMoodBoardSuccess | DeleteMoodBoardFail;
