import * as colorActions from "../actions/color.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { IColor } from "../models/color";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface ColorState extends EntityState<any> {
    selectedColorId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const colorAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultColor: ColorState = {
    ids: [],
    entities: {},
    selectedColorId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = colorAdapter.getInitialState(defaultColor);

export function ColorReducer(state = initialState, action: colorActions.colorActionTypes): ColorState {
    switch (action.type) {
        case colorActions.ColorActionTypes.LOAD_COLOR_LIBRARY_SUCCESS:
            return colorAdapter.addMany(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case colorActions.ColorActionTypes.LOAD_COLOR_LIBRARY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case colorActions.ColorActionTypes.LOAD_COLOR_SUCCESS:
            return colorAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedColorId: action.payload1.id
            })
            break;
        case colorActions.ColorActionTypes.LOAD_COLOR_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case colorActions.ColorActionTypes.CREATE_COLOR_SUCCESS:
            return colorAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedColorId: action.payload1.id
            })
            break;
        case colorActions.ColorActionTypes.CREATE_COLOR_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case colorActions.ColorActionTypes.UPDATE_COLOR_SUCCESS:
            return colorAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                 ...state })
            break;
        case colorActions.ColorActionTypes.UPDATE_COLOR_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case colorActions.ColorActionTypes.UPDATE_COLOR_LIBRARY_SUCCESS:
            return colorAdapter.updateMany(action.payload1, { 
                ...state.message = action.payload2,
                ...state })
            break;
        case colorActions.ColorActionTypes.UPDATE_COLOR_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case colorActions.ColorActionTypes.DELETE_COLOR_LIBRARY_SUCCESS:
            return colorAdapter.removeMany(action.payload1,{
                ...state.message = (action.payload2 === 'Error') ? action.payload1 : action.payload2,
                ...state})
            break;
        case colorActions.ColorActionTypes.DELETE_COLOR_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case colorActions.ColorActionTypes.UPDATE_COLOR_DOCUMENT_SUCCESS:
            return colorAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state })
            break;
        case colorActions.ColorActionTypes.UPDATE_COLOR_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getColorLibraryFeatureState = createFeatureSelector<ColorState>('color');

export const getColorLibrary = createSelector(
    getColorLibraryFeatureState, colorAdapter.getSelectors().selectAll
)
export const getColorLibraryLoading = createSelector(
    getColorLibraryFeatureState, (state: ColorState) => state.loading
)
export const getColorLibraryLoaded = createSelector(
    getColorLibraryFeatureState, (state: ColorState) => state.loaded
)
export const getColorLibraryError = createSelector(
    getColorLibraryFeatureState, (state: ColorState) => state.error
)
export const getColorLibraryMessage = createSelector(
    getColorLibraryFeatureState, (state: ColorState) => state.message
)
export const getColorId = createSelector(
    getColorLibraryFeatureState, (state: ColorState) => state.selectedColorId
)
export const getColor = createSelector(
    getColorLibraryFeatureState, getColorId, state => state.entities[state.selectedColorId]
)