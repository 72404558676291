import { Component, Input, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { DataService } from '../../../services/data.service';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { LogService } from '../../../shared/log.service';
import Swal from 'sweetalert2';
import { UserService } from '../../../helpers/user.service';
import { CalSubTotal } from '../../../models/color';
import { StorageService } from '../../../helpers/storage.service';
import { process, filterBy, orderBy, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StyledetailsComponent } from '../styledetails/styledetails.component';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../services/shared.service';
import { Router } from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
declare var $: any;

@Component({
  selector: 'app-stylepattern',
  templateUrl: './stylepattern.component.html',
  styleUrls: ['./stylepattern.component.css']
})
export class StylePatternComponent implements OnInit {
  patternform: FormGroup;
  editData: any;
  showFileInput: boolean = true;
  appStaticMsgs: any;
  patternload = false;
  submitted = false;
  fileData: Array<any> = [];

  public patternstartCountList = 0;
  public patternendCountList = 10;
  public patternstartEndCount = "";
  public patternisPageChanged = false;
  public patternRecordCount: any;
  public patternloading: boolean;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  public isNew: boolean;

  modalReference: any;
  public filteredData: any[];
  patternData: any;
  isThumbnailAdded: boolean = false;
  imgload = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn: any = [];
  showImage = false;
  operationload = true;
  inputValue: number = 0;
  patternImages: any;
  patternImageData: any;
  showPatternNote = false;
  pattern: any = [];

  constructor(   private dialogRef: MatDialogRef<StyledetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private userservice: UserService,
    private storageservice: StorageService,
    private sharedService: SharedService,
    private router: Router,
    private toastService: ToastService,
    private modalService: NgbModal) {
      this.sharedService.getPatternData().subscribe(x => {
        this.editData = x;
      })
     }

     get f1() { return this.patternform.controls; }
     get fa() { return this.f1.formArray as FormArray; }

  ngOnInit(): void {
    console.log(this.data,'data');
    this.appStaticMsgs = appStaticMsg.data[0];
    if (this.data?.isPatternedit == false) {
    this.patternform = new FormGroup({
      'patternid': new FormControl(''),
      'pc': new FormControl('',Validators.required),
      'description': new FormControl(''),
      'cut': new FormControl('',Validators.required),
      'allocation': new FormControl('',Validators.required)
    });
  }
  else if (this.data?.isPatternedit == true) {
    this.patternform = new FormGroup({
      'patternid': new FormControl(this.data.patternData.id),
      'pc': new FormControl(this.data.patternData.pc,Validators.required),
      'description': new FormControl(this.data.patternData.description),
      'cut': new FormControl(this.data.patternData.cut,Validators.required),
      'allocation': new FormControl(this.data.patternData.allocation,Validators.required)
    });
    this.showFileInput = false;
  }
  this.getPatternbyId();
  }


  initSlectPicker(key) {
    $(document).ready(function () {
      $('#' + key).selectpicker();
    });
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessgetcolorwaysionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })

  }

  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    )
  }

  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove', 'selectAll');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Image Type' }, 'deselectAll');
    });
  }

  Modalopen(content) {
    console.log(content,'content')
    this.isThumbnailAdded = false;
    let ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    };

    this.modalReference = this.modalService.open(content, ngbModalOptions);
    console.log(this.modalReference,'this.modalRefe')
  }

  close() {
    this.dialogRef.close();
  }


  confirmPattern() {
    this.showImage = true;
    this.showFileInput = false;
    this.modalReference.close();
  }

  closePattern(event){
    this.croppedImage = [];
    this.fileToReturn='';
    this.showImage = false;
    this.showFileInput = true;
    this.fileUploader.nativeElement.value = null;
    this.modalReference.close();
  }

  public async savePattern({ sender, rowIndex, formGroup, isNew }) {

    if (this.patternform.controls.pc.value.length > 100) {
      this.patternform.controls.pc.setErrors({ 'incorrect': true });
    }
    if (this.patternform.controls.cut.value.length > 100) {
      this.patternform.controls.cut.setErrors({ 'incorrect': true });
    }
    if (this.patternform.controls.allocation.value.length > 100) {
      this.patternform.controls.allocation.setErrors({ 'incorrect': true });
    }
    if (this.patternform.controls.description.value.length > 200) {
      this.patternform.controls.description.setErrors({ 'incorrect': true });
    }

    if (this.patternform.invalid) {
      this.showPatternNote = true;
      // this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields)
      this.toastService.showError("Please enter all the mandatory fields.");
      return;
    }

    this.patternload = true;
    this.pattern = this.patternform.value;
    //this.closeEditor(sender,rowIndex);
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('patterncount', '1');
    if (this.data.isPatternedit == false) {
      formData.append('patterntag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add'));
      this.patternImages = '';
    } else {
      formData.append('patternid[0]', this.pattern['patternid']);
      formData.append('patterntag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update'));
      if(this.patternImages==undefined){
        this.patternImages = this.data.patternData.image
      }
    }
    formData.append('pc[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pattern['pc']));
    formData.append('description[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pattern['description']));
    formData.append('cut[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pattern['cut']));
    formData.append('allocation[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pattern['allocation']));

    console.log('this.patternImages', this.patternImages);
    if (!this.patternImages) {
      if (this.fileData.length != 0) {
        for (let f of this.fileData) {
          if (JSON.stringify(f) != '[]') {
            let fileUploadedData = await this.uploadFile(f);
            if (fileUploadedData != "error") {
              formData.append('imageid[0]', JSON.parse(fileUploadedData)["Key"]);
            }
            else {
              this.error(fileUploadedData);
              this.submitted = false;
              this.patternload = false;
              return false;
            }
          }
        }
      }
      else
        formData.append('imageid[0]', '')
    }
    this.patternload = true;
    this.dataservice.addupdatedeleteStylePatterns(formData).subscribe(async response => {
      this.showPatternNote = false;
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.patternload = false;
          this.isNew = false;
          // this.success(this.appStaticMsgs.styledetails.patternsavesuccessfully);

           this.logger.info(this.appStaticMsgs.styledetails.patternsavesuccessfully, "Style Pattern");
           if(this.data.isPatternedit == false){
            this.toastService.showSuccess('Added Successfully');
           }
           else{
            this.toastService.showSuccess('Updated Successfully');
           }
          this.dialogRef.close();
          await this.getPatternbyId();
          //his.patternform = undefined;
        }
        else {
          // this.error(response.body['item1']);
           this.logger.error(response.body['item1'], "Style Pattern");
          this.toastService.showError(response.body['item1']);
          this.patternload = false;
        }

      }
    }, err => {
      this.showPatternNote = false;
      this.logger.error(err.error.message, "Style Pattern");
      this.toastService.showError(err.error.message);
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
      }
      else {
         this.logger.error(err.error.message, "Style Pattern");
        // this.error(err.message);
        // this.toastService.showError(err.error.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.patternload = false;
    });
  }


  public async getPatternbyId() {
    this.patternloading = true;
    this.patternstartEndCount = this.patternstartCountList + "-" + this.patternendCountList;

    this.dataservice.getstylepatterns(this.storageservice.getEditedStyleId(), this.patternstartEndCount).subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.logger.info("getstylepatterns() method call", "Style Pattern");
        this.patternloading = false;
        this.filteredData = response.body['item1']['patterns'];
        this.patternRecordCount = response.body['item3'];

        if (response.body['item1']['patterns']) {
          for (let i = 0; i < this.filteredData.length; i++) {
            this.filteredData[i].allocation = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].allocation);
            this.filteredData[i].cut = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].cut);
            this.filteredData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].description);
            this.filteredData[i].pc = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].pc);
            //this.filteredData[i].pc = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].pc);
            if (this.filteredData[i].image) {
              this.filteredData[i].image.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].image.thumbnail);
            }
          }
          var gridView = {
            data: this.filteredData,
            total: this.patternRecordCount,
          };
          this.patternData = gridView;
        }
        else {
          this.patternData = null;
        }
        //this.patternform = undefined;
      }
      else {
        this.logger.error(response.body['item1'], "Style Pattern");

        this.error(response.body['item1']);
        this.patternloading = false;
      }
    }, err => {
      this.logger.error(err.error.message, "Style Pattern");

      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.patternloading = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
        this.patternloading = false;
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        this.patternloading = false;
      }
      else {
        this.logger.error(err.error.message, "Style Pattern");

        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);

      }
      this.patternloading = false;
    });
  }

  async uploadFile(file): Promise<any> {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          //console.log('There was an error uploading your file: ', err);
          reject("error");
        }
        //console.log('Successfully uploaded file.', data);
        resolve(JSON.stringify(data));
      });
    });
  }

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  onChangePatternImg(event) {
    if(event.target.files[0].type=='image/jpeg' || event.target.files[0].type=='image/png'){
      this.imgload = true;
      this.fileData = event.target.files;
      this.imageChangedEvent = event;
      this.isThumbnailAdded = true;
      this.fileToReturn='';
    }
  else if(event.target.files[0].type=='application/pdf' || event.target.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
    this.imgload = true;
    this.fileData = event.target.files;
    this.isThumbnailAdded = true;
    this.fileToReturn='';
    this.modalReference.close();
  }
  else{
    this.error("File Not Supported.")
    this.fileUploader.nativeElement.value = null;
    this.modalReference.close();

  }
}

removeThumbnailPatternSection(event) {
  console.log(event)
  if(this.data.isPatternedit==false){
  this.patternImages = '';
  this.croppedImage = [];
  this.fileToReturn='';
  this.showImage = false;
  this.showFileInput = true;
  }
  else{
  this.data.patternData.image = [];
  this.patternImages = '';
  this.croppedImage = [];
  this.fileToReturn='';
  this.showImage = false;
  this.showFileInput = true;
  }

}

imageCropped(event: any) {
  console.log(event,'imageevent')
  this.imgload = false;
  this.croppedImage = event.base64;
  console.log(event);
  this.fileToReturn = this.base64ToFile(
    event.base64,
    this.imageChangedEvent.target.files[0].name,
  )
  console.log(this.fileToReturn, 'filetoreturn');
}

base64ToFile(data: any, filename: any) {
  const arr = data.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  } return new File([u8arr], filename, { type: mime });
}


}
