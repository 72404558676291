<app-loader [showLoader]="showLoader"></app-loader>
<!-- Root -->
<div class="dt-root">
  <div class="dt-root__inner">
    <div class="dt-login--container">

      <!-- Login Content -->
      <div class="dt-login__content-wrapper">

        <!-- Login Background Section -->
        <div class="dt-login__bg-section">

          <div class="dt-login__bg-content">
            <!-- Login Title -->
            <h1 class="dt-login__title f-13">Verify Account</h1>
            <!-- /login title -->

            <p class="f-16">You're just one step away now!</p>
          </div>


          <!-- Brand logo -->
          <div class="dt-login__logo text-center">
            <a class="dt-brand__logo-link" href="javascript:void(0)">
              <img class="dt-brand__logo-img" alt="Fitos">
            </a>
          </div>
          <!-- /brand logo -->

        </div>
        <!-- /login background section -->

        <!-- Login Content Section -->
        <div class="dt-login__content">

          <!-- Login Content Inner -->
          <div class="dt-login__content-inner">

            <!-- Form -->
            <form [formGroup]="verificationForm" (ngSubmit)="verifyUser()" >

              <p class="f-13 mb-10" *ngIf="errorDiv" [innerHTML]="errors"></p>

              <!-- Form Group -->
              <div class="form-group mb-5">
                <input type="text" formControlName="code" id="code"  class="form-control" placeholder="Code" required/>
                <div *ngIf="(submitted && this.a.code.errors) ||(this.a.code.invalid && (this.a.code.dirty || this.a.code.touched))">
                  <div *ngIf="this.a.code.errors.required" class="red">
                    Verification Code is required.
                  </div>
                </div>
              </div>
              <!-- /form group -->

              <div class="form-group">
                <button type="submit" class="btn btn-primary">Verify Code</button>
              </div>

            </form>
            <!-- /form -->

          </div>
          <!-- /login content inner -->

          <!-- Login Content Footer -->
          <div class="dt-login__content-footer">
            <label>Still can't find the email?</label> &nbsp;<a href="javascript:;" (click)="ResendCode()">Resend Code</a>
          </div>
          <!-- /login content footer -->

        </div>
        <!-- /login content section -->

      </div>
      <!-- /login content -->

    </div>
  </div>
</div>
<!-- /root -->
