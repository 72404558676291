import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as commonActions from "../actions/common.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";

@Injectable()

export class CommonEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }

    @Effect()
    loadClassifications: Observable<Action> = this.actions.pipe(
        ofType<commonActions.LoadClassifications>(
            commonActions.CommonActionTypes.LOAD_CLASSIFICATIONS
        ), mergeMap((action: commonActions.LoadClassifications) =>
            this.dataService.getClassification(action.payload).pipe(
                map((classifications: any[]) => {
                    if (classifications['body']['item1'] != null && classifications['body']['item1'].length > 0 && !classifications['body']['item2'].toLowerCase().includes('error')) {
                        classifications['body']['item1'].forEach(z => {
                            z.text = this.aesdecryptservice.decryptData(this.dataService.Key, z.text);
                            if (z.items != null && z.items.length > 0) {
                                z.items.forEach(x => {
                                    x.text = this.aesdecryptservice.decryptData(this.dataService.Key, x.text);
                                    x.parentName = this.aesdecryptservice.decryptData(this.dataService.Key, x.parentName);
                                    if (x.items != null && x.items.length > 0) {
                                        x.items.forEach(y => {
                                            y.text = this.aesdecryptservice.decryptData(this.dataService.Key, y.text);
                                            y.parentName = this.aesdecryptservice.decryptData(this.dataService.Key, y.parentName);
                                        });
                                    }
                                });
                            }
                        });
                    }
                    return new commonActions.LoadClassificationsSuccess(classifications['body']['item1'], classifications['body'])
                }),
                catchError(err => of(new commonActions.LoadClassificationsFail(err)))
            )
        )
    );

    @Effect()
    loadAdvancedColorSearch: Observable<Action> = this.actions.pipe(
        ofType<commonActions.LoadAdvancedColorSearch>(
            commonActions.CommonActionTypes.LOAD_ADV_COLOR_SEARCH
        ), mergeMap((action: commonActions.LoadAdvancedColorSearch) =>
            this.dataService.getAdvanceSearchColor(action.payload).pipe(
                map((advColorSearch: any[]) => {
                    if (advColorSearch['body']['item1'] != null && advColorSearch['body']['item1'].length > 0 && !advColorSearch['body']['item2'].toLowerCase().includes('error')) {
                        advColorSearch['body']['item1'].forEach(x => {
                            x.colorStandard = this.aesdecryptservice.decryptData(this.dataService.Key, x.colorStandard);
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                            if (x.hexcode) {
                                x.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, x.hexcode);

                            } else {
                                if (x.thumbnailFiles['thumbnail'] != null) {
                                    x.thumbnailFiles['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles['thumbnail']);
                                }
                            }
                            if (x.createdBy != null) {
                                x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                                x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
                            }
                        });
                    }
                    return new commonActions.LoadAdvancedColorSearchSuccess(advColorSearch['body']['item1'], advColorSearch['body'])
                }
                ),
                catchError(err => of(new commonActions.LoadAdvancedColorSearchFail(err)))
            )
        )
    );

    @Effect()
    loadAdvancedImageSearch: Observable<Action> = this.actions.pipe(
        ofType<commonActions.LoadAdvancedImageSearch>(
            commonActions.CommonActionTypes.LOAD_ADV_IMAGE_SEARCH
        ), mergeMap((action: commonActions.LoadAdvancedImageSearch) =>
            this.dataService.getAdvanceSearchDocument(action.payload).pipe(
                map((advImageSearch: any[]) => {
                    if (advImageSearch['body']['item1'] != null && advImageSearch['body']['item1'].length > 0 && !advImageSearch['body']['item2'].toLowerCase().includes('error')) {
                        advImageSearch['body']['item1'].forEach(x => {
                            x.baseClassification = this.aesdecryptservice.decryptData(this.dataService.Key, x.baseClassification);
                            x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.org = this.aesdecryptservice.decryptData(this.dataService.Key, x.org);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            if (x.createdBy != null) {
                                x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                                x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
                                x.createdBy.email = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.email);
                                x.createdBy.imageUrl = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.imageUrl);
                            }
                            if (x.modifiedBy != null) {
                                x.modifiedBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.modifiedBy.firstName);
                                x.modifiedBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.modifiedBy.lastName);
                                x.modifiedBy.email = this.aesdecryptservice.decryptData(this.dataService.Key, x.modifiedBy.email);
                                x.modifiedBy.imageUrl = this.aesdecryptservice.decryptData(this.dataService.Key, x.modifiedBy.imageUrl);
                            }
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            if (x.thumbnailFiles != null && x.thumbnailFiles.thumbnail != null) {
                                x.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail);

                            }
                        })
                    }
                    return new commonActions.LoadAdvancedImageSearchSuccess(advImageSearch['body']['item1'], advImageSearch['body'])
                }
                ),
                catchError(err => of(new commonActions.LoadAdvancedImageSearchFail(err)))
            )
        )
    );

    @Effect()
    loadAdvancedMaterialSearch: Observable<Action> = this.actions.pipe(
        ofType<commonActions.LoadAdvancedMaterialSearch>(
            commonActions.CommonActionTypes.LOAD_ADV_MATERIAL_SEARCH
        ), mergeMap((action: commonActions.LoadAdvancedMaterialSearch) =>
            this.dataService.getAdvanceSearchMaterial(action.payload).pipe(
                map((advMaterialSearch: any[]) => {
                    if (advMaterialSearch['body']['item1'] != null && advMaterialSearch['body']['item1'].length > 0 && !advMaterialSearch['body']['item2'].toLowerCase().includes('error')) {
                        advMaterialSearch['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.subType = this.aesdecryptservice.decryptData(this.dataService.Key, x.subType);
                            x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                            if (x.createdBy != null) {
                                x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                                x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
                            }
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            if (x.thumbnailFiles != null && x.thumbnailFiles.thumbnail != null) {
                                x.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail);
                            }
                        })
                    }
                    return new commonActions.LoadAdvancedImageSearchSuccess(advMaterialSearch['body']['item1'], advMaterialSearch['body'])
                }
                ),
                catchError(err => of(new commonActions.LoadAdvancedImageSearchFail(err)))
            )
        )
    );

    @Effect()
    loadAdvancedPaletteSearch: Observable<Action> = this.actions.pipe(
        ofType<commonActions.LoadAdvancedPaletteSearch>(
            commonActions.CommonActionTypes.LOAD_ADV_PALETTE_SEARCH
        ), mergeMap((action: commonActions.LoadAdvancedPaletteSearch) =>
            this.dataService.getAdvanceSearchPalettes(action.payload).pipe(
                map((advPaletteSearch: any[]) => {
                    if (advPaletteSearch['body']['item1'] != null && advPaletteSearch['body']['item1'].length > 0 && !advPaletteSearch['body']['item2'].toLowerCase().includes('error')) {
                        advPaletteSearch['body']['item1'].forEach(x => {
                            x.colourHexCodes = this.aesdecryptservice.decryptData(this.dataService.Key, x.colourHexCodes);
                            x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            /* if (x.createdBy != null) {
                                x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                                x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
                            } */
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            /* if (x.thumbnailFiles != null && x.thumbnailFiles.thumbnail != null) {
                                x.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail);
                            } */
                        })
                    }
                    return new commonActions.LoadAdvancedPaletteSearchSuccess(advPaletteSearch['body']['item1'], advPaletteSearch['body'])
                }
                ),
                catchError(err => of(new commonActions.LoadAdvancedPaletteSearchFail(err)))
            )
        )
    );

    @Effect()
    loadAdvancedSeamsSearch: Observable<Action> = this.actions.pipe(
        ofType<commonActions.LoadAdvancedSeamsSearch>(
            commonActions.CommonActionTypes.LOAD_ADV_SEAMS_SEARCH
        ), mergeMap((action: commonActions.LoadAdvancedSeamsSearch) =>
            this.dataService.getAdvanceSearchSeams(action.payload).pipe(
                map((advSeamsSearch: any[]) => {
                    if (advSeamsSearch['body']['item1'] != null && advSeamsSearch['body']['item1'].length > 0 && !advSeamsSearch['body']['item2'].toLowerCase().includes('error')) {
                        advSeamsSearch['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.seam751aNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.seam751aNumber);
                            x.isO4916Number = this.aesdecryptservice.decryptData(this.dataService.Key, x.isO4916Number);
                            x.commonApplication = this.aesdecryptservice.decryptData(this.dataService.Key, x.commonApplication);
                            x.requirements = this.aesdecryptservice.decryptData(this.dataService.Key, x.requirements);
                            /* if (x.createdBy != null) {
                                x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                                x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
                            } */
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            if (x.seamDrawingFile != null) {
                                x.seamDrawingFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.seamDrawingFile.thumbnail);
                            }
                        })
                    }
                    return new commonActions.LoadAdvancedPaletteSearchSuccess(advSeamsSearch['body']['item1'], advSeamsSearch['body'])
                }
                ),
                catchError(err => of(new commonActions.LoadAdvancedPaletteSearchFail(err)))
            )
        )
    );

    @Effect()
    loadAdvancedStitchesSearch: Observable<Action> = this.actions.pipe(
        ofType<commonActions.LoadAdvancedStitchSearch>(
            commonActions.CommonActionTypes.LOAD_ADV_STITCH_SEARCH
        ), mergeMap((action: commonActions.LoadAdvancedStitchSearch) =>
            this.dataService.getAdvanceSearchStitches(action.payload).pipe(
                map((advStitchSearch: any[]) => {
                    if (advStitchSearch['body']['item1'] != null && advStitchSearch['body']['item1'].length > 0 && !advStitchSearch['body']['item2'].toLowerCase().includes('error')) {
                        advStitchSearch['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.isO4915Number = this.aesdecryptservice.decryptData(this.dataService.Key, x.isO4915Number);
                            x.commonApplication = this.aesdecryptservice.decryptData(this.dataService.Key, x.commonApplication);
                            x.requirements = this.aesdecryptservice.decryptData(this.dataService.Key, x.requirements);
                            x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            if (x.topViewFile != null) {
                                x.topViewFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.topViewFile.thumbnail);
                            }
                        })
                    }
                    return new commonActions.LoadAdvancedStitchSearchSuccess(advStitchSearch['body']['item1'], advStitchSearch['body'])
                }
                ),
                catchError(err => of(new commonActions.LoadAdvancedStitchSearchFail(err)))
            )
        )
    );

    @Effect()
    loadAdvancedSupplierSearch: Observable<Action> = this.actions.pipe(
        ofType<commonActions.LoadAdvancedSupplierSearch>(
            commonActions.CommonActionTypes.LOAD_ADV_SUPPLIER_SEARCH
        ), mergeMap((action: commonActions.LoadAdvancedSupplierSearch) =>
            this.dataService.getAdvanceSearchSupplier(action.payload).pipe(
                map((advSupplierSearch: any[]) => {
                    if (advSupplierSearch['body']['item1'] != null && advSupplierSearch['body']['item1'].length > 0 && !advSupplierSearch['body']['item2'].toLowerCase().includes('error')) {
                        advSupplierSearch['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.phoneNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.phoneNumber);
                            x.email = this.aesdecryptservice.decryptData(this.dataService.Key, x.email);
                            x.country = this.aesdecryptservice.decryptData(this.dataService.Key, x.country);
                            x.state = this.aesdecryptservice.decryptData(this.dataService.Key, x.state);
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            if (x.createdBy != null) {
                                x.createdBy.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.name);
                            }
                        })
                    }
                    return new commonActions.LoadAdvancedSupplierSearchSuccess(advSupplierSearch['body']['item1'], advSupplierSearch['body'])
                }
                ),
                catchError(err => of(new commonActions.LoadAdvancedSupplierSearchFail(err)))
            )
        )
    );
}
