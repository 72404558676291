<div class="dt-page__header">
  <button class="close" mat-button (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="dt-page__title" *ngIf="!data.isPatternedit">Create Pattern</h3>
  <h3 class="dt-page__title" *ngIf="data.isPatternedit">Edit Pattern</h3>
</div>

<mat-dialog-content class="mat-typography">
  <svg *ngIf="patternload " viewBox="25 25 50 50">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
  <div id="tab-pane" class="tab-pane bg-white active">
    <form [formGroup]="patternform" [ngClass]="patternload == true ? 'datahidden bom_modal_content' : 'bom_modal_content'">
      <div class="form-row">
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">PC#</label>
          <input name="pc" formControlName="pc" class="form-control fill" placeholder="Enter pc">
          <div
            *ngIf="(submitted && this.f1.pc.errors) || ( this.f1.pc.invalid && (this.f1.pc.dirty || this.f1.pc.touched))">
            <div *ngIf="this.f1.pc.errors.required" class="red">
              PC is required
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Description</label>
          <input name="description" formControlName="description" class="form-control fill"
            placeholder="Enter description">
          <div
            *ngIf="(submitted && this.f1.description.errors) || ( this.f1.description.invalid && (this.f1.description.dirty || this.f1.description.touched))">
            <div *ngIf="this.f1.description.errors.required" class="red">
              description is required
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Cut count</label>
          <input name="cut" formControlName="cut" class="form-control fill" placeholder="Enter cut">
          <div
            *ngIf="(submitted && this.f1.cut.errors) || ( this.f1.cut.invalid && (this.f1.cut.dirty || this.f1.cut.touched))">
            <div *ngIf="this.f1.cut.errors.required" class="red">
              cut is required
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Material / Fabric</label>
          <input name="allocation" formControlName="allocation" class="form-control fill"
            placeholder="Enter Material / Fabric">
          <div
            *ngIf="(submitted && this.f1.allocation.errors) || ( this.f1.allocation.invalid && (this.f1.allocation.dirty || this.f1.allocation.touched))">
            <div *ngIf="this.f1.allocation.errors.required" class="red">
              Material / Fabric is required
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Drawing</label>
          <input *ngIf="showFileInput" type='file' #fileUploader multiple="false" (change)="onChangePatternImg($event)"
          (click)="Modalopen(content)">
          <div *ngIf="showImage" class="col-md-12 text-center sree">
            <img class="img ng-star-inserted" height="50px" width="50px" [src]="croppedImage" />
            <button
               type="button" class="close image-close"
              (click)="removeThumbnailPatternSection(patternImages)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div *ngIf="data.patternData?.image">
            <img
              *ngIf="data.patternData?.image.fileType == '.png' || data.patternData?.image.fileType == '.jpg' || data.patternData?.image.fileType == '.jpeg' || data.patternData?.image.fileType == '.gif'"
              src="{{data.patternData?.image.thumbnail}}" class="img ng-star-inserted" alt="">
              <button *ngIf="data.patternData?.image.fileType == '.png' || data.patternData?.image.fileType == '.jpg' || data.patternData?.image.fileType == '.jpeg' || data.patternData?.image.fileType == '.gif'"
               type="button" class="close image-close"
              (click)="removeThumbnailPatternSection(patternImages)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div *ngIf="data.patternData?.image">
            <a *ngIf="data.patternData?.image.fileType == '.pdf' || data.patternData?.image.fileType == '.docx'" href="{{data.patternData?.image.thumbnail}}"
              target="_blank" style="text-decoration: underline;">Open <i class="fa fa-external-link"></i></a>
              <button *ngIf="data.patternData?.image.fileType == '.pdf' || data.patternData?.image.fileType == '.docx' "
               type="button" class="close image-close"
              (click)="removeThumbnailPatternSection(patternImages)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <ng-template #content let-modal>
          <div *ngIf="isThumbnailAdded==true">
            <!-- Modal Content -->
            <div class="modal-content">

              <!-- Modal Header -->
              <div class="modal-header" title="Style Image">
                <h3 class="modal-title" id="model-3">Image</h3>
                <button type="button" class="close" aria-label="Close" (click)="closePattern($event)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- /modal header -->

              <!-- Modal Body -->
              <div class="modal-body loader_content">
                <svg viewBox="25 25 50 50" *ngIf="imgload">
                  <circle r="20" cy="50" cx="50"></circle>
                </svg>
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                  [aspectRatio]="4 / 3" format="jpeg/png" (imageCropped)="imageCropped($event)"></image-cropper>
              </div>
              <!-- /modal body -->
              <div class="modal-footer">
                <button type="button" #closeModal class="btn btn-outline-primary btn-sm" data-dismiss="modal"
                  (click)="closePattern($event)">{{appStaticMsgs.common.cancel}}</button>
                <button type="button" class="btn btn-outline-primary btn-sm" [disabled]="!fileToReturn"
                  (click)="confirmPattern()" data-dismiss="modal">
                  Confirm
                </button>
              </div>
            </div>
            <!-- /modal content -->
          </div>

        </ng-template>
      </div>
    </form>
  </div>
  <div class="footer">
    <div class="row mt-5 float-right mr-3">
      <button type="submit" class="btn btn-outline-primary mr-2" (click)="close()">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary btn-sm btn-sm" [disabled]="patternform?.invalid"
        (click)="savePattern($event)">
        Save
      </button>
    </div>
  </div>
</mat-dialog-content>
