import * as moodBoardActions from "../actions/moodboard.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { Moodboard } from "../models/moodboard";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface MoodboardState extends EntityState<Moodboard> {
    selectedMoodboardId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
    totalRecords: number | null;
}

export const moodboardAdapter: EntityAdapter<Moodboard> = createEntityAdapter<Moodboard>();

export const defaultMoodboard: MoodboardState = {
    ids: [],
    entities: {},
    selectedMoodboardId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null,
    totalRecords: 0
}

export const initialState = moodboardAdapter.getInitialState(defaultMoodboard);

export function MoodboardReducer(state = initialState, action: moodBoardActions.moodBoardActionTypes): MoodboardState {
    switch (action.type) {
        case moodBoardActions.MoodBoardActionTypes.LOAD_MOODBOARDS_SUCCESS:
            return moodboardAdapter.addMany(action.payload1, {
                ...state.message = action.payload2,
                ...state.totalRecords = action.payload3,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case moodBoardActions.MoodBoardActionTypes.LOAD_MOODBOARDS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case moodBoardActions.MoodBoardActionTypes.LOAD_MOODBOARD_SUCCESS:
            return moodboardAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMoodboardId: action.payload1.id
            })
            break;
        case moodBoardActions.MoodBoardActionTypes.LOAD_MOODBOARD_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case moodBoardActions.MoodBoardActionTypes.CREATE_MOODBOARD_SUCCESS:
            return moodboardAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMoodboardId: action.payload1.id
            })
            break;
        case moodBoardActions.MoodBoardActionTypes.CREATE_MOODBOARD_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case moodBoardActions.MoodBoardActionTypes.UPDATE_MOODBOARD_SUCCESS:
            return moodboardAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case moodBoardActions.MoodBoardActionTypes.UPDATE_MOODBOARD_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case moodBoardActions.MoodBoardActionTypes.DELETE_MOODBOARD_SUCCESS:
            return moodboardAdapter.removeOne(action.payload1, {
                ...state.message = (action.payload2 === 'Error') ? action.payload1 : action.payload2,
                ...state
            })
            break;
        case moodBoardActions.MoodBoardActionTypes.DELETE_MOODBOARD_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getMoodboardsFeatureState = createFeatureSelector<MoodboardState>('moodboard');

export const getMoodBoards = createSelector(
    getMoodboardsFeatureState, moodboardAdapter.getSelectors().selectAll
)
export const getMoodBoardsLoading = createSelector(
    getMoodboardsFeatureState, (state: MoodboardState) => state.loading
)
export const getMoodBoardsLoaded = createSelector(
    getMoodboardsFeatureState, (state: MoodboardState) => state.loaded
)
export const getMoodBoardsError = createSelector(
    getMoodboardsFeatureState, (state: MoodboardState) => state.error
)
export const getMoodBoardId = createSelector(
    getMoodboardsFeatureState, (state: MoodboardState) => state.selectedMoodboardId
)
export const getMoodBoard = createSelector(
    getMoodboardsFeatureState, getMoodBoardId, state => state.entities[state.selectedMoodboardId]
)
