import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as userActions from "../actions/userActions.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";


@Injectable()

export class UserEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }

    @Effect()
    loadUserManagement: Observable<Action> = this.actions.pipe(
        ofType<userActions.LoadUserManagement>(
            userActions.UserActionTypes.LOAD_USER_MANAGEMENT
        ), mergeMap((action: userActions.LoadUserManagement) =>
            this.dataService.getallUsersMang(action.payload).pipe(
                map((userManagement: any[]) => {
                    if (userManagement['body']['item1'] != null && userManagement['body']['item1'].length > 0 && !userManagement['body']['item2'].toLowerCase().includes('error')) {
                        userManagement['body']['item1'].forEach(x => {
                            x.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.firstName)
                            x.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.lastName)
                            x.fullName = x.firstName + " " + x.lastName;
                            x.userrole = this.aesdecryptservice.decryptData(this.dataService.Key, x.userrole)
                            x.eMail = this.aesdecryptservice.decryptData(this.dataService.Key, x.eMail)
                            if (x.status != null && x.status != "") {
                                x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status)
                            }
                        });
                    }
                    return new userActions.LoadUserManagementSuccess(userManagement['body']['item1'], userManagement['body'])
                }),
                catchError(err => of(new userActions.LoadUserManagementFail(err)))
            )
        )
    );

    @Effect()
    loadUserRoles: Observable<Action> = this.actions.pipe(
        ofType<userActions.LoadUserRoles>(
            userActions.UserActionTypes.LOAD_USER_ROLES
        ), mergeMap((action: userActions.LoadUserRoles) =>
            this.dataService.getRolesList().pipe(
                map((userRoles: any[]) => {
                    if (userRoles['body']['item1'] != null && userRoles['body']['item1'].length > 0 && !userRoles['body']['item2'].toLowerCase().includes('error')) {
                        userRoles['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
                        });
                    }
                    return new userActions.LoadUserRolesSuccess(userRoles['body']['item1'], userRoles['body'])
                }),
                catchError(err => of(new userActions.LoadUserRolesFail(err)))
            )
        )
    );

    @Effect()
    loadMenuItems: Observable<Action> = this.actions.pipe(
        ofType<userActions.LoadMenuItems>(
            userActions.UserActionTypes.LOAD_MENU_ITEMS
        ), mergeMap((action: userActions.LoadMenuItems) =>
            this.dataService.getMenuItems().pipe(
                map((menuItems: any[]) => {
                    if (menuItems['body']['item1'] != null && menuItems['body']['item1'].length > 0 && !menuItems['body']['item2'].toLowerCase().includes('error')) {
                        menuItems['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x['name']);
                            x.title = this.aesdecryptservice.decryptData(this.dataService.Key, x['title']);
                            x.type = this.aesdecryptservice.decryptData(this.dataService.Key, x['type']);
                            x.url = this.aesdecryptservice.decryptData(this.dataService.Key, x['url']);
                            x.icon = this.aesdecryptservice.decryptData(this.dataService.Key, x['icon']);
                            if (x.children && x.children.length > 0) {
                                x.children.forEach(y => {
                                    y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y['name']);
                                    y.title = this.aesdecryptservice.decryptData(this.dataService.Key, y['title']);
                                    y.type = this.aesdecryptservice.decryptData(this.dataService.Key, y['type']);
                                    y.url = this.aesdecryptservice.decryptData(this.dataService.Key, y['url']);
                                    y.icon = this.aesdecryptservice.decryptData(this.dataService.Key, y['icon']);
                                    if (y.children && y.children.length > 0) {
                                        y.children.forEach(z => {
                                            z.name = this.aesdecryptservice.decryptData(this.dataService.Key, z['name']);
                                            z.title = this.aesdecryptservice.decryptData(this.dataService.Key, z['title']);
                                            z.type = this.aesdecryptservice.decryptData(this.dataService.Key, z['type']);
                                            z.url = this.aesdecryptservice.decryptData(this.dataService.Key, z['url']);
                                            z.icon = this.aesdecryptservice.decryptData(this.dataService.Key, z['icon']);

                                        });
                                    }
                                });
                            }
                        });
                    }
                    return new userActions.LoadMenuItemsSuccess(menuItems['body']['item1'], menuItems['body'])
                }),
                catchError(err => of(new userActions.LoadMenuItemsFail(err)))
            )
        )
    );

    @Effect()
    loadUser: Observable<Action> = this.actions.pipe(
        ofType<userActions.LoadUser>(
            userActions.UserActionTypes.LOAD_USER
        ), mergeMap((action: userActions.LoadUser) =>
            this.dataService.getUserById(action.payload).pipe(
                map((user: any) => {
                    if (!user['body']['item2'].toLowerCase().includes('error')) {
                        user['body']['item1'].firstName = this.aesdecryptservice.decryptData(this.dataService.Key, user['body']['item1'].firstName)
                        user['body']['item1'].lastName = this.aesdecryptservice.decryptData(this.dataService.Key, user['body']['item1'].lastName)
                        user['body']['item1'].org = this.aesdecryptservice.decryptData(this.dataService.Key, user['body']['item1'].org)
                        user['body']['item1'].eMail = this.aesdecryptservice.decryptData(this.dataService.Key, user['body']['item1'].eMail)
                        user['body']['item1'].group = this.aesdecryptservice.decryptData(this.dataService.Key, user['body']['item1'].group)
                        if (user['body']['item1']['role']) {
                            user['body']['item1']['role'].forEach(x => {
                                x['role']['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['role']['name'])
                            });
                        }
                    }
                    return new userActions.LoadUserSuccess(user['body']['item1'], user['body'])
                }),
                catchError(err => of(new userActions.LoadUserFail(err)))
            )
        )
    );

    @Effect()
    loadUserDetails: Observable<Action> = this.actions.pipe(
        ofType<userActions.LoadUserDetails>(
            userActions.UserActionTypes.LOAD_USER_DETAILS
        ), mergeMap((action: userActions.LoadUserDetails) =>
            this.dataService.getUserDetails().pipe(
                map((userDetails: any[]) => {
                    if (!userDetails['body']['item2'].toLowerCase().includes('error')) {
                        userDetails['body']['item1']['firstName'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['firstName']);
                        userDetails['body']['item1']['lastName'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['lastName']);
                        userDetails['body']['item1']['org'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['org']);
                        userDetails['body']['item1']['eMail'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['eMail']);
                        userDetails['body']['item1']['about'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['about']);
                        if (userDetails['body']['item1']['pictureID']) {
                            userDetails['body']['item1']['picture']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['picture']['thumbnail']);
                        }
                        userDetails['body']['item1']['group'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['group']);
                        if (userDetails['body']['item1']['role']) {
                            if (userDetails['body']['item1']['role'][0]) {
                                userDetails['body']['item1']['role'][0]['role']['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['role'][0]['role']['name']);
                            }
                        }
                        if (userDetails['body']['item1']['portfolios']) {
                            if (userDetails['body']['item1']['portfolios'][0]) {
                                userDetails['body']['item1']['portfolios'][0]['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['portfolios'][0]['name']);
                                userDetails['body']['item1']['portfolios'][0]['status'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['portfolios'][0]['status']);
                                //userDetails['body']['item1']['portfolios'][0]['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['portfolios'][0]['image']);
                                if (userDetails['body']['item1']['portfolios'][0]['image']) {
                                    userDetails['body']['item1']['portfolios'][0]['image']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['portfolios'][0]['image']['thumbnail']);
                                    userDetails['body']['item1']['portfolios'][0]['image']['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['portfolios'][0]['image']['name']);

                                }
                            }
                        }
                        if (userDetails['body']['item1']['supplier']) {
                            if (userDetails['body']['item1']['supplier'][0]) {
                                userDetails['body']['item1']['supplier'][0]['auditStatus'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['auditStatus']);
                                userDetails['body']['item1']['supplier'][0]['capacityUOM'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['capacityUOM']);
                                userDetails['body']['item1']['supplier'][0]['classification'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['classification']);
                                userDetails['body']['item1']['supplier'][0]['description'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['description']);
                                userDetails['body']['item1']['supplier'][0]['email'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['email']);
                                userDetails['body']['item1']['supplier'][0]['machineTypes'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['machineTypes']);
                                userDetails['body']['item1']['supplier'][0]['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['name']);
                                userDetails['body']['item1']['supplier'][0]['phoneNumber'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['phoneNumber']);
                                userDetails['body']['item1']['supplier'][0]['sequence'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['sequence']);
                                userDetails['body']['item1']['supplier'][0]['speciality'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['speciality']);
                                userDetails['body']['item1']['supplier'][0]['supplierChainPartner'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['supplierChainPartner']);
                                userDetails['body']['item1']['supplier'][0]['productType'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['productType']);
                                userDetails['body']['item1']['supplier'][0]['org'] = this.aesdecryptservice.decryptData(this.dataService.Key, userDetails['body']['item1']['supplier'][0]['org']);

                                if (userDetails['body']['item1']['supplier'][0]['contact']) {
                                    userDetails['body']['item1']['supplier'][0]['contact'].forEach(x => {
                                        x.address = this.aesdecryptservice.decryptData(this.dataService.Key, x.address);
                                        x.city = this.aesdecryptservice.decryptData(this.dataService.Key, x.city);
                                        x.contactEmail = this.aesdecryptservice.decryptData(this.dataService.Key, x.contactEmail);
                                        x.contactNo = this.aesdecryptservice.decryptData(this.dataService.Key, x.contactNo);
                                        x.ext = this.aesdecryptservice.decryptData(this.dataService.Key, x.ext);
                                        x.fax = this.aesdecryptservice.decryptData(this.dataService.Key, x.fax);
                                        x.fullName = this.aesdecryptservice.decryptData(this.dataService.Key, x.fullName);
                                        x.officeNo = this.aesdecryptservice.decryptData(this.dataService.Key, x.officeNo);
                                        x.state = this.aesdecryptservice.decryptData(this.dataService.Key, x.state);
                                        x.zipCode = this.aesdecryptservice.decryptData(this.dataService.Key, x.zipCode);
                                        x.country = this.aesdecryptservice.decryptData(this.dataService.Key, x.country);
                                    });
                                }

                                if (userDetails['body']['item1']['supplier'][0]['documents']) {
                                    userDetails['body']['item1']['supplier'][0]['documents'].forEach(x => {
                                        x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                                        x.baseClassification = this.aesdecryptservice.decryptData(this.dataService.Key, x.baseClassification);
                                        x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                                        x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                                        x.notes = this.aesdecryptservice.decryptData(this.dataService.Key, x.notes);
                                        x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                                    });
                                }
                            }
                        }
                    }
                    return new userActions.LoadUserDetailsSuccess(userDetails['body']['item1'], userDetails['body'])
                }),
                catchError(err => of(new userActions.LoadUserDetailsFail(err)))
            )
        )
    );

    @Effect()
    updateUser: Observable<any> = this.actions.pipe(
        ofType<userActions.UpdateUser>(
            userActions.UserActionTypes.UPDATE_USER
        ), map((action: userActions.UpdateUser) => action.payload),
        mergeMap((user: any) =>
            this.dataService.updateUserDetails(user).pipe(
                map((updatedUser: any) => {
                    if (!updatedUser['body']['item2'].toLowerCase().includes('error')) {
                        updatedUser['body']['item1'].firstName = this.aesdecryptservice.decryptData(this.dataService.Key, updatedUser['body']['item1'].firstName)
                        updatedUser['body']['item1'].lastName = this.aesdecryptservice.decryptData(this.dataService.Key, updatedUser['body']['item1'].lastName)
                        updatedUser['body']['item1'].org = this.aesdecryptservice.decryptData(this.dataService.Key, updatedUser['body']['item1'].org)
                        updatedUser['body']['item1'].group = this.aesdecryptservice.decryptData(this.dataService.Key, updatedUser['body']['item1'].group)
                        if (updatedUser['body']['item1']['role']) {
                            updatedUser['body']['item1']['role'].forEach(x => {
                                x['role']['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['role']['name'])
                            });
                        }
                    }
                    return new userActions.UpdateUserSuccess({
                        id: updatedUser['body']['item1'].id,
                        changes: updatedUser['body']['item1']
                    }, updatedUser['body'])
                }),
                catchError(err => of(new userActions.UpdateUserFail(err)))
            )
        )
    );

    @Effect()
    loadUserSupplier: Observable<Action> = this.actions.pipe(
        ofType<userActions.LoadUserSupplier>(
            userActions.UserActionTypes.LOAD_USER_SUPPLIER
        ), mergeMap((action: userActions.LoadUserSupplier) =>
            this.dataService.getUserSupplier(action.payload).pipe(
                map((userSupplier: any) =>
                    new userActions.LoadUserSupplierSuccess(userSupplier['body']['item1'], userSupplier['body'])
                ),
                catchError(err => of(new userActions.LoadUserSupplierFail(err)))
            )
        )
    );

    @Effect()
    createUserSupplier: Observable<any> = this.actions.pipe(
        ofType<userActions.CreateUserSupplier>(
            userActions.UserActionTypes.CREATE_USER_SUPPLIER
        ), map((action: userActions.CreateUserSupplier) => action.payload),
        mergeMap((user: any) =>
            this.dataService.addUserSupplier(user).pipe(
                map((newUserSupplier: any) =>
                    new userActions.CreateUserSupplierSuccess(newUserSupplier['body']['item1'], newUserSupplier['body'])
                ),
                catchError(err => of(new userActions.CreateUserSupplierFail(err)))
            )
        )
    );

    @Effect()
    updateUserSupplier: Observable<any> = this.actions.pipe(
        ofType<userActions.UpdateUserSupplier>(
            userActions.UserActionTypes.UPDATE_USER_SUPPLIER
        ), map((action: userActions.UpdateUserSupplier) => action.payload),
        mergeMap((user: any) =>
            this.dataService.updateUserSupplier(user).pipe(
                map((updatedUserSupplier: any) =>
                    new userActions.UpdateUserSupplierSuccess({
                        id: updatedUserSupplier['body']['item1'].id,
                        changes: updatedUserSupplier['body']['item1']
                    }, updatedUserSupplier['body'])
                ),
                catchError(err => of(new userActions.UpdateUserSupplierFail(err)))
            )
        )
    );

    @Effect()
    createUserPortfolio: Observable<any> = this.actions.pipe(
        ofType<userActions.CreateUserPortfolio>(
            userActions.UserActionTypes.CREATE_USER_PORTFOLIO
        ), map((action: userActions.CreateUserPortfolio) => action.payload),
        mergeMap((user: any) =>
            this.dataService.addUserPortfolio(user).pipe(
                map((newUserPortfolio: any) => {
                    if (!newUserPortfolio['body']['item2'].toLowerCase().includes('error')) {
                        newUserPortfolio['body']['item1']['portfolios'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status)
                            if (x.image) {
                                x.image.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.image.name)
                                x.image.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.image.thumbnail)
                            }
                        });
                    }
                    return new userActions.CreateUserPortfolioSuccess(newUserPortfolio['body']['item1'], newUserPortfolio['body'])
                }),
                catchError(err => of(new userActions.CreateUserPortfolioFail(err)))
            )
        )
    );

    @Effect()
    updateUserPortfolio: Observable<any> = this.actions.pipe(
        ofType<userActions.UpdateUserPortfolio>(
            userActions.UserActionTypes.UPDATE_USER_PORTFOLIO
        ), map((action: userActions.UpdateUserPortfolio) => action.payload),
        mergeMap((user: any) =>
            this.dataService.updateUserPortfolio(user).pipe(
                map((updatedUserPortfolio: any) =>{
                    if (!updatedUserPortfolio['body']['item2'].toLowerCase().includes('error')) {
                        updatedUserPortfolio['body']['item1']['portfolios'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status)
                            if (x.image) {
                                x.image.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.image.name)
                                x.image.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.image.thumbnail)
                            }
                        });
                    }
                   return new userActions.UpdateUserPortfolioSuccess({
                        id: updatedUserPortfolio['body']['item1'].id,
                        changes: updatedUserPortfolio['body']['item1']
                    }, updatedUserPortfolio['body'])
                }),
                catchError(err => of(new userActions.UpdateUserPortfolioFail(err)))
            )
        )
    );

    @Effect()
    sendInvite: Observable<any> = this.actions.pipe(
        ofType<userActions.SendInvite>(
            userActions.UserActionTypes.SEND_INVITE
        ), map((action: userActions.SendInvite) => action.payload),
        mergeMap((user: any) =>
            this.dataService.sendInvite(user).pipe(
                map((invite: any) =>
                    new userActions.SendInviteSuccess(invite['body']['item1'], invite['body'])
                ),
                catchError(err => of(new userActions.SendInviteFail(err)))
            )
        )
    );
}
