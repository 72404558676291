import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as moodBoardActions from "../actions/moodboard.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { Moodboard } from "../models/moodboard";
import { AESDecryptService } from "../helpers/AESDecrypt.service";


@Injectable()

export class MoodBoardEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }

    getMoodboardDetails(data: any) {
        data.canvas = this.aesdecryptservice.decryptData(this.dataService.Key, data.canvas)
        data.color = this.aesdecryptservice.decryptData(this.dataService.Key, data.color)
        data.note = this.aesdecryptservice.decryptData(this.dataService.Key, data.note)
        data.org = this.aesdecryptservice.decryptData(this.dataService.Key, data.org)
        data.title = this.aesdecryptservice.decryptData(this.dataService.Key, data.title)
        data.toDo = this.aesdecryptservice.decryptData(this.dataService.Key, data.toDo)
        data.url = this.aesdecryptservice.decryptData(this.dataService.Key, data.url)
        data.thumbnail.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.thumbnail.name)
        data.thumbnail.org = this.aesdecryptservice.decryptData(this.dataService.Key, data.thumbnail.org)
        data.thumbnail.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, data.thumbnail.thumbnail)
    }

    @Effect()
    loadMoodBoards: Observable<Action> = this.actions.pipe(
        ofType<moodBoardActions.LoadMoodBoards>(
            moodBoardActions.MoodBoardActionTypes.LOAD_MOODBOARDS
        ), mergeMap((action: moodBoardActions.LoadMoodBoards) =>
            this.dataService.getAllMoodBoards(action.payload1, action.payload2).pipe(
                map((moodboards: any) => {
                    let moodboardLibCards = moodboards['body']['item1'];
                    if (moodboardLibCards.length > 0 && !moodboards['body']['item2'].toLowerCase().includes('error')) {
                        moodboardLibCards.forEach(element => {
                            if (!element.deleteStatus) {
                                element.thumbnail.name = this.aesdecryptservice.decryptData(this.dataService.Key, element.thumbnail.name)
                                element.thumbnail.org = this.aesdecryptservice.decryptData(this.dataService.Key, element.thumbnail.org)
                                element.thumbnail.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, element.thumbnail.thumbnail)
                                element.title = this.aesdecryptservice.decryptData(this.dataService.Key, element.title)
                            }
                        });
                    }
                    return new moodBoardActions.LoadMoodBoardsSuccess(moodboardLibCards, moodboards['body'], moodboards['body']['item3'])
                }
                ),
                catchError(err => of(new moodBoardActions.LoadMoodBoardsFail(err)))
            )
        )
    );
    @Effect()
    loadMoodBoard: Observable<Action> = this.actions.pipe(
        ofType<moodBoardActions.LoadMoodBoard>(
            moodBoardActions.MoodBoardActionTypes.LOAD_MOODBOARD
        ), mergeMap((action: moodBoardActions.LoadMoodBoard) =>
            this.dataService.getMoodBoardById(action.payload).pipe(
                map((moodboard: any) => {
                    this.getMoodboardDetails(moodboard['body']['item1'])
                    return new moodBoardActions.LoadMoodBoardSuccess(moodboard['body']['item1'], moodboard['body'])
                }),
                catchError(err => of(new moodBoardActions.LoadMoodBoardFail(err)))
            )
        )
    );
    @Effect()
    createMoodBoard: Observable<any> = this.actions.pipe(
        ofType<moodBoardActions.CreateMoodBoard>(
            moodBoardActions.MoodBoardActionTypes.CREATE_MOODBOARD
        ), map((action: moodBoardActions.CreateMoodBoard) => action.payload),
        mergeMap((moodboard: Moodboard) =>
            this.dataService.addMoodBoard(moodboard).pipe(
                map((newMoodboard: any) => {
                    this.getMoodboardDetails(newMoodboard['body']['item1'])
                    return new moodBoardActions.CreateMoodBoardSuccess(newMoodboard['body']['item1'], newMoodboard['body'])
                }
                ),
                catchError(err => of(new moodBoardActions.CreateMoodBoardFail(err)))
            )
        )
    );

    @Effect()
    updateMoodBoard: Observable<any> = this.actions.pipe(
        ofType<moodBoardActions.UpdateMoodBoard>(
            moodBoardActions.MoodBoardActionTypes.UPDATE_MOODBOARD
        ), map((action: moodBoardActions.UpdateMoodBoard) => action.payload),
        mergeMap((moodboard: Moodboard) =>
            this.dataService.addMoodBoard(moodboard).pipe(
                map((updatedMoodboard: any) => {
                    this.getMoodboardDetails(updatedMoodboard['body']['item1'])
                    return new moodBoardActions.UpdateMoodBoardSuccess({
                        id: updatedMoodboard['body']['item1'].id,
                        changes: updatedMoodboard['body']['item1']
                    }, updatedMoodboard['body'])
                }),
                catchError(err => of(new moodBoardActions.UpdateMoodBoardFail(err)))
            )
        )
    );

    @Effect()
    deleteMoodBoard: Observable<any> = this.actions.pipe(
        ofType<moodBoardActions.DeleteMoodBoard>(
            moodBoardActions.MoodBoardActionTypes.DELETE_MOODBOARD
        ), map((action: moodBoardActions.DeleteMoodBoard) => action.payload),
        mergeMap((moodboardId: string) =>
            this.dataService.deleteMoodBoardById(moodboardId).pipe(
                map((deletedMoodboard: any) =>
                    new moodBoardActions.DeleteMoodBoardSuccess(moodboardId, deletedMoodboard['body'])
                ),
                catchError(err => of(new moodBoardActions.DeleteMoodBoardFail(err)))
            )
        )
    );
}
