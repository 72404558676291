<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>

  <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">
    <!-- Site Content -->
    <div class="dt-content">
      <!-- Page Header -->
      <div class="dt-page__header">
        <div class="row">
          <div class="col-xl-4 col-sm-4 col-md-4">
            <h3 class="dt-page__title m-auto">
              {{ appStaticMsgs.common.styles }} {{ ": " + HeadingText }}
            </h3>
            <!-- <h3 class="dt-page__title m-auto" *ngIf="listV">Styles : List View</h3> -->
          </div>
          <div class="col-xl-8 col-sm-8 col-md-8">
            <span class="float-right">
              <button
                type="button"
                class="btn mr-3"
                [ngClass]="btnstatus1 ? 'icon-active' : 'btn-color-primary'"
                placement="bottom"
                ngbTooltip="Change to Card View"
                (click)="cardView()"
              >
                <i class="fa fa-th" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                class="btn mr-3"
                [ngClass]="btnstatus ? 'icon-active' : 'btn-color-primary'"
                placement="bottom"
                ngbTooltip="Change to List View"
                (click)="tableView()"
              >
                <i class="fa fa-bars" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-primary adv-search-btn mr-3"
                (click)="createStyle()"
              >
                <i class="icon icon-plus"></i>
                {{ appStaticMsgs.common.createnew }}
              </button>
              <ng-container *ngIf="this.styleViewMode == 'Shared'">
                <select
                  #selectOrgpicker
                  placeholder="Sort By org"
                  class="dropdownStyling"
                  [disabled]="selectedOrg === 'NA' ? true : false"
                  [(ngModel)]="this.selectedOrg"
                  (ngModelChange)="switchOrg(selectedOrg, 'shared')"
                >
                  <ng-container
                    *ngIf="(userOrgInfo.length > 0 && selectedOrg !== 'NA'); else noOrgsTemplate"
                  >
                    <option *ngFor="let item of userOrgInfo" [value]="item.org">
                      {{ item.org }}
                    </option>
                  </ng-container>
                  <ng-template #noOrgsTemplate>
                    <option disabled selected>
                      No organisations availablex
                    </option>
                  </ng-template>
                  <!-- <option *ngFor="let item of userOrgInfo" [value]="item.org">
                    {{ item.org }}
                  </option> -->
                </select>
              </ng-container>

              <!-- <button type="button" class="btn btn-primary adv-search-btn"  ></button> -->

              <!-- <div class="float-right d-web mt-2">
                <div class="dropdown">
                  <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="javascript:void(0)" (click)="downloadTemplate()">
                      <i class="fa fa-download"></i> &nbsp;
                      {{appStaticMsgs.styles.downloadtemplate}}
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="uploadFile()">
                      <i class="fa fa-upload"></i> &nbsp;
                      {{appStaticMsgs.styles.uploadstyles}}
                    </a>
                  </div>
                </div>
              </div>

              <input type="file" [(ngModel)]="file" (change)="onChange($event)" style="display:none;"> -->
              <!-- <button type="button" class="btn btn-primary adv-search-btn" ></button> -->

              <!-- <button type="button" class="btn btn-primary adv-search-btn" (click)="advanceSearchOpen()">Advance Search</button> -->
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-12 col-sm-12">
          <p class="text-muted">
            <a
              href="javascript:void(0)"
              [ngClass]="mystyle ? 'data_active' : 'text-muted'"
              (click)="styleView('Created')"
            >
              {{ appStaticMsgs.styles.mystyles }}
            </a>
            <span>|</span>
            <a
              href="javascript:void(0)"
              [ngClass]="sharedstyle ? 'data_active' : 'text-muted'"
              (click)="styleView('Shared')"
            >
              {{ appStaticMsgs.styles.sharedwithme }}
            </a>
            <!-- <span  >My Styles</span> | <span  >Shared with me</span> -->
          </p>
        </div>
      </div>
      <!-- /page header -->
      <ng-container *ngIf="this.styleViewMode == 'Shared'">
        <div class="text-center" *ngIf="sharedNotFound">
          <h3>{{ appStaticMsgs.styles.nosharedstylesavailable }}</h3>
          <img [src]="notfoundImg" class="w-50" /><br />
          <button
            *ngIf="mystyle"
            type="button"
            class="btn btn-primary adv-search-btn mr-3"
            (click)="createStyle()"
          >
            <i class="icon icon-plus"></i> {{ appStaticMsgs.common.createnew }}
          </button>
        </div>

        <div class="grid-content" *ngIf="gridContent && !sharedNotFound">
          <div class="row">
            <div class="col-sm-10 col-md-10 col-xl-10 col-lg-10 d-flex">
              <div *ngIf="!checkSharedStatusData" class="col-md-12">
                <i class="fa fa-search search-button"></i>
                <input
                  type="text"
                  class="form-control searchBox"
                  value=""
                  placeholder="Enter Keywords to filter results"
                  [(ngModel)]="searchedKeyword"
                />

                <!-- <tag-input [(ngModel)]='searchedKeyword' class="w-85" placeholder="Enter keywords to search"></tag-input> -->
                <!-- <button type="button"  (click)="onSave()"class="btn btn-primary btn-sm adv-search-btn ml-7">
                  <i class="icon icon-search mr-1"></i> Search
                </button> -->
              </div>
              <!-- <button type="button" class="btn btn-primary adv-search-btn mr-3">
                <i class="icon icon-search"></i> Search
              </button> -->
            </div>
            <div class="col-sm-2 col-md-2 col-xl-2 col-lg-2">
              <select
                placeholder="Sort By"
                class="form-control selectpicker w-100"
                [(ngModel)]="selected"
                (ngModelChange)="onSelect(selected, 'shared')"
              >
                <option *ngFor="let status of options" [value]="status.value">
                  {{ status.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-center" *ngIf="checkSharedStatusData">
            <h2>{{ sharedMsg }}</h2>
            <img [src]="notfoundImg" class="img-fluid w-40" /><br />
          </div>

          <!-- Grid -->
          <div class="row mt-5 mr-0">
            <!-- Grid Item -->
            <div class="col-xl-12 order-xl-1">
              <!-- Card -->
              <div class="row">
                <!-- Grid Item -->

                <div
                  class="col-xl-3 col-md-4 col-sm-6 col-12 prn-1"
                  *ngFor="let card of sharedCards | filter : searchedKeyword"
                >
                  <!-- Card -->
                  <div class="card border-l-r">
                    <!-- Card Body -->
                    <div class="w-100">
                      <!--<img class="w-100 border-l-r h-260" [src]='thumbnail' alt="Img" >-->
                      <!-- <img *ngIf="card.styleImage.thumbnail" class="w-100 border-l-r h-260" alt="" src={{card.styleImage.thumbnail}}>
                      <img *ngIf="!card.styleImage.thumbnail" class="w-100 border-l-r h-260" [src]='thumbnail' alt="Img">-->
                      <img
                        *ngIf="card.styleImage"
                        class="h-260"
                        alt=""
                        src="{{ card.styleImage.thumbnail }}"
                      />
                      <img
                        *ngIf="card.styleImage == null"
                        class="h-260"
                        alt=""
                        src="{{ thumbnail }}"
                      />
                    </div>

                    <div class="card-body">
                      <p class="mb-1 mb-sm-0 f-13 text-truncate styleNumber">
                        <a
                          href="javascript:void(0)"
                          (click)="onEditStyle(card.id, $event)"
                        >
                          Style# : {{ card.styleNumber }}
                        </a>
                      </p>
                      <p class="mt-1 mb-8 text-truncate styleName">
                        <a
                          href="javascript:void(0)"
                          (click)="onEditStyle(card.id, $event)"
                        >
                          {{ card.styleName }}
                        </a>
                      </p>

                      <div class="row style_selected_values">
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                           <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleColourwayRel"
                            />
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="card.styleColourwayRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.common.colorways }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                           <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleImageRel"
                            />
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="card.styleImageRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.styleimage }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                             <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleBOMRel"
                            />
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="card.styleBOMRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.bom }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                           <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleConstructionRel"
                            />
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="card.styleConstructionRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.construction }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                          <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.stylePattrenRel"
                            />

                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="card.stylePattrenRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14 text-break"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.pattern }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleSpecificationRel"
                            />
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="card.styleSpecificationRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.specification }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                           <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleProcessingRel"
                            />
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="card.styleProcessingRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.processing }}</a
                            >
                          </div>
                        </div>
                      </div>
                      <hr />
                      <!-- <div class="col-sm-8 col-xl-8">
                            <span class="d-block text-light-gray mb-3 mt-1 f-12 text-truncate">{{card.category}}</span>
                          </div> -->
                      <div class="row">
                        <div class="col-sm-2 col-xl-2">
                          <div class="d-web">
                            <span
                              *ngIf="card.status == 'Active'"
                              class="activeState"
                            >
                              {{ card.status }}
                            </span>
                            <span
                              *ngIf="card.status == 'Inactive'"
                              class="inactiveState"
                            >
                              {{ card.status }}
                            </span>
                          </div>
                        </div>
                        <!-- <i class="icon icon-dot d-green"></i>
                            <span class="text-light-gray mb-3 f-12 d-active">{{card.active}}</span>
                             Dropdown -->
                        <div class="col-sm-10 col-xl-10">
                          <div
                            *ngFor="
                              let rate of sustainabilityRating;
                              let i = index
                            "
                          >
                            <div *ngIf="card.id == rate.styleId">
                              <ng-template #popTitle
                                >{{ appStaticMsgs.styles.sustainabilityRating }}
                                <span class="float-right">
                                  <h4>
                                    {{ rate.TotalRating }}/5
                                    <i
                                      class="fa fa-close f-11 cp ml-2"
                                      (click)="toggleWithGreeting(rate)"
                                    >
                                    </i>
                                  </h4>
                                </span>
                              </ng-template>

                              <ng-template #popContent>
                                <div class="row w-100">
                                  <div class="col-md-6">
                                    <b class="float-right">
                                      <ul
                                        class="list-inline rating-list"
                                        *ngFor="let rating of stars"
                                        style="display: inline-block"
                                      >
                                        <li
                                          (click)="
                                            countStarGreenMaterials(
                                              rate,
                                              rating
                                            )
                                          "
                                          [ngClass]="{
                                            selected:
                                              rating <= rate.greenMaterials
                                          }"
                                        >
                                          <i class="fa fa-star"></i>
                                        </li>
                                      </ul>
                                    </b>
                                  </div>
                                  <div class="col-md-6">
                                    <p class="float-right">
                                      {{ appStaticMsgs.styles.greenMaterials }}
                                    </p>
                                  </div>

                                  <div class="col-md-6">
                                    <b class="float-right">
                                      <ul
                                        class="list-inline rating-list"
                                        *ngFor="let rating of stars"
                                        style="display: inline-block"
                                      >
                                        <li
                                          (click)="
                                            countStarLocallySourced(
                                              rate,
                                              rating
                                            )
                                          "
                                          [ngClass]="{
                                            selected:
                                              rating <= rate.locallySourced
                                          }"
                                        >
                                          <i class="fa fa-star"></i>
                                        </li>
                                      </ul>
                                    </b>
                                  </div>
                                  <div class="col-md-6">
                                    <p class="float-right">
                                      {{ appStaticMsgs.styles.locallySourced }}>
                                    </p>
                                  </div>

                                  <div class="col-md-6">
                                    <b class="float-right">
                                      <ul
                                        class="list-inline rating-list"
                                        *ngFor="let rating of stars"
                                        style="display: inline-block"
                                      >
                                        <li
                                          (click)="
                                            countStarLeanProduction(
                                              rate,
                                              rating
                                            )
                                          "
                                          [ngClass]="{
                                            selected:
                                              rating <= rate.leanProduction
                                          }"
                                        >
                                          <i class="fa fa-star"></i>
                                        </li>
                                      </ul>
                                    </b>
                                  </div>
                                  <div class="col-md-6">
                                    <p class="float-right">
                                      {{ appStaticMsgs.styles.leanProduction }}
                                    </p>
                                  </div>

                                  <div class="col-md-6">
                                    <b class="float-right">
                                      <ul
                                        class="list-inline rating-list"
                                        *ngFor="let rating of stars"
                                        style="display: inline-block"
                                      >
                                        <li
                                          (click)="
                                            countStarFairTrade(rate, rating)
                                          "
                                          [ngClass]="{
                                            selected: rating <= rate.fairTrade
                                          }"
                                        >
                                          <i class="fa fa-star"></i>
                                        </li>
                                      </ul>
                                    </b>
                                  </div>
                                  <div class="col-md-6">
                                    <p class="float-right">
                                      {{ appStaticMsgs.styles.fairTrade }}
                                    </p>
                                  </div>
                                </div>
                              </ng-template>
                              <div class="a-right mr-3 d-flex mm-2 icons_data">
                                <ul class="list-group list-group-horizontal">
                                  <li
                                    class="list-group-item"
                                    placement="bottom"
                                    ngbTooltip="Edit"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      (click)="onEditStyleDetails(card.id)"
                                    >
                                      <i class="fa fa-pencil-square-o mr-1"></i>
                                    </a>
                                  </li>
                                  <!-- <li class="list-group-item" placement="bottom" ngbTooltip="Delete"> <a
                                      href="javascript:void(0)" (click)="onDeleteStyle(card.id)">
                                      <i class="fa fa-trash mr-1"></i>
                                    </a></li>
                                  <li class="list-group-item" placement="bottom" ngbTooltip="Add Collaborator">
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#long-modal"
                                      (click)="getSharedData(card.id)">
                                      <i class="fa fa-group mr-1"></i>
                                    </a>
                                  </li>
                                  <li class="list-group-item" placement="bottom" ngbTooltip="Generate Techpack">
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#small-modal">
                                      <img src="../../../../assets/images/icon/tech-pack.png" width="16" />
                                    </a>
                                  </li> -->
                                  <!-- <li class="list-group-item">
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#small-modal"
                                      (mouseenter)="p.open()">
                                      <i class="fas fa-infinity m-auto mr-2 cp text-primary" [autoClose]="true"
                                        #p="ngbPopover" triggers="manual" [ngbPopover]="popContent"
                                        [popoverTitle]="popTitle"></i>
                                    </a>
                                  </li> -->
                                </ul>
                                <!--<i class="fas fa-infinity m-auto mr-2 cp text-primary" [autoClose]="false" #p="ngbPopover"
                                  (click)="p.open()" triggers="manual" [ngbPopover]="popContent"
                                  [popoverTitle]="popTitle"></i>-->
                                <!-- <div class="dropdown">
                                  <a
                                    class="dropdown-toggle no-arrow"
                                    href="#"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i
                                      class="icon icon-vertical-more icon-fw icon-lg text-primary"
                                    ></i>
                                  </a>

                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0)"
                                      (click)="onEditStyleDetails(card.id)"
                                    >
                                      <i class="fa fa-pencil-square-o mr-1"></i>
                                      {{ appStaticMsgs.common.edit }}
                                    </a>
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0)"
                                      (click)="onDeleteStyle(card.id)"
                                    >
                                      <i class="fa fa-trash mr-1"></i>
                                      {{ appStaticMsgs.common.delete }}
                                    </a>
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#long-modal"
                                      (click)="onEditStyle(card.id, $event)"
                                    >
                                      <i class="fa fa-group mr-1"></i>
                                      {{ appStaticMsgs.common.share }}
                                    </a>
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#small-modal"
                                      (click)="onEditStyle(card.id, $event)"
                                    >
                                      <i class="fas fa-tshirt mr-1" mr-1></i>
                                      {{
                                        appStaticMsgs.common.generateTechpack
                                      }}
                                    </a>
                                  </div>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /dropdown -->
                    </div>

                    <div class="action-tools">
                      <!-- Checkbox -->
                      <div
                        class="dt-checkbox dt-checkbox-icon dt-checkbox-only"
                      >
                        <input
                          id="checkbox{{ card.id }}"
                          type="checkbox"
                          [ngModel]="card.isFavourite"
                          (click)="AddToFavorite($event)"
                          [ngbTooltip]="
                            card.isFavourite
                              ? 'Remove from favorite'
                              : 'Add to favorite'
                          "
                        />
                        <label
                          class="font-weight-light dt-checkbox-content"
                          for="checkbox-1"
                        >
                          <span class="unchecked"
                            ><i class="icon icon-star-o text-white icon-lg"></i
                          ></span>
                          <span class="checked"
                            ><i
                              class="icon icon-star-fill text-secondary icon-lg"
                            ></i
                          ></span>
                        </label>
                      </div>
                      <!-- /checkbox -->
                    </div>

                    <!-- /card body -->
                  </div>
                  <!-- /card -->
                </div>
                <!-- /grid item -->
              </div>
              <!-- /card -->
            </div>
            <!-- /grid item -->
          </div>
          <div *ngIf="!isSharedGetAllRecords" class="row mt-5">
            <div
              *ngIf="!isSharedScrollDown"
              class="col-xl-12 col-md-12 col-sm-12 col-12 text-center"
            >
              <div class="text-center w-100">
                <div class="loadersmall"></div>
              </div>
            </div>
          </div>

          <!-- /grid -->
        </div>
      </ng-container>
      <ng-container *ngIf="this.styleViewMode == 'Created'">
        <div class="text-center" *ngIf="notFound">
          <button
            *ngIf="mystyle"
            type="button"
            class="btn btn-primary adv-search-btn mb-5"
            (click)="createStyle()"
          >
            <i class="icon icon-plus"></i>{{ appStaticMsgs.common.createnew }}
          </button>
          <h3>{{ appStaticMsgs.styles.noStylesAvailable }}</h3>
          <img [src]="notfoundImg" class="img-fluid w-40" />
        </div>
        <div class="grid-content" *ngIf="gridContent && !notFound">
          <div class="row">
            <div class="col-sm-9 col-md-9 col-xl-9 col-lg-9">
              <div *ngIf="!checkStatusData">
                <i class="fa fa-search search-button"></i>
                <input
                  type="text"
                  class="form-control searchBox"
                  value=""
                  placeholder="Enter Keywords to filter results"
                  [(ngModel)]="searchedKeyword"
                />
                <!-- <tag-input [(ngModel)]='searchedKeyword' class="w-85" placeholder="Enter keywords to search"></tag-input>
                <button type="button"  (click)="onSave()"class="btn btn-primary btn-sm adv-search-btn ml-7">
                  <i class="icon icon-search mr-1"></i> Search
                </button> -->
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-xl-3 col-lg-3">
              <select
                placeholder="Sort By"
                class="form-control selectpicker w-100"
                [(ngModel)]="selected"
                (ngModelChange)="onSelect(selected, 'mystyle')"
              >
                <option *ngFor="let status of options" [value]="status.value">
                  {{ status.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-center" *ngIf="checkStatusData">
            <h2>{{ msg }}</h2>
            <img [src]="notfoundImg" class="img-fluid w-40" /><br />
          </div>

          <!-- Grid -->
          <div class="row mt-5 mr-0">
            <!-- Grid Item -->
            <div class="col-xl-12 order-xl-1">
              <!-- Card -->
              <div class="row">
                <!-- Grid Item -->

                <div
                  class="col-xl-3 col-md-4 col-sm-6 col-12 prn-1"
                  *ngFor="
                    let card of cards | async | filter : searchedKeyword;
                    let i = index
                  "
                >
                  <!-- Card -->
                  <div class="card border-l-r">
                    <!-- Card Body -->
                    <div
                      class="w-100"
                      style="position: relative"
                      (mouseenter)="StyleImageHover(i)"
                      (mouseleave)="StyleImageHover(-1)"
                    >
                      <!--<img class="w-100 border-l-r h-260" [src]='thumbnail' alt="Img" >-->
                      <!-- <img *ngIf="card.styleImage.thumbnail" class="w-100 border-l-r h-260" alt="" src={{card.styleImage.thumbnail}}>
                      <img *ngIf="!card.styleImage.thumbnail" class="w-100 border-l-r h-260" [src]='thumbnail' alt="Img">-->
                      <img
                        *ngIf="card.styleImage"
                        class="card-view-img"
                        alt=""
                        src="{{ card.styleImage.thumbnail }}"
                      />
                      <img
                        *ngIf="card.styleImage == null"
                        class="card-view-img"
                        alt=""
                        src="{{ thumbnail }}"
                      />

                      <div
                        class="hover_content img-fit h-260"
                        *ngIf="i == hoverIndex"
                      >
                        <i
                          class="fa fa-eye mr-1"
                          (click)="Modalopen(content)"
                        ></i>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="row style_design">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <span
                            class="mb-1 mb-sm-0 f-13 text-truncate style_header"
                          >
                            <a
                              href="javascript:void(0)"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ card.newStyleNum }}
                            </a>
                          </span>
                          <!-- <span class="style_header">v2.3</span> -->
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <div class="version_history">
                            <div class="d-web">
                              <span
                                *ngIf="card.status == 'Active'"
                                class="activeState"
                              >
                                {{ card.status }}
                              </span>
                              <span
                                *ngIf="card.status == 'Inactive'"
                                class="inactiveState"
                              >
                                {{ card.status }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <p class="mb-1 mb-sm-0 f-13 text-truncate styleNumber">
                        <a href="javascript:void(0)" (click)="onEditStyle(card.id,$event)">
                          {{appStaticMsgs.styles.styless}} : {{card.styleNumber}}
                        </a>
                      </p> -->
                      <div class="row">
                        <p class="styleName">
                          <a
                            href="javascript:void(0)"
                            (click)="onEditStyle(card.id, $event)"
                          >
                            {{ card.styleName }}
                          </a>
                        </p>
                      </div>
                      <hr class="border_line" />
                      <div class="row style_selected_values">
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="true"
                            />
                            <!-- <input type="checkbox" class="custom-control-input" [checked]="card.styleColourwayRel"> -->
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="true"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              Style Info</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleColourwayRel"
                            />
                            <!-- <input type="checkbox" class="custom-control-input" [checked]="card.styleColourwayRel"> -->
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="card.styleBOMRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.bom }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              *ngIf="card.styleColourwayRel"
                              width="22"
                              [checked]="card.styleColourwayRel"
                            />
                            <img
                              src="../../../../assets/images/icon/check-inactive.png"
                              *ngIf="!card.styleColourwayRel"
                              width="22"
                              [checked]="!card.styleColourwayRel"
                            />
                            <!-- <input type="checkbox" class="custom-control-input" [checked]="card.styleColourwayRel"> -->
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.common.colorways }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleColourwayRel"
                            />
                            <!-- <input type="checkbox" class="custom-control-input" [checked]="card.styleColourwayRel"> -->
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                            POM</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleColourwayRel"
                            />
                            <!-- <input type="checkbox" class="custom-control-input" [checked]="card.styleColourwayRel"> -->
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14 text-break"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.pattern }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleColourwayRel"
                            />
                            <!-- <input type="checkbox" class="custom-control-input" [checked]="card.styleColourwayRel"> -->
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.construction }}</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleColourwayRel"
                            />
                            <!-- <input type="checkbox" class="custom-control-input" [checked]="card.styleColourwayRel"> -->
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            > Attachments
                             </a
                            >
                          </div>
                        </div>
                        <!-- <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                          <div
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <img
                              src="../../../../assets/images/icon/check-active.png"
                              width="22"
                              [checked]="card.styleColourwayRel"
                            />
                            <a
                              href="javascript:void(0)"
                              class="custom-control-label f-14"
                              (click)="onEditStyle(card.id, $event)"
                            >
                              {{ appStaticMsgs.styles.processing }}</a
                            >
                          </div>
                        </div> -->
                      </div>
                      <hr class="border_line" />
                      <!-- <div class="col-sm-8 col-xl-8">
                            <span class="d-block text-light-gray mb-3 mt-1 f-12 text-truncate">{{card.category}}</span>
                          </div> -->
                      <div class="row">
                        <div class="col-sm-8 col-xl-8">
                          <div class="group_members">
                            <div
                              *ngFor="
                                let arr of card.styleCollaboratorUsers;
                                let i = index
                              "
                            >
                              <div *ngIf="i <= 3" class="avatar-group">
                                <ng-container
                                  *ngIf="
                                    arr.user[0]?.eMail !==
                                    LoggedinuserDetails?.attributes.email
                                  "
                                >
                                  <div
                                    *ngIf="!arr.user[0].pictureID"
                                    class="avatar pull-up"
                                  >
                                    <a
                                      placement="top"
                                      alt="Avatar"
                                      ngbTooltip="{{ arr.user[0].fullName }}"
                                    >
                                      <ngx-avatar
                                        name="{{
                                          arr.user[0].firstName +
                                            ' ' +
                                            arr.user[0].lastName
                                        }}"
                                        size="27"
                                      ></ngx-avatar>
                                    </a>
                                  </div>
                                  <div
                                    *ngIf="arr.user[0].pictureID"
                                    class="avatar pull-up"
                                  >
                                    <img
                                      [src]="arr.user[0].picture.thumbnail"
                                      placement="top"
                                      ngbTooltip="{{ arr.user[0].fullName }}"
                                      alt="Avatar"
                                      height="27"
                                      width="27"
                                    />
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                            <div
                              *ngIf="card.styleCollaboratorUsers.length >= 5"
                              class="pull-up add-user-data1"
                            >
                              <span style="color: white;"
                                >  <i class="fa fa-plus" aria-hidden="true"></i
                                  >{{ card.styleCollaboratorUsers.length - 4 }}
                               </span>
                            </div>
                            <div
                              class="add-user-data"
                              data-toggle="modal"
                              data-target="#long-modal"
                              (click)="getSharedData(card.id)"
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                        <!-- <i class="icon icon-dot d-green"></i>
                            <span class="text-light-gray mb-3 f-12 d-active">{{card.active}}</span>
                             Dropdown -->
                        <div class="col-sm-4 col-xl-4">
                          <div
                            *ngFor="
                              let rate of sustainabilityRating;
                              let i = index
                            "
                          >
                            <ng-template #popTitle
                              >{{ appStaticMsgs.styles.sustainabilityRating }}
                              <span class="float-right">
                                <h4>
                                  {{ rate.TotalRating }}/5
                                  <i
                                    class="fa fa-close f-11 cp ml-2"
                                    (click)="p.close()"
                                  >
                                  </i>
                                </h4>
                              </span>
                            </ng-template>
                            <ng-template #popContent>
                              <div class="row w-100">
                                <div class="col-md-6">
                                  <b class="float-right">
                                    <ul
                                      class="list-inline rating-list"
                                      *ngFor="let rating of stars"
                                      style="display: inline-block"
                                    >
                                      <li
                                        (click)="
                                          countStarGreenMaterials(rate, rating)
                                        "
                                        [ngClass]="{
                                          selected:
                                            rating <= rate.greenMaterials
                                        }"
                                      >
                                        <i class="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </b>
                                </div>
                                <div class="col-md-6">
                                  <p class="float-right">
                                    {{ appStaticMsgs.styles.greenMaterials }}
                                  </p>
                                </div>

                                <div class="col-md-6">
                                  <b class="float-right">
                                    <ul
                                      class="list-inline rating-list"
                                      *ngFor="let rating of stars"
                                      style="display: inline-block"
                                    >
                                      <li
                                        (click)="
                                          countStarLocallySourced(rate, rating)
                                        "
                                        [ngClass]="{
                                          selected:
                                            rating <= rate.locallySourced
                                        }"
                                      >
                                        <i class="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </b>
                                </div>
                                <div class="col-md-6">
                                  <p class="float-right">
                                    {{ appStaticMsgs.styles.locallySourced }}
                                  </p>
                                </div>

                                <div class="col-md-6">
                                  <b class="float-right">
                                    <ul
                                      class="list-inline rating-list"
                                      *ngFor="let rating of stars"
                                      style="display: inline-block"
                                    >
                                      <li
                                        (click)="
                                          countStarLeanProduction(rate, rating)
                                        "
                                        [ngClass]="{
                                          selected:
                                            rating <= rate.leanProduction
                                        }"
                                      >
                                        <i class="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </b>
                                </div>
                                <div class="col-md-6">
                                  <p class="float-right">
                                    {{ appStaticMsgs.styles.leanProduction }}
                                  </p>
                                </div>

                                <div class="col-md-6">
                                  <b class="float-right">
                                    <ul
                                      class="list-inline rating-list"
                                      *ngFor="let rating of stars"
                                      style="display: inline-block"
                                    >
                                      <li
                                        (click)="
                                          countStarFairTrade(rate, rating)
                                        "
                                        [ngClass]="{
                                          selected: rating <= rate.fairTrade
                                        }"
                                      >
                                        <i class="fa fa-star"></i>
                                      </li>
                                    </ul>
                                  </b>
                                </div>
                                <div class="col-md-6">
                                  <p class="float-right">
                                    {{ appStaticMsgs.styles.fairTrade }}
                                  </p>
                                </div>
                              </div>
                            </ng-template>
                            <div class="a-right mr-3 d-flex mm-2 icons_data">
                              <ul class="list-group list-group-horizontal">
                                <li
                                  class="list-group-item"
                                  placement="bottom"
                                  ngbTooltip="Edit"
                                >
                                  <a
                                    href="javascript:void(0)"
                                    (click)="onEditStyleDetails(card.id)"
                                  >
                                    <i class="fa fa-pencil-square-o mr-1"></i>
                                  </a>
                                </li>
                                <!-- <li class="list-group-item" placement="bottom" ngbTooltip="Delete"> <a
                                      href="javascript:void(0)" (click)="onDeleteStyle(card.id)">
                                      <i class="fa fa-trash mr-1"></i>
                                    </a></li> -->
                                <!-- <li class="list-group-item" placement="bottom" ngbTooltip="Add Collaborator">
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#long-modal"
                                      (click)="getSharedData(card.id)">
                                      <i class="fa fa-group mr-1"></i>
                                    </a>
                                  </li>
                                  <li class="list-group-item" placement="bottom" ngbTooltip="Generate Techpack">
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#small-modal">
                                      <img src="../../../../assets/images/icon/tech-pack.png" width="16" />
                                    </a>
                                  </li>
                                  <li class="list-group-item" style="pointer-events:none;opacity: 0.5;">
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#small-modal"
                                      (click)="toggleWithGreeting(p)">
                                      <i class="fas fa-infinity m-auto mr-2 cp text-primary" [autoClose]="false"
                                        #p="ngbPopover" triggers="manual" [ngbPopover]="popContent"
                                        [popoverTitle]="popTitle"></i>
                                    </a>
                                  </li> -->
                              </ul>
                              <!-- <i class="fas fa-infinity m-auto mr-2 cp text-primary" [autoClose]="false" #p="ngbPopover"
                                  (click)="p.open()" triggers="manual" [ngbPopover]="popContent"
                                  [popoverTitle]="popTitle"></i>-->
                              <div class="dropdown mt-3">
                                <a
                                  class="dropdown-toggle no-arrow"
                                  href="#"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    class="icon icon-vertical-more icon-fw icon-lg text-primary"
                                  ></i>
                                </a>

                                <div
                                  class="dropdown-menu dropdown-menu-right style-delete-dropdown"
                                >
                                  <a
                                    href="javascript:void(0)"
                                    class="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#small-modal"
                                    (click)="saveTechpackId(card.id)"
                                  >
                                    <img
                                      src="../../../../assets/images/icon/tech-pack.png"
                                      width="24"
                                    />
                                    Generate Techpack
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    style="opacity: 0.4"
                                    class="dropdown-item"
                                  >
                                    <i
                                      class="fas fa-infinity m-auto mr-2 text-primary"
                                      [autoClose]="false"
                                      #p="ngbPopover"
                                      triggers="manual"
                                      [ngbPopover]="popContent"
                                      [popoverTitle]="popTitle"
                                    ></i>
                                    Sustainability Rating
                                  </a>
                                  <!-- <a href="javascript:void(0)" style="opacity: 0.4;" class="dropdown-item" data-toggle="modal" data-target="#small-modal"
                                      (click)="toggleWithGreeting(p)">
                                      <i class="fas fa-infinity m-auto mr-2 text-primary" [autoClose]="false"
                                        #p="ngbPopover" triggers="manual" [ngbPopover]="popContent"
                                        [popoverTitle]="popTitle"></i> Sustainability Rating
                                    </a> -->
                                  <hr class="border_line" />
                                  <a
                                    class="dropdown-item red1"
                                    href="javascript:void(0)"
                                    (click)="onDeleteStyle(card.id)"
                                  >
                                    <i class="fa fa-trash mr-1"></i>
                                    {{ appStaticMsgs.common.delete }} Style
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /dropdown -->
                    </div>

                    <div class="action-tools">
                      <!-- Checkbox -->
                      <div
                        class="dt-checkbox dt-checkbox-icon dt-checkbox-only"
                      >
                        <input
                          id="checkbox{{ card.id }}"
                          type="checkbox"
                          [ngModel]="card.isFavourite"
                          (click)="AddToFavorite($event)"
                          [ngbTooltip]="
                            card.isFavourite
                              ? 'Remove from favorite'
                              : 'Add to favorite'
                          "
                        />
                        <label
                          class="font-weight-light dt-checkbox-content"
                          for="checkbox-1"
                        >
                          <span class="unchecked"
                            ><i class="icon icon-star-o text-white icon-lg"></i
                          ></span>
                          <span class="checked"
                            ><i
                              class="icon icon-star-fill text-secondary icon-lg"
                            ></i
                          ></span>
                        </label>
                      </div>
                      <!-- /checkbox -->
                    </div>

                    <!-- /card body -->
                  </div>
                  <!-- /card -->
                  <!-- Style Image Modal Popup -->
                  <ng-template #content let-modal>
                    <!-- Modal Content -->
                    <!-- Modal Header -->
                    <div class="modal-header d-none">
                      <!-- <h3 class="modal-title" id="model-3">Sreekanth Sample</h3> -->
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <!-- /modal header -->

                    <!-- Modal Body -->
                    <div class="modal-body">
                      <div class="header_div">
                        <button
                          type="button"
                          class="close"
                          aria-label="Close"
                          (click)="modal.dismiss('Cross click')"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <img
                        *ngIf="card.styleImage"
                        alt="{{ thumbnail }}"
                        width="100%"
                        src="{{ card.styleImage.thumbnail }}"
                      />
                      <img
                        *ngIf="card.styleImage == null"
                        alt="{{ thumbnail }}"
                        width="100%"
                        src="{{ thumbnail }}"
                      />
                    </div>
                    <!-- /modal body -->
                  </ng-template>
                  <!-- /Style Image Modal Popup  -->
                  <div
                    class="modal fade"
                    id="small-modal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="model-2"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <!-- Modal Content -->
                      <div class="modal-content">
                        <!-- Modal Header -->
                        <div class="modal-header">
                          <h3 class="modal-title" id="model-2">
                            {{ appStaticMsgs.styledetails.generateTechpack }}
                          </h3>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <!-- /modal header -->
                        <!-- Modal Body -->
                        <div class="modal-body">
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col-md-12 mb-2 p-5">
                                <div class="col-sm-8 mb-3">
                                  <label for="validationDefault02">{{
                                    appStaticMsgs.styledetails.selecttemplate
                                  }}</label>
                                  <select
                                    [multiple]="false"
                                    title="Select template"
                                    class="form-control w-100 selectpicker" style="display: block !important;"
                                  >
                                    <option
                                      *ngFor="let seltemplate of seltemplates"
                                      [value]="seltemplate.value"
                                    >
                                      {{ seltemplate.label }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-sm-8 mt-5">
                                  <!-- <label for="validationDefault02">Select Type</label>
                                <br>
                                <div class="custom-control custom-radio custom-control-inline">
                                  <input disabled="true" type="radio" id="customRadioInline1"
                                    name="customRadioInline1" class="custom-control-input">
                                  <label class="custom-control-label" for="customRadioInline1">
                                    PDF
                                  </label>
                                </div> -->
                                  <!-- /radio button -->
                                  <!-- Radio Button -->
                                  <div
                                    class="custom-control custom-radio custom-control-inline d-none"
                                  >
                                    <input
                                      type="radio"
                                      id="customRadioInline2"
                                      name="customRadioInline1"
                                      class="custom-control-input"
                                      checked
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadioInline2"
                                    >
                                      {{ appStaticMsgs.common.excel }}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- /modal body -->
                        <!-- Modal Footer -->
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-sm"
                            (click)="addRow()"
                            data-dismiss="modal"
                          >
                            {{ appStaticMsgs.styledetails.close }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            (click)="exportTechPackReport()"
                            data-dismiss="modal"
                          >
                            {{ appStaticMsgs.styledetails.generateTechpack }}
                          </button>
                        </div>
                        <!-- /modal footer -->
                      </div>
                      <!-- /modal content -->
                    </div>
                  </div>
                </div>
                <!-- /grid item -->
              </div>
              <!-- /card -->
            </div>
            <!-- /grid item -->
          </div>
          <div *ngIf="!isGetAllRecords" class="row mt-5">
            <div
              *ngIf="!isScrollDown"
              class="col-xl-12 col-md-12 col-sm-12 col-12 text-center"
            >
              <div class="text-center w-100">
                <div class="loadersmall"></div>
              </div>
            </div>
          </div>

          <!-- /grid -->
        </div>
      </ng-container>

      <div class="grid-content custom-table" *ngIf="tableContent && !notFound">
        <!-- Grid Item -->
        <div class="">
          <!-- (dblclick)="dblClick($event)"  -->
          <kendo-grid
            [resizable]="true"
            #grid
            id="productsGrid"
            kendoGridSelectBy="id"
            [data]="gridData"
            [selectedKeys]="mySelection"
            [pageable]="true"
            [reorderable]="true"
            [resizable]="true"
            [pageSize]="10"
            [height]="605"
            [(loading)]="loading"
            (pageChange)="onPageChange($event)"
            [skip]="state.skip"
            [sort]="state.sort"
            filterable="menu"
            [filter]="filter"
            [sortable]="true"
            (dataStateChange)="dataStateChange($event)"
            [groupable]="true"
            (filterChange)="filterChange($event)"
          >
            <kendo-grid-checkbox-column
              [width]="60"
              [showSelectAll]="true"
              [resizable]="false"
              [locked]="true"
            ></kendo-grid-checkbox-column>
            <ng-template kendoGridToolbarTemplate>
              <input
                placeholder="Search in all columns..."
                kendoTextBox
                (input)="searchListData($event.target.value)"
                class="kendoSearch"
              />
              <!-- <ng-template *ngIf="isEditMode" kendoGridToolbarTemplate> -->
              <!-- <div *ngIf="isEditMode" class="float-right">
                <button (click)="onSave()" class="k-button k-primary mr-3">{{appStaticMsgs.common.save}}</button>
                <button (click)="onCancel()" class="k-button k-primary mr-3">{{appStaticMsgs.common.cancel}}</button>
              </div> -->
              <!-- </ng-template> -->
              <!-- <div class="float-right" *ngIf="!isEditMode">
                <button (click)="onEdit()" placement="top" ngbTooltip="Edit" class="k-button k-primary mr-3">
                  <i class="icon icon-editors f-17"></i>
                </button>

              </div> -->
              <div class="float-right">
                <!-- <button
                  kendoGridExcelCommand
                  type="button"
                  class="mr-3"
                  icon="file-excel"
                >
                  Export to Excel
                </button>
                <button kendoGridPDFCommand class="mr-3" icon="file-pdf">
                  Export to PDF
                </button> -->
                <div
                                class="kendo-button-icon mr-3"
                                kendoGridExcelCommand
                              >
                                <i
                                  class="fa fa-file-excel-o"
                                  aria-hidden="true"
                                ></i>
                              </div>



<div
                                kendoGridPDFCommand
                                class="kendo-button-icon mr-3"
                              >
                                <i
                                  class="fa fa-file-pdf-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
              </div>
              <!-- <kendo-grid-spacer></kendo-grid-spacer> -->
            </ng-template>

            <kendo-grid-column
              field="country"
              title="Image"
              [width]="100"
              [class]="{ 'text-center': true }"
              [resizable]="false"
              [locked]="true"
              [filterable]="false"
              [sortable]="false"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <img
                  class="flag"
                  width="100%"
                  height="100%"
                  *ngIf="dataItem.styleImage == null"
                  [src]="thumbnail"
                />
                <img
                  *ngIf="dataItem.styleImage"
                  class="flag"
                  width="100%"
                  height="100%"
                  alt=""
                  src="{{ dataItem.styleImage.thumbnail }}"
                />
              </ng-template>
            </kendo-grid-column>

            <!-- <kendo-grid-column
              field="styleNumber"
              title="ID"
              [width]="220"
              [locked]="true"
              [filterable]="false"
              [sortable]="false"
            >
            </kendo-grid-column> -->
            <kendo-grid-column
              field="newStyleNum"
              title="Style Number"
              [width]="220"
              [locked]="true"
              [filterable]="false"
              [sortable]="false"
            >
            </kendo-grid-column>

            <kendo-grid-column field="styleName" title="Name" [width]="280">
              <!-- <ng-template kendoGridCellTemplate let-dataItem>
                <div
                  class="customer-photo"
                  [ngStyle]="{ 'background-image': photoURL(dataItem) }"
                ></div>
                <div class="customer-name">{{ dataItem.createdBy.FirstName }}</div>
              </ng-template> -->
            </kendo-grid-column>

            <kendo-grid-column field="category" title="Category" [width]="250">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.category }}
              </ng-template>
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
                let-dataItem="dataItem"
                let-rowIndex="rowIndex"
              >
                <app-dropdownlist-filter
                  [data]="categoryList"
                  [textField]="'label'"
                  [valueField]="'value'"
                  [isPrimitive]="true"
                  [field]="column.field"
                  [currentFilter]="filter"
                  [filterService]="filterService"
                >
                </app-dropdownlist-filter>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="status" title="Status" [width]="120">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.status }}
              </ng-template>
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
                let-dataItem="dataItem"
                let-rowIndex="rowIndex"
              >
                <app-dropdownlist-filter
                  [data]="options"
                  [textField]="'label'"
                  [valueField]="'value'"
                  [isPrimitive]="true"
                  [field]="column.field"
                  [currentFilter]="filter"
                  [filterService]="filterService"
                >
                </app-dropdownlist-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Created By" [width]="250">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="customer-name">
                  <div
                    [ngStyle]="{
                      'background-image': 'url(' + userThumbnail + ')'
                    }"
                    class="customer-photo"
                    *ngIf="!dataItem.createdBy.imageUrl"
                  >
                    <!-- <img [src]='userThumbnail' class="select_rounded_icon w-6"> -->
                  </div>
                  <div
                    [ngStyle]="{
                      'background-image':
                        'url(' + dataItem.createdBy.imageUrl + ')'
                    }"
                    class="customer-photo"
                    *ngIf="dataItem.createdBy.imageUrl"
                  >
                    <!-- <img class="select_rounded_icon w-6" src={{dataItem.createdBy.imageUrl}} alt=""> -->
                  </div>
                  {{
                    dataItem.createdBy.firstName +
                      " " +
                      dataItem.createdBy.lastName
                  }}
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="createdOn"
              title="Created On"
              [width]="120"
              filter="date"
              format="MM/dd/yyyy"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.createdOn | date : "MM-dd-yyyy" }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="modifiedOn"
              title="Modified On"
              [width]="135"
              filter="date"
              format="MM/dd/yyyy"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.modifiedOn | date : "MM-dd-yyyy" }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-command-column title="Action" width="220">
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <button
                  (click)="editClick(rowIndex, dataItem)"
                  kendoButton
                  class="k-button k-primary mr-3"
                  title="Edit"
                >
                  Edit
                </button>
                <button
                  *ngIf="dataItem.isDelete"
                  kendoButton
                  kendoGridRemoveCommand
                  (click)="onRemove(rowIndex, dataItem)"
                  class="k-button k-grid-remove-command"
                  title="Delete"
                >
                  Delete
                </button>
              </ng-template>
            </kendo-grid-command-column>
            <kendo-grid-excel
              fileName="StyleLibrary.xlsx"
              [fetchData]="allData"
            >
            </kendo-grid-excel>
            <kendo-grid-pdf fileName="StyleLibrary.pdf" [allPages]="true">
              <kendo-grid-pdf-margin
                top="0.5cm"
                left="0.5cm"
                right="0.5cm"
                bottom="0.5cm"
              ></kendo-grid-pdf-margin>
            </kendo-grid-pdf>

            <ng-template
              kendoPagerTemplate
              let-totalPages="totalPages"
              let-currentPage="currentPage"
            >
              <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
              <kendo-pager-numeric-buttons
                [buttonCount]="10"
              ></kendo-pager-numeric-buttons>
              <kendo-pager-next-buttons></kendo-pager-next-buttons>
              <kendo-pager-info></kendo-pager-info>
            </ng-template>
          </kendo-grid>
        </div>
        <!-- /grid item -->
      </div>

      <span *ngIf="this.showNote" class="red"
        >*Note: Name field is allowed 50 characters only.</span
      >
    </div>
  </div>
  <!-- /Site Content Wrapper-->
  <!-- Generate Techpack Modal -->

  <!-- /Generate Techpack Modal End -->
</main>

<!-- Footer -->
<app-footer></app-footer>
<!-- /footer -->
<!-- Modal -->
<div
  class="modal fade"
  id="long-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="model-2"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <!-- Modal Content -->
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title" id="model-2">
          <i aria-hidden="true" class="mr-3 fa fa-group ul-icon"></i
          >{{ appStaticMsgs.styledetails.addcollaborator }}
        </h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- /modal header -->
      <!-- Modal Body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 mb-2 p-5">
              <div class="row">
                <div class="col-md-9">
                  <!-- <ng-select [(ngModel)]="selectedCar" [multiple]="true" class="custom">
                    <ng-option *ngFor="let item of dropdownList" [value]="item.id">{{item.firstName+' '+item.lastName}}</ng-option>
                 </ng-select> -->

                  <angular2-multiselect
                    [data]="dropdownList"
                    [(ngModel)]="selectedItemsnew"
                    [settings]="dropdownSettings"
                    (onSelect)="onItemSelect($event)"
                    (onDeSelect)="OnItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)"
                    (onDeSelectAll)="onDeSelectAll($event)"
                  >
                    <c-item>
                      <ng-template let-item="item">
                        <img
                          *ngIf="item.image"
                          [src]="item.image"
                          class="size-30"
                          style="
                            width: 30px;
                            border: 1px solid #efefef;
                            margin-right: 20px;
                            float: left;
                            border-radius: 45%;
                          "
                        />
                        <ngx-avatar
                          class="avatar"
                          *ngIf="!item.image"
                          size="30"
                          name="{{ item.firstName + ' ' + item.lastName }}"
                        ></ngx-avatar>
                        <label
                          style="
                            color: #333;
                            float: left;
                            margin-top: 6px;
                            position: relative;
                            left: 10px;
                          "
                          >{{
                            item.firstName +
                              "
                          " +
                              item.lastName
                          }}
                          - {{ item.eMail }}</label
                        >
                      </ng-template>
                    </c-item>
                  </angular2-multiselect>
                </div>
                <div class="col-md-3">
                  <div class="float-right d-flex">
                    <select
                      title="Select"
                      [(ngModel)]="userPermission"
                      (change)="permChange($event)"
                      class="form-control selectpicker w-100 mr-3 border-primary border"
                    >
                      <option value="view" selected>
                        <i class="fa fa-eye mr-2 f-12"></i
                        >{{ appStaticMsgs.common.canview }}
                      </option>
                      <option value="edit">
                        <i class="fas fa-pen mr-2 f-12"></i
                        >{{ appStaticMsgs.common.canedit }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="card mt-10" *ngIf="this?.fieldArray?.length">
                <div>
                  <ul
                    class="list-group"
                    *ngFor="let users of this.fieldArray; let i = index"
                  >
                    <li
                      class="list-group-item border-0 justify-content-between align-items-center"
                    >
                      <span
                        *ngIf="users.user[0]?.picture"
                        style="float: left !important"
                      >
                        <img
                          src="{{ users.user[0]?.picture }}"
                          class="size-30 dt-avatar mr-2"
                        />
                      </span>
                      <span
                        *ngIf="!users.user[0]?.picture"
                        style="float: left !important"
                      >
                        <ngx-avatar
                          size="30"
                          name="{{
                            users.user[0]?.firstName +
                              ' ' +
                              users.user[0]?.lastName
                          }}"
                        >
                        </ngx-avatar>
                      </span>

                      <span style="padding: 12px; position: relative; top: 3px"
                        >{{ users.user[0]?.fullName }} -
                        {{ users.user[0]?.eMail }}</span
                      >

                      <div
                        class="float-right d-flex"
                        style="margin-right: 10px"
                      >
                        <div *ngIf="!users.edit && users.view" class="mr-10">
                          <select
                            title="Select"
                            class="form-control w-100 mr-3 border-primary border"
                            (change)="changePermission($event, users)"
                          >
                            <option value="view" selected>
                              <i class="fa fa-eye mr-2 f-12"></i
                              >{{ appStaticMsgs.common.canview }}
                            </option>
                            <option value="edit">
                              <i class="fas fa-pen mr-2 f-12"></i
                              >{{ appStaticMsgs.common.canedit }}
                            </option>
                          </select>
                        </div>
                        <div
                          *ngIf="users.edit == true && users.view == true"
                          class="mr-10"
                        >
                          <select
                            title="Select"
                            class="form-control w-100 mr-3 border-primary border"
                            (change)="changePermission($event, users)"
                          >
                            <option value="view">
                              <i class="fa fa-eye mr-2 f-12"></i
                              >{{ appStaticMsgs.common.canview }}
                            </option>
                            <option value="edit" selected>
                              <i class="fas fa-pen mr-2 f-12"></i
                              >{{ appStaticMsgs.common.canedit }}
                            </option>
                          </select>
                        </div>

                        <!-- <select title="Select" class="form-control selectpicker w-100 mr-3 border-primary border" >
                                <option value="Can View" ><i class="fa fa-eye mr-2 f-12"></i>Can View</option>
                                <option value="Promotional" selected ><i class="fas fa-pen mr-2 f-12"></i>Can Edit</option>
                              </select> -->

                        <button
                          class="btn btn-secondary btn-esm"
                          type="button"
                          (click)="removeRow(i)"
                        >
                          <i class="fas fa-trash px-0 text-white"></i>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {{ selectedItemsnew }}
        </div>
      </div>
      <!-- /modal body -->
      <!-- Modal Footer -->
      <div class="modal-footer">
        <!-- *ngIf="isshared" -->
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          data-dismiss="modal"
        >
          {{ appStaticMsgs.common.cancel }}
        </button>
        <button
          type="button"
          (click)="saveShareStyle()"
          data-dismiss="modal"
          class="btn btn-primary btn-sm"
        >
          <!-- data-dismiss="modal" -->
          {{ appStaticMsgs.common.save }}
        </button>
      </div>
      <!-- /modal footer -->
    </div>
    <!-- /modal content -->
  </div>
</div>
<!-- /modal -->
<!-- /site content -->
<!-- Customizer Sidebar -->
<aside
  class="dt-customizer dt-drawer position-right"
  [class.open]="classApplied"
>
  <!-- Item Detail Section -->
  <div class="dt-customizer__inner">
    <!-- Customizer Header -->
    <div class="dt-customizer__header">
      <!-- Customizer Title -->
      <div class="dt-customizer__title">
        <h4 class="mb-0">{{ appStaticMsgs.styles.advanceSearch }}</h4>
      </div>
      <!-- /customizer title -->
      <!-- Close Button -->
      <button type="button" class="close" (click)="advanceSearchClose()">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- /close button -->
    </div>
    <!-- /customizer header -->
    <!-- Customizer Body -->
    <div class="dt-customizer__body ps-custom-scrollbar">
      <!-- Customizer Body Inner  -->
      <div class="dt-customizer__body-inner">
        <form [formGroup]="advForm" (ngSubmit)="onSubmit(advForm.value)">
          <!-- Section -->
          <section>
            <div class="col-sm-12">
              <!--Accordion wrapper-->
              <div
                class="accordion md-accordion"
                id="accordionEx"
                role="tablist"
                aria-multiselectable="true"
              >
                <div class="col-sm-12 mb-4 text-center">
                  <button
                    type="button"
                    class="btn btn-primary adv-clear-btn"
                    *ngIf="isResetMode"
                    (click)="clearSearch()"
                  >
                    {{ appStaticMsgs.styles.clearSearch }}
                  </button>
                </div>

                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div
                    class="card-header"
                    role="tab"
                    id="headingOne1"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseten"
                    aria-expanded="true"
                    aria-controls="collapseten"
                  >
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{ appStaticMsgs.styles.category }}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enablecategoryVal">
                        <button
                          type="button"
                          class="close image-close ml-5"
                          (click)="this.clearfield('category')"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div
                    id="collapseten"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body h-auto w-100">
                      <select
                        id="cateorylist"
                        [multiple]="false"
                        title="Select category"
                        formControlName="category"
                        class="form-control selectpicker w-100"
                        [(ngModel)]="enablecategoryVal"
                        (ngModelChange)="
                          checkFieldVal(enablecategoryVal, 'category')
                        "
                      >
                        <option
                          *ngFor="let category of categoryList"
                          [value]="category.value"
                        >
                          {{ category.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div
                    class="card-header"
                    role="tab"
                    id="headingOne1"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseone"
                    aria-expanded="true"
                    aria-controls="collapseone"
                  >
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{ appStaticMsgs.common.name }}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enablestyleNameVal">
                        <button
                          type="button"
                          class="close image-close ml-5"
                          (click)="this.clearfield('styleName')"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div
                    id="collapseone"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body h-auto w-100">
                      <input
                        type="text"
                        class="form-control"
                        value=""
                        formControlName="styleName"
                        placeholder="Enter style name"
                        name="Searchsty"
                        #Searchsty
                        (input)="checkFieldVal(Searchsty.value, 'styleName')"
                      />
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div
                    class="card-header"
                    role="tab"
                    id="headingTwo2"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseTwo22"
                    aria-expanded="true"
                    aria-controls="collapseTwo22"
                  >
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{ appStaticMsgs.styles.styleNumber }}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enablestyleNumberVal">
                        <button
                          type="button"
                          class="close image-close ml-5"
                          (click)="this.clearfield('styleNumber')"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div
                    id="collapseTwo22"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body h-auto">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="styleNumber"
                        placeholder="Enter style number"
                        value=""
                        name="SearchNum"
                        #SearchNum
                        (input)="checkFieldVal(SearchNum.value, 'styleNumber')"
                      />
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree3"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseThree3"
                    aria-expanded="true"
                    aria-controls="collapseThree3"
                  >
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{ appStaticMsgs.styles.gender }}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enablegenderVal">
                        <button
                          type="button"
                          class="close image-close ml-5"
                          (click)="this.clearfield('gender')"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div
                    id="collapseThree3"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree3"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body h-auto">
                      <select
                        id="genderlist"
                        [multiple]="false"
                        title="Select target gender"
                        formControlName="gender"
                        class="form-control selectpicker w-100"
                        [(ngModel)]="enablegenderVal"
                        (ngModelChange)="
                          checkFieldVal(enablegenderVal, 'gender')
                        "
                      >
                        <option
                          *ngFor="let gender of gender"
                          [value]="gender.value"
                        >
                          {{ gender.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree3"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseEight"
                    aria-expanded="true"
                    aria-controls="collapseEight"
                  >
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{ appStaticMsgs.styles.fabric }}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enablefabricVal">
                        <button
                          type="button"
                          class="close image-close ml-5"
                          (click)="this.clearfield('fabric')"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div
                    id="collapseEight"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree3"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body h-auto">
                      <select
                        id="fabriclist"
                        [multiple]="false"
                        title="Select fabric"
                        formControlName="fabric"
                        class="form-control selectpicker w-100"
                        [(ngModel)]="enablefabricVal"
                        (ngModelChange)="
                          checkFieldVal(enablefabricVal, 'fabric')
                        "
                      >
                        <option
                          *ngFor="let fabric of fabric"
                          [value]="fabric.value"
                        >
                          {{ fabric.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->

                <div class="card">
                  <!-- Card header -->
                  <div
                    class="card-header"
                    role="tab"
                    id="headingTwo2"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseTwo2"
                    aria-expanded="true"
                    aria-controls="collapseTwo2"
                  >
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
                        aria-expanded="false" aria-controls="collapseTwo2">
                      <h5 class="mb-0">
                        Created By <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{ appStaticMsgs.common.createdBy }}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableCreatedByVal">
                        <button
                          type="button"
                          class="close image-close ml-5"
                          (click)="this.clearfield('createdBy')"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div
                    id="collapseTwo2"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body h-auto">
                      <!--<select title="Select Created By" multiple class="form-control selectpicker w-100">
                        <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
                      </select>-->
                      <input
                        type="text"
                        class="form-control"
                        value=""
                        formControlName="createdBy"
                        placeholder="Created By"
                        name="SearchD1"
                        #SearchD1
                        (input)="checkFieldVal(SearchD1.value, 'createdBy')"
                      />
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree3"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapsefour"
                    aria-expanded="true"
                    aria-controls="collapsefour"
                  >
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{ appStaticMsgs.common.status }}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableStatusVal">
                        <button
                          type="button"
                          class="close image-close ml-5"
                          (click)="this.clearfield('status')"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div
                    id="collapsefour"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree3"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body h-auto">
                      <select
                        title="Select Status"
                        formControlName="status"
                        id="statuslist"
                        multiple
                        class="form-control selectpicker w-100"
                        [(ngModel)]="enableStatusVal"
                        (ngModelChange)="
                          checkFieldVal(enableStatusVal, 'status')
                        "
                      >
                        <option
                          *ngFor="let status of options"
                          [value]="status.value"
                        >
                          {{ status.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div
                    class="card-header"
                    role="tab"
                    id="headingFive"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapsefive"
                    aria-expanded="true"
                    aria-controls="collapsefive"
                  >
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{ appStaticMsgs.styles.createdOn }}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div
                        class="col-sm-1"
                        *ngIf="enableCreatToVal || enableCreatFromVal"
                      >
                        <button
                          type="button"
                          class="close image-close ml-5"
                          (click)="this.clearfield('createdFromChild')"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div
                    id="collapsefive"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingFive"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body h-auto">
                      <div class="form-group">
                        <label>{{ appStaticMsgs.common.from }}</label>

                        <div class="input-group date">
                          <input
                            class="form-control"
                            name="dp"
                            [(ngModel)]="createdFromDate"
                            ngbDatepicker
                            readonly
                            (click)="d1.toggle()"
                            #d1="ngbDatepicker"
                            formControlName="createdFrom"
                            (dateSelect)="dateChange($event, 'createdFromDate')"
                            [minDate]="minDate"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d1.toggle()"
                              type="button"
                            >
                              <i class="icon icon-calendar"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <br />
                      <label>{{ appStaticMsgs.common.to }}</label>

                      <div class="input-group date">
                        <input
                          class="form-control"
                          name="dp"
                          [(ngModel)]="createdToDate"
                          ngbDatepicker
                          readonly
                          (click)="d2.toggle()"
                          #d2="ngbDatepicker"
                          formControlName="createdTo"
                          (dateSelect)="dateChange($event, 'createdToDate')"
                          [minDate]="minDate"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="d2.toggle()"
                            type="button"
                          >
                            <i class="icon icon-calendar"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Accordion card -->
              <!-- Accordion card -->
              <div class="card">
                <!-- Card header -->
                <div
                  class="card-header"
                  role="tab"
                  id="headingSix"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapsesix"
                  aria-expanded="true"
                  aria-controls="collapsesix"
                >
                  <div class="row">
                    <div class="col-sm-9">
                      <h5 class="mb-0">
                        {{ appStaticMsgs.styles.modifiedOn }}
                      </h5>
                    </div>
                    <div class="col-sm-1 m-auto">
                      <i class="fa fa-angle-down rotate-icon"></i>
                    </div>
                    <div
                      class="col-sm-1"
                      *ngIf="enableModifToVal || enableModifFromVal"
                    >
                      <button
                        type="button"
                        class="close image-close ml-5"
                        (click)="this.clearfield('modifiedFromChild')"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Card body -->
                <div
                  id="collapsesix"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingSix"
                  data-parent="#accordionEx"
                >
                  <div class="card-body h-auto">
                    <div class="form-group">
                      <label>From</label>
                      <div class="input-group date">
                        <input
                          class="form-control"
                          name="dp"
                          [(ngModel)]="modifiedFromDate"
                          ngbDatepicker
                          readonly
                          (click)="d3.toggle()"
                          #d3="ngbDatepicker"
                          formControlName="modifiedFrom"
                          (dateSelect)="dateChange($event, 'modifiedFromDate')"
                          [minDate]="minDate"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="d3.toggle()"
                            type="button"
                          >
                            <i class="icon icon-calendar"></i>
                          </button>
                        </div>
                      </div>
                      <br />
                      <label>{{ appStaticMsgs.common.to }}</label>

                      <div class="input-group date">
                        <input
                          class="form-control"
                          name="dp"
                          [(ngModel)]="modifiedToDate"
                          ngbDatepicker
                          readonly
                          (click)="d4.toggle()"
                          #d4="ngbDatepicker"
                          formControlName="modifiedTo"
                          (dateSelect)="dateChange($event, 'modifiedToDate')"
                          [minDate]="minDate"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="d4.toggle()"
                            type="button"
                          >
                            <i class="icon icon-calendar"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Accordion card -->
            </div>
            <!-- Accordion wrapper -->
            <!--<div class="add-comment-box">
                        <div class="col-sm-12">
                          <button type="button" class="btn btn-primary adv-search-btn w-100 ">
                            Apply Filters</button>
                          </div>
                      </div>-->
            <div class="add-comment-box">
              <div class="d-flex">
                <div class="col-sm-6">
                  <button
                    type="submit"
                    class="btn btn-primary adv-search-btn w-100"
                  >
                    {{ appStaticMsgs.common.apply }}
                  </button>
                </div>
                <div class="col-sm-6">
                  <button
                    type="button"
                    (click)="clearSearch()"
                    class="btn btn-primary adv-search-btn w-100"
                  >
                    {{ appStaticMsgs.common.clearAll }}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </form>
        <!-- /section -->
      </div>
      <!-- /customizer body inner -->
    </div>
    <!-- /customizer body -->
  </div>
  <!-- Item Details Section End-->
</aside>
<!-- /customizer sidebar -->
<!-- Root -->

<div class="dt-backdrop"></div>
