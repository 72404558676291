<app-loader [showLoader]="showLoader"></app-loader>
<app-loader [showLoader]="showLoader1"></app-loader>

<mat-dialog-content class="mat-typography">
  <div class="dt-page__header">
    <h3 class="dt-page__title">Edit Seams
      <button type="button" class="close" aria-label="Close" (click)="cancelSeamsCreate()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
  </div>

  <form [formGroup]="seamsForm" (ngSubmit)="onSubmit()">
    <div class="form-row">

      <div class="col-lg-4 col-sm-4 mb-3" *ngIf="isIDVisible">
        <label for="id">{{staticMsgs.seamDetail.id}}</label>
        <input type="text" class="form-control" formControlName="sequence" id="sequence" placeholder="Enter ID" value=""
          required="">
      </div>
      <div class="col-lg-4 col-sm-4 mb-3">
        <label for="name">Name<span class="red_icon">*</span></label>
        <input type="text" class="form-control" formControlName="name" id="name" minlength="3" maxlength="50"
          placeholder="Enter Name" value="" required="">
        <div
          *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
          <div *ngIf="this.f.name.errors.required" class="red">
            Name is required.
          </div>
          <div *ngIf="this.f.name.errors.minlength" class="red">
            Minimum 3 characters required.
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-sm-4 mb-3">
        <label for="seam751aNumber">{{staticMsgs.seamDetail.seam751Anum}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" formControlName="seam751aNumber" id="seam751aNumber"
          placeholder="Enter 751A Number" value="" required="">

        <div
          *ngIf="(submitted && this.f.seam751aNumber.errors) || ( this.f.seam751aNumber.invalid && (this.f.seam751aNumber.dirty || this.f.seam751aNumber.touched))">
          <div *ngIf="this.f.seam751aNumber.errors.required" class="red">
            {{staticMsgs.seamDetail.seam751AReq}}
          </div>
          <div *ngIf="this.f.seam751aNumber.errors?.pattern" class="red">
            {{staticMsgs.seamDetail.seam751AInvalid}}
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="isO4916Number">{{staticMsgs.seamDetail.iso4916num}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" formControlName="isO4916Number" id="isO4916Number"
          placeholder="Enter ISO 4916 Number" value="" required="">
        <div
          *ngIf="(submitted && this.f.isO4916Number.errors) || ( this.f.isO4916Number.invalid && (this.f.isO4916Number.dirty || this.f.isO4916Number.touched))">
          <div *ngIf="this.f.isO4916Number.errors.required" class="red">
            {{staticMsgs.seamDetail.iso4916Req}}
          </div>

          <div *ngIf="this.f.isO4916Number.errors?.pattern" class="red">
            {{staticMsgs.seamDetail.iso4916Invalid}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-4  mt-3 mb-3">
        <label for="commonApplication">{{staticMsgs.seamDetail.commonApp}}</label>
        <textarea cols="2" name="description" formControlName="commonApplication" id="commonApplication"
          placeholder="Enter Common Application" class="form-control"></textarea>
        <div
          *ngIf="(submitted && this.f.commonApplication.errors) || ( this.f.commonApplication.invalid && (this.f.commonApplication.dirty || this.f.commonApplication.touched))">
          <div *ngIf="this.f.commonApplication.errors.required" class="red">
            {{staticMsgs.seamDetail.commonAppReq}}
          </div>
          <div
            *ngIf="this.seamsForm.controls.commonApplication.value && this.seamsForm.controls.commonApplication.value.length > 500"
            class="red">
            {{staticMsgs.seamDetail.commonAppAllow}}
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="requirements">{{staticMsgs.seamDetail.requirement}}</label>
        <textarea cols="2" name="description" id="requirements" formControlName="requirements"
          placeholder="Enter Requirements" class="form-control"></textarea>
        <div
          *ngIf="this.seamsForm.controls.requirements.errors && this.seamsForm.controls.requirements.value && this.seamsForm.controls.requirements.value.length > 500"
          class="red">
          {{staticMsgs.seamDetail.reqAllow}}
        </div>
      </div>

      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="status">{{staticMsgs.seamDetail.status}}<span class="red_icon">*</span></label>
        <select title="Select Status" id="status" formControlName="status" class="form-control selectpicker w-100">
          <option *ngFor="let status of status" [value]="status.value">{{ status.label }}</option>
        </select>
        <div
          *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
          <div *ngIf="this.f.status.errors.required" class="red">
            {{staticMsgs.seamDetail.statusReq}}
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 mb-3">
                                <label for="validationDefault02">Image #</label>
                                <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Image #" value="" required="">
                            </div>
                            <div class="col-lg-4 mb-3">
                              <label for="validationDefault02">Name</label>
                              <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Name" value="" required="">
                          </div> -->

    </div>

    <div>

      <div class="dt-entry__header mt-5">
        <!-- Entry Heading -->
        <div class="dt-entry__heading">
          <h3 class="dt-entry__title">{{staticMsgs.seamDetail.uploadSeam}}</h3>
        </div>
        <!-- /entry heading -->
      </div>

      <div class="form-row">
        <div class="col-sm-12 mb-3">
          <label for="validationDefault01">{{staticMsgs.seamDetail.comment}}</label>
          <textarea cols="2" class="form-control" name="comments" formControlName="comments"
            placeholder="Enter Comments"></textarea>
          <div
            *ngIf="this.seamsForm.controls.comments.errors && this.seamsForm.controls.comments.value && this.seamsForm.controls.comments.value.length > 500"
            class="red">
            {{staticMsgs.seamDetail.commentAllow}}
          </div>
        </div>
        <div class="col-xl-6 mt-6" *ngIf="this.f.id.value">
          <div class="mb-5" *ngFor="let editedThumbnail of editedThumbnails; let j=index">
            <img src="{{editedThumbnail.thumbnail}}" width="250px" *ngIf="isValidImageURL(editedThumbnail.name)"
              (click)="openThumbnail(j)">
            <button type="button" class="close image-close" (click)="removeThumbnailSection(editedThumbnail)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="col-xl-12">
          <label> {{staticMsgs.seamDetail.uploadFile}}<span class="red_icon">*</span></label>
          <div class="custom-dropzone" name="upload" [multiple]="false" ngx-dropzone [accept]="'image/*'"
            (change)="onSelect($event,0)">
            <ngx-dropzone-label>
              <div>
                <h5 class="text-light-gray mt-3">
                  {{staticMsgs.seamDetail.dropFile}}</h5>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of thumbNails" [file]="f"
              [removable]="true" (removed)="onRemoveImg(f)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
              </ngx-dropzone-label>
            </ngx-dropzone-image-preview>

          </div>
          <div *ngIf="(submitted && isFileError)">
            <div class="red">
              {{staticMsgs.seamDetail.notEmpty}}
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- <div class="form-row mt-5 float-right">
                          <div class="col-sm-12 ">
                          <button type="button" class="btn btn-primary adv-search-btn">Save</button>
                          </div>
                        </div> -->

    <div class="d-flex justify-content-end mt-4">

      <div class="row mt-3">
        <button type="button" *ngIf="isEditMode" (click)="cancelSeamsCreate()"
          class="btn btn-outline-primary adv-search-btn mr-1">
          {{staticMsgs.seamDetail.cancel}}
        </button>
        <button type="submit" *ngIf="!isEditMode" [disabled]="seamsForm?.invalid || !isThumbnailAdded"
          class="btn btn-primary adv-search-btn">{{staticMsgs.seamDetail.create}}</button>
        <button type="submit" *ngIf="isEditMode" [disabled]="seamsForm?.invalid || !isThumbnailAdded"
          class="btn btn-primary adv-search-btn mr-3">{{staticMsgs.seamDetail.update}}</button>
      </div>
    </div>

  </form>
</mat-dialog-content>