<app-loader [showLoader]="showLoader"></app-loader>
<app-loader [showLoader]="showLoader1"></app-loader>

<mat-dialog-content class="mat-typography">
  <div class="dt-page__header">
    <h3 class="dt-page__title">Edit Image
      <button type="button" class="close" aria-label="Close" (click)="cancelImageCreate()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
  </div>
  <form [formGroup]="imageLibraryForm" (ngSubmit)="onImgSubmit()">
    <div class="form-row">
      <div class="col-lg-4 col-sm-12 mb-3">
        <!-- <label for="validationDefault01">{{staticMsgs.imgDetail.selectClass}}</label>
      <input type="text" class="form-control" id="imageclassification"
        formControlName="imageclassification" placeholder="Classification" /> -->
        <label for="validationDefault01">{{staticMsgs.imgDetail.selectClass}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="imageclassification" formControlName="imageclassification"
          placeholder="Classification" required />

        <div
          *ngIf="(submitted && this.f.imageclassification.errors) || ( this.f.imageclassification.invalid && (this.f.imageclassification.dirty || this.f.imageclassification.touched))">
          <div *ngIf="this.f.imageclassification.errors.required" class="red">
            {{staticMsgs.imgDetail.classReq}}
          </div>
        </div>
        <!--<div
*ngIf="(submitted && this.f.imageclassification.errors) || ( this.f.imageclassification.invalid && (this.f.imageclassification.dirty || this.f.imageclassification.touched))">
<div *ngIf="this.f.imageclassification.errors.required"
class="red">
Classification is required.
</div>
</div>-->
      </div>

      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="validationDefault02">{{staticMsgs.imgDetail.name}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter Name" value=""
          required="" (keypress)="characterOnly($event)" />
        <div
          *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
          <div *ngIf="this.f.name.errors?.required" class="red">
            {{staticMsgs.imgDetail.nameReq}}
          </div>
          <div *ngIf="this.f.name.errors?.pattern" class="red">
            {{staticMsgs.imgDetail.nameInvalid}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="validationDefault02">{{staticMsgs.imgDetail.status}}<span class="red_icon">*</span></label>
        <select title="Select Status" id="docstatus" formControlName="docstatus"
          class="form-control selectpicker w-100">
          <!--<option *ngFor="let status of status" [value]="status.value">{{ status.label }}</option>-->
          <!-- <option value="" [disabled]="true" selected>Select Status
</option> -->
          <option value="Active">{{staticMsgs.imgDetail.active}}</option>
          <option value="Inactive">{{staticMsgs.imgDetail.inactive}}</option>
        </select>

        <div
          *ngIf="(submitted && this.f.docstatus.errors) || ( this.f.docstatus.invalid && (this.f.docstatus.dirty || this.f.docstatus.touched))">
          <div *ngIf="this.f.docstatus.errors.required" class="red">
            {{staticMsgs.imgDetail.statusReq}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 mb-3 imageNo" *ngIf="imageclassificationParent === 'Images'">
        <!--- <label for="validationDefault02">Image #</label>-->
        <input type="hidden" class="form-control" id="sequence" formControlName="sequence" placeholder="Enter Image #"
          value="" />
        <input type="hidden" id="baseclassification" formControlName="baseclassification"
          [(ngModel)]="imageclassificationParent" />

      </div>

      <div class="col-lg-4 col-sm-12 mb-3" *ngIf="imageclassificationParent === 'Documents'">
        <!--<label for="validationDefault02">Document #</label>-->
        <input type="hidden" class="form-control" id="sequence" formControlName="sequence"
          placeholder="Enter Document #" value="" />
        <input type="hidden" id="baseclassification" formControlName="baseclassification"
          [(ngModel)]="imageclassificationParent" />
      </div>
    </div>
    <div class="form-row">
      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="validationDefault01">{{staticMsgs.imgDetail.description}}</label>
        <textarea cols="2" class="form-control" id="description" formControlName="description"
          placeholder="Enter Description"></textarea>
        <!--<div  *ngIf="(submitted && this.f.description.errors) || ( this.f.description.invalid && (this.f.description.dirty || this.f.description.touched))">
<div *ngIf="this.f.description.errors.required" class="red">
Description is required.
</div>
</div>-->
      </div>
    </div>

    <div *ngIf="imageclassificationParent === 'Images'">
      <div class="dt-entry__header mt-5">
        <!-- Entry Heading -->
        <div class="dt-entry__heading">
          <h3 class="dt-entry__title">{{staticMsgs.imgDetail.img}}<span class="red_icon">*</span></h3>
        </div>
        <!-- /entry heading -->
      </div>

      <div class="form-row">
        <div class="col-sm-12 mb-3">
          <label for="validationDefault01">{{staticMsgs.imgDetail.comment}}</label>
          <textarea cols="2" class="form-control" id="notes" formControlName="notes"
            placeholder="Enter Comments"></textarea>
        </div>
      </div>



      <div class="form-row">
        <div class="col-xl-12">
          <div class="custom-dropzone" id="" formControlName="" ngx-dropzone [accept]="'image/*'"
            (change)="onSelect($event)">
            <ngx-dropzone-label>
              <div>
                <h5 class="text-light-gray mt-3">{{staticMsgs.imgDetail.dropFile}}</h5>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData" [file]="f"
              [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>
                {{ f.name }}
                <!--({{ f.type }})--->
              </ngx-dropzone-label>
            </ngx-dropzone-image-preview>

          </div>
          <div class="red" *ngIf="isFileError && this.submitted==true">
            {{staticMsgs.imgDetail.imageisrequired}}
          </div>
        </div>
      </div>

      <div class="col-xl-6 mt-6" *ngIf="imageLibraryForm.get('docid').value">
        <div class="mb-5" *ngFor="let img of editedFileData; let i=index">
          <ng-container *ngIf="img.thumbnail">
            <img
              *ngIf="img.fileType===('.jpg') || img.fileType===('.jpeg') || img.fileType===('.tiff') || img.fileType===('.png') || img.fileType===('.gif') || img.fileType===('.bmp')"
              [src]="(img.thumbnail !=null)?img.thumbnail: documentImage" width="250px" (click)="open(i)"
              title="{{img.name}}" />
            <img
              *ngIf="img.fileType!==('.jpg') && img.fileType!==('.jpeg') && img.fileType!==('.tiff') && img.fileType!==('.png') && img.fileType!==('.gif') && img.fileType!==('.bmp')"
              src="../../../../assets/images/doccard/document.png" width="250px" (click)="downloadDoc(img)"
              title="{{img.name}}" />
          </ng-container>
          <button type="button" class="close image-close" (click)="removeImgSection(img.id)">
            <span aria-hidden="true">×</span>
          </button>
          <!-- <input type="hidden" class="form-control" formControlName="filedelete"
          [(ngModel)]="imgId" /> -->
        </div>
      </div>
    </div>
    <div *ngIf="imageclassificationValue === 'Lab Reports'">
      <div class="dt-entry__header mt-5">
        <!-- Entry Heading -->
        <div class="dt-entry__heading">
          <h3 class="dt-entry__title">{{staticMsgs.imgDetail.labReport}}</h3>
        </div>
        <!-- /entry heading -->
      </div>
    </div>
    <div class="form-row" *ngIf="imageclassificationValue === 'Lab Reports'">
      <div class="col-sm-4 mb-3">
        <label for="validationDefault01">{{staticMsgs.imgDetail.selectReport}}</label>
        <select id="reportStatus" formControlName="reportStatus" class="form-control selectpicker w-100">
          <!--<option *ngFor="let status of reportstatus" [value]="status.value">{{ status.label }}</option>-->
          <option value="" [disabled]="true" selected>{{staticMsgs.imgDetail.selectReport}}
          </option>
          <option value="Pass">{{staticMsgs.imgDetail.pass}}</option>
          <option value="Fail">{{staticMsgs.imgDetail.fail}}</option>
        </select>

        <!--<div  *ngIf="(submitted && this.f.reportStatus.errors) || ( this.f.reportStatus.invalid && (this.f.reportStatus.dirty || this.f.reportStatus.touched))">
<div *ngIf="this.f.reportStatus.errors.required" class="red">
ReportStatus is required.
</div>
</div>-->
      </div>

      <div class="col-sm-4 mb-3">
        <label for="validationDefault01">{{staticMsgs.imgDetail.labLoc}}</label>
        <input type="text" class="form-control" id="labLocation" formControlName="labLocation" placeholder=""
          value="" />

        <!--<div  *ngIf="(submitted && this.f.labLocation.errors) || ( this.f.labLocation.invalid && (this.f.labLocation.dirty || this.f.labLocation.touched))">
<div *ngIf="this.f.labLocation.errors.required" class="red">
    LabLocation is required.
</div>
</div>-->
      </div>

      <!---<div class="col-sm-4 mb-3">
      <label for="validationDefault02">Select Type<span
                      class="red_icon">*</span></label>
      <select  title="Select Type"  id="type" formControlName="type" class="form-control w-100">
        <option *ngFor="let status of reportstatus" [value]="status.value">{{ status.label }}</option>
      </select>
      <div  *ngIf="(submitted && this.f.type.errors) || ( this.f.type.invalid && (this.f.type.dirty || this.f.type.touched))">
          <div *ngIf="this.f.type.errors.required" class="red">
              Type is required.
          </div>
      </div>
  </div>-->
    </div>

    <div *ngIf="imageclassificationParent === 'Documents'">
      <div class="dt-entry__header mt-5">
        <!-- Entry Heading -->
        <div class="dt-entry__heading">
          <h3 class="dt-entry__title">{{staticMsgs.imgDetail.uploadDoc}}<span class="red_icon">*</span></h3>
        </div>
        <!-- /entry heading -->
      </div>

      <div class="form-row">
        <div class="col-sm-12 mb-3">
          <label for="validationDefault01">{{staticMsgs.imgDetail.comment}}</label>
          <textarea cols="2" class="form-control" id="notes" formControlName="notes"
            placeholder="Enter Comments"></textarea>
        </div>
      </div>



      <div class="form-row">
        <div class="col-xl-12">
          <div class="custom-dropzone" id="docupload" ngx-dropzone (change)="onSelect($event)">
            <ngx-dropzone-label>
              <div>
                <h5 class="text-light-gray mt-3">{{staticMsgs.imgDetail.dropFile}}</h5>
              </div>
            </ngx-dropzone-label>
            <!--<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData" [file]="f" [removable]="true" (removed)="onRemove(f)">
<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>-->
            <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData" [file]="f"
              [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>
                {{ f.name }}
                <!--  ({{ f.type }}) -->
              </ngx-dropzone-label>
            </ngx-dropzone-preview>

          </div>
          <div class="red" *ngIf="isFileError && this.submitted==true">
            {{staticMsgs.imgDetail.documentisrequired}}
          </div>
        </div>
      </div>
      <div class="form-row">
        
      <div class="col-xl-6 mt-6" *ngIf="imageLibraryForm.get('docid').value">
        <div class="mb-5" *ngFor="let img of editedFileData; let i=index">
          <ng-container *ngIf="img.thumbnail">
            <img
              *ngIf="img.fileType===('.jpg') || img.fileType===('.jpeg') || img.fileType===('.tiff') || img.fileType===('.png') || img.fileType===('.gif') || img.fileType===('.bmp')"
              [src]="(img.thumbnail !=null)?img.thumbnail: documentImage" width="250px" (click)="open(i)"
              title="{{img.name}}" />
            <img
              *ngIf="img.fileType!==('.jpg') && img.fileType!==('.jpeg') && img.fileType!==('.tiff') && img.fileType!==('.png') && img.fileType!==('.gif') && img.fileType!==('.bmp')"
              src="../../../../assets/images/doccard/document.png" width="250px" title="{{img.name}}"
              (click)="downloadDoc(img)" />
            <button type="button" class="close image-close" (click)="removeDocSection(img.id)">
              <span aria-hidden="true">×</span>
            </button>
          </ng-container>
        </div>
        <input type="hidden" class="form-control" formControlName="filedelete" [(ngModel)]="imgId" />
      </div>
      </div>
    </div>
  
  
   <div class="d-flex justify-content-end mt-4">
    <input type="text" class="form-control" [hidden]="true" id="docid" formControlName="docid"
      placeholder="Document Id" />
    <div class="row mt-3">
      <button type="button" *ngIf="isEditMode" (click)="cancelImageCreate()"
        class="btn btn-outline-primary adv-search-btn mr-3">
        {{staticMsgs.imgDetail.cancel}}
      </button>
      <button type="submit" *ngIf="!isEditMode" class="btn btn-primary adv-search-btn"
        [disabled]="imageLibraryForm?.invalid || !isThumbnailAdded">{{staticMsgs.imgDetail.create}}</button>
      <button type="submit" *ngIf="isEditMode" class="btn btn-primary adv-search-btn mr-3"
        [disabled]="imageLibraryForm?.invalid || !isThumbnailAdded">{{staticMsgs.imgDetail.update}}</button>
    </div>
  </div>
</form>
</mat-dialog-content>