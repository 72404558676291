<app-loader [showLoader]="showLoader"></app-loader>
<app-loader [showLoader]="showLoader1"></app-loader>
<div class="dt-page__header">
  <button class="close" mat-button (click)="onNoclick()">X</button>
  <h3 class="dt-page__title">Edit Supplier</h3>
</div>
<form [formGroup]="supplierLibraryForm" class="form_content" (ngSubmit)="onSubmit()">
  <div class="form-row">
    <div class="col-lg-4 col-sm-12">
      <label for="classification">{{staticMsgs.supplierDetails.SelectClassification}}<span
          class="red_icon">*</span></label>
      <input type="text" class="form-control" id="classification" formControlName="classification"
        required />
      <div
        *ngIf="(submitted && this.f.classification.errors) || ( this.f.classification.invalid && (this.f.classification.dirty || this.f.classification.touched))">
        <div *ngIf="this.f.classification.errors.required" class="red">
          {{staticMsgs.supplierDetails.Classification+staticMsgs.common.required}}
        </div>
      </div>
    </div>


  </div>

  <div *ngIf="agent">

    <div class="dt-entry__header mt-5">
      <!-- Entry Heading -->
      <div class="dt-entry__heading">
        <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Agent'">
          {{staticMsgs.supplierDetails.Agent}}</h3>
        <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Factory'">
          {{staticMsgs.supplierDetails.Factory}}</h3>
        <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Raw Material Supplier'">
          {{staticMsgs.supplierDetails.RawMaterialSupplier}}</h3>
      </div>
      <!-- /entry heading -->
    </div>

    <div class="form-row mt-5">

      <!--<div class="col-lg-4 mb-3">
                <label for="validationDefault02">ID</label>
                <input type="text" class="form-control" id="validationDefault02" placeholder="Enter ID" value="" >
            </div>-->
      <div class="col-lg-4 mb-3">
        <label for="name">{{staticMsgs.supplierDetails.Name}}<span
                class="red_icon">*</span></label>
        <input type="text" class="form-control" id="name" minlength="3"
           maxlength="50" formControlName="name" placeholder="Enter Name"
            required (keypress)="characterOnly($event)" />
        <div
            *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
            <div *ngIf="this.f.name.errors.required" class="red">
             Name is required.
            </div>
            <div *ngIf="this.f.name.errors.minlength" class="red">
              {{staticMsgs.common.minimum3charactersrequired}}
           </div>
        </div>
    </div>
      <div class="col-sm-4 mb-3">
        <label for="phone_number_code">{{staticMsgs.supplierDetails.PhoneNo}}<span
            class="red_icon">*</span></label>
        <div class="form-row">
          <div class="col-sm-5">
            <!--<select [multiple]=false title="" id="" formControlName="" class="form-control selectpicker w-100" >
                    <option *ngFor="let number of number" [value]="number.value">{{ number.label }}</option>
                    </select>-->
            <select formControlName="phone_number_code" id="phone_number_code"
              class="form-control selectpicker w-100" data-live-search="true">
              <option *ngFor="let countrycode of countrycodes" [value]="countrycode.value">
                {{ countrycode.name }}</option>

            </select>
          </div>
          <div class="col-sm-7">
            <input type="number" class="form-control no-arrow" id="phoneNo" formControlName="phoneNo"
              placeholder="Enter Phone Number " (keypress)="numberOnly($event)">
            <!-- <div
                  *ngIf="(submitted && this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors) || ( this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].invalid && (this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].dirty || this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].touched))">
                  <div
                    *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors.required"
                    class="red">
                    {{staticMsgs.supplierDetails.ContactNumber+staticMsgs.common.required}}
                  </div>
                  <div
                  *ngIf="(submitted && this.f.phoneNo.errors) || ( this.f.phoneNo.invalid && (this.f.phoneNo.dirty || this.f.phoneNo.touched))">
                  <div *ngIf="this.f.phoneNo.errors.pattern" class="red">
                    {{staticMsgs.supplierDetails.validMobileNumber}}
                    </div>
                  </div>
                </div> -->
            <div
              *ngIf="(submitted && this.f.phoneNo.errors) || ( this.f.phoneNo.invalid && (this.f.phoneNo.dirty || this.f.phoneNo.touched))">
              <div *ngIf="this.f.phoneNo.errors.required" class="red">
                {{staticMsgs.supplierDetails.PhoneNo+staticMsgs.common.required}}
              </div>
              <div *ngIf="this.f.phoneNo.errors?.pattern" class="red">
                {{staticMsgs.supplierDetails.validPhoneNumber}}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="col-lg-4 mb-3">
        <label for="email">{{staticMsgs.supplierDetails.Email}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="email" formControlName="email"
          placeholder="Enter Email">
        <div
          *ngIf="(submitted && this.f.email.errors) || ( this.f.email.invalid && (this.f.email.dirty || this.f.email.touched))">
          <div *ngIf="this.f.email.errors.required" class="red">
            {{staticMsgs.supplierDetails.Email+staticMsgs.common.required}}
          </div>
        </div>
      </div>

      <span class="col-lg-12"
        *ngIf="supplierclassificationValue === 'Factory' || supplierclassificationValue === 'Raw Material Supplier'">
        <span class="mr-12 ml-12">
          <div class="form-row">
            <!--<div class="col-lg-4 mb-3" >
                    <label for="SupplierChainPartner">Supplier Chain Partner</label>
                    <select title="Select Chain Partner" id="SupplierChainPartner" formControlName="SupplierChainPartner" class="form-control selectpicker w-100">
                        <--<option *ngFor="let chain of chain" [value]="chain.value">{{ chain.label }}</option>-->
            <!-- <option value="" [disabled]="true" selected>Select Chain Partner
                    </option> --
                    <option value="Agent">Agent</option>
                    <option value="Factory">Factory</option>
                    <option value="Good supplier">Finished Good Supplier</option>
                    <option value="Raw material">Raw Material Supplier</option>
                    </select>
                </div>-->

            <div class="col-lg-4 mb-3">
              <label for="productType">{{staticMsgs.supplierDetails.ProductType}}</label>
              <select title="Select Product Type" id="productType" formControlName="productType"
                class="form-control selectpicker w-100">
                <option value="" [disabled]="true" selected>
                  {{staticMsgs.supplierDetails.SelectProductType}}
                <option *ngFor="let pOptions of productTypeOptions" [value]="pOptions.id">{{
                  pOptions.value }}</option>
              </select>
            </div>


            <div class="col-lg-4 mb-3">
              <label for="speciality">{{staticMsgs.supplierDetails.Speciality}}</label>
              <input type="text" class="form-control" formControlName="speciality"
                placeholder="Enter Speciality" (keypress)="characterOnly($event)">
              <!-- <select  title="Select Speciality" formControlName="speciality" class="form-control selectpicker w-100">
                    <option value="Knits">Knits</option>
                    <option value="Wovan">Wovan</option>
                    <option value="Trims">Trims</option>
                    <option value="Packaging">Packaging</option>
                    <option value="Leather">Leather</option>
                </select> -->
            </div>

            <div class="col-lg-4 mb-3 mt-2" *ngIf="supplierclassificationValue=='Factory'">
              <label for="moq">{{staticMsgs.supplierDetails.mOQ}}<span class="red_icon">*</span></label>
              <input type="text" class="form-control" id="moq" formControlName="moq"
                placeholder="Enter MOQ" (focusout)="addDecimal('moq')" (keypress)="numberOnly($event)" required>
              <div
                *ngIf="(submitted && this.f['moq'].errors) || ( this.f['moq'].invalid && (this.f['moq'].dirty || this.f['moq'].touched))">
                <div *ngIf="this.f['moq'].errors.required" class="red">
                  {{staticMsgs.supplierDetails.mOQ+staticMsgs.common.required}}
                </div>
                <div *ngIf="this.f['moq'].errors?.pattern" class="red">
                  MOQ is invalid.
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-3 mt-2">
              <label for="moq">{{staticMsgs.supplierDetails.mOQUOM}}</label>
              <select id="moqUOM" title="Select Capacity (UOM)" formControlName="moqUOM"
                class="form-control selectpicker w-100">
                <option value="Units">Units</option>
                <option value="Sq.Yds">Sq.Yds</option>
                <option value="Feet">Feet</option>
                <option value="Inches">Inches</option>
                <option value="Sq Ft">Sq Ft</option>
                <option value="Lb">Lb</option>
                <option value="Kg">Kg</option>
                <option value="Oz">Oz</option>
                <option value="Gm">Gm</option>
              </select>
              <!-- <div
                          *ngIf="(submitted && this.f.moqUOM.errors) || ( this.f.moqUOM.invalid && (this.f.moqUOM.dirty || this.f.moqUOM.touched))">
                          <div *ngIf="this.f.moqUOM.errors.required" class="red">
                              MOQ (UOM)is required.
                          </div>
                      </div> -->
            </div>

            <div class="col-lg-4 mb-3 mt-2" *ngIf="supplierclassificationValue=='Factory'">
              <label for="leadTime">{{staticMsgs.supplierDetails.LeadTime}}<span
                  class="red_icon">*</span></label>
              <input type="text" class="form-control" id="leadTime" formControlName="leadTime"
                placeholder="Enter Lead Time" (keypress)="numberOnly($event)" required>
              <div
                *ngIf="(submitted && this.f.leadTime.errors) || ( this.f.leadTime.invalid && (this.f.leadTime.dirty || this.f.leadTime.touched))">
                <div *ngIf="this.f.leadTime.errors.required" class="red">
                  {{staticMsgs.supplierDetails.LeadTime+staticMsgs.common.required}}
                </div>
                <div *ngIf="this.f.leadTime.errors?.pattern" class="red">
                  Lead Time is invalid.
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-3 mt-2">
              <label for="capacity">{{staticMsgs.supplierDetails.Capacity}}</label>
              <input type="number" class="form-control" id="capacity" formControlName="capacity"
                placeholder="Enter Capacity"  min="0"
                >
              <div
                *ngIf="(submitted && this.f.capacity.errors) || ( this.f.capacity.invalid && (this.f.capacity.dirty || this.f.capacity.touched))">
                <div *ngIf="this.f.capacity.errors?.pattern" class="red">
                  Capacity is invalid.
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-3 mt-2">
              <label for="capacityUOM">{{staticMsgs.supplierDetails.CapacityUOM}}</label>
              <select id="capacityUOM" title="Select Capacity (UOM)" formControlName="capacityUOM"
                class="form-control selectpicker w-100">
                <option value="Units">Units</option>
                <option value="Sq.Yds">Sq.Yds</option>
                <option value="Feet">Feet</option>
                <option value="Inches">Inches</option>
                <option value="Sq Ft">Sq Ft</option>
                <option value="Lb">Lb</option>
                <option value="Kg">Kg</option>
                <option value="Oz">Oz</option>
                <option value="Gm">Gm</option>
              </select>
            </div>
          </div>
        </span>
      </span>
    </div>

    <div class="mt-3" *ngIf="supplierclassificationValue === 'Factory'">
      <div class="row">
        <div class="col-sm-7">
          <div cdkDropListGroup class="row">
            <div class="col-sm-5 example-container">
              <label for="todo"> {{staticMsgs.supplierDetails.MachineType}}</label>
              <div cdkDropList class="example-list" [cdkDropListData]="todo"
                (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>
              </div>
            </div>
            <i class="icon icon-shuffle"></i>
            <div class="col-sm-5 example-container">
              <label for="done"></label>

              <div cdkDropList class="example-list mt-1" [cdkDropListData]="done"
                (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
              </div>
            </div>
          </div>
          <!-- <div class="form-row mt-2">
            <div class="col-sm-12 ">
            <button type="button" class="btn btn-primary adv-search-btn " (click)="addMachine()">Confirm Machine Type</button>
            </div>
            </div> -->
        </div>
        <div class="col-sm-5">
          <div class="form-row">
            <div class="col-xl-6 mb-5" *ngFor="let item of done; let i=index">

              <label>{{item}}</label>
              <input type="number" class="form-control" id="machineTypes{{i}}"
                formControlName="machineTypes{{i}}" placeholder="Enter Qty">
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card mt-5" id="accordion-example">
      <div class="card-header card-header-acc" data-toggle="collapse" data-target="#collapse-one"
        aria-expanded="true" aria-controls="collapse-one" id="headingOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button">
            {{staticMsgs.supplierDetails.AuditStatus}}
          </button>
          <i class="fa fa-angle-down down" aria-hidden="true"></i>
        </h5>
      </div>

      <div id="collapse-one" class="collapse show" aria-labelledby="headingOne"
        data-parent="#accordion-example">
        <div class="card-body-acc">
          <div class="form-row mt-5">

            <div class="col-lg-4 mb-3">
              <label for="lastSocialAudit"> {{staticMsgs.supplierDetails.LastAudit}}<span
                  class="red_icon">*</span></label>
              <div class="input-group date">
                <input id="lastSocialAudit" class="form-control" placeholder="yyyy-mm-dd" name="dp"
                  formControlName="lastSocialAudit" ngbDatepicker #date="ngbDatepicker"
                  (dateSelect)="dateChange($event,'lastSocialAuditDate')" [minDate]="minDate"
                  [maxDate]="maxDate">
                <!-- <input class="form-control" name="dp" [(ngModel)]="lastSocialAuditDate" ngbDatepicker readonly (click)="d.toggle()"
                          #d="ngbDatepicker" formControlName="lastSocialAudit" (dateSelect)="dateChange($event,'maxDate')" [minDate]="minDate"[maxDate]="maxDate"> -->
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar"
                    [ngClass]="{'disableDiv': showLoader1}" (click)="date.toggle()" type="button"><i
                      class="icon icon-calendar"></i></button>
                </div><br>
              </div>
              <div
                *ngIf="(submitted && this.f.lastSocialAudit.errors) || ( this.f.lastSocialAudit.invalid && (this.f.lastSocialAudit.dirty || this.f.lastSocialAudit.touched))">
                <div *ngIf="this.f.lastSocialAudit.errors.required" class="red">
                  {{staticMsgs.supplierDetails.LastSocialAudit+staticMsgs.common.required}}
                </div>
              </div>
              <!-- <div class="input-group date" id="date-time-picker-1"
                    data-target-input="nearest">
                    <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-1" #lastSocialAudit formControlName="lastSocialAudit" (change)="setDate($event)"/>
                        <div class="input-group-append" data-target="#date-time-picker-1"
                            data-toggle="datetimepicker">
                            <div class="input-group-text"><i class="icon icon-calendar"></i>
                            </div>
                        </div>

                    </div> -->
              <!--<div
                        *ngIf="(submitted && this.f.lastSocialAudit.errors) || ( this.f.lastSocialAudit.invalid && (this.f.lastSocialAudit.dirty || this.f.lastSocialAudit.touched))">
                        <div *ngIf="this.f.lastSocialAudit.errors.required" class="red">
                            LastSocialAudit is required.
                        </div>
                    </div>-->
            </div>

            <div class="col-lg-4 mb-3">
              <label for="auditStatus"> {{staticMsgs.supplierDetails.AuditStatus}}</label>
              <select id="auditStatus" title="Select Status" formControlName="auditStatus"
                class="form-control selectpicker w-100">
                <!-- <option *ngFor="let auditstatus of auditstatus" [value]="auditstatus.value">{{ auditstatus.label }}</option>-->
                <!-- <option value="" [disabled]="true" selected>Select
                                    Status
                                </option> -->
                <option value="Passed"> {{staticMsgs.supplierDetails.Passed}}</option>
                <option value="Failed"> {{staticMsgs.supplierDetails.Failed}}</option>
                <option value="Under Review"> {{staticMsgs.supplierDetails.UnderReview}}</option>
              </select>
              <!-- <div
                    *ngIf="(submitted && this.f.auditStatus.errors) || ( this.f.auditStatus.invalid && (this.f.auditStatus.dirty || this.f.auditStatus.touched))">
                    <div *ngIf="this.f.auditStatus.errors.required" class="red">
                          Audit Status is required.
                    </div>
                </div> -->
            </div>

            <div class="col-lg-4 mb-3">
              <label for="description"> {{staticMsgs.supplierDetails.Comments}}</label>
              <textarea cols="2" name="description" id="description" formControlName="description"
                placeholder="Enter Comments" class="form-control"></textarea>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div formGroupName="Contactss">
      <div class="card mt-4" id="accordion-example" formGroupName="contactOrder1">
        <div class="card-header card-header-acc" id="headingtwo">
          <h5 class="mb-0">
            <button class="btn btn-link" type="button">
              {{staticMsgs.supplierDetails.PrimaryContact}}
            </button>
            <i class="fa fa-angle-down down" data-toggle="collapse" data-target="#collapse-two"
              aria-expanded="true" aria-controls="collapse-one" aria-hidden="true"></i>
            <button class="btn btn-primary btn-esm float-right mr-3 mt-1" (click)="secondContact()"
              *ngIf="secondcontact" type="button">
              + {{staticMsgs.supplierDetails.SecondaryContact}}
            </button>
          </h5>

        </div>

        <div id="collapse-two" class="collapse show" aria-labelledby="headingtwo"
          data-parent="#accordion-example">
          <div class="card-body-acc">
            <div class="form-row mt-5">
              <div class="form-row" [hidden]="true">
                <input type="text" class="form-control" id="" formControlName="contactOrder">
              </div>
              <div class="col-lg-4 mb-3">
                <label for="fullName"> {{staticMsgs.supplierDetails.Name}}<span class="red_icon">*</span>
                </label>
                <input type="text" class="form-control" id="" formControlName="fullName"
                  placeholder="Enter Name" (keypress)="characterOnly($event)" required>
                <div
                  *ngIf="(submitted && this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].errors) || ( this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].invalid && (this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].dirty || this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].touched))">
                  <div
                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].errors.required"
                    class="red">
                    {{staticMsgs.supplierDetails.Name+staticMsgs.common.required}}
                  </div>
                  <div
                    *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].errors?.pattern"
                    class="red">
                    Name is invalid.
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-3">
                <label for="officeNo"> {{staticMsgs.supplierDetails.OfficeNo}}</label>
                <input type="text" class="form-control" id="officeNo" formControlName="officeNo"
                  placeholder="Enter Office No" (keypress)="numberOnly($event)">
                  <div
                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['officeNo'].errors?.pattern"
                  class="red">
                  officeNo is invalid.
                </div>
              </div>

              <div class="col-lg-4 mb-3">
                <label for="ext"> {{staticMsgs.supplierDetails.Ext}}</label>
                <input type="text" class="form-control" id="ext" formControlName="ext"
                  placeholder="Enter Ext (+123)" (keypress)="extNumberOnly($event)">
                <div
                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['ext'].errors?.pattern"
                  class="red">
                  ext is invalid.
                </div>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="fax"> {{staticMsgs.supplierDetails.Fax}}</label>
                <input type="text" class="form-control" id="fax" formControlName="fax"
                  placeholder="Enter Fax" (keypress)="faxNumberOnly($event)">
                  <div
                      *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').dirty || supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').touched))">
                        <div
                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').errors.pattern"
                            class="red">
                            {{staticMsgs.supplierDetails.Fax+staticMsgs.common.invalid}}
                        </div>
                   </div>
              </div>

              <div class="col-sm-4 mt-3 mb-3">
                <label for="contactNo">{{staticMsgs.supplierDetails.PhoneNo}}<span
                    class="red_icon">*</span> </label>
                <input type="text" class="form-control" id="" formControlName="contactNo"
                  placeholder="Enter Phone Number " maxlength="10" (keypress)="numberOnly($event)">
                <div
                  *ngIf="(submitted && this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors) || ( this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].invalid && (this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].dirty || this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].touched))">
                  <div
                    *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors.required"
                    class="red">
                    {{staticMsgs.supplierDetails.PhoneNo+staticMsgs.common.required}}
                  </div>
                  <div
                    *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors?.pattern"
                    class="red">
                    {{staticMsgs.supplierDetails.validPhoneNumber}}
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="contactEmail">Email {{staticMsgs.supplierDetails.SecondaryContact}} <span
                    class="red_icon">*</span></label>
                <input type="text" class="form-control" id="" formControlName="contactEmail"
                  placeholder="Enter Email" required>
                <div
                  *ngIf="(submitted && this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].errors) || ( this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].invalid && (this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].dirty || this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].touched))">
                  <div
                    *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].errors.required"
                    class="red">
                    {{staticMsgs.supplierDetails.Email+staticMsgs.common.required}}
                  </div>
                  <div
                    *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].errors?.pattern"
                    class="red">
                    Email is invalid.
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="country"> {{staticMsgs.supplierDetails.Country}}</label>
                <select [title]="staticMsgs.supplierDetails.SelectCountry" id="country" formControlName="country"
                  class="form-control selectpicker w-100" data-live-search="true"
                  (change)="changeCountry($event,'')">
                  <!-- <option value="" [disabled]="true" selected>
                    {{staticMsgs.supplierDetails.SelectCountry}}
                  </option> -->
                  <option *ngFor="let country of country" [value]="country.country">{{ country.country }}
                  </option>

                </select>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="state"> {{staticMsgs.supplierDetails.State}}</label>
                <!-- <input type="text" class="form-control" id="" formControlName="state" placeholder="Enter State"> -->
                <select [title]="staticMsgs.supplierDetails.SelectState" id="state" formControlName="state"
                  class="form-control selectpicker w-100" data-live-search="true">
                  <option *ngFor="let states of allStates" [value]="states.value">{{ states.label }}
                  </option>
                </select>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="city"> {{staticMsgs.supplierDetails.City}}</label>
                <input type="text" class="form-control" id="" formControlName="city"
                  placeholder="Enter City">
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="zipCode"> {{staticMsgs.supplierDetails.ZipCode}}</label>
                <input type="text" class="form-control" id="" formControlName="zipCode"
                  placeholder="Enter Zip Code" (keypress)="numberOnly($event)">
                <div
                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['zipCode'].errors?.pattern"
                  class="red">
                  zipCode is invalid.
                </div>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="address"> {{staticMsgs.supplierDetails.Address}}</label>
                <textarea cols="2" formControlName="address" placeholder="Enter Address"
                  class="form-control"></textarea>
              </div>
              <input type="hidden" class="form-control" id="" formControlName="id">


            </div>
          </div>
        </div>


      </div>

      <div class="card mt-4" id="accordion-example" *ngIf="secondcontact2" formGroupName="contactOrder2">
        <div class="card-header card-header-acc" id="headingtwo">
          <h5 class="mb-0">
            <button class="btn btn-link" type="button">
              {{staticMsgs.supplierDetails.SecondaryContact}}
            </button>
            <i class="fa fa-angle-down down" data-toggle="collapse" data-target="#collapse-three"
              aria-expanded="true" aria-controls="collapse-three" aria-hidden="true"></i>
            <button class="btn btn-primary btn-esm float-right mr-3 mt-1" (click)="secondContact2()"
              type="button">
              - {{staticMsgs.supplierDetails.Remove}}
            </button>
          </h5>

        </div>

        <div id="collapse-three" class="collapsed" aria-labelledby="headingthree"
          data-parent="#accordion-example">
          <div class="card-body-acc">
            <div class="form-row mt-5">
              <div class="form-row" [hidden]="true">
                <input type="text" class="form-control" value="" id="" formControlName="contactOrder">
              </div>
              <div class="col-lg-4 mb-3">
                <label for="fullName">{{staticMsgs.supplierDetails.Name}}</label>
                <input type="text" class="form-control" formControlName="fullName" id=""
                  placeholder="Enter Name">
              </div>

              <div class="col-lg-4 mb-3">
                <label for="officeNo">{{staticMsgs.supplierDetails.OfficeNo}}</label>
                <input type="text" class="form-control" formControlName="officeNo" id=""
                  placeholder="Enter Office No">
              </div>

              <div class="col-lg-4 mb-3">
                <label for="ext">{{staticMsgs.supplierDetails.Ext}}</label>
                <input type="text" class="form-control" formControlName="ext" id=""
                  placeholder="Enter Ext (+123)" (keypress)="extNumberOnly($event)">
                  <div
                  *ngIf="this.f['Contactss']['controls']['contactOrder2'].controls['ext'].errors?.pattern"
                  class="red">
                  ext is invalid.
                </div>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="fax">{{staticMsgs.supplierDetails.Fax}}</label>
                <input type="text" class="form-control" id="" formControlName="fax"
                  placeholder="Enter Fax" (keypress)="faxNumberOnly($event)">
              </div>

              <div class="col-sm-4 mt-3 mb-3">
                <label for="contactNo">{{staticMsgs.supplierDetails.PhoneNo}}</label>

                <input type="text" class="form-control" id="" formControlName="contactNo"
                  placeholder="Enter Phone Number " maxlength="10">
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="contactEmail">{{staticMsgs.supplierDetails.Email}}</label>
                <input type="text" class="form-control" formControlName="contactEmail" id=""
                  placeholder="Enter Email">
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="country">{{staticMsgs.supplierDetails.Country}}</label>
                <select title="Select Country" id="country" formControlName="country"
                  class="form-control selectpicker w-100" data-live-search="true"
                  (change)="changeCountry($event,'')">
                  <option value="" [disabled]="true" selected>{{staticMsgs.supplierDetails.SelectCountry}}
                  </option>
                  <option *ngFor="let country of country" [value]="country.country">{{ country.country }}
                  </option>
                </select>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="state">{{staticMsgs.supplierDetails.State}}</label>
                <select title="Select State" id="state" formControlName="state"
                  class="form-control selectpicker w-100" data-live-search="true">
                  <option value="" [disabled]="true" selected>{{staticMsgs.supplierDetails.SelectState}}
                  </option>
                  <option *ngFor="let states of allStates" [value]="states.value">{{ states.label }}
                  </option>
                </select>
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="city">{{staticMsgs.supplierDetails.City}}</label>
                <input type="text" class="form-control" id="" formControlName="city"
                  placeholder="Enter City">
              </div>
              <div class="col-lg-4 mt-3 mb-3">
                <label for="zipCode">{{staticMsgs.supplierDetails.ZipCode}}</label>
                <input type="text" class="form-control" id="" formControlName="zipCode"
                  placeholder="Enter Zip Code">
              </div>

              <div class="col-lg-4 mt-3 mb-3">
                <label for="address">{{staticMsgs.supplierDetails.Address}}</label>
                <textarea cols="2" formControlName="address" placeholder="Enter Description"
                  class="form-control"></textarea>
              </div>
              <input type="hidden" class="form-control" id="contactid" formControlName="id">
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>


  <div class="form-row mt-5 float-right">
    <input type="text" class="form-control" [hidden]="true" id="supplierid" formControlName="supplierid"
      placeholder="Supplier Id" />

    <button type="submit" *ngIf="!isEditMode" [disabled]="supplierLibraryForm?.invalid"
      class="btn btn-primary adv-search-btn">{{staticMsgs.supplierDetails.Create}}</button>
      <button type="submit" *ngIf="isEditMode"
      class="btn btn-primary adv-search-btn" [disabled]="supplierLibraryForm?.invalid">{{staticMsgs.supplierDetails.Update}}</button>
    <!-- <button type="submit" *ngIf="isEditMode"
      class="btn btn-primary adv-search-btn">{{staticMsgs.supplierDetails.Update}}</button> -->
      <button type="submit" *ngIf="isEditMode"
      class="btn btn-primary adv-search-btn" (click)="onNoclick()">Cancel</button>

  </div>


</form>
