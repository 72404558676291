import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DataStateChangeEvent, PageChangeEvent, GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';
import { DataService } from '../../../services/data.service';
import { process, State, CompositeFilterDescriptor, filterBy, orderBy, distinct } from '@progress/kendo-data-query';
import { UserService } from '../../../helpers/user.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from '../../../helpers/storage.service';
import { SharedService } from '../../../services/shared.service';
import Swal from 'sweetalert2';
import { Favourite } from '../../../models/color';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { LogService } from '../../../shared/log.service';
import { TagInputModule } from 'ngx-chips';
import { ExportExcelService } from '../../../services/exportexcel.service';
import { identifierModuleUrl } from '@angular/compiler';
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { select, Store } from '@ngrx/store';
import * as styleReducer from "../../../reducers/styles.reducer";
import * as styleActionTypes from "../../../actions/styles.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take } from 'rxjs/operators';
import { interval, Observable, of, Subscription, Observer } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { ToastService } from '../../../services/toast.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import { FilterService } from "@progress/kendo-angular-grid";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { Options } from '../style-edit/options';
import { StyleEditComponent } from '../style-edit/style-edit.component';
import { MatDialog } from '@angular/material/dialog';
import Amplify, { Auth, API } from 'aws-amplify';
import { log } from 'console';
const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);
@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.css']
})

export class StylesComponent implements OnInit, AfterViewInit {

  //public filter: CompositeFilterDescriptor;
  @ViewChild('selectOrgpicker', { static: false }) selectOrgpicker: any;
  public filter: CompositeFilterDescriptor = { logic: "or", filters: [] };
  LoggedinuserDetails: any;
  gridContent = true;
  tableContent = false;
  classApplied = false;
  copyCard: Observable<any[]>;
  styleData = []
  editedSharedData = [];
  listV = false;
  public filterQuery = "";
  public SearchData = "";
  editedStyleData: any;
  cardV = true;
  cards: Observable<any[]>;
  paginationStartcount = 0;
  paginationEndcount = 10;
  public startCount = 0;
  public endCount = 10;
  public startCountList = 0;
  public endCountList = 10;
  public startEndCount = "";
  public isPageChanged = false;
  public isScrollDown = false;
  public isGetAllRecords = false;
  public totalRecordCount: any;
  public optionslist: Options[] = <any>[];
  loading: boolean;
  stars: number[] = [1, 2, 3, 4, 5];
  sustainabilityRating: any;
  selectedGreenMaterials: number;
  selectedLocallySourced: number;
  selectedLeanProduction: number;
  selectedFairTrade: number;
  checkStatusData = false;
  msg: string;
  thumbnail: string = "../../../../assets/images/style-default-thumbnail.png";
  //thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  userThumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";
  showLoader: boolean;
  stylesDataForm: FormGroup;
  stylesData = [];
  idArray: string[];
  clickedRowItem: any;
  dbstylesData = [];
  selected: any = [];
  settings = {};
  isEditMode = false;
  styleViewMode = "Created";
  searchedKeyword: string;
  advForm: FormGroup;
  enableCreatToVal: string[];
  enableCreatedByVal: string[];
  enableCreatFromVal: string[];
  enableModifFromVal: string[];
  enableModifToVal: string[];
  enablestyleNameVal: string[];
  enablestyleNumberVal: string[];
  enablegenderVal: string[];
  enablefabricVal: string[];
  btnstatus: boolean = false;
  btnstatus1: boolean = true;
  mystyle: boolean = true;
  sharedstyle: boolean = false;
  HeadingText = "Card View";
  exportData: any[];
  fieldArray: any;
  isedit: Array<boolean> = [];
  newfieldArray: any;
  itemList = [];
  submitted = false;
  isshared: boolean;
  enablecategoryVal: string[];
  enableStatusVal: string[];
  isResetMode = false;
  notFound: boolean;
  notfoundImg: string;
  showNote = false;
  appStaticMsgs: any;
  mySelection: string[] = [];
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild('createdFrom') createdFromChild: any;
  @ViewChild('createdTo') createdToChild: any;
  @ViewChild('modifiedFrom') modifiedFromChild: any;
  @ViewChild('modifiedTo') modifiedToChild: any;
  statuslist = ["Active", "Inactive"];
  cateorylist = ["Casual wear", "Promotional", "Designer", "Sports wear", "Lounge wear", "Uniform", "Other"];
  genderlist = ["Male", "Female"];
  fabriclist = ["Knit", "Woven"]
  originalstylesData = [];
  @ViewChild('grid') grid: GridComponent;
  createdFromDate: any;
  createdToDate: any;
  modifiedFromDate: any;
  modifiedToDate: any;
  minDate = { year: 1985, month: 1, day: 1 };
  createdStartDate: string;
  modifiedEndDate: string;
  modifiedStartDate: string;
  createdEndDate: string;
  gridData: any;
  isDataLoading = false;
  filteredData: any[];
  log: any[];
  filteredXcelData: any[];
  sharedCopyCard: any[] = [];
  sharedCards: any[] = [];
  isMyStyles = true;
  sharedNotFound: boolean;
  isSharedGetAllRecords = false;
  isSharedScrollDown = false;
  totalSharedRecordCount: any;
  sharedMsg: string;
  sharedEndCount = 10;
  sharedStartCount = 0;
  sharedStartEndCount = "";
  checkSharedStatusData = false;
  sharedEndListCount = 10;
  sharedStartListCount = 0;
  sharedGridData: any[] = [];
  sharedStartEndListCount: string;
  startEndListCount: string;
  dropdownList = [];
  selectedItemsnew = [];
  dropdownSettings = {};
  selectedItems = [];
  userPermission: any = 'view';
  modifiedData: any = [];
  sharedUsers = [];
  modalReference: any;
  hoverIndex: number = -1;

  state: State = {
    skip: 0,
    take: 10
  };
  file: any;
  cData: any;
  modifiedRow: any = [];
  docOutClick = false;

  options = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];


  classification = [
    { value: 'agent', label: 'Agent' },
    { value: 'factory', label: 'Factory' },
    { value: 'rawmaterial', label: 'Raw Material Supplier' },
  ];

  categoryList = [
    { value: 'Casual wear', label: 'Casual wear' },
    { value: 'Promotional', label: 'Promotional' },
    { value: 'Designer', label: 'Designer' },
    { value: 'Sports wear', label: 'Sports wear' },
    { value: 'Lounge wear', label: 'Lounge wear' },
    { value: 'uniform', label: 'Uniform' },
    { value: 'other', label: 'Other' }
  ];

  gender = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];
  userOrgInfo: any=[];
  selectedOrg: any = [];;
  agegroup = [
    { value: 'infants&toddlers', label: 'Infants & Toddlers (0 & 4 Years)' },
    { value: 'boys', label: 'Boys (5 to 10 Years)' },
    { value: 'girls', label: 'Girls (5 to 10 Years)' },
    { value: 'girls10', label: 'Girls (10 to 19 Years)' },
    { value: 'men', label: 'Men (29 to 49 Years)' },
    { value: 'women', label: 'Women (29 to 49 Years)' },
    { value: 'menabove', label: 'Men (Above 50 Years)' },
    { value: 'womenabove', label: 'Women (Above 50 Years)' },
  ];

  size = [
    { value: 'xs', label: 'XS' },
    { value: 's', label: 'S' },
    { value: 'm', label: 'M' },
    { value: 'l', label: 'L' },
    { value: 'xl', label: 'XL' },
    { value: '2XL', label: '2XL' },
  ];

  fabric = [
    { value: 'knit', label: 'Knit' },
    { value: 'woven', label: 'Woven' },
  ];
  onlineUsers: any;
  showLoader1: boolean;
  get f1() { return this.advForm.controls; }
  get f() { return this.stylesDataForm.controls; }
  get fa() { return this.f.formArray as FormArray; }

  dropdownList1: any = [
    { id: 1, itemName: 'Can View', selected: true },
    { id: 2, itemName: 'Can Edit' }
  ]

  seltemplates = [
    { value: 'design', label: 'Design' },
    { value: 'technical', label: 'Technical' },
    { value: 'pready', label: 'Production ready' },
  ];

  fac(data) {
    if (this.styleViewMode === 'Shared') {
      return this.fa.controls[this.sharedGridData.indexOf(data)] as FormGroup;
    } else {
      return this.fa.controls[this.stylesData.indexOf(data)] as FormGroup;
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    let cDat = [];
    if (this.selected.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.filteredData;
    }
    this.state = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total; //process(this.filteredData, this.state);
    }
    else {
      var gridView = {
        data: this.styleViewMode === 'Shared' ? this.sharedGridData : this.stylesData,
        total: this.styleViewMode === 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total; // process(this.stylesData, this.state);
    }
    //this.gridData = process(this.colorData, this.state);
  }

  public allData(): ExcelExportData {
    if (this.selected.length > 0) {
      this.cData = this.selected;
    } else {
      this.cData = this.filteredData;
    }
    this.filteredData = filterBy((this.styleViewMode === 'Shared') ? this.sharedGridData : this.stylesData, this.state.filter);
    this.state.take = this.cData.length;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.cData = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort) this.cData = orderBy(this.cData, this.state.sort);
    const result: ExcelExportData = {
      data: this.cData //process(this.filteredData, this.state).data,
    };
    this.mySelection = [];
    this.selected = [];
    //this.state.take = 5;
    return result;
  }

  onPageChange(e: PageChangeEvent): void {
    this.isPageChanged = true;
    this.state.skip = e.skip;
    this.state.take = e.take;
    if (this.styleViewMode === 'Shared') {
      this.sharedStartListCount = e.skip;
      this.sharedEndListCount = e.skip + e.take;
      this.loadSharedListData();
    } else {
      this.startCountList = e.skip;
      this.endCountList = e.skip + e.take;
      this.LoadDataList();
    }
  }



  constructor(
    private sharedService: SharedService,
    private storageservice: StorageService,
    private exportExcel: ExportExcelService,
    private dataService: DataService,
    public userservice: UserService,
    private formBuilder: FormBuilder,
    private aesdecryptservice: AESDecryptService,
    private router: Router,
    private logger: LogService,
    private store: Store<any>,
    public toastService: ToastService,
    private renderer: Renderer2,
    private modalService: NgbModal,
    public filterService: FilterService,
    public dialog: MatDialog) {


    this.allData = this.allData.bind(this);
  }

  private docClickSubscription: Subscription = new Subscription();

  /* To set properties initialization on load page
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  async ngOnInit() {
    console.log('pattern',appStaticMsg)
    this.appStaticMsgs = appStaticMsg?.data[0];
    this.LoggedinuserDetails = await Auth.currentAuthenticatedUser();
    console.log(this.LoggedinuserDetails.attributes,'uuuduuuuuu');
    TagInputModule.withDefaults({
      tagInput: {
        placeholder: 'Enter keywords to search',
        // add here other default values for tag-input
      }
    });
    // this.stylesDataForm = this.formBuilder.group({
    //   formArray: new FormArray([])
    // });
    window.addEventListener('scroll', this.scrollEvent, true);
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());

    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    this.storageservice.storeEditedStyleId("");
    $(document).ready(function () {
      $('.selectpicker').selectpicker('val', 'Active');
    });
    this.selected = 'Active';
    this.sustainabilityRating = [];

    this.advForm = this.formBuilder.group({
      category: [''],
      styleName: [''],
      styleNumber: [''],
      gender: [''],
      fabric: [''],
      // state:[''],
      createdBy: [''],
      //classification: [''],
      status: [''],
      createdFrom: [''],
      createdTo: [''],
      modifiedFrom: [''],
      modifiedTo: ['']
    });

    this.dropdownSettings = {
      primaryKey: 'id',
      labelKey: 'itemName',
      showCheckbox: false,
      singleSelection: false,
      escapeToClose: true,
      text: this.appStaticMsgs.styledetails.selectCollaborators,
      selectAllText: '',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };

    this.fieldArray = [
      {
        "edit": false,
        "view": true,
        "id": "",
        "sharedTo": "",
        "user": ""
      }
    ];
    this.getOnlineUsers();
    this.stylesDataForm = this.formBuilder.group({
      formArray: new FormArray([])
    });
    this.LoadData();
    this.getAllsharedaccess();
    this.sharedService.sendClickEvent();
    this.docClickSubscription.add(this.renderer.listen('document', 'click', this.onDocumentClick.bind(this)));
    this.getOrgInfoBasedOnUser();
  }
  getOrgInfoBasedOnUser() {
    this.dataService.getOrganisationBasedOnUser(this.LoggedinuserDetails.attributes.email).subscribe((orginfo)=>{
      if (
        orginfo['body']['item1'] != null &&
        orginfo['body']['item1'].length > 0 &&
        !orginfo['body']['item2'].toLowerCase().includes('error')
      ){
        const response = orginfo['body']['item1'];
        const uniqueObjectsMap = new Map<string, any>();
        response.forEach(obj => {
          const key = `${obj.org}-${obj.userID}-${obj.isDefaultOrg}`;
          uniqueObjectsMap.set(key, obj);
        });

        this.userOrgInfo = Array.from(uniqueObjectsMap.values());
        const defaultOrg = response.find(item => item.isDefaultOrg);
        this.selectedOrg = defaultOrg?.org || (response[0] || 'NA');
        console.log('defaulitOrg',this.selectedOrg);

      }
    });

  }
  ngAfterViewInit(): void {
    this.initializeSelectPicker();
  }
  private initializeSelectPicker(): void {
    if (this.selectOrgpicker) {
      $(this.selectOrgpicker.nativeElement).selectpicker('val', this.selectedOrg);
    }
  }

  public categories: any[] = distinct(this.stylesData, "value").map(
    (item: any) => item.categoryList
  );

  private onDocumentClick(e: Event): void {
    this.docOutClick = true;
    //console.log('onDocumentClick-1',this.clickedRowItem)
    if (
      this.stylesDataForm &&
      this.stylesDataForm.valid &&
      !matches(e.target, '#productsGrid tbody *, #productsGrid .k-grid-toolbar .k-button')
      && this.clickedRowItem
    ) {

      this.saveCurrent();
      //console.log('onDocumentClick',this.stylesDataForm.value.formArray[this.clickedRowItem.rowIndex],this.clickedRowItem.rowIndex)
    }
  }
  // image Hover Event
  StyleImageHover(i: number) {
    this.hoverIndex = i;

  }

  // Method to open the Role selection Modal pop-up
  Modalopen(content) {
    let ngbModalOptions: NgbModalOptions = {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };
    this.modalReference = this.modalService.open(content, ngbModalOptions);


  }

  // Method to close the Role selection Pop-Up
  continue() {
    this.modalReference.close();
  }

  /* On scroll down to get next 10 records to display card view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */

  scrollEvent = (event: any): void => {
    if (this.gridContent && this.router.url.includes('styles') && !this.tableContent) {
      if (!this.isDataLoading) {
        if (event.target.scrollTop >= (event.target.scrollHeight - event.target.clientHeight - 2)) {
          if (this.styleViewMode == 'Created') {
            this.isDataLoading = true;
            if (!this.isGetAllRecords) {
              this.isScrollDown = false;
              this.startCount = this.startCount + this.paginationEndcount;
              this.endCount = this.endCount + this.paginationEndcount;
              this.LoadData();
            }
          } else {
            this.isDataLoading = true;
            if (!this.isSharedGetAllRecords) {
              this.isScrollDown = false;
              this.sharedStartCount = this.sharedStartCount + this.paginationEndcount;
              this.sharedEndCount = this.sharedEndCount + this.paginationEndcount;
              this.loadSharedData();
            }
          }

        }
      }
    }
  }

  /* To calculate average rating of each style and dispay on card view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  calculateTotalRating(sustainabilityRating) {
    var totalRatingcnt = sustainabilityRating.greenMaterials +
      sustainabilityRating.locallySourced +
      sustainabilityRating.leanProduction +
      sustainabilityRating.fairTrade;
    sustainabilityRating.TotalRating = (5 / 20) * totalRatingcnt;
  }

  /* To save Green Materials rating for style
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  countStarGreenMaterials(rate, rating) {
    if (this.styleViewMode == 'Created') {
      this.saveSustainabilityRating(rate, rating, "GreenMaterials");
    }
  }

  /* To save Locally Sourced rating for style
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  countStarLocallySourced(rate, rating) {
    if (this.styleViewMode == 'Created') {

      this.saveSustainabilityRating(rate, rating, "LocallySourced");
    }

  }

  /* To save Lean Production rating for style
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  countStarLeanProduction(rate, rating) {
    if (this.styleViewMode == 'Created') {

      this.saveSustainabilityRating(rate, rating, "LeanProduction");
    }

  }

  /* To save Fair Trade rating for style
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  countStarFairTrade(rate, rating) {
    if (this.styleViewMode == 'Created') {

      this.saveSustainabilityRating(rate, rating, "FairTrade");
    }
  }


  /* To save Sustainability style Rating in database
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  saveSustainabilityRating(rate, rating, type) {
    let data = {
      styleID: rate.styleId,
      greenMaterials: rate.greenMaterials,
      locallySourced: rate.locallySourced,
      leanProduction: rate.leanProduction,
      fairTrade: rate.fairTrade
    };

    if (type == "GreenMaterials") {
      data.greenMaterials = rating;
    }
    if (type == "LocallySourced") {
      data.locallySourced = rating;
    }
    if (type == "LeanProduction") {
      data.leanProduction = rating;
    }
    if (type == "FairTrade") {
      data.fairTrade = rating;
    }

    this.showLoader = true;
    this.dataService.AddUpdateStyleSustainability(data).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          if (type == "GreenMaterials") {
            rate.greenMaterials = rating;
          }
          if (type == "LocallySourced") {
            rate.locallySourced = rating;
          }
          if (type == "LeanProduction") {
            rate.leanProduction = rating;
          }
          if (type == "FairTrade") {
            rate.fairTrade = rating;
          }
          this.calculateTotalRating(rate);
          this.logger.info(this.appStaticMsgs.styles.ratingsavedsuccessfully, "Style Details");
          // this.success(this.appStaticMsgs.styles.ratingsavedsuccessfully);
          this.toastService.showSuccess(this.appStaticMsgs.styles.ratingsavedsuccessfully);
          this.showLoader = false;

        } else {
          // this.error(response.body['item1']);
          this.toastService.showError(response.body['item1']);
          this.showLoader = false;
        }
      }
    }, err => {
      this.logger.error(err.error.message, "Styles");
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
      } else if (err.error.message === "The incoming token has expired") {
        this.toastService.showError("The token being used has expired. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
      }
      else {
        this.logger.error(err.error.message, "Styles");
        // this.error('Service Unresponsive');
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.showLoader = false;
    });
  }


  /* NO USE
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  advanceSearchOpen() {
    this.classApplied = true;
    this.getClassificationData()
  }

  /* NO USE
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  advanceSearchClose() {
    this.classApplied = false;
  }

  /* To open new page for create style
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  createStyle() {
    this.dataService.AddClientLog();
    this.router.navigate(['/styledetails']);
  }

  /*To get Classification Data for advanced search
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  getClassificationData() {
    var self = this;
    $(document).ready(function () {
      $("#colorclassification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          //  self.colorclassificationValue = value["text"];
        }
      });
    });
  }

  /*To clear field for advanced search
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  clearfield(name) {
    switch (name) {
      case "createdFromChild": {
        this.createdStartDate = "";
        this.createdEndDate = "";
        this.createdFromDate = "";
        this.createdToDate = "";
        this.enableCreatFromVal = null;
        this.enableCreatToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "modifiedFromChild": {
        this.modifiedFromDate = "";
        this.modifiedToDate = "";
        this.modifiedStartDate = "";
        this.modifiedEndDate = "";
        this.enableModifFromVal = null;
        this.enableModifToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "styleName": { this.f1.styleName.patchValue(""); this.enablestyleNameVal = null; this.checkAllFieldVal(); break; }
      case "createdBy": { this.f1.createdBy.patchValue(""); this.enableCreatedByVal = null; this.checkAllFieldVal(); break; }
      case "styleNumber": { this.f1.styleNumber.patchValue(""); this.enablestyleNumberVal = null; this.checkAllFieldVal(); break; }
      case "gender": { this.f1.gender.patchValue(""); $("#genderlist").val('default').selectpicker("refresh"); this.enablegenderVal = null; this.checkAllFieldVal(); break; }
      case "fabric": { this.f1.fabric.patchValue(""); $("#fabriclist").val('default').selectpicker("refresh"); this.enablefabricVal = null; this.checkAllFieldVal(); break; }
      case "category": { this.f1.category.patchValue(""); $("#cateorylist").val('default').selectpicker("refresh"); this.enablecategoryVal = null; this.checkAllFieldVal(); break; }
      case "status": { this.f1.status.patchValue(""); $("#statuslist").val('default').selectpicker("refresh"); this.enableStatusVal = null; this.checkAllFieldVal(); break; }
    }
  }

  /*To check all field for advanced search
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  checkAllFieldVal() {
    if ((this.f1.category.value == "") && (this.createdFromChild.nativeElement.value == "") && (this.modifiedFromChild.nativeElement.value == "") && (this.f1.styleName.value == "") && (this.f1.createdBy.value == "") && ((this.f1.status.value == "") || (this.f1.status.value == undefined)) && (this.f1.styleNumber.value == "") && (this.f1.gender.value == "") && (this.f1.fabric.value == "")) {
      this.clearSearch()
    }
  }

  /*To check field  val for advanced search
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  checkFieldVal(val: string[], val1: string) {
    switch (val1) {
      case "createdFromChild": { this.enableCreatFromVal = val; break; }
      case "createdToChild": { this.enableCreatToVal = val; break; }
      case "modifiedFromChild": { this.enableModifFromVal = val; break; }
      case "modifiedToChild": { this.enableModifToVal = val; break; }
      // case "keyword": { this.enableKeywordVal = val; break; }
      case "createdBy": { this.enableCreatedByVal = val; break; }
      case "styleName": { this.enablestyleNameVal = val; break; }
      case "styleNumber": { this.enablestyleNumberVal = val; break; }
      case "gender": { this.enablegenderVal = val; break; }
      case "fabric": { this.enablefabricVal = val; break; }
      //// case "state": { this.enablestateVal = val; break; }
      case "category": { this.enablecategoryVal = val; break; }
      case "status": { this.enableStatusVal = val; break; }

    }
  }

  /*To check date for advanced search
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  dateChange(date: any, type: any) {
    switch (type) {
      case 'createdFromDate':
        this.createdStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdFromDate && this.createdFromDate !== undefined) {
          this.checkFieldVal(['val'], 'createdFromChild');
        }
        break;
      case 'createdToDate':
        this.createdEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdToDate && this.createdToDate !== undefined) {
          this.checkFieldVal(['val'], 'createdToChild')
        }
        break;
      case 'modifiedFromDate':
        this.modifiedStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedFromDate && this.modifiedFromDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedFromChild')
        }
        break;
      case 'modifiedToDate':
        this.modifiedEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedToDate && this.modifiedToDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedToChild')
        }
        break;
    }
  }

  /*To clear field for advanced search
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  clearFields() {
    this.createdFromDate = "";
    this.createdToDate = "";
    this.modifiedFromDate = "";
    this.modifiedToDate = "";
    ///this.f1.keyword.patchValue("");
    this.f1.createdBy.patchValue("");
    this.f1.status.patchValue("");
    this.f1.styleName.patchValue("");
    this.f1.category.patchValue("");
    this.f1.gender.patchValue("");
    this.f1.fabric.patchValue("");
    this.f1.styleNumber.patchValue("");
    $("#statuslist").val('default').selectpicker("refresh");
    $("#cateorylist").val('default').selectpicker("refresh");
    $("#genderlist").val('default').selectpicker("refresh");
    $("#fabriclist").val('default').selectpicker("refresh");
    this.getClassificationData();
    this.enableCreatFromVal = null;
    this.enableCreatToVal = null;
    this.enableModifFromVal = null;
    this.enableModifToVal = null;
    //this.enableKeywordVal = null;
    this.enablestyleNameVal = null;
    this.enablestyleNumberVal = null;
    this.enablegenderVal = null;
    this.enablefabricVal = null;
    ///this.enablestateVal = null;
    this.enablecategoryVal = null;
    this.enableStatusVal = null;
    this.enableCreatedByVal = null;

  }

  /*To clear Search for advanced search
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  clearSearch() {
    this.clearFields();
    this.LoadData();
    this.isResetMode = false;
  }

  /* To search data for grid list view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  // searchListData(val: string) {
  //   if (this.styleViewMode === 'Shared') {
  //     if (val !== "") {
  //       let arr = [];
  //       this.filterQuery = val;
  //       this.filteredData = this.sharedGridData.filter((data) => {
  //         var search = this.filterQuery.toString().toLowerCase();
  //         var values = Object.values(data);
  //         var indicator = false;
  //         values.forEach((vl) => {
  //           if (String(vl).toLowerCase().indexOf(search) > -1) {
  //             indicator = true;
  //             return;
  //           }
  //         });
  //         if (indicator) { return data; }
  //       });
  //     }
  //     else {
  //       this.filteredData = this.sharedGridData;
  //       this.state.take = 10;
  //       //return
  //     }
  //   } else {
  //     if (val !== "") {
  //       let arr = [];
  //       this.filterQuery = val;
  //       this.filteredData = this.stylesData.filter((data) => {
  //         var search = this.filterQuery.toString().toLowerCase();
  //         var values = Object.values(data);
  //         var indicator = false;
  //         values.forEach((vl) => {
  //           if (String(vl).toLowerCase().indexOf(search) > -1) {
  //             indicator = true;
  //             return;
  //           }
  //         });
  //         if (indicator) { return data; }
  //       });
  //     }
  //     else {
  //       this.filteredData = this.stylesData;
  //       this.state.take = this.paginationEndcount;
  //       //return
  //     }
  //   }
  //   if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
  //   //this.colorData = this.filteredData;

  //   var gridView = {
  //     data: this.filteredData,
  //     total: this.styleViewMode == 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
  //   };
  //   this.gridData = gridView; // process(this.filteredData, this.state);
  //   this.filteredXcelData = filterBy(this.filteredData, this.state.filter);

  // }
  searchListData(val: string) {
    
    if (this.styleViewMode === 'Shared') {
      console.log('shared styles')
      if (val !== "") {
        let arr = [];
        this.filterQuery = val;
        // this.filteredData = this.sharedGridData.filter((data) => {
        //   var search = this.filterQuery.toString().toLowerCase();
        //   var values = Object.values(data);
        //   var indicator = false;
        //   values.forEach((vl) => {
        //     if (String(vl).toLowerCase().indexOf(search) > -1) {
        //       indicator = true;
        //       return;
        //     }
        //   });
        //   if (indicator) { return data; }
        // });
        if(this.filterQuery.length > 0){
          console.log('stylesData', this.stylesData);
          this.filteredData = this.stylesData.filter((data) =>
          data.styleNumber?.toLowerCase().includes(this.filterQuery.toLowerCase()) || 
          data.styleName?.toLowerCase().includes(this.filterQuery.toLowerCase()) ||  
          data.category?.toLowerCase().includes(this.filterQuery.toLowerCase()) || 
          data.status?.toLowerCase().includes(this.filterQuery.toLowerCase()) 
          )
        }
        
      }
      else {
        this.filteredData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      
      if (val !== "") {
        console.log('my styles')
        let arr = [];
        this.filterQuery = val;
        console.log('filterQuery', this.filterQuery)
        // this.filteredData = this.stylesData.filter((data) => {
        //   console.log('stylesData', this.stylesData)
        //   var search = this.filterQuery.toString().toLowerCase();
        //   console.log('search', search)
        //   var values = Object.values(data);
        //   var indicator = false;
        //   values.forEach((vl) => {
        //     if (String(vl).toLowerCase().indexOf(search) > -1) {
        //       indicator = true;
        //       return;
        //     }
        //   });
        //   if (indicator) { return data; }
        // });
        console.log('stylesData', this.stylesData);
        this.filteredData = this.stylesData.filter((data) =>
        data.styleNumber?.toLowerCase().includes(this.filterQuery.toLowerCase()) || 
        data.styleName?.toLowerCase().includes(this.filterQuery.toLowerCase()) ||  
        data.category?.toLowerCase().includes(this.filterQuery.toLowerCase()) || 
        data.status?.toLowerCase().includes(this.filterQuery.toLowerCase()) 
        )
      }
      else {
        this.filteredData = this.stylesData;
        this.state.take = this.paginationEndcount;
        //return
      }
    }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.filteredData,
      total: this.styleViewMode == 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
    };
    this.gridData = gridView; // process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(this.filteredData, this.state.filter);

  }

  /* To display error msg as sweet alert
   Written by : Kiran Nagargoje
   Date: 07-Oct-2021 */
  error(message: string) {
    this.toastService.showError(message);
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Oops...',
    //   text: message
    // })
  }

  /* To display success msg as sweet alert
   Written by : Kiran Nagargoje
   Date: 07-Oct-2021 */
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
    this.LoadDataList();
  }

  /* To save Added to Favourites and Removed from Favourites of styles
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  AddToFavorite(e) {
    this.showLoader = true;

    let selected = e.target.id.replace("checkbox", "");
    if (selected != '' && e.target.checked == true) {
      let data: Favourite[] = [];
      data.push(new Favourite(selected));
      this.dataService.bulkAddFavourites(data, "Styles").subscribe(response => {

        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.toastService.showSuccess('Added to favorite')
            //this.success("Added to Favourites Successfully!");
          }
          else {
            //console.log(response)
            //alert(response.body['item2'])
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.showLoader = false;
          }
        }
      }, err => {
        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.toastService.showError("The token being used has expired. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        }
        else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      });
    }
    else if (selected != '' && e.target.checked == false) {
      let data: string[] = [];
      data.push(selected);
      this.dataService.bulkDeleteFavourites(data, "Styles").subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            // this.success(this.appStaticMsgs.styles.removedfromFavourites);
            this.toastService.showSuccess('Removed from favorite');
          }
          else {
            //console.log(response)
            //alert(response.body['item2'])
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.showLoader = false;
          }
        }
      }, err => {
        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.toastService.showError("The token being used has expired. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        }
        else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      });
    }
  }

  /* To display styles data in card view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  cardView() {
    this.gridContent = true;
    this.tableContent = false;
    this.isMyStyles = true;
    this.showNote = false;
    this.HeadingText = "Card View";
    this.listV = false;
    this.cardV = true;
    this.btnstatus = false;
    this.btnstatus1 = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', 'Active');
    }, 10);
    if (this.styleViewMode === 'Shared') {
      this.isSharedGetAllRecords = false;
      this.sharedCards = [];
      this.sharedStartCount = this.paginationStartcount;
      this.sharedEndCount = this.paginationEndcount;
      this.loadSharedData();
    } else {
      this.isGetAllRecords = false;
      this.startCount = this.paginationStartcount;
      this.endCount = this.paginationEndcount;
      this.LoadData();
    }
  }

  /* To display styles data in List view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  tableView() {
    this.gridContent = false;
    this.tableContent = true;
    this.listV = true;
    this.isMyStyles = false;
    this.HeadingText = "List View";
    this.cardV = false;
    this.btnstatus = true;
    this.btnstatus1 = false;
    if (this.styleViewMode === 'Shared') {
      if (this.isPageChanged) {
        this.sharedStartListCount = this.state.skip;
        this.sharedEndListCount = this.state.skip + this.state.take;
      }
      else {
        this.sharedStartListCount = this.paginationStartcount;
        this.sharedEndListCount = this.paginationEndcount;
      }
    } else {
      if (this.isPageChanged) {
        this.startCountList = this.state.skip;
        this.endCountList = this.state.skip + this.state.take;
      }
      else {
        this.startCountList = this.paginationStartcount;
        this.endCountList = this.paginationEndcount;
      }
    }
    this.styleViewMode == 'Shared' ? this.loadSharedListData() : this.LoadDataList();
    //this.gridData = this.gridData.slice(0, 10);
  }


  /* To display mystyles data or sharedwithme data of styles
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  styleView(styles) {
    this.notFound = false;
    this.isMyStyles = (styles === 'Created') ? true : false
    this.showLoader = true;
    this.styleViewMode = styles;
    this.isEditMode = false;
    if (this.tableContent) {
      this.closeAllRows();
    }
    if (this.styleViewMode == 'Shared') {
      this.mystyle = false;
      this.sharedstyle = true;
    }
    else {
      this.mystyle = true;
      this.sharedstyle = false;
    }

    if (this.gridContent) {
      this.HeadingText = "Card View";
      this.styleViewMode == 'Shared' ? this.loadSharedData() : this.LoadData();
    }
    else {
      this.HeadingText = "List View";
      this.styleViewMode == 'Shared' ? this.loadSharedListData() : this.LoadDataList();
    }
    this.showLoader = false;
  }


  /*  If any error from api response
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.toastService.showError("The token being used has expired. Please login again.")
      this.sessionTimedOut();
      this.userservice.logout();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
      this.showLoader = false;
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.appStaticMsgs.unResponsive.error);

    }
    this.showLoader = false;
  }

  decryptData(x) {
    x.styleNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleNumber);
    x.styleName = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleName);
    x.category = this.aesdecryptservice.decryptData(this.dataService.Key, x.category);
    x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
    if (x.createdBy != null) {
      x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
      x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
      x.CreatedbyName = x.createdBy.firstName;
      x.createdBylastName = x.createdBy.lastName;
      x.createdBy.imageUrl = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.imageUrl);
    }
    if (x.styleImage) {
      x.styleImage.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleImage.thumbnail);
    }
  }

  loadSharedListData() {
    this.sharedStartEndListCount = this.sharedStartListCount + "-" + this.sharedEndListCount;
    this.dataService.getAllStyles(this.sharedStartEndListCount, this.styleViewMode,this.selectedOrg).subscribe(response => {
      if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
        this.isDataLoading = false;
        this.logger.info(this.appStaticMsgs.styles.allstylelistloadedsuccessfully, "Style");
        this.showLoader = false;
        this.sharedGridData = [];
        response['body']['item1'].forEach(x => {
          this.decryptData(x);
          this.sharedGridData.push(x);
        });
        this.totalSharedRecordCount = response['body']['item3'];
        if (this.sharedGridData.length > 0) {
          this.sharedNotFound = false;
        } else {
          this.sharedNotFound = true;
        }
        this.sharedGridData.forEach((x, i) => {
          this.fa.push(this.updateFormGroup(x));
        });
        var gridView = {
          data: this.sharedGridData,
          total: this.totalSharedRecordCount,
        };
        this.gridData = gridView;
        this.loading = false;
      } else {
        this.isSharedScrollDown = true;
        if (response['body']['item2'] !== 'Success') {
          // this.error("Failed:" + response['body']['item1'])
          this.toastService.showError(response['body']['item1']);
          this.logger.error(response['body']['item1'], "Style");
        }
        this.showLoader = false;
        this.sharedNotFound = true;
      }
    }, err => {
      // this.getError(err);
      this.toastService.showError(err);
      this.logger.error(err.error.message, "Style");
      this.showLoader = false;
    })
  }

  loadSharedData() {
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', this.selected);
    }, 10);
    if (!this.isSharedGetAllRecords) {
      this.sharedStartEndCount = this.sharedStartCount + "-" + this.sharedEndCount;
      this.dataService.getAllStyles(this.sharedStartEndCount, this.styleViewMode,this.selectedOrg).subscribe(response => {
        if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
          this.isDataLoading = false;
          this.logger.info(this.appStaticMsgs.styles.allstylelistloadedsuccessfully, "Style");
          this.showLoader = false;
          response['body']['item1'].forEach(x => {
            this.decryptData(x)
            this.sharedCards.push(x);
            this.sharedCards = this.sharedCards.filter((item, index) => this.sharedCards.indexOf(item) === index)
          });
          console.log('^^^^^^^^^^^',response);
          this.sharedCopyCard = [];
          this.sharedCopyCard = this.sharedCards;
          this.totalSharedRecordCount = response['body']['item3'];
          if (this.sharedCards.length > 0) {
            this.sharedNotFound = false;
          } else {
            this.sharedNotFound = true;
          }
          if (this.sharedCards.length == this.totalSharedRecordCount) {
            this.isSharedGetAllRecords = true;
          }
          else {
            this.isSharedScrollDown = true;
          }
          this.onSelect('Active', 'shared')
        } else {
          this.isSharedScrollDown = true;
          if (response['body']['item2'] !== 'Success') {
            // this.error("Failed:" + response['body']['item1'])
            this.toastService.showError(response['body']['item1']);
            this.logger.error(response['body']['item1'], "Style");
          }
          this.showLoader = false;
          this.sharedNotFound = true;
        }
      }, err => {
        // this.getError(err);
        this.toastService.showError(err);
        this.logger.error(err.error.message, "Style");
        this.showLoader = false;
      })
    }
  }

  /* To getallstyles and display styles for card view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  LoadData() {
    //this.showLoader = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', this.selected);
    }, 10);
    this.startEndCount = this.startCount + "-" + this.endCount;
    this.store.dispatch(new styleActionTypes.LoadStylesLibrary(this.startEndCount, this.styleViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((styleLibrary) => {
      if (styleLibrary.style.error === "") {
        if (styleLibrary.style.message?.item2 === 'Success') {
          console.log('response@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', styleLibrary);
          //this.showLoader = false;
          this.isDataLoading = false;
          this.totalRecordCount = styleLibrary.style.message?.item3;
          this.cards = this.store.pipe(select(styleReducer.getStylesLibrary));
          this.copyCard = this.store.pipe(select(styleReducer.getStylesLibrary));
          this.logger.info(this.appStaticMsgs.styles.allstylelistloadedsuccessfully, "Style");

          this.fa.clear();
          this.stylesData = styleLibrary.style.message?.item1;
          this.dbstylesData = styleLibrary.style.message?.item1;

          if (this.stylesData.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }

          this.sustainabilityRating = [];
          this.stylesData.forEach((x, i) => {
            this.fa.push(this.updateFormGroup(x));
            let sustainabilityRating = {
              styleId: x.id,
              greenMaterials: 0,
              locallySourced: 0,
              leanProduction: 0,
              fairTrade: 0,
              TotalRating: 0
            };
            if (x.sustainability.length > 0) {
              sustainabilityRating.greenMaterials = x.sustainability[0].greenMaterials;
              sustainabilityRating.locallySourced = x.sustainability[0].locallySourced;
              sustainabilityRating.leanProduction = x.sustainability[0].leanProduction;
              sustainabilityRating.fairTrade = x.sustainability[0].fairTrade;
              this.calculateTotalRating(sustainabilityRating);
            }
            this.sustainabilityRating.push(sustainabilityRating);
          });

          if (styleLibrary.style.ids.length == this.totalRecordCount) {
            this.isGetAllRecords = true;
          }
          else {
            this.isScrollDown = true;
          }

          this.onSelect(this.selected, 'mystyle');

        } else {
          //this.showLoader = false;
          this.notFound = true;
          // this.error("Failed:" + styleLibrary.style.message?.item1)
          this.toastService.showError(styleLibrary.style.message?.item1);
          this.logger.error(styleLibrary.style.message?.item1, "Style");
        }
      } else {
        this.isScrollDown = true;
        this.isGetAllRecords = true;
        this.logger.error(styleLibrary.style.error.error.message, "Style");
        // this.getError(styleLibrary.style.error);
        this.toastService.showError(styleLibrary.style.error);
        //this.showLoader = false;
      }
    })
  }

  /* To getallstyles and display styles for list view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  LoadDataList() {
    this.loading = true;
    this.startEndListCount = this.startCountList + "-" + this.endCountList;
    this.store.dispatch(new styleActionTypes.LoadStylesLibrary(this.startEndListCount, this.styleViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((styleLibrary) => {
      if (styleLibrary.style.error === "") {
        if (styleLibrary.style.message?.item2 === 'Success') {
          this.showLoader = false;
          this.notFound = false;
          this.totalRecordCount = styleLibrary.style.message?.item3;
          //this.cards = this.store.pipe(select(styleReducer.getStylesLibrary));
          //this.copyCard = this.store.pipe(select(styleReducer.getStylesLibrary));
          this.logger.info(this.appStaticMsgs.styles.allstylelistloadedsuccessfully, "Style");
          this.fa.clear();
          this.stylesData = styleLibrary.style.message?.item1;
          this.dbstylesData = styleLibrary.style.message?.item1;
          this.stylesData.forEach((x, i) => {
            this.fa.push(this.updateFormGroup(x));
          });

          if (this.stylesData.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          var gridView = {
            data: this.stylesData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView; //process(this.stylesData, this.state);
          this.loading = false;
          this.onSelect('Active', 'mystyle');
        } else {
          this.loading = false;
          this.notFound = true;
          // this.error("Failed:" + styleLibrary.style.message?.item1)
          this.toastService.showError(styleLibrary.style.message?.item1);
          this.logger.error(styleLibrary.style.message?.item1, "Style");
        }
      } else {
        this.logger.error(styleLibrary.style.error.error.message, "Style");
        // this.getError(styleLibrary.style.error);
        this.toastService.showError(styleLibrary.style.error);
        this.loading = false;
      }
    })
  }

  /* To change Active and Inactive mode for sorting of styles in card view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  onSelect(val: any, type: any) {
    if (type === 'mystyle') {
      if (val == "") {
        this.cards = this.copyCard;
      } else {
        this.cards = this.copyCard?.pipe(map(data =>
          data.filter(res => res.status.toLowerCase() == val.toLowerCase())))
      }
      var data = this.stylesData.filter(x => x.status.toLowerCase() == val.toLowerCase())

      if (data.length > 0) {
        this.checkStatusData = false;
      } else {
        this.msg = (val.toLowerCase() === 'active') ? 'No active records available' : 'No inactive records available';
        this.checkStatusData = true;
      }

    } else {
      if (val == "") {
        this.sharedCards = this.sharedCopyCard;
      } else {
        this.sharedCards = this.sharedCopyCard.filter(res => res.status.toLowerCase() == val.toLowerCase())
      }
      if (this.sharedCards.length > 0) {
        this.checkSharedStatusData = false;
      } else {
        this.sharedMsg = (val.toLowerCase() === 'active') ? 'No active shared records available' : 'No inactive shared records available';
        this.checkSharedStatusData = true;
      }

    }
  }

  switchOrg(org:any,type){
    console.log('org',org)
    try{
      this.showLoader = true;
      this.isEditMode = false;
      if (this.tableContent) {
        this.closeAllRows();
      }
      if (this.gridContent) {
        this.HeadingText = "Card View";
        if (this.styleViewMode === 'Shared') {
          this.isSharedGetAllRecords = false;
          this.sharedCards = [];
          this.sharedStartCount = this.paginationStartcount;
          this.sharedEndCount = this.paginationEndcount;
          this.loadSharedData();
        }

      }
      else {
        this.HeadingText = "List View";
        this.styleViewMode == 'Shared' ? this.loadSharedListData() : this.LoadDataList();
      }
      this.showLoader = false;
    }
    catch(e){
      this.showLoader = false;
    }

  }


  /* To set properties for list view data
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  private updateFormGroup(styles: any = {}) {
    // create a new form group containing controls and validators for a product
    return this.formBuilder.group({
      styleName: [styles.styleName],
      //styleNumber: [styles.styleNumber],
      category: [styles.category],
      targetGender: [styles.targetGender],
      ageGroup: [styles.ageGroup],
      sampleSize: [styles.sampleSize],
      sizeRange: [styles.sizeRange],
      createdBy: [styles.createdBy],
      id: [styles.id],
      fabric: [styles.fabric],
      technicalPackage: [styles.technicalPackage],
      status: [styles.status]
    })
  }

  /* To display session expired msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: 'Session Expired!',
      html: 'You Session has Expired Please Login Again..',
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
      }
    })
  }

  /* NO USE
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
    this.filter = filter;
    this.filteredData = filterBy((this.styleViewMode === 'Shared') ? this.sharedGridData : this.stylesData, this.state.filter);
  }

  public distinctPrimitive(fieldName: string): string[] | string[] {
    return distinct(this.stylesData, fieldName).map((item) => item[fieldName]);
  }

  /*To set edit row properties of grid
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  private editGridRows() {
    if (this.styleViewMode === 'Shared') {
      this.filteredData = filterBy(this.sharedGridData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        if (x.isEdit) {
          var styleRow = this.fa.controls[this.sharedGridData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, styleRow);
        }
      });
    } else {
      this.filteredData = filterBy(this.stylesData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {

        if (x.isEdit) {
          var styleRow = this.fa.controls[this.stylesData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, styleRow);
          //console.log('edit',styleRow,rowIndex,x.isEdit);
        }
      });
    }
  }

  /*To set close row properties of grid
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  private closeAllRows() {
    // close all rows to display readonly view of data
    if (this.styleViewMode === 'Shared') {
      // close all rows to display readonly view of data
      this.sharedGridData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    } else {
      this.stylesData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    }
  }

  toggleWithGreeting(tooltip) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open();
    }
  }

  /*On click of edit icon on top of grid to set edit properties of grid for all rows
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  // editClick({ sender, columnIndex, rowIndex, dataItem }: CellClickEvent): void {
  //   if (isEdited || (this.stylesDataForm && !this.stylesDataForm.valid)) {
  //     return;
  //   }
  //   this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
  //   //console.log('editClick',this.clickedRowItem,dataItem)
  //   //this.grid.editRow(this.clickedRowItem.rowIndex, dataItem);
  //   this.grid.editRow(rowIndex, dataItem);
  // }

  //   public editClick({ isEdited, dataItem, rowIndex }: CellClickEvent): void {
  //     this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
  //     this.grid.editRow(rowIndex, this.clickedRowItem.dataItem);
  // }


  /*To enable to edit grid rowOn double click of grid row to set  edit
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  //   editClick({ sender, columnIndex, rowIndex, dataItem }: CellClickEvent): void {
  //     this.saveCurrent();
  //     //console.log('saving previous data',this.clickedRowItem);
  //     this.docOutClick = false;
  //     //this.saveCurrent();

  //     //setTimeout(()=>{
  //       //console.log('saving new data',this.clickedRowItem);
  //       this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
  //       if (this.clickedRowItem.dataItem.isEdit) {
  //         this.isEditMode = true;
  //         this.closeAllRows();
  //         var dataindx;
  //         if (this.styleViewMode === 'Shared') {
  //           // store copy of original documents in case cancelled
  //           this.originalstylesData = [...this.sharedGridData];

  //           // set single row to edit mode to display form fields
  //           dataindx = this.sharedGridData.indexOf(this.clickedRowItem.dataItem);
  //         } else {
  //           // store copy of original documents in case cancelled
  //           this.originalstylesData = [...this.stylesData];

  //           // set single row to edit mode to display form fields
  //           dataindx = this.stylesData.indexOf(this.clickedRowItem.dataItem);
  //         }


  //         var colorRow = this.fa.controls[dataindx] as FormGroup;
  //         this.grid.editRow(this.clickedRowItem.rowIndex, colorRow);
  //         //console.log('aa',this.originalstylesData[rowIndex].styleName);
  //         //this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
  //         //console.log('dbclick',this.clickedRowItem.rowIndex, colorRow,this.stylesDataForm.value.formArray[rowIndex]);
  //       }
  //       else {
  //         alert(this.appStaticMsgs.styles.noPermissiontoEdit);

  //       }
  //     //},1000);





  // }
  editClick(rowIndex, dataItem) {
    console.log(dataItem, 'style')
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setItem(dataItem);
    this.optionslist = dataItem;
    console.log(this.optionslist, 'styles')
    this.dialog.afterAllClosed.subscribe(data => this.LoadDataList())
    this.dialog.open(StyleEditComponent);
  }

  private saveCurrent(): void {
    if (this.stylesDataForm && this.clickedRowItem) {
      //this.modifiedRow.push(this.stylesDataForm.value.formArray[this.clickedRowItem.rowIndex]);
      // this.onSave();
      //console.log('saving',this.stylesDataForm.value.formArray[this.clickedRowItem.rowIndex],this.clickedRowItem.rowIndex);
      // setTimeout(()=>{
      //   this.grid.closeRow(this.clickedRowItem.rowIndex);
      // },1000);
    }
  }

  /*To reset Form properties
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  private resetForm() {
    // clear form array and create a new form group for each product
    this.fa.clear();
    if (this.styleViewMode === 'Shared') {
      this.sharedGridData.forEach((x, i) => {
        this.fa.push(this.updateFormGroup(x));
      });
    } else {
      this.stylesData.forEach((x, i) => {
        this.fa.push(this.updateFormGroup(x));
      });
    }
  }

  onEdit() {
    this.isEditMode = true;
    // store copy of original colors in case cancelled
    this.originalstylesData = this.styleViewMode === 'Shared' ? [...this.sharedGridData] : [...this.stylesData];
    var gridView = {
      data: this.originalstylesData,
      total: this.styleViewMode === 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
    };
    this.gridData = gridView;
    // reset / initialise form fields
    this.resetForm();
    // set all rows to edit mode to display form fields
    this.editGridRows();
  }

  /*To compare old data and new data for bulk delete styles
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  jsonDeleteCompare(element, index, array) {
    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }


  /*To compare old data and new data for bulk update styles
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  jsonCompare(element, index, array) {
    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      Object.keys(element).forEach(key => {

        if (element[key] != dataRow[0][key]) {
          diff++;

        }
      })
    }
    if (diff > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  /*To save all bulk update and bulk delete styles data on list view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  onSave() {
    //this.showLoader = true;
    // mark all fields as touched to highlight any invalid fields
    // this.modifiedRow= [];
    this.stylesDataForm.markAllAsTouched();

    for (let i = 0; i < this.stylesDataForm.value.formArray.length; i++) {

      if (this.stylesDataForm.controls.formArray['controls'][i].controls.styleName.value.length > 50) {
        this.stylesDataForm.controls.formArray['controls'][i].controls.styleName.setErrors({ 'incorrect': true });
      }
    }

    ////console.log('this.stylesDataForm.value.formArray',this.style.value.formArray[this.clickedRowItem.rowIndex]);
    // this.modifiedRow.push(this.stylesDataForm.value.formArray[this.clickedRowItem.rowIndex]);
    //return;

    // stop here if form is invalid
    if (this.stylesDataForm.invalid) {
      this.showNote = true;
      // this.error(this.appStaticMsgs.styles.pleaseChecktheinvalidfields)
      this.toastService.showError("Please enter all the mandatory fields.");
      return;
    }

    //this.grid.closeRow(this.clickedRowItem.rowIndex);
    // copy form data to colors array on success
    if (this.styleViewMode === 'Shared') {
      this.sharedGridData = this.fa.value;
    } else {
      this.styleData = this.fa.value;
    }

    console.log('modifiedRow', this.styleData);
    let updStyleList = this.styleViewMode === 'Shared' ? this.sharedGridData.filter((x, i) => this.jsonCompare(x, i, this.originalstylesData)) : this.styleData.filter((x, i) => this.jsonCompare(x, i, this.originalstylesData));
    //let updStyleList = this.modifiedRow;
    updStyleList.forEach((vl) => {

      console.log('styleName', vl.styleName);
      console.log('Category', vl.category);
      console.log('Status', vl.status)
    });

    updStyleList.forEach((vl) => {
      vl.createdBy = null;
    });

    if (updStyleList.length == 0) {
      this.onCancel();
      return;
    }
    this.loading = true;
    //console.log('updStyleList',updStyleList);
    if (updStyleList.length > 0) {
      for (let i = 0; i < updStyleList.length; i++) {
        // updStyleList[i].ageGroup=this.aesdecryptservice.encryptData(this.dataService.Key,updStyleList[i].ageGroup);
        updStyleList[i].category = this.aesdecryptservice.encryptData(this.dataService.Key, updStyleList[i].category);
        updStyleList[i].status = this.aesdecryptservice.encryptData(this.dataService.Key, updStyleList[i].status);
        // updStyleList[i].fabric=this.aesdecryptservice.encryptData(this.dataService.Key,updStyleList[i].fabric);
        // updStyleList[i].sampleSize=this.aesdecryptservice.encryptData(this.dataService.Key,updStyleList[i].sampleSize);
        updStyleList[i].styleName = this.aesdecryptservice.encryptData(this.dataService.Key, updStyleList[i].styleName);
        // updStyleList[i].targetGender=this.aesdecryptservice.encryptData(this.dataService.Key,updStyleList[i].targetGender);
        // updStyleList[i].technicalPackage=this.aesdecryptservice.encryptData(this.dataService.Key,updStyleList[i].technicalPackage);
        // updStyleList[i].styleNumber=this.aesdecryptservice.encryptData(this.dataService.Key,updStyleList[i].styleNumber);
        // updStyleList[i].sizeRange=this.aesdecryptservice.encryptData(this.dataService.Key,updStyleList[i].sizeRange);
      }

      this.store.dispatch(new styleActionTypes.UpdateStylesLibrary(updStyleList));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedStyleLibrary) => {
        if (updatedStyleLibrary.style.error === "") {
          if (updatedStyleLibrary.style.message?.item2 === 'Success') {
            this.showLoader = false;
            this.closeAllRows();
            //if(this.clickedRowItem){
            //this.grid.closeRow(this.clickedRowItem.rowIndex);
            //}
            this.showNote = false;
            this.modifiedRow = [];
            //console.log('docOutClick',this.docOutClick);
            //if(this.docOutClick){
            this.clickedRowItem = '';
            //}
            // this.success(this.appStaticMsgs.styles.styleUpdatedSuccessfully)
            this.toastService.showSuccess(this.appStaticMsgs.styles.styleUpdatedSuccessfully);


            this.styleViewMode === 'Shared' ? this.loadSharedListData() : this.LoadDataList();
            this.loading = false;
          } else {
            this.loading = false;
            this.styleViewMode === 'Shared' ? this.loadSharedListData() : this.LoadDataList();
            // this.error("Failed:" + updatedStyleLibrary.style.message?.item1)
            this.toastService.showError(updatedStyleLibrary.style.message?.item1);
            this.logger.error(updatedStyleLibrary.style.message?.item1, "Style");
          }
        } else {
          this.logger.error(updatedStyleLibrary.style.error.error.message, "Style");
          // this.getError(updatedStyleLibrary.style.error);
          this.toastService.showError(updatedStyleLibrary.style.error);
          this.loading = false;
        }
      })
    }
    if (this.originalstylesData.length != (this.styleViewMode == 'Shared' ? this.sharedGridData.length : this.styleData.length)) {
      let deleterecordIds = [];
      var DelStyleList;
      if (this.styleViewMode === 'Shared') {
        DelStyleList = this.originalstylesData.filter((x, i) => this.jsonDeleteCompare(x, i, this.sharedGridData));
      } else {
        DelStyleList = this.originalstylesData.filter((x, i) => this.jsonDeleteCompare(x, i, this.styleData));
      }
      if (DelStyleList.length > 0) {
        DelStyleList.forEach((x, i) => {
          deleterecordIds.push(x.id);
        });
        this.store.dispatch(new styleActionTypes.DeleteStylesLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedStyleLibrary) => {
          if (deletedStyleLibrary.style.error === "") {
            if (deletedStyleLibrary.style.message?.item2 === 'Success') {
              this.showNote = false;

              // this.success(this.appStaticMsgs.style.styleDeletedSuccessfully)
              this.toastService.showSuccess(this.appStaticMsgs.style.styleDeletedSuccessfully);
              this.logger.info(this.appStaticMsgs.style.styleDeletedSuccessfully, "Style");
              this.styleViewMode === 'Shared' ? this.loadSharedListData() : this.LoadDataList();
              this.loading = false;
            } else {
              this.loading = false;
              this.styleViewMode === 'Shared' ? this.loadSharedListData() : this.LoadDataList();
              // this.error("Failed:" + deletedStyleLibrary.style.message?.item1)
              this.toastService.showError(deletedStyleLibrary.style.message?.item1);
              this.logger.error(deletedStyleLibrary.style.message?.item1, "Style");
            }

          } else {
            this.logger.error(deletedStyleLibrary.style.error.error.message, "Style");
            // this.getError(deletedStyleLibrary.style.error);
            this.toastService.showError(deletedStyleLibrary.style.error);
            this.loading = false;
          }
        })
      }
    }
    ////console.log(updArry);
    this.isEditMode = false;
  }

  /*To cancel edit mode on list view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  onCancel() {
    this.showNote = false;
    this.isEditMode = false;
    this.closeAllRows();
    this.styleViewMode === 'Shared' ? this.sharedGridData : this.styleData = this.originalstylesData;
    var gridView = {
      data: this.styleViewMode === 'Shared' ? this.sharedGridData : this.styleData,
      total: this.styleViewMode === 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
    };
    this.gridData = gridView;
  }


  /*To delete single style on card view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  onDeleteStyle(id) {
    Swal.fire({
      title: 'Are you sure you want to delete this style?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        let deleterecordIds = [];
        deleterecordIds.push(id);
        this.store.dispatch(new styleActionTypes.DeleteStylesLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedStyleLibrary) => {
          if (deletedStyleLibrary.style.error === "") {
            if (deletedStyleLibrary.style.message?.item2 === 'Success') {
              // this.success(this.appStaticMsgs.styledetails.styleDeletedSuccessfully);
              this.toastService.showSuccess(this.appStaticMsgs.styledetails.styleDeletedSuccessfully);
              this.logger.info(this.appStaticMsgs.style.styleDeletedSuccessfully!, "Style");
              this.cardView()
            } else {
              // this.error("Failed:" + deletedStyleLibrary.style.message?.item1)
              this.toastService.showError(deletedStyleLibrary.style.message?.item1);
              this.logger.error(deletedStyleLibrary.style.message?.item1, "Style");
            }

          } else {
            this.logger.error(deletedStyleLibrary.style.error.error.message, "Style");
            // this.getError(deletedStyleLibrary.style.error);
            this.toastService.showError(deletedStyleLibrary.style.error)
          }
        })


      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  /*To delete single style on List view
   Written by : Kiran Nagargoje
   Date: 07-Oct-2021 */
  onRemove(index, dataItem) {
    Swal.fire({
      title: 'Are you sure you want to delete this style?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        // rows must all be closed while removing colorData
        this.closeAllRows();
        // if (this.styleViewMode === 'Shared') {
        //   let dataIndex = this.sharedGridData.indexOf(dataItem);
        // remove product and product form group

        //  this.sharedGridData = JSON.parse(JSON.stringify(this.sharedGridData));
        //  this.sharedGridData.splice(dataIndex, 1);
        //  this.fa.removeAt(dataIndex);

        // this.filteredData = filterBy(this.sharedGridData, this.state.filter);

        let deleterecordIds = [];
        deleterecordIds.push(dataItem.id);
        this.store.dispatch(new styleActionTypes.DeleteStylesLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedStyleLibrary) => {
          if (deletedStyleLibrary.style.error === "") {
            if (deletedStyleLibrary.style.message?.item2 === 'Success') {
              // this.success(this.appStaticMsgs.styledetails.styleDeletedSuccessfully);
              this.toastService.showSuccess(this.appStaticMsgs.styledetails.styleDeletedSuccessfully);
              this.logger.info(this.appStaticMsgs.style?.styleDeletedSuccessfully!, "Style");
              this.LoadDataList()
              this.showLoader = false;
             // this.showLoader1 = false;
            } else {
              let dataIndex = this.stylesData.indexOf(dataItem);

              this.stylesData = JSON.parse(JSON.stringify(this.stylesData))
              this.stylesData.splice(dataIndex, 1);
              this.fa.removeAt(dataIndex);
              this.filteredData = filterBy(this.stylesData, this.state.filter);
            }
            if (this.SearchData != null && this.SearchData != "") {
              this.searchListData(this.SearchData);
            }
            if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
            // this.colorData = this.filteredData;
            var gridView = {
              data: this.filteredData,
              total: this.totalRecordCount,
            };
            this.gridData = gridView;

          } else if (result.dismiss === Swal.DismissReason.cancel) { }
        })
        /* else {
          let dataIndex = this.stylesData.indexOf(dataItem);

          this.stylesData = JSON.parse(JSON.stringify(this.stylesData))
          this.stylesData.splice(dataIndex, 1);
          this.fa.removeAt(dataIndex);
          this.filteredData = filterBy(this.stylesData, this.state.filter);
        } */
         //  process(this.filteredData, this.state);
        // reset all rows back to edit mode
        //this.success('style deleted successfully')
        //this.editGridRows();
      }


    });

  }

  onItemSelect(item: any) {
    let a = {};
    a['id'] = item.id;
    a['view'] = true;
    if (this.userPermission == 'edit') {
      a['edit'] = true;
    } else {
      a['edit'] = false;
    }

    this.selectedItems.push(a);
    //console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    //console.log(item);
    this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
  }
  onSelectAll(items: any) {
    ////console.log(items);
    items.forEach(x => {
      let a = {};
      a['id'] = x.id;
      a['view'] = true;
      if (this.userPermission == 'edit') {
        a['edit'] = true;
      } else {
        a['edit'] = false;
      }
      this.selectedItems.push(a);
    });
    ////console.log(this.selectedItems);
  }
  onDeSelectAll(items: any) {
    //console.log(items);
    this.selectedItems = [];
  }

  permChange(event) {
    //console.log(event.target.value);
    if (this.selectedItems.length > 0) {
      if (event.target.value == 'edit') {
        this.selectedItems.forEach(x => {
          x['edit'] = true;
        });
      } else {
        this.selectedItems.forEach(x => {
          x['edit'] = false;
        });
      }
    }
    //console.log(this.selectedItems);
  }

  changePermission(event, user) {
    //console.log(event.target.value,user)
    let a = {};
    if (event.target.value == 'edit') {
      user['edit'] = true;
    } else {
      user['edit'] = false;
    }
    this.modifiedData.push(user);
    //console.log(this.modifiedData);
  }

  addRow() {
    this.isedit[this.fieldArray.length] = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);

    var fields =
    {
      "edit": false,
      "view": true,
      "id": "",
      "sharedTo": "",
      "user": "",
    }
      ;
    this.fieldArray.push(fields);
  }

  /*To export excel sheet for generate techpack for style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  exportTechPackReport() {
    this.dataService.AddClientLog();
    this.showLoader = true;
    ////console.log(this.storageservice.getEditedStyleId())/
    this.dataService.GetStyleByID(this.storageservice.getEditedStyleId()).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.logger.info(response.body['item1'][0]["styleName"] + " loaded successfully", "Style Details");
        ////console.log(JSON.stringify(this.editedStyleData));
        ////console.log(JSON.stringify(response.body['item1']));


        for (let i = 0; i < response.body['item1'].length; i++) {
          response.body['item1'][i].styleName = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].styleName);
          response.body['item1'][i].newStyleNum = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].newStyleNum);
          response.body['item1'][i].styleNumber = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].styleNumber);
          response.body['item1'][i].category = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].category);
          response.body['item1'][i].subCategory = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].subCategory);
          response.body['item1'][i].targetGender = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].targetGender);
          response.body['item1'][i].ageGroup = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].ageGroup);
          //response.body['item1'][i].sampleSize = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].sampleSize);
          response.body['item1'][i].sizeRange = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].sizeRange);
          response.body['item1'][i].sizeChart = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].sizeChart);
          response.body['item1'][i].fabric = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].fabric);
          response.body['item1'][i].description = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].description);
          response.body['item1'][i].status = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].status);
        }

        this.editedStyleData = response.body['item1'];

        this.storeExcelData();
        setTimeout(() => {
          this.exportExcel.exportTechPackReport(this.exportData);
          this.showLoader = false;
        }, 5000);
      }
      else {
        this.showLoader = false;
        this.logger.error(response.body['item1'], "Style Details");
      }
    },
      err => {
        ////console.log(err)
        this.logger.error(err.error.message, "Style Details");
        if (err.error.message === "Network error communicating with endpoint") {
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
          this.showLoader = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.toastService.showError("The token being used has expired. Please login again.")
          this.showLoader = false;
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.showLoader = false;
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        }
        else{
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
      })
  }

  async storeExcelData() {
    var styleData = [];
    styleData.push(this.editedStyleData);
    if (styleData) {
      if (styleData[0][0] == undefined) {
        this.exportData = [];
        this.exportData.push(styleData[0]);
      } else {
        this.exportData = [];
        this.exportData.push(styleData[0][0]);
      }

      var styleImages = this.exportData[0]['styleImages'];
      var styleStitchess = this.exportData[0]['styleStitchess'];
      var styleConstructions = this.exportData[0]['styleConstructions'];

      if (styleImages) {
        await this.callStyleImages(styleImages);
      }
      if (styleStitchess) {
        await this.callStichesdetails(styleStitchess);
      }
      if (styleConstructions) {
        await this.callStyleConstructions(styleConstructions);
      }
    }
  }

  /*To convert Base64 Image From style images Image URL
 Written by : Kiran Nagargoje
 Date: 12-Oct-2021 */
  async callStyleImages(StyleImages) {

    StyleImages.forEach((StyleImage, imgi) => {

      StyleImage.imageType = this.aesdecryptservice.decryptData(this.dataService.Key, StyleImage.imageType);
      if (StyleImage.imageType == "coverpage") {

        StyleImage['image']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, StyleImage['image']['thumbnail']);
        let thumbnail = StyleImage['image']['thumbnail'];
        if (thumbnail) {
          this.getBase64ImageFromURL(thumbnail).subscribe((base64Data: string) => {

            StyleImage['image']['base64URL'] = base64Data;
          });
        }
      }
    });
  }

  /*To convert Base64 Image From style Stiches Image URL
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async callStichesdetails(stichesdetails) {
    stichesdetails.forEach((stiches, imgi) => {

      //console.log('stiches',stiches);
      if (stiches['stitch']['topViewFile']) {
        stiches['stitch']['topViewFile']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, stiches['stitch']['topViewFile']['thumbnail']);
        let thumbnail = stiches['stitch']['topViewFile']['thumbnail'];
        if (thumbnail) {
          this.getBase64ImageFromURL(thumbnail).subscribe((base64Data: string) => {
            stiches['stitch']['topViewFile']['base64URL'] = base64Data;
          });
        }
      }

    });
  }

  /*To convert Base64 Image From style Constructions Image URL
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async callStyleConstructions(StyleConstructions) {
    StyleConstructions.forEach((Constructions, imgi) => {
      if (Constructions['image']) {
        Constructions['image']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, Constructions['image']['thumbnail']);
        let thumbnail = Constructions['image']['thumbnail'];
        if (thumbnail) {
          this.getBase64ImageFromURL(thumbnail).subscribe((base64Data: string) => {
            Constructions['image']['base64URL'] = base64Data;
          });
        }
      }
    });
  }

  getBase64ImageFromURL(url: string): Observable<string> {

    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    var canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL: string = canvas.toDataURL("image/png");
    //this.imgResult = dataURL;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  /*To open selected style in view mode, share, techpach from card view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  onEditStyle(id, e) {
    this.dataService.setActiveStyleTabValue(e.target.innerText);
    this.storageservice.storeEditedStyleId(id);
    this.dataService.AddClientLog();
    this.router.navigate(['/styledetails']);
  }

  /*To open selected style in edit mode from card view only
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  onEditStyleDetails(id) {
    this.storageservice.storeEditedStyleId(id);
    this.storageservice.storeEditedStyleDetailsId(id);
    this.dataService.AddClientLog();
    this.router.navigate(['/styledetails']);
  }

  getAllUsers() {
    this.showLoader = true;
    let UserData = JSON.parse(localStorage.getItem('userFullName'));
    this.dataService.getallUsers().subscribe(response => {
      if (response.body) {
        this.showLoader = false;
        this.logger.info(this.appStaticMsgs.styledetails.loadalluserdetails, "Style Details");
        var data = response.body['item1'];
        this.itemList = data.filter(x => this.aesdecryptservice.decryptData(this.dataService.Key, x.eMail) !== UserData['eMail']);


        // for (let i = 0; i < data.length; i++) {
        //   data[i].eMail = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].eMail);
        //   data[i].firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].firstName);
        //   data[i].lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].lastName);
        //   data[i].fullName = data[i].firstName + " " + data[i].lastName;
        //   if (data[i].picture) {
        //     data[i].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].picture.thumbnail);
        //     data[i].picture.name = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].picture.name);
        //   }
        // }



        this.itemList.forEach((x) => {
          x['id'] = x.id;
          x['firstName'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.firstName);
          x['lastName'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.lastName);
          x['eMail'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.eMail);
          x['itemName'] = x['firstName'] + ' ' + x['lastName'] + ' - ' + x['eMail'];
          if (x.picture) {
            x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.picture.thumbnail);
          }
        });

        this.dropdownList = this.itemList;

        console.log("USER DATA", this.itemList);


      }
      else {
        // this.error(response.body['item1']);
        this.toastService.showError(response.body['item1']);
        this.logger.error(response.body['item1'], "Style Details");
        this.showLoader = false;
      }
    }, err => {
      this.logger.error(err.error.message, "Style Details");
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.showLoader = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.toastService.showError("The token being used has expired. Please login again.")
        this.sessionTimedOut();
        this.showLoader = false;
        this.userservice.logout();
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        this.showLoader = false;
      }
      else {
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        this.logger.error(err.error.message, "Style Details");


      }
      this.showLoader = false;
    });
  }

  getAllsharedaccess() {

    if (this.storageservice.getEditedStyleId()) {
      this.showLoader = true;
      this.sharedUsers = [];
      this.dataService.getsharedaccess(this.storageservice.getEditedStyleId(), 'Styles').subscribe(response => {
        if (response.status === 200) {
          //this.allUser = response.body;
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.logger.info(this.appStaticMsgs.styledetails.getshareddata, "Style Details");
            for (let i = 0; i < response.body['item1'].length; i++) {
              response.body['item1'][i].module = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].module);
              response.body['item1'][i].org = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].module);
              for (let j = 0; j < response.body['item1'][i].user.length; j++) {
                response.body['item1'][i].user[j].firstName = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].firstName);
                response.body['item1'][i].user[j].lastName = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].lastName);
                response.body['item1'][i].user[j].about = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].about);
                response.body['item1'][i].user[j].eMail = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].eMail);
                response.body['item1'][i].user[j].group = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].group);
                response.body['item1'][i].user[j].org = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].org);
                response.body['item1'][i].user[j].fullName = response.body['item1'][i].user[j].firstName + " " + response.body['item1'][i].user[j].lastName;
                if (response.body['item1'][i].user[j].picture) {
                  response.body['item1'][i].user[j].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].picture.thumbnail);
                  response.body['item1'][i].user[j].picture.name = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].picture.name);
                }

                var isOnline = false;
                if (this.onlineUsers.length > 0) {
                  if (this.onlineUsers.indexOf(response.body['item1'][i].user[j].eMail) !== -1) {
                    isOnline = true;
                  }
                }
                let item = {
                  user: response.body['item1'][i].user,
                  isOnline: isOnline
                };
                this.sharedUsers.push(item);

              }
            }

            //this.isSharedView=true;
            this.fieldArray = response.body['item1'];
            this.newfieldArray = [];
            this.newfieldArray = response.body['item1'];

          } else {
            ////console.log(response)
            //this.alert = 'danger';
            //this.errorDiv = true;
            // this.errors = response.body['item2'];
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "Style Details");

            this.showLoader = false;
          }
        }
      }, err => {
        //this.alert = 'danger';
        //this.errorDiv = true;
        //this.errors = err.message;
        this.logger.error(err.error.message, "Style Details");

        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.toastService.showError("The token being used has expired. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        }
        else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          this.logger.error(err.message, "Style Details");

        }
        this.showLoader = false;
      });
    }
  }

  getSharedData(id) {
    this.storageservice.storeEditedStyleId(id);
    this.dataService.AddClientLog();
    this.getAllUsers();
    this.getAllsharedaccess();
    this.isedit[this.fieldArray.length] = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);

    var fields =
    {
      "edit": false,
      "view": true,
      "id": "",
      "sharedTo": "",
      "user": "",
    }
      ;
    this.fieldArray.push(fields);
    this.isedit = [false];
    // setTimeout(() => {
    // }, 100);
  }

  removeRow(index: number) {
    Swal.fire({
      title: 'Are you sure you want to delete this shared data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    })
      .then((result) => {
        if (result.value) {
          if (this.fieldArray[index].id) {
            const formData = new FormData();
            formData.append('deleteids', this.fieldArray[index].id);
            formData.append('recordid', this.storageservice.getEditedStyleId());
            formData.append('module', this.aesdecryptservice.encryptData(this.dataService.Key, 'Styles'));
            formData.append('styleid', this.storageservice.getEditedStyleId());
            this.fieldArray.splice(index, 1);
            this.showLoader = true;
            this.dataService.addupdatesharedaccess(formData).subscribe(response => {
              if (response.status === 200) {
                if (response.body['item2'] === "Success") {
                  this.logger.info(this.appStaticMsgs.styledetails.sharedstyledeletedsuccessfully, "Style Details");
                  // this.success(this.appStaticMsgs.styledetails.sharedstyledeletedsuccessfully);
                  this.toastService.showSuccess(this.appStaticMsgs.styledetails.sharedstyledeletedsuccessfully);

                  this.sharedUsers = [];
                  for (let i = 0; i < response.body['item1'].length; i++) {
                    response.body['item1'][i].module = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].module);
                    response.body['item1'][i].org = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].module);
                    for (let j = 0; j < response.body['item1'][i].user.length; j++) {
                      response.body['item1'][i].user[j].firstName = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].firstName);
                      response.body['item1'][i].user[j].lastName = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].lastName);
                      response.body['item1'][i].user[j].about = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].about);
                      response.body['item1'][i].user[j].eMail = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].eMail);
                      response.body['item1'][i].user[j].group = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].group);
                      response.body['item1'][i].user[j].org = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].org);
                      response.body['item1'][i].user[j].fullName = response.body['item1'][i].user[j].firstName + " " + response.body['item1'][i].user[j].lastName;
                      if (response.body['item1'][i].user[j].picture) {
                        response.body['item1'][i].user[j].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].picture.thumbnail);
                        response.body['item1'][i].user[j].picture.name = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].picture.name);
                      }
                      var isOnline = false;
                      if (this.onlineUsers.length > 0) {
                        if (this.onlineUsers.indexOf(response.body['item1'][i].user[j].eMail) !== -1) {
                          isOnline = true;
                        }
                      }
                      let item = {
                        user: response.body['item1'][i].user,
                        isOnline: isOnline
                      };
                      this.sharedUsers.push(item);
                    }
                  }
                  this.showLoader = false;
                  this.submitted = false;

                } else {
                  ////console.log(response)
                  //this.alert = 'danger';
                  //this.errorDiv = true;
                  // this.errors = response.body['item2'];
                  // this.error(response.body['item1']);
                  this.toastService.showError(response.body['item1']);
                  this.showLoader = false;
                }
              }
            }, err => {
              //this.alert = 'danger';
              //this.errorDiv = true;
              //this.errors = err.message;
              this.logger.error(err.error.message, "Style Details");
              if (err.error.message === "Network error communicating with endpoint") {
                // this.error(err.error.message);
                this.toastService.showError("Oops!! Something went wrong. Please try again.");
              } else if (err.error.message === "The incoming token has expired") {
                this.toastService.showError("The token being used has expired. Please login again.")
                this.sessionTimedOut();
                this.userservice.logout()
              } else if (err.error.message === "Unauthorized") {
                // this.userservice.error();
                this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
              }
              else {
                this.logger.error(err.error.message, "Style Details");

                // this.error(err.message);
                this.toastService.showError(this.appStaticMsgs.unResponsive.error);
              }
              this.showLoader = false;
            });
          } else {
            this.fieldArray.splice(index, 1);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
      });
  }

  saveShareStyle() {
    const formData = new FormData();
    //this.selectedItems = this.selectedItems.filter(x => x.id == "");
    formData.append('recordid', this.storageservice.getEditedStyleId());
    formData.append('module', this.aesdecryptservice.encryptData(this.dataService.Key, 'Styles'));
    formData.append('recordscount', (this.modifiedData.length + this.selectedItems.length).toString());
    //console.log('this.modifiedData',this.modifiedData)
    if (this.modifiedData.length > 0) {
      this.modifiedData.forEach(x => {
        let a = {};
        a['id'] = x.userID;
        a['sharedaccessid'] = x.id;
        a['view'] = x.view;
        a['edit'] = x.edit;
        this.selectedItems.push(a);
      })
    }
    if (this.selectedItems.length > 0) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i]?.sharedaccessid) {
          formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataService.Key, "Update"));
          formData.append('sharedaccessid' + '[' + i + ']', this.selectedItems[i].sharedaccessid);
        } else {
          formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataService.Key, "Add"));
        }
        formData.append('sharedto' + '[' + i + ']', this.selectedItems[i].id);
        formData.append('view' + '[' + i + ']', this.selectedItems[i].view);
        formData.append('edit' + '[' + i + ']', this.selectedItems[i].edit);
      }

      this.addupdateSharedData(formData);
    }

    // if(this.modifiedData.length > 0){
    //   for (let i = 0; i < this.modifiedData.length; i++) {
    //     formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
    //     formData.append('sharedaccessid' + '[' + i + ']', this.modifiedData[i].id);
    //     formData.append('sharedto' + '[' + i + ']', this.modifiedData[i].user[0].id);
    //     formData.append('view' + '[' + i + ']', this.modifiedData[i].view);
    //     formData.append('edit' + '[' + i + ']', this.modifiedData[i].edit);
    //   }
    //   this.addupdateSharedData(formData);
    // }



    /*if (this.editedSharedData.length > 0 && this.fieldArray.length == 0) {
      for (let i = 0; i < this.editedSharedData.length; i++) {
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
        formData.append('sharedaccessid' + '[' + i + ']', this.editedSharedData[i].id);
        formData.append('sharedto' + '[' + i + ']', this.editedSharedData[i].user[0].id);
        formData.append('view' + '[' + i + ']', this.editedSharedData[i].view);
        formData.append('edit' + '[' + i + ']', this.editedSharedData[i].edit);
      }
      this.addupdateSharedData(formData);
    }
    if (this.fieldArray.length > 0 && this.editedSharedData.length == 0) {
      for (let i = 0; i < this.fieldArray.length; i++) {
        var isValid = false;
        if (this.fieldArray[i].user) {
          formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
          formData.append('sharedto' + '[' + i + ']', this.fieldArray[i].user[0].id);
          formData.append('view' + '[' + i + ']', this.fieldArray[i].view);
          formData.append('edit' + '[' + i + ']', this.fieldArray[i].edit);
          isValid = true;
        }
      }

      if (isValid) {
        this.addupdateSharedData(formData);
      }
    }*/
  }

  getOnlineUsers() {
    this.onlineUsers = [];
    if (this.storageservice.getOnlineUsers()) {
      let result = this.storageservice.getOnlineUsers();
      this.onlineUsers = result.split(',');
    }
  }

  addupdateSharedData(formData) {

    this.showLoader = true;
    this.dataService.addupdatesharedaccess(formData).subscribe(response => {
      this.editedSharedData = [];

      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.modifiedData = [];
          this.selectedItems = [];
          this.selectedItemsnew = [];
          this.isedit = [false];
          this.sharedUsers = [];
          for (let i = 0; i < response.body['item1'].length; i++) {
            response.body['item1'][i].module = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].module);
            response.body['item1'][i].org = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].module);
            for (let j = 0; j < response.body['item1'][i].user.length; j++) {
              response.body['item1'][i].user[j].firstName = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].firstName);
              response.body['item1'][i].user[j].lastName = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].lastName);
              response.body['item1'][i].user[j].about = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].about);
              response.body['item1'][i].user[j].eMail = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].eMail);
              response.body['item1'][i].user[j].group = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].group);
              response.body['item1'][i].user[j].org = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].org);
              response.body['item1'][i].user[j].fullName = response.body['item1'][i].user[j].firstName + " " + response.body['item1'][i].user[j].lastName;
              if (response.body['item1'][i].user[j].picture) {
                response.body['item1'][i].user[j].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].picture.thumbnail);
                response.body['item1'][i].user[j].picture.name = this.aesdecryptservice.decryptData(this.dataService.Key, response.body['item1'][i].user[j].picture.name);
              }
              var isOnline = false;
              console.log(this.onlineUsers, 'onlineusers');
              if (this.onlineUsers.length > 0) {
                if (this.onlineUsers.indexOf(response.body['item1'][i].user[j].eMail) !== -1) {
                  isOnline = true;
                }
              }
              let item = {
                user: response.body['item1'][i].user,
                isOnline: isOnline
              };
              this.sharedUsers.push(item);
            }
          }
          this.fieldArray = response.body['item1'];
          this.LoadData();
          this.submitted = false;
          this.logger.info(this.appStaticMsgs.styledetails.stylesharedsuccessfully, "Style Details");
          // this.success(this.appStaticMsgs.styledetails.stylesharedsuccessfully)
          this.toastService.showSuccess(this.appStaticMsgs.styledetails.stylesharedsuccessfully);

          this.showLoader = false;
        } else {
          // this.error(response.body['item1']);
          this.toastService.showError(response.body['item1']);
          this.showLoader = false;
        }
      }
    }, err => {
      //this.alert = 'danger';
      //this.errorDiv = true;
      //this.errors = err.message;
      this.logger.error(err.error.message, "Style Details");
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
      } else if (err.error.message === "The incoming token has expired") {
        this.toastService.showError("The token being used has expired. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
      }
      else {
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        this.logger.error(err.error.message, "Style Details");
      }
      this.showLoader = false;
    });
  }

  /*NO USE
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  HandleError(err) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
    } else if (err.error.message === "The incoming token has expired") {
      this.toastService.showError("The token being used has expired. Please login again.")
      this.sessionTimedOut();
      this.userservice.logout();
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
    }
    else{
      this.toastService.showError(this.appStaticMsgs.unResponsive.error);
    }
  }

  /*To apply advance search but NOt in USE
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */
  onSubmit(value) {
    //console.log(value)
    this.showLoader = true;
    let createdFromChildStr = this.createdStartDate;
    let createdToChildStr = this.createdEndDate;
    let modifiedFromChildStr = this.modifiedStartDate;
    let modifiedToChildStr = this.modifiedEndDate;

    if ((createdFromChildStr !== null && createdToChildStr == null) && (createdFromChildStr !== undefined && createdToChildStr == undefined)) {
      // this.error(this.appStaticMsgs.styles.createdToismandatorywhenCreatedFromisPresent);
      this.toastService.showError(this.appStaticMsgs.styles.createdToismandatorywhenCreatedFromisPresent);
      this.showLoader = false;
      return;
    }
    else if ((createdFromChildStr == null && createdToChildStr !== null) && (createdFromChildStr == undefined && createdToChildStr !== undefined)) {
      // this.error(this.appStaticMsgs.styles.createdFromismandatorywhenCreatedToisPresent);
      this.toastService.showError(this.appStaticMsgs.styles.createdFromismandatorywhenCreatedToisPresent);
      this.showLoader = false;
      return;
    }
    if ((modifiedFromChildStr !== null && modifiedToChildStr == null) && (modifiedFromChildStr !== undefined && modifiedToChildStr == undefined)) {
      // this.error(this.appStaticMsgs.styles.modifiedToismandatorywhenCreatedFromisPresent);
      this.toastService.showError(this.appStaticMsgs.styles.modifiedToismandatorywhenCreatedFromisPresent);
      this.showLoader = false;
      return;
    }
    else if ((modifiedFromChildStr == null && modifiedToChildStr !== null) && (modifiedFromChildStr == undefined && modifiedToChildStr !== undefined)) {
      // this.error(this.appStaticMsgs.styles.modifiedFromismandatorywhenModifiedToisPresent);
      this.toastService.showError(this.appStaticMsgs.styles.modifiedFromismandatorywhenModifiedToisPresent);
      this.showLoader = false;
      return;
    }

    let adv_data = {
      // keyword: this.f1.keyword.value,
      createdBy: this.f1.createdBy.value,
      styleName: this.f1.styleName.value,
      styleNumber: this.f1.styleNumber.value,
      gender: this.f1.gender.value,
      fabric: this.f1.fabric.value,
      //state: this.f1.state.value,
      category: this.f1.category.value,
      status: this.f1.status.value,
      createdFrom: createdFromChildStr,
      createdTo: createdToChildStr,
      modifiedFrom: modifiedFromChildStr,
      modifiedTo: modifiedToChildStr
    }
    ////console.log(JSON.stringify(adv_data));
  }

  onChange(event) {
    this.file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", this.file, this.file.name);
    this.showLoader = true;
    this.dataService.uploadStyles(formData).subscribe(data => {
      //console.log(data);
      if (data.body['item2'] == 'Success') {
        this.LoadData();

        // this.success(this.appStaticMsgs.styles.datauploadedsuccessfully)
        this.toastService.showSuccess(this.appStaticMsgs.styles.datauploadedsuccessfully);
      } else {
        // this.error(data.body['item1']);
        this.toastService.showError(data.body['item1'])
      }
      this.showLoader = false;
    }, err => {
      // this.error(err.common.message);
      this.toastService.showError(err.common.message);
      this.showLoader = false;
    });
  }

  downloadTemplate() {
    window.open('../../../../assets/excels/SampleTemplate_Style.xlsx', '_blank');
  }

  uploadFile() {
    let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
    element.click();
  }

  tableSelection(event) {
    //console.log('deselecetd',event);
    let selectedRows = event.selectedRows;
    let deSelectedRows = event.deselectedRows;

    if (selectedRows.length > 0) {
      selectedRows.forEach(row => {
        this.selected.push(row.dataItem)
      });
    }

    if (deSelectedRows.length > 0) {
      deSelectedRows.forEach(x => {
        this.selected.splice(x.dataItem, 1);
      });
    }
    //console.log('all items',this.selected);
  }

  saveTechpackId(id: any) {
    this.storageservice.storeEditedStyleId(id);
  }
}

