import * as userActions from "../actions/userActions.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface UserState extends EntityState<any> {
    selectedUserId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const userAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultUser: UserState = {
    ids: [],
    entities: {},
    selectedUserId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = userAdapter.getInitialState(defaultUser);

export function UserReducer(state = initialState, action: userActions.userActionTypes): UserState {
    switch (action.type) {
        case userActions.UserActionTypes.LOAD_USER_MANAGEMENT_SUCCESS:
            return userAdapter.addMany(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case userActions.UserActionTypes.LOAD_USER_MANAGEMENT_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.LOAD_USER_ROLES_SUCCESS:
            return userAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                error: '',
                selectedUserId: action.payload1.id
            })
            break;
        case userActions.UserActionTypes.LOAD_USER_ROLES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
            case userActions.UserActionTypes.LOAD_MENU_ITEMS_SUCCESS:
            return userAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                error: '',
                selectedUserId: action.payload1.id
            })
            break;
        case userActions.UserActionTypes.LOAD_MENU_ITEMS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.LOAD_USER_SUCCESS:
            return userAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                error: '',
                selectedSupplierId: action.payload1.id
            })
            break;
        case userActions.UserActionTypes.LOAD_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
            case userActions.UserActionTypes.LOAD_USER_DETAILS_SUCCESS:
            return userAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedUserId: action.payload1.id
            })
            break;
        case userActions.UserActionTypes.LOAD_USER_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.UPDATE_USER_SUCCESS:
            return userAdapter.updateOne(action.payload1, { ...state.message = action.payload2, ...state })
            break;
        case userActions.UserActionTypes.UPDATE_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.LOAD_USER_SUPPLIER_SUCCESS:
            return userAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedSupplierId: action.payload1.id
            })
            break;
        case userActions.UserActionTypes.LOAD_USER_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.CREATE_USER_SUPPLIER_SUCCESS:
            return userAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedUserId: action.payload1.id
            })
            break;
        case userActions.UserActionTypes.CREATE_USER_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.UPDATE_USER_SUPPLIER_SUCCESS:
            return userAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case userActions.UserActionTypes.UPDATE_USER_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.CREATE_USER_PORTFOLIO_SUCCESS:
            return userAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedUserId: action.payload1.id
            })
            break;
        case userActions.UserActionTypes.CREATE_USER_PORTFOLIO_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.UPDATE_USER_PORTFOLIO_SUCCESS:
            return userAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case userActions.UserActionTypes.UPDATE_USER_PORTFOLIO_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case userActions.UserActionTypes.SEND_INVITE_SUCCESS:
            return userAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedUserId: action.payload1.id
            })
            break;
        case userActions.UserActionTypes.SEND_INVITE_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getUsersFeatureState = createFeatureSelector<UserState>('user');

export const getUserLibarary = createSelector(
    getUsersFeatureState, userAdapter.getSelectors().selectAll
)
export const getUsersLoading = createSelector(
    getUsersFeatureState, (state: UserState) => state.loading
)
export const getUsersLoaded = createSelector(
    getUsersFeatureState, (state: UserState) => state.loaded
)
export const getUsersError = createSelector(
    getUsersFeatureState, (state: UserState) => state.error
)
export const getUsersMessage = createSelector(
    getUsersFeatureState, (state: UserState) => state.message
)
export const getUserId = createSelector(
    getUsersFeatureState, (state: UserState) => state.selectedUserId
)
export const getUser = createSelector(
    getUsersFeatureState, getUserId, state => state.entities[state.selectedUserId]
)