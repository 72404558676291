import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

export enum MaterialActionTypes {
    LOAD_MATERIAL_LIBRARY = '[Material] Load Material Library',
    LOAD_MATERIAL_LIBRARY_SUCCESS = '[Material] Load Material Library Success',
    LOAD_MATERIAL_LIBRARY_FAIL = '[Material] Load Material Library Fail',
    LOAD_MATERIAL = '[Material] Load Material',
    LOAD_MATERIAL_SUCCESS = '[Material] Load Material Success',
    LOAD_MATERIAL_FAIL = '[Material] Load Material Fail',
    LOAD_MATERIAL_SUPPLIER = '[Material] Load Material Supplier',
    LOAD_MATERIAL_SUPPLIER_SUCCESS = '[Material] Load Material Supplier Success',
    LOAD_MATERIAL_SUPPLIER_FAIL = '[Material] Load Material Supplier Fail',
    LOAD_MATERIAL_COLOR = '[Material] Load Material Color',
    LOAD_MATERIAL_COLOR_SUCCESS = '[Material] Load Material Color Success',
    LOAD_MATERIAL_COLOR_FAIL = '[Material] Load Material Color Fail',
    CREATE_MATERIAL = '[Material] Create Material',
    CREATE_MATERIAL_SUCCESS = '[Material] Create Material Success',
    CREATE_MATERIAL_FAIL = '[Material] Create Material Fail',
    UPDATE_MATERIAL = '[Material] Update Material',
    UPDATE_MATERIAL_SUCCESS = '[Material] Update Material Success',
    UPDATE_MATERIAL_FAIL = '[Material] Update Material Fail',
    UPDATE_MATERIAL_LIBRARY = '[Material] Update Material Library',
    UPDATE_MATERIAL_LIBRARY_SUCCESS = '[Material] Update Material Library Success',
    UPDATE_MATERIAL_LIBRARY_FAIL = '[Material] Update Material Library Fail',
    DELETE_MATERIAL_LIBRARY = '[Material] Delete Material Library',
    DELETE_MATERIAL_LIBRARY_SUCCESS = '[Material] Delete Material Library Success',
    DELETE_MATERIAL_LIBRARY_FAIL = '[Material] Delete Material Library Fail',
    UPDATE_MATERIAL_DOCUMENT = '[Material] Update Material Document',
    UPDATE_MATERIAL_DOCUMENT_SUCCESS = '[Material] Update Material Document Success',
    UPDATE_MATERIAL_DOCUMENT_FAIL = '[Material] Update Material Document Fail',
    CREATE_MATERIAL_COLOR = '[Material] Create Material Color',
    CREATE_MATERIAL_COLOR_SUCCESS = '[Material] Create Material Color Success',
    CREATE_MATERIAL_COLOR_FAIL = '[Material] Create Material Color Fail',
    CREATE_MATERIAL_SUPPLIER = '[Material] Create Material Supplier',
    CREATE_MATERIAL_SUPPLIER_SUCCESS = '[Material] Create Material Supplier Success',
    CREATE_MATERIAL_SUPPLIER_FAIL = '[Material] Create Material Supplier Fail',
    CREATE_MATERIAL_SUPPLIER_COLOR = '[Material] Create Material Supplier Color',
    CREATE_MATERIAL_SUPPLIER_COLOR_SUCCESS = '[Material] Create Material Supplier Color Success',
    CREATE_MATERIAL_SUPPLIER_COLOR_FAIL = '[Material] Create Material Supplier Color Fail',
}

export class LoadMaterialLibrary implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_LIBRARY

    constructor(public payload1: string,public payload2: string) { }
}

export class LoadMaterialLibrarySuccess implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadMaterialLibraryFail implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export class LoadMaterial implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL

    constructor(public payload: string) { }
}

export class LoadMaterialSuccess implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadMaterialFail implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_FAIL

    constructor(public payload: string) { }
}

export class LoadMaterialSupplier implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_SUPPLIER

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadMaterialSupplierSuccess implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_SUPPLIER_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadMaterialSupplierFail implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_SUPPLIER_FAIL

    constructor(public payload: string) { }
}

export class LoadMaterialColor implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_COLOR

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadMaterialColorSuccess implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_COLOR_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadMaterialColorFail implements Action {
    readonly type = MaterialActionTypes.LOAD_MATERIAL_COLOR_FAIL

    constructor(public payload: string) { }
}
export class CreateMaterial implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL

    constructor(public payload: any) { }
}
export class CreateMaterialSuccess implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateMaterialFail implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_FAIL

    constructor(public payload: string) { }
}
export class UpdateMaterial implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL

    constructor(public payload: any) { }
}

export class UpdateMaterialSuccess implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateMaterialFail implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL_FAIL

    constructor(public payload: string) { }
}

export class UpdateMaterialLibrary implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL_LIBRARY

    constructor(public payload: any[]) { }
}

export class UpdateMaterialLibrarySuccess implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL_LIBRARY_SUCCESS

    constructor(public payload1: Update<any>[],public payload2: any) { }
}

export class UpdateMaterialLibraryFail implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class DeleteMaterialLibrary implements Action {
    readonly type = MaterialActionTypes.DELETE_MATERIAL_LIBRARY

    constructor(public payload: string[]) { }
}

export class DeleteMaterialLibrarySuccess implements Action {
    readonly type = MaterialActionTypes.DELETE_MATERIAL_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class DeleteMaterialLibraryFail implements Action {
    readonly type = MaterialActionTypes.DELETE_MATERIAL_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class UpdateMaterialDocument implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL_DOCUMENT

    constructor(public payload: any) { }
}

export class UpdateMaterialDocumentSuccess implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL_DOCUMENT_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateMaterialDocumentFail implements Action {
    readonly type = MaterialActionTypes.UPDATE_MATERIAL_DOCUMENT_FAIL

    constructor(public payload: string) { }
}

export class CreateMaterialColor implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_COLOR

    constructor(public payload: any) { }
}

export class CreateMaterialColorSuccess implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_COLOR_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateMaterialColorFail implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_COLOR_FAIL

    constructor(public payload: string) { }
}

export class CreateMaterialSupplier implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_SUPPLIER

    constructor(public payload: any) { }
}

export class CreateMaterialSupplierSuccess implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateMaterialSupplierFail implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_FAIL

    constructor(public payload: string) { }
}

export class CreateMaterialSupplierColor implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_COLOR

    constructor(public payload: any) { }
}

export class CreateMaterialSupplierColorSuccess implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_COLOR_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateMaterialSupplierColorFail implements Action {
    readonly type = MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_COLOR_FAIL

    constructor(public payload: string) { }
}

export type materialActionTypes = LoadMaterialLibrary | LoadMaterialLibrarySuccess | LoadMaterialLibraryFail |
    LoadMaterial | LoadMaterialSuccess | LoadMaterialFail | LoadMaterialColor | LoadMaterialColorSuccess | LoadMaterialColorFail |
    LoadMaterialSupplier | LoadMaterialSupplierSuccess | LoadMaterialSupplierFail | CreateMaterial | CreateMaterialSuccess |
    CreateMaterialFail | UpdateMaterial | UpdateMaterialSuccess | UpdateMaterialFail | UpdateMaterialLibrary | UpdateMaterialLibrarySuccess
    | UpdateMaterialLibraryFail | DeleteMaterialLibrary | DeleteMaterialLibrarySuccess | DeleteMaterialLibraryFail
    | UpdateMaterialDocument | UpdateMaterialDocumentSuccess | UpdateMaterialDocumentFail | CreateMaterialColor
    | CreateMaterialColorSuccess | CreateMaterialColorFail |CreateMaterialSupplier
    | CreateMaterialSupplierSuccess | CreateMaterialSupplierFail | CreateMaterialSupplierColor
    | CreateMaterialSupplierColorSuccess | CreateMaterialSupplierColorFail ;