import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

export enum StylesActionTypes {
    LOAD_STYLES_LIBRARY = '[Styles] Load Styles Library',
    LOAD_STYLES_LIBRARY_SUCCESS = '[Styles] Load Styles Library Success',
    LOAD_STYLES_LIBRARY_FAIL = '[Styles] Load Styles Library Fail',
    LOAD_STYLES = '[Styles] Load Styles',
    LOAD_STYLES_SUCCESS = '[Styles] Load Styles Success',
    LOAD_STYLES_FAIL = '[Styles] Load Styles Fail',
    CREATE_STYLES = '[Styles] Create Styles',
    CREATE_STYLES_SUCCESS = '[Styles] Create Styles Success',
    CREATE_STYLES_FAIL = '[Styles] Create Styles Fail',
    UPDATE_STYLES = '[Styles] Update Styles',
    UPDATE_STYLES_SUCCESS = '[Styles] Update Styles Success',
    UPDATE_STYLES_FAIL = '[Styles] Update Styles Fail',
    UPDATE_STYLES_LIBRARY = '[Styles] Update Styles Library',
    UPDATE_STYLES_LIBRARY_SUCCESS = '[Styles] Update Styles Library Success',
    UPDATE_STYLES_LIBRARY_FAIL = '[Styles] Update Styles Library Fail',
    DELETE_STYLES_LIBRARY = '[Styles] Delete Styles Library',
    DELETE_STYLES_LIBRARY_SUCCESS = '[Styles] Delete Styles Library Success',
    DELETE_STYLES_LIBRARY_FAIL = '[Styles] Delete Styles Library Fail',
    UPDATE_STYLE_COLORWAYS = '[Styles] Update Style Colorways',
    UPDATE_STYLE_COLORWAYS_SUCCESS = '[Styles] Update Style Colorways Success',
    UPDATE_STYLE_COLORWAYS_FAIL = '[Styles] Update Style Colorways Fail',
    ADD_UPDATE_DEL_STYLE_PATTERNS = '[Styles] AddUpdateDelete Style Patterns',
    ADD_UPDATE_DEL_STYLE_PATTERNS_SUCCESS = '[Styles] AddUpdateDelete Style Patterns Success',
    ADD_UPDATE_DEL_STYLE_PATTERNS_FAIL = '[Styles] AddUpdateDelete Style Patterns Fail',
    ADD_UPDATE_DEL_STYLE_IMAGES = '[Styles] AddUpdateDelete Style Images',
    ADD_UPDATE_DEL_STYLE_IMAGES_SUCCESS = '[Styles] AddUpdateDelete Style Images Success',
    ADD_UPDATE_DEL_STYLE_IMAGES_FAIL = '[Styles] AddUpdateDelete Style Images Fail',
    ADD_UPDATE_DEL_STYLE_CONSTRUCTION = '[Styles] AddUpdateDelete Style Construction',
    ADD_UPDATE_DEL_STYLE_CONSTRUCTION_SUCCESS = '[Styles] AddUpdateDelete Style Construction Success',
    ADD_UPDATE_DEL_STYLE_CONSTRUCTION_FAIL = '[Styles] AddUpdateDelete Style Construction Fail',
    ADD_UPDATE_DEL_STYLE_SPECIFICATION = '[Styles] AddUpdateDelete Style Specification',
    ADD_UPDATE_DEL_STYLE_SPECIFICATION_SUCCESS = '[Styles] AddUpdateDelete Style Specification Success',
    ADD_UPDATE_DEL_STYLE_SPECIFICATION_FAIL = '[Styles] AddUpdateDelete Style Specification Fail',
    ADD_UPDATE_DEL_STYLE_PROCESSING = '[Styles] AddUpdateDelete Style Processing',
    ADD_UPDATE_DEL_STYLE_PROCESSING_SUCCESS = '[Styles] AddUpdateDelete Style Processing Success',
    ADD_UPDATE_DEL_STYLE_PROCESSING_FAIL = '[Styles] AddUpdateDelete Style Processing Fail',
    ADD_UPDATE_DEL_STYLE_BOM = '[Styles] AddUpdateDelete Style Bom',
    ADD_UPDATE_DEL_STYLE_BOM_SUCCESS = '[Styles] AddUpdateDelete Style Bom Success',
    ADD_UPDATE_DEL_STYLE_BOM_FAIL = '[Styles] AddUpdateDelete Style Bom Fail',
    ADD_UPDATE_STYLE_SHARED_ACCESS = '[Styles] AddUpdateDelete Style Shared Access',
    ADD_UPDATE_STYLE_SHARED_ACCESS_SUCCESS = '[Styles] AddUpdateDelete Style Shared Access Success',
    ADD_UPDATE_STYLE_SHARED_ACCESS_FAIL = '[Styles] AddUpdateDelete Style Shared Access Fail',
    LOAD_STYLE_COLORWAYS = '[Styles] Load Style Colorways',
    LOAD_STYLE_COLORWAYS_SUCCESS = '[Styles] Load Style Colorways Success',
    LOAD_STYLE_COLORWAYS_FAIL = '[Styles] Load Style Colorways Fail',
    LOAD_STYLE_BOM = '[Styles] Load Style Bom',
    LOAD_STYLE_BOM_SUCCESS = '[Styles] Load Style Bom Success',
    LOAD_STYLE_BOM_FAIL = '[Styles] Load Style Bom Fail',
    LOAD_STYLE_IMAGES = '[Styles] Load Style Images',
    LOAD_STYLE_IMAGES_SUCCESS = '[Styles] Load Style Images Success',
    LOAD_STYLE_IMAGES_FAIL = '[Styles] Load Style Images Fail',
    LOAD_STYLE_PATTERNS = '[Styles] Load Style Patterns',
    LOAD_STYLE_PATTERNS_SUCCESS = '[Styles] Load Style Patterns Success',
    LOAD_STYLE_PATTERNS_FAIL = '[Styles] Load Style Patterns Fail',
    LOAD_STYLE_CONSTRUCTION = '[Styles] Load Style Construction',
    LOAD_STYLE_CONSTRUCTION_SUCCESS = '[Styles] Load Style Construction Success',
    LOAD_STYLE_CONSTRUCTION_FAIL = '[Styles] Load Style Construction Fail',
    LOAD_STYLE_ALL_USERS = '[Styles] Load Style All Users',
    LOAD_STYLE_ALL_USERS_SUCCESS = '[Styles] Load Style All Users Success',
    LOAD_STYLE_ALL_USERS_FAIL = '[Styles] Load Style All Users Fail',
    LOAD_STYLE_SHARED_ACCESS = '[Styles] Load Style Shared Access',
    LOAD_STYLE_SHARED_ACCESS_SUCCESS = '[Styles] Load Style Shared Access Success',
    LOAD_STYLE_SHARED_ACCESS_FAIL = '[Styles] Load Style Shared Access Fail',
    LOAD_STYLE_NOTIFICATIONS = '[Styles] Load Style Notifications',
    LOAD_STYLE_NOTIFICATIONS_SUCCESS = '[Styles] Load Style Notifications Success',
    LOAD_STYLE_NOTIFICATIONS_FAIL = '[Styles] Load Style Notifications Fail',
}

export class LoadStylesLibrary implements Action {
    readonly type = StylesActionTypes.LOAD_STYLES_LIBRARY
    constructor(public payload: any ,public payload1: any){}
}

export class LoadStylesLibrarySuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLES_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStylesLibraryFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLES_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export class LoadStyles implements Action {
    readonly type = StylesActionTypes.LOAD_STYLES

    constructor(public payload: string) { }
}

export class LoadStylesSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLES_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStylesFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLES_FAIL

    constructor(public payload: string) { }
}
export class CreateStyles implements Action {
    readonly type = StylesActionTypes.CREATE_STYLES

    constructor(public payload: any) { }
}

export class CreateStylesSuccess implements Action {
    readonly type = StylesActionTypes.CREATE_STYLES_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateStylesFail implements Action {
    readonly type = StylesActionTypes.CREATE_STYLES_FAIL

    constructor(public payload: string) { }
}
export class UpdateStyles implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLES

    constructor(public payload: any) { }
}

export class UpdateStylesSuccess implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLES_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateStylesFail implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLES_FAIL

    constructor(public payload: string) { }
}

export class UpdateStylesLibrary implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLES_LIBRARY

    constructor(public payload: any[]) { }
}

export class UpdateStylesLibrarySuccess implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLES_LIBRARY_SUCCESS

    constructor(public payload1: Update<any>[],public payload2: any) { }
}

export class UpdateStylesLibraryFail implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLES_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class DeleteStylesLibrary implements Action {
    readonly type = StylesActionTypes.DELETE_STYLES_LIBRARY

    constructor(public payload: string[]) { }
}

export class DeleteStylesLibrarySuccess implements Action {
    readonly type = StylesActionTypes.DELETE_STYLES_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class DeleteStylesLibraryFail implements Action {
    readonly type = StylesActionTypes.DELETE_STYLES_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class UpdateStyleColorways implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLE_COLORWAYS

    constructor(public payload: any) { }
}

export class UpdateStyleColorwaysSuccess implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLE_COLORWAYS_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateStyleColorwaysFail implements Action {
    readonly type = StylesActionTypes.UPDATE_STYLE_COLORWAYS_FAIL

    constructor(public payload: string) { }
}
export class AddUpdateDeleteStylePatterns implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_PATTERNS

    constructor(public payload: any) { }
}

export class AddUpdateDeleteStylePatternsSuccess implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_PATTERNS_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class AddUpdateDeleteStylePatternsFail implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_PATTERNS_FAIL

    constructor(public payload: string) { }
}

export class AddUpdateDeleteStyleImages implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_IMAGES

    constructor(public payload: any) { }
}

export class AddUpdateDeleteStyleImagesSuccess implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_IMAGES_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class AddUpdateDeleteStyleImagesFail implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_IMAGES_FAIL

    constructor(public payload: string) { }
}
export class AddUpdateDeleteStyleConstruction implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_CONSTRUCTION

    constructor(public payload: any) { }
}

export class AddUpdateDeleteStyleConstructionSuccess implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_CONSTRUCTION_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class AddUpdateDeleteStyleConstructionFail implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_CONSTRUCTION_FAIL

    constructor(public payload: string) { }
}
export class AddUpdateDeleteStyleSpecification implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_SPECIFICATION

    constructor(public payload: any) { }
}

export class AddUpdateDeleteStyleSpecificationSuccess implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_SPECIFICATION_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class AddUpdateDeleteStyleSpecificationFail implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_SPECIFICATION_FAIL

    constructor(public payload: string) { }
}
export class AddUpdateDeleteStyleProcessing implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_PROCESSING

    constructor(public payload: any) { }
}

export class AddUpdateDeleteStyleProcessingSuccess implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_PROCESSING_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class AddUpdateDeleteStyleProcessingFail implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_PROCESSING_FAIL

    constructor(public payload: string) { }
}
export class AddUpdateDeleteStyleBom implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_BOM

    constructor(public payload: any) { }
}

export class AddUpdateDeleteStyleBomSuccess implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_BOM_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class AddUpdateDeleteStyleBomFail implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_DEL_STYLE_BOM_FAIL

    constructor(public payload: string) { }
}
export class AddUpdateStyleSharedAccess implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_STYLE_SHARED_ACCESS

    constructor(public payload: any) { }
}

export class AddUpdateStyleSharedAccessSuccess implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_STYLE_SHARED_ACCESS_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class AddUpdateStyleSharedAccessFail implements Action {
    readonly type = StylesActionTypes.ADD_UPDATE_STYLE_SHARED_ACCESS_FAIL

    constructor(public payload: string) { }
}

export class LoadStyleColorways implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_COLORWAYS

    constructor(public payload: string) { }
}

export class LoadStyleColorwaysSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_COLORWAYS_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStyleColorwaysFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_COLORWAYS_FAIL

    constructor(public payload: string) { }
}

export class LoadStyleBom implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_BOM

    constructor(public payload: string) { }
}

export class LoadStyleBomSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_BOM_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStyleBomFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_BOM_FAIL

    constructor(public payload: string) { }
}
export class LoadStyleImages implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_IMAGES

    constructor(public payload: string) { }
}

export class LoadStyleImagesSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_IMAGES_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStyleImagesFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_IMAGES_FAIL

    constructor(public payload: string) { }
}
export class LoadStylePatterns implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_PATTERNS

    constructor(public payload: string) { }
}

export class LoadStylePatternsSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_PATTERNS_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStylePatternsFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_PATTERNS_FAIL

    constructor(public payload: string) { }
}
export class LoadStyleConstruction implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_CONSTRUCTION

    constructor(public payload: string) { }
}

export class LoadStyleConstructionSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_CONSTRUCTION_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStyleConstructionFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_CONSTRUCTION_FAIL

    constructor(public payload: string) { }
}
export class LoadStyleAllUsers implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_ALL_USERS
}

export class LoadStyleAllUsersSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_ALL_USERS_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStyleAllUsersFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_ALL_USERS_FAIL

    constructor(public payload: string) { }
}
export class LoadStyleSharedAccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_SHARED_ACCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStyleSharedAccessSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_SHARED_ACCESS_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStyleSharedAccessFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_SHARED_ACCESS_FAIL

    constructor(public payload: string) { }
}
export class LoadStyleNotifications implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_NOTIFICATIONS
}

export class LoadStyleNotificationsSuccess implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_NOTIFICATIONS_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStyleNotificationsFail implements Action {
    readonly type = StylesActionTypes.LOAD_STYLE_NOTIFICATIONS_FAIL

    constructor(public payload: string) { }
}
export type stylesActionTypes = LoadStylesLibrary | LoadStylesLibrarySuccess | LoadStylesLibraryFail |
    LoadStyles | LoadStylesSuccess | LoadStylesFail | CreateStyles | CreateStylesSuccess |
    CreateStylesFail | UpdateStyles | UpdateStylesSuccess | UpdateStylesFail | UpdateStylesLibrary | UpdateStylesLibrarySuccess
    | UpdateStylesLibraryFail | DeleteStylesLibrary | DeleteStylesLibrarySuccess | DeleteStylesLibraryFail
    | UpdateStyleColorways | UpdateStyleColorwaysSuccess | UpdateStyleColorwaysFail | AddUpdateDeleteStylePatterns
    | AddUpdateDeleteStylePatternsSuccess | AddUpdateDeleteStylePatternsFail | AddUpdateDeleteStyleConstruction
    | AddUpdateDeleteStyleConstructionSuccess | AddUpdateDeleteStyleConstructionFail | AddUpdateDeleteStyleSpecification
    | AddUpdateDeleteStyleSpecificationSuccess | AddUpdateDeleteStyleSpecificationFail | AddUpdateDeleteStyleProcessing
    | AddUpdateDeleteStyleProcessingSuccess | AddUpdateDeleteStyleProcessingFail | AddUpdateDeleteStyleBom
    | AddUpdateDeleteStyleBomSuccess | AddUpdateDeleteStyleBomFail | LoadStyleColorways | LoadStyleColorwaysSuccess
    | LoadStyleColorwaysFail | AddUpdateDeleteStyleImages | AddUpdateDeleteStyleImagesSuccess | AddUpdateDeleteStyleImagesFail
    | AddUpdateStyleSharedAccess | AddUpdateStyleSharedAccessSuccess | AddUpdateStyleSharedAccessFail
    | LoadStyleBom | LoadStyleBomSuccess | LoadStyleBomFail | LoadStyleImages | LoadStyleImagesSuccess | LoadStyleImagesFail
    | LoadStylePatterns | LoadStylePatternsSuccess | LoadStylePatternsFail | LoadStyleConstruction | LoadStyleConstructionSuccess | LoadStyleConstructionFail
    | LoadStyleAllUsers | LoadStyleAllUsersSuccess | LoadStyleAllUsersFail | LoadStyleSharedAccess | LoadStyleSharedAccessSuccess | LoadStyleSharedAccessFail
    | LoadStyleNotifications | LoadStyleNotificationsSuccess | LoadStyleNotificationsFail;