import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { Observable } from 'rxjs';
//import { BehaviorSubject } from 'rxjs/BehaviorSubject';
//import { fromPromise } from 'rxjs/observable/fromPromise';
//import { from } from 'rxjs';
//import { map, tap, catchError } from 'rxjs/operators';
//import { of } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import Amplify, { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import awsconfig from '../../../../aws-exports';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router,
    private http: HttpClient) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }


  async SignIn(username, password) {
    return await Auth.signIn(username, password);
  }

  async checkUser() {
    /* Auth.currentSession()
        .then(data => {
          return data;
        }).catch (err => {
            console.log(err)
          });
          */
       
    return await Auth.currentSession();
  }

  // tslint:disable-next-line: variable-name
  // async signUp(username: any, email: any, password: any, phone_number: any, role: any) {
  //   return await Auth.signUp({
  //     username,
  //     password,
  //     attributes: {
  //       email,
  //       phone_number
  //     }
  //   });

  // }

  async confirmSignUp(username, code) {
    try {
      return await Auth.confirmSignUp(username, code);
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  async signOut() {
    return await Auth.signOut({ global: true });

  }

  async configupdate() {
    AWS.config.update({
      region: awsconfig.aws_project_region, accessKeyId: 'AKIAW7NQSUNQROBYVTD5',
      secretAccessKey: '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'
    });
  }
  
  async callCreateGroup(params) {
    this.configupdate();
    // tslint:disable-next-line: prefer-const
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.createGroup(params, function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred     
          reject(err)
        }
        else {
          //console.log(data);  // successful response
          resolve(data);
        }
      })
    });
  }
  
  async calladdUsertoGroup(params) {
    this.configupdate();
    // tslint:disable-next-line: prefer-const
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.adminAddUserToGroup(params, function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred     
          reject(err)
        }
        else {
          //console.log(data);  // successful response
          resolve(data);
        }
      })
    });
  }
  
  async callremoveUserFromGroup(params) {
    this.configupdate();
    // tslint:disable-next-line: prefer-const
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.adminRemoveUserFromGroup(params, function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred     
          reject(err)
        }
        else {
          //console.log(data);  // successful response
          resolve(data);
        }
      })
    });
  }
  
  async callUpdateUserAttributes(params) {
    this.configupdate();
    // tslint:disable-next-line: prefer-const
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred     
          reject(err)
        }
        else {
          //console.log(data);  // successful response
          resolve(data);
        }
      })
    });
  }

  async callDeleteGroup(params) {
    this.configupdate();
    // tslint:disable-next-line: prefer-const
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.deleteGroup(params, function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred     
          reject(err)
        }
        else {
          //console.log(data);  // successful response
          resolve(data);
        }
      })
    });
  }

  async callDeleteUser(params) {
    this.configupdate();
    // tslint:disable-next-line: prefer-const
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.adminDeleteUser(params, function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred     
          reject(err)
        }
        else {
          //console.log(data);  // successful response
          resolve(data);
        }
      })
    });
  }


}
