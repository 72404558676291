<!--
  FITOS Registration Page
-->
<app-loader [showLoader]="showLoader"></app-loader>
<!-- Root -->
<div class="dt-root">
  <div class="dt-root__inner">
    <div class="dt-login--container">

      <!-- Register Content -->
      <div class="dt-login__content-wrapper">

        <!-- Register Background Section -->
        <div class="dt-login__bg-section dt-slider">

          <div class='slide1'></div>
          <div class='slide2'></div>
          <div class='slide3'></div>

          <div class="dt-login__bg-content">
            <!-- <h1 class="dt-login__title c-pointer" *ngIf='backarrow'>
              <i class="fa fa-arrow-left mr-10" aria-hidden="true" (click)="backarraowclick()" *ngIf='backarrow'></i>
            </h1> -->

            <!-- Register Title -->
            <h1 class="dt-login__title" *ngIf='!registrationtext'>{{staticMsgs.registration.Register}}</h1>
            <!-- /Register title -->

            <h2 class="f-26 text-white mt-150" *ngIf='registrationtext'>{{staticMsgs.registration.Register}} <br>{{staticMsgs.registration.asA}}{{flag}}</h2>

          </div>


          <!-- Brand logo -->
          <div class="dt-login__logo text-center">
            <a class="dt-brand__logo-link">
              <img class="dt-brand__logo-img" alt="Fitos">
            </a>
          </div>

          <!-- /brand logo -->

        </div>
        <!-- /Register background section -->

        <div class="text-center ml-auto mr-auto" id="select_type" *ngIf="registrationdiv">
          <div class="col-sm-12 text-center">
            <h1 class="primary-text-color dt-login__title mt-7">{{staticMsgs.registration.SelectUserType}}</h1>
          </div>
          <div class="form-row mt-40">
            <div class="col-sm-6 mt-40">
              <h2 class="primary-text-color">{{staticMsgs.registration.Maker}}</h2>
              <!-- <img src={{makerImg}} (click)="makerclick('Maker')" class="w-350 img-fluid img-effect"> -->
            </div>
            <div class="col-sm-6 mt-40">
              <h2 class="primary-text-color">{{staticMsgs.registration.Collaborator}}</h2>
              <!-- <img src={{collaboratorImg}} (click)="collaboratorclick('Collaborator')" class="w-350 img-fluid img-effect"> -->
            </div>
          </div>
          <div class="col-sm-12 text-center mt-50">
            <h5 (click)="open(content1)" class="primary-text-color c-pointer link_underline">{{staticMsgs.registration.IamNotsurewhatToselect}}</h5>
          </div>
        </div>

        <!-- Register Content Section -->
        <div class="dt-login__content" *ngIf="!registrationdiv">

          <!-- Register Content Inner -->
          <div class="dt-login__content-inner">

            <!-- Card Header -->
            <!-- <div class="card-header">
              Tab Navigation
              <ul class="card-header-pills nav nav-pills nav-fill" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active show" data-toggle="tab" href="" role="tab"
                    [ngClass]="{ 'active':flag==='Maker'}" (click)="makerclick('Maker')" aria-controls="tab-maker"
                    aria-selected="true">I am a maker
                    <span><a href="" data-toggle="popover" data-trigger="focus" title="" data-placement="bottom"
                        data-content=" <p>I design and/or sell apparel products (or) I am a Fashion Entrepreneur (or) I help a Fashion business develop new apparel products.</p>"
                        data-original-title="I am a maker">
                        <i class="icon icon-question-circle icon-fw icon-sm"></i></a>
                    </span>
                  </a>

                  <i class="icon icon-question-circle icon-fw icon-sm" data-toggle="tooltip"
              data-placement="top" title="Tooltip on top"></i>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="" role="tab" [ngClass]="{ 'active':flag==='Collaborator'}"
                    (click)="collaboratorclick('Collaborator')" aria-controls="tab-collaborator" aria-selected="false">I
                    am a collaborator
                    <span><a href="" data-toggle="popover" data-trigger="focus" title="" data-placement="bottom"
                        data-content=" <p>I provide services / supply to a Fashion business. I supply materials, fabrics, accessories.
              I provide pattern making / illustration / art / compliance services. </p> "
                        data-original-title="I am a collaborator">
                        <i class="icon icon-question-circle icon-fw icon-sm"></i></a>
                    </span>

                  </a>

                </li>
              </ul>
              /tab navigation
            </div> -->
            <!-- /card header -->
            <!-- Tab Content -->
            <div class="tab-content">

              <!-- Tab Pane -->
              <div class="tab-pane active show">
                <div class="card-body">
                  <form [formGroup]="regForm" (ngSubmit)="onSubmit()" class="col-sm-12">
                    <div class="form-row">

                      <div class="col-sm-6 mb-3">
                        <label for="firstname">{{staticMsgs.registration.FirstName}}<span class="red_icon">*</span></label>
                        <input type="text" formControlName="firstname" id="firstname" class="form-control" minlength="3"
                          maxlength="40" required />
                        <div
                          *ngIf="(submitted && this.f.firstname.errors) ||(this.f.firstname.invalid && (this.f.firstname.dirty || this.f.firstname.touched))">
                          <div *ngIf="this.f.firstname.errors.required" class="red">
                            {{staticMsgs.registration.FirstName+staticMsgs.common.required}}
                          </div>
                          <div *ngIf="this.f.firstname.errors.minlength" class="red">
                            {{staticMsgs.registration.FirstName+staticMsgs.registration.mustbe+staticMsgs.registration.charecters3}}
                          </div>
                          <div *ngIf="this.f.firstname.errors.maxlength" class="red">
                            {{staticMsgs.registration.FirstName+staticMsgs.registration.shouldNot+staticMsgs.registration.Exceed}}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 mb-3">
                        <label for="lastname">{{staticMsgs.registration.LastName}}<span class="red_icon">*</span></label>
                        <input type="text" formControlName="lastname" id="lastname" class="form-control" minlength="1"
                          maxlength="15" required />
                        <div
                          *ngIf="(submitted && this.f.lastname.errors) || ( this.f.lastname.invalid && (this.f.lastname.dirty || this.f.lastname.touched))">
                          <div *ngIf="this.f.lastname.errors.required" class="red">
                            {{staticMsgs.registration.LastName+staticMsgs.common.required}}

                          </div>
                          <div *ngIf="this.f.lastname.errors.minlength" class="red">
                            {{staticMsgs.registration.LastName+staticMsgs.registration.mustbe+staticMsgs.registration.characterslong}}
                          </div>
                          <div *ngIf="this.f.lastname.errors.maxlength" class="red">
                            {{staticMsgs.registration.LastName+staticMsgs.registration.shouldNot+staticMsgs.registration.charecters15}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div [ngClass]="{'disableDiv': isDisabled}" class="col-sm-6 mb-3">
                        <label for="email">{{staticMsgs.registration.EmailAddress}}<span class="red_icon">*</span></label>
                        <input  [disabled]='isDisabled' type="email" formControlName="email" id="email" class="form-control" required />
                        <div *ngIf="(submitted && this.f.email.errors) || (this.f.email.invalid && (this.f.email.dirty || this.f.email.touched))">
                          <div *ngIf="this.f.email.errors.required" class="red">
                            {{staticMsgs.common.email+staticMsgs.common.required}}
                          </div>
                          <div *ngIf="this.f.email.errors.email" class="red">
                            {{staticMsgs.registration.enterValidEmail}}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 mb-3">
                        <label for="phone_number_code">{{staticMsgs.registration.ContactNumber}}</label>
                        <div class="form-row">
                          <div class="col-sm-5">
                            <!-- <ng-select2 formControlName="phone_number_code" id="phone_number_code"
                            class="" [data]="countrycodes" [width]="154" ></ng-select2> -->
                            <select formControlName="phone_number_code" id="phone_number_code"
                              class="form-control dt-contact-select selectpicker" data-live-search="true">
                              <option *ngFor="let countrycode of countrycodes" [value]="countrycode.value">
                                {{ countrycode.name }}</option>
                              <!-- <option value="+1">+1</option>
                              <option value="+91">+91</option>
                              <option value="+65">+65</option>
                              <option value="+134">+134</option> -->
                            </select>
                          </div>
                          <div class="col-sm-7">
                            <input type="phone" formControlName="phone_number" id="phone_number" class="form-control"(keypress)="numberOnly($event)"
                              maxlength="10" />
                            <!-- <div
                              *ngIf="(submitted && this.f.phone_number.errors) || (this.f.phone_number.invalid && (this.f.phone_number.dirty || this.f.phone_number.touched))">
                              <div *ngIf="this.f.phone_number.errors.required" class="red">
                                Phone number is required.
                              </div>
                            </div> -->
                          </div>
                          <div class="ml-3"
                            *ngIf="(submitted && this.f.phone_number.errors) || (this.f.phone_number.invalid && (this.f.phone_number.dirty || this.f.phone_number.touched))">
                            <div *ngIf="this.f.phone_number.errors.required" class="red">
                              {{staticMsgs.registration.ContactNumber+staticMsgs.common.required}}
                            </div>
                            <div *ngIf="this.f.phone_number.errors.pattern" class="red">
                              {{staticMsgs.registration.validMobile}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-sm-6">
                        <label for="password">{{staticMsgs.common.password}}<span class="red_icon">*</span></label>
                        <input type="password" formControlName="password" id="password" class="form-control"
                          minlength="8" maxlength="15" required />
                        <span toggle="#password" class="fa fa-eye-slash field-icon toggle-password"></span>
                        <div
                          *ngIf="(submitted && this.f.password.errors) || (this.f.password.invalid && (this.f.password.dirty || this.f.password.touched))">
                          <div *ngIf="this.f.password.errors.required" class="red">
                            {{staticMsgs.common.password+staticMsgs.common.required}}
                          </div>

                          <div
                            *ngIf="(this.f.password.errors.minlength) || (this.f.password.errors.maxlength) || (this.f.password.errors.hasNumber) || (this.f.password.errors.hasCapitalCase) || (this.f.password.errors.hasSmallCase) || (this.f.password.errors.hasSpecialCharacters)"
                            class="red">
                            {{staticMsgs.forgotpassword.InvalidPasswordFormat}}
                          </div>

                        </div>

                        <div class="" id="password-validation-box" style="display: none;">
                          <form>
                            <fieldset class="fieldset-password">
                              <div id="password-info">
                                <ul>
                                  <li id="length" class="invalid clearfix">
                                    <span class="icon-container">
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    {{staticMsgs.forgotpassword.Atleast8Characters}}
                                  </li>
                                  <li id="capital" class="invalid clearfix">
                                    <span class="icon-container">
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    {{staticMsgs.forgotpassword.Atleast1UppercaseLetter}}
                                  </li>
                                  <li id="lowercase" class="invalid clearfix">
                                    <span class="icon-container">
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    {{staticMsgs.forgotpassword.Atleast1LowercaseLetter}}
                                  </li>
                                  <li id="number-special" class="invalid clearfix">
                                    <span class="icon-container">
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    <span title="" class="special-characters tip"
                                      data-original-title="! @ #  \ $ % ^ &amp; * ">{{staticMsgs.forgotpassword.specialCharecter}}</span>
                                  </li>
                                  <li id="number-special1" class="invalid clearfix">
                                    <span class="icon-container">
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    {{staticMsgs.forgotpassword.Atleast1Number}}
                                  </li>
                                </ul>
                              </div>
                            </fieldset>
                          </form>
                        </div>


                      </div>
                      <div class="col-sm-6">
                        <label for="confirmpassword">{{staticMsgs.forgotpassword.ConfirmPassword}}<span class="red_icon">*</span></label>
                        <input type="password" formControlName="confirmpassword" id="confirmpassword"
                          class="form-control" required />
                        <span toggle="#confirmpassword" class="fa fa-eye field-icon toggle-password"></span>
                        <div
                          *ngIf="(submitted && this.f.confirmpassword.errors) || (this.f.confirmpassword.invalid && (this.f.confirmpassword.dirty || this.f.confirmpassword.touched))">
                          <div *ngIf="this.f.confirmpassword.errors.required" class="red">
                            {{staticMsgs.forgotpassword.ConfirmPassword+staticMsgs.common.required}}
                          </div>
                          <div *ngIf="this.f.confirmpassword.errors.confirmedValidator" class="red">
                            {{staticMsgs.forgotpassword.doesnotMatch}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">

                      <div [ngClass]="{'disableDiv': isDisabled}" class="col-sm-6 mb-6 mt-5">
                        <label for="company"> {{staticMsgs.userregister.company}}
                          <span><a href="javascript:void(0)" data-toggle="popover" data-trigger="focus" title=""
                              data-placement="bottom"
                              data-content=" <p>Enter unique name under which your company/organisation is registered. In case the system doesn’t accept the name, Please try the suggested names given by System. Still if you are facing any issue please contact FitOs Support Team.</p>">
                              <i class="icon icon-question-circle icon-fw icon-sm"></i></a>
                          </span>
                        </label>
                        <input [disabled]='isDisabled' type="text" formControlName="company" id="company" class="form-control" maxlength="100">
                        <div
                          *ngIf="(submitted && this.f.company.errors) || (this.f.company.invalid && (this.f.company.dirty || this.f.company.touched))">
                          <div *ngIf="this.f.company.errors.pattern" class="red">
                            {{staticMsgs.registration.nameValidations}}
                          </div>
                          <div *ngIf="this.f.company.errors.maxlength" class="red">
                            {{staticMsgs.registration.Name+staticMsgs.registration.shouldNot+staticMsgs.registration.Charecters}}
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 mb-6 mt-5">
                        <label>{{staticMsgs.registration.Timezone}}</label>
                        <select formControlName="timeZone" id="timeZone"
                              class="form-control dt-contact-select selectpicker" data-live-search="true">
                                <optgroup *ngFor="let time of TimeZone" [label]="time.value">
                                  <option *ngFor="let utc of time.utc;let i=index"  [value]="utc">{{utc}}</option>
                                </optgroup>
                            </select>
                      </div>

                    </div>



                    <!-- Form Group -->
                    <div class="dt-checkbox d-block mb-6">
                      <input type="checkbox" formControlName="terms_and_conditions" id="terms_and_conditions"
                        required />
                      <label class="dt-checkbox-content" for="terms_and_conditions"> {{staticMsgs.registration.Byregister}}
                        <a href="javascript:void(0)" (click)="open(content)">{{staticMsgs.registration.Terms}} &amp;
                          {{staticMsgs.registration.Conditions}}</a>
                      </label>
                      <div
                        *ngIf="(submitted && f.terms_and_conditions.errors) || (this.f.terms_and_conditions.invalid && (this.f.terms_and_conditions.dirty || this.f.terms_and_conditions.touched))">
                        <div *ngIf="this.f.terms_and_conditions.errors.required" class="red">
                          {{staticMsgs.registration.accept}}
                        </div>
                      </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group">
                      <div class="dt-flex">
                        <button type="submit" class="btn btn-primary">{{staticMsgs.registration.Register}}</button>
                        <!-- <span class="d-inline-block ml-4 m-a">Or
                          <span class="d-inline-block mr-2">Sign up with</span>
                        </span>
                        <ul class="dt-list dt-list-sm dt-list-cm-0 ml-auto">
                          <li class="dt-list__item"> -->
                        <!-- Fab Button -->
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary dt-fab-btn size-30">
                              <i class="icon icon-facebook icon-xl"></i>
                            </a> -->
                        <!-- /fab button -->
                        <!-- </li>

                          <li class="dt-list__item"> -->
                        <!-- Fab Button -->
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary dt-fab-btn size-30">
                              <i class="icon icon-google-plus icon-xl"></i>
                            </a> -->
                        <!-- /fab button -->
                        <!-- </li>

                          <li class="dt-list__item"> -->
                        <!-- Fab Button -->
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary dt-fab-btn size-30">
                              <i class="icon icon-github icon-xl"></i>
                            </a> -->
                        <!-- /fab button -->
                        <!-- </li>

                          <li class="dt-list__item>" -->
                        <!-- Fab Button -->
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary dt-fab-btn size-30">
                              <i class="icon icon-twitter icon-xl"></i>
                            </a> -->
                        <!-- /fab button -->
                        <!-- </li>
                        </ul> -->
                      </div>
                    </div>
                    <!-- /form group -->

                    <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div>

                  </form>

                </div>
              </div>
              <!-- /tab pane-->

            </div>
            <!-- /tab content -->

          </div>
          <!-- /Register content inner -->

          <!-- Register Content Footer -->
          <!-- <div class="dt-login__content-footer">
            <a>Already have an account?</a>
            <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active"
              class="d-inline-block font-weight-500 mr-2" href>&nbsp;Login Here</a>
          </div> -->
          <!-- /Register content footer -->

        </div>
        <!-- /Register content section -->

      </div>
      <!-- /Register content -->

    </div>
  </div>
</div>
<!-- /root -->

<!-- Terms Cond. Modal -->
<ng-template #content let-modal>

  <!-- Modal Content -->
  <div class="modal-content">

    <!-- Modal Header -->
    <div class="modal-header">
      <h3 class="modal-title" id="model-3">{{staticMsgs.userregister.Title}}</h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- /modal header -->

    <!-- Modal Body -->
    <div class="modal-body">
      Cras mattis consectetur purus sit amet fermentum. Cras justo
      odio,
      dapibus ac facilisis in,
      egestas eget quam. Morbi leo risus, porta ac consectetur ac,
      vestibulum at eros.
    </div>
    <!-- /modal body -->
  </div>
  <!-- /modal content -->

</ng-template>
<!-- /Terms Cond. Modal  -->

<!-- Role selection. Modal -->
<ng-template #content1 let-modal>

  <!-- Modal Content -->
  <div class="modal-content">

    <!-- Modal Header -->
    <div class="modal-header">
      <h3 class="modal-title" id="model-3">{{staticMsgs.userregister.WhatToSelect}}</h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- /modal header -->

    <!-- Modal Body -->
    <div class="modal-body text-center ">
      <div class="row m-auto ">
        <div class="col-sm-6">
            <h3  class="primary-text-color">{{staticMsgs.registration.Maker}}</h3>
            <img src={{makerImg}} class="w-300 img-fluid img-effect">
            <h6 class="mt-6">I design and/or sell apparel products (or) I am a Fashion Entrepreneur (or) I help a Fashion business develop new apparel products.</h6>
        </div>
        <div class="col-sm-6">
            <h3  class="primary-text-color">{{staticMsgs.registration.Collaborator}}</h3>
            <img src={{collaboratorImg}} class="w-300 img-fluid img-effect">
            <h6 class="mt-6">I provide services / supply to a Fashion business. I supply materials, fabrics, accessories.
              I provide pattern making / illustration / art / compliance services. </h6>
            </div>
       </div>
     </div>
     <!-- /modal body -->
  </div>
  <!-- /modal content -->

</ng-template>
<!-- /Role selection. Modal  -->

