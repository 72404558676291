import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ColorEvent } from 'ngx-color';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import { SharedService } from '../../../services/shared.service';
import { IColor, ColourDocuments } from '../../../models/color';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import * as S3 from 'aws-sdk/clients/s3';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as stitchesReducer from "../../../reducers/stitches.reducer";
import * as stitchesActionTypes from "../../../actions/stitches.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { Router } from '@angular/router';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { MatDialogRef } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-stitches-custom',
  templateUrl: './stitches-custom.component.html',
  styleUrls: ['./stitches-custom.component.css']
})
export class StitchesCustomComponent implements OnInit {
  stitchesForm: FormGroup;
  documentsForm: FormGroup;
  public stichesDataCnt = 0;
  notfoundImg: string;
  activeTab = 'stitchesDetailsTab';
  isIDVisible = false;
  isTopFileError = false;
  isBottomFileError = false;
  sequenceNumber = '';
  showButton: boolean = false;
  isFilesAdded: boolean =false;
  get f() { return this.stitchesForm.controls; }
  topView: File[] = [];
  bottomView: File[] = [];
  editedtopViewfile: File[] = [];
  editedtopViewValue = '';
  editedbottomViewfile: File[] = [];
  editedbottomViewValue = '';
  files: File[] = [];
  fileData: Array<any> = [];
  editedFileData: Array<any> = [];
  removedDocIds = '';
  documentClassificationData: any[];
  cardsTop = [];
  cardsBottom = [];
  appStaticMsgs: any;
  documentImage = "../../../../assets/images/doccard/document.png";
  cards = [];
  editedStitchesData: any;
  isCreateMode: boolean;
  isTopViewAdded: boolean = false;
  isBottomViewAdded: boolean = false;
  showLoader: boolean;
  showLoader1: boolean;
  submitted = false;
  submitted1 = false;
  isEditMode: boolean;
  isEditingMode: boolean;
  isViewingMode: boolean;
  log: any[];
  editedThumbnails: File[] = [];
  thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  /* Written by : Himanshu */

  imageObject: Array<object> = [];

  //   imageObject = [{
  //     image: '../../../../assets/images/color-card/1.png',
  //     thumbImage: '../../../../assets/images/color-card/1.png',
  // }
  // ];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private dataservice: DataService,
    private storageservice: StorageService,
    public userservice: UserService,
    public aesdecryptservice: AESDecryptService,
    private dialogRef: MatDialogRef<StitchesCustomComponent>,
    private logger: LogService,
    private _lightbox: Lightbox,
    private store: Store<any>) {
    this.documentsForm = this.fb.group({
      documents: this.fb.array([])
    })
    //this.setDocuments();
  }


  ngOnInit(): void {
    this.appStaticMsgs = appStaticMsg.data[0];
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    this.dataservice.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    if (this.storageservice.getEditedStitchesId()) {
      this.isCreateMode = false;
      if (this.storageservice.getEditedStichesDetailsId()) {
        this.storageservice.removeStichesDetailsId();
        // this.isEditMode = false;
        // this.isEditingMode = true;
        // this.isViewingMode = false;
        this.editStitches();
      }
      else {
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.isIDVisible = true;
        this.getStitchesDetails();
      }
    }
    else {
      this.getDocumentClassification("Documents");
      this.isCreateMode = true;
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isIDVisible = false;
      this.isViewingMode = false;
    }
    this.initForm();
    this.loadSelectPicker();
    this.sharedService.sendClickEvent();
  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataservice.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  initForm() {
    this.showLoader = true;

    this.stitchesForm = this.fb.group({
      sequence: [{ value: '', disabled: true }],
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      isO4915Number: ['', [Validators.required, Validators.maxLength(20)]],
      commonApplication: ['', [Validators.required, Validators.maxLength(500)]],
      requirements: ['', Validators.maxLength(500)],
      status: ['', [Validators.required]],
      description: ['', Validators.maxLength(500)],
      topviewComments: ['', Validators.maxLength(500)],
      bottomviewComments: ['', Validators.maxLength(500)],
    });
    this.showLoader = false;
  }

  characterOnly(e: any) {
    var regex = new RegExp("^[[A-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characternameOnly(e: any) {
    var regex = new RegExp("^[[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select supplier Standard' });
    });
  }

  editStitches() {
    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;

    if (this.activeTab === "stitchesDetailsTab") {
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
      this.isTopViewAdded = true;
      this.isBottomViewAdded = true;
      if (this.storageservice.getEditedStitchesId()) {
        this.getStitchesDetails();
      }
      this.initForm();
      //this.loadSelectPicker();
    }
    else if (this.activeTab === "docDetailsTab") {
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
      this.showLoader = true;
      this.showButton=false;

      if (this.editedStitchesData.documents) {
        if (this.editedStitchesData.documents.length > 0) {
          this.editedFileData = [];
          this.removedDocIds = "";
          //this.removedFileData = [];
          this.isFilesAdded = true;
          let control = <FormArray>this.documentsForm.controls.documents;
          if (control.length > 0) {
            this.documentsForm = this.fb.group({
              documents: this.fb.array([])
            })
          }
          let editeddata = JSON.stringify(this.editedStitchesData.documents);
          let data = JSON.parse(editeddata);
          this.reloadDocumentFormData(data);
          this.showLoader = false;
        }
        else {
          this.showLoader = false;
        }
      }
      else {
        this.showLoader = false;
      }
    }

  }

  stitchesDetailsTab(activeTab) {
    this.activeTab = activeTab;
    this.isTopViewAdded = true;
    this.isBottomViewAdded = true;
    if (this.storageservice.getEditedStitchesId()) {
      this.getStitchesDetails();
    }

    this.initForm();
    //this.loadSelectPicker();
  }

  docDetailsTab(activeTab) {
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    this.activeTab = activeTab;
    this.showLoader = true;
    if (this.editedStitchesData.documents) {

      if (this.editedStitchesData.documents.length > 0) {

        this.editedFileData = [];
        this.isFilesAdded=true;
        this.removedDocIds = "";
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        let editeddata = JSON.stringify(this.editedStitchesData.documents);
        let data = JSON.parse(editeddata);

        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {

        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }

  getDocumentClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.logger.info(this.appStaticMsgs.stitches.documentclassificationloadedsuccessfully, "StichesDetails");
          this.documentClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.showLoader = false;
        } else {
          this.showLoader = false;
          this.error("Failed:" + classificationData.common.message?.item1)
          this.logger.error(classificationData.common.message?.item1, "StichesDetails");
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, "StichesDetails");
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })

    /* this.dataservice.getClassification(module).subscribe(response => {
     if (response.body['item2'] === "Success") {
       let parentclass = response.body['item1'][0]["items"];
       for (let i = 0; i < parentclass.length; i++) {
         parentclass[i].parentName = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i]['parentName']);
         parentclass[i].text = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i]['text']);

         if (parentclass[i].items.length > 0) {
           for (let j = 0; j < parentclass[i].items.length; j++) {
             parentclass[i].items[j].parentName = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i].items[j]['parentName']);
             parentclass[i].items[j].text = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i].items[j]['text']);
           }
         }
       }
       this.documentClassificationData = parentclass;
       this.logger.info("Document classification loaded successfully", "StichesDetails");
       //console.log(JSON.stringify(this.documentClassificationData ))
       //this.refreshDocTree();
       this.showLoader = false;
     }
     else {
       this.showLoader = false;
       this.error(response.body['item1']);
       this.logger.error(response.body['item1'], "StichesDetails");
     }
   }, err => {
     this.logger.error(err.error.message, "StichesDetails");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
       this.showLoader = false;
     } else if (err.error.message === "The incoming token has expired") {
       this.sessionTimedOut();
       this.showLoader = false;
     } else if (err.error.message === "Unauthorized") {
       this.userservice.error();
       this.showLoader = false;
     }
     else {
       this.error(err.message);
       this.logger.error(err.message, "StichesDetails");

     }
     this.showLoader = false;
   }); */
  }

  cancelStitchesEdit() {
    (this.documentsForm.get('documents') as FormArray).clear();
    if (this.storageservice.getEditedStitchesId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getStitchesDetails();
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.initForm();
    //this.loadSelectPicker();
  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader = false;
  }

  getStitchesDetails() {
    this.showLoader1 = true;
    this.store.dispatch(new stitchesActionTypes.LoadStitches(this.storageservice.getEditedStitchesId()));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((stitchesData) => {
      if (stitchesData.stitches.error === "") {
        if (stitchesData.stitches.message['item2'] === 'Success') {
          this.showLoader1 = false;
          this.editedStitchesData = stitchesData.stitches.entities[this.storageservice.getEditedStitchesId()];
          this.logger.info(stitchesData.stitches.message['item1']["isO4915Number"] + " loaded successfully", "StichesDetails");
          this.sequenceNumber = stitchesData.stitches.message['item1']["sequence"];
          this.initForm();
          this.getDocumentClassification("Documents");
          this.stitchesForm.get('sequence').setValue(stitchesData.stitches.message['item1']["sequence"]);
          this.stitchesForm.get('name').setValue(stitchesData.stitches.message['item1']["name"]);
          this.stitchesForm.get('isO4915Number').setValue(stitchesData.stitches.message['item1']["isO4915Number"]);
          this.stitchesForm.get('commonApplication').setValue(stitchesData.stitches.message['item1']["commonApplication"]);
          this.stitchesForm.get('requirements').setValue(stitchesData.stitches.message['item1']["requirements"]);
          this.stitchesForm.get('status').setValue(stitchesData.stitches.message['item1']["status"]);
          this.stitchesForm.get('description').setValue(stitchesData.stitches.message['item1']["description"]);
          this.stitchesForm.get('topviewComments').setValue(stitchesData.stitches.message['item1']["topviewComments"]);
          this.stitchesForm.get('bottomviewComments').setValue(stitchesData.stitches.message['item1']["bottomviewComments"]);
          this.refreshSelect();
          if (stitchesData.stitches.message['item1']["topViewFile"]) {
            /* Written by : Himanshu */
            this.editedtopViewfile[0] = stitchesData.stitches.message['item1']["topViewFile"];
            //  this.editedtopViewfile[0].["thumbnail"]=this.editedtopViewfile[0].thumbnail);
            //  this.editedtopViewfile=this.editedtopViewfile
            this.editedtopViewValue = stitchesData.stitches.message['item1']["topViewFile"]["thumbnail"];
            this.editedThumbnails = [];
            this.editedThumbnails.push(stitchesData.stitches.message['item1']["topViewFile"]);
            if (this.editedThumbnails.length > 0) {
              this.imageObject=[];
              this.editedThumbnails.forEach((x, idx, array) => {
                const temp = {
                  'image': x['thumbnail'],
                  'thumbImage': x['thumbnail']
                }
                this.imageObject.push(temp);
              })
            } else {
              const temp = {
                'image': this.thumbnail,
                'thumbImage': this.thumbnail
              }
              this.imageObject.push(temp);
            }


          }
          /* Written by : Himanshu */

          if (stitchesData.stitches.message['item1']["bottomViewFile"]) {
            this.editedbottomViewfile[0] = stitchesData.stitches.message['item1']["bottomViewFile"];
            this.editedbottomViewValue = stitchesData.stitches.message['item1']["bottomViewFile"]["thumbnail"];

            this.editedThumbnails = [];
            this.editedThumbnails.push(stitchesData.stitches.message['item1']["bottomViewFile"]);

            /* Written by : Himanshu */
            if (this.editedThumbnails.length > 0) {
              this.imageObject=[];
              this.editedThumbnails.forEach((x, idx, array) => {
                const temp = {
                  'image': x['thumbnail'],
                  'thumbImage': x['thumbnail']
                }
                this.imageObject.push(temp);
              })
            } else {
              const temp = {
                'image': this.thumbnail,
                'thumbImage': this.thumbnail
              }
              this.imageObject.push(temp);

            }

          }
        } else {
          this.showLoader1 = false;
          this.error("Failed:" + stitchesData.stitches.message['item1'])
          this.logger.error(stitchesData.stitches.message['item1'], "StitchesDetails");
        }
      } else {
        this.logger.error(stitchesData.stitches.error.error.message, "StitchesDetails");
        this.getError(stitchesData.stitches.error);
        this.showLoader1 = false;
      }
    })

    /* this.dataservice.getStitchesById(this.storageservice.getEditedStitchesId()).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.showLoader1 = false;
        this.editedStitchesData = response.body['item1'];
        this.logger.info(response.body['item1']["isO4915Number"] + " loaded successfully", "StichesDetails");
        this.initForm();
        this.stitchesForm.get('sequence').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sequence"]));
        this.stitchesForm.get('isO4915Number').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["isO4915Number"]));
        this.stitchesForm.get('commonApplication').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["commonApplication"]));
        this.stitchesForm.get('requirements').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["requirements"]));
        this.stitchesForm.get('status').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["status"]));
        this.stitchesForm.get('description').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["description"]));
        this.stitchesForm.get('topviewComments').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["topviewComments"]));
        this.stitchesForm.get('bottomviewComments').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["bottomviewComments"]));
        this.refreshSelect();

        // if (response.body['item1']["colourSwatch"]) {
        //   this.color = JSON.parse(response.body['item1']["colourSwatch"]);
        //   this.colourSwatch = response.body['item1']["colourSwatch"];
        // }
        // this.stitchesForm.get('colorhexacode').setValue(response.body['item1']["hexcode"]);
        // this.colorbg = response.body['item1']["hexcode"];
        // this.colorHex = response.body['item1']["hexcode"];
        // if (response.body['item1']["colorStandard"]) {
        //   this.stitchesForm.get('colorstandard').setValue(JSON.parse("[\"" + response.body['item1']["colorStandard"] + "\"]"));
        // }
        // this.stitchesForm.get('pantone').setValue(response.body['item1']["pantoneCode"]);

        if (response.body['item1']["topViewFile"]) {
          response.body['item1']["topViewFile"]["thumbnail"] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["topViewFile"]["thumbnail"]);
          response.body['item1']["topViewFile"]["name"] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["topViewFile"]["name"]);

          this.editedtopViewfile[0] = response.body['item1']["topViewFile"];
          //  this.editedtopViewfile[0].["thumbnail"]=this.aesdecryptservice.decryptData(this.dataservice.Key,this.editedtopViewfile[0].thumbnail);
          //  this.editedtopViewfile=this.editedtopViewfile
          this.editedtopViewValue = response.body['item1']["topViewFile"]["thumbnail"];

        }
        if (response.body['item1']["bottomViewFile"]) {
          response.body['item1']["bottomViewFile"]["thumbnail"] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["bottomViewFile"]["thumbnail"]);
          response.body['item1']["bottomViewFile"]["name"] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["bottomViewFile"]["name"]);

          this.editedbottomViewfile[0] = response.body['item1']["bottomViewFile"];
          this.editedbottomViewValue = response.body['item1']["bottomViewFile"]["thumbnail"];

        }

      }
      else {
        this.showLoader1 = false;
      }
    },
      err => {
        //console.log(err)
        this.logger.error(err.error.message, "StichesDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.showLoader1 = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.showLoader1 = false;
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.showLoader1 = false;
          this.userservice.error();
        }
      }) */
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  onSelect(event) {
    this.topView = [];

    let imgext = "png,jpeg,jpg,xlsx,pdf,docx"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.topView.push(...event.addedFiles);
      this.isTopViewAdded = true;
    }
    else {
      this.isTopViewAdded = false;
      this.error("File Not Supported.")
      return false;
    }
    // this.topView.push(...event.addedFiles);

    if (this.topView && this.topView.length > 0) {
      this.isTopFileError = false;
    }
  }

  onRemove(event) {
    this.topView.splice(this.topView.indexOf(event), 1);
    if (this.topView.length === 1) {
      this.isTopViewAdded = true;
    }
    else {
      this.isTopFileError = true;
      this.isTopViewAdded = false;
    }
  }

  removeTopSection(event) {
    this.editedtopViewfile.splice(this.editedtopViewfile.indexOf(event), 1);
    this.isTopViewAdded = false;
  }

  openTopFiles(imageindex: number): void {
    this.cardsTop = [];
    for (let i = 1; i <= this.editedtopViewfile.length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedtopViewfile[i - 1]["name"])) {
        src = this.editedtopViewfile[i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedtopViewfile[i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cardsTop.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cardsTop, imageindex);
  }

  onSelectBottomSewn(event) {
    this.bottomView = [];

    let imgext = "png,jpeg,jpg,xlsx,pdf,docx"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.bottomView.push(...event.addedFiles);
      this.isBottomViewAdded = true;
    }
    else {
      this.isBottomViewAdded = false;
      this.error("File Not Supported.")
      return false;
    }
    if (this.bottomView && this.bottomView.length > 0) {
      this.isBottomFileError = false;
    }
  }
  onRemoveBottomSewn(event) {
    this.bottomView.splice(this.bottomView.indexOf(event), 1);
    if (this.bottomView.length === 1) {
      this.isBottomViewAdded = true;
    }
    else {
      this.isBottomFileError = true;
      this.isBottomViewAdded = false;
    }
  }

  removeBottomSection(event) {
    this.editedbottomViewfile.splice(this.editedbottomViewfile.indexOf(event), 1);
    this.isBottomViewAdded = false;
  }

  openBottomFiles(imageindex: number): void {
    this.cardsBottom = [];
    for (let i = 1; i <= this.editedbottomViewfile.length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedbottomViewfile[i - 1]["name"])) {
        src = this.editedbottomViewfile[i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedbottomViewfile[i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cardsBottom.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cardsBottom, imageindex);
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: 'Ssssion Expired!',
      html: 'You Session has Expired Please Login Again..',
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
        return;
      }
    })

  }

  reloadStitchesFormData(data) {
    this.initForm();
    this.storageservice.storeEditStitchesId(data["id"]);
    this.stitchesForm.get('sequence').setValue(data["sequence"]);
    this.stitchesForm.get('name').setValue(data["name"]);
    this.stitchesForm.get('isO4915Number').setValue(data["isO4915Number"]);
    this.stitchesForm.get('commonApplication').setValue(data["commonApplication"]);
    this.stitchesForm.get('requirements').setValue(data["requirements"]);
    this.stitchesForm.get('status').setValue(data["status"]);
    this.stitchesForm.get('description').setValue(data["description"]);
    this.stitchesForm.get('topviewComments').setValue(data["topviewComments"]);
    this.stitchesForm.get('bottomviewComments').setValue(data["bottomviewComments"]);


    if (data["topViewFile"]) {
      this.editedtopViewfile[0] = data["topViewFile"];
      this.editedtopViewValue = data["topViewFile"]["thumbnail"];

    }
    if (data["bottomViewFile"]) {
      this.editedbottomViewfile[0] = data["bottomViewFile"];
      this.editedbottomViewValue = data["bottomViewFile"]["thumbnail"];

    }
    // this.imageObject=[];
    const temp = {
      'image': this.thumbnail,
      'thumbImage': this.thumbnail
    }
    this.imageObject.push(temp);
    //this.materialForm.get('trimcost').setValue(data["trimcost"]);
    if (data["topViewFile"]) {
      this.editedThumbnails = [];
      this.editedThumbnails.push(data["topViewFile"]);
    }
    if (data["bottomViewFile"]) {
      this.editedThumbnails = [];
      this.editedThumbnails.push(data["bottomViewFile"]);
    }

  }


  async onStitchesSubmit() {

    this.submitted = true;
    var topViewId = null;
    var topViewName = null;
    var bottomViewId = null;
    var bottomViewName = null;

    if (!this.f.sequence.value) {

      if (this.topView.length === 1 || this.editedtopViewfile) {
        this.isTopViewAdded = true;
      }
      else {
        this.isTopViewAdded = false;
      }

      if (this.bottomView.length === 1 || this.editedbottomViewfile) {
        this.isBottomViewAdded = true;
      }
      else {
        this.isBottomViewAdded = false;
      }

    }
    else {

      if (this.editedtopViewfile) {
        this.isTopViewAdded = true;
      }
      else {
        if (this.topView.length === 1) {
          this.isTopViewAdded = true;
        }
        else {
          this.isTopViewAdded = false;
        }
      }

      if (this.editedbottomViewfile) {
        this.isBottomViewAdded = true;
      }
      else {
        if (this.bottomView.length === 1) {
          this.isBottomViewAdded = true;
        }
        else {
          this.isBottomViewAdded = false;
        }
      }

    }

    if (!this.stitchesForm.valid) {
      this.error('Invalid Stitches Form')
      if (this.topView.length == 0) {
        this.isTopFileError = true;
        this.isTopViewAdded = false;
      }

      if (this.bottomView.length == 0) {
        this.isBottomFileError = true;
        this.isBottomViewAdded = false;
      }
      return false;
    } else if (!this.isTopViewAdded && !this.isBottomViewAdded) {
      return false;
    }

    this.showLoader1 = true;
    if (this.isTopViewAdded) {
      if (this.topView.length === 1) {
        let topViewuploaded = await this.uploadFile(this.topView[0]);
        if (topViewuploaded != "error") {
          topViewId = JSON.parse(topViewuploaded)["Key"];
          topViewName = this.topView[0].name;
        }
        else {
          this.error(topViewuploaded);
          this.submitted = false;
          this.showLoader1 = false;
          return false;
        }
      }
    }

    if (this.isBottomViewAdded) {
      if (this.bottomView.length === 1) {
        let bottomViewuploaded = await this.uploadFile(this.bottomView[0]);
        if (bottomViewuploaded != "error") {
          bottomViewId = JSON.parse(bottomViewuploaded)["Key"];
          bottomViewName = this.bottomView[0].name;
        }
        else {
          this.error(bottomViewuploaded);
          this.submitted = false;
          this.showLoader1 = false;
          return false;
        }
      }
    }

    if (topViewId == null && this.editedtopViewfile.length <= 0) {
      this.isTopFileError = true;
      this.showLoader1 = false;
      return false;
    }

    if (bottomViewId == null && this.editedbottomViewfile.length <= 0) {
      this.isBottomFileError = true;
      this.showLoader1 = false;
      return false;
    }

    let stitchesData = {
      name: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.name.value),
      isO4915Number: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.isO4915Number.value),
      commonApplication: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.commonApplication.value),
      requirements: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.requirements.value),
      status: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.status.value),
      description: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.description.value),
      topviewComments: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.topviewComments.value),
      bottomviewComments: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.bottomviewComments.value),
      topView: topViewId,
      bottomView: bottomViewId,
    };

    //Add stitchesData
    if (!this.f.sequence.value) {
      this.store.dispatch(new stitchesActionTypes.CreateStitches(stitchesData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((newStitchesData) => {
        if (newStitchesData.stitches.error === "") {
          if (newStitchesData.stitches.message['item2'] === 'Success') {
            this.showLoader1 = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedStitchesData = newStitchesData.stitches.message['item1'];
            this.topView = [];
            this.bottomView = [];

            this.editedtopViewfile = [];
            this.editedtopViewValue = "";

            this.editedbottomViewfile = [];
            this.editedbottomViewValue = "";
            this.reloadStitchesFormData(newStitchesData.stitches.message['item1']);
            this.dialogRef.close();
            this.success(this.appStaticMsgs.stitches.stitchescreatedsuccessfully);
            this.logger.info("Stitches Created Successfully", "StichesDetails");
          } else {
            this.showLoader1 = false;
            this.error("Failed:" + newStitchesData.stitches.message['item1'])
            this.logger.error(newStitchesData.stitches.message['item1'], "StichesDetails");
          }
        } else {
          this.logger.error(newStitchesData.stitches.error.error.message, "StichesDetails");
          this.getError(newStitchesData.stitches.error);
          this.showLoader1 = false;
        }
      })

      /* this.dataservice.addStitches(stitchesData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedStitchesData = response.body['item1'];
            this.topView = [];
            this.bottomView = [];

            this.editedtopViewfile = [];
            this.editedtopViewValue = "";

            this.editedbottomViewfile = [];
            this.editedbottomViewValue = "";
            this.reloadStitchesFormData(response.body['item1']);
            this.success("Stitches Created Successfully!");
            this.logger.info("Stitches Created Successfully", "StichesDetails");
          }
          else {
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "StichesDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "StichesDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "StichesDetails");
        }
        this.showLoader = false;
      }); */

    }
    else {

      //Update stitchesData
      let stitchesid = { id: this.storageservice.getEditedStitchesId() };
      let updatedStitchesData = { ...stitchesData, ...stitchesid };
      this.store.dispatch(new stitchesActionTypes.UpdateStitches(updatedStitchesData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((stitchesData) => {
        if (stitchesData.stitches.error === "") {
          if (stitchesData.stitches.message['item2'] === 'Success') {
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedStitchesData = stitchesData.stitches.message['item1'];
            //console.log(this.editedStitchesData);
            this.topView = [];
            this.bottomView = [];
            this.editedtopViewfile = [];
            this.editedtopViewValue = "";
            this.editedbottomViewfile = [];
            this.editedbottomViewValue = "";
            this.reloadStitchesFormData(stitchesData.stitches.message['item1']);
            this.dialogRef.close();
            this.success(this.appStaticMsgs.stitches.stitchesupdatedsuccessfully);
            this.showLoader=false;
            this.logger.info(this.appStaticMsgs.stitches.stitchesupdatedsuccessfully, "StichesDetails");
          } else {
            this.showLoader = false;
            this.error("Failed:" + stitchesData.stitches.message['item1'])
            this.logger.error(stitchesData.stitches.message['item1'], "StichesDetails");
          }
        } else {
          this.logger.error(stitchesData.stitches.error.error.message, "StichesDetails");
          this.getError(stitchesData.stitches.error);
          this.showLoader = false;
        }
      })

      /* this.dataservice.updateStitchesById(updatedStitchesData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']);
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedStitchesData = response.body['item1'];
            //console.log(this.editedStitchesData);
            this.topView = [];
            this.bottomView = [];
            this.editedtopViewfile = [];
            this.editedtopViewValue = "";
            this.editedbottomViewfile = [];
            this.editedbottomViewValue = "";
            this.reloadStitchesFormData(response.body['item1'])
            this.success("Stitches Updated Successfully!");
            this.logger.info("Stitches Updated Successfully", "StichesDetails");
          }
          else {
            console.log(response)
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "StichesDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "StichesDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "StichesDetails");
        }
        this.showLoader = false;
      }); */
    }

  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }

  mandatoryError(message: string) {
    Swal.fire({
      icon: 'error',
      title: '',
      text: message
    })
  }
  async uploadFile(file): Promise<any> {

    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );

    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          reject("error");
        }
        resolve(JSON.stringify(data));
      });
    });
  };

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  isValidImageURL(str) {
    str = str.toLowerCase();
    str = str.split('?')[0];
    //moving on, split the uri into parts that had dots before them
    var parts = str.split('.');
    //get the last part ( should be the extension )
    var extension = parts[parts.length - 1];
    //define some image types to test against
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    //check if the extension matches anything in the list.
    if (imageTypes.indexOf(extension) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  doc_Error = [];
  statusForm: boolean = false;
  async documentUploadCheck(documentControl) {
    this.doc_Error = []

    // if (this.editedFileData.length > 0) {
    for (let i = 0; i < documentControl.length; i++) {
      if (this.editedFileData.length > 0) {
        if (i + 1 <= documentControl?.length) {
          if (this.editedFileData[i]?.length > 0) {
            this.doc_Error.push('');
          } else {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.appStaticMsgs.stitches.documentisrequired);
            }
          }
        } else {
          if (this.fileData.length > 0) {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.appStaticMsgs.stitches.documentisrequired)
            }
          } else {
            this.doc_Error.push(this.appStaticMsgs.stitches.documentisrequired)
          }
        }

      } else {
        if (this.fileData.length > 0) {
          if (this.fileData[i].length > 0) {
            this.doc_Error.push('');
          } else {
            this.doc_Error.push(this.appStaticMsgs.stitches.documentisrequired)
          }
        } else {
          this.doc_Error.push(this.appStaticMsgs.stitches.documentisrequired)
        }

      }
    }
    // }

    if (this.doc_Error.length > 0) {
      for (let i = 0; i < this.doc_Error.length; i++) {
        if (this.doc_Error[i] == this.appStaticMsgs.stitches.documentisrequired) {
          this.statusForm = true;
          break;
        } else {
          this.statusForm = false;
        }
      }
    } else {
      this.doc_Error.push(this.appStaticMsgs.stitches.documentisrequired);
      this.statusForm = true;
    }
    return this.statusForm;
  }

  test(formData) {
    for (var key of formData.entries()) {
    }
  }
  async onDocSubmit() {
    this.submitted1 = true;
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();

    //alert(this.findInvalidControlsRecursive(this.documentsForm))
    if (!this.documentsForm.valid) {
      this.error('Invalid Document Form')
      return false;
    }
    let statusUploadDoc = await this.documentUploadCheck(documentControl);
    if (statusUploadDoc == true) {
      return false
    }
    this.showLoader = true;


    const formData = new FormData();
    formData.append('stitchid', this.storageservice.getEditedStitchesId());
    formData.append('doccount', control.length.toString());
    if (this.removedDocIds) {
      formData.append('docdelete', this.removedDocIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (documentControl[i].get('docid').value) {
        formData.append('docid' + '[' + i + ']', documentControl[i].get('docid').value);
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
      }
      else {
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
      }

      if (documentControl[i].get('docdeletedfileids').value) {
        formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
      }

      formData.append('baseclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Documents"));
      formData.append('docclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docclassification').value));
      formData.append('docname' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docname').value));
      formData.append('docstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docstatus').value));
      formData.append('docdescription' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docdescription').value));
      formData.append('docreportstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docreportstatus').value));
      formData.append('doclablocation' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('doclablocation').value));
      formData.append('docnotes' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docnotes').value));
      formData.append('docfilecount' + '[' + i + ']', this.fileData[i].length);

      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '[]') {
          //console.log (f.name);
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != "error") {
            formData.append('file' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, JSON.parse(fileUploadedData)["Key"]));
            formData.append('filename' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
          }
          else {
            this.error(fileUploadedData);
            this.submitted1 = false;
            this.showLoader = false;
            return false;
          }
        }
        a = a + 1;
      }
    }


    //console.log(JSON.stringify(formData));
    this.test(formData);
    this.store.dispatch(new stitchesActionTypes.UpdateStitchesDocument(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((stitchesDocData) => {
      if (stitchesDocData.stitches.error === "") {
        if (stitchesDocData.stitches.message['item2'] === 'Success') {
          this.showLoader = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedFileData = [];
          this.removedDocIds = "";
          this.editedStitchesData = stitchesDocData.stitches.message['item1'];
          //console.log(this.editedStitchesData);
          if (this.editedStitchesData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            this.isFilesAdded = true;
            let editeddata = JSON.stringify(this.editedStitchesData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          this.docDetailsTab('docDetailsTab');
          this.success(this.appStaticMsgs.stitches.documentsupdatedsuccessfully);
          this.logger.info("Documents Updated Successfully", "StichesDetails");
        } else {
          this.showLoader = false;
          this.error("Failed:" + stitchesDocData.stitches.message['item1'])
          this.logger.error(stitchesDocData.stitches.message['item1'], "StichesDetails");
        }
      } else {
        this.showLoader = false;
        this.logger.error(stitchesDocData.stitches.error.error.message, "StichesDetails");
        this.getError(stitchesDocData.stitches.error);

      }
    })

    /* this.dataservice.updateStitchesDocuments(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          //console.log(JSON.stringify(response.body['item1']));
          this.showLoader = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedFileData = [];
          this.removedDocIds = "";
          this.editedStitchesData = response.body['item1'];
          //console.log(this.editedStitchesData);
          if (this.editedStitchesData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            let editeddata = JSON.stringify(this.editedStitchesData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          this.docDetailsTab('docDetailsTab');
          this.success("Documents Updated Successfully!");
          this.logger.info("Documents Updated Successfully", "StichesDetails");
        }
        else {
          //console.log(response)
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "StichesDetails");
          this.showLoader = false;
        }

      }
    }, err => {
      this.logger.error(err.error.message, "StichesDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "StichesDetails");
      }
      this.showLoader = false;
    }); */

  }

  reloadDocumentFormData(data) {

    for (let i = 0; i < data.length; i++) {
      this.addNewDoc();
      this.refreshDocTree(i);
    }
    //console.log(JSON.stringify(data))
    let documentControl = this.getControls();
    for (let i = 0; i < data.length; i++) {
      documentControl[i].get('docid').setValue(data[i]["id"]);
      documentControl[i].get('docseq').setValue(data[i]["sequence"]);
      this.refreshDocTree(i);
      if (this.isEditingMode) {
        this.setDocumentTreeValue(i, data[i]["classification"]);
      }
      documentControl[i].get('docclassification').setValue(data[i]["classification"]);
      documentControl[i].get('docname').setValue(data[i]["name"]);
      documentControl[i].get('docstatus').setValue(data[i]["status"]);
      documentControl[i].get('docdescription').setValue(data[i]["description"]);
      documentControl[i].get('docreportstatus').setValue(data[i]["reportStatus"]);
      documentControl[i].get('doclablocation').setValue(data[i]["labLocation"]);
      if (data[i]["classification"] === "Lab Reports") {
        documentControl[i].get('docreportstatus').enable();
        documentControl[i].get('doclablocation').enable();
      }
      else {
        documentControl[i].get('docreportstatus').disable();
        documentControl[i].get('doclablocation').disable();
      }
      documentControl[i].get('docnotes').setValue(data[i]["notes"]);
      if (data[i]["files"][0]) {
        // this.editedFileData=data[i]["files"];
        data[i]["files"][0]['thumbnail'] = data[i]["files"][0]['thumbnail'];
        data[i]["files"][0]['name'] = data[i]["files"][0]['name'];
      }
      this.editedFileData.push(data[i]["files"]);
      this.stichesDataCnt = this.fileData[i].length + 1;
      this.isFilesAdded = true;
    }
  }

  getControls() {
    return (this.documentsForm.get('documents') as FormArray).controls;
  }


  setDocumentTreeValue(index, value) {
    $(document).ready(function () {
      $("#docclassification" + index).data('kendoDropDownTree').value(value);
    })
  }

  addNewDoc() {

    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    let control = <FormArray>this.documentsForm.controls.documents;
    //alert(control.length)
    control.push(
      this.fb.group({
        docclassification: ['', Validators.required],
        docid: [''],
        docseq: [''],
        docname: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
        docstatus: ['', Validators.required],
        docdescription: ['', [Validators.required, Validators.maxLength(500)]],
        docreportstatus: ['', Validators.required],
        doclablocation: ['', [Validators.required, Validators.maxLength(50)]],
        docnotes: [''],
        docdeletedfileids: ['']
      })
    )
    this.files = [];
    this.fileData[control.length - 1] = this.files;
    this.showButton = true;
    this.refreshDocTree(control.length - 1);
  }

  removeDoc(index) {
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    if (this.removedDocIds) {
      this.removedDocIds = this.removedDocIds + "," + documentControl[index].get('docid').value
    }
    else {
      this.removedDocIds = documentControl[index].get('docid').value
    }
    // if(this.editedFileData[index].length > 0)
    // {
    //   let deletedids = "";
    //   for (let i = 0; i < this.editedFileData[index].length; i++) {
    //     //console.log(this.editedFileData[index][i]["id"])
    //     if(deletedids)
    //     {
    //     deletedids = deletedids + "," + this.editedFileData[index][i]["id"]
    //     }
    //     else
    //     {
    //       deletedids = this.editedFileData[index][i]["id"]
    //     }
    //   }
    //   console.log("deletedids"+ deletedids)
    //   documentControl[index].get('docid').setValue(deletedids);
    // }
    control.removeAt(index)
    this.fileData.splice(index, 1);
    //console.log(this.fileData);
    this.editedFileData.splice(index, 1);
    //this.removedFileData[index].push([]);
  }

  onRemoveDoc(event, i) {
    this.fileData[i].splice(this.fileData[i].indexOf(event), 1);
    //console.log(this.fileData);
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  onSelectDoc(event, i) {
    this.files = [];
    let imgext = "png,jpeg,jpg,xlsx,pdf,txt,docx,zip,pptx"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.files.push(...event.addedFiles);
      this.isFilesAdded = true;
      this.doc_Error[i] = '';

    }
    else {
      this.isFilesAdded = false;
      this.error("File Not Supported.")
      return false;
    }

    this.fileData[i] = this.files;
  }


  refreshDocTree(index: number) {
    var data = this.documentClassificationData;
    var currentDocument = this.getControls();

    var self = this;
    $(document).ready(function () {
      var treeview = $("#docclassification" + index).kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        dataSource: data,
        filter: "startswith",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          //alert(value);
          if (value) {
            //self.documentclassificationValue = value["text"];
            //console.log(value["text"])
            currentDocument[index].get('docclassification').setValue(value["text"]);
            if (value["text"] === "Lab Reports") {
              currentDocument[index].get('docreportstatus').enable();
              currentDocument[index].get('doclablocation').enable();
            }
            else {
              currentDocument[index].get('docreportstatus').disable();
              currentDocument[index].get('doclablocation').disable();
            }
          }
          else {
            //self.documentclassificationValue = "";
            currentDocument[index].get('docclassification').setValue("");
          }
        }
      });

    });
  }

  isEmptyObject(obj) {
    return JSON.stringify(obj) === '[]';
  }

  open(index: number, imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedFileData[index].length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedFileData[index][i - 1]["name"])) {
        src = this.editedFileData[index][i - 1]["thumbnail"];
        thumb = this.editedFileData[index][i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  removeImgSection(event, i) {
    this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    if(this.editedFileData[i].length == 0){
      this.isFilesAdded = false;
    }
    let documentControl = this.getControls();
    let docdeltedFiles = documentControl[i].get('docdeletedfileids').value;
    if (docdeltedFiles) {
      documentControl[i].get('docdeletedfileids').setValue(docdeltedFiles + "," + event.id);
    }
    else {
      documentControl[i].get('docdeletedfileids').setValue(event.id);
    }

    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  cancelDocumentEdit() {
    this.showButton = false;
    (this.documentsForm.get('documents') as FormArray).clear();
    this.isTopViewAdded = true;
    this.isBottomViewAdded = true;
    if (this.storageservice.getEditedStitchesId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.showLoader = true;
    if (this.editedStitchesData.documents) {
      if (this.editedStitchesData.documents.length > 0) {
        this.editedFileData = [];
        this.removedDocIds = "";
        //this.removedFileData = [];
        this.isFilesAdded = true;
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        let editeddata = JSON.stringify(this.editedStitchesData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }

  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }
  cancelStitchesCreate() {
    this.router.navigate(['/stitcheslibrary']);
    this.dialogRef.close();
  }
  onDeleteStiches() {

    Swal.fire({
      title: 'Are you sure you want to delete this stitches?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getEditedStitchesId());
        this.store.dispatch(new stitchesActionTypes.DeleteStitchesLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedStitchesLibrary) => {
          if (deletedStitchesLibrary.stitches.error === "") {
            if (deletedStitchesLibrary.stitches.message?.item2 === 'Success') {
              this.success(this.appStaticMsgs.stitches.stitchesdeletedsuccessfully);
              this.logger.info(this.appStaticMsgs.stitches.stitchesdeletedsuccessfully, "StichesLibrary");
              this.router.navigate(['/stitcheslibrary']);
              this.showLoader = false;
            } else {
              this.showLoader = false;
              this.error("Failed:" + deletedStitchesLibrary.stitches.message?.item1)
              this.logger.error(deletedStitchesLibrary.stitches.message?.item1, "StichesLibrary");
            }

          } else {
            this.logger.error(deletedStitchesLibrary.stitches.error.error.message, "StichesLibrary");
            this.getError(deletedStitchesLibrary.stitches.error);
            this.showLoader = false;
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }
}
