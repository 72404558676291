import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, from, throwError } from 'rxjs';
import {
  HttpClientModule,
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  map,
  catchError,
  tap,
  retry,
  concatMap,
  switchMap,
} from 'rxjs/operators';
import { environment } from '../../../../tenants/main/environments/environment';
import { StorageService } from '../helpers/storage.service';
import {
  IColor,
  IColorResultDataModal,
  ColourDocuments,
  IResultDataModal,
  DeleteColor,
  Favourite,
  DeleteItem,
} from '../models/color';
import { IDocument } from '../models/document';
import { DatePipe } from '@angular/common';
import * as Crypto from 'crypto-js';
import { LogService } from '../shared/log.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
};
interface generativeAI {
  Garment_type: string;
  Length: string;
  Neck: string;
  Right_Sleeve: string;
  Left_Sleeve: string;
  Right_Sleeve_length: string;
  Left_Sleeve_length: string;
  silhouette: string;
  fitting: string;
  Color: string;
  occasion: string;
  season: string;
  size: string;
}

declare var AWS: any;

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public countrycodes: any[] = [];
  private countriesUrl = '../../assets/data/countrycodes.json';
  public colorformfields: any[] = [];
  private colorformfieldsUrl = '../../assets/data/colorformfields.json';
  API_URL: string;
  REG_URL: string;
  GETROLES_URL: string;
  MENU_ITEMS_URL: string;
  USER_DETAILS_URL: string;
  UPDATE_USER_DETAILS_URL: string;
  GETCOLOUR_DETAILS_URL: string;
  USER_INVITE_URL: string;
  Key: string;
  log: any[];
  private activeStyleTabValueObs: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  private s3: any;
  ACCESS_KEY: any;
  SECRET_KEY: any;
  REGION: string;
  constructor(
    private router: Router,
    private http: HttpClient,
    private logger: LogService,
    private storage: StorageService
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.API_URL = `${environment.server}`;
    this.REG_URL = `${environment.reg_user}`;
    this.MENU_ITEMS_URL = `${environment.menu_items}`;
    this.GETROLES_URL = `${environment.get_roles}`;
    this.USER_DETAILS_URL = `${environment.get_user_details}`;
    this.UPDATE_USER_DETAILS_URL = `${environment.update_user_details}`;
    this.GETCOLOUR_DETAILS_URL = `${environment.GetAllColours}`;
    this.USER_INVITE_URL = `${environment.sendInvite}`;
    this.Key = '01234567890123456789012345678901';

    this.ACCESS_KEY = `${environment.BucketAccessKeyId}`;
    this.SECRET_KEY = `${environment.BucketSecretAccessKey}`;
    this.REGION = `${environment.BucketRegion}`;

    AWS.config.update({
      credentials: new AWS.Credentials(this.ACCESS_KEY, this.SECRET_KEY),
      region: this.REGION,
    });

    this.s3 = new AWS.S3();
  }

  public setActiveStyleTabValue(value: string): void {
    this.activeStyleTabValueObs.next(value);
    this.getActiveStyleTabValue();
  }

  public getActiveStyleTabValue(): Observable<string> {
    return this.activeStyleTabValueObs;
  }

  async getCountrycodes() {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.countriesUrl, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }),
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err: HttpErrorResponse) => {
            console.log(err.message);
            reject(err);
          }
        );
    });
  }

  async getColorFormFields() {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.colorformfieldsUrl, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }),
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err: HttpErrorResponse) => {
            console.log(err.message);
            reject(err);
          }
        );
    });
  }

  getRolesList(): Observable<any> {
    return this.http
      .get(`${this.GETROLES_URL}`, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To post data to create new User in Database.
  registerUserDetails(data): Observable<any> {
    return this.http
      .post(`${this.REG_URL}`, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  insertDataForNewUser(): Observable<any> {
    return this.http
      .post(
        `${environment.DataInsertForNewUser}`,
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.storage.getToken(),
          }),
          observe: 'response',
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To get Data of menu Items.
  getMenuItems(): Observable<any> {
    return this.http
      .get(`${this.MENU_ITEMS_URL}` + '?email=' + this.storage.getEmail(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To get data of Menu Items from backend API.
  //   async getMenuItems(){
  //     return new Promise((resolve, reject) => {
  //       this.http.get(`${this.MENU_ITEMS_URL}` + '?email=' + this.storage.getEmail(),
  //       {
  //         headers: new HttpHeaders({
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + this.storage.getToken(),
  //           'Access-Control-Allow-Origin': '*'
  //         })
  //       }).subscribe(data => {
  //         resolve(data);
  //       },
  //         (err: HttpErrorResponse) => {
  //           console.log(err.message);
  //           reject(err)
  //         });
  //     });

  // }

  //To get Data of User by Email.
  getUserDetails(): Observable<any> {
    return this.http
      .get(`${this.USER_DETAILS_URL}` + '?email=' + this.storage.getEmail(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To post data for Updating User Deatils in Database.
  updateUserDetails(data): Observable<any> {
    return this.http
      .post(`${this.UPDATE_USER_DETAILS_URL}`, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //Portfolios
  addUserPortfolio(data): Observable<any> {
    return this.http
      .post(`${environment.addUserPortfolio}`, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  updateUserPortfolio(data): Observable<any> {
    return this.http
      .post(`${environment.updateUserPortfolio}`, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getUserPortfolios(): Observable<any> {
    return this.http
      .get(
        `${environment.getUserPortfolios}` +
          '?email=' +
          this.storage.getEmail(),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.storage.getToken(),
          }),
          observe: 'response',
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //Supplier
  addUserSupplier(data): Observable<any> {
    return this.http
      .post(`${environment.addUserSupplier}`, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  updateUserSupplier(data): Observable<any> {
    return this.http
      .post(`${environment.updateUserSupplier}`, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  // getUserSupplier(): Observable<any> {
  //   return this.http.get(`${environment.getUserSupplier}` + '?email=' + this.storage.getEmail(), {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + this.storage.getToken()
  //     }),
  //     observe: 'response'
  //   })
  //     .pipe(
  //       catchError(err => {
  //         return throwError(err)
  //       })
  //     )
  // }

  getUserSupplier(id: string): Observable<any> {
    const apiUrl = `${environment.getUserSupplier}?RecordID=${id}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //Start Stitches...
  getAllStitches(recordCount, stichesViewMode): Observable<any> {
    var apiUrl;

    apiUrl =
      `${environment.GetAllStitches}` +
      '?Type=' +
      stichesViewMode +
      '&RecordCount=' +
      recordCount;

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkUpdateStitches(data): Observable<any> {
    const apiUrl = `${
      environment.BulkUpdateStitches
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkDeleteStitches(colorlist: string[]): Observable<any> {
    const apiUrl = `${
      environment.BulkDeleteStitches
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, new DeleteColor(colorlist), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAdvanceSearchStitches(colors: any): Observable<any> {
    const apiUrl = `${
      environment.StitchesAdvancedsearch
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, colors, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getStitchesById(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetStitchesByID
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addStitches(data): Observable<any> {
    const apiUrl = `${environment.AddStitches}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateStitchesById(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateStitchesById
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateStitchesDocuments(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateStitchesDocuments
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //End Stitches...

  //To get Data of Color by Email.
  getColor(recordCount, colorviewmode): Observable<any> {
    var apiUrl;
    /* if(colorviewmode == 'sharedcolor')
    {
      //sharedstyle
    if(recordCount)
      {
        apiUrl = `${environment.GetAllSharedColours}` + '?userEmail=' + this.storage.getEmail() + '&RecordCount=' + recordCount;
      }
      else
      {
        apiUrl = `${environment.GetAllSharedColours}` + '?email=' + this.storage.getEmail();
      }
    }
    else
    {
      //mystyles
      if(recordCount)
      { */
    apiUrl =
      `${this.GETCOLOUR_DETAILS_URL}` +
      '?Type=' +
      colorviewmode +
      '&RecordCount=' +
      recordCount;
    /* }
    else
    {
        apiUrl = `${this.GETCOLOUR_DETAILS_URL}` + '?email=' + this.storage.getEmail();
      }
    } */

    //const apiUrl = `${this.GETCOLOUR_DETAILS_URL}?userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getColorById(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetColourByID
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addColor(data): Observable<any> {
    const apiUrl = `${environment.AddColour}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateColorById(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateColorById
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateColorDocuments(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateColorDocuments
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkUpdateColor(data): Observable<any> {
    const apiUrl = `${
      environment.BulkUpdateColour
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addDocumentFile(formData): Observable<any> {
    formData.append('userInfo', `${this.storage.getEmail()}`);
    const apiUrl = `${environment.AddDocumentFile}`;
    return this.http.post<any>(apiUrl, formData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  bulkAddFavourites(favourite: Favourite[], module: string): Observable<any> {
    const apiUrl = `${
      environment.BulkAddFavourites
    }?userEmail=${this.storage.getEmail()}&Module=${module}`;
    return this.http
      .post(apiUrl, favourite, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkDeleteFavourites(favourite: string[], module: string): Observable<any> {
    const apiUrl = `${
      environment.BulkDeleteFavourites
    }?userEmail=${this.storage.getEmail()}&Module=${module}`;
    return this.http
      .post(apiUrl, new DeleteColor(favourite), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // async getClassification(module:string) {
  //   const apiUrl = `${environment.GetClassification}?module=${module}`;
  //   return new Promise((resolve, reject) => {
  //     this.http.get(apiUrl,{
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + this.storage.getToken()
  //       })
  //     }).subscribe(data => {
  //       resolve(data);
  //     },
  //       (err: HttpErrorResponse) => {
  //         console.log(err.message);
  //         reject(err)
  //       });
  //   });
  // }

  getClassification(module: string): Observable<any> {
    const apiUrl = `${environment.GetClassification}?module=${module}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkDeleteColour(colorlist: string[]): Observable<any> {
    const apiUrl = `${
      environment.BulkDeleteColour
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, new DeleteColor(colorlist), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAdvanceSearchColor(colors: any): Observable<any> {
    const apiUrl = `${
      environment.ColorAdvancedsearch
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, colors, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  addMaterialColor(data): Observable<any> {
    const apiUrl = `${environment.AddMaterialColor}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  /* Document Services */
  getAdvanceSearchDocument(doc: any): Observable<any> {
    const apiUrl = `${
      environment.DocumentAdvancedsearch
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, doc, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getDocument(recordCount, imageviewmode): Observable<any> {
    var apiUrl;
    apiUrl =
      `${environment.GetAllDocuments}` +
      '?Type=' +
      imageviewmode +
      '&RecordCount=' +
      recordCount;

    return this.http
      .get<any>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getDocumentById(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetDocumentByID
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get<any>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // addDocument(Document: IDocument): Observable<any> {

  //   const apiUrl = `${environment.AddDocument}?userEmail=${this.storage.getEmail()}`;
  //   return this.http.post<any>(apiUrl, Document, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + this.storage.getToken()
  //     })
  //     , observe: 'response'
  //   }).pipe(
  //     catchError(err => {return throwError(err)})
  //   );
  // }
  // bulkUpdateDocument(documents: IDocument[]): Observable<any> {
  //   const apiUrl = `${environment.BulkUpdateDocument}?userEmail=${this.storage.getEmail()}`;
  //   return this.http.post<any>(apiUrl, documents, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + this.storage.getToken()
  //     })
  //     , observe: 'response'
  //   }).pipe(
  //     catchError(err => {return throwError(err)})
  //   );
  // }
  bulkDeleteDocument(documentlist: string[]): Observable<any> {
    const apiUrl = `${
      environment.DeleteBulkDocumentByIDs
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post<any>(apiUrl, new DeleteItem(documentlist), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Error handling
  // private handleError(err: HttpErrorResponse) {
  //   let errorMessage = '';
  //   if (err.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred.
  //     errorMessage = err.error.message;
  //   } else {
  //     // The server-side returned an unsuccessful response code.
  //     errorMessage = err.message;
  //   }
  //   //console.error(errorMessage);
  //   return throwError(errorMessage);
  // }

  /* Document Services */
  pipe = new DatePipe('en-US');
  dateFromFormat(d) {
    return this.pipe.transform(d, 'yyyy-MM-dd');
  }
  dateToFormat(d) {
    return this.pipe.transform(d, 'yyyy-MM-dd');
  }

  bulkUpdateDocument(documents: IDocument[]): Observable<any> {
    const apiUrl = `${
      environment.BulkUpdateDocument
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post<any>(apiUrl, documents, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getSuppliers(recordCount, supplierViewMode): Observable<any> {
    var apiUrl;

    apiUrl =
      `${environment.GetAllSuppliers}` +
      '?Type=' +
      supplierViewMode +
      '&RecordCount=' +
      recordCount;

    return this.http
      .get<any>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createSupplier(data): Observable<any> {
    const apiUrl = `${environment.CreateSupplier}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  BulkUpdateSupplier(data): Observable<any> {
    const apiUrl = `${
      environment.BulkUpdateSupplier
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  BulkDeleteSupplier(colorlist: string[]): Observable<any> {
    const apiUrl = `${
      environment.BulkDeleteSupplier
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, new DeleteColor(colorlist), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAdvanceSearchSupplier(doc: any): Observable<any> {
    const apiUrl = `${
      environment.SupplierAdvancedSearch
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, doc, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getSupplierById(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetSupplierByID
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  updateSupplierDocuments(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateSupplierDocuments
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createImageDocument(data): Observable<any> {
    const apiUrl = `${environment.CreateImageDocument}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getMaterial(recordCount, materialviewmode): Observable<any> {
    var apiUrl;

    apiUrl =
      `${environment.GetAllMaterial}` +
      '?Type=' +
      materialviewmode +
      '&RecordCount=' +
      recordCount;

    return this.http
      .get<any>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getAdvanceSearchMaterial(material: any): Observable<any> {
    const apiUrl = `${
      environment.MaterialAdvancedsearch
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, material, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  bulkUpdateMaterial(data): Observable<any> {
    const apiUrl = `${
      environment.BulkUpdateMaterial
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getMaterialClassification(module: string): Observable<any> {
    const apiUrl = `${environment.GetMaterialClassification}?module=${module}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  bulkDeleteMaterial(materiallist: string[]): Observable<any> {
    const apiUrl = `${
      environment.BulkDeleteMaterial
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, new DeleteItem(materiallist), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addMaterial(data): Observable<any> {
    const apiUrl = `${environment.AddMaterial}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getMaterialById(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetMaterialById
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updatematerialById(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateMaterialById
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  AddupdateMaterialDocuments(data): Observable<any> {
    const apiUrl = `${
      environment.AddupdateMaterialDoc
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  addMaterialRelation(data): Observable<any> {
    const apiUrl = `${environment.AddMaterialRelation}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addMaterialSupplier(data): Observable<any> {
    console.log('SupMat' + data);
    const apiUrl = `${environment.AddMaterialSupplier}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  addSupplierColor(data): Observable<any> {
    const apiUrl = `${environment.AddSupplierColor}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getSupplierFilesById(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetSupplierFiles
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getMeterialData(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetMeterialData
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getmaterialcolours(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.Getmaterialcolours
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.Getmaterialcolours
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getmaterialsuppliers(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.Getmaterialsuppliers
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.Getmaterialsuppliers
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getSeams(recordCount, seamsViewMode): Observable<any> {
    var apiUrl;

    apiUrl =
      `${environment.GetAllSeams}` +
      '?Type=' +
      seamsViewMode +
      '&RecordCount=' +
      recordCount;

    return this.http
      .get<any>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  bulkUpdateSeams(data): Observable<any> {
    const apiUrl = `${
      environment.BulkUpdateSeams
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  bulkDeleteSeams(seamslist: string[]): Observable<any> {
    const apiUrl = `${
      environment.BulkDeleteSeams
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, new DeleteColor(seamslist), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getAdvanceSearchSeams(seams: any): Observable<any> {
    const apiUrl = `${
      environment.SeamsAdvanceSearch
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, seams, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addSeams(data): Observable<any> {
    const apiUrl = `${environment.AddSeams}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  updateSeamsById(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateSeamsById
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getSeamsById(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetSeamsById
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateSeamsDocuments(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateSeamDocument
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getDocumentBySeamId(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetDocumentBySeamId
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //Start Palettes...
  getAllPalettes(recordCount, paletteViewMode): Observable<any> {
    var apiUrl;

    apiUrl =
      `${environment.GetAllPalettes}` +
      '?Type=' +
      paletteViewMode +
      '&RecordCount=' +
      recordCount;

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkUpdatePalettes(data): Observable<any> {
    const apiUrl = `${
      environment.BulkUPdatePalettes
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkDeletePalettes(palettelist: string[]): Observable<any> {
    const apiUrl = `${
      environment.BulkDeletePalettes
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, new DeleteColor(palettelist), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAdvanceSearchPalettes(palettes: any): Observable<any> {
    const apiUrl = `${
      environment.PalettesAdvancedSearch
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, palettes, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getPaletteById(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetPaletteByID
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addPalette(data): Observable<any> {
    const apiUrl = `${environment.AddPalette}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updatePaletteById(data): Observable<any> {
    const apiUrl = `${
      environment.UpdatePaletteById
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updatePaletteDocuments(data): Observable<any> {
    const apiUrl = `${
      environment.UpdatePaletteDocuments
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  //End Palettes...

  //Start Style
  //To get MAterial Data of User by Email.
  getMaterialBOM(): Observable<any> {
    return this.http
      .get(
        `${environment.GetMaterialBOM}` + '?email=' + this.storage.getEmail(),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.storage.getToken(),
          }),
          observe: 'response',
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To get MAterial Data of User by Email.
  getColorBOM(): Observable<any> {
    return this.http
      .get(`${environment.GetColorBOM}` + '?email=' + this.storage.getEmail(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }



  saveOrganisationForuser(email,org){
    var apiUrl = `${environment.SaveUserOrg}?email=${email}&org=${org}`;
    return this.http
      .post(apiUrl,{}, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        },),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getOrganisationBasedOnUser(email: any) {
    var apiUrl = `${environment.GetUserRelatedOrganisationInfo}?Email=${email}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  //To get all Styles Data of User by Email.
  getAllStyles(recordCount, styleviewmode,org?:any): Observable<any> {
    var apiUrl;

      if(styleviewmode == 'Shared'){
        apiUrl = `${environment.GetAllStyles}` + '?Type=' + styleviewmode + '&RecordCount=' + recordCount + '&sharedOrg=' +org;
      }
      else{
        apiUrl =
        `${environment.GetAllStyles}` +
          '?Type=' +
          styleviewmode +
          '&RecordCount=' +
          recordCount;
      }
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  //To add Styles Bom Data of User by Email.
  addupdatedeleteStyleBOMS(data): Observable<any> {
    const apiUrl = `${environment.AddUpdateDeleteStyleBOMS}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  AddStyles(data): Observable<any> {
    const apiUrl = `${environment.AddStyles}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  cloneStyle(data): Observable<any> {
    const apiUrl = `${environment.styleClone}`;
    //console.log('apiUrl',apiUrl);
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  AddStyleColorway(data): Observable<any> {
    const apiUrl = `${environment.AddStyleColorway}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  GetStyleByID(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetStyleByID
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  GetStyleDataByID(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetStyleDataById
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  updateStylebyId(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateStyleByID
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAdvanceSearchStyle(colors: any): Observable<any> {
    const apiUrl = `${
      environment.StyleAdvancesearch
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, colors, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  AddUpdateDeleteStyImages(data): Observable<any> {
    const apiUrl = `${
      environment.AddUpdateDeleteStyleImages
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  GetStyleImages(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetStyleImages
    }?RecordID=${id}&ImageType=StyleImage&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  GetStylePatterns(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetStyleImages
    }?RecordID=${id}&ImageType=PatternImage&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  GetStyleDocuments(id: string): Observable<any> {
    const apiUrl = `${
      environment.GetStyleDocuments
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  UpdateStyleColourways(data): Observable<any> {
    const apiUrl = `${
      environment.UpdateStyleColourways
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To add update Styles pattern Data of User by Email.
  addupdatedeleteStylePatterns(data): Observable<any> {
    const apiUrl = `${environment.AddUpdateDeleteStylePatterns}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getstylepatterns(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.Getstylepatterns
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.Getstylepatterns
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To add update Styles pattern Data of User by Email.
  addupdatedeleteStylespecification(data): Observable<any> {
    const apiUrl = `${environment.AddUpdateDeleteStyleSpecifications}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getStyleStitcheSpecifications(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.GetStyleStitcheSpecifications
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.GetStyleStitcheSpecifications
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getStylePomSpecifications(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.GetStylePomSpecifications
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.GetStylePomSpecifications
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To add update Styles pattern Data of User by Email.
  addupdatedeleteStyleprocessing(data): Observable<any> {
    const apiUrl = `${environment.AddUpdateDeleteStyleProcessing}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getStyleSewnProcessing(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.GetStyleSewnProcessing
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.GetStyleSewnProcessing
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getStylePomProcessing(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.GetStylePomProcessing
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.GetStylePomProcessing
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getdetailsForBOM(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.GetDetailsForBOM
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.GetDetailsForBOM
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getstyleConstruction(id: string, recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${
          environment.GetStyleConstruction
        }?RecordID=${id}&userEmail=${this.storage.getEmail()}` +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.GetStyleConstruction
      }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //To add update Styles pattern Data of User by Email.
  addupdatedeletestyleConstructions(data): Observable<any> {
    const apiUrl = `${environment.AddUpdateDeleteStyleConstructions}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkdeleteStyles(colorlist: string[]): Observable<any> {
    const apiUrl = `${
      environment.BulkDeleteStyles
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, new DeleteColor(colorlist), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  bulkupdateStyles(data): Observable<any> {
    const apiUrl = `${
      environment.BulkUpdateStyles
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getallUsers(): Observable<any> {
    const apiUrl = `${
      environment.GetAllusers
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  AddUpdateStyleSustainability(data): Observable<any> {
    const apiUrl = `${environment.AddUpdateStyleSustainability}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addupdatesharedaccess(data): Observable<any> {
    const apiUrl = `${
      environment.AddUpdateSharedaccess
    }?userEmail=${this.storage.getEmail()}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getsharedaccess(id: string, style: string): Observable<any> {
    const apiUrl = `${
      environment.GetSharedAccess
    }?ModuleName=${style}&RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // getdropdownlibraryitems(): Observable<any> {
  //   const apiUrl = `${environment.GetDropdownlibraryitems}?Module=Styles&RecordCount=0-10`;
  //   return this.http.get(apiUrl, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + this.storage.getToken()
  //     }),
  //     observe: 'response'
  //   }).pipe(
  //     catchError(err => {
  //       return throwError(err)
  //     })
  //   );
  // }

  getstylecolourways(id: string): Observable<any> {
    const apiUrl = `${environment.GetStyleColourways}?RecordID=${id}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  //End Style

  //User Management
  getUserById(id: string): Observable<any> {
    const apiUrl = `${
      environment.getuserbyid
    }?RecordID=${id}&userEmail=${this.storage.getEmail()}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  sendInvite(data): Observable<any> {
    return this.http
      .post(`${this.USER_INVITE_URL}`, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getallUsersMang(recordCount): Observable<any> {
    var apiUrl;
    if (recordCount) {
      apiUrl =
        `${environment.GetAllUserMang}` +
        '?userEmail=' +
        this.storage.getEmail() +
        '&RecordCount=' +
        recordCount;
    } else {
      apiUrl = `${
        environment.GetAllUserMang
      }?userEmail=${this.storage.getEmail()}`;
    }

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //MoodBoard Start
  addMoodBoard(moodBoardData: any) {
    const apiUrl = `${environment.AddMoodBoard}`;
    return this.http
      .post(apiUrl, moodBoardData, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAllMoodBoards(recordCount, moodboardViewMode): Observable<any> {
    var apiUrl;
    apiUrl =
      `${environment.GetAllMoodBoards}` +
      '?Type=' +
      moodboardViewMode +
      '&RecordCount=' +
      recordCount;

    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getMoodBoardById(id: string): Observable<any> {
    const apiUrl = `${environment.GetMoodBoardById}?moodboardID=${id}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteMoodBoardById(id: string): Observable<any> {
    const apiUrl = `${environment.DeleteMoodBoardById}?RecordID=${id}`;
    return this.http
      .post(apiUrl, id, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getImageLibraryIntoMoodBoard(): Observable<any> {
    const apiUrl = `${environment.GetImageURLSs}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getImageByURL(url: any): Observable<any> {
    const apiUrl = `${environment.GetImageByURL}?url=${encodeURIComponent(
      url
    )}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  //MoodBoard End
  //Logging Start
  addClientLog(data): Observable<any> {
    return this.http
      .post(`${environment.AddClientLog}`, data, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  AddClientLog() {
    this.log = JSON.parse(
      localStorage.getItem(new Date().toLocaleDateString())
    );
    if (this.log != null) {
      this.addClientLog(this.log).subscribe();
      this.log = [];
      this.logger.clear();
    }
  }

  //Logging end

  //Notifications
  getNotifications(): Observable<any> {
    const apiUrl =
      `${environment.getNotificationbyUser}` +
      '?email=' +
      this.storage.getEmail();
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getExistingDevice(data): Observable<any> {
    const apiUrl = `${environment.getExistingDevice}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  readNotifications(data): Observable<any> {
    const apiUrl = `${environment.readNotifications}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteNotifications(data): Observable<any> {
    const apiUrl = `${environment.deleteNotifications}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //Notifications End

  //Dashboard Start

  getRecentActivities(recordCount: any): Observable<any> {
    const apiUrl =
      `${environment.GetRecentActivities}` + '?RecordCount=' + recordCount;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getDashboardNotifications(recordCount: any): Observable<any> {
    const apiUrl =
      `${environment.GetDashboardNotifications}` +
      '?RecordCount=' +
      recordCount;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getDashboardFavourites(recordCount: any): Observable<any> {
    const apiUrl =
      `${environment.GetDashboardFavourites}` + '?RecordCount=' + recordCount;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  //Dashboard End

  getDetailsByModuleName(module: any, recordCount: any): Observable<any> {
    const apiUrl =
      `${environment.GetDetailsByModuleName}` +
      '?Module=' +
      module +
      '&RecordCount=' +
      recordCount;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  upload(data): Observable<any> {
    const apiUrl = `${environment.uploadMaterials}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          //'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  uploadStyles(data): Observable<any> {
    const apiUrl = `${environment.uploadStyles}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          //'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  uploadSuppliers(data): Observable<any> {
    const apiUrl = `${environment.uploadSuppliers}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          //'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  uploadColors(data): Observable<any> {
    const apiUrl = `${environment.uploadColors}`;
    return this.http
      .post(apiUrl, data, {
        headers: new HttpHeaders({
          //'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getStyleSizeRange() {
    const apiUrl = `${environment.styleSizeRnage}`;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          //'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  downloadFileFromS3(bucketName: string, key: string): void {
    const params = { Bucket: bucketName, Key: key };

    // Make a request to get a pre-signed URL for the file
    this.s3.getSignedUrl('getObject', params, (err: any, url: string) => {
      if (err) {
        console.error('Error getting pre-signed URL:', err);
        return;
      }

      // Use HttpClient to download the file
      this.http.get(url, { responseType: 'blob' }).subscribe((data: any) => {
        // Create a blob from the response
        const blob = new Blob([data]);

        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = key; // Set the filename
        link.click();
      });
    });
  }
  getPOMTemplatesList(templateName:any): Observable<any>{
    const apiUrl = `${environment.getStylePOMTemplate}` + '?TemplateName='+templateName;
    return this.http
      .get(apiUrl, {
        headers: new HttpHeaders({
          //'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  /*Generative-AI */

  getAIPOMList(payload: generativeAI): Observable<any> {
    const apiUrl = environment.POMAIList;
    return this.http
      .post(apiUrl,payload, {
        headers: new HttpHeaders({
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getAIDraftedPOMTemplatesList(pomNameList:any): Observable<any> {
    const apiUrl = environment.POMAITemplates;
    return this.http
      .post(apiUrl, pomNameList, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getAIBOMList(payload: any): Observable<any> {
    const apiUrl = environment.BomAI;
    return this.http
      .post(apiUrl, payload,{
        headers: new HttpHeaders({
          // //'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getAIConstructionList(payload: any): Observable<any> {
    const apiUrl = environment.ConstructionListAI;
    return this.http
      .post(apiUrl,payload, {
        headers: new HttpHeaders({
          // //'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getDescUsingAI(descPayload:any){
    const DescUsingAI = environment.DecsUsingAI;
    return this.http
      .post(DescUsingAI, descPayload, {
        headers: new HttpHeaders({
          // //'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + this.storage.getToken(),
        }),
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );

  }

}
