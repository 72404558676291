import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ColorEvent } from 'ngx-color';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { SharedService } from '../../../services/shared.service';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import { IColor, ColourDocuments } from '../../../models/color';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import * as S3 from 'aws-sdk/clients/s3';
import html2canvas from 'html2canvas';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as paletteReducer from "../../../reducers/palette.reducer";
import * as paletteActionTypes from "../../../actions/palette.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs'; import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { Router } from '@angular/router';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { MatDialogRef } from '@angular/material/dialog';

declare var $: any;
@Component({
  selector: 'app-palette-custom',
  templateUrl: './palette-custom.component.html',
  styleUrls: ['./palette-custom.component.css']
})
export class PaletteCustomComponent implements OnInit {
  paletteForm: FormGroup;
  documentsForm: FormGroup;
  activeTab = 'paletteDetailsTab';
  isIDVisible = false;
  staticMsgs: any;
  isFilesAdded: boolean= false;
  get f() { return this.paletteForm.controls; }
  editedPaletteViewValue = '';
  files: File[] = [];
  fileData: Array<any> = [];
  editedFileData: Array<any> = [];
  removedDocIds = '';
  documentClassificationData: any[];
  cardsTop = [];
  documentImage = "../../../../assets/images/doccard/document.png";
  cards = [];
  colorCode = [];
  editedPaletteData: any;
  isColorAdded: boolean=false;
  showLoader: boolean;
  showLoader1: boolean;
  submitted = false;
  submitted1 = false;
  isEditMode: boolean;
  isEditingMode: boolean;
  isViewingMode: boolean;
  colorSection: any;
  log: any[];
  sequenceNumber = '';
  thumbnail = '../../../../assets/images/pallet.png';
  isCreateMode:boolean
  /* Written by : Himanshu */
  notfoundImg: string;
  public paletteDataCnt = 0;
  showButton:boolean=false;

  imageObject = [{
    image: '../../../../assets/images/color-card/1.png',
    thumbImage: '../../../../assets/images/color-card/1.png',
}
];
  constructor(
    private fb: FormBuilder,
    private router:Router,
    private sharedService: SharedService,
    private dataservice: DataService,
    private storageservice: StorageService,
    public userservice: UserService,
    private dialogRef: MatDialogRef<PaletteCustomComponent>,
    private _lightbox: Lightbox,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private store: Store<any>) {
    this.documentsForm = this.fb.group({
      documents: this.fb.array([])
    })
    //this.setDocuments();
  }


  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    this.dataservice.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    this.colorSection = [
      // {
      //   "colorHex":"",
      //   "colorbg":"",
      //   "isopencolor": ""
      // },
    ];

    $(document).ready(function () {

      $(".chrome-picker").css("width", "165px");
    });

    setTimeout(function () {
      $("#sortable").sortable();
      $("#sortable").disableSelection();
      $("#sortable").sortable({ handle: '.handle' });
    }, 10);

    if (this.storageservice.getEditedPaletteId()) {
      this.isCreateMode = false;
      if (this.storageservice.getEditedPaletteDetailsId()) {
        this.storageservice.removePaletteDetailsId();
        // this.isEditMode = false;
        // this.isEditingMode = true;
        // this.isViewingMode = false;
        this.editPalette();
      }
      else {
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.isIDVisible = true;
        this.getPaletteDetails();
      }
    }
    else {
      this.getDocumentClassification("Documents");
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
      this.isIDVisible = false;
      this.isCreateMode = true;
    }
    this.initForm();
    this.loadSelectPicker();
    this.sharedService.sendClickEvent();
  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataservice.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  addColor() {

    $(".chrome-picker").css("width", "165px");

    this.isColorAdded = true;
    this.colorSection.forEach((x, i) => {
      x.isopencolor = false;
    });

    var item = {
      "colorHex": "#FF0000",
      "colorbg": "#FF0000",
      "isopencolor": false,
    }
    this.colorSection.push(item);
  }

  removeColor(index) {
    this.colorSection.splice(index, 1);
    this.colorSection.forEach((x, i) => {
      x.isopencolor = false;
    });
    if(this.colorSection.length == 0){
      this.isColorAdded=false;
    }
  }

  editColor($event, index, colorelement) {
    //$event.preventDefault();
    this.colorSection.forEach((x, i) => {
      if (index != i) { x.isopencolor = false; }
    });
    colorelement.isopencolor = !colorelement.isopencolor;
    $(".chrome-alpha").css("display", "none");

    $(".chrome-picker").css("width", "165px");
  }

  chromeClick(colorelement) {
    colorelement.isopencolor = !colorelement.isopencolor;
  }

  handleChange($event: ColorEvent, colorelement) {
    colorelement.colorbg = ($event.color.hex);
    colorelement.colorHex = ($event.color.hex);

  }

  saveJPG() {

    if (this.colorSection.length > 0) {
      this.colorSection.forEach((x, i) => {
        x.isopencolor = false;
      });

      $(".color-icons").hide();
      setTimeout(function () {
        $(".color-icons").show();
      }, 20);
      var img = document.getElementById("sortable");
      html2canvas(img).then(function (canvas) {
        // var generatedImg = canvas.toDataURL("image/png").replace("image/png","image/octet-steram");
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.download = "Palette.jpg";
        link.href = canvas.toDataURL();
        link.target = '_blank';
        link.click();
      });
    }
    else {
      this.error(this.staticMsgs.paletteDetail.paletteNotFound);
    }
  }



  saveJPGView() {

    if (this.colorSection.length > 0) {
      $(".color-icons").hide();
      setTimeout(function () {
        $(".color-icons").show();
      }, 20);
      var img = document.getElementById("sortableView");
      html2canvas(img).then(function (canvas) {
        // var generatedImg = canvas.toDataURL("image/png").replace("image/png","image/octet-steram");
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.download = "Palette.jpg";
        link.href = canvas.toDataURL();
        link.target = '_blank';
        link.click();
      });
    }
    else {
      this.error(this.staticMsgs.paletteDetail.paletteNotFound);
    }

  }

  initForm() {
    this.showLoader = true;

    this.paletteForm = this.fb.group({
      sequence: [{ value: '', disabled: true }],
      name: ['',  [Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      description: ['',[Validators.maxLength(500)]],
      status: ['', [Validators.required]],

    });
    this.showLoader = false;
  }

  characterOnly(e: any) {
    var regex = new RegExp("^[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  editPalette() {
    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;

    if (this.activeTab === "paletteDetailsTab") {
      this.isColorAdded = true;
      if (this.storageservice.getEditedPaletteId()) {
        this.getPaletteDetails();
      }
      this.initForm();
      this.loadSelectPicker();
    }
    else if (this.activeTab === "docDetailsTab") {
      this.loadSelectPicker();
      this.showLoader = true;
      this.showButton=false;
      if (this.editedPaletteData.documents) {
        if (this.editedPaletteData.documents.length > 0) {
          this.editedFileData = [];
          this.removedDocIds = "";
          //this.removedFileData = [];
          this.isFilesAdded=true;
          let control = <FormArray>this.documentsForm.controls.documents;
          if (control.length > 0) {
            this.documentsForm = this.fb.group({
              documents: this.fb.array([])
            })
          }
          let editeddata = JSON.stringify(this.editedPaletteData.documents);
          let data = JSON.parse(editeddata);
          this.reloadDocumentFormData(data);
          this.showLoader = false;
        }
        else {
          this.showLoader = false;
        }
      }
      else {
        this.showLoader = false;
      }
    }

  }

  paletteDetailsTab(activeTab) {
    this.activeTab = activeTab;
    this.isColorAdded = true;
    if (this.storageservice.getEditedPaletteId()) {
      this.getPaletteDetails();
    }

    this.initForm();
    this.loadSelectPicker();
  }

  docDetailsTab(activeTab) {
    this.activeTab = activeTab;
    this.showLoader = true;
    if (this.editedPaletteData.documents) {

      if (this.editedPaletteData.documents.length > 0) {

        this.editedFileData = [];
        this.removedDocIds = "";
     this.isFilesAdded=true;
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        let editeddata = JSON.stringify(this.editedPaletteData.documents);
        let data = JSON.parse(editeddata);

        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {

        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader = false;
  }

  getDocumentClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.paletteDetail.docLoad, "PaletteDetails");
          this.documentClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.showLoader = false;
        } else {
          this.showLoader = false;
          this.error("Failed:" + classificationData.common.message?.item1)
          this.logger.error(classificationData.common.message?.item1, "PaletteDetails");
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, "PaletteDetails");
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })

    /* this.dataservice.getClassification(module).subscribe(response => {
     if (response.body['item2'] === "Success") {
       let parentclass=response.body['item1'][0]["items"];
       for(let i=0;i<parentclass.length;i++){
         parentclass[i].parentName= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i]['parentName']);
         parentclass[i].text= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i]['text']);

         if(parentclass[i].items.length>0){
             for(let j=0;j<parentclass[i].items.length;j++){
               parentclass[i].items[j].parentName= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i].items[j]['parentName']);
               parentclass[i].items[j].text= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i].items[j]['text']);
             }
           }
         }
       this.documentClassificationData =parentclass;
       this.logger.info("Document classification loaded successfully","PaletteDetails");
       //console.log(JSON.stringify(this.documentClassificationData ))
       //this.refreshDocTree();
       this.showLoader = false;
     }
     else {
       this.error(response.body['item1']);
       this.logger.error(response.body['item1'],"PaletteDetails");
       this.showLoader = false;
     }
   }, err => {
     this.logger.error(err.error.message,"PaletteDetails");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
       this.showLoader = false;
     } else if (err.error.message === "The incoming token has expired") {
       this.sessionTimedOut();
       this.showLoader = false;
     } else if (err.error.message === "Unauthorized") {
       this.userservice.error();
       this.showLoader = false;
     }
     else {
       this.error(err.message);
       this.logger.error(err.message,"PaletteDetails");

     }
     this.showLoader = false;
   }); */
  }

  cancelPaletteEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents')as FormArray).clear();
    if (this.storageservice.getEditedPaletteId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getPaletteDetails();
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.initForm();
    this.loadSelectPicker();
  }

  getPaletteDetails() {
    this.showLoader1 = true;
    this.store.dispatch(new paletteActionTypes.LoadPalette(this.storageservice.getEditedPaletteId()));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((paletteData) => {
      if (paletteData.palette.error === "") {
        if (paletteData.palette.message['item2'] === 'Success') {
          this.showLoader1 = false;
          this.editedPaletteData = paletteData.palette.entities[this.storageservice.getEditedPaletteId()];
          this.logger.info(this.editedPaletteData["name"] + this.staticMsgs.paletteDetail.loadSuccess, "PaletteDetails");
          this.initForm();
          this.getDocumentClassification("Documents");
          this.sequenceNumber = paletteData.palette.message['item1']["sequence"];
          this.paletteForm.get('sequence').setValue(this.editedPaletteData["sequence"]);
          this.paletteForm.get('name').setValue(this.editedPaletteData["name"]);
          this.paletteForm.get('description').setValue(this.editedPaletteData["description"]);
          this.paletteForm.get('status').setValue(this.editedPaletteData["status"]);
          this.refreshSelect();
          setTimeout(function () {
            $("#sortable").sortable();
            $("#sortable").disableSelection();
            $("#sortable").sortable({ handle: '.handle' });
          }, 10);

          if (this.editedPaletteData["colourHexCodes"]) {
            var colourHexCodes = this.editedPaletteData["colourHexCodes"];
            var hexCodes = colourHexCodes.split(',');
            this.colorSection = [];
            this.isColorAdded = true;
            hexCodes.forEach((code, i) => {
              var item = {
                "colorHex": code,
                "colorbg": code,
                "isopencolor": false
              }
              this.colorSection.push(item);
            });
          }
        } else {
          this.showLoader1 = false;
          this.error("Failed:" + paletteData.palette.message['item1'])
          this.logger.error(paletteData.palette.message['item1'], "PaletteDetails");
        }
      } else {
        this.logger.error(paletteData.palette.error.error.message, "PaletteDetails");
        this.getError(paletteData.palette.error);
        this.showLoader1 = false;
      }
    })
    /* this.dataservice.getPaletteById(this.storageservice.getEditedPaletteId()).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.showLoader1 = false;
        this.editedPaletteData = response.body['item1'];
        this.logger.info(response.body['item1']["name"] + " loaded successfully", "PaletteDetails");
        this.initForm();
        this.paletteForm.get('sequence').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sequence"]));
        this.paletteForm.get('name').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["name"]));
        this.paletteForm.get('description').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["description"]));
        this.paletteForm.get('status').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["status"]));
        this.refreshSelect();
        setTimeout(function () {
          $("#sortable").sortable();
          $("#sortable").disableSelection();
          $("#sortable").sortable({ handle: '.handle' });
        }, 10);

        if (this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["colourHexCodes"])) {
          var colourHexCodes = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["colourHexCodes"]);
          var hexCodes = colourHexCodes.split(',');
          this.colorSection = [];
          this.isColorAdded = true;
          hexCodes.forEach((code, i) => {
            var item = {
              "colorHex": code,
              "colorbg": code,
              "isopencolor": false
            }
            this.colorSection.push(item);
          });
        }
      }
      else {
        this.showLoader1 = false;
      }
    }, err => {
      this.logger.error(err.error.message, "PaletteDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader = false;
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "PaletteDetails");

      }
      this.showLoader = false;
    }); */
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    // if (event.previousContainer === event.container) {
    //   moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    // } else {
    //   transferArrayItem(event.previousContainer.data,
    //     event.container.data,
    //     event.previousIndex,
    //     event.currentIndex);
    // }
  }



  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.paletteDetail.session,
      html: this.staticMsgs.paletteDetail.sessExp,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
        return;
      }
    })

  }

  reloadPaletteFormData(data) {
    this.initForm();
    this.storageservice.storeEditPaletteId(data["id"]);
    this.paletteForm.get('sequence').setValue(data["sequence"]);
    this.paletteForm.get('name').setValue(data["name"]);
    this.paletteForm.get('description').setValue(data["description"]);
    this.paletteForm.get('status').setValue(data["status"]);

    if (data["colourHexCodes"]) {
      var colourHexCodes = data["colourHexCodes"];
      var hexCodes = colourHexCodes.split(',');
      this.colorSection = [];
      this.isColorAdded = true;
      hexCodes.forEach((code, i) => {
        var item = {
          "colorHex": code,
          "colorbg": code,
          "isopencolor": false
        }
        this.colorSection.push(item);
      });
    }
  }


  async onPaletteSubmit() {
    this.showLoader = true;
    this.showLoader1 = true;
    this.submitted = true;

    this.colorCode = [];
    var colourhexcode;

    this.colorSection.forEach((x, i) => {
      x.isopencolor = false;
      this.colorCode.push(x.colorHex);
    });

    if (this.colorCode.length > 0) {
      colourhexcode = this.colorCode.join(',');
    }

    if (this.colorCode.length > 0) {
      this.isColorAdded = true;
    }
    else {
      this.isColorAdded = false;
    }

    if (!this.paletteForm.valid) {
      this.error(this.staticMsgs.paletteDetail.invalidPalette)
      this.showLoader = false;
      this.showLoader1 = false;
      return false;
    } else if (!this.isColorAdded) {
      this.showLoader = false;
      this.showLoader1 = false;
      return false;
    }

    let paletteData = {
      name: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.name.value),
      description: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.description.value),
      status: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.status.value),
      colourhexcodes: this.aesdecryptservice.encryptData(this.dataservice.Key, colourhexcode)
    };

    //console.log(JSON.stringify(paletteData));
    //Add PaletteData
    if (!this.f.sequence.value) {
      this.store.dispatch(new paletteActionTypes.CreatePalette(paletteData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((newPaletteData) => {
        if (newPaletteData.palette.error === "") {
          if (newPaletteData.palette.message['item2'] === 'Success') {
            this.showLoader = false;
            this.showLoader1 = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedPaletteData = newPaletteData.palette.message['item1'];
            this.editedPaletteViewValue = "";
            this.reloadPaletteFormData(newPaletteData.palette.message['item1']);
            this.logger.info(this.staticMsgs.paletteDetail.paletteCreated, "PaletteDetails");
            this.success(this.staticMsgs.paletteDetail.paletteCreated1);
            this.dialogRef.close();
          } else {
            this.showLoader = false;
            this.showLoader1 = false;
            this.error("Failed:" + newPaletteData.palette.message['item1'])
            this.logger.error(newPaletteData.palette.message['item1'], "PaletteDetails");
          }
        } else {
          this.logger.error(newPaletteData.palette.error.error.message, "PaletteDetails");
          this.getError(newPaletteData.palette.error);
          this.showLoader = false;
          this.showLoader1 = false;
        }
      })

      /* this.dataservice.addPalette(paletteData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {

          }
          else {
            this.error(response.body['item2']);
            this.logger.error(response.body['item2'], "PaletteDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "PaletteDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "PaletteDetails");
        }
        this.showLoader = false;
      }); */

    }
    else {
      //Update PaletteData
      let paletteid = { id: this.storageservice.getEditedPaletteId() };
      let updatedPaletteData = { ...paletteData, ...paletteid };
      this.store.dispatch(new paletteActionTypes.UpdatePalette(updatedPaletteData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((paletteData) => {
        if (paletteData.palette.error === "") {
          if (paletteData.palette.message['item2'] === 'Success') {
            this.showLoader = false;
            this.showLoader1 = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedPaletteData = paletteData.palette.message['item1'];
            //console.log(this.editedPaletteData);
            this.editedPaletteViewValue = "";
            this.reloadPaletteFormData(paletteData.palette.message['item1'])
            this.logger.info(this.staticMsgs.paletteDetail.paletteUpd, "PaletteDetails");
            this.success(this.staticMsgs.paletteDetail.paletteUpd1);
            this.dialogRef.close();
          } else {
            this.showLoader = false;
            this.showLoader1 = false;
            this.error("Failed:" + paletteData.palette.message['item1'])
            this.logger.error(paletteData.palette.message['item1'], "PaletteDetails");
          }
        } else {
          this.logger.error(paletteData.palette.error.error.message, "PaletteDetails");
          this.getError(paletteData.palette.error);
          this.showLoader = false;
          this.showLoader1 = false;
        }
      })

      /* this.dataservice.updatePaletteById(updatedPaletteData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedPaletteData = response.body['item1'];
            //console.log(this.editedPaletteData);
            this.editedPaletteViewValue = "";
            this.reloadPaletteFormData(response.body['item1'])
            this.logger.info("Palette Updated Successfully", "PaletteDetails");
            this.success("Palette Updated Successfully!");
          }
          else {
            //console.log(response)
            this.error(response.body['item2']);
            this.logger.error(response.body['item2'], "PaletteDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "PaletteDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "PaletteDetails");
        }
        this.showLoader = false;
      }); */
    }

  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.paletteDetail.oops,
      text: message
    })
  }

  mandatoryError(message: string) {
    Swal.fire({
      icon: 'error',
      title: '',
      text: message
    })
  }
  async uploadFile(file): Promise<any> {

    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );

    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          reject("error");
        }
        resolve(JSON.stringify(data));
      });
    });
  };

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  isValidImageURL(str) {
    str = str.toLowerCase();
    str = str.split('?')[0];
    //moving on, split the uri into parts that had dots before them
    var parts = str.split('.');
    //get the last part ( should be the extension )
    var extension = parts[parts.length - 1];
    //define some image types to test against
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    //check if the extension matches anything in the list.
    if (imageTypes.indexOf(extension) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  doc_Error = [];
  statusForm: boolean = false;
  async documentUploadCheck(documentControl) {
    this.doc_Error = []
    console.log(this.editedFileData);

    // if (this.editedFileData.length > 0) {
    for (let i = 0; i < documentControl.length; i++) {
      if (this.editedFileData.length > 0) {
        if (i + 1 <= documentControl?.length) {
          if (this.editedFileData[i]?.length > 0) {
            this.doc_Error.push('');
          } else {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.paletteDetail.documentisrequired);
            }
          }
        } else {
          if (this.fileData.length > 0) {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.paletteDetail.documentisrequired)
            }
          } else {
            this.doc_Error.push(this.staticMsgs.paletteDetail.documentisrequired)
          }
        }

      } else {
        if (this.fileData.length > 0) {
          if (this.fileData[i].length > 0) {
            this.doc_Error.push('');
          } else {
            this.doc_Error.push(this.staticMsgs.paletteDetail.documentisrequired)
          }
        } else {
          this.doc_Error.push(this.staticMsgs.paletteDetail.documentisrequired)
        }

      }
    }
    // }

    if (this.doc_Error.length > 0) {
      for (let i = 0; i < this.doc_Error.length; i++) {
        if (this.doc_Error[i] == this.staticMsgs.paletteDetail.documentisrequired) {
          this.statusForm = true;
          break;
        } else {
          this.statusForm = false;
        }
      }
    } else {
      this.doc_Error.push(this.staticMsgs.paletteDetail.documentisrequired);
      this.statusForm = true;
    }
    return this.statusForm;
  }


  async onDocSubmit() {
    this.submitted1 = true;
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();

    //alert(this.findInvalidControlsRecursive(this.documentsForm))
    if (!this.documentsForm.valid) {
      this.error(this.staticMsgs.paletteDetail.invalidDoc)
      return false;
    }

    let statusUploadDoc = await this.documentUploadCheck(documentControl);
    if (statusUploadDoc == true) {
      return false
    }
    this.showLoader = true;


    const formData = new FormData();
    formData.append('paletteid', this.storageservice.getEditedPaletteId());
    formData.append('doccount', control.length.toString());
    if (this.removedDocIds) {
      formData.append('docdelete', this.removedDocIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (documentControl[i].get('docid').value) {
        formData.append('docid' + '[' + i + ']', documentControl[i].get('docid').value);
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
      }
      else {
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
      }

      if (documentControl[i].get('docdeletedfileids').value) {
        formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
      }

      formData.append('baseclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Documents"));
      formData.append('docclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docclassification').value));
      formData.append('docname' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docname').value));
      formData.append('docstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docstatus').value));
      formData.append('docdescription' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docdescription').value));
      formData.append('docreportstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docreportstatus').value));
      formData.append('doclablocation' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('doclablocation').value));
      formData.append('docnotes' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docnotes').value));
      formData.append('docfilecount' + '[' + i + ']', this.fileData[i].length);

      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '[]') {
          //console.log (f.name);
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != "error") {
            formData.append('file' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, JSON.parse(fileUploadedData)["Key"]));
            formData.append('filename' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
          }
          else {
            this.error(fileUploadedData);
            this.submitted1 = false;
            this.showLoader = false;
            return false;
          }
        }
        a = a + 1;
      }
    }

    //console.log(JSON.stringify(formData));
    //this.test(formData);
    this.store.dispatch(new paletteActionTypes.UpdatePaletteDocument(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((paletteDocData) => {
      if (paletteDocData.palette.error === "") {
        if (paletteDocData.palette.message['item2'] === 'Success') {
          this.editedFileData = [];
          this.removedDocIds = "";
          this.showLoader = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedPaletteData = paletteDocData.palette.message['item1'];;
          //console.log(this.editedPaletteData);
          if (this.editedPaletteData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            let editeddata = JSON.stringify(this.editedPaletteData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          //this.docDetailsTab('docDetailsTab');
          this.logger.info(this.staticMsgs.paletteDetail.docUpd, "PaletteDetails");
          this.success(this.staticMsgs.paletteDetail.docUpd1);
        } else {
          this.showLoader = false;
          this.error("Failed:" + paletteDocData.palette.message['item1'])
          this.logger.error(paletteDocData.palette.message['item1'], "PaletteDetails");
        }
      } else {
        this.logger.error(paletteDocData.palette.error.error.message, "PaletteDetails");
        this.getError(paletteDocData.palette.error);
        this.showLoader = false;
      }
    })


    /* this.dataservice.updatePaletteDocuments(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          //console.log(JSON.stringify(response.body['item1']));
          this.editedFileData = [];
          this.removedDocIds = "";
          this.showLoader = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedPaletteData = response.body['item1'];
          //console.log(this.editedPaletteData);
          if (this.editedPaletteData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            let editeddata = JSON.stringify(this.editedPaletteData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          //this.docDetailsTab('docDetailsTab');
          this.logger.info("Documents Updated Successfully", "PaletteDetails");
          this.success("Documents Updated Successfully!");
        }
        else {
          //console.log(response)
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "PaletteDetails");
          this.showLoader = false;
        }

      }
    }, err => {
      this.logger.error(err.error.message, "PaletteDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.logger.error(err.message, "PaletteDetails");
        this.error(err.message);
      }
      this.showLoader = false;
    }); */

  }

  reloadDocumentFormData(data) {

    for (let i = 0; i < data.length; i++) {
      this.addNewDoc();
      this.refreshDocTree(i);
    }
    //console.log(JSON.stringify(data))
    let documentControl = this.getControls();
    for (let i = 0; i < data.length; i++) {
      documentControl[i].get('docid').setValue(data[i]["id"]);
      documentControl[i].get('docseq').setValue(data[i]["sequence"]);
      this.refreshDocTree(i);
      if (this.isEditingMode) {
        this.setDocumentTreeValue(i, data[i]["classification"]);
      }
      documentControl[i].get('docclassification').setValue(data[i]["classification"]);
      documentControl[i].get('docname').setValue(data[i]["name"]);
      documentControl[i].get('docstatus').setValue(data[i]["status"]);
      documentControl[i].get('docdescription').setValue(data[i]["description"]);
      documentControl[i].get('docreportstatus').setValue(data[i]["reportStatus"]);
      documentControl[i].get('doclablocation').setValue(data[i]["labLocation"]);
      if (data[i]["classification"] === "Lab Reports") {
        documentControl[i].get('docreportstatus').enable();
        documentControl[i].get('doclablocation').enable();
      }
      else {
        documentControl[i].get('docreportstatus').disable();
        documentControl[i].get('doclablocation').disable();
      }
      documentControl[i].get('docnotes').setValue(data[i]["notes"]);

      if (data[i]["files"][0]) {
        // this.editedFileData=data[i]["files"];
        data[i]["files"][0]['thumbnail'] = data[i]["files"][0]['thumbnail'];
        data[i]["files"][0]['name'] = data[i]["files"][0]['name'];
      }
      this.editedFileData.push(data[i]["files"]);
      /* Written by : Himanshu */
      this.paletteDataCnt = this.fileData[i].length+1;
      this.isFilesAdded=true;

    }
  }

  getControls() {
    return (this.documentsForm.get('documents') as FormArray).controls;
  }


  setDocumentTreeValue(index, value) {
    $(document).ready(function () {
      $("#docclassification" + index).data('kendoDropDownTree').value(value);
    })
  }

  addNewDoc() {

    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    let control = <FormArray>this.documentsForm.controls.documents;
    //alert(control.length)
    control.push(
      this.fb.group({
        docclassification: ['', Validators.required],
        docid: [''],
        docseq: [''],
        docname: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
        docstatus: ['', Validators.required],
        docdescription: ['', Validators.required],
        docreportstatus: ['', Validators.required],
        doclablocation: ['', Validators.required],
        docnotes: [''],
        docdeletedfileids: ['']
      })
    )
    this.files = [];
    this.fileData[control.length - 1] = this.files;
    /* Written by : Himanshu */
    this.showButton=true;
    this.refreshDocTree(control.length - 1);
  }

  removeDoc(index) {
    /* Written by : Himanshu */

    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    if(this.editedFileData.length !== 1) {
    if (this.removedDocIds) {
      this.removedDocIds = this.removedDocIds + "," + documentControl[index].get('docid').value
    }
    else {
      this.removedDocIds = documentControl[index].get('docid').value
    }
  }
  else {
    alert('Minimum 1 document is required');
    return false;
  }
    // if(this.editedFileData[index].length > 0)
    // {
    //   let deletedids = "";
    //   for (let i = 0; i < this.editedFileData[index].length; i++) {
    //     //console.log(this.editedFileData[index][i]["id"])
    //     if(deletedids)
    //     {
    //     deletedids = deletedids + "," + this.editedFileData[index][i]["id"]
    //     }
    //     else
    //     {
    //       deletedids = this.editedFileData[index][i]["id"]
    //     }
    //   }
    //   console.log("deletedids"+ deletedids)
    //   documentControl[index].get('docid').setValue(deletedids);
    // }
    control.removeAt(index)
    this.fileData.splice(index, 1);
    //console.log(this.fileData);
    this.editedFileData.splice(index, 1);
    //this.removedFileData[index].push([]);
  }

  onRemoveDoc(event, i) {
    this.fileData[i].splice(this.fileData[i].indexOf(event), 1);
    //console.log(this.fileData);
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  onSelectDoc(event, i) {
    this.files = [];
    let imgext = "png,jpeg,jpg,xlsx,pdf,txt,docx,zip,pptx"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.files.push(...event.addedFiles);
      this.isFilesAdded = true;
      this.doc_Error[i] = '';

    }
    else {
      this.isFilesAdded = false;
      this.error("File Not Supported.")
      return false;
    }
     console.log(event.addedFiles,'event');
     this.fileData[i] = this.files;
  }



  refreshDocTree(index: number) {
    var data = this.documentClassificationData;
    var currentDocument = this.getControls();

    var self = this;
    $(document).ready(function () {
      var treeview = $("#docclassification" + index).kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        dataSource: data,
        filter: "startswith",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          //alert(value);
          if (value) {
            //self.documentclassificationValue = value["text"];
            //console.log(value["text"])
            currentDocument[index].get('docclassification').setValue(value["text"]);
            if (value["text"] === "Lab Reports") {
              currentDocument[index].get('docreportstatus').enable();
              currentDocument[index].get('doclablocation').enable();
            }
            else {
              currentDocument[index].get('docreportstatus').disable();
              currentDocument[index].get('doclablocation').disable();
            }
          }
          else {
            //self.documentclassificationValue = "";
            currentDocument[index].get('docclassification').setValue("");
          }
        }
      });

    });
  }

  isEmptyObject(obj) {
    return JSON.stringify(obj) === '[]';
  }

  open(index: number, imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedFileData[index].length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedFileData[index][i - 1]["name"])) {
        src = this.editedFileData[index][i - 1]["thumbnail"];
        thumb = this.editedFileData[index][i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  removeImgSection(event, i) {
    this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    //console.log(this.editedFileData);
    if(this.editedFileData[i].length == 0){
      this.isFilesAdded = false;
    }
    let documentControl = this.getControls();
    let docdeltedFiles = documentControl[i].get('docdeletedfileids').value;
    if (docdeltedFiles) {
      documentControl[i].get('docdeletedfileids').setValue(docdeltedFiles + "," + event.id);
    }
    else {
      documentControl[i].get('docdeletedfileids').setValue(event.id);
    }
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  cancelDocumentEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents')as FormArray).clear();
    this.isColorAdded = true;
    if (this.storageservice.getEditedPaletteId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.showLoader = true;
    if (this.editedPaletteData.documents) {
      if (this.editedPaletteData.documents.length > 0) {
        this.editedFileData = [];
        this.removedDocIds = "";
        //this.removedFileData = [];
        this.isFilesAdded=true;
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        let editeddata = JSON.stringify(this.editedPaletteData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }
  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Image Type' });
    });
  }

  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

   onDeletePalette(){

    Swal.fire({
      title: this.staticMsgs.paletteDetail.deletePalette,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.paletteDetail.deleteit
    }).then((result) => {
      if (result.value) {
        this.showLoader=true;
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getEditedPaletteId());
        this.store.dispatch(new paletteActionTypes.DeletePaletteLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedPaletteLibrary) => {
          /*  if (colorLibrary.moodboardState.error === "") {
             if(colorLibrary.moodboardState.ids[0]!== undefined){
             } */
          if (deletedPaletteLibrary.palette.error === "") {
            if (deletedPaletteLibrary.palette.message?.item2 === 'Success') {
              this.success(this.staticMsgs.paletteDetail.paletteDeleted1);
              this.logger.info(this.staticMsgs.paletteDetail.paletteDeleted, "PaletteLibrary");
              this.router.navigate(['/palettelibrary']);
              this.showLoader = false;
            } else {
              this.showLoader = false;
              this.error("Failed:" + deletedPaletteLibrary.palette.message?.item1)
              this.logger.error(deletedPaletteLibrary.palette.message?.item1, "PaletteLibrary");
            }

          } else {
            this.logger.error(deletedPaletteLibrary.palette.error.error.message, "PaletteLibrary");
            this.getError(deletedPaletteLibrary.palette.error);
            this.showLoader = false;
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }
  cancelPaletteCreate(){
    this.router.navigate(['/palettelibrary']);
    this.dialogRef.close();
  }

}
