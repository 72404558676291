import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { TokenService } from './token.service';
import Amplify, { Auth, API } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import awsconfig from '../../../../aws-exports';
import { Router } from '@angular/router'
import { AuthService } from '../services/auth.service';
import { environment } from '../../../../tenants/main/environments/environment';
import {​​​​​​​​ io }​​​​​​​​ from'socket.io-client';
import { select, Store } from '@ngrx/store';
import * as userActions from "../actions/userActions.actions";
const socket = io(environment.socketIoServerUrl, {transports: ['websocket', 'polling', 'flashsocket']});

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public currentUser = {
		user: '',
		thumb: 'https://via.placeholder.com/150x150',
		userrole: ''
	};



  constructor(
    private storage: StorageService,
    private store: Store<any>,
    private token: TokenService,
     private router: Router,
     private auth: AuthService) { }

  hasToken(){
    const storedToken = this.storage.getToken();
    if(storedToken){
       return this.token.isValid(storedToken) ? true : this.logout();
    }
    return false;
}

getFName(){
    let fName = JSON.parse(localStorage.getItem('fullName'));
    return fName.firstName;
}

getLName(){
  let lName = JSON.parse(localStorage.getItem('fullName'));
  return lName.lastName;
}

hasValidToken(){
  const storedToken = this.storage.getToken();
  if(storedToken){
     return !this.token.isExpired(storedToken) ? true : this.logout();
  }
  return false;
}

loggedIn(){
  return this.hasToken();
}

logout(){

  this.store.dispatch(new userActions.Logout());
  this.store.pipe(select(state => state));
  this.auth.signOut().then(data => {
    //console.log(data);
    let email = this.storage.getEmail();
		socket.emit('logout',{userId:email});
    this.storage.clear();
    //localStorage.removeItem('userrole');
    this.router.navigate(['auth/login'], { queryParams: { 'returnUrl': this.router.url } });
  })
}

error(){
    this.router.navigate(['/error'], { queryParams: { 'returnUrl': "/dashboard" } });
}

id(){
  if(this.loggedIn()){
      const payload = this.token.payload(this.storage.getToken());
      return payload.sub;
  }
}

own(id){
  return this.id() == id
}

async configupdate() {
  AWS.config.update({
    region: awsconfig.aws_project_region, accessKeyId: 'AKIAW7NQSUNQROBYVTD5',
    secretAccessKey: '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'
  });
}


// async getUserDetails(params) {
//   this.configupdate();
//   // tslint:disable-next-line: prefer-const
//   const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
//   return new Promise((resolve, reject) => {
//     cognitoidentityserviceprovider.getUser(params, function (err, data) {
//       if (err) {
//         //console.log(err, err.stack); // an error occurred
//         reject(err)
//       }
//       else {
//         //console.log(data);  // successful response
//         resolve(data);
//       }
//     })
//   });
// }

async getUserDetails() {
  return new Promise<any>((resolve) => {
    resolve(Auth.currentUserInfo());
});
}

async updateUserDetails(params) {
  this.configupdate();
  // tslint:disable-next-line: prefer-const
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
  return new Promise((resolve, reject) => {
    cognitoidentityserviceprovider.updateUserAttributes(params, function (err, data) {
      if (err) {
        //console.log(err, err.stack); // an error occurred
        reject(err)
      }
      else {
        //console.log(data);  // successful response
        resolve(data);
      }
    })
  });
}

}
