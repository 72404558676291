import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

export enum SupplierActionTypes {
    LOAD_SUPPLIER_LIBRARY = '[Supplier] Load Supplier Library',
    LOAD_SUPPLIER_LIBRARY_SUCCESS = '[Supplier] Load Supplier Library Success',
    LOAD_SUPPLIER_LIBRARY_FAIL = '[Supplier] Load Supplier Library Fail',
    LOAD_SUPPLIER = '[Supplier] Load Supplier',
    LOAD_SUPPLIER_SUCCESS = '[Supplier] Load Supplier Success',
    LOAD_SUPPLIER_FAIL = '[Supplier] Load Supplier Fail',
    CREATE_SUPPLIER = '[Supplier] Create Supplier',
    CREATE_SUPPLIER_SUCCESS = '[Supplier] Create Supplier Success',
    CREATE_SUPPLIER_FAIL = '[Supplier] Create Supplier Fail',
    UPDATE_SUPPLIER = '[Supplier] Update Supplier',
    UPDATE_SUPPLIER_SUCCESS = '[Supplier] Update Supplier Success',
    UPDATE_SUPPLIER_FAIL = '[Supplier] Update Supplier Fail',
    UPDATE_SUPPLIER_LIBRARY = '[Supplier] Update Supplier Library',
    UPDATE_SUPPLIER_LIBRARY_SUCCESS = '[Supplier] Update Supplier Library Success',
    UPDATE_SUPPLIER_LIBRARY_FAIL = '[Supplier] Update Supplier Library Fail',
    DELETE_SUPPLIER_LIBRARY = '[Supplier] Delete Supplier Library',
    DELETE_SUPPLIER_LIBRARY_SUCCESS = '[Supplier] Delete Supplier Library Success',
    DELETE_SUPPLIER_LIBRARY_FAIL = '[Supplier] Delete Supplier Library Fail',
    UPDATE_SUPPLIER_DOCUMENT = '[Supplier] Update Supplier Document',
    UPDATE_SUPPLIER_DOCUMENT_SUCCESS = '[Supplier] Update Supplier Document Success',
    UPDATE_SUPPLIER_DOCUMENT_FAIL = '[Supplier] Update Supplier Document Fail',
}

export class LoadSupplierLibrary implements Action {
    readonly type = SupplierActionTypes.LOAD_SUPPLIER_LIBRARY
    constructor(public payload1: string,public payload2: string) { }

}

export class LoadSupplierLibrarySuccess implements Action {
    readonly type = SupplierActionTypes.LOAD_SUPPLIER_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadSupplierLibraryFail implements Action {
    readonly type = SupplierActionTypes.LOAD_SUPPLIER_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export class LoadSupplier implements Action {
    readonly type = SupplierActionTypes.LOAD_SUPPLIER

    constructor(public payload: string) { }
}

export class LoadSupplierSuccess implements Action {
    readonly type = SupplierActionTypes.LOAD_SUPPLIER_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadSupplierFail implements Action {
    readonly type = SupplierActionTypes.LOAD_SUPPLIER_FAIL

    constructor(public payload: string) { }
}
export class CreateSupplier implements Action {
    readonly type = SupplierActionTypes.CREATE_SUPPLIER

    constructor(public payload: any) { }
}

export class CreateSupplierSuccess implements Action {
    readonly type = SupplierActionTypes.CREATE_SUPPLIER_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateSupplierFail implements Action {
    readonly type = SupplierActionTypes.CREATE_SUPPLIER_FAIL

    constructor(public payload: string) { }
}
export class UpdateSupplier implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER

    constructor(public payload: any) { }
}

export class UpdateSupplierSuccess implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateSupplierFail implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER_FAIL

    constructor(public payload: string) { }
}

export class UpdateSupplierLibrary implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER_LIBRARY

    constructor(public payload: any[]) { }
}

export class UpdateSupplierLibrarySuccess implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER_LIBRARY_SUCCESS

    constructor(public payload1: Update<any>[],public payload2: any) { }
}

export class UpdateSupplierLibraryFail implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class DeleteSupplierLibrary implements Action {
    readonly type = SupplierActionTypes.DELETE_SUPPLIER_LIBRARY

    constructor(public payload: string[]) { }
}

export class DeleteSupplierLibrarySuccess implements Action {
    readonly type = SupplierActionTypes.DELETE_SUPPLIER_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class DeleteSupplierLibraryFail implements Action {
    readonly type = SupplierActionTypes.DELETE_SUPPLIER_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class UpdateSupplierDocument implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER_DOCUMENT

    constructor(public payload: any) { }
}

export class UpdateSupplierDocumentSuccess implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER_DOCUMENT_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateSupplierDocumentFail implements Action {
    readonly type = SupplierActionTypes.UPDATE_SUPPLIER_DOCUMENT_FAIL

    constructor(public payload: string) { }
}
export type supplierActionTypes = LoadSupplierLibrary | LoadSupplierLibrarySuccess | LoadSupplierLibraryFail |
    LoadSupplier | LoadSupplierSuccess | LoadSupplierFail | CreateSupplier | CreateSupplierSuccess |
    CreateSupplierFail | UpdateSupplier | UpdateSupplierSuccess | UpdateSupplierFail | UpdateSupplierLibrary | UpdateSupplierLibrarySuccess
    | UpdateSupplierLibraryFail | DeleteSupplierLibrary | DeleteSupplierLibrarySuccess | DeleteSupplierLibraryFail
    | UpdateSupplierDocument | UpdateSupplierDocumentSuccess | UpdateSupplierDocumentFail;