<main class="dt-main">
  <!-- <app-leftbar *ngIf="isOn"></app-leftbar> -->
  <app-leftbar></app-leftbar>
  <!-- <app-layout></app-layout> -->
  <app-loader [showLoader]="showLoader"></app-loader>

  <div class="dt-customizer dt-drawer position-right" [class.open]="classApplied">

    <!-- Item Detail Section -->
    <div class="dt-customizer__inner">

      <!-- Customizer Header -->
      <div class="dt-customizer__header">

        <!-- Customizer Title -->
        <div class="dt-customizer__title">
          <h4 class="mb-0" *ngIf="text">{{staticMsgs.moodBoard.EditText}}</h4>
          <h4 class="mb-0" *ngIf="image">{{staticMsgs.moodBoard.Image}}</h4>
          <h4 class="mb-0" *ngIf="shape">{{staticMsgs.moodBoard.Shape}}</h4>
          <h4 class="mb-0" *ngIf="background">{{staticMsgs.moodBoard.Background}}</h4>
          <h4 class="mb-0" *ngIf="highlight">{{staticMsgs.moodBoard.Highlight}}</h4>
          <h4 class="mb-0" *ngIf="colorPanel">{{staticMsgs.moodBoard.ColorPanel}}</h4>
        </div>
        <!-- /customizer title -->
        <!-- Close Button -->
        <button type="button" class="close" (click)="itemDetailsClose()">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- /close button -->

      </div>
      <!-- /customizer header -->
      <!-- Customizer Body -->
      <div class="dt-customizer__body ps-custom-scrollbar">
        <!-- Customizer Body Inner  -->
        <div class="dt-customizer__body-inner">

          <!-- Section -->
          <section *ngIf="textSection" class="d-none d-lg-block text-dark w-70 mx-auto">
            <p class="textColor">{{staticMsgs.moodBoard.Text}}</p>
            <div class="row col-md-12 mt-5">
              <ul class="editText">
                <li><img [src]="bold" (click)="showFontType('text-cmd-bold')"></li>
                <li><img [src]="italic" (click)="showFontType('text-cmd-italic')"></li>
                <li><img [src]="underline" (click)="showFontType('text-cmd-underline')"></li>
                <li><img [src]="strikethrough" (click)="showFontType('text-cmd-linethrough')"></li>
                <li><b class="bold" (click)="showFontType('text-cmd-overline')">O</b></li>
              </ul>

            </div>
            <p class="textColor mt-5">{{staticMsgs.moodBoard.selectColor}}</p>
            <div class="row col-md-12 mt-8 mx-auto">
              <color-chrome (onChange)="showTextColor($event)">
              </color-chrome>
            </div>
          </section>
          <!-- /section -->

          <!-- Section -->
          <section *ngIf="imageSection" class="d-none d-lg-block text-dark w-70 mx-auto users">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xl-12">
                <div class="custom-dropzone" id="thumbnail" ngx-dropzone [multiple]="false"
                  [accept]="'image/png,image/jpeg,image/jpg'" (change)="selectImage($event)">
                  <ngx-dropzone-label>
                    <div>
                      <h5 class="text-light-gray mt-3">&nbsp; {{staticMsgs.moodBoard.DragDrop}} &nbsp; </h5>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files"
                    [removable]="true" (removed)="onRemove(f)" [file]="f">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-image-preview>
                </div>
              </div>
            </div>

            <p class="text-center text-muted mt-4">{{staticMsgs.moodBoard.Or}}</p>
            <div>

              <p class="disableBtn"><i class="fa fa-search mr-2"></i>{{staticMsgs.moodBoard.ExploreFitosStock}}</p>

              <p class="cp" (click)="recommendedImages()"><i class="fa fa-image mr-2"></i>{{staticMsgs.moodBoard.UploadfromImageLibrary}}</p>

              <p class="disableBtn"><i class="fab fa-google-drive mr-2"></i>{{staticMsgs.moodBoard.ConnecttoGoogleDrive}}</p>

              <p class="disableBtn" id="googleBtn"><i class="fa fa-fan mr-2"></i>{{staticMsgs.moodBoard.ConnecttoGooglePhotos}}</p>

              <p class="disableBtn" id="instagramBtn"><i class="fa fa-instagram mr-2"></i>{{staticMsgs.moodBoard.ConnecttoInstagram}}</p>

              <p class="disableBtn" id="pintrestBtn"><i class="fa fa-pinterest mr-2"></i>{{staticMsgs.moodBoard.ConnecttoPintrest}}</p>

            </div>
          </section>
          <!-- /section -->

          <!-- Section -->
          <section *ngIf="backgroundSection || highlightSection || colorPanelSection"
            class="d-none d-lg-block text-dark w-70 mx-auto">
            <color-chrome [color]="color" class="mx-auto" (onChange)="colorChange($event)">
            </color-chrome>

          </section>
          <!-- /section -->

          <!-- Section -->
          <section *ngIf="shapeSection" class="d-none d-lg-block text-dark w-70 mx-auto">
            <ul class="editShape">
              <li><img [src]="rect" (click)="addCanvasElement('rect')"></li>
              <li><img [src]="triangle" (click)="addCanvasElement('tri')"></li>
              <li><img [src]="circle" (click)="addCanvasElement('circle')"></li>
            </ul>

            <p class="textColor mt-5">{{staticMsgs.moodBoard.SelectColorForShape}}</p>
            <div class="row col-md-12 mt-8 mx-auto">
              <color-chrome (onChange)="shapeColorChange($event)">
              </color-chrome>
            </div>

          </section>
          <!-- /section -->



        </div>
        <!-- /customizer body inner -->
      </div>
      <!-- /customizer body -->

    </div>
    <!-- Item Details Section End-->
  </div>

  <!-- Site Content -->
  <div class="dt-content-wrapper">
    <div class="">
      <div class="sidenav">
        <ul class="mainUl text-center">
          <span *ngFor="let item of items; let i = index" (click)="selectedItem(i,'new')"
            [ngClass]="(isHighlightDisable && i== 7) ? 'disable': ''">
            <li [ngClass]="{'active': liitems === i}">
              <span class="mainImg">
                <img [src]='item.img'><span class="mainText f-12">{{item.text}}</span>
              </span>
            </li>
          </span>
        </ul>
      </div>
    </div>
    <!-- Page Header -->
    <div class="dt-page__header">
      <div class="row">
        <div class="col-md-6">
          <h3 class="dt-page__title">{{this.moodBoardObject?.title}}</h3>
          <nav class="mb-0 breadcrumb"><a href="javascript:void(0)" routerLink="/moodboardlibrary"
              class="breadcrumb-item">
              <i class="fa fa-home"></i>
            </a>
            <span class="breadcrumb-item">{{this.moodBoardObject?.title}}</span>
          </nav>
        </div>

        <!-- <div class="col-md-6 d-sm-flex">
                <i class="icon icon-menu" (click)="mainMenu()"></i>
                <h3 class="dt-page__title" (click)="itemDetails()">Moodboard Library</h3>
              </div> -->
        <div class="col-md-6 my-auto">
          <span class="float-right">
            <ul class="dt-nav">
              <li class="dt-nav__item">
                <i class="fas fa-save ul-icon" aria-hidden="true" ngbTooltip="Save" (click)="saveJsonCanvas()"></i>
              </li>
              <li class="dt-nav__item">
                <i class="fa fa-star-o ul-icon" aria-hidden="true"></i>
              </li>
              <li class="dt-nav__item">
                <i class="fa fa-share-alt ul-icon" aria-hidden="true"></i>
              </li>
              <li class="dt-nav__item">
                <i class="fa fa-trash-o ul-icon" aria-hidden="true" (click)="delete()"></i>
              </li>
            </ul>
          </span>
        </div>

      </div>
    </div>


    <!-- Site Content -->
    <div class="dt-content">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xl-12">
          <div class="canvasContainer" id="canvasContainer" (click)="showTextMenu($event)"
            (keydown)="enterTodoListandUrl($event)">
            <canvas id="c" width="1130" height="639"></canvas>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Image Tab Upload Section-->
  <div class="dt-customizer dt-drawer position-right" [class.open]="classAppliedImage">
    <!-- Item Detail Section -->
    <div class="dt-customizer__inner">
      <!-- Customizer Header -->
      <div class="dt-customizer__header">
        <!-- Customizer Title -->
        <div class="dt-customizer__title">
          <h4 class="mb-0" *ngIf="exploreFitos">{{staticMsgs.moodBoard.ExploreFitosStock}}
          </h4>
          <h4 class="mb-0" *ngIf="uploadimageLibrary">{{staticMsgs.moodBoard.UploadfromImageLibrary}}</h4>
          <h4 class="mb-0" *ngIf="googlePhotosLibrary">{{staticMsgs.moodBoard.GooglePhotosLibrary}}</h4>
        </div>
        <!-- /customizer title -->
        <!-- Close Button -->
        <div class="p-fixed mr-2 cp">
          <i class="icon icon-arrow-long-left icon-2x mr-3" (click)="itemDetailsImageClose()"></i>
        </div>
        <!-- <button type="button" class="close" (click)="itemDetailsClose()">
      <span aria-hidden="true">&times;</span>
    </button> -->
        <!-- /close button -->
      </div>
      <!-- /customizer header -->
      <!-- Customizer Body -->
      <div class="dt-customizer__body ps-custom-scrollbar" *ngIf="showGooglePhotos || showRecommendedImages">
        <!-- Customizer Body Inner  -->
        <div class="dt-customizer__body-inner">
          <!-- Section -->
          <!-- <section *ngIf="explorefitos" class="d-none d-lg-block text-dark w-70 mx-auto">
        <div class="row">
         <div class="col-sm-6 col-md-6 col-xl-6" *ngFor="let item of img">
               <img [src]='item.img' class="img-fluid mb-2 img_view cp">
         </div>
        </div>
      </section> -->
          <!-- /section -->
          <!-- Section -->
          <section class="d-none d-lg-block text-dark w-70 mx-auto">
            <div class="row bgC">
              <div class="col-sm-12 col-md-12 col-xl-12" *ngIf="showGooglePhotos || showRecommendedImages">
                <input type="text" #name class="form-control" value="" placeholder="Enter name"
                  (keyup)="searchByName(name.value)" [(ngModel)]="searchName" />
                <i class="fa fa-search search-button ml-230"></i>
              </div>
              <!-- <div class="col-sm-12 col-md-12 col-xl-12 mt-2" *ngIf="!showGooglePhotos && showRecommendedImages">
              <select title="Shared With Me" data-live-search="true" class="form-control selectpicker w-100">
                <option *ngFor="let shared of shared" [value]="shared.value">{{ shared.label }}</option>
              </select>
            </div> -->
              <div class="col-sm-12 col-md-12 col-xl-12 mt-2" *ngIf="!showGooglePhotos && showRecommendedImages">
                <select [(ngModel)]="classificationType" title="Select Classfication" data-live-search="true"
                  class="form-control w-100">
                  <option *ngFor="let classification of imgLibraryClassificationData" [value]="classification.text">{{
                    classification.text }}</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-xl-12 mt-2" *ngIf="!showGooglePhotos && showRecommendedImages">
                <select [(ngModel)]="statusType" title="Select Status" data-live-search="true"
                  class="form-control w-100">
                  <option *ngFor="let status of status" [value]="status.value">{{ status.label }}</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-xl-12 mt-2" *ngIf="!showGooglePhotos && showRecommendedImages">
                <div class="input-group date">
                  <input class="form-control" placeholder="From Date" name="dp" [(ngModel)]="fromDate" ngbDatepicker readonly (click)="d1.toggle()"
                    #d1="ngbDatepicker" (dateSelect)="dateChange($event,'from')" [minDate]="minDate">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                        class="icon icon-calendar"></i></button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-xl-12 mt-2" *ngIf="!showGooglePhotos && showRecommendedImages">
                <div class="input-group date">
                  <input class="form-control" placeholder="To Date" name="dp" [(ngModel)]="toDate" ngbDatepicker readonly (click)="d2.toggle()"
                    #d2="ngbDatepicker" (dateSelect)="dateChange($event,'to')" [minDate]="minDate">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                        class="icon icon-calendar"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row bg">
              <div class="col-sm-12 col-md-12 col-xl-12 mt-4 text-center"
                *ngIf="!showGooglePhotos && showRecommendedImages">
                <button class="btn btn-primary adv-search-btn" type="button" (click)="searchImages()">
                  {{staticMsgs.moodBoard.Search}}
                </button>
                <button class="btn btn-primary adv-search-btn ml-2" type="button" (click)="resetFilter()">
                  {{staticMsgs.moodBoard.Reset}}
                </button>
                <br>
                <i class="fa fa-angle-down f15 rotate" (click)="openPanel()"
                  *ngIf="!showGooglePhotos && showRecommendedImages"></i>
              </div>
            </div>
            <div class="row mt-5" *ngIf="showRecommendedImages">
              <div class="col-sm-6 col-md-6 col-xl-6" *ngFor="let item of recommendedImgData">
                <img [src]='item.imageurl[0]' class="img-fluid mb-2 img_view cp" (click)="selectRecomImg(item.imageurl)"
                  title="{{item.name}}">
              </div>
            </div>
            <div class="row mt-5" *ngIf="showGooglePhotos">
              <div class="col-sm-6 col-md-6 col-xl-6" *ngFor="let item of gPhotosBaseUrl">
                <img [src]='item.baseUrl' class="img-fluid mb-2 img_view cp" (click)="selectGoogleImage(item.baseUrl)"
                  title="{{item.name}}">
              </div>
            </div>
          </section>
          <!-- /section -->
        </div>
        <!-- /customizer body inner -->
      </div>
      <!-- /customizer body -->
    </div>
    <!-- Item Details Section End-->
  </div>
  <!-- Image Tab Upload Section End-->

</main>
