import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { interval, timer } from 'rxjs';
//import { first } from 'rxjs/operators';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Auth } from 'aws-amplify';
import { DataService } from '../../../services/data.service';
import { LogService } from '../../../shared/log.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';

@Component({
  selector: 'app-verifyuser',
  templateUrl: './verifyuser.component.html',
  styleUrls: ['./verifyuser.component.css']
})
export class VerifyuserComponent implements OnInit {
  @ViewChild(LoaderComponent) child: LoaderComponent;
  showLoader: boolean;

  verificationForm: FormGroup;
  submitted = false;
  errors = '';
  errorDiv = false;
  alert = '';
  username = "";
 log:any[];

  constructor(private fb: FormBuilder,
    private router: Router,
    private dataService:DataService,
    private logger:LogService,
    private auth: AuthService) { }

  ngOnInit(): void {
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    const verify = localStorage.getItem('verify_user');
    if (verify == null) {
      this.router.navigate(['auth/login']);
    }
    this.initForm();
  }
// addClientLog(){
//     this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
//     if(this.log!=null){
//       this.dataService.addClientLog(this.log).subscribe();;
//       this.log=[];
//       this.logger.clear();
//     }

//   }
  initForm() {
    this.username = localStorage.getItem('register_username');
    this.errorDiv = true;
    this.errors = "We have sent a registration code over email to:  <a href='javascript:void(0)'>"+this.username+"</a>. Enter the code below and click on 'Verify Code' to complete verification of your account.<br/>Please check your Spam folder if you don't see the&nbsp;e-mail.";
    this.logger.info("Registration code sent successfully","VerifyUser");
    this.verificationForm = this.fb.group({
      code: ['', [Validators.required]]
    });
  }

  get a() { return this.verificationForm.controls; }

  async verifyUser() {
    this.submitted = true;

    if (this.verificationForm.invalid) {
      return;
    }
    this.showLoader = true;

    //const username = localStorage.getItem('register_username');
    const code = this.a.code.value;

    try {
      await Auth.confirmSignUp(this.username, code);
      localStorage.removeItem('register_username');
      localStorage.removeItem('verify_user');
      this.showLoader = false;
      this.errorDiv = true;
      this.alert = 'success';
      this.errors = "<div class='alert alert-success'>"+'Account verified successfully. Redirecting you to login page.'+"</div>";
    this.logger.info("Account verified successfully","VerifyUser");

      // setTimeout(() => {
      //   this.router.navigate(['auth/login']);
      // }, 2000);
      this.router.navigate(['auth/login']);
    } catch (error) {
      this.showLoader = false;
      this.errorDiv = true;
      this.alert = 'danger';
      this.errors = "<div class='alert alert-danger'>"+error.message+"</div>";
    this.logger.error(error.message,"VerifyUser");

      console.log('error confirming sign up', error);

    }

  }

  async verify() {
    const user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    Auth.verifyCurrentUserAttribute(attributes)
      .then(() => {
        console.log('a verification code is sent');
    this.logger.info("Verification code Sent Successfully","VerifyUser");

      }).catch((e) => {
        console.log('failed with error', e);
    this.logger.error(e.message,"VerifyUser");

      });

  }

  async ResendCode() {
    console.log('resendcode');
    this.showLoader = true;

    try {
      console.log('try');
      await Auth.resendSignUp(this.username);
      this.showLoader = false;
      this.errorDiv = true;
    this.errors = "We have successfully re-sent the registration code on <a href='javascript:void(0)'>"+this.username+"</a>.  Just copy and paste the code which you've received in the email to complete registration process. <br> If you don't see the mail, you may need to check your spam folder.";
    this.logger.info("Re-sent the registration code on "+this.username+"successfully","VerifyUser");
    } catch (err) {
      this.showLoader = false;
      this.errorDiv = true;
      this.alert = 'danger';
      this.errors = "<div class='alert alert-danger'>"+err.message+"</div>";
      this.logger.error(err.message,"VerifyUser");

    }
  }

}

