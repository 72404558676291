import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

export enum StitchesActionTypes {
    LOAD_STITCHES_LIBRARY = '[Stitches] Load Stitches Library',
    LOAD_STITCHES_LIBRARY_SUCCESS = '[Stitches] Load Stitches Library Success',
    LOAD_STITCHES_LIBRARY_FAIL = '[Stitches] Load Stitches Library Fail',
    LOAD_STITCHES = '[Stitches] Load Stitches',
    LOAD_STITCHES_SUCCESS = '[Stitches] Load Stitches Success',
    LOAD_STITCHES_FAIL = '[Stitches] Load Stitches Fail',
    CREATE_STITCHES = '[Stitches] Create Stitches',
    CREATE_STITCHES_SUCCESS = '[Stitches] Create Stitches Success',
    CREATE_STITCHES_FAIL = '[Stitches] Create Stitches Fail',
    UPDATE_STITCHES = '[Stitches] Update Stitches',
    UPDATE_STITCHES_SUCCESS = '[Stitches] Update Stitches Success',
    UPDATE_STITCHES_FAIL = '[Stitches] Update Stitches Fail',
    UPDATE_STITCHES_LIBRARY = '[Stitches] Update Stitches Library',
    UPDATE_STITCHES_LIBRARY_SUCCESS = '[Stitches] Update Stitches Library Success',
    UPDATE_STITCHES_LIBRARY_FAIL = '[Stitches] Update Stitches Library Fail',
    DELETE_STITCHES_LIBRARY = '[Stitches] Delete Stitches Library',
    DELETE_STITCHES_LIBRARY_SUCCESS = '[Stitches] Delete Stitches Library Success',
    DELETE_STITCHES_LIBRARY_FAIL = '[Stitches] Delete Stitches Library Fail',
    UPDATE_STITCHES_DOCUMENT = '[Stitches] Update Stitches Document',
    UPDATE_STITCHES_DOCUMENT_SUCCESS = '[Stitches] Update Stitches Document Success',
    UPDATE_STITCHES_DOCUMENT_FAIL = '[Stitches] Update Stitches Document Fail',
}

export class LoadStitchesLibrary implements Action {
    readonly type = StitchesActionTypes.LOAD_STITCHES_LIBRARY
    constructor(public payload1: string,public payload2: string) { }
}

export class LoadStitchesLibrarySuccess implements Action {
    readonly type = StitchesActionTypes.LOAD_STITCHES_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStitchesLibraryFail implements Action {
    readonly type = StitchesActionTypes.LOAD_STITCHES_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export class LoadStitches implements Action {
    readonly type = StitchesActionTypes.LOAD_STITCHES

    constructor(public payload: string) { }
}

export class LoadStitchesSuccess implements Action {
    readonly type = StitchesActionTypes.LOAD_STITCHES_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadStitchesFail implements Action {
    readonly type = StitchesActionTypes.LOAD_STITCHES_FAIL

    constructor(public payload: string) { }
}
export class CreateStitches implements Action {
    readonly type = StitchesActionTypes.CREATE_STITCHES

    constructor(public payload: any) { }
}

export class CreateStitchesSuccess implements Action {
    readonly type = StitchesActionTypes.CREATE_STITCHES_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateStitchesFail implements Action {
    readonly type = StitchesActionTypes.CREATE_STITCHES_FAIL

    constructor(public payload: string) { }
}
export class UpdateStitches implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES

    constructor(public payload: any) { }
}

export class UpdateStitchesSuccess implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateStitchesFail implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES_FAIL

    constructor(public payload: string) { }
}

export class UpdateStitchesLibrary implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES_LIBRARY

    constructor(public payload: any[]) { }
}

export class UpdateStitchesLibrarySuccess implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES_LIBRARY_SUCCESS

    constructor(public payload1: Update<any>[],public payload2: any) { }
}

export class UpdateStitchesLibraryFail implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class DeleteStitchesLibrary implements Action {
    readonly type = StitchesActionTypes.DELETE_STITCHES_LIBRARY

    constructor(public payload: string[]) { }
}

export class DeleteStitchesLibrarySuccess implements Action {
    readonly type = StitchesActionTypes.DELETE_STITCHES_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class DeleteStitchesLibraryFail implements Action {
    readonly type = StitchesActionTypes.DELETE_STITCHES_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class UpdateStitchesDocument implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES_DOCUMENT

    constructor(public payload: any) { }
}

export class UpdateStitchesDocumentSuccess implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES_DOCUMENT_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateStitchesDocumentFail implements Action {
    readonly type = StitchesActionTypes.UPDATE_STITCHES_DOCUMENT_FAIL

    constructor(public payload: string) { }
}
export type stitchesActionTypes = LoadStitchesLibrary | LoadStitchesLibrarySuccess | LoadStitchesLibraryFail |
    LoadStitches | LoadStitchesSuccess | LoadStitchesFail | CreateStitches | CreateStitchesSuccess |
    CreateStitchesFail | UpdateStitches | UpdateStitchesSuccess | UpdateStitchesFail | UpdateStitchesLibrary | UpdateStitchesLibrarySuccess
    | UpdateStitchesLibraryFail | DeleteStitchesLibrary | DeleteStitchesLibrarySuccess | DeleteStitchesLibraryFail
    | UpdateStitchesDocument | UpdateStitchesDocumentSuccess | UpdateStitchesDocumentFail;