import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as materialActions from "../actions/material.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";
import { DateFormatPipe } from "../helpers/date.pipe";


@Injectable()

export class MaterialEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
        private dateFormatPipe: DateFormatPipe
    ) { }

    getAllMaterialDetails(data) {
        data.baseClassification=this.aesdecryptservice.decryptData(this.dataService.Key, data["baseClassification"]);
        data.classification = this.aesdecryptservice.decryptData(this.dataService.Key, data["classification"]);
        data.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, data["sequence"]);
        data.name = this.aesdecryptservice.decryptData(this.dataService.Key, data["name"]);
        data.cuttablewidth = this.aesdecryptservice.decryptData(this.dataService.Key, data.cuttableWidth)
        data.fabriccomp = this.aesdecryptservice.decryptData(this.dataService.Key, data.fabricComposition)
        data.comments = this.aesdecryptservice.decryptData(this.dataService.Key, data.comments)
        data.widthuom = this.aesdecryptservice.decryptData(this.dataService.Key, data.widthUOM)
        data.weightgsm = data.weight_GSM
        data.width =  data.width
        data.subtype = this.aesdecryptservice.decryptData(this.dataService.Key, data.subType)
        data.size = this.aesdecryptservice.decryptData(this.dataService.Key, data.size)
        data.content = this.aesdecryptservice.decryptData(this.dataService.Key, data.content)
        data.sizeuom = this.aesdecryptservice.decryptData(this.dataService.Key, data.sizeUOM)
        data.substabce = this.aesdecryptservice.decryptData(this.dataService.Key, data.substabce)
        data.substanceuom = this.aesdecryptservice.decryptData(this.dataService.Key, data.substanceUOM)
        data.rawhide = this.aesdecryptservice.decryptData(this.dataService.Key, data.rawHide)
        data.trimcost = this.aesdecryptservice.decryptData(this.dataService.Key, data.trimcost)
        data.cost = this.aesdecryptservice.decryptData(this.dataService.Key, data.cost)
        if (data.createdBy) {
            data.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.firstName);
            data.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key,data.createdBy.lastName);
        }
        if (data["thumbnailFiles"]) {
            data["thumbnailFiles"].thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, data["thumbnailFiles"].thumbnail);
            data["thumbnailFiles"].name = this.aesdecryptservice.decryptData(this.dataService.Key, data["thumbnailFiles"].name);
        }
        if (data.documents && Object.keys(data.documents).length !== 0) {
            data.documents.forEach(x => {
                x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                x.notes = this.aesdecryptservice.decryptData(this.dataService.Key, x.notes);
                x.labLocation = this.aesdecryptservice.decryptData(this.dataService.Key, x.labLocation);
                x.reportStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.reportStatus);
                if (x.files) {
                    x.files.forEach(y => {
                        y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name);
                        y.fileType = this.aesdecryptservice.decryptData(this.dataService.Key, y.fileType);
                        y.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnail);
                    });
                }
            });
        }
    }
    getMaterialColorSupplierDetails(data) {
        data.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.name);
        data.capacityUOM = this.aesdecryptservice.decryptData(this.dataService.Key, data.capacityUOM);
        data.materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataService.Key, data.materialSupplierPrice);
        data.status = this.aesdecryptservice.decryptData(this.dataService.Key, data.status);
        data.description = this.aesdecryptservice.decryptData(this.dataService.Key, data.description);

        if (data.documents) {
            data.documents.forEach(x => {
                x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                x.notes = this.aesdecryptservice.decryptData(this.dataService.Key, x.notes);
                x.labLocation = this.aesdecryptservice.decryptData(this.dataService.Key, x.labLocation);
                x.reportStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.reportStatus);
                if (x.files) {
                    x.files.forEach(y => {
                        y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name);
                        y.fileType = this.aesdecryptservice.decryptData(this.dataService.Key, y.fileType);
                        y.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnail);
                    });
                }
            });
        }
        if (data.suppliers != null) {
            data.suppliers.forEach(y => {
                y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name)
                y.capacityUOM = this.aesdecryptservice.decryptData(this.dataService.Key, y.capacityUOM)
                y.materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataService.Key, y.materialSupplierPrice)
                y.status = this.aesdecryptservice.decryptData(this.dataService.Key, y.status)
                y.auditStatus = this.aesdecryptservice.decryptData(this.dataService.Key, y.auditStatus)
                y.description = this.aesdecryptservice.decryptData(this.dataService.Key, y.description)
                if (y.colours != null) {
                    y.colours.forEach(z => {
                        z['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, z['name']);
                        z['colorStandard'] = this.aesdecryptservice.decryptData(this.dataService.Key, z['colorStandard']);
                        if (z.createdBy) {
                            z.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, z.createdBy.firstName);
                            z.CreatedbyName = z.createdBy.firstName;
                        }
                        z.status = this.aesdecryptservice.decryptData(this.dataService.Key, z.status);
                        if (z['hexcode']) {
                            z['hexcode'] = this.aesdecryptservice.decryptData(this.dataService.Key, z['hexcode']);
                        } else {
                            if (z['thumbnailFiles']) {
                                z['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, z['thumbnailFiles']['thumbnail']);
                            }
                        }
                    });
                }
            })
        }
    }
    
    @Effect()
    loadMaterialLibrary: Observable<Action> = this.actions.pipe(
        ofType<materialActions.LoadMaterialLibrary>(
            materialActions.MaterialActionTypes.LOAD_MATERIAL_LIBRARY
        ), mergeMap((action: materialActions.LoadMaterialLibrary) =>
            this.dataService.getMaterial(action.payload1, action.payload2).pipe(
                map((materialLibrary: any[]) => {
                    if (materialLibrary['body']['item1'].length > 0 && !materialLibrary['body']['item2'].toLowerCase().includes('error')) {
                        materialLibrary['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.subType = this.aesdecryptservice.decryptData(this.dataService.Key, x.subType);
                            x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                            x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
                            x.createdOn = this.dateFormatPipe.transform(x.createdOn)
                            x.modifiedOn = this.dateFormatPipe.transform(x.modifiedOn)
                            x.CreatedbyName = x.createdBy.firstName;
                            if (x.thumbnailFiles) {
                                x.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail);
                            }
                        });
                    }
                    return new materialActions.LoadMaterialLibrarySuccess(materialLibrary['body']['item1'], materialLibrary['body'])
                }
                ),
                catchError(err => of(new materialActions.LoadMaterialLibraryFail(err)))
            )
        )
    );

    @Effect()
    loadMaterial: Observable<Action> = this.actions.pipe(
        ofType<materialActions.LoadMaterial>(
            materialActions.MaterialActionTypes.LOAD_MATERIAL
        ), mergeMap((action: materialActions.LoadMaterial) =>
            this.dataService.getMaterialById(action.payload).pipe(
                map((material: any) => {
                    if (!material['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllMaterialDetails(material['body']['item1']);
                    }
                    return new materialActions.LoadMaterialSuccess(material['body']['item1'], material['body'])
                }),
                catchError(err => of(new materialActions.LoadMaterialFail(err)))
            )
        )
    );

    @Effect()
    loadMaterialSupplier: Observable<Action> = this.actions.pipe(
        ofType<materialActions.LoadMaterialSupplier>(
            materialActions.MaterialActionTypes.LOAD_MATERIAL_SUPPLIER
        ), mergeMap((action: materialActions.LoadMaterialSupplier) =>
            this.dataService.getmaterialsuppliers(action.payload1, action.payload2).pipe(
                map((materialSupplier: any) => {
                    if (materialSupplier['body']['item1'].suppliers != null && materialSupplier['body']['item1'].suppliers.length > 0 && !materialSupplier['body']['item2'].toLowerCase().includes('error')) {
                        materialSupplier['body']['item1'].suppliers.forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
                            x.capacityUOM = this.aesdecryptservice.decryptData(this.dataService.Key, x.capacityUOM)
                            x.materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataService.Key, x.materialSupplierPrice)
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status)
                            x.auditStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.auditStatus)
                            x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description)
                            if (x.createdBy != null) {
                                x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName)
                                x.CreatedbyName = x.createdBy.firstName;
                            }
                            if (x.colours != null) {
                                x.colours.forEach(y => {
                                    y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name)
                                    y.colorStandard = this.aesdecryptservice.decryptData(this.dataService.Key, y.colorStandard)
                                    y.status = this.aesdecryptservice.decryptData(this.dataService.Key, y.status)
                                    if (y.createdBy != null) {
                                        y.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, y.createdBy.firstName)
                                        y.CreatedbyName = y.createdBy.firstName;
                                    } if (y.thumbnailFiles != null) {
                                        y.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnailFiles.thumbnail)
                                        y.thumbnailFiles.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnailFiles.name)
                                    } if (y.hexcode != null) {
                                        y.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, y.hexcode)
                                    }
                                });
                            }
                        });
                    }
                    return new materialActions.LoadMaterialSupplierSuccess(materialSupplier['body']['item1'], materialSupplier['body'])
                }),
                catchError(err => of(new materialActions.LoadMaterialSupplierFail(err)))
            )
        )
    );

    @Effect()
    loadMaterialColor: Observable<Action> = this.actions.pipe(
        ofType<materialActions.LoadMaterialColor>(
            materialActions.MaterialActionTypes.LOAD_MATERIAL_COLOR
        ), mergeMap((action: materialActions.LoadMaterialColor) =>
            this.dataService.getmaterialcolours(action.payload1, action.payload2).pipe(
                map((materialColor: any) => {
                    if (materialColor['body']['item1'].colours != null && materialColor['body']['item1'].colours.length > 0 && !materialColor['body']['item2'].toLowerCase().includes('error')) {
                        materialColor['body']['item1'].colours.forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
                            x.colorStandard = this.aesdecryptservice.decryptData(this.dataService.Key, x.colorStandard)
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status)
                            if (x.createdBy != null) {
                                x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName)
                                x.CreatedbyName = x.createdBy.firstName;
                            } if (x.thumbnailFiles != null) {
                                x.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail)
                                x.thumbnailFiles.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.name)
                            } if (x.hexcode != null) {
                                x.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, x.hexcode)
                            }
                        });
                    }

                    return new materialActions.LoadMaterialColorSuccess(materialColor['body']['item1'], materialColor['body'])
                }),
                catchError(err => of(new materialActions.LoadMaterialColorFail(err)))
            )
        )
    );

    @Effect()
    createMaterial: Observable<any> = this.actions.pipe(
        ofType<materialActions.CreateMaterial>(
            materialActions.MaterialActionTypes.CREATE_MATERIAL
        ), map((action: materialActions.CreateMaterial) => action.payload),
        mergeMap((material: any) =>
            this.dataService.addMaterial(material).pipe(
                map((newMaterial: any) => {
                    if (!newMaterial['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllMaterialDetails(newMaterial['body']['item1']);
                    }
                    return new materialActions.CreateMaterialSuccess(newMaterial['body']['item1'], newMaterial['body'])
                }),
                catchError(err => of(new materialActions.CreateMaterialFail(err)))
            )
        )
    );

    @Effect()
    updateMaterial: Observable<any> = this.actions.pipe(
        ofType<materialActions.UpdateMaterial>(
            materialActions.MaterialActionTypes.UPDATE_MATERIAL
        ), map((action: materialActions.UpdateMaterial) => action.payload),
        mergeMap((material: any) =>
            this.dataService.updatematerialById(material).pipe(
                map((updatedMaterial: any) => {
                    if (!updatedMaterial['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllMaterialDetails(updatedMaterial['body']['item1']);
                    }
                    return new materialActions.UpdateMaterialSuccess({
                        id: updatedMaterial['body']['item1'].id,
                        changes: updatedMaterial['body']['item1']
                    }, updatedMaterial['body'])
                }),
                catchError(err => of(new materialActions.UpdateMaterialFail(err)))
            )
        )
    );

    @Effect()
    updateMaterialLibrary: Observable<any> = this.actions.pipe(
        ofType<materialActions.UpdateMaterialLibrary>(
            materialActions.MaterialActionTypes.UPDATE_MATERIAL_LIBRARY
        ), map((action: materialActions.UpdateMaterialLibrary) => action.payload),
        mergeMap((materialIds: any[]) =>
            this.dataService.bulkUpdateMaterial(materialIds).pipe(
                map((updatedMaterial: any) =>
                    new materialActions.UpdateMaterialLibrarySuccess([{
                        id: updatedMaterial['body']['item1'].id,
                        changes: updatedMaterial['body']['item1']
                    }], updatedMaterial['body'])
                ),
                catchError(err => of(new materialActions.UpdateMaterialLibraryFail(err)))
            )
        )
    );

    @Effect()
    deleteMaterial: Observable<any> = this.actions.pipe(
        ofType<materialActions.DeleteMaterialLibrary>(
            materialActions.MaterialActionTypes.DELETE_MATERIAL_LIBRARY
        ), map((action: materialActions.DeleteMaterialLibrary) => action.payload),
        mergeMap((materialIds: any[]) =>
            this.dataService.bulkDeleteMaterial(materialIds).pipe(
                map((deletedMatretial: any) =>
                    new materialActions.DeleteMaterialLibrarySuccess(materialIds, deletedMatretial['body'])
                ),
                catchError(err => of(new materialActions.DeleteMaterialLibraryFail(err)))
            )
        )
    );

    @Effect()
    updateMaterialDocument: Observable<any> = this.actions.pipe(
        ofType<materialActions.UpdateMaterialDocument>(
            materialActions.MaterialActionTypes.UPDATE_MATERIAL_DOCUMENT
        ), map((action: materialActions.UpdateMaterialDocument) => action.payload),
        mergeMap((materialdocument: any) =>
            this.dataService.AddupdateMaterialDocuments(materialdocument).pipe(
                map((updatedMaterialDocument: any) => {
                    if (!updatedMaterialDocument['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllMaterialDetails(updatedMaterialDocument['body']['item1']);
                    }
                    return new materialActions.UpdateMaterialDocumentSuccess({
                        id: updatedMaterialDocument['body']['item1'].id,
                        changes: updatedMaterialDocument['body']['item1']
                    }, updatedMaterialDocument['body'])
                }),
                catchError(err => of(new materialActions.UpdateMaterialDocumentFail(err)))
            )
        )
    );

    @Effect()
    createMaterialColor: Observable<any> = this.actions.pipe(
        ofType<materialActions.CreateMaterialColor>(
            materialActions.MaterialActionTypes.CREATE_MATERIAL_COLOR
        ), map((action: materialActions.CreateMaterialColor) => action.payload),
        mergeMap((materialColor: any) =>
            this.dataService.addMaterialColor(materialColor).pipe(
                map((newMaterialColor: any) => {
                    this.getMaterialColorSupplierDetails(newMaterialColor['body']['item1'])
                    return new materialActions.CreateMaterialColorSuccess(newMaterialColor['body']['item1'], newMaterialColor['body'])
                }),
                catchError(err => of(new materialActions.CreateMaterialColorFail(err)))
            )
        )
    );

    @Effect()
    createMaterialSupplier: Observable<any> = this.actions.pipe(
        ofType<materialActions.CreateMaterialSupplier>(
            materialActions.MaterialActionTypes.CREATE_MATERIAL_SUPPLIER
        ), map((action: materialActions.CreateMaterialSupplier) => action.payload),
        mergeMap((materialSupplier: any) =>
            this.dataService.addMaterialSupplier(materialSupplier).pipe(
                map((newMaterialSupplier: any) => {
                    if (newMaterialSupplier['body']['item1'].length > 0 && !newMaterialSupplier['body']['item2'].toLowerCase().includes('error')) {
                        this.getMaterialColorSupplierDetails(newMaterialSupplier['body']['item1'])
                    }
                    return new materialActions.CreateMaterialSupplierSuccess(newMaterialSupplier['body']['item1'], newMaterialSupplier['body'])
                }),
                catchError(err => of(new materialActions.CreateMaterialSupplierFail(err)))
            )
        )
    );

    @Effect()
    createMaterialSupplierColor: Observable<any> = this.actions.pipe(
        ofType<materialActions.CreateMaterialSupplierColor>(
            materialActions.MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_COLOR
        ), map((action: materialActions.CreateMaterialSupplierColor) => action.payload),
        mergeMap((material: any) =>
            this.dataService.addSupplierColor(material).pipe(
                map((newMaterialSupplierColor: any) => {
                    if (newMaterialSupplierColor['body']['item1'] != null && !newMaterialSupplierColor['body']['item2'].toLowerCase().includes('error')) {
                        this.getMaterialColorSupplierDetails(newMaterialSupplierColor['body']['item1']);
                    }
                    return new materialActions.CreateMaterialSupplierColorSuccess(newMaterialSupplierColor['body']['item1'],
                        newMaterialSupplierColor['body'])
                }),
                catchError(err => of(new materialActions.CreateMaterialSupplierColorFail(err)))
            )
        )
    );
}
