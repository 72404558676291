import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StyledetailsComponent } from '../styledetails/styledetails.component';
import { DataService } from '../../../services/data.service';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { LogService } from '../../../shared/log.service';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import { SharedService } from '../../../services/shared.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
@Component({
  selector: 'app-styleprocessing',
  templateUrl: './styleprocessing.component.html',
  styleUrls: ['./styleprocessing.component.css']
})
export class StyleprocessingComponent implements OnInit {
  isprocessingedit: boolean;
  ismodaldata: boolean = true;
  processingForm: FormGroup;
  showProcPomNote = false;
  appStaticMsgs: any;
  editpomData: any;
  processingPoms: any;
  showLoader: boolean;
  public isNew: boolean;
  pomData = [];
  dbprocessingPoms: any[];
  public processingPomsstartCountList = 0;
  public processingPomsendCountList = 10;
  public processingPomsstartEndCount = "";
  public processingPomsisPageChanged = false;
  public processingPomsRecordCount: any;
  public processingPomsloading: boolean;
  formload : boolean;

  constructor(private dialogRef: MatDialogRef<StyledetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private userservice: UserService,
    private storageservice: StorageService,
    private sharedService: SharedService,
    private router: Router,) {
    this.sharedService.getprocessingpomData().subscribe(x => {
      console.log(x);
      this.editpomData = x;
      console.log(this.editpomData, 'editedData');
    })
  }

  ngOnInit(): void {
    this.appStaticMsgs = appStaticMsg.data[0];
    if (this.data?.isprocessingedit == false) {
      this.processingForm = new FormGroup({
        'pom': new FormControl({ value: 'POM', disabled: true }),
        'pc': new FormControl(),
        'poms': new FormControl(),
        'originalLength': new FormControl('', Validators.required),
        'originalWidth': new FormControl('', Validators.required),
        'afterwashLength': new FormControl('', Validators.required),
        'afterwashWidth': new FormControl('', Validators.required),
        'afterdryLength': new FormControl('', Validators.required),
        'afterdryWidth': new FormControl('', Validators.required),
        'id': new FormControl(),
      });
      this.isNew = true
    }
    else if (this.data?.isprocessingedit == true) {
      this.processingForm = new FormGroup({
        'pom': new FormControl({ value: 'POM', disabled: true }),
        'id': new FormControl(this.editpomData.id, Validators.required),
        'pc': new FormControl(this.editpomData.pc),
        'poms': new FormControl(this.editpomData.poms, Validators.required),
        'originalLength': new FormControl(this.editpomData.originalLength, Validators.required),
        'originalWidth': new FormControl(this.editpomData.originalWidth, Validators.required),
        'afterwashLength': new FormControl(this.editpomData.afterwashLength, Validators.required),
        'afterwashWidth': new FormControl(this.editpomData.afterwashWidth, Validators.required),
        'afterdryLength': new FormControl(this.editpomData.afterdryLength, Validators.required),
        'afterdryWidth': new FormControl(this.editpomData.afterdryWidth, Validators.required),
      });
    }
  }
  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }
  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    )
  }
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })

  }
  numberOnly(e: any) {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
  onpomSubmit() {
    if (this.processingForm.controls.pc.value.length > 100) {
      this.processingForm.controls.pc.setErrors({ 'incorrect': true });
    }
    if (this.processingForm.controls.poms.value.length > 100) {
      this.processingForm.controls.poms.setErrors({ 'incorrect': true });
    }
    if (this.processingForm.controls.originalLength.value.length > 100) {
      this.processingForm.controls.originalLength.setErrors({ 'incorrect': true });
    }

    if (this.processingForm.invalid) {
      this.showProcPomNote = true;
      this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields)
      return;
    }
    this.formload = true;
    this.pomData = this.processingForm.value;
    console.log(this.pomData, 'PomData')
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('ppcount', '1');
    if (this.data?.isprocessingedit == false) {
      formData.append('pptag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add'));
    } else {
      formData.append('ppid[0]', this.pomData['id']);
      formData.append('pptag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update'));
    }
    formData.append('pc[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomData['pc']));
    formData.append('poms[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomData['poms']));
    formData.append('originallength[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomData['originalLength']));
    formData.append('originalwidth[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomData['originalWidth']));
    formData.append('afterwashlength[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomData['afterwashLength']));
    formData.append('afterwashwidth[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomData['afterwashWidth']));
    formData.append('afterdrylength[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomData['afterdryLength']));
    formData.append('afterdrywidth[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomData['afterdryWidth']));

    this.formload = true;
    console.log(formData, 'formData')
    this.dataservice.addupdatedeleteStyleprocessing(formData).subscribe(async response => {
      console.log(response, 'response')
      this.showProcPomNote = false;
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.formload = false;
          this.success(this.appStaticMsgs.styledetails.processingpomsavesuccessfully);
          this.logger.info(this.appStaticMsgs.styledetails.processingpomsavesuccessfully, "Style ProcessingPOM");
          this.dialogRef.close();
          this.getStylePomProcessing();
        }
        else {
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "Style ProcessingPOM");
          this.formload = false;
        }

      }
    }, err => {
      this.showProcPomNote = false;
      this.logger.error(err.error.message, "Style ProcessingPOM");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.logger.error(err.error.message, "Style ProcessingPOM");
        this.error(err.message);
      }
      this.formload = false;
    });
  }
  public async getStylePomProcessing() {
    this.processingPomsloading = true;
    this.processingPomsstartEndCount = this.processingPomsstartCountList + "-" + this.processingPomsendCountList;
    this.dataservice.getStylePomProcessing(this.storageservice.getEditedStyleId(), this.processingPomsstartEndCount).subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.processingPomsloading = false;
        this.logger.info(this.appStaticMsgs.styledetails.allprocessingpomloadedsuccessfully, "Style PomProcessing");

        this.dbprocessingPoms = response.body['item1']['processingPOMs'];
        this.processingPomsRecordCount = response.body['item3'];
        if (response.body['item1']['processingPOMs']) {
          for (let i = 0; i < this.dbprocessingPoms.length; i++) {
            this.dbprocessingPoms[i]["pom"] = "POM";

            this.dbprocessingPoms[i].pc = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].pc);
            this.dbprocessingPoms[i].poms = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].poms);
            this.dbprocessingPoms[i].originalLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].originalLength);
            this.dbprocessingPoms[i].originalWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].originalWidth);
            this.dbprocessingPoms[i].afterwashLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].afterwashLength);
            this.dbprocessingPoms[i].afterwashWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].afterwashWidth);
            this.dbprocessingPoms[i].afterdryLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].afterdryLength);
            this.dbprocessingPoms[i].afterdryWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].afterdryWidth);
          }

          var gridView = {
            data: this.dbprocessingPoms,
            total: this.processingPomsRecordCount,
          };

          this.processingPoms = gridView;
        }
        else {
          this.processingPoms = null;
        }
        //this.processingForm = undefined;
        //this.sewnForm = undefined;
      }
      else {
        this.error(response.body['item1']);
        this.logger.error(response.body['item1'], "Style PomProcessing");
        this.processingPomsloading = false;
      }
    }, err => {
      this.logger.error(err.error.message, "Style PomProcessing");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.processingPomsloading = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.processingPomsloading = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.processingPomsloading = false;
      }
      else {
        this.error(err.message);
        this.logger.error(err.error.message, "Style PomProcessing");


      }
      this.processingPomsloading = false;
    });

  }

  close() {
    this.dialogRef.close();
  }
}
