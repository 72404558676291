import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { StorageService } from '../../helpers/storage.service';
import { NavigationService } from '../../../gaxon/components/navigation/navigation.service';
import { DataStateChangeEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State, CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { UserService } from '../../helpers/user.service';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { SharedService } from '../../services/shared.service';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from '../../../../../aws-exports';
import { NavigationModel } from '../navigation/navigation.model';
import { AESDecryptService } from '../../helpers/AESDecrypt.service';
import { LogService } from '../../shared/log.service';
import { interval, of, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as userReducer from "../../reducers/user.reducer";
import * as userActionTypes from "../../actions/userActions.actions";
import * as commonActionTypes from "../../actions/common.actions";
import * as commonReducer from "../../reducers/common.reducer";
import { map, skip, take, tap } from 'rxjs/operators';
import { ToastService } from '../../services/toast.service';
import * as appStaticMsg from '../../../assets/appStaticMsg.json';

Amplify.configure(awsconfig);
declare var $: any;

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.css']
})

export class UsermanagementComponent implements OnInit {
  classApplied = false;

  dropdownList = [];
  selectedItemsnew = [];
  dropdownSettings = {};
  public gridData: any;
  public totalRecordCount: any;
  userData:any[]=[];
  userList:any[]=[];
  showLoader: boolean;
  loading: boolean;
  teammates:any=[];
  startCountList: any = 0;
  endCountList: any = 0;
  startEndCount: string;
  senderName: string;
  senderEmail: any;
  company: any;
  firstname: any;
  lastname: any;
  filterData:[]=[];
  data:any;
  roles: any = [];
  staticMsgs:any;
  public countrycodes: any[] = [];
  public filterQuery = "";
  public SearchData = "";
  public filteredXcelData: any[];
  public filteredData: any[];
  public navigation: any[];
  public state: State = {
    skip: 0,
    take: 10
  };
  log: any[];
  modifiedSearch() {
    this.classApplied = true;
  }
  modifiedClose() {
    this.classApplied = false;
  }
  public isPageChanged = false;

  inviteForm: FormGroup;
  profileForm: FormGroup;
  submitted = false;
  showLoader1: boolean;
  errors = '';
  errorDiv = false;
  alert = '';
  emailNotification: any = [];
  paginationStartcount = 0;
  paginationEndcount = 10;
  public startCount = 0;
  public endCount = 10;
  public filterUsers: any = '';


  get f() { return this.profileForm.controls; }
  get invitef() { return this.inviteForm.controls; }

  currentUser = {
    firstname: '',
    lastname: '',
    email: '',
    userrole: '',
    phone_number: '',
    phone_number_code: '',
    thumb: '../../../assets/images/placeholder.jpg',
    usersubroleid: '',
    usersubrole: '',
    company: '',
    about: '',
    userid: ''
  };

  constructor(private store: Store<any>,
              private dataservice:DataService,
              private logger: LogService,
              private userservice:UserService,
              private aesdecryptservice:AESDecryptService,
              private invitefb: FormBuilder,
              private fb: FormBuilder,
              public toastService: ToastService) {

                this.profileForm = this.fb.group({
                  profileData: this.fb.array([])
                })

  }

  onPageChange(e: PageChangeEvent): void {
    this.isPageChanged = true;
    this.state.skip = e.skip;
    this.state.take = e.take;
    this.startCountList = e.skip;
    this.endCountList = e.skip + e.take;
    this.getAllUsers();
  }

  loadSelectpicker() {
    $(document).ready(function () {
      // $('.selectpicker').selectpicker();
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $("[data-toggle='popover']").popover({ html: true });
    });
  }

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
          this.dataservice.AddClientLog();
          const source = interval(1.2e+8);
          source.subscribe(val => this.dataservice.AddClientLog());

         this.getAllUsers();

          $(document).ready(function () {
            $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
            $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
            $("[data-toggle='popover']").popover({ html: true });
          });

          //this.getRolesList();
          this.dataservice.getUserDetails().subscribe((response) => {
            if (response.status === 200) {
              if (response.body['item2'] === "Success") {
                this.showLoader=true;
                this.company = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['org']);
                this.senderEmail = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['eMail']);
                var firstname = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['firstName']);
                var lastname = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['lastName']);
                this.senderName = firstname + " " + lastname;
                this.logger.info(firstname + this.staticMsgs.usermanagement.loadedSuccessfully, this.staticMsgs.usermanagement.UserManagement);

                // let name = {
                //   firstname: firstname,
                //   lastName: lastname,
                //   eMail: this.senderEmail,
                //   company: this.company
                // };
                // localStorage.setItem('userFullName',JSON.stringify(name));
              }
            }
          });

          //  this.store.dispatch(new userActionTypes.LoadUserManagement(this.startEndCount));
          //  this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userManagement) => {
          //    if (userManagement.user.error === "") {
          //      if (userManagement.user.message['item2'] === 'Success') {
          //        this.logger.info("All users list loaded successfully", "UserManagement");
          //        this.userData = [];
          //        //this.totalRecordCount = userManagement.user.message['item3'];
          //        this.userData = userManagement.user.message.item1;
          //       //  var gridView = {
          //       //    data: this.userData,
          //       //    total: this.totalRecordCount,
          //       //  };


          //       //let picture = [];
          //       console.log('userManagementData 1',userManagement.user.message);
          //       let result = userManagement.user.message.item1.map((e) => ({
          //         'id' : e.id,
          //         'firstName' : e.firstName,
          //         'lastName' : e.lastName,
          //         'fullName' : e.fullName,
          //         'eMail' : e.eMail,
          //         'image' : this.aesdecryptservice.decryptData(this.dataservice.Key,e.picture?.thumbnail),
          //         'userrole' : e.userrole
          //       }))


          //       console.log(result,'userManagement');
          //        this.teammates= result;
          //        //console.log(this.userData,'teammates');
          //        //this.gridData = gridView;
          //        this.loading = false;

          //        this.getRolesList();
          //      } else {
          //        this.loading = false;
          //        this.error("Failed:" + userManagement.user.message['item1'])
          //        //this.logger.error(userManagement.user.message['item1'], "UserManagement");
          //      }
          //    } else {
          //      //this.logger.error(userManagement.user.error.error.message, "UserManagement");
          //      this.getError(userManagement.user.error);
          //      this.loading = false;
          //    }
          //  })

          this.userList = [
            {
              name: 'alexrobert@pluraltechnolgy.com',
              img: '../assets/images/icon/jimmy-jo.jpg',
              status: 'Accepted',
              invitedon: '14-04-2022'
            },
            {
              name: 'jimmyjo@pluraltechnolgy.com',
              img: '../assets/images/icon/amay.jpg',
              status: 'Pending',
              invitedon: '21-05-2022'
            },
            {
              name: 'jonedoe@pluraltechnolgy.com',
              img: '../assets/images/icon/jimmy-jo.jpg',
              status: 'Decline',
              invitedon: '29-07-2022'
            }
          ]

          this.initForm();

          let select2 = $('.js-example-basic-single').select2({
            placeholder: "Enter email id's",
            tags: true,
            tokenSeparators: [',', ' ']
          });

          select2.on('select2:select',(e)=>{
            var data = e.params.data;
            console.log('select 2 select',data.id);
            this.emailNotification.push(data.id);
            this.inviteForm.controls['emailId'].setErrors({required: null})
          });

          select2.on('select2:unselect', (e)=>{
            var data = e.params.data;
            //console.log('select 2 unselecting',data.id);
            this.emailNotification.splice(this.emailNotification.indexOf(data.id), 1);
            if(this.emailNotification.length == 0){
              this.inviteForm.controls['emailId'].setErrors({required: true})
            }
          });


        }
          search(event:any){
            this.teammates= this.teammates.filter((val) =>val.fullName.toLowerCase().includes(this.data));
      }
        error(message: string) {
          Swal.fire({
            icon: this.staticMsgs.usermanagement.error,
            title: this.staticMsgs.usermanagement.Oops,
            text: message
          })
        }
        decryptData(x){
          x['firstName'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x['firstName']);
          x['lastName'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x['lastName']);
          x['eMail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x['eMail']);
          x['org'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x['org']);
          x['group'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x['group']);
          x['picture'].thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, x['picture'].thumbnail);
        }

        async initForm() {
          this.showLoader = true;
          this.profileForm = this.fb.group({
            firstname: [{ value: this.currentUser.firstname, disabled: false }, [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
            lastname: [{ value: this.currentUser.lastname, disabled: false }, [Validators.required, Validators.minLength(1), Validators.maxLength(15)]],
            userrole: [{ value: this.currentUser.usersubroleid, disabled: false }, [Validators.required]],
            email: [{ value: this.currentUser.email, disabled: true }],
            company: [{ value: this.currentUser.company, disabled: true }, [Validators.pattern('^[a-zA-Z0-9._-]+$'), Validators.maxLength(100)]],
          });
          this.inviteForm = this.invitefb.group({
            emailId: ['', [Validators.required, Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$')]],
          });
          this.showLoader = false;
        }

        sessionTimedOut() {
          let timerInterval
          Swal.fire({
            title: this.staticMsgs.leftBar.SessionExpired,
            html: this.staticMsgs.leftBar.loginAgain,
            timer: 1000,
            timerProgressBar: true,
            onBeforeOpen: () => {
              Swal.showLoading()
              timerInterval = setInterval(() => {
                const content = Swal.getContent()
              }, 100)
            },
            onClose: () => {
              clearInterval(timerInterval)
              this.userservice.logout()
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              //console.log('Session Timedout.')
              return;
            }
          })

        }
        getError(err: any) {

          if (err.error.message === "Network error communicating with endpoint") {
            this.error(err.error.message);
            this.showLoader = false;
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
            this.showLoader = false;
          } else if (err.error.message === "Unauthorized") {
            this.userservice.error();
            this.showLoader = false;
          }
          else {
            this.error(err.message);
          }
          this.showLoader = false;
        }
        getAllUsers() {
          this.loading = true;
          // this.startEndCount = this.startCountList + "-" + this.endCountList;
          //  this.store.dispatch(new userActionTypes.LoadUserManagement(this.startEndCount));
          // this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userManagement) => {
          //   if (userManagement.user.error === "") {
          //     if (userManagement.user.message['item2'] === 'Success') {
          //       this.logger.info("All users list loaded successfully", "UserManagement");
          //       this.userData = [];
          //       this.totalRecordCount = userManagement.user.message['item3'];
          //       this.userData = userManagement.user.message['item1'];
          //       // var gridView = {
          //       //   data: this.userData,
          //       //   total: this.totalRecordCount,
          //       // };
          //       console.log(userManagement.user.message,'userManagement abcd');
          //       //this.gridData = gridView;
          //       //this.teammates=userManagement.user.item1;
          //       this.loading = false;
          this.store.dispatch(new userActionTypes.LoadUserManagement(this.startEndCount));
           this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userManagement) => {
             if (userManagement.user.error === "") {
               if (userManagement.user.message['item2'] === 'Success') {
                 this.logger.info(this.staticMsgs.usermanagement.AllUsersList, this.staticMsgs.usermanagement.UserManagement);
                 this.userData = [];
                 //this.totalRecordCount = userManagement.user.message['item3'];
                 this.userData = userManagement.user.message.item1;
                //  var gridView = {
                //    data: this.userData,
                //    total: this.totalRecordCount,
                //  };


                //let picture = [];
                console.log('userManagementData 1',userManagement.user.message);
                let result = userManagement.user.message.item1.map((e) => ({
                  'id' : e.id,
                  'firstName' : e.firstName,
                  'lastName' : e.lastName,
                  'fullName' : e.fullName,
                  'eMail' : e.eMail,
                  'image' : this.aesdecryptservice.decryptData(this.dataservice.Key,e.picture?.thumbnail),
                  'userrole' : e.userrole
                }))


                console.log(result,'userManagement');
                 this.teammates= result;
                 //console.log(this.userData,'teammates');
                 //this.gridData = gridView;
                 this.getRolesList();
                 this.loading = false;



              } else {
                this.loading = false;
                this.error("Failed:" + userManagement.user.message['item1'])
                this.logger.error(userManagement.user.message['item1'], this.staticMsgs.usermanagement.UserManagement);
              }
            } else {
              this.logger.error(userManagement.user.error.error.message, this.staticMsgs.usermanagement.UserManagement);
              this.getError(userManagement.user.error);
              this.loading = false;
            }
          })

      }
      onItemSelect(item: any) {
        console.log(item);
      }
      OnItemDeSelect(item: any) {
        console.log(item);
      }
      onSelectAll(items: any) {
        console.log(items);
      }
      onDeSelectAll(items: any) {
        console.log(items);
      }


      getRolesList() {
        this.showLoader = true;
        this.store.dispatch(new userActionTypes.LoadUserRoles());
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userRoles) => {
          if (userRoles.user.error === "") {
            if (userRoles.user.message['item2'] === 'Success') {
              this.logger.info(this.staticMsgs.usermanagement.AllRoleList, this.staticMsgs.usermanagement.UserManagement);
              this.roles = userRoles.user.message['item1'];
              this.showLoader = false;
              //this.getAllUsers();
            } else {
              this.roles = [];
              this.showLoader = false;
              this.error("Failed:" + userRoles.user.message['item1'])
              this.logger.error(userRoles.user.message['item1'], this.staticMsgs.usermanagement.UserManagement);
            }
          } else {
            this.logger.error(userRoles.user.error.error.message,this.staticMsgs.usermanagement.UserManagement);
            this.getError(userRoles.user.error);
            this.showLoader = false;
          }
        })

        /* this.dataservice.getRolesList().subscribe((response) => {
          if (response.status === 200) {
            if (response.body['item2'] === "Success") {
              this.logger.info("All role list loaded successfully","UserManagement");
              //console.log(response.body)
              for(let i=0;i<response.body['item1'].length;i++){


                response.body['item1'][i].name= this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1'][i]['name']);
            }
              this.roles = response.body['item1'];

              this.showLoader = false;
            }
            else {
              this.roles = [];
              this.showLoader = false;
            }

          }
          else {
            this.roles = [];
            this.showLoader = false;
          }
        }, err => {
          this.logger.error(err.error.message,"UserManagement");
          if (err.error.message === "Network error communicating with endpoint") {
            this.error(err.error.message);
            this.showLoader = false;
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
            this.showLoader = false;
          } else if (err.error.message === "Unauthorized") {
            this.userservice.error();
            this.showLoader = false;
          }
          else {
            this.error(err.message);
            this.logger.error(err.message,"UserManagement");

          }
          this.showLoader = false;
        }); */
      }

      sendInvite() {



        let emailToString = '';
        var encodeEmail = '';
        var baseUrl = '';

        var encodeCompany = window.encodeURIComponent(this.aesdecryptservice.encryptData(this.dataservice.Key, this.company));



        this.emailNotification.forEach((x, idx, array)=>{
          if (idx === array.length - 1){
            encodeEmail = window.encodeURIComponent(this.aesdecryptservice.encryptData(this.dataservice.Key,x.toString()));
            baseUrl+= window.location.href.replace('usermanagement', '') + "auth/userregister" + "?email=" + encodeEmail + "&company=" + encodeCompany;
            emailToString+= this.aesdecryptservice.encryptData(this.dataservice.Key,x.toString());
          }else{
            encodeEmail = window.encodeURIComponent(this.aesdecryptservice.encryptData(this.dataservice.Key,x.toString()));
            baseUrl+= window.location.href.replace('usermanagement', '') + "auth/userregister" + "?email=" + encodeEmail + "&company=" + encodeCompany+',';
            emailToString+= this.aesdecryptservice.encryptData(this.dataservice.Key,x.toString())+',';
          }
        });

      console.log('baseUrl',baseUrl);

        this.submitted = true;
        if (this.emailNotification.length == 0) {

          return;
        }


        this.showLoader = true;





        console.log('senderEmail',this.senderEmail);
        console.log(this.company);
        console.log(this.senderName);
        console.log('baseUrl',baseUrl);
        console.log('emailToString',emailToString);
        //return;
        const formData = new FormData();

        formData.append('fromEmail', this.aesdecryptservice.encryptData(this.dataservice.Key, this.senderEmail));
        formData.append('toEmail', emailToString);
        formData.append('baseUrl', baseUrl);
        formData.append('senderName', this.aesdecryptservice.encryptData(this.dataservice.Key, this.senderName));
        formData.append('org', this.aesdecryptservice.encryptData(this.dataservice.Key, this.company));

        console.log(formData);
        //return false;
        this.dataservice.sendInvite(formData).subscribe((response) => {

          if (response.status === 200) {
            if (response.body['item2'] === "Success") {
              this.showLoader = false;
              this.success(this.staticMsgs.usermanagement.InvitationSuccess);

              $("#small-modal").modal('hide');
              this.logger.info(this.invitef.emailId.value + this.staticMsgs.usermanagement.InvitationSuccess, this.staticMsgs.usermanagement.UserManagement);
              // this.inviteForm = this.invitefb.group({
              //   emailId: [[], [Validators.required, Validators.email]],
              // });
              $("#emailId").val(null).trigger("change");
              $("#long-modal").modal('hide');
            }
            else {
              this.error(response.body['item1']);

              this.showLoader = false;
            }
          } else {
            this.alert = 'danger';
            this.errorDiv = true;
            this.errors = response.err;
            this.showLoader = false;
          }
        },
          err => {
            this.showLoader = false;
            this.alert = 'danger';
            this.errorDiv = true;
            if (err.error.message === "Network error communicating with endpoint") {
              this.errors = err.error.message;
            } else if (err.error.message === "The incoming token has expired") {
              this.errors = err.error.message;
              this.sessionTimedOut();
            } else if (err.error.message === "Unauthorized") {
              this.errors = err.error.message;
              this.userservice.error();
            }
            else {
              this.errors = err.message;
            }
            this.showLoader = false;
          })
      }


      async editUser(id) {
        console.log('id',id);
        this.loadSelectpicker();
        this.showLoader = true;
        this.store.dispatch(new userActionTypes.LoadUser(id));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userData) => {
          console.log(userData,'userdata');
          if (userData.user.error === "") {
            if (userData.user.message['item2'] === 'Success') {
              // console.log(userData.user.message['item2'],'success');
              this.logger.info(userData.user.message['item1']['firstName'] +this.staticMsgs.usermanagement.loadedSuccessfully, this.staticMsgs.usermanagement.UserManagement);
              // this.getCountrycodes();
               this.getRolesList();
              // this.getAllUsers();
              // this.refreshSelect();
              this.showLoader = false;
              this.currentUser.firstname = userData.user.message['item1']['firstName'];
              this.currentUser.lastname = userData.user.message['item1']['lastName'];
              this.currentUser.company = userData.user.message['item1']['org'];
              this.currentUser.email = userData.user.message['item1']['eMail'];
              this.currentUser.userrole = userData.user.message['item1']['group'];
              this.currentUser.userid = userData.user.message['item1']['id'];
              this.refreshSelect();
              if (userData.user.message['item1']['role']) {
                if (userData.user.message['item1']['role'][0]) {
                  this.showLoader = false;
                  this.currentUser.usersubroleid = userData.user.message['item1']['role'][0]['role']['id'];
                  this.currentUser.usersubrole = userData.user.message['item1']['role'][0]['role']['name'];
                }
              }
              this.initForm();
            } else {
              this.showLoader = false;
              this.error("Failed:" + userData.user.message['item1'])
              this.logger.error(userData.user.message['item1'], this.staticMsgs.usermanagement.UserManagement);
            }
          } else {
            this.logger.error(userData.user.error.error.message, this.staticMsgs.usermanagement.UserManagement);
            this.getError(userData.user.error);
            this.showLoader = false;
          }
        })

        /* this.dataservice.getUserById(id).subscribe((response) => {
          if (response.status === 200) {
            this.showLoader = false;
            if (response.body['item2'] === "Success") {
              this.logger.info(response.body['item1']['firstName']+" loaded successfully","UserManagement");
              //console.log(response.body)
              this.getCountrycodes();
              this.getRolesList();

              this.showLoader = false;

              this.currentUser.firstname = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['firstName']);
              this.currentUser.lastname = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['lastName']);
              this.currentUser.company = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['org']);
              this.currentUser.email = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['eMail']);
              this.currentUser.userrole = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['group']);

              // this.currentUser.firstname = response.body['item1']['firstName'];
              // this.currentUser.lastname = response.body['item1']['lastName'];
              //this.currentUser.company = response.body['item1']['org'];
              // this.currentUser.email = response.body['item1']['eMail'];
              this.currentUser.userid = response.body['item1']['id'];


              // this.currentUser.userrole = response.body['item1']['group'];
              this.refreshSelect();
              if (response.body['item1']['role']) {
                if (response.body['item1']['role'][0]) {
                  this.showLoader = false;
                  this.currentUser.usersubroleid = response.body['item1']['role'][0]['role']['id'];
                  // this.currentUser.usersubrole = response.body['item1']['role'][0]['role']['name'];
                  this.currentUser.usersubrole = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['role'][0]['role']['name']);
                }
              }
              //portfolios

              this.initForm();


            }
            else {
              this.showLoader = false;
            }
          }
          else {
            this.showLoader = false;
          }
        }, err => {
          this.logger.error(err.error.message,"UserManagement");
          if (err.error.message === "Network error communicating with endpoint") {
            this.error(err.error.message);
            this.showLoader = false;
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
            this.showLoader = false;
          } else if (err.error.message === "Unauthorized") {
            this.userservice.error();
            this.showLoader = false;
          }
          else {
            this.error(err.message);
            this.logger.error(err.message,"UserManagement");

          }
          this.showLoader = false;
        }); */

      }

      async getCountrycodes() {
        const data = await this.dataservice.getCountrycodes();
        this.countrycodes = this.countrycodes.concat(data['countrycodes']);
        this.refreshSelect();
      }

      refreshSelect() {
        $(document).ready(function () {
          $('.selectpicker').selectpicker('refresh');
        });
      }
      success(message: string) {
        Swal.fire(
          'Success',
          message,
          'success'
        )
      }
      async onSubmit() {
        this.errors = '';
        this.errorDiv = false;
        this.alert = '';
        if (this.profileForm.invalid) {
          return;
        }
        this.showLoader = true;
        const formData = new FormData();
        formData.append('firstname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.firstname.value));
        formData.append('lastname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.lastname.value));
        formData.append('email', this.aesdecryptservice.encryptData(this.dataservice.Key, this.currentUser.email));
        formData.append('role', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.userrole.value));
        formData.append('org', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.company.value));
        this.store.dispatch(new userActionTypes.UpdateUser(formData));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedUserData) => {
          if (updatedUserData.user.error === "") {
            if (updatedUserData.user.message['item2'] === 'Success') {
              console.log(updatedUserData.user.message['item2'],'message');
              this.getAllUsers();

              this.showLoader = false;
              this.success(this.staticMsgs.usermanagement.UpdatedUser);
              this.logger.info(this.staticMsgs.usermanagement.UpdatedUser, this.staticMsgs.usermanagement.UserManagement);
            } else {
              this.alert = this.staticMsgs.usermanagement.danger;
              this.errorDiv = true;
              this.errors = updatedUserData.user.message['item1'];
              this.showLoader = false;
              this.logger.error(this.errors, this.staticMsgs.usermanagement.UserManagement);
            }
          } else {
            this.logger.error(updatedUserData.user.error.error.message, this.staticMsgs.usermanagement.UserManagement);
            this.getError(updatedUserData.user.error);
            this.showLoader = false;
          }
        })
      }
}

