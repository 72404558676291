import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/Auth/login/login.component';
import { RegisterComponent } from './components/Auth/register/register.component';
import { UserRegisterComponent } from './components/Auth/userregister/userregister.component';

import { ForgotpasswordComponent } from './components/Auth/forgotpassword/forgotpassword.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { VerifyuserComponent } from './components/Auth/verifyuser/verifyuser.component';
import { AuthGuard } from './services/auth.guard';
import { LeftbarComponent } from './components/leftbar/leftbar.component';
import { ColorlibraryComponent } from './components/libraries/colorlibrary/colorlibrary.component';
import { ColordetailsComponent } from './components/libraries/colordetails/colordetails.component';
import { StitcheslibraryComponent } from './components/libraries/stitcheslibrary/stitcheslibrary.component';
import { StitchesdetailsComponent } from './components/libraries/stitchesdetails/stitchesdetails.component';
import { PalettelibraryComponent } from './components/libraries/palettelibrary/palettelibrary.component';
import { PalettedetailsComponent } from './components/libraries/palettedetails/palettedetails.component';

import { ErrorComponent } from './error/error.component';
import { ImagelibraryComponent } from './components/libraries/imagelibrary/imagelibrary.component';
import { SupplierlibraryComponent } from './components/libraries/supplierlibrary/supplierlibrary.component';
import { ImagedetailsComponent } from './components/libraries/imagedetails/imagedetails.component';
import { SupplierdetailsComponent } from './components/libraries/supplierdetails/supplierdetails.component';
import { MateriallibraryComponent } from './components/libraries/materiallibrary/materiallibrary.component';
import { MaterialdetailsComponent } from './components/libraries/materialdetails/materialdetails.component';
import { SeamslibraryComponent } from './components/libraries/seamslibrary/seamslibrary.component';
import { SeamsdetailsComponent } from './components/libraries/seamsdetails/seamsdetails.component';
import { StyledetailsComponent } from './components/style/styledetails/styledetails.component';
import { StylesComponent } from './components/style/styles/styles.component';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { UsermanagementComponent } from './components/usermanagenent/usermanagement.component';
import { MoodBoardComponent } from './components/moodboard/moodboard/moodboard.component';
import { MoodboardlibraryComponent } from './components/moodboard/moodboardlibrary/moodboardlibrary.component';
import { ExistedUserLoginComponent } from './components/Auth/existed-user-login/existed-user-login.component';



const routes: Routes = [
  { path: '', component: DashboardComponent, pathMatch: 'full' },
  { path: 'auth/login', component: LoginComponent },
  { path: 'auth/register', component: RegisterComponent },
  { path: 'auth/userregister', component: UserRegisterComponent },
  { path: 'auth/userlogin', component: ExistedUserLoginComponent },
  { path: 'auth/verifyuser', component: VerifyuserComponent },
  { path: 'auth/forgot-password', component: ForgotpasswordComponent },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Supplier", "Collaborator", "Fashion Designer", "Pattern Maker"
      ]
    }
  },
  {
    path: 'profile', component: ProfileComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Supplier", "Maker", "Collaborator", "Fashion Designer", "Pattern Maker","Guest"
      ]
    }
  },
  {
    path: 'colorlibrary', component: ColorlibraryComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'stitcheslibrary', component: StitcheslibraryComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'stitchesdetails', component: StitchesdetailsComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'palettelibrary', component: PalettelibraryComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'palettedetails', component: PalettedetailsComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'imagelibrary', component: ImagelibraryComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Supplier", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'supplierlibrary', component: SupplierlibraryComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Supplier", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'materiallibrary', component: MateriallibraryComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'colordetails', component: ColordetailsComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'imagedetails', component: ImagedetailsComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Supplier", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'supplierdetails', component: SupplierdetailsComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Supplier", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'materialdetails', component: MaterialdetailsComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'seamslibrary', component: SeamslibraryComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'seamsdetails', component: SeamsdetailsComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'styledetails', component: StyledetailsComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'styles', component: StylesComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'usermanagement', component: UsermanagementComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator"
      ]
    }
  },
  {
    path: 'discussion',
    component: DiscussionComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'moodboardlibrary', component: MoodboardlibraryComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  },
  {
    path: 'moodboard',component: MoodBoardComponent, canActivate: [AuthGuard],
    data: {
      roles: [
        "Administrator", "Maker", "Collaborator", "Pattern Maker"
      ]
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    FormsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
