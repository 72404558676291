import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { DataService } from '../services/data.service';
import * as stylesActions from '../actions/styles.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { AESDecryptService } from '../helpers/AESDecrypt.service';

@Injectable()
export class StylesEffect {
  constructor(
    private actions: Actions,
    private dataService: DataService,
    private aesdecryptservice: AESDecryptService
  ) {}

  @Effect()
  loadStylesLibrary: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStylesLibrary>(
      stylesActions.StylesActionTypes.LOAD_STYLES_LIBRARY
    ),
    mergeMap((action: stylesActions.LoadStylesLibrary) =>
      this.dataService.getAllStyles(action.payload, action.payload1).pipe(
        map((stylesLibrary: any[]) => {
          if (
            stylesLibrary['body']['item1'] != null &&
            stylesLibrary['body']['item1'].length > 0 &&
            !stylesLibrary['body']['item2'].toLowerCase().includes('error')
          ) {
            stylesLibrary['body']['item1'].forEach((x) => {
              x.styleNumber = this.aesdecryptservice.decryptData(
                this.dataService.Key,
                x.styleNumber
              );
              x.styleName = this.aesdecryptservice.decryptData(
                this.dataService.Key,
                x.styleName
              );
              x.newStyleNum = this.aesdecryptservice.decryptData(
                this.dataService.Key,
                x.newStyleNum
              );
              x.category = this.aesdecryptservice.decryptData(
                this.dataService.Key,
                x.category
              );
              x.status = this.aesdecryptservice.decryptData(
                this.dataService.Key,
                x.status
              );
              if (x.createdBy != null) {
                x.createdBy.firstName = this.aesdecryptservice.decryptData(
                  this.dataService.Key,
                  x.createdBy.firstName
                );
                x.createdBy.lastName = this.aesdecryptservice.decryptData(
                  this.dataService.Key,
                  x.createdBy.lastName
                );
                x.CreatedbyName = x.createdBy.firstName;
                x.createdBylastName = x.createdBy.lastName;
                x.createdBy.imageUrl = this.aesdecryptservice.decryptData(
                  this.dataService.Key,
                  x.createdBy.imageUrl
                );
              }
              if (x.styleImage) {
                x.styleImage.thumbnail = this.aesdecryptservice.decryptData(
                  this.dataService.Key,
                  x.styleImage.thumbnail
                );
              }
              if (x.styleCollaboratorUsers) {
                x.styleCollaboratorUsers.forEach((j) => {
                  j.user.forEach((i) => {
                    i.firstName = this.aesdecryptservice.decryptData(
                      this.dataService.Key,
                      i.firstName
                    );
                    i.lastName = this.aesdecryptservice.decryptData(
                      this.dataService.Key,
                      i.lastName
                    );
                    i.fullName = this.aesdecryptservice.decryptData(
                      this.dataService.Key,
                      i.fullName
                    );
                    i.eMail = this.aesdecryptservice.decryptData(
                      this.dataService.Key,
                      i.eMail
                    );
                    if (i.pictureID) {
                      i.picture.thumbnail = this.aesdecryptservice.decryptData(
                        this.dataService.Key,
                        i.picture.thumbnail
                      );
                    }
                  });
                });
              }
            });
          }
          return new stylesActions.LoadStylesLibrarySuccess(
            stylesLibrary['body']['item1'],
            stylesLibrary['body']
          );
        }),
        catchError((err) => of(new stylesActions.LoadStylesLibraryFail(err)))
      )
    )
  );

  @Effect()
  loadStyles: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStyles>(
      stylesActions.StylesActionTypes.LOAD_STYLES
    ),
    mergeMap((action: stylesActions.LoadStyles) =>
      this.dataService.GetStyleDataByID(action.payload).pipe(
        map(
          (styles: any) =>
            new stylesActions.LoadStylesSuccess(
              styles['body']['item1'],
              styles['body']
            )
        ),
        catchError((err) => of(new stylesActions.LoadStylesFail(err)))
      )
    )
  );

  @Effect()
  createStyles: Observable<any> = this.actions.pipe(
    ofType<stylesActions.CreateStyles>(
      stylesActions.StylesActionTypes.CREATE_STYLES
    ),
    map((action: stylesActions.CreateStyles) => action.payload),
    mergeMap((styles: any) =>
      this.dataService.AddStyles(styles).pipe(
        map(
          (newStyles: any) =>
            new stylesActions.CreateStylesSuccess(
              newStyles['body']['item1'],
              newStyles['body']
            )
        ),
        catchError((err) => of(new stylesActions.CreateStylesFail(err)))
      )
    )
  );

  @Effect()
  updateStyles: Observable<any> = this.actions.pipe(
    ofType<stylesActions.UpdateStyles>(
      stylesActions.StylesActionTypes.UPDATE_STYLES
    ),
    map((action: stylesActions.UpdateStyles) => action.payload),
    mergeMap((styles: any) =>
      this.dataService.updateStylebyId(styles).pipe(
        map(
          (updatedStyles: any) =>
            new stylesActions.UpdateStylesSuccess(
              {
                id: updatedStyles['body']['item1'].id,
                changes: updatedStyles['body']['item1'],
              },
              updatedStyles['body']
            )
        ),
        catchError((err) => of(new stylesActions.UpdateStylesFail(err)))
      )
    )
  );

  @Effect()
  updateStylesLibrary: Observable<any> = this.actions.pipe(
    ofType<stylesActions.UpdateStylesLibrary>(
      stylesActions.StylesActionTypes.UPDATE_STYLES_LIBRARY
    ),
    map((action: stylesActions.UpdateStylesLibrary) => action.payload),
    mergeMap((stylesIds: any[]) =>
      this.dataService.bulkupdateStyles(stylesIds).pipe(
        map(
          (updatedStyles: any) =>
            new stylesActions.UpdateStylesLibrarySuccess(
              [
                {
                  id: updatedStyles['body']['item1'].id,
                  changes: updatedStyles['body']['item1'],
                },
              ],
              updatedStyles['body']
            )
        ),
        catchError((err) => of(new stylesActions.UpdateStylesLibraryFail(err)))
      )
    )
  );

  @Effect()
  deleteStyles: Observable<any> = this.actions.pipe(
    ofType<stylesActions.DeleteStylesLibrary>(
      stylesActions.StylesActionTypes.DELETE_STYLES_LIBRARY
    ),
    map((action: stylesActions.DeleteStylesLibrary) => action.payload),
    mergeMap((stylesIds: any[]) =>
      this.dataService.bulkdeleteStyles(stylesIds).pipe(
        map(
          (deletedStyle: any) =>
            new stylesActions.DeleteStylesLibrarySuccess(
              stylesIds,
              deletedStyle['body']
            )
        ),
        catchError((err) => of(new stylesActions.DeleteStylesLibraryFail(err)))
      )
    )
  );

  @Effect()
  updateStyleColorways: Observable<any> = this.actions.pipe(
    ofType<stylesActions.UpdateStyleColorways>(
      stylesActions.StylesActionTypes.UPDATE_STYLE_COLORWAYS
    ),
    map((action: stylesActions.UpdateStyleColorways) => action.payload),
    mergeMap((stylesdocument: any) =>
      this.dataService.UpdateStyleColourways(stylesdocument).pipe(
        map(
          (updatedStylesDocument: any) =>
            new stylesActions.UpdateStyleColorwaysSuccess(
              {
                id: updatedStylesDocument['body']['item1'].id,
                changes: updatedStylesDocument['body']['item1'],
              },
              updatedStylesDocument['body']
            )
        ),
        catchError((err) => of(new stylesActions.UpdateStyleColorwaysFail(err)))
      )
    )
  );

  @Effect()
  addUpdateDeleteStylePatterns: Observable<any> = this.actions.pipe(
    ofType<stylesActions.AddUpdateDeleteStylePatterns>(
      stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_PATTERNS
    ),
    map((action: stylesActions.AddUpdateDeleteStylePatterns) => action.payload),
    mergeMap((stylePatterns: any) =>
      this.dataService.addupdatedeleteStylePatterns(stylePatterns).pipe(
        map(
          (stylesPatterns: any) =>
            new stylesActions.AddUpdateDeleteStylePatternsSuccess(
              {
                id: stylesPatterns['body']['item1'].id,
                changes: stylesPatterns['body']['item1'],
              },
              stylesPatterns['body']
            )
        ),
        catchError((err) =>
          of(new stylesActions.AddUpdateDeleteStylePatternsFail(err))
        )
      )
    )
  );

  @Effect()
  addUpdateDeleteStyleImages: Observable<any> = this.actions.pipe(
    ofType<stylesActions.AddUpdateDeleteStyleImages>(
      stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_IMAGES
    ),
    map((action: stylesActions.AddUpdateDeleteStyleImages) => action.payload),
    mergeMap((styleImages: any) =>
      this.dataService.AddUpdateDeleteStyImages(styleImages).pipe(
        map(
          (stylesImages: any) =>
            new stylesActions.AddUpdateDeleteStyleImagesSuccess(
              {
                id: stylesImages['body']['item1'].id,
                changes: stylesImages['body']['item1'],
              },
              stylesImages['body']
            )
        ),
        catchError((err) =>
          of(new stylesActions.AddUpdateDeleteStyleImagesFail(err))
        )
      )
    )
  );

  @Effect()
  addUpdateDeleteStyleConstruction: Observable<any> = this.actions.pipe(
    ofType<stylesActions.AddUpdateDeleteStyleConstruction>(
      stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_CONSTRUCTION
    ),
    map(
      (action: stylesActions.AddUpdateDeleteStyleConstruction) => action.payload
    ),
    mergeMap((styleConstruction: any) =>
      this.dataService
        .addupdatedeletestyleConstructions(styleConstruction)
        .pipe(
          map(
            (stylesConstruction: any) =>
              new stylesActions.AddUpdateDeleteStyleConstructionSuccess(
                {
                  id: stylesConstruction['body']['item1'].id,
                  changes: stylesConstruction['body']['item1'],
                },
                stylesConstruction['body']
              )
          ),
          catchError((err) =>
            of(new stylesActions.AddUpdateDeleteStyleConstructionFail(err))
          )
        )
    )
  );

  @Effect()
  addUpdateDeleteStyleSpecification: Observable<any> = this.actions.pipe(
    ofType<stylesActions.AddUpdateDeleteStyleSpecification>(
      stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_SPECIFICATION
    ),
    map(
      (action: stylesActions.AddUpdateDeleteStyleSpecification) =>
        action.payload
    ),
    mergeMap((styleSpecification: any) =>
      this.dataService
        .addupdatedeleteStylespecification(styleSpecification)
        .pipe(
          map(
            (stylesSpecification: any) =>
              new stylesActions.AddUpdateDeleteStyleSpecificationSuccess(
                {
                  id: stylesSpecification['body']['item1'].id,
                  changes: stylesSpecification['body']['item1'],
                },
                stylesSpecification['body']
              )
          ),
          catchError((err) =>
            of(new stylesActions.AddUpdateDeleteStyleSpecificationFail(err))
          )
        )
    )
  );

  @Effect()
  addUpdateDeleteStyleProcessing: Observable<any> = this.actions.pipe(
    ofType<stylesActions.AddUpdateDeleteStyleProcessing>(
      stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_PROCESSING
    ),
    map(
      (action: stylesActions.AddUpdateDeleteStyleProcessing) => action.payload
    ),
    mergeMap((styleProcessing: any) =>
      this.dataService.addupdatedeleteStyleprocessing(styleProcessing).pipe(
        map(
          (stylesProcessing: any) =>
            new stylesActions.AddUpdateDeleteStyleProcessingSuccess(
              {
                id: stylesProcessing['body']['item1'].id,
                changes: stylesProcessing['body']['item1'],
              },
              stylesProcessing['body']
            )
        ),
        catchError((err) =>
          of(new stylesActions.AddUpdateDeleteStyleProcessingFail(err))
        )
      )
    )
  );

  @Effect()
  addUpdateDeleteStyleBom: Observable<any> = this.actions.pipe(
    ofType<stylesActions.AddUpdateDeleteStyleBom>(
      stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_BOM
    ),
    map((action: stylesActions.AddUpdateDeleteStyleBom) => action.payload),
    mergeMap((styleBom: any) =>
      this.dataService.addupdatedeleteStyleBOMS(styleBom).pipe(
        map(
          (stylesBom: any) =>
            new stylesActions.AddUpdateDeleteStyleBomSuccess(
              {
                id: stylesBom['body']['item1'].id,
                changes: stylesBom['body']['item1'],
              },
              stylesBom['body']
            )
        ),
        catchError((err) =>
          of(new stylesActions.AddUpdateDeleteStyleBomFail(err))
        )
      )
    )
  );

  @Effect()
  addUpdateStyleSharedAccess: Observable<any> = this.actions.pipe(
    ofType<stylesActions.AddUpdateStyleSharedAccess>(
      stylesActions.StylesActionTypes.ADD_UPDATE_STYLE_SHARED_ACCESS
    ),
    map((action: stylesActions.AddUpdateStyleSharedAccess) => action.payload),
    mergeMap((styleSharedAccess: any) =>
      this.dataService.addupdatesharedaccess(styleSharedAccess).pipe(
        map(
          (stylesSharedAccess: any) =>
            new stylesActions.AddUpdateStyleSharedAccessSuccess(
              {
                id: stylesSharedAccess['body']['item1'].id,
                changes: stylesSharedAccess['body']['item1'],
              },
              stylesSharedAccess['body']
            )
        ),
        catchError((err) =>
          of(new stylesActions.AddUpdateStyleSharedAccessFail(err))
        )
      )
    )
  );

  @Effect()
  loadStyleColorways: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStyleColorways>(
      stylesActions.StylesActionTypes.LOAD_STYLE_COLORWAYS
    ),
    mergeMap((action: stylesActions.LoadStyleColorways) =>
      this.dataService.getstylecolourways(action.payload).pipe(
        map(
          (styleColorways: any) =>
            new stylesActions.LoadStyleColorwaysSuccess(
              styleColorways['body']['item1'],
              styleColorways['body']
            )
        ),
        catchError((err) => of(new stylesActions.LoadStyleColorwaysFail(err)))
      )
    )
  );

  @Effect()
  loadStyleBom: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStyleBom>(
      stylesActions.StylesActionTypes.LOAD_STYLE_BOM
    ),
    mergeMap((action: stylesActions.LoadStyleBom) =>
      this.dataService.getdetailsForBOM(action.payload, '').pipe(
        map(
          (styleBom: any) =>
            new stylesActions.LoadStyleBomSuccess(
              styleBom['body']['item1'],
              styleBom['body']
            )
        ),
        catchError((err) => of(new stylesActions.LoadStyleBomFail(err)))
      )
    )
  );

  @Effect()
  loadStyleImages: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStyleImages>(
      stylesActions.StylesActionTypes.LOAD_STYLE_IMAGES
    ),
    mergeMap((action: stylesActions.LoadStyleImages) =>
      this.dataService.GetStyleImages(action.payload).pipe(
        map(
          (styleImages: any) =>
            new stylesActions.LoadStyleImagesSuccess(
              styleImages['body']['item1'],
              styleImages['body']
            )
        ),
        catchError((err) => of(new stylesActions.LoadStyleImagesFail(err)))
      )
    )
  );

  @Effect()
  loadStylePatterns: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStylePatterns>(
      stylesActions.StylesActionTypes.LOAD_STYLE_PATTERNS
    ),
    mergeMap((action: stylesActions.LoadStylePatterns) =>
      this.dataService.getstylepatterns(action.payload, '').pipe(
        map(
          (stylePatterns: any) =>
            new stylesActions.LoadStylePatternsSuccess(
              stylePatterns['body']['item1'],
              stylePatterns['body']
            )
        ),
        catchError((err) => of(new stylesActions.LoadStylePatternsFail(err)))
      )
    )
  );

  @Effect()
  loadStyleConstruction: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStyleConstruction>(
      stylesActions.StylesActionTypes.LOAD_STYLE_CONSTRUCTION
    ),
    mergeMap((action: stylesActions.LoadStyleConstruction) =>
      this.dataService.getstyleConstruction(action.payload, '').pipe(
        map(
          (styleConstruction: any) =>
            new stylesActions.LoadStyleConstructionSuccess(
              styleConstruction['body']['item1'],
              styleConstruction['body']
            )
        ),
        catchError((err) =>
          of(new stylesActions.LoadStyleConstructionFail(err))
        )
      )
    )
  );

  /* @Effect()
    loadStyleSpecification: Observable<Action> = this.actions.pipe(
        ofType<stylesActions.LoadStyleSpecification>(
            stylesActions.StylesActionTypes.LOAD_STYLE_SPECIFICATION
        ), mergeMap((action: stylesActions.LoadStyleSpecification) =>
            this.dataService.getstylespecifications(action.payload).pipe(
                map((styleSpecification: any) =>
                    new stylesActions.LoadStyleSpecificationSuccess(styleSpecification['body']['item1'],styleSpecification['body'])
                ),
                catchError(err => of(new stylesActions.LoadStyleSpecificationFail(err)))
            )
        )
    ); */

  /* @Effect()
    loadStyleProcessing: Observable<Action> = this.actions.pipe(
        ofType<stylesActions.LoadStyleProcessing>(
            stylesActions.StylesActionTypes.LOAD_STYLE_PROCESSING
        ), mergeMap((action: stylesActions.LoadStyleProcessing) =>
            this.dataService.getstyleprocessing(action.payload).pipe(
                map((styleProcessing: any) =>
                    new stylesActions.LoadStyleProcessingSuccess(styleProcessing['body']['item1'],styleProcessing['body'])
                ),
                catchError(err => of(new stylesActions.LoadStyleProcessingFail(err)))
            )
        )
    ); */

  @Effect()
  loadStyleAllUsers: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStyleAllUsers>(
      stylesActions.StylesActionTypes.LOAD_STYLE_ALL_USERS
    ),
    mergeMap((action: stylesActions.LoadStyleAllUsers) =>
      this.dataService.getallUsers().pipe(
        map(
          (styleAllUsers: any) =>
            new stylesActions.LoadStyleAllUsersSuccess(
              styleAllUsers['body']['item1'],
              styleAllUsers['body']
            )
        ),
        catchError((err) => of(new stylesActions.LoadStyleAllUsersFail(err)))
      )
    )
  );

  @Effect()
  loadStyleSharedAccess: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStyleSharedAccess>(
      stylesActions.StylesActionTypes.LOAD_STYLE_SHARED_ACCESS
    ),
    mergeMap((action: stylesActions.LoadStyleSharedAccess) =>
      this.dataService.getsharedaccess(action.payload1, action.payload2).pipe(
        map(
          (styleSharedAccess: any) =>
            new stylesActions.LoadStyleSharedAccessSuccess(
              styleSharedAccess['body']['item1'],
              styleSharedAccess['body']
            )
        ),
        catchError((err) =>
          of(new stylesActions.LoadStyleSharedAccessFail(err))
        )
      )
    )
  );

  @Effect()
  loadStyleNotifications: Observable<Action> = this.actions.pipe(
    ofType<stylesActions.LoadStyleNotifications>(
      stylesActions.StylesActionTypes.LOAD_STYLE_NOTIFICATIONS
    ),
    mergeMap((action: stylesActions.LoadStyleNotifications) =>
      this.dataService.getNotifications().pipe(
        map(
          (styleNotifications: any) =>
            new stylesActions.LoadStyleNotificationsSuccess(
              styleNotifications['body']['item1'],
              styleNotifications['body']
            )
        ),
        catchError((err) =>
          of(new stylesActions.LoadStyleNotificationsFail(err))
        )
      )
    )
  );
}
