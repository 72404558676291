<app-loader [showLoader]="showLoader"></app-loader>
<app-loader [showLoader]="showLoader1"></app-loader>

<mat-dialog-content class="mat-typography">
  <div class="dt-page__header">
    <h3 class="dt-page__title">Edit Colors
      <button type="button" class="close" aria-label="Close"
        (click)="cancelColorCreate()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
  </div>
    <form [formGroup]="colorForm" (ngSubmit)="onSubmit()">
        <!-- <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div> -->
        <div class="form-row">

            <div class="col-lg-4 col-sm-12">
                <label for="colorclassification">{{staticMsgs.colorDetail.selectClass}}<span
                        class="red_icon">*</span></label>
                <input type="text" class="form-control" id="colorclassification"
                    formControlName="colorclassification"
                    placeholder="Classification" required />
                <div
                    *ngIf="(submitted && this.f.colorclassification.errors) || ( this.f.colorclassification.invalid && (this.f.colorclassification.dirty || this.f.colorclassification.touched))">
                    <div *ngIf="this.f.colorclassification.errors.required"
                        class="red">
                        {{staticMsgs.colorDetail.classReq}}
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 mb-3" *ngIf="isEditMode">
                <label for="colorseq">{{staticMsgs.colorDetail.color1}}</label>

                <input type="text" class="form-control" [readonly]="true"
                    id="colorseq" formControlName="colorseq"
                    placeholder="Color Id" />
            </div>

          <div class="col-lg-4 col-sm-12 mb-3">
              <label for="colorname">{{staticMsgs.colorDetail.name1}}<span
                      class="red_icon">*</span></label>
              <input type="text" class="form-control" id="colorname" minlength="3"
                 maxlength="50" formControlName="colorname" placeholder="Color Name"
                  required (keypress)="characterOnly($event)" />
              <div
                  *ngIf="(submitted && this.f.colorname.errors) || ( this.f.colorname.invalid && (this.f.colorname.dirty || this.f.colorname.touched))">
                  <div *ngIf="this.f.colorname.errors.required" class="red">
                    {{staticMsgs.colorDetail.colorReq}}
                  </div>
                  <div *ngIf="this.f.colorname.errors.minlength" class="red">
                    {{staticMsgs.common.minimum3charactersrequired}}
                     </div>

              </div>
          </div>
          <div class="col-lg-4 col-sm-12 mb-3">
              <label for="status">{{staticMsgs.colorDetail.status}}<span
                      class="red_icon">*</span></label>
              <select type="text" title="Select Status"
                  class="form-control selectpicker w-100" id="status"
                  formControlName="status" required>
                  <!-- <option value="" [disabled]="true" selected>Select Status -->
                  <!-- </option> -->
                  <option value="Active">{{staticMsgs.colorDetail.active}}</option>
                  <option value="Inactive">{{staticMsgs.colorDetail.inactive}}</option>
              </select>

                <!-- <label for="status">Status<span
                    class="red_icon">*</span></label>
            <select type="text" title="Select Status" class="form-control"
                id="status" formControlName="status"
                required>

                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
            </select> -->
                <div
                    *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
                    <div *ngIf="this.f.status.errors.required" class="red">
                      {{staticMsgs.colorDetail.statusReq}}
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 mb-3">
                <label for="internalref">{{staticMsgs.colorDetail.internal}}<span
                  class="red_icon">*</span></label>
                <input type="text" class="form-control" id="internalref"
                    formControlName="internalref" placeholder="Internal Ref #"
                    required />
                <div
                    *ngIf="(submitted && this.f.internalref.errors) || ( this.f.internalref.invalid && (this.f.internalref.dirty || this.f.internalref.touched))">
                    <div *ngIf="this.f.internalref.errors.required" class="red">
                      {{staticMsgs.colorDetail.internalReq}}
                    </div>
                    <div *ngIf="this.f.internalref.errors.pattern" class="red">
                      {{staticMsgs.colorDetail.internalValid}}
                    </div>
                    <div *ngIf="this.colorForm.controls.internalref.errors.required && this.colorForm.controls.internalref.value.length > 20"
                        class="red">
                        {{staticMsgs.colorDetail.internalAllow}}
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 mb-3">
              <label for="description">{{staticMsgs.colorDetail.desc}}<span
                      class="red_icon">*</span></label>
              <textarea cols="2" class="form-control" id="description"
                  formControlName="description" required></textarea>
              <div
                  *ngIf="(submitted && this.f.description.errors) || ( this.f.description.invalid && (this.f.description.dirty || this.f.description.touched))">
                  <div *ngIf="this.f.description.errors.required" class="red">
                    {{staticMsgs.colorDetail.decReq}}
                  </div>
                  <div *ngIf="this.colorForm.controls.description.value && this.colorForm.controls.description.value.length > 500"
                      class="red" >
                      {{staticMsgs.colorDetail.descAllow}}
                  </div>
              </div>
          </div>

        </div>

        <div class="dt-entry__header mt-5" *ngIf="colorclassificationValue">
            <!-- Entry Heading -->
            <div class="dt-entry__heading">
                <h3 class="dt-entry__title">{{colorclassificationValue}}</h3>
            </div>
            <!-- /entry heading -->
        </div>

        <div class="form-row mt-2" *ngIf="colorclassificationValue">
            <div class="col-sm-3 mb-3"
                *ngIf="colorclassificationValue != 'Print and Pattern'">
                <color-sketch [color]="color" (onChange)="handleChange($event)">
                </color-sketch>
            </div>

            <div class="col-sm-3 mb-3"
                *ngIf="colorclassificationValue != 'Print and Pattern'">
                <div>
                    <label for="colorhexacode">{{staticMsgs.colorDetail.hexcode}}<span
                            class="red_icon">*</span></label>
                    <input type="text" class="form-control" [readonly]="true"
                        id="colorhexacode" formControlName="colorhexacode"
                        placeholder="Color Hexcode" required />
                    <div
                        *ngIf="(submitted && this.f.colorhexacode.errors) || ( this.f.colorhexacode.invalid && (this.f.colorhexacode.dirty || this.f.colorhexacode.touched))">
                        <div *ngIf="this.f.colorhexacode.errors.required"
                            class="red">
                            {{staticMsgs.colorDetail.hexcodeReq}}
                        </div>
                    </div>
                </div>

                <div class="color-section text-center mt-4"
                    [ngStyle]="{'background-color': colorbg}">
                    <p>{{colorHex}}</p>
                </div>
            </div>

            <div class="col-sm-3 mb-3"
                *ngIf="colorclassificationValue === 'Solid'">
                <label for="colorstandard">{{staticMsgs.colorDetail.colorStd}}<span
                        class="red_icon">*</span></label>
                <select title="Select Color Standard"
                    formControlName="colorstandard"
                    class="form-control selectpicker w-100"
                    (ngModelChange)="onSelect(selected)" data-max-options="1">
                    <option *ngFor="let color of options" [value]="color.value">
                        {{ color.label }}</option>
                </select>
                <div
                    *ngIf="(submitted && this.f.colorstandard.errors) || ( this.f.colorstandard.invalid && (this.f.colorstandard.dirty || this.f.colorstandard.touched))">
                    <div *ngIf="this.f.colorstandard.errors.required"
                        class="red">
                        {{staticMsgs.colorDetail.colorStdReq}}
                    </div>
                </div>

                <div class="mt-4">
                    <label for="pantone">{{staticMsgs.colorDetail.pantone}}<span
                            class="red_icon">*</span></label>
                    <input type="text" class="form-control" id="pantone"
                        formControlName="pantone" placeholder="Pantone"
                        required />
                    <div
                        *ngIf="(submitted && this.f.pantone.errors) || ( this.f.pantone.invalid && (this.f.pantone.dirty || this.f.pantone.touched))">
                        <div *ngIf="this.f.pantone.errors.required" class="red">
                          {{staticMsgs.colorDetail.pantoneReq}}
                        </div>
                        <div *ngIf="this.f.pantone.errors.pattern" class="red">
                          {{staticMsgs.colorDetail.pantoneInvalid}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-4 mb-3" *ngIf="colorclassificationValue === 'Print and Pattern'">
            <label for="thumbnail">Thumbnail<span
                class="red_icon">*</span></label>
        </div> -->


        </div>

        <div class="form-row mt-2"
            *ngIf="(colorclassificationValue &&(colorclassificationValue === 'Print and Pattern'))">
            <div class="col-xl-12">
                <label for="thumbnail">{{staticMsgs.colorDetail.uploadImg}}<span
                        class="red_icon">*</span></label>
                <div class="custom-dropzone" id="thumbnail" ngx-dropzone
                    [multiple]="false"
                    [accept]="'image/png,image/jpeg,image/jpg'"
                    (change)="onSelectThumbnail($event)">
                    <ngx-dropzone-label>
                        <div>
                            <h5 class="text-light-gray mt-3">&nbsp;
                              {{staticMsgs.colorDetail.dropThumb}}
                             &nbsp; </h5>
                        </div>
                    </ngx-dropzone-label>
                    <ngx-dropzone-image-preview
                        ngProjectAs="ngx-dropzone-preview"
                        *ngFor="let f of thumbNails" [file]="f"
                        [removable]="true" (removed)="onRemoveThumbnail(f)">
                        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                    </ngx-dropzone-image-preview>

                </div>
                <div *ngIf="!isThumbnailAdded" class="red">
                  {{staticMsgs.colorDetail.imgReq}}
                </div>
            </div>

            <div class="col-xl-6 mt-6" *ngIf="this.f.colorid.value">
                <div class="col-xl-6 mb-5"
                    *ngFor="let editedThumbnail of editedThumbnails; let j=index">
                    <img src="{{editedThumbnail.thumbnail}}"
                        class="img-fluid  img-fit "
                        *ngIf="isValidImageURL(editedThumbnail.name)"
                        (click)="openThumbnail(j)">
                    <button type="button" class="close image-close"
                        (click)="removeThumbnailSection(editedThumbnail)">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>
        </div>

   
    
    <div class="d-flex justify-content-end mt-4">
        <div class="row mt-3">
        <input type="text" class="form-control" [hidden]="true" id="colorid"
            formControlName="colorid" placeholder="Color Id" />
            <button type="button" *ngIf="isEditMode"
            (click)="cancelColorCreate()"
           class="btn btn-outline-primary adv-search-btn mr-3">{{staticMsgs.colorDetail.cancel}}</button>
        <button type="submit" *ngIf="!isEditMode" [disabled]="colorForm?.invalid"
            class="btn btn-primary adv-search-btn">{{staticMsgs.colorDetail.create}}</button>
        <button type="submit" *ngIf="isEditMode"[disabled]="colorForm?.invalid"
            class="btn btn-primary adv-search-btn mr-3">{{staticMsgs.colorDetail.update}}</button>
        </div>
    </div>
</form>
</mat-dialog-content>
