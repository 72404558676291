import { Injectable } from '@angular/core';

import { LogPublishersService } from "./log-publishers.service";
import { LogPublisher } from "./log-publishers";
import { String } from 'aws-sdk/clients/apigateway';
import { UserService } from '../helpers/user.service';
import { HttpClient } from '@angular/common/http';

// ****************************************************
// Log Level Enumeration
// ****************************************************
export enum applicationType {
  All = "All",
  Debug = "Debug",
  Info = "Info",
  Warn = "Warn",
  Error = "Error",
  Fatal = "Fetal",
  Off = "Off"
}

// ****************************************************
// Log Entry Class
// ****************************************************

export class LogEntry {
  // Public Properties
  logType:string;
  module:string;
  ip:string;
  classType : string;
  loggedTime :  Date = new Date();
  loggedBy : string;
  logMessage :string;
  applicationType:string;

  // **************
  // Public Methods
  // **************
  // buildLogString(): string {
  //   let value:string = "";
    
  //   if (this.logWithDate) {
  //     value = new Date() + " - ";
  //   }
  //   value += "Type: " + applicationType[this.LogType];
  //   value += " - Message: " + this.message;
  //   if (this.extraInfo.length) {
  //     value += " - Extra Info: "
  //       + this.formatParams(this.extraInfo);
  //   }

  //   return value;
  }

  // ***************
  // Private Methods
  // ***************
  // private formatParams(params: any[]): string {
  //   let ret:string = params.join(",");

  //   // Is there at least one object in the array?
  //   if (params.some(p => typeof p == "object")) {
  //     ret = "";
  //     // Build comma-delimited string
  //     for (let item of params) {
  //       ret += JSON.stringify(item) + ",";
  //     }
  //   }

  //   return ret;
  // }
//}

// ****************************************************
// Log Service Class
// ****************************************************

@Injectable()

export class LogService {
 
  constructor(private http:HttpClient,private publishersService: LogPublishersService,  public userservice: UserService,) {
    // Set publishers
    this.publishers = this.publishersService.publishers;
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
  }

  // Public Properties
  publishers: LogPublisher[];
  level: applicationType = applicationType.All;
  logWithDate: boolean = true;
   ipAddress:string;

  // *************************
  // Public methods
  // *************************
  debug(msg: string,module:String, ...optionalParams: any[]) {
    this.writeToLog(msg,module, applicationType.Debug, optionalParams);
  }

  info(msg: string,module:String, ...optionalParams: any[]) {
    this.writeToLog(msg, module,applicationType.Info, optionalParams);
  }

  warn(msg: string,module:String, ...optionalParams: any[]) {
    this.writeToLog(msg, module,applicationType.Warn, optionalParams);
  }

  error(msg: string,module:String, ...optionalParams: any[]) {
    this.writeToLog(msg,module,applicationType.Error, optionalParams);
  }

  fatal(msg: string,module:String, ...optionalParams: any[]) {
    this.writeToLog(msg,module, applicationType.Fatal, optionalParams);
  }

  log(msg: string,module:String, ...optionalParams: any[]) {
    this.writeToLog(msg,module,applicationType.All, optionalParams);
  }

  clear(): void {
    for (let logger of this.publishers) {
      logger.clear()
        .subscribe();
    }
  
  }

  // *************************
  // Private methods
  // *************************
  private shouldLog(level: applicationType): boolean {
    let ret: boolean = false;

    if ((level >= this.level &&
      level !== applicationType.Off) ||
      this.level === applicationType.All) {
      ret = true;
    }

    return ret;
  }

  private async writeToLog(msg: string,module, level: applicationType, params: any[]) {
    if (this.shouldLog(level)) {
      // Declare variables
     
      var entry: LogEntry = new LogEntry();

  entry.ip=this.ipAddress;
      entry.logType = level;
      entry.applicationType="Angular";
      entry.logMessage = msg;
      entry.classType="NA"
      
      
     entry.module=module;
      entry.loggedBy =localStorage.getItem("email");
      entry.loggedTime = new Date();
      for (let logger of this.publishers) {
        logger.log(entry)
          .subscribe();
      }
    }
  }
}