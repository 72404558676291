import * as supplierActions from "../actions/supplier.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface SupplierState extends EntityState<any> {
    selectedSupplierId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const supplierAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultSupplier: SupplierState = {
    ids: [],
    entities: {},
    selectedSupplierId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = supplierAdapter.getInitialState(defaultSupplier);

export function SupplierReducer(state = initialState, action: supplierActions.supplierActionTypes): SupplierState {
    switch (action.type) {
        case supplierActions.SupplierActionTypes.LOAD_SUPPLIER_LIBRARY_SUCCESS:
            return supplierAdapter.addMany(action.payload1, {...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case supplierActions.SupplierActionTypes.LOAD_SUPPLIER_LIBRARY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case supplierActions.SupplierActionTypes.LOAD_SUPPLIER_SUCCESS:
            return supplierAdapter.setOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedSupplierId: action.payload1.id
            })
            break;
        case supplierActions.SupplierActionTypes.LOAD_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case supplierActions.SupplierActionTypes.CREATE_SUPPLIER_SUCCESS:
            return supplierAdapter.addOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedSupplierId: action.payload1.id
            })
            break;
        case supplierActions.SupplierActionTypes.CREATE_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case supplierActions.SupplierActionTypes.UPDATE_SUPPLIER_SUCCESS:
            return supplierAdapter.updateOne(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case supplierActions.SupplierActionTypes.UPDATE_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case supplierActions.SupplierActionTypes.UPDATE_SUPPLIER_LIBRARY_SUCCESS:
            return supplierAdapter.updateMany(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case supplierActions.SupplierActionTypes.UPDATE_SUPPLIER_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case supplierActions.SupplierActionTypes.DELETE_SUPPLIER_LIBRARY_SUCCESS:
            return supplierAdapter.removeMany(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case supplierActions.SupplierActionTypes.DELETE_SUPPLIER_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case supplierActions.SupplierActionTypes.UPDATE_SUPPLIER_DOCUMENT_SUCCESS:
            return supplierAdapter.updateOne(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case supplierActions.SupplierActionTypes.UPDATE_SUPPLIER_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getSupplierLibraryFeatureState = createFeatureSelector<SupplierState>('supplier');

export const getSupplierLibrary = createSelector(
    getSupplierLibraryFeatureState, supplierAdapter.getSelectors().selectAll
)
export const getSupplierLibraryLoading = createSelector(
    getSupplierLibraryFeatureState, (state: SupplierState) => state.loading
)
export const getSupplierLibraryLoaded = createSelector(
    getSupplierLibraryFeatureState, (state: SupplierState) => state.loaded
)
export const getSupplierLibraryError = createSelector(
    getSupplierLibraryFeatureState, (state: SupplierState) => state.error
)
export const getSupplierLibraryMessage = createSelector(
    getSupplierLibraryFeatureState, (state: SupplierState) => state.message
)
export const getSupplierId = createSelector(
    getSupplierLibraryFeatureState, (state: SupplierState) => state.selectedSupplierId
)
export const getSupplier = createSelector(
    getSupplierLibraryFeatureState, getSupplierId, state => state.entities[state.selectedSupplierId]
)