import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { NotificationService } from './services/notification-generated';
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { MoodboardReducer } from "./reducers/moodboard.reducer";
import { EffectsModule, Actions } from "@ngrx/effects";
import { MoodBoardEffect } from "./Effects/moodboard.effects";
import { reducers } from "./reducers";
import { metaReducers } from "./reducers/index";
import { environment } from '../environments/environment.prod';



/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
/* Add Amplify imports */

import { LoginComponent } from './components/Auth/login/login.component';
import { RegisterComponent } from './components/Auth/register/register.component';
import { UserRegisterComponent } from './components/Auth/userregister/userregister.component';
import { ForgotpasswordComponent } from './components/Auth/forgotpassword/forgotpassword.component';
import { HeaderComponent } from '../../../shared/shared-components/header/header.component';
import { LoaderComponent } from '../../../shared/shared-components/loader/loader.component';
import { FooterComponent } from '../../../shared/shared-components/footer/footer.component';
import { StorageService } from './helpers/storage.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TokenService } from './helpers/token.service';
import { UserService } from './helpers/user.service';
import { ExceptionService } from './helpers/exception.service';
import { VerifyuserComponent } from './components/Auth/verifyuser/verifyuser.component';
import { LeftbarComponent } from './components/leftbar/leftbar.component';
import { SearchBoxModule } from '../gaxon/components';
import { SharedModule } from '../gaxon/modules';
import { NavigationModule } from '../gaxon/components/navigation/navigation.module';
import { NavigationService } from '../gaxon/components/navigation/navigation.service';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ProfileComponent } from './components/profile/profile.component';
import { TagInputModule } from 'ngx-chips';
import { LightboxModule } from 'ngx-lightbox';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorBlockModule } from 'ngx-color/block';
import { TreeviewModule } from 'ngx-treeview';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ColorlibraryComponent } from './components/libraries/colorlibrary/colorlibrary.component';
import { ErrorComponent } from './error/error.component';
import { GridModule, ExcelModule, PDFModule, FilterService } from '@progress/kendo-angular-grid';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ToastrModule } from 'ngx-toastr';
import { ColordetailsComponent } from './components/libraries/colordetails/colordetails.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ImagelibraryComponent } from './components/libraries/imagelibrary/imagelibrary.component';
import { SupplierlibraryComponent } from './components/libraries/supplierlibrary/supplierlibrary.component';
import { ImagedetailsComponent } from './components/libraries/imagedetails/imagedetails.component';
import { SupplierdetailsComponent } from './components/libraries/supplierdetails/supplierdetails.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MateriallibraryComponent } from './components/libraries/materiallibrary/materiallibrary.component';
import { MaterialdetailsComponent } from './components/libraries/materialdetails/materialdetails.component';
import { StitchesdetailsComponent } from './components/libraries/stitchesdetails/stitchesdetails.component';
import { StitcheslibraryComponent } from './components/libraries/stitcheslibrary/stitcheslibrary.component';
import { SeamsdetailsComponent } from './components/libraries/seamsdetails/seamsdetails.component';
import { SeamslibraryComponent } from './components/libraries/seamslibrary/seamslibrary.component';
import { PalettelibraryComponent } from './components/libraries/palettelibrary/palettelibrary.component';
import { PalettedetailsComponent } from './components/libraries/palettedetails/palettedetails.component';
import { StyledetailsComponent } from './components/style/styledetails/styledetails.component';
import { StylesComponent } from './components/style/styles/styles.component';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { UsermanagementComponent } from './components/usermanagenent/usermanagement.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgImageSliderModule } from 'ng-image-slider';
import { MoodBoardComponent } from './components/moodboard/moodboard/moodboard.component';
import { MoodboardlibraryComponent } from './components/moodboard/moodboardlibrary/moodboardlibrary.component';
import { from } from 'rxjs';
import { AESDecryptService } from './helpers/AESDecrypt.service';
import { LogService } from './shared/log.service';
import { LogPublishersService } from './shared/log-publishers.service';
import { ColorReducer } from './reducers/color.reducer';
import { CommonReducer } from './reducers/common.reducer';
import { ImageReducer } from './reducers/image.reducer';
import { ColorEffect } from './Effects/color.effects';
import { CommonEffect } from './Effects/common.effects';
import { ImageEffect } from './Effects/image.effects';
import { MaterialEffect } from './Effects/material.effects';
import { MaterialReducer } from './reducers/material.reducer';
import { PaletteReducer } from './reducers/palette.reducer';
import { SeamsReducer } from './reducers/seams.reducer';
import { StitchesReducer } from './reducers/stitches.reducer';
import { StylesReducer } from './reducers/styles.reducer';
import { SupplierReducer } from './reducers/supplier.reducer';
import { UserReducer } from './reducers/user.reducer';
import { PaletteEffect } from './Effects/palette.effects';
import { SeamsEffect } from './Effects/seams.effects';
import { StitchesEffect } from './Effects/stitches.effects';
import { StylesEffect } from './Effects/styles.effects';
import { SupplierEffect } from './Effects/supplier.effects';
import { UserEffect } from './Effects/user.effects';
import { DateFormatPipe } from './helpers/date.pipe';
import { AvatarModule } from 'ngx-avatar';
import {MatDialogModule} from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ColorwaysComponent } from './components/popups/colorways/colorways.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { NgSelectModule } from '@ng-select/ng-select';

import { SeamsCustomComponent } from './components/libraries/seams-custom/seams-custom.component';
import { StitchesCustomComponent } from './components/libraries/stitches-custom/stitches-custom.component';
import { CustomColorComponent } from './components/libraries/custom-color/custom-color.component';
import { ImageCustomComponent } from './components/libraries/image-custom/image-custom.component';
import { MaterialCustomComponent } from './components/libraries/material-custom/material-custom.component';
import { PaletteCustomComponent } from './components/libraries/palette-custom/palette-custom.component';
// import { ImageCropperModule } from 'ngx-image-cropper';
import { ConstructionStitchesComponent } from './components/style/construction-stitches/construction-stitches.component';
import { ConstructionOperationComponent } from './components/style/construction-operation/construction-operation.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { StylebomComponent } from './components/style/stylebom/stylebom.component';
import {  FilterDescriptor } from '@progress/kendo-data-query';
import { DropdownlistFilterComponent } from '../../../shared/shared-components/dropdownlist-filter/dropdownlist-filter.component';
import { SupplierEditComponent } from './components/libraries/supplier-edit/supplier-edit.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

import { StyleEditComponent } from './components/style/style-edit/style-edit.component';

import { StylePOMComponent } from './components/style/style-pom/style-pom.component';


import { StyleprocessingComponent } from './components/style/styleprocessing/styleprocessing.component';
import { StyleprosewnComponent } from './components/style/styleprosewn/styleprosewn.component';
import { StylePatternComponent } from './components/style/stylepattern/stylepattern.component';
import { PatternFileUploadComponent } from './components/style/pattern-file-upload/pattern-file-upload.component';
import { ExistedUserLoginComponent } from './components/Auth/existed-user-login/existed-user-login.component';


/* Configure Amplify resources */
Amplify.configure(awsconfig);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    UserRegisterComponent,
    ForgotpasswordComponent,
    HeaderComponent,
    LoaderComponent,
    DashboardComponent,
    VerifyuserComponent,
    LeftbarComponent,
    FooterComponent,
    ProfileComponent,
    ColorlibraryComponent,
    ErrorComponent,
    ColordetailsComponent,
    ImagelibraryComponent,
    SupplierlibraryComponent,
    ImagedetailsComponent,
    MateriallibraryComponent,
    MaterialdetailsComponent,
    SupplierdetailsComponent,
    StitchesdetailsComponent,
    StitcheslibraryComponent,
    SeamsdetailsComponent,
    SeamslibraryComponent,
    PalettelibraryComponent,
    PalettedetailsComponent,
    StyledetailsComponent,
    StylesComponent,
    DiscussionComponent,
    UsermanagementComponent,
    MoodBoardComponent,
    MoodboardlibraryComponent,
    DateFormatPipe,
    ColorwaysComponent,
    DropdownlistFilterComponent,
    SupplierEditComponent,
    StyleEditComponent,
    SeamsCustomComponent,
    StitchesCustomComponent,
    ConstructionStitchesComponent,
    ConstructionOperationComponent,
    CustomColorComponent,
    ImageCustomComponent,
    StylebomComponent,
    MaterialCustomComponent,
    PaletteCustomComponent,
    SupplierEditComponent,
    StylePOMComponent,
    StyleprocessingComponent,
    StyleprosewnComponent,
    StylePatternComponent,
    PatternFileUploadComponent,
    ExistedUserLoginComponent


  ],
  imports: [
    AmplifyUIAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    SearchBoxModule,
    NavigationModule,
    PerfectScrollbarModule,
    TagInputModule,
    LightboxModule,
    ColorSketchModule,
    ColorChromeModule,
    ColorBlockModule,
    TreeviewModule.forRoot(),
    NgxDropzoneModule,
    GridModule,
    ExcelModule,
    PDFModule,
    ExcelExportModule,
    DropDownsModule,
    TooltipModule,
    PopupModule,
    DragDropModule,
    ImageCropperModule,
    AngularMultiSelectModule,
    DropDownListModule,
    ToastrModule.forRoot({timeOut: 4000}),
    NgImageSliderModule,
    AvatarModule,
    NgSelectModule,
    ImageCropperModule,
    // ImageCropperModule,
    NgImageFullscreenViewModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: true
      }
    }),
    StoreModule.forFeature('moodboard', MoodboardReducer),
    StoreModule.forFeature('color', ColorReducer),
    StoreModule.forFeature('common', CommonReducer),
    StoreModule.forFeature('image', ImageReducer),
    StoreModule.forFeature('material', MaterialReducer),
    StoreModule.forFeature('palette', PaletteReducer),
    StoreModule.forFeature('seams', SeamsReducer),
    StoreModule.forFeature('stitches', StitchesReducer),
    StoreModule.forFeature('style', StylesReducer),
    StoreModule.forFeature('supplier', SupplierReducer),
    StoreModule.forFeature('user', UserReducer),


    StoreDevtoolsModule.instrument({
    logOnly: environment.production
    }),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([MoodBoardEffect, ColorEffect, CommonEffect, ImageEffect, MaterialEffect, PaletteEffect,
    SeamsEffect, StitchesEffect, StylesEffect, SupplierEffect, UserEffect])
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    TranslateService,
    NavigationService,
    AuthService,
    AuthGuard,
    StorageService,
    TokenService,
    UserService,
    ExceptionService,
    AESDecryptService,
    LogService,
    FilterService,
    LogPublishersService,
    NotificationService,
    DateFormatPipe,
    FilterService,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],

  entryComponents: [ColorwaysComponent,SeamsCustomComponent, StitchesCustomComponent, CustomColorComponent, ImageCustomComponent,StyleEditComponent,
  ConstructionStitchesComponent, ConstructionOperationComponent, PaletteCustomComponent, MaterialCustomComponent,StylebomComponent,SupplierEditComponent,
  StylePOMComponent,DropdownlistFilterComponent,StyleprocessingComponent,StyleprosewnComponent,StylePatternComponent,PatternFileUploadComponent]


})
export class AppModule { }
