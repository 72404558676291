<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>

  <!-- <div *ngIf="activeNavDrawer" class="dt-bacakdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->

  <!-- Site Content -->
  <div class="dt-content-wrapper">

    <!-- Site Content -->
    <div class="dt-content">

      <!-- Page Header -->
      <div class="dt-page__header">
        <div class="row">
          <div class="col-xl-6 ">
            <h3 class="dt-page__title m-auto">{{staticMsgs.seamsLibrary.SeamsLibrary}} {{notFound ? '':': '+ HeadingText}}</h3>

          </div>
          <div class="col-xl-6 " >
            <span class="float-right">

              <button type="button" class="btn mr-3" [ngClass]="btnstatus1 ? 'icon-active' : 'btn-color-primary'" placement="bottom"
                ngbTooltip="Card View" (click)="cardView()">
                <i class="fa fa-th" aria-hidden="true"></i></button>
                <button type="button" class="btn mr-3" [ngClass]="btnstatus ? 'icon-active' : 'btn-color-primary'" placement="bottom"
                ngbTooltip="List View" (click)="tableView()">
                <i class="fa fa-bars" aria-hidden="true"></i></button>
              <button type="button" class="btn btn-outline-primary adv-search-btn mr-3" (click)="createSeams()"><i
                  class="icon icon-plus"></i> {{staticMsgs.seamsLibrary.CreateNew}}</button>
            </span>
            </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-12 col-sm-12">
          <p>
            <a href="javascript:void(0)" [ngClass]="myseams ? 'data_active' : 'text-muted'"
              (click)="seamsView('Created')">
              {{staticMsgs.seamsLibrary.MySeams}}
            </a>
            <span>|</span>
            <a href="javascript:void(0)" [ngClass]="sharedseams ? 'data_active' : 'text-muted'"
              (click)="seamsView('Shared')">
              {{staticMsgs.seamsLibrary.Sharedwithme}}
            </a>
          </p>
        </div>
      </div>
      <!-- /page header -->
      <ng-container *ngIf="this.seamsViewMode=='Shared'">
        <div class="text-center" *ngIf="sharedNotFound">
          <h2>{{staticMsgs.seamsLibrary.NoSharedData}}</h2>
          <img [src]="notfoundImg" class="img-fluid "><br>
          <!-- <button type="button" class="btn btn-primary adv-search-btn mr-3" (click)="createSeams()"><i
              class="icon icon-plus"></i> Create New</button> -->

        </div>
        <div class="grid-content" *ngIf="gridContent && !sharedNotFound">

          <div class="row">
            <div class="col-sm-10" >
              <div *ngIf="!checkSharedStatusData">
              <i class="fa fa-search search-button"></i>
              <input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords"
                placeholder="Enter Keyword for filter results" [(ngModel)]="searchedKeyword" />
              <!---<input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords" [(ngModel)]="searchedKeyword" />-->
            </div>
            </div>

            <div class="col-sm-2">
              <select placeholder="Select Status" class="form-control selectpicker w-100" [(ngModel)]="selected"
                (ngModelChange)="onSelect(selected,'shared')">
                <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
              </select>
            </div>

          </div>
          <div class="text-center" *ngIf="checkSharedStatusData">
            <h2>{{sharedMsg}}</h2>
            <img [src]="notfoundImg" class="img-fluid ">
          </div>
          <!-- Grid -->
          <div class="row mt-5">

            <!-- Grid Item -->
            <div class="col-xl-12 order-xl-1">

              <!-- Card -->
              <div class="row">

                <!-- Grid Item -->
                <div class="col-xl-3 col-md-4 col-sm-6 col-12"
                  *ngFor="let card of sharedCards | filter:searchedKeyword">

                  <!-- Card -->
                  <div class="card border-l-r">

                    <!-- Card Body -->
                    <div class="">
                      <!--<img class="w-100 border-l-r h-260" [src]='card.img' alt="Img" >-->

                      <!-- <img class="w-100 border-l-r h-260" [src]='card.thumbnail != "string"?card.thumbnail:thumbnail' alt="Img"> -->
                      <img *ngIf="card.seamDrawingFile" class="card-view-img" alt=""
                        src={{card.seamDrawingFile.thumbnail}}>
                      <img *ngIf="card.seamDrawingFile==null" class="card-view-img" alt="" src={{thumbnail}}>

                    </div>
                    <div class="card-body color-card">
                      <div class="text-eli">
                        <p class="mb-1 mb-sm-0 f-13 text-truncate sequence">
                          <a href="javascript:void(0)" (click)="onEditSeams(card.id)">
                            {{card.name ? card.name : card.sequence}}
                          </a>
                        </p>
                      </div>
                      <div class="row ">
                        <div class="col-sm-7 col-xl-7">
                          <span
                            class="d-block text-light-gray  mt-1 f-12 text-truncate">{{card.classification}}</span>
                        </div>
                        <div class="col-sm-5 col-xl-5">
                          <div class="float-right d-web">
                            <!-- <span class="text-light-gray mb-3 f-12 d-active"><i
                          class="icon icon-dot d-red"></i>{{card.status}}</span> -->
                            <span class="text-light-gray mb-3 f-12 d-active"><i
                                class="icon icon-dot d-green"></i>{{card.status | titlecase}}</span>
                            <!-- <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status.toLowerCase()=='dropped'"><i
                              class="icon icon-dot d-blue"></i>{{card.status | titlecase}}</span>
                              <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status.toLowerCase()=='inreview'"><i
                                class="icon icon-dot d-yellow"></i>{{card.status | titlecase}}</span> -->
                            <!-- Dropdown -->
                            <div class="dropdown">
                              <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)" (click)="onEditSeamsDetails(card.id)">
                                  <i class="icon icon-editors icon-fw"></i>
                                  {{staticMsgs.seamsLibrary.Edit}}
                                </a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteSeams(card.id)">
                                  <i class="icon icon-trash icon-fw"></i>
                                  {{staticMsgs.seamsLibrary.delete}}
                                </a>
                                <!-- <a class="dropdown-item" href="javascript:void(0)">
                              <i class="icon icon-contacts icon-fw"></i>
                              Contact List</a>
                            <a class="dropdown-item" href="javascript:void(0)">
                              <i class="icon icon-heart icon-fw"></i>
                              Favourite List
                            </a> -->
                              </div>
                            </div>
                            <!-- /dropdown -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="action-tools">
                      <!-- Checkbox -->

                      <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only">
                        <input id="checkbox{{card.id}}" type="checkbox" [(ngModel)]="card.isFavourite"
                          (click)="AddToFavorite($event)" [ngbTooltip]="card.isFavourite ? 'Remove From favorite' : 'Add to favorite'" />
                        <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                          <span class="unchecked"><i class="icon icon-star-o text-white icon-lg"></i></span>
                          <span class="checked"><i class="icon icon-star-fill text-secondary icon-lg"></i></span>
                        </label>
                      </div>
                      <!-- <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only" *ngIf="addToFavorite==true">
                        <input id="checkbox{{card.id}}" type="checkbox" [(ngModel)]="card.isFavourite"
                          (click)="AddToFavorite($event,card)"  ngbTooltip="Remove From Favourite">
                        <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                          <span class="unchecked"><i class="icon icon-star-o text-white icon-lg"></i></span>
                          <span class="checked"><i class="icon icon-star-fill text-secondary icon-lg"></i></span>
                        </label>
                      </div> -->
                      <!-- /checkbox -->

                    </div>

                    <!-- /card body -->

                  </div>
                  <!-- /card -->

                </div>
                <!-- /grid item -->

              </div>
              <!-- /card -->

            </div>
            <!-- /grid item -->

          </div>
          <!-- /grid -->

          <div *ngIf="!isSharedGetAllRecords" class="row mt-5">
            <div *ngIf="!isSharedScrollDown" class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
              <div class="text-center w-100">
                <div class="loadersmall">
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
      <ng-container  *ngIf="this.seamsViewMode=='Created'">
        <div class="text-center" *ngIf="notFound">
          <h2>{{staticMsgs.seamsLibrary.NoDataAvailable}}</h2>
          <img [src]="notfoundImg" class="img-fluid "><br>
          <!-- <button type="button" class="btn btn-primary adv-search-btn mr-3" (click)="createSeams()"><i
              class="icon icon-plus"></i> Create New</button> -->

        </div>
        <div class="grid-content" *ngIf="gridContent  && !notFound">

          <div class="row">
            <div class="col-sm-9" >
              <div *ngIf="!checkStatusData">
              <i class="fa fa-search search-button"></i>
              <input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords"
                placeholder="Enter Keyword for filter results" [(ngModel)]="searchedKeyword" />
              <!---<input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords" [(ngModel)]="searchedKeyword" />-->
            </div>
            </div>

            <div class="col-sm-3">
              <select placeholder="Select Status" class="form-control selectpicker w-100" [(ngModel)]="selected"
                (ngModelChange)="onSelect(selected,'myseams')">
                <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
              </select>
            </div>

          </div>
          <div class="text-center" *ngIf="checkStatusData">
            <h2>{{msg}}</h2>
            <img [src]="notfoundImg" class="img-fluid ">
          </div>
          <!-- Grid -->
          <div class="row mt-5">

            <!-- Grid Item -->
            <div class="col-xl-12 order-xl-1">

              <!-- Card -->
              <div class="row">

                <!-- Grid Item -->
                <div class="col-xl-3 col-md-4 col-sm-6 col-12"
                  *ngFor="let card of (cards | async) | filter:searchedKeyword">

                  <!-- Card -->
                  <div class="card border-l-r">

                    <!-- Card Body -->
                    <div class="">
                      <!--<img class="h-260" [src]='card.img' alt="Img" >-->

                      <!-- <img class="h-260" [src]='card.thumbnail != "string"?card.thumbnail:thumbnail' alt="Img"> -->
                      <img *ngIf="card.seamDrawingFile" class="card-view-img" alt=""
                        src={{card.seamDrawingFile.thumbnail}}>
                      <img *ngIf="card.seamDrawingFile==null" class="card-view-img" alt="" src={{thumbnail}}>

                    </div>
                    <div class="card-body color-card">
                      <div class="text-eli">
                        <p class="mb-1 mb-sm-0 f-13 text-truncate sequence">
                          <a href="javascript:void(0)" (click)="onEditSeams(card.id)">
                            {{card.name ? card.name : card.sequence}}
                          </a>
                        </p>
                      </div>
                      <div class="row ">
                        <div class="col-sm-7 col-xl-7">
                          <span
                            class="d-block text-light-gray  mt-1 f-12 text-truncate">{{card.classification}}</span>
                        </div>
                        <div class="col-sm-5 col-xl-5">
                          <div class="float-right d-web">
                            <!-- <span class="text-light-gray mb-3 f-12 d-active"><i
                          class="icon icon-dot d-red"></i>{{card.status}}</span> -->
                            <span class="text-light-gray mb-3 f-12 d-active"><i
                                class="icon icon-dot d-green"></i>{{card.status | titlecase}}</span>
                            <!-- <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status.toLowerCase()=='dropped'"><i
                              class="icon icon-dot d-blue"></i>{{card.status | titlecase}}</span>
                              <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status.toLowerCase()=='inreview'"><i
                                class="icon icon-dot d-yellow"></i>{{card.status | titlecase}}</span> -->
                            <!-- Dropdown -->
                            <div class="dropdown">
                              <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)" (click)="onEditSeamsDetails(card.id)">
                                  <i class="icon icon-editors icon-fw"></i>
                                  {{staticMsgs.seamsLibrary.Edit}}
                                </a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteSeams(card.id)">
                                  <i class="icon icon-trash icon-fw"></i>
                                  {{staticMsgs.seamsLibrary.delete}}
                                </a>
                                <!-- <a class="dropdown-item" href="javascript:void(0)">
                              <i class="icon icon-contacts icon-fw"></i>
                              Contact List</a>
                            <a class="dropdown-item" href="javascript:void(0)">
                              <i class="icon icon-heart icon-fw"></i>
                              Favourite List
                            </a> -->
                              </div>
                            </div>
                            <!-- /dropdown -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="action-tools">
                      <!-- Checkbox -->
                      <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only">
                        <input id="checkbox{{card.id}}" type="checkbox" [(ngModel)]="card.isFavourite"
                          (click)="AddToFavorite($event)" [ngbTooltip]="card.isFavourite ? 'Remove From favorite' : 'Add to favorite'">
                        <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                          <span class="unchecked"><i class="icon icon-star-o text-white icon-lg"></i></span>
                          <span class="checked"><i class="icon icon-star-fill text-secondary icon-lg"></i></span>
                        </label>
                      </div>
                      <!-- /checkbox -->

                    </div>

                    <!-- /card body -->

                  </div>
                  <!-- /card -->

                </div>
                <!-- /grid item -->

              </div>
              <!-- /card -->

            </div>
            <!-- /grid item -->

          </div>
          <!-- /grid -->

          <div *ngIf="!isGetAllRecords" class="row mt-5">
            <div *ngIf="!isScrollDown" class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
              <div class="text-center w-100">
                <div class="loadersmall">
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

      <div class="grid-content custom-table" *ngIf="tableContent  && !notFound">

        <!-- Grid Item -->
        <div class="">

          <kendo-grid #grid [data]="gridData" [pageable]="true" [sortable]="true"
            [reorderable]="true" [resizable]="true" [pageSize]="10" [height]="605" [(loading)]="loading"
            (pageChange)="onPageChange($event)"  [skip]="state.skip" [sort]="state.sort"
            [selectable]="{enabled: true, checkboxOnly: true}"
            filterable="menu" [groupable]="true" [filter] = "filter" (filterChange)="filterChange($event)"
             (dataStateChange)="dataStateChange($event)" (dblclick)="dblClick($event)" [groupable]="true">
             <kendo-grid-checkbox-column [width]="60" [showSelectAll]="true" [resizable]="false"
              [locked]="true"></kendo-grid-checkbox-column>
            <ng-template kendoGridToolbarTemplate>
              <input placeholder="Search in all columns..." kendoTextBox (input)="searchListData($event.target.value)"
                class="kendoSearch" />
                <div class="float-right">
                  <!-- <button kendoGridExcelCommand type="button" class="mr-3" icon="file-excel">
                    Export to Excel
                  </button>
                <button kendoGridPDFCommand class="mr-3" icon="file-pdf">Export to PDF</button> -->
                <div
                                class="kendo-button-icon mr-3"
                                kendoGridExcelCommand
                              >
                                <i
                                  class="fa fa-file-excel-o"
                                  aria-hidden="true"
                                ></i>
                              </div>



<div
                                kendoGridPDFCommand
                                class="kendo-button-icon mr-3"
                              >
                                <i
                                  class="fa fa-file-pdf-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                </div>

          </ng-template>
            <!-- <kendo-grid-column field="Discontinued" title="" width="40">
          <ng-template kendoGridCellTemplate let-dataItem>
              <input type="checkbox" [checked]="dataItem.Discontinued" />
          </ng-template>
        </kendo-grid-column> -->
            <!-- <kendo-grid-column field="thumbnail" title="Thumbnail">

          <img class="w-100 border-l-r h-260" [src]='thumbnail != "string"?thumbnail:thumbnail' alt="Img">
        </kendo-grid-column> -->
            <kendo-grid-column title="Thumbnail" [filterable]="false" width="200" class="text-center">
              <ng-template kendoGridCellTemplate let-dataItem>
                <img *ngIf="dataItem.seamDrawingFile==null" class="img" [src]="thumbnail" alt="">
                <img *ngIf="dataItem.seamDrawingFile" class="img" src={{dataItem.seamDrawingFile.thumbnail}} alt="">

              </ng-template>
            </kendo-grid-column>
            <!-- <kendo-grid-column *ngIf="isEditMode" field="id" title="ID" [editable]="false">
        </kendo-grid-column> -->
            <kendo-grid-column field="name" width="250" title="Name" >
        </kendo-grid-column>
            <kendo-grid-column [filterable]="false" width="200" field="seam751aNumber" title="751A Number">
            </kendo-grid-column>

            <kendo-grid-column field="isO4916Number" width="200" title="ISO 4916 Number">
            </kendo-grid-column>
            <kendo-grid-column [filterable]="false" [sortable]="false" width="250" field="commonApplication" title="Common Application">
            </kendo-grid-column>
            <kendo-grid-column [filterable]="false" [sortable]="false" width="250" field="requirements" title="Requirements">
            </kendo-grid-column>
            <!-- <kendo-grid-column  field="status" title="Status"  >
        </kendo-grid-column> -->
            <kendo-grid-column field="status" title="Status" width="200">
              <!-- <ng-template kendoGridCellTemplate let-dataItem>
                <span><i class="icon icon-dot d-green"></i>{{dataItem.status | titlecase}}</span> -->
                <!-- <span *ngIf="dataItem.status=='Dropped'"><i
              class="icon icon-dot d-red"></i>{{dataItem.status}}</span>
                <span *ngIf="dataItem.status=='In Review'"><i
                  class="icon icon-dot d-yellow"></i>{{dataItem.status}}</span> -->
                  <ng-template kendoGridFilterMenuTemplate let-column="column"
                  let-filter="filter"
                  let-filterService="filterService" let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <app-dropdownlist-filter [data]="options" [textField]="'label'" [valueField]="'value'"
                  [isPrimitive]="true" [field]="column.field" [currentFilter]="filter"
                   [filterService]="filterService">

                  </app-dropdownlist-filter>

              </ng-template>
              <!-- <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <kendo-dropdownlist [data]="listItems" [textField]="'text'" [valueField]="'value'"
                  [valuePrimitive]="true" [formControl]="fac(dataItem).controls.status">
                </kendo-dropdownlist>
              </ng-template> -->
            </kendo-grid-column>
            <!-- <kendo-grid-column field="createdBy.firstName" title="Created By" [editable]="false">
        </kendo-grid-column>
        <kendo-grid-column field="createdOn" title="Created On" [editable]="false">
        </kendo-grid-column>
        <kendo-grid-column field="modifiedoOn" title="Modified On" [editable]="false">
        </kendo-grid-column> -->
            <kendo-grid-column title="Action" width="140">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button (click)="editClick(rowIndex,dataItem)" title="Edit" class="mr-2 k-button k-primary k-grid-edit-command">
                  Edit
                  <!-- <i class="icon icon-editors f-17"></i> -->
                </button>
                <button *ngIf="dataItem.isDelete" (click)="onRemove(rowIndex,dataItem)" class="k-button k-grid-remove-command"
                  title="Delete">Delete
                  <!-- <i class="icon icon-trash f-16"></i> -->
                </button>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="SeamsLibrary.xlsx" [fetchData]="allData"></kendo-grid-excel>
            <kendo-grid-pdf fileName="SeamsLibrary.pdf" [allPages]="true">
              <kendo-grid-pdf-margin top="0.5cm" left="0.5cm" right="0.5cm" bottom="0.5cm"></kendo-grid-pdf-margin>
            </kendo-grid-pdf>

            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
              <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
              <kendo-pager-next-buttons></kendo-pager-next-buttons>
              <kendo-pager-info></kendo-pager-info>
            </ng-template>
            <ng-template #SeamsCustomComponent>
              <mat-dialog-content >
                <app-seams-custom></app-seams-custom>
              </mat-dialog-content>
              </ng-template>
          </kendo-grid>

          <!-- /card -->

        </div>
        <!-- /grid item -->

      </div>

      <span *ngIf="this.showNote" class="red">*Note: 751ANumber,ISO4916Number fields are allowed 20 & CommonApplication,Requirements fields are allowed 500 characters only.</span>
    </div>

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->
  </div>
  <!-- /Site Content Wrapper-->

</main>
<!-- /site content -->
<!-- Customizer Sidebar -->
<aside class="dt-customizer dt-drawer position-right" [class.open]="classApplied">
  <!-- Item Detail Section -->
  <div class="dt-customizer__inner">

    <!-- Customizer Header -->
    <div class="dt-customizer__header">

      <!-- Customizer Title -->
      <div class="dt-customizer__title">
        <h4 class="mb-0">{{staticMsgs.seamsLibrary.AdvanceSearch}}</h4>
      </div>
      <!-- /customizer title -->
      <!-- Close Button -->
      <button type="button" class="close" (click)="advanceSearchClose()">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- /close button -->

    </div>
    <!-- /customizer header -->
    <!-- Customizer Body -->
    <div class="dt-customizer__body ps-custom-scrollbar">
      <!-- Customizer Body Inner  -->
      <div class="dt-customizer__body-inner">
        <form [formGroup]="advForm" (ngSubmit)="onSubmit(advForm.value)">
          <!--<input type="text" formControlName="keywords" id="keywords" class="form-control" />
                                      <button>Submit</button>
                                    </form>-->
          <!-- Section -->
          <section>
            <div class="col-sm-12">

              <!--Accordion wrapper-->
              <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                <div class="col-sm-12 mb-4 text-center">
                  <button type="button" class="btn btn-primary adv-clear-btn" *ngIf="isResetMode"
                    (click)="clearSearch()">{{staticMsgs.seamsLibrary.ClearSearch}}</button>
                </div>

                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne1" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                    <!-- <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true"
                                    aria-controls="collapseOne1">
                                    <h5 class="mb-0">
                                      Keywords <i class="fa fa-angle-down rotate-icon float-right"></i>
                                    </h5>
                                  </a>-->

                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.seamsLibrary.Keywords}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableKeywordVal">
                        <button type="button" class="close image-close ml-5" (click)="this.clearfield('keyword');"><span
                            aria-hidden="true">×</span></button>

                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseOne1" class="collapse show" role="tabpanel" aria-labelledby="headingOne1"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto w-100">
                      <input type="text" class="form-control" formControlName="keyword" #keywordData
                        placeholder="Enter Keywords" name="SearchD" #SearchD
                        (input)="checkFieldVal(SearchD.value, 'keyword')" />

                    </div>
                  </div>

                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingThree3" data-toggle="collapse"
                    data-parent="#accordionEx" href="#collapseThree3" aria-expanded="true"
                    aria-controls="collapseThree3">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree3"
         aria-expanded="false" aria-controls="collapseThree3">
        <h5 class="mb-0 ">
          Select Classification <i class="fa fa-angle-down rotate-icon float-right"></i>
        </h5>
      </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">{{staticMsgs.seamsLibrary.seamNumber}}</h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="seamsclassificationValue">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('classification');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseThree3" class="collapse" role="tabpanel" aria-labelledby="headingThree3"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto">
                      <!--<select id="classificationlist" title="Select Classification" multiple class="form-control selectpicker w-100"
          formControlName="classification">
          <option *ngFor="let stand of standardoptions" [value]="stand.value">{{ stand.value }}</option>
        </select>-->
                      <input type="text" class="form-control" id="seam751aNumber" formControlName="seam751aNumber"
                        placeholder="Select seam751aNumber" name="SearchClass" #SearchClass
                        (input)="checkFieldVal(SearchClass.value, 'seam751aNumber')" />
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingThree4" data-toggle="collapse"
                    data-parent="#accordionEx" href="#collapseThree4" aria-expanded="true"
                    aria-controls="collapseThree4">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree3"
           aria-expanded="false" aria-controls="collapseThree3">
          <h5 class="mb-0 ">
            Select Classification <i class="fa fa-angle-down rotate-icon float-right"></i>
          </h5>
        </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">{{staticMsgs.seamsLibrary.isoNumber}}</h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableisO4916Number">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('isO4916Number');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseThree4" class="collapse" role="tabpanel" aria-labelledby="headingThree4"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto">
                      <!--<select id="classificationlist" title="Select Classification" multiple class="form-control selectpicker w-100"
            formControlName="classification">
            <option *ngFor="let stand of standardoptions" [value]="stand.value">{{ stand.value }}</option>
          </select>-->
                      <input type="text" class="form-control" id="isO4916Number" formControlName="isO4916Number"
                        placeholder="Select isO4916Number" name="SearchClass" #SearchClass
                        (input)="checkFieldVal(SearchClass.value, 'isO4916Number')" />
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingThree5" data-toggle="collapse"
                    data-parent="#accordionEx" href="#collapseThree5" aria-expanded="true"
                    aria-controls="collapseThree5">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree3"
         aria-expanded="false" aria-controls="collapseThree3">
        <h5 class="mb-0 ">
          Select Classification <i class="fa fa-angle-down rotate-icon float-right"></i>
        </h5>
      </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">{{staticMsgs.seamsLibrary.CommonApplication}}</h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enablecommonApplication">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('commonApplication');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseThree5" class="collapse" role="tabpanel" aria-labelledby="headingThree5"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto">
                      <!--<select id="classificationlist" title="Select Classification" multiple class="form-control selectpicker w-100"
          formControlName="classification">
          <option *ngFor="let stand of standardoptions" [value]="stand.value">{{ stand.value }}</option>
        </select>-->
                      <input type="text" class="form-control" id="commonApplication" formControlName="commonApplication"
                        placeholder="Select commonApplication" name="SearchClass" #SearchClass
                        (input)="checkFieldVal(SearchClass.value, 'commonApplication')" />
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingTwo2" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapseTwo2" aria-expanded="true" aria-controls="collapseTwo2">
                    <!-- <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
                                    aria-expanded="false" aria-controls="collapseTwo2">
                                    <h5 class="mb-0">
                                      Created By <i class="fa fa-angle-down rotate-icon float-right"></i>
                                    </h5>
                                  </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.seamsLibrary.CreatedBy}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableCreatedByVal">
                        <button type="button" class="close image-close ml-5" ([ngModel])="enableCreatedByVal"
                          (click)="this.clearfield('createdBy');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseTwo2" class="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto">
                      <!---<select title="Select Created By" multiple formControlName="createdBy" class="form-control selectpicker w-100" >
                                                  <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
                                                </select>--->
                      <input type="text" class="form-control" value="" formControlName="createdBy"
                        placeholder="Created By" name="SearchD1" #SearchD1
                        (input)="checkFieldVal(SearchD1.value, 'createdBy')" />
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingThree3" data-toggle="collapse"
                    data-parent="#accordionEx" href="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapsefour"
                                    aria-expanded="false" aria-controls="collapseThree3">
                                    <h5 class="mb-0">
                                      Status <i class="fa fa-angle-down rotate-icon float-right"></i>
                                    </h5>
                                  </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.seamsLibrary.Status}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableStatusVal">
                        <button type="button" class="close image-close ml-5" (click)="this.clearfield('status');"><span
                            aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapsefour" class="collapse" role="tabpanel" aria-labelledby="headingThree3"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto">
                      <select id="statuslist" title="Select Status" multiple formControlName="status"
                        class="form-control selectpicker w-100" [(ngModel)]="enableStatusVal"
                        (ngModelChange)="checkFieldVal(enableStatusVal, 'status')">
                        <option *ngFor="let status of options" [value]="status.label">{{ status.label }}</option>
                      </select>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingFive" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapsefive"
                                    aria-expanded="false" aria-controls="collapseThree3">
                                    <h5 class="mb-0">
                                      Created On <i class="fa fa-angle-down rotate-icon float-right"></i>
                                    </h5>
                                  </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.seamsLibrary.CreatedOn}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableCreatToVal||enableCreatFromVal">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('createdFromChild');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapsefive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto">
                      <div class="form-group">
                        <label>{{staticMsgs.seamsLibrary.From}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-1" data-target-input="nearest">
                                        <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-1"
                                          formControlName="createdFrom" #createdFrom (change)="setDate($event)" name="createdFrom" />
                                        <div class="input-group-append" data-target="#date-time-picker-1" data-toggle="datetimepicker"
                                          (click)="checkFieldVal(['val'],'createdFromChild')">
                                          <div class="input-group-text">
                                            <i class="icon icon-calendar"></i>
                                          </div>
                                        </div>
                                      </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="createdFromDate" ngbDatepicker readonly
                            (click)="d1.toggle()" #d1="ngbDatepicker" formControlName="createdFrom"
                            (dateSelect)="dateChange($event,'createdFromDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                        <br>
                        <label>{{staticMsgs.seamsLibrary.To}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-2" data-target-input="nearest">
                                        <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-2"
                                          formControlName="createdTo" #createdTo />
                                        <div class="input-group-append" data-target="#date-time-picker-2" data-toggle="datetimepicker"
                                          (click)="checkFieldVal(['val'],'createdToChild')">
                                          <div class="input-group-text">
                                            <i class="icon icon-calendar"></i>
                                          </div>
                                        </div>
                                      </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="createdToDate" ngbDatepicker readonly
                            (click)="d2.toggle()" #d2="ngbDatepicker" formControlName="createdTo"
                            (dateSelect)="dateChange($event,'createdToDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingSix" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapsesix" aria-expanded="true" aria-controls="collapsesix">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapsesix"
                                    aria-expanded="false" aria-controls="collapseThree3">
                                    <h5 class="mb-0">
                                      Modified On <i class="fa fa-angle-down rotate-icon float-right"></i>
                                    </h5>
                                  </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.seamsLibrary.ModifiedOn}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableModifToVal||enableModifFromVal">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('modifiedFromChild');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapsesix" class="collapse" role="tabpanel" aria-labelledby="headingSix"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto">
                      <div class="form-group">
                        <label>{{staticMsgs.seamsLibrary.From}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-3" data-target-input="nearest">
                                        <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-3"
                                          #modifiedFrom formControlName="modifiedFrom" (change)="setDate($event)" />
                                        <div class="input-group-append" data-target="#date-time-picker-3" data-toggle="datetimepicker"
                                          (click)="checkFieldVal(['val'],'modifiedFromChild')">
                                          <div class="input-group-text">
                                            <i class="icon icon-calendar"></i>
                                          </div>
                                        </div>
                                      </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="modifiedFromDate" ngbDatepicker readonly
                            (click)="d3.toggle()" #d3="ngbDatepicker" formControlName="modifiedFrom"
                            (dateSelect)="dateChange($event,'modifiedFromDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                        <br>
                        <label>{{staticMsgs.seamsLibrary.To}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-4" data-target-input="nearest">
                                        <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-4"
                                          formControlName="modifiedTo" #modifiedTo />
                                        <div class="input-group-append" data-target="#date-time-picker-4" data-toggle="datetimepicker"
                                          (click)="checkFieldVal(['val'],'modifiedToChild')">
                                          <div class="input-group-text">
                                            <i class="icon icon-calendar"></i>
                                          </div>
                                        </div>

                                      </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="modifiedToDate" ngbDatepicker readonly
                            (click)="d4.toggle()" #d4="ngbDatepicker" formControlName="modifiedTo"
                            (dateSelect)="dateChange($event,'modifiedToDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->

              </div>
              <!-- Accordion wrapper -->

            </div>

            <div class="add-comment-box">
              <div class="d-flex">
                <div class="col-sm-6">
                  <button class="btn btn-primary adv-search-btn w-100">
                    {{staticMsgs.seamsLibrary.Apply}}</button>
                </div>
                <div class="col-sm-6">
                  <button type="button" (click)="clearSearch()" class="btn btn-primary adv-search-btn w-100 ">
                    {{staticMsgs.seamsLibrary.ClearAll}}</button>
                </div>
              </div>
            </div>
          </section>
        </form>

        <!-- /section -->

      </div>
      <!-- /customizer body inner -->
    </div>
    <!-- /customizer body -->

  </div>
  <!-- Item Details Section End-->

</aside>
<!-- /customizer sidebar -->

<!-- Root -->



