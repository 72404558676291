import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppComponent } from '../../app.component'
import { concat, interval, Subscription } from 'rxjs';
import { Router } from '@angular/router'
import { Route } from '@angular/compiler/src/core';
import { NavigationModel } from '../navigation/navigation.model';
import { NavigationService } from '../../../gaxon/components/navigation/navigation.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
//import { ShareService } from '../../../services/share.service';
import { StorageService } from '../../helpers/storage.service';
import { DataService } from '../../services/data.service';
import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import { NotificationMiddlewareService } from '../../services/notification-middleware.service';
import { UserService } from '../../helpers/user.service';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../Auth/register/custom-validators';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';
import { environment } from '../../../../../tenants/main/environments/environment';
import awsconfig from '../../../../../aws-exports';
import { io } from 'socket.io-client';
import { AESDecryptService } from '../../helpers/AESDecrypt.service';
import { LogService } from '../../shared/log.service';
import * as userActions from "../../actions/userActions.actions";
import { select, Store } from '@ngrx/store';
import * as appStaticMsg from '../../../assets/appStaticMsg.json';

const socket = io(environment.socketIoServerUrl,{transports: ['websocket', 'polling', 'flashsocket']});
declare var $: any;

@Component({
	selector: 'app-leftbar',
	templateUrl: './leftbar.component.html',
	styleUrls: ['./leftbar.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class LeftbarComponent implements OnInit {

	@ViewChild('content') content: any;
	@ViewChild('openterms') openterms: any;
	@ViewChild('termsandcondition') termsandcondition: any;
	@ViewChild('privacypolicy') privacypolicy: any;
	customScrollbars: boolean;
	isNavOpened: boolean;
	sidebarRef: any;
	drawer: any;
	navigationStyle: string = 'default';
	navigationFixed: boolean;
	showLoader: boolean;
	showLoader1: boolean;
	userLoggedIn: boolean;
	modalReference: any;
	alert1 = '';
	errors1 = '';
	errorDiv1: boolean;
	changePassword: FormGroup;
	submitted = false;
	Swal = require('sweetalert2');
	navArray: NavigationModel;
	public navigation: any[];
	log: any[];
	//user: String;
	//userrole: String;
	clickEventsubscription: Subscription;
	autosubscription: Subscription;
	classApplied = false;
	drawerlist = false;
	drawernotifications = false;
	drawermessages = false;
	notificationData = [];
	notificationCheckData = [];
	unReadCnt = 0;
	userThumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";
	//userImg = '../../../assets/images/placeholder.jpg';
  userImg = '';
	intervalId: number;
	notificationCnt = 0;
  firstName: any;
  lastName: any;
  staticMsgs:any;

	constructor(eleRef: ElementRef,
		private store: Store<any>,
		private fb: FormBuilder,
		private navService: NavigationService,
		private auth: AuthService,
		private storage: StorageService,
		private router: Router,
		public userservice: UserService,
		private dataservice: DataService,
		private sharedService: SharedService,
		private modalService: NgbModal,
		public aesdecryptservice: AESDecryptService,
		public notificationMiddleware: NotificationMiddlewareService,
		private logger: LogService,
		@Inject(DOCUMENT) private document: any
	) {
		this.sidebarRef = eleRef.nativeElement;
		this.sidebarRef.classList.add('dt-sidebar');

		this.userservice.currentUser = {
			user: '',
			thumb: 'https://via.placeholder.com/150x150',
			userrole: ''
		};
		// Set the navigation model
		this.getMenuItems();

		this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
			this.closeSideBar();
		});

	}

	ngOnDestroy() {
		this.autosubscription && this.autosubscription.unsubscribe();
	}

  image:any = '';

	ngOnInit() {


this.staticMsgs=appStaticMsg.data[0];
		const autosource = interval(10000);
		this.autosubscription = autosource.subscribe(val => this.getNotificationsCount());

		this.dataservice.AddClientLog();
		const source = interval(1.2e+8);
		source.subscribe(val => this.dataservice.AddClientLog());
		this.showLoader = true;
		this.navigationStyle = 'default';
		this.isNavOpened = false;
		this.navigationFixed = true;
		this.customScrollbars = true;
		let username = this.storage.getUser();
    let userFullName = this.storage.getUserData();
		this.userservice.currentUser.user = username;

		this.userservice.currentUser.userrole = this.storage.getUserMainRole();
		if (!username) {
			this.userLoggedIn = false;
			this.dataservice.AddClientLog();
			this.router.navigate(['auth/login']);
		} else {
			this.userLoggedIn = true;

			if (this.storage.getUserImage()) {
				this.userservice.currentUser.thumb = localStorage.getItem('image');
        this.image = localStorage.getItem('image'); //this.storage.getUserImage();
				this.showLoader = false;
        console.log('image 1',this.image);
			}
			else {
          //this.image = localStorage.getItem('image');
          this.getUserDetails();
          //console.log('image 2',this.image);
			}
		}



	}

  getUserDetails(){
    let name = JSON.parse(localStorage.getItem('userFullName'));
    if(!name){
      this.dataservice.getUserDetails().subscribe((response) => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            var company = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['org']);
            var senderEmail = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['eMail']);
            var firstname = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['firstName']);
            var lastname = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['lastName']);

            this.logger.info(firstname + this.staticMsgs.leftBar.loadedSuccessfully, this.staticMsgs.leftBar.Usermanagement);

            let name = {
              firstname: firstname,
              lastName: lastname,
              eMail: senderEmail,
              company: company
            };
            localStorage.setItem('userFullName',JSON.stringify(name));
            if (response.body['item1']['pictureID']) {
              this.storage.storeUserImage(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['picture']['thumbnail']));
              this.userservice.currentUser.thumb = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['picture']['thumbnail']);
            }
            else {
              this.storage.storeUserImage(this.userImg);
              this.userservice.currentUser.thumb = this.userImg;
            }

            this.image = this.storage.getUserImage();

            if (response.body['item1']['role']) {
              if (response.body['item1']['role'][0]) {
                this.storage.storeUserSubRole(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['role'][0]['role']['name']));
              }
            }
          }
          else {
            this.showLoader = false;
          }
        }
        else {
          this.showLoader = false;
        }
      },
        err => {
          //console.log(err)
          this.logger.error(err.error.message, "Leftbar");
          if (err.error.message === "Network error communicating with endpoint") {
            this.showLoader = false;
          } else if ((err.error.message === "Unauthorized") || (err.error.message === "The incoming token has expired")) {
            this.showLoader = false;
          }
        })
    }else{
      this.firstName = name.firstName;
      this.lastName = name.lastName;
    }

  }

  getNotificationsCount() {
    this.dataservice.getNotifications().subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          let notificationData = response.body['item1'];
          if (notificationData.length > 0) {
            var unReadData = notificationData.filter((x, i) => x.readMessage === false);
            if (unReadData.length > 0) {
              this.notificationCnt = unReadData.length;
            }
          }
        }
      }
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);

      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();

      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
      }
    });
  }


  getNotifications() {
    this.notificationData = [];
    this.notificationMiddleware.notifications = [];
    this.notificationCheckData = [];
    this.unReadCnt = 0;
    this.classApplied = true;
    this.drawernotifications = true;
    this.showLoader1 = true;
    this.dataservice.getNotifications().subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          //console.log(response.body['item1']);
          //console.log(JSON.stringify(response.body['item1']));
          this.notificationData = response.body['item1'];
          if (this.notificationData.length > 0) {
            var unReadData = this.notificationData.filter((x, i) => x.readMessage === false);
            if (unReadData.length > 0) {
              this.unReadCnt = unReadData.length;
            }

            this.notificationData.forEach(item => {
              item.notificationMessage = this.aesdecryptservice.decryptData(this.dataservice.Key, item.notificationMessage);
              item.textMessage = this.aesdecryptservice.decryptData(this.dataservice.Key, item.textMessage);
              item.recordName = this.aesdecryptservice.decryptData(this.dataservice.Key, item.recordName);
              item.senderEmail = this.aesdecryptservice.decryptData(this.dataservice.Key, item.senderEmail);
              item.receiverEmail = this.aesdecryptservice.decryptData(this.dataservice.Key, item.receiverEmail);
              item.moduleName = this.aesdecryptservice.decryptData(this.dataservice.Key, item.moduleName);
              item.notificationType = this.aesdecryptservice.decryptData(this.dataservice.Key, item.notificationType);
              item.org = this.aesdecryptservice.decryptData(this.dataservice.Key, item.org);
              item.status = this.aesdecryptservice.decryptData(this.dataservice.Key, item.status);
              item.senderUserDTO[0].firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, item.senderUserDTO[0].firstName);
              item.senderUserDTO[0].lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, item.senderUserDTO[0].lastName);
              item.senderUserDTO[0].fullName =  item.senderUserDTO[0].firstName +" "+ item.senderUserDTO[0].lastName
              if (item.senderUserDTO[0].pictureID) {
                item.senderUserDTO[0].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, item.senderUserDTO[0].picture.thumbnail);
              }
              var ago = this.transform(item.createdOn);
              item["timestampAgo"] = ago;
              if (item.senderUserDTO[0].pictureID) {
                item["userThumbnail"] = item.senderUserDTO[0].picture.thumbnail;
              }
              else {
                item["userThumbnail"] = this.userThumbnail;
              }

              //item["notificationMessage"] = environment.notificationStyleChatMessage;
              if (item.moduleName == "Styles") {

                if (item.notificationType == "Share") {
                  environment.notificationStyleSharedMessage = environment.notificationStyleSharedMessage.replace("StyleName", item["recordName"]);
                  item["message"] = environment.notificationStyleSharedMessage;
                }
                if (item.notificationType == "UnShare") {
                  environment.notificationStyleUnSharedMessage = environment.notificationStyleUnSharedMessage.replace("StyleName", item["recordName"]);
                  item["message"] = environment.notificationStyleUnSharedMessage;
                }
                if (item.notificationType == "Chat") {
                  environment.notificationStyleChatMessage = environment.notificationStyleChatMessage.replace("StyleName", item["recordName"]);
                  item["message"] = environment.notificationStyleChatMessage;
                }
                if (item.notificationType == "ShareEdit") {
                  environment.notificationStyleEditAccessMessage = environment.notificationStyleEditAccessMessage.replace("StyleName", item["recordName"]);
                  item["message"] = environment.notificationStyleEditAccessMessage;
                }
                if (item.notificationType == "ShareView") {
                  environment.notificationStyleViewAccessMessage = environment.notificationStyleViewAccessMessage.replace("StyleName", item["recordName"]);
                  item["message"] = environment.notificationStyleViewAccessMessage;
                }
              }
            });
          }

        }
        else {
          this.showLoader1 = false;
        }
      }
      else {
        this.showLoader1 = false;
      }
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader1 = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader1 = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader1 = false;
      }
      else {
        this.error(err.message);
      }
      this.showLoader1 = false;
    });

  }

  transform(value: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }

  onclickNavigation(item) {
    if (item.moduleName == "Styles") {
      this.storage.storeEditedStyleId(item.recordId);
      if (item.notificationType == "Chat") {
        this.storage.storeStyleIdNotification(item.recordId);
      }
      //this.router.navigate(['/styledetails']);
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }

      var role = this.storage.getUserSubRole();
      if (role !== "Supplier") {
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/styledetails']);
      }
      else {
        this.error(this.staticMsgs.leftBar.roleDonthaveAccess);
      }

    }
  }

  markAsRead(item) {
    this.showLoader1 = true;
    var recordIds = [];
    recordIds.push(item.id);
    const notification = {};
    notification["recordIds"] = recordIds;
    //console.log(JSON.stringify(notification));
    this.dataservice.readNotifications(notification).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          this.onclickNavigation(item);
        }
        else {
          this.showLoader1 = false;
        }
      }
      else {
        this.showLoader1 = false;
      }
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader1 = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader1 = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader1 = false;
      }
      else {
        this.error(err.message);
      }
      this.showLoader1 = false;
    });
  }

  markAllRead(notificationData) {
    this.showLoader1 = true;
    var recordIds = [];
    notificationData.forEach(item => {
      recordIds.push(item.id);
    });
    const notification = {};
    notification["recordIds"] = recordIds;
    //console.log(JSON.stringify(notification));
    this.dataservice.readNotifications(notification).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          this.notificationCheckData = [];
          this.getNotifications();
        }
        else {
          this.showLoader1 = false;
        }
      }
      else {
        this.showLoader1 = false;
      }
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader1 = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader1 = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader1 = false;
      }
      else {
        this.error(err.message);
      }
      this.showLoader1 = false;
    });
  }

  clickNavigation(item) {
    this.onclickNavigation(item);
  }

  checkValue(item) {
    var data = this.notificationCheckData.filter(x => x.id == item.id);
    if (data.length > 0) {
      let dataIndex = this.notificationCheckData.indexOf(item);
      this.notificationCheckData.splice(dataIndex, 1);
    }
    else {
      this.notificationCheckData.push(item);
    }
  }


  deleteNotifications(item) {
    this.showLoader1 = true;
    var recordIds = [];
    recordIds.push(item.id);
    const notification = {};
    notification["recordIds"] = recordIds;
    //console.log(JSON.stringify(notification));
    this.dataservice.deleteNotifications(notification).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          this.notificationCheckData = [];
          this.getNotifications();
        }
        else {
          this.showLoader1 = false;
        }
      }
      else {
        this.showLoader1 = false;
      }
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader1 = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader1 = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader1 = false;
      }
      else {
        this.error(err.message);
      }
      this.showLoader1 = false;
    });
  }

  deleteAllNotifications(notificationData) {
    this.showLoader1 = true;
    var recordIds = [];
    notificationData.forEach(item => {
      recordIds.push(item.id);
    });
    const notification = {};
    notification["recordIds"] = recordIds;
    //console.log(JSON.stringify(notification));
    this.dataservice.deleteNotifications(notification).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          this.notificationCheckData = [];
          this.getNotifications();
        }
        else {
          this.showLoader1 = false;
        }
      }
      else {
        this.showLoader1 = false;
      }
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader1 = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader1 = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader1 = false;
      }
      else {
        this.error(err.message);
      }
      this.showLoader1 = false;
    });
  }


  search() {
    this.classApplied = true;
    this.drawerlist = true;
  }
  message() {
    this.classApplied = true;
    this.drawermessages = true;
  }
  dismiss() {
    this.classApplied = false;
    this.drawernotifications = false;
    this.drawerlist = false;
    this.drawermessages = false;

  }

  // addClientLog(){
  // 	this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  // 	if(this.log!=null){
  // 	  this.dataservice.addClientLog(this.log).subscribe();;
  // 	  this.log=[];
  // 	  this.logger.clear();
  // 	}

  //   }
  /**
  * On Toggle navigation
  * @param event
  */
  onToggleNavigation(event) {
    event.preventDefault();

    if (this.navigationStyle === 'folded') {
      this.navigationStyle = 'default';
      //this.sidebarRef.classList.remove('dt-sidebar--folded');
      this.document.body.classList.remove('dt-sidebar--folded');
      this.isNavOpened = false;
    } else {
      this.navigationStyle = 'folded';
      //this.sidebarRef.classList.add('dt-sidebar--folded');
      this.document.body.classList.add('dt-sidebar--folded');
      this.isNavOpened = true;
    }

  }

  closeSideBar() {
    setTimeout(() => {
      this.navigationStyle = 'folded';
      //this.sidebarRef.classList.add('dt-sidebar--folded');
      this.document.body.classList.add('dt-sidebar--folded');
      this.isNavOpened = true;
    }, 5);

  }

  // Method to open the Change Password selection Modal pop-up
  openVersion(openterms) {
    let ngbModalOptions: NgbModalOptions = {
      // backdrop: 'static',
      // keyboard: false
    };
    this.modalReference = this.modalService.open(openterms, ngbModalOptions);
  }
  openTermsandcondition(termsandcondition) {
    let ngbModalOptions: NgbModalOptions = {
      // backdrop: 'static',
      // keyboard: false
    };
    this.modalReference = this.modalService.open(termsandcondition, ngbModalOptions);
  }
  openPrivacyPolicy(privacypolicy) {
    let ngbModalOptions: NgbModalOptions = {
      // backdrop: 'static',
      // keyboard: false
    };
    this.modalReference = this.modalService.open(privacypolicy, ngbModalOptions);
  }

  open(content) {
    let ngbModalOptions: NgbModalOptions = {
      // backdrop: 'static',
      // keyboard: false
    };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
    this.submitted = false;
    this.alert1 = '';
    this.errors1 = '';
    this.errorDiv1 = false;

    $(document).ready(function () {
      $("[data-toggle='popover']").popover({ html: true });

      if (!('ontouchstart' in window)) {
        $('.tip').tooltip();
      }

      $(function passwordValidation() {

        var pwdInput = $('#password');
        var pwdValid = false;

        function validatePwdStrength() {

          var pwdValue = $(this).val(); // This works because when it's called it's called from the pwdInput, see end

          // Validate the length
          if (pwdValue.length > 7) {
            $('#length').removeClass('invalid').addClass('valid');
            pwdValid = true;
          } else {
            $('#length').removeClass('valid').addClass('invalid');
          }

          // Validate capital letter
          if (pwdValue.match(/[A-Z]/)) {
            $('#capital').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#capital').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // Validate lowercase letter
          if (pwdValue.match(/[a-z]/)) {
            $('#lowercase').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#lowercase').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // special character
          if (pwdValue.match(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            $('#number-special').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#number-special').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // Validate number
          if (pwdValue.match(/[\d\\]/)) {
            $('#number-special1').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#number-special1').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }
        }

        pwdInput.bind('change keyup input', validatePwdStrength); // Keyup is a bit unpredictable

      }); // END passwordValidation()

      $('#password').focusin(function () {
        $('#password-validation-box').show();
      }).focusout(function () {
        $('#password-validation-box').hide();
      });

      $('.toggle-password').click(function () {

        $(this).toggleClass('fa-eye fa-eye-slash');
        const input = $($(this).attr('toggle'));
        if (input.attr('type') === 'password') {
          input.attr('type', 'text');
        } else {
          input.attr('type', 'password');
        }
      });

    });

    this.initForm();
  }

  initForm() {

    this.changePassword = this.fb.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required,
      // check whether the entered password has a number
      CustomValidators.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(8), Validators.maxLength(15)]],
      confirmpassword: ['', [Validators.required]]
    }, {
      // validator: ConfirmedValidator('password', 'confirmpassword')
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator(this.staticMsgs.common.password, this.staticMsgs.leftBar.cp)
    });

  }

  get a() { return this.changePassword.controls; }

  async changePasswordSubmit() {

    this.submitted = true;
    const old_password = this.a.oldPassword.value;
    const new_password = this.a.password.value;
    const confirm_password=this.a.confirmpassword.value;
    if(old_password==new_password ){
      console.log('invalid');
    this.changePassword.invalid;
    this.alert1 = 'danger';
      this.errorDiv1 = true;
      this.errors1 = "<div class='alert alert-danger'>Old Password and New Password should not be same.</div>";


  }
  else if(new_password!=confirm_password){
    console.log('invalid');
    this.errorDiv1 = true;
    this.alert1 = 'danger';
    this.errors1 = "<div class='alert alert-danger'>New Password and Confirm Password should be same.</div>";
    this.changePassword.invalid;

  }else{
    this.showLoader = true;


    console.log(old_password,new_password,confirm_password,'passwords');
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, old_password, new_password);
      })
      .then(data => {
        this.showLoader = false;
       console.log(data,'data');
        this.alert1 = 'success';
        this.errorDiv1 = true;
        this.errors1 = "<div class='alert alert-success'> Password Changed Successfully.</div>";
        // setTimeout(() => {
        //   this.continue();
        // },2000);

      })
      .catch(err => {
        this.showLoader = false;
        if ((err.code === 'InvalidParameterException') || (err.code === 'NotAuthorizedException')) {
          this.alert1 = 'danger';
          this.errorDiv1 = true;
          this.errors1 = "<div class='alert alert-danger'> Incorrect Old Password.</div>";
        }

        else {
          this.alert1 = 'danger';
          this.errorDiv1 = true;
          this.errors1 = "<div class='alert alert-danger'>" + err.message + "</div>";
          console.log(err);
        }
      });

  }
  }
  // Method to close the Change Password selection Pop-Up
  continue() {
    //alert(this.flag);
    this.modalReference.close();
  }

  logOut() {
    Swal.fire({
      title: this.staticMsgs.leftBar.alertForLogout,
      // text: '',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        this.store.dispatch(new userActions.Logout());
        this.store.pipe(select(state => state));
        this.auth.signOut().then(data => {
          //console.log(data);
          this.dataservice.AddClientLog();
          let email = this.storage.getEmail();
          socket.emit('logout', { userId: email });

          this.storage.clear();
          //localStorage.removeItem('userrole');
          this.router.navigate(['/auth/login']);
        },
          err => {
            console.log(err);
            //if (err.error.message === "Access Token has been revoked") {
              this.storage.clear();
              this.showLoader = false;
              this.dataservice.AddClientLog();
              this.router.navigate(['/auth/login']);
            //}
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });

  }


	getMenuItems() {
		this.showLoader1 = true;
		if (this.storage.getMenu()) {
			this.navService.setNavigationModel(new NavigationModel(JSON.parse(this.storage.getMenu())));
			this.showLoader1 = false;
		}
		else {
			this.dataservice.getMenuItems().subscribe((response) => {
				if (response.status === 200) {
					if (response.body['item2'] === "Success") {
						console.log(response.body['item2'])
						for (let i = 0; i < response.body['item1'].length; i++) {
							response.body['item1'][i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['name']);
							response.body['item1'][i].title = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['title']);
							response.body['item1'][i].type = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['type']);
							response.body['item1'][i].url = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['url']);
							response.body['item1'][i].icon = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['icon']);
							if (response.body['item1'][i].children.length > 0) {
								for (let j = 0; j < response.body['item1'][i].children.length; j++) {
									response.body['item1'][i].children[j].name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['name']);
									response.body['item1'][i].children[j].title = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['title']);
									response.body['item1'][i].children[j].type = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['type']);
									response.body['item1'][i].children[j].url = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['url']);
									response.body['item1'][i].children[j].icon = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['icon']);

									let construction = response.body['item1'][i].children[j].children;
									if (construction.length > 0) {
										for (let k = 0; k < construction.length; k++) {
											construction[k].name = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['name']);
											construction[k].title = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['title']);
											construction[k].type = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['type']);
											construction[k].url = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['url']);
											construction[k].icon = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['icon']);
										}
									}
								}
							}
							//	response.body['item1'][i].children= this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1'][i]['icon']);

						}
						this.logger.info("All Menu list loaded successfully", "Leftbar");
						this.navService.setNavigationModel(new NavigationModel(response.body['item1']));
						this.storage.storeMenu(JSON.stringify(response.body['item1']));
						this.showLoader1 = false;
					}
					else {
						this.navigation = [{
							id: 'No Menu Items',
							title: 'No Menu Items',
							type: 'item',
							icon: '',
							url: '/'
						}];
						this.navService.setNavigationModel(new NavigationModel(this.navigation));
						this.showLoader1 = false;
					}
				}
				else {
					this.navigation = [{
						id: 'No Menu Items',
						title: 'No Menu Items',
						type: 'item',
						icon: '',
						url: '/'
					}];
					this.navService.setNavigationModel(new NavigationModel(this.navigation));
					this.showLoader1 = false;
				}
			},
				err => {
					//console.log(err)
					this.logger.error(err.error.message, "Leftbar");
					if (err.error.message === "Network error communicating with endpoint") {
						this.navigation = [{
              id: 'Network Issue',
              title: this.staticMsgs.leftBar.NetworkIssue,
              type: 'item',
              icon: '',
              url: '/'
            }];
            this.navService.setNavigationModel(new NavigationModel(this.navigation));
          } else if ((err.error.message === "Unauthorized") || (err.error.message === "The incoming token has expired")) {
            this.navigation = [{
              id: 'Session Expired',
              title: this.staticMsgs.leftBar.SessionExpired,
              type: 'item',
              icon: '',
              url: '/auth/login'
            }];
						this.navService.setNavigationModel(new NavigationModel(this.navigation));
					}
					this.showLoader1 = false;
				})

			this.navigation = [{
				id: 'Loading Menu Items',
				title: 'Loading Menu Items',
				type: 'item',
				icon: 'dot-o',
				url: '/'
			}];
			this.navService.setNavigationModel(new NavigationModel(this.navigation));
			//console.log("coming");
		}
	}


  success(message: string) {
    Swal.fire(
      'Success',
      message,
      this.staticMsgs.leftBar.success
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.leftBar.SessionExpired,
      html: this.staticMsgs.leftBar.loginAgain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
      }
    })
  }

}
