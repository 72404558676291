import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ColorEvent } from 'ngx-color';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import {
  process,
  State,
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
} from '@progress/kendo-data-query';
import { StorageService } from '../../../helpers/storage.service';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../helpers/user.service';
import { environment } from '../../../../environments/environment';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import * as S3 from 'aws-sdk/clients/s3';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import {
  DataStateChangeEvent,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { SharedService } from '../../../services/shared.service';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as materialReducer from '../../../reducers/material.reducer';
import * as materialActionTypes from '../../../actions/material.actions';
import * as commonActionTypes from '../../../actions/common.actions';
import * as colorActionTypes from '../../../actions/color.actions';
import * as supplierActionTypes from '../../../actions/supplier.actions';
import { skip, take, tap } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { Router } from '@angular/router';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { ToastService } from '../../../services/toast.service';

declare var $: any;

@Component({
  selector: 'app-materialdetails',
  templateUrl: './materialdetails.component.html',
  styleUrls: ['./materialdetails.component.css'],
})
export class MaterialdetailsComponent implements OnInit {
  @ViewChild(LoaderComponent) child: LoaderComponent;
  public materialDataCnt = 0;
  Swal = require('sweetalert2');
  showLoader: boolean;
  showLoader1: boolean;
  thumbNails: File[] = [];
  editedThumbnails: File[] = [];
  isThumbnailAdded: boolean = false;
  activeTab = 'materialDetailsTab';
  submitted = false;
  submitted1 = false;
  materialForm: FormGroup;
  documentsForm: FormGroup;
  materialColorForm: FormGroup;
  materialSupForm: FormGroup;
  materialClassificationData: any[];
  docclassification: any[];
  fileData: Array<any> = [];
  editedFileData: Array<any> = [];
  isEditingMode: boolean;
  isViewingMode: boolean;
  imageObject: Array<object> = [];
  sharedUsers: any;
  //removedFileData: Array<any> = [];
  supplierIds: Array<string> = [];
  colorId = [];
  colorSupplierId: string;
  removedDocIds = '';
  files: File[] = [];
  updMaterialList: any[];
  colordocument: any;
  errors = '';
  errorDiv = false;
  alert = '';
  isEditMode: boolean;
  notfoundImg: string;
  editedMaterialData: any;
  materialclassificationValue: string;
  parentClassification: string;
  selected: any;
  treeview: any;
  documentImage = '../../../../assets/images/doccard/document.png';
  thumbnail: string =
    'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8';
  userThumbnail: string =
    'https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png';
  public gridData1: any[];
  public gridData: any;
  public filteredData: any[];
  public gridDatacol: any;
  public gridDatasup: any;
  isCreateMode: boolean;
  UserDetails: any[];
  log: any[];
  apiGridData = [];
  apiData = [];
  public gridSupData: any;
  cards = [];
  sequenceNumber = '';
  dbsupplierData = [];
  supplierData = [];
  matsuppliersIds = [];

  dbcolorData = [];
  matecolorData = [];
  matcolorsIds = [];

  isColorButton: boolean;

  public filterQuery = '';
  public SearchData = '';

  public colorflag = 'matecolor';

  paginationStartcount = 0;
  paginationEndcount = 5;

  public colorstartCountList = 0;
  public colorendCountList = 5;
  public colorstartEndCount = '';
  public colorisPageChanged = false;
  public colorTotalRecordCount: any;
  public colorloading: boolean;

  public supplierstartCountList = 0;
  public supplierendCountList = 5;
  public supplierstartEndCount = '';
  public supplierisPageChanged = false;
  public supplierTotalRecordCount: any;
  public supplierloading: boolean;

  public filter: CompositeFilterDescriptor;

  public matecolorstartCountList = 0;
  public matecolorendCountList = 5;
  public matecolorstartEndCount = '';
  public matecolorisPageChanged = false;
  public matecolorTotalRecordCount: any;
  public matecolorloading: boolean;

  public matesupplierstartCountList = 0;
  public matesupplierendCountList = 5;
  public matesupplierstartEndCount = '';
  public matesupplierisPageChanged = false;
  public matesupplierTotalRecordCount: any;
  public matesupplierloading: boolean;

  public state: State = {
    //  sort: [],
    skip: 0,
    take: 5,
  };

  public colorState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };

  public supplierState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };

  public matecolorState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };
  public matesupplierState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };
  thumbnailId: any;
  isFileError = false;
  staticMsgs: any;
  isFilesAdded: boolean = false;

  fabricList = {
    parentName: 'Fabric',

    wovenTypeList:
      [
        {type: '', value: 'Select Material'},
        { "type": "Crepe", "value": "Crepe" },
        { "type": "Chiffon", "value": "Chiffon" },
        { "type": "Georgette", "value": "Georgette" },
        { "type": "Organza", "value": "Organza" },
        { "type": "Voile", "value": "Voile" },
        { "type": "Challis", "value": "Challis" },
        { "type": "Poplin", "value": "Poplin" },
        { "type": "Taffeta", "value": "Taffeta" },
        { "type": "Memory", "value": "Memory" },
        { "type": "Oxford", "value": "Oxford" },
        { "type": "Chambray", "value": "Chambray" },
        { "type": "Canvas", "value": "Canvas" },
        { "type": "Ottoman", "value": "Ottoman" },
        { "type": "Seersucker", "value": "Seersucker" },
        { "type": "Gauge", "value": "Gauge" },
        { "type": "Double Gauge", "value": "Double Gauge" },
        { "type": "Twill", "value": "Twill" },
        { "type": "Denim", "value": "Denim" },
        { "type": "Flannel", "value": "Flannel" },
        { "type": "Gabardine", "value": "Gabardine" },
        { "type": "Melton", "value": "Melton" },
        { "type": "Tweed", "value": "Tweed" },
        { "type": "Satin", "value": "Satin" },
        { "type": "Crepe Satin", "value": "Crepe Satin" },
        { "type": "Charmeuse", "value": "Charmeuse" },
        { "type": "Jacquard", "value": "Jacquard" },
        { "type": "Brocade", "value": "Brocade" },
        { "type": "Clip Jacquard", "value": "Clip Jacquard" },
        { "type": "Corduroy", "value": "Corduroy" },
        { "type": "Velvet", "value": "Velvet" },
        { "type": "Dobby", "value": "Dobby" },
        { "type": "Double Weave", "value": "Double Weave" },
        { "type": "Eyelet", "value": "Eyelet" },
        { "type": "Ripstop", "value": "Ripstop" }
    ],
    knitTypeList: 
    [
      {type: '', value: 'Select Material'},
      { "type": "Jersey", "value": "Jersey" },
      { "type": "Matte Jersey", "value": "Matte Jersey" },
      { "type": "Double Knit", "value": "Double Knit" },
      { "type": "Interlock", "value": "Interlock" },
      { "type": "Rib", "value": "Rib" },
      { "type": "Flatback Rib", "value": "Flatback Rib" },
      { "type": "Waffle", "value": "Waffle" },
      { "type": "Pointelle", "value": "Pointelle" },
      { "type": "Ponte", "value": "Ponte" },
      { "type": "Neoprene", "value": "Neoprene" },
      { "type": "Quilted Knit", "value": "Quilted Knit" },
      { "type": "French Terry", "value": "French Terry" },
      { "type": "Loop Terry", "value": "Loop Terry" },
      { "type": "Fleece", "value": "Fleece" },
      { "type": "Polar Fleece", "value": "Polar Fleece" },
      { "type": "Shepra", "value": "Shepra" },
      { "type": "Tricot", "value": "Tricot" },
      { "type": "Cerpe Knit", "value": "Cerpe Knit" },
      { "type": "Pique", "value": "Pique" },
      { "type": "Mesh", "value": "Mesh" },
      { "type": "Low Guage Knit", "value": "Low Guage Knit" }
  ],
    

    materialTypeList: [
      {type: '', value: 'Select Material'},
      { type: 'Single', value: 'Single' },
      { type: 'Jacquard', value: 'Jacquard' },
      { type: 'Pile', value: 'Pile' },
      { type: 'Cerpe Knit', value: 'Cerpe Knit' },
      { type: 'Pique', value: 'Pique' },
      { type: 'Mesh', value: 'Mesh' },
      { type: 'Double', value: 'Double' },
    ],
    threadCount: [
      {type: '', value: 'Select Threadcount'},
      { type: '100', value: '100' },
      { type: '70', value: '70' },
      { type: '60', value: '60' },
      { type: '65', value: '65' },
      { type: '140', value: '140' },
      { type: '120', value: '120' },
      { type: '80', value: '80' },
      { type: '87', value: '87' },
      { type: '111', value: '111' },
      { type: '78', value: '78' },
      { type: '93', value: '93' },
      { type: '105', value: '105' },
      { type: '50', value: '50' },
      { type: '110', value: '110' },
      { type: '102', value: '102' },
      { type: '75', value: '75' },
      { type: '94', value: '94' },
      { type: '85', value: '85' },
      { type: '95', value: '95' },
      { type: '96', value: '96' },
      { type: '90', value: '90' },
      { type: '102', value: '102' },
    ],
    gSM: [
      {type: '', value: 'Select GSM'},
      { type: '120', value: '120' },
      { type: '100', value: '100' },
      { type: '160', value: '160' },
      { type: '140', value: '140' },
      { type: '270', value: '270' },
      { type: '260', value: '260' },
      { type: '250', value: '250' },
      { type: '230', value: '230' },
      { type: '210', value: '210' },
      { type: '200', value: '200' },
      { type: '190', value: '190' },
      { type: '180', value: '180' },
      { type: '170', value: '170' },
      { type: '160', value: '160' },
      { type: '150', value: '150' },
      { type: '140', value: '140' },
      { type: '130', value: '130' },
      { type: '120', value: '120' },
    ],
    ply: [
      {type: '', value: 'Select Ply'},
      { type: 'Single-Ply', value: 'Single-Ply' },
      { type: 'Two-Ply', value: 'Two-Ply' },
      { type: 'Multi-Ply', value: 'Multi-Ply' },
    ],
    wash: [
      {type: '', value: 'Select Wash'},
      { type: 'Machine Wash : Delicate ', value: 'Machine Wash : Delicate ' },
      { type: 'Machine Wash : Regular ', value: 'Machine Wash : Regular ' },
      { type: 'Dry Clean', value: 'Dry Clean' },
      { type: 'Hand Wash', value: 'Hand Wash' },
    ],
    composition: [
      {type: '', value: 'Select Composition'},
      { type: '80%', value: '80%' },
      { type: '100%', value: '100%' },
      { type: '60%', value: '60%' },
      { type: '50%', value: '50%' },
      { type: '70%', value: '70%' },
      { type: '75%', value: '75%' },
    ],
  };

  leatherList = {
    parentName: 'leather',
    materialTypeList: [
      {type: '', value: 'Select Material'},
      { type: 'Imitation', value: 'Imitation' },
      { type: 'Genuine', value: 'Genuine' },
      { type: 'Top-grain', value: 'Top-grain' },
      { type: 'Full-grain', value: 'Full-grain' },
      { type: 'Nubuck', value: 'Nubuck' },
      { type: 'Lambskin', value: 'Lambskin' },
      { type: 'Pigskin', value: 'Pigskin' },
      { type: 'Snakeskin', value: 'Snakeskin' },
      { type: 'Cowhide', value: 'Cowhide' },
      { type: 'Sheepskin', value: 'Sheepskin' },
      { type: 'Split-grain', value: 'Split-grain' },
      { type: 'Buffalo ', value: 'Buffalo ' },
      { type: 'Goatskin', value: 'Goatskin' },
      { type: 'Alligator', value: 'Alligator' },
      { type: 'Crocodile', value: 'Crocodile' },
      { type: 'Ostrich', value: 'Ostrich' },
      { type: 'Vegetable Tanned', value: 'Vegetable Tanned' },
      { type: 'Chrome Tanned Leather', value: 'Chrome Tanned Leather' },
    ],
    softness: [
      {type: '', value: 'Select Softness'},
      { type: 'Yes', value: 'Yes' },
      { type: 'No', value: 'No' },
    ],
    durability: [
      {type: '', value: 'Select Durability'},
      { type: 'Yes', value: 'Yes' },
      { type: 'No', value: 'No' },
    ],
    weight: [
      {type: '', value: 'Select Weight'},
      { type: '3', value: '3' },
      { type: '4', value: '4' },
      { type: '5', value: '5' },
      { type: '6', value: '6' },
      { type: '7', value: '7' },
    ],
    unitofMeasure: [{type: '', value: 'Select UOM'},{ type: 'oz', value: 'oz' }],
  };

  packagingListSewinLabel = {
    parentName: 'packagingSewinLabel',
    LabelSize: [
      {type: '', value: 'Select Label Size'},
      {
        type: '2 inch by 2 inches',
        value: '2 inch by 2 inches',
      },
      {
        type: '1 inch by 2 inches',
        value: '1 inch by 2 inches',
      },
    ],
    Fabric: [
      {type: '', value: 'Select Fabric'},
      {
        type: '100% cotton',
        value: '100% cotton',
      },
    ],
  };

  packagingListHangTag = {
    parentName: 'packagingLisHangTagt',
    Size: [
      {type: '', value: 'Select Size'},
      {
        type: '2 inch by 2 inches',
        value: '2 inch by 2 inches',
      },
    ],
    Color: [
      {type: '', value: 'Select Color'},
      {
        type: 'White',
        value: 'White',
      },
      {
        type: 'Grey',
        value: 'Grey',
      },
    ],
    Material: [
      {type: '', value: 'Select Material'},
      {
        type: 'Paper',
        value: 'Paper',
      },
    ],
    GSM: [
      {type: '', value: 'Select GSM'},
      {
        type: '200',
        value: '200',
      },
    ],
  };

  trimsList = {
    zipper: {
      parentName: 'trims',
      materialType: [
        {type: '', value: 'Select Material'},
        { type: 'Aluminium ', value: 'Aluminium' },
        { type: 'Brass ', value: 'Brass' },
        { type: 'Plastic  ', value: 'Plastic ' },
        { type: 'NICKEL ', value: 'NICKEL' },
      ],
      Functionality: [
        {type: '', value: 'Select Functionality'},
        { type: 'White', value: 'White' },
        { type: 'Grey', value: 'Grey' },
      ],
      Zippers: [{type: '', value: 'Select Zippers'},{ type: 'Paper', value: 'Paper' }],
    },
    button: {
      materialTypeList: [
        {type: '', value: 'Select Button'},
        { type: 'Plain', value: 'Plain' },
        { type: 'Low Guage Knit', value: 'Low Guage Knit' },
        { type: 'Twill', value: 'Twill' },
      ],
      buttonColor: [
        {type: '', value: 'Select Button Color'},
        { type: 'Red', value: 'Red' },
        { type: 'Blue', value: 'Blue' },
        { type: 'Green', value: 'Green' },
        { type: 'Yellow', value: 'Yellow' },
        { type: 'Orange', value: 'Orange' },
        { type: 'Purple', value: 'Purple' },
        { type: 'Pink', value: 'Pink' },
        { type: 'Brown', value: 'Brown' },
        { type: 'Black', value: 'Black' },
        { type: 'White', value: 'White' },
        { type: 'Gray', value: 'Gray' },
        { type: 'Beige', value: 'Beige' },
        { type: 'Turquoise', value: 'Turquoise' },
        { type: 'Maroon', value: 'Maroon' },
        { type: 'Cyan', value: 'Cyan' },
        { type: 'Magenta', value: 'Magenta' },
        { type: 'Lavender', value: 'Lavender' },
        { type: 'Indigo', value: 'Indigo' },
        { type: 'Teal', value: 'Teal' },
        { type: 'Olive', value: 'Olive' },
        { type: 'Gold', value: 'Gold' },
        { type: 'Silver', value: 'Silver' },
        { type: 'Coral', value: 'Coral' },
        { type: 'Ruby', value: 'Ruby' },
        { type: 'Sapphire', value: 'Sapphire' },
        { type: 'Emerald', value: 'Emerald' },
        { type: 'Topaz', value: 'Topaz' },
        { type: 'Amber', value: 'Amber' },
        { type: 'Charcoal', value: 'Charcoal' },
        { type: 'Tan', value: 'Tan' },
        { type: 'Mahogany', value: 'Mahogany' },
        { type: 'Azure', value: 'Azure' },
        { type: 'Jade', value: 'Jade' },
        { type: 'Ruby', value: 'Ruby' },
        { type: 'Brass', value: 'Brass' },
        { type: 'Plum', value: 'Plum' },
        { type: 'Salmon', value: 'Salmon' },
        { type: 'Mauve', value: 'Mauve' },
        { type: 'Periwinkle', value: 'Periwinkle' },
        { type: 'Cinnamon', value: 'Cinnamon' },
        { type: 'Mint', value: 'Mint' },
        { type: 'Peach', value: 'Peach' },
        { type: 'Slate', value: 'Slate' },
        { type: 'Sky Blue', value: 'Sky Blue' },
        { type: 'Lemon', value: 'Lemon' },
        { type: 'Brick', value: 'Brick' },
        { type: 'Champagne', value: 'Champagne' },
        { type: 'Lemon', value: 'Lemon' },
        { type: 'Turmeric', value: 'Turmeric' },
      ],
      shape: [
        {type: '', value: 'Select Shape'},
        { type: 'Round ', value: 'Round ' },
        { type: 'Rectangle ', value: 'Rectangle ' },
        { type: 'Oval ', value: 'Oval' },
        { type: 'Square ', value: 'Square ' },
        { type: 'Triangle ', value: 'Triangle ' },
      ],
      buttonSize: [
        {type: '', value: 'Select Button Size'},
        { type: '44mm', value: '1 3/4 inches - 72 Linge' },
        { type: '34mm', value: '1 3/8 inches - 54 Linge' },
        { type: '27mm', value: '1 1/8 inches - 44 Linge' },
        { type: '25mm', value: '1 Inch - 40 Linge' },
        { type: '22mm', value: '7/8 Inch - 36 Linge' },
        { type: '20mm', value: '13/16 Inch - 32 Linge' },
        { type: '19mm', value: '3/4 Inch - 30 Linge' },
        { type: '18mm', value: '11/16 Inch - 28 Linge' },
        { type: '15mm', value: '5/8 Inch - 24 Linge' },
        { type: '12mm', value: '1/2 Inch - 20 Linge' },
        { type: '11.5mm', value: '7/16 Inch - 18 Linge' },
        { type: '8mm', value: '5/16 Inch - 14 Linge' },
      ],
      unitofMeasure: [{type: '', value: 'Select UOM'},{ type: 'mm', value: 'mm' }],
    },
    thread: {
      parentName: 'trims',
      materialofthread: [
        {type: '', value: 'Select Material of thread'},
        { type: 'Cotton', value: 'Cotton' },
        { type: 'Nylon', value: 'Nylon' },
        { type: 'Silk', value: 'Silk' },
        { type: 'Metallic', value: 'Metallic' },
      ],
      color: [
        {type: '', value: 'Select Color'},
        { type: 'Grey', value: 'Grey' },
        { type: 'Gold', value: 'Gold' },
        { type: 'White', value: 'White' },
        { type: 'Dark Pink', value: 'Dark Pink' },
        { type: 'Red', value: 'Red' },
        { type: 'Orange', value: 'Orange' },
        { type: 'Maroon', value: 'Maroon' },
        { type: 'Violet', value: 'Violet' },
      ],
      ply: [
        {type: '', value: 'Select Ply'},
        { type: 'Two Ply', value: 'Two Ply' },
        { type: 'Three Ply', value: 'Three Ply' },
      ],
      LengthWeight: [
        {type: '', value: 'Select Length weight'},
        { type: 100, value: 100 },
        { type: 120, value: 120 },
        { type: 140, value: 140 },
        { type: 160, value: 160 },
        { type: 180, value: 180 },
        { type: 200, value: 200 },
        { type: 220, value: 220 },
        { type: 240, value: 240 },
      ],
    },
    rivet: {
      rivetMaterial: [{ type: '', value: 'Select Material' },{ type: 'Stainless Steel', value: 'Stainless Steel' }],
      shape: [
        {type: '', value: 'Select Shape'},
        { type: 'Round', value: 'Round' },
        { type: 'Flat', value: 'Flat' },
        { type: 'Coned', value: 'Coned' },
      ],
      finish: [
        {type: '', value: 'Finish'},
        { type: 'Polished', value: 'Polished' },
        { type: 'Brushed', value: 'Brushed' },
        { type: 'Matte', value: 'Matte' },
      ],
      capDiameter: [
        {type: '', value: 'Cap Diameter'},
        { type: 8, value: 8 },
        { type: 9, value: 9 },
        { type: 10, value: 10 },
        { type: 11, value: 11 },
        { type: 12, value: 12 },
        { type: 13, value: 13 },
        { type: 14, value: 14 },
        { type: 15, value: 15 },
      ],
      unitsofMeasure: [{type: '', value: 'Select UOM'},{ type: 'Coned', value: 'Coned' }],
      baseDiameter: [{type: '', value: 'Select Base Diameter'},{ type: 'Coned', value: 'Coned' }],
      unitsofBaseMeasure: [{type: '', value: 'Select Unit of base measure'},{ type: 'mm', value: 'mm' }],
    },
  };

  showButton: boolean = false;
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredData = filterBy(this.gridDatacol, filter);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridDatacol = process(this.matecolorData, this.state);
  }

  public dataStateChangeColor(state: DataStateChangeEvent): void {
    this.colorState = state;
    var gridView = {
      data: this.dbcolorData,
      total: this.colorTotalRecordCount,
    };
    this.gridDatacol = process(gridView.data, state); //process(this.colorData, this.state);
    this.gridDatacol.total = gridView.total;
  }

  public dataStateChangeSupplier(state: DataStateChangeEvent): void {
    this.supplierState = state;
    var gridView = {
      data: this.dbsupplierData,
      total: this.supplierTotalRecordCount,
    };
    this.gridDatasup = process(gridView.data, state); //process(this.colorData, this.state);
    this.gridDatasup.total = gridView.total;
  }

  public dataStateChangeMateColor(state: DataStateChangeEvent): void {
    this.matecolorState = state;
    var gridView = {
      data: this.matecolorData,
      total: this.matecolorTotalRecordCount,
    };
    this.gridData = process(gridView.data, state); //process(this.colorData, this.state);
    this.gridData.total = gridView.total;
  }

  public dataStateChangeMateSupplier(state: DataStateChangeEvent): void {
    this.matesupplierState = state;
    var gridView = {
      data: this.supplierData,
      total: this.matesupplierTotalRecordCount,
    };
    this.gridSupData = process(gridView.data, state); //process(this.colorData, this.state);
    this.gridData.total = gridView.total;
  }

  public dataStateChangeSup(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridDatasup = process(this.supplierData, this.state);
  }
  public dataSupStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridSupData = process(this.supplierData, this.state);
  }
  options = [{ value: 'Pantone', label: 'Pantone' }];

  onPageChangeColor(e: PageChangeEvent): void {
    this.colorisPageChanged = true;
    this.colorState.skip = e.skip;
    this.colorState.take = e.take;
    this.colorstartCountList = e.skip;
    this.colorendCountList = e.skip + e.take;
    if (this.colorflag == 'matecolor') {
      this.getAllColors('matecolor');
    } else {
      this.getAllColors('supcolor');
    }
  }

  onPageChangeSupplier(e: PageChangeEvent): void {
    this.supplierisPageChanged = true;
    this.supplierState.skip = e.skip;
    this.supplierState.take = e.take;
    this.supplierstartCountList = e.skip;
    this.supplierendCountList = e.skip + e.take;
    this.getAllSupplierData();
  }

  onPageChangeMateColor(e: PageChangeEvent): void {
    this.matecolorisPageChanged = true;
    this.matecolorState.skip = e.skip;
    this.matecolorState.take = e.take;
    this.matecolorstartCountList = e.skip;
    this.matecolorendCountList = e.skip + e.take;
    this.getMaterialColors();
  }

  onPageChangeMateSupplier(e: PageChangeEvent): void {
    this.matesupplierisPageChanged = true;
    this.matesupplierState.skip = e.skip;
    this.matesupplierState.take = e.take;
    this.matesupplierstartCountList = e.skip;
    this.matesupplierendCountList = e.skip + e.take;
    this.getMaterialSuppliers();
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private dataservice: DataService,
    private storageservice: StorageService,
    public userservice: UserService,
    private logger: LogService,
    private aesdecryptservice: AESDecryptService,
    public toastService: ToastService,
    private _lightbox: Lightbox,
    private store: Store<any>,
    
  ) {
    this.documentsForm = this.fb.group({
      documents: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.staticMsgs = appStaticMsg.data[0];
    this.dataservice.AddClientLog();
    const source = interval(1.2e8);
    source.subscribe((val) => this.dataservice.AddClientLog());
    this.notfoundImg = '../../../../assets/images/notfound/NotFound.png';
    //  this.isThumbnailAdded = true;
    this.colorSupplierId = '';
    if (this.storageservice.getMaterialId()) {
      this.isCreateMode = false;
      if (this.storageservice.getEditedMaterialDetailsId()) {
        this.storageservice.removeMaterialDetailsId();
        this.editMaterial();
      } else {
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.getMaterialDetails();
      }
    } else {
      this.getMaterialClassification('Material');
      this.isCreateMode = true;
      // this.isEditMode = false;
      // this.isEditingMode = false;
      // this.isViewingMode = false;
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }
    this.loadSelectPicker();
    this.initForm();
    this.sharedService.sendClickEvent();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
      this.refreshSelect();
    }, 10);
  }

  get f1() {
    return this.materialColorForm.controls;
  }
  get fa() {
    return this.f1.formArray as FormArray;
  }

  getError(err: any) {
    if (err.error.message === 'Network error communicating with endpoint') {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
    } else if (err.error.message === 'The incoming token has expired') {
      this.sessionTimedOut();
      this.userservice.logout();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.showLoader = false;
    } else if (err.error.message === 'Unauthorized') {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
      this.showLoader = false;
    } else {
      this.toastService.showError(this.staticMsgs.unResponsive.error);
      // this.error(err.message);
    }
    this.showLoader = false;
  }

  getDocumentClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store
      .pipe(
        select((state) => state),
        take(2),
        skip(1)
      )
      .subscribe((classificationData) => {
        if (classificationData.common.error === '') {
          if (classificationData.common.message['item2'] === 'Success') {
            this.docclassification =
              classificationData.common.entities[
                classificationData.common.ids[0]
              ].items;
            this.logger.info(
              this.staticMsgs.matDetail.docLoad,
              'MaterialDetails'
            );
            this.showLoader = false;
            if (this.editedMaterialData.documents) {
              if (this.editedMaterialData.documents.length > 0) {
                this.editedFileData = [];
                this.removedDocIds = '';
                this.isFilesAdded = true;
                let control = <FormArray>this.documentsForm.controls.documents;
                if (control.length > 0) {
                  this.documentsForm = this.fb.group({
                    documents: this.fb.array([]),
                  });
                  this.showLoader = false;
                }
                let editeddata = JSON.stringify(
                  this.editedMaterialData.documents
                );
                let data = JSON.parse(editeddata);
                this.reloadDocumentFormData(data);
                this.showLoader = false;
              }
            }
          } else {
            this.showLoader = false;
            // this.error('Failed:' + classificationData.common.message?.item1);
            this.toastService.showError(classificationData.common.message?.item1);
            this.logger.error(
              classificationData.common.message?.item1,
              'MaterialDetails'
            );
          }
        } else {
          this.logger.error(
            classificationData.common.error.error.message,
            'MaterialDetails'
          );
          this.getError(classificationData.common.error);
          this.showLoader = false;
        }
      });

    /* this.dataservice.getClassification(module).subscribe(response => {
     if (response.body['item2'] === "Success") {
       this.docclassification = response.body['item1'][0]["items"];
       this.logger.info("Document classification loaded successfully", "MaterialDetails");
       //console.log(JSON.stringify(this.documentClassificationData ))
       // this.refreshDocTree();
       this.showLoader = false;
     }
     else {
       this.error(response.body['item1']);
       this.logger.error(response.body['item1'], "MaterialDetails");
       this.showLoader = false;
     }
   }, err => {
     this.logger.error(err.error.message, "MaterialDetails");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
     } else if (err.error.message === "The incoming token has expired") {
       this.sessionTimedOut();
     } else if (err.error.message === "Unauthorized") {
       this.userservice.error();
     }
     else {
       this.error(err.message);
       this.logger.error(err.message, "MaterialDetails");
     }
     this.showLoader = false;
   }); */
  }

  getAllColors(colorflag) {
    this.colorloading = true;
    this.colorstartEndCount =
      this.colorstartCountList + '-' + this.colorendCountList;
    this.dataservice
      .getDetailsByModuleName('Colours', this.colorstartEndCount)
      .subscribe((response) => {
        if (response['body']['item2'] === 'Success') {
          if (
            response['body']['item1'].length > 0 &&
            !response['body']['item2'].toLowerCase().includes('error')
          ) {
            response['body']['item1'].forEach((x) => {
              x.name = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.name
              );
              x.classification = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.classification
              );
              x.colorStandard = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.colorStandard
              );

              if (x.hexcode) {
                x.hexcode = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  x.hexcode
                );
              } else if (x.thumbnailFiles) {
                x.thumbnailFiles['thumbnail'] =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    x.thumbnailFiles['thumbnail']
                  );
              }
              x.createdUser.firstName = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.createdUser.firstName
              );
              x.createdUser.lastName = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.createdUser.lastName
              );
              x.CreatedbyName = x.createdUser.firstName;
              x.status = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.status
              );
            });
          }
          this.logger.info(
            this.staticMsgs.matDetail.colorList,
            'MaterialDetails'
          );
          this.dbcolorData = response['body']['item1'];
          this.colorTotalRecordCount = response['body']['item3'];

          if (colorflag == 'matecolor') {
            this.colorflag = 'matecolor';

            if (this.matcolorsIds.length > 0) {
              this.matcolorsIds.forEach((vl) => {
                this.removeColorElement(vl);
              });
            }
          }

          if (colorflag == 'supcolor') {
            this.colorflag = 'supcolor';
            var supplier = this.supplierData.filter(
              (x, i) => x.id === this.colorSupplierId
            );
            if (supplier && supplier.length > 0) {
              if (supplier[0].colours) {
                supplier[0].colours.forEach((vl) => {
                  this.removeColorElement(vl.id);
                });
              }
            }
          }

          var gridView = {
            data: this.dbcolorData,
            total: this.colorTotalRecordCount,
          };
          this.gridDatacol = gridView; //process(this.colorData, this.state);
          this.colorloading = false;
        } else {
          this.colorloading = false;
          // this.error('Failed:' + response['body']['item1']);
          this.toastService.showError(response['body']['item1']);
          this.logger.error(response['body']['item1'], 'MaterialDetails');
        }
      });

    /* this.dataservice.getColor(this.colorstartEndCount).subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.logger.info("All color list loaded successfully", "MaterialDetails");

        for (let i = 0; i < response.body['item1'].length; i++) {
          response.body['item1'][i]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['name']);
          response.body['item1'][i]['classification'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['classification']);
          response.body['item1'][i]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['colorStandard']);
          response.body['item1'][i].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].createdBy.firstName);
          response.body['item1'][i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].status);
          if (response.body['item1'][i]['hexcode']) {
            response.body['item1'][i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['hexcode']);

          } else if (response.body['item1'][i]['thumbnailFiles']) {
            response.body['item1'][i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['thumbnailFiles']['thumbnail']);
            response.body['item1'][i]['thumbnailFiles']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['thumbnailFiles']['name']);
          }
        }

        this.dbcolorData = response.body['item1'];
        this.colorTotalRecordCount = response.body['item3'];

        if (colorflag == 'matecolor') {
          this.colorflag = 'matecolor';

          if (this.matcolorsIds.length > 0) {
            this.matcolorsIds.forEach((vl) => {
              this.removeColorElement(vl);
            });
          }
        }

        if (colorflag == 'supcolor') {
          this.colorflag = 'supcolor';
          var supplier = this.supplierData.filter((x, i) => x.id === this.colorSupplierId);
          if (supplier) {
            if (supplier[0].colours) {
              supplier[0].colours.forEach((vl) => {
                this.removeColorElement(vl.id);
              });
            }
          }
        }

        var gridView = {
          data: this.dbcolorData,
          total: this.colorTotalRecordCount,
        };
        this.gridDatacol = gridView; //process(this.colorData, this.state);
        this.colorloading = false;
      }
      else {
        this.error(response.body['item1']);
        this.logger.error(response.body['item1'], "MaterialDetails");
        this.colorloading = false;
      }
    }, err => {
      this.colorloading = false;
      this.logger.error(err.error.message, "MaterialDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialDetails");
      }

    }); */
  }

  removeColorElement(element: string) {
    this.dbcolorData.forEach((value, index) => {
      if (value.id == element) {
        this.dbcolorData = JSON.parse(JSON.stringify(this.dbcolorData));
        this.dbcolorData.splice(index, 1);
      }
    });
  }

  getAllSupplierData() {
    this.supplierloading = true;
    this.supplierstartEndCount =
      this.supplierstartCountList + '-' + this.supplierendCountList;
    this.dataservice
      .getDetailsByModuleName('Suppliers', this.supplierstartEndCount)
      .subscribe(
        (response) => {
          if (response['body']['item2'] === 'Success') {
            response['body']['item1'].forEach((x) => {
              if (x.contact) {
                x.contact.forEach((y) => {
                  y.country = this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    y.country
                  );
                  y.state = this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    y.state
                  );
                });
              }
              x.classification = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.classification
              );
              x.name = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.name
              );
              x.phoneNo = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.phoneNo
              );
              x.phoneNumber = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.phoneNumber
              );
              x.email = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.email
              );
              x.auditStatus = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.auditStatus
              );
              x.sequence = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.sequence
              );
              if (x.createdUser != null) {
                x.createdUser.firstName = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  x.createdUser.firstName
                );
                x.CreatedbyName = x.createdUser.firstName;
              }
            });
            this.dbsupplierData = response['body']['item1'];
            this.supplierData = this.dbsupplierData;
            this.supplierTotalRecordCount = response['body']['item3'];
            if (this.matsuppliersIds.length > 0) {
              this.matsuppliersIds.forEach((vl) => {
                this.removeSupplierElement(vl);
              });
            }

            var gridView = {
              data: this.dbsupplierData,
              total: this.supplierTotalRecordCount,
            };
            this.gridDatasup = gridView; //process(this.dbsupplierData, this.state);
            this.supplierloading = false;
          } else {
            this.supplierloading = false;
            // this.error('Failed:' + response['body']['item1']);
            this.toastService.showError(response['body']['item1']);
            this.logger.error(response['body']['item1'], 'MaterialDetails');
          }
        },
        (err) => {
          this.getError(err);
          this.logger.error(err.error.message, 'MaterialDetails');
          this.supplierloading = false;
        }
      );
  }

  removeSupplierElement(element: string) {
    this.dbsupplierData.forEach((value, index) => {
      if (value.id == element) {
        this.dbsupplierData = JSON.parse(JSON.stringify(this.dbsupplierData));
        this.dbsupplierData.splice(index, 1);
      }
    });
  }

  onColRemove(index, dataItem) {
    Swal.fire({
      title: this.staticMsgs.matDetail.deleteColor,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.matDetail.deleteit,
    }).then((result) => {
      if (result.value) {
        let materialid = this.storageservice.getMaterialId();
        const formData = new FormData();
        formData.append('materialid', materialid);
        formData.append('coloursdelete', dataItem.id);
        //console.log(formData);
        this.showLoader1 = true;
        this.store.dispatch(
          new materialActionTypes.CreateMaterialColor(formData)
        );
        this.store
          .pipe(
            select((state) => state),
            take(2),
            skip(1)
          )
          .subscribe((materialColorLibrary) => {
            if (materialColorLibrary.material.error === '') {
              if (
                materialColorLibrary.material.message['item2'] === 'Success'
              ) {
                this.cards = materialColorLibrary.material.message['item1'];
                this.editedMaterialData =
                  materialColorLibrary.material.message['item1'];
                if (this.editedMaterialData.colours) {
                  this.refreshSelect();
                }
                this.showLoader1 = false;
                // this.success(this.staticMsgs.matDetail.colorDelete1);
                this,this.toastService.showSuccess(this.staticMsgs.matDetail.colorDelete1);
                this.logger.info(
                  this.staticMsgs.matDetail.colorDelete,
                  'MaterialDetails'
                );
                this.getMaterialColors();
              } else {
                this.showLoader1 = false;
                // this.error(
                //   'Failed:' + materialColorLibrary.material.message['item1']
                // );
                this.toastService.showError(materialColorLibrary.material.message['item1']);
                this.logger.error(
                  materialColorLibrary.material.message['item1'],
                  'MaterialDetails'
                );
              }
            } else {
              this.logger.error(
                materialColorLibrary.material.error.error.message,
                'MaterialDetails'
              );
              this.getError(materialColorLibrary.material.error);
              this.showLoader1 = false;
            }
          });

        /* this.dataservice.addMaterialColor(formData).subscribe(response => {
          if (response.status === 200) {
            if (response.body['item2'] === "Success") {
              this.showLoader1 = false;
              this.success("Color Deleted Successfully!");
              this.logger.info("Color Deleted Successfully", "MaterialDetails");
              this.getMaterialColors();

              // this.cards = response.body.item1;
              // this.editedMaterialData = response.body['item1'];
              // if (this.editedMaterialData.colours) {
              //   this.matecolorData = this.editedMaterialData.colours
              //   for (let i = 0; i < this.matecolorData.length; i++) {
              //     this.matecolorData[i]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['name']);
              //     //this.matecolorData[i]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.matecolorData[i]['classification']);
              //     this.matecolorData[i]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['colorStandard']);
              //     this.matecolorData[i].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].createdBy.firstName);
              //     this.matecolorData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].status);


              //     if (this.matecolorData[i]['hexcode']) {
              //       this.matecolorData[i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['hexcode']);

              //     } else {
              //       this.matecolorData[i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['thumbnailFiles']['thumbnail']);

              //     }


              //   }
              //   var gridView = {
              //     data: this.matecolorData,
              //     total: this.matecolorTotalRecordCount,
              //   };
              //   this.gridData = gridView;//process(this.matecolorData, this.state);

              //   this.refreshSelect();
              // }


            }
            else {
              this.error(response.body['item1']);
              this.logger.error(response.body['item1'], "MaterialDetails");
              this.showLoader1 = false;
            }
          }
        }
          , err => {
            this.logger.error(err.error.message, "MaterialDetails");
            if (err.error.message === "Network error communicating with endpoint") {
              this.error(err.error.message);
            } else if (err.error.message === "The incoming token has expired") {
              this.sessionTimedOut();
            } else if (err.error.message === "Unauthorized") {
              this.userservice.error();
            }
            else {
              this.error(err.message);
              this.logger.error(err.message, "MaterialDetails");
            }
            this.showLoader1 = false;
          }); */

        // reset all rows back to edit mode
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  onSupRemove(index, dataItem) {
    Swal.fire({
      title: this.staticMsgs.matDetail.matSupp,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.matDetail.deleteit,
    }).then((result) => {
      if (result.value) {
        let materialid = this.storageservice.getMaterialId();
        const formData = new FormData();
        formData.append('materialid', materialid);
        formData.append('suppliersdelete', dataItem.id);
        //console.log(formData);
        this.showLoader1 = true;
        this.store.dispatch(
          new materialActionTypes.CreateMaterialSupplier(formData)
        );
        this.store
          .pipe(
            select((state) => state),
            take(2),
            skip(1)
          )
          .subscribe((materialSupplier) => {
            if (materialSupplier.material.error === '') {
              if (materialSupplier.material.message['item2'] === 'Success') {
                this.editedMaterialData =
                  materialSupplier.material.message['item1'];
                this.cards = materialSupplier.material.message.item1;
                this.getMaterialSuppliers();
                this.showLoader1 = false;
                // this.success(this.staticMsgs.matDetail.suppDelete1);
                this.toastService.showSuccess(this.staticMsgs.matDetail.suppDelete1);
                this.logger.info(
                  this.staticMsgs.matDetail.suppDelete,
                  'MaterialDetails'
                );
              } else {
                this.showLoader1 = false;
                // this.error(
                //   'Failed:' + materialSupplier.material.message['item1']
                // );
                this.toastService.showError(materialSupplier.material.message['item1']);
                this.logger.error(
                  materialSupplier.material.message['item1'],
                  'MaterialDetails'
                );
              }
            } else {
              this.logger.error(
                materialSupplier.material.error.error.message,
                'MaterialDetails'
              );
              this.getError(materialSupplier.material.error);
              this.showLoader1 = false;
            }
          });

        /* this.dataservice.addMaterialSupplier(formData).subscribe(response => {
          if (response.status === 200) {
            if (response.body['item2'] === "Success") {

              this.showLoader1 = false;
              this.success("Supplier Deleted Successfully!");
              this.logger.info("Supplier Deleted Successfully", "MaterialDetails");
              this.getMaterialSuppliers();

              // this.editedMaterialData = response.body['item1'];
              // this.cards = response.body.item1;
              // if (this.editedMaterialData.suppliers) {
              //   this.supplierData = this.editedMaterialData.suppliers;
              //   for (let i = 0; i < this.supplierData.length; i++) {

              //     this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
              //     this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
              //     this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
              //     // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
              //     // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
              //     this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
              //     //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
              //     this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
              //     // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
              //     if (this.supplierData[i].colours != null) {
              //       for (let j = 0; j < this.supplierData[i].colours.length; j++) {
              //         this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
              //         //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
              //         this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
              //         this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
              //         this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);

              //         if (this.supplierData[i].colours[j]['hexcode']) {
              //           this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);

              //         } else {
              //           this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);

              //         }


              //       }
              //     }

              //   }
              //   this.gridSupData = process(this.supplierData, this.state);
              // }

            }
            else {
              //console.log(response)
              //alert(response.body['item2'])
              this.error(response.body['item1']);
              this.logger.error(response.body['item1'], "MaterialDetails");
              this.showLoader1 = false;
            }
          }
        }, err => {
          this.logger.error(err.error.message, "MaterialDetails");
          if (err.error.message === "Network error communicating with endpoint") {
            this.error(err.error.message);
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
          } else if (err.error.message === "Unauthorized") {
            this.userservice.error();
          }
          else {
            this.error(err.message);
            this.logger.error(err.message, "MaterialDetails");
          }
          this.showLoader1 = false;
        }); */
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  onSupColorRemove(id, dataItem) {
    Swal.fire({
      title: this.staticMsgs.matDetail.matSuppCol,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.matDetail.deleteit,
    }).then((result) => {
      if (result.value) {
        let materialid = this.storageservice.getMaterialId();
        const formData = new FormData();
        formData.append('materialid', materialid);
        formData.append('supplierid', this.colorSupplierId);
        formData.append('colourtosdelete', dataItem.id);
        //console.log(formData);
        this.showLoader1 = true;
        this.store.dispatch(
          new materialActionTypes.CreateMaterialSupplierColor(formData)
        );
        this.store
          .pipe(
            select((state) => state),
            take(2),
            skip(1)
          )
          .subscribe((materialSupplierColor) => {
            if (materialSupplierColor.material.error === '') {
              if (
                materialSupplierColor.material.message['item2'] === 'Success'
              ) {
                this.editedMaterialData =
                  materialSupplierColor.material.message['item1'];
                this.cards = materialSupplierColor.material.message.item1;
                if (this.editedMaterialData.suppliers) {
                  this.supplierData = this.editedMaterialData.suppliers;
                  this.gridSupData = process(this.supplierData, this.state);
                }
                // this.success(this.staticMsgs.matDetail.suppColor1);
                this.toastService.showSuccess(this.staticMsgs.matDetail.suppColor1);
                this.logger.info(
                  this.staticMsgs.matDetail.suppColor,
                  'MaterialDetails'
                );
                this.showLoader1 = false;
              } else {
                this.showLoader1 = false;
                // this.error(
                //   'Failed:' + materialSupplierColor.material.message['item1']
                // );
                this.toastService.showError(materialSupplierColor.material.message['item1']);
                this.logger.error(
                  materialSupplierColor.material.message['item1'],
                  'MaterialDetails'
                );
              }
            } else {
              this.logger.error(
                materialSupplierColor.material.error.error.message,
                'MaterialDetails'
              );
              this.getError(materialSupplierColor.material.error);
              this.showLoader1 = false;
            }
          });

        /* this.dataservice.addSupplierColor(formData).subscribe(response => {
          if (response.status === 200) {
            if (response.body['item2'] === "Success") {
              this.editedMaterialData = response.body['item1'];
              this.cards = response.body.item1;
              if (this.editedMaterialData.suppliers) {
                this.supplierData = this.editedMaterialData.suppliers;
                for (let i = 0; i < this.supplierData.length; i++) {

                  this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
                  this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
                  this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
                  // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
                  // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
                  this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
                  //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
                  this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
                  // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
                  if (this.supplierData[i].colours != null) {
                    for (let j = 0; j < this.supplierData[i].colours.length; j++) {
                      this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
                      //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
                      this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
                      this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
                      this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);

                      if (this.supplierData[i].colours[j]['hexcode']) {
                        this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);

                      } else {
                        this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);

                      }


                    }
                  }

                }
                this.gridSupData = process(this.supplierData, this.state);
              }
              this.success("Supplier Color Deleted Successfully!");
              this.logger.info("Color classification loaded successfully", "MaterialDetails");
              this.showLoader1 = false;

            }
            else {
              //console.log(response)
              //alert(response.body['item2'])
              this.error(response.body['item2']);
              this.logger.error(response.body['item2'], "MaterialDetails");
              this.showLoader1 = false;
            }
          }
        }, err => {
          this.logger.error(err.error.message, "MaterialDetails");

          if (err.error.message === "Network error communicating with endpoint") {
            this.error(err.error.message);
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
          } else if (err.error.message === "Unauthorized") {
            this.userservice.error();
          }
          else {
            this.error(err.message);
            this.logger.error(err.message, "MaterialDetails");

          }
          this.showLoader1 = false;
        }); */

        // reset all rows back to edit mode
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  createColorRea() {
    this.showLoader1 = true;
    let controlleng = this.colorId.toString();
    if (controlleng == '') {
      // this.error('please select color');
      this.toastService.showError('please select color');
      this.showLoader1 = false;
      return false;
    }
    let controlleng1 = controlleng.split(',');
    let colcount = controlleng1.length;
    let materialid = this.storageservice.getMaterialId();
    //// alert(controlleng1.length)
    const formData = new FormData();
    formData.append('materialid', materialid);
    formData.append('colpickcount', colcount.toString());
    for (let i = 0; i < controlleng1.length; i++) {
      formData.append('MCcolourid' + '[' + i + ']', this.colorId[i]);
      formData.append('colPickTag' + '[' + i + ']', 'Add');
    }

    if (this.colorSupplierId != '') {
      const formData = new FormData();
      formData.append('materialid', materialid);
      formData.append('supplierid', this.colorSupplierId);
      formData.append('colourcount', colcount.toString());
      for (let i = 0; i < controlleng1.length; i++) {
        formData.append('colourid' + '[' + i + ']', this.colorId[i]);
        formData.append('coltag' + '[' + i + ']', 'Add');
      }
      this.showLoader1 = true;
      this.store.dispatch(
        new materialActionTypes.CreateMaterialSupplierColor(formData)
      );
      this.colorId = [];
      this.store
        .pipe(
          select((state) => state),
          take(2),
          skip(1)
        )
        .subscribe((materialSupplierColor) => {
          if (materialSupplierColor.material.error === '') {
            if (materialSupplierColor.material.message['item2'] === 'Success') {
              this.cards = materialSupplierColor.material.message.item1;
              this.editedMaterialData =
                materialSupplierColor.material.message['item1'];
              if (this.editedMaterialData.suppliers) {
                this.supplierData = this.editedMaterialData.suppliers;
                this.gridSupData = process(this.supplierData, this.state);
              }
              // this.success(this.staticMsgs.matDetail.addedColor);
              this.toastService.showSuccess(this.staticMsgs.matDetail.addedColor);
              this.logger.info(
                this.staticMsgs.matDetail.addedColor1,
                'MaterialDetails'
              );
              this.showLoader1 = false;
              $('.chkbxqcolor').each(function () {
                $(this).prop('checked', false);
              });
            } else {
              this.showLoader1 = false;
              // this.error(
              //   'Failed:' + materialSupplierColor.material.message['item1']
              // );
              this.toastService.showError(materialSupplierColor.material.message['item1']);
              this.logger.error(
                materialSupplierColor.material.message['item1'],
                'MaterialDetails'
              );
            }
          } else {
            this.logger.error(
              materialSupplierColor.material.error.error.message,
              'MaterialDetails'
            );
            this.getError(materialSupplierColor.material.error);
            this.showLoader1 = false;
          }
        });

      /* this.dataservice.addSupplierColor(formData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {

            this.cards = response.body.item1;
            this.editedMaterialData = response.body['item1'];
            if (this.editedMaterialData.suppliers) {
              this.supplierData = this.editedMaterialData.suppliers;
              for (let i = 0; i < this.supplierData.length; i++) {

                this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
                this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
                this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
                // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
                // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
                this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
                //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
                this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
                // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
                if (this.supplierData[i].colours != null) {
                  for (let j = 0; j < this.supplierData[i].colours.length; j++) {
                    this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
                    //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
                    this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
                    this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
                    this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);

                    if (this.supplierData[i].colours[j]['hexcode']) {
                      this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);

                    } else {
                      this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);

                    }


                  }
                }

              }
              this.gridSupData = process(this.supplierData, this.state);

            }
            this.success("Added to Supplier Color Successfully!");
            this.logger.info("Supplier Color Added Successfully", "MaterialDetails");
            this.showLoader1 = false;
            $('.chkbxqcolor').each(function () {
              $(this).prop('checked', false);
            });
          }
          else {
            //console.log(response)
            //alert(response.body['item2'])
            this.error(response.body['item2']);
            this.logger.error(response.body['item2'], "MaterialDetails");
            this.showLoader1 = false;
          }
        }
      }, err => {
        this.logger.error(err.error.message, "MaterialDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.showLoader1 = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.showLoader1 = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.showLoader1 = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialDetails");

        }
        this.showLoader1 = false;
      }); */
    } else {
      this.colorId = [];
      this.showLoader1 = true;
      this.store.dispatch(
        new materialActionTypes.CreateMaterialColor(formData)
      );
      this.store
        .pipe(
          select((state) => state),
          take(2),
          skip(1)
        )
        .subscribe((materialColorLibrary) => {
          if (materialColorLibrary.material.error === '') {
            if (materialColorLibrary.material.message['item2'] === 'Success') {
              this.cards = materialColorLibrary.material.message['item1'];
              this.editedMaterialData =
                materialColorLibrary.material.message['item1'];
              if (this.editedMaterialData.colours) {
                this.refreshSelect();
              }
              // this.success(this.staticMsgs.matDetail.matColor1);
              this.toastService.showSuccess(this.staticMsgs.matDetail.matColor1);
              this.logger.info(
                this.staticMsgs.matDetail.matColor1,
                'MaterialDetails'
              );
              this.showLoader1 = false;
              $('.chkbxqcolor').each(function () {
                $(this).prop('checked', false);
              });
              this.getMaterialColors();
            } else {
              this.showLoader1 = false;
              // this.error(
              //   'Failed:' + materialColorLibrary.material.message['item1']
              // );
              this.toastService.showError(materialColorLibrary.material.message['item1']);
              this.logger.error(
                materialColorLibrary.material.message['item1'],
                'MaterialDetails'
              );
            }
          } else {
            this.logger.error(
              materialColorLibrary.material.error.error.message,
              'MaterialDetails'
            );
            this.getError(materialColorLibrary.material.error);
            this.showLoader1 = false;
          }
        });
      /* this.dataservice.addMaterialColor(formData).subscribe(response => {
        // //console.log(response);
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          this.success("Material color Created Successfully!");
          this.logger.info("Material color Created Successfully", "MaterialDetails");
          $('.chkbxqcolor').each(function () {
            $(this).prop('checked', false);
          });
          this.getMaterialColors();

          // //console.log(response.body.item1);
          // this.cards = response.body.item1;
          // this.editedMaterialData = response.body['item1'];
          // if (this.editedMaterialData.colours) {
          //   this.matecolorData = this.editedMaterialData.colours
          //   for (let i = 0; i < this.matecolorData.length; i++) {
          //     this.matecolorData[i]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['name']);
          //     //this.matecolorData[i]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.matecolorData[i]['classification']);
          //     this.matecolorData[i]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['colorStandard']);
          //     this.matecolorData[i].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].createdBy.firstName);
          //     this.matecolorData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].status);

          //     if (this.matecolorData[i]['hexcode']) {
          //       this.matecolorData[i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['hexcode']);

          //     } else {
          //       this.matecolorData[i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['thumbnailFiles']['thumbnail']);

          //     }
          //   }
          //   var gridView = {
          //     data: this.matecolorData,
          //     total: this.matecolorTotalRecordCount,
          //   };
          //   this.gridData = gridView;//process(this.matecolorData, this.state);

          // }

        }
        else {
          this.showLoader1 = false;
        }
        this.showLoader1 = false;
      }, err => {
        this.logger.error(err.error.message, "MaterialDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialDetails");
        }
        this.showLoader1 = false;
      }); */
    }
  }

  /*** Color display table End */
  // Method to initialise the Material form.
  initForm() {
    this.showLoader = true;
    this.materialForm = this.fb.group({
      // materialclassification: ['', [Validators.required]],
      // materialid: [{ value: '', disabled: true }],
      // materialseq: [''],
      // materialname: ['', [Validators.pattern('^[a-zA-Z0-9 ]{1,50}$')]],
      // cuttablewidth: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // widthuom: [''],
      // weightgsm: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // subtype: ['',[Validators.required]],
      // size: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // width: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // sizeuom: [''],
      // content: [''],
      // substabce: ['', [Validators.maxLength(20),,Validators.pattern('^[a-zA-Z0-9% ]{1,50}$')]],
      // comments: ['', [Validators.maxLength(500)]],
      // substanceuom: [''],
      // rawhide: [''],
      // cost: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // trimcost: ['', [Validators.maxLength(20)]],
      // fabriccomp: ['']
      materialclassification: [''],
      materialid: [{ value: '', disabled: true }],
      materialseq: [''],
      materialname: ['', [Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      cuttablewidth: [''],
      widthuom: [''],
      weightgsm: [''],
      fabriccomp: [''],
      subtype: [''],
      size: [''],
      width: [''],
      sizeuom: [''],
      content: [''],
      substabce: [''],
      comments: [''],
      substanceuom: [''],
      rawhide: [''],
      cost: [''],
      trimcost: [''],
      fabricType: [''],
      threadCount: [''],
      gSM: [''],
      ply: [''],
      wash: [''],
      composition: [''],
      finish: [''],
      materialTypeList: [''],
      softness: [''],
      durability: [''],
      weight: [''],
      unitofMeasure: [''],
      sewLabelSize: [''],
      sewFabric: [''],
      hangSize: [''],
      hangColor: [''],
      hangMaterial: [''],
      hangWeightgsm: [''],
      zipType: [''],
      zipFunctionality: [''],
      zipper: [''],
      buttonType: [''],
      buttonColor: [''],
      buttonShape: [''],
      buttonSize: [''],
      buttonnuom: [''],
      threadMot: [''],
      threadColor: [''],
      threadPly: [''],
      threadLength: [''],
      rivetMat: [''],
      rivetShape: [''],
      rivetFinish: [''],
      rivetCapDiameter: [''],
      rivetuom: [''],
      rivetBaseDiameter: [''],
      rivetBaseuom: [''],
    });
    this.showLoader = false;
  }

  onChange(id: string, val: boolean) {
    if (val == true) {
      this.colorId.push(id);
      //console.log(this.colorId);
    } else {
      var index = this.colorId.indexOf(id);
      this.colorId.splice(index, 1);
      //console.log(this.colorId);
    }
  }

  public listItems: Array<{ text: string; value: string }> = [
    { text: 'Active', value: 'Active' },
    { text: 'Inactive', value: 'Inactive' },
  ];

  get f() {
    return this.materialForm.controls;
  }

  numberOnly(e: any) {
    var regex = new RegExp('^[0-9.]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characterOnly(e: any) {
    var regex = new RegExp('^[a-zA-Z0-9-_ ]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  addDecimal(value: any) {
    switch (value) {
      case 'cuttablewidth':
        if (
          this.f.cuttablewidth.value.includes('.') ||
          this.f.cuttablewidth.value.length <= 0
        ) {
        } else {
          this.f.cuttablewidth.setValue(this.f.cuttablewidth.value + '.00');
        }
        break;

      case 'weight':
        if (
          !this.f.weightgsm.value.includes('.') ||
          this.f.weightgsm.value.length! <= 0
        ) {
          this.f.weightgsm.setValue(this.f.weightgsm.value + '.00');
        }
        break;

      case 'size':
        if (this.f.size.value.includes('.') || this.f.size.value.length <= 0) {
        } else {
          this.f.size.setValue(this.f.size.value + '.00');
        }
        break;

      case 'width':
        if (
          this.f.width.value.includes('.') ||
          this.f.width.value.length <= 0
        ) {
        } else {
          this.f.width.setValue(this.f.width.value + '.00');
        }
      case 'substabce':
        if (
          this.f.substabce.value.includes('.') ||
          this.f.substabce.value.length <= 0
        ) {
        } else {
          this.f.substabce.setValue(this.f.substabce.value + '.00');
        }
    }
  }

  getMaterialClassification(module: string) {
    this.loadSelectPicker();
    this.showLoader = true;
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store
      .pipe(
        select((state) => state),
        take(2),
        skip(1)
      )
      .subscribe((classificationData) => {
        if (classificationData.common.error === '') {
          if (classificationData.common.message['item2'] === 'Success') {
            this.loadSelectPicker();
            setTimeout(() => {
              $('.selectpicker').selectpicker();
            }, 10);
            this.materialClassificationData =
              classificationData.common.message['item1'][0]['items'];
            this.logger.info(
              this.staticMsgs.matDetail.matClass,
              'MaterialDetails'
            );
            this.refreshTree();
            if (this.isEditingMode) {
              this.setMaterialTreeValue(
                this?.editedMaterialData['classification']
              );
            }
            this.showLoader = false;
          } else {
            this.refreshTree();
            this.showLoader = false;
            // this.error('Failed:' + classificationData.common.message?.item1);
            this.toastService.showError(classificationData.common.message?.item1);
            this.logger.error(
              classificationData.common.message?.item1,
              'MaterialDetails'
            );
          }
        } else {
          this.logger.error(
            classificationData.common.error.error.message,
            'MaterialDetails'
          );
          this.getError(classificationData.common.error);
          this.showLoader = false;
        }
      });
  }

  getMaterialDetails() {
    this.showLoader1 = true;
    //console.log(this.storageservice.getEditedMaterialId())
    this.store.dispatch(
      new materialActionTypes.LoadMaterial(this.storageservice.getMaterialId())
    );
    this.store
      .pipe(
        select((state) => state),
        take(2),
        skip(1)
      )
      .subscribe((materialDetails) => {
        if (materialDetails.material.error === '') {
          if (materialDetails.material.message['item2'] === 'Success') {
            this.editedMaterialData = materialDetails.material.message['item1'];
            this.logger.info(
              materialDetails.material.message['item1']['name'] +
                ' loaded successfully',
              'MaterialDetails'
            );
            this.showLoader1 = false;
            this.initForm();
            this.getMaterialClassification('Material');
            this.materialForm
              .get('materialclassification')
              .setValue(
                materialDetails.material.message['item1']['classification']
              );
            this.materialclassificationValue =
              materialDetails.material.message['item1']['classification'];
            this.parentClassification =
              materialDetails.material.message['item1']['baseClassification'];
            if (
              this.parentClassification === 'Trims' ||
              this.parentClassification === 'Product Packaging'
            ) {
              this.materialForm.get('subtype').enable();
              setTimeout(() => {
                $('.selectpicker').selectpicker({
                  noneSelectedText: 'Select classification',
                });
              }, 10);
              if (this.parentClassification === 'Trims') {
                this.materialForm.get('content').enable();
                this.materialForm.get('sizeuom').enable();
                this.materialForm.get('size').enable();
                this.materialForm.get('width').enable();
                setTimeout(() => {
                  $('.selectpicker').selectpicker({
                    noneSelectedText: 'Select classification',
                  });
                }, 10);
              }
            }
            if (this.parentClassification === 'Material') {
              this.materialForm.get('substabce').enable();
              this.materialForm.get('substanceuom').enable();
              this.materialForm.get('rawhide').enable();
              this.materialForm.get('cost').enable();
              setTimeout(() => {
                $('.selectpicker').selectpicker({
                  noneSelectedText: 'Select classification',
                });
              }, 10);
            }
            this.sequenceNumber =
              materialDetails.material.message['item1']['sequence'];
            console.log(materialDetails.material.message['item1']);
            console.log(this.materialForm);
            this.materialForm
              .get('materialid')
              .setValue(materialDetails.material.message['item1']['id']);
            this.materialForm
              .get('materialseq')
              .setValue(materialDetails.material.message['item1']['sequence']);
            this.materialForm
              .get('materialname')
              .setValue(materialDetails.material.message['item1']['name']);
            this.materialForm
              .get('cuttablewidth')
              .setValue(
                materialDetails.material.message['item1']['cuttablewidth']
              );
            this.materialForm
              .get('fabriccomp')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['fabriccomp']
                )
              );
            this.materialForm
              .get('comments')
              .setValue(materialDetails.material.message['item1']['comments']);
            this.materialForm
              .get('widthuom')
              .setValue(materialDetails.material.message['item1']['widthuom']);
            if (materialDetails.material.message['item1']['weightgsm'] !== 0) {
              this.materialForm
              .get('weightgsm')
              .setValue(materialDetails.material.message['item1']['weightgsm']);
            }
            this.materialForm
              .get('width')
              .setValue(materialDetails.material.message['item1']['width']);
            this.materialForm
              .get('subtype')
              .setValue(materialDetails.material.message['item1']['subtype']);
            this.materialForm
              .get('size')
              .setValue(materialDetails.material.message['item1']['size']);
            this.materialForm
              .get('content')
              .setValue(materialDetails.material.message['item1']['content']);
            this.materialForm
              .get('sizeuom')
              .setValue(materialDetails.material.message['item1']['sizeuom']);
            this.materialForm
              .get('substabce')
              .setValue(materialDetails.material.message['item1']['substabce']);
            this.materialForm
              .get('substanceuom')
              .setValue(
                materialDetails.material.message['item1']['substanceuom']
              );
            this.materialForm
              .get('rawhide')
              .setValue(materialDetails.material.message['item1']['rawhide']);
            this.materialForm
              .get('cost')
              .setValue(materialDetails.material.message['item1']['cost']);
            this.materialForm
              .get('trimcost')
              .setValue(materialDetails.material.message['item1']['trimcost']);
            this.materialForm
              .get('threadCount')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['threadCount']
                )
              );
            if (materialDetails.material.message['item1']['weight_GSM']) {
              this.materialForm
                .get('weight')
                .setValue(
                  materialDetails.material.message['item1']['weight_GSM']
                );
            }
            this.materialForm
              .get('ply')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['ply']
                )
              );
            this.materialForm
              .get('softness')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['softness']
                )
              );
            this.materialForm
              .get('fabriccomp')
              .setValue(
                materialDetails.material.message['item1']['fabriccomp']
              );
            this.materialForm
              .get('composition')
              .setValue(
                materialDetails.material.message['item1']['fabriccomp']
              );
            this.materialForm
              .get('finish')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['finish']
                )
              );
            this.materialForm
              .get('durability')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['durability']
                )
              );
            this.materialForm
              .get('unitofMeasure')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['widthUOM']
                )
              );
            this.materialForm
              .get('zipFunctionality')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['functionality']
                )
              );
              console.log(this.materialForm);
              console.log(this.aesdecryptservice.decryptData(this.dataservice.Key, 'Yh3WkbKOmWX6wYSIPtbdPSlC9DlMqx17WLw/SXvFwfDsZShfRFRxNsh/ZOLjFRXP'));
              console.log(this.aesdecryptservice.decryptData(this.dataservice.Key, '809IU+5l5LIHfKEg/t+MWgRm6PnbNmq+EuQJkTCrKmg='));
              console.log(this.aesdecryptservice.decryptData(this.dataservice.Key, 'q/mfZjAHqTmjEqol55W05Q=='));

              
            this.materialForm
              .get('zipper')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['zipperType']
                )
              );
            this.materialForm
              .get('fabricType')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['materialType']
                )
              );
            this.materialForm
              .get('wash')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['wash']
                )
              );
            this.materialForm
              .get('materialTypeList')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['materialType']
                )
              );
            if (materialDetails.material.message['item1']['weight_GSM']) {
              this.materialForm
                .get('gSM')
                .setValue(
                  materialDetails.material.message['item1']['weight_GSM']
                );
            }
            this.materialForm
              .get('zipType')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['materialType']
                )
              );
            this.materialForm
              .get('buttonnuom')
              .setValue(materialDetails.material.message['item1']['widthuom']);
            this.materialForm
              .get('buttonSize')
              .setValue(materialDetails.material.message['item1']['size']);
            this.materialForm
              .get('buttonColor')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['color']
                )
              );
            this.materialForm
              .get('buttonShape')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['shape']
                )
              );
            this.materialForm
              .get('buttonType')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['materialType']
                )
              );
            this.materialForm
              .get('threadPly')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['ply']
                )
              );
            this.materialForm
              .get('threadColor')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['color']
                )
              );
            if (materialDetails.material.message['item1']['weight_GSM']) {
            this.materialForm
              .get('threadLength')
              .setValue(
                materialDetails.material.message['item1']['weight_GSM']
              );
            }
            this.materialForm
              .get('threadMot')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['materialType']
                )
              );
            this.materialForm
              .get('rivetFinish')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['finish']
                )
              );
            this.materialForm
              .get('rivetShape')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['shape']
                )
              );
              console.log(this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                materialDetails.material.message['item1']['shape']
              ))
            this.materialForm
              .get('rivetBaseDiameter')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['diameter']
                )
              );
              this.materialForm
              .get('rivetBaseuom')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['widthUOM']
                )
              );
            this.materialForm
              .get('rivetCapDiameter')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails?.material?.message['item1']['capDaimeter']
                )
              );
            this.materialForm
              .get('rivetuom')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['capMeasure']
                )
              );
             
            this.materialForm
              .get('rivetMat')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['materialType']
                )
              );
              console.log('first', this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                materialDetails.material.message['item1']['materialType']
              ))
            this.materialForm
              .get('sewFabric')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['fabric']
                )
              );
            this.materialForm
              .get('sewLabelSize')
              .setValue(materialDetails.material.message['item1']['size']);
            this.materialForm
              .get('hangColor')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['color']
                )
              );
            this.materialForm
              .get('hangMaterial')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  materialDetails.material.message['item1']['materialType']
                )
              );
            this.materialForm
              .get('hangSize')
              .setValue(
                materialDetails.material.message['item1']['size']?.trim()
              );
            if (materialDetails.material.message['item1']['weight_GSM']) {
              this.materialForm
                .get('hangWeightgsm')
                .setValue(
                  materialDetails.material.message['item1']['weight_GSM']
                );
            }
            this.materialForm
              .get('size')
              .setValue(materialDetails.material.message['item1']['size']);

            //this.materialForm.get('finish').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, materialDetails.material.message['item1']["finish"]));
            if (materialDetails.material.message['item1']['thumbnailFiles']) {
              this.editedThumbnails = [];
              this.editedThumbnails.push(
                materialDetails.material.message['item1']['thumbnailFiles']
              );

              if (this.editedThumbnails.length > 0) {
                this.imageObject = [];
                this.editedThumbnails.forEach((x, idx, array) => {
                  const temp = {
                    image: x['thumbnail'],
                    thumbImage: x['thumbnail'],
                  };
                  this.imageObject.push(temp);
                });
              } else {
                const temp = {
                  image: this.thumbnail,
                  thumbImage: this.thumbnail,
                };
                this.imageObject.push(temp);
              }
            }
            setTimeout(()=>{
              $('.selectpicker').selectpicker();
              this.refreshSelect();
            },1000)
          } else {
            this.showLoader1 = false;
            // this.error('Failed:' + materialDetails.material.message['item1']);
            this.toastService.showError(materialDetails.material.message['item1']);
            this.logger.error(
              materialDetails.material.message['item1'],
              'MaterialDetails'
            );
          }
        } else {
          this.logger.error(
            materialDetails.material.error.error.message,
            'MaterialDetails'
          );
          this.getError(materialDetails.material.error);
          this.showLoader1 = false;
        }
      });
    /* this.dataservice.getMaterialById(this.storageservice.getMaterialId()).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.showLoader1 = false;
        //console.log(JSON.stringify(data[0]));
        this.editedMaterialData = response.body['item1'];
        this.logger.info(response.body['item1']["name"] + " loaded successfully", "MaterialDetails");

        this.initForm();
        this.materialForm.get('materialclassification').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["classification"]));
        this.setMaterialTreeValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["classification"]));
        this.materialclassificationValue = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["classification"]);
        this.refreshTree();
        this.materialForm.get('materialid').setValue(response.body['item1']["id"]);
        this.materialForm.get('materialseq').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sequence"]));
        this.materialForm.get('materialname').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["name"]));
        //this.materialForm.get('cuttablewidth').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["cuttablewidth"]));
        this.materialForm.get('fabriccomp').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["fabriccomp"]));
        this.materialForm.get('comments').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["comments"]));
        this.materialForm.get('widthuom').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["widthuom"]));
        this.materialForm.get('weightgsm').setValue(response.body['item1']["weightgsm"]);
        this.materialForm.get('width').setValue(response.body['item1']["width"]);
        this.materialForm.get('subtype').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["subtype"]));
        this.materialForm.get('size').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["size"]));
        this.materialForm.get('content').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["content"]));
        this.materialForm.get('sizeuom').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeuom"]));
        this.materialForm.get('substabce').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["substabce"]));
        this.materialForm.get('substanceuom').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["substanceuom"]));
        this.materialForm.get('rawhide').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["rawhide"]));
        this.materialForm.get('cost').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["cost"]));
        this.materialForm.get('trimcost').setValue(response.body['item1']["trimcost"]);
        if (response.body['item1']["thumbnailFiles"]) {
          response.body['item1']["thumbnailFiles"]['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["thumbnailFiles"]['thumbnail']);
          response.body['item1']["thumbnailFiles"]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["thumbnailFiles"]['name']);
          this.editedThumbnails[0] = response.body['item1']["thumbnailFiles"];

        }
        this.refreshSelect();
      }
      else {
        this.showLoader1 = false;
      }
    }, err => {
      this.logger.error(err.error.message, "MaterialDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader1 = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader1 = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader1 = false;
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialDetails");

      }
      this.showLoader1 = false;
    }); */
  }

  getMaterialColors() {
    this.matecolorloading = true;
    this.showLoader = true;
    this.matecolorstartEndCount =
      this.matecolorstartCountList + '-' + this.matecolorendCountList;
    this.store.dispatch(
      new materialActionTypes.LoadMaterialColor(
        this.storageservice.getMaterialId(),
        this.matecolorstartEndCount
      )
    );
    this.store
      .pipe(
        select((state) => state),
        take(2),
        skip(1)
      )
      .subscribe((materialColor) => {
        if (materialColor.material.error === '') {
          this.showLoader = false;
          if (materialColor.material.message['item2'] === 'Success') {
            this.matecolorloading = false;
            var materialcolorData = materialColor.material.message['item1'];
            this.matecolorTotalRecordCount =
              materialColor.material.message['item3'];
            this.matcolorsIds = materialColor.material.message['item4'];
            this.matecolorData = materialcolorData.colours;
            if (this.matecolorData != null && this.matecolorData.length > 0) {
              var gridView = {
                data: this.matecolorData,
                total: this.matecolorTotalRecordCount,
              };
              this.gridData = gridView;
            }
            this.logger.info(
              materialColor.material.message['item1']['name'] +
                ' loaded successfully',
              'MaterialColors'
            );
          } else {
            this.matecolorloading = false;
            this.refreshTree();
            // this.error('Failed:' + materialColor.material.message?.item1);
            this.toastService.showError(materialColor.material.message?.item1);
            this.logger.error(
              materialColor.material.message?.item1,
              'MaterialColors'
            );
          }
        } else {
          this.showLoader = false;
          this.logger.error(
            materialColor.material.error.error.message,
            'MaterialColors'
          );
          this.getError(materialColor.material.error);
          this.matecolorloading = false;
        }
      });
    /* this.dataservice.getmaterialcolours(this.storageservice.getMaterialId(), this.matecolorstartEndCount).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.matecolorloading = false;
        var materialcolorData = response.body['item1'];
        this.matecolorTotalRecordCount = response.body['item3'];
        this.matcolorsIds = response.body['item4'];
        this.logger.info(response.body['item1']["name"] + " loaded successfully", "MaterialColors");
        if (this.matcolorsIds.length > 0) {
          this.matecolorData = materialcolorData.colours
          for (let i = 0; i < this.matecolorData.length; i++) {
            this.matecolorData[i]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['name']);
            //  this.matecolorData[i]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.matecolorData[i]['classification']);
            this.matecolorData[i]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['colorStandard']);
            this.matecolorData[i].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].createdBy.firstName);
            this.matecolorData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].status);
            if (this.matecolorData[i]['hexcode']) {
              this.matecolorData[i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['hexcode']);

            } else if (this.matecolorData[i]['thumbnailFiles']) {
              this.matecolorData[i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['thumbnailFiles']['thumbnail']);
              this.matecolorData[i]['thumbnailFiles']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['thumbnailFiles']['name']);
            }
          }
          var gridView = {
            data: this.matecolorData,
            total: this.matecolorTotalRecordCount,
          };
          this.gridData = gridView;//process(this.matecolorData, this.state);
        }
        else
        {
          this.gridData = null;
        }
      }
      else {
        this.matecolorloading = false;
      }
    }, err => {
      this.logger.error(err.error.message, "MaterialColors");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.matecolorloading = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.matecolorloading = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.matecolorloading = false;
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialColors");
      }
      this.matecolorloading = false;
    }); */
  }

  getMaterialSuppliers() {
    this.showLoader = true;
    this.matesupplierloading = true;
    this.matesupplierstartEndCount =
      this.matesupplierstartCountList + '-' + this.matesupplierendCountList;
    this.store.dispatch(
      new materialActionTypes.LoadMaterialSupplier(
        this.storageservice.getMaterialId(),
        this.matesupplierstartEndCount
      )
    );
    this.store
      .pipe(
        select((state) => state),
        take(2),
        skip(1)
      )
      .subscribe((materialSuppliers) => {
        if (materialSuppliers.material.error === '') {
          this.showLoader = false;
          if (materialSuppliers.material.message['item2'] === 'Success') {
            this.matesupplierloading = false;
            var materialSupplierData =
              materialSuppliers.material.message['item1'];
            this.matesupplierTotalRecordCount =
              materialSuppliers.material.message['item3'];
            this.matsuppliersIds = materialSuppliers.material.message['item4'];
            this.supplierData = materialSupplierData.suppliers;
            if (this.supplierData != null && this.supplierData.length > 0) {
              var gridView = {
                data: this.supplierData,
                total: this.matesupplierTotalRecordCount,
              };
              this.gridSupData = gridView;
            }
            this.logger.info(
              materialSuppliers.material.message['item1']['name'] +
                ' loaded successfully',
              'MaterialSuppliers'
            );
          } else {
            this.matesupplierloading = false;
            this.showLoader = false;
            this.refreshTree();
            // this.error('Failed:' + materialSuppliers.material.message?.item1);
            this.toastService.showError(materialSuppliers.material.message?.item1);
            this.logger.error(
              materialSuppliers.material.message?.item1,
              'MaterialSuppliers'
            );
          }
        } else {
          this.logger.error(
            materialSuppliers.material.error.error.message,
            'MaterialSuppliers'
          );
          this.getError(materialSuppliers.material.error);
          this.matesupplierloading = false;
          this.showLoader = false;
        }
      });
    /*   this.dataservice.getmaterialsuppliers(this.storageservice.getMaterialId(), this.matesupplierstartEndCount).subscribe((response) => {
        if (response.body['item2'] === "Success") {
          this.matesupplierloading = false;

          var materialSupplierData = response.body['item1'];
          this.matesupplierTotalRecordCount = response.body['item3'];
          this.matsuppliersIds = response.body['item4'];
          this.logger.info(response.body['item1']["name"] + " loaded successfully", "MaterialSuppliers");
          if (materialSupplierData.suppliers.length > 0) {
            this.supplierData = materialSupplierData.suppliers;
            for (let i = 0; i < this.supplierData.length; i++) {
              this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
              this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
              this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
              // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
              // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
              this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
              //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
              this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
              // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
              if (this.supplierData[i].colours != null) {
                for (let j = 0; j < this.supplierData[i].colours.length; j++) {
                  this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
                  //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
                  this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
                  this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
                  this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);
                  if (this.supplierData[i].colours[j]['hexcode']) {
                    this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);
                  } else if (this.supplierData[i].colours[j]['thumbnailFiles']) {
                    this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);
                    this.supplierData[i].colours[j]['thumbnailFiles']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['name']);
                  }
                }
              }
            }

            var gridView = {
              data: this.supplierData,
              total: this.matesupplierTotalRecordCount,
            };
            this.gridSupData = gridView;//process(this.supplierData, this.state);

          }
        }
        else {
          this.matesupplierloading = false;
        }
      }, err => {
        this.logger.error(err.error.message, "MaterialSuppliers");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.matesupplierloading = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.matesupplierloading = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.matesupplierloading = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialSuppliers");
        }
        this.matesupplierloading = false;
      }); */
  }

  loadSelectPicker() {
    $(document).ready(function () {
      setTimeout(() => {
        $('.selectpicker').selectpicker(
          'setStyle',
          'dropdown-toggle',
          'remove'
        );
        $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
        $('.selectpicker').selectpicker({
          noneSelectedText: 'Select Color Standard',
        });
      }, 5);
    });
  }

  refreshTree() {
    var data = this.materialClassificationData;
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      var treeview = $('#materialclassification').kendoDropDownTree({
        placeholder: 'Select Classification',
        height: '230px',
        dataSource: data,
        filter: 'startswith',
        /*dataSource:[
              { text: "Fabric" },
              { text: "Trim" },
              { text: "Leather" }

        ],*/
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault();
            }
          },
        },
        change: function (e) {
          setTimeout(() => {
            $('.selectpicker').selectpicker(
              'setStyle',
              'dropdown-toggle',
              'remove'
            );
            $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
            $('.selectpicker').selectpicker({
              noneSelectedText: 'Select Color Standard',
            });
          }, 10);
          var value = this.value();
          // alert(JSON.stringify(value));
          //alert(value["parentName"]);
          if (value) {
            self.materialclassificationValue = value['text'];
            self.materialForm
              .get('materialclassification')
              .setValue(value['text']);
            if (value['parentName']) {
              self.parentClassification = value['parentName'];
            } else {
              self.parentClassification = '';
            }
          } else {
            self.materialclassificationValue = '';
            self.parentClassification = '';
            self.materialForm.get('materialclassification').setValue('');
          }
        },
      });
    });
  }

  setMaterialTreeValue(value) {
    $(document).ready(function () {
      $('#materialclassification').data('kendoDropDownTree')?.value(value);
    });
  }

  materialDetailsTab(activeTab) {
    this.getMaterialClassification('Material');
    this.loadSelectPicker();
    this.activeTab = activeTab;
  }

  colorTab(activeTab) {
    this.activeTab = activeTab;
    this.isColorButton = true;
    this.colorSupplierId = '';
    if (this.storageservice.getMaterialId()) {
      this.getMaterialColors();
    }
  }

  supplierTab(activeTab) {
    this.activeTab = activeTab;
    this.isColorButton = true;
    if (this.storageservice.getMaterialId()) {
      this.getMaterialSuppliers();
    }
  }

  getControls() {
    return (this.documentsForm.get('documents') as FormArray).controls;
  }

  onRemove1(event) {
    //console.log(event);
    //this.files.splice(this.files.indexOf(event), 1);
    this.fileData.splice(this.fileData.indexOf(event), 1);
    // //console.log(this.fileData);
  }

  removeImgSection(event, i) {
    this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    if (this.editedFileData[i].length == 0) {
      this.isFilesAdded = false;
    }
    //console.log(this.editedFileData);
    let documentControl = this.getControls();
    let docdeltedFiles = documentControl[i].get('docdeletedfileids').value;
    if (docdeltedFiles) {
      documentControl[i]
        .get('docdeletedfileids')
        .setValue(docdeltedFiles + ',' + event.id);
    } else {
      documentControl[i].get('docdeletedfileids').setValue(event.id);
    }
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  handleChange($event: ColorEvent) {}

  /*onSelect(val: string[]) {
   }*/

  onSelect(event, i) {
    //console.log(event);
    this.files.push(...event.addedFiles);
    //  this.files = [];
    //  this.files.push(...event.addedFiles);
    this.fileData[i] = this.files;
    //console.log(this.fileData);
  }

  async onSubmit() {
    console.log('0000', this.materialForm);
    this.thumbnailId = null;
    var thumbnailName = '';
    this.submitted = true;
    // if(this.materialclassificationValue=='Fabric'){
    //   this.materialColorForm.controls['subtype'].setValidators([]);
    //  }else if(this.materialclassificationValue=='Material'){
    //   this.materialColorForm.controls['subtype'].setValidators([]);
    //   this.materialColorForm.controls['fabriccomp'].setValidators([]);
    //  }else if(this.materialclassificationValue=='Product Packaging'){
    //   this.materialColorForm.controls['fabriccomp'].setValidators([]);

    //  }
    //  else if(this.materialclassificationValue=='Trims'){
    //   this.materialColorForm.controls['fabriccomp'].setValidators([]);

    //   }
    if (!this.materialForm.valid) {
      // this.error(this.staticMsgs.matDetail.inValidForm);
      // this.toastService.showError(this.staticMsgs.matDetail.inValidForm);
      this.toastService.showError("Please enter all the mandatory fields.");

      if (this.thumbNails.length == 0) {
        this.isFileError = true;
      }
      return false;
    } else if (!this.isThumbnailAdded) {
      return false;
    }
    this.showLoader = true;
    // let re = /\"/gi;
    // this.colourSwatch = this.colourSwatch.replace(re,"'");

    if (this.thumbNails.length === 1) {
      let thumbnailuploaded = await this.uploadFile(this.thumbNails[0]);
      if (thumbnailuploaded != 'error') {
        this.thumbnailId = JSON.parse(thumbnailuploaded)['Key'];
        thumbnailName = this.thumbNails[0].name;
      } else {
        // this.error(thumbnailuploaded);
        this.toastService.showError(thumbnailuploaded);
        this.submitted = false;
        this.showLoader = false;
        return false;
      }
    }
    if (this.isThumbnailAdded) {
      if (this.thumbNails.length === 1) {
        let thumbnailuploaded = await this.uploadFile(this.thumbNails[0]);
        if (thumbnailuploaded != 'error') {
          this.thumbnailId = JSON.parse(thumbnailuploaded)['Key'];
          thumbnailName = this.thumbNails[0].name;
        } else {
          // this.error(thumbnailuploaded);
          this.toastService.showError(thumbnailuploaded);
          this.submitted = false;
          this.showLoader = false;
          return false;
        }
      }
    }
    if (this.thumbnailId == null && this.editedThumbnails.length <= 0) {
      this.isFileError = true;
      this.showLoader = false;
      return false;
    }
    let materialData;

    if (this.parentClassification === 'Fabric') {
      materialData = {
        baseClassification: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.parentClassification
        ),
        classification: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.materialclassificationValue
        ),
        name: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.materialname.value
        ),
        materialType: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.fabricType.value
        ),
        comments: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.comments.value
        ),
        thumbnail: this.thumbnailId,
        threadCount: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.threadCount.value
        ),
        wash: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.wash.value
        ),
        ply: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.ply.value
        ),
        fabricComposition: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.composition.value
        ),
        finish: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.finish.value
        ),
      };
      if (this.f.gSM.value) materialData.weight_GSM = this.f.gSM.value;
    } else if (this.parentClassification == 'Material') {
      materialData = {
        baseClassification: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.parentClassification
        ),
        classification: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.materialclassificationValue
        ),
        name: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.materialname.value
        ),
        materialType: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.materialTypeList.value
        ),
        comments: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.comments.value
        ),
        thumbnail: this.thumbnailId,
        softness: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.softness.value
        ),
        durability: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.durability.value
        ),
        widthUOM: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.unitofMeasure.value
        ),
        
      };
      if (this.f.weight.value) materialData.weight_GSM = this.f.weight.value;
      console.log( this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        this.f.unitofMeasure.value
      ))
    } else if (this.parentClassification == 'Trims') {
      if (this.materialForm.get('materialclassification').value == 'Zipper') {
        materialData = {
          baseClassification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.parentClassification
          ),
          classification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.materialclassificationValue
          ),
          name: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.materialname.value
          ),
          materialType: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.zipType.value
          ),
          comments: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.comments.value
          ),
          thumbnail: this.thumbnailId,
          functionality: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.zipFunctionality.value
          ),
          zipperType: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.zipper.value
          ),
        };
      } else if (
        this.materialForm.get('materialclassification').value == 'Button'
      ) {
        materialData = {
          baseClassification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.parentClassification
          ),
          classification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.materialclassificationValue
          ),
          name: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.materialname.value
          ),
          materialType: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.buttonType.value
          ),
          comments: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.comments.value
          ),
          thumbnail: this.thumbnailId,
          color: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.buttonColor.value
          ),
          shape: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.buttonShape.value
          ),
          size: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.buttonSize.value
          ),
          widthUOM: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.buttonnuom.value
          ),
        };
      } else if (
        this.materialForm.get('materialclassification').value == 'Thread'
      ) {
        materialData = {
          baseClassification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.parentClassification
          ),
          classification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.materialclassificationValue
          ),
          name: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.materialname.value
          ),
          materialType: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.threadMot.value
          ),
          comments: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.comments.value
          ),
          thumbnail: this.thumbnailId,
          color: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.threadColor.value
          ),
          ply: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.threadPly.value
          ),
        };
        if (this.f.threadLength.value) materialData.weight_GSM = this.f.threadLength.value;
      } else if (
        this.materialForm.get('materialclassification').value == 'Rivet'
      ) {
        materialData = {
          baseClassification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.parentClassification
          ),
          classification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.materialclassificationValue
          ),
          name: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.materialname.value
          ),
          materialType: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.rivetMat.value
          ),
          comments: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.comments.value
          ),
          thumbnail: this.thumbnailId,
          shape: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.rivetShape.value
          ),
          finish: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.rivetFinish.value
          ),
          widthUOM: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.rivetBaseuom.value
          ),
          diameter: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.rivetBaseDiameter.value
          ),
          // widthUOM: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.rivetBaseuom.value),
          capDaimeter: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.rivetCapDiameter.value
          ),
          capMeasure: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.rivetuom.value
          ),
        };
      }
    } else if (
      this.parentClassification != 'Trims' &&
      this.parentClassification !== 'Fabric' &&
      this.parentClassification !== 'Leather'
    ) {
      if (
        this.materialForm.get('materialclassification').value == 'Sew in Label'
      ) {
        materialData = {
          baseClassification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.parentClassification
          ),
          classification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.materialclassificationValue
          ),
          name: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.materialname.value
          ),
          comments: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.comments.value
          ),
          thumbnail: this.thumbnailId,
          fabric: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.sewFabric.value
          ),
          size: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.sewLabelSize.value
          ),
        };
      } else if (
        this.materialForm.get('materialclassification').value == 'Hang Tag'
      ) {
        materialData = {
          baseClassification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.parentClassification
          ),
          classification: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.materialclassificationValue
          ),
          name: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.materialname.value
          ),
          comments: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.comments.value
          ),
          thumbnail: this.thumbnailId,
          color: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.hangColor.value
          ),
          materialType: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.hangMaterial.value
          ),
          size: this.aesdecryptservice.encryptData(
            this.dataservice.Key,
            this.f.hangSize.value
          ),
        };
        if (this.f.hangWeightgsm.value) materialData.weight_GSM = this.f.hangWeightgsm.value;
      }
    }

    if (!this.f.materialid.value) {
      if (this.thumbNails.length === 1) {
        this.isThumbnailAdded = true;
      } else {
        this.isThumbnailAdded = false;
      }
      this.store.dispatch(new materialActionTypes.CreateMaterial(materialData));
      this.store
        .pipe(
          select((state) => state),
          take(2),
          skip(1)
        )
        .subscribe((materialDetails) => {
          if (materialDetails.material.error === '') {
            if (materialDetails.material.message['item2'] === 'Success') {
              this.showLoader = false;
              this.submitted = false;
              this.isEditMode = true;
              this.isViewingMode = true;
              this.isEditingMode = false;
              this.editedMaterialData =
                materialDetails.material.message['item1'];
              this.storageservice.storeEditedMaterialId(
                this.editedMaterialData.id
              );
              this.reloadmaterialFormData(
                materialDetails.material.message['item1']
              );
              this.getMaterialDetails();
              // this.success(this.staticMsgs.matDetail.matCreate1);
              this.toastService.showSuccess(this.staticMsgs.matDetail.matCreate1);
              this.logger.info(
                this.staticMsgs.matDetail.matCreate,
                'MaterialDetails'
              );
            } else {
              this.showLoader = false;
              //this.refreshTree();
              // this.error('Failed:' + materialDetails.material.message?.item1);
              this.toastService.showError(materialDetails.material.message?.item1);
              this.logger.error(
                materialDetails.material.message?.item1,
                'MaterialDetails'
              );
            }
          } else {
            this.showLoader = false;
            this.logger.error(
              materialDetails.material?.error?.error?.message,
              'MaterialDetails'
            );
            this.getError(materialDetails.material?.error);
          }
        });

      //console.log(JSON.stringify(materialData))
      /* this.dataservice.addMaterial(materialData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader = false;
            //this.alert = 'success';
            // this.errorDiv = true;
            // this.errors = 'Material Created Sucessfully.';
            this.submitted = false;
            this.isEditMode = true;
            this.isViewingMode = true;
            this.isEditingMode = false;
            this.editedMaterialData = response.body['item1'];
            this.storageservice.storeEditedMaterialId(this.editedMaterialData.id)
            //console.log(this.editedMaterialData);
            this.reloadmaterialFormData(response.body['item1'])
            this.success("Material Created Successfully!");
            this.logger.info("Material Created Successfully", "MaterialDetails");

          }
          else {
            //console.log(response)
            //this.alert = 'danger';
            //this.errorDiv = true;
            // this.errors = response.body['item2'];
            this.error(response.body['item2']);
            this.logger.error(response.body['item2'], "MaterialDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        //this.alert = 'danger';
        //this.errorDiv = true;
        //this.errors = err.message;
        this.logger.error(err.error.message, "MaterialDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialDetails");
        }
        this.showLoader = false;
      }); */
    } else {
      if (this.editedThumbnails.length === 1) {
        this.isThumbnailAdded = true;
      } else {
        if (this.thumbNails.length === 1) {
          this.isThumbnailAdded = true;
        } else {
          this.isThumbnailAdded = false;
        }
      }

      let materialid = { id: this.storageservice.getMaterialId() };
      let updatedMaterialData = { ...materialData, ...materialid };
      //console.log(JSON.stringify(updatedMaterialData))
      this.store.dispatch(
        new materialActionTypes.UpdateMaterial(updatedMaterialData)
      );
      this.store
        .pipe(
          select((state) => state),
          take(2),
          skip(1)
        )
        .subscribe((materialDetails) => {
          if (materialDetails.material.error === '') {
            if (materialDetails.material.message['item2'] === 'Success') {
              this.showLoader = false;
              this.submitted = false;
              this.isEditMode = true;
              this.editedMaterialData =
                materialDetails.material.message['item1'];
              this.isViewingMode = true;
              this.isEditingMode = false;
              //console.log(this.editedMaterialData);

              this.reloadmaterialFormData(
                materialDetails.material.message['item1']
              );
              this.getMaterialDetails();
              // this.success(this.staticMsgs.matDetail.matUpd1);
              this.toastService.showSuccess(this.staticMsgs.matDetail.matUpd1);
              this.logger.info(
                this.staticMsgs.matDetail.matUpd,
                'MaterialDetails'
              );
            } else {
              this.showLoader = false;
              this.refreshTree();
              // this.error('Failed:' + materialDetails.material.message?.item1);
              this.toastService.showError(materialDetails.material.message?.item1);
              this.logger.error(
                materialDetails.material.message?.item1,
                'MaterialDetails'
              );
            }
          } else {
            this.logger.error(
              materialDetails.material.error.error.message,
              'MaterialDetails'
            );
            this.getError(materialDetails.material.error);
            this.showLoader = false;
          }
        });

      /* this.dataservice.updatematerialById(updatedMaterialData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.editedMaterialData = response.body['item1'];
            this.isViewingMode = true;
            this.isEditingMode = false;
            //console.log(this.editedMaterialData);
            this.reloadmaterialFormData(response.body['item1'])
            this.success("Material Updated Successfully!");
            this.logger.info("Material Updated Successfully", "MaterialDetails");
          }
          else {
            //console.log(response)
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "MaterialDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "MaterialDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialDetails");
        }
        this.showLoader = false;
      }); */
    }
  }

  reloadmaterialFormData(data) {
    this.initForm();
    this.materialForm
      .get('materialclassification')
      .setValue(data['classification']);
    //this.refreshTree();
    this.setMaterialTreeValue(data['classification']);
    this.materialclassificationValue = data['classification'];
    if (
      this.parentClassification === 'Trims' ||
      this.parentClassification === 'Product Packaging'
    ) {
      this.materialForm.get('subtype').enable();
      setTimeout(() => {
        $('.selectpicker').selectpicker({
          noneSelectedText: 'Select classification',
        });
      }, 10);
      if (this.parentClassification === 'Trims') {
        this.materialForm.get('content').enable();
        this.materialForm.get('sizeuom').enable();
        this.materialForm.get('size').enable();
        this.materialForm.get('width').enable();
        setTimeout(() => {
          $('.selectpicker').selectpicker({
            noneSelectedText: 'Select classification',
          });
        }, 10);
      }
    }
    if (this.parentClassification === 'Material') {
      this.materialForm.get('substabce').enable();
      this.materialForm.get('substanceuom').enable();
      this.materialForm.get('rawhide').enable();
      this.materialForm.get('cost').enable();
      setTimeout(() => {
        $('.selectpicker').selectpicker({
          noneSelectedText: 'Select classification',
        });
      }, 10);
    }
    /* if (data["classification"] === "Solid") {
       this.materialForm.get('colorstandard').enable();
       this.materialForm.get('pantone').enable();
       setTimeout(() => {
         $('.selectpicker').selectpicker({ noneSelectedText: 'Select Material Standard' });
       }, 10);
     }
     else {
       //this.refreshTree();
       this.materialForm.get('colorstandard').disable();
       this.materialForm.get('pantone').disable();
     }*/
    this.materialForm.get('materialid').setValue(data['id']);
    this.storageservice.storeEditedMaterialId(data['id']);
    this.materialForm.get('materialseq').setValue(data['sequence']);
    this.materialForm.get('materialname').setValue(data['name']);
    this.materialForm.get('cuttablewidth').setValue(data['cuttablewidth']);
    this.materialForm.get('fabriccomp').setValue(data['fabriccomp']);
    this.materialForm.get('comments').setValue(data['comments']);
    this.materialForm.get('widthuom').setValue(data['widthuom']);
    this.materialForm.get('weightgsm').setValue(data['weightgsm']);
    this.materialForm.get('subtype').setValue(data['subtype']);
    this.materialForm.get('size').setValue(data['size']);
    this.materialForm.get('width').setValue(data['width']);
    this.materialForm.get('content').setValue(data['content']);
    this.materialForm.get('sizeuom').setValue(data['sizeuom']);
    this.materialForm.get('substabce').setValue(data['substabce']);
    this.materialForm.get('substanceuom').setValue(data['substanceuom']);
    this.materialForm.get('rawhide').setValue(data['rawhide']);
    this.materialForm.get('cost').setValue(data['cost']);
    this.refreshSelect();
    const temp = {
      image: this.thumbnail,
      thumbImage: this.thumbnail,
    };
    this.imageObject.push(temp);
    //this.materialForm.get('trimcost').setValue(data["trimcost"]);
    if (data['thumbnailFiles']) {
      this.editedThumbnails = [];
      this.editedThumbnails.push(data['thumbnailFiles']);
    }
  }

  isEmptyObject(obj) {
    return JSON.stringify(obj) === '[]';
  }

  onInput(id:string) {
    const Value = this.materialForm.get(id)?.value;
    console.log(Value, 'Value')
    if (isNaN(Value) || Value <= 0) {
      this.materialForm.get(id)?.setValue('');
    }
  }
  onHangWeightgsm(id:string) {
    const Value = this.materialForm.get(id)?.value;
    console.log(Value, 'Value')
    if (isNaN(Value) || Value <= 0) {
      this.materialForm.get(id)?.setValue('');
    }
  }

  openThumbnail(imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedThumbnails.length; i++) {
      let src = '';
      let thumb = '';
      if (this.isValidImageURL(this.editedThumbnails[i - 1]['name'])) {
        src = this.editedThumbnails[i - 1]['thumbnail'];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedThumbnails[i - 1]['thumbnail'];
      } else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb,
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }
  open(index: number, imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedFileData[index].length; i++) {
      let src = '';
      let thumb = '';
      if (this.isValidImageURL(this.editedFileData[index][i - 1]['name'])) {
        src = this.editedFileData[index][i - 1]['thumbnail'];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedFileData[index][i - 1]['thumbnail'];
      } else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb,
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  success(message: string) {
    Swal.fire('Success', message, 'success');
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.matDetail.oops,
      text: message,
    });
  }

  sessionTimedOut() {
    let timerInterval;
    Swal.fire({
      title: this.staticMsgs.matDetail.session,
      html: this.staticMsgs.matDetail.sessExp,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
        this.userservice.logout();
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
        return;
      }
    });
  }
  onSelectThumbnail(event, i) {
    this.thumbNails = [];
    let imgext = 'png,jpeg,jpg';
    var str = event.addedFiles[0].name.split('.')[1]?.toLowerCase();
    if (event.addedFiles.length === 1 && imgext.includes(str)) {
      this.thumbNails.push(...event.addedFiles);
      this.fileData[i] = this.thumbNails;
      this.isThumbnailAdded = true;
    } else {
      this.isThumbnailAdded = false;
      // this.error(this.staticMsgs.matDetail.unSupportFile);
      this.toastService.showError(this.staticMsgs.matDetail.unSupportFile);
      return false;
    }
    if (this.thumbNails && this.thumbNails.length > 0) {
      this.isFileError = false;
    }
  }
  onRemoveThumbnail(event) {
    this.thumbNails.splice(this.thumbNails.indexOf(event), 1);
    if (this.thumbNails.length === 1) {
      this.isThumbnailAdded = true;
    } else {
      this.isFileError = true;
      this.isThumbnailAdded = false;
    }
  }
  removeThumbnailSection(event) {
    this.editedThumbnails.splice(this.editedThumbnails.indexOf(event), 1);
    this.isThumbnailAdded = false;
  }
  async uploadFile(file): Promise<any> {
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: `${environment.BucketAccessKeyId}`,
      secretAccessKey: `${environment.BucketSecretAccessKey}`,
      region: `${environment.BucketRegion}`,
    });
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType,
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          //console.log('There was an error uploading your file: ', err);
          reject('error');
        }
        //console.log('Successfully uploaded file.', data);
        resolve(JSON.stringify(data));
      });
    });
    //for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              //console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  //console.log('There was an error uploading your file: ', err);
                  return false;
              }
              //console.log('Successfully uploaded file.', data);
              return true;
          });*/
  }

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  isValidImageURL(str) {
    if (str != undefined) {
      str = str.toLowerCase();
      str = str.split('?')[0];
      //moving on, split the uri into parts that had dots before them
      var parts = str.split('.');
      //get the last part ( should be the extension )
      var extension = parts[parts.length - 1];
      //define some image types to test against
      var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
      //check if the extension matches anything in the list.
      if (imageTypes.indexOf(extension) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  public allData(): ExcelExportData {
    this.gridData1 = filterBy(this.matecolorData, this.state.filter);
    if (this.SearchData != null) {
      this.searchListData(this.SearchData);
    }
    const result: ExcelExportData = {
      data: process(this.gridData1, { sort: [{ field: 'name' }] }).data,
    };
    return result;
  }

  onSupChange(id: string, val: boolean) {
    if (val == true) {
      this.supplierIds.push(id);
      //console.log(this.supplierIds);
    } else {
      var index = this.supplierIds.indexOf(id);
      this.supplierIds.splice(index, 1);
      //console.log(this.supplierIds);
    }
  }

  createMatSupplier() {
    this.showLoader1 = true;
    let controlleng = this.supplierIds.toString();
    if (controlleng == '') {
      // this.error('Please select supplier');
      this.toastService.showError('Please select supplier');
      this.showLoader1 = false;
      return false;
    }
    let controlleng1 = controlleng.split(',');
    let colcount = controlleng1.length;
    let materialid = this.storageservice.getMaterialId();
    //// alert(controlleng1.length)
    const formData = new FormData();
    formData.append('materialid', materialid);
    formData.append('suppickcount', colcount.toString());
    for (let i = 0; i < controlleng1.length; i++) {
      formData.append('MSsupplierid' + '[' + i + ']', this.supplierIds[i]);
      formData.append('supPickTag' + '[' + i + ']', 'Add');
    }
    this.showLoader1 = true;
    this.supplierIds = [];
    this.store.dispatch(
      new materialActionTypes.CreateMaterialSupplier(formData)
    );
    this.store
      .pipe(
        select((state) => state),
        take(2),
        skip(1)
      )
      .subscribe((materialSupplier) => {
        if (materialSupplier.material.error === '') {
          if (materialSupplier.material.message['item2'] === 'Success') {
            this.editedMaterialData =
              materialSupplier.material.message['item1'];
            this.cards = materialSupplier.material.message.item1;
            this.getMaterialSuppliers();
            // this.success(this.staticMsgs.matDetail.addSupp1);
            this.toastService.showSuccess(this.staticMsgs.matDetail.addSupp1);
            this.logger.info(
              this.staticMsgs.matDetail.addSupp,
              'MaterialDetails'
            );
            this.showLoader1 = false;
            $('.chkbxqsupplier').each(function () {
              $(this).prop('checked', false);
            });
          } else {
            this.showLoader1 = false;
            // this.error('Failed:' + materialSupplier.material.message['item1']);
            this.toastService.showError(materialSupplier.material.message['item1'])
            this.logger.error(
              materialSupplier.material.message['item1'],
              'MaterialDetails'
            );
          }
        } else {
          this.logger.error(
            materialSupplier.material.error.error.message,
            'MaterialDetails'
          );
          this.getError(materialSupplier.material.error);
          this.showLoader1 = false;
        }
      });
    /* this.dataservice.addMaterialSupplier(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          this.success("Added to Supplier Material Successfully!");
          this.logger.info("Added to Supplier Material Successfully", "MaterialDetails");
          $('.chkbxqsupplier').each(function () {
            $(this).prop('checked', false);
          });

          this.getMaterialSuppliers();

          // this.cards = response.body.item1;
          // this.editedMaterialData = response.body['item1'];
          // this.cards = response.body.item1;
          // if (this.editedMaterialData.suppliers) {
          //   this.supplierData = this.editedMaterialData.suppliers;
          //   for (let i = 0; i < this.supplierData.length; i++) {
          //     this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
          //     this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
          //     this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
          //     // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
          //     // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
          //     this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
          //     //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
          //     this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
          //     // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
          //     if (this.supplierData[i].colours != null) {
          //       for (let j = 0; j < this.supplierData[i].colours.length; j++) {
          //         this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
          //         //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
          //         this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
          //         this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
          //         this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);

          //         if (this.supplierData[i].colours[j]['hexcode']) {
          //           this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);

          //         } else if (this.supplierData[i].colours[j]['thumbnailFiles']) {
          //           this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);
          //           this.supplierData[i].colours[j]['thumbnailFiles']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['name']);
          //         }
          //       }
          //     }
          //   }
          //   this.gridSupData = process(this.supplierData, this.state);
          // }

        }
        else {
          //console.log(response)
          //alert(response.body['item2'])
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "MaterialDetails");
          this.showLoader1 = false;
        }
      }
    }, err => {
      this.logger.error(err.error.message, "MaterialDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialDetails");
      }
      this.showLoader1 = false;
    }); */
  }

  getsupplierId(Id: string) {
    this.colorSupplierId = Id;
    this.getAllColors('supcolor');
    //console.log(Id);
  }

  /*** Supplier display table Start */

  public allSupData(): ExcelExportData {
    this.filteredData = filterBy(this.apiGridData, this.state.filter);
    this.state.take = this.filteredData.length;
    if (this.state.sort)
      this.filteredData = orderBy(this.filteredData, this.state.sort);
    const result: ExcelExportData = {
      data: process(this.filteredData, this.state).data,
    };
    this.state.take = 10;

    return result;
  }

  searchListData(val: string) {
    if (val !== '') {
      let arr = [];
      this.filterQuery = val;
      this.gridData = this.matecolorData.filter((data) => {
        var search = this.filterQuery.toString().toLowerCase();
        var values = Object.values(data);
        var indicator = false;
        values.forEach((vl) => {
          if (String(vl).toLowerCase().indexOf(search) > -1) {
            indicator = true;
            return;
          }
        });
        if (indicator) {
          return data;
        }
      });
    } else {
      this.gridData = this.matecolorData;
      return;
    }
    this.gridData1 = this.gridData;
  }
  searchSupplierListData(val: string) {
    if (val !== '') {
      let arr = [];
      this.filterQuery = val;
      this.gridSupData = this.supplierData.filter((data) => {
        var search = this.filterQuery.toString().toLowerCase();
        var values = Object.values(data);
        var indicator = false;
        values.forEach((vl) => {
          if (String(vl).toLowerCase().indexOf(search) > -1) {
            indicator = true;
            return;
          }
        });
        if (indicator) {
          return data;
        }
      });
    } else {
      this.gridSupData = this.supplierData;
      return;
    }
    this.gridData1 = this.gridSupData;
  }

  async documentUploadCheck(documentControl) {
    this.doc_Error = [];

    // if (this.editedFileData.length > 0) {
    for (let i = 0; i < documentControl.length; i++) {
      if (this.editedFileData.length > 0) {
        if (i + 1 <= documentControl?.length) {
          if (this.editedFileData[i]?.length > 0) {
            this.doc_Error.push('');
          } else {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired);
            }
          }
        } else {
          if (this.fileData.length > 0) {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired);
            }
          } else {
            this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired);
          }
        }
      } else {
        if (this.fileData.length > 0) {
          if (this.fileData[i].length > 0) {
            this.doc_Error.push('');
          } else {
            this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired);
          }
        } else {
          this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired);
        }
      }
    }
    // }

    if (this.doc_Error.length > 0) {
      for (let i = 0; i < this.doc_Error.length; i++) {
        if (this.doc_Error[i] == this.staticMsgs.matDetail.documentisrequired) {
          this.statusForm = true;
          break;
        } else {
          this.statusForm = false;
        }
      }
    } else {
      this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired);
      this.statusForm = true;
    }
    return this.statusForm;
  }

  /*** Supplier display table End */
  doc_Error = [];
  statusForm: boolean = false;

  async onMaterialDocSubmit() {
    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    //console.log(this.documentsForm.value);
    this.submitted1 = true;
    if (!this.documentsForm.valid) {
      // this.error(this.staticMsgs.matDetail.invalidDoc);
      this.toastService.showError("Invalid Document Form");
      // alert("ccc")
      return false;
    }

    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    let statusUploadDoc = await this.documentUploadCheck(documentControl);
    if (statusUploadDoc == true) {
      return false;
    }
    this.showLoader1 = true;

    const formData = new FormData();
    formData.append('Materialid', this.storageservice.getMaterialId());
    formData.append('doccount', control.length.toString());
    if (this.removedDocIds) {
      formData.append('docdelete', this.removedDocIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (documentControl[i].get('docid').value) {
        formData.append(
          'docid' + '[' + i + ']',
          documentControl[i].get('docid').value
        );
        formData.append(
          'tag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
        );
      } else {
        formData.append(
          'tag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
        );
      }

      if (documentControl[i].get('docdeletedfileids').value) {
        formData.append(
          'filedelete' + '[' + i + ']',
          documentControl[i].get('docdeletedfileids').value
        );
      }

      formData.append(
        'baseclassification' + '[' + i + ']',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Documents')
      );
      formData.append(
        'docclassification' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          documentControl[i].get('docclassification').value
        )
      );
      formData.append(
        'docname' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          documentControl[i].get('docname').value
        )
      );
      formData.append(
        'docstatus' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          documentControl[i].get('docstatus').value
        )
      );
      formData.append(
        'docdescription' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          documentControl[i].get('docdescription').value
        )
      );
      formData.append(
        'docreportstatus' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          documentControl[i].get('docreportstatus').value
        )
      );
      formData.append(
        'doclablocation' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          documentControl[i].get('doclablocation').value
        )
      );
      formData.append(
        'docnotes' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          documentControl[i].get('docnotes').value
        )
      );
      formData.append('docfilecount' + '[' + i + ']', this.fileData[i].length);

      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '[]') {
          //console.log (f.name);
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != 'error') {
            formData.append(
              'file' + i + '[' + a + ']',
              this.aesdecryptservice.encryptData(
                this.dataservice.Key,
                JSON.parse(fileUploadedData)['Key']
              )
            );
            formData.append(
              'filename' + i + '[' + a + ']',
              this.aesdecryptservice.encryptData(this.dataservice.Key, f.name)
            );
          } else {
            // this.error(fileUploadedData);
            this.toastService.showError(fileUploadedData);
            this.submitted1 = false;
            this.showLoader = false;
            return false;
          }
        }
        a = a + 1;
      }
    }
    this.showLoader1 = true;
    this.store.dispatch(
      new materialActionTypes.UpdateMaterialDocument(formData)
    );
    this.store
      .pipe(
        select((state) => state),
        take(2),
        skip(1)
      )
      .subscribe((materialDocument) => {
        if (materialDocument.material.error === '') {
          if (materialDocument.material.message['item2'] === 'Success') {
            this.loadSelectPicker();
            setTimeout(() => {
              $('.selectpicker').selectpicker();
            }, 10);
            this.showLoader1 = false;
            this.submitted1 = false;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.documentsForm.reset();
            this.documentsForm = this.fb.group({
              documents: this.fb.array([]),
            });
            this.editedMaterialData =
              materialDocument.material.message['item1'];
            //console.log(this.editedMaterialData);
            if (this.editedMaterialData.documents.length > 0) {
              this.editedFileData = [];
              this.removedDocIds = '';
              this.isFilesAdded = true;
              let editeddata = JSON.stringify(
                this.editedMaterialData.documents
              );
              let data = JSON.parse(editeddata);
              this.reloadDocumentFormData(data);
            }
            this.showLoader1 = false;
            //this.docDetailsTab('docDetailsTab');
            // this.success(this.staticMsgs.matDetail.docUpd1);
            this.toastService.showSuccess(this.staticMsgs.matDetail.docUpd1);
            this.logger.info(
              this.staticMsgs.matDetail.docUpd,
              'MaterialDetails'
            );
          } else {
            this.showLoader1 = false;
            this.refreshTree();
            // this.error('Failed:' + materialDocument.material.message?.item1);
            this.toastService.showError(materialDocument.material.message?.item1);
            this.logger.error(
              materialDocument.material.message?.item1,
              'MaterialDetails'
            );
          }
        } else {
          this.logger.error(
            materialDocument.material.error.error.message,
            'MaterialDetails'
          );
          this.getError(materialDocument.material.error);
          this.showLoader1 = false;
        }
      });

    /* this.dataservice.AddupdateMaterialDocuments(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.loadSelectPicker();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          this.showLoader1 = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.removedDocIds = "";
          this.editedFileData = [];
          this.editedMaterialData = response.body['item1'];
          //console.log(this.editedMaterialData);
          if (this.editedMaterialData.documents.length > 0) {
            this.editedFileData = [];

            let editeddata = JSON.stringify(this.editedMaterialData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          //this.docDetailsTab('docDetailsTab');
          this.success("Documents Updated Successfully!");
          this.logger.info("Documents Updated Successfully", "MaterialDetails");
          this.showLoader1 = false;
        }
        else {
          //console.log(response)
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "MaterialDetails");
          this.showLoader1 = false;
        }

      }
    }, err => {
      this.logger.error(err.error.message, "MaterialDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialDetails");
      }
      this.showLoader1 = false;
    }); */
  }

  addNewDoc() {
    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    let control = <FormArray>this.documentsForm.controls.documents;
    //alert(control.length)
    control.push(
      this.fb.group({
        docclassification: ['', Validators.required],
        docid: [''],
        docseq: [''],
        docname: [
          '',
          [Validators.required, Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')],
        ],
        docstatus: ['', Validators.required],
        docdescription: ['', [Validators.required, Validators.maxLength(50)]],
        docreportstatus: ['', Validators.required],
        doclablocation: ['', [Validators.required, Validators.maxLength(50)]],
        docnotes: ['', Validators.maxLength(500)],
        docdeletedfileids: [''],
        materialid: [''],
      })
    );
    this.files = [];
    this.fileData[control.length - 1] = this.files;
    this.showButton = true;
    this.refreshDocTree(control.length - 1);
  }

  documentTab(activeTab) {
    this.getDocumentClassification('Documents');
    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    this.showLoader = true;
    this.activeTab = activeTab;
    this.isColorButton = false;
    if (this.editedMaterialData.documents) {
      if (this.editedMaterialData.documents.length > 0) {
        this.editedFileData = [];
        this.removedDocIds = '';
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([]),
          });
          this.showLoader = false;
        }
        let editeddata = JSON.stringify(this.editedMaterialData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      } else {
        this.showLoader = false;
      }
    } else {
      this.showLoader = false;
    }
  }

  setDocumentTreeValue(index, value) {
    $(document).ready(function () {
      $('#docclassification' + index)
        .data('kendoDropDownTree')
        .value(value);
    });
  }
  reloadDocumentFormData(data) {
    //console.log(JSON.stringify(data));
    for (let i = 0; i < data.length; i++) {
      this.addNewDoc();
      this.refreshDocTree(i);
    }
    //console.log(JSON.stringify(data))
    let documentControl = this.getControls();
    for (let i = 0; i < data.length; i++) {
      documentControl[i].get('docid').setValue(data[i]['id']);
      documentControl[i].get('docseq').setValue(data[i]['sequence']);
      this.refreshDocTree(i);
      if (this.isEditingMode) {
        //console.log("Coming")
        this.setDocumentTreeValue(i, data[i]['classification']);
      }
      documentControl[i]
        .get('docclassification')
        .setValue(data[i]['classification']);
      documentControl[i].get('docname').setValue(data[i]['name']);
      documentControl[i].get('docstatus').setValue(data[i]['status']);
      $('select[name=docstatus]').selectpicker('refresh');

      // this.documentsForm.get('docstatus').setValue(data[i]["status"]);

      documentControl[i].get('docdescription').setValue(data[i]['description']);
      documentControl[i]
        .get('docreportstatus')
        .setValue(data[i]['reportStatus']);
      documentControl[i].get('doclablocation').setValue(data[i]['labLocation']);
      if (data[i]['classification'] === 'Lab Reports') {
        documentControl[i].get('docreportstatus').enable();
        documentControl[i].get('doclablocation').enable();
      } else {
        documentControl[i].get('docreportstatus').disable();
        documentControl[i].get('doclablocation').disable();
      }
      documentControl[i].get('docnotes').setValue(data[i]['notes']);
      // if (data[i]["files"][0]) {
      //   // this.editedFileData=data[i]["files"];
      //   data[i]["files"][0]['thumbnail'] = data[i]["files"][0]['thumbnail'];
      //   data[i]["files"][0]['name'] = data[i]["files"][0]['name'];
      // }
      this.editedFileData.push(data[i]['files']);
      this.materialDataCnt = this.fileData[i].length + 1;
      this.isFilesAdded = true;
    }
    this.refreshSelect();
  }

  refreshDocTree(index: number) {
    var data = this.docclassification;
    var currentDocument = this.getControls();
    // alert(currentDocument)
    //console.log(currentDocument[index].get('docclassification'))
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      var treeview = $('#docclassification' + index).kendoDropDownTree({
        placeholder: 'Select Classification',
        height: 'auto',
        dataSource: data,
        filter: 'startswith',
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault();
            }
          },
        },
        change: function (e) {
          var value = this.value();
          // this.loadSelectPicker();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          //alert(value);
          if (value) {
            //self.documentclassificationValue = value["text"];
            //console.log(value["text"])
            currentDocument[index]
              .get('docclassification')
              .setValue(value['text']);
            if (value['text'] === 'Lab Reports') {
              currentDocument[index].get('docreportstatus').enable();
              currentDocument[index].get('doclablocation').enable();
            } else {
              currentDocument[index].get('docreportstatus').disable();
              currentDocument[index].get('doclablocation').disable();
            }
          } else {
            //self.documentclassificationValue = "";
            currentDocument[index].get('docclassification').setValue('');
          }
        },
      });
    });
  }

  removeDoc(index) {
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    if (this.editedFileData.length !== 1) {
      if (this.removedDocIds) {
        this.removedDocIds =
          this.removedDocIds + ',' + documentControl[index].get('docid').value;
      } else {
        this.removedDocIds = documentControl[index].get('docid').value;
      }
    } else {
      alert('Minimum 1 document is required');
      return false;
    }
    control.removeAt(index);
    this.fileData.splice(index, 1);
    //console.log(this.fileData);
    this.editedFileData.splice(index, 1);
    //this.removedFileData[index].push([]);
  }
  onSelectDoc(event, i) {
    this.files = [];
    let imgext = 'png,jpeg,jpg,xlsx,pdf,txt,docx,zip,pptx';
    var str = event.addedFiles[0].name.split('.')[1]?.toLowerCase();
    if (event.addedFiles.length === 1 && imgext.includes(str)) {
      this.files.push(...event.addedFiles);
      this.isFilesAdded = true;
      this.doc_Error[i] = '';
    } else {
      this.isFilesAdded = false;
      // this.error('File Not Supported.');
      this.toastService.showError('File Not Supported.');
      return false;
    }
    this.fileData[i] = this.files;
  }

  onRemove(event, i) {
    this.fileData[i].splice(this.fileData[i].indexOf(event), 1);
    //console.log(this.fileData);
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  onExpand(e) {
    e.sender.tbody.find('.k-detail-row').each(function (idx, item) {
      if (item !== e.detailRow[0]) {
        e.sender.collapseRow($(item).prev());
      }
    });
    this.colorSupplierId = e;
  }

  editMaterial() {
    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);

    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;
    if (this.activeTab === 'materialDetailsTab') {
      this.isThumbnailAdded = true;
      if (this.storageservice.getMaterialId()) {
        this.getMaterialDetails();
      }
      this.initForm();
      this.loadSelectPicker();
    } else if (this.activeTab === 'documentTab') {
      this.getDocumentClassification('Documents');
      this.isFilesAdded = true;
      this.showButton = false;
      this.loadSelectPicker();
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);

      this.showLoader1 = true;
      if (this.editedMaterialData.documents) {
        if (this.editedMaterialData.documents.length > 0) {
          this.editedFileData = [];
          this.removedDocIds = '';
          //this.removedFileData = [];
          let control = <FormArray>this.documentsForm.controls.documents;
          if (control.length > 0) {
            this.documentsForm = this.fb.group({
              documents: this.fb.array([]),
            });
          }
          let editeddata = JSON.stringify(this.editedMaterialData.documents);
          let data = JSON.parse(editeddata);
          this.reloadDocumentFormData(data);
          this.showLoader1 = false;
        } else {
          this.showLoader1 = false;
        }
      } else {
        this.showLoader1 = false;
      }
    }
  }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }
  cancelMaterialEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents') as FormArray).clear();
    if (this.storageservice.getMaterialId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getMaterialDetails();
    } else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }
    this.initForm();
    ///this.loadSelectPicker();
  }
  cancelDocumentEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents') as FormArray).clear();
    this.isThumbnailAdded = true;
    if (this.storageservice.getMaterialId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
    } else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.showLoader = true;
    if (this.editedMaterialData.documents) {
      if (this.editedMaterialData.documents.length > 0) {
        this.editedFileData = [];
        this.isFilesAdded = true;
        this.removedDocIds = '';
        //this.removedFileData = [];
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([]),
          });
        }
        let editeddata = JSON.stringify(this.editedMaterialData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      } else {
        this.showLoader = false;
      }
    } else {
      this.showLoader = false;
    }
  }

  cancelMaterialCreate() {
    this.router.navigate(['/materiallibrary']);
    this.isFilesAdded = true;
  }
  onDeleteMaterial() {
    Swal.fire({
      title: this.staticMsgs.matDetail.deleteMat,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.matDetail.deleteit,
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getMaterialId());
        this.store.dispatch(
          new materialActionTypes.DeleteMaterialLibrary(deleterecordIds)
        );
        this.store
          .pipe((state) => state, take(2), skip(1))
          .subscribe((deletedMaterialLibrary) => {
            if (deletedMaterialLibrary.material.error === '') {
              if (
                deletedMaterialLibrary.material.message?.item2 === 'Success'
              ) {
                this.showLoader = false;
                this.logger.info(
                  this.staticMsgs.matDetail.matDelete,
                  'MaterialLibrary'
                );
                // this.success(this.staticMsgs.matDetail.matDelete1);
                this.toastService.showSuccess(this.staticMsgs.matDetail.matDelete1);
                this.router.navigate(['/materiallibrary']);
              } else {
                this.showLoader = false;
                // this.error(
                //   'Failed:' + deletedMaterialLibrary.material.message?.item1
                // );
                this.toastService.showError(deletedMaterialLibrary.material.message?.item1);
                this.logger.error(
                  deletedMaterialLibrary.material.message?.item1,
                  'MaterialLibrary'
                );
              }
            } else {
              this.logger.error(
                deletedMaterialLibrary.material.error.error.message,
                'MaterialLibrary'
              );
              this.getError(deletedMaterialLibrary.material.error);
              this.showLoader = false;
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
}
