import { Injectable } from '@angular/core';
import * as Crypto from 'crypto-js';

@Injectable({
    providedIn: 'root'
  })
 
  export class AESDecryptService {
     keySize = 256;
     ivSize = 128;
     iterations = 100;
   
    decryptData(key, ciphertextB64) {  
      if(ciphertextB64!=null&& ciphertextB64!=""){
        var key = Crypto.enc.Utf8.parse(key);                             // Convert into WordArray (using Utf8)
        var iv = Crypto.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);   // Use zero vector as IV
        var decrypted = Crypto.AES.decrypt(ciphertextB64, key, {iv: iv}); // By default: CBC, PKCS7 
        return decrypted.toString(Crypto.enc.Utf8);  
        
      } else{return ciphertextB64}                           // Base64 encoded ciphertext, 32 bytes string as key
                          // Convert into string (using Utf8)
  }
  encryptData(key, str) {                              // Base64 encoded ciphertext, 32 bytes string as key
    var key = Crypto.enc.Utf8.parse(key);                             // Convert into WordArray (using Utf8)
    var iv = Crypto.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);   // Use zero vector as IV
    var encrypted = Crypto.AES.encrypt(str, key, {iv: iv}); // By default: CBC, PKCS7 
    return encrypted.toString();                       // Convert into string (using Utf8)
}   
 
 
    
    
    
}