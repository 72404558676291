import * as paletteActions from "../actions/palette.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface PaletteState extends EntityState<any> {
    selectedPaletteId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const paletteAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultPalette: PaletteState = {
    ids: [],
    entities: {},
    selectedPaletteId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = paletteAdapter.getInitialState(defaultPalette);

export function PaletteReducer(state = initialState, action: paletteActions.paletteActionTypes): PaletteState {
    switch (action.type) {
        case paletteActions.PaletteActionTypes.LOAD_PALETTE_LIBRARY_SUCCESS:
            return paletteAdapter.addMany(action.payload1, {...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case paletteActions.PaletteActionTypes.LOAD_PALETTE_LIBRARY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case paletteActions.PaletteActionTypes.LOAD_PALETTE_SUCCESS:
            return paletteAdapter.setOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedPaletteId: action.payload1.id
            })
            break;
        case paletteActions.PaletteActionTypes.LOAD_PALETTE_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case paletteActions.PaletteActionTypes.CREATE_PALETTE_SUCCESS:
            return paletteAdapter.addOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedPaletteId: action.payload1.id
            })
            break;
        case paletteActions.PaletteActionTypes.CREATE_PALETTE_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case paletteActions.PaletteActionTypes.UPDATE_PALETTE_SUCCESS:
            return paletteAdapter.updateOne(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case paletteActions.PaletteActionTypes.UPDATE_PALETTE_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case paletteActions.PaletteActionTypes.UPDATE_PALETTE_LIBRARY_SUCCESS:
            return paletteAdapter.updateMany(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case paletteActions.PaletteActionTypes.UPDATE_PALETTE_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case paletteActions.PaletteActionTypes.DELETE_PALETTE_LIBRARY_SUCCESS:
            return paletteAdapter.removeMany(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case paletteActions.PaletteActionTypes.DELETE_PALETTE_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case paletteActions.PaletteActionTypes.UPDATE_PALETTE_DOCUMENT_SUCCESS:
            return paletteAdapter.updateOne(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case paletteActions.PaletteActionTypes.UPDATE_PALETTE_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getPaletteLibraryFeatureState = createFeatureSelector<PaletteState>('palette');

export const getPaletteLibrary = createSelector(
    getPaletteLibraryFeatureState, paletteAdapter.getSelectors().selectAll
)
export const getPaletteLibraryLoading = createSelector(
    getPaletteLibraryFeatureState, (state: PaletteState) => state.loading
)
export const getPaletteLibraryLoaded = createSelector(
    getPaletteLibraryFeatureState, (state: PaletteState) => state.loaded
)
export const getPaletteLibraryError = createSelector(
    getPaletteLibraryFeatureState, (state: PaletteState) => state.error
)
export const getPaletteLibraryMessage = createSelector(
    getPaletteLibraryFeatureState, (state: PaletteState) => state.message
)
export const getPaletteId = createSelector(
    getPaletteLibraryFeatureState, (state: PaletteState) => state.selectedPaletteId
)
export const getPalette = createSelector(
    getPaletteLibraryFeatureState, getPaletteId, state => state.entities[state.selectedPaletteId]
)