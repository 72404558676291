import * as materialActions from "../actions/material.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface MaterialState extends EntityState<any> {
    selectedMaterialId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const materialAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultMaterial: MaterialState = {
    ids: [],
    entities: {},
    selectedMaterialId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = materialAdapter.getInitialState(defaultMaterial);

export function MaterialReducer(state = initialState, action: materialActions.materialActionTypes): MaterialState {
    switch (action.type) {
        case materialActions.MaterialActionTypes.LOAD_MATERIAL_LIBRARY_SUCCESS:
            return materialAdapter.addMany(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case materialActions.MaterialActionTypes.LOAD_MATERIAL_LIBRARY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.LOAD_MATERIAL_SUCCESS:
            return materialAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMaterialId: action.payload1.id
            })
            break;
        case materialActions.MaterialActionTypes.LOAD_MATERIAL_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.LOAD_MATERIAL_SUPPLIER_SUCCESS:
            return materialAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMaterialId: action.payload1.id
            })
            break;
        case materialActions.MaterialActionTypes.LOAD_MATERIAL_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.LOAD_MATERIAL_COLOR_SUCCESS:
            return materialAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMaterialId: action.payload1.id
            })
            break;
        case materialActions.MaterialActionTypes.LOAD_MATERIAL_COLOR_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.CREATE_MATERIAL_SUCCESS:
            return materialAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMaterialId: action.payload1.id
            })
            break;
        case materialActions.MaterialActionTypes.CREATE_MATERIAL_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.UPDATE_MATERIAL_SUCCESS:
            return materialAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case materialActions.MaterialActionTypes.UPDATE_MATERIAL_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.UPDATE_MATERIAL_LIBRARY_SUCCESS:
            return materialAdapter.updateMany(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case materialActions.MaterialActionTypes.UPDATE_MATERIAL_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.DELETE_MATERIAL_LIBRARY_SUCCESS:
            return materialAdapter.removeMany(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case materialActions.MaterialActionTypes.DELETE_MATERIAL_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.UPDATE_MATERIAL_DOCUMENT_SUCCESS:
            return materialAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case materialActions.MaterialActionTypes.UPDATE_MATERIAL_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.CREATE_MATERIAL_COLOR_SUCCESS:
            return materialAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMaterialId: action.payload1.id
            })
            break;
        case materialActions.MaterialActionTypes.CREATE_MATERIAL_COLOR_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_SUCCESS:
            return materialAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMaterialId: action.payload1.id
            })
            break;
        case materialActions.MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case materialActions.MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_COLOR_SUCCESS:
            return materialAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedMaterialId: action.payload1.id
            })
            break;
        case materialActions.MaterialActionTypes.CREATE_MATERIAL_SUPPLIER_COLOR_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getMaterialLibraryFeatureState = createFeatureSelector<MaterialState>('material');

export const getMaterialLibrary = createSelector(
    getMaterialLibraryFeatureState, materialAdapter.getSelectors().selectAll
)
export const getMaterialLibraryLoading = createSelector(
    getMaterialLibraryFeatureState, (state: MaterialState) => state.loading
)
export const getMaterialLibraryLoaded = createSelector(
    getMaterialLibraryFeatureState, (state: MaterialState) => state.loaded
)
export const getMaterialLibraryError = createSelector(
    getMaterialLibraryFeatureState, (state: MaterialState) => state.error
)
export const getMaterialLibraryMessage = createSelector(
    getMaterialLibraryFeatureState, (state: MaterialState) => state.message
)
export const getMaterialId = createSelector(
    getMaterialLibraryFeatureState, (state: MaterialState) => state.selectedMaterialId
)
export const getMaterial = createSelector(
    getMaterialLibraryFeatureState, getMaterialId, state => state.entities[state.selectedMaterialId]
)