import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { timer, Subscription, interval } from 'rxjs';
//import { first } from 'rxjs/operators';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
// tslint:disable-next-line: import-spacing
import { StorageService } from  '../../../helpers/storage.service';
import { TokenService } from '../../../helpers/token.service';
import { UserService } from '../../../helpers/user.service';
import { ExceptionService } from '../../../helpers/exception.service';
import { Auth } from 'aws-amplify';
import { CustomValidators } from '../register/custom-validators';
import { LogService } from '../../../shared/log.service';
import { DataService } from '../../../services/data.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';

declare var $: any;


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit{

  @ViewChild(LoaderComponent) child: LoaderComponent;
  showLoader: boolean;
  countDown: Subscription;
  counter = 60;
  tick = 1000;
  staticMsgs:any;
  forgotPassword: FormGroup;
  resetPassword: FormGroup;
    submitted = false;
    errors = '';
    errorDiv: boolean;
    password: boolean;
    alert = '';
    //disabled: boolean;
    fpassword: boolean = true;
    fusername = "";
    errors1 = '';
    errorDiv1: boolean;
    alert1 = '';
    readonly: boolean = false;
    codeExpired: boolean = false;
log:any[];
  constructor(private fb: FormBuilder,
              private router: Router,
              private auth: AuthService,
              private storage: StorageService,
              private token: TokenService,
              private exception: ExceptionService,
              private logger:LogService,
              private dataService:DataService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    $(document).ready(function () {
      $("[data-toggle='popover']").popover({ html: true });

      if (!('ontouchstart' in window)) {
        $('.tip').tooltip();
      }

      $(function passwordValidation() {

        var pwdInput = $('#password');
        var pwdValid = false;

        function validatePwdStrength() {

          var pwdValue = $(this).val(); // This works because when it's called it's called from the pwdInput, see end

          // Validate the length
          if (pwdValue.length > 7) {
            $('#length').removeClass('invalid').addClass('valid');
            pwdValid = true;
          } else {
            $('#length').removeClass('valid').addClass('invalid');
          }

          // Validate capital letter
          if (pwdValue.match(/[A-Z]/)) {
            $('#capital').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#capital').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // Validate lowercase letter
          if (pwdValue.match(/[a-z]/)) {
            $('#lowercase').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#lowercase').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // special character
          if (pwdValue.match(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            $('#number-special').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#number-special').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // Validate number
          if (pwdValue.match(/[\d\\]/)) {
            $('#number-special1').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#number-special1').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }
        }

        pwdInput.bind('change keyup input', validatePwdStrength); // Keyup is a bit unpredictable

      }); // END passwordValidation()

      $('#password').focusin(function () {
        $('#password-validation-box').show();
      }).focusout(function () {
        $('#password-validation-box').hide();
      });

      $('.toggle-password').click(function() {

        $(this).toggleClass('fa-eye fa-eye-slash');
        const input = $($(this).attr('toggle'));
        if (input.attr('type') === 'password') {
          input.attr('type', 'text');
        } else {
          input.attr('type', 'password');
        }
      });

    });



    this.initForm();

  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataService.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  initForm() {


    const otp = localStorage.getItem('otp_sent');
    this.fusername = localStorage.getItem('fusername');

    this.errorDiv = true;
    this.errors = this.staticMsgs.forgotpassword.errorMsg;
    this.errorDiv1 = true;
    this.errors1 =  this.staticMsgs.forgotpassword.enterPassword + "<a href='javascript:void(0)'>" + this.fusername+"</a> .";

    if (otp === '1'){
      this.fpassword = false;
    }
    this.forgotPassword = this.fb.group({
      username: ['', [Validators.required,Validators.email]]
    });

    this.resetPassword = this.fb.group({
      code: ['', [Validators.required]],
      password: ['', [Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8), Validators.maxLength(15)]],
        confirmpassword: ['', [Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8), Validators.maxLength(15)]]},
       {
     // validator: ConfirmedValidator('password', 'confirmpassword')
     // check whether our password and confirm password match
     validator: CustomValidators.passwordMatchValidator(this.staticMsgs.common.password, this.staticMsgs.forgotpassword.cp)
      });

  }

  get f() { return this.forgotPassword.controls; }
  get a() { return this.resetPassword.controls; }

  async onSubmit(){

    this.submitted = true;

    if (this.forgotPassword.invalid) {
        return;
    }
    this.showLoader = true;
    //this.disabled = true;
    const username = this.f.username.value;

    Auth.forgotPassword(username)
    .then(data => {
      this.showLoader = false;
      console.log(data);
      // alert(JSON.stringify(data));
      this.alert = this.staticMsgs.verifyuser.success;
      this.errorDiv = true;
      this.errors = "<div class='alert alert-success'>"+this.staticMsgs.verifyuser.verificationCode2+"</div>"
      this.logger.info(this.staticMsgs.verifyuser.verificationCode2,this.staticMsgs.forgotpassword.Fp);

      //this.fpassword = false;

      this.readonly = true;
      localStorage.setItem('otp_sent', '1');
      localStorage.setItem('fusername', username);
      location.reload();
    }).catch(err => {
      this.showLoader = false;
      //this.disabled = false;
      // console.log(err)
      if (err.code === 'UserNotFoundException'){
          this.alert = this.staticMsgs.verifyuser.danger;
          this.errorDiv = true;
          this.errors = "<div class='alert alert-danger'>"+this.staticMsgs.forgotpassword.EmailDoennotExists+"</div>";
        this.logger.error(this.staticMsgs.forgotpassword.EmailDoennotExists,this.staticMsgs.forgotpassword.Fp);

      }else{
          this.alert = this.staticMsgs.forgotpassword.danger;
          this.errorDiv = true;
          this.errors = "<div class='alert alert-danger'>"+err.message+"</div>";
        this.logger.error(err.message,this.staticMsgs.forgotpassword.Fp);

      }
    });
  }


  async verifyPasswordSubmit(){

    this.submitted = true;
    const lpassword = localStorage.getItem('login_password');
    const password = this.a.password.value,
    confirmpassword = this.a.confirmpassword.value;
    if(password==lpassword){
    this.resetPassword.invalid;
    this.errorDiv = true;
    this.alert = this.staticMsgs.forgotpassword.danger;
    this.errors = 'New Password and Old Password should not be same';
}
else if(password!=confirmpassword){
  this.resetPassword.invalid;
  this.errorDiv = true;
  this.alert = this.staticMsgs.forgotpassword.danger;
  this.errors = 'New Password and Confirm Password should same';
}
   else if (this.resetPassword.invalid) {
        return;
    }
    else{
    this.showLoader = true;
    //this.disabled = true;
    const code = this.a.code.value;
    // tslint:disable-next-line: variable-name
    const new_password = this.a.password.value;

    //alert(username + ' - ' + code + ' - ' + new_password);


    Auth.forgotPasswordSubmit(this.fusername, code, new_password)
    .then(data => {
      this.showLoader = false;
     // console.log(data);
      this.alert1 = this.staticMsgs.forgotpassword.success;
      this.errorDiv1 = true;
      this.errors1 = "<div class='alert alert-success'>"+this.staticMsgs.forgotpassword.passwordUpdate+"</div>";
      this.logger.info(this.staticMsgs.forgotpassword.updateSucessfully,this.staticMsgs.forgotpassword.Fp);
      // setTimeout(() => {
      //   this.router.navigate(['auth/login']);
      // },2000);
      this.dataService.AddClientLog();
      this.router.navigate(['/auth/login']);
    })
    .catch(err => {
      this.showLoader = false;
      //this.disabled = false;
      if (err.code === 'CodeMismatchException'){
          this.alert1 = this.staticMsgs.forgotpassword.danger;
          this.errorDiv1 = true;
          this.errors1 = "<div class='alert alert-danger'>"+err.message+"</div>";
        this.logger.error(err.message,this.staticMsgs.forgotpassword.Fp);

      }else if (err.code === 'ExpiredCodeException'){
          this.alert1 =this.staticMsgs.forgotpassword.danger;
          this.errors1 = "";
         // this.errorDiv1 = true;
          //this.errors1 = "<div class='alert alert-danger'>"+err.message+"</div>";
          this.codeExpired = true;
      }else if (err.code === 'LimitExceededException'){
        this.alert1 = this.staticMsgs.forgotpassword.danger;
        this.errorDiv1 = true;
        this.errors1 = "<div class='alert alert-danger'>"+err.message+"</div>";
        this.logger.error(err.message,this.staticMsgs.forgotpassword.Fp);
        localStorage.removeItem('otp_sent');
        localStorage.removeItem('fusername');
        // setTimeout(() => {
        //     this.router.navigate(['auth/login']);
        // }, 2000);
        this.dataService.AddClientLog();
        this.router.navigate(['/auth/login']);
      }else{
          this.alert1 = this.staticMsgs.forgotpassword.danger;
          this.errorDiv1 = true;
          this.errors1 = "<div class='alert alert-danger'>"+err.message+"</div>";
        this.logger.error(err.message,this.staticMsgs.forgotpassword.Fp);

      }
      //console.log(err);
    });

  }
  }

  backtoPage(){
    localStorage.setItem('otp_sent', '0');
    this.fpassword = true;
    //this.onSubmit();
  }

  resendOTP(){
    this.fusername = localStorage.getItem('fusername');
    if (this.fusername){
      this.showLoader = true;
      Auth.forgotPassword(this.fusername)
      .then(data => {
        this.showLoader = false;
        //console.log(data);
        this.showLoader = false;
        // alert(JSON.stringify(data));
        this.codeExpired = false;
        this.alert1 = this.staticMsgs.forgotpassword.success;
        this.errorDiv1 = true;
        this.errors1 =  this.staticMsgs.forgotpassword.resetCode+"<a href='javascript:void(0)'>"+this.fusername+"</a> Successfully.";
        this.logger.info(this.staticMsgs.forgotpassword.resendPassCodeSucess,this.staticMsgs.forgotpassword.Fp);

        //this.fpassword = false;

        localStorage.setItem('otp_sent', '1');
        localStorage.setItem('fusername', this.fusername);
        location.reload();
      }).catch(err => {
        this.showLoader = false;
        this.alert1 = this.staticMsgs.forgotpassword.danger;
        this.errorDiv1 = true;
        this.errors1 = err.message;
        this.logger.error(err.message,this.staticMsgs.forgotpassword.Fp);


      });
    }else{
      this.backtoPage();
    }
  }

}


