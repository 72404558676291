import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../helpers/user.service';
import { StorageService } from '../helpers/storage.service';

//Comments:-
//The auth guard is used to prevent unauthenticated users from accessing restricted routes.
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  constructor(private router: Router, private userService: UserService, private storage: StorageService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   const token = this.userService.loggedIn();
  //   if (token === false) {
  //     // not logged in so redirect to login page
  //     this.router.navigate(['auth/login'], { queryParams: { 'returnUrl': state.url } });
  //     return false;
  //   }
  //   // authorised so return true
  //   return true;
  // }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    const token = this.userService.loggedIn();
    if (token === true) {

      let userRole = this.storage.getUserMainRole();
      if(this.storage.getUserSubRole())
      {
        userRole = this.storage.getUserSubRole();
      }
     // alert(route.data.roles)
     // alert(route.data.roles.indexOf(userRole))
      if (route.data.roles && route.data.roles.indexOf(userRole) === -1) {
        this.router.navigate(['/error'], { queryParams: { 'returnUrl': url } });
        return false;
      }
      return true;
    }    
    this.router.navigate(['auth/login'], { queryParams: { 'returnUrl': url } });
    return false;
  }

}
