<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>

  <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">

    <!-- Site Content -->
    <div class="dt-content">
      <div class="row">
        <!-- Page Header -->
        <div class="col-xl-4 col-sm-4 col-md-4">
          <div class="dt-page__header">
            <h3 class="dt-page__title" *ngIf="!isEditMode">{{appStaticMsgs.stitches.createstitches}}</h3>
            <h3 class="dt-page__title" *ngIf="isEditMode">{{appStaticMsgs.stitches.stitchesdetails}}</h3>
            <nav class="mb-0 breadcrumb">
              <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/dashboard"><i class="fa fa-home" aria-hidden="true"></i>            </a>
              <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/stitcheslibrary">
                <span class="active breadcrumb-item" *ngIf="isEditMode || !isEditMode">{{appStaticMsgs.stitcheslibrary.sticheslibrary}}</span>
              </a>
              <span class="active breadcrumb-item" *ngIf="!isEditMode">{{appStaticMsgs.stitches.createstitches}}</span>
              <span class="active breadcrumb-item" *ngIf="isEditMode">{{appStaticMsgs.stitches.stitchesdetails}} / {{ sequenceNumber }} </span>
          </nav>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-md-4 d-flex justify-content-center" *ngIf="isEditMode">
          <ul class="dt-nav">
            <button  *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" (click)="editStitches()"
              ngbTooltip="Edit Stitches">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button  *ngIf="isEditingMode" type="button" [ngClass]="isEditingMode ? 'icon-active' : 'btn-color-primary'" class="btn mr-3 round" (click)="cancelStitchesEdit()"
              ngbTooltip="Cancel Edit Stitches">
              <i class="fa fa-times text-white" aria-hidden="true"></i>
            </button>
            <button  *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" (click)="onDeleteStiches()"
            ngbTooltip="Delete">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
          </ul>
        </div>
        <!-- <div class="col-xl-6">

          <ul class="dt-nav">

            <li class="dt-nav__item">
              <i class="fa fa-pencil-square-o ul-icon" aria-hidden="true"></i>
            </li>

            <li class="dt-nav__item">
              <i class="fa fa-star-o ul-icon" aria-hidden="true"></i>
            </li>

            <li class="dt-nav__item">
              <i class="fa fa-share-alt ul-icon" aria-hidden="true"></i>
            </li>

            <li class="dt-nav__item">
              <i class="fa fa-trash-o ul-icon" aria-hidden="true"></i>
            </li>

            <li class="dt-nav__item">
              <i class="fa fa-trash-o ul-icon" aria-hidden="true"></i>
            </li>

          </ul>
        </div> -->
      </div>
      <!-- /page header -->


      <div class="profile-content">

        <!-- Grid -->
        <div class="row">

          <!-- Grid Item -->
          <div class="wd-300"  *ngIf="isViewingMode">
            <!-- /* Written by : Himanshu */ -->
            <ng-image-slider  class="sidebar_sliders sidebar_image"  [images]="imageObject" *ngIf="imageObject.length>0" [infinite]="false" [autoSlide]="1"
              slideImage="1" [imageSize]="{width: '100%', height: 200}">
            </ng-image-slider>

              <div class="accordion mt-5" id="accordion-example">
                    <div class="card" *ngIf="editedStitchesData">
                      <div class="" id="headingOne">
                        <h5 class="mb-0">
                          <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                            data-target="#collapse-one" aria-expanded="false" aria-controls="collapse-one">
                            {{appStaticMsgs.stitches.stichesinfo}}
                            <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                          </h4>
                        </h5>
                      </div>

                      <div id="collapse-one" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-example">
                        <div class="card-body bg-white p-4" *ngIf="editedStitchesData">
                          <div class="row">

                            <div class="col-md-5 col-sm-5 col-xl-5">
                              <p class="card_text">{{appStaticMsgs.stitches.iso4915number}}:</p>
                            </div>
                            <div class="col-md-7 col-sm-7 col-xl-7">
                              <p class="a-font">{{editedStitchesData.isO4915Number}} </p>
                            </div>
                          </div>
                          <div class="row">

                            <div class="col-md-5 col-sm-5 col-xl-5">
                              <p class="card_text">{{appStaticMsgs.common.status}}:</p>
                            </div>
                            <div class="col-md-7 col-sm-7 col-xl-7">
                              <p class="a-font">{{editedStitchesData.status}} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card" *ngIf="editedStitchesData">
                      <div class="" id="headingTwo">
                        <h5 class="mb-0">
                          <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                            data-target="#collapse-two" aria-expanded="false" aria-controls="collapse-one">
                           {{appStaticMsgs.common.userinfo}}
                            <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                          </h4>
                        </h5>

                      </div>
                      <div id="collapse-two" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion-example">
                        <div class="card-body bg-white p-4" *ngIf="editedStitchesData.createdBy">
                          <div class="row">

                            <div class="col-md-5 col-sm-5 col-xl-5">
                              <p class="card_text">{{appStaticMsgs.common.createdby}}</p>
                            </div>
                            <div class="col-md-7 col-sm-7 col-xl-7">
                              <p class="a-font">{{editedStitchesData.createdBy.firstName}}</p>
                            </div>
                          </div>
                          <div class="row">

                            <div class="col-md-5 col-sm-5 col-xl-5">
                              <p class="card_text">{{appStaticMsgs.common.createdon}}</p>
                            </div>
                            <div class="col-md-7 col-sm-7 col-xl-7">
                              <p class="a-font">
                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                {{editedStitchesData.createdOn |date: "dd MMM yyyy"}}
                              </p>
                              <p class="a-font">
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                                {{editedStitchesData.createdOn |date:"hh:mm a"}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        </div>
          <!-- /grid item -->
          <!-- Grid Item -->
        <div  [ngClass]="isViewingMode ? 'wdcacl-300' : 'w-100'" *ngIf="isViewingMode || isEditingMode">
          <div class="order-xl-1">

            <!-- Card -->
            <div class="card card-shadow-none mr-3">

              <!-- Card Header -->
              <div class="card-header card-nav border-bottom d-sm-flex justify-content-sm-between" *ngIf="!isCreateMode">
                <!-- <h3 class="mb-2 mb-sm-n5">About</h3> -->

                <ul class="card-header-links nav nav-underline" role="tablist">
                  <li class="nav-item">
                    <a *ngIf="activeTab !='stitchesDetailsTab'" class="nav-link active" data-toggle="tab" href="#tab-pane1"
                       [ngClass]="{ 'active':activeTab === 'stitchesDetailsTab' }"
                       (click)="stitchesDetailsTab('stitchesDetailsTab')" role="tab"
                       aria-controls="tab-pane1" aria-selected="true">{{appStaticMsgs.stitches.stitchesdetails}}</a>
                       <a *ngIf="activeTab =='stitchesDetailsTab'" class="nav-link active" data-toggle="tab" href="#tab-pane1"
                       [ngClass]="{ 'active':activeTab === 'stitchesDetailsTab' }"
                       role="tab"
                       aria-controls="tab-pane1" aria-selected="true">{{appStaticMsgs.stitches.stitchesdetails}}</a>

                  </li>
                  <li class="nav-item" *ngIf="isEditMode">
                    <a  *ngIf="activeTab !='docDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane2"
                       [ngClass]="{ 'active':activeTab === 'docDetailsTab' }"
                       (click)="docDetailsTab('docDetailsTab')" role="tab"
                       aria-controls="tab-pane2" aria-selected="true">{{appStaticMsgs.common.document}}</a>
                       <a  *ngIf="activeTab =='docDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane2"
                       [ngClass]="{ 'active':activeTab === 'docDetailsTab' }"
                       role="tab"
                       aria-controls="tab-pane2" aria-selected="true">{{appStaticMsgs.common.document}}</a>
                  </li>
                </ul>
                <div class="dropdown" *ngIf="!isViewingMode">
                    <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                    </a>
                </div>
                <div class="dropdown" *ngIf="isViewingMode">
                  <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                  </a> -->

                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="javascript:void(0)" (click)="editStitches()">
                      <i class="fa fa-pencil-square-o"></i>
                      {{appStaticMsgs.common.edit}}
                    </a>
                    <!-- <a class="dropdown-item" href="javascript:void(0)">
                      <i class="icon icon-trash icon-fw"></i>
                      Delete
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                      <i class="icon icon-addnew icon-fw"></i>
                      Create New
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                      <i class="fa fa-files-o mr-1" aria-hidden="true"></i>
                      Copy
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                      <i class="fa fa-floppy-o mr-1" aria-hidden="true"></i>
                      Save New Version
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                      <i class="fa fa-thumb-tack mr-1" aria-hidden="true"></i>
                      Mark As Basline Version
                    </a> -->
                  </div>
                </div>
              </div>
              <!-- /card header -->
              <!-- Card Body -->
              <div class="mt-3 pb-2">
                <!-- Tab Content-->
                <div class="tab-content">
                  <!-- Tab panel -->
                  <div id="tab-pane1" class="tab-pane card-body card-body-shadow bg-white active"
                    [ngClass]="{ 'active':activeTab==='stitchesDetailsTab'}">
                    <div *ngIf="isEditingMode">
                      <form [formGroup]="stitchesForm" (ngSubmit)="onStitchesSubmit()">
                        <div class="form-row">
                          <div class="col-lg-3 col-sm-3 mb-3" *ngIf="isIDVisible">
                            <label for="sequence">{{appStaticMsgs.common.id1}}<span class="red_icon">*</span></label>
                            <input type="text" class="form-control" id="sequence" formControlName="sequence" placeholder="Enter ID" value="">
                            <!-- <div *ngIf="(submitted && this.f.sequence.errors) || ( this.f.sequence.invalid && (this.f.sequence.dirty || this.f.sequence.touched))">
                              <div *ngIf="this.f.ID.errors.required" class="red">
                                ID # is required.
                              </div>
                            </div> -->
                          </div>

                          <div class="col-lg-3 col-sm-3 mb-3" >
                            <label for="name">{{appStaticMsgs.stitches.stitchname}}<span class="red_icon">*</span></label>
                            <input type="text" class="form-control" id="name" (keypress)="characternameOnly($event)" formControlName="name" placeholder="Enter Stitch Name"
                            value="" minlength="3" maxlength="50">
                            <div *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
                              <div *ngIf="this.f.name.errors.required" class="red">
                                {{appStaticMsgs.stitches.stitchnameisrequired}}
                              </div>
                              <div *ngIf="this.f.name.errors.minlength" class="red">
                               {{appStaticMsgs.common.minimum3charactersrequired}}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-sm-3 mb-3">
                            <label for="isO4915Number">{{appStaticMsgs.stitches.iso4915number}}<span class="red_icon">*</span></label>
                            <input type="text" class="form-control" (keypress)="characterOnly($event)" id="isO4915Number" formControlName="isO4915Number" placeholder="Enter ISO 4915 Number" value="" required>
                            <div *ngIf="(submitted && this.f.isO4915Number.errors) || ( this.f.isO4915Number.invalid && (this.f.isO4915Number.dirty || this.f.isO4915Number.touched))">
                              <div *ngIf="this.f.isO4915Number.errors.required" class="red">
                                {{appStaticMsgs.stitches.iso4915numberisrequired}}
                              </div>
                              <div *ngIf="this.stitchesForm.controls.isO4915Number.value && this.stitchesForm.controls.isO4915Number.value.length > 20"
                                class="red">
                                {{appStaticMsgs.stitches.iso4915isallowed20charactersonly}}
                            </div>
                            </div>

                          </div>
                          <div class="col-lg-3 col-sm-3 mb-3" >
                            <label for="status"> {{appStaticMsgs.common.status}}<span class="red_icon">*</span></label>
                            <select id="status" formControlName="status" class="form-control selectpicker"
                            [title]="appStaticMsgs.common.selectstatus">
                              <option value="Approved">Approved</option>
                              <option value="In Review">In Review</option>
                              <option value="Dropped">Dropped</option>
                            </select>
                            <div *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
                              <div *ngIf="this.f.status.errors.required" class="red">
                            {{appStaticMsgs.common.statusisrequired}}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-sm-3 mb-3">
                            <label for="commonApplication">{{appStaticMsgs.stitches.commonapplication}}<span class="red_icon">*</span></label>
                            <textarea cols="2" formControlName="commonApplication" name="commonApplication" placeholder="Enter Common Application" class="form-control" required></textarea>
                            <div *ngIf="(submitted && this.f.commonApplication.errors) || ( this.f.commonApplication.invalid && (this.f.commonApplication.dirty || this.f.commonApplication.touched))">
                              <div *ngIf="this.f.commonApplication.errors.required" class="red">
                                {{appStaticMsgs.stitches.commonapplicationisrequired}}
                              </div>
                              <div *ngIf="this.stitchesForm.controls.commonApplication.value && this.stitchesForm.controls.commonApplication.value.length > 500"
                                class="red">
                                {{appStaticMsgs.stitches.commonapplicationisallowed500charactersonly}}
                            </div>
                            </div>

                          </div>
                          <div class="col-lg-3 col-sm-3 mb-3">
                            <label for="requirements">{{appStaticMsgs.common.requirements}}</label>
                            <textarea cols="2" formControlName="requirements" name="requirements" placeholder="Enter Requirements" class="form-control"></textarea>
                            <div *ngIf="this.stitchesForm.controls.requirements.value && this.stitchesForm.controls.requirements.value.length > 500"
                              class="red">
                              {{appStaticMsgs.stitches.requirementsisallowed500charactersonly}}
                          </div>
                          </div>
                          <div class="col-lg-6 col-sm-6 mb-3">
                            <label for="description">{{appStaticMsgs.common.description}}</label>
                            <textarea cols="2" formControlName="description" name="description" placeholder="Enter Description" class="form-control"></textarea>

                          </div>

                          <!-- <div class="col-lg-4 mb-3">
                                <label for="validationDefault02">Image #</label>
                                <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Image #" value="" required="">
                            </div>
                            <div class="col-lg-4 mb-3">
                              <label for="validationDefault02">Name</label>
                              <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Name" value="" required="">
                          </div> -->
                        </div>
                        <div>
                          <div class="dt-entry__header mt-7">
                            <!-- Entry Heading -->
                            <div class="dt-entry__heading">
                              <h3 class="dt-entry__title">{{appStaticMsgs.stitches.uploadtopviewassewn}}</h3>
                            </div>
                            <!-- /entry heading -->
                          </div>
                          <div class="form-row">
                            <div class="col-sm-12 mb-3">
                              <label for="topviewComments">{{appStaticMsgs.common.comments}}</label>
                              <textarea cols="2" class="form-control" formControlName="topviewComments" name="topviewComments" placeholder="Enter Comments"></textarea>
                              <div *ngIf="this.stitchesForm.controls.topviewComments.errors && this.stitchesForm.controls.topviewComments.value && this.stitchesForm.controls.topviewComments.value.length > 500"
                                class="red">
                                {{appStaticMsgs.stitches.commentsisallowed500charactersonly}}
                            </div>
                            </div>

                            <div class="col-xl-12">
                              <label>{{appStaticMsgs.stitches.uploadtopviewfile}}<span class="red_icon">*</span></label>
                              <div class="custom-dropzone"
                                   id="topView"
                                   ngx-dropzone
                                   [multiple]="false"

                                   (change)="onSelect($event)">
                                <ngx-dropzone-label>
                                  <div>
                                    <h5 class="text-light-gray mt-3">{{appStaticMsgs.common.dropfilesheretoupload}}</h5>
                                  </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of topView" [file]="f" [removable]="true" (removed)="onRemove(f)">
                                  <ngx-dropzone-label>
                                    {{ f.name }}
                                    <!-- ({{ f.type }}) -->
                                  </ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                              </div>
                              <div *ngIf="(submitted && isTopFileError)" class="red">
                                {{appStaticMsgs.stitches.uploadtopviewsewnisrequired}}
                              </div>
                            </div>
                            <div class="col-xl-6 mt-6" *ngIf="this.f.sequence.value">
                              <div class="col-xl-6 mb-5"
                                   *ngFor="let editedThumbnail of editedtopViewfile; let j=index">
                                <img src="{{editedThumbnail.thumbnail}}"
                                     class="img-fluid  img-fit "
                                     *ngIf="isValidImageURL(editedThumbnail.name)"
                                     (click)="openTopFiles(j)">
                                <button type="button" class="close image-close"
                                        (click)="removeTopSection(editedThumbnail)">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="dt-entry__header mt-7 mt-3">
                            <!-- Entry Heading -->
                            <div class="dt-entry__heading">
                              <h3 class="dt-entry__title">{{appStaticMsgs.stitches.uploadbottomviewassewn}}</h3>
                            </div>
                            <!-- /entry heading -->
                          </div>
                          <div class="form-row">
                            <div class="col-sm-12 mb-3">
                              <label for="bottomviewComments">{{appStaticMsgs.common.comments}}</label>
                              <textarea cols="2" class="form-control" formControlName="bottomviewComments" name="bottomviewComments" placeholder="Enter Comments"></textarea>
                              <div *ngIf="this.stitchesForm.controls.bottomviewComments.errors && this.stitchesForm.controls.bottomviewComments.value && this.stitchesForm.controls.bottomviewComments.value.length > 500"
                                class="red">
                                {{appStaticMsgs.stitches.commentsisallowed500charactersonly}}
                            </div>
                            </div>
                            <div class="col-xl-12">
                              <label>{{appStaticMsgs.stitches.uploadbottomviewfile}}<span class="red_icon">*</span></label>
                              <div class="custom-dropzone"
                                   id="bottomView"
                                   ngx-dropzone
                                   [multiple]="false"
                                   (change)="onSelectBottomSewn($event)">
                                <ngx-dropzone-label>
                                  <div>
                                  <h5 class="text-light-gray mt-3">{{appStaticMsgs.common.dropfilesheretoupload}}</h5>
                                  </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of bottomView" [file]="f" [removable]="true" (removed)="onRemoveBottomSewn(f)">
                                  <ngx-dropzone-label>
                                    {{ f.name }}
                                    <!-- ({{ f.type }}) -->
                                  </ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                              </div>
                              <div *ngIf="(submitted && isBottomFileError)" class="red">
                                {{appStaticMsgs.stitches.uploadbottomviewsewnisrequired}}
                              </div>
                            </div>
                            <div class="col-xl-6 mt-6" *ngIf="this.f.sequence.value">
                              <div class="col-xl-6 mb-5"
                                   *ngFor="let editedThumbnail of editedbottomViewfile; let j=index">
                                <img src="{{editedThumbnail.thumbnail}}"
                                     class="img-fluid  img-fit "
                                     *ngIf="isValidImageURL(editedThumbnail.name)"
                                     (click)="openBottomFiles(j)">
                                <button type="button" class="close image-close"
                                        (click)="removeBottomSection(editedThumbnail)">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-row mt-10 float-right">
                          <div class="row mt-3">
                              <button type="button" *ngIf="!isEditMode"
                              (click)="cancelStitchesCreate()"
                             class="btn btn-outline-primary adv-search-btn mr-1">{{appStaticMsgs.common.cancel}}</button>
                          <button type="submit" *ngIf="!isEditMode" class="btn btn-primary adv-search-btn"
                            [disabled]="stitchesForm?.invalid || !isTopViewAdded || !isBottomViewAdded">
                              {{appStaticMsgs.common.create}}</button>
                          <button type="submit" *ngIf="isEditMode" class="btn btn-primary adv-search-btn"
                            [disabled]="stitchesForm?.invalid || !isTopViewAdded || !isBottomViewAdded">
                              {{appStaticMsgs.common.update}}</button>

                          </div>
                      </div>
                        <!-- <div class="form-row mt-10 float-right">
                          <div class="row mt-3">
                            <button type="button" *ngIf="!isEditMode" (click)="cancelStitchesCreate()" class="btn btn-outline-primary adv-search-btn mr-3">
                              Cancel
                            </button>
                            <button type="submit" *ngIf="!isEditMode"
                            class="btn btn-primary adv-search-btn">Create</button>
                            <button type="submit" *ngIf="isEditMode"
                            class="btn btn-primary adv-search-btn">Update</button>
                          </div>

                        </div> -->
                      </form>
                    </div>

                    <div *ngIf="isViewingMode">
                      <form>
                        <div class="form-row">
                          <div class="col-lg-12 col-sm-12">
                            <div class="form-row">
                              <div class="col-sm-4 mb-3">
                                <label class="label-view" for="this.f.sequence.value">{{appStaticMsgs.stitches.stitchname}}</label>
                                <p class="mt-2 viewtext">{{this.f.name.value}}</p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label class="label-view" for="this.f.sequence.value">{{appStaticMsgs.common.id1}}</label>
                                <p class="mt-2 viewtext">{{this.f.sequence.value}}</p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label class="label-view" for="this.f.isO4915Number.value">{{appStaticMsgs.stitches.iso4915number}}</label>
                                <p class="mt-2 viewtext">{{this.f.isO4915Number.value}}</p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label class="label-view" for="this.f.status.value">{{appStaticMsgs.common.status}}</label>
                                <p class="mt-2 viewtext">{{this.f.status.value | titlecase}}</p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label class="label-view" for="this.f.commonApplication.value">{{appStaticMsgs.stitches.commonapplication}}</label>
                                <p class="mt-2 viewtext"> {{this.f.commonApplication.value}} </p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label class="label-view" for="this.f.requirements.value">{{appStaticMsgs.common.requirements}}</label>
                                <p class="mt-2 viewtext">  {{this.f.requirements.value}}  </p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label class="label-view" for="this.f.description.value">{{appStaticMsgs.common.description}}</label>
                                <p class="mt-2 viewtext">  {{this.f.description.value}}  </p>
                              </div>
                            </div>
                            <div class="dt-entry__header mt-7">
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">{{appStaticMsgs.stitches.topviewassewn}}</h3>
                              </div>
                            </div>
                            <div class="form-row mt-2">
                              <div class="col-lg-4 col-sm-3">
                                <div class="card">
                                  <img *ngIf="editedtopViewValue"
                                       class="card-view-img" alt=""
                                       src={{editedtopViewValue}}>
                                  <div class="">
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-8 mb-3">
                                <label class="label-view" for="this.f.topviewComments.value">{{appStaticMsgs.common.comments}}</label>
                                <p class="mt-2 viewtext">{{this.f.topviewComments.value}}</p>
                              </div>
                            </div>

                            <div class="dt-entry__header mt-7">
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">{{appStaticMsgs.stitches.bottomviewassewn}}</h3>
                              </div>
                            </div>
                            <div class="form-row mt-2">
                              <div class="col-lg-4 col-sm-3">
                                <div class="card">
                                  <img *ngIf="editedbottomViewValue"
                                       class="card-view-img" alt=""
                                       src={{editedbottomViewValue}}>
                                  <div class="">
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-8 mb-3">
                                <label class="label-view" for="this.f.bottomviewComments.value">{{appStaticMsgs.common.comments}}</label>
                                <p class="mt-2 viewtext">{{this.f.bottomviewComments.value}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                  <!-- /tab panel -->

                  <!-- Document /tab panel -->
                  <!-- Tab panel -->
                  <div id="tab-pane2" class="tab-pane card-body card-body-shadow bg-white"
                       [ngClass]="{ 'active':activeTab === 'docDetailsTab'}">
                    <div *ngIf="isEditingMode">
                      <form [formGroup]="documentsForm" (ngSubmit)="onDocSubmit()">
                        <div formArrayName="documents">
                          <div *ngFor="let document of getControls(); let i=index">
                            <div class="card p-5" [formGroupName]="i">
                              <div class="form-row ">
                                <div class="col-sm-12 float-right">
                                  <button type="button" class="close"
                                          (click)="removeDoc(i)">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                              </div>

                              <div class="form-row">

                                <div class="col-lg-4 col-sm-12 mb-3">
                                  <label for="docclassification">
                                    {{appStaticMsgs.stitches.selectclassification}}<span class="red_icon">*</span>
                                  </label>
                                  <input type="text" class="form-control"
                                         id="docclassification{{i}}"
                                         formControlName="docclassification"
                                         placeholder="Classification" required />
                                  <div *ngIf="(submitted1 && document.get('docclassification').errors) || ( document.get('docclassification').invalid && (document.get('docclassification').dirty || document.get('docclassification').touched))">
                                    <div *ngIf="document.get('docclassification').errors.required"
                                         class="red">
                                        {{appStaticMsgs.stitches.classificationisrequired}}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4 mb-3"
                                     *ngIf="document.get('docid').value">
                                  <label for="docseq">{{appStaticMsgs.common.document1}}</label>
                                  <input type="text" class="form-control"
                                         [readonly]="true" id="docseq"
                                         formControlName="docseq"
                                         placeholder="Document Id" />
                                </div>

                                <div class="col-lg-4 col-sm-12 mb-3">
                                  <label for="docname">{{appStaticMsgs.common.name}}
                                    <span class="red_icon">*</span>
                                  </label>
                                  <input type="text" class="form-control" id="docname"
                                         formControlName="docname" minlength="3" maxlength="50"
                                         placeholder="Document Name" (keypress)="characternameOnly($event)" required />
                                  <div *ngIf="(submitted1 && document.get('docname').errors) || ( document.get('docname').invalid && (document.get('docname').dirty || document.get('docname').touched))">
                                    <div *ngIf="document.get('docname').errors.required"
                                         class="red">
                                  {{appStaticMsgs.stitches.documentnameisrequired}}
                                    </div>
                                    <div *ngIf="document.get('docname').errors.minlength" class="red">
                                      {{appStaticMsgs.common.minimum3charactersrequired}}
                                     </div>
                                  </div>

                                </div>

                              </div>

                              <div class="form-row">
                                <div class="col-sm-4 mt-3 mb-3">
                                  <label for="docstatus">{{appStaticMsgs.common.status}}
                                  <span class="red_icon">*</span>

                                  </label>
                                  <select type="text" class="form-control selectpicker"
                                          id="docstatus" formControlName="docstatus"
                                          [title]="appStaticMsgs.common.selectstatus"
                                          required>
                                    <!-- <option value="" [disabled]="true" selected>
                                      {{appStaticMsgs.common.selectstatus}}
                                    </option> -->
                                    <option value="Active" selected>Active</option>
                                    <option value="Inactive">Inactive</option>
                                  </select>
                                  <div *ngIf="(submitted1 && document.get('docstatus').errors) || ( document.get('docstatus').invalid && (document.get('docstatus').dirty || document.get('docstatus').touched))">
                                    <div *ngIf="document.get('docstatus').errors.required"
                                         class="red">
                                        {{appStaticMsgs.common.statusisrequired}}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-8 mt-3 mb-3">
                                  <label for="docdescription">
                                    {{appStaticMsgs.common.description}}<span class="red_icon">*</span>
                                  </label>
                                  <textarea cols="2" class="form-control"
                                            id="docdescription"
                                            formControlName="docdescription"
                                            required></textarea>
                                  <div *ngIf="(submitted1 && document.get('docdescription').errors) || ( document.get('docdescription').invalid && (document.get('docdescription').dirty || document.get('docdescription').touched))">
                                    <div *ngIf="document.get('docdescription').errors.required"
                                         class="red">
                                      {{appStaticMsgs.common.descriptionisrequired}}
                                    </div>
                                    
                                  </div>

                                </div>

                              </div>

                              <div class="dt-entry__header mt-5"
                                   *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                <div class="dt-entry__heading">
                                  <h3 class="dt-entry__title">
                                    {{document.get('docclassification').value}}
                                  </h3>
                                </div>
                              </div>

                              <div class="form-row"
                                   *ngIf="document.get('docclassification').value === 'Lab Reports'">

                                <div class="col-sm-4 mb-3">
                                  <label for="docreportstatus">
                                    {{appStaticMsgs.common.reportstatus}}<span class="red_icon">*</span>
                                  </label>
                                  <select type="text" class="form-control selectpicker"
                                          id="docreportstatus" [title]="appStaticMsgs.common.selectreportstatus"
                                          formControlName="docreportstatus" required>
                                    <option value="Pass">Pass</option>
                                    <option value="Fail">Fail</option>
                                  </select>
                                  <div *ngIf="(submitted1 && document.get('docreportstatus').errors) || ( document.get('docreportstatus').invalid && (document.get('docreportstatus').dirty || document.get('docreportstatus').touched))">
                                    <div *ngIf="document.get('docreportstatus').errors.required"
                                         class="red">
                                      {{appStaticMsgs.common.reportstatusisrequired}}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4 mb-3">
                                  <label for="doclablocation">
                             {{appStaticMsgs.common.lablocation}}
                             <span class="red_icon">*</span>
                                  </label>
                                  <input type="text" class="form-control"
                                         id="doclablocation"
                                         formControlName="doclablocation"
                                         placeholder="Lab Location" required />
                                  <div *ngIf="(submitted1 && document.get('doclablocation').errors) || ( document.get('doclablocation').invalid && (document.get('doclablocation').dirty || document.get('doclablocation').touched))">
                                    <div *ngIf="document.get('doclablocation').errors.required"
                                         class="red">
                                         {{appStaticMsgs.stitches.lablocationisrequired}}
                                    </div>
                                    <div *ngIf="document.get('doclablocation').value && document.get('doclablocation').value.length > 50"
                                      class="red">
                                      {{appStaticMsgs.stitches.lablocationisallowed50charactersonly}}
                                  </div>
                                  </div>

                                </div>

                              </div>

                              <div class="dt-entry__header mt-5">
                                <div class="dt-entry__heading">
                                  <h3 class="dt-entry__title">{{appStaticMsgs.common.document}}</h3>
                                </div>
                              </div>


                              <div class="form-row" [hidden]="true">
                                <input type="text" class="form-control"
                                       id="docdeletedfileids"
                                       formControlName="docdeletedfileids"
                                       placeholder="Deleted File Ids">
                              </div>

                              <div class="form-row">
                                <div class="col-xl-12">
                                  <label for="document">{{appStaticMsgs.common.docname}}<span
                                    class="red_icon">*</span></label>
                                  <div class="custom-dropzone" id="docupload"
                                       ngx-dropzone (change)="onSelectDoc($event,i)">
                                    <ngx-dropzone-label>
                                      <div>
                                        <h5 class="text-light-gray mt-3">
                                        {{appStaticMsgs.common.dropfilesheretoupload}}
                                        </h5>
                                      </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview"
                                                          *ngFor="let f of fileData[i]" [file]="f"
                                                          [removable]="true"
                                                          (removed)="onRemoveDoc(f,i)">
                                      <ngx-dropzone-label>
                                        {{ f.name }}
                                        <!--  ({{ f.type }}) -->
                                      </ngx-dropzone-label>
                                    </ngx-dropzone-preview>

                                  </div>
                                  <div *ngIf="doc_Error[i]">
                                    <span class="red">
                                      <span>
                                        {{doc_Error[i]}}
                                      </span></span>
                                    </div>
                                    <div class="col-xl-6 mt-6" *ngIf="document.get('docid').value">
                                      <div class="col-xl-6 mb-5"
                                           *ngFor="let img of editedFileData[i]; let j = index">
                                        <img src="{{img.thumbnail}}"
                                             class="img-fluid  img-fit "
                                             *ngIf="isValidImageURL(img.name)"
                                             (click)="open(i,j)">
                                        <a href="{{img.thumbnail}}" target="_blank"
                                           download>
                                          <img src="{{documentImage}}"
                                               class="img-fluid  img-fit "
                                               *ngIf="!isValidImageURL(img.name)">
                                        </a>
                                        <button type="button" class="close image-close"
                                                (click)="removeImgSection(img,i)">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                    </div>
                                </div>
                              </div>

                              <div class="form-row mt-5"
                                   *ngIf="(fileData[i]) && (!isEmptyObject(fileData[i]))">
                                <div class="col-xl-12 ">
                                  <div class="card">
                                    <div class="dt-card__body p-2">
                                      <label for="docnotes">{{appStaticMsgs.stitches.notes}}</label>
                                      <textarea cols="2" class="form-control"
                                                id="docnotes"
                                                formControlName="docnotes"></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <input type="text" class="form-control" [hidden]="true"
                                     id="docid" formControlName="docid"
                                     placeholder="Document Id" />

                            </div>

                          </div>
                        </div>

                        <div class="form-row text-center mb-7">
                          <div class="col-sm-12">
                            <!-- <button type="button" class="btn btn-primary adv-search-btn">Select Existing</button> -->
                            <button type="button" class="btn btn-primary adv-search-btn"
                                    (click)="addNewDoc()">
                              <i class="icon icon-plus"></i>
                            {{appStaticMsgs.common.addnew1}}
                            </button>
                          </div>
                        </div>

                        <div class="form-row text-center mb-7" *ngIf="showButton">
                          <div class="col-sm-12">

                            <button type="submit" [disabled]="documentsForm?.invalid || !isFilesAdded"
                                    class="btn btn-primary adv-search-btn">
                                    {{appStaticMsgs.common.save}}
                            </button>
                          </div>
                        </div>

                      </form>
                    </div>

                    <div *ngIf="isViewingMode">
                      <div class="text-center" *ngIf="stichesDataCnt == 0">
                        <h3 class="mt-4">{{appStaticMsgs.common.nodataavailable}}</h3>
                        <img [src]="notfoundImg" class="img-fluid w-40"><br>
                      </div>
                      <div>
                      <div *ngFor="let document of getControls(); let i=index">
                        <div class="dt-entry__header mt-5">
                          <!-- Entry Heading -->
                          <div class="dt-entry__heading">
                            <h3 class="dt-entry__title">
                              {{document.get('docclassification').value}}
                            </h3>
                          </div>
                          <!-- /entry heading -->
                        </div>
                        <form>
                          <div class="form-row">
                            <div class="col-sm-4 mb-3">
                              <label for="document.get('docseq').value">
                                {{appStaticMsgs.common.document1}}
                              </label>
                              <p class="mt-2 viewtext">
                                {{document.get('docseq').value}}
                              </p>
                            </div>
                            <div class="col-sm-4 mb-3">
                              <label class="label-view" for="document.get('docname').value"> {{appStaticMsgs.common.name}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('docname').value}}
                              </p>
                            </div>
                            <div class="col-sm-4 mb-3">
                              <label class="label-view" for="document.get('docstatus').value"> {{appStaticMsgs.common.status}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('docstatus').value | titlecase}}
                              </p>
                            </div>

                            <div class="col-sm-4 mb-3"
                                 *ngIf="document.get('docclassification').value === 'Lab Reports'">
                              <label class="label-view" for="document.get('docreportstatus').value">
                               {{appStaticMsgs.common.reportstatus}}
                              </label>
                              <p class="mt-2 viewtext">
                                {{document.get('docreportstatus').value}}
                              </p>
                            </div>
                            <div class="col-sm-4 mb-3"
                                 *ngIf="document.get('docclassification').value === 'Lab Reports'">
                              <label class="label-view" for="document.get('doclablocation').value">
                                {{appStaticMsgs.common.lablocation}}
                              </label>
                              <p class="mt-2 viewtext">
                                {{document.get('doclablocation').value}}
                              </p>
                            </div>

                            <div class="col-sm-8 mb-3">
                              <label class="label-view" for="document.get('docdescription').value">{{appStaticMsgs.common.description}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('docdescription').value}}
                              </p>
                            </div>
                            <div class="col-sm-8 mb-3"
                                 *ngIf="document.get('docnotes').value">
                              <label class="label-view" for="document.get('docnotes').value">{{appStaticMsgs.common.documentstatus}}</label>
                              <p class="mt-2 viewtext">
                                {{document.get('docnotes').value}}
                              </p>
                            </div>
                          </div>

                          <div class="form-row mt-2">
                            <div class="col-xl-3 mb-5"
                                 *ngFor="let img of editedFileData[i]; let j = index">
                              <img src="{{img.thumbnail}}" class="img-fluid  img-fit "
                                   *ngIf="isValidImageURL(img.name)"
                                   (click)="open(i,j)">
                              <a href="{{img.thumbnail}}" target="_blank"
                                 download>
                                <img src="{{documentImage}}"
                                     class="img-fluid  img-fit "
                                     *ngIf="!isValidImageURL(img.name)">
                              </a>
                            </div>
                          </div>

                        </form>
                      </div>
                      </div>
                    </div>

                  </div>
                  <!-- /tab panel -->

                </div>
                <!-- /tab content-->

              </div>
              <!-- /card body -->

            </div>
            <!-- /card -->

          </div>
        </div>
          <!-- /grid item -->



        </div>
        <!-- /grid -->

      </div>


    </div>

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->
  </div>
  <!-- /Site Content Wrapper-->

</main>
