import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { LogEntry, LogService } from './log.service';
import { DatePipe } from '@angular/common';

// ****************************************************
// Log Publisher Abstract Class
// NOTE: This class must be located BEFORE
//       all those that extend this class
// ****************************************************
export abstract class LogPublisher {
  location: string;

  abstract log(record: LogEntry): Observable<boolean>
  abstract clear(): Observable<boolean>;
}

// ****************************************************
// Console Logging Class
// ****************************************************
// export class LogConsole extends LogPublisher {
//   log(entry: LogEntry): Observable<boolean> {
//     // Log to console
//     console.log(entry.buildLogString());

//     return Observable.of(true);
//   }

//   clear(): Observable<boolean> {
//     console.clear();

//     return Observable.of(true);
//   }
// }

// ****************************************************
// Local Storage Logging Class
// ****************************************************
export class LogLocalStorage extends LogPublisher {
 
  constructor() {
    
    // Must call super() from derived classes
    super();
    // Set location
    this.location = new Date().toLocaleDateString();
  }

  // Append log entry to local storage
  log(entry: LogEntry): Observable<boolean> {
    
    let ret: boolean = false;
    let values: LogEntry[];
   

    try {
      //this.location=this.location+" | "+level+" | "+entry.Module+" Component"
      // Retrieve previous values from local storage
      values = JSON.parse(localStorage.getItem(this.location)) || [];
      // Add new log entry to array
      values.push(entry);
      // Store array into local storage
      localStorage.setItem(this.location, JSON.stringify(values));

      // Set return value
      ret = true;
    } catch (ex) {
      // Display error in console
      console.log(ex);
    }

    return Observable.of(ret);
  }

  // Clear all log entries from local storage
  clear(): Observable<boolean> {
    localStorage.removeItem(this.location);
    return Observable.of(true);
  }
}