<kendo-dropdownlist
  [value]="value"
  [valuePrimitive]="isPrimitive"
  [data]="data"
  [textField]="textField"
  [valueField]="valueField"
[filterable]="true"
  (valueChange)="onValueChange($event)"
>
</kendo-dropdownlist>
