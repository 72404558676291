import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

export enum ColorActionTypes {
    LOAD_COLOR_LIBRARY = '[Color] Load Color Library',
    LOAD_COLOR_LIBRARY_SUCCESS = '[Color] Load Color Library Success',
    LOAD_COLOR_LIBRARY_FAIL = '[Color] Load Color Library Fail',
    LOAD_COLOR = '[Color] Load Color',
    LOAD_COLOR_SUCCESS = '[Color] Load Color Success',
    LOAD_COLOR_FAIL = '[Color] Load Color Fail',
    CREATE_COLOR = '[Color] Create Color',
    CREATE_COLOR_SUCCESS = '[Color] Create Color Success',
    CREATE_COLOR_FAIL = '[Color] Create Color Fail',
    UPDATE_COLOR = '[Color] Update Color',
    UPDATE_COLOR_SUCCESS = '[Color] Update Color Success',
    UPDATE_COLOR_FAIL = '[Color] Update Color Fail',
    UPDATE_COLOR_LIBRARY = '[Color] Update Color Library',
    UPDATE_COLOR_LIBRARY_SUCCESS = '[Color] Update Color Library Success',
    UPDATE_COLOR_LIBRARY_FAIL = '[Color] Update Color Library Fail',
    DELETE_COLOR_LIBRARY = '[Color] Delete Color Library',
    DELETE_COLOR_LIBRARY_SUCCESS = '[Color] Delete Color Library Success',
    DELETE_COLOR_LIBRARY_FAIL = '[Color] Delete Color Library Fail',
    UPDATE_COLOR_DOCUMENT = '[Color] Update Color Document',
    UPDATE_COLOR_DOCUMENT_SUCCESS = '[Color] Update Color Document Success',
    UPDATE_COLOR_DOCUMENT_FAIL = '[Color] Update Color Document Fail',
}

export class LoadColorLibrary implements Action {
    readonly type = ColorActionTypes.LOAD_COLOR_LIBRARY
    constructor(public payload1: string, public payload2: string) { }
}

export class LoadColorLibrarySuccess implements Action {
    readonly type = ColorActionTypes.LOAD_COLOR_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadColorLibraryFail implements Action {
    readonly type = ColorActionTypes.LOAD_COLOR_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export class LoadColor implements Action {
    readonly type = ColorActionTypes.LOAD_COLOR

    constructor(public payload: string) { }
}

export class LoadColorSuccess implements Action {
    readonly type = ColorActionTypes.LOAD_COLOR_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadColorFail implements Action {
    readonly type = ColorActionTypes.LOAD_COLOR_FAIL

    constructor(public payload: string) { }
}
export class CreateColor implements Action {
    readonly type = ColorActionTypes.CREATE_COLOR

    constructor(public payload: any) { }
}

export class CreateColorSuccess implements Action {
    readonly type = ColorActionTypes.CREATE_COLOR_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateColorFail implements Action {
    readonly type = ColorActionTypes.CREATE_COLOR_FAIL

    constructor(public payload: string) { }
}
export class UpdateColor implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR

    constructor(public payload: any) { }
}

export class UpdateColorSuccess implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateColorFail implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR_FAIL

    constructor(public payload: string) { }
}

export class UpdateColorLibrary implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR_LIBRARY

    constructor(public payload: any[]) { }
}

export class UpdateColorLibrarySuccess implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR_LIBRARY_SUCCESS

    constructor(public payload1: Update<any>[],public payload2: any) { }
}

export class UpdateColorLibraryFail implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class DeleteColorLibrary implements Action {
    readonly type = ColorActionTypes.DELETE_COLOR_LIBRARY

    constructor(public payload: any[]) { }
}

export class DeleteColorLibrarySuccess implements Action {
    readonly type = ColorActionTypes.DELETE_COLOR_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class DeleteColorLibraryFail implements Action {
    readonly type = ColorActionTypes.DELETE_COLOR_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class UpdateColorDocument implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR_DOCUMENT

    constructor(public payload: any) { }
}

export class UpdateColorDocumentSuccess implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR_DOCUMENT_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateColorDocumentFail implements Action {
    readonly type = ColorActionTypes.UPDATE_COLOR_DOCUMENT_FAIL

    constructor(public payload: string) { }
}
export type colorActionTypes = LoadColorLibrary | LoadColorLibrarySuccess | LoadColorLibraryFail |
    LoadColor | LoadColorSuccess | LoadColorFail | CreateColor | CreateColorSuccess |
    CreateColorFail | UpdateColor | UpdateColorSuccess | UpdateColorFail | UpdateColorLibrary | UpdateColorLibrarySuccess
    | UpdateColorLibraryFail | DeleteColorLibrary | DeleteColorLibrarySuccess | DeleteColorLibraryFail
    | UpdateColorDocument | UpdateColorDocumentSuccess | UpdateColorDocumentFail;