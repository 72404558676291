import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isLoading = new Subject<boolean>();
  private subject = new Subject<any>();
  editData = new BehaviorSubject<any>(null);
  editBomdata = new BehaviorSubject<any>(null);
  stitch = new BehaviorSubject<any>(null);
  operation = new BehaviorSubject<any>(null);
  bomData = new BehaviorSubject<any>(null);
  pomData = new BehaviorSubject<any>(null);
  processingpom = new BehaviorSubject<any>(null);
  processingsewn = new BehaviorSubject<any>(null);
  editSupplierData = new BehaviorSubject<any>(null);
  sizerange = new BehaviorSubject<any>(null);
  filter = new BehaviorSubject<any>(null);
  sample = new BehaviorSubject<any>(null);
  pattern = new BehaviorSubject<any>(null);
  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  setStitchData(data) {
    this.stitch.next(data);
  }
  getStitchData() {
    return this.stitch.asObservable();
  }
  setOperationData(data) {
    this.operation.next(data);
  }
  getOperationData() {
    return this.operation.asObservable();
  }
  setPomData(data) {
    this.pomData.next(data);
  }
  getPomData() {
    return this.pomData.asObservable();
  }
  setSizeRange(data){
    this.sizerange.next(data);
  }
  getSizeRange(){
    return this.sizerange.asObservable();
  }
  setUnfilter(data){
    this.filter.next(data);
  }
  getUnfilter(){
    return this.filter.asObservable();
  }
  setSample(data){
    this.sample.next(data);
  }
  getSample(){
    return this.sample.asObservable();
  }
  show() {
    this.isLoading.next(true);
  }
  hide() {
    this.isLoading.next(false);
  }
  getItem() {
    return this.editData.asObservable();
  }
  setItem(data) {
    this.editData.next(data);
  }
  setBomData(data) {
  this.bomData.next(data);
}

getBomData(){
  return this.bomData.asObservable();
  }
  setprocessingpomData(data){
    this.processingpom.next(data);
  }
  getprocessingpomData(){
    return this.processingpom.asObservable();
  }
  setprocessingsewnData(data){
    this.processingsewn.next(data);
  }
  getprocessingsewnData(){
    return this.processingsewn.asObservable();
  }
  setPatternData(data){
    this.pattern.next(data);
  }
  getPatternData(){
    return this.pattern.asObservable();
  }
}
