import * as stylesActions from "../actions/styles.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface StylesState extends EntityState<any> {
    selectedStylesId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const stylesAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultStyles: StylesState = {
    ids: [],
    entities: {},
    selectedStylesId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = stylesAdapter.getInitialState(defaultStyles);

export function StylesReducer(state = initialState, action: stylesActions.stylesActionTypes): StylesState {
    switch (action.type) {
        case stylesActions.StylesActionTypes.LOAD_STYLES_LIBRARY_SUCCESS:
            return stylesAdapter.addMany(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLES_LIBRARY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLES_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.CREATE_STYLES_SUCCESS:
            return stylesAdapter.addOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.CREATE_STYLES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.UPDATE_STYLES_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.UPDATE_STYLES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.UPDATE_STYLES_LIBRARY_SUCCESS:
            return stylesAdapter.updateMany(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.UPDATE_STYLES_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.DELETE_STYLES_LIBRARY_SUCCESS:
            return stylesAdapter.removeMany(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.DELETE_STYLES_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.UPDATE_STYLE_COLORWAYS_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.UPDATE_STYLE_COLORWAYS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_PATTERNS_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_PATTERNS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_IMAGES_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_IMAGES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_CONSTRUCTION_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_CONSTRUCTION_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_SPECIFICATION_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_SPECIFICATION_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_PROCESSING_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_PROCESSING_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_BOM_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_DEL_STYLE_BOM_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_STYLE_SHARED_ACCESS_SUCCESS:
            return stylesAdapter.updateOne(action.payload1, {
                ...state.message = action.payload2,
                ...state
            })
            break;
        case stylesActions.StylesActionTypes.ADD_UPDATE_STYLE_SHARED_ACCESS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_COLORWAYS_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_COLORWAYS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_BOM_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_BOM_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
            case stylesActions.StylesActionTypes.LOAD_STYLE_IMAGES_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_IMAGES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
            case stylesActions.StylesActionTypes.LOAD_STYLE_PATTERNS_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_PATTERNS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
            case stylesActions.StylesActionTypes.LOAD_STYLE_CONSTRUCTION_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_CONSTRUCTION_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
            
            case stylesActions.StylesActionTypes.LOAD_STYLE_ALL_USERS_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_ALL_USERS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
            case stylesActions.StylesActionTypes.LOAD_STYLE_SHARED_ACCESS_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_SHARED_ACCESS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
            case stylesActions.StylesActionTypes.LOAD_STYLE_NOTIFICATIONS_SUCCESS:
            return stylesAdapter.setOne(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                selectedStylesId: action.payload1.id
            })
            break;
        case stylesActions.StylesActionTypes.LOAD_STYLE_NOTIFICATIONS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getStylesLibraryFeatureState = createFeatureSelector<StylesState>('style');

export const getStylesLibrary = createSelector(
    getStylesLibraryFeatureState, stylesAdapter.getSelectors().selectAll
)
export const getStylesLibraryLoading = createSelector(
    getStylesLibraryFeatureState, (state: StylesState) => state.loading
)
export const getStylesLibraryLoaded = createSelector(
    getStylesLibraryFeatureState, (state: StylesState) => state.loaded
)
export const getStylesLibraryError = createSelector(
    getStylesLibraryFeatureState, (state: StylesState) => state.error
)
export const getStylesLibraryMessage = createSelector(
    getStylesLibraryFeatureState, (state: StylesState) => state.message
)
export const getStylesId = createSelector(
    getStylesLibraryFeatureState, (state: StylesState) => state.selectedStylesId
)
export const getStyles = createSelector(
    getStylesLibraryFeatureState, getStylesId, state => state.entities[state.selectedStylesId]
)