<!-- <footer class="dt-footer d-none">
  <div class="row">
       <div class="col-md-6 col-xl-6">
            <ul class="list-group list-group-horizontal">
                 <li class="list-group-item"><a data-toggle="modal" data-target="#termsandcondition">Terms and Conditions</a></li>
                 <li class="list-group-item">|</li>
                 <li class="list-group-item"><a data-toggle="modal"
                    data-target="#privacypolicy">Privacy Policy</a></li>
            </ul>
       </div>
       <div class="col-md-4 col-xl-4" style="display:none">
            <p class="text-center">
                 Copyright fitos © 2022 </p>
       </div>
       <div class="col-md-6 col-xl-6">
            <p class="text-right mb-0">
                 Version: 1.0.0 Beta </p>
       </div>
  </div>
</footer> -->


<!-- Terms Cond. and Privacy Policy Modal -->

<div class="modal fade" id="termsandcondition" tabindex="-1" role="dialog" aria-labelledby="termsandcondition"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">

    <!-- Modal Content -->
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title" id="model-2"> Terms and Conditions </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- /modal header -->
      <!-- Modal Body -->
      <div class="modal-body p-4">

        <p>Last updated: August 02, 2022 <br>
          Please read these terms and conditions carefully before using Our Service. </p>

        <h1 class="main-title">Interpretation and Definitions </h1>

        <p class="semi-title">Interpretation</p>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
          The following definitions shall have the same meaning regardless of whether they appear in singular or in
          plural. </p>

        <p class="semi-title">Definitions <br>
          <span style="font-size: 1.4rem;color: #121212;">For the purposes of these Terms and Conditions:</span>
        </p>

        <ul class="">
          <li class=""><b>Application</b> means the software program provided by the Company downloaded by You on any
            electronic device, named Fitos </li>
          <li class=""><b>Application Store</b> means the digital distribution service operated and developed by Apple
            Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.
          </li>
          <li class=""><b>Affiliate</b> means an entity that controls, is controlled by or is under common control with
            a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing authority. </li>
          <li class=""><b>Country</b> refers to: Maryland, United States </li>
          <li class=""><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
            refers to Pleneri, 6734 Charles S. Ave., Towson, MD - 21204. </li>
          <li class=""><b>Device</b> means any device that can access the Service such as a computer, a cellphone or a
            digital tablet. </li>
          <li class=""><b>Service</b> refers to the Application.</li>
          <li class=""><b>Terms and Conditions</b> (also referred as "Terms") mean these Terms and Conditions that form
            the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions
            agreement has been created with the help of the TermsFeed Terms and Conditions Generator. </li>
          <li class=""><b>Third-party</b> Social Media Service means any services or content (including data,
            information, products or services) provided by a third-party that may be displayed, included or made
            available by the Service. </li>
          <li class=""><b>You</b> means the individual accessing or using the Service, or the company, or other legal
            entity on behalf of which such individual is accessing or using the Service, as applicable. </li>

        </ul>

        <h1 class="main-title">Acknowledgment</h1>
        <p>These are the Terms and Conditions overning the use of this Service and the agreement that operates between
          You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the
          use of the Service. </p>
        <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms
          and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the
          Service. </p>
        <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with
          any part of these Terms and Conditions then You may not access the Service. </p>
        <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
        </p>
        <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the
          Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use
          and disclosure of Your personal information when You use the Application or the Website and tells You about
          Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our
          Service. </p>

        <h1 class="main-title">Links to Other Websites </h1>
        <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the
          Company. </p>
        <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or
          practices of any third party web sites or services. You further acknowledge and agree that the Company shall
          not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with the use of or reliance on any such content, goods or services available on or through
          any such web sites or services. </p>
        <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or
          services that You visit. </p>

        <h1 class="main-title">Termination</h1>
        <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if You breach these Terms and Conditions. </p>
        <p>Upon termination, Your right to use the Service will cease immediately. </p>

        <h1 class="main-title">Limitation of Liability </h1>
        <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its
          suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be
          limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything
          through the Service. </p>
        <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable
          for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, loss of data or other information, for business interruption, for personal
          injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service,
          third-party software and/or third-party hardware used with the Service, or otherwise in connection with any
          provision of this Terms), even if the Company or any supplier has been advised of the possibility of such
          damages and even if the remedy fails of its essential purpose. </p>
        <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
          consequential damages, which means that some of the above limitations may not apply. In these states, each
          party's liability will be limited to the greatest extent permitted by law. </p>

        <h1 class="main-title">"AS IS" and "AS AVAILABLE" Disclaimer </h1>
        <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf
          of its Affiliates and its and their respective licensors and service providers, expressly disclaims all
          warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all
          implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and
          warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without
          limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of
          any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work
          with any other software, applications, systems or services, operate without interruption, meet any performance
          or reliability standards or be error free or that any errors or defects can or will be corrected. </p>
        <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any
          representation or warranty of any kind, express or implied: (i) as to the operation or availability of the
          Service, or the information, content, and materials or products included thereon; (ii) that the Service will
          be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or
          content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from
          or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other
          harmful components. </p>
        <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
          statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You.
          But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest
          extent enforceable under applicable law. </p>

        <h1 class="main-title">Governing Law </h1>
        <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the
          Service. Your use of the Application may also be subject to other local, state, national, or international
          laws. </p>

        <h1 class="main-title">Disputes Resolution </h1>
        <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute
          informally by contacting the Company. </p>

        <h1 class="main-title">For European Union (EU) Users </h1>
        <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the
          country in which you are resident in. </p>

        <h1 class="main-title">United States Legal Compliance </h1>
        <p>You represent and warrant that (i) You are not located in a country that is subject to the United States
          government embargo, or that has been designated by the United States government as a "terrorist supporting"
          country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
        </p>

        <h1 class="main-title">Severability and Waiver </h1>
        <p class="semi-title">Severability</p>
        <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
          interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
          law and the remaining provisions will continue in full force and effect. </p>

        <p class="semi-title">Waiver</p>
        <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under
          these Terms shall not effect a party's ability to exercise such right or require such performance at any time
          thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach. </p>

        <h1 class="main-title">Translation Interpretation </h1>
        <p>These Terms and Conditions may have been translated if We have made them available to You on our Service. You
          agree that the original English text shall prevail in the case of a dispute. </p>

        <h1 class="main-title">Changes to These Terms and Conditions </h1>
        <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
          material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking
          effect. What constitutes a material change will be determined at Our sole discretion. </p>
        <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by
          the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website
          and the Service. </p>

        <h1 class="main-title">Contact Us </h1>
        <p>If you have any questions about these Terms and Conditions, You can contact us: </p>
        <p>By email: <a href="mailto:">info@pleneri.com</a></p>
        <p>By visiting this page on our website: <a href="https://pleneri.com/contact/ ">https://pleneri.com/contact/
          </a></p>
      </div>
      <!-- /modal body -->

    </div>
    <!-- /modal content -->

  </div>
</div>

<div class="modal fade" id="privacypolicy" tabindex="-1" role="dialog" aria-labelledby="privacypolicy" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">

    <!-- Modal Content -->
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title" id="model-2"> Privacy Policy </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- /modal header -->
      <!-- Modal Body -->
      <div class="modal-body p-4">

        <p>Last updated: August 02, 2022 </p>
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </p>
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
          collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created
          with the help of the TermsFeed Privacy Policy Generator. </p>

        <h1 class="main-title">Interpretation and Definitions </h1>

        <p class="semi-title">Interpretation</p>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
          The following definitions shall have the same meaning regardless of whether they appear in singular or in
          plural. </p>

        <p class="semi-title">Definitions <br>
          <span style="font-size: 1.4rem;color: #121212;">For the purposes of this Privacy Policy: </span>
        </p>

        <ul class="">
          <li class=""><b>Account</b> means a unique account created for You to access our Service or parts of our
            Service. </li>
          <li class=""><b>Affiliate </b>means an entity that controls, is controlled by or is under common control with
            a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing authority.
          </li>
          <li class=""><b>Application </b> means the software program provided by the Company downloaded by You on any
            electronic device, named Fitos </li>
          <li class=""><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
            refers to Pleneri, 6734 Charles S. Ave., Towson, MD - 21204. </li>
          <li class=""><b>Country</b> refers to: Maryland, United States </li>
          <li class=""><b>Device</b> means any device that can access the Service such as a computer, a cellphone or a
            digital tablet. </li>
          <li class=""><b>Personal Data </b>is any information that relates to an identified or identifiable individual.
          </li>
          <li class=""><b>Service </b> refers to the Application. </li>
          <li class=""><b>Service Provider </b> means any natural or legal person who processes the data on behalf of
            the Company. It refers to third-party companies or individuals employed by the Company to facilitate the
            Service, to provide the Service on behalf of the Company, to perform services related to the Service or to
            assist the Company in analyzing how the Service is used. </li>
          <li class=""><b>Usage Data</b> refers to data collected automatically, either generated by the use of the
            Service or from the Service infrastructure itself (for example, the duration of a page visit). </li>
          <li class=""><b>You</b> means the individual accessing or using the Service, or the company, or other legal
            entity on behalf of which such individual is accessing or using the Service, as applicable. </li>

        </ul>

        <h1 class="main-title">Collecting and Using Your Personal Data </h1>
        <p class="semi-title">Types of Data Collected </p>
        <p class="semi-title">Personal Data </p>
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that
          can be used to contact or identify You. Personally identifiable information may include, but is not limited
          to: </p>
        <p>Email address <br>
          First name and last name <br>
          Phone number <br>
          Address, State, Province, ZIP/Postal code, City <br>
          Usage Data </p>

        <p class="semi-title">Usage Data </p>

        <p>Usage Data is collected automatically when using the Service. </p>
        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser
          type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time
          spent on those pages, unique device identifiers and other diagnostic data. </p>
        <p>When You access the Service by or through a mobile device, We may collect certain information automatically,
          including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address
          of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique
          device identifiers and other diagnostic data.
        </p>
        <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the
          Service by or through a mobile device. </p>

        <p class="semi-title">Information Collected while Using the Application </p>
        <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior
          permission: </p>
        <p class="ml-3">Information regarding your location <br>
          Pictures and other information from your Device's camera and photo library </p>
        <p>We use this information to provide features of Our Service, to improve and customize Our Service. The
          information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply
          stored on Your device. </p>
        <p>You can enable or disable access to this information at any time, through Your Device settings. </p>



        <p class="semi-title">Use of Your Personal Data <br>
          <span style="font-size: 1.4rem;color: #121212;">The Company may use Personal Data for the following purposes:
          </span>
        </p>

        <ul class="">
          <li class=""><b>To provide and maintain our Service,</b> including to monitor the usage of our Service. </li>
          <li class=""><b>To manage Your Account: </b> to manage Your registration as a user of the Service. The
            Personal Data You provide can give You access to different functionalities of the Service that are available
            to You as a registered user. </li>
          <li class=""><b>For the performance of a contract: </b> the development, compliance and undertaking of the
            purchase contract for the products, items or services You have purchased or of any other contract with Us
            through the Service. </li>
          <li class=""><b>To contact You: </b> To contact You by email, telephone calls, SMS, or other equivalent forms
            of electronic communication, such as a mobile application's push notifications regarding updates or
            informative communications related to the functionalities, products or contracted services, including the
            security updates, when necessary or reasonable for their implementation. </li>
          <li class=""><b>To provide You </b> with news, special offers and general information about other goods,
            services and events which we offer that are similar to those that you have already purchased or enquired
            about unless You have opted not to receive such information. </li>
          <li class=""><b>Device</b> means any device that can access the Service such as a computer, a cellphone or a
            digital tablet. </li>
          <li class=""><b>To manage Your requests: </b> To attend and manage Your requests to Us. </li>
          <li class=""><b>For business transfers: </b> We may use Your information to evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our
            assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
            Personal Data held by Us about our Service users is among the assets transferred. </li>
          <li class=""><b>For other purposes: </b> We may use Your information for other purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and your experience. </li>
        </ul>

        <p>We may share Your personal information in the following situations: </p>

        <ul class="">
          <li class=""><b>With Service Providers: </b> We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, to contact You. </li>
          <li class=""><b>For business transfers: </b>We may share or transfer Your personal information in connection
            with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a
            portion of Our business to another company. </li>
          <li class=""><b>With Affiliates: </b> We may share Your information with Our affiliates, in which case we will
            require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners or other companies that We control or that are under common control
            with Us. </li>
          <li class=""><b>With business partners:</b> We may share Your information with Our business partners to offer
            You certain products, services or promotions. </li>
          <li class=""><b>With other users:</b> when You share personal information or otherwise interact in the public
            areas with other users, such information may be viewed by all users and may be publicly distributed outside.
          </li>
          <li class=""><b>With Your consent:</b>We may disclose Your personal information for any other purpose with
            Your consent. </li>

        </ul>

        <p class="semi-title">Use of Your Personal Data </p>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
          Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
          disputes, and enforce our legal agreements and policies. </p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for
          a shorter period of time, except when this data is used to strengthen the security or to improve the
          functionality of Our Service, or We are legally obligated to retain this data for longer time periods. </p>

        <p class="semi-title">Transfer of Your Personal Data </p>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other
          places where the parties involved in the processing are located. It means that this information may be
          transferred to — and maintained on — computers located outside of Your state, province, country or other
          governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. </p>
        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement
          to that transfer. </p>
        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
          accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
          or a country unless there are adequate controls in place including the security of Your data and other
          personal information. </p>

        <p class="semi-title">Disclosure of Your Personal Data </p>
        <p class="semi-title">Business Transactions </p>
        <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
          will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
          Policy. </p>

        <p class="semi-title">Law enforcement </p>
        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
          by law or in response to valid requests by public authorities (e.g. a court or a government agency). </p>

        <p class="semi-title">Other legal requirements </p>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to: </p>

        <ul>
          <li>Comply with a legal obligation </li>
          <li>Protect and defend the rights or property of the Company </li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service </li>
          <li>Protect the personal safety of Users of the Service or the public </li>
          <li>Protect against legal liability </li>
        </ul>

        <p class="semi-title">Security of Your Personal Data </p>
        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the
          Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
          to protect Your Personal Data, We cannot guarantee its absolute security. </p>



        <h1 class="main-title">Children's Privacy </h1>
        <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
          information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child
          has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data
          from anyone under the age of 13 without verification of parental consent, We take steps to remove that
          information from Our servers. </p>
        <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
          consent from a parent, We may require Your parent's consent before We collect and use that information. </p>

        <h1 class="main-title">Links to Other Websites </h1>
        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party
          link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of
          every site You visit. </p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
          third party sites or services. </p>

        <h1 class="main-title">Changes to this Privacy Policy </h1>
        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
          Privacy Policy on this page. </p>
        <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
          effective and update the "Last updated" date at the top of this Privacy Policy. </p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page. </p>

        <h1 class="main-title">Contact Us </h1>
        <p>If you have any questions about these Terms and Conditions, You can contact us: </p>
        <p>By email: <a href="mailto:">info@pleneri.com</a></p>
        <p>By visiting this page on our website: <a href="https://pleneri.com/contact/ ">https://pleneri.com/contact/
          </a></p>
      </div>
      <!-- /modal body -->

    </div>
    <!-- /modal content -->

  </div>
</div>
<!-- /Terms Cond. Modal  -->
