import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as paletteActions from "../actions/palette.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";


@Injectable()

export class PaletteEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }

    getAllPaletteDetails(data) {
        data.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, data.sequence);
        data.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.name);
        data.description = this.aesdecryptservice.decryptData(this.dataService.Key, data.description);
        data.status = this.aesdecryptservice.decryptData(this.dataService.Key, data.status);
        if (data.createdBy) {
            data.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.firstName);
            data.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key,data.createdBy.lastName);
        }
        if (data.documents && Object.keys(data.documents).length !== 0) {
            data.documents.forEach(x => {
                x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                x.labLocation = this.aesdecryptservice.decryptData(this.dataService.Key, x.labLocation);
                x.reportStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.reportStatus);
                x.notes = this.aesdecryptservice.decryptData(this.dataService.Key, x.notes);
               
                if (x.files) {
                    x.files.forEach(y => {
                        y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name)
                        y.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnail)
                    });
                }
            });
        }
        if (this.aesdecryptservice.decryptData(this.dataService.Key, data.colourHexCodes)) {
            data.colourHexCodes = this.aesdecryptservice.decryptData(this.dataService.Key, data.colourHexCodes);
        }
    }

    @Effect()
    loadPaletteLibrary: Observable<Action> = this.actions.pipe(
        ofType<paletteActions.LoadPaletteLibrary>(
            paletteActions.PaletteActionTypes.LOAD_PALETTE_LIBRARY
        ), mergeMap((action: paletteActions.LoadPaletteLibrary) =>
            this.dataService.getAllPalettes(action.payload1, action.payload2).pipe(
                map((paletteLibrary: any) => {
                    if (paletteLibrary['body']['item1'] != null && paletteLibrary['body']['item1'].length > 0 && !paletteLibrary['body']['item2'].toLowerCase().includes('error')) {
                        paletteLibrary['body']['item1'].forEach(x => {
                            x.colourHexCodes = this.aesdecryptservice.decryptData(this.dataService.Key, x.colourHexCodes);
                            x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                        });
                    }
                    return new paletteActions.LoadPaletteLibrarySuccess(paletteLibrary['body']['item1'], paletteLibrary['body'])
                }),
                catchError(err => of(new paletteActions.LoadPaletteLibraryFail(err)))
            )
        )
    );
    @Effect()
    loadPalette: Observable<Action> = this.actions.pipe(
        ofType<paletteActions.LoadPalette>(
            paletteActions.PaletteActionTypes.LOAD_PALETTE
        ), mergeMap((action: paletteActions.LoadPalette) =>
            this.dataService.getPaletteById(action.payload).pipe(
                map((palette: any) => {
                    if (!palette['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllPaletteDetails(palette['body']['item1']);
                    }
                    return new paletteActions.LoadPaletteSuccess(palette['body']['item1'], palette['body'])
                }),
                catchError(err => of(new paletteActions.LoadPaletteFail(err)))
            )
        )
    );
    @Effect()
    createPalette: Observable<any> = this.actions.pipe(
        ofType<paletteActions.CreatePalette>(
            paletteActions.PaletteActionTypes.CREATE_PALETTE
        ), map((action: paletteActions.CreatePalette) => action.payload),
        mergeMap((palette: any) =>
            this.dataService.addPalette(palette).pipe(
                map((newPalette: any) => {
                    if (!newPalette['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllPaletteDetails(newPalette['body']['item1']);
                    }
                    return new paletteActions.CreatePaletteSuccess(newPalette['body']['item1'], newPalette['body'])
                }),
                catchError(err => of(new paletteActions.CreatePaletteFail(err)))
            )
        )
    );

    @Effect()
    updatePalette: Observable<any> = this.actions.pipe(
        ofType<paletteActions.UpdatePalette>(
            paletteActions.PaletteActionTypes.UPDATE_PALETTE
        ), map((action: paletteActions.UpdatePalette) => action.payload),
        mergeMap((palette: any) =>
            this.dataService.updatePaletteById(palette).pipe(
                map((updatedPalette: any) => {
                    if (!updatedPalette['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllPaletteDetails(updatedPalette['body']['item1']);
                    }
                    return new paletteActions.UpdatePaletteSuccess({
                        id: updatedPalette['body']['item1'].id,
                        changes: updatedPalette['body']['item1']
                    }, updatedPalette['body'])
                }),
                catchError(err => of(new paletteActions.UpdatePaletteFail(err)))
            )
        )
    );

    @Effect()
    updatePaletteLibrary: Observable<any> = this.actions.pipe(
        ofType<paletteActions.UpdatePaletteLibrary>(
            paletteActions.PaletteActionTypes.UPDATE_PALETTE_LIBRARY
        ), map((action: paletteActions.UpdatePaletteLibrary) => action.payload),
        mergeMap((paletteIds: any[]) =>
            this.dataService.bulkUpdatePalettes(paletteIds).pipe(
                map((updatedPalette: any) =>
                    new paletteActions.UpdatePaletteLibrarySuccess([{
                        id: updatedPalette['body']['item1'].id,
                        changes: updatedPalette['body']['item1']
                    }], updatedPalette['body'])
                ),
                catchError(err => of(new paletteActions.UpdatePaletteLibraryFail(err)))
            )
        )
    );

    @Effect()
    deletePalette: Observable<any> = this.actions.pipe(
        ofType<paletteActions.DeletePaletteLibrary>(
            paletteActions.PaletteActionTypes.DELETE_PALETTE_LIBRARY
        ), map((action: paletteActions.DeletePaletteLibrary) => action.payload),
        mergeMap((paletteIds: any[]) =>
            this.dataService.bulkDeletePalettes(paletteIds).pipe(
                map((deletedPalette: any) =>
                    new paletteActions.DeletePaletteLibrarySuccess(paletteIds, deletedPalette['body'])
                ),
                catchError(err => of(new paletteActions.DeletePaletteLibraryFail(err)))
            )
        )
    );

    @Effect()
    updatePaletteDocument: Observable<any> = this.actions.pipe(
        ofType<paletteActions.UpdatePaletteDocument>(
            paletteActions.PaletteActionTypes.UPDATE_PALETTE_DOCUMENT
        ), map((action: paletteActions.UpdatePaletteDocument) => action.payload),
        mergeMap((palettedocument: any) =>
            this.dataService.updatePaletteDocuments(palettedocument).pipe(
                map((updatedPaletteDocument: any) => {
                    if (!updatedPaletteDocument['body']['item2'].toLowerCase().includes('error')) {
                        if (updatedPaletteDocument['body']['item1'].documents) {
                            this.getAllPaletteDetails(updatedPaletteDocument['body']['item1']);
                        }
                    }
                    return new paletteActions.UpdatePaletteDocumentSuccess({
                        id: updatedPaletteDocument['body']['item1'].id,
                        changes: updatedPaletteDocument['body']['item1']
                    }, updatedPaletteDocument['body'])
                }),
                catchError(err => of(new paletteActions.UpdatePaletteDocumentFail(err)))
            )
        )
    );
}