import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ColorEvent } from 'ngx-color';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {
  AddEvent,
  GridDataResult,
  GridComponent,
  CellClickEvent,
  PageChangeEvent,
  DataStateChangeEvent,
  SelectableSettings,
  SelectableMode,
  EditEvent,
} from '@progress/kendo-angular-grid';
import { select, Store } from '@ngrx/store';
import { map, skip, take } from 'rxjs/operators';
import * as styleActionTypes from '../../../actions/styles.actions';
import { DataService } from '../../../services/data.service';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import {
  IColor,
  ColourDocuments,
  CalSubTotal,
  Favourite,
} from '../../../models/color';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import * as S3 from 'aws-sdk/clients/s3';
import {
  process,
  filterBy,
  orderBy,
  State,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { Specialty } from 'aws-sdk/clients/transcribeservice';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { stringify } from '@angular/compiler/src/util';
import { ExportExcelService } from '../../../services/exportexcel.service';
import { SharedService } from '../../../services/shared.service';
import { interval, Observable, Observer } from 'rxjs';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { APIService } from '../../../services/API.service';
import Amplify, { Auth, API } from 'aws-amplify';
import { Router } from '@angular/router';
import { DiscussionComponent } from '../../../components/discussion/discussion.component';
import { ImageObject } from '../../../models/document';
import { LogEntry, LogService } from '../../../shared/log.service';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { truncate } from 'fs';
import { ToastService } from '../../../services/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { ColorwaysComponent } from '../../popups/colorways/colorways.component';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { NgxNumToWordsService, SUPPORTED_LANGUAGE } from 'ngx-num-to-words';
import numToWords from 'num-to-words';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { GroupDescriptor, GroupResult } from '@progress/kendo-data-query';
import { groupBy } from '@progress/kendo-data-query';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { base64ToFile } from 'ngx-image-cropper';
import { ConstructionStitchesComponent } from '../construction-stitches/construction-stitches.component';
import { ConstructionOperationComponent } from '../construction-operation/construction-operation.component';
import { StylebomComponent } from '../stylebom/stylebom.component';
import { FilterService } from '@progress/kendo-angular-grid';
import { datalist } from '../bomnew/datalist';
import { StylePOMComponent } from '../style-pom/style-pom.component';
import { StyleprocessingComponent } from '../styleprocessing/styleprocessing.component';
import { StyleprosewnComponent } from '../styleprosewn/styleprosewn.component';
import { StylePatternComponent } from '../stylepattern/stylepattern.component';
import { PatternFileUploadComponent } from '../pattern-file-upload/pattern-file-upload.component';
import { bool } from 'aws-sdk/clients/signer';
declare var $: any;

@Component({
  selector: 'app-styledetails',
  templateUrl: './styledetails.component.html',
  styleUrls: ['./styledetails.component.css'],
})
export class StyledetailsComponent implements OnInit {
  @ViewChild(LoaderComponent) child: LoaderComponent;
  @ViewChild(DiscussionComponent) discussionComponent: DiscussionComponent;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild('fileUploader') fileUploader: ElementRef;

  //@ViewChild('grid') grid: GridComponent;
  valueFromChild = {};
  showDiv = {
    previous: false,
    current: true,
    next: false,
  };

  lang: SUPPORTED_LANGUAGE = 'en';
  hovercontent: any = false;
  hoverimagecontent: any = true;
  imagePopupObject: any = [];
  currentIndex: any = -1;
  showFlag: any = false;
  hoverIndex: number = -1;
  modalReference: any;
  processingForm: FormGroup;
  constructionForm: FormGroup;
  patternForm: FormGroup;
  specificationForm: FormGroup;
  StichesForm: FormGroup;
  sewnForm: FormGroup;
  BOMformGroup: FormGroup;
  calsubTotal = new CalSubTotal();
  imageObject: Array<object> = [];
  topView: File[] = [];
  bottomView: File[] = [];
  public filteredData: any[];
  editedtopViewfile: File[] = [];
  editedtopViewValue = '';
  imgfiledata: Array<any> = [];
  imgFileName: any;
  editedbottomViewfile: File[] = [];
  editedbottomViewValue = '';
  fileData: Array<any> = [];
  isedit: Array<boolean> = [];
  data: any[] = [];
  sharedGridData: any[] = [];
  styleViewMode = 'Created';
  appStaticMsgs: any;
  userPermission: any = 'view';
  isFavourite: boolean;
  selected: any;
  select1: any;
  selectData: any = [
    'XS',
    'S',
    'M',
    'L',
    'XL',
    '2XL',
    '3XL',
    '4XL',
    '5XL',
    '6XL',
    'OS',
  ];
  selectData1: any = [
    0,
    2,
    4,
    6,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    'OS 8',
    'OS 10',
    'OS 12',
  ];

  public paginationStartcount = 0;
  public paginationEndcount = 10;

  public bomstartCountList = 0;
  public bomendCountList = 10;
  public bomstartEndCount = '';
  public bomisPageChanged = false;
  public bomRecordCount: any;
  public bomloading: boolean;
  public constructionstartCountList = 0;
  public constructionendCountList = 10;
  public constructionstartEndCount = '';
  public constructionisPageChanged = false;
  public constructionRecordCount: any;
  public constructionloading: boolean;

  public patternstartCountList = 0;
  public patternendCountList = 10;
  public patternstartEndCount = '';
  public patternisPageChanged = false;
  public patternRecordCount: any;
  public patternloading: boolean;

  public specificationPomsstartCountList = 0;
  public specificationPomsendCountList = 10;
  public specificationPomsstartEndCount = '';
  public specificationPomsisPageChanged = false;
  public specificationPomsRecordCount: any;
  public specificationPomsloading: boolean;

  public specificationStitchestartCountList = 0;
  public specificationStitcheendCountList = 10;
  public specificationStitchestartEndCount = '';
  public specificationStitcheisPageChanged = false;
  public specificationStitcheRecordCount: any;
  public specificationStitcheloading: boolean;

  public processingPomsstartCountList = 0;
  public processingPomsendCountList = 10;
  public processingPomsstartEndCount = '';
  public processingPomsisPageChanged = false;
  public processingPomsRecordCount: any;
  public processingPomsloading: boolean;

  public processingSewnstartCountList = 0;
  public processingSewnendCountList = 10;
  public processingSewnstartEndCount = '';
  public processingSewnisPageChanged = false;
  public processingSewnRecordCount: any;
  public processingSewnloading: boolean;

  public styleColourwaysCnt = 0;
  public styleImagesCnt = 0;

  public styleBomCnt = 0;
  public styleConstCnt = 0;
  public stylePtrnCnt = 0;
  public styleSpcCnt = 0;
  public styleProcCnt = 0;

  public isColourwaysUpdate = false;
  public isImagesUpdate = false;
  showBomNote = false;
  showConstructionNote = false;
  showPatternNote = false;
  showSpecPomNote = false;
  showSpecStitcheNote = false;
  showProcPomNote = false;
  showProcSewnNote = false;
  public selectedValue = '';
  public Quantity: number = 0;
  public changemode: boolean;
  public colorCode: any;
  MaterialName: Array<string>;
  public bomViewData: any;
  itemList = [];
  selectedItems = [];
  settings = {};
  editedSharedData = [];
  unReadCnt = 0;
  searchedKeyword: string;
  totalData: any[] = [];
  public totalRecordCount: any;
  stylesData = [];
  public SearchData = '';
  public isPageChanged = false;
  sharedEndListCount = 10;
  sharedStartListCount = 0;
  public startCountList = 0;
  public endCountList = 10;
  public selectableSettings: SelectableSettings;
  public checkboxOnly = false;
  public mode: SelectableMode = 'multiple';
  public filter: CompositeFilterDescriptor;
  sharedStartEndListCount: string;
  isDataLoading = false;
  sharedNotFound: boolean;
  loading: boolean;
  isSharedScrollDown = false;
  isTitleError = false;
  showImage = false;
  isPatternnew: boolean;
  isPatternedit: boolean;
  @ViewChild('closeModal') closeModal;

  @ViewChild(GridComponent) grid: GridComponent;
  public isNew: boolean;
  public isConNew: boolean;
  public isPatNew: boolean;
  public isSpeNew: boolean;
  public isStiNew: boolean;
  public isProNew: boolean;
  public isSewNew: boolean;
  private editedRowIndex: number;
  flag: any;
  exportData: any[];
  notfoundImg: string;
  classApplied = false;
  itemDetailsSection = false;
  timeLineSection = false;
  chatSection = false;
  LoggedinuserDetails: any;
  Loggedinuser: any;
  styleId: any;
  allChats: any = [];
  allUser: any;
  log: any[];
  editedFileData: Array<any> = [];
  removedDocIds = '';
  documentClassificationData: any[];
  cardsTop = [];
  cardsBottom = [];
  editedStyleData: any;
  UserDetails: any;
  isTopViewAdded: boolean;
  isBottomViewAdded: boolean;
  showLoader: boolean;
  showLoader1: boolean;
  subtotal: number;
  stylesNumber: any;
  Swal = require('sweetalert2');
  errors = '';
  errorDiv = false;
  alert = '';
  activeTab = 'styleDetailsTab';
  styleForm: FormGroup;
  createForm: FormGroup;
  stylecolorForm: FormGroup;
  styImgForm: FormGroup;
  isEditMode: boolean;
  isCreateMode: boolean;
  isEditingMode: boolean;
  isshared: boolean;
  isViewingMode: boolean;
  removedStyImgIds = '';
  submitted = false;
  submitted1 = false;
  colorways: any;
  styImg: [];
  isThumbnailAdded: boolean = false;
  colordata: any[] = [];
  images: any;
  files: File[] = [];
  cards = [];
  thumbNails: File[] = [];
  editedThumbnails: File[] = [];
  editedThumbnailValue = '';
  removedColorwaysIds = '';
  imageTypeSelected: any;
  gridContent = true;
  stichImage = '';
  materialData: any[] = [];
  vendorData = [];
  colorData = [];
  gridData: GridDataResult | any = [];
  BomData = [];
  specificationPoms: any;
  specificationPOMSAI: any;
  dbspecificationPoms: any[];
  stichesData: any;
  dbstichesData: any;
  sticheslist = [];
  patternData: any;
  ConstructionData: any;
  fieldArray: any;
  newfieldArray: any;
  sharedUsers = [];
  onlineUsers: any;
  processingPoms: any;
  dbprocessingPoms: any[];
  processingSewn: any;
  dbprocessingSewn: any[];
  agegroup: any;
  isDisabled: boolean;
  createTitle = '';
  id: any;
  bom: any;
  popupDescription: any = '';
  popupStyleName: any = '';
  recordId: any;
  activeTabValue;
  imageChangedEvent: any = '';
  croppedImage: any;
  imgload = false;
  fileChanged: any;

  documentImage = '../../../../assets/images/doccard/document.png';
  userImg = '../tenants/main/assets/images/icon/amay.jpg';
  userImgO = '../tenants/main/assets/images/icon/jimmy-jo.jpg';
  thumbnail: string =
    'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8';
  userThumbnail: string =
    'https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png';

  designDirection: {
    subcategory: boolean;
    designLength: boolean;
    neckType: boolean;
    sleevesLength: boolean;
    sleeveType: boolean;
  };

  public employees = [
    {
      Image: '848e6002-8a92-447d-951b-1ffd5e695578',
      id: '1',
      Name: 'Sig Jeannel',
      Category: 'Human Resources Assistant III',
      Status: 'Active',
      CreatedBy: '23/23/2002',
      CreatedOn: '23/23/2003',
      ModifiedOn: '23/23/2003',
      Action:
        "<button type='button' class='btn btn-secondary'>Secondary</button>",
    },
    {
      Image: '848e6002-8a92-447d-951b-1ffd5e695578',
      id: '2',
      Name: 'Sree',
      Category: 'Assistant III',
      Status: 'Active',
      CreatedBy: '12/3/2022',
      CreatedOn: '19/02/2022',
      ModifiedOn: '17/04/2020',
      Action:
        "<button type='button' class='btn btn-secondary'>Secondary</button>",
    },
  ];
  seltemplate = [
    { value: 'design', label: 'Design' },
    { value: 'technical', label: 'Technical' },
    { value: 'pready', label: 'Production ready' },
  ];

  category = [
    { value: 'casualwear', label: 'Casual wear' },
    { value: 'promotional', label: 'Promotional' },
    { value: 'designer', label: 'Designer' },
    { value: 'sportswear', label: 'Sports wear' },
    { value: 'loungewear', label: 'Lounge wear' },
    { value: 'uniform', label: 'Uniform' },
    { value: 'other', label: 'Other' },
  ];

  subcategory = [
    { value: 'mens', label: 'Mens' },
    { value: 'ladies', label: 'Ladies' },
    { value: 'children', label: 'Children' },
    { value: 'baby', label: 'Baby' },
    { value: 'unisex', label: 'Unisex' },
    { value: 'other', label: 'Other' },
  ];

  season = [
    { label: 'Spring', value: 'Spring' },
    { label: 'Summer', value: 'Summer' },
    { label: 'Fall', value: 'Fall' },
    { label: 'Winter', value: 'Winter' },
    { label: 'Everyday', value: 'Everyday' },
  ];
  gender = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  garmentTypeList = [
    { type: '', value: 'Select Garment' },
    { type: 'Topwear', value: 'Topwear' },
    { type: 'Dress', value: 'Dress' },
    { type: 'Bottomwear', value: 'Bottomwear' },
    { type: 'Specialwear', value: 'Specialwear' },
    { type: 'Outerwear', value: 'Outerwear' },
    { type: 'Active and Sportswear', value: 'Active and Sportswear' },
    { type: 'Loungewear', value: 'Loungewear' },
  ];

  // agegroup = [
  //   { value: 'infants&toddlers', label: 'Infants & Toddlers (0 & 4 Years)' },
  //   { value: 'boys', label: 'Boys (5 to 10 Years)' },
  //   { value: 'girls', label: 'Girls (5 to 10 Years)' },
  //   { value: 'girls10', label: 'Girls (10 to 19 Years)' },
  //   { value: 'men', label: 'Men (29 to 49 Years)' },
  //   { value: 'women', label: 'Women (29 to 49 Years)' },
  //   { value: 'menabove', label: 'Men (Above 50 Years)' },
  //   { value: 'womenabove', label: 'Women (Above 50 Years)' },
  // ];

  size = [
    { value: 'xs', label: 'XS' },
    { value: 's', label: 'S' },
    { value: 'm', label: 'M' },
    { value: 'l', label: 'L' },
    { value: 'xl', label: 'XL' },
    { value: '2XL', label: '2XL' },
  ];

  fabric = [
    { value: 'knit', label: 'Knit' },
    { value: 'woven', label: 'Woven' },
  ];

  package = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'complex', label: 'Complex' },
  ];

  imgtype = [
    { value: 'colorways', label: 'Colorways' },
    { value: 'designdetail', label: 'Design detail' },
    { value: 'measurement', label: 'Measurement' },
    { value: 'construction', label: 'Construction' },
    { value: 'coverpage', label: 'Cover page' },
    { value: 'certificate', label: 'Certificate' },
    { value: 'test reports', label: 'Test Reports' },
    { value: 'specifications', label: 'Specifications' },
    { value: 'PatternImages', label: 'Pattern Images' },
    { value: 'flatSketches', label: 'FlatSketches' },
  ];

  Component = [
    { value: 'Self', text: 'Self' },
    { value: 'Contrast', text: 'Contrast' },
    { value: 'Lining', text: 'Lining' },
    { value: 'Thread', text: 'Thread' },
    { value: 'Brand Label', text: 'Brand Label' },
    { value: 'Size Label', text: 'Size Label' },
    { value: 'Care/Content Label', text: 'Care/Content Label' },
    { value: 'Clamp Label', text: 'Clamp Label' },
    { value: 'Packaging', text: 'Packaging' },
  ];

  Size = [
    {
      value: '58/60 Inches',
      text: '58/60 Inches',
    },
    { value: '57/58 Inches', text: '57/58 Inches' },
    { value: '56/57 Inches', text: '56/57 Inches' },
  ];

  listItems: Array<{ text: string; value: string }> = [
    { text: 'Active', value: 'Active' },
    { text: 'Inactive', value: 'Inactive' },
  ];
  startEndCount: any;
  startCount: number = 0;
  endCount: number = 100;
  colorDropdownList: any = [];
  supplierDropdownList: any = [];
  addedSupplierIDs: any = [];
  addedColourIds: any = [];
  pomTemplatesList: any = [
    { label: 'T-Shirt', value: 'T-Shirt' },
    { label: 'Formal Shirt', value: 'Formal Shirt' },
    { label: 'Polo Shirt', value: 'Polo Shirt' },
    { label: 'Tank Top', value: 'Tank Top' },
    { label: 'A-line Dress', value: 'A-line Dress' },
    { label: 'Bodycon Dress', value: 'Bodycon Dress' },
    { label: 'Sheath Dress', value: 'Sheath Dress' },
    { label: 'Wrap Dress', value: 'Wrap Dress' },
    { label: 'Empire Waist Dress', value: 'Empire Waist Dress' },
    { label: 'Trousers', value: 'Trousers' },
    { label: 'Cargos', value: 'Cargos' },
    { label: 'Shorts', value: 'Shorts' },
    { label: 'Wide Leg Jeans', value: 'Wide Leg Jeans' },
    { label: ' Straight Jeans', value: ' Straight Jeans' },
    { label: 'Fit & Flare', value: 'Fit & Flare' },
    { label: 'Shift', value: 'Shift' },
    { label: 'Tent', value: 'Tent' },
    { label: 'Sun Dress', value: 'Sun Dress' },
    { label: 'Shirt Dress', value: 'Shirt Dress' },
    { label: 'Tee-Top', value: 'Tee-Top' },
    { label: 'Blouse', value: 'Blouse' },
    { label: 'Sweatshirt', value: 'Sweatshirt' },
    { label: 'Tunic', value: 'Tunic' },
    { label: 'Kaftan', value: 'Kaftan' },
    { label: 'Tube', value: 'Tube' },
    { label: 'Corset', value: 'Corset' },
    { label: 'Bardot', value: 'Bardot' },
  ];

  public colorstartCountList = 0;
  public colorendCountList = 5;
  public colorstartEndCount = '';
  totalColorCount: any;
  public materialstartCountList = 0;
  public materialendCountList = 10;
  public materialstartEndCount = '';
  totalMaterialCount: any;
  public stitchesstartCountList = 0;
  public stitchesendCountList = 10;
  public stitchesstartEndCount = '';
  totalStitchesCount: any;
  isColorWays = false;
  isBOM = false;
  isSpecification = false;
  stitchesClicked = false;
  materialClicked = false;
  colorClicked = false;
  dropdownList = [];
  selectedItemsnew = [];
  dropdownSettings = {};
  item: any;
  pomtab: boolean = false;
  dropdownList1: any = [
    { id: 1, itemName: 'Can View', selected: true },
    { id: 2, itemName: 'Can Edit' },
  ];
  dropdownSettings1 = {};
  selectedPermissions: any = [];
  sampleSize: any = [];
  patternImages: any;
  patternImageData: any;
  constData: any = [];
  data1: any;
  pomdata: any;
  isFilesAdded: boolean;
  isFileError = false;
  fileToReturn: any = [];
  showFileInput: boolean = true;
  isStitchesedit: boolean;
  isStitchesnew: boolean;
  optionslist: any = [];
  processingpomdata: any = [];
  processingsewndata: any = [];
  clickedRowItem: any;
  isOperationnew: boolean;
  isOperationedit: boolean;
  isPomnew: boolean;
  isPomedit: boolean;
  isbomnew: boolean;
  isbomedit: boolean;
  isprocessingnew: boolean;
  isprocessingedit: boolean;
  isprocessingsewnnew: boolean;
  isprocessingsewnedit: boolean;
  selctedPOMDescription: any[] = [];
  selectedPOMTemplateList: any = [];
  garmentTypeBasedList: any = [];
  topwearJSONList = {
    parentName: 'Topwear',
    Silhouette: [
      {
        type: '',
        value: 'Select Silhouette',
      },
      {
        type: 'Bardot',
        value: 'Bardot',
      },
      {
        type: 'Blouse',
        value: 'Blouse',
      },
      {
        type: 'Corset',
        value: 'Corset',
      },
      {
        type: 'Polo',
        value: 'Polo',
      },
      {
        type: 'Peplum',
        value: 'Peplum',
      },
      {
        type: 'Shirt',
        value: 'Shirt',
      },
      {
        type: 'Tank Top/Cami',
        value: 'Tank Top/Cami',
      },
      {
        type: 'Sweatshirt',
        value: 'Sweatshirt',
      },
      {
        type: 'T-Shirt',
        value: 'T-Shirt',
      },
      {
        type: 'Tee-Top',
        value: 'Tee-Top',
      },
      {
        type: 'Tube',
        value: 'Tube',
      },
      {
        type: 'Tunic',
        value: 'Tunic',
      },
      {
        type: 'Kaftan',
        value: 'Kaftan',
      },
      {
        type: 'Empire',
        value: 'Empire',
      },
      {
        type: 'Wrap',
        value: 'Wrap',
      },
    ],
    Length: [
      {
        type: '',
        value: 'Select Length',
      },
      {
        type: 'Crop',
        value: 'Crop',
      },
      {
        type: 'Regular/Waist',
        value: 'Regular/Waist',
      },
      {
        type: 'Longline',
        value: 'Longline',
      },
    ],
    NeckType: [
      {
        type: '',
        value: 'Select NeckType',
      },
      {
        type: 'Round',
        value: 'Round',
      },
      {
        type: 'Square',
        value: 'Square',
      },
      {
        type: 'V-Neck',
        value: 'V-Neck',
      },
      {
        type: 'Sweetheart',
        value: 'Sweetheart',
      },
      {
        type: 'Turtle Neck',
        value: 'Turtle Neck',
      },
      {
        type: 'Off-Shoulder',
        value: 'Off-Shoulder',
      },
      {
        type: 'Peter Pan Collar',
        value: 'Peter Pan Collar',
      },
      {
        type: 'Straight Collar',
        value: 'Straight Collar',
      },
      {
        type: 'Strapless-Sweetheart',
        value: 'Strapless-Sweetheart',
      },
      {
        type: 'Strapless-Straight',
        value: 'Strapless-Straight',
      },
      {
        type: 'Halter',
        value: 'Halter',
      },
      {
        type: 'Mandarin Collar',
        value: 'Mandarin Collar',
      },
      {
        type: 'Boat',
        value: 'Boat',
      },
      {
        type: 'Lapel',
        value: 'Lapel',
      },
      {
        type: 'Shawl Collar',
        value: 'Shawl Collar',
      },
      {
        type: 'Cowl',
        value: 'Cowl',
      },
    ],
    SleeveLength: [
      {
        type: '',
        value: 'Select Sleeve Length',
      },
      {
        type: 'Short',
        value: 'Short',
      },
      {
        type: 'Elbow',
        value: 'Elbow',
      },
      {
        type: 'Three-Fourth',
        value: 'Three-Fourth',
      },
      {
        type: 'Full',
        value: 'Full',
      },
    ],
    SleeveType: [
      {
        type: '',
        value: 'Select Sleeve Type',
      },
      {
        type: 'Regular',
        value: 'Regular',
      },
      {
        type: 'Puff',
        value: 'Puff',
      },
      {
        type: 'Bell',
        value: 'Bell',
      },
      {
        type: 'Straps',
        value: 'Straps',
      },
      {
        type: 'Sleeveless',
        value: 'Sleeveless',
      },
      {
        type: 'Flutter',
        value: 'Flutter',
      },
      {
        type: 'Bishop',
        value: 'Bishop',
      },
      {
        type: 'Cold Shoulder',
        value: 'Cold Shoulder',
      },
    ],
    Fitting: [
      {
        type: '',
        value: 'Select Fitting',
      },
      {
        type: 'Fitted',
        value: 'Fitted',
      },
      {
        type: 'Relaxed',
        value: 'Relaxed',
      },
      {
        type: 'Oversized',
        value: 'Oversized',
      },
    ],
    Color: [
      {
        type: '',
        value: 'Select Color',
      },
      {
        type: 'Red',
        value: 'Red',
      },
      {
        type: 'Green',
        value: 'Green',
      },
      {
        type: 'Blue',
        value: 'Blue',
      },
      {
        type: 'Yellow',
        value: 'Yellow',
      },
      {
        type: 'Purple',
        value: 'Purple',
      },
      {
        type: 'Orange',
        value: 'Orange',
      },
      {
        type: 'Pink',
        value: 'Pink',
      },
      {
        type: 'Brown',
        value: 'Brown',
      },
      {
        type: 'Gray',
        value: 'Gray',
      },
      {
        type: 'Teal',
        value: 'Teal',
      },
      {
        type: 'White',
        value: 'White',
      },
    ],
  };

  dressJSONList = {
    parentName: 'Dress',
    Silhouette: [
      {
        type: '',
        value: 'Select Silhouette',
      },
      {
        type: 'A-Line',
        value: 'A-Line',
      },
      {
        type: 'Bodycon',
        value: 'Bodycon',
      },
      {
        type: 'Empire',
        value: 'Empire',
      },
      {
        type: 'Fit & Flare',
        value: 'Fit & Flare',
      },
      {
        type: 'Tent',
        value: 'Tent',
      },
      {
        type: 'Sun Dress',
        value: 'Sun Dress',
      },
      {
        type: 'Shift',
        value: 'Shift',
      },
      {
        type: 'Sheath',
        value: 'Sheath',
      },
      {
        type: 'Shirt Dress',
        value: 'Shirt Dress',
      },
      {
        type: 'Wrap',
        value: 'Wrap',
      },
    ],
    Length: [
      {
        type: '',
        value: 'Select Length',
      },
      {
        type: 'Mini',
        value: 'Mini',
      },
      {
        type: 'Midi',
        value: 'Midi',
      },
      {
        type: 'Maxi',
        value: 'Maxi',
      },
    ],
    NeckType: [
      {
        type: '',
        value: 'Select Neck Type',
      },
      {
        type: 'Round',
        value: 'Round',
      },
      {
        type: 'Square',
        value: 'Square',
      },
      {
        type: 'V-Neck',
        value: 'V-Neck',
      },
      {
        type: 'Sweetheart',
        value: 'Sweetheart',
      },
      {
        type: 'Turtle Neck',
        value: 'Turtle Neck',
      },
      {
        type: 'Off-Shoulder',
        value: 'Off-Shoulder',
      },
      {
        type: 'Peter Pan Collar',
        value: 'Peter Pan Collar',
      },
      {
        type: 'Straight Collar',
        value: 'Straight Collar',
      },
      {
        type: 'Strapless-Sweetheart',
        value: 'Strapless-Sweetheart',
      },
      {
        type: 'Strapless-Straight',
        value: 'Strapless-Straight',
      },
      {
        type: 'Halter',
        value: 'Halter',
      },
      {
        type: 'Mandarin Collar',
        value: 'Mandarin Collar',
      },
      {
        type: 'Boat',
        value: 'Boat',
      },
      {
        type: 'Lapel',
        value: 'Lapel',
      },
      {
        type: 'Shawl Collar',
        value: 'Shawl Collar',
      },
      {
        type: 'Cowl',
        value: 'Cowl',
      },
    ],
    SleeveLength: [
      {
        type: '',
        value: 'Select Sleeve Length',
      },
      {
        type: 'Short',
        value: 'Short',
      },
      {
        type: 'Elbow',
        value: 'Elbow',
      },
      {
        type: 'Three-Fourth',
        value: 'Three-Fourth',
      },
      {
        type: 'Full',
        value: 'Full',
      },
    ],
    SleeveType: [
      {
        type: '',
        value: 'Select Sleeve Type',
      },
      {
        type: 'Regular',
        value: 'Regular',
      },
      {
        type: 'Puff',
        value: 'Puff',
      },
      {
        type: 'Bell',
        value: 'Bell',
      },
      {
        type: 'Straps',
        value: 'Straps',
      },
      {
        type: 'Sleeveless',
        value: 'Sleeveless',
      },
      {
        type: 'Flutter',
        value: 'Flutter',
      },
      {
        type: 'Bishop',
        value: 'Bishop',
      },
      {
        type: 'Cold Shoulder',
        value: 'Cold Shoulder',
      },
    ],
    Fitting: [],
    Color: [
      {
        type: '',
        value: 'Select Color',
      },
      {
        type: 'Red',
        value: 'Red',
      },
      {
        type: 'Green',
        value: 'Green',
      },
      {
        type: 'Blue',
        value: 'Blue',
      },
      {
        type: 'Yellow',
        value: 'Yellow',
      },
      {
        type: 'Purple',
        value: 'Purple',
      },
      {
        type: 'Orange',
        value: 'Orange',
      },
      {
        type: 'Pink',
        value: 'Pink',
      },
      {
        type: 'Brown',
        value: 'Brown',
      },
      {
        type: 'Gray',
        value: 'Gray',
      },
      {
        type: 'Teal',
        value: 'Teal',
      },
      {
        type: 'White',
        value: 'White',
      },
    ],
  };
  bottomwearJSONList = {
    parentName: 'BottomWear',
    Silhouette: [
      {
        type: '',
        value: 'Select Silhouette',
      },
      { type: 'Jeans', value: 'Jeans' },
      { type: 'Cargo', value: 'Cargo' },
      { type: 'Trousers', value: 'Trousers' },
      { type: 'Leggings', value: 'Leggings' },
      { type: 'Shorts', value: 'Shorts' },
      { type: 'Joggers', value: 'Joggers' },
      { type: 'A_Line_Skirt', value: 'A_Line_Skirt' },
      { type: 'Skater_Skirt', value: 'Skater_Skirt' },
      { type: 'Wrap_Skirt', value: 'Wrap_Skirt' },
      { type: 'Pleated_Skirt', value: 'Pleated_Skirt' },
      { type: 'Pencil_Skirt', value: 'Pencil_Skirt' },
    ],
  };

  specialWearJSONLIST = {
    parentName: 'SpecialWear',
    Silhouette: [
      {
        type: '',
        value: 'Select Silhouette',
      },
      { type: 'Hoodie', value: 'Hoodie' },
      { type: 'Blazer', value: 'Blazer' },
      { type: 'Denim_Jacket', value: 'Denim_Jacket' },
      { type: 'Leather_Jacket', value: 'Leather_Jacket' },
      { type: 'Trench_Coat', value: 'Trench_Coat' },
      { type: 'Cardigan', value: 'Cardigan' },
      { type: 'Sweater', value: 'Sweater' },
    ],
  };
  aiPomList: any[] = [];
  aiPomListkeys: any[] = [];
  generateDscusingAI: string = '';
  generateDscResponse: any[] = [];
  EditedImage = false;
  // styleSubCategory: string;
  get f() {
    return this.styleForm?.controls;
  }
  get f2() {
    return this.createForm.controls;
  }
  get f1() {
    return this.BOMformGroup.controls;
  }
  get fa() {
    return this.f1.formArray as FormArray;
  }
  fac(data) {
    return this.fa.controls[this.BomData.indexOf(data)] as FormGroup;
  }

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public bomgridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public constructiongridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public patterngridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public specificationPomsgridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public specificationStitchegridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public processingPomsgridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public processingSewngridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  showModal: boolean;
  /* To call dataStateChange event for BOM grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */

  public onBOMChange(state: DataStateChangeEvent): void {
    let cDat = [];
    if (this.selected?.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.filteredData;
    }
    this.bomgridState = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.bomRecordCount,
      };
      this.bomViewData = process(gridView.data, this.bomgridState);
      console.log(this.bomViewData);
      this.bomViewData.total = gridView.total; //process(this.filteredData, this.state);
    } else {
      var gridView = {
        data:
          this.styleViewMode === 'Shared'
            ? this.sharedGridData
            : this.filteredData,
        total:
          this.styleViewMode === 'Shared'
            ? this.totalSharedRecordCount
            : this.bomRecordCount,
      };
      this.bomViewData = process(gridView.data, this.bomgridState);
      this.bomViewData.total = gridView.total; // process(this.stylesData, this.state);
    }
  }

  // image Hover Event
  StyleImageHover(i: number) {
    this.imgload = true;
    this.hoverIndex = i;
  }

  // Method to open the Role selection Modal pop-up
  Modalopen(content) {
    console.log(content);
    this.isThumbnailAdded = false;
    let ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
  }

  // Method to close the Role selection Pop-Up
  continue() {
    this.modalReference.close();
  }

  /* To call dataStateChange event for Construction grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */

  public onConstructionChange(state: DataStateChangeEvent) {
    let cDat = [];
    if (this.selected?.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.filteredData;
    }
    this.constructiongridState = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.constructionRecordCount,
      };
      this.ConstructionData = process(
        gridView.data,
        this.constructiongridState
      );
      this.ConstructionData.total = gridView.total; //process(this.filteredData, this.state);
    } else {
      var gridView = {
        data:
          this.styleViewMode === 'Shared'
            ? this.sharedGridData
            : this.filteredData,
        total:
          this.styleViewMode === 'Shared'
            ? this.totalSharedRecordCount
            : this.constructionRecordCount,
      };
      this.ConstructionData = process(
        gridView.data,
        this.constructiongridState
      );
      this.ConstructionData.total = gridView.total; // process(this.stylesData, this.state);
    }

    /* this.constructiongridState = state;
    var gridView = {
      data: this.filteredData,
      total: this.constructionRecordCount,
    };
    this.ConstructionData = gridView; */
    //process(this.filteredData, this.gridState);
  }

  /* To call dataStateChange event for Pattern grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */
  public onPatternStateChange(state: DataStateChangeEvent) {
    let cDat = [];
    if (this.selected?.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.filteredData;
    }
    this.patterngridState = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.patternRecordCount,
      };
      this.patternData = process(gridView.data, this.patterngridState);
      this.patternData.total = gridView.total; //process(this.filteredData, this.state);
    } else {
      var gridView = {
        data:
          this.styleViewMode === 'Shared'
            ? this.sharedGridData
            : this.filteredData,
        total:
          this.styleViewMode === 'Shared'
            ? this.totalSharedRecordCount
            : this.patternRecordCount,
      };
      this.patternData = process(gridView.data, this.patterngridState);
      this.patternData.total = gridView.total; // process(this.stylesData, this.state);
    }

    /*  this.patterngridState = state;
     var gridView = {
       data: this.filteredData,
       total: this.patternRecordCount,
     };
     this.patternData = gridView; */
    //process(this.filteredData, this.gridState);
  }

  /* To call dataStateChange event for SpecificationPoms grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */
  public onSpecificationPomsStateChange(state: DataStateChangeEvent) {
    let cDat = [];
    if (this.selected?.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.dbspecificationPoms;
    }
    this.specificationPomsgridState = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.specificationPomsRecordCount,
      };
      this.specificationPoms = process(
        gridView.data,
        this.specificationPomsgridState
      );
      this.specificationPoms.total = gridView.total; //process(this.filteredData, this.state);
      // this.specificationPOMSAI = this.specificationPoms;
      console.log('nnnnnnnnnnn 1472', this.specificationPoms);
    } else {
      var gridView = {
        data:
          this.styleViewMode === 'Shared'
            ? this.sharedGridData
            : this.dbspecificationPoms,
        total:
          this.styleViewMode === 'Shared'
            ? this.totalSharedRecordCount
            : this.specificationPomsRecordCount,
      };
      this.specificationPoms = process(
        gridView.data,
        this.specificationPomsgridState
      );
      this.specificationPoms.total = gridView.total; // process(this.stylesData, this.state);
      // this.specificationPOMSAI = this.specificationPoms;
      console.log('nnnnnnnnnnn 1490', this.specificationPoms);
    }

    /* this.specificationPomsgridState = state;
    var gridView = {
      data: this.dbspecificationPoms,
      total: this.specificationPomsRecordCount,
    };
    this.specificationPoms = gridView;
    console.log(this.specificationPoms, 'specifictaion'); */
    //process(this.filteredData['stylePOMs'], this.specificationPomsgridState);
  }

  /* To call dataStateChange event for SpecificationStitches grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */

  public onSpecificationStitcheStateChange(state: DataStateChangeEvent) {
    let cDat = [];
    if (this.selected?.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.filteredData;
    }
    this.specificationStitchegridState = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.specificationStitcheRecordCount,
      };
      this.stichesData = process(
        gridView.data,
        this.specificationStitchegridState
      );
      this.stichesData.total = gridView.total; //process(this.filteredData, this.state);
    } else {
      var gridView = {
        data:
          this.styleViewMode === 'Shared'
            ? this.sharedGridData
            : this.filteredData,
        total:
          this.styleViewMode === 'Shared'
            ? this.totalSharedRecordCount
            : this.specificationStitcheRecordCount,
      };
      this.stichesData = process(
        gridView.data,
        this.specificationStitchegridState
      );
      this.stichesData.total = gridView.total; // process(this.stylesData, this.state);
    }
    /* this.specificationStitchegridState = state;
    var gridView = {
      data: this.dbstichesData,
      total: this.specificationStitcheRecordCount,
    };
    this.stichesData = gridView; */
    //process(this.filteredData['stylePOMs'], this.specificationStitchegridState);
  }

  /* To call dataStateChange event for ProcessingPoms grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */
  public onProcessingPomsStateChange(state: DataStateChangeEvent) {
    let cDat = [];
    if (this.selected?.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.dbprocessingPoms;
    }
    this.processingPomsgridState = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.specificationStitcheRecordCount,
      };
      this.processingPoms = process(
        gridView.data,
        this.processingPomsgridState
      );
      this.processingPoms.total = gridView.total; //process(this.filteredData, this.state);
    } else {
      var gridView = {
        data:
          this.styleViewMode === 'Shared'
            ? this.sharedGridData
            : this.dbprocessingPoms,
        total:
          this.styleViewMode === 'Shared'
            ? this.totalSharedRecordCount
            : this.specificationStitcheRecordCount,
      };
      this.processingPoms = process(
        gridView.data,
        this.processingPomsgridState
      );
      this.processingPoms.total = gridView.total; // process(this.stylesData, this.state);
    }

    /* this.processingPomsgridState = state;
    var gridView = {
      data: this.dbprocessingPoms,
      total: this.processingPomsRecordCount,
    };
    this.processingPoms = gridView; */
    //process(this.filteredData['stylePOMs'], this.processingPomsgridState);
  }

  /* To call dataStateChange event for ProcessingSewn grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */
  public onProcessingSewnStateChange(state: DataStateChangeEvent) {
    let cDat = [];
    if (this.selected?.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.dbprocessingSewn;
    }
    this.processingSewngridState = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.processingSewnRecordCount,
      };
      this.processingSewn = process(
        gridView.data,
        this.processingSewngridState
      );
      this.processingSewn.total = gridView.total; //process(this.filteredData, this.state);
    } else {
      var gridView = {
        data:
          this.styleViewMode === 'Shared'
            ? this.sharedGridData
            : this.dbprocessingSewn,
        total:
          this.styleViewMode === 'Shared'
            ? this.totalSharedRecordCount
            : this.processingSewnRecordCount,
      };
      this.processingSewn = process(
        gridView.data,
        this.processingSewngridState
      );
      this.processingSewn.total = gridView.total; // process(this.stylesData, this.state);
    }

    /*  this.processingSewngridState = state;
     var gridView = {
       data: this.dbprocessingSewn,
       total: this.processingSewnRecordCount,
     };
     this.processingSewn = gridView; */
    //process(this.filteredData['stylePOMs'], this.processingSewngridState);
  }

  public checkBoxData = [
    { title: 'Colourways', name: 'Colourways', checked: false, disabled: true },
    { title: 'Style Images', name: 'Images', checked: false, disabled: true },
    { title: 'BOM', name: 'BOM', checked: false, disabled: true },
    {
      title: 'Construction',
      name: 'Construction',
      checked: false,
      disabled: true,
    },
    { title: 'Pattern', name: 'Pattern', checked: false, disabled: true },
    {
      title: 'Specification',
      name: 'Specification',
      checked: false,
      disabled: true,
    },
    { title: 'Processing', name: 'Processing', checked: false, disabled: true },
  ];
  public checkedData: any;
  modifiedData: any = [];

  public supplierstartCountList = 0;
  public supplierendCountList = 10;
  public supplierstartEndCount = '';
  totalSupplierCount: any = [];

  selectedCar: number;
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  sizeCharts: any;
  pomModal: any;
  uploadedFileName: any = '';
  fileName: any;
  attachmentUpdateImg = false;
  colorwaysCard: boolean = false;
  pomsAdded = false;
  savePOMSTemplate: any = [];
  savePOMSAITemplate: any = [];
  getPOMSList = 0;
  addNewPOMS = false;
  newPOMSTemplate: any;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private router: Router,
    private apiService: APIService,
    private exportExcel: ExportExcelService,
    private storageservice: StorageService,
    private dataservice: DataService,
    private _lightbox: Lightbox,
    public userservice: UserService,
    private renderer: Renderer2,
    private store: Store<any>,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private toastService: ToastService,
    public filterService: FilterService,
    private modalService: NgbModal,
    private httpClient: HttpClient
  ) {
    (this.styImgForm = this.fb.group({
      styImg: this.fb.array([]),
    })),
      (this.stylecolorForm = this.fb.group({
        colorways: this.fb.array([]),
      }));
    this.allData = this.allData.bind(this);
    this.stitchExpoertData = this.stitchExpoertData.bind(this);
    this.specificationPomsExpoertData =
      this.specificationPomsExpoertData.bind(this);
    this.processingPomsExpoertData = this.processingPomsExpoertData.bind(this);
    this.processingSewnExpoertData = this.processingSewnExpoertData.bind(this);
  }

  /* To display success msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    );
  }

  /* To display error msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  }

  warning(message: string) {
    Swal.fire({
      icon: 'warning',
      title: '',
      text: message,
    });
  }

  /* To display session expired msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  sessionTimedOut() {
    let timerInterval;
    Swal.fire({
      title: this.appStaticMsgs.common.sessionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
        this.userservice.logout();
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    });
  }

  changeDiv() {
    this.showDiv.next = !this.showDiv.next;
    this.showDiv.previous = false;
    this.showDiv.current = false;
    this.loadSelectPicker();
  }

  /* To set properties initialization on load page
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */
  async ngOnInit() {
    this.LoggedinuserDetails = await Auth.currentAuthenticatedUser();
    this.showLoader = true;
    this.getActiveTab();
    this.getAllStyleSizeRange();
    this.getColoursList();
    this.getSupplierList();
    this.appStaticMsgs = appStaticMsg?.data[0];
    this.storageservice.removeAIDescription();
    //console.log('showDiv',this.showDiv);
    // this.dropdownList = [
    //   {"id":1,"itemName":"lakhan.sharma@pluraltechnology.com"},
    //   {"id":2,"itemName":"Sreekanth.Manne@pluraltechnology.com"},
    //   {"id":3,"itemName":"lakhan.sharma@pluraltechnology.com"},
    //   {"id":4,"itemName":"Sreekanth.Manne@pluraltechnology.com"},
    //   {"id":5,"itemName":"lakhan.sharma@pluraltechnology.com"},
    //   {"id":6,"itemName":"Sreekanth.Manne@pluraltechnology.com"},
    //   {"id":7,"itemName":"lakhan.sharma@pluraltechnology.com"},
    //   {"id":8,"itemName":"Sreekanth.Manne@pluraltechnology.com"}
    // ];

    this.selectedItemsnew = [];
    this.dropdownSettings = {
      primaryKey: 'id',
      labelKey: 'itemName',
      showCheckbox: false,
      singleSelection: false,
      escapeToClose: true,
      text: this.appStaticMsgs.styledetails.selectCollaborators,
      selectAllText: '',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
    };

    this.dropdownSettings1 = {
      primaryKey: 'id',
      labelKey: 'itemName',
      singleSelection: true,
      escapeToClose: true,
      text: this.appStaticMsgs.styledetails.selectcolorwayname,
      classes: 'myclass custom-class',
    };

    this.flag = 0;
    const source = interval(1.2e8);
    source.subscribe((val) => this.dataservice.AddClientLog());
    //this.loadSelectPicker();
    this.notfoundImg = '../../../../assets/images/notfound/NotFound.png';
    this.newfieldArray = [];
    this.designDirection = {
      subcategory: true,
      designLength: true,
      neckType: true,
      sleevesLength: true,
      sleeveType: true,
    };
    this.fieldArray = [
      {
        edit: false,
        view: true,
        id: '',
        sharedTo: '',
        user: '',
      },
    ];

    this.settings = {
      text: this.appStaticMsgs.styledetails.searchName,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      showCheckbox: false,
      singleSelection: true,
      classes: 'myclass custom-class-example',
    };

    this.BOMformGroup = new FormGroup({
      stylebomid: new FormControl(),
      styleid: new FormControl(),
      styleNumber: new FormControl(),
      component: new FormControl(''),
      materialid: new FormControl(''),
      materialType: new FormControl(),
      materialname: new FormControl(),
      itemNo: new FormControl(),
      supplierId: new FormControl(''),
      colourid: new FormControl(''),
      colourname: new FormControl(),
      quantity: new FormControl(0),
      size: new FormControl(''),
      // colorCode:new FormControl(),
      price: new FormControl(),
      subTotal: new FormControl(),
      uom: new FormControl(),
      colourcode: new FormControl(),
      notes: new FormControl(''),
    });

    this.constructionForm = new FormGroup({
      operation: new FormControl('', Validators.required),
      mC_Type: new FormControl('', Validators.required),
      smv: new FormControl('', Validators.required),
      imageid: new FormControl(''),
      scid: new FormControl(''),
    });

    this.patternForm = new FormGroup({
      patternid: new FormControl(),
      pc: new FormControl(),
      description: new FormControl(''),
      cut: new FormControl(''),
      allocation: new FormControl(''),
    });

    /* written by Umadevi Gajji,line number 813 to 865
    on 24/11/2022 */
    this.specificationForm = this.createGroup();
    /*if(this.data1 || this.pomdata =='XS, S, …..6XL, OS'){
        this.specificationForm = new FormGroup({
          'spid': new FormControl(),
           'poms': new FormControl('', Validators.required),
          // 's': new FormControl('', Validators.required),
          // 'xs': new FormControl('', Validators.required),
          // 'm': new FormControl('', Validators.required),
          // 'l': new FormControl('', Validators.required),
          // 'xl': new FormControl('', Validators.required),
          // 'doubleXL': new FormControl('', Validators.required),
         'tolerance': new FormControl('', Validators.required),
          's': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'xs': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'm': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'l': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'xl': new FormControl('', Validators.required),
          'doubleXL': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'threebleXL': new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'fourXL': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'fiveXL': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'sixXL': new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'OS': new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
        });
      }else{
        this.specificationForm = new FormGroup({
          'spid': new FormControl(),
           'poms': new FormControl('', Validators.required),
          // 's': new FormControl('', Validators.required),
          // 'xs': new FormControl('', Validators.required),
          // 'm': new FormControl('', Validators.required),
          // 'l': new FormControl('', Validators.required),
          // 'xl': new FormControl('', Validators.required),
          // 'doubleXL': new FormControl('', Validators.required),
           'tolerance': new FormControl('', Validators.required),
          'zero': new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'two': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'four': new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'six': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'eight': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'ten': new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'twelve': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'fourteen': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'sixteen': new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          'eightteen': new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
           "twenty": new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          "twentytwo": new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
        "twentyfour": new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
         "eightOS": new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
         "tenOS": new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
           "twelveOS": new FormControl('',[Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
        });

      }*/

    this.StichesForm = new FormGroup({
      ssid: new FormControl(),
      stitchid: new FormControl(),
      placement: new FormControl('', Validators.required),
      notes: new FormControl(''),
    });

    this.processingForm = new FormGroup({
      pom: new FormControl({ value: 'POM', disabled: true }),
      pc: new FormControl(),
      poms: new FormControl(),
      originalLength: new FormControl('', Validators.required),
      originalWidth: new FormControl('', Validators.required),
      afterwashLength: new FormControl('', Validators.required),
      afterwashWidth: new FormControl('', Validators.required),
      afterdryLength: new FormControl('', Validators.required),
      afterdryWidth: new FormControl('', Validators.required),
      id: new FormControl(),
    });

    this.sewnForm = new FormGroup({
      sewn: new FormControl({ value: 'SEWN', disabled: true }),
      pc: new FormControl(),
      poms: new FormControl(),
      originalLength: new FormControl('', Validators.required),
      originalWidth: new FormControl('', Validators.required),
      afterwashLength: new FormControl('', Validators.required),
      afterwashWidth: new FormControl('', Validators.required),
      afterdryLength: new FormControl('', Validators.required),
      afterdryWidth: new FormControl('', Validators.required),
      id: new FormControl(),
    });

    this.getOnlineUsers();
    this.dataservice.AddClientLog();

    // this.isThumbnailAdded = true;
    if (this.storageservice.getEditedStyleId()) {
      this.isCreateMode = false;
      if (this.storageservice.getEditedStyleDetailsId()) {
        this.storageservice.removeStyleDetailsId();
        //Edit mode
        this.isEditMode = false;
        this.isEditingMode = true;
        this.isViewingMode = false;
        this.editStyle();
        this.getImageDetails();
        this.getBomDetails();
        //this.getStyleDocuments();
      } else {
        //view mode
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.showLoader = true;
        this.getStyleDetails();
        this.getImageDetails();
        this.getBomDetails();
        //this.getStyleDocuments();
      }
    } else {
      //Create mode
      this.isCreateMode = true;
      this.isEditMode = false;
      this.isEditingMode = false;
      this.isViewingMode = false;
    }

    // this.showLoader = true;

    this.sampleSize;

    this.getPaletteDetails();
    //this.refreshSelect();
    this.initForm();
    this.sharedService.sendClickEvent();
    this.loadSelectPicker();
    this.getNotifications();
  }
  userList = [
    {
      name: 'alexrobert@pluraltechnolgy.com',
      img: '../assets/images/icon/jimmy-jo.jpg',
    },
    {
      name: 'jimmyjo@pluraltechnolgy.com',
      img: '../assets/images/icon/amay.jpg',
    },
    {
      name: 'jonedoe@pluraltechnolgy.com',
      img: '../assets/images/icon/jimmy-jo.jpg',
    },
  ];
  onItemSelect(item: any) {
    // //console.log(this.fieldArray);
    // this.fieldArray.filter(x=>{
    //   if(x.userID == item.id){
    //     let access;
    //     if(x.edit){
    //       access = 'Edit';
    //     }else{
    //       access = 'View';
    //     }
    //     this.error('This style had already shared with '+x.user[0].fullName+' and has '+access+' access');
    //     this.selectedItems.splice(this.selectedItems.indexOf(item),1);
    //     this.selectedItemsnew.splice(this.selectedItemsnew.indexOf(item),1);
    //     return;
    //   }
    // })
    let a = {};
    a['id'] = item.id;
    a['view'] = true;
    if (this.userPermission == 'edit') {
      a['edit'] = true;
    } else {
      a['edit'] = false;
    }

    this.selectedItems.push(a);
    //console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    //console.log(item);
    this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
  }
  onSelectAll(items: any) {
    ////console.log(items);
    items.forEach((x) => {
      let a = {};
      a['id'] = x.id;
      a['view'] = true;
      if (this.userPermission == 'edit') {
        a['edit'] = true;
      } else {
        a['edit'] = false;
      }
      this.selectedItems.push(a);
    });
    ////console.log(this.selectedItems);
  }
  onDeSelectAll(items: any) {
    //console.log(items);
    this.selectedItems = [];
  }

  permChange(event) {
    //console.log(event.target.value);
    if (this.selectedItems.length > 0) {
      if (event.target.value == 'edit') {
        this.selectedItems.forEach((x) => {
          x['edit'] = true;
        });
      } else {
        this.selectedItems.forEach((x) => {
          x['edit'] = false;
        });
      }
    }
    //console.log(this.selectedItems);
  }

  changePermission(event, user) {
    //console.log(event.target.value,user)
    let a = {};
    if (event.target.value == 'edit') {
      user['edit'] = true;
    } else {
      user['edit'] = false;
    }
    this.modifiedData.push(user);
    //console.log(this.modifiedData);
  }

  CopyStyle() {
    Swal.fire({
      title: this.appStaticMsgs.styledetails.copyofpleneridressstylecreated,
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'View Style!',
      confirmButtonText: 'Okay',
    });
  }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker(
        'setStyle',
        'dropdown-toggle',
        'remove',
        'selectAll'
      );
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker(
        { noneSelectedText: 'Select Image Type' },
        'deselectAll'
      );
    });
  }

  /* To set page properties
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */
  initForm() {
    // this.showLoader = true;
    this.styleForm = this.fb.group({
      styleName: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')],
      ],
      newStyleNum: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z0-9- ]{1,50}$')],
      ],
      season: [''],
      sizeChart: ['', [Validators.required]],
      sizeRange: ['', [Validators.required]],
      sampleSize: ['', Validators.required],
      ageGroup: ['', Validators.required],
      subCategory: ['', Validators.required],
      targetGender: ['', Validators.required],
      category: ['', Validators.required],
      styleNumber: [
        { value: '', disabled: true },
        Validators.pattern('^[a-zA-Z0-9- ]{1,50}$'),
      ],
      description: [''],
      status: [''],
      styleid: [{ value: '', disabled: true }],
      neckType: ['', Validators.required],
      sleeveType: ['', Validators.required],
      sleevesLength: ['', Validators.required],
      silhouette: ['', Validators.required],
      garmentType: ['', Validators.required],
      fitting: [''],
      length: ['', Validators.required],
      color: [''],
    });
    this.createForm = this.fb.group({
      styleName: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z0-9- ]{3,50}$')],
      ],
      description: [''],
      Colourways: [''],
      Images: [''],
      BOM: [''],
      Construction: [''],
      Pattern: [''],
      Specification: [''],
      Processing: [''],
    });
    // this.showLoader = false;

    //this.loadSelectPicker();
  }

  charactersOnly(e: any) {
    var regex = new RegExp('^[a-zA-Z0-9]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characterOnly(e: any) {
    var regex = new RegExp('^[a-zA-Z0-9-_ ]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  copyStyle(style) {
    this.showModal = true;
    $('#myModal').modal('show');
    this.item = style;
    //console.log(this.item);
    //this.popupStyleName = 'Copy of '+style.styleName;
    //this.popupDescription = 'Copy of '+style.description;
    this.f2.styleName.setValue('Copy of ' + style.styleName);
    if (style.description != null) {
      this.f2.description.setValue('Copy of ' + style.description);
    }
    // $('#myModal').on('hidden.bs.modal', function (e) {
    //   //console.log('modal closed');
    //   this.createForm.reset(this.createForm.value);
    //   this.createForm.setErrors(null);
    // })
  }

  onChange(name: string, event: any) {
    //console.log(name,event.target.checked)
  }

  creating() {
    this.submitted = true;
    this.showLoader1 = true;
    var Data = {
      StyleID: this.item.id,
      StyleName: this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        this.createForm.value.styleName
      ),
      Description: this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        this.createForm.value.description
      ),
      Colourways: this.createForm.value.Colourways == 1 ? true : false,
      Images: this.createForm.value.Images == 1 ? true : false,
      BOM: this.createForm.value.BOM == 1 ? true : false,
      Construction: this.createForm.value.Construction == 1 ? true : false,
      Pattern: this.createForm.value.Pattern == 1 ? true : false,
      Specification: this.createForm.value.Specification == 1 ? true : false,
      Processing: this.createForm.value.Processing == 1 ? true : false,
    };

    console.log('Data', Data);

    this.dataservice.cloneStyle(Data).subscribe(
      (response) => {
        if (response.status == 200) {
          //console.log('response',response['body']['item2']);
          this.showLoader1 = false;
          if (response['body']['item2'] == 'Success') {
            $('#myModal').modal('hide');
            console.log('*******', response);
            this.logger.info(
              this.appStaticMsgs.styledetails.styleclonedsuccessfully,
              'Style Details'
            );
            // this.success(
            //   this.appStaticMsgs.styledetails.styleclonedsuccessfully
            // );
            this.toastService.showSuccess(
              this.appStaticMsgs.styledetails.styleclonedsuccessfully
            );
            setTimeout(() => {
              this.router.navigate(['/styles']);
            }, 1000);
          }
        } else {
          // this.getError(response);
          // this.toastService.showError(response);
          this.toastService.showError(response.body['item1']);
          this.logger.error(response, 'Style');
          this.showLoader = false;
        }
      },
      (err) => {
        this.getError(err);
        this.logger.error(err.error.message, 'Style');
        this.showLoader = false;
      }
    );
  }

  submit(e: PageChangeEvent): void {
    //this.showLoader = true;
    this.data = this.sharedGridData.filter((val) =>
      val.styleName.toLowerCase().includes(this.searchedKeyword)
    );
    //this.showLoader = false;
  }

  getError(err: any) {
    if (err.error.message === 'Network error communicating with endpoint') {
      // this.error(err.error.message);
      this.toastService.showError(
        'Oops!! Something went wrong. Please try again.'
      );
      this.showLoader = false;
    } else if (err.error.message === 'The incoming token has expired') {
      // this.sessionTimedOut();
      // this.showLoader = false;
      this.toastService.showError(
        'The token being used has expired. Please login again.'
      );
      this.sessionTimedOut();
      this.userservice.logout();
    } else if (err.error.message === 'Unauthorized') {
      // this.userservice.error();
      this.showLoader = false;
      this.toastService.showError(
        "You don't have permission to access this resource. Please login again."
      );
      this.sessionTimedOut();
      this.userservice.logout();
    } else {
      // this.error(err.message);
      this.toastService.showError(this.appStaticMsgs.unResponsive.error);
    }
    this.showLoader = false;
  }

  decryptData(x) {
    x.styleNumber = this.aesdecryptservice.decryptData(
      this.dataservice.Key,
      x.styleNumber
    );
    x.styleName = this.aesdecryptservice.decryptData(
      this.dataservice.Key,
      x.styleName
    );
    x.newStyleNum = this.aesdecryptservice.decryptData(
      this.dataservice.Key,
      x.newStyleNum
    );
    x.category = this.aesdecryptservice.decryptData(
      this.dataservice.Key,
      x.category
    );
    x.status = this.aesdecryptservice.decryptData(
      this.dataservice.Key,
      x.status
    );
    x.subCategory = this.aesdecryptservice.decryptData(
      this.dataservice.Key,
      x.subCategory
    );
    x.targetGender = this.aesdecryptservice.decryptData(
      this.dataservice.Key,
      x.targetGender
    );

    if (x.createdBy != null) {
      x.createdBy.firstName = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        x.createdBy.firstName
      );
      x.createdBy.lastName = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        x.createdBy.lastName
      );
      x.CreatedbyName = x.createdBy.firstName;
      x.createdBylastName = x.createdBy.lastName;
      x.createdBy.imageUrl = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        x.createdBy.imageUrl
      );
    }
    if (x.styleImage) {
      x.styleImage.thumbnail = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        x.styleImage.thumbnail
      );
    }
  }

  private updateFormGroup(styles: any = {}) {
    // create a new form group containing controls and validators for a product
    return this.fb.group({
      styleName: [styles.styleName],
      newStyleNum: [styles.newStyleNum],
      //styleNumber: [styles.styleNumber],
      category: [styles.category],
      targetGender: [styles.targetGender],
      ageGroup: [styles.ageGroup],
      sampleSize: [styles.sampleSize],
      sizeChart: [styles.sizeChart],
      sizeRange: [styles.sizeRange],
      createdBy: [styles.createdBy],
      id: [styles.id],
      fabric: [styles.fabric],
      technicalPackage: [styles.technicalPackage],
      garmentType: [styles.garmentType],
      neckType: [styles.neckType],
      sleeveType: [styles.sleeveType],
      sleevesLength: [styles.sleeveLength],
      silhouette: [styles.silhouette],
      fitting: [styles.fitting],
      length: [styles.length],
      color: [styles.colour],
    });
  }

  loadSharedListData() {
    this.sharedStartEndListCount =
      this.sharedStartListCount + '-' + this.sharedEndListCount;
    this.dataservice
      .getAllStyles(this.sharedStartEndListCount, this.styleViewMode)
      .subscribe(
        (response) => {
          //console.log(response,'res')
          if (
            response['body']['item2'] === 'Success' &&
            response['body']['item1'].length > 0
          ) {
            this.isDataLoading = false;
            this.logger.info(
              this.appStaticMsgs.styledetails.allstylelistloadedsuccessfully,
              'Style'
            );
            this.showLoader = false;
            this.sharedGridData = [];
            response['body']['item1'].forEach((x) => {
              this.decryptData(x);
              this.sharedGridData.push(x);
            });
            this.data = this.sharedGridData;
            //console.log(this.data,'data');

            this.totalRecordCount = response['body']['item3'];
            if (this.sharedGridData.length > 0) {
              this.sharedNotFound = false;
            } else {
              this.sharedNotFound = true;
            }
            this.sharedGridData.forEach((x, i) => {
              this.fa?.push(this.updateFormGroup(x));
            });
            var gridView = {
              data: this.sharedGridData,
              total: this.totalRecordCount,
            };
            this.gridData = gridView;
            //console.log(this.gridData,'grid');
            this.loading = false;
          } else {
            this.isSharedScrollDown = true;
            if (response['body']['item2'] !== 'Success') {
              // this.error('Failed:' + response['body']['item1']);
              this.toastService.showError(response.body['item1']);
              this.logger.error(response['body']['item1'], 'Style');
            }
            this.showLoader = false;
            this.sharedNotFound = true;
          }
          this.sharedGridData = response.body.item1;
        },
        (err) => {
          this.getError(err);
          this.logger.error(err.error.message, 'Style');
          this.showLoader = false;
        }
      );
  }

  closePopup() {
    this.createTitle = '';
    this.isTitleError = false;
  }

  dataSource: any = [];
  hexColours: any = [];
  selectedItems1: any = [{ data: [] }];
  getPaletteDetails() {
    //console.log('palette');
    this.sharedStartEndListCount =
      this.sharedStartListCount + '-' + this.sharedEndListCount;
    this.dataservice
      .getDetailsByModuleName('Palettes', this.sharedStartEndListCount)
      .subscribe((response1) => {
        if (response1.body['item2'] === 'Success') {
          this.endCountList = response1.body['item3'];
          this.sharedStartEndListCount =
            this.sharedStartListCount + '-' + this.endCountList;
          this.dataservice
            .getDetailsByModuleName('Palettes', this.sharedStartEndListCount)
            .subscribe(
              (response) => {
                if (response.body['item2'] === 'Success') {
                  response.body['item1'].forEach((x) => {
                    x['name'] = this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      x['name']
                    );
                    x['classification'] = this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      x['classification']
                    );
                    if (x['colourHexCodes']) {
                      x['colourHexCodes'] = this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        x['colourHexCodes']
                      );
                      //console.log(x['hexCode'],'hexCode');
                    } else if (x['thumbnailFiles']) {
                      x['thumbnailFiles']['thumbnail'] =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          x['thumbnailFiles']['thumbnail']
                        );
                    }
                    this.colordata.push(x);

                    // for(var i=0;i<this.colordata.length;i++){
                    //   this.selectedItems1[i].push({id: this.colordata[i].id,'itemName': this.colordata[i].name,value: this.colordata[i].colourHexCodes});
                    // }
                  });

                  //console.log('this.selectedItems1[i].data',this.selectedItems1[i].data);

                  // this.dataSource = this.colordata.map(x => ({
                  //     id: x.id,
                  //     itemName: x.name,
                  //     value: x.colourHexCodes
                  // }))

                  this.totalColorCount = response.body['item3'];
                  setTimeout(() => {
                    $('.selectpicker').selectpicker();
                    this.refreshSelect();
                  }, 10);
                  this.logger.info(
                    this.appStaticMsgs.styledetails
                      .allpalletteslistloadedsuccessfully,
                    'Style Palette Details'
                  );
                } else {
                  this.showLoader = false;
                  // this.error(response.body['item1']);
                  this.toastService.showError(response.body['item1']);
                  this.logger.error(response.body['item1'], 'Style Details');
                }
              },
              (err) => {
                this.logger.error(err.error.message, 'Style Details');
                if (
                  err.error.message ===
                  'Network error communicating with endpoint'
                ) {
                  // this.error(err.error.message);
                  this.toastService.showError(
                    'Oops!! Something went wrong. Please try again.'
                  );
                  this.showLoader = false;
                } else if (
                  err.error.message === 'The incoming token has expired'
                ) {
                  // this.sessionTimedOut();
                  // this.showLoader = false;
                  this.toastService.showError(
                    'The token being used has expired. Please login again.'
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else if (err.error.message === 'Unauthorized') {
                  // this.userservice.error();
                  this.showLoader = false;
                  this.toastService.showError(
                    "You don't have permission to access this resource. Please login again."
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else {
                  this.logger.error(err.message, 'Style Details');
                  // this.error(err.message);
                  this.toastService.showError(
                    this.appStaticMsgs.unResponsive.error
                  );
                }
                this.showLoader = false;
              }
            );
        }
      });
  }

  myDropdownArray: any = [];
  colorwayDropdown() {
    let colorways = (this.stylecolorForm.get('colorways') as FormArray)
      .controls;
    for (var k = 0; k < colorways.length; k++) {
      ////console.log('colordata',this.colordata);
      var option = '';
      for (var i = 0; i < this.colordata.length; i++) {
        let hexCode = this.colordata[i].colourHexCodes;
        let name = this.colordata[i].name;
        let id = this.colordata[i].id;
        ////console.log('colorways[i].value.hexcode',controls1['hexcode'].value,controls1['colourid'].value,controls1['name'].value);
        var hexcode = hexCode.split(',');
        var dataContent = '';
        if (hexcode) {
          for (var j = 0; j < hexcode.length; j++) {
            dataContent +=
              '<span style="width: 20px;height: 20px;float: left;background-color: ' +
              hexcode[j] +
              '"></span>';
          }
        } else {
          dataContent += '';
        }

        let data = {
          value: id,
          title: name,
          content: dataContent + ' ' + name,
        };

        this.myDropdownArray.push(data);

        //option+="<option value='"+id+"' title='"+name+"' data-content='"+dataContent+" "+name+"'></option>";
        ////console.log('option',option);
      }
      //$("#selectpicker"+k).html(option);
      //$("#selectpicker"+k).selectpicker();
    }
  }

  onItemSelect1(item: any, i) {
    //console.log('onItemSelect1',item);
    this.dataSource.forEach((y) => {
      if (y.id == item.id) {
        y['position'] = i;
      }
    });
    if (this.hexColours.find((x) => x.position == i)) {
      this.hexColours.splice(this.hexColours.indexOf(item.position), 1);
      //this.hexColours.push({'hexColor': item.value,'position' : i});
    }
    this.hexColours.push({ hexColor: item.value, position: i });
    //console.log('this.hexColours',this.hexColours)
  }

  OnItemDeSelect1(item: any) {
    //console.log('OnItemDeSelect1',item);
  }

  getColorDetails() {
    this.colorstartEndCount =
      this.colorstartCountList + '-' + this.colorendCountList;
    // this.dataservice.getDetailsByModuleName('Palettes',this.colorstartEndCount).subscribe(response1 => {
    //     //console.log('response1',response1.body);
    // });

    this.dataservice
      .getDetailsByModuleName('Colours', this.colorstartEndCount)
      .subscribe((response1) => {
        if (response1.body['item2'] === 'Success') {
          this.colorendCountList = response1.body['item3'];
          this.colorstartEndCount =
            this.colorstartCountList + '-' + this.colorendCountList;
          this.dataservice
            .getDetailsByModuleName('Colours', this.colorstartEndCount)
            .subscribe(
              (response) => {
                if (response.body['item2'] === 'Success') {
                  response.body['item1'].forEach((x) => {
                    x['name'] = this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      x['name']
                    );
                    x['classification'] = this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      x['classification']
                    );
                    if (x['hexcode']) {
                      x['hexcode'] = this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        x['hexcode']
                      );
                    } else if (x['thumbnailFiles']) {
                      x['thumbnailFiles']['thumbnail'] =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          x['thumbnailFiles']['thumbnail']
                        );
                    }
                    this.colorData.push(x);
                    //this.colorDataArr = this.colorData.slice();
                  });

                  console.log('this.colordata', response.body['item1']);
                  this.totalColorCount = response.body['item3'];
                  setTimeout(() => {
                    $('.selectpicker').selectpicker();
                    this.refreshSelect();
                  }, 10);
                  this.logger.info(
                    this.appStaticMsgs.styledetails
                      .allcolorlistloadedsuccessfully,
                    'Style Details'
                  );
                } else {
                  this.showLoader = false;
                  // this.error(response.body['item1']);
                  this.toastService.showError(response.body['item1']);
                  this.logger.error(response.body['item1'], 'Style Details');
                }
              },
              (err) => {
                this.logger.error(err.error.message, 'Style Details');
                if (
                  err.error.message ===
                  'Network error communicating with endpoint'
                ) {
                  // this.error(err.error.message);
                  this.toastService.showError(
                    'Oops!! Something went wrong. Please try again.'
                  );
                  this.showLoader = false;
                } else if (
                  err.error.message === 'The incoming token has expired'
                ) {
                  // this.sessionTimedOut();
                  // this.showLoader = false;
                  this.toastService.showError(
                    'The token being used has expired. Please login again.'
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else if (err.error.message === 'Unauthorized') {
                  // this.userservice.error();
                  this.showLoader = false;
                  this.toastService.showError(
                    "You don't have permission to access this resource. Please login again."
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else {
                  this.logger.error(err.message, 'Style Details');
                  // this.error(err.message);
                  this.toastService.showError(
                    this.appStaticMsgs.unResponsive.error
                  );
                }
                this.showLoader = false;
              }
            );
        }
      });
  }

  /*To get get Notifications count for display count in chat window
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getNotifications() {
    this.showLoader = true;
    this.unReadCnt = 0;
    this.dataservice.getNotifications().subscribe(
      (response) => {
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.showLoader = false;
            var notificationData = [];
            notificationData = response.body['item1'];
            if (notificationData.length > 0) {
              var unReadData = notificationData.filter(
                (x, i) =>
                  x.recordId === this.storageservice.getEditedStyleId() &&
                  x.readMessage === false &&
                  x.notificationType === 'Chat'
              );
              if (unReadData.length > 0) {
                this.unReadCnt = unReadData.length;
              }
            }
          } else {
            this.showLoader = false;
          }
        } else {
          this.showLoader = false;
        }
      },
      (err) => {
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
          this.showLoader = false;
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.showLoader = false;
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.showLoader = false;
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
  }

  /* To get Online Users for display online or offline status.
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021
 */
  getOnlineUsers() {
    this.onlineUsers = [];
    if (this.storageservice.getOnlineUsers()) {
      let result = this.storageservice.getOnlineUsers();
      this.onlineUsers = result.split(',');
    }
  }

  /*To get style details data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async getStyleDetails() {
    this.showLoader1 = true;
    ////console.log(this.storageservice.getEditedStyleId())/
    this.dataservice
      .GetStyleDataByID(this.storageservice.getEditedStyleId())
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.logger.info(
              response.body['item1'][0]['styleName'] + ' loaded successfully',
              'Style Details'
            );
            this.getSharedData();
            ////console.log(JSON.stringify(response.body['item1']));
            this.editedStyleData = response.body['item1'];
            this.UserDetails = response.body['item1'];
            this.isshared = response.body['item1'][0]['isShared'];
            this.editedStyleData[0].createdBy.firstName =
              this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                this.editedStyleData[0].createdBy.firstName
              );
            this.editedStyleData[0].createdBy.lastName =
              this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                this.editedStyleData[0].createdBy.lastName
              );
            this.UserDetails[0].createdBy.firstName =
              this.editedStyleData[0].createdBy.firstName;
            this.UserDetails[0].createdBy.lastName =
              this.editedStyleData[0].createdBy.lastName;
            this.initForm();
            this.stylesNumber = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              response.body['item1'][0]['styleNumber']
            );
            this.styleForm
              .get('styleid')
              .setValue(response.body['item1'][0]['id']);
            this.styleForm
              .get('styleName')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['styleName']
                )
              );
            this.styleForm
              .get('newStyleNum')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['newStyleNum']
                )
              );
            this.styleForm
              .get('category')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['category']
                )
              );
            this.styleForm
              .get('styleNumber')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['styleNumber']
                )
              );
            this.styleForm
              .get('subCategory')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['subCategory']
                )
              );
            this.styleForm
              .get('description')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['description']
                )
              );
            this.styleForm
              .get('season')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['season']
                )
              );
            //this.styleForm.get('fabric').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["fabric"]));
            this.styleForm
              .get('ageGroup')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['ageGroup']
                )
              );
            this.styleForm
              .get('targetGender')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['targetGender']
                )
              );

            this.styleForm
              .get('garmentType')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['garmentType']
                )
              );
            if (response.body['item1'][0]['garmentType'] !== null) {
              this.selectedGarmentType(this.f.garmentType.value, false);
              this.styleForm
                .get('silhouette')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][0]['silhouette']
                  )
                );

              this.styleForm
                .get('neckType')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][0]['neckType']
                  )
                );

              this.styleForm
                .get('sleeveType')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][0]['sleeveType']
                  )
                );

              this.styleForm
                .get('sleevesLength')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][0]['sleeveLength']
                  )
                );

              this.styleForm
                .get('length')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][0]['length']
                  )
                );

              this.styleForm
                .get('fitting')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][0]['fitting']
                  )
                );

              this.styleForm
                .get('color')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][0]['colour']
                  )
                );
            }

            this.styleForm
              .get('sizeChart')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['sizeChart']
                )
              );
            //this.changeSizeRange('',this.styleForm.get('sizeRange').value);
            this.changeSizeChart('', this.styleForm.get('sizeChart').value);

            //this.styleForm.get('technicalPackage').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["technicalPackage"]));
            this.styleForm
              .get('status')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['status']
                )
              );
            this.styleForm
              .get('sampleSize')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['sampleSize']
                )
              );
            //console.log('this.styleForm',this.styleForm);
            this.styleForm
              .get('sizeRange')
              .setValue(
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][0]['sizeRange']
                )
              );
            console.log(this.styleForm);
            this.refreshSelect();
            if (this.activeTabValue === 'Specification') {
              this.getSpecificationbyId();
            }
            this.recordId = response.body['item1'][0]['id'];
            //this.showLoader1 = false;
            var styleId = this.storageservice.getEditedStyleId();
            var styleIdNotification =
              this.storageservice.getstyleIdNotification();
            if (styleId == styleIdNotification) {
              this.storageservice.removeStyleIdNotification();
              this.stylesdiscussion();
            }
          } else {
            this.showLoader1 = false;
            this.isEditMode = false;
            this.logger.error(response.body['item1'], 'Style Details');
          }
        },
        (err) => {
          this.showLoader1 = false;
          this.logger.error(err.error.message, 'Style Details');
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.showLoader1 = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.showLoader1 = false;
            // this.sessionTimedOut();
            // this.userservice.logout();
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            this.showLoader1 = false;
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else {
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
        }
      );
  }

  /*To get images details data to bind
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public getImageDetails() {
    this.showLoader = true;
    this.dataservice
      .GetStyleImages(this.storageservice.getEditedStyleId())
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.logger.info(
              this.appStaticMsgs.styledetails.imagedetailsloadedsuccsessfully,
              'Style Details'
            );
            this.showLoader = false;
            this.styleImagesCnt = response.body['item3'];
            console.log(this.styleImagesCnt, 'data2');
            if (response.body['item1'].styleImages) {
              if (response.body['item1'].styleImages.length > 0) {
                this.editedFileData = [];
                this.removedStyImgIds = null;
                this.showLoader = false;
                this.isThumbnailAdded = true;
                //this.removedFileData = [];
                let control = <FormArray>this.styImgForm.controls.styImg;
                if (control.length > 0) {
                  this.styImgForm = this.fb.group({
                    styImg: this.fb.array([]),
                  });
                }
                let editeddata = JSON.stringify(
                  response.body['item1'].styleImages
                );
                let data = JSON.parse(editeddata);
                this.reloadImageFormData(data);
                console.log('data', data);
                this.fileName = data[0].imageID + '/' + data[0]['image'].name;
                if (data.length !== 0) {
                  data.map((x: any) => {
                    if (x.imageType == 'PatternImages') {
                      this.uploadedFileName = x['image'].name;
                    }
                  });
                } else {
                  this.uploadedFileName = '';
                }

                console.log(this.uploadedFileName);
              }
            } else {
              if (this.imageObject.length == 0) {
                const temp = {
                  image: this.thumbnail,
                  thumbImage: this.thumbnail,
                };
                this.imageObject.push(temp);
                console.log(this.imageObject, '0000');
              }
            }
          } else {
            this.showLoader = false;
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], 'Style Details');
          }
        },
        (err) => {
          this.logger.error(err.error.message, 'Style Details');

          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.showLoader = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            // this.showLoader = false;
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.showLoader = false;
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else {
            // this.error(err.message);
            this.logger.error(err.message, 'Style Details');
            // this.toastService.showError('Un Responsive');
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
          this.showLoader = false;
        }
      );
  }

  getStyleDocuments() {
    this.dataservice
      .GetStyleDocuments(this.storageservice.getEditedStyleId())
      .subscribe(
        (response) => {
          if (response.body['item2'] == 'Success') {
            let documents: any = response.body['item1'].styleDocuments;
            console.log('GetStyleDocuments res', documents);
          }
        },
        (err) => {
          console.log('GetStyleDocuments error', err);
        }
      );
  }

  /*To get style BOM details data to bind
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getSupplierList() {
    this.startEndCount = this.startCount + '-' + this.endCount;
    this.dataservice
      .getDetailsByModuleName('Suppliers', this.startEndCount)
      .subscribe((res: any) => {
        console.log(res, 'supplierList');
        if (res.body['item2'] == 'Success') {
          this.supplierDropdownList = res.body['item1'];
          for (let i = 0; i < this.supplierDropdownList.length; i++) {
            res.body['item1'][i].name = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              res.body['item1'][i].name
            );
          }
        }
      });
  }
  getColoursList() {
    this.startEndCount = this.startCount + '-' + this.endCount;
    this.dataservice
      .getDetailsByModuleName('Colours', this.startEndCount)
      .subscribe((res: any) => {
        if (res.body['item2'] == 'Success') {
          this.colorDropdownList = res.body['item1'];
          for (let i = 0; i < this.colorDropdownList.length; i++) {
            res.body['item1'][i].name = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              res.body['item1'][i].name
            );
          }
          console.log(this.colorDropdownList, 'colocrs');
        }
      });
  }

  public getBomDetails() {
    this.addedColourIds = [];
    this.addedSupplierIDs = [];
    this.bomloading = true;
    this.bomstartEndCount = this.bomstartCountList + '-' + this.bomendCountList;
    this.dataservice
      .getdetailsForBOM(
        this.storageservice.getEditedStyleId(),
        this.bomstartEndCount
      )
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.logger.info(
              this.appStaticMsgs.styledetails.bomdetailsloadedsuccsessfully,
              'Style Details'
            );
            this.filteredData = response.body['item1']['boMs'];
            console.log('this.filteredData', this.filteredData);
            this.bomRecordCount = response.body['item3'];
            if (this.filteredData) {
              for (
                let i = 0;
                this.filteredData != null && i < this.filteredData.length;
                i++
              ) {
                this.filteredData[i].component =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i].component
                  );
                this.filteredData[i].materialName =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i]?.material?.name
                  );
                this.filteredData[i].materialType =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i]?.materialType
                  );
                this.filteredData[i].itemNo =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i].itemNo
                  );
                // if (this.filteredData[i].supplier) {
                //   this.filteredData[i].supplier.name =
                //     this.aesdecryptservice.decryptData(
                //       this.dataservice.Key,
                //       this.filteredData[i].supplier?.name
                //     );
                // }
                if (
                  this.filteredData[i]?.supplierId &&
                  this.supplierDropdownList.length !== 0
                ) {
                  this.addedSupplierIDs.push(this.filteredData[i]?.supplierId);
                  localStorage.setItem('addedSuppliers', this.addedSupplierIDs);
                  const supplierName = this.supplierDropdownList?.filter(
                    (x: any) => x.id == this.filteredData[i]?.supplierId
                  )[0]?.name;
                  console.log(supplierName, 'supplierName');

                  this.filteredData[i]['supplierName'] = supplierName;
                }

                // if (this.filteredData[i].colour) {
                //   this.filteredData[i].colour.name =
                //     this.aesdecryptservice.decryptData(
                //       this.dataservice.Key,
                //       this.filteredData[i].colour?.name
                //     );
                // }
                if (
                  this.filteredData[i]?.colourID &&
                  this.colorDropdownList.length !== 0
                ) {
                  this.addedColourIds.push(this.filteredData[i]?.colourID);
                  localStorage.setItem('addedColors', this.addedColourIds);
                  const colorName = this.colorDropdownList?.filter(
                    (x: any) => x.id == this.filteredData[i]?.colourID
                  )[0]?.name;
                  this.filteredData[i].colourName = colorName;
                  // const hexCode = this.colorDropdownList?.filter(
                  //   (x: any) => x.id == this.filteredData[i]?.colourID
                  // )[0]?.hexCode;
                  // this.filteredData[i].colourChip =
                  // this.aesdecryptservice.decryptData(
                  //   this.dataservice.Key,
                  //   this.filteredData[i].hexCode
                  // );
                }

                this.filteredData[i].sizeOrWidth =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i].sizeOrWidth
                  );
                this.filteredData[i].uom = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  this.filteredData[i].uom
                );
                this.filteredData[i].notes = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  this.filteredData[i].notes
                );
                this.filteredData[i].price = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  this.filteredData[i].price
                );
                let subtotal =
                  this.filteredData[i].price * this.filteredData[i].quantity;
                this.filteredData[i].subTotal = subtotal;
                console.log(',', subtotal);

                // this.filteredData[i].colourChip=this.aesdecryptservice.decryptData(this.dataservice.Key,this.filteredData[i].colourChip);
                if (this.filteredData[i].colour) {
                  this.filteredData[i].colour.hexcode =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      this.filteredData[i].colour?.hexcode
                    );
                }
                if (this.filteredData[i].colourChip) {
                  this.filteredData[i].colourChip =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      this.filteredData[i].colourChip
                    );
                }
              }

              var gridView = {
                data: this.filteredData,
                total: this.bomRecordCount,
              };
              this.bomViewData = gridView;
              console.log(this.bomViewData);
            } else {
              this.bomViewData = null;
            }

            this.bomloading = false;
          } else {
            this.bomloading = false;
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], 'Style Details');
          }
        },
        (err) => {
          this.logger.error(err.error.message, 'Style Details');

          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.bomloading = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            // this.bomloading = false;
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.bomloading = false;
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else {
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
            this.logger.error(err.message, 'Style Details');
          }
          this.bomloading = false;
        }
      );
  }

  /* To open style page in edit mode
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021*/
  editStyle() {
    this.garmentTypeBasedList = [];
    this.pomtab = true;
    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;
    if (this.activeTab === 'styleDetailsTab') {
      this.loadSelectPicker();
      this.isThumbnailAdded = true;
      if (this.storageservice.getEditedStyleId()) {
        this.getStyleDetails();
      }
      this.initForm();
    } else if (this.activeTab === 'imgDetailsTab') {
      this.croppedImage = [];
      this.isThumbnailAdded = true;
      this.loadSelectPicker();
      this.getImageDetails();
    } else if (this.activeTab === 'colorwaysTab') {
      this.loadSelectPicker();
      this.getstylecolourways();
    }
  }

  /*To save style details data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async onStyleSubmit() {
    console.log(this.styleForm, 'styleform');
    this.submitted = true;
    console.log(this.styleForm.value);
    if (this.styleForm.invalid) {
      console.log('invalid');
      // this.error('Invalid Style Form');
      this.toastService.showError('Please enter all mandatory fields');
      return false;
    } else if (
      (this.styleForm.value.garmentType == 'Topwear' ||
        this.styleForm.value.garmentType == 'Dress') &&
      this.styleForm.value.subCategory == ''
    ) {
      this.addRemoveValidation(false);
      this.toastService.showError('Please enter all mandatory fields');
      console.log('else iffff');
    } else {
      this.showLoader = true;
      let styleData = {
        styleName: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.styleName.value
        ),
        styleNumber: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.styleNumber.value
        ),
        newStyleNum: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.newStyleNum.value
        ),
        category: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.category.value
        ),
        subCategory: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.subCategory.value
        ),
        targetGender: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.targetGender.value
        ),

        garmentType: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.garmentType.value
        ),

        neckType: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.neckType.value
        ),

        sleeveType: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.sleeveType.value
        ),

        sleeveLength: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.sleevesLength.value
        ),

        silhouette: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.silhouette.value
        ),

        length: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.length.value
        ),

        fitting: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.fitting.value
        ),

        colour: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.color.value
        ),

        ageGroup: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.ageGroup.value
        ),
        sampleSize: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.sampleSize.value
        ),
        sizeRange: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.sizeRange.value.join()
        ),
        sizeChart: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.sizeChart.value
        ),

        //fabric: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.fabric.value),
        //technicalPackage: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.technicalPackage.value),
        description: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.description.value
        ),
        // classification: this.f.classification.value,
        // org: '',
        season: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.f.season.value
        ),
        status: this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          'Active'
        ),
      };
      if (!this.f.styleid.value) {
        this.dataservice.AddStyles(styleData).subscribe(
          (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                ////console.log(JSON.stringify(response.body['item1']));
                this.stylesNumber = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1']['styleNumber']
                );
                this.styleForm
                  .get('styleid')
                  .setValue(response.body['item1']['id']);
                this.styleForm
                  .get('styleName')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['styleName']
                    )
                  );
                this.styleForm
                  .get('newStyleNum')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['newStyleNum']
                    )
                  );
                this.styleForm
                  .get('category')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['category']
                    )
                  );
                this.styleForm.get('styleNumber').setValue(this.stylesNumber);
                // this.styleSubCategory = this.aesdecryptservice.decryptData(
                //   this.dataservice.Key,
                //   response.body['item1']['subCategory']
                // );
                this.styleForm
                  .get('subCategory')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['subCategory']
                    )
                  );
                this.styleForm
                  .get('description')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['description']
                    )
                  );
                this.styleForm
                  .get('season')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['season']
                    )
                  );
                //this.styleForm.get('fabric').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["fabric"]));
                this.styleForm
                  .get('ageGroup')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['ageGroup']
                    )
                  );
                this.styleForm
                  .get('targetGender')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['targetGender']
                    )
                  );

                this.styleForm
                  .get('garmentType')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['garmentType']
                    )
                  );

                this.styleForm
                  .get('silhouette')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['silhouette']
                    )
                  );

                this.styleForm
                  .get('neckType')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['neckType']
                    )
                  );

                this.styleForm
                  .get('sleeveType')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sleeveType']
                    )
                  );

                this.styleForm
                  .get('sleevesLength')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sleeveLength']
                    )
                  );

                this.styleForm
                  .get('length')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['length']
                    )
                  );

                this.styleForm
                  .get('fitting')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['fitting']
                    )
                  );

                this.styleForm
                  .get('color')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['colour']
                    )
                  );
                this.styleForm
                  .get('sizeChart')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sizeChart']
                    )
                  );
                this.styleForm
                  .get('sampleSize')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sampleSize']
                    )
                  );
                this.styleForm
                  .get('sizeRange')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sizeRange']
                    )
                  );
                this.styleForm
                  .get('status')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['status']
                    )
                  );
                this.changeSizeChart('', this.styleForm.get('sizeChart').value);
                this.refreshSelect();
                //this.sampleSize=[this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeRange"])];
                //this.success("Style Added Successfully!");
                // this.success(
                //   this.appStaticMsgs.styledetails.styleaddedsuccessfully
                // );

                this.logger.info(
                  this.appStaticMsgs.styledetails.styleaddedsuccessfully,
                  'Style Details'
                );
                this.toastService.showSuccess('Added Successfully');
                this.storageservice.storeEditedStyleId(
                  response.body['item1'].id
                );
                this.submitted = false;
                this.isCreateMode = false;
                this.isEditMode = true;
                this.isEditingMode = true;
                this.isViewingMode = false;
                //this.getStyleDetails();

                this.getImageDetails();
                this.getBomDetails();
                this.showLoader = false;
              } else {
                this.submitted = false;
                // this.error(response.body['item1']);
                this.logger.error(response.body['item1'], 'Style Details');
                this.toastService.showError(response.body['item1']);
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.submitted = false;
            this.showLoader = false;
            this.logger.error(err.error.message, 'Style Details');
            this.toastService.showError(err.error.message);
            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              this.submitted = false;
              this.logger.error(err.error.message, 'Style Details');
              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
            }
            this.showLoader = false;
          }
        );
      } else {
        let styleid = { id: this.f.styleid.value };
        let updatedStyleData = { ...styleData, ...styleid };
        //console.log(JSON.stringify(updatedStyleData))
        this.dataservice.updateStylebyId(updatedStyleData).subscribe(
          (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                ////console.log(JSON.stringify(response.body['item1']));
                let stylesnumber = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1']['styleNumber']
                );
                this.stylesNumber = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  stylesnumber
                );
                this.styleForm
                  .get('styleid')
                  .setValue(response.body['item1']['id']);
                this.styleForm
                  .get('styleName')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['styleName']
                    )
                  );
                this.styleForm
                  .get('newStyleNum')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['newStyleNum']
                    )
                  );
                this.styleForm
                  .get('category')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['category']
                    )
                  );
                this.styleForm.get('styleNumber').setValue(this.stylesNumber);
                // this.styleSubCategory = this.aesdecryptservice.decryptData(
                //   this.dataservice.Key,
                //   response.body['item1']['subCategory']
                // );
                this.styleForm
                  .get('subCategory')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['subCategory']
                    )
                  );
                this.styleForm
                  .get('description')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['description']
                    )
                  );
                this.styleForm
                  .get('season')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['season']
                    )
                  );
                //this.styleForm.get('fabric').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["fabric"]));
                this.styleForm
                  .get('ageGroup')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['ageGroup']
                    )
                  );
                this.styleForm
                  .get('targetGender')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['targetGender']
                    )
                  );

                this.styleForm
                  .get('garmentType')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['garmentType']
                    )
                  );

                this.styleForm
                  .get('silhouette')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['silhouette']
                    )
                  );
                this.styleForm
                  .get('neckType')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['neckType']
                    )
                  );

                this.styleForm
                  .get('sleeveType')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sleeveType']
                    )
                  );

                this.styleForm
                  .get('sleevesLength')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sleeveLength']
                    )
                  );

                this.styleForm
                  .get('length')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['length']
                    )
                  );

                this.styleForm
                  .get('fitting')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['fitting']
                    )
                  );

                this.styleForm
                  .get('color')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['colour']
                    )
                  );

                this.styleForm
                  .get('sizeChart')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sizeChart']
                    )
                  );
                this.changeSizeChart('', this.styleForm.get('sizeChart').value);
                this.styleForm
                  .get('status')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['status']
                    )
                  );
                this.styleForm
                  .get('sampleSize')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sampleSize']
                    )
                  );
                this.styleForm
                  .get('sizeRange')
                  .setValue(
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1']['sizeRange']
                    )
                  );
                this.refreshSelect();
                this.submitted = false;
                this.isEditMode = true;
                this.isEditingMode = true;
                this.isViewingMode = false;
                //this.success("Style Updated Successfully!");

                // this.success(
                //   this.appStaticMsgs.styledetails.styleupdatedsuccessfully
                // );
                this.logger.info(
                  this.appStaticMsgs.styledetails.styleupdatedsuccessfully,
                  'Style Details'
                );
                this.toastService.showSuccess('Updated Successfully');
                //this.getStyleDetails();
                this.showLoader = false;
              } else {
                ////console.log(response)
                // this.error(response.body['item1']);
                this.logger.error(response.body['item1'], 'Style Details');
                this.toastService.showError(response.body['item1']);
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.logger.error(err.error.message, 'Style Details');

            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
              this.logger.error(err.message, 'Style Details');
            }
            this.showLoader = false;
          }
        );
      }
    }
  }
  /*To delete style
    Date: 12-Oct-2021 */
  onDeleteStyle() {
    Swal.fire({
      title: 'Are you sure you want to delete this style?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getEditedStyleId());
        this.store.dispatch(
          new styleActionTypes.DeleteStylesLibrary(deleterecordIds)
        );
        this.store
          .pipe(
            select((state) => state),
            take(2),
            skip(1)
          )
          .subscribe((deletedStyleLibrary) => {
            if (deletedStyleLibrary.style.error === '') {
              if (deletedStyleLibrary.style.message?.item2 === 'Success') {
                // this.success(
                //   this.appStaticMsgs.styledetails.styleDeletedSuccessfully
                // );
                this.toastService.showSuccess('Deleted Successfully');
                this.logger.info(
                  this.appStaticMsgs.styledetails.styleDeletedSuccessfully!,
                  'Style'
                );
                this.router.navigate(['/styles']);
              } else {
                // this.error(
                //   'Failed:' + deletedStyleLibrary.style.message?.item1
                // );
                this.toastService.showError(
                  deletedStyleLibrary.style.message?.item1
                );
                this.logger.error(
                  deletedStyleLibrary.style.message?.item1,
                  'Style'
                );
              }
            } else {
              this.logger.error(
                deletedStyleLibrary.style.error.error.message,
                'Style'
              );
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To cancel edit mode and set to view mode for style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  cancelStyleEdit() {
    this.garmentTypeBasedList = [];
    // if (this.activeTab === 'colorwaysTab') {
    //   //(this.stylecolorForm.get('colorways') as FormArray).clear();
    //   this.getColorwaysControls();
    //   console.log(this.getColorwaysControls());
    //   //  this.getColorDetails();
    // } else if (this.activeTab === 'imgDetailsTab') {
    //   //(this.styImgForm.get('styImg') as FormArray).clear();
    //   this.getImageDetails();
    //   this.isThumbnailAdded = true;
    // }
    if (
      this.isNew ||
      this.isConNew ||
      this.isPatNew ||
      this.isSpeNew ||
      this.isStiNew ||
      this.isProNew ||
      this.isSewNew
      // ||
      // this.savePOMSTemplate?.length > 0 ||
      // this.savePOMSAITemplate?.length > 0
    ) {
      var errorMessage =
        "Please choose 'Save' or 'Discard Changes' for the records added or edited.";
      // this.warning(errorMessage);
      this.toastService.showError(errorMessage);
      return;
    }

    if (this.storageservice.getEditedStyleId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getStyleDetails();
    } else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.initForm();

    ///this.loadSelectPicker();
  }

  /*To cancel create mode and redirect to style list page
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  cancelStyleCreate() {
    this.router.navigate(['/styles']);
  }

  /*To add Colorways properties on control
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  addColorwaysGroup() {
    this.isColourwaysUpdate = true;
    let control = <FormArray>this.stylecolorForm.controls.colorways;
    control.push(
      this.fb.group({
        colourid: ['', Validators.required],
        colourway: [''], //[Validators.required, Validators.maxLength(50)]
        colourwayid: [''],
        name: [''],
        hexcode: [''],
        comments: [''],
      })
    );

    //this.colorwayDropdown();
    this.loadSelectPicker();
  }

  /*To get style colourways data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async getstylecolourways() {
    this.showLoader = true;
    this.dataservice
      .getstylecolourways(this.storageservice.getEditedStyleId())
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.logger.info(
              this.appStaticMsgs.styledetails.allColorwaareloadedsuccessfully,
              'Colorway Details'
            );
            this.showLoader = false;
            this.removedColorwaysIds = null;
            this.styleColourwaysCnt = response.body['item3'];

            let control = <FormArray>this.stylecolorForm.controls.colorways;
            if (control.length > 0) {
              this.stylecolorForm = this.fb.group({
                colorways: this.fb.array([]),
              });
            }
            let editeddata = JSON.stringify(response.body['item1'].colourways);
            let data = JSON.parse(editeddata);
            this.reloadColorFormData(data);
          } else {
            this.showLoader1 = false;
            this.logger.error(response.body['item1'], 'Colorway Details');
          }
        },
        (err) => {
          this.showLoader1 = false;
          this.logger.error(err.error.message, 'Colorway Details');
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.showLoader = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.showLoader = false;
            // this.sessionTimedOut();
            // this.userservice.logout();
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            this.showLoader = false;
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else {
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
        }
      );
  }

  /*To get Color HexCode for display color
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getColorHexCode(id) {
    let color = this.colordata.filter((x) => x.id == id);
    if (color.length > 0) {
      return color[0].colourHexCodes;
    }
  }

  /*To get Colorways Controls properties
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getColorwaysControls() {
    //this.colorwayDropdown();
    ////console.log('this.stylecolorForm',this.stylecolorForm);
    let colorways = (this.stylecolorForm.get('colorways') as FormArray)
      .controls;
    ////console.log('colorways',colorways);
    colorways.forEach((control, i) => {
      let controls = control['controls'];
      controls['hexcode'].value = this.getColorHexCode(
        controls['colourid'].value
      );
    });
    return colorways;
  }

  /*To bind Colorways Controls properties
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  reloadColorFormData(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.addColorwaysGroup();
      }
      let colorwayControl = this.getColorwaysControls();

      for (let i = 0; i < data.length; i++) {
        colorwayControl[i].get('colourid').setValue(data[i]['palettes']['id']);
        colorwayControl[i]
          .get('colourway')
          .setValue(
            this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              data[i]['colourway']
            )
          );
        colorwayControl[i]
          .get('comments')
          .setValue(
            this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              data[i]['comments']
            )
          );
        colorwayControl[i].get('colourwayid').setValue(data[i]['id']);
        colorwayControl[i]
          .get('name')
          .setValue(
            this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              data[i]['palettes']['name']
            )
          );
        this.refreshSelect();
      }
    }
  }

  /*To remove Colorways from Controls
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  removeColorway(index) {
    Swal.fire({
      title: 'Are you sure you want to delete this colorway?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let control = <FormArray>this.stylecolorForm.controls.colorways;
        let CWControl = this.getColorwaysControls();
        if (this.removedColorwaysIds) {
          this.removedColorwaysIds =
            this.removedColorwaysIds +
            ',' +
            CWControl[index].get('colourwayid').value;
        } else {
          this.removedColorwaysIds = CWControl[index].get('colourwayid').value;
        }
        control.removeAt(index);
        if (control.controls.length == 0) {
          this.isColourwaysUpdate = false;
        }
        this.onDeleteColorways();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To save Colorways details data in database
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  onColourwaySubmit() {
    this.submitted = true;
    if (this.stylecolorForm.invalid) {
      //this.submitted = false;
      this.toastService.showError('Please enter all the mandatory fields.');
      return false;
    }
    this.showLoader = true;
    let colorwaycontrol = <FormArray>this.stylecolorForm.controls.colorways;
    let colorwaysArraControl = this.getColorwaysControls();
    const formData = new FormData();

    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('cwcount', colorwaycontrol.length.toString());
    if (this.removedColorwaysIds) {
      formData.append('cwtoDelete', this.removedColorwaysIds);
    }

    //console.log('colorwaycontrol',colorwaycontrol);

    for (let i = 0; i < colorwaycontrol.length; i++) {
      //console.log('abcd',colorwaysArraControl[i].get('colourid').value);

      if (colorwaysArraControl[i].get('colourwayid').value) {
        formData.append(
          'colourwayid' + '[' + i + ']',
          colorwaysArraControl[i].get('colourwayid').value
        );
        formData.append(
          'tag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
        );
        this.colorwaysCard = true;
      } else {
        formData.append(
          'tag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
        );
        this.colorwaysCard = false;
      }
      formData.append(
        'paletteid' + '[' + i + ']',
        colorwaysArraControl[i].get('colourid').value
      );
      formData.append(
        'colourway' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          colorwaysArraControl[i].get('colourway').value
        )
      );
      formData.append(
        'comments' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          colorwaysArraControl[i].get('comments').value
        )
      );
      this.refreshSelect();

      //console.log('formData',colorwaysArraControl[i].get('colourid').value[0].id);
    }

    //return;

    this.dataservice.UpdateStyleColourways(formData).subscribe(
      (response) => {
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            ////console.log(JSON.stringify(response.body['item1']));

            this.logger.info(
              this.appStaticMsgs.styledetails.colorwaysupdatedsuccessfully,
              'Colourways Details'
            );
            // this.success(
            //   this.appStaticMsgs.styledetails.colorwaysupdatedsuccessfully
            // );
            if (this.colorwaysCard) {
              this.toastService.showSuccess('Updated Successfully');
            } else {
              this.toastService.showSuccess('Added Successfully');
            }

            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.removedColorwaysIds = null;
            this.showLoader = false;
            this.getstylecolourways();
          } else {
            this.logger.error(response.body['item1'], 'Colorway Details');
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showLoader = false;
        this.logger.error(err.error.message, 'Colorway Details');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          this.logger.error(err.message, 'Colorway Details');
        }
        this.showLoader = false;
      }
    );
  }

  onDeleteColorways() {
    this.submitted = true;
    if (this.stylecolorForm.invalid) {
      //this.submitted = false;
      return false;
    }
    this.showLoader = true;
    let colorwaycontrol = <FormArray>this.stylecolorForm.controls.colorways;
    let colorwaysArraControl = this.getColorwaysControls();
    const formData = new FormData();

    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('cwcount', colorwaycontrol.length.toString());
    if (this.removedColorwaysIds) {
      formData.append('cwtoDelete', this.removedColorwaysIds);
    }

    //console.log('colorwaycontrol',colorwaycontrol);

    for (let i = 0; i < colorwaycontrol.length; i++) {
      //console.log('abcd',colorwaysArraControl[i].get('colourid').value);

      if (colorwaysArraControl[i].get('colourwayid').value) {
        formData.append(
          'colourwayid' + '[' + i + ']',
          colorwaysArraControl[i].get('colourwayid').value
        );
        formData.append(
          'tag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
        );
      } else {
        formData.append(
          'tag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
        );
      }
      formData.append(
        'paletteid' + '[' + i + ']',
        colorwaysArraControl[i].get('colourid').value
      );
      formData.append(
        'colourway' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          colorwaysArraControl[i].get('colourway').value
        )
      );
      formData.append(
        'comments' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          colorwaysArraControl[i].get('comments').value
        )
      );
      this.refreshSelect();

      //console.log('formData',colorwaysArraControl[i].get('colourid').value[0].id);
    }

    //return;

    this.dataservice.UpdateStyleColourways(formData).subscribe(
      (response) => {
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            ////console.log(JSON.stringify(response.body['item1']));

            this.logger.info(
              this.appStaticMsgs.styledetails.colorwaysupdatedsuccessfully,
              'Colourways Details'
            );
            // this.success(
            //   this.appStaticMsgs.styledetails.colorwaysupdatedsuccessfully
            // );
            this.toastService.showSuccess('Deleted Successfully');

            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.removedColorwaysIds = null;
            this.showLoader = false;
            this.getstylecolourways();
          } else {
            this.logger.error(response.body['item1'], 'Colorway Details');
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showLoader = false;
        this.logger.error(err.error.message, 'Colorway Details');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          this.logger.error(err.message, 'Colorway Details');
        }
        this.showLoader = false;
      }
    );
  }

  /*To get Style Images Controls properties
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getStyleImgControls() {
    return (this.styImgForm.get('styImg') as FormArray).controls;
  }

  /*To add style images properties on control
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  addStyImage() {
    // this.uploadImg = true;
    this.isImagesUpdate = true;
    this.submitted = false;
    let control = <FormArray>this.styImgForm.controls.styImg;
    control.push(
      this.fb.group({
        id: [''],
        imageName: [''],
        imagetype: ['', Validators.required],
        comments: [''],
        imagestoDelete: [],
        croppedImage: [],
        showImage: [false],
      })
    );
    this.fileToReturn = '';
    this.files = [];
    this.fileData[control.length - 1] = this.files;
    this.loadSelectPicker();
    this.isThumbnailAdded = false;
  }

  /*To check validation for Select only one file at a time
    Written by : Kiran Nagargoje
    image/avif , image/svg+xml, image/x-icon are not supported by ngx-image-cropper
    Date: 12-Oct-2021 */
  async onSelectStyImgc(event, i) {
    console.log(event);
    if (
      event.target.files[0].type.includes('image') &&
      (event.target.files[0].type !== 'image/avif' || event.target.files[0].type !== 'image/svg+xml' || event.target.files[0].type !== 'image/x-icon')
    ) {
      this.imageChangedEvent = event;
      this.imgload = true;
      this.files = [];
      this.files.push(...this.imageChangedEvent.target.files);
      this.fileData[i] = this.files;
      this.imgfiledata[i] = this.imageChangedEvent.target.files[0].name;
      this.isFileError = false;
      this.isThumbnailAdded = true;
    } else {
      this.fileChanged = event;
      this.files = [];
      this.files.push(...this.fileChanged.target.files);
      this.fileData[i] = this.files;
      this.imgfiledata[i] = this.fileChanged.target.files[0].name;
      this.isFileError = false;
      this.isThumbnailAdded = true;
      const styImgArray = this.styImgForm.get('styImg') as FormArray;
      const control = styImgArray.at(styImgArray.length - 1) as FormGroup;
      control.patchValue({
        showImage: true,
      });
      this.fileToReturn = {
        name: this.fileChanged.target.files[0].name,
      };
      await this.modalReference.close();
    }
  }

  onStyImgRemove(event, i) {
    this.fileData[i].splice(this.fileData[i].indexOf(event), 1);
    this.imgfiledata[i].splice(this.imgfiledata[i].indexOf(event), 1);
    if (this.fileData[i].length === 1) {
      this.isThumbnailAdded = true;
    } else {
      this.isFileError = true;
      this.isThumbnailAdded = false;
    }
  }

  /*To remove images from control
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async onDeleteAttachement() {
    this.submitted = true;
    if (this.styImgForm.invalid) {
      if (this.thumbNails.length == 0) {
        this.isFileError = true;
      }
      return false;
    } else if (!this.isThumbnailAdded) {
      this.submitted = false;
      return false;
    }

    this.showLoader = true;
    let control = <FormArray>this.styImgForm.controls.styImg;
    let styImgControl = this.getStyleImgControls();
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    // formData.append('styleid', '1185bcbb-5524-4b68-8fd5-db0bfa69b46e');
    formData.append('imagecount', control.length.toString());
    //alert(this.removedStyImgIds)

    if (this.removedStyImgIds) {
      formData.append('imagestoDelete', this.removedStyImgIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (styImgControl[i].get('id').value) {
        formData.append('id' + '[' + i + ']', styImgControl[i].get('id').value);
        formData.append(
          'imagestag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
        );
      } else {
        formData.append(
          'imagestag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
        );
        if (this.fileToReturn.name != null) {
          this.isThumbnailAdded = true;
        } else {
          this.isThumbnailAdded = false;
          this.submitted = false;
          this.showLoader = false;
          return false;
        }
      }

      /*  if (styImgControl[i].get('docdeletedfileids').value) {
            formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
          }*/
      formData.append(
        'comments' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          styImgControl[i].get('comments').value
        )
      );
      formData.append(
        'imagetype' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          styImgControl[i].get('imagetype').value
        )
      );
      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '') {
          console.log(f);
          // if (this.fileData[i].length === 1) {
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != 'error') {
            // formData.append('imageid[' + i + ']', JSON.parse(fileUploadedData)["Key"]);
            // formData.append('filename[' + i + ']', f.name);
            formData.append(
              'imageid[' + i + ']',
              JSON.parse(fileUploadedData)['Key']
            );
            formData.append(
              'filename[' + i + ']',
              this.aesdecryptservice.encryptData(this.dataservice.Key, f.name)
            );
          } else {
            // this.error(fileUploadedData);
            this.toastService.showError(fileUploadedData);
            this.submitted = false;
            this.showLoader = false;
            return false;
          }
          // }
        }
        a = a + 1;
      }
    }

    this.dataservice.AddUpdateDeleteStyImages(formData).subscribe(
      (response) => {
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            ////console.log(JSON.stringify(response.body['item1']));
            this.logger.info(
              this.appStaticMsgs.styledetails.imagesupdatedsuccessfully,
              'Style Images'
            );
            // this.success(
            //   this.appStaticMsgs.styledetails.imagesupdatedsuccessfully
            // );
            this.toastService.showSuccess('Deleted Successfully');

            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.removedStyImgIds = null;
            this.submitted = false;
            this.showLoader = false;
            this.styImgForm.reset();
            if (response.body['item1'].styleImages) {
              if (response.body['item1'].styleImages.length > 0) {
                this.getImageDetails();
              }
            }
          } else {
            ////console.log(response)
            this.logger.error(response.body['item1'], 'Style Images');
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.submitted = false;
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.logger.error(err.error.message, 'Style Images');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style Images');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.submitted = false;
        this.showLoader = false;
      }
    );
  }
  removeStyImg(index) {
    Swal.fire({
      title: 'Are you sure you want to delete this style image?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let control = <FormArray>this.styImgForm.controls.styImg;
        let styImgControl = this.getStyleImgControls();
        const removedStyImg = styImgControl[index].value;
        if (this.removedStyImgIds) {
          this.removedStyImgIds =
            this.removedStyImgIds + ',' + styImgControl[index].get('id').value;
        } else {
          this.removedStyImgIds = styImgControl[index].get('id').value;
        }
        control.removeAt(index);

        if (control.controls.length == 0) {
          this.isImagesUpdate = false;
        }
        this.fileData.splice(index, 1);
        this.editedFileData.splice(index, 1);
        if (removedStyImg.id) {
          this.onDeleteAttachement();
        }
      }
    });
  }

  /*  selectFile() {
     let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
     element.click();
   } */

  removeStyImgForEdit(index) {
    this.EditedImage = true;
    this.croppedImage = [];
    this.editedFileData[index].thumbnail = '';
    this.isThumbnailAdded = false;
  }

  onRemoveImg(index) {
    Swal.fire({
      title: 'Are you sure you want to delete this Style Image ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // this.editedFileData[index].thumbnail = this.thumbnail;
        // this.imgfiledata.splice(index, 1);

        this.editedFileData[index].thumbnail = '';
        //console.log('abc',this.styImgForm.get('styImg'));

        // this.success(
        //   this.appStaticMsgs.styledetails
        //     .imageremovedsuccessfullypleaseuploadanewimage
        // );
        this.toastService.showSuccess('Deleted Successfully');
        this.logger.info(
          this.appStaticMsgs.styledetails
            .imageremovedsuccessfullypleaseuploadanewimage,
          'Style Image'
        );
      }
    });
  }

  onEditImg(index) {
    // this.uploadImg = true;
  }

  /*To save images details data in database
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  // async onStyImgSubmit() {
  //   this.submitted = true;
  //   if (this.styImgForm.invalid) {
  //     this.submitted = false;
  //     return false;
  //   } else if (!this.isThumbnailAdded) {
  //     this.submitted = false;
  //     return false;
  //   }

  //   this.showLoader = true;
  //   let control = <FormArray>this.styImgForm.controls.styImg;
  //   let styImgControl = this.getStyleImgControls();
  //   const formData = new FormData();
  //   formData.append('styleid', this.storageservice.getEditedStyleId());
  //   // formData.append('styleid', '1185bcbb-5524-4b68-8fd5-db0bfa69b46e');
  //   formData.append('imagecount', control.length.toString());
  //   //alert(this.removedStyImgIds)

  //   if (this.removedStyImgIds) {
  //     formData.append('imagestoDelete', this.removedStyImgIds);
  //   }
  //   for (let i = 0; i < control.length; i++) {
  //     if (styImgControl[i].get('id').value) {
  //       formData.append('id' + '[' + i + ']', styImgControl[i].get('id').value);
  //       formData.append('imagestag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));

  //     }
  //     else {
  //       formData.append('imagestag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
  //       if (this.imgfiledata[i] != null) {
  //         this.isThumbnailAdded = true;
  //       }
  //       else {
  //         this.isThumbnailAdded = false;
  //         this.submitted = false;
  //         this.showLoader = false;
  //         return false;

  //       }
  //     }

  //     /*  if (styImgControl[i].get('docdeletedfileids').value) {
  //         formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
  //       }*/
  //     formData.append('comments' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, styImgControl[i].get('comments').value));
  //     formData.append('imagetype' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, styImgControl[i].get('imagetype').value));
  //     let a: number = 0;
  //     for (let f of this.fileData[i]) {
  //       if (JSON.stringify(f) != '[]') {
  //         ////console.log (f.name);
  //         // if (this.fileData[i].length === 1) {
  //         let fileUploadedData = await this.uploadFile(f);
  //         if (fileUploadedData != "error") {
  //           // formData.append('imageid[' + i + ']', JSON.parse(fileUploadedData)["Key"]);
  //           // formData.append('filename[' + i + ']', f.name);
  //           formData.append('imageid[' + i + ']', JSON.parse(fileUploadedData)["Key"]);
  //           formData.append('filename[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
  //         }
  //         else {
  //           this.error(fileUploadedData);
  //           this.submitted = false;
  //           this.showLoader = false;
  //           return false;
  //         }
  //         // }
  //       }
  //       a = a + 1;
  //     }
  //   }

  //   this.dataservice.AddUpdateDeleteStyImages(formData).subscribe(response => {
  //     if (response.status === 200) {
  //       if (response.body['item2'] === "Success") {
  //         ////console.log(JSON.stringify(response.body['item1']));
  //         this.logger.info(this.appStaticMsgs.styledetails.imagesupdatedsuccessfully, "Style Images");
  //         //this.success("Images Updated Successfully!");
  //         this.toastService.showSuccess(this.appStaticMsgs.styledetails.imagesupdatedsuccessfully,"success");
  //         this.isEditMode = true;
  //         this.isEditingMode = false;
  //         this.isViewingMode = true;
  //         this.removedStyImgIds = null;
  //         this.submitted = false;
  //         this.showLoader = false;
  //         this.styImgForm.reset();
  //         if (response.body['item1'].styleImages) {
  //           if (response.body['item1'].styleImages.length > 0) {
  //             this.getImageDetails();
  //           }
  //         }
  //       }
  //       else {
  //         ////console.log(response)
  //         this.logger.error(response.body['item1'], "Style Images");
  //         this.error(response.body['item1']);
  //         this.submitted = false;
  //         this.showLoader = false;
  //       }

  //     }
  //   }, err => {
  //     this.logger.error(err.error.message, "Style Images");
  //     if (err.error.message === "Network error communicating with endpoint") {
  //       this.error(err.error.message);
  //     } else if (err.error.message === "The incoming token has expired") {
  //       this.sessionTimedOut();
  //       this.userservice.logout()
  //     } else if (err.error.message === "Unauthorized") {
  //       this.userservice.error();
  //     }
  //     else {
  //       this.logger.error(err.error.message, "Style Images");
  //       this.error(err.message);
  //     }
  //     this.submitted = false;
  //     this.showLoader = false;
  //   });

  // }
  async onStyImgSubmit() {
    this.submitted = true;
    if (this.styImgForm.invalid) {
      if (this.thumbNails.length == 0) {
        this.isFileError = true;
      }
      return false;
    } else if (!this.isThumbnailAdded) {
      this.submitted = false;
      return false;
    }
    let control = <FormArray>this.styImgForm.controls.styImg;
    for (let data of control.value) {
      if (!data.id && !data.croppedImage) {
        this.isFileError = true;
        return false;
      }
    }
    this.showLoader = true;
    let styImgControl = this.getStyleImgControls();
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    // formData.append('styleid', '1185bcbb-5524-4b68-8fd5-db0bfa69b46e');
    formData.append('imagecount', control.length.toString());
    //alert(this.removedStyImgIds)

    if (this.removedStyImgIds) {
      formData.append('imagestoDelete', this.removedStyImgIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (styImgControl[i].get('id').value) {
        formData.append('id' + '[' + i + ']', styImgControl[i].get('id').value);
        formData.append(
          'imagestag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
        );
        this.attachmentUpdateImg = true;
      } else {
        formData.append(
          'imagestag' + '[' + i + ']',
          this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
        );
        if (this.fileToReturn.name != null) {
          this.isThumbnailAdded = true;
        } else {
          this.isThumbnailAdded = false;
          this.submitted = false;
          this.showLoader = false;
          return false;
        }
        this.attachmentUpdateImg = false;
      }

      /*  if (styImgControl[i].get('docdeletedfileids').value) {
          formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
        }*/
      formData.append(
        'comments' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          styImgControl[i].get('comments').value
        )
      );
      formData.append(
        'imagetype' + '[' + i + ']',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          styImgControl[i].get('imagetype').value
        )
      );
      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '') {
          console.log(f);
          // if (this.fileData[i].length === 1) {
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != 'error') {
            // formData.append('imageid[' + i + ']', JSON.parse(fileUploadedData)["Key"]);
            // formData.append('filename[' + i + ']', f.name);
            formData.append(
              'imageid[' + i + ']',
              JSON.parse(fileUploadedData)['Key']
            );
            formData.append(
              'filename[' + i + ']',
              this.aesdecryptservice.encryptData(this.dataservice.Key, f.name)
            );
          } else {
            // this.error(fileUploadedData);
            this.toastService.showError(fileUploadedData);
            this.submitted = false;
            this.showLoader = false;
            return false;
          }
          // }
        }
        a = a + 1;
      }
    }

    this.dataservice.AddUpdateDeleteStyImages(formData).subscribe(
      (response) => {
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            ////console.log(JSON.stringify(response.body['item1']));
            this.logger.info(
              this.appStaticMsgs.styledetails.imagesupdatedsuccessfully,
              'Style Images'
            );
            // this.success(
            //   this.appStaticMsgs.styledetails.imagesupdatedsuccessfully
            // );
            if (this.attachmentUpdateImg) {
              this.toastService.showSuccess('Updated Successfully');
            } else {
              this.toastService.showSuccess('Added Successfully');
            }

            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.removedStyImgIds = null;
            this.submitted = false;
            this.showLoader = false;
            this.styImgForm.reset();
            if (response.body['item1'].styleImages) {
              if (response.body['item1'].styleImages.length > 0) {
                this.getImageDetails();
              }
            }
          } else {
            ////console.log(response)
            this.logger.error(response.body['item1'], 'Style Images');
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.submitted = false;
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.logger.error(err.error.message, 'Style Images');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style Images');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.submitted = false;
        this.showLoader = false;
      }
    );
  }
  // async onStyImgSubmit() {
  //   this.submitted = true;
  //   if (this.styImgForm.invalid) {
  //     return false;
  //   } else if (!this.isThumbnailAdded) {
  //     this.submitted = false;
  //     return false;
  //   }

  //   this.showLoader = true;
  //   let control = <FormArray>this.styImgForm.controls.styImg;
  //   let styImgControl = this.getStyleImgControls();
  //   const formData = new FormData();
  //   formData.append('styleid', this.storageservice.getEditedStyleId());
  //   // formData.append('styleid', '1185bcbb-5524-4b68-8fd5-db0bfa69b46e');
  //   formData.append('imagecount', control.length.toString());
  //   //alert(this.removedStyImgIds)

  //   if (this.removedStyImgIds) {
  //     formData.append('imagestoDelete', this.removedStyImgIds);
  //   }
  //   for (let i = 0; i < control.length; i++) {
  //     if (styImgControl[i].get('id').value) {
  //       formData.append('id' + '[' + i + ']', styImgControl[i].get('id').value);
  //       formData.append('imagestag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));

  //     }
  //     else {
  //       formData.append('imagestag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
  //       if (this.imgfiledata[i] != null) {
  //         this.isThumbnailAdded = true;
  //       }
  //       else {
  //         this.isThumbnailAdded = false;
  //         this.submitted = false;
  //         this.showLoader = false;
  //         return false;

  //       }
  //     }

  //     /*  if (styImgControl[i].get('docdeletedfileids').value) {
  //         formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
  //       }*/
  //     formData.append('comments' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, styImgControl[i].get('comments').value));
  //     formData.append('imagetype' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, styImgControl[i].get('imagetype').value));
  //     let a: number = 0;
  //     for (let f of this.fileData[i]) {
  //       if (JSON.stringify(f) != '[]') {
  //         ////console.log (f.name);
  //         // if (this.fileData[i].length === 1) {
  //         let fileUploadedData = await this.uploadFile(f);
  //         if (fileUploadedData != "error") {
  //           // formData.append('imageid[' + i + ']', JSON.parse(fileUploadedData)["Key"]);
  //           // formData.append('filename[' + i + ']', f.name);
  //           formData.append('imageid[' + i + ']', JSON.parse(fileUploadedData)["Key"]);
  //           formData.append('filename[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
  //         }
  //         else {
  //           this.error(fileUploadedData);
  //           this.submitted = false;
  //           this.showLoader = false;
  //           return false;
  //         }
  //         // }
  //       }
  //       a = a + 1;
  //     }
  //   }

  //   this.dataservice.AddUpdateDeleteStyImages(formData).subscribe(response => {
  //     if (response.status === 200) {
  //       if (response.body['item2'] === "Success") {
  //         ////console.log(JSON.stringify(response.body['item1']));
  //         this.logger.info(this.appStaticMsgs.styledetails.imagesupdatedsuccessfully, "Style Images");
  //         //this.success("Images Updated Successfully!");
  //         this.toastService.showSuccess(this.appStaticMsgs.styledetails.imagesupdatedsuccessfully, "success");
  //         this.isEditMode = true;
  //         this.isEditingMode = false;
  //         this.isViewingMode = true;
  //         this.removedStyImgIds = null;
  //         this.submitted = false;
  //         this.showLoader = false;
  //         this.styImgForm.reset();
  //         if (response.body['item1'].styleImages) {
  //           if (response.body['item1'].styleImages.length > 0) {
  //             this.getImageDetails();
  //           }
  //         }
  //       }
  //       else {
  //         ////console.log(response)
  //         this.logger.error(response.body['item1'], "Style Images");
  //         this.error(response.body['item1']);
  //         this.submitted = false;
  //         this.showLoader = false;
  //       }

  //     }
  //   }, err => {
  //     this.logger.error(err.error.message, "Style Images");
  //     if (err.error.message === "Network error communicating with endpoint") {
  //       this.error(err.error.message);
  //     } else if (err.error.message === "The incoming token has expired") {
  //       this.sessionTimedOut();
  //       this.userservice.logout()
  //     } else if (err.error.message === "Unauthorized") {
  //       this.userservice.error();
  //     }
  //     else {
  //       this.logger.error(err.error.message, "Style Images");
  //       this.error(err.message);
  //     }
  //     this.submitted = false;
  //     this.showLoader = false;
  //   });

  // }
  /*Common method To upload images
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async uploadFile(file): Promise<any> {
    console.log(file, 'file');
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: `${environment.BucketAccessKeyId}`,
      secretAccessKey: `${environment.BucketSecretAccessKey}`,
      region: `${environment.BucketRegion}`,
    });
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType,
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          ////console.log('There was an error uploading your file: ', err);
          reject('error');
        }
        ////console.log('Successfully uploaded file.', data);
        resolve(JSON.stringify(data));
      });
    });
    //for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
             ////console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                 ////console.log('There was an error uploading your file: ', err);
                  return false;
              }
             ////console.log('Successfully uploaded file.', data);
              return true;
          });*/
  }

  /*Common method To create Unique UUID
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  /*Common method to check isValid ImageURL or not
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  isValidImageURL(str) {
    if (!str) {
      return;
    }
    str = str.toLowerCase();
    str = str.split('?')[0];
    //moving on, split the uri into parts that had dots before them
    var parts = str.split('.');
    //get the last part ( should be the extension )
    var extension = parts[parts.length - 1];
    //define some image types to test against
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    //check if the extension matches anything in the list.
    if (imageTypes.indexOf(extension) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /*To bind style images Controls properties
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  reloadImageFormData(data) {
    this.imageObject = [];
    for (let i = 0; i < data.length; i++) {
      this.addStyImage();
    }
    console.log(data, 'data');

    this.imgfiledata = [];
    let styImgControl = this.getStyleImgControls();
    for (let i = 0; i < data.length; i++) {
      this.imgfiledata.push(
        this.aesdecryptservice.decryptData(
          this?.dataservice?.Key,
          data[i]['image']['name']
        )
      );
      styImgControl[i]
        .get('imagetype')
        .setValue(
          this.aesdecryptservice.decryptData(
            this.dataservice.Key,
            data[i]['imageType']
          )
        );
      styImgControl[i]
        .get('comments')
        .setValue(
          this.aesdecryptservice.decryptData(
            this.dataservice.Key,
            data[i]['comments']
          )
        );
      styImgControl[i].get('id').setValue(data[i]['id']);
      data[i]['image'].thumbnail = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        data[i]['image'].thumbnail
      );
      data[i]['image'].name = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        data[i]['image'].name
      );
      data[i]['imageType'] = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        data[i]['imageType']
      );
      styImgControl[i].get('imageName').setValue(data[i]['image'].name);

      this.editedFileData.push(data[i]['image']);
      console.log(data);

      const temp = {
        image: data[i]['image']['thumbnail'],
        imageName: data[i]['image'].name,
        thumbImage: data[i]['image']['thumbnail'],
      };
      if (data[i]?.imageType == 'coverpage') {
        this.imageObject.push(temp);
      }

      this.isThumbnailAdded = true;
    }
    console.log('this.imageObject', this.imageObject, this.editedFileData);
  }

  /*To open style images
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  open(index: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedFileData.length; i++) {
      let src = '';
      let thumb = '';
      if (this.isValidImageURL(this.editedFileData[i - 1]['name'])) {
        src = this.editedFileData[i - 1]['thumbnail'];
        thumb = this.editedFileData[i - 1]['thumbnail'];
      } else {
        src = this.documentImage;
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        thumb: thumb,
      };
      this.cards.push(album);
    }
    this._lightbox.open(this.cards, index);
  }

  /*To close opened style images
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  close(): void {
    this._lightbox.close();
  }

  /*To get Style details data on click of styleDetailsTab
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  styleDetailsTab(activeTab) {
    if (this.activeTab !== activeTab) {
      this.activeTab = activeTab;
      //this.isThumbnailAdded = true;
      if (this.storageservice.getEditedStyleId()) {
        this.getStyleDetails();
      }
      this.initForm();
    }
  }

  /*To get colorways details data on click of colorwaysTab
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  colorwaysTab(activeTab) {
    if (this.activeTab !== activeTab) {
      this.activeTab = activeTab;
      this.loadSelectPicker();
      this.getstylecolourways();
    }
  }

  /*To get image details data on click of imgDetailsTab
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  imgDetailsTab(activeTab) {
    if (this.activeTab !== activeTab) {
      this.activeTab = activeTab;
      this.isThumbnailAdded = true;
      this.loadSelectPicker();
      this.getImageDetails();
    }
  }

  /*To get bom details data on click of bomDetailsTab
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  bomDetailsTab(activeTab) {
    if (this.activeTab !== activeTab) {
      this.isEditMode = true;
      this.activeTab = activeTab;

      if (this.bomisPageChanged) {
        this.bomstartCountList = this.bomgridState.skip;
        this.bomendCountList = this.bomgridState.skip + this.bomgridState.take;
      } else {
        this.bomstartCountList = this.paginationStartcount;
        this.bomendCountList = this.paginationEndcount;
      }

      this.getBomDetails();
    }
  }

  /*To get construction details data on click of constructionTab
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async constructionTab(activeTab) {
    if (this.activeTab !== activeTab) {
      this.getAllSeams();
      this.getAllStitches();
      this.getStylesStitcheSpecifications();
      this.isEditMode = true;
      this.activeTab = activeTab;

      if (this.constructionisPageChanged) {
        this.constructionstartCountList = this.constructiongridState.skip;
        this.constructionendCountList =
          this.constructiongridState.skip + this.constructiongridState.take;
      } else {
        this.constructionstartCountList = this.paginationStartcount;
        this.constructionendCountList = this.paginationEndcount;
      }
      await this.getConstructionbyId();
    }
  }

  /*To get pattern details data on click of patternTab
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async patternTab(activeTab) {
    this.isEditMode = true;
    this.activeTab = activeTab;

    if (this.patternisPageChanged) {
      this.patternstartCountList = this.patterngridState.skip;
      this.patternendCountList =
        this.patterngridState.skip + this.patterngridState.take;
    } else {
      this.patternstartCountList = this.paginationStartcount;
      this.patternendCountList = this.paginationEndcount;
    }
    await this.getPatternbyId();
  }

  /*To get specification details data on click of specificationTab
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async specificationTab(activeTab) {
    if (this.activeTab !== activeTab) {
      this.isEditMode = true;
      this.activeTab = activeTab;
      if (this.specificationPomsisPageChanged) {
        this.specificationPomsstartCountList =
          this.specificationPomsgridState.skip;
        this.specificationPomsendCountList =
          this.specificationPomsgridState.skip +
          this.specificationPomsgridState.take;
      } else {
        this.specificationPomsstartCountList = this.paginationStartcount;
        this.specificationPomsendCountList = this.paginationEndcount;
      }

      if (this.specificationStitcheisPageChanged) {
        this.specificationStitchestartCountList =
          this.specificationStitchegridState.skip;
        this.specificationStitcheendCountList =
          this.specificationStitchegridState.skip +
          this.specificationStitchegridState.take;
      } else {
        this.specificationStitchestartCountList = this.paginationStartcount;
        this.specificationStitcheendCountList = this.paginationEndcount;
      }
      this.selectedItem = null;
      await this.getSpecificationbyId();
      //this.getAllStitches();
    }
  }

  /*To get processing details data on click of processingTab
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async processingTab(activeTab) {
    if (this.activeTab !== activeTab) {
      this.isEditMode = true;
      this.activeTab = activeTab;

      if (this.processingPomsisPageChanged) {
        this.processingPomsstartCountList = this.processingPomsgridState.skip;
        this.processingPomsendCountList =
          this.processingPomsgridState.skip + this.processingPomsgridState.take;
      } else {
        this.processingPomsstartCountList = this.paginationStartcount;
        this.processingPomsendCountList = this.paginationEndcount;
      }

      if (this.processingSewnisPageChanged) {
        this.processingSewnstartCountList = this.processingSewngridState.skip;
        this.processingSewnendCountList =
          this.processingSewngridState.skip + this.processingSewngridState.take;
      } else {
        this.processingSewnstartCountList = this.paginationStartcount;
        this.processingSewnendCountList = this.paginationEndcount;
      }
      await this.getProcessingbyId();
    }
  }

  /*To add bom properties on control
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  // public addBom({ sender }: AddEvent): void {
  //   this.loadMaterialData();
  //   // this.loadSupplierData();
  //   // this.getColorDetails();
  //   this.closeEditor(sender, this.editedRowIndex);

  //   this.colorCode = "";
  //   this.BOMformGroup = new FormGroup({
  //     stylebomid: new FormControl(),
  //     styleid: new FormControl(),
  //     component: new FormControl(''),
  //     //componentType: new FormControl(''),
  //     materialid: new FormControl(''),
  //     materialType: new FormControl(''),
  //     materialname: new FormControl(''),
  //     itemNo: new FormControl(''),
  //     supplierId: new FormControl(''),
  //     colourid: new FormControl(''),
  //     colourname: new FormControl(''),
  //     quantity: new FormControl(0),
  //     size: new FormControl(''),
  //     // colorCode:new FormControl(),
  //     price: new FormControl(0),
  //     subTotal: new FormControl(),
  //     uom: new FormControl(''),
  //     colourcode: new FormControl(''),
  //     notes: new FormControl('')
  //   });
  //   this.isNew = true;
  //   sender.addRow(this.BOMformGroup);
  // }
  public bomnew() {
    this.isbomnew = true;
    this.isbomedit = false;
    this.dialog.open(StylebomComponent, {
      disableClose: true,
      data: { isbomedit: this.isbomedit },
    });
    this.dialog.afterAllClosed.subscribe((data) => this.getBomDetails());
  }

  public AIBOMgenerator() {
    const bomAIpayload = {
      Garment_type: 'Topwear',
      Length: 'crop',
      Neck: 'Round Neck',
      Right_Sleeve: 'Puff Sleeves',
      Left_Sleeve: 'Puff Sleeves:',
      Right_Sleeve_length: 'Short Sleeves',
      Left_Sleeve_length: 'Short Sleeves',
      silhouette: 'CORSET',
      fitting: 'fitted',
      Color: 'White',
      occasion: 'Casual',
      season: 'summer',
      size: 'M',
    };

    this.dataservice.getAIBOMList(bomAIpayload).subscribe((aiBomList: any) => {
      console.log('888888888888888', bomAIpayload, this.bomViewData);
    });
  }

  public addprocessing() {
    this.isprocessingnew = true;
    this.isprocessingedit = false;
    this.dialog.open(StyleprocessingComponent, {
      disableClose: true,
      data: { isprocessingedit: this.isprocessingedit },
    });
    this.dialog.afterAllClosed.subscribe((data) =>
      this.getStylePomProcessing()
    );
  }

  loadSupplierData() {
    this.showLoader = true;
    this.supplierstartEndCount =
      this.supplierstartCountList + '-' + this.supplierendCountList;
    this.dataservice
      .getDetailsByModuleName('Suppliers', this.materialstartEndCount)
      .subscribe((response1) => {
        if (response1.body['item2'] === 'Success') {
          this.supplierendCountList = response1.body['item3'];
          this.supplierstartEndCount =
            this.supplierstartCountList + '-' + this.supplierendCountList;
          this.dataservice
            .getDetailsByModuleName('Suppliers', this.supplierstartEndCount)
            .subscribe(
              (response) => {
                console.log(response, 'response12');
                if (response.body['item2'] === 'Success') {
                  response.body['item1'].forEach((x) => {
                    x.name = this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      x.name
                    );
                    this.vendorData.push(x);
                  });
                  this.totalSupplierCount = response.body['item3'];
                  this.logger.info(
                    this.appStaticMsgs.styledetails
                      .allsuppliersdataloadedsuccessfully,
                    'Style Details'
                  );
                  this.showLoader = false;
                } else {
                  this.showLoader = false;
                  // this.error(response.body['item1']);
                  this.toastService.showError(response.body['item1']);
                  this.logger.error(response.body['item1'], 'Style Details');
                }
              },
              (err) => {
                this.logger.error(err.error.message, 'Style Details');
                if (
                  err.error.message ===
                  'Network error communicating with endpoint'
                ) {
                  // this.error(err.error.message);
                  this.toastService.showError(
                    'Oops!! Something went wrong. Please try again.'
                  );
                  this.showLoader = false;
                } else if (
                  err.error.message === 'The incoming token has expired'
                ) {
                  // this.sessionTimedOut();
                  // this.showLoader = false;
                  this.toastService.showError(
                    'The token being used has expired. Please login again.'
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else if (err.error.message === 'Unauthorized') {
                  // this.userservice.error();
                  this.showLoader = false;
                  this.toastService.showError(
                    "You don't have permission to access this resource. Please login again."
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else {
                  this.logger.error(err.message, 'Style Details');
                  // this.error(err.message);
                  this.toastService.showError(
                    this.appStaticMsgs.unResponsive.error
                  );
                }
                this.showLoader = false;
              }
            );
        }
      });
  }
  aiInformationOpen() {
    console.log('icon clicked');
    let ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    };
    // this.modalReference = this.modalService.open();
  }
  /*To get all stitches for bom
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public getAllStitches() {
    this.stitchesstartEndCount =
      this.stitchesstartCountList + '-' + this.stitchesendCountList;
    this.dataservice
      .getDetailsByModuleName('Stitches', this.stitchesstartEndCount)
      .subscribe((response1) => {
        if (response1.body['item2'] === 'Success') {
          this.stitchesendCountList = response1.body['item3'];
          this.stitchesstartEndCount =
            this.stitchesstartCountList + '-' + this.stitchesendCountList;
          this.dataservice
            .getDetailsByModuleName('Stitches', this.stitchesstartEndCount)
            .subscribe(
              (response) => {
                if (response.body['item2'] === 'Success') {
                  console.log('thumbnail', response.body['item1']);
                  response.body['item1'].forEach((x) => {
                    x.id = x.id;
                    x.name = this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      x.name
                    );
                    if (x.topViewFile) {
                      x.topViewFile.thumbnail =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          x.topViewFile?.thumbnail
                        );
                    } else {
                      x.topViewFile = null;
                    }
                    this.sticheslist.push(x);
                  });
                  this.sticheslistArr = this.sticheslist;
                  this.totalStitchesCount = response.body['item3'];
                  this.logger.info('Get All Stiches', 'Style Details');
                } else {
                  this.logger.error(response.body['item1'], 'Style Details');

                  // this.error(response.body['item1']);
                  this.toastService.showError(response.body['item1']);
                  this.showLoader = false;
                }
              },
              (err) => {
                this.logger.error(err.error.message, 'Style Details');
                if (
                  err.error.message ===
                  'Network error communicating with endpoint'
                ) {
                  // this.error(err.error.message);
                  this.toastService.showError(
                    'Oops!! Something went wrong. Please try again.'
                  );
                  this.showLoader = false;
                } else if (
                  err.error.message === 'The incoming token has expired'
                ) {
                  // this.sessionTimedOut();
                  // this.showLoader = false;
                  this.toastService.showError(
                    'The token being used has expired. Please login again.'
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else if (err.error.message === 'Unauthorized') {
                  // this.userservice.error();
                  this.showLoader = false;
                  this.toastService.showError(
                    "You don't have permission to access this resource. Please login again."
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else {
                  this.logger.error(err.error.message, 'Style Details');
                  // this.error(err.message);
                  this.toastService.showError(
                    this.appStaticMsgs.unResponsive.error
                  );
                }
                this.showLoader = false;
              }
            );
        }
      });
  }

  /*To get all Material Data for bom
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  loadMaterialData() {
    this.showLoader = true;
    this.materialstartEndCount =
      this.materialstartCountList + '-' + this.materialendCountList;
    this.dataservice
      .getDetailsByModuleName('Materials', this.materialstartEndCount)
      .subscribe((response1) => {
        if (response1.body['item2'] === 'Success') {
          this.materialendCountList = response1.body['item3'];
          this.materialstartEndCount =
            this.materialstartCountList + '-' + this.materialendCountList;
          this.dataservice
            .getDetailsByModuleName('Materials', this.materialstartEndCount)
            .subscribe(
              (response) => {
                console.log(response, 'response');
                if (response.body['item2'] === 'Success') {
                  response.body['item1'].forEach((x) => {
                    x.name = this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      x.name
                    );
                    this.materialData.push(x);
                    this.materialDataArr = this.materialData.slice();
                  });
                  this.totalMaterialCount = response.body['item3'];
                  this.logger.info(
                    this.appStaticMsgs.styledetails
                      .allmaterialdataloadedsuccessfully,
                    'Style Details'
                  );
                  this.showLoader = false;
                } else {
                  this.showLoader = false;
                  // this.error(response.body['item1']);
                  this.toastService.showError(response.body['item1']);
                  this.logger.error(response.body['item1'], 'Style Details');
                }
              },
              (err) => {
                this.logger.error(err.error.message, 'Style Details');
                if (
                  err.error.message ===
                  'Network error communicating with endpoint'
                ) {
                  // this.error(err.error.message);
                  this.toastService.showError(
                    'Oops!! Something went wrong. Please try again.'
                  );
                  this.showLoader = false;
                } else if (
                  err.error.message === 'The incoming token has expired'
                ) {
                  // this.sessionTimedOut();
                  // this.showLoader = false;
                  this.toastService.showError(
                    'The token being used has expired. Please login again.'
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else if (err.error.message === 'Unauthorized') {
                  // this.userservice.error();
                  this.showLoader = false;
                  this.toastService.showError(
                    "You don't have permission to access this resource. Please login again."
                  );
                  this.sessionTimedOut();
                  this.userservice.logout();
                } else {
                  this.logger.error(err.message, 'Style Details');
                  // this.error(err.message);
                  this.toastService.showError(
                    this.appStaticMsgs.unResponsive.error
                  );
                }
                this.showLoader = false;
              }
            );
        }
      });
  }

  /*On selection Change of Material get color and supplier
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  selectionChangeMaterial(event, id) {
    console.log(event, 'event');
    // this.loadSupplierData();
    // this.getColorDetails();
    let eveId;
    if (event) {
      eveId = event.id;
    } else {
      eveId = id;
    }
    this.showLoader1 = true;
    console.log('selectionChangeMaterial', event);
    this.dataservice.getMaterialById(eveId).subscribe(
      (response) => {
        if (response.body['item2'] === 'Success') {
          this.logger.info(
            response.body['item1']['name'] + ' material loaded successfully',
            'Style Details'
          );
          this.showLoader1 = false;
          this.calsubTotal.price = parseFloat(response.body['item1']['cost']);
          this.BOMformGroup.controls.colourid.setValue(undefined);
          this.BOMformGroup.controls.supplierId.setValue(undefined);
          for (let i = 0; i < response.body['item1']['suppliers'].length; i++) {
            response.body['item1']['suppliers'][i].name =
              this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                response.body['item1']['suppliers'][i].name
              );
          }

          for (let i = 0; i < response.body['item1']['colours'].length; i++) {
            response.body['item1']['colours'][i].name =
              this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                response.body['item1']['colours'][i].name
              );
          }
          this.vendorData = response.body['item1']['suppliers'];
          this.colorData = response.body['item1']['colours'];

          this.vendorDataArr = this.vendorData.slice();
          this.colorDataArr = this.colorData.slice();
          console.log('vendorData', this.vendorData);
          console.log('colorDataArr', this.colorDataArr);
          this.BOMformGroup.get('styleid').setValue(
            this.storageservice.getEditedStyleId()
          );
          this.BOMformGroup.get('materialType').setValue(
            this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              response.body['item1']['classification']
            )
          );
          this.BOMformGroup.get('itemNo').setValue(
            this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              response.body['item1']['sequence']
            )
          );
          this.BOMformGroup.get('materialid').setValue(
            response.body['item1']['id']
          );
          this.BOMformGroup.get('materialname').setValue(
            this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              response.body['item1']['name']
            )
          );
          this.BOMformGroup.get('price').setValue(
            this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              response.body['item1']['cost']
            )
          );
          this.BOMformGroup.get('uom').setValue(
            this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              response.body['item1']['sizeUOM']
            )
          );
          this.showLoader1 = false;
        } else {
          this.showLoader1 = false;
          this.logger.error(response.body['item1'], 'Style Details');
        }
      },
      (err) => {
        ////console.log(err)
        this.logger.error(err.error.message, 'Style Details');
        if (err.error.message === 'Network error communicating with endpoint') {
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
          this.showLoader1 = false;
        } else if (err.error.message === 'The incoming token has expired') {
          // this.showLoader1 = false;
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          this.showLoader1 = false;
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
      }
    );
  }

  onKeyUpPrice(event) {
    this.calsubTotal.price = parseFloat(event.target.value);
    this.subtotal =
      this.BOMformGroup.get('quantity').value * this.calsubTotal.price;
    this.BOMformGroup.get('subTotal').setValue(this.subtotal.toFixed(2));
  }

  onKeyUpQuantity(event) {
    this.calsubTotal.quantity = parseFloat(event.target.value);
    this.subtotal =
      this.calsubTotal.quantity * this.BOMformGroup.get('price').value;
    this.BOMformGroup.get('subTotal').setValue(this.subtotal.toFixed(2));
    // if (this.isNew) {
    //   this.BOMformGroup.get('subTotal').setValue(this.subtotal);
    // }else {
    //   //this.BOMformGroup.controls.subTotal.setValue(this.subtotal);
    //   this.BOMformGroup.get('subTotal').setValue(this.subtotal);
    // }
    console.log(
      'this.subtotal',
      this.calsubTotal.quantity,
      this.BOMformGroup.get('price').value,
      this.subtotal
    );
  }

  /*On selection Change of color get hexcode and thumbnailFiles
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  selectionChangeColor(event) {
    this.BOMformGroup.get('colourname').setValue(
      this.aesdecryptservice.decryptData(this.dataservice.Key, event['name'])
    );
    event['classification'] = this.aesdecryptservice.decryptData(
      this.dataservice.Key,
      event['classification']
    );

    if (event.hexcode) {
      event['hexcode'] = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        event['hexcode']
      );
      this.BOMformGroup.get('colourcode').setValue(event['hexcode']);
      console.log(
        this.aesdecryptservice.decryptData(
          this.dataservice.Key,
          event['hexcode']
        ),
        '3333333333'
      );
    } else if (event.thumbnailFiles) {
      event['thumbnailFiles'].thumbnail = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        event['thumbnailFiles'].thumbnail
      );
    }

    this.colorCode = event;
  }

  /* editClick(rowIndex, dataItem ) {
     this.selectionChangeMaterial('', dataItem.materialID);
    console.log('editClick')
    this.isbomnew=false;
    this.isbomedit=true;
    console.log(dataItem,'style')
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setBomData(dataItem);
    this.optionslist = dataItem;
    console.log(dataItem,'styles')
    console.log(dataItem.id)
    this.dialog.open(BomnewComponent,{
      disableClose: true,
      data: {bomData: dataItem,isbomedit: this.isbomedit}
    });
    this.dialog.afterAllClosed.subscribe(data=> this.getBomDetails() )
  } */
  /*To Edit BOM
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  // public editBOM({ sender, rowIndex, dataItem }) {
  //   this.loadMaterialData();
  //   // this.loadSupplierData();
  //   // this.getColorDetails();
  //   this.isNew = false;

  //   this.selectionChangeMaterial('', dataItem.materialID);

  //   if (dataItem.material?.colours) {
  //     for (let i = 0; i < dataItem.material.colours.length; i++) {
  //       dataItem.material.colours[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, dataItem.material.colours[i].name);
  //     }
  //     this.colorData = dataItem.material.colours;
  //   }

  //   if (dataItem.material?.suppliers) {
  //     for (let i = 0; i < dataItem.material.suppliers.length; i++) {
  //       dataItem.material.suppliers[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, dataItem.material.suppliers[i].name);
  //     }
  //     this.vendorData = dataItem.material.suppliers;
  //   }

  //   this.calsubTotal.price = parseFloat(dataItem.price);
  //   this.subtotal = dataItem.subTotal;
  //   this.closeEditor(sender, rowIndex);
  //   this.BOMformGroup = new FormGroup({
  //     stylebomid: new FormControl(dataItem.id),
  //     styleid: new FormControl(dataItem.stylesDTOID),
  //     component: new FormControl(dataItem.component),
  //     materialid: new FormControl(dataItem.materialID),
  //     materialType: new FormControl(dataItem.materialType),
  //     materialname: new FormControl(dataItem.materialName),
  //     itemNo: new FormControl(dataItem.itemNo),
  //     supplierId: new FormControl(dataItem.supplier?.id),
  //     colourid: new FormControl(dataItem.colourID),
  //     colourname: new FormControl(dataItem.colourName),
  //     quantity: new FormControl(dataItem.quantity),
  //     size: new FormControl(dataItem.sizeOrWidth),
  //     // colorCode:new FormControl(),
  //     price: new FormControl(dataItem.price),
  //     subTotal: new FormControl(dataItem.subTotal),
  //     uom: new FormControl(dataItem.uom),
  //     colourcode: new FormControl(dataItem.colourcode),
  //     notes: new FormControl(dataItem.notes)
  //   });
  //   // this.images=dataItem.image;
  //   this.isNew = true;
  //   this.editedRowIndex = rowIndex;
  //   sender.editRow(rowIndex, this.BOMformGroup);
  // }
  /*To Edit BOM
      Written by : Lasya Devathi
      Date: 23-JUN-2023 */
  editBom(rowIndex, dataItem) {
    this.selectionChangeMaterial('', dataItem.materialID);
    console.log('editClick');
    this.isbomnew = false;
    this.isbomedit = true;
    console.log(dataItem, 'style');
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setBomData(dataItem);
    this.optionslist = dataItem;
    console.log(dataItem, 'styles');
    console.log(dataItem.id);
    this.dialog.open(StylebomComponent, {
      disableClose: true,
      data: { bomData: dataItem, isbomedit: this.isbomedit },
    });
    this.dialog.afterAllClosed.subscribe((data) => this.getBomDetails());
  }

  /*To save BOM details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public saveBomDetails({ sender, rowIndex, formGroup, isNew }) {
    // if (this.BOMformGroup.controls.notes.value.length > 200) {
    //   this.BOMformGroup.controls.notes.setErrors({ 'incorrect': true });
    // }
    // if (this.BOMformGroup.invalid) {
    //   this.showBomNote = true;
    //   this.error('Please check the invalid fields!')
    //   return;
    // }
    this.showLoader = true;
    this.closeEditor(sender, rowIndex);
    this.BomData = formGroup.value;
    this.showLoader = true;

    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    // formData.append('colourid', this.BomData['colourid']);
    if (this.BomData['colourid']) {
      formData.append('colourid', this.BomData['colourid']);
    }
    if (this.BomData['materialid']) {
      formData.append('materialid', this.BomData['materialid']);
    }
    if (this.BomData['stylebomid']) {
      formData.append('stylebomid', this.BomData['stylebomid']);
      formData.append(
        'tag',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
      );
    } else {
      formData.append(
        'tag',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
      );
    }

    if (this.BomData['colourname']) {
      formData.append(
        'colourname',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['colourname']
        )
      );
    }

    if (this.BomData['materialType']) {
      formData.append(
        'materialtype',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['materialType']
        )
      );
    }
    if (this.BomData['materialname']) {
      formData.append(
        'materialname',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['materialname']
        )
      );
    }
    if (this.BomData['component']) {
      formData.append(
        'component',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['component']
        )
      );
    }
    if (this.BomData['notes']) {
      formData.append(
        'notes',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['notes']
        )
      );
    }
    if (this.BomData['itemNo']) {
      formData.append(
        'item',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['itemNo']
        )
      );
    }
    if (this.BomData['quantity']) {
      formData.append(
        'quantity',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['quantity'].toString()
        )
      );
    }
    if (this.BomData['uom']) {
      formData.append(
        'uom',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['uom']
        )
      );
    }

    if (this.BomData['subTotal']) {
      formData.append(
        'subtotal',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['subTotal'].toString()
        )
      );
    }
    if (this.BomData['price']) {
      formData.append(
        'price',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['price'].toString()
        )
      );
    }
    if (this.BomData['size']) {
      formData.append(
        'size',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['size']
        )
      );
    }
    if (this.BomData['supplierId']) {
      formData.append('supplierid', this.BomData['supplierId']);
    }
    if (this.BomData['colourcode']) {
      formData.append(
        'colourcode',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          this.BomData['colourcode']
        )
      );
    }
    console.log(formData, 'formData');
    console.log(
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        this.BomData['colourcode']
      ),
      '444444'
    );

    this.showLoader = true;
    this.dataservice.addupdatedeleteStyleBOMS(formData).subscribe(
      async (response) => {
        console.log(response, 'response');
        this.showBomNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.logger.info('BOM Save Successfully', 'Style BomDetails');
            this.showLoader = false;
            this.filteredData = response.body['item1']['boMs'];
            await this.getBomDetails();
            //this.BOMformGroup = undefined;
            // this.success(this.appStaticMsgs.styledetails.bomsavesuccessfully);
            this.toastService.showSuccess(
              this.appStaticMsgs.styledetails.bomsavesuccessfully
            );
            this.logger.info(
              this.appStaticMsgs.styledetails.bomsavesuccessfully,
              'Style Details'
            );
          } else {
            ////console.log(response)
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], 'Style BomDetails');
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showBomNote = false;
        this.logger.error(err.error.message, 'Style BomDetails');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style BomDetails');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );

    sender.closeRow(rowIndex);
  }
  // editClick(rowIndex, dataItem ) {
  //   console.log(dataItem,'style')
  //   this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
  //   this.sharedService.setItem(dataItem);
  //   this.optionslist = dataItem;
  //   console.log(this.optionslist,'styles')

  // }
  /*To remove BOM details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public removeBOM({ dataItem }) {
    Swal.fire({
      title: 'Are you sure you want to delete this BOM?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('bomtodelete', dataItem.id);
        formData.append('materialid', dataItem.materialid);
        formData.append('colourid', dataItem.colourid);
        formData.append('supplierid', dataItem.supplierId);
        formData.append('styleid', this.storageservice.getEditedStyleId());

        this.showLoader = true;
        this.dataservice.addupdatedeleteStyleBOMS(formData).subscribe(
          async (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(
                //   this.appStaticMsgs.styledetails.bomdeletedsuccessfully
                // );
                this.toastService.showSuccess('Deleted Successfully');
                this.logger.info(
                  this.appStaticMsgs.styledetails.bomdeletedsuccessfully,
                  'Style Bom Details'
                );
                await this.getBomDetails();
                //this.BOMformGroup = undefined;
              } else {
                ////console.log(response)
                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.logger.error(response.body['item1'], 'Style Bom Details');
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.logger.error(err.error.message, 'Style Details');
            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
              this.logger.error(err.error.message, 'Style Bom Details');
            }
            this.showLoader = false;
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To add Pattern properties on control
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public addPattern({ sender }: AddEvent): void {
    this.patternForm = new FormGroup({
      patternid: new FormControl(),
      pc: new FormControl('0'),
      description: new FormControl(''),
      cut: new FormControl('0'),
      allocation: new FormControl('0'),
    });
    this.isPatNew = true;
    sender.addRow(this.patternForm);
  }

  /*To save Pattern details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public async savePattern({ sender, rowIndex, formGroup, isNew }) {
    if (this.patternForm.controls.pc.value.length > 100) {
      this.patternForm.controls.pc.setErrors({ incorrect: true });
    }
    if (this.patternForm.controls.cut.value.length > 100) {
      this.patternForm.controls.cut.setErrors({ incorrect: true });
    }
    if (this.patternForm.controls.allocation.value.length > 100) {
      this.patternForm.controls.allocation.setErrors({ incorrect: true });
    }
    if (this.patternForm.controls.description.value.length > 200) {
      this.patternForm.controls.description.setErrors({ incorrect: true });
    }

    if (this.patternForm.invalid) {
      this.showPatternNote = true;
      // this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields);
      this.toastService.showError('Please enter all the mandatory fields.');
      return;
    }

    this.showLoader = true;

    //this.closeEditor(sender,rowIndex);
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('patterncount', '1');
    if (isNew) {
      formData.append(
        'patterntag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
      );
    } else {
      formData.append('patternid[0]', formGroup.value['patternid']);
      formData.append(
        'patterntag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
      );
    }
    formData.append(
      'pc[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['pc']
      )
    );
    formData.append(
      'description[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['description']
      )
    );
    formData.append(
      'cut[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['cut']
      )
    );
    formData.append(
      'allocation[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['allocation']
      )
    );

    console.log('this.patternImages', this.patternImages);
    if (!this.patternImages) {
      if (this.patternImageData.length != 0) {
        for (let f of this.patternImageData) {
          if (JSON.stringify(f) != '[]') {
            let fileUploadedData = await this.uploadFile(f);
            if (fileUploadedData != 'error') {
              formData.append(
                'imageid[0]',
                JSON.parse(fileUploadedData)['Key']
              );
            } else {
              // this.error(fileUploadedData);
              this.toastService.showError(fileUploadedData);
              this.submitted = false;
              this.showLoader = false;
              return false;
            }
          }
        }
      } else formData.append('imageid[0]', '');
    }

    this.showLoader = true;
    this.dataservice.addupdatedeleteStylePatterns(formData).subscribe(
      async (response) => {
        this.showPatternNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.showLoader = false;
            this.isNew = false;
            // this.success(
            //   this.appStaticMsgs.styledetails.patternsavesuccessfully
            // );
            this.toastService.showSuccess(
              this.appStaticMsgs.styledetails.patternsavesuccessfully
            );

            this.logger.info(
              this.appStaticMsgs.styledetails.patternsavesuccessfully,
              'Style Pattern'
            );
            await this.getPatternbyId();
            //his.patternForm = undefined;
          } else {
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], 'Style Pattern');
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showPatternNote = false;
        this.logger.error(err.error.message, 'Style Pattern');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style Pattern');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
    sender.closeRow(rowIndex);
  }

  /*To Edit Pattern details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 { sender, rowIndex, dataItem }*/
  public editPattern({ sender, rowIndex, dataItem }) {
    //const { dataItem } = dataItem;
    this.closeEditor(sender, rowIndex);
    //this.closeEditor(sender);
    //this.closeEditor(this.grid,rowIndex);

    this.patternForm = new FormGroup({
      patternid: new FormControl(dataItem.id, Validators.required),
      pc: new FormControl(dataItem.pc),
      description: new FormControl(dataItem.description),
      cut: new FormControl(dataItem.cut),
      allocation: new FormControl(dataItem.allocation),
    });
    this.isPatNew = true;
    this.patternImages = dataItem.image;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.patternForm);
  }

  /*To remove Pattern details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public removePattern({ dataItem }) {
    Swal.fire({
      title: 'Are you sure you want to delete this Pattern?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('patterntodelete', dataItem.id);
        formData.append('styleid', this.storageservice.getEditedStyleId());
        this.showLoader = true;
        this.dataservice.addupdatedeleteStylePatterns(formData).subscribe(
          async (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(
                //   this.appStaticMsgs.styledetails.patterndeletedsuccessfully
                // );
                this.toastService.showSuccess('Deleted Successfully');

                this.logger.info(
                  this.appStaticMsgs.styledetails.patterndeletedsuccessfully,
                  'Style Pattern'
                );
                await this.getPatternbyId();
                //this.patternForm = undefined;
              } else {
                ////console.log(response)
                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.logger.error(response.body['item1'], 'Style Pattern');
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.logger.error(err.error.message, 'Style Pattern');
            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              this.logger.error(err.error.message, 'Style Pattern');
              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
            }
            this.showLoader = false;
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To add Construction properties on control
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public addConstruction({ sender }: AddEvent): void {
    this.constructionForm = new FormGroup({
      operation: new FormControl('', Validators.required),
      mC_Type: new FormControl('', Validators.required),
      smv: new FormControl('', Validators.required),
      imageid: new FormControl(''),
      scid: new FormControl(''),
    });
    this.images = '';
    this.isConNew = true;
    sender.addRow(this.constructionForm);
  }

  /*To save Construction details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public async saveConstruction({ sender, rowIndex, formGroup, isNew }) {
    if (this.constructionForm.controls.operation.value.length > 100) {
      this.constructionForm.controls.operation.setErrors({ incorrect: true });
    }
    if (this.constructionForm.controls.mC_Type.value.length > 100) {
      this.constructionForm.controls.mC_Type.setErrors({ incorrect: true });
    }
    if (this.constructionForm.controls.smv.value.length > 100) {
      this.constructionForm.controls.smv.setErrors({ incorrect: true });
    }

    if (this.constructionForm.invalid) {
      this.showConstructionNote = true;
      // this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields);
      this.toastService.showError('Please enter all the mandatory fields.');
      return;
    }

    this.showLoader = true;
    //this.closeEditor(sender,rowIndex);
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('sccount', '1');
    if (isNew) {
      formData.append(
        'sctag',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
      );
    } else {
      formData.append('scid', formGroup.value['scid']);
      formData.append(
        'sctag',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
      );
    }
    formData.append(
      'operation',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['operation']
      )
    );
    formData.append(
      'mctype',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['mC_Type']
      )
    );
    formData.append(
      'smv',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['smv']
      )
    );

    if (!this.images) {
      if (this.fileData.length != 0) {
        for (let f of this.fileData) {
          if (JSON.stringify(f) != '[]') {
            let fileUploadedData = await this.uploadFile(f);
            if (fileUploadedData != 'error') {
              formData.append('imageid', JSON.parse(fileUploadedData)['Key']);
            } else {
              // this.error(fileUploadedData);
              this.toastService.showError(fileUploadedData);
              this.submitted = false;
              this.showLoader = false;
              return false;
            }
          }
        }
      } else formData.append('imageid', '');
    }

    this.showLoader = true;
    this.dataservice.addupdatedeletestyleConstructions(formData).subscribe(
      async (response) => {
        this.showConstructionNote = false;
        this.isConNew = false;
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.showLoader = false;
            // this.success(
            //   this.appStaticMsgs.styledetails.constructionsavesuccessfully
            // );
            this.toastService.showSuccess(
              this.appStaticMsgs.styledetails.constructionsavesuccessfully
            );

            this.logger.info(
              this.appStaticMsgs.styledetails.constructionsavesuccessfully,
              'Style Constructions'
            );
            this.fileData = [];
            await this.getConstructionbyId();
            //this.constructionForm = undefined;
          } else {
            this.logger.error(response.body['item1'], 'Style Constructions');
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showConstructionNote = false;
        this.logger.error(err.error.message, 'Style Constructions');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style Constructions');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
    sender.closeRow(rowIndex);
  }

  /*To remove Construction details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public removeConstruction({ dataItem }) {
    Swal.fire({
      title: 'Are you sure you want to delete this Construction?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('sctodelete', dataItem.id);
        formData.append('styleid', this.storageservice.getEditedStyleId());
        this.showLoader = true;
        this.dataservice.addupdatedeletestyleConstructions(formData).subscribe(
          async (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(
                //   this.appStaticMsgs.styledetails
                //     .constructiondeletedsuccessfully
                // );
                this.toastService.showSuccess('Deleted Successfully');

                this.logger.info(
                  this.appStaticMsgs.styledetails
                    .constructiondeletedsuccessfully,
                  'Style Construction'
                );
                await this.getConstructionbyId();
                //this.constructionForm = undefined;
              } else {
                ////console.log(response)
                this.logger.error(response.body['item1'], 'Style Construction');
                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.logger.error(err.error.message, 'Style Construction');
            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              this.logger.error(err.error.message, 'Style Construction');
              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
            }
            this.showLoader = false;
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To Edit Construction details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public editConstruction({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender, rowIndex);
    this.constructionForm = new FormGroup({
      scid: new FormControl(dataItem.id, Validators.required),
      operation: new FormControl(dataItem.operation),
      mC_Type: new FormControl(dataItem.mC_Type, Validators.required),
      smv: new FormControl(dataItem.smv, Validators.required),
      imageid: new FormControl(dataItem.imageID),
    });
    this.isConNew = true;
    this.images = dataItem.image;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.constructionForm);
  }

  removeThumbnailConstructionSection(event) {
    this.images = '';
  }
  onChangeConstructionImg(event) {
    this.fileData = event.target.files;
  }

  removeThumbnailPatternSection(event) {
    this.patternImages = '';
  }
  onChangePatternImg(event) {
    this.patternImageData = event.target.files;
  }

  /*To add Specification properties on control
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public addSpecification({ sender }: AddEvent): void {
    console.log('sampleSize', this.sampleSize);

    /* if(this.data1 || this.pomdata =='XS, S, …..6XL, OS'){
       this.specificationForm = new FormGroup({
         'spid': new FormControl(),
          'poms': new FormControl('', Validators.required),

         's': new FormControl('', Validators.required),
         'xs': new FormControl('', Validators.required),
         'm': new FormControl('', Validators.required),
         'l': new FormControl('', Validators.required),
         'xl': new FormControl('', Validators.required),
         'doubleXL': new FormControl('', Validators.required),
       'threebleXL': new FormControl('', Validators.required),
       'fourXL': new FormControl('', Validators.required),
       'fiveXL': new FormControl('', Validators.required),
       'sixXL': new FormControl('', Validators.required),
       'OS': new FormControl('', Validators.required),
       'tolerance': new FormControl('', Validators.required),
       });
     }else{
       this.specificationForm = new FormGroup({
         'spid': new FormControl(),
          'poms': new FormControl('', Validators.required),

         'zero': new FormControl('', Validators.required),
         'two': new FormControl('', Validators.required),
         'four': new FormControl('', Validators.required),
         'six': new FormControl('', Validators.required),
         'eight': new FormControl('', Validators.required),
         'ten': new FormControl('', Validators.required),
         'twelve': new FormControl('', Validators.required),
         'fourteen': new FormControl('', Validators.required),
         'sixteen': new FormControl('', Validators.required),
         'eightteen': new FormControl('', Validators.required),
          "twenty": new FormControl('', Validators.required),
         "twentytwo": new FormControl('', Validators.required),
       "twentyfour": new FormControl('', Validators.required),
        "eightOS": new FormControl('', Validators.required),
        "tenOS": new FormControl('', Validators.required),
          "twelveOS": new FormControl('', Validators.required),
          'tolerance': new FormControl('', Validators.required),
       });

     }*/

    this.specificationForm = this.createGroup();

    this.isSpeNew = true;
    // this.addNewPOMS = true;
    this.newPOMSTemplate = sender;
    sender.addRow(this.specificationForm);
    console.log(this.specificationForm.value, 'formvalue');
  }

  createGroup() {
    const group = this.fb.group({});
    if (this.pomdata) {
      this.pomdata.forEach((x) => {
        group.addControl(x, this.fb.control(''));
      });

      console.log(group, 'group');
    }
    // else{
    //   this.sampleSize.forEach(x=> group.addControl(x, this.fb.control('',Validators.required)));
    //   console.log(group,'group');
    // }
    return group;
  }

  /*To save Specification details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public saveSpecification({ sender, rowIndex, formGroup, isNew }) {
    console.log(this.pomdata, 'formvalue');

    console.log(this.pomdata, formGroup, 'formvalue', this.specificationPoms);
    for (var i = 0; i < this.specificationForm.value.length; i++)
      if (this.specificationForm.controls[i].value.length > 100) {
        this.specificationForm.controls[i].setErrors({ incorrect: true });
      }

    // if (this.specificationForm.invalid) {
    //   this.showSpecPomNote = true;
    //   this.error('Please check the invalid fields!')
    //   return;
    // }
    this.showLoader = true;
    this.closeEditor(sender, rowIndex);

    const specification = formGroup.value;
    let temp = [];
    temp.push({ name: formGroup.value });
    console.log(temp, 'temp');
    temp.forEach((k) => {
      console.log(k, formGroup.value[k]);
    });
    // temp.push()

    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('stylepomcount', '1');
    if (isNew) {
      formData.append(
        'sptag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
      );
    } else {
      formData.append('SPID[0]', formGroup.value['id']);
      formData.append(
        'sptag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
      );
    }

    // formData.append(
    //   'pomId[0]',
    //   this.aesdecryptservice.encryptData(
    //     this.dataservice.Key,
    //     formGroup.value['poms']
    //   )
    // );
    // formData.append(
    //   'pomName[0]',
    //   this.aesdecryptservice.encryptData(
    //     this.dataservice.Key,
    //     formGroup.value['poms']
    //   )
    // );

    formData.append(
      'poms[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['poms']
      )
    );
    formData.append(
      'tolerance[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['tolerance']
      )
    );
    formData.append(
      'description[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['description']
      )
    );
    this.sampleSize.forEach((x) => {
      console.log(
        'key : ' + x.value + ' - value : ' + formGroup.value[x.value]
      );
      formData.append(
        x.value + '[0]',
        this.aesdecryptservice.encryptData(
          this.dataservice.Key,
          formGroup.value[x.value]
        )
      );
    });

    let datas = [];
    this.unfilteredData.forEach((y) => {
      datas.push(y.columnName);
    });

    let b = this.pomdata;
    let a = datas;
    let bc = [];
    let array3 = a.filter((entry1) => !b.some((entry2) => entry1 === entry2));

    array3.forEach((z) => {
      formData.append(
        z + '[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, '')
      );
    });

    // console.log('unfilteredData',datas);
    // console.log('pom data',this.pomdata);
    // console.log('filter pom data',array3);
    // return;
    /*
    formData.append('S[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['s']));
    formData.append('M[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['m']));
    formData.append('L[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['l']));
    formData.append('XS[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['xs']));
    formData.append('XL[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['xl']));
    formData.append('DoubleXL[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['doubleXL']));
    formData.append('ThreebleXL[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['threebleXL']));

    formData.append('FourXL[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['fourXL']));

    formData.append('FiveXL[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['fiveXL']));

    formData.append('SixXL[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['sixXL']));

    formData.append('OS[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['OS']));
    formData.append('Tolerance[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['tolerance']));

      formData.append('zero[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['zero']));
      formData.append('two[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['two']));
      formData.append('four[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['four']));
      formData.append('six[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['six']));
      formData.append('eight[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['eight']));
      formData.append('ten[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['ten']));
      formData.append('twelve[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['twelve']));

      formData.append('Fourteen[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['Fourteen']));

      formData.append('sixteen[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['sixteen']));

      formData.append('eightteen[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['eightteen']));
      formData.append('twenty[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['twenty']));
      formData.append('twentytwo[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['twentytwo']));
      formData.append('twentyfour[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['twentyfour']));

      formData.append('eightOS[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['eightOS']));


      formData.append('tenOS[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['tenOS']));
      formData.append('twelveOS[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, formGroup.value['twelveOS']));
*/
    this.showLoader = true;
    console.log(formData, 'formdata');
    console.log(this.specificationForm.value, 'formvalue');
    //return;
    this.dataservice.addupdatedeleteStylespecification(formData).subscribe(
      async (response) => {
        this.showSpecPomNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.showLoader = false;
            this.logger.info(
              this.appStaticMsgs.styledetails.specificationpomsavesuccessfully,
              'Style Specification pom'
            );
            // this.success(
            //   this.appStaticMsgs.styledetails.specificationpomsavesuccessfully
            // );
            if (isNew) {
              this.toastService.showSuccess('Added Successfully');
            } else {
              this.toastService.showSuccess('Updated Successfully');
            }

            this.getStylePomSpecifications();
          } else {
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(
              response.body['item1'],
              'Style Specification pom'
            );
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showSpecPomNote = false;
        this.logger.error(err.error.message, 'Style Specification pom');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style Specification pom');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
    sender.closeRow(rowIndex);
  }

  /*To edit Specification details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public editSpecification({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender, rowIndex);

    console.log(
      'this.dbspecificationPoms',
      this.dbspecificationPoms,
      sender,
      rowIndex,
      dataItem
    );
    let data1: any = [];
    data1.push(dataItem);
    console.log(data1, 'data1');
    const group = this.fb.group({});
    if (dataItem !== null) {
      this.pomdata.push('id');
    }
    if (this.pomdata) {
      data1.forEach((y, index) => {
        console.log(y, 'y');
        this.pomdata.forEach((x, i) =>
          group.addControl(x, this.fb.control(y[x.toLowerCase()]))
        );
      });
      console.log(group, 'group');
    }
    this.isSpeNew = true;
    // this.addNewPOMS = true;

    let data = this.createGroup();
    //  data.controls.push('spid');
    console.log(data, 'data');
    this.newPOMSTemplate = sender;
    this.specificationForm = group;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.specificationForm);
  }

  /*To remove Specification details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public removeSpecification({ dataItem }) {
    Swal.fire({
      title: 'Are you sure you want to delete this specification?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('stylepomstodelete', dataItem.id);
        formData.append('styleid', this.storageservice.getEditedStyleId());
        this.showLoader = true;
        this.dataservice.addupdatedeleteStylespecification(formData).subscribe(
          async (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(
                //   this.appStaticMsgs.styledetails
                //     .specificationpomdeletedsuccessfully
                // );
                this.toastService.showSuccess('Deleted Successfully');

                this.logger.info(
                  this.appStaticMsgs.styledetails
                    .specificationpomdeletedsuccessfully,
                  'Style specificationPom'
                );
                this.getStylePomSpecifications();
                //this.specificationForm = undefined;
                //this.StichesForm = undefined;
                //await this.getSpecificationbyId();
              } else {
                ////console.log(response)
                this.logger.error(
                  response.body['item1'],
                  'Style specificationPom'
                );
                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.logger.error(err.error.message, 'Style specificationPom');
            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              this.logger.error(err.error.message, 'Style specificationPom');
              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
            }
            this.showLoader = false;
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To add Stiches properties on control
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021 */
  public addStiches({ sender }: AddEvent): void {
    this.StichesForm = new FormGroup({
      ssid: new FormControl(),
      stitchid: new FormControl(),
      placement: new FormControl('', Validators.required),
      notes: new FormControl(''),
    });
    this.isStiNew = true;

    sender.addRow(this.StichesForm);
  }

  /*To save Stiches details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public saveStitch({ sender, rowIndex, formGroup, isNew }) {
    console.log(formGroup);
    console.log(isNew);
    if (this.StichesForm.controls.placement.value.length > 100) {
      this.StichesForm.controls.placement.setErrors({ incorrect: true });
    }
    if (this.StichesForm.controls.notes.value.length > 200) {
      this.StichesForm.controls.notes.setErrors({ incorrect: true });
    }

    if (this.StichesForm.invalid) {
      this.showSpecStitcheNote = true;
      // this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields);
      this.toastService.showError('Please enter all the mandatory fields.');
      return;
    }

    this.showLoader = true;
    this.closeEditor(sender, rowIndex);
    const specification = formGroup.value;
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('stylestitchescount', '1');
    if (isNew) {
      formData.append(
        'sstag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
      );
    } else {
      formData.append('ssid[0]', formGroup.value['ssid']);
      formData.append(
        'sstag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
      );
    }
    formData.append('stitchid[0]', formGroup.value['stitchid']);
    formData.append(
      'placement[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['placement']
      )
    );
    formData.append(
      'notes[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['notes']
      )
    );

    this.showLoader = true;
    this.dataservice.addupdatedeleteStylespecification(formData).subscribe(
      async (response) => {
        this.showSpecStitcheNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.showLoader = false;
            // this.success(
            //   this.appStaticMsgs.styledetails
            //     .specificationstitchssavesuccessfully
            // );
            this.toastService.showSuccess(
              this.appStaticMsgs.styledetails
                .specificationstitchssavesuccessfully
            );

            this.logger.info(
              this.appStaticMsgs.styledetails
                .specificationstitchssavesuccessfully,
              'Style StitcheSpecifications'
            );
            this.getStylesStitcheSpecifications();
            //this.specificationForm = undefined;
            //this.StichesForm = undefined;
            //await this.getSpecificationbyId();
          } else {
            this.logger.error(
              response.body['item1'],
              'Style StitcheSpecifications'
            );
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showSpecStitcheNote = false;
        this.logger.error(err.error.message, 'Style StitcheSpecifications');

        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style StitcheSpecifications');

          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
    sender.closeRow(rowIndex);
  }

  /*To edit Stiches details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public editStitch({ sender, rowIndex, dataItem }) {
    console.log(dataItem, 'dataitem');
    this.closeEditor(sender, rowIndex);
    this.StichesForm = new FormGroup({
      stitchid: new FormControl(dataItem.stichID, Validators.required),
      placement: new FormControl(dataItem.placement, Validators.required),
      notes: new FormControl(dataItem.notes),
      ssid: new FormControl(dataItem.id),
    });
    this.isStiNew = true;
    this.selectedValue = dataItem.stichID;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.StichesForm);
  }

  /*To remove Stiches details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public removeStitch({ dataItem }) {
    Swal.fire({
      title: 'Are you sure you want to delete this Specifications Stitchs?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('styleid', this.storageservice.getEditedStyleId());
        formData.append('stylestitchestodelete', dataItem.id);
        this.showLoader = true;
        this.dataservice.addupdatedeleteStylespecification(formData).subscribe(
          async (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(
                //   this.appStaticMsgs.styledetails
                //     .specificationstitchsdeletedsuccessfully
                // );
                this.toastService.showSuccess('Deleted Successfully');

                this.logger.info(
                  this.appStaticMsgs.styledetails
                    .specificationstitchsdeletedsuccessfully,
                  'Style StitcheSpecifications'
                );
                this.getStylesStitcheSpecifications();
                //this.specificationForm = undefined;
                //this.StichesForm = undefined;
                //await this.getSpecificationbyId();
              } else {
                ////console.log(response)
                this.logger.error(
                  response.body['item1'],
                  'Style StitcheSpecifications'
                );

                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.logger.error(err.error.message, 'Style StitcheSpecifications');

            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              this.logger.error(err.message, 'Style StitcheSpecifications');

              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
            }
            this.showLoader = false;
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  public selectionStiches(event) {
    this.changemode = true;
    this.stichImage = event.topView?.thumbnail;
    console.log(this.stichImage, 'stich');
  }

  /*To add ProcessingPOM properties on control
     Written by : Kiran Nagargoje
     Date: 12-Oct-2021 */
  public addProcessingPOM({ sender }: AddEvent): void {
    this.processingForm = new FormGroup({
      pom: new FormControl({ value: 'POM', disabled: true }),
      pc: new FormControl(),
      poms: new FormControl('', Validators.required),
      originalLength: new FormControl('', Validators.required),
      originalWidth: new FormControl('', Validators.required),
      afterwashLength: new FormControl('', Validators.required),
      afterwashWidth: new FormControl('', Validators.required),
      afterdryLength: new FormControl('', Validators.required),
      afterdryWidth: new FormControl('', Validators.required),
      id: new FormControl(),
    });

    this.isProNew = true;
    sender.addRow(this.processingForm);
  }

  /*To save ProcessingPOM details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public saveProcessingPOM({ sender, rowIndex, formGroup, isNew }) {
    if (this.processingForm.controls.pc.value.length > 100) {
      this.processingForm.controls.pc.setErrors({ incorrect: true });
    }
    if (this.processingForm.controls.poms.value.length > 100) {
      this.processingForm.controls.poms.setErrors({ incorrect: true });
    }
    if (this.processingForm.controls.originalLength.value.length > 100) {
      this.processingForm.controls.originalLength.setErrors({
        incorrect: true,
      });
    }

    if (this.processingForm.invalid) {
      this.showProcPomNote = true;
      // this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields);
      // this.toastService.showError(
      //   this.appStaticMsgs.common.pleasechecktheinvalidfields
      // );
      this.toastService.showError('Please enter all the mandatory fields.');
      return;
    }

    this.showLoader = true;
    this.closeEditor(sender, rowIndex);
    const specification = formGroup.value;
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('ppcount', '1');
    if (isNew) {
      formData.append(
        'pptag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
      );
    } else {
      formData.append('ppid[0]', formGroup.value['id']);
      formData.append(
        'pptag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
      );
    }
    formData.append(
      'pc[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['pc']
      )
    );
    formData.append(
      'poms[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['poms']
      )
    );
    formData.append(
      'originallength[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['originalLength']
      )
    );
    formData.append(
      'originalwidth[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['originalWidth']
      )
    );
    formData.append(
      'afterwashlength[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['afterwashLength']
      )
    );
    formData.append(
      'afterwashwidth[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['afterwashWidth']
      )
    );
    formData.append(
      'afterdrylength[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['afterdryLength']
      )
    );
    formData.append(
      'afterdrywidth[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['afterdryWidth']
      )
    );

    this.showLoader = true;
    this.dataservice.addupdatedeleteStyleprocessing(formData).subscribe(
      async (response) => {
        this.showProcPomNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.showLoader = false;
            // this.success(
            //   this.appStaticMsgs.styledetails.processingpomsavesuccessfully
            // );
            this.toastService.showSuccess(
              this.appStaticMsgs.styledetails.processingpomsavesuccessfully
            );

            this.logger.info(
              this.appStaticMsgs.styledetails.processingpomsavesuccessfully,
              'Style ProcessingPOM'
            );
            this.getStylePomProcessing();
            //this.processingForm = undefined;
            //this.sewnForm = undefined;
            //await this.getProcessingbyId();
          } else {
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], 'Style ProcessingPOM');
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showProcPomNote = false;
        this.logger.error(err.error.message, 'Style ProcessingPOM');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style ProcessingPOM');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
    sender.closeRow(rowIndex);
  }

  /*To edit ProcessingPOM details
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021 */
  // public editProcessPOM({ sender, rowIndex, dataItem }) {
  //   this.closeEditor(sender, rowIndex);

  //   this.processingForm = new FormGroup({
  //     'id': new FormControl(dataItem.id, Validators.required),
  //     'pc': new FormControl(dataItem.pc),
  //     'poms': new FormControl(dataItem.poms, Validators.required),
  //     'originalLength': new FormControl(dataItem.originalLength, Validators.required),
  //     'originalWidth': new FormControl(dataItem.originalWidth, Validators.required),
  //     'afterwashLength': new FormControl(dataItem.afterwashLength, Validators.required),
  //     'afterwashWidth': new FormControl(dataItem.afterwashWidth, Validators.required),
  //     'afterdryLength': new FormControl(dataItem.afterdryLength, Validators.required),
  //     'afterdryWidth': new FormControl(dataItem.afterdryWidth, Validators.required),
  //   });
  //   this.isProNew = true;

  //   this.editedRowIndex = rowIndex;
  //   sender.editRow(rowIndex, this.processingForm);
  // }
  /*To edit ProcessingPOM details
    Written by : Lasya Devathi
    Date: 27-jun-2023 */
  editProcessingpom(rowIndex, dataItem) {
    console.log('editClick');
    this.isprocessingnew = false;
    this.isprocessingedit = true;
    console.log(dataItem, 'style');
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setprocessingpomData(dataItem);
    this.processingpomdata = dataItem;
    console.log(dataItem, 'styles');
    console.log(dataItem.id);
    this.dialog.open(StyleprocessingComponent, {
      disableClose: true,
      data: { PomData: dataItem, isprocessingedit: this.isprocessingedit },
    });
    this.dialog.afterAllClosed.subscribe((data) =>
      this.getStylePomProcessing()
    );
  }
  /*To remove ProcessingPOM details
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021 */
  public removeProcessingPOM({ dataItem }) {
    Swal.fire({
      title: 'Are you sure you want to delete this Proceesing POM?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('pptodelete', dataItem.id);
        formData.append('styleid', this.storageservice.getEditedStyleId());
        this.showLoader = true;
        this.dataservice.addupdatedeleteStyleprocessing(formData).subscribe(
          async (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(
                //   this.appStaticMsgs.styledetails
                //     .processingpomdeletedsuccessfully
                // );
                this.toastService.showSuccess('Deleted Successfully');

                this.logger.info(
                  this.appStaticMsgs.styledetails
                    .processingpomdeletedsuccessfully,
                  'Style ProcessingPOM'
                );
                this.getStylePomProcessing();
                //this.processingForm = undefined;
                //this.sewnForm = undefined;
                //await this.getProcessingbyId();
              } else {
                ////console.log(response)
                this.logger.error(
                  response.body['item1'],
                  'Style ProcessingPOM'
                );
                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.logger.error(err.error.message, 'Style ProcessingPOM');

            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              this.logger.error(err.error.message, 'Style ProcessingPOM');

              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
            }
            this.showLoader = false;
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To add ProcessingSEWN properties on control
     Written by : Kiran Nagargoje
     Date: 12-Oct-2021 */
  public addProcessingSEWN({ sender }: AddEvent): void {
    this.sewnForm = new FormGroup({
      sewn: new FormControl({ value: 'SEWN', disabled: true }),
      pc: new FormControl(),
      poms: new FormControl('', Validators.required),
      originalLength: new FormControl('', Validators.required),
      originalWidth: new FormControl('', Validators.required),
      afterwashLength: new FormControl('', Validators.required),
      afterwashWidth: new FormControl('', Validators.required),
      afterdryLength: new FormControl('', Validators.required),
      afterdryWidth: new FormControl('', Validators.required),
      id: new FormControl(),
    });

    this.isSewNew = true;
    sender.addRow(this.sewnForm);
  }
  /*To add ProcessingSEWN properties on control
       Written by : Lasya Devathi
       Date: 27-Jun-2023 */
  addprocessingSEWN() {
    this.isprocessingsewnnew = true;
    this.isprocessingsewnedit = false;
    this.dialog.open(StyleprosewnComponent, {
      disableClose: true,
      data: { isprocessingsewnedit: this.isprocessingsewnedit },
    });
    this.dialog.afterAllClosed.subscribe((data) => this.getStyleSewnProcessing);
  }
  /*To save ProcessingSEWN details
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021 */
  public saveProcessingSEWN({ sender, rowIndex, formGroup, isNew }) {
    if (this.sewnForm.controls.pc.value.length > 100) {
      this.sewnForm.controls.pc.setErrors({ incorrect: true });
    }
    if (this.sewnForm.controls.poms.value.length > 100) {
      this.sewnForm.controls.poms.setErrors({ incorrect: true });
    }
    if (this.sewnForm.invalid) {
      this.showProcSewnNote = true;
      // this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields);
      // this.toastService.showError(
      //   this.appStaticMsgs.common.pleasechecktheinvalidfields
      // );
      this.toastService.showError('Please enter all the mandatory fields.');
      return;
    }
    this.showLoader = true;
    this.closeEditor(sender, rowIndex);
    const specification = formGroup.value;
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('pscount', '1');
    if (isNew) {
      formData.append(
        'pstag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
      );
    } else {
      formData.append('psid[0]', formGroup.value['id']);
      formData.append(
        'pstag[0]',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
      );
    }
    formData.append(
      'pc[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['pc']
      )
    );
    formData.append(
      'poms[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['poms']
      )
    );
    formData.append(
      'originallength[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['originalLength']
      )
    );
    formData.append(
      'originalwidth[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['originalWidth']
      )
    );
    formData.append(
      'afterwashlength[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['afterwashLength']
      )
    );
    formData.append(
      'afterwashwidth[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['afterwashWidth']
      )
    );
    formData.append(
      'afterdrylength[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['afterdryLength']
      )
    );
    formData.append(
      'afterdrywidth[0]',
      this.aesdecryptservice.encryptData(
        this.dataservice.Key,
        formGroup.value['afterdryWidth']
      )
    );

    this.showLoader = true;
    this.dataservice.addupdatedeleteStyleprocessing(formData).subscribe(
      async (response) => {
        this.showProcSewnNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.showLoader = false;
            // this.success(
            //   this.appStaticMsgs.styledetails.processingsewnsavesuccessfully
            // );
            this.toastService.showSuccess(
              this.appStaticMsgs.styledetails.processingsewnsavesuccessfully
            );

            this.logger.info(
              this.appStaticMsgs.styledetails.processingsewnsavesuccessfully,
              'Style Processing SEWN'
            );
            this.getStyleSewnProcessing();
            //this.processingForm = undefined;
            //this.sewnForm = undefined;
            //await this.getProcessingbyId();
          } else {
            this.logger.error(response.body['item1'], 'Style Processing SEWN');
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showProcSewnNote = false;
        this.logger.error(err.error.message, 'Style Processing SEWN');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style Processing SEWN');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
    sender.closeRow(rowIndex);
  }

  /*To edit ProcessingSEWN details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  // public editProcessSEWN({ sender, rowIndex, dataItem }) {
  //   this.closeEditor(sender, rowIndex);
  //   this.sewnForm = new FormGroup({
  //     'id': new FormControl(dataItem.id, Validators.required),
  //     'pc': new FormControl(dataItem.pc),
  //     'poms': new FormControl(dataItem.poms, Validators.required),
  //     'originalLength': new FormControl(dataItem.originalLength, Validators.required),
  //     'originalWidth': new FormControl(dataItem.originalWidth, Validators.required),
  //     'afterwashLength': new FormControl(dataItem.afterwashLength, Validators.required),
  //     'afterwashWidth': new FormControl(dataItem.afterwashWidth, Validators.required),
  //     'afterdryLength': new FormControl(dataItem.afterdryLength, Validators.required),
  //     'afterdryWidth': new FormControl(dataItem.afterdryWidth, Validators.required),
  //   });
  //   this.editedRowIndex = rowIndex;
  //   sender.editRow(rowIndex, this.sewnForm);
  // }

  /*To edit ProcessingPOM details
    Written by : Lasya Devathi
    Date: 27-jun-2023 */
  editProcessingsewn(rowIndex, dataItem) {
    console.log('editClick');
    this.isprocessingsewnnew = false;
    this.isprocessingsewnedit = true;
    console.log(dataItem, 'style');
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setprocessingsewnData(dataItem);
    this.processingsewndata = dataItem;
    console.log(dataItem, 'styles');
    console.log(dataItem.id);
    this.dialog.open(StyleprosewnComponent, {
      disableClose: true,
      data: {
        SewnData: dataItem,
        isprocessingsewnedit: this.isprocessingsewnedit,
      },
    });
    this.dialog.afterAllClosed.subscribe((data) =>
      this.getStyleSewnProcessing()
    );
  }
  /*To remove ProcessingSEWN details
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public removeProcessingSEWN({ dataItem }) {
    Swal.fire({
      title: 'Are you sure you want to delete this Proceesing SEWN?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('pstodelete', dataItem.id);
        formData.append('styleid', this.storageservice.getEditedStyleId());
        this.showLoader = true;
        this.dataservice.addupdatedeleteStyleprocessing(formData).subscribe(
          async (response) => {
            if (response.status === 200) {
              if (response.body['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(
                //   this.appStaticMsgs.styledetails
                //     .processingsewndeletedsuccessfully
                // );
                this.toastService.showSuccess(
                  this.appStaticMsgs.styledetails
                    .processingsewndeletedsuccessfully
                );

                this.logger.info(
                  this.appStaticMsgs.styledetails
                    .processingsewndeletedsuccessfully,
                  'Style Proceesing SEWN'
                );
                this.getStyleSewnProcessing();

                // this.filteredData = response.body['item1'];
                // this.ProcessingData = response.body['item1']['processingPOMs'];
                // this.sewnData = response.body['item1']['processingSewns'];
                //this.processingForm = undefined;
                //this.sewnForm = undefined;
                // await this.getProcessingbyId();
              } else {
                ////console.log(response)
                this.logger.error(
                  response.body['item1'],
                  'Style Proceesing SEWN'
                );

                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.showLoader = false;
              }
            }
          },
          (err) => {
            this.logger.error(err.error.message, 'Style Proceesing SEWN');

            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              this.logger.error(err.error.message, 'Style Proceesing SEWN');

              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
            }
            this.showLoader = false;
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To get on Page Change Bom data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  onPageChangeBom(e: PageChangeEvent): void {
    this.bomisPageChanged = true;
    this.bomgridState.skip = e.skip;
    this.bomgridState.take = e.take;
    this.bomstartCountList = e.skip;
    this.bomendCountList = e.skip + e.take;
    this.getBomDetails();
  }

  /*To get on Page Change Construction data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  onPageChangeConstruction(e: PageChangeEvent): void {
    this.constructionisPageChanged = true;
    this.constructiongridState.skip = e.skip;
    this.constructiongridState.take = e.take;
    this.constructionstartCountList = e.skip;
    this.constructionendCountList = e.skip + e.take;
    this.getConstructionbyId();
  }

  /*To get on Page Change Pattern data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  onPageChangePattern(e: PageChangeEvent): void {
    this.patternisPageChanged = true;
    this.patterngridState.skip = e.skip;
    this.patterngridState.take = e.take;
    this.patternstartCountList = e.skip;
    this.patternendCountList = e.skip + e.take;
    this.getPatternbyId();
  }

  /*To get on Page Change Specification Poms data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  onPageChangeSpecificationPoms(e: PageChangeEvent): void {
    this.specificationPomsisPageChanged = true;
    this.specificationPomsgridState.skip = e.skip;
    this.specificationPomsgridState.take = e.take;
    this.specificationPomsstartCountList = e.skip;
    this.specificationPomsendCountList = e.skip + e.take;
    this.getStylePomSpecifications();
  }

  /*To get on Page Change Specification Stitche data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  onPageChangeSpecificationStitche(e: PageChangeEvent): void {
    this.specificationStitcheisPageChanged = true;
    this.specificationStitchegridState.skip = e.skip;
    this.specificationStitchegridState.take = e.take;
    this.specificationStitchestartCountList = e.skip;
    this.specificationStitcheendCountList = e.skip + e.take;
    this.getStylesStitcheSpecifications();
  }

  /*To get on Page Change Processing Poms data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  onPageChangeProcessingPoms(e: PageChangeEvent): void {
    this.processingPomsisPageChanged = true;
    this.processingPomsgridState.skip = e.skip;
    this.processingPomsgridState.take = e.take;
    this.processingPomsstartCountList = e.skip;
    this.processingPomsendCountList = e.skip + e.take;
    this.getStylePomProcessing();
  }

  /*To get on Page Change Processing Sewn data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  onPageChangeProcessingSewn(e: PageChangeEvent): void {
    this.processingSewnisPageChanged = true;
    this.processingSewngridState.skip = e.skip;
    this.processingSewngridState.take = e.take;
    this.processingSewnstartCountList = e.skip;
    this.processingSewnendCountList = e.skip + e.take;
    this.getStyleSewnProcessing();
  }

  /*To get pattern data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public async getPatternbyId() {
    this.patternloading = true;
    this.patternstartEndCount =
      this.patternstartCountList + '-' + this.patternendCountList;

    this.dataservice
      .getstylepatterns(
        this.storageservice.getEditedStyleId(),
        this.patternstartEndCount
      )
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.logger.info('getstylepatterns() method call', 'Style Pattern');
            this.patternloading = false;
            this.filteredData = response.body['item1']['patterns'];
            this.patternRecordCount = response.body['item3'];

            if (response.body['item1']['patterns']) {
              for (let i = 0; i < this.filteredData.length; i++) {
                this.filteredData[i].allocation =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i].allocation
                  );
                this.filteredData[i].cut = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  this.filteredData[i].cut
                );
                this.filteredData[i].description =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i].description
                  );
                this.filteredData[i].pc = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  this.filteredData[i].pc
                );
                //this.filteredData[i].pc = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].pc);
                if (this.filteredData[i].image) {
                  this.filteredData[i].image.thumbnail =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      this.filteredData[i].image.thumbnail
                    );
                }
              }
              var gridView = {
                data: this.filteredData,
                total: this.patternRecordCount,
              };
              this.patternData = gridView;
            } else {
              this.patternData = null;
            }
            console.log('this.filteredData CAD ', this.filteredData);
          } else {
            this.logger.error(response.body['item1'], 'Style Pattern');

            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.patternloading = false;
          }
        },
        (err) => {
          this.logger.error(err.error.message, 'Style Pattern');

          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.patternloading = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            // this.patternloading = false;
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.patternloading = false;
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else {
            this.logger.error(err.error.message, 'Style Pattern');

            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
          this.patternloading = false;
        }
      );
  }

  /*To get Construction data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public async getConstructionbyId() {
    this.constructionloading = true;
    this.constructionstartEndCount =
      this.constructionstartCountList + '-' + this.constructionendCountList;
    this.dataservice
      .getstyleConstruction(
        this.storageservice.getEditedStyleId(),
        this.constructionstartEndCount
      )
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.logger.info(
              this.appStaticMsgs.styledetails
                .styleconstructiondataloadedsuccessfully,
              'Style Construction'
            );
            this.constructionloading = false;
            this.filteredData = response.body['item1']['styleConstructions'];
            this.constructionRecordCount = response.body['item3'];
            if (response.body['item1']['styleConstructions']) {
              for (let i = 0; i < this.filteredData.length; i++) {
                this.filteredData[i].operation =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i].operation
                  );
                this.filteredData[i].mC_Type =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.filteredData[i].mC_Type
                  );
                if (this.filteredData[i].image) {
                  this.filteredData[i].image.thumbnail =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      this.filteredData[i].image.thumbnail
                    );
                }
                this.filteredData[i].smv = this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  this.filteredData[i].smv
                );
              }

              var gridView = {
                data: this.filteredData,
                total: this.constructionRecordCount,
              };
              this.ConstructionData = gridView;
            } else {
              this.ConstructionData = null;
            }
            //this.constructionForm = undefined;
          } else {
            this.constructionloading = false;
            this.logger.error(response.body['item1'], 'Style Construction');

            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
          }
        },
        (err) => {
          this.logger.error(err.error.message, 'Style Construction');
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.constructionloading = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            // this.constructionloading = false;
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
            this.constructionloading = false;
          } else {
            this.logger.error(err.error.message, 'Style Construction');
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
          this.constructionloading = false;
        }
      );
  }

  /*To get Specification data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public async getSpecificationbyId() {
    await this.getStylePomSpecifications();
    await this.getStylesStitcheSpecifications();
  }
  numberOnly(e: any) {
    var regex = new RegExp('^[0-9]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  /*To get Style Pom Specifications data
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021 */
  public async getStylePomSpecifications() {
    this.pomdata = [];
    if (typeof this.f.sizeRange.value === 'string') {
      this.f?.sizeRange?.value.split(',').forEach((x) => {
        this.pomdata.push(x);
      });
    } else {
      this.pomdata = this.f.sizeRange.value;
    }
    this.pomdata.sort((a, b) => {
      // Move items with size 'xs' to the first place
      if (a === 'XS' && b !== 'XS') {
        return -1;
      } else if (a !== 'XS' && b === 'XS') {
        return 1;
      } else {
        // For other sizes or when both items have size 'xs', maintain the original order
        return 0;
      }
    });
    console.log('this.f.sizeRange.value', this.f.sizeRange.value);
    //this.pomdata= this.f.sizeRange.value;

    let bb = ['tolerance', 'description', 'poms'];
    bb.forEach((y) => {
      const index = this.pomdata.indexOf(y);
      if (index > -1) {
        // only splice array when item is found
        this.pomdata.splice(index, 1); // 2nd parameter means remove one item only
      }
    });

    console.log('this.f.sizeRange.value 1', bb);

    // bb.forEach(y => {
    //   this.pomdata.push(y);
    // });
    bb.forEach((y) => {
      this.pomdata.unshift(y);
    });
    //this.pomdata=this.pomdata.split(',');
    // this.pomdata.map((x,i)=>{
    //   console.log(x,x);
    //   // this.pomdata='tolerance'
    // })

    console.log('pomdata', this.pomdata);
    this.specificationPomsloading = true;
    this.specificationPomsstartEndCount =
      this.specificationPomsstartCountList +
      '-' +
      this.specificationPomsendCountList;
    this.dataservice
      .getStylePomSpecifications(
        this.storageservice.getEditedStyleId(),
        this.specificationPomsstartEndCount
      )
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.specificationPomsloading = false;

            this.logger.info(
              this.appStaticMsgs.styledetails
                .allstylespecifictionpomsloadedsuccessfully,
              'Style PomSpecifications'
            );
            // this.toastService.showSuccess('Added Successfully');
            this.dbspecificationPoms = response.body['item1']['stylePOMs'];
            this.specificationPomsRecordCount = response.body['item3'];
            if (response.body['item1']['stylePOMs']) {
              console.log(
                'getStylePomSpecifications',
                this.dbspecificationPoms
              );

              for (let i = 0; i < this.dbspecificationPoms.length; i++) {
                this.dbspecificationPoms[i].poms =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbspecificationPoms[i].poms
                  );
                this.dbspecificationPoms[i].tolerance =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbspecificationPoms[i].tolerance
                  );
                // this.dbspecificationPoms[i].name =
                //   this.dbspecificationPoms[i].pomName;
                this.unfilteredData.forEach((x) => {
                  this.dbspecificationPoms[i][x.columnName] =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      this.dbspecificationPoms[i][x.columnName]
                    );
                });
              }

              var gridView = {
                data: this.dbspecificationPoms,
                total: this.specificationPomsRecordCount,
              };

              this.specificationPoms = gridView;
              console.log('nnnnnnnnnnn 8812', this.specificationPoms);

              // if (this.savePOMSTemplate?.length > 0) {
              //   this.savePOMSTemplate.forEach((item: any) => {
              //     this.specificationPoms.data.push(item);
              //   });
              // }
              // if (this.savePOMSAITemplate?.length > 0) {
              //   this.savePOMSAITemplate.forEach((item: any) => {
              //     this.specificationPoms.data.push(item);
              //   });
              // }
              // this.addNewPOMS = false;

              // this.specificationPOMSAI = this.specificationPoms;
              // this.getPOMSList = this.specificationPoms?.data?.length;
            } else {
              this.specificationPoms = null;
              // this.specificationPOMSAI = [];
              // this.getPOMSList = 0;
              // this.addNewPOMS = false;
            }
            //this.specificationForm = undefined;
            //this.StichesForm = undefined;
          } else {
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(
              response.body['item1'],
              'Style PomSpecifications'
            );
            this.specificationPomsloading = false;
          }
        },
        (err) => {
          this.logger.error(err.error.message, 'Style PomSpecifications');
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.specificationPomsloading = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            // this.specificationPomsloading = false;
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
            this.specificationPomsloading = false;
          } else {
            this.logger.error(err.error.message, 'Style PomSpecifications');
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
          this.specificationPomsloading = false;
        }
      );
  }
  selectedItem: any;
  selectedPomTemplate(event: any, POMDROPDOWN: any) {
    this.selctedPOMDescription = [];
    this.selectedPOMTemplateList = [];
    const selcetdPOM = event.target.value;
    const extractedValue = selcetdPOM.split(':');
    this.showLoader = true;
    this.dataservice.getPOMTemplatesList(extractedValue[0]).subscribe(
      (res: any) => {
        if (res?.body?.item2 == 'Success') {
          this.showLoader = false;
          if (
            res.body['item1'] !== null &&
            res.body['item1'].length !== 0 &&
            res.body['item1'] !== undefined
          ) {
            console.log(res.body['item1']);
            // this.showLoader = false;
            for (let i = 0; i < res.body['item1'].length; i++) {
              res.body['item1'][i].template =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  res.body['item1'][i].template
                );
              res.body['item1'][i].pomName = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                res.body['item1'][i].pomName
              );
              res.body['item1'][i].pomId = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                res.body['item1'][i].pomId
              );
              res.body['item1'][i].pomDescription =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  res.body['item1'][i].pomDescription
                );
              if (res.body['item1'][i].template == selcetdPOM) {
                this.selctedPOMDescription.push(res.body['item1'][i]);
              }
            }
            if (this.selctedPOMDescription.length !== 0) {
              for (let i = 0; i < this.selctedPOMDescription.length; i++) {
                this.selectedPOMTemplateList.push({
                  '2xl': null,
                  '3xl': null,
                  '4xl': null,
                  '6xl': null,
                  name: this.selctedPOMDescription[i].pomName,
                  description: this.selctedPOMDescription[i].pomDescription,
                  poms: this.selctedPOMDescription[i].pomId,
                  doublexl: null,
                  eight: '',
                  eighteen: '',
                  eightos: '',
                  four: '',
                  fourteen: '',
                  fourxl: null,
                  l: '',
                  m: '',
                  one: '',
                  os: '',
                  s: '',
                  six: '',
                  sixteen: '',
                  sixxl: null,
                  status: null,
                  style: null,
                  ten: '',
                  tenos: '',
                  threeplexl: null,
                  tolerance: '',
                  twelve: '',
                  twelveos: '',
                  twenty: '',
                  twentyfour: '',
                  twentytwo: '',
                  two: '',
                  xl: '',
                  xs: '',
                });
              }
              let ngbModalOptions: NgbModalOptions = {
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
              };

              this.pomModal = this.modalService.open(
                POMDROPDOWN,
                ngbModalOptions
              );
            }
            console.log(this.selectedPOMTemplateList);
          } else {
            this.toastService.showError(
              'Sorry, we currently do not have the measurements for the selected garment. Please check back later.'
            );
          }
        } else {
          this.showLoader = false;
        }
      },
      (err: Error) => {
        this.showLoader = false;
      }
    );
  }

  capitalizeFirstLetter(value: string): string {
    const lowerCaseValue = value?.toLowerCase();
    switch (lowerCaseValue) {
      case 'poms':
        return 'ID';
      case 'description':
        return 'Description';
      // case 'name':
      //   return 'POM Name';
      default:
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }

  POMInputValidation(event: any, item) {
    console.log(item, 'nnnnnnnnnnn');
    const trimmedItem = item?.replace(/\s/g, '')?.toLowerCase();
    if (
      trimmedItem === 'description' ||
      trimmedItem === 'poms'
      // ||
      // trimmedItem === 'name'
    ) {
      return;
    }
    const inputChar = String.fromCharCode(event.charCode);
    // Check if the entered character is a digit or a dot
    if (!/^[0-9.]+$/.test(inputChar)) {
      event.preventDefault();
    }
    // Check if the entered value is a valid positive number
    const inputValue = event.target.value + inputChar;
    if (!/^\d*\.?\d*$/.test(inputValue) || parseFloat(inputValue) < 0) {
      event.preventDefault();
    }
  }

  /*To get Styles Stitche Specifications data
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021 */

  public async getStylesStitcheSpecifications() {
    this.specificationStitcheloading = true;
    this.specificationStitchestartEndCount =
      this.specificationStitchestartCountList +
      '-' +
      this.specificationStitcheendCountList;
    this.dataservice
      .getStyleStitcheSpecifications(
        this.storageservice.getEditedStyleId(),
        this.specificationStitchestartEndCount
      )
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.specificationStitcheloading = false;
            this.logger.info(
              this.appStaticMsgs.styledetails
                .allstichesspecificationloadedsuccessfully,
              'Style StitcheSpecifications'
            );
            this.dbstichesData = response.body['item1']['styleStitchess'];
            this.specificationStitcheRecordCount = response.body['item3'];
            console.log(
              'stitch data',
              response.body['item1']['styleStitchess']
            );
            if (response.body['item1']['styleStitchess']) {
              for (let i = 0; i < this.dbstichesData.length; i++) {
                this.dbstichesData[i].placement =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbstichesData[i].placement
                  );
                this.dbstichesData[i].notes =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbstichesData[i].notes
                  );
                this.dbstichesData[i].stitch.name =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbstichesData[i].stitch.name
                  );
                if (this.dbstichesData[i].stitch.topViewFile) {
                  this.dbstichesData[i].stitch.topViewFile.thumbnail =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      this.dbstichesData[i].stitch.topViewFile.thumbnail
                    );
                  this.dbstichesData[i].stitch.topViewFile.name =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      this.dbstichesData[i].stitch.topViewFile.name
                    );
                } else {
                  this.dbstichesData[i].stitch.topViewFile = null;
                }
              }

              var gridView = {
                data: this.dbstichesData,
                total: this.specificationStitcheRecordCount,
              };

              this.stichesData = gridView;
            } else {
              this.stichesData = null;
            }
            //this.specificationForm = undefined;
            //this.StichesForm = undefined;
          } else {
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(
              response.body['item1'],
              'Style StitcheSpecifications'
            );
            this.specificationStitcheloading = false;
          }
        },
        (err) => {
          this.logger.error(err.error.message, 'Style StitcheSpecifications');
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.specificationStitcheloading = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            // this.specificationStitcheloading = false;
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
            this.specificationStitcheloading = false;
          } else {
            this.logger.error(err.error.message, 'Style StitcheSpecifications');
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
          this.specificationStitcheloading = false;
        }
      );
  }

  /*To get Processing data
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021 */
  public async getProcessingbyId() {
    await this.getStylePomProcessing();
    await this.getStyleSewnProcessing();
  }

  /*To get Style Pom Processing data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public async getStylePomProcessing() {
    this.processingPomsloading = true;
    this.processingPomsstartEndCount =
      this.processingPomsstartCountList + '-' + this.processingPomsendCountList;
    this.dataservice
      .getStylePomProcessing(
        this.storageservice.getEditedStyleId(),
        this.processingPomsstartEndCount
      )
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.processingPomsloading = false;
            this.logger.info(
              this.appStaticMsgs.styledetails
                .allprocessingpomloadedsuccessfully,
              'Style PomProcessing'
            );

            this.dbprocessingPoms = response.body['item1']['processingPOMs'];
            this.processingPomsRecordCount = response.body['item3'];
            if (response.body['item1']['processingPOMs']) {
              for (let i = 0; i < this.dbprocessingPoms.length; i++) {
                this.dbprocessingPoms[i]['pom'] = 'POM';
                this.dbprocessingPoms[i].pc =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingPoms[i].pc
                  );
                this.dbprocessingPoms[i].poms =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingPoms[i].poms
                  );
                this.dbprocessingPoms[i].originalLength =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingPoms[i].originalLength
                  );
                this.dbprocessingPoms[i].originalWidth =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingPoms[i].originalWidth
                  );
                this.dbprocessingPoms[i].afterwashLength =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingPoms[i].afterwashLength
                  );
                this.dbprocessingPoms[i].afterwashWidth =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingPoms[i].afterwashWidth
                  );
                this.dbprocessingPoms[i].afterdryLength =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingPoms[i].afterdryLength
                  );
                this.dbprocessingPoms[i].afterdryWidth =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingPoms[i].afterdryWidth
                  );
              }

              var gridView = {
                data: this.dbprocessingPoms,
                total: this.processingPomsRecordCount,
              };

              this.processingPoms = gridView;
            } else {
              this.processingPoms = null;
            }
            //this.processingForm = undefined;
            //this.sewnForm = undefined;
          } else {
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], 'Style PomProcessing');
            this.processingPomsloading = false;
          }
        },
        (err) => {
          this.logger.error(err.error.message, 'Style PomProcessing');
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.processingPomsloading = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            // this.processingPomsloading = false;
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
            this.processingPomsloading = false;
          } else {
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
            this.logger.error(err.error.message, 'Style PomProcessing');
          }
          this.processingPomsloading = false;
        }
      );
  }

  /*To get Style Sewn Processing data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public async getStyleSewnProcessing() {
    this.processingSewnloading = true;
    this.processingSewnstartEndCount =
      this.processingSewnstartCountList + '-' + this.processingSewnendCountList;
    this.dataservice
      .getStyleSewnProcessing(
        this.storageservice.getEditedStyleId(),
        this.processingSewnstartEndCount
      )
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.processingSewnloading = false;
            this.filteredData = response.body['item1'];
            this.logger.info(
              this.appStaticMsgs.styledetails
                .allprocessingsewnloadedsuccessfully,
              'Style SewnProcessing'
            );
            this.dbprocessingSewn = response.body['item1']['processingSewns'];
            this.processingSewnRecordCount = response.body['item3'];
            if (response.body['item1']['processingSewns']) {
              for (let i = 0; i < this.dbprocessingSewn.length; i++) {
                this.dbprocessingSewn[i]['sewn'] = 'SEWN';
                this.dbprocessingSewn[i].pc =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingSewn[i].pc
                  );
                this.dbprocessingSewn[i].poms =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingSewn[i].poms
                  );
                this.dbprocessingSewn[i].originalLength =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingSewn[i].originalLength
                  );
                this.dbprocessingSewn[i].originalWidth =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingSewn[i].originalWidth
                  );
                this.dbprocessingSewn[i].afterwashLength =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingSewn[i].afterwashLength
                  );
                this.dbprocessingSewn[i].afterwashWidth =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingSewn[i].afterwashWidth
                  );
                this.dbprocessingSewn[i].afterdryLength =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingSewn[i].afterdryLength
                  );
                this.dbprocessingSewn[i].afterdryWidth =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    this.dbprocessingSewn[i].afterdryWidth
                  );
              }

              var gridView = {
                data: this.dbprocessingSewn,
                total: this.processingSewnRecordCount,
              };
              this.processingSewn = gridView;
            } else {
              this.processingSewn = null;
            }
            //this.processingForm = undefined;
            //this.sewnForm = undefined;
          } else {
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], 'Style SewnProcessing');
            this.processingSewnloading = false;
          }
        },
        (err) => {
          this.logger.error(err.error.message, 'Style SewnProcessing');
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.processingSewnloading = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            // this.processingSewnloading = false;
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
            this.processingSewnloading = false;
          } else {
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
            this.logger.error(err.error.message, 'Style SewnProcessing');
          }
          this.processingSewnloading = false;
        }
      );
  }

  private closeEditor(
    grid: GridComponent,
    rowIndex: number = this.editedRowIndex
  ): void {
    this.isNew = false;
    this.isConNew = false;
    this.isPatNew = false;
    this.isSpeNew = false;
    this.isStiNew = false;
    this.isProNew = false;
    this.isSewNew = false;
    console.log('rowIndex', this.editedRowIndex, rowIndex);
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  /*To cancel edit grid row
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  public cancelHandler({ sender, rowIndex }, str) {
    switch (str) {
      case 'bomDetailsTab':
        this.isNew = false;
        this.showBomNote = false;
        break;
      case 'constructionTab':
        this.isConNew = false;
        this.showConstructionNote = false;
        break;
      case 'patternTab':
        this.isPatNew = false;
        this.showPatternNote = false;
        break;
      case 'specificationTab':
        this.isSpeNew = false;
        this.showSpecPomNote = false;
        break;
      case 'StichesForm':
        this.isStiNew = false;
        this.showSpecStitcheNote = false;
        break;
      case 'processingTab':
        this.isProNew = false;
        this.showProcPomNote = false;
        break;
      case 'sewnForm':
        this.isSewNew = false;
        this.showProcSewnNote = false;
        break;
      default:
        ////console.log("No such data exists!");
        break;
    }
    sender.closeRow(rowIndex);
  }

  /*To export excel sheet for generate techpack for style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  exportTechPackReport() {
    this.showLoader = true;
    ////console.log(this.storageservice.getEditedStyleId())/
    this.dataservice
      .GetStyleByID(this.storageservice.getEditedStyleId())
      .subscribe(
        (response) => {
          if (response.body['item2'] === 'Success') {
            this.logger.info(
              response.body['item1'][0]['styleName'] + ' loaded successfully',
              'Style Details'
            );
            ////console.log(JSON.stringify(this.editedStyleData));
            ////console.log(JSON.stringify(response.body['item1']));

            for (let i = 0; i < response.body['item1'].length; i++) {
              response.body['item1'][i].styleName =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].styleName
                );
              response.body['item1'][i].newStyleNum =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].newStyleNum
                );
              response.body['item1'][i].styleNumber =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].styleNumber
                );
              response.body['item1'][i].category =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].category
                );
              response.body['item1'][i].subCategory =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].subCategory
                );
              response.body['item1'][i].targetGender =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].targetGender
                );

              this.styleForm
                .get('garmentType')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i]['garmentType']
                  )
                );

              this.styleForm
                .get('silhouette')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i]['silhouette']
                  )
                );

              this.styleForm
                .get('neckType')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i]['neckType']
                  )
                );

              this.styleForm
                .get('sleeveType')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i]['sleeveType']
                  )
                );

              this.styleForm
                .get('sleevesLength')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i]['sleeveLength']
                  )
                );

              this.styleForm
                .get('length')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i]['length']
                  )
                );

              this.styleForm
                .get('fitting')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i]['fitting']
                  )
                );

              this.styleForm
                .get('color')
                .setValue(
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i]['colour']
                  )
                );

              response.body['item1'][i].ageGroup =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].ageGroup
                );
              //response.body['item1'][i].sampleSize = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].sampleSize);
              response.body['item1'][i].sizeRange =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].sizeRange
                );
              response.body['item1'][i].sizeChart =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].sizeChart
                );
              response.body['item1'][i].fabric =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].fabric
                );
              response.body['item1'][i].description =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].description
                );
              response.body['item1'][i].status =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].status
                );
            }

            this.editedStyleData = response.body['item1'];
            this.storeExcelData();
            setTimeout(() => {
              this.exportExcel.exportTechPackReport(this.exportData);
              this.showLoader = false;
            }, 5000);
          } else {
            this.showLoader = false;
            this.logger.error(response.body['item1'], 'Style Details');
          }
        },
        (err) => {
          ////console.log(err)
          this.logger.error(err.error.message, 'Style Details');
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
            this.showLoader = false;
          } else if (err.error.message === 'The incoming token has expired') {
            // this.showLoader = false;
            // this.sessionTimedOut();
            // this.userservice.logout();
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            this.showLoader = false;
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else {
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
        }
      );
  }

  /*To store style data for generate techpack
   Written by : Kiran Nagargoje
   Date: 12-Oct-2021 */
  async storeExcelData() {
    var styleData = [];
    styleData.push(this.editedStyleData);
    if (styleData) {
      if (styleData[0][0] == undefined) {
        this.exportData = [];
        this.exportData.push(styleData[0]);
      } else {
        this.exportData = [];
        this.exportData.push(styleData[0][0]);
      }

      var styleImages = this.exportData[0]['styleImages'];
      var styleStitchess = this.exportData[0]['styleStitchess'];
      var styleConstructions = this.exportData[0]['styleConstructions'];

      if (styleImages) {
        await this.callStyleImages(styleImages);
      }
      if (styleStitchess) {
        await this.callStichesdetails(styleStitchess);
      }
      if (styleConstructions) {
        await this.callStyleConstructions(styleConstructions);
      }
    }
  }

  /*To convert Base64 Image From style images Image URL
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async callStyleImages(StyleImages) {
    console.log('Style-Image Called');
    StyleImages.forEach(async (StyleImage, imgi) => {
      StyleImage.imageType = this.aesdecryptservice.decryptData(
        this.dataservice.Key,
        StyleImage.imageType
      );
      if (StyleImage.imageType == 'coverpage') {
        StyleImage['image']['thumbnail'] = this.aesdecryptservice.decryptData(
          this.dataservice.Key,
          StyleImage['image']['thumbnail']
        );
        let thumbnail = StyleImage['image']['thumbnail'];
        console.log('thumbnailthumbnail', thumbnail);
        if (thumbnail) {
          await this.getBase64ImageFromURL(thumbnail).subscribe(
            (base64Data: string) => {
              StyleImage['image']['base64URL'] = base64Data;
            }
          );
        }
      } else {
        StyleImage['image']['thumbnail'] = this.aesdecryptservice.decryptData(
          this.dataservice.Key,
          StyleImage['image']['thumbnail']
        );
        let thumbnail = StyleImage['image']['thumbnail'];
        console.log('thumbnailthumbnail', thumbnail);
        if (thumbnail) {
          await this.getBase64ImageFromURL(thumbnail).subscribe(
            (base64Data: string) => {
              StyleImage['image']['base64URL'] = base64Data;
            }
          );
        }
      }
    });

    console.log('StyleImageStyleImageStyleImage', StyleImages);
  }

  /*To convert Base64 Image From style Stiches Image URL
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async callStichesdetails(stichesdetails) {
    stichesdetails.forEach((stiches, imgi) => {
      //console.log('stiches',stiches);
      if (stiches['stitch']['topViewFile']) {
        stiches['stitch']['topViewFile']['thumbnail'] =
          this.aesdecryptservice.decryptData(
            this.dataservice.Key,
            stiches['stitch']['topViewFile']['thumbnail']
          );
        let thumbnail = stiches['stitch']['topViewFile']['thumbnail'];
        if (thumbnail) {
          this.getBase64ImageFromURL(thumbnail).subscribe(
            (base64Data: string) => {
              stiches['stitch']['topViewFile']['base64URL'] = base64Data;
            }
          );
        }
      }
    });
  }

  /*To convert Base64 Image From style Constructions Image URL
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async callStyleConstructions(StyleConstructions) {
    StyleConstructions.forEach((Constructions, imgi) => {
      if (Constructions['image']) {
        Constructions['image']['thumbnail'] =
          this.aesdecryptservice.decryptData(
            this.dataservice.Key,
            Constructions['image']['thumbnail']
          );
        let thumbnail = Constructions['image']['thumbnail'];
        if (thumbnail) {
          this.getBase64ImageFromURL(thumbnail).subscribe(
            (base64Data: string) => {
              Constructions['image']['base64URL'] = base64Data;
            }
          );
        }
      }
    });
  }

  /*Common Method To convert Base64 Image From any URL
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getBase64ImageFromURL(url: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  /* Method to create base64Data Url from fetched image */
  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    var canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL: string = canvas.toDataURL('image/png');
    //this.imgResult = dataURL;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  /*To get all user for share style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getAllUsers() {
    // this.showLoader = true;
    let UserData = JSON.parse(localStorage.getItem('userFullName'));
    this.dataservice.getallUsers().subscribe(
      (response) => {
        if (response.body) {
          this.showLoader = false;
          this.logger.info(
            this.appStaticMsgs.styledetails.loadalluserdetails,
            'Style Details'
          );
          var data = response.body['item1'];
          this.itemList = data.filter(
            (x) =>
              this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.eMail
              ) !== UserData['eMail']
          );

          // for (let i = 0; i < data.length; i++) {
          //   data[i].eMail = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].eMail);
          //   data[i].firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].firstName);
          //   data[i].lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].lastName);
          //   data[i].fullName = data[i].firstName + " " + data[i].lastName;
          //   if (data[i].picture) {
          //     data[i].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].picture.thumbnail);
          //     data[i].picture.name = this.aesdecryptservice.decryptData(this.dataservice.Key, data[i].picture.name);
          //   }
          // }

          this.itemList.forEach((x) => {
            x['id'] = x.id;
            x['firstName'] = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              x.firstName
            );
            x['lastName'] = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              x.lastName
            );
            x['eMail'] = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              x.eMail
            );
            x['itemName'] =
              x['firstName'] + ' ' + x['lastName'] + ' - ' + x['eMail'];
            if (x.picture) {
              x['image'] = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.picture.thumbnail
              );
            }
          });

          this.dropdownList = this.itemList;

          console.log('USER DATA', this.itemList);
        } else {
          // this.error(response.body['item1']);
          this.toastService.showError(response.body['item1']);
          this.logger.error(response.body['item1'], 'Style Details');
          this.showLoader = false;
        }
      },
      (err) => {
        this.logger.error(err.error.message, 'Style Details');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
          this.showLoader = false;
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.showLoader = false;
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
          this.showLoader = false;
        } else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          this.logger.error(err.error.message, 'Style Details');
        }
        this.showLoader = false;
      }
    );
  }

  /*To add new row for new user in share style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  addRow() {
    this.isedit[this.fieldArray.length] = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);

    var fields = {
      edit: false,
      view: true,
      id: '',
      sharedTo: '',
      user: '',
    };
    this.fieldArray.push(fields);
  }

  /*To Remove user from shared style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  removeRow(index: number) {
    Swal.fire({
      title: 'Are you sure you want to delete this shared data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        if (this.fieldArray[index].id) {
          const formData = new FormData();
          formData.append('deleteids', this.fieldArray[index].id);
          formData.append('recordid', this.storageservice.getEditedStyleId());
          formData.append(
            'module',
            this.aesdecryptservice.encryptData(this.dataservice.Key, 'Styles')
          );
          formData.append('styleid', this.storageservice.getEditedStyleId());
          this.fieldArray.splice(index, 1);
          this.showLoader = true;
          this.dataservice.addupdatesharedaccess(formData).subscribe(
            (response) => {
              if (response.status === 200) {
                if (response.body['item2'] === 'Success') {
                  this.logger.info(
                    this.appStaticMsgs.styledetails
                      .sharedstyledeletedsuccessfully,
                    'Style Details'
                  );
                  // this.success(
                  //   this.appStaticMsgs.styledetails
                  //     .sharedstyledeletedsuccessfully
                  // );
                  this.toastService.showSuccess('Delete Successfully');

                  this.sharedUsers = [];
                  for (let i = 0; i < response.body['item1'].length; i++) {
                    response.body['item1'][i].module =
                      this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        response.body['item1'][i].module
                      );
                    response.body['item1'][i].org =
                      this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        response.body['item1'][i].module
                      );
                    for (
                      let j = 0;
                      j < response.body['item1'][i].user.length;
                      j++
                    ) {
                      response.body['item1'][i].user[j].firstName =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          response.body['item1'][i].user[j].firstName
                        );
                      response.body['item1'][i].user[j].lastName =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          response.body['item1'][i].user[j].lastName
                        );
                      response.body['item1'][i].user[j].about =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          response.body['item1'][i].user[j].about
                        );
                      response.body['item1'][i].user[j].eMail =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          response.body['item1'][i].user[j].eMail
                        );
                      response.body['item1'][i].user[j].group =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          response.body['item1'][i].user[j].group
                        );
                      response.body['item1'][i].user[j].org =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          response.body['item1'][i].user[j].org
                        );
                      response.body['item1'][i].user[j].fullName =
                        response.body['item1'][i].user[j].firstName +
                        ' ' +
                        response.body['item1'][i].user[j].lastName;
                      if (response.body['item1'][i].user[j].picture) {
                        response.body['item1'][i].user[j].picture.thumbnail =
                          this.aesdecryptservice.decryptData(
                            this.dataservice.Key,
                            response.body['item1'][i].user[j].picture.thumbnail
                          );
                        response.body['item1'][i].user[j].picture.name =
                          this.aesdecryptservice.decryptData(
                            this.dataservice.Key,
                            response.body['item1'][i].user[j].picture.name
                          );
                      }
                      var isOnline = false;
                      if (this.onlineUsers.length > 0) {
                        if (
                          this.onlineUsers.indexOf(
                            response.body['item1'][i].user[j].eMail
                          ) !== -1
                        ) {
                          isOnline = true;
                        }
                      }
                      let item = {
                        user: response.body['item1'][i].user,
                        isOnline: isOnline,
                      };
                      this.sharedUsers.push(item);
                    }
                  }
                  this.showLoader = false;
                  this.submitted = false;
                } else {
                  ////console.log(response)
                  //this.alert = 'danger';
                  //this.errorDiv = true;
                  // this.errors = response.body['item2'];
                  // this.error(response.body['item1']);
                  this.toastService.showError(response.body['item1']);
                  this.showLoader = false;
                }
              }
            },
            (err) => {
              //this.alert = 'danger';
              //this.errorDiv = true;
              //this.errors = err.message;
              this.logger.error(err.error.message, 'Style Details');
              if (
                err.error.message ===
                'Network error communicating with endpoint'
              ) {
                // this.error(err.error.message);
                this.toastService.showError(
                  'Oops!! Something went wrong. Please try again.'
                );
              } else if (
                err.error.message === 'The incoming token has expired'
              ) {
                // this.sessionTimedOut();
                // this.userservice.logout();
                this.toastService.showError(
                  'The token being used has expired. Please login again.'
                );
                this.sessionTimedOut();
                this.userservice.logout();
              } else if (err.error.message === 'Unauthorized') {
                // this.userservice.error();
                this.toastService.showError(
                  "You don't have permission to access this resource. Please login again."
                );
                this.sessionTimedOut();
                this.userservice.logout();
              } else {
                this.logger.error(err.error.message, 'Style Details');

                // this.error(err.message);
                this.toastService.showError(
                  this.appStaticMsgs.unResponsive.error
                );
              }
              this.showLoader = false;
            }
          );
        } else {
          this.fieldArray.splice(index, 1);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  /*To check existing user is added for shared style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  checkValue(event) {
    if (!event.id) return;
    var data = this.editedSharedData.filter((x) => x.id == event.id);
    if (data.length > 0) {
      let index = this.editedSharedData.findIndex((x) => x.id == event.id);
      this.editedSharedData[index] = event;
    } else {
      this.editedSharedData.push(event);
    }
  }

  /*To save shared Style data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  saveShareStyle() {
    const formData = new FormData();
    console.log('save button clicked', formData);
    //this.selectedItems = this.selectedItems.filter(x => x.id == "");
    formData.append('recordid', this.storageservice.getEditedStyleId());
    formData.append(
      'module',
      this.aesdecryptservice.encryptData(this.dataservice.Key, 'Styles')
    );
    formData.append(
      'recordscount',
      (this.modifiedData.length + this.selectedItems.length).toString()
    );
    //console.log('this.modifiedData',this.modifiedData)
    if (this.modifiedData.length > 0) {
      this.modifiedData.forEach((x) => {
        let a = {};
        a['id'] = x.userID;
        a['sharedaccessid'] = x.id;
        a['view'] = x.view;
        a['edit'] = x.edit;
        this.selectedItems.push(a);
      });
      console.log('modified length greater than 0');
    }
    if (this.selectedItems.length > 0) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i]?.sharedaccessid) {
          formData.append(
            'tag' + '[' + i + ']',
            this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update')
          );
          formData.append(
            'sharedaccessid' + '[' + i + ']',
            this.selectedItems[i].sharedaccessid
          );
        } else {
          formData.append(
            'tag' + '[' + i + ']',
            this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
          );
        }
        formData.append('sharedto' + '[' + i + ']', this.selectedItems[i].id);
        formData.append('view' + '[' + i + ']', this.selectedItems[i].view);
        formData.append('edit' + '[' + i + ']', this.selectedItems[i].edit);
      }
      console.log(
        'selected length is greater than 0',
        this.modalService.dismissAll()
      );
      this.addupdateSharedData(formData);
    }

    // if(this.modifiedData.length > 0){
    //   for (let i = 0; i < this.modifiedData.length; i++) {
    //     formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
    //     formData.append('sharedaccessid' + '[' + i + ']', this.modifiedData[i].id);
    //     formData.append('sharedto' + '[' + i + ']', this.modifiedData[i].user[0].id);
    //     formData.append('view' + '[' + i + ']', this.modifiedData[i].view);
    //     formData.append('edit' + '[' + i + ']', this.modifiedData[i].edit);
    //   }
    //   this.addupdateSharedData(formData);
    // }

    /*if (this.editedSharedData.length > 0 && this.fieldArray.length == 0) {
      for (let i = 0; i < this.editedSharedData.length; i++) {
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
        formData.append('sharedaccessid' + '[' + i + ']', this.editedSharedData[i].id);
        formData.append('sharedto' + '[' + i + ']', this.editedSharedData[i].user[0].id);
        formData.append('view' + '[' + i + ']', this.editedSharedData[i].view);
        formData.append('edit' + '[' + i + ']', this.editedSharedData[i].edit);
      }
      this.addupdateSharedData(formData);
    }
    if (this.fieldArray.length > 0 && this.editedSharedData.length == 0) {
      for (let i = 0; i < this.fieldArray.length; i++) {
        var isValid = false;
        if (this.fieldArray[i].user) {
          formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
          formData.append('sharedto' + '[' + i + ']', this.fieldArray[i].user[0].id);
          formData.append('view' + '[' + i + ']', this.fieldArray[i].view);
          formData.append('edit' + '[' + i + ']', this.fieldArray[i].edit);
          isValid = true;
        }
      }

      if (isValid) {
        this.addupdateSharedData(formData);
      }
    }*/
  }

  /*To save shared Style data in data base
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  addupdateSharedData(formData) {
    this.showLoader = true;
    this.dataservice.addupdatesharedaccess(formData).subscribe(
      (response) => {
        this.editedSharedData = [];

        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.modifiedData = [];
            this.selectedItems = [];
            this.selectedItemsnew = [];
            this.isedit = [false];
            this.sharedUsers = [];
            for (let i = 0; i < response.body['item1'].length; i++) {
              response.body['item1'][i].module =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].module
                );
              response.body['item1'][i].org =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].module
                );
              for (let j = 0; j < response.body['item1'][i].user.length; j++) {
                response.body['item1'][i].user[j].firstName =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i].user[j].firstName
                  );
                response.body['item1'][i].user[j].lastName =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i].user[j].lastName
                  );
                response.body['item1'][i].user[j].about =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i].user[j].about
                  );
                response.body['item1'][i].user[j].eMail =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i].user[j].eMail
                  );
                response.body['item1'][i].user[j].group =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i].user[j].group
                  );
                response.body['item1'][i].user[j].org =
                  this.aesdecryptservice.decryptData(
                    this.dataservice.Key,
                    response.body['item1'][i].user[j].org
                  );
                response.body['item1'][i].user[j].fullName =
                  response.body['item1'][i].user[j].firstName +
                  ' ' +
                  response.body['item1'][i].user[j].lastName;
                if (response.body['item1'][i].user[j].picture) {
                  response.body['item1'][i].user[j].picture.thumbnail =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1'][i].user[j].picture.thumbnail
                    );
                  response.body['item1'][i].user[j].picture.name =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1'][i].user[j].picture.name
                    );
                }
                var isOnline = false;
                if (this.onlineUsers.length > 0) {
                  if (
                    this.onlineUsers.indexOf(
                      response.body['item1'][i].user[j].eMail
                    ) !== -1
                  ) {
                    isOnline = true;
                  }
                }
                let item = {
                  user: response.body['item1'][i].user,
                  isOnline: isOnline,
                };
                this.sharedUsers.push(item);
              }
            }
            this.fieldArray = response.body['item1'];
            this.submitted = false;
            this.logger.info(
              this.appStaticMsgs.styledetails.stylesharedsuccessfully,
              'Style Details'
            );

            // this.success(
            //   this.appStaticMsgs.styledetails.stylesharedsuccessfully
            // );
            this.toastService.showSuccess('Shared Successfully');
            this.showLoader = false;
          } else {
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.showLoader = false;
          }
        }
      },
      (err) => {
        //this.alert = 'danger';
        //this.errorDiv = true;
        //this.errors = err.message;
        this.logger.error(err.error.message, 'Style Details');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          this.logger.error(err.error.message, 'Style Details');
        }
        this.showLoader = false;
      }
    );
  }

  /*To get all shared user data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getSharedData() {
    this.getAllUsers();
    this.getAllsharedaccess();
    this.isedit[this.fieldArray.length] = true;
    //console.log('edit',this.fieldArray)
    // let abc = [];
    // this.fieldArray[0].forEach(e => {
    //   let a = {};
    //     if(e.view == true && e.edit == true){
    //       a['eid'] = 2;
    //       a['fieldName'] = 'Can Edit';
    //       a['selected'] = true;
    //     }else if(e.edit == true){
    //       a['eid'] = 2;
    //       a['fieldName'] = 'Can Edit';
    //       a['selected'] = true;
    //     }else{
    //       a['eid'] = 1;
    //       a['fieldName'] = 'Can View';
    //       a['selected'] = true;
    //     }
    //     abc.push(e);
    // });
    // //console.log('abc',abc)
    // this.selectedPermissions = abc;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);

    var fields = {
      edit: false,
      view: true,
      id: '',
      sharedTo: '',
      user: '',
    };
    this.fieldArray.push(fields);
    this.isedit = [false];
    // setTimeout(() => {
    // }, 100);
  }

  itemDetails() {
    this.discussionComponent.openitemDetails(this.editedStyleData);
  }

  /*To open styles discussion for chat
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  stylesdiscussion() {
    if (this.storageservice.getEditedStyleId()) {
      this.dataservice
        .getsharedaccess(this.storageservice.getEditedStyleId(), 'Styles')
        .subscribe(
          (response) => {
            if (response.status === 200) {
              //this.allUser = response.body;
              if (response.body['item2'] === 'Success') {
                this.logger.info('Get shared data', 'Style Details');

                this.showLoader = false;
                //this.isSharedView=true;
                this.fieldArray = response.body['item1'];
                this.newfieldArray = [];
                this.newfieldArray = response.body['item1'];

                this.discussionComponent.openStylesDiscussion(
                  this.storageservice.getEditedStyleId(),
                  this.newfieldArray,
                  this.editedStyleData
                );
              } else {
                ////console.log(response)
                //this.alert = 'danger';
                //this.errorDiv = true;
                // this.errors = response.body['item2'];
                this.logger.error(response.body['item1'], 'Style Details');

                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.showLoader = false;
              }
            }
          },
          (err) => {
            //this.alert = 'danger';
            //this.errorDiv = true;
            //this.errors = err.message;
            this.logger.error(err.error.message, 'Style Details');

            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
              this.logger.error(err.error.message, 'Style Details');
            }
            this.showLoader = false;
          }
        );
    }
  }

  /*To get all shared user acess data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getAllsharedaccess() {
    if (this.storageservice.getEditedStyleId()) {
      // this.showLoader = true;
      this.sharedUsers = [];
      this.dataservice
        .getsharedaccess(this.storageservice.getEditedStyleId(), 'Styles')
        .subscribe(
          (response) => {
            if (response.status === 200) {
              //this.allUser = response.body;
              if (response.body['item2'] === 'Success') {
                // this.showLoader = false;
                this.logger.info(
                  this.appStaticMsgs.styledetails.getshareddata,
                  'Style Details'
                );
                for (let i = 0; i < response.body['item1'].length; i++) {
                  response.body['item1'][i].module =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1'][i].module
                    );
                  response.body['item1'][i].org =
                    this.aesdecryptservice.decryptData(
                      this.dataservice.Key,
                      response.body['item1'][i].module
                    );
                  for (
                    let j = 0;
                    j < response.body['item1'][i].user.length;
                    j++
                  ) {
                    response.body['item1'][i].user[j].firstName =
                      this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        response.body['item1'][i].user[j].firstName
                      );
                    response.body['item1'][i].user[j].lastName =
                      this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        response.body['item1'][i].user[j].lastName
                      );
                    response.body['item1'][i].user[j].about =
                      this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        response.body['item1'][i].user[j].about
                      );
                    response.body['item1'][i].user[j].eMail =
                      this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        response.body['item1'][i].user[j].eMail
                      );
                    response.body['item1'][i].user[j].group =
                      this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        response.body['item1'][i].user[j].group
                      );
                    response.body['item1'][i].user[j].org =
                      this.aesdecryptservice.decryptData(
                        this.dataservice.Key,
                        response.body['item1'][i].user[j].org
                      );
                    response.body['item1'][i].user[j].fullName =
                      response.body['item1'][i].user[j].firstName +
                      ' ' +
                      response.body['item1'][i].user[j].lastName;
                    if (response.body['item1'][i].user[j].picture) {
                      response.body['item1'][i].user[j].picture.thumbnail =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          response.body['item1'][i].user[j].picture.thumbnail
                        );
                      response.body['item1'][i].user[j].picture.name =
                        this.aesdecryptservice.decryptData(
                          this.dataservice.Key,
                          response.body['item1'][i].user[j].picture.name
                        );
                    }

                    var isOnline = false;
                    if (this.onlineUsers.length > 0) {
                      if (
                        this.onlineUsers.indexOf(
                          response.body['item1'][i].user[j].eMail
                        ) !== -1
                      ) {
                        isOnline = true;
                      }
                    }
                    let item = {
                      user: response.body['item1'][i].user,
                      isOnline: isOnline,
                    };
                    this.sharedUsers.push(item);
                  }
                }

                //this.isSharedView=true;
                this.fieldArray = response.body['item1'];
                this.newfieldArray = [];
                this.newfieldArray = response.body['item1'];
              } else {
                ////console.log(response)
                //this.alert = 'danger';
                //this.errorDiv = true;
                // this.errors = response.body['item2'];
                // this.error(response.body['item1']);
                this.toastService.showError(response.body['item1']);
                this.logger.error(response.body['item1'], 'Style Details');

                this.showLoader = false;
              }
            }
          },
          (err) => {
            //this.alert = 'danger';
            //this.errorDiv = true;
            //this.errors = err.message;
            this.logger.error(err.error.message, 'Style Details');

            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              // this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              // this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              // this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
              this.logger.error(err.message, 'Style Details');
            }
            this.showLoader = false;
          }
        );
    }
  }
  onSelectGender(value) {
    this.agegroup = [
      {
        value: 'Infants & Toddlers (0 & 4 Years)',
        label: 'Infants & Toddlers (0 & 4 Years)',
      },
      { value: '5 to 10 Years', label: '5 to 10 Years' },
      { value: '10 to 19 Years', label: '10 to 19 Years' },
      { value: '19 to 29 Years', label: '19 to 29 Years' },
      { value: '29 to 49 Years', label: '29 to 49 Years' },
      { value: 'Above 50 Years"', label: 'Above 50 Years' },
    ];
  }

  AddToFavorite(e) {
    this.showLoader = true;
    let selected = e.target.id.replace('checkbox', '');
    if (e.target.checked) {
      let data: Favourite[] = [];
      data.push(new Favourite(this.recordId));
      this.dataservice.bulkAddFavourites(data, 'Styles').subscribe(
        (response) => {
          if (response.status === 200) {
            if (response.body['item2'] === 'Success') {
              this.showLoader = false;
              this.toastService.showSuccess('Added to favorite');
              //this.success("Added to Favourites Successfully!");
            } else {
              //console.log(response)
              //alert(response.body['item2'])
              // this.error(response.body['item1']);
              this.toastService.showError(response.body['item1']);
              this.showLoader = false;
            }
          }
        },
        (err) => {
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else {
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
          this.showLoader = false;
        }
      );
    } else {
      let data: string[] = [];
      data.push(this.recordId);
      this.dataservice.bulkDeleteFavourites(data, 'Styles').subscribe(
        (response) => {
          if (response.status === 200) {
            if (response.body['item2'] === 'Success') {
              this.showLoader = false;
              this.toastService.showSuccess('Removed from favorite');
              //this.success("Removed from Favourites!");
            } else {
              //console.log(response)
              //alert(response.body['item2'])
              // this.error(response.body['item1']);
              this.toastService.showError(response.body['item1']);
              this.showLoader = false;
            }
          }
        },
        (err) => {
          if (
            err.error.message === 'Network error communicating with endpoint'
          ) {
            // this.error(err.error.message);
            this.toastService.showError(
              'Oops!! Something went wrong. Please try again.'
            );
          } else if (err.error.message === 'The incoming token has expired') {
            // this.sessionTimedOut();
            this.toastService.showError(
              'The token being used has expired. Please login again.'
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else if (err.error.message === 'Unauthorized') {
            // this.userservice.error();
            this.toastService.showError(
              "You don't have permission to access this resource. Please login again."
            );
            this.sessionTimedOut();
            this.userservice.logout();
          } else {
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
          this.showLoader = false;
        }
      );
    }
  }

  changeSizeRange(event, data) {
    console.log(
      'changeSizeRange',
      this.styleForm.get('sizeChart').value,
      this.styleForm.get('sampleSize')?.value
    );

    this.sampleSize = [];
    if (event) {
      let selectedSize = this.styleForm.get('sizeRange').value;
      console.log(selectedSize);
      for (var i = 0; i < selectedSize.length; i++) {
        let data = { id: selectedSize[i] + '*', value: selectedSize[i] };
        this.sampleSize.push(data);
      }
    } else {
      let dropData = [];
      let selectedSize;
      if (this.styleForm.get('sizeRange')?.value) {
        selectedSize = this.styleForm.get('sizeRange')?.value?.split(',');
      }

      for (var i = 0; i < selectedSize?.length; i++) {
        let data = { id: selectedSize[i] + '*', value: selectedSize[i] };
        console.log(data);

        this.sampleSize.push(data);
        this.sampleSize.sort((a, b) => {
          // Move items with size 'xs' to the first place
          if (a.value === 'XS' && b.value !== 'XS') {
            return -1;
          } else if (a.value !== 'XS' && b.value === 'XS') {
            return 1;
          } else {
            // For other sizes or when both items have size 'xs', maintain the original order
            return 0;
          }
        });
        dropData.push(selectedSize[i]);
      }

      if (dropData) this.styleForm.get('sizeRange').setValue(dropData);
    }

    const matchedSize = this.sampleSize.find(
      (size) => (size.id === this.styleForm.get('sampleSize')?.value) || (size.value === this.styleForm.get('sampleSize')?.value)
    );
    if (!matchedSize) {
      this.styleForm.get('sampleSize')?.setValue('');
    }

    console.log(
      this.sampleSize,
      'sampleSize',
      this.styleForm.get('sampleSize')?.value
    );
    this.sharedService.setSample(this.sampleSize);
    $('#sampleSize').selectpicker();
    this.refreshSelect();

    //this.getStylePomSpecifications();
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(ColorwaysComponent);

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog closed`);
  //     this.loadSelectPicker();
  //     this.getstylecolourways();
  //   });
  // }

  filteredItems: any;

  changeSizeChart(event, data) {
    this.filteredItems = [];

    let selectedCat = this.styleForm.get('sizeChart').value;
    console.log('selectedCat', selectedCat);

    let filterCategory = this.unfilteredData?.filter(
      (x) => x.sizeCategory == selectedCat
    );
    console.log('sizeCategory', filterCategory);
    filterCategory.forEach((x) => {
      this.filteredItems.push(x);
    });
    this.filteredItems.sort((a, b) => {
      // Move items with size 'xs' to the first place
      if (a.size === 'XS' && b.size !== 'XS') {
        return -1;
      } else if (a.size !== 'XS' && b.size === 'XS') {
        return 1;
        console.log('1', 1);
      } else {
        // For other sizes or when both items have size 'xs', maintain the original order
        return 0;
      }
    });
    console.log('filterCategory', this.filteredItems);
    $('#sizeRange').selectpicker();
    this.styleForm.get('sizeRange').setValue('');
    this.refreshSelect();
    setTimeout(() => {
      this.changeSizeRange('', '');
    }, 100);
  }

  getAllSeams() {
    this.dataservice.getSeams('0-100', 'Created').subscribe(
      (res) => {
        if (res.body['item2'] == 'Success') {
          let data: any = res.body['item1'];
          data.forEach((x) => {
            if (x.name) {
              x['id'] = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.name
              );
              x['name'] = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.name
              );
            } else {
              x['id'] = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.sequence
              );
              x['name'] = this.aesdecryptservice.decryptData(
                this.dataservice.Key,
                x.sequence
              );
            }
          });
          this.constData = data;
          console.log(this.constData);
        }
      },
      (err) => {}
    );
  }

  sticheslistArr: any = [];
  materialDataArr: any = [];
  vendorDataArr: any = [];
  colorDataArr: any = [];
  handleFilter(value, type) {
    console.log(value);
    if (type == 'Stitch') {
      this.sticheslistArr = this.sticheslist.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (type == 'MaterialName') {
      this.materialDataArr = this.materialData.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (type == 'VendorName') {
      console.log('this.vendorDataArr', this.vendorDataArr);
      if (value) {
        this.vendorDataArr = this.vendorData.filter(
          (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      } else {
        this.vendorDataArr = this.vendorData;
      }
    } else if (type == 'ColorName') {
      console.log('this.colorDataArr', this.colorDataArr);
      if (value) {
        this.colorDataArr = this.colorData.filter(
          (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      } else {
        this.colorDataArr = this.colorData;
      }
    }
  }

  messageFromChild: string;
  onMessageFromChild(message: any) {
    //this.messageFromChild = messae;
    this.showLightbox(message);
  }

  showLightbox(img) {
    this.imagePopupObject = [
      {
        image: img.documentId,
        title: img.documentName,
      },
    ];
    console.log('calling startStopLoader', this.imagePopupObject);
    this.currentIndex = 0;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }

  sizeRanges: any;

  unfilteredData: any;
  public dropdown = [{ data: [] }];
  getAllStyleSizeRange() {
    this.dataservice.getStyleSizeRange().subscribe(
      (response) => {
        if (response['body']['item2'] == 'Success') {
          let Data = response['body']['item1'];
          Data.forEach((x) => {
            x['id'] = x.id;
            x['size'] = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              x.size
            );
            x['sizeCategory'] = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              x.sizeCategory
            );
            x['columnName'] = this.aesdecryptservice.decryptData(
              this.dataservice.Key,
              x.columnName
            );
          });

          this.unfilteredData = Data;

          console.log(this.unfilteredData, Data);

          /* this.filteredItems = Data.reduce((r,{sizeCategory})=>{
           if(!r.some(o=>o.sizeCategory==sizeCategory)){
             r.push({sizeCategory,groupItem:Data.filter(v=>v.sizeCategory==sizeCategory)});
         }
       return r;
       },[]);*/
          if (Data.length > 0) {
            Data.forEach((x) => {
              if (x.sizeCategory) {
                if (this.dropdown[0].data.length > 0) {
                  if (
                    !this.dropdown[0].data.find((y) => y.id == x.sizeCategory)
                  ) {
                    this.dropdown[0].data.push({
                      id: x.sizeCategory,
                      text: x.sizeCategory,
                    });
                  }
                } else {
                  this.dropdown[0].data.push({
                    id: x.sizeCategory,
                    text: x.sizeCategory,
                  });
                }
              }
            });
          }
          this.sizeCharts = this.dropdown[0].data;
          this.sizeCharts.unshift({ id: '', text: 'Select Chart' });
          console.log('filteredItems', this.dropdown[0].data);
          //this.sizeRanges = Data;
        }
      },
      (err) => {
        console.log('getAllStyleSizeRange error', err);
      }
    );
  }

  /* To search data for grid list view
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021 */

  public filterQuery = '';
  state: State = {
    skip: 0,
    take: 10,
  };

  totalSharedRecordCount: any;
  filteredXcelData: any[];
  serachData: any[];

  //search Events
  searchListData(val: string) {
    if (this.styleViewMode === 'Shared') {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.serachData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.serachData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.serachData = this.filteredData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.serachData = this.filteredData;
        this.state.take = this.paginationEndcount;
        //return
      }
    }
    if (this.state.sort)
      this.serachData = orderBy(this.serachData, this.state.sort);
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.serachData,
      total:
        this.styleViewMode == 'Shared'
          ? this.totalSharedRecordCount
          : this.bomRecordCount,
    };
    this.bomViewData = gridView; // process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(this.serachData, this.state.filter);
  }

  constructionSearchData = [];
  searchcConstructionData(val: string) {
    if (this.styleViewMode === 'Shared') {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.constructionSearchData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.constructionSearchData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.constructionSearchData = this.filteredData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.constructionSearchData = this.filteredData;
        this.state.take = this.paginationEndcount;
        //return
      }
    }
    if (this.state.sort)
      this.constructionSearchData = orderBy(
        this.constructionSearchData,
        this.state.sort
      );
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.constructionSearchData,
      total:
        this.styleViewMode == 'Shared'
          ? this.totalSharedRecordCount
          : this.constructionRecordCount,
    };
    this.ConstructionData = gridView; // process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(
      this.constructionSearchData,
      this.state.filter
    );
  }

  stichesSearchData = [];
  searchStichesData(val: string) {
    if (this.styleViewMode === 'Shared') {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.stichesSearchData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.stichesSearchData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.stichesSearchData = this.dbstichesData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.stichesSearchData = this.dbstichesData;
        this.state.take = this.paginationEndcount;
        //return
      }
    }
    if (this.state.sort)
      this.stichesSearchData = orderBy(this.stichesSearchData, this.state.sort);
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.stichesSearchData,
      total:
        this.styleViewMode == 'Shared'
          ? this.totalSharedRecordCount
          : this.specificationStitcheRecordCount,
    };
    this.stichesData = gridView; // process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(this.stichesSearchData, this.state.filter);
  }

  patternSearchData = [];
  searchPatternData(val: string) {
    if (this.styleViewMode === 'Shared') {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.patternSearchData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.patternSearchData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.patternSearchData = this.filteredData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.patternSearchData = this.filteredData;
        this.state.take = this.paginationEndcount;
        //return
      }
    }
    if (this.state.sort)
      this.patternSearchData = orderBy(this.patternSearchData, this.state.sort);
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.patternSearchData,
      total:
        this.styleViewMode == 'Shared'
          ? this.totalSharedRecordCount
          : this.patternRecordCount,
    };
    this.patternData = gridView; // process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(this.patternSearchData, this.state.filter);
  }

  specificatioSearchData = [];
  searchSpecificationData(val: string) {
    if (this.styleViewMode === 'Shared') {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.specificatioSearchData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.specificatioSearchData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.specificatioSearchData = this.dbspecificationPoms.filter(
          (data) => {
            var search = this.filterQuery.toString().toLowerCase();
            var values = Object.values(data);
            var indicator = false;
            values.forEach((vl) => {
              if (String(vl).toLowerCase().indexOf(search) > -1) {
                indicator = true;
                return;
              }
            });
            if (indicator) {
              return data;
            }
          }
        );
      } else {
        this.specificatioSearchData = this.dbspecificationPoms;
        this.state.take = this.paginationEndcount;
        //return
      }
    }
    if (this.state.sort)
      this.specificatioSearchData = orderBy(
        this.specificatioSearchData,
        this.state.sort
      );
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.specificatioSearchData,
      total:
        this.styleViewMode == 'Shared'
          ? this.totalSharedRecordCount
          : this.specificationPomsRecordCount,
    };
    this.specificationPoms = gridView;
    // this.specificationPOMSAI = gridView;
    console.log('nnnnnnnnnnn 11265', this.specificationPoms);
    // process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(
      this.specificatioSearchData,
      this.state.filter
    );
  }

  processingPomSearchData = [];
  searchProcessingPomData(val: string) {
    if (this.styleViewMode === 'Shared') {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.processingPomSearchData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.processingPomSearchData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.processingPomSearchData = this.dbprocessingPoms.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.processingPomSearchData = this.dbprocessingPoms;
        this.state.take = this.paginationEndcount;
        //return
      }
    }
    if (this.state.sort)
      this.processingPomSearchData = orderBy(
        this.processingPomSearchData,
        this.state.sort
      );
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.processingPomSearchData,
      total:
        this.styleViewMode == 'Shared'
          ? this.totalSharedRecordCount
          : this.processingPomsRecordCount,
    };
    this.processingPoms = gridView;
    // process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(
      this.processingPomSearchData,
      this.state.filter
    );
  }

  processingSewnSearchData = [];
  searchProcessingSewnData(val: string) {
    if (this.styleViewMode === 'Shared') {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.processingSewnSearchData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.processingSewnSearchData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== '') {
        let arr = [];
        this.filterQuery = val;
        this.processingSewnSearchData = this.dbprocessingSewn.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) {
            return data;
          }
        });
      } else {
        this.processingSewnSearchData = this.dbprocessingSewn;
        this.state.take = this.paginationEndcount;
        //return
      }
    }
    if (this.state.sort)
      this.processingSewnSearchData = orderBy(
        this.processingSewnSearchData,
        this.state.sort
      );
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.processingSewnSearchData,
      total:
        this.styleViewMode == 'Shared'
          ? this.totalSharedRecordCount
          : this.processingSewnRecordCount,
    };
    this.processingSewn = gridView;
    // process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(
      this.processingSewnSearchData,
      this.state.filter
    );
  }

  // ExcelExportData

  public processingPomsExpoertData(): ExcelExportData {
    this.processingPoms = filterBy(
      this.styleViewMode === 'Shared'
        ? this.sharedGridData
        : this.processingPoms,
      this.state.filter
    );
    //this.state.take = this.filteredData.length;

    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.processingPoms = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort)
      this.processingPoms = orderBy(this.processingPoms, this.state.sort);
    const result: ExcelExportData = {
      data: this.processingPoms.data, //process(this.filteredData, this.state).data,
    };
    //this.state.take = 5;
    return result;
  }

  public stitchExpoertData(): ExcelExportData {
    this.stichesData = filterBy(
      this.styleViewMode === 'Shared' ? this.sharedGridData : this.stichesData,
      this.state.filter
    );
    //this.state.take = this.filteredData.length;

    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.stichesData = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort)
      this.stichesData = orderBy(this.stichesData, this.state.sort);
    const result: ExcelExportData = {
      data: this.stichesData.data, //process(this.filteredData, this.state).data,
    };
    //this.state.take = 5;
    return result;
  }

  public specificationPomsExpoertData(): ExcelExportData {
    this.specificationPoms = filterBy(
      this.styleViewMode === 'Shared'
        ? this.sharedGridData
        : this.specificationPoms,
      this.state.filter
    );
    //this.state.take = this.filteredData.length;

    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.specificationPoms = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort)
      this.specificationPoms = orderBy(this.specificationPoms, this.state.sort);
    const result: ExcelExportData = {
      data: this.specificationPoms.data, //process(this.filteredData, this.state).data,
    };
    console.log('nnnnnnnnnnn 11481', this.specificationPoms);
    //this.state.take = 5;
    return result;
  }
  public processingSewnExpoertData(): ExcelExportData {
    this.processingSewn = filterBy(
      this.styleViewMode === 'Shared'
        ? this.sharedGridData
        : this.processingSewn,
      this.state.filter
    );
    //this.state.take = this.filteredData.length;

    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.processingSewn = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort)
      this.processingSewn = orderBy(this.processingSewn, this.state.sort);
    const result: ExcelExportData = {
      data: this.processingSewn.data, //process(this.filteredData, this.state).data,
    };
    //this.state.take = 5;
    return result;
  }

  public allData(): ExcelExportData {
    this.filteredData = filterBy(
      this.styleViewMode === 'Shared' ? this.sharedGridData : this.filteredData,
      this.state.filter
    );
    //this.state.take = this.filteredData.length;

    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.filteredData = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort)
      this.filteredData = orderBy(this.filteredData, this.state.sort);
    const result: ExcelExportData = {
      data: this.filteredData, //process(this.filteredData, this.state).data,
    };
    //this.state.take = 5;
    return result;
  }
  /*To routing particular tab of style details
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  getActiveTab() {
    this.dataservice.getActiveStyleTabValue().subscribe((data) => {
      this.activeTabValue = data;
    });
    if (this.activeTabValue === 'styleName') {
      this.activeTab = 'styleDetailsTab';
    } else if (this.activeTabValue === 'Colorways') {
      this.activeTab = 'colorwaysTab';
      this.getstylecolourways();
    } else if (this.activeTabValue === 'Style Image') {
      this.activeTab = 'imgDetailsTab';
      this.getImageDetails();
    } else if (this.activeTabValue === 'BOM') {
      this.activeTab = 'bomDetailsTab';
      //this.bomDetailsTab(this.activeTab);
      this.getBomDetails();
    } else if (this.activeTabValue === 'Construction') {
      this.activeTab = 'constructionTab';
      this.getAllSeams();
      this.getAllStitches();
      this.getStylesStitcheSpecifications();
    } else if (this.activeTabValue === 'Pattern') {
      this.activeTab = 'patternTab';
      this.getPatternbyId();
    } else if (this.activeTabValue === 'Specification') {
      this.activeTab = 'specificationTab';
      // this.styleDetailsTab(this.activeTab);
      this.getStyleDetails();
    } else if (this.activeTabValue === 'Processing') {
      this.activeTab = 'processingTab';
      this.getProcessingbyId();
    } else {
      this.activeTab = 'styleDetailsTab';
    }
  }

  fileChangeEvent(event: any): void {
    console.log(event.target.files[0]);
    this.showLoader = true;
    this.imageChangedEvent = event;
    console.log('this.imageChangedEvent', this.imageChangedEvent);
  }
  /*Conversion of image in file
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  imageCropped(event: any, i: number) {
    this.imgload = false;
    this.croppedImage = event.base64;
    console.log(event, i, "nnnnnnnnnnnnn");

    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name
    );
    const styImgArray = this.styImgForm.get('styImg') as FormArray;
    const control = styImgArray.at(i) as FormGroup;
    control.patchValue({
      croppedImage: this.croppedImage,
      showImage: true,
    });
    console.log(
      this.fileToReturn,
      'filetoreturn',
      this.styImgForm.controls.styImg
    );
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  /*To close image cropper
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  // closeImg() {
  //   this.croppedImage = [];
  //   this.showImage = false;
  //   this.showFileInput = true;
  //   this.fileToReturn = '';
  //   if (this.fileUploader) this.fileUploader.nativeElement.value = null;
  //   this.modalReference.close();
  // }
  closeImg(index) {
    this.croppedImage = [];
    this.showImage = false;
    this.showFileInput = true;
    this.fileToReturn = '';
    const styImgArray = this.styImgForm.get('styImg') as FormArray;
    const control = styImgArray.at(index) as FormGroup;
    control.patchValue({
      croppedImage: '',
      showImage: false,
    });
    if (this.fileUploader) this.fileUploader.nativeElement.value = null;
    this.modalReference.close();
  }
  /*To confirm image cropper
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  confirmSty() {
    this.showImage = true;
    this.showFileInput = false;
    this.isFileError = false;
    this.modalReference.close();
  }
  /*To Add Stitches New modal
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  stitchesNew() {
    this.isStitchesnew = true;
    this.isStitchesedit = false;
    this.dialog.open(ConstructionStitchesComponent, {
      data: { isStitchesedit: this.isStitchesedit },
    });
    this.dialog.afterAllClosed.subscribe((data) =>
      this.getStylesStitcheSpecifications()
    );
  }
  /*To Edit Stitches Specification
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  editClick(rowIndex, dataItem) {
    this.isStitchesnew = false;
    this.isStitchesedit = true;
    console.log(dataItem, 'style');
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setStitchData(dataItem);
    this.optionslist = dataItem;
    console.log(dataItem, 'styles');
    console.log(dataItem.id);
    this.dialog.open(ConstructionStitchesComponent, {
      data: { stitchData: dataItem, isStitchesedit: this.isStitchesedit },
    });
    this.dialog.afterAllClosed.subscribe((data) =>
      this.getStylesStitcheSpecifications()
    );
  }
  /*To Add operation
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */

  public AIConstructiongenerator() {
    const constructionAIpayload = {
      Garment_type: 'Topwear',
      Length: 'crop',
      Neck: 'Round Neck',
      Right_Sleeve: 'Puff Sleeves',
      Left_Sleeve: 'Puff Sleeves:',
      Right_Sleeve_length: 'Short Sleeves',
      Left_Sleeve_length: 'Short Sleeves',
      silhouette: 'CORSET',
      fitting: 'fitted',
      Color: 'White',
      occasion: 'Casual',
      season: 'summer',
      size: 'M',
      pom: {},
      bom: {},
    };
    this.dataservice
      .getAIConstructionList(constructionAIpayload)
      .subscribe((aiBomList: any) => {
        console.log(
          '888888888888888',
          constructionAIpayload,
          this.ConstructionData
        );
      });
  }

  operationNew() {
    this.isOperationnew = true;
    this.isOperationedit = false;
    this.dialog.open(ConstructionOperationComponent, {
      disableClose: true,
      data: { isOperationedit: this.isOperationedit },
    });
    this.dialog.afterAllClosed.subscribe((data) => this.getConstructionbyId());
  }
  /*To Edit Construction
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  editOperation(rowIndex, dataItem) {
    this.isOperationnew = false;
    this.isOperationedit = true;
    console.log(dataItem, 'style');
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setOperationData(dataItem);
    this.optionslist = dataItem;
    console.log(dataItem, 'styles');
    console.log(dataItem.id);
    this.dialog.open(ConstructionOperationComponent, {
      disableClose: true,
      data: { operationData: dataItem, isOperationedit: this.isOperationedit },
    });
    this.dialog.afterAllClosed.subscribe((data) => this.getConstructionbyId());
  }
  /*To add POM
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  AIPOMModal: any;
  public AIPOMgenerator(POMAI) {
    const styleData = this.styleForm.getRawValue();
    const pomAIpayload = {
      Garment_type: styleData.garmentType,
      Length: styleData.sleevesLength,
      Neck: styleData.neckType,
      Right_Sleeve: styleData.sleeveType,
      Left_Sleeve: styleData.sleeveType,
      Right_Sleeve_length: styleData.sleevesLength,
      Left_Sleeve_length: styleData.sleevesLength,
      silhouette: styleData.silhouette,
      fitting: styleData.fitting,
      Color: styleData.color,
      occasion: styleData.category,
      season: styleData.season,
      size: styleData.garmentType,
    };
    this.showLoader = true;
    this.dataservice.getAIPOMList(pomAIpayload).subscribe(
      (aiPomListResp: any) => {
        if (aiPomListResp && aiPomListResp.body['status'] == true) {
          this.showLoader = false;
          this.aiPomList = JSON.parse(
            JSON.stringify(
              aiPomListResp?.body?.Data[0]['Dress Type'][0]['Points of Measure']
            )
          );
          if (this.aiPomList && this.aiPomList.length > 0) {
            //this.aiPomListkeys = Object.keys(this.aiPomList[0]);
            this.aiPomListkeys = Object.keys(this.aiPomList[0]).map((key) => ({
              label: key,
              selected: false,
              description: this.aiPomList[0][key].Points
            }));
            let ngbModalOptions: NgbModalOptions = {
              size: 'lg',
              backdrop: 'static',
              keyboard: false,
            };

            this.AIPOMModal = this.modalService.open(POMAI, ngbModalOptions);
            this.AIPOMModal.result.then(
              (result: any) => {},
              async (reason: any) => {
                console.log('^^^^^^', reason);
              }
            );
          } else {
            console.warn(
              "Invalid or empty 'Points of Measure' data in the API response."
            );
          }
        }
      },
      (err) => {
        this.showLoader = false;
        this.logger.error(err.error.message, 'POM LIST with AI');
        if (err.error.message === 'Network error communicating with endpoint') {
          // this.error(err.error.message);
          this.toastService.showError(
            'Oops!! Something went wrong. Please try again.'
          );
        } else if (err.error.message === 'The incoming token has expired') {
          // this.sessionTimedOut();
          // this.userservice.logout();
          this.toastService.showError(
            'The token being used has expired. Please login again.'
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError(
            "You don't have permission to access this resource. Please login again."
          );
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'POM LIST with AI');
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
  }
  closeAIModal() {
    this.AIPOMModal.close();
  }
  bindGridPOM(event: any) {}
  addPom() {
    this.isPomnew = true;
    this.isPomedit = false;
    this.sharedService.setPomData(this.pomdata);
    this.sharedService.setSizeRange(this.f.sizeRange.value);
    this.sharedService.setUnfilter(this.unfilteredData);
    this.dialog.open(StylePOMComponent, {
      disableClose: true,
      data: { isPomedit: this.isPomedit },
    });
    this.dialog.afterAllClosed.subscribe((data) =>
      this.getStylePomSpecifications()
    );
  }

  /*To edit POM
      Written by : Himanshu Deshmukh
      Date: 23-JUN-2023 */
  editPom(rowIndex, dataItem) {
    this.isPomnew = false;
    this.isPomedit = true;
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setPomData(this.pomdata);
    this.sharedService.setSizeRange(this.f.sizeRange.value);
    this.sharedService.setUnfilter(this.unfilteredData);
    this.optionslist = dataItem;
    this.dialog.open(StylePOMComponent, {
      disableClose: true,
      data: { operationData: dataItem, isPomedit: this.isPomedit },
    });
    this.dialog.afterAllClosed.subscribe((data) =>
      this.getStylePomSpecifications()
    );
  }

  patternNew() {
    this.isPatternnew = true;
    this.isPatternedit = false;
    this.dialog.open(StylePatternComponent, {
      disableClose: true,
      data: { isPatternedit: this.isPatternedit },
    });
    this.dialog.afterAllClosed.subscribe((data) => this.getPatternbyId());
  }

  editPatternModal(rowIndex, dataItem) {
    this.isPatternnew = false;
    this.isPatternedit = true;
    console.log(dataItem, 'style');
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.sharedService.setPatternData(dataItem);
    this.optionslist = dataItem;
    console.log(dataItem, 'styles');
    console.log(dataItem.id);
    this.dialog.open(StylePatternComponent, {
      disableClose: true,
      data: { patternData: dataItem, isPatternedit: this.isPatternedit },
    });
    this.dialog.afterAllClosed.subscribe((data) => this.getPatternbyId());
  }

  openModel() {
    this.dialog.open(PatternFileUploadComponent, {
      disableClose: true,
      data: { isEditingMode: this.isEditingMode },
    });
    this.dialog.afterAllClosed.subscribe((data) => this.getImageDetails());
  }

  selectedGarmentType(data: string, eventChange): void {
    this.garmentTypeBasedList = [];
    if (eventChange === true) {
      this.styleForm.get('subCategory').setValue('');
    }
    const lowercasedData = data?.toLowerCase();
    switch (lowercasedData) {
      case 'topwear':
        this.garmentTypeBasedList = this.topwearJSONList;
        this.addRemoveValidation(false);
        break;
      case 'dress':
        this.garmentTypeBasedList = this.dressJSONList;
        this.styleForm.get('fitting').setValue('');
        this.addRemoveValidation(false);
        break;
      case 'bottomwear':
        this.garmentTypeBasedList = this.bottomwearJSONList;
        this.addRemoveValidation(true);
        break;
      case 'specialwear':
      case 'active and sportswear':
      case 'loungewear':
        this.addRemoveValidation(true);

        break;
      default:
        this.addRemoveValidation(true);

        break;
    }
    this.refreshSelect();
    this.storageservice.removeAIDescription();
  }

  async addRemoveValidation(removevalidation: boolean) {
    if (removevalidation) {
      // Clear validators
      await this.removeValidators(this.styleForm, [
        'silhouette',
        'subCategory',
        'neckType',
        'sleeveType',
        'length',
        'sleevesLength',
      ]);
      this.designDirection = {
        subcategory: false,
        designLength: false,
        neckType: false,
        sleevesLength: false,
        sleeveType: false,
      };
    } else {
      this.styleForm.get('silhouette').setValidators([Validators.required]);
      this.styleForm.get('subCategory').setValidators([Validators.required]);
      this.styleForm.get('neckType').setValidators([Validators.required]);
      this.styleForm.get('sleeveType').setValidators([Validators.required]);
      this.styleForm.get('sleevesLength').setValidators([Validators.required]);
      this.styleForm.get('length').setValidators([Validators.required]);
      this.designDirection = {
        subcategory: true,
        designLength: true,
        neckType: true,
        sleevesLength: true,
        sleeveType: true,
      };
    }

    // Update form validity
    this.styleForm.updateValueAndValidity();
  }

  public removeValidators(form: FormGroup, removeFields: string[]): void {
    for (const key of removeFields) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    }
    form.updateValueAndValidity();
  }

  isControlRequired(controlName: string): boolean {
    const control = this.styleForm.get(controlName);
    return control && control.validator === Validators.required;
  }
  removeLocalStoredDesc() {
    this.storageservice.removeAIDescription();
  }
  generateDescUsingAI(descContentAI) {
    console.log(this.f, '88888888888888888');
    if (
      this.f.garmentType != null &&
      this.f.garmentType != undefined &&
      this.f.garmentType.value != '' &&
      this.f.category != null &&
      this.f.category != undefined &&
      this.f.category.value != '' &&
      this.f.season != null &&
      this.f.season != undefined &&
      this.f.season.value != ''
    ) {
      let storedDesc = this.storageservice.getAIDescription();
      if (storedDesc != null && storedDesc != undefined) {
        this.generateDscResponse = JSON.parse(storedDesc);
        let ngbModalOptions: NgbModalOptions = {
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
        };

        const modalRef = this.modalService.open(descContentAI, ngbModalOptions);
        modalRef.result.then(
          (result: any) => {},
          async (reason: any) => {
            console.log('^^^^^^', reason);
          }
        );
      } else {
        this.showLoader = true;
        const styleData = this.styleForm.getRawValue();
        let payload = {
          Garment_type: styleData.garmentType,
          occasion: styleData.category,
          season: styleData.season,
          AgeCategory: styleData.ageGroup,
          Gender: styleData.targetGender,
          Style: styleData.subCategory,
        };
        this.dataservice.getDescUsingAI(payload).subscribe(
          (descUsingAiResponse: any) => {
            if (
              descUsingAiResponse &&
              descUsingAiResponse.body['status'] == true
            ) {
              this.generateDscResponse = descUsingAiResponse.body['Data'];
              this.storageservice.removeAIDescription();
              this.storageservice.storeAIDescription(
                JSON.stringify(this.generateDscResponse)
              );
              this.showLoader = false;
              let ngbModalOptions: NgbModalOptions = {
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
              };

              const modalRef = this.modalService.open(
                descContentAI,
                ngbModalOptions
              );
              modalRef.result.then(
                (result: any) => {},
                async (reason: any) => {
                  console.log('^^^^^^', reason);
                }
              );
            } else {
              this.showLoader = false;
              this.generateDscResponse = descUsingAiResponse.body['Data'];
            }
          },
          (err) => {
            this.showLoader = false;
            this.logger.error(err.error.message, 'AI Description Generator');
            if (
              err.error.message === 'Network error communicating with endpoint'
            ) {
              //this.error(err.error.message);
              this.toastService.showError(
                'Oops!! Something went wrong. Please try again.'
              );
            } else if (err.error.message === 'The incoming token has expired') {
              // this.sessionTimedOut();
              // this.userservice.logout();
              this.toastService.showError(
                'The token being used has expired. Please login again.'
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else if (err.error.message === 'Unauthorized') {
              //this.userservice.error();
              this.toastService.showError(
                "You don't have permission to access this resource. Please login again."
              );
              this.sessionTimedOut();
              this.userservice.logout();
            } else {
              this.logger.error(err.error.message, 'AI Description Generator');
              //this.error(err.message);
              this.toastService.showError(
                this.appStaticMsgs.unResponsive.error
              );
            }
            this.showLoader = false;
          }
        );
      }
      return false;
    } else {
      this.toastService.showError(
        'GarmentType / Category / Season is Mandatory'
      );
      return true;
    }
  }
  getAIDescriptionName(desc: any): string {
    return Object.keys(desc)[0] + ' : ' + Object.values(desc)[0];
  }

  bindDescription(event: any) {
    console.log(event.target.value);
    let desc = event?.target?.value.split(':');
    // const filterAiDescription = this.generateDscResponse.find(
    //   (desc) => this.getAIDescriptionName(desc) === event.target.value
    // );

    if (desc && desc !== null) {
      this.styleForm.get('description').setValue(desc[1]);
    }
  }

  saveAIPOMS() {
    const selectedLabels: any = this.aiPomListkeys
      .filter(option => option.selected);
    this.AIPOMModal?.close();
    const payloads = [];
    for (let data of selectedLabels) {
      // Create payload object for current data set
      const payload = {
        pomName: data.label,
        templateName: this.styleForm.controls.silhouette.value,
        description: data.description
      };

      // Push the payload object into payloads array
      payloads.push(payload);
    }
    this.showLoader = true;
    this.dataservice
      .getAIDraftedPOMTemplatesList(
        payloads
      )
      .subscribe((response) => {
        if (response.body['item2'] == 'Success') {
          this.showLoader = false;
          if (
            response.body['item1'] !== null &&
            response.body['item1'].length !== 0 &&
            response.body['item1'] !== undefined
          ) {
            console.log(response.body['item1']);

            for (let i = 0; i < response.body['item1'].length; i++) {
              response.body['item1'][i].template =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].template
                );
              response.body['item1'][i].pomName =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].pomName
                );
              response.body['item1'][i].pomId =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].pomId
                );
              response.body['item1'][i].pomDescription =
                this.aesdecryptservice.decryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].pomDescription
                );
            }
            const formData = new FormData();
            formData.append('styleid', this.storageservice.getEditedStyleId());
            formData.append('stylepomcount', response.body['item1'].length);
            for (let i = 0; i < response.body['item1'].length; i++) {
              formData.append(
                'sptag' + '[' + i + ']',
                this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
              );
              formData.append(
                'poms' + '[' + i + ']',
                this.aesdecryptservice.encryptData(this.dataservice.Key, response.body['item1'][i].pomId)
              );
              formData.append(
                'tolerance' + '[' + i + ']',
                this.aesdecryptservice.encryptData(this.dataservice.Key, '')
              );
              formData.append(
                'description' + '[' + i + ']',
                this.aesdecryptservice.encryptData(
                  this.dataservice.Key,
                  response.body['item1'][i].pomDescription
                )
              );
              this.sampleSize.forEach((x) => {
                formData.append(
                  x.value + '[0]',
                  this.aesdecryptservice.encryptData(this.dataservice.Key, '')
                );
              });
            }
            this.dataservice
              .addupdatedeleteStylespecification(formData)
              .subscribe(async (response: any) => {
                this.showSpecPomNote = false;
                if (response.status === 200) {
                  if (response.body['item2'] === 'Success') {
                    this.showLoader = false;
                    this.logger.info(
                      this.appStaticMsgs.styledetails
                        .specificationpomsavesuccessfully,
                      'Style Specification pom'
                    );
                    // this.success(
                    //   this.appStaticMsgs.styledetails.specificationpomsavesuccessfully
                    // );
                    this.toastService.showSuccess('Added Successfully');

                    this.getStylePomSpecifications();
                  } else {
                    //this.error(response.body['item1']);
                    this.toastService.showError(
                      'Style Specification pom Error'
                    );
                    this.logger.error(
                      response.body['item1'],
                      'Style Specification pom'
                    );
                    this.showLoader = false;
                  }
                }
              },
                (err) => {
                  this.showSpecPomNote = false;
                  this.logger.error(
                    err.error.message,
                    'Style Specification pom'
                  );
                  if (
                    err.error.message ===
                    'Network error communicating with endpoint'
                  ) {
                    //this.error(err.error.message);
                    this.toastService.showError("Oops!! Something went wrong. Please try again.");
                  } else if (
                    err.error.message === 'The incoming token has expired'
                  ) {
                    // this.sessionTimedOut();
                    // this.userservice.logout();
                    this.toastService.showError("The token being used has expired. Please login again.")
                    this.sessionTimedOut();
                    this.userservice.logout();
                  } else if (err.error.message === 'Unauthorized') {
                    // this.userservice.error();
                    this.toastService.showError("You don't have permission to access this resource. Please login again.")
                    this.sessionTimedOut();
                    this.userservice.logout();
                  } else {
                    this.logger.error(
                      err.error.message,
                      'Style Specification pom'
                    );
                    //this.error(err.message);
                    this.toastService.showError(this.appStaticMsgs.unResponsive.error);
                  }
                  this.showLoader = false;
                }
              );
          }
        } else {
        }
      });
  }

  savePOMS() {
    const data = this.selectedPOMTemplateList.filter((option) => option.selected);
    console.log('data', data);
    this.pomModal.close();
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('stylepomcount', data.length);
    for (let i = 0; i < data.length; i++) {
      formData.append(
        'sptag' + '[' + i + ']',
        this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')
      );
      formData.append(
        'poms' + '[' + i + ']',
        this.aesdecryptservice.encryptData(this.dataservice.Key, data[i].poms)
      );
      formData.append(
        'tolerance' + '[' + i + ']',
        this.aesdecryptservice.encryptData(this.dataservice.Key, '')
      );
      formData.append(
        'description' + '[' + i + ']',
        this.aesdecryptservice.encryptData(this.dataservice.Key, data[i].description)
      );
      this.sampleSize.forEach((x) => {
        formData.append(
          x.value + '[0]',
          this.aesdecryptservice.encryptData(this.dataservice.Key, '')
        );
      });
    }
    this.dataservice.addupdatedeleteStylespecification(formData).subscribe(
      async (response) => {
        this.showSpecPomNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === 'Success') {
            this.showLoader = false;
            this.logger.info(
              this.appStaticMsgs.styledetails.specificationpomsavesuccessfully,
              'Style Specification pom'
            );
            // this.success(
            //   this.appStaticMsgs.styledetails.specificationpomsavesuccessfully
            // );
            this.toastService.showSuccess('Added Successfully');

            this.getStylePomSpecifications();
          } else {
            //this.error(response.body['item1']);
            this.toastService.showError(
              'Style Specification Pom Issue,Please retry!!!'
            );
            this.logger.error(
              response.body['item1'],
              'Style Specification pom'
            );
            this.showLoader = false;
          }
        }
      },
      (err) => {
        this.showSpecPomNote = false;
        this.logger.error(err.error.message, 'Style Specification pom');
        if (err.error.message === 'Network error communicating with endpoint') {
          //this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === 'The incoming token has expired') {
          this.toastService.showError("The token being used has expired. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        } else if (err.error.message === 'Unauthorized') {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        } else {
          this.logger.error(err.error.message, 'Style Specification pom');
          //this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      }
    );
  }

  closePOMModal() {
    this.pomModal.close();
  }

  downloadFile(): void {
    const bucketName = `${environment.Bucketname}`;
    const fileKey = this.fileName;
    this.dataservice.downloadFileFromS3(bucketName, fileKey);
  }
}