<mat-dialog-content class="mat-typography palette_modal">
  <div class="dt-page__header">
    <h3 class="dt-page__title">Edit Palette
      <button type="button" class="close" aria-label="Close" (click)="cancelPaletteCreate()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
  </div>
  <form [formGroup]="paletteForm" (ngSubmit)="onPaletteSubmit()">

    <div class="form-row">
      <div class="col-lg-4 col-sm-4 mb-3" *ngIf="isIDVisible">
        <label for="sequence">{{staticMsgs.paletteDetail.id}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="sequence" formControlName="sequence" placeholder="Enter ID"
          value="">
      </div>
      <div class="col-lg-4 col-sm-4 mb-3">
        <label for="name">{{staticMsgs.paletteDetail.name1}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter Name" value=""
          minlength="3" maxlength="50" (keypress)="characterOnly($event)" required>
        <div
          *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
          <div *ngIf="this.f.name.errors.required" class="red">
            {{staticMsgs.paletteDetail.nameReq}}
          </div>
          <div *ngIf="this.f.name.errors.minlength" class="red">
            {{staticMsgs.common.minimum3charactersrequired}}
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-sm-4 mb-3">
        <label for="status">{{staticMsgs.paletteDetail.status}}<span class="red_icon">*</span></label>
        <select id="status" title="Select Status" formControlName="status" class="form-control selectpicker w-100">
          <!-- <option value="" [disabled]="true" selected>
          Select Status
        </option> -->
          <option value="Approved">{{staticMsgs.paletteDetail.approved}}</option>
          <option value="In Review">{{staticMsgs.paletteDetail.inReview}}</option>
          <option value="Dropped">{{staticMsgs.paletteDetail.dropped}}</option>
        </select>
        <div
          *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
          <div *ngIf="this.f.status.errors.required" class="red">
            {{staticMsgs.paletteDetail.statusReq}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-4 mb-3">
        <label for="description">{{staticMsgs.paletteDetail.description}}</label>
        <textarea cols="2" formControlName="description" name="description" placeholder="Enter Description"
          class="form-control"></textarea>
        <div
          *ngIf="this.paletteForm.controls.description.errors && this.paletteForm.controls.description.value && this.paletteForm.controls.description.value.length > 500"
          class="red">
          {{staticMsgs.paletteDetail.descAllow}}
        </div>
      </div>

    </div>

    <div class="col-lg-12 col-sm-12">
      <div class="dt-entry__header mt-5">
        <!-- Entry Heading -->
        <div class="dt-entry__heading w-100 form-row">
          <h3 class="dt-entry__title col-sm-10">{{staticMsgs.paletteDetail.selectPalette}}<span
              class="red_icon">*</span></h3>
          <div class="col-sm-2">
            <div class="float-right d-flex">
              <i class="fa fa-file-export extra_icon text-white" placement="top" (click)="saveJPG()"
                ngbTooltip="Export"></i>
            </div>
          </div>
        </div>
        <!-- /entry heading -->
      </div>
      <div class="form-row">
        <div class="col-sm-12 text-center">
          <button type="button" class="btn btn-primary btn-esm" (click)="addColor()" placement="top">
            {{staticMsgs.paletteDetail.addPalette}} </button>
        </div>
      </div>
      <div class="form-row mt-3" id="sortable" cdkDropList [cdkDropListData]="colorSection"
        (cdkDropListDropped)="drop($event)">
        <div class="col-sm-2 mb-3 ui-state-default" *ngFor="let colorelement of colorSection; let i = index">
          <p class="color_name">{{colorelement.colorHex}}</p>
          <div class="color-section text-center mt-4 handle" name="colordiv"
            [ngStyle]="{'background-color': colorelement.colorbg}" cdkDrag>
          </div>
          <div class="color-icons" name="coloricon">
            <div>
              <i class="fa fa-eye-dropper text-secondary" #ColorInput (click)="editColor($event,i,colorelement)"
                placement="bottom" ngbTooltip="Edit Color"></i>
              <i class="fa fa-times" aria-hidden="true" (click)="removeColor(i)" placement="bottom"
                ngbTooltip="Delete Color"></i>
              <!-- <i class="icon-invert-color" #ColorInput (click)="editColor($event,i,colorelement)" placement="bottom" ngbTooltip="Edit Color"></i> -->
            </div>
          </div>
          <color-chrome (onChange)="handleChange($event,colorelement)" name="colorsketch"
            [ngClass]="colorelement.isopencolor ? 'cshow' : 'chide'"></color-chrome>
          <i class="fa fa-times colorChrome" aria-hidden="true" (click)="chromeClick(colorelement)"
            [ngClass]="colorelement.isopencolor ? 'cshow' : 'chide'" placement="bottom" ngbTooltip="Close"></i>

        </div>
        <div *ngIf="(submitted && !isColorAdded)" class="red">
          {{staticMsgs.paletteDetail.paletteReq}}
        </div>
      </div>

    </div>


    <div class="d-flex justify-content-end mt-4">
      <div class="row mt-3">
        <button type="button" *ngIf="isEditMode" (click)="cancelPaletteCreate()"
          class="btn btn-outline-primary adv-search-btn mr-1">{{staticMsgs.paletteDetail.cancel}}</button>
        <button type="submit" *ngIf="!isEditMode" class="btn btn-primary adv-search-btn float-right"
          [disabled]="paletteForm?.invalid || !isColorAdded">
          {{staticMsgs.paletteDetail.create}}</button>
        <button type="submit" *ngIf="isEditMode" class="btn btn-primary adv-search-btn"
          [disabled]="paletteForm?.invalid || !isColorAdded">
          {{staticMsgs.paletteDetail.update}}</button>
      </div>
    </div>
  </form>
</mat-dialog-content>