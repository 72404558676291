import * as seamsActions from "../actions/seams.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface SeamsState extends EntityState<any> {
    selectedSeamsId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const seamsAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultSeams: SeamsState = {
    ids: [],
    entities: {},
    selectedSeamsId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = seamsAdapter.getInitialState(defaultSeams);

export function SeamsReducer(state = initialState, action: seamsActions.seamsActionTypes): SeamsState {
    switch (action.type) {
        case seamsActions.SeamsActionTypes.LOAD_SEAMS_LIBRARY_SUCCESS:
            return seamsAdapter.addMany(action.payload1, {...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case seamsActions.SeamsActionTypes.LOAD_SEAMS_LIBRARY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case seamsActions.SeamsActionTypes.LOAD_SEAMS_SUCCESS:
            return seamsAdapter.setOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedSeamsId: action.payload1.id
            })
            break;
        case seamsActions.SeamsActionTypes.LOAD_SEAMS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case seamsActions.SeamsActionTypes.CREATE_SEAMS_SUCCESS:
            return seamsAdapter.addOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedSeamsId: action.payload1.id
            })
            break;
        case seamsActions.SeamsActionTypes.CREATE_SEAMS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case seamsActions.SeamsActionTypes.UPDATE_SEAMS_SUCCESS:
            return seamsAdapter.updateOne(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case seamsActions.SeamsActionTypes.UPDATE_SEAMS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case seamsActions.SeamsActionTypes.UPDATE_SEAMS_LIBRARY_SUCCESS:
            return seamsAdapter.updateMany(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case seamsActions.SeamsActionTypes.UPDATE_SEAMS_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case seamsActions.SeamsActionTypes.DELETE_SEAMS_LIBRARY_SUCCESS:
            return seamsAdapter.removeMany(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case seamsActions.SeamsActionTypes.DELETE_SEAMS_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case seamsActions.SeamsActionTypes.UPDATE_SEAMS_DOCUMENT_SUCCESS:
            return seamsAdapter.updateOne(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case seamsActions.SeamsActionTypes.UPDATE_SEAMS_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getSeamsLibraryFeatureState = createFeatureSelector<SeamsState>('seams');

export const getSeamsLibrary = createSelector(
    getSeamsLibraryFeatureState, seamsAdapter.getSelectors().selectAll
)
export const getSeamsLibraryLoading = createSelector(
    getSeamsLibraryFeatureState, (state: SeamsState) => state.loading
)
export const getSeamsLibraryLoaded = createSelector(
    getSeamsLibraryFeatureState, (state: SeamsState) => state.loaded
)
export const getSeamsLibraryError = createSelector(
    getSeamsLibraryFeatureState, (state: SeamsState) => state.error
)
export const getSeamsLibraryMessage = createSelector(
    getSeamsLibraryFeatureState, (state: SeamsState) => state.message
)
export const getSeamsId = createSelector(
    getSeamsLibraryFeatureState, (state: SeamsState) => state.selectedSeamsId
)
export const getSeams = createSelector(
    getSeamsLibraryFeatureState, getSeamsId, state => state.entities[state.selectedSeamsId]
)