<a class="dt-side-nav__link" *ngIf="item.url" [routerLink]="[item.url]" routerLinkActive="active"
   [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
  <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
</a>

<span class="dt-side-nav__link" *ngIf="item.function" (click)="item.function()">
  <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
</span>

<ng-template #itemTemplate>
  <gx-icon *ngIf="item.icon" name="{{item.icon}}" size="lg" [fw]="true"></gx-icon>
  <span *ngIf="item.translate" class="dt-side-nav__text" [translate]="item.translate"></span>
  <span *ngIf="!item.translate" class="dt-side-nav__text">{{item.title}}</span>
</ng-template>
