import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ColorEvent } from 'ngx-color';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { process, State, CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { StorageService } from '../../../helpers/storage.service';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../helpers/user.service';
import { environment } from '../../../../environments/environment';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import * as S3 from 'aws-sdk/clients/s3';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SharedService } from '../../../services/shared.service';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as materialReducer from "../../../reducers/material.reducer";
import * as materialActionTypes from "../../../actions/material.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as colorActionTypes from "../../../actions/color.actions";
import * as supplierActionTypes from "../../../actions/supplier.actions";
import { skip, take, tap } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { Router } from '@angular/router';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../../services/toast.service';

declare var $: any;

@Component({
  selector: 'app-material-custom',
  templateUrl: './material-custom.component.html',
  styleUrls: ['./material-custom.component.css']
})
export class MaterialCustomComponent implements OnInit {

  @ViewChild(LoaderComponent) child: LoaderComponent;
   public materialDataCnt=0;
  Swal = require('sweetalert2');
  showLoader: boolean;
  showLoader1: boolean;
  thumbNails: File[] = [];
  editedThumbnails: File[] = [];
  isThumbnailAdded: boolean = false;
  activeTab = 'materialDetailsTab';
  submitted = false;
  submitted1 = false;
  materialForm: FormGroup;
  documentsForm: FormGroup;
  materialColorForm: FormGroup;
  materialSupForm: FormGroup;
  materialClassificationData: any[];
  docclassification: any[];
  fileData: Array<any> = [];
  editedFileData: Array<any> = [];
  isEditingMode: boolean;
  isViewingMode: boolean;
  imageObject: Array<object> = [];
  sharedUsers: any;
  //removedFileData: Array<any> = [];
  supplierIds: Array<string> = [];
  colorId = [];
  colorSupplierId: string;
  removedDocIds = '';
  files: File[] = [];
  updMaterialList: any[];
  colordocument: any;
  errors = '';
  errorDiv = false;
  alert = '';
  isEditMode: boolean;
  notfoundImg: string;
  editedMaterialData: any;
  materialclassificationValue: string;
  parentClassification: string;
  selected: any;
  treeview: any;
  documentImage = "../../../../assets/images/doccard/document.png";
  thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  userThumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";
  public gridData1: any[];
  public gridData: any;
  public filteredData: any[];
  public gridDatacol: any;
  public gridDatasup: any;
  isCreateMode: boolean;
  UserDetails: any[];
  log: any[];
  apiGridData = [];
  apiData = [];
  public gridSupData: any;
  cards = [];
  sequenceNumber = '';
  dbsupplierData = [];
  supplierData = [];
  matsuppliersIds = [];

  dbcolorData = [];
  matecolorData = [];
  matcolorsIds = [];

  isColorButton: boolean;

  public filterQuery = "";
  public SearchData = "";

  public colorflag = 'matecolor';

  paginationStartcount = 0;
  paginationEndcount = 5;

  public colorstartCountList = 0;
  public colorendCountList = 5;
  public colorstartEndCount = "";
  public colorisPageChanged = false;
  public colorTotalRecordCount: any;
  public colorloading: boolean;


  public supplierstartCountList = 0;
  public supplierendCountList = 5;
  public supplierstartEndCount = "";
  public supplierisPageChanged = false;
  public supplierTotalRecordCount: any;
  public supplierloading: boolean;

  public filter: CompositeFilterDescriptor;

  public matecolorstartCountList = 0;
  public matecolorendCountList = 5;
  public matecolorstartEndCount = "";
  public matecolorisPageChanged = false;
  public matecolorTotalRecordCount: any;
  public matecolorloading: boolean;

  public matesupplierstartCountList = 0;
  public matesupplierendCountList = 5;
  public matesupplierstartEndCount = "";
  public matesupplierisPageChanged = false;
  public matesupplierTotalRecordCount: any;
  public matesupplierloading: boolean;

  public state: State = {
    //  sort: [],
    skip: 0,
    take: 5
  };

  public colorState: State = {
    sort: [],
    skip: 0,
    take: 5
  };

  public supplierState: State = {
    sort: [],
    skip: 0,
    take: 5
  };

  public matecolorState: State = {
    sort: [],
    skip: 0,
    take: 5
  };
  public matesupplierState: State = {
    sort: [],
    skip: 0,
    take: 5
  };
  thumbnailId: any;
  isFileError = false;
  staticMsgs: any;
  isFilesAdded: boolean =false;

  showButton:boolean=false;
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredData = filterBy(this.gridDatacol, filter);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridDatacol = process(this.matecolorData, this.state);
  }

  public dataStateChangeColor(state: DataStateChangeEvent): void {
    this.colorState = state;
    var gridView = {
      data: this.dbcolorData,
      total: this.colorTotalRecordCount,
    };
    this.gridDatacol = process(gridView.data, state); //process(this.colorData, this.state);
    this.gridDatacol.total = gridView.total;
  }

  public dataStateChangeSupplier(state: DataStateChangeEvent): void {
    this.supplierState = state;
    var gridView = {
      data: this.dbsupplierData,
      total: this.supplierTotalRecordCount,
    };
    this.gridDatasup = process(gridView.data, state); //process(this.colorData, this.state);
    this.gridDatasup.total = gridView.total;
  }


  public dataStateChangeMateColor(state: DataStateChangeEvent): void {
    this.matecolorState = state;
    var gridView = {
      data: this.matecolorData,
      total: this.matecolorTotalRecordCount,
    };
    this.gridData = process(gridView.data, state); //process(this.colorData, this.state);
    this.gridData.total = gridView.total;
  }

  public dataStateChangeMateSupplier(state: DataStateChangeEvent): void {
    this.matesupplierState = state;
    var gridView = {
      data: this.supplierData,
      total: this.matesupplierTotalRecordCount,
    };
    this.gridSupData = process(gridView.data, state); //process(this.colorData, this.state);
    this.gridData.total = gridView.total;
  }

  public dataStateChangeSup(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridDatasup = process(this.supplierData, this.state);
  }
  public dataSupStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridSupData = process(this.supplierData, this.state);
  }
  options = [
    { value: 'Pantone', label: 'Pantone' }
  ];


  onPageChangeColor(e: PageChangeEvent): void {
    this.colorisPageChanged = true;
    this.colorState.skip = e.skip;
    this.colorState.take = e.take;
    this.colorstartCountList = e.skip;
    this.colorendCountList = e.skip + e.take;
    if (this.colorflag == 'matecolor') {
      this.getAllColors('matecolor');
    }
    else {
      this.getAllColors('supcolor');
    }
  }

  onPageChangeSupplier(e: PageChangeEvent): void {
    this.supplierisPageChanged = true;
    this.supplierState.skip = e.skip;
    this.supplierState.take = e.take;
    this.supplierstartCountList = e.skip;
    this.supplierendCountList = e.skip + e.take;
    this.getAllSupplierData();
  }


  onPageChangeMateColor(e: PageChangeEvent): void {
    this.matecolorisPageChanged = true;
    this.matecolorState.skip = e.skip;
    this.matecolorState.take = e.take;
    this.matecolorstartCountList = e.skip;
    this.matecolorendCountList = e.skip + e.take;
    this.getMaterialColors();
  }

  onPageChangeMateSupplier(e: PageChangeEvent): void {
    this.matesupplierisPageChanged = true;
    this.matesupplierState.skip = e.skip;
    this.matesupplierState.take = e.take;
    this.matesupplierstartCountList = e.skip;
    this.matesupplierendCountList = e.skip + e.take;
    this.getMaterialSuppliers();
  }


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private dataservice: DataService,
    private storageservice: StorageService,
    public userservice: UserService,
    private logger: LogService,
    private dialogRef: MatDialogRef<MaterialCustomComponent>,
    private aesdecryptservice: AESDecryptService,
    private _lightbox: Lightbox,
    private toastService: ToastService,
    private store: Store<any>) {
    this.documentsForm = this.fb.group({
      documents: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    this.dataservice.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
  //  this.isThumbnailAdded = true;
    this.colorSupplierId = "";
    if (this.storageservice.getMaterialId()) {
      this.isCreateMode = false;
      if (this.storageservice.getEditedMaterialDetailsId()) {
        this.storageservice.removeMaterialDetailsId();
        this.editMaterial();
      }
      else {
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.getMaterialDetails();
      }
    }
    else {
      this.getMaterialClassification("Material");
       this.isCreateMode = true;
      // this.isEditMode = false;
      // this.isEditingMode = false;
      // this.isViewingMode = false;
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }
    this.loadSelectPicker();
    this.initForm();
    this.sharedService.sendClickEvent();
  }

  get f1() { return this.materialColorForm.controls; }
  get fa() { return this.f1.formArray as FormArray; }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.userservice.logout();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
          this.sessionTimedOut();
          this.userservice.logout();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
      this.showLoader = false;
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.staticMsgs.unResponsive.error);

    }
    this.showLoader = false;
  }

  getDocumentClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.docclassification = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.logger.info(this.staticMsgs.matDetail.docLoad, "MaterialDetails");
          this.showLoader = false;
          if (this.editedMaterialData.documents) {
            if (this.editedMaterialData.documents.length > 0) {
              this.editedFileData = [];
              this.removedDocIds = "";
              this.isFilesAdded=true;
              let control = <FormArray>this.documentsForm.controls.documents;
              if (control.length > 0) {
                this.documentsForm = this.fb.group({
                  documents: this.fb.array([])
                })
                this.showLoader = false;
              }
              let editeddata = JSON.stringify(this.editedMaterialData.documents);
              let data = JSON.parse(editeddata);
              this.reloadDocumentFormData(data);
              this.showLoader = false;
            }
          }
        } else {
          this.showLoader = false;
          // this.error("Failed:" + classificationData.common.message?.item1)
          this.toastService.showError(classificationData.common.message?.item1);
          this.logger.error(classificationData.common.message?.item1, "MaterialDetails");
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, "MaterialDetails");
        // this.getError(classificationData.common.error);
        this.toastService.showError(classificationData.common.error);
        this.showLoader = false;
      }
    })

    /* this.dataservice.getClassification(module).subscribe(response => {
     if (response.body['item2'] === "Success") {
       this.docclassification = response.body['item1'][0]["items"];
       this.logger.info("Document classification loaded successfully", "MaterialDetails");
       //console.log(JSON.stringify(this.documentClassificationData ))
       // this.refreshDocTree();
       this.showLoader = false;
     }
     else {
       this.error(response.body['item1']);
       this.logger.error(response.body['item1'], "MaterialDetails");
       this.showLoader = false;
     }
   }, err => {
     this.logger.error(err.error.message, "MaterialDetails");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
     } else if (err.error.message === "The incoming token has expired") {
       this.sessionTimedOut();
     } else if (err.error.message === "Unauthorized") {
       this.userservice.error();
     }
     else {
       this.error(err.message);
       this.logger.error(err.message, "MaterialDetails");
     }
     this.showLoader = false;
   }); */
  }



  getAllColors(colorflag) {
    this.colorloading = true;
    this.colorstartEndCount = this.colorstartCountList + "-" + this.colorendCountList;
    this.dataservice.getDetailsByModuleName('Colours',this.colorstartEndCount).subscribe(response => {
      if (response['body']['item2'] === 'Success') {
          if (response['body']['item1'].length > 0 && !response['body']['item2'].toLowerCase().includes('error')) {
            response['body']['item1'].forEach(x => {
                x.name = this.aesdecryptservice.decryptData(this.dataservice.Key, x.name);
                x.classification = this.aesdecryptservice.decryptData(this.dataservice.Key, x.classification);
                x.colorStandard = this.aesdecryptservice.decryptData(this.dataservice.Key, x.colorStandard);

                if (x.hexcode) {
                    x.hexcode = this.aesdecryptservice.decryptData(this.dataservice.Key, x.hexcode);

                } else if (x.thumbnailFiles) {
                    x.thumbnailFiles['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.thumbnailFiles['thumbnail']);

                }
                x.createdUser.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, x.createdUser.firstName);
                x.createdUser.lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, x.createdUser.lastName);
                x.CreatedbyName= x.createdUser.firstName;
                x.status = this.aesdecryptservice.decryptData(this.dataservice.Key, x.status);
            });
        }
        this.logger.info(this.staticMsgs.matDetail.colorList, "MaterialDetails");
        this.dbcolorData = response['body']['item1'];
          this.colorTotalRecordCount = response['body']['item3'];

          if (colorflag == 'matecolor') {
            this.colorflag = 'matecolor';

            if (this.matcolorsIds.length > 0) {
              this.matcolorsIds.forEach((vl) => {
                this.removeColorElement(vl);
              });
            }
          }

          if (colorflag == 'supcolor') {
            this.colorflag = 'supcolor';
            var supplier = this.supplierData.filter((x, i) => x.id === this.colorSupplierId);
            if (supplier && supplier.length > 0) {
              if (supplier[0].colours) {
                supplier[0].colours.forEach((vl) => {
                  this.removeColorElement(vl.id);
                });
              }
            }
          }


          var gridView = {
            data: this.dbcolorData,
            total: this.colorTotalRecordCount,
          };
          this.gridDatacol = gridView; //process(this.colorData, this.state);
          this.colorloading = false;
        } else {
        this.colorloading = false;
        // this.error("Failed:" + response['body']['item1'])
        this.toastService.showError(response['body']['item1']);
        this.logger.error(response['body']['item1'], "MaterialDetails");
      }
    })

    /* this.dataservice.getColor(this.colorstartEndCount).subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.logger.info("All color list loaded successfully", "MaterialDetails");

        for (let i = 0; i < response.body['item1'].length; i++) {
          response.body['item1'][i]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['name']);
          response.body['item1'][i]['classification'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['classification']);
          response.body['item1'][i]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['colorStandard']);
          response.body['item1'][i].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].createdBy.firstName);
          response.body['item1'][i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].status);
          if (response.body['item1'][i]['hexcode']) {
            response.body['item1'][i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['hexcode']);

          } else if (response.body['item1'][i]['thumbnailFiles']) {
            response.body['item1'][i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['thumbnailFiles']['thumbnail']);
            response.body['item1'][i]['thumbnailFiles']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['thumbnailFiles']['name']);
          }
        }

        this.dbcolorData = response.body['item1'];
        this.colorTotalRecordCount = response.body['item3'];

        if (colorflag == 'matecolor') {
          this.colorflag = 'matecolor';

          if (this.matcolorsIds.length > 0) {
            this.matcolorsIds.forEach((vl) => {
              this.removeColorElement(vl);
            });
          }
        }

        if (colorflag == 'supcolor') {
          this.colorflag = 'supcolor';
          var supplier = this.supplierData.filter((x, i) => x.id === this.colorSupplierId);
          if (supplier) {
            if (supplier[0].colours) {
              supplier[0].colours.forEach((vl) => {
                this.removeColorElement(vl.id);
              });
            }
          }
        }

        var gridView = {
          data: this.dbcolorData,
          total: this.colorTotalRecordCount,
        };
        this.gridDatacol = gridView; //process(this.colorData, this.state);
        this.colorloading = false;
      }
      else {
        this.error(response.body['item1']);
        this.logger.error(response.body['item1'], "MaterialDetails");
        this.colorloading = false;
      }
    }, err => {
      this.colorloading = false;
      this.logger.error(err.error.message, "MaterialDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialDetails");
      }

    }); */
  }


  removeColorElement(element: string) {
    this.dbcolorData.forEach((value, index) => {
      if (value.id == element) {
        this.dbcolorData = JSON.parse(JSON.stringify(this.dbcolorData));
        this.dbcolorData.splice(index, 1);
      }
    });
  }

  getAllSupplierData() {
    this.supplierloading = true;
    this.supplierstartEndCount = this.supplierstartCountList + "-" + this.supplierendCountList;
    this.dataservice.getDetailsByModuleName('Suppliers', this.supplierstartEndCount).subscribe(response => {
      if (response['body']['item2'] === 'Success') {
        response['body']['item1'].forEach(x => {
          if (x.contact) {
            x.contact.forEach(y => {
              y.country = this.aesdecryptservice.decryptData(this.dataservice.Key, y.country);
              y.state = this.aesdecryptservice.decryptData(this.dataservice.Key, y.state);
            });
          }
          x.classification = this.aesdecryptservice.decryptData(this.dataservice.Key, x.classification);
          x.name = this.aesdecryptservice.decryptData(this.dataservice.Key, x.name);
          x.phoneNo = this.aesdecryptservice.decryptData(this.dataservice.Key, x.phoneNo);
          x.phoneNumber = this.aesdecryptservice.decryptData(this.dataservice.Key, x.phoneNumber);
          x.email = this.aesdecryptservice.decryptData(this.dataservice.Key, x.email);
          x.auditStatus = this.aesdecryptservice.decryptData(this.dataservice.Key, x.auditStatus);
          x.sequence = this.aesdecryptservice.decryptData(this.dataservice.Key, x.sequence);
          if (x.createdUser != null) {
            x.createdUser.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, x.createdUser.firstName);
            x.CreatedbyName = x.createdUser.firstName;
          }
        });
        this.dbsupplierData = response['body']['item1'];
        this.supplierData = this.dbsupplierData;
        this.supplierTotalRecordCount = response['body']['item3']
        if (this.matsuppliersIds.length > 0) {

          this.matsuppliersIds.forEach((vl) => {
            this.removeSupplierElement(vl);
          });
        }

        var gridView = {
          data: this.dbsupplierData,
          total: this.supplierTotalRecordCount,
        };
        this.gridDatasup = gridView; //process(this.dbsupplierData, this.state);
        this.supplierloading = false;
      } else {
        this.supplierloading = false;
        // this.error("Failed:" + response['body']['item1'])
        this.toastService.showError(response['body']['item1']);
        this.logger.error(response['body']['item1'], "MaterialDetails");
      }
    }, err => {
      // this.getError(err);
      this.toastService.showError(err);
      this.logger.error(err.error.message, "MaterialDetails");
      this.supplierloading = false;
    })
  }

  removeSupplierElement(element: string) {
    this.dbsupplierData.forEach((value, index) => {
      if (value.id == element) {
        this.dbsupplierData = JSON.parse(JSON.stringify(this.dbsupplierData));
        this.dbsupplierData.splice(index, 1);
      }
    });
  }


  onColRemove(index, dataItem) {
    Swal.fire({
      title: this.staticMsgs.matDetail.deleteColor,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.matDetail.deleteit
    }).then((result) => {
      if (result.value) {
        let materialid = this.storageservice.getMaterialId();
        const formData = new FormData();
        formData.append('materialid', materialid);
        formData.append('coloursdelete', dataItem.id);
        //console.log(formData);
        this.showLoader1 = true;
        this.store.dispatch(new materialActionTypes.CreateMaterialColor(formData));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialColorLibrary) => {
          if (materialColorLibrary.material.error === "") {
            if (materialColorLibrary.material.message['item2'] === 'Success') {
              this.cards = materialColorLibrary.material.message['item1'];
              this.editedMaterialData = materialColorLibrary.material.message['item1'];
              if (this.editedMaterialData.colours) {
                this.refreshSelect();
              }
              this.showLoader1 = false;
              // this.success(this.staticMsgs.matDetail.colorDelete1);
              this.toastService.showSuccess(this.staticMsgs.matDetail.colorDelete1);
              this.logger.info(this.staticMsgs.matDetail.colorDelete, "MaterialDetails");
              this.getMaterialColors();
            } else {
              this.showLoader1 = false;
              // this.error("Failed:" + materialColorLibrary.material.message['item1'])
              this.toastService.showError( materialColorLibrary.material.message['item1']);
              this.logger.error(materialColorLibrary.material.message['item1'], "MaterialDetails");
            }

          } else {
            this.logger.error(materialColorLibrary.material.error.error.message, "MaterialDetails");
            // this.getError(materialColorLibrary.material.error);
            this.toastService.showError(materialColorLibrary.material.error);
            this.showLoader1 = false;
          }
        })

        /* this.dataservice.addMaterialColor(formData).subscribe(response => {
          if (response.status === 200) {
            if (response.body['item2'] === "Success") {
              this.showLoader1 = false;
              this.success("Color Deleted Successfully!");
              this.logger.info("Color Deleted Successfully", "MaterialDetails");
              this.getMaterialColors();

              // this.cards = response.body.item1;
              // this.editedMaterialData = response.body['item1'];
              // if (this.editedMaterialData.colours) {
              //   this.matecolorData = this.editedMaterialData.colours
              //   for (let i = 0; i < this.matecolorData.length; i++) {
              //     this.matecolorData[i]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['name']);
              //     //this.matecolorData[i]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.matecolorData[i]['classification']);
              //     this.matecolorData[i]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['colorStandard']);
              //     this.matecolorData[i].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].createdBy.firstName);
              //     this.matecolorData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].status);


              //     if (this.matecolorData[i]['hexcode']) {
              //       this.matecolorData[i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['hexcode']);

              //     } else {
              //       this.matecolorData[i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['thumbnailFiles']['thumbnail']);

              //     }


              //   }
              //   var gridView = {
              //     data: this.matecolorData,
              //     total: this.matecolorTotalRecordCount,
              //   };
              //   this.gridData = gridView;//process(this.matecolorData, this.state);

              //   this.refreshSelect();
              // }


            }
            else {
              this.error(response.body['item1']);
              this.logger.error(response.body['item1'], "MaterialDetails");
              this.showLoader1 = false;
            }
          }
        }
          , err => {
            this.logger.error(err.error.message, "MaterialDetails");
            if (err.error.message === "Network error communicating with endpoint") {
              this.error(err.error.message);
            } else if (err.error.message === "The incoming token has expired") {
              this.sessionTimedOut();
            } else if (err.error.message === "Unauthorized") {
              this.userservice.error();
            }
            else {
              this.error(err.message);
              this.logger.error(err.message, "MaterialDetails");
            }
            this.showLoader1 = false;
          }); */

        // reset all rows back to edit mode
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });


  }

  onSupRemove(index, dataItem) {
    Swal.fire({
      title: this.staticMsgs.matDetail.matSupp,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.matDetail.deleteit
    }).then((result) => {
      if (result.value) {
        let materialid = this.storageservice.getMaterialId();
        const formData = new FormData();
        formData.append('materialid', materialid);
        formData.append('suppliersdelete', dataItem.id);
        //console.log(formData);
        this.showLoader1 = true;
        this.store.dispatch(new materialActionTypes.CreateMaterialSupplier(formData));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialSupplier) => {
          if (materialSupplier.material.error === "") {
            if (materialSupplier.material.message['item2'] === 'Success') {
              this.editedMaterialData = materialSupplier.material.message['item1'];
              this.cards = materialSupplier.material.message.item1;
              this.getMaterialSuppliers();
              this.showLoader1 = false;
              // this.success(this.staticMsgs.matDetail.suppDelete1);
              this.toastService.showSuccess(this.staticMsgs.matDetail.suppDelete1);
              this.logger.info(this.staticMsgs.matDetail.suppDelete, "MaterialDetails");

            } else {
              this.showLoader1 = false;
              // this.error("Failed:" + materialSupplier.material.message['item1'])
              this.toastService.showError(materialSupplier.material.message['item1']);
              this.logger.error(materialSupplier.material.message['item1'], "MaterialDetails");
            }

          } else {
            this.logger.error(materialSupplier.material.error.error.message, "MaterialDetails");
            // this.getError(materialSupplier.material.error);
            this.toastService.showError(materialSupplier.material.error);
            this.showLoader1 = false;
          }
        })

        /* this.dataservice.addMaterialSupplier(formData).subscribe(response => {
          if (response.status === 200) {
            if (response.body['item2'] === "Success") {

              this.showLoader1 = false;
              this.success("Supplier Deleted Successfully!");
              this.logger.info("Supplier Deleted Successfully", "MaterialDetails");
              this.getMaterialSuppliers();

              // this.editedMaterialData = response.body['item1'];
              // this.cards = response.body.item1;
              // if (this.editedMaterialData.suppliers) {
              //   this.supplierData = this.editedMaterialData.suppliers;
              //   for (let i = 0; i < this.supplierData.length; i++) {

              //     this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
              //     this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
              //     this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
              //     // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
              //     // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
              //     this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
              //     //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
              //     this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
              //     // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
              //     if (this.supplierData[i].colours != null) {
              //       for (let j = 0; j < this.supplierData[i].colours.length; j++) {
              //         this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
              //         //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
              //         this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
              //         this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
              //         this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);

              //         if (this.supplierData[i].colours[j]['hexcode']) {
              //           this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);

              //         } else {
              //           this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);

              //         }


              //       }
              //     }

              //   }
              //   this.gridSupData = process(this.supplierData, this.state);
              // }

            }
            else {
              //console.log(response)
              //alert(response.body['item2'])
              this.error(response.body['item1']);
              this.logger.error(response.body['item1'], "MaterialDetails");
              this.showLoader1 = false;
            }
          }
        }, err => {
          this.logger.error(err.error.message, "MaterialDetails");
          if (err.error.message === "Network error communicating with endpoint") {
            this.error(err.error.message);
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
          } else if (err.error.message === "Unauthorized") {
            this.userservice.error();
          }
          else {
            this.error(err.message);
            this.logger.error(err.message, "MaterialDetails");
          }
          this.showLoader1 = false;
        }); */

      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  onSupColorRemove(id, dataItem) {
    Swal.fire({
      title: this.staticMsgs.matDetail.matSuppCol,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.matDetail.deleteit
    }).then((result) => {
      if (result.value) {
        let materialid = this.storageservice.getMaterialId();
        const formData = new FormData();
        formData.append('materialid', materialid);
        formData.append('supplierid', this.colorSupplierId);
        formData.append('colourtosdelete', dataItem.id);
        //console.log(formData);
        this.showLoader1 = true;
        this.store.dispatch(new materialActionTypes.CreateMaterialSupplierColor(formData));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialSupplierColor) => {
          if (materialSupplierColor.material.error === "") {
            if (materialSupplierColor.material.message['item2'] === 'Success') {
              this.editedMaterialData = materialSupplierColor.material.message['item1'];
              this.cards = materialSupplierColor.material.message.item1;
              if (this.editedMaterialData.suppliers) {
                this.supplierData = this.editedMaterialData.suppliers;
                this.gridSupData = process(this.supplierData, this.state);
              }
              // this.success(this.staticMsgs.matDetail.suppColor1);
              this.toastService.showSuccess(this.staticMsgs.matDetail.suppColor1)
              this.logger.info(this.staticMsgs.matDetail.suppColor, "MaterialDetails");
              this.showLoader1 = false;
            } else {
              this.showLoader1 = false;
              // this.error("Failed:" + materialSupplierColor.material.message['item1'])
              this.toastService.showError(materialSupplierColor.material.message['item1']);
              this.logger.error(materialSupplierColor.material.message['item1'], "MaterialDetails");
            }

          } else {
            this.logger.error(materialSupplierColor.material.error.error.message, "MaterialDetails");
            // this.getError(materialSupplierColor.material.error);
            this.toastService.showError(materialSupplierColor.material.error);
            this.showLoader1 = false;
          }
        })

        /* this.dataservice.addSupplierColor(formData).subscribe(response => {
          if (response.status === 200) {
            if (response.body['item2'] === "Success") {
              this.editedMaterialData = response.body['item1'];
              this.cards = response.body.item1;
              if (this.editedMaterialData.suppliers) {
                this.supplierData = this.editedMaterialData.suppliers;
                for (let i = 0; i < this.supplierData.length; i++) {

                  this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
                  this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
                  this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
                  // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
                  // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
                  this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
                  //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
                  this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
                  // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
                  if (this.supplierData[i].colours != null) {
                    for (let j = 0; j < this.supplierData[i].colours.length; j++) {
                      this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
                      //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
                      this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
                      this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
                      this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);

                      if (this.supplierData[i].colours[j]['hexcode']) {
                        this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);

                      } else {
                        this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);

                      }


                    }
                  }

                }
                this.gridSupData = process(this.supplierData, this.state);
              }
              this.success("Supplier Color Deleted Successfully!");
              this.logger.info("Color classification loaded successfully", "MaterialDetails");
              this.showLoader1 = false;

            }
            else {
              //console.log(response)
              //alert(response.body['item2'])
              this.error(response.body['item2']);
              this.logger.error(response.body['item2'], "MaterialDetails");
              this.showLoader1 = false;
            }
          }
        }, err => {
          this.logger.error(err.error.message, "MaterialDetails");

          if (err.error.message === "Network error communicating with endpoint") {
            this.error(err.error.message);
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
          } else if (err.error.message === "Unauthorized") {
            this.userservice.error();
          }
          else {
            this.error(err.message);
            this.logger.error(err.message, "MaterialDetails");

          }
          this.showLoader1 = false;
        }); */



        // reset all rows back to edit mode
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  createColorRea() {
    this.showLoader1 = true;
    let controlleng = this.colorId.toString();
    if (controlleng == '') { 
      // this.error("please select color");
      this.toastService.showError("please select color");
      this.showLoader1 = false; return false; 
    }
    let controlleng1 = controlleng.split(',');
    let colcount = controlleng1.length;
    let materialid = this.storageservice.getMaterialId();
    //// alert(controlleng1.length)
    const formData = new FormData();
    formData.append('materialid', materialid);
    formData.append('colpickcount', colcount.toString());
    for (let i = 0; i < controlleng1.length; i++) {
      formData.append('MCcolourid' + '[' + i + ']', this.colorId[i]);
      formData.append('colPickTag' + '[' + i + ']', "Add");
    }

    if (this.colorSupplierId != "") {

      const formData = new FormData();
      formData.append('materialid', materialid);
      formData.append('supplierid', this.colorSupplierId);
      formData.append('colourcount', colcount.toString());
      for (let i = 0; i < controlleng1.length; i++) {
        formData.append('colourid' + '[' + i + ']', this.colorId[i]);
        formData.append('coltag' + '[' + i + ']', "Add");
      }
      this.showLoader1 = true;
      this.store.dispatch(new materialActionTypes.CreateMaterialSupplierColor(formData));
      this.colorId = [];
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialSupplierColor) => {
        if (materialSupplierColor.material.error === "") {
          if (materialSupplierColor.material.message['item2'] === 'Success') {
            this.cards = materialSupplierColor.material.message.item1;
            this.editedMaterialData = materialSupplierColor.material.message['item1'];
            if (this.editedMaterialData.suppliers) {
              this.supplierData = this.editedMaterialData.suppliers;
              this.gridSupData = process(this.supplierData, this.state);
            }
            // this.success(this.staticMsgs.matDetail.addedColor);
            this.toastService.showSuccess(this.staticMsgs.matDetail.addedColor);
            this.logger.info(this.staticMsgs.matDetail.addedColor1, "MaterialDetails");
            this.showLoader1 = false;
            $('.chkbxqcolor').each(function () {
              $(this).prop('checked', false);
            });
          } else {
            this.showLoader1 = false;
            // this.error("Failed:" + materialSupplierColor.material.message['item1'])
            this.toastService.showError(materialSupplierColor.material.message['item1']);
            this.logger.error(materialSupplierColor.material.message['item1'], "MaterialDetails");
          }

        } else {
          this.logger.error(materialSupplierColor.material.error.error.message, "MaterialDetails");
          // this.getError(materialSupplierColor.material.error);
          this.toastService.showError(materialSupplierColor.material.error);
          this.showLoader1 = false;
        }
      })

      /* this.dataservice.addSupplierColor(formData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {

            this.cards = response.body.item1;
            this.editedMaterialData = response.body['item1'];
            if (this.editedMaterialData.suppliers) {
              this.supplierData = this.editedMaterialData.suppliers;
              for (let i = 0; i < this.supplierData.length; i++) {

                this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
                this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
                this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
                // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
                // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
                this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
                //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
                this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
                // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
                if (this.supplierData[i].colours != null) {
                  for (let j = 0; j < this.supplierData[i].colours.length; j++) {
                    this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
                    //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
                    this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
                    this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
                    this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);

                    if (this.supplierData[i].colours[j]['hexcode']) {
                      this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);

                    } else {
                      this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);

                    }


                  }
                }

              }
              this.gridSupData = process(this.supplierData, this.state);

            }
            this.success("Added to Supplier Color Successfully!");
            this.logger.info("Supplier Color Added Successfully", "MaterialDetails");
            this.showLoader1 = false;
            $('.chkbxqcolor').each(function () {
              $(this).prop('checked', false);
            });
          }
          else {
            //console.log(response)
            //alert(response.body['item2'])
            this.error(response.body['item2']);
            this.logger.error(response.body['item2'], "MaterialDetails");
            this.showLoader1 = false;
          }
        }
      }, err => {
        this.logger.error(err.error.message, "MaterialDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.showLoader1 = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.showLoader1 = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.showLoader1 = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialDetails");

        }
        this.showLoader1 = false;
      }); */
    }
    else {
      this.colorId = [];
      this.showLoader1 = true;
      this.store.dispatch(new materialActionTypes.CreateMaterialColor(formData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialColorLibrary) => {
        if (materialColorLibrary.material.error === "") {
          if (materialColorLibrary.material.message['item2'] === 'Success') {
            this.cards = materialColorLibrary.material.message['item1'];
            this.editedMaterialData = materialColorLibrary.material.message['item1'];
            if (this.editedMaterialData.colours) {
              this.refreshSelect();
            }
            // this.success(this.staticMsgs.matDetail.matColor1);
            this.toastService.showSuccess(this.staticMsgs.matDetail.matColor1);
            this.logger.info(this.staticMsgs.matDetail.matColor1, "MaterialDetails");
            this.showLoader1 = false;
            $('.chkbxqcolor').each(function () {
              $(this).prop('checked', false);
            });
            this.getMaterialColors();
          } else {
            this.showLoader1 = false;
            // this.error("Failed:" + materialColorLibrary.material.message['item1'])
            this.toastService.showError(materialColorLibrary.material.message['item1']);
            this.logger.error(materialColorLibrary.material.message['item1'], "MaterialDetails");
          }

        } else {
          this.logger.error(materialColorLibrary.material.error.error.message, "MaterialDetails");
          // this.getError(materialColorLibrary.material.error);
          this.toastService.showError(materialColorLibrary.material.error);
          this.showLoader1 = false;
        }
      })
      /* this.dataservice.addMaterialColor(formData).subscribe(response => {
        // //console.log(response);
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          this.success("Material color Created Successfully!");
          this.logger.info("Material color Created Successfully", "MaterialDetails");
          $('.chkbxqcolor').each(function () {
            $(this).prop('checked', false);
          });
          this.getMaterialColors();

          // //console.log(response.body.item1);
          // this.cards = response.body.item1;
          // this.editedMaterialData = response.body['item1'];
          // if (this.editedMaterialData.colours) {
          //   this.matecolorData = this.editedMaterialData.colours
          //   for (let i = 0; i < this.matecolorData.length; i++) {
          //     this.matecolorData[i]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['name']);
          //     //this.matecolorData[i]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.matecolorData[i]['classification']);
          //     this.matecolorData[i]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['colorStandard']);
          //     this.matecolorData[i].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].createdBy.firstName);
          //     this.matecolorData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].status);

          //     if (this.matecolorData[i]['hexcode']) {
          //       this.matecolorData[i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['hexcode']);

          //     } else {
          //       this.matecolorData[i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['thumbnailFiles']['thumbnail']);

          //     }
          //   }
          //   var gridView = {
          //     data: this.matecolorData,
          //     total: this.matecolorTotalRecordCount,
          //   };
          //   this.gridData = gridView;//process(this.matecolorData, this.state);

          // }

        }
        else {
          this.showLoader1 = false;
        }
        this.showLoader1 = false;
      }, err => {
        this.logger.error(err.error.message, "MaterialDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialDetails");
        }
        this.showLoader1 = false;
      }); */
    }
  }


  /*** Color display table End */
  // Method to initialise the Material form.
  initForm() {
    this.showLoader = true;
    this.materialForm = this.fb.group({
      // materialclassification: ['', [Validators.required]],
      // materialid: [{ value: '', disabled: true }],
      // materialseq: [''],
      // materialname: ['', [Validators.pattern('^[a-zA-Z0-9 ]{1,50}$')]],
      // cuttablewidth: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // widthuom: [''],
      // weightgsm: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // subtype: ['',[Validators.required]],
      // size: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // width: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // sizeuom: [''],
      // content: [''],
      // substabce: ['', [Validators.maxLength(20),,Validators.pattern('^[a-zA-Z0-9% ]{1,50}$')]],
      // comments: ['', [Validators.maxLength(500)]],
      // substanceuom: [''],
      // rawhide: [''],
      // cost: ['', [Validators.maxLength(20),Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      // trimcost: ['', [Validators.maxLength(20)]],
      // fabriccomp: ['']
            materialclassification: [''],
      materialid: [{ value: '', disabled: true }],
      materialseq: [''],
      materialname: ['', [Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      cuttablewidth: ['',Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')],
      widthuom: [''],
      weightgsm: ['',Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')],
      fabriccomp: [''],
      subtype: [''],
      size: ['',Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')],
      width: ['',Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')],
      sizeuom: [''],
      content: [''],
      substabce: ['',Validators.pattern('^[a-zA-Z0-9% ]{1,50}$')],
      comments: ['',[Validators.maxLength(500)]],
      substanceuom: [''],
      rawhide: [''],
      cost: ['',Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')],
      trimcost: [''],
    });
    this.showLoader = false;
  }

  onChange(id: string, val: boolean) {
    if (val == true) {
      this.colorId.push(id);
      //console.log(this.colorId);
    }
    else {
      var index = this.colorId.indexOf(id);
      this.colorId.splice(index, 1);
      //console.log(this.colorId);
    }

  }

  public listItems: Array<{ text: string, value: string }> = [
    { text: "Active", value: "Active" },
    { text: "Inactive", value: "Inactive" }
  ];

  get f() { return this.materialForm.controls; }

  numberOnly(e: any) {
    var regex = new RegExp("^[0-9.]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characterOnly(e: any) {
    var regex = new RegExp("^[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  addDecimal(value:any)
  {
    switch (value){
 case 'cuttablewidth':
  if(this.f.cuttablewidth.value.includes('.') || this.f.cuttablewidth.value.length <= 0){

  }else{
    this.f.cuttablewidth.setValue(this.f.cuttablewidth.value + '.00');
  }
  break;

  case 'weight':
  if(!this.f.weightgsm.value.includes('.') || this.f.weightgsm.value.length !<= 0){
    this.f.weightgsm.setValue(this.f.weightgsm.value + '.00');
  }
  break;

  case 'size':
  if(this.f.size.value.includes('.') || this.f.size.value.length <= 0){

  }else{
    this.f.size.setValue(this.f.size.value + '.00');
  }
  break;

  case 'width':
  if(this.f.width.value.includes('.') || this.f.width.value.length <= 0){

  }else{
    this.f.width.setValue(this.f.width.value + '.00');
  }
  case 'substabce':
    if(this.f.substabce.value.includes('.') || this.f.substabce.value.length <= 0){

    }else{
      this.f.substabce.setValue(this.f.substabce.value + '.00');
    }
}
 }

  getMaterialClassification(module: string) {
    this.loadSelectPicker();
    this.showLoader = true;
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.loadSelectPicker();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          this.materialClassificationData = classificationData.common.message['item1'][0]['items'];
          this.logger.info(this.staticMsgs.matDetail.matClass, "MaterialDetails");
          this.refreshTree();
          if (this.isEditingMode) {
            this.setMaterialTreeValue(this.editedMaterialData["classification"]);
          }
          this.showLoader = false;
        } else {
          this.refreshTree();
          this.showLoader = false;
          // this.error("Failed:" + classificationData.common.message?.item1)
          this.toastService.showError(classificationData.common.message?.item1);
          this.logger.error(classificationData.common.message?.item1, "MaterialDetails");
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, "MaterialDetails");
        // this.getError(classificationData.common.error);
        this.toastService.showError(classificationData.common.error);
        this.showLoader = false;
      }
    })
  }

  getMaterialDetails() {
    this.showLoader1 = true;
    //console.log(this.storageservice.getEditedMaterialId())
    this.store.dispatch(new materialActionTypes.LoadMaterial(this.storageservice.getMaterialId()));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialDetails) => {
      if (materialDetails.material.error === "") {
        if (materialDetails.material.message['item2'] === 'Success') {
          this.editedMaterialData = materialDetails.material.message['item1'];
          this.logger.info(materialDetails.material.message['item1']["name"] + " loaded successfully", "MaterialDetails");
          this.showLoader1 = false;
          this.initForm();
          this.getMaterialClassification("Material");
          this.materialForm.get('materialclassification').setValue(materialDetails.material.message['item1']["classification"]);
          this.materialclassificationValue = materialDetails.material.message['item1']["classification"];
         this.parentClassification= materialDetails.material.message['item1']["baseClassification"];
         if (this.parentClassification === "Trims" || this.parentClassification === "Product Packaging") {
          this.materialForm.get('subtype').enable();
          setTimeout(() => {
                $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
              }, 10);
          if (this.parentClassification === "Trims"){
            this.materialForm.get('content').enable();
            this.materialForm.get('sizeuom').enable();
            this.materialForm.get('size').enable();
            this.materialForm.get('width').enable();
            setTimeout(() => {
              $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
            }, 10);
          }
        }
          if (this.parentClassification === "Material"){
            this.materialForm.get('substabce').enable();
            this.materialForm.get('substanceuom').enable();
            this.materialForm.get('rawhide').enable();
            this.materialForm.get('cost').enable();
            setTimeout(() => {
              $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
            }, 10);
          }
          this.sequenceNumber = materialDetails.material.message['item1']["sequence"];

          this.materialForm.get('materialid').setValue(materialDetails.material.message['item1']["id"]);
          this.materialForm.get('materialseq').setValue(materialDetails.material.message['item1']["sequence"]);
          this.materialForm.get('materialname').setValue(materialDetails.material.message['item1']["name"]);
          this.materialForm.get('cuttablewidth').setValue(materialDetails.material.message['item1']["cuttablewidth"]);
          this.materialForm.get('fabriccomp').setValue(materialDetails.material.message['item1']["fabriccomp"]);
          this.materialForm.get('comments').setValue(materialDetails.material.message['item1']["comments"]);
          this.materialForm.get('widthuom').setValue(materialDetails.material.message['item1']["widthuom"]);
          this.materialForm.get('weightgsm').setValue(materialDetails.material.message['item1']["weightgsm"]);
          this.materialForm.get('width').setValue(materialDetails.material.message['item1']["width"]);
          this.materialForm.get('subtype').setValue(materialDetails.material.message['item1']["subtype"]);
          this.materialForm.get('size').setValue(materialDetails.material.message['item1']["size"]);
          this.materialForm.get('content').setValue(materialDetails.material.message['item1']["content"]);
          this.materialForm.get('sizeuom').setValue(materialDetails.material.message['item1']["sizeuom"]);
          this.materialForm.get('substabce').setValue(materialDetails.material.message['item1']["substabce"]);
          this.materialForm.get('substanceuom').setValue(materialDetails.material.message['item1']["substanceuom"]);
          this.materialForm.get('rawhide').setValue(materialDetails.material.message['item1']["rawhide"]);
          this.materialForm.get('cost').setValue(materialDetails.material.message['item1']["cost"]);
          this.materialForm.get('trimcost').setValue(materialDetails.material.message['item1']["trimcost"]);
          if (materialDetails.material.message['item1']["thumbnailFiles"]) {
            this.editedThumbnails = [];
            this.editedThumbnails.push(materialDetails.material.message['item1']["thumbnailFiles"]);

            if(this.editedThumbnails.length > 0){
              this.imageObject=[];
              this.editedThumbnails.forEach((x,idx,array)=>{
                const temp = {
                  'image':x['thumbnail'],
                  'thumbImage':x['thumbnail']
                }
                this.imageObject.push(temp);
              })
            }else{
              const temp = {
                'image':this.thumbnail,
                'thumbImage':this.thumbnail
              }
              this.imageObject.push(temp);
            }



          }
          this.refreshSelect();
        } else {
          this.showLoader1 = false;
          // this.error("Failed:" + materialDetails.material.message['item1'])
          this.toastService.showError(materialDetails.material.message['item1']);
          this.logger.error(materialDetails.material.message['item1'], "MaterialDetails");
        }
      } else {
        this.logger.error(materialDetails.material.error.error.message, "MaterialDetails");
        // this.getError(materialDetails.material.error);
        this.toastService.showError(materialDetails.material.error);
        this.showLoader1 = false;
      }
    })
    /* this.dataservice.getMaterialById(this.storageservice.getMaterialId()).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.showLoader1 = false;
        //console.log(JSON.stringify(data[0]));
        this.editedMaterialData = response.body['item1'];
        this.logger.info(response.body['item1']["name"] + " loaded successfully", "MaterialDetails");

        this.initForm();
        this.materialForm.get('materialclassification').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["classification"]));
        this.setMaterialTreeValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["classification"]));
        this.materialclassificationValue = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["classification"]);
        this.refreshTree();
        this.materialForm.get('materialid').setValue(response.body['item1']["id"]);
        this.materialForm.get('materialseq').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sequence"]));
        this.materialForm.get('materialname').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["name"]));
        //this.materialForm.get('cuttablewidth').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["cuttablewidth"]));
        this.materialForm.get('fabriccomp').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["fabriccomp"]));
        this.materialForm.get('comments').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["comments"]));
        this.materialForm.get('widthuom').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["widthuom"]));
        this.materialForm.get('weightgsm').setValue(response.body['item1']["weightgsm"]);
        this.materialForm.get('width').setValue(response.body['item1']["width"]);
        this.materialForm.get('subtype').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["subtype"]));
        this.materialForm.get('size').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["size"]));
        this.materialForm.get('content').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["content"]));
        this.materialForm.get('sizeuom').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeuom"]));
        this.materialForm.get('substabce').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["substabce"]));
        this.materialForm.get('substanceuom').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["substanceuom"]));
        this.materialForm.get('rawhide').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["rawhide"]));
        this.materialForm.get('cost').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["cost"]));
        this.materialForm.get('trimcost').setValue(response.body['item1']["trimcost"]);
        if (response.body['item1']["thumbnailFiles"]) {
          response.body['item1']["thumbnailFiles"]['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["thumbnailFiles"]['thumbnail']);
          response.body['item1']["thumbnailFiles"]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["thumbnailFiles"]['name']);
          this.editedThumbnails[0] = response.body['item1']["thumbnailFiles"];

        }
        this.refreshSelect();
      }
      else {
        this.showLoader1 = false;
      }
    }, err => {
      this.logger.error(err.error.message, "MaterialDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader1 = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader1 = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader1 = false;
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialDetails");

      }
      this.showLoader1 = false;
    }); */
  }

  getMaterialColors() {
    this.matecolorloading = true;
    this.showLoader = true;
    this.matecolorstartEndCount = this.matecolorstartCountList + "-" + this.matecolorendCountList;
    this.store.dispatch(new materialActionTypes.LoadMaterialColor(this.storageservice.getMaterialId(), this.matecolorstartEndCount));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialColor) => {
      if (materialColor.material.error === "") {
        this.showLoader = false;
        if (materialColor.material.message['item2'] === 'Success') {
          this.matecolorloading = false;
          var materialcolorData = materialColor.material.message['item1'];
          this.matecolorTotalRecordCount = materialColor.material.message['item3'];
          this.matcolorsIds = materialColor.material.message['item4'];
          this.matecolorData = materialcolorData.colours;
          if (this.matecolorData != null && this.matecolorData.length > 0) {
            var gridView = {
              data: this.matecolorData,
              total: this.matecolorTotalRecordCount,
            };
            this.gridData = gridView;
          }
          this.logger.info(materialColor.material.message['item1']["name"] + " loaded successfully", "MaterialColors");
        } else {
          this.matecolorloading = false;
          this.refreshTree();
          // this.error("Failed:" + materialColor.material.message?.item1)
          this.toastService.showError(materialColor.material.message?.item1);
          this.logger.error(materialColor.material.message?.item1, "MaterialColors");
        }
      } else {
        this.showLoader = false;
        this.logger.error(materialColor.material.error.error.message, "MaterialColors");
        // this.getError(materialColor.material.error);
        this.toastService.showError(materialColor.material.error);
        this.matecolorloading = false;
      }
    })
    /* this.dataservice.getmaterialcolours(this.storageservice.getMaterialId(), this.matecolorstartEndCount).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.matecolorloading = false;
        var materialcolorData = response.body['item1'];
        this.matecolorTotalRecordCount = response.body['item3'];
        this.matcolorsIds = response.body['item4'];
        this.logger.info(response.body['item1']["name"] + " loaded successfully", "MaterialColors");
        if (this.matcolorsIds.length > 0) {
          this.matecolorData = materialcolorData.colours
          for (let i = 0; i < this.matecolorData.length; i++) {
            this.matecolorData[i]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['name']);
            //  this.matecolorData[i]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.matecolorData[i]['classification']);
            this.matecolorData[i]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['colorStandard']);
            this.matecolorData[i].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].createdBy.firstName);
            this.matecolorData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i].status);
            if (this.matecolorData[i]['hexcode']) {
              this.matecolorData[i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['hexcode']);

            } else if (this.matecolorData[i]['thumbnailFiles']) {
              this.matecolorData[i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['thumbnailFiles']['thumbnail']);
              this.matecolorData[i]['thumbnailFiles']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.matecolorData[i]['thumbnailFiles']['name']);
            }
          }
          var gridView = {
            data: this.matecolorData,
            total: this.matecolorTotalRecordCount,
          };
          this.gridData = gridView;//process(this.matecolorData, this.state);
        }
        else
        {
          this.gridData = null;
        }
      }
      else {
        this.matecolorloading = false;
      }
    }, err => {
      this.logger.error(err.error.message, "MaterialColors");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.matecolorloading = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.matecolorloading = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.matecolorloading = false;
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialColors");
      }
      this.matecolorloading = false;
    }); */
  }

  getMaterialSuppliers() {
    this.showLoader = true;
    this.matesupplierloading = true;
    this.matesupplierstartEndCount = this.matesupplierstartCountList + "-" + this.matesupplierendCountList;
    this.store.dispatch(new materialActionTypes.LoadMaterialSupplier(this.storageservice.getMaterialId(), this.matesupplierstartEndCount));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialSuppliers) => {
      if (materialSuppliers.material.error === "") {
        this.showLoader = false;
        if (materialSuppliers.material.message['item2'] === 'Success') {
          this.matesupplierloading = false;
          var materialSupplierData = materialSuppliers.material.message['item1'];
          this.matesupplierTotalRecordCount = materialSuppliers.material.message['item3'];
          this.matsuppliersIds = materialSuppliers.material.message['item4'];
          this.supplierData = materialSupplierData.suppliers;
          if (this.supplierData != null && this.supplierData.length > 0) {
            var gridView = {
              data: this.supplierData,
              total: this.matesupplierTotalRecordCount,
            };
            this.gridSupData = gridView;
          }
          this.logger.info(materialSuppliers.material.message['item1']["name"] + " loaded successfully", "MaterialSuppliers");
        } else {
          this.matesupplierloading = false;
          this.showLoader = false;
          this.refreshTree();
          // this.error("Failed:" + materialSuppliers.material.message?.item1)
          this.toastService.showError(materialSuppliers.material.message?.item1);
          this.logger.error(materialSuppliers.material.message?.item1, "MaterialSuppliers");
        }
      } else {
        this.logger.error(materialSuppliers.material.error.error.message, "MaterialSuppliers");
        // this.getError(materialSuppliers.material.error);
        this.toastService.showError(materialSuppliers.material.error);
        this.matesupplierloading = false;
        this.showLoader = false;
      }
    })
    /*   this.dataservice.getmaterialsuppliers(this.storageservice.getMaterialId(), this.matesupplierstartEndCount).subscribe((response) => {
        if (response.body['item2'] === "Success") {
          this.matesupplierloading = false;

          var materialSupplierData = response.body['item1'];
          this.matesupplierTotalRecordCount = response.body['item3'];
          this.matsuppliersIds = response.body['item4'];
          this.logger.info(response.body['item1']["name"] + " loaded successfully", "MaterialSuppliers");
          if (materialSupplierData.suppliers.length > 0) {
            this.supplierData = materialSupplierData.suppliers;
            for (let i = 0; i < this.supplierData.length; i++) {
              this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
              this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
              this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
              // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
              // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
              this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
              //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
              this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
              // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
              if (this.supplierData[i].colours != null) {
                for (let j = 0; j < this.supplierData[i].colours.length; j++) {
                  this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
                  //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
                  this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
                  this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
                  this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);
                  if (this.supplierData[i].colours[j]['hexcode']) {
                    this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);
                  } else if (this.supplierData[i].colours[j]['thumbnailFiles']) {
                    this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);
                    this.supplierData[i].colours[j]['thumbnailFiles']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['name']);
                  }
                }
              }
            }

            var gridView = {
              data: this.supplierData,
              total: this.matesupplierTotalRecordCount,
            };
            this.gridSupData = gridView;//process(this.supplierData, this.state);

          }
        }
        else {
          this.matesupplierloading = false;
        }
      }, err => {
        this.logger.error(err.error.message, "MaterialSuppliers");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.matesupplierloading = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.matesupplierloading = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.matesupplierloading = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialSuppliers");
        }
        this.matesupplierloading = false;
      }); */
  }



  loadSelectPicker() {
    $(document).ready(function () {

      setTimeout(() => {
        $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
        $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
        $('.selectpicker').selectpicker({ noneSelectedText: 'Select Color Standard' });
      }, 5);

    });
  }

  refreshTree() {
    var data = this.materialClassificationData;
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      var treeview = $("#materialclassification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "230px",
        dataSource: data,
        filter: "startswith",
        /*dataSource:[
              { text: "Fabric" },
              { text: "Trim" },
              { text: "Leather" }

        ],*/
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          setTimeout(() => {
            $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
            $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
            $('.selectpicker').selectpicker({ noneSelectedText: 'Select Color Standard' });
          }, 10);
          var value = this.value();
          // alert(JSON.stringify(value));
          //alert(value["parentName"]);
          if (value) {
            self.materialclassificationValue = value["text"];
            self.materialForm.get('materialclassification').setValue(value["text"]);
            if (value["parentName"]) {
              self.parentClassification = value["parentName"];
            }
            else { self.parentClassification = ""; }

          }
          else {
            self.materialclassificationValue = "";
            self.parentClassification = "";
            self.materialForm.get('materialclassification').setValue("");
          }
        }
      });

    });
  }

  setMaterialTreeValue(value) {
    $(document).ready(function () {
      $("#materialclassification").data('kendoDropDownTree')?.value(value);
    })
  }

  materialDetailsTab(activeTab) {
    this.getMaterialClassification("Material");
    this.loadSelectPicker();
    this.activeTab = activeTab;
  }

  colorTab(activeTab) {
    this.activeTab = activeTab;
    this.isColorButton = true;
    this.colorSupplierId = "";
    if (this.storageservice.getMaterialId()) {
      this.getMaterialColors();
    }

  }

  supplierTab(activeTab) {
    this.activeTab = activeTab;
    this.isColorButton = true;
    if (this.storageservice.getMaterialId()) {
      this.getMaterialSuppliers();
    }

  }

  getControls() {
    return (this.documentsForm.get('documents') as FormArray).controls;
  }


  onRemove1(event) {
    //console.log(event);
    //this.files.splice(this.files.indexOf(event), 1);
    this.fileData.splice(this.fileData.indexOf(event), 1);
    // //console.log(this.fileData);
  }

  removeImgSection(event, i) {
    this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    if(this.editedFileData[i].length == 0){
      this.isFilesAdded = false;
    }
    //console.log(this.editedFileData);
    let documentControl = this.getControls();
    let docdeltedFiles = documentControl[i].get('docdeletedfileids').value;
    if (docdeltedFiles) {
      documentControl[i].get('docdeletedfileids').setValue(docdeltedFiles + "," + event.id);
    }
    else {
      documentControl[i].get('docdeletedfileids').setValue(event.id);
    }
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  handleChange($event: ColorEvent) {
  }

  /*onSelect(val: string[]) {
   }*/

  onSelect(event, i) {
    //console.log(event);
    this.files.push(...event.addedFiles);
    //  this.files = [];
    //  this.files.push(...event.addedFiles);
    this.fileData[i] = this.files;
    //console.log(this.fileData);

  }


  async onSubmit() {

    this.thumbnailId = null;
    var thumbnailName = "";
    this.submitted = true;
    if(this.materialclassificationValue=='Fabric'){
      this.materialColorForm.controls['subtype'].setValidators([]);

     }else if(this.materialclassificationValue=='Material'){
      this.materialColorForm.controls['subtype'].setValidators([]);
      this.materialColorForm.controls['fabriccomp'].setValidators([]);
     }else if(this.materialclassificationValue=='Product Packaging'){
      this.materialColorForm.controls['fabriccomp'].setValidators([]);

     }
     else if(this.materialclassificationValue=='Trims'){
      this.materialColorForm.controls['fabriccomp'].setValidators([]);

      }
    if (!this.materialForm.valid) {
      // this.error(this.staticMsgs.matDetail.inValidForm)
      this.toastService.showError("Please enter all the mandatory fields.");

      if(this.thumbNails.length == 0){
      this.isFileError=true;
      }
        return false;

    }
    else if (!this.isThumbnailAdded) {
      return false;
    }
    this.showLoader = true;
    // let re = /\"/gi;
    // this.colourSwatch = this.colourSwatch.replace(re,"'");

    if (this.thumbNails.length === 1) {
      let thumbnailuploaded = await this.uploadFile(this.thumbNails[0]);
      if (thumbnailuploaded != "error") {
        this.thumbnailId = JSON.parse(thumbnailuploaded)["Key"];
        thumbnailName = this.thumbNails[0].name;
      }
      else {
        // this.error(thumbnailuploaded);
        this.toastService.showError(thumbnailuploaded);
        this.submitted = false;
        this.showLoader = false;
        return false;
      }
    }
    if (this.isThumbnailAdded) {

      if (this.thumbNails.length === 1) {
        let thumbnailuploaded = await this.uploadFile(this.thumbNails[0]);
        if (thumbnailuploaded != "error") {
          this.thumbnailId = JSON.parse(thumbnailuploaded)["Key"];
          thumbnailName = this.thumbNails[0].name;
        }
        else {
          // this.error(thumbnailuploaded);
          this.toastService.showError(thumbnailuploaded);
          this.submitted = false;
          this.showLoader = false;
          return false;
        }
      }
    }
    if (this.thumbnailId == null && this.editedThumbnails.length <= 0) {
      this.isFileError = true;
      this.showLoader = false;
      return false;
    }

    let materialData = {
      baseClassification: this.aesdecryptservice.encryptData(this.dataservice.Key,this.parentClassification),
      classification: this.aesdecryptservice.encryptData(this.dataservice.Key, this.materialclassificationValue),
      name: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.materialname.value),
      cuttableWidth: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.cuttablewidth.value),
      fabricComposition: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.fabriccomp.value),
      comments: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.comments.value),
      widthUOM: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.widthuom.value),
      weight_GSM: this.f.weightgsm.value == "" ? "0" : this.f.weightgsm.value,
      subType: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.subtype.value),
      rawHide: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.rawhide.value),
      size: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.size.value),
      width: this.f.width.value == "" ? "0" : this.f.width.value,
      // status:this.f.status.value,
      content: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.content.value),
      sizeUOM: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.sizeuom.value),
      substabce: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.substabce.value),
      substanceUOM: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.substanceuom.value),
      cost: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.cost.value),
      thumbnail: this.thumbnailId
      // trimcost: this.f.trimcost.value
    };


    if (!this.f.materialid.value) {
      if (this.thumbNails.length === 1) {
        this.isThumbnailAdded = true;
      }
      else {
        this.isThumbnailAdded = false;
      }
      this.store.dispatch(new materialActionTypes.CreateMaterial(materialData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialDetails) => {
        if (materialDetails.material.error === "") {
          if (materialDetails.material.message['item2'] === 'Success') {
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isViewingMode = true;
            this.isEditingMode = false;
            this.editedMaterialData = materialDetails.material.message['item1'];
            this.storageservice.storeEditedMaterialId(this.editedMaterialData.id);
            this.reloadmaterialFormData(materialDetails.material.message['item1'])
            this.getMaterialDetails();
            this.dialogRef.close();
            // this.success(this.staticMsgs.matDetail.matCreate1);
            this.toastService.showSuccess(this.staticMsgs.matDetail.matCreate1);
            this.logger.info(this.staticMsgs.matDetail.matCreate, "MaterialDetails");
          } else {
            this.showLoader = false;
            //this.refreshTree();
            // this.error("Failed:" + materialDetails.material.message?.item1)
            this.toastService.showError(materialDetails.material.message?.item1);
            this.logger.error(materialDetails.material.message?.item1, "MaterialDetails");
          }
        } else {
          this.logger.error(materialDetails.material.error.error.message, "MaterialDetails");
          // this.getError(materialDetails.material.error);
          // this.toastService.showError(materialDetails.material.error);
          this.toastService.showError(materialDetails.material.error);
          this.showLoader = false;
        }
      })

      //console.log(JSON.stringify(materialData))
      /* this.dataservice.addMaterial(materialData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader = false;
            //this.alert = 'success';
            // this.errorDiv = true;
            // this.errors = 'Material Created Sucessfully.';
            this.submitted = false;
            this.isEditMode = true;
            this.isViewingMode = true;
            this.isEditingMode = false;
            this.editedMaterialData = response.body['item1'];
            this.storageservice.storeEditedMaterialId(this.editedMaterialData.id)
            //console.log(this.editedMaterialData);
            this.reloadmaterialFormData(response.body['item1'])
            this.success("Material Created Successfully!");
            this.logger.info("Material Created Successfully", "MaterialDetails");

          }
          else {
            //console.log(response)
            //this.alert = 'danger';
            //this.errorDiv = true;
            // this.errors = response.body['item2'];
            this.error(response.body['item2']);
            this.logger.error(response.body['item2'], "MaterialDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        //this.alert = 'danger';
        //this.errorDiv = true;
        //this.errors = err.message;
        this.logger.error(err.error.message, "MaterialDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialDetails");
        }
        this.showLoader = false;
      }); */

    }

    else {

      if (this.editedThumbnails.length === 1) {
        this.isThumbnailAdded = true;
      }
      else {
        if (this.thumbNails.length === 1) {
          this.isThumbnailAdded = true;
        }
        else {
          this.isThumbnailAdded = false;
        }
      }

      let materialid = { id: this.storageservice.getMaterialId() };
      let updatedMaterialData = { ...materialData, ...materialid };
      //console.log(JSON.stringify(updatedMaterialData))
      this.store.dispatch(new materialActionTypes.UpdateMaterial(updatedMaterialData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialDetails) => {
        if (materialDetails.material.error === "") {
          if (materialDetails.material.message['item2'] === 'Success') {
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.editedMaterialData = materialDetails.material.message['item1'];
            this.isViewingMode = true;
            this.isEditingMode = false;
            //console.log(this.editedMaterialData);

            this.reloadmaterialFormData(materialDetails.material.message['item1'])
            this.getMaterialDetails();
            this.dialogRef.close();
            // this.success(this.staticMsgs.matDetail.matUpd1);
            this.toastService.showSuccess(this.staticMsgs.matDetail.matUpd1);
            this.logger.info(this.staticMsgs.matDetail.matUpd, "MaterialDetails");
          } else {
            this.showLoader = false;
            this.refreshTree();
            // this.error("Failed:" + materialDetails.material.message?.item1)
            this.toastService.showError(materialDetails.material.message?.item1);
            this.logger.error(materialDetails.material.message?.item1, "MaterialDetails");
          }
        } else {
          this.logger.error(materialDetails.material.error.error.message, "MaterialDetails");
          // this.getError(materialDetails.material.error);
          this.toastService.showError(materialDetails.material.error);
          this.showLoader = false;
        }
      })

      /* this.dataservice.updatematerialById(updatedMaterialData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.editedMaterialData = response.body['item1'];
            this.isViewingMode = true;
            this.isEditingMode = false;
            //console.log(this.editedMaterialData);
            this.reloadmaterialFormData(response.body['item1'])
            this.success("Material Updated Successfully!");
            this.logger.info("Material Updated Successfully", "MaterialDetails");
          }
          else {
            //console.log(response)
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "MaterialDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "MaterialDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "MaterialDetails");
        }
        this.showLoader = false;
      }); */
    }

  }

  reloadmaterialFormData(data) {
    this.initForm();
    this.materialForm.get('materialclassification').setValue(data["classification"]);
    //this.refreshTree();
    this.setMaterialTreeValue(data["classification"]);
    this.materialclassificationValue = data["classification"];
    if (this.parentClassification === "Trims" || this.parentClassification === "Product Packaging") {
      this.materialForm.get('subtype').enable();
      setTimeout(() => {
            $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
          }, 10);
      if (this.parentClassification === "Trims"){
        this.materialForm.get('content').enable();
        this.materialForm.get('sizeuom').enable();
        this.materialForm.get('size').enable();
        this.materialForm.get('width').enable();
        setTimeout(() => {
          $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
        }, 10);
      }
    }
      if (this.parentClassification === "Material"){
        this.materialForm.get('substabce').enable();
        this.materialForm.get('substanceuom').enable();
        this.materialForm.get('rawhide').enable();
        this.materialForm.get('cost').enable();
        setTimeout(() => {
          $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
        }, 10);
      }
    /* if (data["classification"] === "Solid") {
       this.materialForm.get('colorstandard').enable();
       this.materialForm.get('pantone').enable();
       setTimeout(() => {
         $('.selectpicker').selectpicker({ noneSelectedText: 'Select Material Standard' });
       }, 10);
     }
     else {
       //this.refreshTree();
       this.materialForm.get('colorstandard').disable();
       this.materialForm.get('pantone').disable();
     }*/
    this.materialForm.get('materialid').setValue(data["id"]);
    this.storageservice.storeEditedMaterialId(data["id"]);
    this.materialForm.get('materialseq').setValue(data["sequence"]);
    this.materialForm.get('materialname').setValue(data["name"]);
    this.materialForm.get('cuttablewidth').setValue(data["cuttablewidth"]);
    this.materialForm.get('fabriccomp').setValue(data["fabriccomp"]);
    this.materialForm.get('comments').setValue(data["comments"]);
    this.materialForm.get('widthuom').setValue(data["widthuom"]);
    this.materialForm.get('weightgsm').setValue(data["weightgsm"]);
    this.materialForm.get('subtype').setValue(data["subtype"]);
    this.materialForm.get('size').setValue(data["size"]);
    this.materialForm.get('width').setValue(data["width"]);
    this.materialForm.get('content').setValue(data["content"]);
    this.materialForm.get('sizeuom').setValue(data["sizeuom"]);
    this.materialForm.get('substabce').setValue(data["substabce"]);
    this.materialForm.get('substanceuom').setValue(data["substanceuom"]);
    this.materialForm.get('rawhide').setValue(data["rawhide"]);
    this.materialForm.get('cost').setValue(data["cost"]);
    this.refreshSelect();
    const temp = {
      'image':this.thumbnail,
      'thumbImage':this.thumbnail
    }
     this.imageObject.push(temp);
    //this.materialForm.get('trimcost').setValue(data["trimcost"]);
    if (data["thumbnailFiles"]) {
      this.editedThumbnails = [];
      this.editedThumbnails.push(data["thumbnailFiles"]);
    }
  }

  isEmptyObject(obj) {
    return JSON.stringify(obj) === '[]';
  }

  openThumbnail(imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedThumbnails.length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedThumbnails[i - 1]["name"])) {
        src = this.editedThumbnails[i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedThumbnails[i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }
  open(index: number, imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedFileData[index].length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedFileData[index][i - 1]["name"])) {
        src = this.editedFileData[index][i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedFileData[index][i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.matDetail.oops,
      text: message
    })
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.matDetail.session,
      html: this.staticMsgs.matDetail.sessExp,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
        return;
      }
    })

  }
  onSelectThumbnail(event, i) {
    this.thumbNails = [];
    let imgext = "png,jpeg,jpg"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.thumbNails.push(...event.addedFiles);
      this.fileData[i] = this.thumbNails;
      this.isThumbnailAdded = true;
    }
    else {

      this.isThumbnailAdded = false;
      // this.error(this.staticMsgs.matDetail.unSupportFile)
      this.toastService.showError(this.staticMsgs.matDetail.unSupportFile);
      return false;
    }
    if (this.thumbNails && this.thumbNails.length > 0) {
      this.isFileError = false;
    }
  }
  onRemoveThumbnail(event) {
    this.thumbNails.splice(this.thumbNails.indexOf(event), 1);
    if (this.thumbNails.length === 1) {
      this.isThumbnailAdded = true;
    }
    else {
      this.isFileError = true;
      this.isThumbnailAdded = false;
    }
  }
  removeThumbnailSection(event) {
    this.editedThumbnails.splice(this.editedThumbnails.indexOf(event), 1);
    this.isThumbnailAdded= false;
  }
  async uploadFile(file): Promise<any> {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          //console.log('There was an error uploading your file: ', err);
          reject("error");
        }
        //console.log('Successfully uploaded file.', data);
        resolve(JSON.stringify(data));
      });
    });
    //for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              //console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  //console.log('There was an error uploading your file: ', err);
                  return false;
              }
              //console.log('Successfully uploaded file.', data);
              return true;
          });*/
  };

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  isValidImageURL(str) {
    if (str != undefined) {
      str = str.toLowerCase();
      str = str.split('?')[0];
      //moving on, split the uri into parts that had dots before them
      var parts = str.split('.');
      //get the last part ( should be the extension )
      var extension = parts[parts.length - 1];
      //define some image types to test against
      var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
      //check if the extension matches anything in the list.
      if (imageTypes.indexOf(extension) !== -1) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  public allData(): ExcelExportData {
    this.gridData1 = filterBy(this.matecolorData, this.state.filter);
    if (this.SearchData != null) {
      this.searchListData(this.SearchData);
    }
    const result: ExcelExportData = {
      data: process(this.gridData1, { sort: [{ field: 'name' }] }).data,
    };
    return result;
  }

  onSupChange(id: string, val: boolean) {
    if (val == true) {
      this.supplierIds.push(id);
      //console.log(this.supplierIds);
    }
    else {
      var index = this.supplierIds.indexOf(id);
      this.supplierIds.splice(index, 1);
      //console.log(this.supplierIds);
    }

  }

  createMatSupplier() {

    this.showLoader1 = true;
    let controlleng = this.supplierIds.toString();
    if (controlleng == '') { 
      // this.error("Please select supplier");
      this.toastService.showError("Please select supplier");
      this.showLoader1 = false; return false; 
    }
    let controlleng1 = controlleng.split(',');
    let colcount = controlleng1.length;
    let materialid = this.storageservice.getMaterialId();
    //// alert(controlleng1.length)
    const formData = new FormData();
    formData.append('materialid', materialid);
    formData.append('suppickcount', colcount.toString());
    for (let i = 0; i < controlleng1.length; i++) {
      formData.append('MSsupplierid' + '[' + i + ']', this.supplierIds[i]);
      formData.append('supPickTag' + '[' + i + ']', "Add");
    }
    this.showLoader1 = true;
    this.supplierIds = [];
    this.store.dispatch(new materialActionTypes.CreateMaterialSupplier(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialSupplier) => {
      if (materialSupplier.material.error === "") {
        if (materialSupplier.material.message['item2'] === 'Success') {
          this.editedMaterialData = materialSupplier.material.message['item1'];
          this.cards = materialSupplier.material.message.item1;
          this.getMaterialSuppliers();
          // this.success(this.staticMsgs.matDetail.addSupp1);
          this.toastService.showSuccess(this.staticMsgs.matDetail.addSupp1);
          this.logger.info(this.staticMsgs.matDetail.addSupp, "MaterialDetails");
          this.showLoader1 = false;
          $('.chkbxqsupplier').each(function () {
            $(this).prop('checked', false);
          });
        } else {
          this.showLoader1 = false;
          // this.error("Failed:" + materialSupplier.material.message['item1'])
          this.toastService.showError(materialSupplier.material.message['item1']);
          this.logger.error(materialSupplier.material.message['item1'], "MaterialDetails");
        }

      } else {
        this.logger.error(materialSupplier.material.error.error.message, "MaterialDetails");
        // this.getError(materialSupplier.material.error);
        this.toastService.showError(materialSupplier.material.error);
        this.showLoader1 = false;
      }
    })
    /* this.dataservice.addMaterialSupplier(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          this.success("Added to Supplier Material Successfully!");
          this.logger.info("Added to Supplier Material Successfully", "MaterialDetails");
          $('.chkbxqsupplier').each(function () {
            $(this).prop('checked', false);
          });

          this.getMaterialSuppliers();

          // this.cards = response.body.item1;
          // this.editedMaterialData = response.body['item1'];
          // this.cards = response.body.item1;
          // if (this.editedMaterialData.suppliers) {
          //   this.supplierData = this.editedMaterialData.suppliers;
          //   for (let i = 0; i < this.supplierData.length; i++) {
          //     this.supplierData[i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].name);
          //     this.supplierData[i].capacityUOM = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].capacityUOM);
          //     this.supplierData[i].materialSupplierPrice = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].materialSupplierPrice);
          //     // this.supplierData[i].country=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].country);
          //     // this.supplierData[i].state=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].state);
          //     this.supplierData[i].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].status);
          //     //this.supplierData[i].FirstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].FirstName);
          //     this.supplierData[i].description = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].description);
          //     // this.supplierData[i].createdBy.firstName=this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].createdBy.firstName);
          //     if (this.supplierData[i].colours != null) {
          //       for (let j = 0; j < this.supplierData[i].colours.length; j++) {
          //         this.supplierData[i].colours[j]['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['name']);
          //         //this.supplierData[i].colours[j]['classification']= this.aesdecryptservice.decryptData(this.dataservice.Key,this.supplierData[i].colours[j]['classification']);
          //         this.supplierData[i].colours[j]['colorStandard'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['colorStandard']);
          //         this.supplierData[i].colours[j].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].createdBy.firstName);
          //         this.supplierData[i].colours[j].status = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j].status);

          //         if (this.supplierData[i].colours[j]['hexcode']) {
          //           this.supplierData[i].colours[j]['hexcode'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['hexcode']);

          //         } else if (this.supplierData[i].colours[j]['thumbnailFiles']) {
          //           this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['thumbnail']);
          //           this.supplierData[i].colours[j]['thumbnailFiles']['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.supplierData[i].colours[j]['thumbnailFiles']['name']);
          //         }
          //       }
          //     }
          //   }
          //   this.gridSupData = process(this.supplierData, this.state);
          // }

        }
        else {
          //console.log(response)
          //alert(response.body['item2'])
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "MaterialDetails");
          this.showLoader1 = false;
        }
      }
    }, err => {
      this.logger.error(err.error.message, "MaterialDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialDetails");
      }
      this.showLoader1 = false;
    }); */

  }

  getsupplierId(Id: string) {
    this.colorSupplierId = Id;
    this.getAllColors('supcolor');
    //console.log(Id);
  }


  /*** Supplier display table Start */

  public allSupData(): ExcelExportData {

    this.filteredData = filterBy(this.apiGridData, this.state.filter);
    this.state.take = this.filteredData.length;
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    const result: ExcelExportData = {

      data: process(this.filteredData, this.state).data,
    };
    this.state.take = 10;

    return result;
  }





  searchListData(val: string) {

    if (val !== "") {
      let arr = [];
      this.filterQuery = val;
      this.gridData = this.matecolorData.filter((data) => {
        var search = this.filterQuery.toString().toLowerCase();
        var values = Object.values(data);
        var indicator = false;
        values.forEach((vl) => {
          if (String(vl).toLowerCase().indexOf(search) > -1) {
            indicator = true;
            return;
          }
        });
        if (indicator) { return data; }
      });
    }
    else {
      this.gridData = this.matecolorData;
      return
    }
    this.gridData1 = this.gridData;

  }
  searchSupplierListData(val: string) {
    if (val !== "") {
      let arr = [];
      this.filterQuery = val;
      this.gridSupData = this.supplierData.filter((data) => {
        var search = this.filterQuery.toString().toLowerCase();
        var values = Object.values(data);
        var indicator = false;
        values.forEach((vl) => {
          if (String(vl).toLowerCase().indexOf(search) > -1) {
            indicator = true;
            return;
          }
        });
        if (indicator) { return data; }
      });
    }
    else {
      this.gridSupData = this.supplierData;
      return
    }
    this.gridData1 = this.gridSupData;

  }

  async documentUploadCheck(documentControl) {
    this.doc_Error = []


    // if (this.editedFileData.length > 0) {
      for (let i = 0; i < documentControl.length; i++) {
        if (this.editedFileData.length > 0) {
          if (i+1 <= documentControl?.length) {
            if (this.editedFileData[i]?.length > 0) {
              this.doc_Error.push('');
            } else {
              if (this.fileData[i].length > 0) {
                this.doc_Error.push('');
              } else {
                this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired);
              }
            }
          } else {
            if (this.fileData.length > 0) {
              if (this.fileData[i].length > 0) {
                this.doc_Error.push('');
              } else {
                this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired)
              }
            } else {
              this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired)
            }
          }

        } else {
          if (this.fileData.length > 0) {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired)
            }
          } else {
            this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired)
          }

        }
      }
   // }

    if (this.doc_Error.length > 0) {
      for (let i = 0; i < this.doc_Error.length; i++) {
        if (this.doc_Error[i] == this.staticMsgs.matDetail.documentisrequired) {
          this.statusForm = true;
          break;
        } else {
          this.statusForm = false;
        }
      }
    } else {
      this.doc_Error.push(this.staticMsgs.matDetail.documentisrequired);
      this.statusForm = true;
    }
    return this.statusForm;
  }


  /*** Supplier display table End */
  doc_Error = [];
  statusForm: boolean = false;

  async onMaterialDocSubmit() {

    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    //console.log(this.documentsForm.value);
    this.submitted1 = true;
    if (!this.documentsForm.valid) {
      // this.error(this.staticMsgs.matDetail.invalidDoc)
      this.toastService.showError(this.staticMsgs.matDetail.invalidDoc);
      // alert("ccc")
      return false;
    }


    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    let statusUploadDoc = await this.documentUploadCheck(documentControl);
    if (statusUploadDoc == true) {
      return false
    }
    this.showLoader1 = true;

    const formData = new FormData();
    formData.append('Materialid', this.storageservice.getMaterialId());
    formData.append('doccount', control.length.toString());
    if (this.removedDocIds) {
      formData.append('docdelete', this.removedDocIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (documentControl[i].get('docid').value) {
        formData.append('docid' + '[' + i + ']', documentControl[i].get('docid').value);
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
      }
      else {
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
      }

      if (documentControl[i].get('docdeletedfileids').value) {
        formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
      }

      formData.append('baseclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Documents"));
      formData.append('docclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docclassification').value));
      formData.append('docname' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docname').value));
      formData.append('docstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docstatus').value));
      formData.append('docdescription' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docdescription').value));
      formData.append('docreportstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docreportstatus').value));
      formData.append('doclablocation' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('doclablocation').value));
      formData.append('docnotes' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docnotes').value));
      formData.append('docfilecount' + '[' + i + ']', this.fileData[i].length);

      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '[]') {
          //console.log (f.name);
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != "error") {
            formData.append('file' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, JSON.parse(fileUploadedData)["Key"]));
            formData.append('filename' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
          }
          else {
            // this.error(fileUploadedData);
            this.toastService.showError(fileUploadedData);
            this.submitted1 = false;
            this.showLoader = false;
            return false;
          }
        }
        a = a + 1;
      }
    }
    this.showLoader1 = true;
    this.store.dispatch(new materialActionTypes.UpdateMaterialDocument(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((materialDocument) => {
      if (materialDocument.material.error === "") {
        if (materialDocument.material.message['item2'] === 'Success') {
          this.loadSelectPicker();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          this.showLoader1 = false;
          this.submitted1 = false;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedMaterialData = materialDocument.material.message['item1'];
          //console.log(this.editedMaterialData);
          if (this.editedMaterialData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            this.isFilesAdded=true;
            let editeddata = JSON.stringify(this.editedMaterialData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          this.showLoader1 = false;
          //this.docDetailsTab('docDetailsTab');
          // this.success(this.staticMsgs.matDetail.docUpd1);
          this.toastService.showSuccess(this.staticMsgs.matDetail.docUpd1);
          this.logger.info(this.staticMsgs.matDetail.docUpd, "MaterialDetails");
        } else {
          this.showLoader1 = false;
          this.refreshTree();
          // this.error("Failed:" + materialDocument.material.message?.item1)
          this.toastService.showError(materialDocument.material.message?.item1);
          this.logger.error(materialDocument.material.message?.item1, "MaterialDetails");
        }
      } else {
        this.logger.error(materialDocument.material.error.error.message, "MaterialDetails");
        // this.getError(materialDocument.material.error);
        this.toastService.showError(materialDocument.material.error);
        this.showLoader1 = false;
      }
    })

    /* this.dataservice.AddupdateMaterialDocuments(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.loadSelectPicker();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          this.showLoader1 = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.removedDocIds = "";
          this.editedFileData = [];
          this.editedMaterialData = response.body['item1'];
          //console.log(this.editedMaterialData);
          if (this.editedMaterialData.documents.length > 0) {
            this.editedFileData = [];

            let editeddata = JSON.stringify(this.editedMaterialData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          //this.docDetailsTab('docDetailsTab');
          this.success("Documents Updated Successfully!");
          this.logger.info("Documents Updated Successfully", "MaterialDetails");
          this.showLoader1 = false;
        }
        else {
          //console.log(response)
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "MaterialDetails");
          this.showLoader1 = false;
        }

      }
    }, err => {
      this.logger.error(err.error.message, "MaterialDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "MaterialDetails");
      }
      this.showLoader1 = false;
    }); */

  }


  addNewDoc() {
    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    let control = <FormArray>this.documentsForm.controls.documents;
    //alert(control.length)
    control.push(
      this.fb.group({
        docclassification: ['', Validators.required],
        docid: [''],
        docseq: [''],
        docname: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
        docstatus: ['', Validators.required],
        docdescription: ['', [Validators.required, Validators.maxLength(50)]],
        docreportstatus: ['', Validators.required],
        doclablocation: ['', [Validators.required, Validators.maxLength(50)]],
        docnotes: ['', Validators.maxLength(500)],
        docdeletedfileids: [''],
        materialid: ['']
      })
    )
    this.files = [];
    this.fileData[control.length - 1] = this.files;
     this.showButton=true;
    this.refreshDocTree(control.length - 1);
  }

  documentTab(activeTab) {
    this.getDocumentClassification("Documents");
    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    this.showLoader = true;
    this.activeTab = activeTab;
    this.isColorButton = false;
    if (this.editedMaterialData.documents) {
      if (this.editedMaterialData.documents.length > 0) {
        this.editedFileData = [];
        this.removedDocIds = "";
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.showLoader = false;
        }
        let editeddata = JSON.stringify(this.editedMaterialData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }

  setDocumentTreeValue(index, value) {
    $(document).ready(function () {
      $("#docclassification" + index).data('kendoDropDownTree').value(value);
    })
  }
  reloadDocumentFormData(data) {
    //console.log(JSON.stringify(data));
    for (let i = 0; i < data.length; i++) {
      this.addNewDoc();
      this.refreshDocTree(i);
    }
    //console.log(JSON.stringify(data))
    let documentControl = this.getControls();
    for (let i = 0; i < data.length; i++) {
      documentControl[i].get('docid').setValue(data[i]["id"]);
      documentControl[i].get('docseq').setValue(data[i]["sequence"]);
      this.refreshDocTree(i);
      if (this.isEditingMode) {
        //console.log("Coming")
        this.setDocumentTreeValue(i, data[i]["classification"]);
      }
      documentControl[i].get('docclassification').setValue(data[i]["classification"]);
      documentControl[i].get('docname').setValue(data[i]["name"]);
      documentControl[i].get('docstatus').setValue(data[i]["status"]);
      $('select[name=docstatus]').selectpicker('refresh');

      // this.documentsForm.get('docstatus').setValue(data[i]["status"]);

      documentControl[i].get('docdescription').setValue(data[i]["description"]);
      documentControl[i].get('docreportstatus').setValue(data[i]["reportStatus"]);
      documentControl[i].get('doclablocation').setValue(data[i]["labLocation"]);
      if (data[i]["classification"] === "Lab Reports") {
        documentControl[i].get('docreportstatus').enable();
        documentControl[i].get('doclablocation').enable();
      }
      else {
        documentControl[i].get('docreportstatus').disable();
        documentControl[i].get('doclablocation').disable();
      }
      documentControl[i].get('docnotes').setValue(data[i]["notes"]);
      // if (data[i]["files"][0]) {
      //   // this.editedFileData=data[i]["files"];
      //   data[i]["files"][0]['thumbnail'] = data[i]["files"][0]['thumbnail'];
      //   data[i]["files"][0]['name'] = data[i]["files"][0]['name'];
      // }
      this.editedFileData.push(data[i]["files"]);
      this.materialDataCnt = this.fileData[i].length+1;
      this.isFilesAdded=true;
    }
    this.refreshSelect();
  }



  refreshDocTree(index: number) {
    var data = this.docclassification;
    var currentDocument = this.getControls();
    // alert(currentDocument)
    //console.log(currentDocument[index].get('docclassification'))
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      var treeview = $("#docclassification" + index).kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        dataSource: data,
        filter: "startswith",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          // this.loadSelectPicker();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          //alert(value);
          if (value) {
            //self.documentclassificationValue = value["text"];
            //console.log(value["text"])
            currentDocument[index].get('docclassification').setValue(value["text"]);
            if (value["text"] === "Lab Reports") {

              currentDocument[index].get('docreportstatus').enable();
              currentDocument[index].get('doclablocation').enable();
            }
            else {
              currentDocument[index].get('docreportstatus').disable();
              currentDocument[index].get('doclablocation').disable();
            }
          }
          else {
            //self.documentclassificationValue = "";
            currentDocument[index].get('docclassification').setValue("");
          }
        }
      });

    });
  }

  removeDoc(index) {
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    if(this.editedFileData.length !== 1) {
    if (this.removedDocIds) {
      this.removedDocIds = this.removedDocIds + "," + documentControl[index].get('docid').value
    }

    else {
      this.removedDocIds = documentControl[index].get('docid').value
    }
  }
  else {
    alert('Minimum 1 document is required');
    return false;
  }
    control.removeAt(index)
    this.fileData.splice(index, 1);
    //console.log(this.fileData);
    this.editedFileData.splice(index, 1);
    //this.removedFileData[index].push([]);

  }
  onSelectDoc(event, i) {
    this.files = [];
    let imgext = "png,jpeg,jpg,xlsx,pdf,txt,docx,zip,pptx"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.files.push(...event.addedFiles);
      this.isFilesAdded = true;
      this.doc_Error[i] = '';
    }
    else {
      this.isFilesAdded = false;
      // this.error("File Not Supported.")
      this.toastService.showError("File Not Supported.");
      return false;
    }
     this.fileData[i] = this.files;
  }


  onRemove(event, i) {

    this.fileData[i].splice(this.fileData[i].indexOf(event), 1);
    //console.log(this.fileData);
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  onExpand(e) {
    e.sender.tbody.find('.k-detail-row').each(function (idx, item) {
      if (item !== e.detailRow[0]) {
        e.sender.collapseRow($(item).prev());
      }
    })
    this.colorSupplierId = e;

  }

  editMaterial() {

    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);

    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;
    if (this.activeTab === "materialDetailsTab") {
      this.isThumbnailAdded = true;
      if (this.storageservice.getMaterialId()) {
        this.getMaterialDetails();
      }
      this.initForm();
      this.loadSelectPicker();
    }
    else if (this.activeTab === "documentTab") {
      this.getDocumentClassification("Documents");
      this.isFilesAdded=true;
      this.showButton=false;
      this.loadSelectPicker();
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);

      this.showLoader1 = true;
      if (this.editedMaterialData.documents) {
        if (this.editedMaterialData.documents.length > 0) {
          this.editedFileData = [];
          this.removedDocIds = "";
          //this.removedFileData = [];
          let control = <FormArray>this.documentsForm.controls.documents;
          if (control.length > 0) {
            this.documentsForm = this.fb.group({
              documents: this.fb.array([])
            })
          }
          let editeddata = JSON.stringify(this.editedMaterialData.documents);
          let data = JSON.parse(editeddata);
          this.reloadDocumentFormData(data);
          this.showLoader1 = false;
        }
        else {
          this.showLoader1 = false;
        }

      }
      else {
        this.showLoader1 = false;
      }
    }


  }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }
  cancelMaterialEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents')as FormArray).clear();
    if (this.storageservice.getMaterialId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getMaterialDetails();
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }
    this.initForm();
    ///this.loadSelectPicker();
  }
  cancelDocumentEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents')as FormArray).clear();
    this.isThumbnailAdded = true;
    if (this.storageservice.getMaterialId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;

    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.showLoader = true;
    if (this.editedMaterialData.documents) {
      if (this.editedMaterialData.documents.length > 0) {
        this.editedFileData = [];
        this.isFilesAdded = true;
        this.removedDocIds = "";
        //this.removedFileData = [];
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        let editeddata = JSON.stringify(this.editedMaterialData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }

  cancelMaterialCreate() {
    this.router.navigate(['/materiallibrary']);
    this.dialogRef.close();
    this.isFilesAdded=true;
  }
  onDeleteMaterial(){
    Swal.fire({
      title: this.staticMsgs.matDetail.deleteMat,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.matDetail.deleteit
    })
   .then((result) => {
      if (result.value) {
        this.showLoader=true;
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getMaterialId());
        this.store.dispatch(new materialActionTypes.DeleteMaterialLibrary(deleterecordIds));
        this.store.pipe((state => state), take(2), skip(1)).subscribe((deletedMaterialLibrary) => {
          if (deletedMaterialLibrary.material.error === "") {
            if (deletedMaterialLibrary.material.message?.item2 === 'Success') {
              this.showLoader = false;
              this.logger.info(this.staticMsgs.matDetail.matDelete, "MaterialLibrary");
              // this.success(this.staticMsgs.matDetail.matDelete1);
              this.toastService.showSuccess(this.staticMsgs.matDetail.matDelete1);
              this.router.navigate(['/materiallibrary']);
            } else {
              this.showLoader = false;
              // this.error("Failed:" + deletedMaterialLibrary.material.message?.item1)
              this.toastService.showError(deletedMaterialLibrary.material.message?.item1);
              this.logger.error(deletedMaterialLibrary.material.message?.item1, "MaterialLibrary");
            }
          } else {
            this.logger.error(deletedMaterialLibrary.material.error.error.message, "MaterialLibrary");
            // this.getError(deletedMaterialLibrary.material.error);
            this.toastService.showError(deletedMaterialLibrary.material.error)
            this.showLoader = false;
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
}

