import { Component, Input, OnInit, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { DataService } from '../../../services/data.service';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { LogService } from '../../../shared/log.service';
import Swal from 'sweetalert2';
import { UserService } from '../../../helpers/user.service';
import { CalSubTotal } from '../../../models/color';
import { StorageService } from '../../../helpers/storage.service';
import { process, filterBy, orderBy, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StyledetailsComponent } from '../styledetails/styledetails.component';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../services/shared.service';
import { Router } from '@angular/router';
import { ToastService } from '../../../services/toast.service';
declare var $: any;

@Component({
  selector: 'app-construction-stitches',
  templateUrl: './construction-stitches.component.html',
  styleUrls: ['./construction-stitches.component.css']
})
export class ConstructionStitchesComponent implements OnInit {

  selected: any;
  public filteredData: any[];
  filteredXcelData: any[];
  public constructionstartCountList = 0;
  public constructionendCountList = 10;
  public constructionstartEndCount = "";
  public constructionisPageChanged = false;
  public constructionRecordCount: any;
  public constructionloading: boolean;

  public specificationStitchestartCountList = 0;
  public specificationStitcheendCountList = 10;
  public specificationStitchestartEndCount = "";
  public specificationStitcheisPageChanged = false;
  public specificationStitcheRecordCount: any;
  public specificationStitcheloading: boolean;
  ConstructionData: any;
  styleViewMode = "Created";
  sharedGridData: any[] = [];
  totalSharedRecordCount: any;
  appStaticMsgs: any;
  dbstichesData: any;
  stichesData: any;
  stitchesform: FormGroup;
  submitted = false;
  public changemode: boolean=false;
  stichImage = "";
  sticheslistArr: any = [];
  sticheslist = [];
  showLoader: boolean;
  showSpecStitcheNote = false;
  isStitchesnew: boolean;
  public stitchesstartEndCount = "";
  public stitchesstartCountList = 0;
  public stitchesendCountList = 10;
  totalStitchesCount: any;

  public constructiongridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  editData: any;
  stitch: any = [];
  imgload = true;

  constructor(
    private dialogRef: MatDialogRef<StyledetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private userservice: UserService,
    private storageservice: StorageService,
    private sharedService: SharedService,
    private router: Router,
    private toastService: ToastService
  ) {
    this.sharedService.getStitchData().subscribe(x => {
      this.editData = x;
    })
  }

  get f1() { return this.stitchesform.controls; }
  get fa() { return this.f1.formArray as FormArray; }

  ngOnInit(): void {
    console.log(this.data, 'data')
    this.appStaticMsgs = appStaticMsg.data[0];
    if (this.data?.isStitchesedit == false) {
      this.stitchesform = new FormGroup({
        'ssid': new FormControl(),
        'stitchid': new FormControl(),
        'placement': new FormControl('', Validators.required),
        'notes': new FormControl('')
      });

    }
    else if (this.data?.isStitchesedit == true) {
      this.stitchesform = new FormGroup({
        'stitchid': new FormControl(this.data.stitchData.stitch.id, Validators.required),
        'placement': new FormControl(this.data.stitchData.placement, Validators.required),
        'notes': new FormControl(this.data.stitchData.notes),
        'ssid': new FormControl(this.data.stitchData.id),
      });
    }
    this.getAllStitches();
    this.loadSelectPicker();

  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessgetcolorwaysionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })

  }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  initSlectPicker(key) {
    $(document).ready(function () {
      $('#' + key).selectpicker();
    });

    if (this.data?.isStitchesedit == true) {
      this.stitchesform.controls['stitchid'].setValue(this.data.stitchData.stitch.id);
    }
    this.imgload = false;
  }

  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove', 'selectAll');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Image Type' }, 'deselectAll');
    });
  }

  handleFilter(value, type) {
    if (type == 'Stitch') {
      this.sticheslistArr = this.sticheslist.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }
  }

  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    )
  }

  public getAllStitches() {
    this.stitchesstartEndCount = this.stitchesstartCountList + "-" + this.stitchesendCountList;
    this.dataservice.getDetailsByModuleName('Stitches', this.stitchesstartEndCount).subscribe(response1 => {
      if (response1.body['item2'] === "Success") {
        this.stitchesendCountList = response1.body['item3'];
        this.stitchesstartEndCount = this.stitchesstartCountList + "-" + this.stitchesendCountList;
        this.dataservice.getDetailsByModuleName('Stitches', this.stitchesstartEndCount).subscribe(response => {
          if (response.body['item2'] === "Success") {
            response.body['item1'].forEach(x => {
              x.id = x.id;
              x.name = this.aesdecryptservice.decryptData(this.dataservice.Key, x.name);
              if (x.topViewFile) {
                x.topViewFile.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, x.topViewFile?.thumbnail);
              } else {
                x.topViewFile = null;
              }
              this.sticheslist.push(x);
            });
            this.sticheslistArr = this.sticheslist;
            console.log(this.sticheslistArr, 'sticheslistArr');
            this.totalStitchesCount = response.body['item3'];
            this.logger.info("Get All Stiches", "Style Details");
            this.imgload=false;
            // setTimeout(() => {
            // this.initSlectPicker('stitchid');
            //   $("#stitchid").attr('data-live-search',true);
            // }, 5000);
          }
          else {
            this.logger.error(response.body['item1'], "Style Details");

            this.error(response.body['item1']);
            this.showLoader = false;
          }
        }, err => {
          this.logger.error(err.error.message, "Style Details");
          if (err.error.message === "Network error communicating with endpoint") {
            // this.error(err.error.message);
            this.toastService.showError("Oops!! Something went wrong. Please try again.");
            this.showLoader = false;
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
            this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
            this.showLoader = false;
          } else if (err.error.message === "Unauthorized") {
            // this.userservice.error();
            this.toastService.showError("You don't have permission to access this resource. Please login again.")
            this.sessionTimedOut();
            this.userservice.logout();
            this.showLoader = false;
          }
          else {
            this.logger.error(err.error.message, "Style Details");
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);

          }
          this.showLoader = false;
        })
      }
    });
  }


  onStitchSubmit() {
    if (this.stitchesform.controls.placement.value.length > 100) {
      this.stitchesform.controls.placement.setErrors({ 'incorrect': true });
    }
    if (this.stitchesform.controls.notes.value.length > 200) {
      this.stitchesform.controls.notes.setErrors({ 'incorrect': true });
    }

    if (this.stitchesform.invalid) {
      this.showSpecStitcheNote = true;
      // this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields)
      // this.toastService.showError(this.appStaticMsgs.common.pleasechecktheinvalidfields)
      this.toastService.showError("Please enter all the mandatory fields.");
      return;
    }
    console.log(this.stitchesform.value);
    this.stitch = this.stitchesform.value;
    this.imgload = true;
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('stylestitchescount', '1');
    if (this.data.isStitchesedit == false) {
      formData.append('sstag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add'));
    } else {
      formData.append('ssid[0]', this.stitch['ssid']);
      formData.append('sstag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update'));
    }
    formData.append('stitchid[0]', this.stitch['stitchid']);
    formData.append('placement[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.stitch['placement']));
    formData.append('notes[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.stitch['notes']));

    this.imgload = true;
    this.dataservice.addupdatedeleteStylespecification(formData).subscribe(async response => {
      this.showSpecStitcheNote = false;
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.imgload = false;
          // this.success(this.appStaticMsgs.styledetails.specificationstitchssavesuccessfully);

          this.logger.info(this.appStaticMsgs.styledetails.specificationstitchssavesuccessfully, "Style StitcheSpecifications");
          if(this.data.isStitchesedit == false){
            this.toastService.showSuccess('Added Successfully');
          }
          else if(this.data.isStitchesedit == true){
            this.toastService.showSuccess('Updated Successfully');
          }
          this.getStylesStitcheSpecifications();
          this.dialogRef.close();
          //this.specificationForm = undefined;
          //this.StichesForm = undefined;
          //await this.getSpecificationbyId();
        }
        else {
           this.logger.error(response.body['item1'], "Style StitcheSpecifications");
          // this.error(response.body['item1']);
          this.toastService.showError(response.body['item1']);
          this.imgload = false;
        }

      }
    }, err => {
      this.showSpecStitcheNote = false;
      this.logger.error(err.error.message, "Style StitcheSpecifications");
      this.toastService.showError(err.error.message)

      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
      }
      else {
        this.logger.error(err.error.message, "Style StitcheSpecifications");
        

        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.imgload = false;
    });

  }


  public selectionStiches(event) {
    this.changemode = true;
    //this.stichImage = event.topView?.thumbnail;
    if(this.sticheslist.length > 0){
      let matchingItem = this.sticheslist.find(item => item.id === this.f1.stitchid.value);
      this.stichImage = matchingItem?.topViewFile?.thumbnail ?? '';
    }
    console.log(this.stichImage, 'stich');

  }


  public async getStylesStitcheSpecifications() {

    this.specificationStitcheloading = true;
    this.specificationStitchestartEndCount = this.specificationStitchestartCountList + "-" + this.specificationStitcheendCountList;
    this.dataservice.getStyleStitcheSpecifications(this.storageservice.getEditedStyleId(), this.specificationStitchestartEndCount).subscribe(response => {
      if (response.body['item2'] === "Success") {

        this.specificationStitcheloading = false;
        this.logger.info(this.appStaticMsgs.styledetails.allstichesspecificationloadedsuccessfully, "Style StitcheSpecifications");
        this.dbstichesData = response.body['item1']['styleStitchess'];
        this.specificationStitcheRecordCount = response.body['item3'];
        console.log('stitch data', response.body['item1']['styleStitchess'])
        if (response.body['item1']['styleStitchess']) {

          for (let i = 0; i < this.dbstichesData.length; i++) {
            this.dbstichesData[i].placement = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbstichesData[i].placement);
            this.dbstichesData[i].notes = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbstichesData[i].notes);
            this.dbstichesData[i].stitch.name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbstichesData[i].stitch.name);
            if (this.dbstichesData[i].stitch.topViewFile) {
              this.dbstichesData[i].stitch.topViewFile.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbstichesData[i].stitch.topViewFile.thumbnail);
              this.dbstichesData[i].stitch.topViewFile.name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbstichesData[i].stitch.topViewFile.name);
            } else {
              this.dbstichesData[i].stitch.topViewFile = null;
            }

          }

          var gridView = {
            data: this.dbstichesData,
            total: this.specificationStitcheRecordCount,
          };

          this.stichesData = gridView;
        }
        else {
          this.stichesData = null;
        }
        //this.specificationForm = undefined;
        //this.StichesForm = undefined;
      }
      else {
        this.error(response.body['item1']);
        this.logger.error(response.body['item1'], "Style StitcheSpecifications");
        this.specificationStitcheloading = false;
      }
    }, err => {
      this.logger.error(err.error.message, "Style StitcheSpecifications");
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.specificationStitcheloading = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
        this.specificationStitcheloading = false;
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
        this.specificationStitcheloading = false;
      }
      else {
        this.logger.error(err.error.message, "Style StitcheSpecifications");
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.specificationStitcheloading = false;
    });

  }
  close() {
    this.dialogRef.close();
  }
}
