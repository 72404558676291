import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Lightbox } from 'ngx-lightbox';
import { DataTableDirective } from 'angular-datatables';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../helpers/user.service';
import { SharedService } from '../../../services/shared.service';
//import {apiGridData } from './apiGridData';
import Swal from 'sweetalert2';
import { process, State, CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult, DataStateChangeEvent, PageChangeEvent, CellClickEvent, FilterService } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { Favourite } from '../../../models/color';
declare var $: any;
//import { IDocument } from '../../../models/document';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Router } from '@angular/router';
import { StorageService } from '../../../helpers/storage.service';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as imageReducer from "../../../reducers/image.reducer";
import * as imageActionTypes from "../../../actions/image.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take, tap } from 'rxjs/operators';
import { interval, Observable, of, combineLatest } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { ImageCustomComponent } from '../image-custom/image-custom.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-imagelibrary',
  templateUrl: './imagelibrary.component.html',

  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./imagelibrary.component.css']
})
export class ImagelibraryComponent implements OnInit {
  public listItems: Array<{ text: string, value: string }> = [
    { text: "Active", value: "Active" },
    { text: "Inactive", value: "Inactive" }
  ];


  thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  apiGridData = [];
  apiData = [];
  imageViewMode = "Created";
  myimage: boolean = true;
  sharedimage: boolean = false;
  originalData = [];
  gridDataForm: FormGroup;
  isEditMode = false;
  @ViewChild('grid') grid: GridComponent;

  public gridData: GridDataResult;

  public filteredData: any[];
  public filteredXcelData: any[];
  isRowEditMode: boolean;
  result: string;
  showNote = false;
  sharedCopyCard: any[] = [];
  sharedCards: any[] = [];
  isMyImages = true;
  sharedNotFound: boolean;
  isSharedGetAllRecords = false;
  isSharedScrollDown = false;
  totalSharedRecordCount: any;
  sharedMsg: string;
  sharedEndCount = 10;
  sharedStartCount = 0;
  sharedStartEndCount = "";
  sharedEndListCount = 10;
  sharedStartListCount = 0;
  sharedGridData: any[] = [];
  sharedStartEndListCount: string;
  startEndListCount: string;
  staticMsgs: any;
  data1: any;
  addtoFavourite: any;
  //
  public dataStateChange(state: DataStateChangeEvent): void {

    this.state = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      this.filteredData = this.filteredXcelData;
      var gridView = {
        data: this.filteredData,
        total: this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total; // process(this.filteredData, this.state);
    }
    else {
      var gridView = {
        data: this.imageViewMode === 'Shared'? this.sharedGridData : this.apiGridData,
        total: this.imageViewMode === 'Shared'? this.totalSharedRecordCount : this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total; // process(this.apiGridData, this.state);
    }
  }
  //@ViewChild(DataTableDirective, { static: false })
  imageClassifications: Observable<any[]>;
  datatableElement: DataTableDirective;
  min: number;
  max: number;
  Swal = require('sweetalert2');
  showLoader: boolean;
  gridContent = true;
  tableContent = false;
  activeTab: boolean = false;
  selected: any;
  cards: Observable<any[]>;
  searchedKeyword: string;
  copyCard: Observable<any[]>;
  public filterQuery = "";
  idArray: string[];
  notFound: boolean;
  notfoundImg: string;
  imageclassificationValue: string;
  imgLibraryClassificationData: any[];

  enableKeywordVal: string[];
  enableCreatedByVal: string[];
  enableCreatToVal: string[];
  enableCreatFromVal: string[];
  enableStatusVal: string[];
  enableClassVal: string[];
  ///listBtn: boolean = true;
  //cardBtn: boolean = false;
  enableModifToVal: string[];
  enableModifFromVal: string[];
  HeadingText = "Card View";
  btnstatus: boolean = false;
  btnstatus1: boolean = true;
  public filter: CompositeFilterDescriptor;
  classApplied = false;
  checkStatusData = false;
  checkSharedStatusData = false;
  msg: string;
  log: any[];
  createdFromDate: any;
  createdToDate: any;
  modifiedFromDate: any;
  modifiedToDate: any;
  minDate = { year: 1985, month: 1, day: 1 };
  createdStartDate: string;
  modifiedEndDate: string;
  modifiedStartDate: string;
  createdEndDate: string;
  imageData:any;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public SearchData = "";

  paginationStartcount = 0;
  paginationEndcount = 10;
  public startCount = 0;
  public endCount = 10;
  public startCountList = 0;
  public endCountList = 10;
  public startEndCount = "";
  public isPageChanged = false;
  public isScrollDown = false;
  public isGetAllRecords = false;
  public totalRecordCount: any;
  loading: boolean;
  isDataLoading = false;

  constructor(
    private sharedService: SharedService,
    private storageservice: StorageService,
    private dataService: DataService,
    public userservice: UserService,
    private formBuilder: FormBuilder,
    private logger: LogService,
    private dialog: MatDialog,
    public filterService: FilterService,
    private toastService: ToastService,
    public aesdecryptservice: AESDecryptService,
    private router: Router,
    private store: Store<any>) {
    this.allData = this.allData.bind(this);
  }
  // public listDataCopy: any[] = apiGridData;
  public group: any[] = [{
    field: 'name'
  }];
  public state: State = {
    skip: 0,
    take: 10
  };
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredData = filterBy((this.imageViewMode === 'Shared') ? this.sharedGridData : this.apiGridData, filter);
  }

  options = [
    //{ value: 'all', label: 'All' },
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];



classification=[
  { value: 'Techpack', label: 'Techpack' },
  { value: 'Genral', label: 'Genral' },
  { value: 'Manuals', label: 'Manuals' },
  { value: 'Concept', label: 'Concept' },
  { value: 'Compliance', label: 'Compliance' },
  { value: 'Lab Reports', label: 'Lab Reports' },
  { value: 'Audit Reports', label: 'Audit Reports' },
  { value: 'Details', label: 'Details' },
  { value: 'CAD', label: 'CAD' },
  { value: 'Measurement', label: 'Measurement' },
  { value: 'Cover Page', label: 'Cover Page' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Branding', label: 'Branding' },
  { value: 'Packaging', label: 'Packaging' },
  { value: 'colorways', label: 'colorways' },
  { value: 'Size Details', label: 'Size Details' },
  { value: 'Design Card', label: 'Design Card' },
  { value: 'Silhouette', label: 'Silhouette' },
  { value: 'Detail Sketch', label: 'Detail Sketch' },
  { value: 'Inspiration', label: 'Inspiration' },
]

  //public gridData: any[] = apiGridData;

  isChecked = false;
  checkuncheckall() {
    if (this.isChecked === true) {
      this.isChecked = false;
    } else {
      this.isChecked = true;
    }
  }

  onPageChange(e: PageChangeEvent): void {
    this.isPageChanged = true;
    this.state.skip = e.skip;
    this.state.take = e.take;
    if(this.imageViewMode === 'Shared'){
      this.sharedStartListCount = e.skip;
      this.sharedEndListCount = e.skip + e.take;
      this.loadSharedListData();
    } else {
      this.startCountList = e.skip;
      this.endCountList = e.skip + e.take;
      this.loadDataList();
    }
  }

  scrollEvent = (event: any): void => {
    // console.log(this.imageData,'imagedata');
    if (this.gridContent && this.router.url.includes('imagelibrary') && !this.tableContent) {
      if (!this.isDataLoading) {
        if (event.target.scrollTop >= (event.target.scrollHeight - event.target.clientHeight - 2)) {
          if (this.imageViewMode == 'Created') {
            console.log('createddata')
            this.isDataLoading = true;
            if (!this.isGetAllRecords) {
              this.isScrollDown = false;
              this.startCount = this.startCount + this.paginationEndcount;
              this.endCount = this.endCount + this.paginationEndcount;
               this.loadData();
            }
          } else {
            this.isDataLoading = true;
            console.log('shareddata');
            if (!this.isSharedGetAllRecords) {
              this.isScrollDown = false;
              this.sharedStartCount = this.sharedStartCount + this.paginationEndcount;
              this.sharedEndCount = this.sharedEndCount + this.paginationEndcount;
              this.loadSharedData();
            }
          }

        }
      }
    }
  }

  imageView(image) {
    this.isMyImages = (image === 'Created') ? true : false
    this.cards = null;
    this.notFound = false;
    this.isEditMode = false;
    if(this.tableContent){
      this.closeAllRows();
    }
    this.imageViewMode = image;
    if (this.imageViewMode == 'Shared') {
      this.myimage = false;
      this.sharedimage = true;
    }
    else {
      this.myimage = true;
      this.sharedimage = false;
    }

    if (this.gridContent) {
      this.HeadingText = "Card View";
      this.imageViewMode == 'Shared' ? this.loadSharedData() : this.loadData();
    }
    else {
      this.HeadingText = "List View";
      this.imageViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();
    }
  }

  cardView() {
    this.gridContent = true;
    this.isMyImages = true;
    this.tableContent = false;
    this.showNote = false;
    this.HeadingText = "Card View";
    ///this.listBtn= true;
    ///this.cardBtn = false;
    this.btnstatus = false;
    this.btnstatus1 = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', 'Active');
    }, 10);
    if (this.imageViewMode === 'Shared') {
      this.isSharedGetAllRecords = false;
      this.sharedCards = [];
      this.sharedStartCount = this.paginationStartcount;
      this.sharedEndCount = this.paginationEndcount;
      this.loadSharedData();
    } else {
      this.isGetAllRecords = false;
      this.startCount = this.paginationStartcount;
      this.endCount = this.paginationEndcount;
      this.loadData();
    }
  }

  tableView() {
    this.gridContent = false;
    this.isMyImages = false;
    this.tableContent = true;
    this.HeadingText = "List View";
    this.btnstatus = true;
    this.btnstatus1 = false;
    if(this.imageViewMode === 'Shared'){
      if (this.isPageChanged) {
        this.sharedStartListCount = this.state.skip;
        this.sharedEndListCount = this.state.skip + this.state.take;
      }
      else {
        this.sharedStartListCount = this.paginationStartcount;
        this.sharedEndListCount = this.paginationEndcount;
      }
    } else {
      if (this.isPageChanged) {
        this.startCountList = this.state.skip;
        this.endCountList = this.state.skip + this.state.take;
      }
      else {
        this.startCountList = this.paginationStartcount;
        this.endCountList = this.paginationEndcount;
      }
    }

    this.imageViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();

  }

  ngOnInit() {
    // localStorage.removeItem('ImagelibraryActive')
    // this.imageData=localStorage.getItem('ImagelibraryActive');

    this.staticMsgs=appStaticMsg.data[0];
     window.addEventListener('scroll', this.scrollEvent, true);
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    this.storageservice.storeEditedImageId('');
    $(document).ready(function () {
      $('.selectpicker').selectpicker('val', 'Active');
    });

    this.advImageForm = this.formBuilder.group({
      keyword: [''],
      createdBy: [''],
      classifications: [''],
      status: [''],
      createdFrom: [''],
      createdTo: [''],
      modifiedFrom: [''],
      modifiedTo: ['']
    });
    this.gridDataForm = this.formBuilder.group({
      formArray: new FormArray([])
    });

    this.loadData();
    // initialise apiGridData form with empty form array
    this.sharedService.sendClickEvent();

  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataService.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  ngAfterViewInit() {

  }
  public allData(): ExcelExportData {

    this.filteredData = filterBy((this.imageViewMode === 'Shared') ? this.sharedGridData : this.apiGridData, this.state.filter);
    //this.state.take = this.filteredData.length;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.filteredData = this.filteredXcelData;
      this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    const result: ExcelExportData = {
      data: this.filteredData //process(this.filteredData, this.state).data,
    };
    //this.state.take = 5;
    return result;
  }

  get f() { return this.gridDataForm.controls; }
  get fa() { return this.f.formArray as FormArray; }

  fac(data) {
    if(this.imageViewMode === 'Shared'){
      return this.fa.controls[this.sharedGridData.indexOf(data)] as FormGroup;
    } else {
      return this.fa.controls[this.apiGridData.indexOf(data)] as FormGroup;
    }

  }

  onEdit() {

    // store copy of original apiGridData in case cancelled
    this.originalData = this.imageViewMode === 'Shared'? [...this.sharedGridData] :[...this.apiGridData];
    var gridView = {
      data: this.originalData,
      total: this.imageViewMode === 'Shared'? this.totalSharedRecordCount :this.totalRecordCount,
    };
    this.gridData = gridView;
    // reset / initialise form fields
    this.resetForm();

    // set all rows to edit mode to display form fields
    this.editGridRows();
    this.isEditMode = true;

  }

  jsonDeleteCompare(element, index, array) {

    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }
  jsonCompare(element, index, array) {

    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      Object.keys(element).forEach(key => {

        if (element[key] != dataRow[0][key]) {
          diff++;
        }
      })
    }
    if (diff > 0) {
      return true;
    }
    else {
      return false;
    }

  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
      this.loading = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.userservice.logout();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.showLoader = false;
      this.loading = false;
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
      this.sessionTimedOut();
      this.userservice.logout();
      this.showLoader = false;
      this.loading = false;
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.staticMsgs.unResponsive.error);

    }
    this.showLoader = false;
    this.loading = false;
  }

  onSave() {
    // mark all fields as touched to highlight any invalid fields
    this.gridDataForm.markAllAsTouched();
    for (let i = 0; i < this.gridDataForm.value.formArray.length; i++) {
      if (this.gridDataForm.controls.formArray['controls'][i].controls.name.value.length > 50) {
        this.gridDataForm.controls.formArray['controls'][i].controls.name.setErrors({ 'incorrect': true });
      }
    }
    // stop here if form is invalid
    if (this.gridDataForm.invalid) {
      this.showNote = true;
      // this.error(this.staticMsgs.imgLib.invalidField)
      this.toastService.showError('Please enter all mandatory fields');
      //alert('FORM INVALID :(');
      return;
    }

    if (this.gridDataForm.dirty) {
      // copy form data to apiGridData array on success
      if(this.imageViewMode === 'Shared'){
        this.sharedGridData = this.fa.value;
      } else {
        this.apiGridData = this.fa.value;
      }
      this.closeAllRows();
      let updDocumentList = this.imageViewMode === 'Shared' ? this.sharedGridData.filter((x, i) => this.jsonCompare(x, i, this.originalData)) : this.apiGridData.filter((x, i) => this.jsonCompare(x, i, this.originalData));
      if (updDocumentList.length > 0) {
        this.showLoader = true;
        for (let i = 0; i < updDocumentList.length; i++) {
          updDocumentList[i].baseClassification = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].baseClassification);
          updDocumentList[i].classification = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].classification);
          updDocumentList[i].name = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].name);
          updDocumentList[i].status = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].status);
          updDocumentList[i].sequence = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].sequence);
        }
        this.store.dispatch(new imageActionTypes.UpdateImageLibrary(updDocumentList))
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageLibraryData) => {
          if (imageLibraryData.image.error === "") {
            if (imageLibraryData.image.message['item2'] === 'Success') {
              this.showLoader = false;
              this.showNote = false;
              // this.success(this.staticMsgs.imgLib.docUpd);
              this.toastService.showSuccess(this.staticMsgs.imgLib.docUpd);
              this.logger.info(this.staticMsgs.imgLib.docUpd1, "ImageLibrary");
              this.imageViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
            } else {
              // this.error("Failed:" + imageLibraryData.image.message['item1'])
              this.toastService.showError(imageLibraryData.image.message['item1']);
              this.imageViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              this.logger.error(imageLibraryData.image.message['item1'], "ImageLibrary");
              this.showLoader = false;
            }

          } else {
            this.logger.error(imageLibraryData.image.error.error.message, "ImageLibrary");
            this.getError(imageLibraryData.image.error);
            this.showLoader = false;
          }
        })
        }
      if (this.originalData.length != (this.imageViewMode == 'Shared' ? this.sharedGridData.length : this.apiGridData.length)) {
        this.showLoader = true;
        let deleterecordIds = [];
        var DelDocumentList;
        if(this.imageViewMode === 'Shared'){
          DelDocumentList = this.originalData.filter((x, i) => this.jsonDeleteCompare(x, i, this.sharedGridData));
        } else {
          DelDocumentList = this.originalData.filter((x, i) => this.jsonDeleteCompare(x, i, this.apiGridData));
        }
        if (DelDocumentList.length > 0) {
          DelDocumentList.forEach((x, i) => {
            deleterecordIds.push(x.id);
          });

          this.store.dispatch(new imageActionTypes.DeleteImageLibrary(deleterecordIds))
          this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageLibraryData) => {
            if (imageLibraryData.image.error === "") {
              if (imageLibraryData.image.message['item2'] === 'Success') {
                this.showLoader = false;
                this.showNote = false;
                // this.success(this.staticMsgs.imgLib.docDelete);
                this.toastService.showSuccess(this.staticMsgs.imgLib.docDelete);
                this.logger.info(this.staticMsgs.imgLib.docDelete, "ImageLibrary");
                this.imageViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              } else {
                this.imageViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
                // this.error("Failed:" + imageLibraryData.image.message['item1'])
                this.toastService.showError(imageLibraryData.image.message['item1']);
                this.logger.error(imageLibraryData.image.message['item1'], "ImageLibrary");
                this.showLoader = false;
              }

            } else {
              this.logger.error(imageLibraryData.image.error.error.message, "ImageLibrary");
              this.getError(imageLibraryData.image.error);
              this.showLoader = false;
            }
          })

          }
      }
    }
    this.isEditMode = false;
    this.isRowEditMode = false;
    this.loading = false;
  }

  onCancel() {
    this.closeAllRows();

    // reset apiGridData back to original data (before edit was clicked)
    this.imageViewMode === 'Shared' ? this.sharedGridData :this.apiGridData = this.originalData;
    var gridView = {
      data: this.imageViewMode === 'Shared' ? this.sharedGridData :this.apiGridData,
      total: this.imageViewMode === 'Shared' ? this.totalSharedRecordCount :this.totalRecordCount,
    };
    this.gridData = gridView;
    this.isEditMode = false;
  }

  // helper methods

  private editGridRows() {
    if(this.imageViewMode ==='Shared'){
      this.filteredData = filterBy(this.sharedGridData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        if (x.isEdit) {
          var documentRow = this.fa.controls[this.sharedGridData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, documentRow);
        }
      });
    } else {
      this.filteredData = filterBy(this.apiGridData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        if (x.isEdit) {
          var documentRow = this.fa.controls[this.apiGridData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, documentRow);
        }
      });
    }
  }

  private closeAllRows() {
    if (this.imageViewMode === 'Shared') {
      // close all rows to display readonly view of data
      this.sharedGridData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    } else {
      // close all rows to display readonly view of data
      this.apiGridData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    }
  }

  private resetForm() {
    // clear form array and create a new form group for each product
    this.fa.clear();
    if (this.imageViewMode === 'Shared') {
      this.sharedGridData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
    } else {
      this.apiGridData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
    }
  }

  private createFormGroup(data1: any = {}) {
    // create a new form group containing controls and validators for a product
    return this.formBuilder.group({
      name: [data1.name],//, Validators.required],
      //sequence: [data1.sequence],
      baseClassification: [data1.baseClassification],
      classification: [data1.classification],
      status: [data1.status],
      createdBy: [data1.createdBy],
      id: [data1.id],
      isEdit: [data1.isEdit],
      isDelete: [data1.isDelete],
    })
  }
  selectedValues:any;
  onSelect(val: any, type: any) {
    this.selectedValues = val;
    if (type === 'myimage') {
      if (val == "") {
        this.cards = this.copyCard;
      } else {
        this.cards = this.copyCard.pipe(
          map(data => data.filter(res => res.status.toLowerCase() == val.toLowerCase()))
        )
      }
      var data = this.apiGridData.filter(x => x.status.toLowerCase() == val.toLowerCase())

      if (data.length > 0) {

        this.checkStatusData = false;
        console.log(this.checkStatusData,'checkfdata')
      } else {
        this.msg = (val.toLowerCase() === 'active') ? 'No active records available' : 'No inactive records available';
        this.checkStatusData = true;
        console.log(this.checkStatusData,'checkfdata1')
      }

    } else {
      if (val == "") {
        this.sharedCards = this.sharedCopyCard;
      } else {
        this.sharedCards = this.sharedCopyCard.filter(res => res.status.toLowerCase() == val.toLowerCase())
      }
      if (this.sharedCards.length > 0) {
        this.checkSharedStatusData = false;
      } else {
        this.sharedMsg = (val.toLowerCase() === 'active') ? 'No active shared records available' : 'No inactive shared records available';
        this.checkSharedStatusData = true;
      }

    }
  }


  searchListData(val: string) {

    if(this.imageViewMode === 'Shared'){
      if (val !== "") {
        let arr = [];
        this.filterQuery = val;
        this.filteredData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) { return data; }
        });
      }
      else {
        this.filteredData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== "") {
        let arr = [];
        this.filterQuery = val;
        this.filteredData = this.apiGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) { return data; }
        });
      }
      else {
        this.filteredData = this.apiGridData;
        this.state.take = 10;
        //return
      }
    }

    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    //this.apiGridData = this.filteredData;

    var gridView = {
      data: this.filteredData,
      total: this.imageViewMode=='Shared' ? this.totalSharedRecordCount: this.totalRecordCount,
    };
    this.gridData = gridView;// process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(this.filteredData, this.state.filter);
  }

decryptData(x){
  x.baseClassification = this.aesdecryptservice.decryptData(this.dataService.Key, x.baseClassification);
  x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
  x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
  x.org = this.aesdecryptservice.decryptData(this.dataService.Key, x.org);
  x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
  x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
  x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
  x.CreatedbyName = x.createdBy.firstName;
  x.createdBy.email = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.email);
  x.createdBy.imageUrl = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.imageUrl);
  x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
  if (x.thumbnailFiles) {
    x.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail);
  }
}

  loadSharedListData(){
      this.sharedStartEndListCount = this.sharedStartListCount + "-" + this.sharedEndListCount;
      this.dataService.getDocument(this.sharedStartEndListCount, this.imageViewMode).subscribe(response => {
        if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
          this.isDataLoading = false;
          this.logger.info(this.staticMsgs.imgLib.loadSucc, "ImageLibrary");
          this.showLoader = false;
          this.sharedGridData = [];
          response['body']['item1'].forEach(x => {
            this.decryptData(x);
            this.sharedGridData.push(x);
          });
          this.totalSharedRecordCount = response['body']['item3'];
          if (this.sharedGridData.length > 0) {
            this.sharedNotFound = false;
          } else {
            this.sharedNotFound = true;
          }
          this.sharedGridData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          var gridView = {
            data: this.sharedGridData,
            total: this.totalSharedRecordCount,
          };
          this.gridData = gridView;
          this.loading = false;
          this.getColorClassification("Documents_Images");
        } else {
          this.isSharedScrollDown = true;
          if (response['body']['item2'] !== 'Success') {
            // this.error("Failed:" + response['body']['item1'])
            this.toastService.showError(response['body']['item1']);
            this.logger.error(response['body']['item1'], "ImageLibrary");
          }
          this.showLoader = false;
          this.sharedNotFound = true;
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, "ImageLibrary");
        this.showLoader = false;
      })
  }

  loadSharedData() {
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', 'Active');
    }, 10);
    if (!this.isSharedGetAllRecords) {
      this.sharedStartEndCount = this.sharedStartCount + "-" + this.sharedEndCount;
      this.dataService.getDocument(this.sharedStartEndCount, this.imageViewMode).subscribe(response => {
        if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
          this.isDataLoading = false;
          this.logger.info(this.staticMsgs.imgLib.loadSucc, "ImageLibrary");
          this.showLoader = false;
          response['body']['item1'].forEach(x => {
            this.decryptData(x);
            this.sharedCards.push(x);
            this.sharedCards = this.sharedCards.filter((item, index) => this.sharedCards.indexOf(item) === index)
          });
          this.sharedCopyCard = [];
          this.sharedCopyCard = this.sharedCards;
          this.totalSharedRecordCount = response['body']['item3'];
          if (this.sharedCards.length > 0) {
            this.sharedNotFound = false;
          } else {
            this.sharedNotFound = true;
          }
          if (this.sharedCards.length == this.totalSharedRecordCount) {
            this.isSharedGetAllRecords = true;
          }
          else {
            this.isSharedScrollDown = true;
          }

           this.getColorClassification("Documents_Images");
           this.onSelect('Active', 'shared')

        } else {
          this.isSharedScrollDown = true;
          if (response['body']['item2'] !== 'Success') {
            // this.error("Failed:" + response['body']['item1'])
            this.toastService.showError(response['body']['item1']);
            this.logger.error(response['body']['item1'], "ImageLibrary");
          }
          this.showLoader = false;
          this.sharedNotFound = true;
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, "ImageLibrary");
        this.showLoader = false;
      })
    }
  }

  loadData() {
    // if(this.data1=='Active'){
      this.startEndCount = this.startCount + "-" + this.endCount;
    setTimeout(() => {
      // if(this.imageData){
      // $('.selectpicker').selectpicker('val', this.imageData);
      // }else{
        $('.selectpicker').selectpicker('val', 'Active');

      // }
    }, 10);
  // }else{
  //   setTimeout(() => {
  //     $('.selectpicker').selectpicker('val', 'Inactive');
  //   }, 10);
  // }


    this.store.dispatch(new imageActionTypes.LoadImageLibrary(this.startEndCount, this.imageViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageLibrary) => {
      console.log(imageLibrary.image.item1,'imageLibrary');
      if (imageLibrary.image.error === "") {
        if (imageLibrary.image.message['item2'] === 'Success' ) {
          this.isDataLoading = false;
          this.logger.info(this.staticMsgs.imgLib.loadSucc, "ImageLibrary");
          this.showLoader = false;

          this.cards = this.store.pipe(select(imageReducer.getImageLibarary));
          this.copyCard = this.store.pipe(select(imageReducer.getImageLibarary));



          // console.log(this.cards,'cards');
          this.fa.clear();
          this.apiGridData = imageLibrary.image.message['item1'];
          this.totalRecordCount = imageLibrary.image.message['item3'];
          if (this.apiGridData.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          if (imageLibrary.image.ids.length == this.totalRecordCount) {
            this.isGetAllRecords = true;
          }
          else {
            this.isScrollDown = true;
          }
          this.apiGridData?.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          // if(localStorage.getItem('Active')){
          //   console.log('active');
          // }else{

          // this.onSelect('Inactive', 'myimage')
          // console.log('Inactive')
          // }
          //this.gridData = process(this.apiGridData, this.state);

          this.onSelect('Active', 'myimage');



          this.getColorClassification("Documents_Images");



        } else {
          this.isScrollDown = true;
          // this.error("Failed:" + imageLibrary.image.message['item1'])
          this.toastService.showError(imageLibrary.image.message['item1']);
          this.logger.error(imageLibrary.image.message['item1'], "ImageLibrary");
          this.showLoader = false;
          this.notFound = true;
        }
      } else {
        this.logger.error(imageLibrary.image.error.error.message, "ImageLibrary");
        this.getError(imageLibrary.image.error);
        this.showLoader = false;
      }
    })
  }

  loadDataList() {
    this.loading = true;
    this.startEndListCount = this.startCountList + "-" + this.endCountList;
    this.store.dispatch(new imageActionTypes.LoadImageLibrary(this.startEndListCount, this.imageViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageLibrary) => {
      if (imageLibrary.image.error === "") {
        if (imageLibrary.image.message['item2'] === 'Success') {
          // this.cards = this.store.pipe(select(imageReducer.getImageLibarary));
          // this.copyCard = this.store.pipe(select(imageReducer.getImageLibarary));
          this.logger.info(this.staticMsgs.imgLib.loadSucc, "ImageLibrary");
          this.fa.clear();
          this.apiGridData = imageLibrary.image.message['item1'];
          this.totalRecordCount = imageLibrary.image.message['item3'];
          if (this.apiGridData.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.apiGridData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          var gridView = {
            data: this.apiGridData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView;
          this.loading = false;
          this.onSelect(this.selectedValues, 'myimage');
         this.getColorClassification("Documents_Images");

        } else {
          // this.error("Failed:" + imageLibrary.image.message['item1'])
          this.toastService.showError(imageLibrary.image.message['item1']);
          this.logger.error(imageLibrary.image.message['item1'], "ImageLibrary");
          this.loading = false;
          this.notFound = true;
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.logger.error(imageLibrary.image.error.error.message, "ImageLibrary");
        this.getError(imageLibrary.image.error);
      }
    })
  }

  editClick( rowIndex, dataItem ) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditedImageId(dataItem.id);
    this.storageservice.storeEditedImageDetailsId(dataItem.id);
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    const dialogRef = this.dialog.open(ImageCustomComponent,{
      disableClose:true
    });
    dialogRef.afterClosed().subscribe(result=>{
    this.loadDataList();
    })

  }
  clickedRowItem: any;
  dblClick(e) {
    var dataindx;
    if (this.clickedRowItem.dataItem.isEdit) {
      this.isEditMode = true;
      // this.isRowEditMode = true;
      this.closeAllRows();
      if (this.imageViewMode === 'Shared') {
        // store copy of original documents in case cancelled
        this.originalData = [...this.sharedGridData];

        // set single row to edit mode to display form fields
        dataindx = this.sharedGridData.indexOf(this.clickedRowItem.dataItem);
      } else {
        // store copy of original documents in case cancelled
        this.originalData = [...this.apiGridData];

        // set single row to edit mode to display form fields
        dataindx = this.apiGridData.indexOf(this.clickedRowItem.dataItem);
      }

      // var documentRow = this.fa.controls[rowIndex] as FormGroup;
      var documentRow = this.fa.controls[dataindx] as FormGroup;

      this.grid.editRow(this.clickedRowItem.rowIndex, documentRow);
    }
    else {
      alert(this.staticMsgs.imgLib.noPermission);

    }

  }
  AddToFavorite(e,card) {

    this.showLoader = true;

    let selected = e.target.id.replace("checkbox", "");
    if (selected != '' && e.target.checked == true) {
      let data: Favourite[] = [];
      data.push(new Favourite(selected));
      this.dataService.bulkAddFavourites(data, "Documents").subscribe(response => {
        let res = response.body;


        this.showLoader = false;
        if (response.status === 200) {
          if (res.item2.toLowerCase().includes("success")) {
            this.logger.info(this.staticMsgs.imgLib.addFav, "ImageLibrary");
            // this.success(this.staticMsgs.imgLib.addFav1);
            this.toastService.showSuccess(this.staticMsgs.imgLib.addFav1);
            res.isFavourite = true;
            card.isFavourite=res.isFavourite;
            console.log( card.isFavourite,'isfv');
          }
          else {
            // this.error(res.item1);
            this.toastService.showError(res.item1);
            this.logger.error(res.item1, "ImageLibrary");
          }
        }

      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, "ImageLibrary");
        this.showLoader = false;
      });



    }
    else if (selected != '' && e.target.checked == false) {
      let data: string[] = [];
      data.push(selected);
      this.dataService.bulkDeleteFavourites(data, "Documents").subscribe(response => {
        let res = response.body;

        this.showLoader = false;
        if (response.status === 200) {
          if (res.item2.toLowerCase().includes("success")) {
            this.logger.info(this.staticMsgs.imgLib.remFav, "ImageLibrary");
            // this.success(this.staticMsgs.imgLib.remFav1);
            this.toastService.showSuccess(this.staticMsgs.imgLib.remFav1);
            res.isFavourite = false;
            card.isFavourite=res.isFavourite;
            console.log( card.isFavourite,'isfv');
          }
          else {
            // this.error(res.item1);
            this.toastService.showError(res.item1);
            this.logger.error(res.item1, "ImageLibrary");
          }
        }

      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, "ImageLibrary");
        this.showLoader = false;
      });
    }

  }
  onRemove(index, dataItem) {
    Swal.fire({
      title: this.staticMsgs.imgLib.delete,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.imgLib.deleteit
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(dataItem.id);
        this.store.dispatch(new imageActionTypes.DeleteImageLibrary(deleterecordIds))
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageLibraryData) => {
          if (imageLibraryData.image.error === "") {
            if (imageLibraryData.image.message['item2'] === 'Success') {
              this.showLoader = false;
              // this.success(this.staticMsgs.imgLib.docDelete);
              this.toastService.showSuccess(this.staticMsgs.imgLib.docDelete);
              this.logger.info(this.staticMsgs.imgLib.docDelete, "ImageLibrary");
              this.loadDataList();
            } else {
              this.loadDataList();
              // this.error("Failed:" + imageLibraryData.image.message['item1'])
              this.toastService.showError(imageLibraryData.image.message['item1']);
              this.logger.error(imageLibraryData.image.message['item1'], "ImageLibrary");
              this.showLoader = false;
            }

          } else {
            this.logger.error(imageLibraryData.image.error.error.message, "ImageLibrary");
            this.getError(imageLibraryData.image.error);
            this.showLoader = false;
          }
        })
        // rows must all be closed while removing apiGridData
        this.closeAllRows();
        if (this.imageViewMode === 'Shared') {
          let dataIndex = this.sharedGridData.indexOf(dataItem);
          // remove product and product form group

          this.sharedGridData = JSON.parse(JSON.stringify(this.sharedGridData));
          this.sharedGridData.splice(dataIndex, 1);
          this.fa.removeAt(dataIndex);

          this.filteredData = filterBy(this.sharedGridData, this.state.filter);
        } else {
          let dataIndex = this.apiGridData.indexOf(dataItem);
          // remove product and product form group

          this.apiGridData = JSON.parse(JSON.stringify(this.apiGridData));
          this.apiGridData.splice(dataIndex, 1);
          this.fa.removeAt(dataIndex);
          this.filteredData = filterBy(this.apiGridData, this.state.filter);
        }
        if (this.SearchData != null && this.SearchData != "") {
          this.searchListData(this.SearchData);
        }
        if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
        // this.apiGridData = this.filteredData;
        //  this.gridData = this.filteredData;
        var gridView = {
          data: this.filteredData,
          total: this.totalRecordCount,
        };
        this.gridData = gridView;// process(this.filteredData, this.state);
        // reset all rows back to edit mode
       // this.editGridRows();
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });

  }
  advImageForm: FormGroup;

  isResetMode = false;
  // @ViewChild('keywordData') keywordDataChild: any;
  @ViewChild('createdFrom') createdFromChild: any;
  @ViewChild('createdTo') createdToChild: any;
  @ViewChild('modifiedFrom') modifiedFromChild: any;
  @ViewChild('modifiedTo') modifiedToChild: any;
  /*classification = [
    { value: 'all', label: 'All' },
    { value: 'branding', label: 'Branding' },
    { value: 'cad', label: 'CAD' },
    { value: 'colorways', label: 'Colorways' },
    { value: 'construction', label: 'Construction' },
    { value: 'coverpage', label: 'Cover page' },
    { value: 'designcard', label: 'Design Card' },
    { value: 'detailsketch', label: 'Detail Sketch' },
    { value: 'details', label: 'Details' },
    { value: 'inspiration', label: 'Inspiration' },
    { value: 'measurement', label: 'Measurement' },
    { value: 'packaging', label: 'Packaging' },
    { value: 'silhouette', label: 'Silhouette' },
    { value: 'sizedetails', label: 'Size Details' },
    { value: 'other', label: 'Other' },
  ];*/
  get f1() { return this.advImageForm.controls; }
  onSubmit(value) {
    //console.log(value);
    //console.log(this.imageclassificationValue);
    this.showLoader = true;

    let createdFromChildStr = this.createdStartDate;
    let createdToChildStr = this.createdEndDate;
    let modifiedFromChildStr = this.modifiedStartDate;
    let modifiedToChildStr = this.modifiedEndDate;

    if ((createdFromChildStr !== null && createdToChildStr == null) && (createdFromChildStr !== undefined && createdToChildStr == undefined)) {
      // this.error(this.staticMsgs.imgLib.createTo);
      this.toastService.showError(this.staticMsgs.imgLib.createTo);
      this.logger.error(this.staticMsgs.imgLib.createTo, "ImageLibrary");
      this.showLoader = false;
      return;
    }
    else if ((createdFromChildStr == null && createdToChildStr !== null) && (createdFromChildStr == undefined && createdToChildStr !== undefined)) {
      // this.error(this.staticMsgs.imgLib.createForm);
      this.toastService.showError(this.staticMsgs.imgLib.createForm);
      this.logger.error(this.staticMsgs.imgLib.createForm, "ImageLibrary");
      this.showLoader = false;
      return;
    }
    if ((modifiedFromChildStr !== null && modifiedToChildStr == null) && (modifiedFromChildStr !== undefined && modifiedToChildStr == undefined)) {
      // this.error(this.staticMsgs.imgLib.modifiedTo);
      this.toastService.showError(this.staticMsgs.imgLib.modifiedTo);
      this.logger.error(this.staticMsgs.imgLib.modifiedTo, "ImageLibrary");
      this.showLoader = false;
      return;
    }
    else if ((modifiedFromChildStr == null && modifiedToChildStr !== null) && (modifiedFromChildStr == undefined && modifiedToChildStr !== undefined)) {
      // this.error(this.staticMsgs.imgLib.modifiedFrom);
      this.toastService.showError(this.staticMsgs.imgLib.modifiedFrom);
      this.logger.error(this.staticMsgs.imgLib.modifiedFrom, "ImageLibrary");
      this.showLoader = false;
      return;
    }

    let adv_data = {
      keyword: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.keyword.value),
      /// keyword: this.keywordDataChild.nativeElement.value,
      createdBy: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.createdBy.value),
      // classifications: this.f1.classifications.value,
      classification: this.aesdecryptservice.encryptData(this.dataService.Key, this.imageclassificationValue),
      status: this.f1.status.value,
      createdFrom: createdFromChildStr,
      createdTo: createdToChildStr,
      modifiedFrom: modifiedFromChildStr,
      modifiedTo: modifiedToChildStr
    }
    console.log(adv_data);
    //this.advForm = this.formBuilder.group({});
    //this.advForm = this.formBuilder.group(adv_data);

    this.store.dispatch(new commonActionTypes.LoadAdvancedImageSearch(adv_data));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((advancedSearchData) => {
      if (advancedSearchData.common.error === "") {
        if (advancedSearchData.common.message['item2'] === 'Success') {
          this.cards = this.store.pipe(select(commonReducer.getCommon));
          this.copyCard = this.store.pipe(select(commonReducer.getCommon));
          this.apiGridData = advancedSearchData.common.message['item1'];
          this.apiData = advancedSearchData.common.message['item1'];
          this.isResetMode = true;
          this.showLoader = false;
          var gridView = {
            data: this.apiGridData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView;
        } else {
          if (advancedSearchData.common.message['item1'].toLowerCase().includes("no records")) {
            // this.error(this.staticMsgs.imgLib.noData);
            this.toastService.showError(this.staticMsgs.imgLib.noData);
            this.logger.error(this.staticMsgs.imgLib.noData, "ImageLibrary");
            var gridView = {
              data: this.apiGridData,
              total: this.totalRecordCount,
            };
            this.apiGridData = [];
            this.cards = of([]);
            this.copyCard = of([]);
            this.gridData = gridView;
            this.showLoader = false;
          } else {
            // this.error("Failed:" + advancedSearchData.common.message['item1'])
            this.toastService.showError(advancedSearchData.common.message['item1']);
            this.logger.error(advancedSearchData.common.message['item1'], "ImageLibrary");
            this.showLoader = false;
            this.clearSearch();
          }

        }
      } else {
        this.logger.error(advancedSearchData.common.error.error.message, "ImageLibrary");
        this.getError(advancedSearchData.common.error);
        this.showLoader = false;
      }
    })
    }
  clearSearch() {
    this.clearFields();
    this.loadData();
    this.loadDataList();
    this.isResetMode = false;
  }
  advanceSearchOpen() {
    this.classApplied = true;
    this.getClassificationData()
  }

  advanceSearchClose() {
    this.classApplied = false;

  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    if ((element.nodeName === 'TH') && (element.offsetWidth < element.scrollWidth)) {
      this.tooltipDir.toggle(element);
    }
    else if ((element.offsetWidth < element.scrollWidth) && (element.scrollWidth! < 937) && (this.isEditMode == false)) {
      this.tooltipDir.toggle(element);
    }
    else {
      this.tooltipDir.hide();
    }
  }
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.imgLib.oops,
      text: message
    })
  }
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.imgLib.session,
      html: this.staticMsgs.imgLib.sessExp,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
      }
    })

  }
  /*createColor() {
      this.router.navigate(['/colordetails']);
    }
      onEditColor(id) {
      this.storageservice.storeEditedColorId(id);
      this.router.navigate(['/colordetails']);
    }*/
  HandleError(err) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.userservice.logout();
      this.toastService.showError("The token being used has expired. Please login again.");
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
      this.sessionTimedOut();
      this.userservice.logout();
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.staticMsgs.unResponsive.error);
    }
  }

  setDate(event: any) {

    console.log(event.target.value);
    if (event.target.getAttribute("formControlName") == "createdFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      this.createdToChild.nativeElement.value = createdToChildStr;
    }
    else if (event.target.getAttribute("formControlName") == "modifiedFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      this.modifiedToChild.nativeElement.value = createdToChildStr;
    }
  };
  clearFields() {
    this.createdStartDate = "";
    this.createdEndDate = "";
    this.createdFromDate = "";
    this.createdToDate = "";
    this.modifiedFromDate = "";
    this.modifiedToDate = "";
    this.modifiedStartDate = "";
    this.modifiedEndDate = "";
    this.f1.keyword.patchValue("");
    this.f1.createdBy.patchValue("");
    this.f1.classifications.patchValue("");
    this.f1.status.patchValue("");
    $("#statuslist").val('default').selectpicker("refresh");
    //$("#classificationlist").val('default').selectpicker("refresh");
    this.getClassificationData(); this.imageclassificationValue = "";
    this.enableCreatFromVal = null;
    this.enableCreatToVal = null;
    this.enableModifFromVal = null;
    this.enableModifToVal = null;
    this.enableKeywordVal = null;
    this.enableStatusVal = null;
    this.enableCreatedByVal = null;
    this.imageclassificationValue = "";

  }
  clearfield(name) {
    switch (name) {
      case "createdFromChild": {
        this.createdStartDate = "";
        this.createdEndDate = "";
        this.createdFromDate = "";
        this.createdToDate = "";
        this.enableCreatFromVal = null;
        this.enableCreatToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "modifiedFromChild": {
        this.modifiedFromDate = "";
        this.modifiedToDate = "";
        this.modifiedStartDate = "";
        this.modifiedEndDate = "";
        this.enableModifFromVal = null;
        this.enableModifToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "keyword": { this.f1.keyword.patchValue(""); this.enableKeywordVal = null; this.checkAllFieldVal(); break; }
      case "createdBy": { this.f1.createdBy.patchValue(""); this.enableCreatedByVal = null; this.checkAllFieldVal(); break; }
      case "classification": { this.getClassificationData(); this.imageclassificationValue = ""; this.checkAllFieldVal(); break; }
      case "status": { this.f1.status.patchValue(""); $("#statuslist").val('default').selectpicker("refresh"); this.enableStatusVal = null; this.checkAllFieldVal(); break; }
      //case "createdFromChild": { this.createdFromChild.nativeElement.value = ""; this.createdToChild.nativeElement.value = ""; break; }
      //case "modifiedFromChild": { this.modifiedFromChild.nativeElement.value = ""; this.modifiedToChild.nativeElement.value = ""; break; }
      //case "keyword": { this.f1.keyword.patchValue(""); break; }
      //case "createdBy": { this.f1.createdBy.patchValue(""); break; }
      ////case "classification": { this.f1.classifications.patchValue(""); $("#classificationlist").val('default').selectpicker("refresh"); break; }
      // case "classification": {  this.getClassificationData(); this.imageclassificationValue= ""; break; }
      //case "status": { this.f1.status.patchValue(""); $("#statuslist").val('default').selectpicker("refresh"); break; }
    }
  }

  checkAllFieldVal() {
    if ((this.f1.keyword.value == "") && (this.createdFromDate == "") && (this.modifiedFromDate == "") && (this.f1.createdBy.value == "") && ((this.f1.status.value == "") || (this.f1.status.value == undefined)) && (this.imageclassificationValue == "")) {
      this.clearSearch()
    }
  }

  checkFieldVal(val: string[], val1: string) {
    switch (val1) {
      case "createdFromChild": { this.enableCreatFromVal = val; break; }
      case "createdToChild": { this.enableCreatToVal = val; break; }
      case "modifiedFromChild": { this.enableModifFromVal = val; break; }
      case "modifiedToChild": { this.enableModifToVal = val; break; }
      case "keyword": { this.enableKeywordVal = val; break; }
      case "createdBy": { this.enableCreatedByVal = val; break; }
      case "status": { this.enableStatusVal = val; break; }
    }
  }

  getColorClassification(module: string) {
console.log('getColorClassification');
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.imgLibraryClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.logger.info(this.staticMsgs.imgLib.docClass, "ImageLibrary");
          this.getClassificationData();
          this.showLoader = false;

        } else {
          this.getClassificationData();
          this.showLoader = false;
          // this.error("Failed:" + classificationData.common.message['item1'])
          this.toastService.showError(classificationData.common.message['item1']);
          this.logger.error(classificationData.common.message['item1'], "ImageLibrary");
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, "ImageLibrary");
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })
    /* await this.dataService.getClassification(module).subscribe(response => {
      // console.log(response)
      if (response.body['item2'] === "Success") {
        this.imgLibraryClassificationData = response.body['item1'][0]["items"];
        this.logger.info("Document classification loaded successfully", "ImageLibrary");
        ///console.log(JSON.stringify(this.imgLibraryClassificationData ))
        // this.refreshTree();
        this.getClassificationData();
        this.showLoader = false;
      }
      else {
        //  this.refreshTree();
        this.getClassificationData();
        this.showLoader = false;
      }
    }, err => {
      this.logger.error(err.error.message, "ImageLibrary");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.logger.error(err.message, "ImageLibrary");
        this.error(err.message);
      }
      this.showLoader = false;
    }); */
  }

  getClassificationData() {
    var data = this.imgLibraryClassificationData;
    var self = this;
    $(document).ready(function () {
      $("#imageclassification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "200px",
        filter: "startswith",
        dataSource: data,
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          self.imageclassificationValue = value["text"];
        }
      });

    });
  }

  onEditImage(id) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditedImageId(id);
    this.router.navigate(['/imagedetails']);

  }

  onEditImageDetails(id) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditedImageId(id);
    this.storageservice.storeEditedImageDetailsId(id);
    this.router.navigate(['/imagedetails']);
  }

  onDeleteImage(id) {

    Swal.fire({
      title: this.staticMsgs.imgLib.deleteSure,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.imgLib.deleteit
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(id);
        this.store.dispatch(new imageActionTypes.DeleteImageLibrary(deleterecordIds))
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageLibraryData) => {
          if (imageLibraryData.image.error === "") {
            if (imageLibraryData.image.message['item2'] === 'Success') {
              this.showLoader = false;
              // this.success(this.staticMsgs.imgLib.docDelete);
              this.toastService.showSuccess(this.staticMsgs.imgLib.docDelete);
              this.logger.info(this.staticMsgs.imgLib.docDelete, "ImageLibrary");
              this.loadDataList();
                            // this.loadDataList();
                            // if(this.data1=="Active"){
                              // this.onSelect('Active','myimage');
                              // }
                              // else{
                              //   // console.log('status');
                              //   this.onSelect('Inactive','myimage');
                              // }

            } else {
              this.loadDataList();
              // this.error("Failed:" + imageLibraryData.image.message['item1'])
              this.toastService.showError(imageLibraryData.image.message['item1']);
              this.logger.error(imageLibraryData.image.message['item1'], "ImageLibrary");
              this.showLoader = false;
            }

          } else {
            this.logger.error(imageLibraryData.image.error.error.message, "ImageLibrary");
            this.getError(imageLibraryData.image.error);
            this.showLoader = false;
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  onRemoveCard(id) {
    //alert(id)
    //console.log(this.storageservice.getEditedImageId())
    //this.storageservice.storeEditedImageId(id);
    //console.log(this.storageservice.storeEditedImageId(id))
    //this.storageservice.getEditedImageId() Delete
    var formData = new FormData();
    if (id) {
      formData.append('docid', id);
      formData.append('tag', "Delete");
    }
    this.store.dispatch(new imageActionTypes.CreateImage(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageDocument) => {
      if (imageDocument.image.error === "") {
        if (imageDocument.image.message['item2'] === 'Success') {
          this.showLoader = false;
          this.logger.info(this.staticMsgs.imgLib.cardDelete, "ImageLibrary");
          // this.success(this.staticMsgs.imgLib.cardDelete1);
          this.toastService.showSuccess(this.staticMsgs.imgLib.cardDelete1);
          this.loadData();
        } else {
          this.showLoader = false;
          // this.error("Failed:" + imageDocument.image.message['item1'])
          this.toastService.showError(imageDocument.image.message['item1']);
          this.logger.error(imageDocument.image.message['item1'], "ImageLibrary");
        }
      } else {
        this.logger.error(imageDocument.image.error.error.message, "ImageLibrary");
        this.getError(imageDocument.image.error);
        this.showLoader = false;
      }
    })


    /* this.dataService.createImageDocument(formData).subscribe(response => {
      if (response.body['item2'] === "Success") {
        // alert("Success:" + response.item1);
        this.showLoader = false;
        this.logger.info("Card Deleted Successfully", "ImageLibrary");
        this.success("Card Deleted Successfully!");
        this.loadData();
      }
      else {
        this.logger.error(response.body['item1'], "ImageLibrary");
        this.error(response.body['item1']);
        this.showLoader = false;
      }
    }, err => {
      this.logger.error(err.error.message, "ImageLibrary");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.logger.error(err.message, "ImageLibrary");
        this.error(err.message);
      }
      this.showLoader = false;
    }); */
  }
  dateChange(date: any, type: any) {
    switch (type) {
      case 'createdFromDate':
        this.createdStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdFromDate && this.createdFromDate !== undefined) {
          this.checkFieldVal(['val'], 'createdFromChild');
        }
        break;
      case 'createdToDate':
        this.createdEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdToDate && this.createdToDate !== undefined) {
          this.checkFieldVal(['val'], 'createdToChild')
        }
        break;
      case 'modifiedFromDate':
        this.modifiedStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedFromDate && this.modifiedFromDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedFromChild')
        }
        break;
      case 'modifiedToDate':
        this.modifiedEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedToDate && this.modifiedToDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedToChild')
        }
        break;
    }
  }

}
