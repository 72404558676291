
<div class="dt-page__header">
  <button class="close" mat-button (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="dt-page__title" *ngIf="!data.isOperationedit">Create Operation</h3>
  <h3 class="dt-page__title" *ngIf="data.isOperationedit">Edit Operation</h3>
</div>

<mat-dialog-content class="mat-typography">

  <svg *ngIf="operationload " viewBox="25 25 50 50">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
  <div id="tab-pane" class="tab-pane bg-white active">
    <form [formGroup]="operationform" [ngClass]="operationload == true ? 'datahidden bom_modal_content' : 'bom_modal_content'">
      <div class="form-row">
        <!-- <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">NO</label>
          <input type="text" class="form-control fill" value="0" disabled>

        </div> -->

        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Operation</label>
          <input name="Operation" formControlName="operation" class="form-control fill" placeholder="Enter Operation">
          <div
            *ngIf="(submitted && this.f1.operation.errors) || ( this.f1.operation.invalid && (this.f1.operation.dirty || this.f1.operation.touched))">
            <div *ngIf="this.f1.operation.errors.required" class="red">
              Operation is required
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Machine Type</label>
          <input name="mC_Type" formControlName="mC_Type" class="form-control fill" placeholder="Enter mC_Type">
          <div
            *ngIf="(submitted && this.f1.mC_Type.errors) || ( this.f1.mC_Type.invalid && (this.f1.mC_Type.dirty || this.f1.mC_Type.touched))">
            <div *ngIf="this.f1.mC_Type.errors.required" class="red">
             Machine Type is required
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Seam</label>
          <select formControlName="smv" id="smv" title="Select Seam" class="form-control">
            <option *ngFor="let item of constData" [value]="item?.id">{{item?.name}}</option>
          </select>
          <div
            *ngIf="(submitted && this.f1.smv.errors) || ( this.f1.smv.invalid && (this.f1.smv.dirty || this.f1.smv.touched))">
            <div *ngIf="this.f1.smv.errors.required" class="red">
              Seam is required
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Image</label>
            <input *ngIf="showFileInput" type='file' #fileUploader multiple="false" (change)="onChangeConstructionImg($event)"
            (click)="Modalopen(content)">
            <div *ngIf="showImage" class="col-md-12 text-center sree" >
              <img class="modal-pop" height="50px" width="50px" [src]="croppedImage"/>
            </div>
          <div *ngIf="data.operationData?.image">
            <img
              *ngIf="data.operationData?.image.fileType == '.png' || data.operationData?.image.fileType == '.jpg' || data.operationData?.image.fileType == '.jpeg' || data.operationData?.image.fileType == '.gif'"
              src="{{data.operationData?.image.thumbnail}}" class="img ng-star-inserted" alt="">
              <button *ngIf="data.operationData?.image.fileType == '.png' || data.operationData?.image.fileType == '.jpg' || data.operationData?.image.fileType == '.jpeg' || data.operationData?.image.fileType == '.gif'"
               type="button" class="close image-close"
              (click)="removeThumbnailConstructionSection(images)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div *ngIf="data.operationData?.image">
            <a *ngIf="data.operationData?.image.fileType == '.pdf' || data.operationData?.image.fileType == '.docx'"
              href="{{data.operationData?.image.thumbnail}}" target="_blank" style="text-decoration: underline;">Open <i
                class="fa fa-external-link"></i></a>
                <button *ngIf="data.operationData?.image.fileType == '.pdf' || data.operationData?.image.fileType == '.docx'" class="close image-close"
                (click)="removeThumbnailConstructionSection(images)">
                <span aria-hidden="true">×</span>
              </button>
          </div>
        </div>

          <ng-template #content let-modal>
            <div  *ngIf="isThumbnailAdded==true">
            <!-- Modal Content -->
            <div class="modal-content" >

              <!-- Modal Header -->
              <div class="modal-header" title="Style Image">
                <h3 class="modal-title" id="model-3">Image</h3>
                <button type="button" class="close" aria-label="Close"
                  (click)="closeOperation($event)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- /modal header -->

              <!-- Modal Body -->
              <div class="modal-body loader_content">
            <svg viewBox="25 25 50 50" *ngIf="imgload">
              <circle r="20" cy="50" cx="50"></circle>
            </svg>
                <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="4 / 3"
            format="jpeg/png"
            (imageCropped)="imageCropped($event)"
        ></image-cropper>
              </div>
              <!-- /modal body -->
              <div class="modal-footer">
                <button type="button" #closeModal class="btn btn-outline-primary btn-sm" data-dismiss="modal"
                 (click)="closeOperation($event)">{{appStaticMsgs.common.cancel}}</button>
                <button type="button" class="btn btn-outline-primary btn-sm"
                [disabled]="!fileToReturn" (click)="confirmOperation()" data-dismiss="modal">
                  Confirm
                </button>
            </div>
            </div>
            <!-- /modal content -->
            </div>

          </ng-template>

      </div>
    </form>
  </div>
  <div class="footer">
    <div class="row mt-5 float-right mr-3">
      <button type="submit" class="btn btn-outline-primary btn-sms mr-2" (click)="close()">
        Cancel
      </button>
      <button type="submit" [disabled]="operationform?.invalid" class="btn btn-primary btn-sm " (click)="onOperationSubmit()">
        Save
      </button>
    </div>
  </div>
</mat-dialog-content>
