import * as commonActions from "../actions/common.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface CommonState extends EntityState<any> {
    selectedCommonId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const commonAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultCommon: CommonState = {
    ids: [],
    entities: {},
    selectedCommonId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = commonAdapter.getInitialState(defaultCommon);

export function CommonReducer(state = initialState, action: commonActions.commonActionTypes): CommonState {
    switch (action.type) {
        case commonActions.CommonActionTypes.LOAD_CLASSIFICATIONS_SUCCESS:
            return commonAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case commonActions.CommonActionTypes.LOAD_CLASSIFICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_COLOR_SEARCH_SUCCESS:
            return commonAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                loading: false,
                loaded: true
            })
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_COLOR_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_IMAGE_SEARCH_SUCCESS:
            return commonAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                loading: false,
                loaded: true
            })
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_IMAGE_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_MATERIAL_SEARCH_SUCCESS:
            return commonAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                loading: false,
                loaded: true
            })
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_MATERIAL_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_PALETTE_SEARCH_SUCCESS:
            return commonAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                loading: false,
                loaded: true
            })
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_PALETTE_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_SEAMS_SEARCH_SUCCESS:
            return commonAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                loading: false,
                loaded: true
            })
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_SEAMS_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_STITCH_SEARCH_SUCCESS:
            return commonAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                loading: false,
                loaded: true
            })
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_STITCH_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_SUPPLIER_SEARCH_SUCCESS:
            return commonAdapter.addAll(action.payload1, {
                ...state.message = action.payload2,
                ...state,
                loading: false,
                loaded: true
            })
            break;
        case commonActions.CommonActionTypes.LOAD_ADV_SUPPLIER_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getCommonFeatureState = createFeatureSelector<CommonState>('common');

export const getCommon = createSelector(
    getCommonFeatureState, commonAdapter.getSelectors().selectAll
)
export const getCommonLoading = createSelector(
    getCommonFeatureState, (state: CommonState) => state.loading
)
export const getCommonLoaded = createSelector(
    getCommonFeatureState, (state: CommonState) => state.loaded
)
export const getCommonError = createSelector(
    getCommonFeatureState, (state: CommonState) => state.error
)
export const getCommonMessage = createSelector(
    getCommonFeatureState, (state: CommonState) => state.message
)