import { Injectable } from '@angular/core';
import { environment } from '../../../../tenants/main/environments/environment';
import { NotificationService, PushSubscription , UnSubscription} from './notification-generated';
import { StorageService } from '../helpers/storage.service';
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: 'root'
})
export class NotificationMiddlewareService {
  public pushNotificationStatus = {
    isSubscribed: false,
    isSupported: false,
    isInProgress: false
  };
  private swRegistration = null;
  public notifications = [];

  constructor(private notificationService: NotificationService,
  private deviceDetectorService: DeviceDetectorService,
  private storage: StorageService) { }


  init() {    
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.register('/assets/sw.js')
        .then(swReg => {
          console.log('Service Worker is registered', swReg);
          this.swRegistration = swReg;
          this.checkSubscription();
        })
        .catch(error => {
          console.error('Service Worker Error', error);
        });
      this.pushNotificationStatus.isSupported = true;
    } else {
      this.pushNotificationStatus.isSupported = false;
    }
    navigator.serviceWorker.addEventListener('message', (event) => {
      this.notifications.push(event.data);
    });
  } 



  checkSubscription() {
    this.swRegistration.pushManager.getSubscription()
      .then(response => {
        this.pushNotificationStatus.isSubscribed = !(response === null);
      });
  }

  toggleSubscription() {
    this.subscribeUser();
    // if (this.pushNotificationStatus.isSubscribed) {
    //   //this.unsubscribeUser();
    // } else {
    //   this.subscribeUser();
    // }
  }

  subscribeUser() {
    this.pushNotificationStatus.isInProgress = true;
    const applicationServerKey = this.urlB64ToUint8Array(environment.applicationServerPublicKey);
    this.swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
      .then(result => {
        //console.log(result);
        //console.log(JSON.stringify(result));
        var newSub = JSON.parse(JSON.stringify(result));
        //console.log(newSub);
        let emailId = this.storage.getEmail();
        let deviceInfo = this.deviceDetectorService.userAgent; 
        
        // console.log("data " + JSON.stringify(<PushSubscription>{
        //   name: deviceInfo,
        //   pushEndpoint: newSub.endpoint,
        //   pushP256DH: newSub.keys.p256dh,
        //   pushAuth: newSub.keys.auth,
        //   emailId: emailId,  
        // }));                
        this.notificationService.deviceSubscription(<PushSubscription>{
              name: deviceInfo,
              pushEndpoint: newSub.endpoint,
              pushP256DH: newSub.keys.p256dh,
              pushAuth: newSub.keys.auth,
              emailId: emailId,  
        }).subscribe(response => {
          if (response.status === 200) {
              if (response.body['item2'] === "Success") {                
                console.log("deviceSubscription Success");                
                console.log(JSON.stringify(response.body['item1']));                
              }
              else {                
                console.log(JSON.stringify(response));
                console.log("deviceSubscription Faild");
                console.log(JSON.stringify(response.body['item1']));                
              }
          }          
          this.pushNotificationStatus.isSubscribed = true;
        })
      })
      .catch(err => {
        console.log('Failed to subscribe the user: ', err);
      })
      .then(() => {
        //console.log(result);
        this.pushNotificationStatus.isInProgress = false;
      });
  }

  unsubscribeUser() {
    this.pushNotificationStatus.isInProgress = true;
    var sub;
    this.swRegistration.pushManager.getSubscription()
      .then(function (subscription) {
        if (subscription) {
          sub = JSON.parse(JSON.stringify(subscription));
          return subscription.unsubscribe();
        }
      })
      .catch(function (error) {
        console.log('Error unsubscribing', error);
      })
      .then(() => {

        let emailId = this.storage.getEmail();
        let deviceInfo = this.deviceDetectorService.userAgent;
        
        this.notificationService.deviceUnsubscription(<UnSubscription>{
          DeviceName: deviceInfo,             
          EmailId: emailId
        }).subscribe((response) => {
           if (response.status === 200) {
              if (response.body['item2'] === "Success") {                
                console.log("deviceUnsubscription Success");                
                console.log(JSON.stringify(response.body['item1']));                
              }
              else {                
                console.log(JSON.stringify(response));
                console.log("deviceUnsubscription Faild");
                console.log(JSON.stringify(response.body['item1']));                
              }
          }          

          this.pushNotificationStatus.isSubscribed = false;
          this.pushNotificationStatus.isInProgress = false;
        });
      });
  }

  urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}
