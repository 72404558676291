import * as stitchesActions from "../actions/stitches.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface StitchesState extends EntityState<any> {
    selectedStitchesId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const stitchesAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultStitches: StitchesState = {
    ids: [],
    entities: {},
    selectedStitchesId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = stitchesAdapter.getInitialState(defaultStitches);

export function StitchesReducer(state = initialState, action: stitchesActions.stitchesActionTypes): StitchesState {
    switch (action.type) {
        case stitchesActions.StitchesActionTypes.LOAD_STITCHES_LIBRARY_SUCCESS:
            return stitchesAdapter.addMany(action.payload1, {...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case stitchesActions.StitchesActionTypes.LOAD_STITCHES_LIBRARY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case stitchesActions.StitchesActionTypes.LOAD_STITCHES_SUCCESS:
            return stitchesAdapter.setOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedStitchesId: action.payload1.id
            })
            break;
        case stitchesActions.StitchesActionTypes.LOAD_STITCHES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stitchesActions.StitchesActionTypes.CREATE_STITCHES_SUCCESS:
            return stitchesAdapter.addOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedStitchesId: action.payload1.id
            })
            break;
        case stitchesActions.StitchesActionTypes.CREATE_STITCHES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stitchesActions.StitchesActionTypes.UPDATE_STITCHES_SUCCESS:
            return stitchesAdapter.updateOne(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case stitchesActions.StitchesActionTypes.UPDATE_STITCHES_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stitchesActions.StitchesActionTypes.UPDATE_STITCHES_LIBRARY_SUCCESS:
            return stitchesAdapter.updateMany(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case stitchesActions.StitchesActionTypes.UPDATE_STITCHES_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stitchesActions.StitchesActionTypes.DELETE_STITCHES_LIBRARY_SUCCESS:
            return stitchesAdapter.removeMany(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case stitchesActions.StitchesActionTypes.DELETE_STITCHES_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case stitchesActions.StitchesActionTypes.UPDATE_STITCHES_DOCUMENT_SUCCESS:
            return stitchesAdapter.updateOne(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case stitchesActions.StitchesActionTypes.UPDATE_STITCHES_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getStitchesLibraryFeatureState = createFeatureSelector<StitchesState>('stitches');

export const getStitchesLibrary = createSelector(
    getStitchesLibraryFeatureState, stitchesAdapter.getSelectors().selectAll
)
export const getStitchesLibraryLoading = createSelector(
    getStitchesLibraryFeatureState, (state: StitchesState) => state.loading
)
export const getStitchesLibraryLoaded = createSelector(
    getStitchesLibraryFeatureState, (state: StitchesState) => state.loaded
)
export const getStitchesLibraryError = createSelector(
    getStitchesLibraryFeatureState, (state: StitchesState) => state.error
)
export const getStitchesLibraryMessage = createSelector(
    getStitchesLibraryFeatureState, (state: StitchesState) => state.message
)
export const getStitchesId = createSelector(
    getStitchesLibraryFeatureState, (state: StitchesState) => state.selectedStitchesId
)
export const getStitches = createSelector(
    getStitchesLibraryFeatureState, getStitchesId, state => state.entities[state.selectedStitchesId]
)