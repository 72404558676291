import {NavigationModelInterface} from '../../../gaxon/components';
import { DataService } from '../../services/data.service';

export class NavigationModel implements NavigationModelInterface {
  public navigation: any[];

  constructor(navigation) {
        
    this.navigation = navigation
    // this.navigation = [
    //   {
    //     id: 'dashboards',
    //     title: 'Dashboard',
    //     type: 'item',
    //     icon: 'dashboard',
    //     url: '/dashboard'
    //   },
    //   {
    //     id: 'style',
    //     title: 'Style',
    //     type: 'item',
    //     icon: 'customer',
    //     url: 'main/style'
    //   },
    //   {
    //     id: 'projectlibrary',
    //     title: 'Project Library',
    //     type: 'item',
    //     icon: 'task-manager',
    //     url: 'main/projectlibrary'
    //   },
    //   {
    //     id: 'Libraries',
    //     title: 'Libraries',
    //     type: 'group',
    //     icon: 'widgets',
    //     children: [
    //       {
    //         id: 'materiallibrary',
    //         title: 'Material Library',
    //         type: 'collapse',
    //         icon: 'widgets',
    //         children: [
    //           {
    //             id: 'fabric',
    //             title: 'Fabric',
    //             type: 'item',
    //             icon: 'double-arrow-right',
    //             url: 'components/tables/base-table'
    //           },
    //           {
    //             id: 'trims',
    //             title: 'Trims',
    //             type: 'item',
    //             icon: 'double-arrow-right',
    //             url: 'components/tables/data-table'
    //           },
    //           {
    //             id: 'labels',
    //             title: 'Labels',
    //             type: 'item',
    //             icon: 'double-arrow-right',
    //             url: 'components/tables/data-table'
    //           },
    //           {
    //             id: 'accessories',
    //             title: 'Accessories',
    //             type: 'item',
    //             icon: 'double-arrow-right',
    //             url: 'components/tables/data-table'
    //           },
    //           {
    //             id: 'packaging',
    //             title: 'Packaging',
    //             type: 'item',
    //             icon: 'double-arrow-right',
    //             url: 'components/tables/data-table'
    //           },
    //           {
    //             id: 'leather',
    //             title: 'Leather',
    //             type: 'item',
    //             icon: 'double-arrow-right',
    //             url: 'components/tables/data-table'
    //           }
    //         ]
    //       },
    //       {
    //         id: 'constructionlibrary',
    //         title: 'Construction Library',
    //         type: 'collapse',
    //         icon: 'components',
    //         children: [
    //           {
    //             id: 'seams',
    //             title: 'Seams',
    //             type: 'item',
    //             icon: 'double-arrow-right',
    //             url: 'components/tables/base-table'
    //           },
    //           {
    //             id: 'stitches',
    //             title: 'Stitches',
    //             type: 'item',
    //             icon: 'double-arrow-right',
    //             url: 'components/tables/data-table'
    //           }
    //         ]
    //       }
    //     ]
    //   },

    //   {
    //     id: 'imagesdoccumentlibrary',
    //     title: 'Images & Document Library',
    //     type: 'item',
    //     icon: 'file-upload',
    //     url: 'main/projectlibrary'
    //   },
    //   {
    //     id: 'colorlibrary',
    //     title: 'Color Library',
    //     type: 'item',
    //     icon: 'color-picker',
    //     url: 'main/projectlibrary'
    //   },
    //   {
    //     id: 'supplierlibrary',
    //     title: 'Supplier Library',
    //     type: 'item',
    //     icon: 'customers',
    //     url: 'main/projectlibrary'
    //   },
    //   {
    //     id: 'sizinglibrary',
    //     title: 'Sizing Library',
    //     type: 'item',
    //     icon: 'projects',
    //     url: 'main/projectlibrary'
    //   }
    // ];
  }
}
