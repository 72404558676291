import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { DataService } from '../../../services/data.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
//import { first } from 'rxjs/operators';
import { CustomValidators } from './custom-validators';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from '../../../../../../aws-exports';
import { LogService } from '../../../shared/log.service';
import { interval } from 'rxjs';
import { timeZone } from './timezone';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { ToastService } from '../../../services/toast.service';

//import { Select2OptionData } from 'ng-Select2';
declare var $: any;
Amplify.configure(awsconfig);

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  @ViewChild(LoaderComponent) child: LoaderComponent;
  showLoader: boolean;
  @ViewChild('content') content: any;
  regForm: FormGroup;
  loading = false;
  submitted = false;
  errors = '';
  errorDiv = false;
  alert = '';
  flag: any;
  modalReference: any;
  phone_number = '';
  staticMsgs: any;

  public countrycodes: any[] = [];
  // public countrycodes: Array<Select2OptionData>;
  backarrow: boolean = true;
  registrationdiv: boolean = false;
  registrationtext: boolean = true;
  log: any[];

  makerImg = '../../../../assets/images/register-img/maker.jpg';
  collaboratorImg = '../../../../assets/images/register-img/collaborator.jpg';
  TimeZone: any;
  maker: boolean;
  collaborator: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dataservice: DataService,
    private auth: AuthService,
    private logger: LogService,
    private modalService: NgbModal,
    private dataService: DataService,
    private toastService: ToastService
  ) {
    if (sessionStorage.getItem('flag')) {
      this.flag = sessionStorage.getItem('flag');
    } else {
      this.flag = 'Maker';
    }
  }

  ngOnInit(): void {
    this.staticMsgs = appStaticMsg.data[0];
    this.dataService.AddClientLog();
    const source = interval(1.2e8);
    source.subscribe((val) => this.dataService.AddClientLog());
    this.showLoader = true;
    $(document).ready(function () {
      //$('.selectpicker').selectpicker();
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $("[data-toggle='popover']").popover({ html: true });

      if (!('ontouchstart' in window)) {
        $('.tip').tooltip();
      }

      $(function passwordValidation() {
        var pwdInput = $('#password');
        var pwdValid = false;

        function validatePwdStrength() {
          var pwdValue = $(this).val(); // This works because when it's called it's called from the pwdInput, see end

          // Validate the length
          if (pwdValue.length > 7) {
            $('#length').removeClass('invalid').addClass('valid');
            pwdValid = true;
          } else {
            $('#length').removeClass('valid').addClass('invalid');
          }

          // Validate capital letter
          if (pwdValue.match(/[A-Z]/)) {
            $('#capital').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#capital').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // Validate lowercase letter
          if (pwdValue.match(/[a-z]/)) {
            $('#lowercase').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#lowercase').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // special character
          if (pwdValue.match(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            $('#number-special').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#number-special').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // Validate number
          if (pwdValue.match(/[\d\\]/)) {
            $('#number-special1').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#number-special1').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }
        }

        pwdInput.bind('change keyup input', validatePwdStrength); // Keyup is a bit unpredictable
      }); // END passwordValidation()

      $('#password')
        .focusin(function () {
          $('#password-validation-box').show();
        })
        .focusout(function () {
          $('#password-validation-box').hide();
        });

      $('.toggle-password').click(function () {
        $(this).toggleClass('fa-eye fa-eye-slash');
        const input = $($(this).attr('toggle'));
        if (input.attr('type') === 'password') {
          input.attr('type', 'text');
        } else {
          input.attr('type', 'password');
        }
      });
    });

    this.showLoader = false;
    this.initForm();
    this.getCountrycodes();
    console.log('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.TimeZone = timeZone;
  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataService.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  async getCountrycodes() {
    const data = await this.dataservice.getCountrycodes();
    this.logger.info(
      this.staticMsgs.registration.countryCode,
      this.staticMsgs.registration.Register
    );
    this.countrycodes = this.countrycodes.concat(data['countrycodes']);
    this.refreshSelect();
    // this.countrycodes = data['countrycodes'];
    // this.countrycodes = [
    // {id: 1, name: "+1", value: "+1"},
    // {id: 2, name: "+91", value: "+91"},
    // {id: 3, name: "+65", value: "+65"},
    // {id: 4, name: "+134", value: "+134"}
    // ]
  }
  numberOnly(e: any) {
    var regex = new RegExp('^[0-9]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characterOnly(e: any) {
    var regex = new RegExp('^[a-zA-Z0-9- ]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  // Method to initialise the Registration form.
  initForm() {
    this.showLoader = true;
    const flag_filled = sessionStorage.getItem('flag_filled');
    if (flag_filled != '1') {
      this.backarrow = true; //making it has true to show arrow icon
      // this.registrationdiv = true;
      this.registrationtext = false;
    }

    this.regForm = this.fb.group(
      {
        firstname: [
          '',
          [Validators.required, Validators.pattern('^[a-zA-Z0-9- ]{3,40}$')],
        ],
        lastname: [
          '',
          [Validators.required, Validators.pattern('^[a-zA-Z0-9- ]{3,15}$')],
        ],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}$'
            ),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
            Validators.maxLength(15),
          ],
        ],
        confirmpassword: [
          '',
          [
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
            Validators.maxLength(15),
          ],
        ],
        // confirmpassword: ['', [Validators.required]],
        phone_number: ['', [Validators.pattern('^[1-9][0-9]{9}$')]],
        phone_number_code: ['+1'],
        company: [
          '',
          [
            Validators.required,
            Validators.maxLength(100),
          ],
        ],
        timeZone: ['Central Standard Time'],
        terms_and_conditions: [
          false,
          [Validators.requiredTrue, Validators.required],
        ],
      },
      {
        // validator: ConfirmedValidator('password', 'confirmpassword')
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator(
          this.staticMsgs.common.password,
          this.staticMsgs.forgotpassword.cp
        ),
      }
    );
    this.showLoader = false;
    //this.refreshSelect();
    //this.select_2cssupdate();
  }

  get f() {
    return this.regForm.controls;
  }

  // Method to submit the Registration form to AWS cognito Sign-Up
  async onSubmit() {
    console.log('Registerhere');
    this.submitted = true;

    const firstname = this.f.firstname.value,
      lastname = this.f.lastname.value,
      email = this.f.email.value,
      password = this.f.password.value,
      confirmpassword = this.f.confirmpassword.value,
      phone_number_value = this.f.phone_number.value,
      phone_number_code = this.f.phone_number_code.value,
      phone_number = phone_number_code + phone_number_value,
      company = this.f.company.value;
    if (password != confirmpassword) {
      this.regForm.invalid;
      this.alert = this.staticMsgs.registration.danger;

      this.errorDiv = true;
      // this.errors = 'Password and Confirm password should be same';
      this.toastService.showError('Password and Confirm password should be same');
    }
    if (!this.f.terms_and_conditions.value) {
      this.regForm.invalid;
      this.alert = this.staticMsgs.registration.danger;
      this.errorDiv = true;
      // this.errors = 'Please accept terms & conditions';
      this.toastService.showError('Please accept terms & conditions');
    } else if (this.regForm.invalid) {
      return;
    } else {
      this.showLoader = true;
      localStorage.setItem('verify_user', '1');

      if (this.f.phone_number.value) {
        this.phone_number =
          this.f.phone_number_code.value + this.f.phone_number.value;
      }

      let user_data = {
        firstName: this.f.firstname.value,
        lastName: this.f.lastname.value,
        group: this.flag,
        eMail: this.f.email.value,
        org: this.f.company.value,
        status: '',
        rolename: '',
      };

      var params4 = {
        Username: email,
        UserPoolId: awsconfig.aws_user_pools_id,
      };

      if (company) {
        var params1 = {
          GroupName: company,
          UserPoolId: awsconfig.aws_user_pools_id,
        };

        try {
          const result: AWS.CognitoIdentityServiceProvider.CreateGroupResponse =
            await this.auth.callCreateGroup(params1);

          if (result.Group.GroupName == company) {
            try {
              var signupparams;

              if (this.phone_number) {
                signupparams = {
                  username: email,
                  password,
                  attributes: {
                    email,
                    phone_number,
                    name: firstname + ' ' + lastname,
                  },
                };
              } else {
                signupparams = {
                  username: email,
                  password,
                  attributes: {
                    email,
                    name: firstname + ' ' + lastname,
                  },
                };
              }

              const user = await Auth.signUp(signupparams);

              //console.log({ user });
              if (user.user) {
                var params2 = {
                  GroupName: this.flag /* required */,
                  UserPoolId: awsconfig.aws_user_pools_id /* required */,
                  Username: email /* required */,
                };

                var params3 = {
                  GroupName: company /* required */,
                  UserPoolId: awsconfig.aws_user_pools_id /* required */,
                  Username: email /* required */,
                };

                try {
                  const result1 = await this.auth.calladdUsertoGroup(params2);
                  const result2 = await this.auth.calladdUsertoGroup(params3);

                  if (
                    Object.keys(result1).length === 0 &&
                    Object.keys(result2).length === 0
                  ) {
                    user_data.rolename = 'Administrator';
                    console.log(user_data, 'userData');
                    this.dataservice.registerUserDetails(user_data).subscribe(
                      (response) => {
                        if (response.status === 200) {
                          if (response.body['item2'] === 'Success') {
                            localStorage.setItem('register_username', email);
                            this.showLoader = false;
                            this.alert = this.staticMsgs.registration.success;
                            this.errorDiv = true;
                            this.errors =
                              this.staticMsgs.registration.registrationSucess +
                              ',' +
                              this.staticMsgs.registration.verifyAccount;
                            this.logger.info(
                              this.staticMsgs.registration.registrationSucess,
                              this.staticMsgs.registration.Register
                            );

                            // setTimeout(() => {
                            //   this.router.navigate(['auth/verifyuser']);
                            // }, 2000);
                            console.log('calling insertDataForNewUser method1');
                            // this.dataService.insertDataForNewUser();
                            this.dataService.AddClientLog();
                            this.router.navigate(['auth/verifyuser']);
                            this.log = JSON.parse(
                              localStorage.getItem(
                                new Date().toLocaleDateString()
                              )
                            );
                            if (this.log != null) {
                              this.dataService
                                .addClientLog(this.log)
                                .subscribe();
                              this.log = [];
                              this.logger.clear();
                            }
                            sessionStorage.removeItem('flag_filled');
                            sessionStorage.removeItem('flag');
                          } else {
                            console.log(response);
                            this.alert = this.staticMsgs.registration.danger;
                            this.errorDiv = true;
                            // this.errors = response.body['item2'];
                            this.toastService.showError(response.body['item2'])
                            this.logger.error(
                              response.body['item2'],
                              this.staticMsgs.registration.Register
                            );

                            this.deleteGroup(params1);
                            this.deleteUser(params4);
                            this.showLoader = false;
                          }
                        } else {
                          console.log(response);
                          this.alert = this.staticMsgs.registration.danger;
                          this.errorDiv = true;
                          this.logger.error(
                            response.err,
                            this.staticMsgs.registration.Register
                          );

                          this.errors = response.err;
                          this.deleteGroup(params1);
                          this.deleteUser(params4);
                          this.showLoader = false;
                        }
                      },
                      (err) => {
                        this.alert = this.staticMsgs.registration.danger;
                        this.errorDiv = true;
                        this.logger.error(
                          err.Message,
                          this.staticMsgs.registration.Register
                        );

                        this.errors = err.message;
                        this.deleteGroup(params1);
                        this.deleteUser(params4);
                        this.showLoader = false;
                      }
                    );
                  }
                } catch (err1) {
                  //console.error("ERROR:" + err1);
                  if (err1.code == 'ResourceNotFoundException') {
                    this.alert = this.staticMsgs.registration.danger;
                    this.errorDiv = true;
                    this.errors = this.staticMsgs.registration.role;
                    this.logger.error(
                      this.staticMsgs.registration.role,
                      this.staticMsgs.registration.Register
                    );
                  } else {
                    this.alert = this.staticMsgs.registration.danger;
                    this.errorDiv = true;
                    this.logger.error(
                      err1.Message,
                      this.staticMsgs.registration.Register
                    );

                    this.errors = err1.message;
                  }
                  this.showLoader = false;
                }
              }
            } catch (error) {
              // console.log('error signing up:', error);
              this.showLoader = false;
              this.alert = this.staticMsgs.registration.danger;
              this.errorDiv = true;
              // this.errors = error.message;
              this.toastService.showError(error.message);
              this.logger.error(
                error.Message,
                this.staticMsgs.registration.Register
              );
            }
          }
        } catch (err) {
          //console.error("ERROR:" + err);
          if (err.code == 'GroupExistsException') {
            this.alert = this.staticMsgs.registration.danger;
            this.errorDiv = true;
            //this.errors = this.staticMsgs.userregister.company + this.f.company.value + "-1\" or \"" + this.f.company.value + "-2\".";
            // this.errors =
            //   'The organization you have entered is already registered by another user. Please contact the admin to invite you to the organization.';
            this.toastService.showError('The organization you have entered is already registered by another user. Please contact the admin to invite you to the organization.');
          }
          else if(err.code.toLowerCase()){
            this.toastService.showError("Please ensure that the organization name does not include any spaces");
          } else {
            this.alert = this.staticMsgs.registration.danger;
            this.errorDiv = true;
            this.errors = err.message;
            this.logger.error(
              err.Message,
              this.staticMsgs.registration.Register
            );
          }
          this.showLoader = false;
        }
      } else {
        try {
          if (this.phone_number) {
            signupparams = {
              username: email,
              password,
              attributes: {
                email,
                phone_number,
                name: firstname + ' ' + lastname,
              },
            };
          } else {
            signupparams = {
              username: email,
              password,
              attributes: {
                email,
                name: firstname + ' ' + lastname,
              },
            };
          }

          const user = await Auth.signUp(signupparams);

          //console.log({ user });
          if (user.user) {
            var params2 = {
              GroupName: this.flag /* required */,
              UserPoolId: awsconfig.aws_user_pools_id /* required */,
              Username: email /* required */,
            };

            try {
              const result1 = await this.auth.calladdUsertoGroup(params2);
              if (Object.keys(result1).length === 0) {
                user_data.group = 'Guest';
                user_data.rolename = 'Guest';
                this.dataservice.registerUserDetails(user_data).subscribe(
                  (response) => {
                    if (response.status === 200) {
                      if (response.body['item2'] === 'Success') {
                        localStorage.setItem('register_username', email);
                        this.showLoader = false;
                        this.alert = this.staticMsgs.registration.success;
                        this.errorDiv = true;
                        this.errors =
                          this.staticMsgs.registration.registrationSucess +
                          ',' +
                          this.staticMsgs.registration.verifyAccount;
                        this.logger.info(
                          this.staticMsgs.registration.registrationSucess,
                          this.staticMsgs.registration.Register
                        );
                        // setTimeout(() => {
                        //   this.router.navigate(['auth/verifyuser']);
                        // }, 2000);
                        console.log('calling insertDataForNewUser method2');
                        // this.dataService.insertDataForNewUser();
                        //.subscribe(res=>{ console.log(res) },err=>{ console.log(err)});
                        this.dataService.AddClientLog();
                        this.router.navigate(['auth/verifyuser']);
                        this.log = JSON.parse(
                          localStorage.getItem(new Date().toLocaleDateString())
                        );
                        if (this.log != null) {
                          this.dataService.addClientLog(this.log).subscribe();
                          this.log = [];
                          this.logger.clear();
                        }
                        sessionStorage.removeItem('flag_filled');
                        sessionStorage.removeItem('flag');
                      } else {
                        this.alert = this.staticMsgs.registration.danger;
                        this.errorDiv = true;
                        this.errors = response.body['item2'];
                        this.logger.error(
                          response.body['item2'],
                          this.staticMsgs.registration.Register
                        );
                        this.deleteUser(params4);
                        this.showLoader = false;
                      }
                    } else {
                      this.alert = this.staticMsgs.registration.danger;
                      this.errorDiv = true;
                      this.errors = response.err;
                      this.logger.error(
                        response.err,
                        this.staticMsgs.registration.Register
                      );
                      this.deleteUser(params4);
                      this.showLoader = false;
                    }
                  },
                  (err) => {
                    this.alert = this.staticMsgs.registration.danger;
                    this.errorDiv = true;
                    this.errors = err.message;
                    this.logger.error(
                      err.Message,
                      this.staticMsgs.registration.Register
                    );

                    this.deleteUser(params4);
                    this.showLoader = false;
                  }
                );
              }
            } catch (err1) {
              //console.error("ERROR:" + err1);
              if (err1.code == 'ResourceNotFoundException') {
                this.alert = this.staticMsgs.registration.danger;
                this.errorDiv = true;
                this.errors = this.staticMsgs.registration.role;
                this.logger.error(
                  this.staticMsgs.registration.role,
                  this.staticMsgs.registration.Register
                );
              } else {
                this.alert = this.staticMsgs.registration.danger;
                this.errorDiv = true;
                this.errors = err1.message;
                this.logger.error(
                  err1.Message,
                  this.staticMsgs.registration.Register
                );
              }
              this.showLoader = false;
            }
          }
        } catch (error) {
          //console.log('error signing up:', error);
          this.showLoader = false;
          this.alert = this.staticMsgs.registration.danger;
          this.errorDiv = true;
          this.errors = error.message;
        }
      }
    }
  }

  backarraowclick() {
    //sessionStorage.setItem('flag_filled', '0');
    // sessionStorage.removeItem('flag');
    // this.registrationdiv = true;
    // this.backarrow = false;
    // this.registrationtext = false;
    this.router.navigate(['/auth/login']);
  }

  // Method to open the Role selection Modal pop-up
  open(content) {
    let ngbModalOptions: NgbModalOptions = {
      // backdrop: 'static',
      // keyboard: false
    };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
  }

  // Methods to assign the Role to variable "flag"
  makerclick(flag) {
    this.flag = flag;
    this.maker = true;
    console.log(flag, 'maker');
    sessionStorage.setItem('flag_filled', '1');
    sessionStorage.setItem('flag', this.flag);
    location.reload();
    //this.registrationdiv = false;
    //this.backarrow = true;
    //this.registrationtext = true;
  }

  collaboratorclick(flag) {
    this.flag = flag;
    this.collaborator = true;
    console.log(flag, 'collaborator');
    sessionStorage.setItem('flag_filled', '1');
    sessionStorage.setItem('flag', this.flag);
    location.reload();
    //this.registrationdiv = false;
    //this.backarrow = true;
    //this.registrationtext = true;
  }

  // Method to close the Role selection Pop-Up
  continue() {
    //alert(this.flag);
    this.modalReference.close();
  }

  async deleteGroup(params) {
    return await this.auth.callDeleteGroup(params);
  }

  async deleteUser(params) {
    return await this.auth.callDeleteUser(params);
  }

  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }
  // select_2cssupdate(){
  //   $(document).ready(function () {
  //     alert("cc")
  //   $('.select2-container .select2-selection--single').css('height','calc(1em + 2.12rem + 2px) !important');
  //   $('.select2-selection__rendered').css('padding-top','3px');
  //   $('.select2-selection__arrow').css('top','5px');
  //   $('.select2-search__field').css('border','1px solid ff9100');
  //   $('.select2-container').css('left','-16px');
  //   });
  // }
}
