  <!-- Root -->
  <div class="dt-root">
    <div class="dt-root__inner">
        <div class="dt-page--container">

<!-- 404 Page -->
<div class="error-page text-center">

    <!-- Title -->
    <h1 class="title">404</h1>
    <!-- /title -->

    <p class="display-2 text-dark mb-7">Sorry! The page not found</p>
    <p class="mb-10">The Link You Followed Probably Broken, or the page has been removed.</p>

    <!-- Search Box -->
    <form class="search-box right-side-icon">
        <!-- <input class="form-control form-control-lg" type="search" id="address" name="address"
               placeholder="Search in app...">
        <button type="submit" class="search-icon"><i class="icon icon-search icon-lg"></i></button> -->

        <button type="button" (click)="redirect()" class="btn btn-primary adv-search-btn">Back To Homepage</button>
    </form>
    <!-- /search box -->

    <!---->

</div>
<!-- /404 page -->

</div>        </div>
</div>
<!-- /root -->
