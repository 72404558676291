import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  returnUrl: string;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let params = this.route.snapshot.queryParams;
    if (params['returnUrl']) {
      this.returnUrl = params['returnUrl'];
    }
    if (this.returnUrl) {
      setTimeout(() => {
      this.router.navigateByUrl(this.returnUrl,)
        .catch(() =>
            this.router.navigate(['/dashboard'])
        )
      }, 3000)
    } else {
      setTimeout(() => {
        this.router.navigate(['/dashboard'])
      }, 3000);
    }
  }

  redirect() {
    this.router.navigate(['/dashboard'])
  }

}
