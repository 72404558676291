import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { DataService } from '../../../services/data.service';
import { interval, timer } from 'rxjs';
//import { first } from 'rxjs/operators';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
// tslint:disable-next-line: import-spacing
import { StorageService } from '../../../helpers/storage.service';
import { TokenService } from '../../../helpers/token.service';
import { UserService } from '../../../helpers/user.service';
import { ExceptionService } from '../../../helpers/exception.service';
import { Auth } from 'aws-amplify';
import awsconfig from '../../../../../../aws-exports';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { NotificationMiddlewareService } from '../../../services/notification-middleware.service';
import { filter } from 'rxjs/operators';
import { io } from 'socket.io-client';
import { LogService } from '../../../shared/log.service';
import { DeviceDetectorService } from "ngx-device-detector";
import * as userActions from "../../../actions/userActions.actions";
import { select, Store } from '@ngrx/store';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
const socket = io(environment.socketIoServerUrl, { transports: ['websocket', 'polling', 'flashsocket'] });
declare var $: any;


@Component({
  selector: 'app-existed-user-login',
  templateUrl: './existed-user-login.component.html',
  styleUrls: ['./existed-user-login.component.css']
})

export class ExistedUserLoginComponent implements OnInit {

  @ViewChild(LoaderComponent) child: LoaderComponent;
  showLoader: boolean;
  appStaticMsg: any;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  errors = '';
  errorDiv = false;
  userData: [];
  userLoggedIn: boolean;
  userNotVerified: boolean;
  alertMessage = '';
  codeSent: boolean;
  newPassword: boolean;
  alert = '';
  disabled: boolean;
  returnUrl: string;
  isInvitedUserOrg: any
  public log: any[];
  email: any;
  company: any;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private store: Store<any>,
    private storage: StorageService,
    private token: TokenService,
    private exception: ExceptionService,
    public notificationMiddleware: NotificationMiddlewareService,
    private deviceDetectorService: DeviceDetectorService,
    private userService: UserService,
    private logger: LogService,
    private dataService: DataService,
    public aesdecryptservice: AESDecryptService,

  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      //console.log(event.url);
    });

  }

  ngOnInit(): void {
    this.appStaticMsg = appStaticMsg.data[0];
    console.log(this.appStaticMsg, 'appStaticMsg');
    $('.toggle-password').click(function () {

      $(this).toggleClass('fa-eye-slash fa-eye');
      const input = $($(this).attr('toggle'));
      if (input.attr('type') === 'password') {
        input.attr('type', 'text');
      } else {
        input.attr('type', 'password');
      }
    });

    // get return url from route parameters or default to '/'
    let params = this.route.snapshot.queryParams;
    if (params['returnUrl']) {
      this.returnUrl = params['returnUrl'];
    }
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    localStorage.removeItem('otp_sent');
    //this.checkLoginSession();
    this.initForm();
    $(".modal-backdrop").remove();
  }



  async socialSignIn(socialProvider: string) {

    let socialPlatformProvider;
    const socialParams = {
      ClientId: awsconfig.aws_user_pools_web_client_id,
      UserPoolId: awsconfig.aws_user_pools_id,
      AppWebDomain: awsconfig.oauth.domain,
      TokenScopesArray: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      RedirectUriSignIn: awsconfig.oauth.redirectSignIn,
      RedirectUriSignOut: awsconfig.oauth.redirectSignOut,
      ResponseType: 'token',
    };

    const fbOauthUrl = 'https://' + socialParams.AppWebDomain + '/oauth2/authorize'
      + '?redirect_uri=' + socialParams.RedirectUriSignIn
      + '&response_type=code'
      + '&client_id=' + socialParams.ClientId
      + '&identity_provider=' + socialProvider;

    window.location.assign(fbOauthUrl);

  }

  initForm() {
    const loaded_flag = sessionStorage.getItem('loaded_flag');
    if (loaded_flag != '1') {
      this.route.queryParams.subscribe(
        params => {
          var decodeEmail = window.decodeURIComponent(params['email']);
          var decodeCompany = window.decodeURIComponent(params['company']);
          this.email = this.aesdecryptservice.decryptData(this.dataService.Key, decodeEmail);
          this.company = this.aesdecryptservice.decryptData(this.dataService.Key, decodeCompany);
        }
      )

      sessionStorage.setItem('loaded_flag', '1');
      sessionStorage.setItem('email', this.email);
      sessionStorage.setItem('company', this.company);
      this.loginForm = this.fb.group({
        username: [this.email, [Validators.required]],
        password: ['', [Validators.required]],
        rememberme: ['']
      });
    }
    else {
      const lusername = localStorage.getItem('login_username');
      const lpassword = localStorage.getItem('login_password');
      const rememberme = localStorage.getItem('login_rememberme');

      this.loginForm = this.fb.group({
        username: [lusername, [Validators.required]],
        password: [lpassword, [Validators.required]],
        rememberme: [rememberme]
      });

    }

  }

  get f() { return this.loginForm.controls; }

  backarraowclick() {
    // sessionStorage.setItem('flag_filled', '0');
    // sessionStorage.removeItem('flag');
    // this.dataService.AddClientLog();
    // this.router.navigate(['auth/login']);
  }

  async onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.logger.error("Loginform is invalid", "Login")
      return;
    }
    this.showLoader = true;
    this.disabled = true;
    // tslint:disable-next-line: one-variable-per-declaration
    const username = this.f.username.value,
      password = this.f.password.value,
      remember = this.f.rememberme.value;
    // tslint:disable-next-line: variable-name

    this.removeRememberMe();

    if (remember) {
      this.addRememberMe(username, password, remember);
    }

    try {
      const user = await Auth.signIn(username, password);
      if (user) {
        socket.emit('login', { userId: user.attributes["email"] });
      }

      console.log('check user challenge', user);
      //localStorage.setItem('role_filled', user.attributes["custom:role_filled"]);
      //localStorage.setItem('user', user.attributes["name"]);
      this.storage.storeUser(user.attributes["name"]);
      this.showLoader = false;
      this.checkLoginSession();

    } catch (error) {
      this.disabled = false;
      //console.log('error signing in', error);
      this.logger.error(error.message, "Login")

      if (error.code === 'UserNotConfirmedException') {
        this.userNotVerified = true;
      } else if (error.code === 'UserNotFoundException') {
        this.alert = 'danger';
        this.errorDiv = true;
        this.errors = error.message;
      } else if (error.code === 'PasswordResetRequiredException') {
        this.alert = 'danger';
        this.errorDiv = true;
        this.errors = error.message;
      } else if (error.code === 'NotAuthorizedException') {
        this.alert = 'danger';
        this.errorDiv = true;
        this.errors = error.message;
      } else if (error.code === 'NetworkError') {
        this.alert = 'danger';
        this.errorDiv = true;
        this.errors = 'Please check your internet connection.';
      } else {
        //console.log(error);
        this.alert = 'danger';
        this.errorDiv = true;
        this.errors = error.message;
      }
      this.showLoader = false;
    }

  }

  async verifyUser() {
    const username = this.f.username.value;
    localStorage.setItem('verify_user', '1');
    localStorage.setItem('register_username', username);

    try {
      await Auth.resendSignUp(username);
      this.userNotVerified = false;
      this.alertMessage = 'success';
      this.codeSent = true;
      this.dataService.AddClientLog();
      this.router.navigate(['auth/verifyuser']);
      this.log = JSON.parse(localStorage.getItem(new Date().toLocaleDateString()))
      if (this.log != null) {
        this.dataService.addClientLog(this.log).subscribe();
        this.log = [];
        this.logger.clear();
      }
      this.log = JSON.parse(localStorage.getItem(new Date().toLocaleDateString()))
      if (this.log != null) {
        this.dataService.addClientLog(this.log).subscribe();
        this.log = [];
        this.logger.clear();
      }

      // console.log('code resent succesfully');
    } catch (err) {
      //console.log('error resending code: ', err);
      this.logger.error(err.message, "Login")

      this.userNotVerified = false;
      this.alertMessage = 'danger';
      this.codeSent = false;
      this.alert = 'danger';
      this.errorDiv = true;
      this.errors = err.message;
      this.disabled = false;
    }

  }



  async checkLoginSession() {
    //alert("Coimng here..................")
    await this.auth.checkUser().then(data => {
      if (data) {
        //alert("Coimng 1.")
        console.log('check login session', data);
        // tslint:disable-next-line: no-string-literal
        if (this.token.isValid(data['idToken'].jwtToken)) {
          // tslint:disable-next-line: max-line-length
          this.storage.Store(data['idToken'].payload.name, data['idToken'].payload.email, data['idToken'].jwtToken, data['accessToken'].jwtToken, data['idToken'].payload['cognito:groups']);

          this.alert = 'success';
          this.errorDiv = true;
          this.errors = 'Login Successful';
          var userroles: string[];
          const rolesString = this.storage.getRoles();
          if (rolesString) {
            userroles = rolesString.substring(0, rolesString.length).split(", ");
            if (userroles.some(role => role.includes("Collaborator"))) {
              this.storage.storeUserMainRole("Collaborator")
              //localStorage.setItem('userrole', "Collaborator");
            }
            else if (userroles.some(role => role.includes("Maker"))) {
              this.storage.storeUserMainRole("Maker")
              //localStorage.setItem('userrole', "Maker");
            }
          }

          if (this.returnUrl) {
            if (this.returnUrl.includes("/auth/userlogin?returnUrl=")) {
              this.router.navigate(['/dashboard'])
            }
            else {
              if (this.returnUrl.includes("/styledetails")) {
                this.router.navigate(['/styles'])
              } else if (this.returnUrl.includes("/colordetails")) {
                this.router.navigate(['/colorlibrary'])
              } else if (this.returnUrl.includes("/palettedetails")) {
                this.router.navigate(['/palettelibrary'])
              } else if (this.returnUrl.includes("/imagedetails")) {
                this.router.navigate(['/imagelibrary'])
              } else if (this.returnUrl.includes("/supplierdetails")) {
                this.router.navigate(['/supplierlibrary'])
              } else if (this.returnUrl.includes("/materialdetails")) {
                this.router.navigate(['/materiallibrary'])
              } else if (this.returnUrl.includes("/stitchesdetails")) {
                this.router.navigate(['/stitcheslibrary'])
              } else if (this.returnUrl.includes("/seamsdetails")) {
                this.router.navigate(['/seamslibrary'])
              } else if (this.returnUrl.includes("/moodboard")) {
                this.router.navigate(['/moodboardlibrary'])
              } else {
                setTimeout(() => {
                  this.router.navigateByUrl(this.returnUrl,)
                    .catch(() =>
                      this.router.navigate(['/dashboard'])
                    )
                }, 10)
              }
            }
          } else {
            setTimeout(() => {
              this.dataService.saveOrganisationForuser(this.email, this.company).subscribe(res => {
              })
              this.dataService.AddClientLog();
              this.router.navigate(['dashboard']);
              this.log = JSON.parse(localStorage.getItem(new Date().toLocaleDateString()))
              if (this.log != null) {
                this.dataService.addClientLog(this.log).subscribe();
                this.log = [];
                this.logger.clear();
              }
            }, 10);
          }

          // this.dataService.insertDataForNewUser().subscribe(res=>{
          //   console.log('res',res);
          // },err=>{
          //   console.log('err',err);
          // });

          this.notificationMiddleware.toggleSubscription();
          // const deviceName = this.deviceDetectorService.userAgent;
          // const devicedata = {};
          // devicedata["deviceName"] = deviceName;
          // this.dataService.getExistingDevice(devicedata).subscribe((response) => {
          //   if (response.status === 200) {
          //     if (response.body['item1'] === "False") {
          //       this.showLoader = false;
          //       this.notificationMiddleware.toggleSubscription();
          //     }
          //     else {
          //       this.showLoader = false;
          //     }
          //   }
          //   else {
          //     this.showLoader = false;
          //   }
          // },
          // err => {
          //     if (err.error.message === "Network error communicating with endpoint") {
          //       this.showLoader = false;
          //     } else if ((err.error.message === "Unauthorized") || (err.error.message === "The incoming token has expired")) {
          //       this.showLoader = false;
          //     }
          // });


          // this.router.navigate(['/']);
        } else {
          this.disabled = false;
          this.store.dispatch(new userActions.Logout());
          this.store.pipe(select(state => state));
          this.auth.signOut();
          this.storage.clear();
          this.alert = 'danger';
          this.errorDiv = true;
          this.errors = 'The login is invalid . logging in again.';
          this.logger.error("The login is invalid", "Login");
        }

        // }
        // alert(data.accessToken.jwtToken)
        // localStorage.setItem('username', data.accessToken.payload.username);
        // localStorage.setItem('accessToken', data.accessToken.jwtToken);
        // this.router.navigate(['/']);
      }
    },
      error => {
        this.disabled = false;
        //alert("error")
        //console.log('check login session1', error);
      });
    //alert("closed")
  }

  removeRememberMe() {
    localStorage.removeItem('login_username');
    localStorage.removeItem('login_password');
    localStorage.removeItem('login_rememberme');
  }

  addRememberMe(username, password, remember) {
    localStorage.setItem('login_username', username);
    localStorage.setItem('login_password', password);
    localStorage.setItem('login_rememberme', remember);
  }
}
