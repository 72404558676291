<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>

  <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">

    <!-- Site Content -->
    <div class="dt-content">

      <!-- Page Header -->
      <div class="dt-page__header">
        <div class="row">
          <div class="col-xl-6 ">
            <h3 class="dt-page__title m-auto">{{staticMsgs.imgLib.imgDocLib}} {{notFound ? '':': '+ HeadingText}}</h3>
          </div>
          <div class="col-xl-6 ">
            <span class="float-right">

              <button type="button" class="btn mr-5" [ngClass]="btnstatus1 ? 'icon-active' : 'btn-color-primary'"
                placement="bottom" ngbTooltip="Card View" (click)="cardView()">
                <i class="fa fa-th" aria-hidden="true"></i></button>
                <button type="button" class="btn mr-3" [ngClass]="btnstatus ? 'icon-active' : 'btn-color-primary'"
                placement="bottom" ngbTooltip="List View" (click)="tableView()">
                <i class="fa fa-bars" aria-hidden="true"></i></button>
              <button type="button" class="btn btn-outline-primary adv-search-btn mr-3" routerLink="/imagedetails"><i
                class="icon icon-plus"></i> {{staticMsgs.imgLib.createNew}}</button>
              </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-12 col-sm-12">
          <p>
            <a href="javascript:void(0)" [ngClass]="myimage ? 'data_active' : 'text-muted'"
              (click)="imageView('Created')">
              {{staticMsgs.imgLib.myImg}}
            </a>
            <span>|</span>
            <a href="javascript:void(0)" [ngClass]="sharedimage ? 'data_active' : 'text-muted'"
              (click)="imageView('Shared')">
              {{staticMsgs.imgLib.shareWithMe}}
            </a>
          </p>
        </div>
      </div>
      <!-- /page header -->
      <ng-container *ngIf="this.imageViewMode=='Shared'">
        <div class="text-center" *ngIf="sharedNotFound">
          <h2>{{staticMsgs.imgLib.noShared}}</h2>
          <img [src]="notfoundImg" class="img-fluid "><br>
          <!-- <button type="button" class="btn btn-primary adv-search-btn mr-3" routerLink="/imagedetails"><i
              class="icon icon-plus"></i> Create New</button> -->
        </div>
        <div class="grid-content" *ngIf="gridContent  && !sharedNotFound">

          <div class="row">
            <div class="col-sm-10" >
              <div *ngIf="!checkSharedStatusData">
              <i class="fa fa-search search-button"></i>
              <input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords for filter results"
                [(ngModel)]="searchedKeyword" />
              <!---<input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords" [(ngModel)]="searchedKeyword" />-->
            </div>
            </div>

            <div class="col-sm-2">
              <select title="Filter By" class="form-control selectpicker w-100" [(ngModel)]="selected"
                (ngModelChange)="onSelect(selected, 'shared')">
                <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
              </select>
            </div>

          </div>
          <div class="text-center" *ngIf="checkSharedStatusData">
            <h2>{{sharedMsg}}</h2>
            <img [src]="notfoundImg" class="img-fluid ">
          </div>
          <!-- Grid -->
          <div class="row mt-5">

            <!-- Grid Item -->
            <div class="col-xl-12 order-xl-1">

              <!-- Card -->
              <div class="row">

                <!-- Grid Item -->
                <div class="col-xl-3 col-md-4 col-sm-6 col-12"
                  *ngFor="let card of sharedCards| filter:searchedKeyword">

                  <!-- Card -->
                  <div class="card border-l-r">

                    <!-- Card Body -->
                    <div class="">

                      <!--<img class="w-100 border-l-r h-260" [src]='card.thumbnail != null?card.thumbnail:thumbnail'
                        alt="Img">document-->


                      <img *ngIf="card.baseClassification === 'Images'" class="h-260" alt=""
                        [src]='(card.thumbnailFiles!= null && card.thumbnailFiles?.thumbnail !=null) ?card.thumbnailFiles?.thumbnail:thumbnail'>
                      <!--<img *ngIf="!card.thumbnailFiles" class="h-260" alt="" src={{thumbnail}}>
              <img *ngIf="card.baseClassification === 'Documents' && card.thumbnailFiles" class="h-260" alt="" src="../../../../assets/images/doccard/document.png">-->
                      <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Concept'"
                        class="h-260" alt="" src="../../../../assets/images/documentcard/concept.png">
                      <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Lab Reports'"
                        class="h-260" alt="" src="../../../../assets/images/documentcard/labport.png">
                      <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'General'"
                        class="h-260" alt="" src="../../../../assets/images/documentcard/general.png">
                      <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Compliance'"
                        class="h-260" alt="" src="../../../../assets/images/documentcard/compliance.png">
                      <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Teckpack'"
                        class="h-260" alt="" src="../../../../assets/images/documentcard/techpack.png">
                      <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Manuals'"
                        class="h-260" alt="" src="../../../../assets/images/documentcard/manual.png">
                      <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Audit Report'"
                        class="h-260" alt="" src="../../../../assets/images/documentcard/auditport.png">
                      <!--  <img *ngIf="dataItem.thumbnail" class="img" [src]="dataItem.thumbnail" alt="">-->
                    </div>

                    <div class="card-body color-card">

                      <div class="text-eli">
                        <p class="mb-1 mb-sm-0 f-13 text-truncate name">
                          <a href="javascript:void(0)" (click)="onEditImage(card.id)">
                            {{card.name}}
                          </a>
                        </p>
                      </div>
                      <div class="row">
                        <div class="col-sm-8 col-xl-8">
                          <p class="mt-1 text-truncate sequence">
                            <a href="javascript:void(0)" (click)="onEditImage(card.id)">
                              {{card.sequence}}
                            </a>
                          </p>
                        </div>
                        <div class="col-sm-3 col-xl-3 ml-2">
                          <div class="float-right d-web">
                            <!--<i class="icon icon-dot d-green"></i>
                            <span class="text-light-gray mb-3 f-12 d-active">{{card.status}}</span>-->
                            <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='Active'"><i
                                class="icon icon-dot d-green"></i>{{card.status}}</span>
                            <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='Inactive'"><i
                                class="icon icon-dot d-red"></i>{{card.status}}</span>


                            <!-- Dropdown -->
                            <div class="dropdown">
                              <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)" (click)="onEditImageDetails(card.id)">
                                  <i class="icon icon-editors icon-fw"></i>
                                  {{staticMsgs.imgLib.edit}}
                                </a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteImage(card.id)">
                                  <i class="icon icon-trash icon-fw"></i>
                                  {{staticMsgs.imgLib.Delete}}
                                </a>
                                </div>
                            </div>
                            <!-- /dropdown -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="action-tools">
                      <!-- Checkbox -->
                      <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only">
                        <input id="checkbox{{card.id}}" type="checkbox" [(ngModel)]="card.isFavourite"
                          (click)="AddToFavorite($event,card)" [ngbTooltip]=" addtoFavourite ? 'Remove From Favourite' : 'Add to favourite'">
                        <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                          <span class="unchecked"><i class="icon icon-star-o text-white icon-lg"></i></span>
                          <span class="checked"><i class="icon icon-star-fill text-secondary icon-lg"></i></span>
                        </label>
                      </div>
                      <!-- /checkbox -->

                    </div>


                    <!-- /card body -->

                  </div>
                  <!-- /card -->

                </div>
                <!-- /grid item -->

              </div>
              <!-- /card -->

            </div>
            <!-- /grid item -->

          </div>
          <!-- /grid -->

          <div *ngIf="!isSharedGetAllRecords" class="row mt-5">
            <div *ngIf="!isSharedScrollDown" class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
              <div class="text-center w-100">
                <div class="loadersmall">
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

      <ng-container *ngIf="this.imageViewMode=='Created'">
      <div class="text-center" *ngIf="notFound">
        <h2>{{staticMsgs.imgLib.noData}}</h2>
        <img [src]="notfoundImg" class="img-fluid "><br>
        <!-- <button type="button" class="btn btn-primary adv-search-btn mr-3" routerLink="/imagedetails"><i
            class="icon icon-plus"></i> Create New</button> -->

      </div>
      <div class="grid-content" *ngIf="gridContent  && !notFound">

        <div class="row">
          <div class="col-sm-9" >
            <div *ngIf="!checkStatusData">
            <i class="fa fa-search search-button"></i>
            <input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords for filter results"
              [(ngModel)]="searchedKeyword" />
            <!---<input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords" [(ngModel)]="searchedKeyword" />-->
          </div>
          </div>

          <div class="col-sm-3">
            <select title="Filter By" class="form-control selectpicker w-100" [(ngModel)]="selected"
              (ngModelChange)="onSelect(selected, 'myimage')">
              <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
            </select>
          </div>

        </div>
        <div class="text-center" *ngIf="checkStatusData">
          <h2>{{msg}}</h2>
          <img [src]="notfoundImg" class="img-fluid ">
        </div>
        <!-- Grid -->
        <div class="row mt-5">

          <!-- Grid Item -->
          <div class="col-xl-12 order-xl-1">

            <!-- Card -->
            <div class="row">

              <!-- Grid Item -->
              <div class="col-xl-3 col-md-4 col-sm-6 col-12"
                *ngFor="let card of (cards | async)| filter:searchedKeyword">

                <!-- Card -->
                <div class="card border-l-r">

                  <!-- Card Body -->
                  <div class="">

                    <!--<img class="h-260" [src]='card.thumbnail != null?card.thumbnail:thumbnail'
                      alt="Img">document-->


                    <img *ngIf="card.baseClassification === 'Images'" class="h-260" alt=""
                      [src]='(card.thumbnailFiles!= null && card.thumbnailFiles?.thumbnail !=null) ?card.thumbnailFiles?.thumbnail:thumbnail'>
                    <!--<img *ngIf="!card.thumbnailFiles" class="h-260" alt="" src={{thumbnail}}>
					  <img *ngIf="card.baseClassification === 'Documents' && card.thumbnailFiles" class="h-260" alt="" src="../../../../assets/images/doccard/document.png">-->
                    <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Concept'"
                      class="h-260" alt="" src="../../../../assets/images/documentcard/concept.png">
                    <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Lab Reports'"
                      class="h-260" alt="" src="../../../../assets/images/documentcard/labport.png">
                    <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'General'"
                      class="h-260" alt="" src="../../../../assets/images/documentcard/general.png">
                    <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Compliance'"
                      class="h-260" alt="" src="../../../../assets/images/documentcard/compliance.png">
                    <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Teckpack'"
                      class="h-260" alt="" src="../../../../assets/images/documentcard/techpack.png">
                    <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Manuals'"
                      class="h-260" alt="" src="../../../../assets/images/documentcard/manual.png">
                    <img *ngIf="card.baseClassification === 'Documents' && card.classification == 'Audit Report'"
                      class="h-260" alt="" src="../../../../assets/images/documentcard/auditport.png">
                    <!--  <img *ngIf="dataItem.thumbnail" class="img" [src]="dataItem.thumbnail" alt="">-->
                  </div>

                  <div class="card-body color-card">

                    <div class="text-eli">
                      <p class="mb-1 mb-sm-0 f-13 text-truncate name">
                        <a href="javascript:void(0)" (click)="onEditImage(card.id)">
                          {{card.name}}
                        </a>
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-sm-8 col-xl-8">
                        <p class="mt-1 text-truncate sequence">
                          <a href="javascript:void(0)" (click)="onEditImage(card.id)">
                            {{card.sequence}}
                          </a>
                        </p>
                      </div>
                      <div class="col-sm-3 col-xl-3 ml-2">
                        <div class="float-right d-web">
                          <!--<i class="icon icon-dot d-green"></i>
                          <span class="text-light-gray mb-3 f-12 d-active">{{card.status}}</span>-->
                          <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='Active'"><i
                              class="icon icon-dot d-green"></i>{{card.status}}</span>
                          <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='Inactive'"><i
                              class="icon icon-dot d-red"></i>{{card.status}}</span>


                          <!-- Dropdown -->
                          <div class="dropdown">
                            <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false">
                              <i class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="javascript:void(0)" (click)="onEditImageDetails(card.id)">
                                <i class="icon icon-editors icon-fw"></i>
                                {{staticMsgs.imgLib.edit}}
                              </a>
                              <a class="dropdown-item" href="javascript:void(0)" (click)="onDeleteImage(card.id)">
                                <i class="icon icon-trash icon-fw"></i>
                                {{staticMsgs.imgLib.Delete}}
                              </a>
                              <!--<a class="dropdown-item" href="javascript:void(0)" (click)="onRemoveCard(card.id)">
                                <i class="icon icon-remove icon-fw"></i>
                                Delete
                              </a>-->
                              <!---<a class="dropdown-item" href="javascript:void(0)">
                                <i class="icon icon-contacts icon-fw"></i>
                                Contact List
                              </a>
                              <a class="dropdown-item" href="javascript:void(0)">
                                <i class="icon icon-heart icon-fw"></i>
                                Favourite List
                              </a>--->
                            </div>
                          </div>
                          <!-- /dropdown -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="action-tools">
                    <!-- Checkbox -->
                    <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only">
                      <input id="checkbox{{card.id}}" type="checkbox" [(ngModel)]="card.isFavourite"
                        (click)="AddToFavorite($event,card)" [ngbTooltip]="card.isFavourite ? 'Remove From Favourite' : 'Add to favourite'">
                      <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                        <span class="unchecked"><i class="icon icon-star-o text-white icon-lg"></i></span>
                        <span class="checked"><i class="icon icon-star-fill text-secondary icon-lg"></i></span>
                      </label>
                    </div>
                    <!-- /checkbox -->

                  </div>


                  <!-- /card body -->

                </div>
                <!-- /card -->

              </div>
              <!-- /grid item -->

            </div>
            <!-- /card -->

          </div>
          <!-- /grid item -->

        </div>
        <!-- /grid -->

        <div *ngIf="!isGetAllRecords" class="row mt-5">
          <div *ngIf="!isScrollDown" class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
            <div class="text-center w-100">
              <div class="loadersmall">
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-container>
      <div class="grid-content custom-table" *ngIf="tableContent  && !notFound">

        <!-- Grid Item -->
        <div class="">

          <!-- Card -->
          <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td th"
            (mouseover)="showTooltip($event)">
            <kendo-grid #grid [data]="gridData" [pageable]="true" [sortable]="true" [groupable]="false"
              [reorderable]="true" [resizable]="true" [pageSize]="10" [height]="605" [(loading)]="loading"
              (pageChange)="onPageChange($event)"  [selectable]="{enabled: true, checkboxOnly: true}" [skip]="state.skip" [sort]="state.sort"
               filterable="menu" [groupable]="true" [filter] = "filter" (filterChange)="filterChange($event)"
              (dataStateChange)="dataStateChange($event)" (dblclick)="dblClick($event)">

              <ng-template kendoGridToolbarTemplate>
                <input placeholder="Search in all columns..." kendoTextBox (input)="searchListData($event.target.value)"
                  class="kendoSearch" />
                  <div class="float-right">
                    <!-- <button kendoGridExcelCommand type="button" class="mr-3" icon="file-excel">
                      Export to Excel
                    </button>
                  <button kendoGridPDFCommand class="mr-3" icon="file-pdf">Export to PDF</button> -->
                  <div
                                class="kendo-button-icon mr-3"
                                kendoGridExcelCommand
                              >
                                <i
                                  class="fa fa-file-excel-o"
                                  aria-hidden="true"
                                ></i>
                              </div>



<div
                                kendoGridPDFCommand
                                class="kendo-button-icon mr-3"
                              >
                                <i
                                  class="fa fa-file-pdf-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                  </div>
              </ng-template>
              <kendo-grid-checkbox-column [width]="60" [showSelectAll]="true"
              ></kendo-grid-checkbox-column>
              <kendo-grid-column title="Thumbnail" [filterable]="false" width="100" class="text-center">
                  <ng-template kendoGridCellTemplate let-dataItem>
                  <!-- <img *ngIf="!dataItem.thumbnail" class="img" [src]="thumbnail" alt="">
                  <img *ngIf="dataItem.thumbnail" class="img" [src]="dataItem.thumbnail" alt="">
                  <img *ngIf="dataItem.seamDrawing==null"  class="img" [src]="thumbnail" alt="">
                  <img *ngIf="dataItem.seamDrawing" class="img" src={{dataItem.seamDrawing.thumbnail}} alt=""> -->
                  <img *ngIf="dataItem.baseClassification === 'Images' && dataItem.thumbnailFiles" class="img" alt=""
                    src={{dataItem.thumbnailFiles.thumbnail}}>
                  <img *ngIf="!dataItem.thumbnailFiles" class="img" alt="" src={{thumbnail}}>
                  <img *ngIf="dataItem.baseClassification === 'Documents' && dataItem.thumbnailFiles" class="img" alt=""
                    src="../../../../assets/images/doccard/document.png">
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [filterable]="false" [sortable]="false" field="sequence" title="Document No" [editable]="false"></kendo-grid-column>

              <kendo-grid-column field="name" title="Name">
              </kendo-grid-column>

              <kendo-grid-column field="classification" title="Classification" [editable]="false">
                <ng-template kendoGridFilterMenuTemplate let-column="column"
                let-filter="filter" let-filterService="filterService" let-dataItem="dataItem" let-rowIndex="rowIndex">
                <app-dropdownlist-filter [data]="classification" [textField]="'label'" [valueField]="'value'"
                [isPrimitive]="true" [field]="column.field" [currentFilter]="filter"
                 [filterService]="filterService">
                </app-dropdownlist-filter>
                </ng-template>
              </kendo-grid-column>


              <kendo-grid-column field="status" title="Status" width="150">
                <ng-template kendoGridFilterMenuTemplate let-column="column"
                let-filter="filter" let-filterService="filterService" let-dataItem="dataItem" let-rowIndex="rowIndex">
                <app-dropdownlist-filter [data]="options" [textField]="'label'" [valueField]="'value'"
                [isPrimitive]="true" [field]="column.field" [currentFilter]="filter"
                 [filterService]="filterService">

                </app-dropdownlist-filter>

                </ng-template>
                <!-- <ng-template kendoGridCellTemplate let-dataItem>

                  <span *ngIf="dataItem.status=='Active'"><i
                      class="icon icon-dot d-green"></i>{{dataItem.status}}</span>
                  <span *ngIf="dataItem.status=='Inactive'"><i
                      class="icon icon-dot d-red"></i>{{dataItem.status}}</span>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <kendo-dropdownlist [data]="listItems" [textField]="'text'" [valueField]="'value'"
                    [valuePrimitive]="true" [formControl]="fac(dataItem).controls.status">
                  </kendo-dropdownlist>
                </ng-template> -->
              </kendo-grid-column>


              <!-- <kendo-grid-column field="createdBy.firstName" title="Created By" [editable]="false"> -->
              <!--<ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.createdBy?.firstName}}
                </ng-template>-->
              <kendo-grid-column [filterable]="false" [sortable]="false" field="CreatedbyName" title="CreatedbyName">
              </kendo-grid-column>

              <kendo-grid-column title="Action" width="140">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <button (click)="editClick(rowIndex,dataItem)" title="Edit" class="mr-2 k-button k-primary k-grid-edit-command">
                    Edit
                    <!-- <i class="icon icon-editors f-17"></i> -->
                  </button>
                  <button *ngIf="dataItem.isDelete" (click)="onRemove(rowIndex,dataItem)" class="k-button k-grid-remove-command"
                    title="Delete">Delete
                    <!-- <i class="icon icon-trash f-16"></i> -->
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-excel fileName="ImageLibrary.xlsx" [fetchData]="allData"></kendo-grid-excel>
              <kendo-grid-pdf fileName="ImageLibrary.pdf" [allPages]="true">
                <kendo-grid-pdf-margin top="0.5cm" left="0.5cm" right="0.5cm" bottom="0.5cm"></kendo-grid-pdf-margin>
              </kendo-grid-pdf>

              <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-info></kendo-pager-info>
              </ng-template>
              <ng-template #ImageCustomComponent>
                <mat-dialog-content >
                  <app-image-custom></app-image-custom>
                </mat-dialog-content>
                </ng-template>
            </kendo-grid>
          </div>
          <!-- /card -->

        </div>
        <!-- /grid item -->

      </div>

      <span *ngIf="this.showNote" class="red">{{staticMsgs.imgLib.nameFieldAllow}}</span>
    </div>

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->
  </div>
  <!-- /Site Content Wrapper-->

</main>
<!-- /site content -->
<!-- Customizer Sidebar -->
<!-- Customizer Sidebar -->
<aside class="dt-customizer dt-drawer position-right" [class.open]="classApplied">
  <!-- Item Detail Section -->
  <div class="dt-customizer__inner">

    <!-- Customizer Header -->
    <div class="dt-customizer__header">

      <!-- Customizer Title -->
      <div class="dt-customizer__title">
        <h4 class="mb-0">{{staticMsgs.imgLib.advSearch}}</h4>
      </div>
      <!-- /customizer title -->
      <!-- Close Button -->
      <button type="button" class="close" (click)="advanceSearchClose()">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- /close button -->

    </div>
    <!-- /customizer header -->
    <!-- Customizer Body -->
    <div class="dt-customizer__body ps-custom-scrollbar">
      <!-- Customizer Body Inner  -->
      <div class="dt-customizer__body-inner">
        <form [formGroup]="advImageForm" (ngSubmit)="onSubmit(advImageForm.value)">
          <!-- Section -->
          <section>
            <div class="col-sm-12">

              <!--Accordion wrapper-->
              <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                <div class="col-sm-12 mb-4 text-center">
                  <button type="button" class="btn btn-primary adv-clear-btn" *ngIf="isResetMode"
                  (click)="clearSearch()">{{staticMsgs.imgLib.clearSearch}}</button>
                </div>


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne1" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                    <!--<a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true"
                       aria-controls="collapseOne1">
                      <h5 class="mb-0">
                        Keywords <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>

                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0"> {{staticMsgs.imgLib.keywords}}</h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableKeywordVal">
                        <button type="button" class="close image-close ml-5" (click)="this.clearfield('keyword');"><span
                            aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseOne1" class="collapse show" role="tabpanel" aria-labelledby="headingOne1"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto w-100 color-card">
                      <input type="text" class="form-control" value="" formControlName="keyword" #keywordData
                        placeholder="Enter Keywords" name="SearchD" #SearchD
                        (input)="checkFieldVal(SearchD.value, 'keyword')" />
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingThree3" data-toggle="collapse"
                    data-parent="#accordionEx" href="#collapseThree3" aria-expanded="true"
                    aria-controls="collapseThree3">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree3"
                       aria-expanded="false" aria-controls="collapseThree3">
                      <h5 class="mb-0 ">
                        Select Classification <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">{{staticMsgs.imgLib.classification}}</h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="imageclassificationValue">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('classification');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseThree3" class="collapse" role="tabpanel" aria-labelledby="headingThree3"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <!-- <select id = "classificationlist" title="Select Classification" multiple class="form-control selectpicker w-100" formControlName="classifications">
                        <option *ngFor="let classification of classification" [value]="classification.value">{{ classification.value }}</option>
                      </select>-->
                      <input type="text" class="form-control" id="imageclassification" formControlName="classifications"
                        placeholder="Classification" #SearchClass
                        (input)="checkFieldVal(SearchClass.value, 'classification')" />
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingTwo2" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapseTwo2" aria-expanded="true" aria-controls="collapseTwo2">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
                       aria-expanded="false" aria-controls="collapseTwo2">
                      <h5 class="mb-0">
                        Created By <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0"> {{staticMsgs.imgLib.createdby}}</h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableCreatedByVal">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('createdBy');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseTwo2" class="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <!--<select title="Select Created By" multiple class="form-control selectpicker w-100">
                       <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
                      </select>-->
                      <input type="text" class="form-control" value="" formControlName="createdBy"
                        placeholder="Created By" name="SearchD1" #SearchD1
                        (input)="checkFieldVal(SearchD1.value, 'createdBy')" />
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingThree3" data-toggle="collapse"
                    data-parent="#accordionEx" href="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                    <!-- <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapsefour"
                       aria-expanded="false" aria-controls="collapseThree3">
                      <h5 class="mb-0">
                        Status <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">{{staticMsgs.imgLib.status}}</h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableStatusVal">
                        <button type="button" class="close image-close ml-5" (click)="this.clearfield('status');"><span
                            aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapsefour" class="collapse" role="tabpanel" aria-labelledby="headingThree3"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <select id="statuslist" title="Select Status" multiple formControlName="status"
                        class="form-control selectpicker w-100" [(ngModel)]="enableStatusVal"
                        (ngModelChange)="checkFieldVal(enableStatusVal, 'status')">
                        <option *ngFor="let status of options" [value]="status.value">{{ status.value }}</option>
                      </select>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingFive" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                    <!-- <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapsefive"
                       aria-expanded="false" aria-controls="collapseThree3">
                      <h5 class="mb-0">
                        Created On <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">{{staticMsgs.imgLib.createdon}}</h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableCreatToVal||enableCreatFromVal">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('createdFromChild');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapsefive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <div class="form-group">
                        <label>{{staticMsgs.imgLib.from}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-1" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-1"
                            formControlName="createdFrom" #createdFrom (change)="setDate($event)" />
                          <div class="input-group-append" data-target="#date-time-picker-1" data-toggle="datetimepicker"
                            (click)="checkFieldVal(['val'],'createdFromChild')">
                            <div class="input-group-text">
                              <i class="icon icon-calendar"></i>
                            </div>
                          </div>
                        </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="createdFromDate" ngbDatepicker readonly
                            (click)="d1.toggle()" #d1="ngbDatepicker" formControlName="createdFrom"
                            (dateSelect)="dateChange($event,'createdFromDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                        <br>
                        <label>{{staticMsgs.imgLib.to}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-2" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-2"
                            formControlName="createdTo" #createdTo />
                          <div class="input-group-append" data-target="#date-time-picker-2" data-toggle="datetimepicker"
                            (click)="checkFieldVal(['val'],'createdToChild')">
                            <div class="input-group-text">
                              <i class="icon icon-calendar"></i>
                            </div>
                          </div>
                        </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="createdToDate" ngbDatepicker readonly
                            (click)="d2.toggle()" #d2="ngbDatepicker" formControlName="createdTo"
                            (dateSelect)="dateChange($event,'createdToDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->
                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingSix" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapsesix" aria-expanded="true" aria-controls="collapsesix">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapsesix"
                       aria-expanded="false" aria-controls="collapseThree3">
                      <h5 class="mb-0">
                        Modified On <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.imgLib.modifiedon}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableModifToVal||enableModifFromVal">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('modifiedFromChild');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>


                  <!-- Card body -->
                  <div id="collapsesix" class="collapse" role="tabpanel" aria-labelledby="headingSix"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <div class="form-group">
                        <label>{{staticMsgs.imgLib.from}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-3" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-3"
                            #modifiedFrom formControlName="modifiedFrom" (change)="setDate($event)" />
                          <div class="input-group-append" data-target="#date-time-picker-3" data-toggle="datetimepicker"
                            (click)="checkFieldVal(['val'],'modifiedFromChild')">
                            <div class="input-group-text">
                              <i class="icon icon-calendar"></i>
                            </div>
                          </div>
                        </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="modifiedFromDate" ngbDatepicker readonly
                            (click)="d3.toggle()" #d3="ngbDatepicker" formControlName="modifiedFrom"
                            (dateSelect)="dateChange($event,'modifiedFromDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                        <br>
                        <label>{{staticMsgs.imgLib.to}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-4" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-4"
                            formControlName="modifiedTo" #modifiedTo />
                          <div class="input-group-append" data-target="#date-time-picker-4" data-toggle="datetimepicker"
                            (click)="checkFieldVal(['val'],'modifiedToChild')">
                            <div class="input-group-text">
                              <i class="icon icon-calendar"></i>
                            </div>
                          </div>

                        </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="modifiedToDate" ngbDatepicker readonly
                            (click)="d4.toggle()" #d4="ngbDatepicker" formControlName="modifiedTo"
                            (dateSelect)="dateChange($event,'modifiedToDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->



              </div>
              <!-- Accordion wrapper -->

            </div>

            <!--<div class="add-comment-box">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary adv-search-btn w-100 ">
                  Apply Filters
                </button>
              </div>
            </div>-->
            <div class="add-comment-box">
              <div class="d-flex">
                <div class="col-sm-6">
                  <button type="submit" class="btn btn-primary adv-search-btn w-100">
                    {{staticMsgs.imgLib.apply}}</button>
                </div>
                <div class="col-sm-6">
                  <button type="button" (click)="clearSearch()" class="btn btn-primary adv-search-btn w-100 ">
                    {{staticMsgs.imgLib.clearAll}}</button>
                </div>
              </div>
            </div>
          </section>
          <!-- /section -->
        </form>



      </div>
      <!-- /customizer body inner -->
    </div>
    <!-- /customizer body -->

  </div>
  <!-- Item Details Section End-->

</aside>
<!-- /customizer sidebar -->
<!-- /customizer sidebar -->
<!-- Root -->
