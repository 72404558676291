<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>

  <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->

  <!-- Site Content -->
  <div class="dt-content-wrapper">

    <!-- Site Content -->
    <div class="dt-content">
      <div class="row">
        <!-- Page Header -->
        <div class="col-xl-4 col-sm-4 col-md-4">
          <div class="dt-page__header">
            <h3 class="dt-page__title" *ngIf="!isEditMode">{{staticMsgs.seamDetail.createSeam}}</h3>
            <h3 class="dt-page__title" *ngIf="isEditMode">{{staticMsgs.seamDetail.seamDetail}}</h3>
            <nav class="mb-0 breadcrumb">
              <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/dashboard"><i class="fa fa-home"
                  aria-hidden="true"></i> </a>
                  <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/seamslibrary">
                    <span class="active breadcrumb-item" *ngIf="isEditMode || !isEditMode">{{staticMsgs.seamsLibrary.SeamsLibrary}}</span>
                  </a>
              <span class="active breadcrumb-item" *ngIf="!isEditMode">{{staticMsgs.seamDetail.createSeam}}</span>
              <span class="active breadcrumb-item" *ngIf="isEditMode">{{staticMsgs.seamDetail.seamDetail}} / {{
                sequenceNumber }} </span>
            </nav>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-md-4 d-flex justify-content-center" *ngIf="isEditMode">

          <ul class="dt-nav">
            <button *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" (click)="editSeams()"
              ngbTooltip="Edit Seams">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button *ngIf="isEditingMode" type="button" [ngClass]="isEditingMode ? 'icon-active' : 'btn-color-primary'"
              class="btn mr-3 round" (click)="cancelSeamsEdit()" ngbTooltip="Cancel Edit Seams">
              <i class="fa fa-times text-white" aria-hidden="true"></i>
            </button>

            <button *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round"
              (click)="onDeleteSeams()" ngbTooltip="Delete">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
            <!-- <button type="button" class="btn mr-3 btn-color-primary round"
                      *ngIf="!isEditingMode" ngbTooltip="Discussion">
                      <i class="fa fa-comments" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal"
                      data-target="#small-modal" ngbTooltip="Generate Techpack">
                      <i class="fas fa-tshirt" aria-hidden="true"></i>
                    </button> -->
          </ul>
        </div>
      </div>
      <!-- /page header -->


      <div class="profile-content">

        <!-- Grid -->
        <div class="row">

          <!-- Grid Item -->
          <div class="wd-300" *ngIf="isViewingMode">
            <ng-image-slider [images]="imageObject"  class="sidebar_sliders sidebar_image" *ngIf="imageObject.length>0" [infinite]="false" [autoSlide]="1"
              slideImage="1" [imageSize]="{width: '100%', height: 200}">
            </ng-image-slider>
            <div class="accordion mt-5" id="accordion-example">
              <div class="card" *ngIf="editedSeamsData">
                <div class="" id="headingOne">
                  <h5 class="mb-0">
                    <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                      data-target="#collapse-one" aria-expanded="false" aria-controls="collapse-one">
                      {{staticMsgs.seamDetail.seamInfo}}
                      <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                    </h4>
                  </h5>
                </div>

                <div id="collapse-one" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-example">
                  <div class="card-body bg-white p-4" *ngIf="editedSeamsData">

                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.seamDetail.name1}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedSeamsData.name}} </p>
                      </div>
                    </div>

                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.seamDetail.seam751A}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedSeamsData.seam751aNumber}} </p>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.seamDetail.iso4916}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedSeamsData.isO4916Number}} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="editedSeamsData">
                <div class="" id="headingTwo">
                  <h5 class="mb-0">
                    <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                      data-target="#collapse-two" aria-expanded="false" aria-controls="collapse-one">
                      {{staticMsgs.seamDetail.userInfo}}
                      <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                    </h4>
                  </h5>
                </div>
                <div id="collapse-two" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion-example">
                  <div class="card-body bg-white p-4" *ngIf="editedSeamsData.createdBy">
                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.seamDetail.createdby}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedSeamsData.createdBy.firstName}}</p>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.seamDetail.createdon}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          {{editedSeamsData.createdOn |date: "dd MMM yyyy"}}
                        </p>
                        <p class="a-font">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                          {{editedSeamsData.createdOn |date:"hh:mm a"}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /grid item -->

          <!-- Grid Item -->
          <div [ngClass]="isViewingMode ? 'wdcacl-300' : 'w-100'" *ngIf="isViewingMode || isEditingMode">
            <div class="order-xl-1">

              <!-- Card -->
              <div class="card-shadow-nonw mr-3">

                <!-- Card Header -->
                <div class="card-header card-nav border-bottom d-sm-flex justify-content-sm-between"
                  *ngIf="!isCreateMode">
                  <!-- <h3 class="mb-2 mb-sm-n5">About</h3> -->

                  <ul class="card-header-links nav nav-underline" role="tablist">
                    <!-- <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tab-pane1"  role="tab" aria-controls="tab-pane1" aria-selected="true">Item Details</a>
                    </li> -->
                    <li class="nav-item">
                      <a *ngIf="activeTab !='seamsDetailsTab'" class="nav-link active" data-toggle="tab"
                        href="#tab-pane1" [ngClass]="{ 'active':activeTab === 'seamsDetailsTab' }"
                        (click)="seamsDetailsTab('seamsDetailsTab')" role="tab" aria-controls="tab-pane1"
                        aria-selected="true">{{staticMsgs.seamDetail.seamDetail}}</a>
                      <a *ngIf="activeTab =='seamsDetailsTab'" class="nav-link active" data-toggle="tab"
                        href="#tab-pane1" [ngClass]="{ 'active':activeTab === 'seamsDetailsTab' }" role="tab"
                        aria-controls="tab-pane1" aria-selected="true">{{staticMsgs.seamDetail.seamDetail}}</a>
                    </li>
                    <li class="nav-item" *ngIf="isEditMode">
                      <a *ngIf="activeTab !='docDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane2"
                        [ngClass]="{ 'active':activeTab === 'docDetailsTab' }" (click)="docDetailsTab('docDetailsTab')"
                        role="tab" aria-controls="tab-pane2" aria-selected="true">{{staticMsgs.seamDetail.document}}</a>
                      <a *ngIf="activeTab =='docDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane2"
                        [ngClass]="{ 'active':activeTab === 'docDetailsTab' }" role="tab" aria-controls="tab-pane2"
                        aria-selected="true">{{staticMsgs.seamDetail.document}}</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-pane2" role="tab" aria-controls="tab-pane2" aria-selected="true">Document</a>
                    </li> -->
                    <!-- <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-pane3" [ngClass]="{ 'active':activeTab === 'docDetailsTab' }" (click)="docDetailsTab('docDetailsTab')" role="tab" aria-controls="tab-pane3" aria-selected="true">Documents</a>
                    </li> -->
                  </ul>


                  <div class="dropdown" *ngIf="!isViewingMode">
                    <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                    </a>
                  </div>

                  <div class="dropdown" *ngIf="isViewingMode">

                    <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                    </a> -->

                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="javascript:void(0)" (click)="editSeams()">
                        <i class="fa fa-pencil-square-o"></i>
                        {{staticMsgs.seamDetail.edit}}
                      </a>
                      <!-- <a class="dropdown-item" href="javascript:void(0)">
                            <i class="icon icon-trash icon-fw"></i>
                            Delete
                        </a>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="icon icon-addnew icon-fw"></i>
                            Create New
                        </a>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="fa fa-files-o mr-1" aria-hidden="true"></i>
                            Copy
                        </a>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="fa fa-floppy-o mr-1" aria-hidden="true"></i>
                            Save New Version
                        </a>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="fa fa-thumb-tack mr-1" aria-hidden="true"></i>
                            Mark As Basline Version
                        </a> -->
                    </div>
                  </div>

                </div>
                <!-- /card header -->

                <!-- Card Body -->
                <div class="mt-3 pb-2">

                  <!-- Tab Content-->
                  <div class="tab-content">

                    <!-- Tab panel -->
                    <div id="tab-pane1" class="tab-pane card-body card-body-shadow bg-white active"
                      [ngClass]="{ 'active':activeTab==='seamsDetailsTab'}">

                      <div *ngIf="isEditingMode">
                        <form [formGroup]="seamsForm" (ngSubmit)="onSubmit()">
                          <div class="form-row">

                            <div class="col-lg-4 col-sm-4 mb-3" *ngIf="isIDVisible">
                              <label for="id">{{staticMsgs.seamDetail.id}}</label>
                              <input type="text" class="form-control" formControlName="sequence" id="sequence"
                                placeholder="Enter ID" value="" required="">
                            </div>
                            <div class="col-lg-4 col-sm-4 mb-3">
                              <label for="name">Name<span class="red_icon">*</span></label>
                              <input type="text" class="form-control" formControlName="name" id="name" minlength="3"
                               maxlength="50" placeholder="Enter Name" value="" required="">
                              <div
                                *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
                                <div *ngIf="this.f.name.errors.required" class="red">
                                  Name is required.
                                </div>
                                <div *ngIf="this.f.name.errors.minlength" class="red">
                                 Minimum 3 characters required.
                                </div>
                              </div>

                            </div>
                            <div class="col-lg-4 col-sm-4 mb-3">
                              <label for="seam751aNumber">{{staticMsgs.seamDetail.seam751Anum}}<span
                                  class="red_icon">*</span></label>
                              <input type="text" class="form-control" formControlName="seam751aNumber"
                                id="seam751aNumber" placeholder="Enter 751A Number" value="" required=""
                                >

                              <div
                                *ngIf="(submitted && this.f.seam751aNumber.errors) || ( this.f.seam751aNumber.invalid && (this.f.seam751aNumber.dirty || this.f.seam751aNumber.touched))">
                                <div *ngIf="this.f.seam751aNumber.errors.required" class="red">
                                  {{staticMsgs.seamDetail.seam751AReq}}
                                </div>
                                <div *ngIf="this.f.seam751aNumber.errors?.pattern" class="red">
                                  {{staticMsgs.seamDetail.seam751AInvalid}}
                                </div>
                              </div>

                            </div>
                            <div class="col-lg-4 col-sm-4 mt-3 mb-3">
                              <label for="isO4916Number">{{staticMsgs.seamDetail.iso4916num}}<span
                                  class="red_icon">*</span></label>
                              <input type="text" class="form-control" formControlName="isO4916Number" id="isO4916Number"
                                placeholder="Enter ISO 4916 Number" value="" required=""
                                >
                              <div
                                *ngIf="(submitted && this.f.isO4916Number.errors) || ( this.f.isO4916Number.invalid && (this.f.isO4916Number.dirty || this.f.isO4916Number.touched))">
                                <div *ngIf="this.f.isO4916Number.errors.required" class="red">
                                  {{staticMsgs.seamDetail.iso4916Req}}
                                </div>

                                <div *ngIf="this.f.isO4916Number.errors?.pattern" class="red">
                                  {{staticMsgs.seamDetail.iso4916Invalid}}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-4  mt-3 mb-3">
                              <label for="commonApplication">{{staticMsgs.seamDetail.commonApp}}</label>
                              <textarea cols="2" name="description" formControlName="commonApplication"
                                id="commonApplication" placeholder="Enter Common Application"
                                class="form-control"></textarea>
                              <div
                                *ngIf="(submitted && this.f.commonApplication.errors) || ( this.f.commonApplication.invalid && (this.f.commonApplication.dirty || this.f.commonApplication.touched))">
                                <div *ngIf="this.f.commonApplication.errors.required" class="red">
                                  {{staticMsgs.seamDetail.commonAppReq}}
                                </div>
                                <div
                                  *ngIf="this.seamsForm.controls.commonApplication.value && this.seamsForm.controls.commonApplication.value.length > 500"
                                  class="red">
                                  {{staticMsgs.seamDetail.commonAppAllow}}
                                </div>
                              </div>

                            </div>
                            <div class="col-lg-4 col-sm-4 mt-3 mb-3">
                              <label for="requirements">{{staticMsgs.seamDetail.requirement}}</label>
                              <textarea cols="2" name="description" id="requirements" formControlName="requirements"
                                placeholder="Enter Requirements" class="form-control"></textarea>
                              <div
                                *ngIf="this.seamsForm.controls.requirements.errors && this.seamsForm.controls.requirements.value && this.seamsForm.controls.requirements.value.length > 500"
                                class="red">
                                {{staticMsgs.seamDetail.reqAllow}}
                              </div>
                            </div>

                            <div class="col-lg-4 col-sm-4 mt-3 mb-3">
                              <label for="status">{{staticMsgs.seamDetail.status}}<span
                                  class="red_icon">*</span></label>
                              <select title="Select Status" id="status" formControlName="status"
                                class="form-control selectpicker w-100">
                                <option *ngFor="let status of status" [value]="status.value">{{ status.label }}</option>
                              </select>
                              <div
                                *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
                                <div *ngIf="this.f.status.errors.required" class="red">
                                  {{staticMsgs.seamDetail.statusReq}}
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-lg-4 mb-3">
                                <label for="validationDefault02">Image #</label>
                                <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Image #" value="" required="">
                            </div>
                            <div class="col-lg-4 mb-3">
                              <label for="validationDefault02">Name</label>
                              <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Name" value="" required="">
                          </div> -->

                          </div>

                          <div>

                            <div class="dt-entry__header mt-5">
                              <!-- Entry Heading -->
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">{{staticMsgs.seamDetail.uploadSeam}}</h3>
                              </div>
                              <!-- /entry heading -->
                            </div>

                            <div class="form-row">
                              <div class="col-sm-12 mb-3">
                                <label for="validationDefault01">{{staticMsgs.seamDetail.comment}}</label>
                                <textarea cols="2" class="form-control" name="comments" formControlName="comments"
                                  placeholder="Enter Comments"></textarea>
                                <div
                                  *ngIf="this.seamsForm.controls.comments.errors && this.seamsForm.controls.comments.value && this.seamsForm.controls.comments.value.length > 500"
                                  class="red">
                                  {{staticMsgs.seamDetail.commentAllow}}
                                </div>
                              </div>
                              <div class="col-xl-6 mt-6" *ngIf="this.f.id.value">
                                <div class="col-xl-6 mb-5"
                                  *ngFor="let editedThumbnail of editedThumbnails; let j=index">
                                  <img src="{{editedThumbnail.thumbnail}}" class="img-fluid  img-fit "
                                    *ngIf="isValidImageURL(editedThumbnail.name)" (click)="openThumbnail(j)">
                                  <button type="button" class="close image-close"
                                    (click)="removeThumbnailSection(editedThumbnail)">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                              </div>
                              <div class="col-xl-12">
                                <label> {{staticMsgs.seamDetail.uploadFile}}<span class="red_icon">*</span></label>
                                <div class="custom-dropzone" name="upload" [multiple]="false" ngx-dropzone
                                  [accept]="'image/*'" (change)="onSelect($event,0)">
                                  <ngx-dropzone-label>
                                    <div>
                                      <h5 class="text-light-gray mt-3">
                                        {{staticMsgs.seamDetail.dropFile}}</h5>
                                    </div>
                                  </ngx-dropzone-label>
                                  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngFor="let f of thumbNails" [file]="f" [removable]="true"
                                    (removed)="onRemoveImg(f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
                                    </ngx-dropzone-label>
                                  </ngx-dropzone-image-preview>

                                </div>
                                <div *ngIf="(submitted && isFileError)">
                                  <div class="red">
                                    {{staticMsgs.seamDetail.notEmpty}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-row mt-10 float-right">

                            <div class="row mt-3">
                              <button type="button" *ngIf="!isEditMode" (click)="cancelSeamsCreate()"
                                class="btn btn-outline-primary adv-search-btn mr-1">
                                {{staticMsgs.seamDetail.cancel}}
                              </button>
                              <button type="submit" *ngIf="!isEditMode" [disabled]="seamsForm?.invalid || !isThumbnailAdded"
                                class="btn btn-primary adv-search-btn">{{staticMsgs.seamDetail.create}}</button>
                              <button type="submit" *ngIf="isEditMode"[disabled]="seamsForm?.invalid || !isThumbnailAdded"
                                class="btn btn-primary adv-search-btn">{{staticMsgs.seamDetail.update}}</button>
                            </div>
                          </div>

                          <!-- <div class="form-row mt-5 float-right">
                          <div class="col-sm-12 ">
                          <button type="button" class="btn btn-primary adv-search-btn">Save</button>
                          </div>
                        </div> -->

                        </form>
                      </div>
                      <div *ngIf="isViewingMode">
                        <!-- Entry Heading -->
                        <div class="dt-entry__header mt-5">
                          <!-- Entry Heading -->
                          <div class="dt-entry__heading">
                            <h3 class="dt-entry__title">{{staticMsgs.seamDetail.uploadSeam}}</h3>
                          </div>
                          <!-- /entry heading -->
                        </div>
                        <!-- /entry heading -->

                        <form>
                          <div class="form-row">

                            <div class="col-lg-12 col-sm-12">
                              <div class="form-row">
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view" for="this.f.sequence.value">
                                    {{staticMsgs.seamDetail.id}}</label>
                                  <p class="mt-2 viewtext">{{this.f.sequence.value}} </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view" for="this.f.sequence.value">Name</label>
                                  <p class="mt-2 viewtext">{{this.f.name.value}} </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view" for="this.f.seam751aNumber.value">
                                    {{staticMsgs.seamDetail.seam751Anum}}</label>
                                  <p class="mt-2 viewtext">{{this.f.seam751aNumber.value}}
                                  </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view" for="this.f.isO4916Number.value">
                                    {{staticMsgs.seamDetail.iso4916num}}</label>
                                  <p class="mt-2 viewtext">{{this.f.isO4916Number.value}}
                                  </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view" for="this.f.commonApplication.value">
                                    {{staticMsgs.seamDetail.commonApp}}</label>
                                  <p class="mt-2 viewtext">
                                    {{this.f.commonApplication.value}} </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view"
                                    for="this.f.requirements.value">{{staticMsgs.seamDetail.requirement}}</label>
                                  <p class="mt-2 viewtext">{{this.f.requirements.value}}
                                  </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view"
                                    for="this.f.status.value">{{staticMsgs.seamDetail.status}}</label>
                                  <p class="mt-2 viewtext">{{this.f.status.value |
                                    titlecase}}</p>
                                </div>
                                <div class="col-sm-8 mb-3">
                                  <label class="label-view"
                                    for="this.f.comments.value">{{staticMsgs.seamDetail.comment}}</label>
                                  <p class="mt-2 viewtext">{{this.f.comments.value}}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- /* Written by : Himanshu */ -->

                          <div class="row form-row mt-2">

                            <div class="col-xl-4 mb-5" *ngFor="let editedThumbnail of editedThumbnails; let j=index">
                              <img src="{{editedThumbnail.thumbnail}}" class="img-fluid "
                                *ngIf="isValidImageURL(editedThumbnail.name)" (click)="openThumbnail(j)">
                              <!-- <button  type="button" class="close image-close" (click)="removeThumbnailSection(editedThumbnail)">
                                      <span aria-hidden="true">×</span>
                                   </button> -->
                            </div>


                          </div>

                          <!-- <div class="form-row mt-5">
                        <div class="col-sm-4 mb-3">
                          <label for="validationDefault02">Internal Ref #</label>
                          <input type="text" class="form-control"  id="validationDefault02" placeholder="Last name" value="SEQ Col: 12345#" required="" disabled>
                      </div>
                        <div class="col-sm-8 mb-3">
                          <label for="validationDefault01" >Description</label>
                          <textarea cols="2" class="form-control" name="description" disabled>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate.</textarea>
                      </div>
                      </div> -->
                        </form>
                      </div>
                    </div>
                    <!-- /tab panel -->

                    <!-- Tab panel -->
                    <div id="tab-pane2" class="tab-pane card-body card-body-shadow bg-white"
                      [ngClass]="{ 'active':activeTab === 'docDetailsTab'}">
                      <div *ngIf="isEditingMode">
                        <form [formGroup]="documentsForm" (ngSubmit)="onDocSubmit()">
                          <div formArrayName="documents">
                            <div *ngFor="let document of getControls(); let i=index">
                              <div class="card p-5" [formGroupName]="i">
                                <div class="form-row ">
                                  <div class="col-sm-12 float-right">
                                    <button type="button" class="close" (click)="removeDoc(i)"><span
                                        aria-hidden="true">×</span></button>
                                  </div>
                                </div>

                                <div class="form-row">

                                  <div class="col-lg-4 col-sm-12 mb-3">
                                    <label for="docclassification">
                                      {{staticMsgs.seamDetail.selectclass}}<span class="red_icon">*</span></label>
                                    <input type="text" class="form-control" id="docclassification{{i}}"
                                      formControlName="docclassification" placeholder="Classification" required />
                                    <div
                                      *ngIf="(submitted1 && document.get('docclassification').errors) || ( document.get('docclassification').invalid && (document.get('docclassification').dirty || document.get('docclassification').touched))">
                                      <div *ngIf="document.get('docclassification').errors.required" class="red">
                                        {{staticMsgs.seamDetail.classReq}}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-4 mb-3" *ngIf="document.get('docid').value">
                                    <label for="docseq">{{staticMsgs.seamDetail.document1}}</label>
                                    <input type="text" class="form-control" [readonly]="true" id="docseq"
                                      formControlName="docseq" placeholder="Document Id" />
                                  </div>

                                  <div class="col-lg-4 col-sm-12 mb-3">
                                    <label for="docname">{{staticMsgs.seamDetail.name}}<span
                                        class="red_icon">*</span></label>
                                    <input type="text" class="form-control" id="docname" formControlName="docname" minlength="3"
                                    maxlength="50"  placeholder="Document Name" (keypress)="characternameOnly($event)" required />
                                    <div
                                      *ngIf="(submitted1 && document.get('docname').errors) || ( document.get('docname').invalid && (document.get('docname').dirty || document.get('docname').touched))">
                                      <div *ngIf="document.get('docname').errors.required" class="red">
                                        {{staticMsgs.seamDetail.docNameReq}}
                                      </div>
                                      <div *ngIf="document.get('docname').errors.minlength" class="red">
                                        {{staticMsgs.common.minimum3charactersrequired}}
                                       </div>
                                    </div>

                                  </div>

                                </div>

                                <div class="form-row ">
                                  <div class="col-sm-4 mb-3 mt-3">
                                    <label for="docstatus">{{staticMsgs.seamDetail.status}}<span class="red_icon">*</span></label>
                                    <select  class="form-control selectpicker" id="docstatus"title="Select Status"
                                      formControlName="docstatus" >
                                      <!-- <option value="" [disabled]="true" selected>
                                         {{staticMsgs.seamDetail.selectStatus}}
                                      </option> -->
                                      <option value="Active" selected>{{staticMsgs.seamDetail.active}}</option>
                                      <option value="Inactive">{{staticMsgs.seamDetail.inactive}}</option>
                                    </select>
                                    <div
                                      *ngIf="(submitted1 && document.get('docstatus').errors) || ( document.get('docstatus').invalid && (document.get('docstatus').dirty || document.get('docstatus').touched))">
                                      <div *ngIf="document.get('docstatus').errors.required" class="red">
                                        {{staticMsgs.seamDetail.statusReq}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-8 mb-3 mt-3">
                                    <label for="docdescription">{{staticMsgs.seamDetail.description}}<span
                                        class="red_icon">*</span></label>
                                    <textarea cols="2" class="form-control" id="docdescription"
                                      formControlName="docdescription" required></textarea>
                                    <div
                                      *ngIf="(submitted1 && document.get('docdescription').errors) || ( document.get('docdescription').invalid && (document.get('docdescription').dirty || document.get('docdescription').touched))">
                                      <div *ngIf="document.get('docdescription').errors.required" class="red">
                                        {{staticMsgs.seamDetail.descReq}}
                                      </div>
                                      <div
                                        *ngIf="document.get('docdescription').value && document.get('docdescription').value.length > 500"
                                        class="red">
                                        {{staticMsgs.seamDetail.descAllow}}
                                      </div>
                                    </div>

                                  </div>

                                </div>

                                <div class="dt-entry__header mt-5"
                                  *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                  <div class="dt-entry__heading">
                                    <h3 class="dt-entry__title">
                                      {{document.get('docclassification').value}}</h3>
                                  </div>
                                </div>

                                <div class="form-row" *ngIf="document.get('docclassification').value === 'Lab Reports'">

                                  <div class="col-sm-4 mb-3">
                                    <label for="docreportstatus">{{staticMsgs.seamDetail.report}}<span
                                        class="red_icon">*</span></label>
                                    <select type="text" class="form-control selectpicker" id="docreportstatus" title="Select Report Status"
                                      formControlName="docreportstatus">

                                      <option value="Pass" selected>{{staticMsgs.seamDetail.pass}}</option>
                                      <option value="Fail">{{staticMsgs.seamDetail.fail}}</option>
                                    </select>
                                    <div
                                      *ngIf="(submitted1 && document.get('docreportstatus').errors) || ( document.get('docreportstatus').invalid && (document.get('docreportstatus').dirty || document.get('docreportstatus').touched))">
                                      <div *ngIf="document.get('docreportstatus').errors.required" class="red">
                                        {{staticMsgs.seamDetail.reportStatusReq}}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-4 mb-3">
                                    <label for="doclablocation">{{staticMsgs.seamDetail.labLoc}}<span
                                        class="red_icon">*</span></label>
                                    <input type="text" class="form-control" id="doclablocation"
                                      formControlName="doclablocation" placeholder="Lab Location" required />
                                    <div
                                      *ngIf="(submitted1 && document.get('doclablocation').errors) || ( document.get('doclablocation').invalid && (document.get('doclablocation').dirty || document.get('doclablocation').touched))">
                                      <div *ngIf="document.get('doclablocation').errors.required" class="red">
                                        {{staticMsgs.seamDetail.labLocReq}}
                                      </div>
                                      <div
                                        *ngIf="document.get('doclablocation').value && document.get('doclablocation').value.length > 50"
                                        class="red">
                                        {{staticMsgs.seamDetail.labLocAllow}}
                                      </div>
                                    </div>

                                  </div>

                                </div>

                                <div class="dt-entry__header mt-5">
                                  <div class="dt-entry__heading">
                                    <h3 class="dt-entry__title">{{staticMsgs.seamDetail.document}}</h3>
                                  </div>
                                </div>


                                <div class="form-row" [hidden]="true">
                                  <input type="text" class="form-control" id="docdeletedfileids"
                                    formControlName="docdeletedfileids" placeholder="Deleted File Ids">
                                </div>

                                <div class="form-row">
                                  <div class="col-xl-12">
                                    <label for="document">{{staticMsgs.common.docname}}<span
                                      class="red_icon">*</span></label>
                                    <div class="custom-dropzone" id="docupload" ngx-dropzone
                                      (change)="onSelectDoc($event,i)">
                                      <ngx-dropzone-label>
                                        <div>
                                          <h5 class="text-light-gray mt-3">
                                            {{staticMsgs.seamDetail.dropFile}}</h5>
                                        </div>
                                      </ngx-dropzone-label>
                                      <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview"
                                        *ngFor="let f of fileData[i]" [file]="f" [removable]="true"
                                        (removed)="onRemove(f,i)">
                                        <ngx-dropzone-label>{{ f.name }}
                                          <!--  ({{ f.type }}) -->
                                        </ngx-dropzone-label>
                                      </ngx-dropzone-preview>

                                    </div>
                                    <div *ngIf="doc_Error[i]">
                                      <span class="red">
                                        <span>
                                          {{doc_Error[i]}}
                                        </span></span>
                                      </div>
                                      <div class="col-xl-6 mt-6" *ngIf="document.get('docid').value">
                                        <div class="col-xl-6 mb-5" *ngFor="let img of editedFileData[i]; let j = index">
                                          <img src="{{img.thumbnail}}" class="img-fluid  img-fit "
                                            *ngIf="isValidImageURL(img.name)" (click)="open(i,j)">
                                          <a href="{{img.thumbnail}}" target="_blank" download><img src="{{documentImage}}"
                                              class="img-fluid  img-fit " *ngIf="!isValidImageURL(img.name)"></a>
                                          <button type="button" class="close image-close" (click)="removeImgSection(img,i)">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                      </div>
                                  </div>
                                </div>

                                <div class="form-row mt-5" *ngIf="(fileData[i]) && (!isEmptyObject(fileData[i]))">
                                  <div class="col-xl-12 ">
                                    <div class="card">
                                      <div class="dt-card__body p-2">
                                        <label for="docnotes">{{staticMsgs.seamDetail.notes}}</label>
                                        <textarea cols="2" class="form-control" id="docnotes"
                                          formControlName="docnotes"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <input type="text" class="form-control" [hidden]="true" id="docid"
                                  formControlName="docid" placeholder="Document Id" />

                              </div>

                            </div>
                          </div>

                          <div class="form-row text-center mb-7">
                            <div class="col-sm-12">
                              <!-- <button type="button" class="btn btn-primary adv-search-btn">Select Existing</button> -->
                              <button type="button" class="btn btn-primary adv-search-btn" (click)="addNewDoc()"><i
                                  class="icon icon-plus"></i>
                                {{staticMsgs.seamDetail.addNew}}</button>

                            </div>
                          </div>
                          <div class="form-row text-center mb-7" *ngIf="showButton">
                            <div class="col-sm-12">
                              <button type="submit" *ngIf="fileData.length>0" [disabled]="documentsForm?.invalid || !isFilesAdded"
                                      class="btn btn-primary adv-search-btn">
                                      {{staticMsgs.seamDetail.save}}
                              </button>
                              <button type="button" *ngIf="isEditMode"
                                      (click)="cancelDocumentEdit()"
                                      class="btn btn-primary adv-search-btn">
                                      {{staticMsgs.common.cancel}}
                              </button>
                            </div>
                          </div>
                          <!-- <div class="form-row text-center mb-7" *ngIf="showButton">
                            <div class="col-sm-12">
                              <button type="button" *ngIf="isEditMode" (click)="cancelDocumentEdit()"
                                class="btn btn-outline-primary adv-search-btn mr-1">{{staticMsgs.seamDetail.cancel}}</button>
                              <button type="submit"
                                class="btn btn-primary adv-search-btn">{{staticMsgs.seamDetail.save}}</button>
                            </div>
                          </div> -->

                        </form>
                      </div>

                      <div *ngIf="isViewingMode">
                        <div class="text-center" *ngIf="seamsDataCnt == 0">
                          <h3 class="mt-4">{{staticMsgs.common.nodataavailable}}</h3>
                          <img [src]="notfoundImg" class="img-fluid w-40"><br>
                        </div>
                        <div>
                          <div *ngFor="let document of getControls(); let i=index">
                            <div class="dt-entry__header mt-5">
                              <!-- Entry Heading -->
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">
                                  {{document.get('docclassification').value}}</h3>
                              </div>
                              <!-- /entry heading -->
                            </div>

                            <form>
                              <div class="form-row">
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view" for="document.get('docseq').value">
                                    {{staticMsgs.seamDetail.document1}}</label>
                                  <p class="mt-2 viewtext">{{document.get('docseq').value}}
                                  </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view"
                                    for="document.get('docname').value">{{staticMsgs.seamDetail.name}}</label>
                                  <p class="mt-2 viewtext">{{document.get('docname').value}}
                                  </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view"
                                    for="document.get('docstatus').value">{{staticMsgs.seamDetail.status}}</label>
                                  <p class="mt-2 viewtext">{{document.get('docstatus').value |
                                    titlecase}}
                                  </p>
                                </div>

                                <div class="col-sm-4 mb-3"
                                  *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                  <label class="label-view"
                                    for="document.get('docreportstatus').value">{{staticMsgs.seamDetail.report}}</label>
                                  <p class="mt-2 viewtext">
                                    {{document.get('docreportstatus').value}}</p>
                                </div>
                                <div class="col-sm-4 mb-3"
                                  *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                  <label class="label-view"
                                    for="document.get('doclablocation').value">{{staticMsgs.seamDetail.labLoc}}</label>
                                  <p class="mt-2 viewtext">
                                    {{document.get('doclablocation').value}}</p>
                                </div>

                                <div class="col-sm-8 mb-3">
                                  <label class="label-view"
                                    for="document.get('docdescription').value">{{staticMsgs.seamDetail.description}}</label>
                                  <p class="mt-2 viewtext">
                                    {{document.get('docdescription').value}}
                                  </p>
                                </div>
                                <div class="col-sm-8 mb-3" *ngIf="document.get('docnotes').value">
                                  <label class="label-view"
                                    for="document.get('docnotes').value">{{staticMsgs.seamDetail.docnotes}}</label>
                                  <p class="mt-2 viewtext">{{document.get('docnotes').value}}
                                  </p>
                                </div>
                              </div>

                              <div class="form-row mt-2">
                                <div class="col-xl-3 mb-5" *ngFor="let img of editedFileData[i]; let j = index">
                                  <img src="{{img.thumbnail}}" class="img-fluid  img-fit "
                                    *ngIf="isValidImageURL(img.name)" (click)="open(i,j)">
                                  <a href="{{img.thumbnail}}" target="_blank" download><img src="{{documentImage}}"
                                      class="img-fluid  img-fit " *ngIf="!isValidImageURL(img.name)"></a>
                                </div>
                              </div>

                            </form>
                          </div>
                        </div>
                      </div>


                    </div>
                    <!-- /tab panel -->

                  </div>
                  <!-- /tab content-->

                </div>
                <!-- /card body -->

              </div>
              <!-- /card -->

            </div>
          </div>
          <!-- /grid item -->



        </div>
        <!-- /grid -->

      </div>


    </div>

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->
  </div>
  <!-- /Site Content Wrapper-->

</main>
<!-- /site content -->

<!-- Theme Chooser -->
<!-- <div class="dt-customizer-toggle" >
               <i class="icon icon-double-arrow-left expand-arrow"></i>
            </div>

            <div class="dt-exla" (click)="itemDetails()">
              <i class="icon icon-info icon-exla"></i>
           </div>

           <div class="dt-time" (click)="timeLine()">
            <i class="icon icon-time icon-exla"></i>
           </div>

           <div class="dt-chat" (click)="chat()">
            <i class="icon icon-message icon-exla"></i>
           </div> -->
<!-- /theme chooser -->


<!-- Root -->
