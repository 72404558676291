import { AfterViewChecked,ElementRef, Component, OnInit, ViewChild,Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';
import { UserService } from '../../helpers/user.service';
import { StorageService } from '../../helpers/storage.service';
import Swal from 'sweetalert2';
import { SharedService } from '../../services/shared.service';
import { NotificationService, NotificationModel } from '../../services/notification-generated';
import { APIService } from '../../services/API.service';
import Amplify, { Auth, API } from 'aws-amplify';
import { environment } from '../../../../../tenants/main/environments/environment';
import awsconfig from '../../../../../aws-exports';
import {​​​​​​​​ io }​​​​​​​​ from'socket.io-client';
const socket = io(environment.socketIoServerUrl, {transports: ['websocket', 'polling', 'flashsocket']});
import { DatePipe } from '@angular/common';
import { AESDecryptService } from '../../helpers/AESDecrypt.service';
declare var $: any;
import * as appStaticMsg from '../../../assets/appStaticMsg.json';
import * as S3 from 'aws-sdk/clients/s3';
import { DomSanitizer } from '@angular/platform-browser';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.css']
})

export class DiscussionComponent implements OnInit,AfterViewChecked{
  @ViewChild('scrollBottom') private scrollBottom: ElementRef;
  @Output() message = new EventEmitter();
  showStopLoader: string = '';
  filteredData: any[];
  staticmsgs:any;
  isedit: Array<boolean> = [];
  editedFileData: Array<any> = [];
  //userThumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";
  userThumbnail: string = '';
  editedStyleData: any;
  showLoader: boolean;
  showLoader1: boolean;
  Swal = require('sweetalert2');
  errors = '';
  errorDiv = false;
  alert = '';
  isEditMode: boolean;
  isEditingMode: boolean;
  isViewingMode: boolean;
  removedStyImgIds = '';
  submitted = false;
  submitted1 = false;
  fieldArray: any;
  sharedUsers = [];
  public isNew: boolean;
  private editedRowIndex: number;
  classApplied = false;
  itemDetailsSection = false;
  timeLineSection = false;
  chatSection = false;
  LoggedinuserDetails: any;
  Loggedinuserthumbnail: any;
  createdBythumbnail:any;
  modifiedBythumbnail:any;
  styleId: any;
  allChats: any = [];
  allUser: any;
  onlineUsers: any;
  notificationModel: NotificationModel;



  constructor(
    private sharedService: SharedService,
    private storageService: StorageService,
    private apiService: APIService,
    private dataservice: DataService,
    private notificationService: NotificationService,
    private aesdecryptservice: AESDecryptService,
    public userservice: UserService,
    private sanitizer: DomSanitizer
   ){
  }

  /* To set properties initialization on load page
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  async ngOnInit() {
    //this.showLoader = true;
    //this.getImage();
    this.staticmsgs=appStaticMsg.data[0];
    this.getOnlineUsers();
    this.LoggedinuserDetails = await Auth.currentAuthenticatedUser();
    //this.apiService.ListStyleDiscussionsQuery.
    this.apiService.OnCreateStyleDiscussionListener.subscribe({
      next: async (StyleDiscussion) => {

        let newMsg = StyleDiscussion.value.data.onCreateStyleDiscussion;
        var thumbnail;
        var user = [];
        var isOnline = false;
        if(this.onlineUsers.length > 0)
        {
          if(this.onlineUsers.indexOf(newMsg.userEmailId) !== -1){
             isOnline  = true;
          }
        }

        if(this.fieldArray)
        {
          user = this.fieldArray.filter((x, i) => x.user[0].eMail === newMsg.userEmailId);
        }
        if (user.length > 0) {
          if (user[0].user[0].pictureID) {
            thumbnail = user[0].user[0].picture.thumbnail;
          }
          else {
            thumbnail = this.userThumbnail;
          }
        }
        else {
          if (this.editedStyleData[0]?.createdBy?.eMail == newMsg.userEmailId) {
            if(this.editedStyleData[0].createdBy.picture != null)
            {
              thumbnail = this.editedStyleData[0].createdBy.picture.thumbnail;
            }
            else
            {
              thumbnail = this.userThumbnail;
            }
          }
          else {
            thumbnail = this.userThumbnail;
          }
        }

        let imageUrl1;
        if(newMsg.documentId){
          imageUrl1 = await this.getImage(newMsg.documentId);
        }else{
          imageUrl1 = {
            url : '',
            name : '',
            ext : ''
          }
        }


        let item = {
          id: newMsg.id,
          createdAt: newMsg.createdAt,
          msgBody: newMsg.msgBody,
          documentId: imageUrl1.url,
          documentName: imageUrl1.name,
          documentExt: imageUrl1.ext,
          styleId: newMsg.styleId,
          updatedAt: newMsg.updatedAt,
          userEmailId: newMsg.userEmailId,
          userName: newMsg.userName,
          thumbnail: thumbnail,
          isOnline : isOnline
        };
        //console.log('item',item)
        this.allChats.push(item);
      }
    })
    this.showLoader = true;
    this.sharedService.sendClickEvent();
  }

  // startStopLoader(event){
  //   this.loaderEvent.emit(event);
  // }

  ngAfterViewChecked() {
     this.scrollToBottom();
  }

  /* To get Online Users for display online or offline status.
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  getOnlineUsers() {
    this.onlineUsers = [];
    if (this.storageService.getOnlineUsers()) {
      let result = this.storageService.getOnlineUsers();
      this.onlineUsers = result.split(',');
    }
  }

  scrollToBottom(): void {
    try {
      this.scrollBottom.nativeElement.scrollTop = this.scrollBottom.nativeElement.scrollHeight;
    } catch(err) { }
  }


  /* To open Styles discussion model.
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  openStylesDiscussion(styleId,fieldArray,editedStyleData) : void {
    socket.on('onlineUsers', (onlineUsers) => {
         this.storageService.storeOnlineUser(onlineUsers);
         this.getOnlineUsers();
         this.bindStyleDiscussion();
    });
    this.styleId = styleId;
    this.sharedUsers = [];
    this.getOnlineUsers();
    if(editedStyleData.length == 1)
    {
      this.fieldArray = [];
      this.fieldArray = fieldArray;
      if (this.fieldArray.length > 0) {
          this.fieldArray.forEach(element => {

            element.user[0].eMail = this.aesdecryptservice.decryptData(this.dataservice.Key, element.user[0].eMail);
            element.user[0].firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, element.user[0].firstName);
            element.user[0].lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, element.user[0].lastName);
            element.user[0].fullName = element.user[0].firstName + " " + element.user[0].lastName
            if(element.user[0].pictureID)
            {
              element.user[0].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, element.user[0].picture.thumbnail);
            }

            var isOnline = false;
            if(this.onlineUsers.length > 0)
            {
              if(this.onlineUsers.indexOf(element.user[0].eMail) !== -1){
                isOnline  = true;
              }
            }
            let item = {
              user: element.user,
              isOnline: isOnline
            };
            this.sharedUsers.push(item);
          });
      }

      this.editedStyleData = editedStyleData;
      if (this.styleId) {
        this.bindStyleDiscussion();
        this.classApplied = true;
        this.chatSection = true;
        this.isViewingMode = true;
      }
    }
  }

  /* To close discussion model.
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  stylesDiscussionClose() {
    this.classApplied = false;
    this.chatSection = false;
  }

  /* To send new chat message and send Notification to all users of style.
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  async sendMsg(msgBody,file) {
    if(!file && msgBody.value.length == 0){
      console.log('file',file);
      console.log('msgBody',msgBody.value);
      return;
    }

    //if (msgBody.value.length) {
       var cValue = new Date().toISOString();
      const newMsg = {
        styleId: this.styleId,
        userEmailId: this.LoggedinuserDetails.attributes.email,
        userName: this.LoggedinuserDetails.attributes.name,
        msgBody: msgBody.value,
        documentId : file,
        createdAt: cValue
      };

      //send message in grop chat discussion
      await this.apiService.CreateStyleDiscussion(newMsg);

      let userEmail =  this.storageService.getEmail();
      let username = this.storageService.getUser();
      this.notificationModel =
      {
        title: this.staticmsgs.discussion.fitosnotifications,
        message: msgBody.value,
        documentId: file,
        url: "",
        email: userEmail,
        recordId: this.styleId,
        notifiationType: "Chat",
        moduleName:"Styles"
      };

      if(msgBody){
        msgBody.value = null;
      }


      this.scrollToBottom();

      this.notificationService.sendNotification(this.notificationModel).subscribe((response) => {
        if (response.status === 200) {
              if (response.body['item2'] === "Success") {
                console.log("sendnotification Success");
                console.log(JSON.stringify(response.body['item1']));
              }
              else {
                console.log(JSON.stringify(response));
                console.log("sendnotification Faild");
                console.log(JSON.stringify(response.body['item1']));
              }
          }
          this.notificationModel.url = "";
          this.notificationModel.title = "";
          this.notificationModel.message = "";
          this.notificationModel.recordId = "";
          this.notificationModel.notifiationType = "";
          this.notificationModel.moduleName = "";
      },err=>{
        console.log('Notification error',err);
      });

      //$("html, body").animate({ scrollTop: $('html, body').get(0).scrollHeight }, 10);
    //}
  }


  /* To get all shared users of style.
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  getAllsharedaccess() {
    let email = this.storageService.getEmail();
    if(email)
    {
      this.sharedUsers = [];
      this.dataservice.getsharedaccess(this.styleId, 'Styles').subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.fieldArray = [];
            this.fieldArray = response.body['item1'];
            if (this.fieldArray.length > 0) {
              this.sharedUsers = [];
              this.fieldArray.forEach(element => {
                element.user[0].eMail = this.aesdecryptservice.decryptData(this.dataservice.Key, element.user[0].eMail);
                element.user[0].firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, element.user[0].firstName);
                element.user[0].lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, element.user[0].lastName);
                element.user[0].fullName = element.user[0].firstName + " " + element.user[0].lastName
                if(element.user[0].pictureID)
                {
                  element.user[0].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, element.user[0].picture.thumbnail);
                }
                var isOnline = false;
                if(this.onlineUsers.length > 0)
                {
                  if(this.onlineUsers.indexOf(element.user[0].eMail) !== -1){
                    isOnline  = true;
                  }
                }
                let item = {
                  user: element.user,
                  isOnline: isOnline
                };
                this.sharedUsers.push(item);
              });
            }
          } else {
            this.error(response.body['item1']);
            this.showLoader = false;
          }
        }
      }, err => {
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
        }
        this.showLoader = false;
      });
    }
  }


  /* To get style discussion of style.
    Written by : Kiran Nagargoje
    Date: 07-Oct-2021
  */
  async bindStyleDiscussion() {
    this.sharedUsers = [];
    this.getAllsharedaccess();
    this.scrollToBottom();
    let result = await this.apiService.ListStyleDiscussions();
    console.log('result',result,result.nextToken);

    this.allChats = [];
    result.items.sort((a: any, b: any) => { return Date.parse(a.createdAt) - Date.parse(b.createdAt) });
    let filtereddata = [];
    let logdateval : string;
    let logdate : string;
    filtereddata = result.items.filter((x, i) => x.styleId === this.styleId);
    console.log('filtereddata',filtereddata);
    if (filtereddata.length > 0) {
      filtereddata.forEach(async element => {
        var thumbnail;
        var user = [];
        var isOnline = false;
        if(this.onlineUsers.length > 0)
        {
          if(this.onlineUsers.indexOf(element.userEmailId) !== -1){
             isOnline  = true;
          }
        }
        if(this.fieldArray)
        {
          user = this.fieldArray.filter((x, i) => x.user[0].eMail === element.userEmailId);
        }
        if (user.length > 0) {
          if (user[0].user[0].pictureID) {
            thumbnail = user[0].user[0].picture.thumbnail;
          }
          else {
            thumbnail = this.userThumbnail;
          }
        }
        else {
          if (this.editedStyleData[0].createdBy.eMail == element.userEmailId) {
            if(this.editedStyleData[0].createdBy.picture != null)
            {
              thumbnail = this.editedStyleData[0].createdBy.picture.thumbnail;
            }
            else
            {
              thumbnail = this.userThumbnail;
            }
          }
          else {
            thumbnail = this.userThumbnail;
          }
        }

        var isSamedate = false;
        let pipe = new DatePipe('en-US');
        //var createdAt =  pipe.transform(element.createdAt, 'dd-MM-yyyy');
        var createdAt =  pipe.transform(element.createdAt, 'MMM dd,yyyy');
        if(logdateval == createdAt)
        {
          isSamedate = false;
        }
        else{
          isSamedate = true;
        }
        logdateval = createdAt;

        let todaydate = new Date();
        //var currentdate =  pipe.transform(todaydate, 'dd/MM/yyyy');
        var currentdate =  pipe.transform(todaydate, 'MMM dd,yyyy');
        if(createdAt == currentdate)
        {
          logdate = "Today";
        }
        else
        {
          console.log('logdateval',createdAt);
          logdate = logdateval;
        }

        let imageUrl;

        if(element.documentId){
          imageUrl = await this.getImage(element.documentId)
        }else{
          imageUrl = {
            url : '',
            name : '',
            ext : ''
          }
        }

        let item = {
          id: element.id,
          createdAt: element.createdAt,
          msgBody: element.msgBody,
          documentId: imageUrl.url,
          documentName: imageUrl.name,
          documentExt: imageUrl.ext,
          styleId: element.styleId,
          updatedAt: element.updatedAt,
          userEmailId: element.userEmailId,
          userName: element.userName,
          thumbnail: thumbnail,
          isOnline : isOnline,
          isSamedate: isSamedate,
          logdate: logdate
        };
        //console.log('item 1',item)
        this.allChats.push(item);

      });
    }

    // let chatData = {
    //   nextToken : result.nextToken
    // }
    //let aa = await this.apiService.ListStyleDiscussions({},100,result.nextToken);
    //console.log('aa with next token',aa);
  }

  getSharedData() {
    this.isedit = [false];
    this.getAllsharedaccess();
    this.stylesDiscussionClose();
  }


  /* To display success msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 06-Oct-2021 */
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }


   /* To display error msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 06-Oct-2021 */
  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }


  /* To display session expired msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 06-Oct-2021 */
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticmsgs.discussion.sessionexpired,
      html: this.staticmsgs.discussion.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
         this.userservice.logout();
        //console.log('Session Timedout.')
        return;
      }
    })
  }


  /* NOT USE
    Written by : Kiran Nagargoje
    Date: 06-Oct-2021 */
  openitemDetails(editedStyleData) {
    if(editedStyleData.length == 1)
    {
      this.editedStyleData = editedStyleData;
      if(editedStyleData[0].createdBy.picture != null)
      {
        this.createdBythumbnail = editedStyleData[0].createdBy.picture.thumbnail;
      }
      else
      {
        this.createdBythumbnail = this.userThumbnail;
      }

      if(editedStyleData[0].modifiedBy.picture != null)
      {
        this.modifiedBythumbnail = editedStyleData[0].modifiedBy.picture.thumbnail;
      }
      else
      {
        this.modifiedBythumbnail = this.userThumbnail;
      }

      this.classApplied = true;
      this.itemDetailsSection = true;
      this.isViewingMode = true;
    }
  }


  /* To close style discussion model
    Written by : Kiran Nagargoje
    Date: 06-Oct-2021 */
  itemDetailsClose() {
    this.classApplied = false;
    this.itemDetailsSection = false;
  }


   /* NOT USE
    Written by : Kiran Nagargoje
    Date: 06-Oct-2021 */
  timeLine() {
    this.classApplied = true;
    this.timeLineSection = true;
  }

   /* NOT USE currently
    Written by : Kiran Nagargoje
    Date: 06-Oct-2021 */
  timeClose() {
    this.classApplied = false;
    this.timeLineSection = false;
  }

  patternImageData: any;
  async onChangePatternImg(event) {
    this.showLoader = true;
    let fileUploadedData = await this.uploadFile(event.target.files[0]);
    console.log('fileUploadedData',fileUploadedData)
    this.sendMsg('',fileUploadedData.Key);
    this.showLoader = false;
  }

  async uploadFile(file): Promise<any> {

    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          reject("error");
        }
        resolve(data);
      });
    });
  }

  /*Common method To create Unique UUID
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  async getImage(image){
    if(!image || image == 'key'){
      return;
    }
    //console.log('calling getObject',image);
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}`,
      Key: image
    };

    const url = await bucket.getSignedUrl('getObject', params);
    //console.log('url',url);
    let ext = this.getFileExtension(image);
    let name = image.split('/');
    let Data = {
      name : name[1],
      url : url,
      ext : ext
    }
    return Data;

  }

  getFileExtension(filename){
    if(!filename){
      return;
    }
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    return extension;
  }

  showLightbox(chat){
    console.log('showLightbox',chat);
    let Data = {
      documentId : chat.documentId,
      documentName : chat.documentName
    }
    this.message.emit(Data);
  }
}
