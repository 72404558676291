import { Component, Input, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { DataService } from '../../../services/data.service';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { LogService } from '../../../shared/log.service';
import Swal from 'sweetalert2';
import { UserService } from '../../../helpers/user.service';
import { CalSubTotal } from '../../../models/color';
import { StorageService } from '../../../helpers/storage.service';
import { process, filterBy, orderBy, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StyledetailsComponent } from '../styledetails/styledetails.component';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../services/shared.service';
import { Router } from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
declare var $: any;

@Component({
  selector: 'app-style-pom',
  templateUrl: './style-pom.component.html',
  styleUrls: ['./style-pom.component.css']
})
export class StylePOMComponent implements OnInit {
  editData: any;
  pomload: boolean;
  isPomnew: boolean;
  isPomedit: boolean;
  pomform: FormGroup;
  pomdata: any = [];
  sizerange: any = [];
  appStaticMsgs: any;
  private editedRowIndex: number;
  public specificationPomsstartCountList = 0;
  public specificationPomsendCountList = 10;
  public specificationPomsstartEndCount = "";
  public specificationPomsisPageChanged = false;
  public specificationPomsRecordCount: any;
  public specificationPomsloading: boolean;
  public isSpeNew: boolean;
  showSpecPomNote = false;
  sampleSize: any = [];
  unfilteredData: any;
  dbspecificationPoms: any[];
  specificationPoms: any;
  storePom: any = [];

  constructor(private dialogRef: MatDialogRef<StyledetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private userservice: UserService,
    private storageservice: StorageService,
    private sharedService: SharedService,
    private router: Router,
    private fb: FormBuilder,
    private toastService: ToastService,
    private modalService: NgbModal) {
    this.sharedService.getPomData().subscribe(x => {
      this.pomdata = x;
    })
    this.sharedService.getSizeRange().subscribe(y => {
      this.sizerange = y;
    })
    this.sharedService.getUnfilter().subscribe(z => {
      this.unfilteredData = z;
    })
    this.sharedService.getSample().subscribe(z => {
      this.sampleSize = z;
    })
  }

  ngOnInit(): void {
    console.log(this.data, 'data');
    this.appStaticMsgs = appStaticMsg.data[0];
    if (this.data?.isPomedit == false) {
      this.pomform = this.createGroup();
    }
    else {
      this.editSpecification(this.data.operationData.id);
    }
    this.sampleSize;

  }

  get f() { return this.pomform.controls; }
  get fa() { return this.f.formArray as FormArray; }

  initSlectPicker(key) {
    $(document).ready(function () {
      $('#' + key).selectpicker();
    });
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }

  numberOnly(e: any, item: any) {
    // var regex = new RegExp("^[0-9]+$");
    //var regex = new RegExp("^[0-9]*\.?[0-9]*$");
    // const regex = new RegExp("^[0-9]*\.?[0-9]*$");
    // var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    // if (regex.test(str)) {
    //   return true;
    // }
    // e.preventDefault();
    // return false;
    if (item && item.trim().toLowerCase() !== 'pomdescription') {
      var inputChar = String.fromCharCode(e.charCode);
      // Check if the entered character is a digit or a dot
      if (!/^[0-9.]+$/.test(inputChar)) {
        e.preventDefault();
      }
      // Check if the entered value is a valid positive number
      const inputValue = e.target.value + inputChar;
      if (!/^\d*\.?\d*$/.test(inputValue) || parseFloat(inputValue) < 0) {
        e.preventDefault();
      }

    }


  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessgetcolorwaysionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })

  }

  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    )
  }

  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove', 'selectAll');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Image Type' }, 'deselectAll');
    });
  }

  public editSpecification(e) {
    console.log(e, 'e');
    let data1: any = [];
    data1.push(this.data.operationData);
    const group = this.fb.group({});
    // if (this.data.operationData !== null) {
    //   this.pomdata.push('id');
    // }
    if (this.pomdata) {
      data1.forEach((y, index) => {
        console.log(y, 'y');
        this.pomdata.forEach((x, i) => {
          const controlValue = x.toLowerCase() === 'pomdescription' ? y['description'] : y[x];
          group.addControl(x, this.fb.control(controlValue));
        },

        );
      })
      console.log(group, 'group');
    }
    this.isSpeNew = true;

    let data = this.createGroup();
    //  data.controls.push('spid');
    console.log(data, 'data');

    this.pomform = group;
  }

  createGroup() {

    const group = this.fb.group({});
    if (this.pomdata) {
      this.pomdata.forEach(x => group.addControl(x, this.fb.control('')));
    }
    return group;

  }

  public pomSubmit() {
    console.log("PomSubmit");

    for (var i = 0; i < this.pomform.value.length; i++)

      if (this.pomform.controls[i].value.length > 100) {
        this.pomform.controls[i].setErrors({ 'incorrect': true });
      }


    if (this.pomform.invalid) {
      this.showSpecPomNote = true;
      this.toastService.showError("Please enter all the mandatory fields.");
      return;
    }
    this.pomload = true;

    console.log(this.pomform.value);

    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('stylepomcount', '1');
    if (this.data?.isPomedit == false) {
      formData.append('sptag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add'));
    } else {
      formData.append('SPID[0]', this.data.operationData['id']);
      formData.append('sptag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update'));
    }
    formData.append('description[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomform.value['Pomdescription']));
    formData.append('poms[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomform.value['poms']));
    formData.append('tolerance[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomform.value['tolerance']));

    this.sampleSize.forEach(x => {
      console.log('key : ' + x.value + ' - value : ' + this.pomform.value[x.value]);
      formData.append(x.value + '[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.pomform.value[x.value]));
    });
    let datas = [];
    this.unfilteredData.forEach(y => {
      datas.push(y.columnName);
    });

    let b = this.pomdata;
    let a = datas;
    let bc = [];
    let array3 = a.filter(entry1 => !b.some(entry2 => entry1 === entry2));

    array3.forEach(z => {
      formData.append(z + '[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, ''));
    })

    this.pomload = true;
    console.log(formData, 'formdata');
    console.log(this.pomform.value, 'formvalue');
    //return;
    this.dataservice.addupdatedeleteStylespecification(formData).subscribe(async response => {
      this.showSpecPomNote = false;
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.pomload = false;
           this.logger.info(this.appStaticMsgs.styledetails.specificationpomsavesuccessfully, "Style Specification pom");
          // this.success(this.appStaticMsgs.styledetails.specificationpomsavesuccessfully);
          this.toastService.showSuccess('Added Successfully');
          this.dialogRef.close();
          this.getStylePomSpecifications();
        }
        else {
          // this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "Style Specification pom");
          this.toastService.showError(response.body['item1']);
          this.pomload = false;
        }
      }
    }, err => {
      this.showSpecPomNote = false;
      this.logger.error(err.error.message, "Style Specification pom");
      this.toastService.showError(err.error.message);
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
      }
      else {
         this.logger.error(err.error.message, "Style Specification pom");
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.pomload = false;
    });
  }

  public async getStylePomSpecifications() {
    this.pomdata = [];
    if (typeof this.sizerange === "string") {
      this.sizerange.split(',').forEach(x => {
        this.pomdata.push(x);
      });
    } else {
      this.pomdata = this.sizerange;
    }

    console.log('this.sizerange', this.sizerange)

    let bb = ['poms', 'tolerance'];
    bb.forEach(y => {
      const index = this.pomdata.indexOf(y);
      if (index > -1) { // only splice array when item is found
        this.pomdata.splice(index, 1); // 2nd parameter means remove one item only
      }
    });

    console.log('bb', bb);

    bb.forEach(y => {
      this.pomdata.push(y);
    });

    console.log('pomdata', this.pomdata);
    this.specificationPomsloading = true;
    this.specificationPomsstartEndCount = this.specificationPomsstartCountList + "-" + this.specificationPomsendCountList;
    this.dataservice.getStylePomSpecifications(this.storageservice.getEditedStyleId(), this.specificationPomsstartEndCount).subscribe(response => {
      if (response.body['item2'] === "Success") {

        this.specificationPomsloading = false;

        this.logger.info(this.appStaticMsgs.styledetails.allstylespecifictionpomsloadedsuccessfully, "Style PomSpecifications");
        this.toastService.showSuccess('Added Successfully');
        this.dbspecificationPoms = response.body['item1']['stylePOMs'];
        this.specificationPomsRecordCount = response.body['item3'];
        if (response.body['item1']['stylePOMs']) {

          console.log('getStylePomSpecifications', this.dbspecificationPoms);

          for (let i = 0; i < this.dbspecificationPoms.length; i++) {
            this.dbspecificationPoms[i].poms = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbspecificationPoms[i].poms);
            this.dbspecificationPoms[i].tolerance = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbspecificationPoms[i].tolerance);
            this.unfilteredData.forEach(x => {
              this.dbspecificationPoms[i][x.columnName] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbspecificationPoms[i][x.columnName]);
            })
          }

          var gridView = {
            data: this.dbspecificationPoms,
            total: this.specificationPomsRecordCount,
          };

          this.specificationPoms = gridView;
        }
        else {
          this.specificationPoms = null;
        }
        //this.specificationForm = undefined;
        //this.StichesForm = undefined;
      }
      else {
        // this.error(response.body['item1']);
        this.toastService.showError(response.body['item1']);
        this.logger.error(response.body['item1'], "Style PomSpecifications");
        this.specificationPomsloading = false;
      }
    }, err => {
      this.logger.error(err.error.message, "Style PomSpecifications");
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.specificationPomsloading = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
        this.specificationPomsloading = false;
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        this.specificationPomsloading = false;
      }
      else {
        this.logger.error(err.error.message, "Style PomSpecifications");
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.specificationPomsloading = false;
    });
  }

  close() {
    this.dialogRef.close();
  }
}
