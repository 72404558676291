import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import Swal from 'sweetalert2';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { StorageService } from '../../../helpers/storage.service';
import { UserService } from '../../../helpers/user.service';
import { DataService } from '../../../services/data.service';
import { SharedService } from '../../../services/shared.service';
import { LogService } from '../../../shared/log.service';
import { Store, select } from "@ngrx/store";
import * as moodBoardActions from "../../../actions/moodboard.actions";
import { Moodboard } from '../../../models/moodboard';
import * as fromMoodboardReducer from "../../../reducers/moodboard.reducer";
import { Observable } from "rxjs";
import { map, skip, take, tap } from 'rxjs/operators';
import { Favourite } from '../../../models/color';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
declare var $: any;

@Component({
  selector: 'app-moodboardlibrary',
  templateUrl: './moodboardlibrary.component.html',
  styleUrls: ['./moodboardlibrary.component.css']
})
export class MoodboardlibraryComponent implements OnInit {
  @ViewChild('closeModal') closeModal;
  showLoader = true;
  moodBoardDataById: any;
  moodBoardTitle = '';
  showLoader1: boolean;
  gridContent = true;
  listBtn: boolean = true;
  cardBtn: boolean = false;
  btnstatus: boolean = false;
  btnstatus1: boolean = true;
  items = [];
  data: Observable<Moodboard[]>;
  notFound: boolean;
  notfoundImg: string;
  apiNoData: boolean;
  moodboardCards: Observable<Moodboard[]>;
  searchedKeyword: string;
  log: any[];

  paginationStartcount = 0;
  paginationEndcount = 10;
  public startCount = 0;
  public endCount = 10;
  public startCountList = 0;
  public endCountList = 10;
  public startEndCount = "";
  public isPageChanged = false;
  public isScrollDown = false;
  public isGetAllRecords = false;
  public totalRecordCount: any;
  loading: boolean;
  moodBoardlibraycards: any[];
  isMyMoodboards: boolean;
  moodboardViewMode = 'Created';


  sharedCopyCard: any[] = [];
  sharedCards: any[] = [];
  isMySuppliers = true;
  sharedNotFound: boolean;
  isSharedGetAllRecords = false;
  isSharedScrollDown = false;
  totalSharedRecordCount: any;
  sharedMsg: string;
  sharedEndCount = 10;
  sharedStartCount = 0;
  sharedStartEndCount = "";
  checkSharedStatusData = false;
  mymoodboard = true;
  sharedmoodboard: boolean;
  isMymoodboards: boolean;
  isTitleError = false;
  staticMsgs:any;

  constructor(
    private store: Store<any>,
    private dataService: DataService,
    private router: Router,
    private storageService: StorageService,
    private aesdecryptservice: AESDecryptService,
    private sharedService: SharedService,
    private userservice: UserService, private logger: LogService) { }

  options = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    window.addEventListener('scroll', this.scrollEvent, true);
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    $(document).ready(function () {
      $('.selectpicker').selectpicker();
      $("[data-toggle='popover']").popover({ html: true });
    });
    this.getAllMoodBoards();
    this.sharedService.sendClickEvent();
  }

  titleError(){
    if (this.moodBoardTitle.length > 0 && this.moodBoardTitle.length <= 50) {
      this.isTitleError = false;
    } else {
      this.isTitleError = true;
    }
  }

  onEditMood(id) {
    this.storageService.storeEditedMoodBoard(id);
    this.dataService.AddClientLog();
    this.router.navigate(['/moodboard']);
  }

  moodboardView(moodboard) {
    this.isMyMoodboards = (moodboard === 'Created') ? true : false
    this.moodboardCards = null;
    this.notFound = false;
    this.moodboardViewMode = moodboard;
    if (this.moodboardViewMode == 'Shared') {
      this.mymoodboard = false;
      this.sharedmoodboard = true;
    }
    else {
      this.mymoodboard = true;
      this.sharedmoodboard = false;
    }
    if (this.gridContent) {
      this.moodboardViewMode == 'Shared' ? this.loadSharedData() : this.getAllMoodBoards();
    }
  }

  cardView() {
    this.gridContent = true;
    this.isMymoodboards = true;
    this.btnstatus = !this.btnstatus;
    this.btnstatus1 = !this.btnstatus1;
    this.isGetAllRecords = false;
    this.startCount = this.paginationStartcount;
    this.endCount = this.paginationEndcount;
    this.getAllMoodBoards();
  }

  /* Scroll event for lazy loading data */
  scrollEvent = (event: any): void => {
    if (this.gridContent && this.router.url.includes('moodboardlibrary')) {
        if (event.target.scrollTop >= (event.target.scrollHeight - event.target.clientHeight - 2)) {
          if (this.moodboardViewMode == 'Created') {
            if (!this.isGetAllRecords) {
              this.isScrollDown = false;
              this.startCount = this.startCount + this.paginationEndcount;
              this.endCount = this.endCount + this.paginationEndcount;
              this.getAllMoodBoards();            }
          } else {
            if (!this.isSharedGetAllRecords) {
              this.isScrollDown = false;
              this.sharedStartCount = this.sharedStartCount + this.paginationEndcount;
              this.sharedEndCount = this.sharedEndCount + this.paginationEndcount;
              this.loadSharedData();
            }
          }
        }
    }
  }

  /* To get all the shared moodboard data */
  loadSharedData(){
    if (!this.isSharedGetAllRecords) {
      this.sharedStartEndCount = this.sharedStartCount + "-" + this.sharedEndCount;
      this.dataService.getAllMoodBoards(this.sharedStartEndCount, this.moodboardViewMode).subscribe(response => {
        if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
          this.logger.info(this.staticMsgs.moodboardLibrary.AllMoodboardLoadSuccess,this.staticMsgs.moodboardLibrary.MoodboardLibrary1);
          this.showLoader = false;
          response['body']['item1'].forEach(x => {
            if (!x.deleteStatus) {
              x.thumbnail.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnail.name)
              x.thumbnail.org = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnail.org)
              x.thumbnail.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnail.thumbnail)
              x.title = this.aesdecryptservice.decryptData(this.dataService.Key, x.title)
          }
            this.sharedCards.push(x);
            this.sharedCards = this.sharedCards.filter((item, index) => this.sharedCards.indexOf(item) === index)
          });
          this.sharedCopyCard = [];
          this.sharedCopyCard = this.sharedCards;
          this.totalSharedRecordCount = response['body']['item3'];
          if (this.sharedCards.length > 0) {
            this.sharedNotFound = false;
          } else {
            this.sharedNotFound = true;
          }
          if (this.sharedCards.length == this.totalSharedRecordCount) {
            this.isSharedGetAllRecords = true;
          }
          else {
            this.isSharedScrollDown = true;
          }
        } else {
          this.isSharedScrollDown = true;
          if (response['body']['item2'] !== 'Success') {
            this.error("Failed:" + response['body']['item1'])
            this.logger.error(response['body']['item1'], this.staticMsgs.moodboardLibrary.ColorLibrary);
          }
          this.showLoader = false;
          this.sharedNotFound = true;
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, this.staticMsgs.moodboardLibrary.ColorLibrary);
        this.showLoader = false;
      })
    }
  }

  /* To get all the moodboards data */
  getAllMoodBoards() {
    this.showLoader = false;
    this.startEndCount = this.startCount + "-" + this.endCount;
    this.store.dispatch(new moodBoardActions.LoadMoodBoards(this.startEndCount, this.moodboardViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((moodBoardData) => {
      if (moodBoardData.moodboardState.error === "") {
        if (moodBoardData.moodboardState.message['item2'] === 'Success') {
          if (moodBoardData.moodboardState.message['item1'].length > 0) {
            this.logger.info(this.staticMsgs.moodboardLibrary.AllMoodboardLoadSuccess, this.staticMsgs.moodboardLibrary.MoodboardLibrary1);
            this.notFound = false;
            this.apiNoData = false;
            this.moodboardCards = this.store.pipe(select(fromMoodboardReducer.getMoodBoards));
            this.moodBoardlibraycards = moodBoardData.moodboardState.message['item1'];
            this.data = this.moodboardCards;
            this.totalRecordCount = moodBoardData.moodboardState.totalRecords;
            if (moodBoardData.moodboardState.ids.length == this.totalRecordCount) {

              this.isGetAllRecords = true;
            }
            else {
              this.isScrollDown = true;
            }
          } else {
            this.notFound = true;
            this.apiNoData = true;
            this.showLoader = false;
          }
        } else {
          this.error(moodBoardData.moodboardState.message['item1'])
        }

      } else {
        this.getError(moodBoardData.moodboardState.error);
      }
    })
  }

  /* To display session expired msg as sweet alert */
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.leftBar.SessionExpired,
      html: this.staticMsgs.leftBar.loginAgain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        //console.log('Session Timedout.')
        this.userservice.logout();
        return;
      }
    })

  }

  /* To edit the moodboard by moodboard id */
  editMoodBoard(id: any) {
    this.dataService.AddClientLog();
    let title = this.moodBoardlibraycards.find(x => x.id === id).title;
    this.storageService.storeEditedMoodBoard({ 'moodBoardId': id, moodBoardTitle: title });
    this.router.navigate(['/moodboard']);
  }

  /* To close the moodboard title popup */
  closeTitle() {
    this.moodBoardTitle = '';
    this.isTitleError = false;
  }

  /* Save moodboard title & redirect to moodboard page */
  saveTitle() {
    if (this.moodBoardTitle.length > 0 && this.moodBoardTitle.length <= 50) {
      const title = this.moodBoardTitle;
      this.closeModal.nativeElement.click();
      this.dataService.AddClientLog();
      this.storageService.storeEditedMoodBoard({ 'moodBoardId': 'new', moodBoardTitle: title });
      this.router.navigate(['/moodboard']);
      this.isTitleError = false;
    } else if(this.moodBoardTitle.length <= 0){
      this.isTitleError = true;
    }
  }

  /* Confirmation alert before deleting the moodboard */
  delete(id: any) {
    Swal.fire({
      title: this.staticMsgs.moodboardLibrary.deleteMoodboard,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.deleteMoodBoard(id);
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  /* Api call to delete the moodboard */
  deleteMoodBoard(id: any) {
    this.store.dispatch(new moodBoardActions.DeleteMoodBoard(id));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((moodBoardData) => {
      if (moodBoardData.moodboardState.error === "") {
        this.success(this.staticMsgs.moodboardLibrary.deleteSuccess);
        this.showLoader = false;
      } else {
        this.getError(moodBoardData.moodboardState.error);
        this.showLoader = false;
      }
    })
  }

  /* Search the moodboard data */
  // searchMoodBoard(event: any) {
  //   if (event.target.value) {
  //     this.moodboardCards = this.moodboardCards.filter(x => x.title.toLowerCase().includes(event.target.value.toLowerCase()));
  //   } else {
  //     this.moodboardCards = this.data;
  //   }
  // }

  /* If any error from api response */
  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.userservice.logout();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);
    }
    this.showLoader = false;
  }

  /* To display success msg as sweet alert */
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  /* To display error msg as sweet alert */
  error(message: string) {
    Swal.fire({
      icon: this.staticMsgs.usermanagement.error,
      title: this.staticMsgs.userregister.Oops,
      text: message
    })
  }

  /* To add a moodboard as favourite */
  AddToFavorite(e) {
    this.showLoader = true;
    let selected = e.target.id.replace("checkbox", "");
    if (selected != '' && e.target.checked == true) {
      let data: Favourite[] = [];
      data.push(new Favourite(selected));
      this.dataService.bulkAddFavourites(data, "Moodboard").subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.success(this.staticMsgs.moodboardLibrary.AddedToFavourites);
            this.logger.info(this.staticMsgs.moodboardLibrary.AddedToFavourites, this.staticMsgs.moodboardLibrary.MoodboardLibrary1);
          }
          else {
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], this.staticMsgs.moodboardLibrary.MoodboardLibrary1);
            this.showLoader = false;
          }
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, this.staticMsgs.moodboardLibrary.MoodboardLibrary1);
        this.showLoader = false;
      });
    }
    else if (selected != '' && e.target.checked == false) {
      let data: string[] = [];
      data.push(selected);
      this.dataService.bulkDeleteFavourites(data, "Moodboard").subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.success(this.staticMsgs.moodboardLibrary.RemovedfromFv);
            this.logger.info(this.staticMsgs.moodboardLibrary.RemovedfromFv,this.staticMsgs.moodboardLibrary.MoodboardLibrary1);
          }
          else {
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], this.staticMsgs.moodboardLibrary.MoodboardLibrary1);
            this.showLoader = false;
          }
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message,this.staticMsgs.moodboardLibrary.MoodboardLibrary1);
        this.showLoader = false;
      });
    }
  }

 }
