/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreateStyleDiscussionInput = {
  id?: string | null;
  styleId: string;
  userEmailId: string;
  userName: string;
  msgBody?: string | null;
  documentId?: string | null;
  createdAt?: string | null;
};

export type ModelStyleDiscussionConditionInput = {
  styleId?: ModelStringInput | null;
  userEmailId?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  msgBody?: ModelStringInput | null;
  documentId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelStyleDiscussionConditionInput | null> | null;
  or?: Array<ModelStyleDiscussionConditionInput | null> | null;
  not?: ModelStyleDiscussionConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type UpdateStyleDiscussionInput = {
  id: string;
  styleId?: string | null;
  userEmailId?: string | null;
  userName?: string | null;
  msgBody?: string | null;
  documentId?: string | null;
  createdAt?: string | null;
};

export type DeleteStyleDiscussionInput = {
  id?: string | null;
};

export type ModelStyleDiscussionFilterInput = {
  id?: ModelIDInput | null;
  styleId?: ModelStringInput | null;
  userEmailId?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  msgBody?: ModelStringInput | null;
  documentId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelStyleDiscussionFilterInput | null> | null;
  or?: Array<ModelStyleDiscussionFilterInput | null> | null;
  not?: ModelStyleDiscussionFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type CreateStyleDiscussionMutation = {
  __typename: "StyleDiscussion";
  id: string;
  styleId: string;
  userEmailId: string;
  userName: string;
  msgBody: string | null;
  documentId?: string | null;
  createdAt: string | null;
};

export type UpdateStyleDiscussionMutation = {
  __typename: "StyleDiscussion";
  id: string;
  styleId: string;
  userEmailId: string;
  userName: string;
  msgBody: string | null;
  documentId?: string | null;
  createdAt: string | null;
};

export type DeleteStyleDiscussionMutation = {
  __typename: "StyleDiscussion";
  id: string;
  styleId: string;
  userEmailId: string;
  userName: string;
  msgBody: string | null;
  documentId?: string | null;
  createdAt: string | null;
};

export type GetStyleDiscussionQuery = {
  __typename: "StyleDiscussion";
  id: string;
  styleId: string;
  userEmailId: string;
  userName: string;
  msgBody: string | null;
  documentId?: string | null;
  createdAt: string | null;
};

export type ListStyleDiscussionsQuery = {
  __typename: "ModelStyleDiscussionConnection";
  items: Array<{
    __typename: "StyleDiscussion";
    id: string;
    styleId: string;
    userEmailId: string;
    userName: string;
    msgBody: string | null;
    documentId?: string | null;
    createdAt: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateStyleDiscussionSubscription = {
  __typename: "StyleDiscussion";
  id: string;
  styleId: string;
  userEmailId: string;
  userName: string;
  msgBody: string | null;
  documentId?: string | null;
  createdAt: string | null;
};

export type OnUpdateStyleDiscussionSubscription = {
  __typename: "StyleDiscussion";
  id: string;
  styleId: string;
  userEmailId: string;
  userName: string;
  msgBody: string | null;
  documentId?: string | null;
  createdAt: string | null;
};

export type OnDeleteStyleDiscussionSubscription = {
  __typename: "StyleDiscussion";
  id: string;
  styleId: string;
  userEmailId: string;
  userName: string;
  msgBody: string | null;
  documentId?: string | null;
  createdAt: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateStyleDiscussion(
    input: CreateStyleDiscussionInput,
    condition?: ModelStyleDiscussionConditionInput
  ): Promise<CreateStyleDiscussionMutation> {
    const statement = `mutation CreateStyleDiscussion($input: CreateStyleDiscussionInput!, $condition: ModelStyleDiscussionConditionInput) {
        createStyleDiscussion(input: $input, condition: $condition) {
          __typename
          id
          styleId
          userEmailId
          userName
          msgBody
          documentId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStyleDiscussionMutation>response.data.createStyleDiscussion;
  }
  async UpdateStyleDiscussion(
    input: UpdateStyleDiscussionInput,
    condition?: ModelStyleDiscussionConditionInput
  ): Promise<UpdateStyleDiscussionMutation> {
    const statement = `mutation UpdateStyleDiscussion($input: UpdateStyleDiscussionInput!, $condition: ModelStyleDiscussionConditionInput) {
        updateStyleDiscussion(input: $input, condition: $condition) {
          __typename
          id
          styleId
          userEmailId
          userName
          msgBody
          documentId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStyleDiscussionMutation>response.data.updateStyleDiscussion;
  }
  async DeleteStyleDiscussion(
    input: DeleteStyleDiscussionInput,
    condition?: ModelStyleDiscussionConditionInput
  ): Promise<DeleteStyleDiscussionMutation> {
    const statement = `mutation DeleteStyleDiscussion($input: DeleteStyleDiscussionInput!, $condition: ModelStyleDiscussionConditionInput) {
        deleteStyleDiscussion(input: $input, condition: $condition) {
          __typename
          id
          styleId
          userEmailId
          userName
          msgBody
          documentId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStyleDiscussionMutation>response.data.deleteStyleDiscussion;
  }
  async GetStyleDiscussion(id: string): Promise<GetStyleDiscussionQuery> {
    const statement = `query GetStyleDiscussion($id: ID!) {
        getStyleDiscussion(id: $id) {
          __typename
          id
          styleId
          userEmailId
          userName
          msgBody
          documentId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStyleDiscussionQuery>response.data.getStyleDiscussion;
  }
  async ListStyleDiscussions(
    filter?: ModelStyleDiscussionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListStyleDiscussionsQuery> {
    const statement = `query ListStyleDiscussions($filter: ModelStyleDiscussionFilterInput, $limit: Int, $nextToken: String) {
        listStyleDiscussions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            styleId
            userEmailId
            userName
            msgBody
            documentId
            createdAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    limit = 100000;
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    console.log('gqlAPIServiceArguments',gqlAPIServiceArguments);
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStyleDiscussionsQuery>response.data.listStyleDiscussions;
  }
  OnCreateStyleDiscussionListener: Observable<
    OnCreateStyleDiscussionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateStyleDiscussion {
        onCreateStyleDiscussion {
          __typename
          id
          styleId
          userEmailId
          userName
          msgBody
          documentId
          documentId
          createdAt
        }
      }`
    )
  ) as Observable<OnCreateStyleDiscussionSubscription>;

  OnUpdateStyleDiscussionListener: Observable<
    OnUpdateStyleDiscussionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateStyleDiscussion {
        onUpdateStyleDiscussion {
          __typename
          id
          styleId
          userEmailId
          userName
          msgBody
          documentId
          createdAt
        }
      }`
    )
  ) as Observable<OnUpdateStyleDiscussionSubscription>;

  OnDeleteStyleDiscussionListener: Observable<
    OnDeleteStyleDiscussionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteStyleDiscussion {
        onDeleteStyleDiscussion {
          __typename
          id
          styleId
          userEmailId
          userName
          msgBody
          documentId
          createdAt
        }
      }`
    )
  ) as Observable<OnDeleteStyleDiscussionSubscription>;
}
