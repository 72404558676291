<!-- Customizer Sidebar -->
<aside class="dt-customizer dt-drawer position-right" [class.open]="classApplied">
  <app-loader [showLoader]="showLoader"></app-loader>
  <!-- Item Detail Section -->
  <div class="dt-customizer__inner" *ngIf="itemDetailsSection">

    <!-- Customizer Header -->
    <div class="dt-customizer__header">

      <!-- Customizer Title -->
      <div class="dt-customizer__title">
        <h4 class="mb-0">{{staticmsgs.discussion.itemdetails}}</h4>
      </div>
      <!-- /customizer title -->
      <!-- Close Button -->
      <button type="button" class="close" (click)="itemDetailsClose()">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- /close button -->

    </div>
    <!-- /customizer header -->
    <!-- Customizer Body -->
    <div class="dt-customizer__body ps-custom-scrollbar">
      <!-- Customizer Body Inner  -->
      <div class="dt-customizer__body-inner" *ngIf="isViewingMode">

        <!-- Section -->
        <section>
          <div class="col-sm-12 text-center">
            <img *ngIf="editedStyleData[0].styleImages[0]" src='{{editedStyleData[0].styleImages[0].image.thumbnail}}' class="img-fluid box-shadow">
            <!-- <img src='{{editedStyleData[0].styleImages[0].image.thumbnail}}' class="img-fluid box-shadow"> -->
            <h5 class="mt-5 mb-1">{{staticmsgs.discussion.stylename}}</h5>
            <p class="f-12">{{editedStyleData[0].styleName}}</p>

          </div>
        </section>
        <!-- /section -->
        <!-- Section -->
        <section class="d-none d-lg-block text-dark w-70 mx-auto">
          <h5 class="underline">{{staticmsgs.discussion.creationinformation}}</h5>

          <div class="dt-avatar-wrapper">
            <div class="mbm-1">
              <img *ngIf="createdBythumbnail" style="height:30px;width:30px;" src='{{createdBythumbnail}}' class="img-fluid box-shadow">
              <div class="dt-avatar-info">
                <h6>{{editedStyleData[0].createdBy.fullName}}</h6>
              </div>
            </div>
            <div class="">
              <i class="icon icon-calendar mr-2"></i>
              <div class="dt-avatar-info">
                <h6>{{editedStyleData[0].createdOn}}</h6>
              </div>
            </div>
          </div>

        </section>
        <!-- /section -->
        <!-- Section -->
        <section class="d-none d-lg-block text-dark w-70 mx-auto">
          <h5 class="underline">{{staticmsgs.discussion.modificationinformation}}</h5>

          <div class="dt-avatar-wrapper">
            <div class="mbm-1">
              <img *ngIf="modifiedBythumbnail" style="height:30px;width:30px;" src='{{modifiedBythumbnail}}' class="img-fluid box-shadow">
              <div class="dt-avatar-info">
                <h6>{{editedStyleData[0].modifiedBy.fullName}}</h6>
              </div>
            </div>
            <div class="">
              <i class="icon icon-calendar mr-2"></i>
              <div class="dt-avatar-info">
                <!-- <h6>24-12-2020 : 12:33 PM{{editedStyleData.modifiedOn.value}}</h6>-->
                <h6>{{editedStyleData[0].modifiedOn}}</h6>
              </div>
            </div>
          </div>

        </section>
        <!-- /section -->


      </div>
      <!-- /customizer body inner -->
    </div>
    <!-- /customizer body -->

  </div>
  <!-- Item Details Section End-->



  <!--Timeline Section-->
  <div class="dt-customizer__inner" *ngIf="timeLineSection">

    <!-- Customizer Header -->
    <div class="dt-customizer__header">

      <!-- Customizer Title -->
      <div class="dt-customizer__title">
        <h4 class="mb-0">{{staticmsgs.discussion.timeline}}</h4>
      </div>
      <!-- /customizer title -->
      <!-- Close Button -->
      <button type="button" class="close" (click)="timeClose()">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- /close button -->

    </div>
    <!-- /customizer header -->
    <!-- Customizer Body -->
    <div class="dt-customizer__body ps-custom-scrollbar">
      <!-- Customizer Body Inner  -->
      <div class="dt-customizer__body-inner">

        <!-- Section -->
        <section>
          <div class="dt-widget__item">

            <!-- Widget Image -->
            <div class="dt-widget__img text-round-green">
              <i class="icon icon-user-add text-green"></i>
            </div>
            <!-- /widget image -->
            <!-- Widget Info -->
            <div class="dt-widget__info">
              <!-- <a href="javascript:void(0)" class="dt-widget__title text-truncate">
                MATIE4 Approved by Megan Marshall.
              </a> -->
              <p class="dt-widget__title ">
               {{staticmsgs.discussion.matie4approvedbymeganmarshall}}
              </p>
              <p class="dt-widget__subtitle text-light-gray">
                {{staticmsgs.discussion.hoursago}}
              </p>
            </div>
            <!-- /widget info -->
          </div>

          <div class="dt-widget__item">

            <!-- Widget Image -->
            <div class="dt-widget__img text-round-blue">
              <i class="icon icon-news text-blue"></i>
            </div>
            <!-- /widget image -->
            <!-- Widget Info -->
            <div class="dt-widget__info">
              <!-- <a href="javascript:void(0)" class="dt-widget__title text-truncate">
                MATIE4 Approved by Megan Marshall.
              </a> -->
              <p class="dt-widget__title ">
  {{staticmsgs.discussion.matie4sentforapprovedbyjohndoe}}
              </p>
              <p class="dt-widget__subtitle text-light-gray">
               {{staticmsgs.discussion.hoursAgo}}
              </p>
            </div>
            <!-- /widget info -->
          </div>

          <div class="dt-widget__item">

            <!-- Widget Image -->
            <div class="dt-widget__img text-round-red">
              <i class="fa fa-unlock-alt text-red" aria-hidden="true"></i>
            </div>
            <!-- /widget image -->
            <!-- Widget Info -->
            <div class="dt-widget__info">
              <!-- <a href="javascript:void(0)" class="dt-widget__title text-truncate">
                MATIE4 Approved by Megan Marshall.
              </a> -->
              <p class="dt-widget__title ">
                {{staticmsgs.discussion.johnnickrsonchangedstatusinreview}}
              </p>
              <p class="dt-widget__subtitle text-light-gray">
                {{staticmsgs.discussion.daysago}}
              </p>
            </div>
            <!-- /widget info -->
          </div>

        </section>
        <!-- /section -->

      </div>
      <!-- /customizer body inner -->
    </div>
    <!-- /customizer body -->

  </div>
  <!-- Timeline Section End-->



  <!--Chat Section-->
  <div class="dt-customizer__inner" *ngIf="chatSection">

    <!-- Customizer Header -->
    <div class="dt-customizer__header">

      <!-- Customizer Title -->
      <div class="dt-customizer__title">
        <h4 class="mb-0">{{staticmsgs.discussion.discussion}}</h4>
      </div>
      <!-- /customizer title -->
      <!-- Close Button -->
      <button type="button" class="close" (click)="stylesDiscussionClose()">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- /close button -->


    </div>
    <!-- /customizer header -->
    <!-- Section -->
    <section class="chat-section-bg">
      <ul class="dt-list dt-team-list dt-team-list-sm">

        <div *ngFor="let arr of sharedUsers; let i = index">
          <li *ngIf="3>=i">
            <div *ngIf="!arr.user[0].pictureID">
              <a  class="avatar-group" placement="bottom" ngbTooltip="{{arr.user[0].fullName}}">
                <ngx-avatar class="avatar pull-up" size="25" name="{{arr.user[0].fullName}}" ></ngx-avatar>
              </a>
              <!-- <div *ngIf="arr.isOnline" class='active-status-circle' ngbTooltip="Online">
              </div>
              <div *ngIf="!arr.isOnline" class='deactive-status-circle' ngbTooltip="Offline">
              </div>               -->
            </div>
            <div *ngIf="arr.user[0].pictureID" class="icon-container">
              <a  class="dt-avatar size-25" placement="bottom" ngbTooltip="{{arr.user[0].fullName}}">
                <img [src]="arr.user[0].picture.thumbnail" />
              </a>
              <!-- <div *ngIf="arr.isOnline" class='active-status-circle' ngbTooltip="Online">
              </div>
              <div *ngIf="!arr.isOnline" class='deactive-status-circle' ngbTooltip="Offline">
              </div> -->
            </div>
          </li>
        </div>
        <li>
          <a *ngIf="sharedUsers.length>=5" class="dt-avatar size-25" placement="bottom" ngbTooltip="Total Shared Users">
            <span class="dt-dot-o">
              {{sharedUsers.length}}
            </span>
          </a>
        </li>

        <li>
          <!-- Avatar -->
          <a class="add-user-data" placement="bottom" ngbTooltip="Add Collaborator">
            <span class="">
              <i class="icon icon-plus text-dark" (click)="getSharedData()" data-toggle="modal" data-target="#long-modal"></i>
            </span>
          </a>
          <!-- /avatar -->
        </li>
      </ul>
    </section>

    <!-- Customizer Body -->
    <div #scrollBottom class="dt-customizer__body">
      <!-- Customizer Body Inner  -->
      <div  class="dt-customizer__body-inner chat-p">
        <div  class="dt-chat__conversation pl-3 pr-3">
          <div *ngFor="let chat of allChats">
            <p *ngIf="chat.isSamedate" class="chat-date"> {{chat.logdate}}</p>

            <!-- Msg Send -->
            <div *ngIf="LoggedinuserDetails.attributes.email === chat.userEmailId" style="text-align:right">
              <div class="dt-chat__item reply">
                <div>
                  <gx-avatar [user]="userservice.currentUser" class="size-30"></gx-avatar>
                  <!-- <div *ngIf="chat.isOnline" class='right-active-status-circle' ngbTooltip="Online">
                  </div>
                  <div *ngIf="!chat.isOnline" class='right-deactive-status-circle' ngbTooltip="Offline">
                  </div> -->
                </div>
                <div class="dt-chat__message-wrapper">
                  <div class="dt-chat__message">
                    <p *ngIf="chat.msgBody"> {{chat.msgBody }}</p>
                    <!-- <p *ngIf="!chat.msgBody"><a href="https://s3.amazonaws.com/fitoshrishifileupload/{{chat.documentId}}?AWSAccessKeyId=AKIAW7NQSUNQQNSQWMHO&Expires=1671008408&Signature=WDLMRGjZumVKaZBNRWgmYMV920U%3D" target="_blank">File</a></p> -->

                      <!-- <img src="{{chat.documentId}}"  style="height: 100px;width:100px;"> -->
                      <div *ngIf="!chat.msgBody">
                        <div *ngIf="chat.documentExt == 'pdf' || chat.documentExt == 'docx' || chat.documentExt == 'ppt' || chat.documentExt == 'xlsx' || chat.documentExt == 'csv' || chat.documentExt == 'zip' || chat.documentExt == '7z' || chat.documentExt == 'rar'">
                          <a [href]="chat.documentId" target="_blank">
                            <img src="../../../assets/images/pdf.png" *ngIf="chat.documentExt == 'pdf'" class="documentImage">
                            <img src="../../../assets/images/word.png" *ngIf="chat.documentExt == 'docx'" class="documentImage">
                            <img src="../../../assets/images/xlsx.png" *ngIf="chat.documentExt == 'xlsx' || chat.documentExt == 'csv'" class="documentImage">
                            <img src="../../../assets/images/ppt.png" *ngIf="chat.documentExt == 'ppt'" class="documentImage">
                            <img src="../../../assets/images/zip.png" *ngIf="chat.documentExt == 'zip' || chat.documentExt == '7z' || chat.documentExt == 'rar'" class="documentImage">
                          {{chat.documentName}}</a>
                        </div>
                        <div *ngIf="chat.documentExt == 'jpeg' || chat.documentExt == 'jpg' || chat.documentExt == 'png' || chat.documentExt == 'gif'"
                        [ngStyle]="{'background-image': 'url(' + chat.documentId + ')','width': '100px','height': '100px','background-position': 'center','background-repeat' : 'no-repea','background-size': 'cover'}" (click)="showLightbox(chat)"></div>
                      </div>


                  </div>
                  <span style="font-size: x-small;"> {{chat.createdAt | date:'hh:mm a'}}</span>
                </div>
              </div>
            </div>

            <!-- Msg Reciver -->
            <div *ngIf="LoggedinuserDetails.attributes.email !== chat.userEmailId" style="text-align:left">
              <div class="dt-chat__item">
                <div class="icon-container" >
                  <img *ngIf="chat.thumbnail != ''" class="dt-avatar size-30" src="{{chat.thumbnail}}">
                  <ngx-avatar *ngIf="chat.thumbnail == ''" size="20" name="{{chat.userName}}" ></ngx-avatar>
                  <!-- <div *ngIf="chat.isOnline" class='left-active-status-circle' ngbTooltip="Online">
                  </div>
                  <div *ngIf="!chat.isOnline" class='left-deactive-status-circle' ngbTooltip="Offline">
                  </div> -->
                </div>
                <div class="dt-chat__message-wrapper">
                  <div class="dt-chat__message">
                    <p><span class="userName">{{chat.userName}}</span><br>
                      <span *ngIf="chat.msgBody">{{ chat.msgBody }}</span>
                      <span *ngIf="!chat.msgBody">
                      <span *ngIf="chat.documentExt == 'pdf' || chat.documentExt == 'docx' || chat.documentExt == 'ppt' || chat.documentExt == 'xlsx' || chat.documentExt == 'csv' || chat.documentExt == 'zip' || chat.documentExt == '7z' || chat.documentExt == 'rar'">
                        <a [href]="chat.documentId" target="_blank">
                          <img src="../../../assets/images/pdf.png" *ngIf="chat.documentExt == 'pdf'" class="documentImage">
                          <img src="../../../assets/images/word.png" *ngIf="chat.documentExt == 'docx'" class="documentImage">
                          <img src="../../../assets/images/xlsx.png" *ngIf="chat.documentExt == 'xlsx' || chat.documentExt == 'csv'" class="documentImage">
                          <img src="../../../assets/images/ppt.png" *ngIf="chat.documentExt == 'ppt'" class="documentImage">
                          <img src="../../../assets/images/zip.png" *ngIf="chat.documentExt == 'zip' || chat.documentExt == '7z' || chat.documentExt == 'rar'" class="documentImage">
                          {{chat.documentName}}
                        </a>
                      </span>
                      <span *ngIf="chat.documentExt == 'jpeg' || chat.documentExt == 'jpg' || chat.documentExt == 'png' || chat.documentExt == 'gif'"
                      [ngStyle]="{'background-image': 'url(' + chat.documentId + ')','width': '100px','height': '100px','background-position': 'center','background-repeat' : 'no-repea','background-size': 'cover'}" (click)="showLightbox(chat)"></span>
                      </span>
                    </p>
                  </div>
                  <span style="font-size: x-small;"> {{chat.createdAt | date:'hh:mm a'}}</span>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="allChats.length == 0">
            <h3 class="mt-4 text-center">{{staticmsgs.discussion.nodiscussionavailableinthisstyle}}</h3>
          </div>
        </div>

        <!-- <ng-select [(ngModel)]="selectedCar">
          <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
       </ng-select> -->

        <div class="add-comment-box">
          <!-- <div class="action-tools">
            <div class="dt-fab-btn dt-attachment-btn size-30">
              <input type="file"> <i class="icon icon-attach-v"></i>
            </div>
          </div> -->
          <div class="">
            <div class="media-body">
              <!-- <textarea class="shadow-none bg-focus ml-2" rows="2" (keyup.enter)="sendMsg(msgBody,'')" placeholder="Write your comment..." #msgBody></textarea>
              <button class="btn btn-secondary dt-fab-btn ft-send-btn size-30 mt-2 float-right" (click)="sendMsg(msgBody,'')" type="button">
                <i class="icon icon-send-new icon-sm"></i>
              </button>

              <div class="image-upload">
                <label for="file-input">
                    <img src="../../../assets/images/uploadIcon.png"/>
                </label>
                <input id="file-input" type='file' (change)="onChangePatternImg($event)">
            </div> -->

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <div class="dt-fab-btn dt-attachment-btn size-30">
                    <input type='file' (change)="onChangePatternImg($event)">
                    <i class="icon icon-attach-v"></i>
                  </div>
            </span>
              </div>
              <input type="text" class="form-control" (keyup.enter)="sendMsg(msgBody,'')" placeholder="Write your comment..." #msgBody style="height: 43px;">
              <div class="input-group-append">
                <span class="input-group-text send_icon" (click)="sendMsg(msgBody,'')"><i class="icon icon-send-new icon-xl"></i></span>

              </div>
            </div>

            </div>



            <!-- <i class="icon icon-attach-v icon-sm"></i> -->
          </div>
        </div>
        <!-- /section -->

      </div>
      <!-- /customizer body inner -->
    </div>
    <!-- /customizer body -->

  </div>
  <!-- Chat Section End-->


</aside>
<!-- /customizer sidebar -->


