<app-loader [showLoader]="showLoader"></app-loader>
<app-header *ngIf="userLoggedIn"></app-header>
<div class="dt-root" style="opacity: 1;">
  <div class="dt-root__inner">
      <div class="dt-login--container">

<!-- Login Content -->
<div class="dt-login__content-wrapper">

  <!-- Login Background Section -->
  <div class="dt-login__bg-section dt-slider">

    <div class='slide1'></div>
    <div class='slide2'></div>
    <div class='slide3'></div>


      <div class="dt-login__bg-content">
          <!-- Login Title -->
          <h1 class="dt-login__title">{{appStaticMsg.login.Login}}</h1>
          <!-- /login title -->

          <p class="f-22">{{appStaticMsg.login.creativity}}</p>
      </div>


      <!-- Brand logo -->
      <div class="dt-login__logo text-center">
          <a class="dt-brand__logo-link" href="javascript:void(0)">
              <img class="dt-brand__logo-img" alt="Fitos">
          </a>
      </div>
      <!-- /brand logo -->

  </div>
  <!-- /login background section -->

  <!-- Login Content Section -->
  <div class="dt-login__content">

      <!-- Login Content Inner -->
      <div class="dt-login__content-inner">

          <!-- Form -->
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

            <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div>
            <div class="alert alert-danger" *ngIf="userNotVerified">{{appStaticMsg.login.accountVerify}} <a
                href="javascript:;" (click)="verifyUser()">{{appStaticMsg.login.Clickhere}}</a> {{appStaticMsg.login.toverify}} </div>
            <div class="alert alert-{{alertMessage}}" *ngIf="codeSent">{{appStaticMsg.login.CodesentAgain}}</div>

              <!-- Form Group -->
              <div class="form-group">
                  <label class="sr-only" for="username">{{appStaticMsg.common.email}}<span class="red_icon">*</span></label>
                  <input type="text" formControlName="username" id="username" class="form-control" placeholder="Enter Email" required/>
                  <div *ngIf="(submitted && this.f.username.errors) ||(this.f.username.invalid && (this.f.username.dirty || this.f.username.touched))">
                    <div *ngIf="this.f.username.errors.required" class="red">
                    {{appStaticMsg.common.email+appStaticMsg.common.required}}
                    </div>
                  </div>
              </div>
              <!-- /form group -->

              <!-- Form Group -->
              <div class="form-group">
                  <label class="sr-only" for="password">{{appStaticMsg.common.password}}<span class="red_icon">*</span></label>
                  <input type="password" formControlName="password" id="password" class="form-control" placeholder="Enter Password" required/>
                  <span toggle="#password" class="fa fa-eye-slash field-icon toggle-password"></span>
                  <div *ngIf="(submitted && this.f.password.errors) ||(this.f.password.invalid && (this.f.password.dirty || this.f.password.touched))">
                    <div *ngIf="this.f.password.errors.required" class="red">
                      {{appStaticMsg.common.password+appStaticMsg.common.required}}
                    </div>
                  </div>
              </div>
              <!-- /form group -->

              <!-- Form Group -->
              <div class="dt-checkbox d-block mb-6">
                  <input type="checkbox" value="1" formControlName="rememberme" id="rememberme">
                  <label class="dt-checkbox-content pl-2" for="rememberme">
                   {{appStaticMsg.login.rememberMe}}
                  </label>
              </div>
              <!-- /form group -->

              <!-- Form Group -->
              <div class="form-group dt-flex">
                  <button type="submit" class="btn btn-primary">{{appStaticMsg.login.Login}}</button>
                  <!-- <span class="d-inline-block m-a">Or connect with
                  </span> -->
                      <!-- List -->
                  <!-- <ul class="dt-list dt-list-sm dt-list-cm-0 ml-auto m-a">
                    <li class="dt-list__item"> -->
                        <!-- Fab Button -->
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary dt-fab-btn size-30">
                            <i class="icon icon-facebook icon-xl"></i>
                        </a> -->
                        <!-- /fab button -->
                    <!-- </li>

                    <li class="dt-list__item"> -->
                        <!-- Fab Button -->
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary dt-fab-btn size-30">
                            <i class="icon icon-google-plus icon-xl"></i>
                        </a> -->
                        <!-- /fab button -->
                    <!-- </li>

                    <li class="dt-list__item"> -->
                        <!-- Fab Button -->
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary dt-fab-btn size-30">
                            <i class="icon icon-github icon-xl"></i>
                        </a> -->
                        <!-- /fab button -->
                    <!-- </li>

                    <li class="dt-list__item"> -->
                        <!-- Fab Button -->
                        <!-- <a href="javascript:void(0)" class="btn btn-outline-primary dt-fab-btn size-30">
                            <i class="icon icon-twitter icon-xl"></i>
                        </a> -->
                        <!-- /fab button -->
                    <!-- </li>
                </ul> -->
                <!-- /list -->

              </div>
              <!-- /form group -->

              <!-- Form Group -->
              <div class="d-flex flex-wrap align-items-center">
                  <!-- <span class="d-inline-block mr-2">Or connect with</span> -->
                  <a [routerLink]="['/auth/forgot-password']" routerLinkActive="router-link-active"
                  class="d-inline-block font-weight-500 ml-3" href>{{appStaticMsg.login.forgotpassword}}</a>

              </div>
              <!-- /form group -->


          </form>
          <!-- /form -->

      </div>
      <!-- /login content inner -->

      <!-- Login Content Footer -->
      <div class="dt-login__content-footer">
          {{appStaticMsg.login.donothaveAnAccount }}<a [routerLink]="['/auth/register']"
            routerLinkActive="router-link-active"  class="d-inline-block font-weight-500 mr-2" href>&nbsp; {{appStaticMsg.login.RegisterHere}}</a>
      </div>
      <!-- /login content footer -->

  </div>
  <!-- /login content section -->

</div>
<!-- /login content -->

</div>        </div>
</div>

