import { Component, OnInit, ViewChild } from '@angular/core';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../helpers/user.service';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import Swal from 'sweetalert2';
import { StorageService } from '../../../helpers/storage.service';
import { Router } from '@angular/router';
import { SharedService } from '../../../services/shared.service';
import * as S3 from 'aws-sdk/clients/s3';
import { Lightbox } from 'ngx-lightbox';
import { throwIfEmpty } from 'rxjs/operators';
import { LogService } from '../../../shared/log.service';
import { interval, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as imageReducer from "../../../reducers/image.reducer";
import * as imageActionTypes from "../../../actions/image.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { skip, take, tap } from 'rxjs/operators';
import * as fs from 'file-saver';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../../services/toast.service';

declare var $: any;
@Component({
  selector: 'app-image-custom',
  templateUrl: './image-custom.component.html',
  styleUrls: ['./image-custom.component.css']
})
export class ImageCustomComponent implements OnInit {
  sequenceNumber = '';
  classApplied = false;
  showLoader: boolean;
  showLoader1: boolean;
  dropdownEnabled = true;
  submitted = false;
  DocNo = false;
  ImgNo = false;
  errors = '';
  removedDocIds = '';
  activeTab = 'docDetailsTab';
  isEditMode: boolean;
  isThumbnailAdded: boolean = false;
  imageLibraryForm: FormGroup;
  items: TreeviewItem[];
  values: number[];
  editedImageData: any;
  imgId: Array<any> = [];
  isEditingMode: boolean;
  isViewingMode: boolean;
  imageclassificationValue: string;
  cards = [];
  log: any[];
  //imageclassificationParent: string;
  imageclassificationParent: any;
  imgLibraryClassificationData: any[];
  editedFileData: Array<any> = [];
  editedFileDataImg: Array<any> = [];
  isCreateMode:boolean;
  Swal = require('sweetalert2');
  documentImage = "../../../../assets/images/doccard/document.png";
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400,
  });
  baseClassification = null;
  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark'
  ];
  buttonClass = this.buttonClasses[0];
  /* Written by : Himanshu */

  imageObject: Array<object> = [];

//   imageObject = [{
//     image: '../../../../assets/images/color-card/1.png',
//     thumbImage: '../../../../assets/images/color-card/1.png',
// }
// ];
editedThumbnails: File[] = [];
thumbnail: string = "../../../../assets/images/color-card/1.png";

  files: File[] = [];
  //fileData: Array<any> = [];
  fileData: any;
  idvalue: any;
  imgLibData: Observable<any[]>;
  staticMsgs: any;
  isFileError = false;
  isFileAdded: boolean=false;

  onSelect(event) {
    //console.log(event);
    //this.files.push(...event.addedFiles);
    this.files = [];
    this.files.push(...event.addedFiles);
    this.fileData = this.files;
    //console.log(this.fileData);
     this.isThumbnailAdded=true;
    if (this.fileData && this.fileData.length > 0) {
      this.isFileError = false;
      this.isThumbnailAdded=true;
    }
    else{
      this.isThumbnailAdded=false;
    }
  }

  onRemove(event) {
    //console.log(event);
    //this.files.splice(this.files.indexOf(event), 1);
    this.fileData.splice(this.fileData.indexOf(event), 1);
    // console.log(this.fileData);
    if (this.fileData.length === 1) {
      this.isThumbnailAdded = true;
    }
    else {
      this.isFileError = true;
      this.isThumbnailAdded = false;
    }  }

  removeDocSection(id) {
    const index = this.editedFileData.findIndex(img => img.id === id);
    this.editedFileData.splice(index, 1)
    if(this.editedFileData.length==0){
      this.isThumbnailAdded=false;
    }    //  this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    this.imgId.push(id);
    let idvalue = this.imgId;
    // console.log(this.imgId);
    console.log(idvalue)

    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  removeImgSection(id) {
    const index = this.editedFileData.findIndex(img => img.id === id);
    this.editedFileData.splice(index, 1)
    if(this.editedFileData.length==0){
      this.isThumbnailAdded=false;
    }
    //  this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    this.imgId.push(id);
    let idvalue = this.imgId;
    // console.log(this.imgId);
    console.log(idvalue)

    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }

  status = [
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
  ];

  reportstatus = [
    { value: 'pass', label: 'Pass' },
    { value: 'failed', label: 'Failed' },
  ];



  onFilterChange(value: string): void {
    console.log('filter:', value);
  }

  constructor(
    private sharedService: SharedService,
    public userservice: UserService,
    private dataservice: DataService,
    private storageservice: StorageService,
    private router: Router,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ImageCustomComponent>,
    private logger: LogService,
    private toastService: ToastService,
    public aesdecryptservice: AESDecryptService,
    private _lightbox: Lightbox,
    private store: Store<any>
  ) {
    this.imageLibraryForm = this.fb.group({
      documents: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    this.dataservice.AddClientLog();
    // this.isThumbnailAdded = true;
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    if (this.storageservice.getEditedImageId()) {
      this.isCreateMode = false;
      if (this.storageservice.getEditedImageDetailsId()) {
        this.storageservice.removeImageDetailsId();
        this.isEditMode = true;
        // this.isEditingMode = true;
        // this.isViewingMode = false;
        this.editDoc();
        console.log('path 1')
      }
      else {
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.getImageDetails();
        console.log('path 2')
      }
    }else {
      this.isCreateMode = true;
      this.isEditMode = false;
      this.isEditingMode = false;
      this.isViewingMode = false;
      this.getColorClassification("Documents_Images");
      console.log('path 3')
    }
    console.log('isEditingMode',this.isEditingMode);
    this.loadSelectPicker();
    this.initForm();
    //this.items = this.service.getImage();
    $('.selectpicker').selectpicker();
    this.sharedService.sendClickEvent();
  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.userservice.logout();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
      this.sessionTimedOut();
      this.userservice.logout();
      this.showLoader = false;
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.staticMsgs.unResponsive.error);

    }
    this.showLoader = false;
  }

  getColorClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.imgLibraryClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.logger.info(this.staticMsgs.imgDetail.colorLoad, "ImageDetails");
          this.getClassificationData();
          this.showLoader = false;

        } else {
          //this.getClassificationData();
          // this.error("Failed:" + classificationData.common.message['item1'])
          this.toastService.showError(classificationData.common.message['item1']);
          this.logger.error(classificationData.common.message['item1'], "ImageDetails");
          this.showLoader = false;
        }
      } else {
        this.getClassificationData();
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })

    /* this.dataservice.getClassification(module).subscribe(response => {
     // console.log(response)
     if (response.body['item2'] === "Success") {
     this.imgLibraryClassificationData = response.body['item1'][0]["items"];
     this.logger.info("Color classification loaded successfully","ImageDetails");
       ///console.log(JSON.stringify(this.imgLibraryClassificationData ))
      // this.refreshTree();
      this.getClassificationData();
       this.showLoader = false;
     }
     else {
     //  this.refreshTree();
     this.getClassificationData();
       this.showLoader = false;
     }
   }, err => {
     this.logger.error(err.error.message,"ImageDetails");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
     } else if (err.error.message === "The incoming token has expired") {
       this.sessionTimedOut();
     } else if (err.error.message === "Unauthorized") {
       this.userservice.error();
     }
     else {
       this.logger.error(err.message,"ImageDetails");
       this.error(err.message);
     }
     this.showLoader = false;
   }); */
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.imgDetail.session,
      html: this.staticMsgs.imgDetail.sessExp,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        //console.log('Session Timedout.')
        this.userservice.logout();
        return;
      }
    })

  }
  isValidImageURL(str) {
    str = str.toLowerCase();
    str = str.split('?')[0];
    //moving on, split the uri into parts that had dots before them
    var parts = str.split('.');
    //get the last part ( should be the extension )
    var extension = parts[parts.length - 1];
    //define some image types to test against
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    //check if the extension matches anything in the list.
    if (imageTypes.indexOf(extension) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  // docDetailsTab(activeTab) {
  //   this.activeTab = activeTab;
  //   this.isThumbnailAdded = true;
  //   if (this.storageservice.getEditedImageId()) {
  //     this.getImageDetails();
  //   }

  //   this.initForm();

  //   this.loadSelectPicker();
  // }


  // reloadDocumentFormData(data) {
  //   //console.log(JSON.stringify(data));
  //   // for (let i = 0; i < data.length; i++) {
  //   //   //this.addNewDoc();
  //   //   this.refreshDocTree(i);
  //   // }
  //   //console.log(JSON.stringify(data))
  //   let documentControl = this.getControls();
  //   console.log(documentControl);
  //   for (let i = 0; i < data.length; i++) {
  //     documentControl[i].get('docid').setValue(data[i]["id"]);
  //     documentControl[i].get('docseq').setValue(data[i]["sequence"]);
  //    // this.refreshDocTree(i);
  //     if (this.isEditingMode) {
  //       //console.log("Coming")
  //       this.setDocumentTreeValue(i, data[i]["classification"]);
  //     }
  //     documentControl[i].get('docclassification').setValue(data[i]["classification"]);
  //     documentControl[i].get('docname').setValue(data[i]["name"]);
  //     documentControl[i].get('docstatus').setValue(data[i]["status"]);
  //     documentControl[i].get('docdescription').setValue(data[i]["description"]);
  //     documentControl[i].get('docreportstatus').setValue(data[i]["reportStatus"]);
  //     documentControl[i].get('doclablocation').setValue(data[i]["labLocation"]);
  //     if (data[i]["classification"] === "Lab Reports") {
  //       documentControl[i].get('docreportstatus').enable();
  //       documentControl[i].get('doclablocation').enable();
  //     }
  //     else {
  //       documentControl[i].get('docreportstatus').disable();
  //       documentControl[i].get('doclablocation').disable();
  //     }
  //     documentControl[i].get('docnotes').setValue(data[i]["notes"]);
  //     this.editedFileData.push(data[i]["files"]);
  //   }
  // }
  setDocumentTreeValue(index, value) {
    $(document).ready(function () {
      $("#docclassification" + index).data('kendoDropDownTree').value(value);
    })
  }

  editDoc() {
    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;

    this.isThumbnailAdded = true;
    if (this.storageservice.getEditedImageId()) {
      this.getImageDetails();
    }
    else {
      this.showLoader = false;
    }
    this.initForm();

    this.loadSelectPicker();



  }
  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Color Standard' });
    });
  }
  // getControls() {
  //   return (this.imageLibraryForm.controls);
  // }
  open(imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedImageData.files.length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedImageData.files[i - 1]["name"])) {
        src = this.editedImageData.files[i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedImageData.files[i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  getImageDetails() {
    this.showLoader1 = true;
    this.store.dispatch(new imageActionTypes.LoadImage(this.storageservice.getEditedImageId()));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageDocumentData) => {
      if (imageDocumentData.image.error === "" ) {
        if (imageDocumentData.image.message['item2'] === 'Success') {
          this.editedImageData = imageDocumentData.image.entities[this.storageservice.getEditedImageId()];
          console.log(this.editedImageData,'editimgdata')
          this.showLoader1 = false;
          this.initForm();
          this.imageLibraryForm.get('imageclassification').setValue(this.editedImageData["classification"]);
          this.getColorClassification("Documents_Images");
          this.imageclassificationValue = this.editedImageData["classification"];
          console.log('this.imageclassificationValue',this.imageclassificationValue)
          this.logger.info(this.editedImageData["name"] + " loaded successfuly", "Colordetails");
          if (this.editedImageData["baseClassification"] === "Documents" || this.editedImageData["baseClassification"] === "Images") {

            this.imageLibraryForm.get('notes').enable();

            if (this.editedImageData["classification"] === "Lab Reports") {
              this.imageLibraryForm.get('reportStatus').enable();
              this.imageLibraryForm.get('labLocation').enable();

              // this.editedFileData.get('files').enable();
              setTimeout(() => {
                $('.selectpicker').selectpicker({ noneSelectedText: 'Select Report' });
              }, 10);
            } else {
              this.imageLibraryForm.get('reportStatus').enable();
              this.imageLibraryForm.get('labLocation').enable();

              // this.editedFileData.get('files').disable();
            }
          } else {
            this.imageLibraryForm.get('notes').disable();
          }
          this.sequenceNumber = imageDocumentData.image.message['item1']["sequence"];
          this.imageLibraryForm.get('reportStatus').setValue(this.editedImageData["reportStatus"]);
          this.imageLibraryForm.get('docstatus').setValue(this.editedImageData["status"]);
          this.imageLibraryForm.get('labLocation').setValue(this.editedImageData["labLocation"]);
          this.imageLibraryForm.get('name').setValue(this.editedImageData["name"]);
          this.imageLibraryForm.get('description').setValue(this.editedImageData["description"]);
          this.imageLibraryForm.get('notes').setValue(this.editedImageData["notes"]);
          this.imageLibraryForm.get('docid').setValue(this.editedImageData["id"]);
          this.imageLibraryForm.get('sequence').setValue(this.editedImageData["sequence"]);
          this.imageLibraryForm.get('baseclassification').setValue(this.editedImageData["baseClassification"]);
          this.refreshSelect();
          let displayimg = this.editedImageData["files"];

          this.editedFileDataImg = [];
          this.editedFileData = [];
          //alert(displayimg)
          if (displayimg) {
              for (let i = 0; i < displayimg.length; i++) {
                this.editedFileData.push(displayimg[i]);
              }
          }
          this.imageclassificationParent = this.editedImageData["baseClassification"];
          this.baseClassification = this.editedImageData["baseClassification"];

          /* Written by : Himanshu */
          if (imageDocumentData.image.message['item1']["files"]) {

            this.editedThumbnails = [];
            this.editedThumbnails.push(imageDocumentData.image.message['item1']["files"]);


            if(this.editedThumbnails.length > 0){
              this.editedThumbnails.forEach((x,i,array)=>{
                console.log(x,'x');
                const temp = {
                  'image':x[i]['thumbnail'],
                  'thumbImage':x[i]['thumbnail']
                }
                this.isThumbnailAdded=true;
                this.imageObject.push(temp);
              })
              console.log('this.imageObject1',this.imageObject);
            }else{
              const temp = {
                'image':this.thumbnail,
                'thumbImage':this.thumbnail
              }
              this.imageObject.push(temp);
            }
            console.log('this.editedThumbnails',this.editedThumbnails)
            console.log('this.imageObject',this.imageObject);
          }
        } else {
          this.showLoader1 = false;
          // this.error("Failed:" + imageDocumentData.image.message['item1'])
          this.toastService.showError(imageDocumentData.image.message['item1']);
          this.logger.error(imageDocumentData.image.message['item1'], "ImageDetails");
        }
      } else {
        this.logger.error(imageDocumentData.image.error.error.message, "ImageDetails");
        this.getError(imageDocumentData.image.error);
        this.showLoader1 = false;
      }
    })


    ////console.log(this.storageservice.getEditedImageId())
    /*    this.dataservice.getDocumentById(this.storageservice.getEditedImageId()).subscribe(response => {

             if (response.body['item2'] === "Success") {
          this.showLoader1 = false;
          //console.log(JSON.stringify(data[0]));
          this.editedImageData = response.body['item1'];
          this.logger.info(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["name"])+" loaded successfuly","ImageDetails");
          console.log(JSON.stringify(this.editedImageData));
          //this.reloadDocumentFormData(this.editedImageData);

          this.initForm();
          this.imageLibraryForm.get('imageclassification').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["classification"]));
          this.getClassificationData();
          this.imageclassificationValue = this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["classification"]);
         //console.log(response.body['item1']['status']);
           if (this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["baseClassification"]) === "Documents" || this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["baseClassification"]) === "Images") {

               this.imageLibraryForm.get('notes').enable();

              if (this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["classification"]) === "Lab Reports") {
                  this.imageLibraryForm.get('reportStatus').enable();
                  this.imageLibraryForm.get('labLocation').enable();

                  // this.editedFileData.get('files').enable();
                    setTimeout(() => {
                      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Report' });
                    }, 10);
              }else{
                  this.imageLibraryForm.get('reportStatus').disable();
                  this.imageLibraryForm.get('labLocation').disable();

                // this.editedFileData.get('files').disable();
              }
           }else{
              this.imageLibraryForm.get('notes').disable();
           }



          this.imageLibraryForm.get('reportStatus').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["reportStatus"]));
          this.imageLibraryForm.get('docstatus').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["status"]));
          this.imageLibraryForm.get('labLocation').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["labLocation"]));
          this.imageLibraryForm.get('name').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["name"]));
          this.imageLibraryForm.get('description').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["description"]));
          this.imageLibraryForm.get('notes').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["notes"]));
          this.imageLibraryForm.get('docid').setValue(response.body['item1']["id"]);
          this.imageLibraryForm.get('sequence').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["sequence"]));
          this.imageLibraryForm.get('baseclassification').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["baseClassification"]));
          this.refreshSelect();
          let displayimg = response.body['item1']["files"];

          this.editedFileDataImg = [];
          this.editedFileData = [];
          for(let i = 0; i < displayimg.length; i++){
            displayimg[i].thumbnail=this.aesdecryptservice.decryptData(this.dataservice.Key,displayimg[i].thumbnail);
            displayimg[i].name=this.aesdecryptservice.decryptData(this.dataservice.Key,displayimg[i].name);

            }
          //alert(displayimg)
          if(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["baseClassification"]) === 'Documents'){
            for(let i = 0; i < displayimg.length; i++){
              displayimg[i].thumbnail
                this.editedFileData.push(displayimg[i]);
              }
          }else{
            for(let i = 0; i < displayimg.length; i++){
              this.editedFileDataImg.push(displayimg[i]);
            }
          }
           this.imageclassificationParent = this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["baseClassification"]);
           this.baseClassification = this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["baseClassification"]);
        }
        else {
          this.error(response.body(['item1']));
          this.logger.error(response.body(['item1']),"ImageDetails");
          this.showLoader1 = false;
        }
      }, err => {
        this.logger.error(err.error.message,"ImageDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.logger.error(err.message,"ImageDetails");
          this.error(err.message);
        }
        this.showLoader = false;
      }); */
  }

  getClassificationData() {
    var data = this.imgLibraryClassificationData;
    var self = this;

    $(document).ready(function () {
      $("#imageclassification").kendoDropDownTree({
        placeholder: "Select Classification",

        filter: "startswith",
        dataSource: data,

        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          //alert(JSON.stringify(value))
          //alert(JSON.stringify(value.text))
          // alert(JSON.stringify(value.parentName))

          if (value) {
            self.imageclassificationParent = value["parentName"];
            self.imageclassificationValue = value["text"];
            self.imageLibraryForm.get('imageclassification').setValue(value["text"]);
            setTimeout(() => {
              $('.selectpicker').selectpicker();
            }, 10);

            if (value["text"] === "Lab Report") {
              this.imageLibraryForm.get('reportStatus').enable();
              this.imageLibraryForm.get('labLocation').enable();
              // this.imageLibraryForm.get('type').enable();

            }

          } else {
            self.imageclassificationParent = "";
            self.imageclassificationValue = "";
            self.imageLibraryForm.get('imageclassification').setValue("");

          }
        }
      });

    });
  }

  initForm() {
    this.showLoader = true;

    this.imageLibraryForm = this.fb.group({
      // imageclassification: ['', [Validators.required]],
      imageclassification: ['',Validators.required],
      sequence: [''],
      baseclassification: [''],
      name: ['', [Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      description: ['',[Validators.maxLength(500)]],
      docstatus: ['', [Validators.required]],
      docid: [''],
      reportStatus: [''],
      labLocation: ['', [Validators.maxLength(50)]],

      filedelete: [''],
      notes: ['',[Validators.maxLength(500)]],
      // reportStatus: ['', [Validators.required]],
      //labLocation: ['', [Validators.required]]

      //// type: ['', [Validators.required]]
    });
    this.showLoader = false;

  }

  characterOnly(e: any) {
    var regex = new RegExp("^[[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  get f() { return this.imageLibraryForm.controls; }
  async onImgSubmit() {

    let ids = []
    this.submitted = true;
    if (!this.imageLibraryForm.valid) {
      // this.error(this.staticMsgs.imgDetail.invalidForm);
      this.toastService.showError("Please enter all the mandatory fields.");
      if(this.fileData.length == 0){
        this.isFileError=true;
        }
      return false;
    }
    else if (!this.isThumbnailAdded) {
      return false;
    }
    this.showLoader = true;

    if (this.baseClassification === 'Documents' && this.imageclassificationParent === 'Images') {
      console.log(this.editedFileData);
      if (this.imageclassificationParent === 'Documents') {
        ids = [...this.editedFileDataImg.map(img => img.id)];
      } else {
        ids = [...this.editedFileData.map(img => img.id)];
      }
    }
    console.log(this.imageLibraryForm.value);
    // console.log(this.f.baseclassification.value);
    //console.log(this.fileData.length)
    /*this.submitted = true;
    if (!this.imageLibraryForm.valid) {
       return false;
     }
     this.showLoader = true;*/
     if (this.fileData == null && this.editedThumbnails.length <= 0) {
      this.isFileError = true;
      this.showLoader = false;
      return false;
    }

    var formData = new FormData();
    let fileDelete = this.f.filedelete.value;
    if (ids.length > 0) {
      fileDelete = ids.join(',');
    }
    //   this.aesdecryptservice.encryptData(this.dataservice.Key,this.f.firstname.value)
    if (this.f.docid.value) {
      formData.append('docid', this.f.docid.value);
      formData.append('tag', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
      formData.append('filedelete', fileDelete);
    }
    else {
      formData.append('tag', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
    }
    formData.append("docclassification", this.aesdecryptservice.encryptData(this.dataservice.Key, this.imageclassificationValue));
    formData.append("documentNo", this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.sequence.value));
    formData.append("docname", this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.name.value));
    formData.append("docdescription", this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.description.value));
    formData.append("docstatus", this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.docstatus.value));
    formData.append("docnotes", this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.notes.value));
    formData.append("docreportstatus", this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.reportStatus.value));
    formData.append("doclablocation", this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.labLocation.value));
    formData.append("baseclassification", this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.baseclassification.value));
    formData.append("docfilecount", this.fileData ? this.fileData.length : 0);

    //this.test(formData);

    let a: number = 0;
    if (this.fileData) {
      for (let f of this.fileData) {

        if (JSON.stringify(f) != '[]') {
          //console.log (f.name);
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != "error") {
            ///console.log(JSON.stringify(f))
            /// formData.append('file' + a, JSON.parse(fileUploadedData)["Key"]);
            formData.append('file[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, JSON.parse(fileUploadedData)["Key"]));
            formData.append('filename[' + a + ']', f.name);
          }
          else {
            // this.error(fileUploadedData);
            this.toastService.showError(fileUploadedData);
            this.submitted = false;
            this.showLoader = false;
            return false;
          }
        }
        a = a + 1;
      }

    }
    ///  this.test(formData);

    this.test(formData);
  if(this.f.docid.value==''){
    if (this.fileData.length === 1) {
      this.isThumbnailAdded = true;
    }
    else {
      this.isThumbnailAdded = false;
    }
    this.store.dispatch(new imageActionTypes.CreateImage(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageDocument) => {
      if (imageDocument.image.error === "") {
        if (imageDocument.image.message['item2'] === 'Success') {
          this.showLoader = false;
          this.submitted = false;
          this.isEditMode = true;
          this.isViewingMode = true;
          this.isEditingMode = false;
          this.imgId = [];
          this.storageservice.storeEditedImageId(imageDocument.image.message['item1'].id);
          this.editedImageData=imageDocument.image.message['item1'];
           this.reloadImageFormData(imageDocument.image.message['item1']);
          this.getImageDetails();
          // if (this.isEditMode) {
          //   this.success(this.staticMsgs.imgDetail.docUpd1);
          //   this.logger.info(this.staticMsgs.imgDetail.docUpd, "ImageDetails");
          // } else {
            // this.success(this.staticMsgs.imgDetail.imgUpd1);
            this.toastService.showSuccess(this.staticMsgs.imgDetail.imgUpd1);
            this.logger.info(this.staticMsgs.imgDetail.imgUpd, "ImageDetails");
            this.dialogRef.close();
          // }
          this.files = [];
          this.fileData = [];
        } else {
          this.showLoader = false;
          // this.error("Failed:" + imageDocument.image.message['item1'])
          this.toastService.showError(imageDocument.image.message['item1']);
          this.logger.error(imageDocument.image.message['item1'], "ImageDetails");
        }

      } else {
        this.logger.error(imageDocument.image.error.error.message, "ImageDetails");
        this.getError(imageDocument.image.error);
        this.showLoader = false;
      }
    })
  }else{
    if (this.editedThumbnails.length === 1) {
      this.isThumbnailAdded = true;
    }
    else {
      if (this.fileData.length === 1) {
        this.isThumbnailAdded = true;
      }
      else {
        this.isThumbnailAdded = false;
      }
    }
    this.store.dispatch(new imageActionTypes.UpdateImage(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageDocument) => {
      if (imageDocument.image.error === "") {
        if (imageDocument.image.message['item2'] === 'Success') {
          this.showLoader = false;
          this.submitted = false;
          this.isEditMode = true;
          this.isViewingMode = true;
          this.isEditingMode = false;
          this.imgId = [];
          this.storageservice.storeEditedImageId(imageDocument.image.message['item1'].id);
          this.editedImageData=imageDocument.image.message['item1'];
           this.reloadImageFormData(imageDocument.image.message['item1']);
          this.getImageDetails();
          // if (this.isEditMode) {
          //   this.success(this.staticMsgs.imgDetail.docUpd1);
          //   this.logger.info(this.staticMsgs.imgDetail.docUpd, "ImageDetails");
          // } else {
            // this.success(this.staticMsgs.imgDetail.imgUpd1);
            this.toastService.showSuccess(this.staticMsgs.imgDetail.imgUpd1);
            this.logger.info(this.staticMsgs.imgDetail.imgUpd1, "ImageDetails");
            this.dialogRef.close();
          // }
          this.files = [];
          this.fileData = [];
        } else {
          this.showLoader = false;
          // this.error("Failed:" + imageDocument.image.message['item1'])
          this.toastService.showError(imageDocument.image.message['item1']);
          this.logger.error(imageDocument.image.message['item1'], "ImageDetails");
        }

      } else {
        this.logger.error(imageDocument.image.error.error.message, "ImageDetails");
        this.getError(imageDocument.image.error);
        this.showLoader = false;
      }
    })
  }
    /* this.dataservice.createImageDocument(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          // console.log(JSON.stringify(response.body['item1']));
          this.showLoader = false;
          this.submitted = false;
          this.isViewingMode = true;
          this.isEditingMode = false;
          this.reloadImageFormData(response.body['item1']);
          this.storageservice.storeEditedImageId(response.body['item1'].id);

          this.success("Image Created Successfully!");
          this.logger.info("Image Created Successfully", "ImageDetails");
          this.getImageDetails();
        }
        else {
          // console.log(response)
          this.error(response.body['item1']);
          this.showLoader = false;
        }

      }
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
      }
      this.showLoader = false;
    }); */

    /// }
  // }
  }


  reloadImageFormData(data) {
    ///console.log(data)
    this.initForm();
    this.imageLibraryForm.get('imageclassification').setValue(data["classification"]);
    this.imageLibraryForm.get('baseclassification').setValue(data["baseClassification"]);
    this.getClassificationData();
    if (data["classification"] === "Lab Reports") {
      this.imageLibraryForm.get('reportStatus').setValue(data["reportStatus"]);
      this.imageLibraryForm.get('labLocation').setValue(data["labLocation"]);
    }
    this.imageclassificationValue = data["classification"];
    this.imageLibraryForm.get('name').setValue(data["name"]);
    this.imageLibraryForm.get('description').setValue(data["description"]);
    this.imageLibraryForm.get('docstatus').setValue(data["status"]);
    this.imageLibraryForm.get('notes').setValue(data["notes"]);

    this.imageLibraryForm.get('docid').setValue(data["id"]);
    this.imageLibraryForm.get('sequence').setValue(data["sequence"]);
    //this.refreshSelect();
    /// this.storageservice.storeEditedColorId(data["id"]);
    //this.imageLibraryForm.get('docfilecount').setValue(data["docfilecount"]);
    this.editedFileData = [];
    //this.editedFileData.push(data["files"]);
    let displayimg = data["files"];

    this.editedFileDataImg = [];
    //alert(displayimg)
    if (displayimg) {
      for (let i = 0; i < displayimg.length; i++) {
        this.editedFileData.push(displayimg[i]);
      }
    }
    this.imageclassificationParent = this.editedImageData["baseClassification"];
    this.baseClassification = this.editedImageData["baseClassification"];
    /* Written by : Himanshu */
    const temp = {
      'image':this.thumbnail,
      'thumbImage':this.thumbnail
    }
    this.imageObject.push(temp);
    this.isThumbnailAdded=true;
    if (data["files"]) {
      this.editedThumbnails = [];
      this.editedThumbnails.push(data["files"]);
    }
  }

  test(formData) {
    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1])
    }
  }

  async uploadFile(file): Promise<any> {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          reject("error");
        }
        console.log('Successfully uploaded file.', data);
        resolve(JSON.stringify(data));
      });
    });
    //for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
  };

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  downloadDoc(doc) {
    try {
      fs.saveAs(doc.thumbnail, doc.name);
    }
    catch (e) {
      console.log(e)
    }
    /* var a = document.createElement('a');
    a.setAttribute('href', doc.thumbnail);
    a.setAttribute('download', doc.name || '');
    document.body.appendChild(a);
    a.onclick = function () { setTimeout(function () { document.body.removeChild(a) }, 100) }
    a.click(); */
  }
  cancelImageEdit() {
    console.log('filedata',this.fileData);
    // (this.imageLibraryForm.get('documents')as FormArray).clear();
    if (this.storageservice.getEditedImageId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getImageDetails();
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }
    this.initForm();
    ///this.loadSelectPicker();
  }
  cancelImageCreate() {
    this.router.navigate(['/imagelibrary']);
    this.dialogRef.close();
  }

  onDeleteImage() {

    Swal.fire({
      title: this.staticMsgs.imgDetail.deleteImg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.imgDetail.deleteit
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getEditedImageId());
        this.store.dispatch(new imageActionTypes.DeleteImageLibrary(deleterecordIds))
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((imageLibraryData) => {
          if (imageLibraryData.image.error === "") {
            if (imageLibraryData.image.message['item2'] === 'Success') {
              this.showLoader = false;
              // this.success(this.staticMsgs.imgDetail.docDelete);
              this.toastService.showSuccess(this.staticMsgs.imgDetail.docDelete);
              this.logger.info(this.staticMsgs.imgDetail.docDelete, "ImageLibrary");
              this.router.navigate(['/imagedetails']);
            } else {
              this.router.navigate(['/imagedetails']);
              // this.error("Failed:" + imageLibraryData.image.message['item1'])
              this.toastService.showError(imageLibraryData.image.message['item1']);
              this.logger.error(imageLibraryData.image.message['item1'], "ImageLibrary");
              this.showLoader = false;
            }

          } else {
            this.logger.error(imageLibraryData.image.error.error.message, "ImageLibrary");
            this.getError(imageLibraryData.image.error);
            this.showLoader = false;
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

}
