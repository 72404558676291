import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DataStateChangeEvent, FilterService, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State, CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { StorageService } from '../../../helpers/storage.service';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../helpers/user.service';
import { Favourite } from '../../../models/color';
import { SharedService } from '../../../services/shared.service';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as seamsReducer from "../../../reducers/seams.reducer";
import * as seamsActionTypes from "../../../actions/seams.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { SeamsCustomComponent } from '../seams-custom/seams-custom.component';



declare var $: any;


@Component({
  selector: 'app-seamslibrary',
  templateUrl: './seamslibrary.component.html',
  styleUrls: ['./seamslibrary.component.css']
})

export class SeamslibraryComponent implements OnInit {
  advForm: FormGroup;
  createdFromDate: any;
  createdToDate: any;
  modifiedFromDate: any;
  modifiedToDate: any;
  minDate:NgbDateStruct = { year: 1985, month: 1, day: 1 };
  createdStartDate: string;
  modifiedEndDate: string;
  modifiedStartDate: string;
  createdEndDate: string;
  public filteredData: any[];
  public filteredXcelData: any[];
  thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  seamsData = [];
  dbseamsData = [];
  Swal = require('sweetalert2');
  enableKeywordVal: string[];
  enableCreatedByVal: string[];
  enableCreatToVal: string[];
  enableCreatFromVal: string[];
  enableStatusVal: string[];
  enableClassVal: string[];
  enableModifToVal: string[];
  enableModifFromVal: string[];
  enableseam751aNumber: string[];
  enableisO4916Number: string[];
  enablecommonApplication: string[];
  seamsClassificationData: any[];
  showLoader: boolean;
  showLoader1: boolean;
  originalData = [];
  apiGridData = [];
  btnstatus: boolean = false;
  btnstatus1: boolean = true;
  notFound: boolean;
  notfoundImg: string;
  cardText = true;
  ListText = false;
  //btnCard = false;
  cards: Observable<any[]>;
  clickedRowItem: any;
  copyCard: Observable<any[]>;
  selected: any;
  //btnList = true;
  searchedKeyword: string;
  seamsDataForm: FormGroup;
  gridContent = true;
  tableContent = false;
  classApplied = false;
  public filterQuery = "";
  isEditMode = false;
  isResetMode = false;
  public SearchData = "";

  paginationStartcount = 0;
  paginationEndcount = 10;
  public startCount = 0;
  public endCount = 10;
  public startCountList = 0;
  public endCountList = 10;
  public startEndCount = "";
  public isPageChanged = false;
  public isScrollDown = false;
  public isGetAllRecords = false;
  public totalRecordCount: any;
  loading: boolean;
  isDataLoading = false;
  public gridData: any;
  idArray: string[];
  log: any[];
  seamsclassificationValue: string;
  HeadingText = "Card View";
  public filter: CompositeFilterDescriptor;
  seamsViewMode = "Created";
  myseams: boolean = true;
  sharedseams: boolean = false;
  checkStatusData = false;
  msg: string;
  showNote = false;
  sharedCopyCard: any[] = [];
  sharedCards: any[] = [];
  isMySeams = true;
  sharedNotFound: boolean;
  isSharedGetAllRecords = false;
  isSharedScrollDown = false;
  totalSharedRecordCount: any;
  sharedMsg: string;
  sharedEndCount = 10;
  sharedStartCount = 0;
  sharedStartEndCount = "";
  checkSharedStatusData = false;
  sharedEndListCount = 10;
  sharedStartListCount = 0;
  sharedGridData: any[] = [];
  sharedStartEndListCount: string;
  startEndListCount: string;
  staticMsgs:any;
  addToFavorite: any;
  data1='Approved';
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      this.filteredData = this.filteredXcelData;
      var gridView = {
        data: this.filteredData,
        total: this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total;
    }
    else {
      var gridView = {
        data: this.seamsViewMode === 'Shared'? this.sharedGridData : this.seamsData,
        total: this.seamsViewMode === 'Shared'? this.totalSharedRecordCount : this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total;
    }

  }
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredData = filterBy((this.seamsViewMode === 'Shared') ? this.sharedGridData : this.seamsData, filter);
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public state: State = {
    skip: 0,
    take: 10
  };
  @ViewChild('grid') grid: GridComponent;
  public gridView: GridDataResult;

  public listItems: Array<{ text: string, value: string }> = [
    // { text: "indevelopment", value: "In Development" },
    { text: "Approved", value: "Approved" },
    { text: "In Review", value: "In Review" },
    { text: "Dropped", value: "Dropped" },
  ];
  options = [
    //{ value: 'All', label: 'All' },
    { value: 'Approved', label: 'Approved' },
    { value: 'In Review', label: 'In Review' },
    { value: 'Dropped', label: 'Dropped' },
  ];

  constructor(
    private sharedService: SharedService,
    private storageservice: StorageService,
    private dataService: DataService,
    public userservice: UserService,
    private formBuilder: FormBuilder,
    private logger: LogService,
    private dialog: MatDialog,
    private aesdecryptservice: AESDecryptService,
    public filterService: FilterService,
    private router: Router,
    private store: Store<any>) {
    this.allData = this.allData.bind(this);
  }

  onPageChange(e: PageChangeEvent): void {
    this.isPageChanged = true;
    this.state.skip = e.skip;
    this.state.take = e.take;
    if (this.seamsViewMode === 'Shared') {
      this.sharedStartListCount = e.skip;
      this.sharedEndListCount = e.skip + e.take;
      this.loadSharedListData();
    } else {
      this.startCountList = e.skip;
      this.endCountList = e.skip + e.take;
      this.loadDataList();
    }
  }

  scrollEvent = (event: any): void => {
    if (this.gridContent && this.router.url.includes('seamslibrary') && !this.tableContent) {
      if (!this.isDataLoading) {
        if (event.target.scrollTop >= (event.target.scrollHeight - event.target.clientHeight - 2)) {
          if (this.seamsViewMode == 'Created') {
            this.isDataLoading = true;
            if (!this.isGetAllRecords) {
              this.isScrollDown = false;
              this.startCount = this.startCount + this.paginationEndcount;
              this.endCount = this.endCount + this.paginationEndcount;
              this.loadData();
            }
          } else {
            this.isDataLoading = true;
            if (!this.isSharedGetAllRecords) {
              this.isScrollDown = false;
              this.sharedStartCount = this.sharedStartCount + this.paginationEndcount;
              this.sharedEndCount = this.sharedEndCount + this.paginationEndcount;
              this.loadSharedData();
            }
          }

        }
      }
    }
  }

  seamsView(seams) {
    this.isMySeams = (seams === 'Created') ? true : false
    this.cards = null;
    this.notFound = false;
    this.isEditMode = false;
    if(this.tableContent){
      this.closeAllRows();
    }
    this.seamsViewMode = seams;
    if (this.seamsViewMode == 'Shared') {
      this.myseams = false;
      this.sharedseams = true;
    }
    else {
      this.myseams = true;
      this.sharedseams = false;
    }

    if (this.gridContent) {
      this.HeadingText = "Card View";
      this.seamsViewMode == 'Shared' ? this.loadSharedData() : this.loadData();
    }
    else {
      this.HeadingText = "List View";
      this.seamsViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();
    }
  }

  cardView() {
    this.ListText = false;
    this.cardText = true;
    this.isMySeams = true;
    this.showNote = false;
    this.tableContent = false;
    this.gridContent = true;
    this.HeadingText = "Card View";
    this.btnstatus = false;
    this.btnstatus1 = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', 'Approved');
    }, 10);
    if (this.seamsViewMode === 'Shared') {
      this.isSharedGetAllRecords = false;
      this.sharedCards = [];
      this.sharedStartCount = this.paginationStartcount;
      this.sharedEndCount = this.paginationEndcount;
      this.loadSharedData();
    } else {
    this.isGetAllRecords = false;
    this.startCount = this.paginationStartcount;
    this.endCount = this.paginationEndcount;
    this.loadData();
    }
  }

  tableView() {
    this.ListText = true;
    this.cardText = false;
    this.isMySeams = false;
    this.tableContent = true;
    this.gridContent = false;
    this.HeadingText = "List View";
    this.btnstatus = true;
    this.btnstatus1 = false;
    if(this.seamsViewMode === 'Shared'){
      if (this.isPageChanged) {
        this.sharedStartListCount = this.state.skip;
        this.sharedEndListCount = this.state.skip + this.state.take;
      }
      else {
        this.sharedStartListCount = this.paginationStartcount;
        this.sharedEndListCount = this.paginationEndcount;
      }
    } else {
    if (this.isPageChanged) {
      this.startCountList = this.state.skip;
      this.endCountList = this.state.skip + this.state.take;
    }
    else {
      this.startCountList = this.paginationStartcount;
      this.endCountList = this.paginationEndcount;
    }
  }
  this.seamsViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();

  }

  public allData(): ExcelExportData {
    this.filteredData = filterBy((this.seamsViewMode === 'Shared') ? this.sharedGridData : this.seamsData, this.state.filter);
    //this.state.take = this.filteredData.length;

    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.filteredData = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    const result: ExcelExportData = {

      data: this.filteredData//process(this.filteredData, this.state).data,
    };
    //this.state.take = 5;
    return result;
  }
  advanceSearchOpen() {
    this.classApplied = true;
    this.getClassificationData();
  }

  advanceSearchClose() {
    this.classApplied = false;

  }
  private createFormGroup(seams: any = {}) {
    // create a new form group containing controls and validators for a product
    return this.formBuilder.group({
      // thumbnail: [seams.thumbnailFiles. ],
      name: [seams.name], status: [seams.status],
      // seamDrawing: [seams.seamDrawing],

      seam751aNumber: [seams.seam751aNumber],
      isO4916Number: [seams.isO4916Number],
      commonApplication: [seams.commonApplication],
      requirements: [seams.requirements],
      // seamsStandard: [seams.seamsStandard, ],

      createdBy: [seams.createdBy],
      createdOn: [seams.createdOn],
      modifiedOn: [seams.modifiedOn],
      id: [seams.id],
      isEdit: [seams.isEdit],
      isDelete: [seams.isDelete],
    })
  }

  private resetForm() {
    // clear form array and create a new form group for each product
    this.fa.clear();
    if (this.seamsViewMode === 'Shared') {
      this.sharedGridData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
    } else {
      this.seamsData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
    }
  }
  onEdit() {
    // reset / initialise form fields
    this.resetForm();
    // store copy of original Seams in case cancelled
    this.originalData = this.seamsViewMode === 'Shared'? [...this.sharedGridData] :[...this.seamsData];
    var gridView = {
      data: this.originalData,
      total: this.seamsViewMode === 'Shared'? this.totalSharedRecordCount :this.totalRecordCount,
    };
    this.gridData = gridView;
    // set all rows to edit mode to display form fields
    this.editGridRows();
    this.isEditMode = true;
  }

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    window.addEventListener('scroll', this.scrollEvent, true);
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";

    this.storageservice.storeEditedSeamsId("");
    $(document).ready(function () {
      $('.selectpicker').selectpicker('val', 'Approved');
    });
    this.advForm = this.formBuilder.group({
      seam751aNumber: [''],
      isO4916Number: [''],
      commonApplication: [''],
      keyword: [''],
      createdBy: [''],
      classification: [''],
      status: [''],
      createdFrom: [''],
      createdTo: [''],
      modifiedFrom: [''],
      modifiedTo: ['']
    });
    this.seamsDataForm = this.formBuilder.group({
      formArray: new FormArray([])
    });
    this.loadData();
    //this.getMaterialClassification("Material");
    this.sharedService.sendClickEvent();
  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader = false;
  }

  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataService.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }

  // convenience getters for easy access to form fields
  get f() { return this.seamsDataForm.controls; }
  get fa() { return this.f.formArray as FormArray; }
  fac(data) {
    if(this.seamsViewMode === 'Shared'){
      return this.fa.controls[this.sharedGridData.indexOf(data)] as FormGroup;
    } else {
      return this.fa.controls[this.seamsData.indexOf(data)] as FormGroup;
    }
  }

  private editGridRows() {
    // set all rows to edit mode to display form fields
    //this.seamsData.forEach((x, i) => {
    //  this.grid.editRow(i, this.fa.controls[i]);
    //});
    if(this.seamsViewMode ==='Shared'){
      this.filteredData = filterBy(this.sharedGridData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        if (x.isEdit) {
          var documentRow = this.fa.controls[this.sharedGridData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, documentRow);
        }
      });
    } else {
    this.filteredData = filterBy(this.seamsData, this.state.filter);
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);

    this.filteredData.forEach((x, i) => {
      //alert(this.seamsData.filter(x).keys);
      //   this.fa.controls[i].patchValue(x);

      if (x.isEdit) {
        var seamsRow = this.fa.controls[this.seamsData.indexOf(x)] as FormGroup;
        var rowIndex = this.state.skip + i;
        this.grid.editRow(rowIndex, seamsRow);
      }
    });
   }
  }
  // convenience getters for easy access to form fields

  onSelect(val: any, type: any) {
    this.data1=val;
    if (type === 'myseams') {
      if (val == "") {
        this.cards = this.copyCard;
      } else {
        this.cards = this.copyCard.pipe(
          map(data => data.filter(res => res.status.toLowerCase() == val.toLowerCase()))
        )
      }
      console.log(this.cards,'seamdata');

      var data = this.seamsData.filter(x => x.status.toLowerCase() == val.toLowerCase())
      console.log('data: ', data);
      if (data.length > 0) {
        this.checkStatusData = false;
        console.log(this.checkStatusData,'statusdata')

      } else {
        this.msg = (val.toLowerCase() === 'approved') ? 'No approved records available' : (val.toLowerCase() === 'dropped') ?  'No dropped records available': 'No in-review records available';
        this.checkStatusData = true;
        console.log(this.checkStatusData,this.msg,'statusdata');

      }

    } else {
      if (val == "") {
        this.sharedCards = this.sharedCopyCard;
      } else {
        this.sharedCards = this.sharedCopyCard.filter(res => res.status.toLowerCase() == val.toLowerCase())
      }
      if (this.sharedCards.length > 0) {
        this.checkSharedStatusData = false;
      } else {
        this.sharedMsg = (val.toLowerCase() === 'approved') ? 'No approved shared records available' : (val.toLowerCase() === 'dropped') ?  'No dropped shared records available': 'No in-review shared records available';
        this.checkSharedStatusData = true;
      }

    }
  }

  getMaterialClassification(module: string) {
    this.showLoader = true;
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.seamsLibrary.seamClassificationLoadSuccess, this.staticMsgs.seamsLibrary.Seamlibrary);
          this.seamsClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.getClassificationData();
          this.showLoader = false;
        } else {
          this.getClassificationData();
          this.showLoader = false;
          this.error("Failed:" + classificationData.common.message?.item1)
          this.logger.error(classificationData.common.message?.item1,this.staticMsgs.seamsLibrary.Seamlibrary);
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, this.staticMsgs.seamsLibrary.Seamlibrary);
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })

    /* this.dataService.getMaterialClassification(module).subscribe(response => {
     // console.log(response)
     if (response.body['item2'] === "Success") {
       this.seamsClassificationData = response.body['item1'][0]["items"];
       this.logger.info("Material classification loaded successfully", "SeamsLibrary");
       ///console.log(JSON.stringify(this.imgLibraryClassificationData ))
       // this.refreshTree();
       this.getClassificationData();
       this.showLoader = false;
     }
     else {
       //  this.refreshTree();
       this.getClassificationData();
       this.showLoader = false;
       this.error(response.body['item1']);
       this.logger.error(response.body['item1'], "SeamsLibrary");
     }
   }, err => {
     this.logger.error(err.error.message, "SeamsLibrary");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
       this.showLoader = false;
     } else if (err.error.message === "The incoming token has expired") {
       this.sessionTimedOut();
       this.showLoader = false;
     } else if (err.error.message === "Unauthorized") {
       this.userservice.error();
       this.showLoader = false;
     }
     else {
       this.error(err.message);
       this.logger.error(err.message, "SeamsLibrary");

     }
     this.showLoader = false;
   }); */
  }
  getClassificationData() {
    var data = this.seamsClassificationData;
    var self = this;
    $(document).ready(function () {
      $("#seamsclassification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "200px",
        filter: "startswith",
        dataSource: data,
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          self.seamsclassificationValue = value["text"];
        }
      });

    });
  }
  jsonDeleteCompare(element, index, array) {

    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }
  jsonCompare(element, index, array) {
    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      Object.keys(element).forEach(key => {

        if (element[key] != dataRow[0][key]) {
          diff++;

        }
      })
    }
    if (diff > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  onSave() {
    // mark all fields as touched to highlight any invalid fields
    this.seamsDataForm.markAllAsTouched();

    for (let i = 0; i < this.seamsDataForm.value.formArray.length; i++) {
      if (this.seamsDataForm.controls.formArray['controls'][i].controls.seam751aNumber.value.length > 20) {
        this.seamsDataForm.controls.formArray['controls'][i].controls.seam751aNumber.setErrors({ 'incorrect': true });
      }
      if (this.seamsDataForm.controls.formArray['controls'][i].controls.isO4916Number.value.length > 20) {
        this.seamsDataForm.controls.formArray['controls'][i].controls.isO4916Number.setErrors({ 'incorrect': true });
      }
      if (this.seamsDataForm.controls.formArray['controls'][i].controls.commonApplication.value.length > 500) {
        this.seamsDataForm.controls.formArray['controls'][i].controls.commonApplication.setErrors({ 'incorrect': true });
      }
      if (this.seamsDataForm.controls.formArray['controls'][i].controls.requirements.value.length > 500) {
        this.seamsDataForm.controls.formArray['controls'][i].controls.requirements.setErrors({ 'incorrect': true });
      }
    }
    // stop here if form is invalid
    if (this.seamsDataForm.invalid) {
      this.showNote = true;
      this.error(this.staticMsgs.seamsLibrary.CheckInvalidFields)
      //alert('FORM INVALID :(');
      return;
    }

    // copy form data to Seams array on success
    if(this.seamsViewMode === 'Shared'){
      this.sharedGridData = this.fa.value;
    } else {
      this.seamsData = this.fa.value;
    }
    this.closeAllRows();
    let updSeamsList = this.seamsViewMode === 'Shared' ? this.sharedGridData.filter((x, i) => this.jsonCompare(x, i, this.originalData)) : this.seamsData.filter((x, i) => this.jsonCompare(x, i, this.originalData));
    console.log(updSeamsList);
    if (updSeamsList.length > 0) {
      this.showLoader = true;
      for (let i = 0; i < updSeamsList.length; i++) {
        updSeamsList[i].commonApplication = this.aesdecryptservice.encryptData(this.dataService.Key, updSeamsList[i].commonApplication);
        updSeamsList[i].isO4916Number = this.aesdecryptservice.encryptData(this.dataService.Key, updSeamsList[i].isO4916Number);
        updSeamsList[i].name = this.aesdecryptservice.encryptData(this.dataService.Key, updSeamsList[i].name);
        updSeamsList[i].status = this.aesdecryptservice.encryptData(this.dataService.Key, updSeamsList[i].status);
        updSeamsList[i].seam751aNumber = this.aesdecryptservice.encryptData(this.dataService.Key, updSeamsList[i].seam751aNumber);
        updSeamsList[i].requirements = this.aesdecryptservice.encryptData(this.dataService.Key, updSeamsList[i].requirements);

      }

      this.store.dispatch(new seamsActionTypes.UpdateSeamsLibrary(updSeamsList));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedSeamsLibrary) => {
        /*  if (colorLibrary.moodboardState.error === "") {
           if(colorLibrary.moodboardState.ids[0]!== undefined){
           } */
        if (updatedSeamsLibrary.seams.error === "") {
          if (updatedSeamsLibrary.seams.message?.item2 === 'Success') {
      this.showNote = false;
            this.success(this.staticMsgs.seamsLibrary.UpdateSeamSuccess);
            this.logger.info(this.staticMsgs.seamsLibrary.UpdateSeamSuccess, this.staticMsgs.seamsLibrary.Seamlibrary);
            this.seamsViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
            this.showLoader = false;
          } else {
            this.showLoader = false;
            this.seamsViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
            this.error("Failed:" + updatedSeamsLibrary.seams.message?.item1)
            this.logger.error(updatedSeamsLibrary.seams.message?.item1, this.staticMsgs.seamsLibrary.Seamlibrary);
          }

        } else {
          this.logger.error(updatedSeamsLibrary.seams.error.error.message, this.staticMsgs.seamsLibrary.Seamlibrary);
          this.getError(updatedSeamsLibrary.seams.error);
          this.showLoader = false;
        }
      })
     }
    if (this.originalData.length != (this.seamsViewMode == 'Shared' ? this.sharedGridData.length : this.seamsData.length)) {
      this.showLoader = true;
      let deleterecordIds = [];
      var DelseamsList;
        if(this.seamsViewMode === 'Shared'){
          DelseamsList = this.originalData.filter((x, i) => this.jsonDeleteCompare(x, i, this.sharedGridData));
        } else {
          DelseamsList = this.originalData.filter((x, i) => this.jsonDeleteCompare(x, i, this.seamsData));
        }
      if (DelseamsList.length > 0) {
        DelseamsList.forEach((x, i) => {
          deleterecordIds.push(x.id);

        });
        this.store.dispatch(new seamsActionTypes.DeleteSeamsLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedSeamsLibrary) => {
          if (deletedSeamsLibrary.seams.error === "") {
            if (deletedSeamsLibrary.seams.message?.item2 === 'Success') {

              this.showNote = false;
              this.success(this.staticMsgs.seamsLibrary.DeleteSeamSucess);
              this.logger.info(this.staticMsgs.seamsLibrary.DeleteSeamSucess, this.staticMsgs.seamsLibrary.Seamlibrary);
              this.seamsViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              this.showLoader = false;
            } else {
              this.seamsViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              this.showLoader = false;
              this.error("Failed:" + deletedSeamsLibrary.seams.message?.item1)
              this.logger.error(deletedSeamsLibrary.seams.message?.item1, this.staticMsgs.seamsLibrary.Seamlibrary);
            }

          } else {
            this.logger.error(deletedSeamsLibrary.seams.error.error.message, this.staticMsgs.seamsLibrary.Seamlibrary);
            this.getError(deletedSeamsLibrary.seams.error);
            this.showLoader = false;
          }
        })
      }
    }
    this.isEditMode = false;
    this.loading = false;
  }

  decryptData(x){
    x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
    x.seam751aNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.seam751aNumber);
    x.isO4916Number = this.aesdecryptservice.decryptData(this.dataService.Key, x.isO4916Number);
    x.commonApplication = this.aesdecryptservice.decryptData(this.dataService.Key, x.commonApplication);
    x.requirements = this.aesdecryptservice.decryptData(this.dataService.Key, x.requirements);
    x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
    if (x.seamDrawingFile) {
      x.seamDrawingFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.seamDrawingFile.thumbnail);
    }
  }

  loadSharedListData(){
    this.sharedStartEndListCount = this.sharedStartListCount + "-" + this.sharedEndListCount;
    this.dataService.getSeams(this.sharedStartEndListCount, this.seamsViewMode).subscribe(response => {
      if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
        this.isDataLoading = false;
        this.logger.info(this.staticMsgs.seamsLibrary.AllSeamLoadSuccess, this.staticMsgs.seamsLibrary.Seamlibrary);
        this.showLoader = false;
        this.sharedGridData = [];
        response['body']['item1'].forEach(x => {
          this.decryptData(x);
          this.sharedGridData.push(x);
        });
        this.totalSharedRecordCount = response['body']['item3'];
        if (this.sharedGridData.length > 0) {
          this.sharedNotFound = false;
        } else {
          this.sharedNotFound = true;
        }
        this.sharedGridData.forEach((x, i) => {
          this.fa.push(this.createFormGroup(x));
        });
        var gridView = {
          data: this.sharedGridData,
          total: this.totalSharedRecordCount,
        };
        this.gridData = gridView;
        this.loading = false;
      } else {
        this.isSharedScrollDown = true;
        if (response['body']['item2'] !== 'Success') {
          this.error("Failed:" + response['body']['item1'])
          this.logger.error(response['body']['item1'], this.staticMsgs.seamsLibrary.Seamlibrary);
        }
        this.showLoader = false;
        this.sharedNotFound = true;
      }
    }, err => {
      this.getError(err);
      this.logger.error(err.error.message,this.staticMsgs.seamsLibrary.Seamlibrary);
      this.showLoader = false;
    })
}

  loadSharedData() {
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', this.data1);
    }, 10);
    if (!this.isSharedGetAllRecords) {
      this.sharedStartEndCount = this.sharedStartCount + "-" + this.sharedEndCount;
      this.dataService.getSeams(this.sharedStartEndCount, this.seamsViewMode).subscribe(response => {
        if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
          this.isDataLoading = false;
          this.logger.info(this.staticMsgs.seamsLibrary.AllSeamLoadSuccess, this.staticMsgs.seamsLibrary.Seamlibrary);
          this.showLoader = false;
          response['body']['item1'].forEach(x => {
            this.decryptData(x);
            this.sharedCards.push(x);
            this.sharedCards = this.sharedCards.filter((item, index) => this.sharedCards.indexOf(item) === index)
          });
          this.sharedCopyCard = [];
          this.sharedCopyCard = this.sharedCards;
          this.totalSharedRecordCount = response['body']['item3'];
          if (this.sharedCards.length > 0) {
            this.sharedNotFound = false;
          } else {
            this.sharedNotFound = true;
          }
          if (this.sharedCards.length == this.totalSharedRecordCount) {
            this.isSharedGetAllRecords = true;
          }
          else {
            this.isSharedScrollDown = true;
          }
          this.onSelect(this.data1, 'shared');
        } else {
          this.isSharedScrollDown = true;
          if (response['body']['item2'] !== 'Success') {
            this.error("Failed:" + response['body']['item1'])
            this.logger.error(response['body']['item1'], this.staticMsgs.seamsLibrary.Seamlibrary);
          }
          this.showLoader = false;
          this.sharedNotFound = true;
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, this.staticMsgs.seamsLibrary.Seamlibrary);
        this.showLoader = false;
      })
    }
  }

  loadData() {
    this.startEndCount = this.startCount + "-" + this.endCount;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val',this.data1);
    }, 10);
    this.store.dispatch(new seamsActionTypes.LoadSeamsLibrary(this.startEndCount, this.seamsViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((seamsLibrary) => {
      if (seamsLibrary.seams.error === "") {
        if (seamsLibrary.seams.message?.item2 === 'Success') {
          this.showLoader1 = false;
          this.totalRecordCount = seamsLibrary.seams.message?.item3;
          this.cards = this.store.pipe(select(seamsReducer.getSeamsLibrary));
          this.copyCard = this.store.pipe(select(seamsReducer.getSeamsLibrary));
          this.logger.info(this.staticMsgs.seamsLibrary.AllSeamLoadSuccess, this.staticMsgs.seamsLibrary.Seamlibrary);
          this.fa.clear();
          this.seamsData = seamsLibrary.seams.message?.item1;
          this.dbseamsData = seamsLibrary.seams.message?.item1;
          console.log(this.seamsData,'seamsdata');
          this.seamsData.forEach(x=>{
            if(x.isFavourite==false){
              this.addToFavorite=false;
              console.log(this.addToFavorite,'addfv')
            }
            else{
              this.addToFavorite=true;
              console.log(this.addToFavorite,'addfv1')
            }

          })
          if(this.seamsData.length > 0){
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.seamsData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          if (seamsLibrary.seams.ids.length == this.totalRecordCount) {
            this.isGetAllRecords = true;
          }
          else {
            this.isScrollDown = true;
          }
          // if(this.data1=='Approved'){
            this.onSelect(this.data1,'myseams')
          // }
          // else if(this.data1=='dropped'){
          //   this.onSelect('dropped','myseams');

          // }
          // else{
          //   this.onSelect('In Review','myseams');

          // }
          //this.gridData = process(this.paletteData, this.state);
          //this.("Material");
        } else {
          this.showLoader1 = false;
          this.notFound = true;
          this.error("Failed:" + seamsLibrary.seams.message?.item1)
          this.logger.error(seamsLibrary.seams.message?.item1, this.staticMsgs.seamsLibrary.PaletteLibrary);
        }

      } else {
        this.isScrollDown = true;
        this.isGetAllRecords = true;
        this.logger.error(seamsLibrary.seams.error.error.message, this.staticMsgs.seamsLibrary.PaletteLibrary);
        this.getError(seamsLibrary.seams.error);
        this.showLoader1 = false;
      }
    })
  }

  loadDataList() {
    this.loading = true;
    this.startEndListCount = this.startCountList + "-" + this.endCountList;
    this.store.dispatch(new seamsActionTypes.LoadSeamsLibrary(this.startEndListCount, this.seamsViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((seamsLibrary) => {
      if (seamsLibrary.seams.error === "") {
        if (seamsLibrary.seams.message?.item2 === 'Success') {
          this.totalRecordCount = seamsLibrary.seams.message?.item3;
          this.cards = this.store.pipe(select(seamsReducer.getSeamsLibrary));
          this.copyCard = this.store.pipe(select(seamsReducer.getSeamsLibrary));
          this.logger.info(this.staticMsgs.seamsLibrary.AllSeamLoadSuccess,this.staticMsgs.seamsLibrary.Seamlibrary);
          this.fa.clear();
          this.seamsData = seamsLibrary.seams.message?.item1;
          this.dbseamsData = seamsLibrary.seams.message?.item1;
          if(this.seamsData.length > 0){
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.seamsData?.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          var gridView = {
            data: this.seamsData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView;
          this.loading = false;
          this.onSelect(this.data1,'myseams')
        } else {
          this.loading = false;
          this.notFound = true;
          this.error("Failed:" + seamsLibrary.seams.message?.item1)
          this.logger.error(seamsLibrary.seams.message?.item1, this.staticMsgs.seamsLibrary.PaletteLibrary);
        }

      } else {
        this.logger.error(seamsLibrary.seams.error.error.message, this.staticMsgs.seamsLibrary.PaletteLibrary);
        this.getError(seamsLibrary.seams.error);
        this.loading = false;
      }
    })
  }

  searchListData(val: string) {
    if(this.seamsViewMode === 'Shared'){
      if (val !== "") {
        let arr = [];
        this.filterQuery = val;
        this.filteredData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) { return data; }
        });
      }
      else {
        this.filteredData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
    if (val !== "") {
      let arr = [];
      this.filterQuery = val;
      this.filteredData = this.seamsData.filter((data) => {
        var search = this.filterQuery.toString().toLowerCase();
        var values = Object.values(data);
        var indicator = false;
        values.forEach((vl) => {
          if (String(vl).toLowerCase().indexOf(search) > -1) {
            indicator = true;
            return;
          }
        });
        if (indicator) { return data; }
      });
    }
    else {
      this.filteredData = this.seamsData;
      this.state.take = 5;
      //return
    }
  }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    //this.seamsData = this.filteredData;

    var gridView = {
      data: this.filteredData,
      total: this.seamsViewMode=='Shared' ? this.totalSharedRecordCount: this.totalRecordCount,
    };
    this.gridData = gridView;
    this.filteredXcelData = filterBy(this.filteredData, this.state.filter);

  }

  createSeams() {
    this.dataService.AddClientLog();
    this.router.navigate(['/seamsdetails']);
  }

  onEditSeams(id) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditedSeamsId(id);
    this.router.navigate(['/seamsdetails']);
  }

  onEditSeamsDetails(id) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditedSeamsId(id);
    this.storageservice.storeEditedSeamsDetailsId(id);
    this.router.navigate(['/seamsdetails']);
  }

  onDeleteSeams(id) {

    Swal.fire({
      title: this.staticMsgs.seamsLibrary.ConfirmationtoDelete,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(id);
        this.store.dispatch(new seamsActionTypes.DeleteSeamsLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedSeamsLibrary) => {
          /*  if (colorLibrary.moodboardState.error === "") {
             if(colorLibrary.moodboardState.ids[0]!== undefined){
             } */
          if (deletedSeamsLibrary.seams.error === "") {
            if (deletedSeamsLibrary.seams.message?.item2 === 'Success') {
              this.success(this.staticMsgs.seamsLibrary.DeleteSeamSucess);
              this.logger.info(this.staticMsgs.seamsLibrary.DeleteSeamSucess,this.staticMsgs.seamsLibrary.Seamlibrary);

                this.onSelect(this.data1,'myseams')

              this.loadDataList();

              this.showLoader = false;
            } else {
              this.showLoader = false;
              this.error("Failed:" + deletedSeamsLibrary.seams.message?.item1)
              this.logger.error(deletedSeamsLibrary.seams.message?.item1, this.staticMsgs.seamsLibrary.Seamlibrary);
            }

          } else {
            this.logger.error(deletedSeamsLibrary.seams.error.error.message, this.staticMsgs.seamsLibrary.Seamlibrary);
            this.getError(deletedSeamsLibrary.seams.error);
            this.showLoader = false;
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    if ((element.nodeName === 'TH') && (element.offsetWidth < element.scrollWidth)) {
      this.tooltipDir.toggle(element);
    }
    else if ((element.offsetWidth < element.scrollWidth) && (element.scrollWidth! < 937) && (this.isEditMode == false)) {
      this.tooltipDir.toggle(element);
    }
    else {
      this.tooltipDir.hide();
    }
  }

  onCancel() {
    this.closeAllRows();
    this.showNote = false;
    // reset apiGridData back to original data (before edit was clicked)
    this.seamsData = this.originalData;
    this.seamsViewMode === 'Shared' ? this.sharedGridData :this.seamsData = this.originalData;
    var gridView = {
      data: this.seamsViewMode === 'Shared' ? this.sharedGridData :this.seamsData,
      total: this.seamsViewMode === 'Shared' ? this.totalSharedRecordCount :this.totalRecordCount,
    };
    this.gridData = gridView;
    this.isEditMode = false;
  }

  private closeAllRows() {
    // close all rows to display readonly view of data
    if (this.seamsViewMode === 'Shared') {
      // close all rows to display readonly view of data
      this.sharedGridData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    } else {
      this.seamsData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    }
  }

  AddToFavorite(e) {
    this.showLoader = true;
    console.log(this.seamsData,'semasdata');
    let selected = e.target.id.replace("checkbox", "");
    if (selected != '' && e.target.checked == true) {
      let data: Favourite[] = [];
      data.push(new Favourite(selected));
      this.dataService.bulkAddFavourites(data, "Seams").subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.success(this.staticMsgs.seamsLibrary.AddToFvSuccess);
            this.logger.info(this.staticMsgs.seamsLibrary.AddToFvSuccess, this.staticMsgs.seamsLibrary.Seamlibrary);

          }
          else {
            console.log(response)
            //alert(response.body['item2'])
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], this.staticMsgs.seamsLibrary.Seamlibrary);
            this.showLoader = false;
          }
        }
      }, err => {
        this.logger.error(err.error.message, this.staticMsgs.seamsLibrary.Seamlibrary);
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, this.staticMsgs.seamsLibrary.Seamlibrary);
        }
        this.showLoader = false;
      });
    }
    else if (selected != '' && e.target.checked == false) {
      let data: string[] = [];
      data.push(selected);
      this.dataService.bulkDeleteFavourites(data, "Seams").subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.success(this.staticMsgs.seamsLibrary.RemoveFromFv);
            this.logger.info(this.staticMsgs.seamsLibrary.RemoveFromFv, this.staticMsgs.seamsLibrary.Seamlibrary);



          }
          else {
            console.log(response)
            //alert(response.body['item2'])
            this.error(response.body['item1']);
            this.showLoader = false;
          }
        }
      }, err => {
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
        }
        this.showLoader = false;
      });
    }
  }

  dblClick(e) {
    if (this.clickedRowItem.dataItem.isEdit) {
      this.isEditMode = true;
      this.closeAllRows();
      var dataindx;
      if (this.seamsViewMode === 'Shared') {
        // store copy of original documents in case cancelled
        this.originalData = [...this.sharedGridData];

        // set single row to edit mode to display form fields
        dataindx = this.sharedGridData.indexOf(this.clickedRowItem.dataItem);
      } else {
        // store copy of original documents in case cancelled
        this.originalData = [...this.seamsData];

        // set single row to edit mode to display form fields
        dataindx = this.seamsData.indexOf(this.clickedRowItem.dataItem);
      }

      var seamsRow = this.fa.controls[dataindx] as FormGroup;
      this.grid.editRow(this.clickedRowItem.rowIndex, seamsRow);
    }
    else {
      alert(this.staticMsgs.seamsLibrary.NopermissiontoEdit);

    }

  }
  editClick( rowIndex, dataItem ) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditedSeamsId(dataItem.id);
    this.storageservice.storeEditedSeamsDetailsId(dataItem.id);
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    const dialogRef = this.dialog.open(SeamsCustomComponent,{
      disableClose:true
    });
    dialogRef.afterClosed().subscribe(result=>{
    this.loadDataList();
    })
  }

  clearSearch() {
    this.clearFields();
    this.loadData();
    this.loadDataList();
    this.isResetMode = false;
  }
  clearFields() {
    this.createdStartDate = "";
    this.createdEndDate = "";
    this.createdFromDate = "";
    this.createdToDate = "";
    this.modifiedFromDate = "";
    this.modifiedToDate = "";
    this.modifiedStartDate = "";
    this.modifiedEndDate = "";
    this.f1.keyword.patchValue("");
    this.f1.createdBy.patchValue("");
    this.f1.status.patchValue("");
    this.f1.commonApplication.patchValue("");
    this.f1.seam751aNumber.patchValue("");
    this.f1.isO4916Number.patchValue("");
    $("#statuslist").val('default').selectpicker("refresh");
    this.getClassificationData(); this.seamsclassificationValue = "";
    this.enableCreatFromVal = null;
    this.enableCreatToVal = null;
    this.enableModifFromVal = null;
    this.enableModifToVal = null;
    this.enableKeywordVal = null;
    this.enableStatusVal = null;
    this.enableCreatedByVal = null;
    this.seamsclassificationValue = "";
  }
  clearfield(name) {
    switch (name) {
      case "createdFromChild": {
        this.createdStartDate = "";
        this.createdEndDate = "";
        this.createdFromDate = "";
        this.createdToDate = "";
        this.enableCreatFromVal = null;
        this.enableCreatToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "modifiedFromChild": {
        this.modifiedFromDate = "";
        this.modifiedToDate = "";
        this.modifiedStartDate = "";
        this.modifiedEndDate = "";
        this.enableModifFromVal = null;
        this.enableModifToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "keyword": { this.f1.keyword.patchValue(""); this.enableKeywordVal = null; this.checkAllFieldVal(); break; }
      case "createdBy": { this.f1.createdBy.patchValue(""); this.enableCreatedByVal = null; this.checkAllFieldVal(); break; }
      case "classification": { this.getClassificationData(); this.seamsclassificationValue = ""; this.checkAllFieldVal(); break; }
      case "status": { this.f1.status.patchValue(""); $("#statuslist").val('default').selectpicker("refresh"); this.enableStatusVal = null; this.checkAllFieldVal(); break; }
    }
  }

  checkAllFieldVal() {
    if ((this.f1.keyword.value == "") && (this.createdFromDate == "") && (this.modifiedFromDate == "") && (this.f1.createdBy.value == "") && ((this.f1.status.value == "") || (this.f1.status.value == undefined)) && (this.seamsclassificationValue == "")) {
      this.clearSearch()
    }
  }

  checkFieldVal(val: string[], val1: string) {
    switch (val1) {
      case "commonApplication": { this.enablecommonApplication = val; break; }
      case "isO4916Number": { this.enableisO4916Number = val; break; }
      case "seam751aNumber": { this.enableseam751aNumber = val; break; }
      case "createdFromChild": { this.enableCreatFromVal = val; break; }
      case "createdToChild": { this.enableCreatToVal = val; break; }
      case "modifiedFromChild": { this.enableModifFromVal = val; break; }
      case "modifiedToChild": { this.enableModifToVal = val; break; }
      case "keyword": { this.enableKeywordVal = val; break; }
      case "createdBy": { this.enableCreatedByVal = val; break; }
      case "status": { this.enableStatusVal = val; break; }
    }
  }
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      this.staticMsgs.seamsLibrary.success
    )
  }
  error(message: string) {
    Swal.fire({
      icon: this.staticMsgs.seamsLibrary.error,
      title: this.staticMsgs.seamsLibrary.Oops,
      text: message
    })
  }
  setDate(event: any) {
    console.log(event.target.value);
    if (event.target.getAttribute("formControlName") == "createdFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      //this.createdToChild.nativeElement.value = createdToChildStr;
    }
    else if (event.target.getAttribute("formControlName") == "modifiedFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      // this.modifiedToChild.nativeElement.value = createdToChildStr;
    }
  };
  get f1() { return this.advForm.controls; }

  onSubmit(value) {
    console.log(value);
    this.showLoader = true;
    let createdFromChildStr = this.createdStartDate;
    let createdToChildStr = this.createdEndDate;
    let modifiedFromChildStr = this.modifiedStartDate;
    let modifiedToChildStr = this.modifiedEndDate;

    if ((createdFromChildStr !== null && createdToChildStr == null) && (createdFromChildStr !== undefined && createdToChildStr == undefined)) {
      this.error(this.staticMsgs.seamsLibrary.CreatedToMandatory);
      this.logger.error(this.staticMsgs.seamsLibrary.CreatedToMandatory, this.staticMsgs.seamsLibrary.Seamlibrary);

      this.showLoader = false;
      return;
    }
    else if ((createdFromChildStr == null && createdToChildStr !== null) && (createdFromChildStr == undefined && createdToChildStr !== undefined)) {
      this.error(this.staticMsgs.seamsLibrary.CreatedFromMandatory);
      this.logger.error(this.staticMsgs.seamsLibrary.CreatedFromMandatory,this.staticMsgs.seamsLibrary.Seamlibrary);

      this.showLoader = false;
      return;
    }
    if ((modifiedFromChildStr !== null && modifiedToChildStr == null) && (modifiedFromChildStr !== undefined && modifiedToChildStr == undefined)) {
      this.error(this.staticMsgs.seamsLibrary.ModifiedToMandatory);
      this.logger.error(this.staticMsgs.seamsLibrary.ModifiedToMandatory, this.staticMsgs.seamsLibrary.Seamlibrary);
      this.showLoader = false;
      return;
    }
    else if ((modifiedFromChildStr == null && modifiedToChildStr !== null) && (modifiedFromChildStr == undefined && modifiedToChildStr !== undefined)) {
      this.error(this.staticMsgs.seamsLibrary.ModifiedFromMandatory);
      this.logger.error(this.staticMsgs.seamsLibrary.ModifiedFromMandatory, this.staticMsgs.seamsLibrary.Seamlibrary);
      this.showLoader = false;
      return;
    }

    let adv_data = {
      seam751aNumber: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.seam751aNumber.value),
      isO4916Number: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.isO4916Number.value),
      commonApplication: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.commonApplication.value),
      keyword: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.keyword.value),
      createdBy: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.createdBy.value),
      status: this.f1.status.value,
      createdFrom: createdFromChildStr,
      createdTo: createdToChildStr,
      modifiedFrom: modifiedFromChildStr,
      modifiedTo: modifiedToChildStr
    }
    //console.log(JSON.stringify(adv_data));
    this.store.dispatch(new commonActionTypes.LoadAdvancedSeamsSearch(adv_data));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((advancedSearchData) => {
      if (advancedSearchData.common.error === "") {
        if (advancedSearchData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.seamsLibrary.AdvanceSerachSeamLoadSuccess, this.staticMsgs.seamsLibrary.Seamlibrary);
          this.cards = this.store.pipe(select(commonReducer.getCommon));
          this.copyCard = this.store.pipe(select(commonReducer.getCommon));
          this.seamsData = advancedSearchData.common.message['item1'];
          this.isResetMode = true;
          this.showLoader = false;
          var gridView = {
            data: this.seamsData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView;
        } else {
          if (advancedSearchData.common.message['item1'].toLowerCase().includes("no records")) {
            this.error(this.staticMsgs.seamsLibrary.NoDataAvailable);
            this.logger.error(this.staticMsgs.seamsLibrary.NoDataAvailable, this.staticMsgs.seamsLibrary.ImageLibrary);
            var gridView = {
              data: this.seamsData,
              total: this.totalRecordCount,
            };
            this.seamsData = [];
            this.cards = of([]);
            this.copyCard = of([]);
            this.gridData = gridView;
            this.showLoader = false;
          } else {
            this.error("Failed:" + advancedSearchData.common.message['item1'])
            this.logger.error(advancedSearchData.common.message['item1'], this.staticMsgs.seamsLibrary.ImageLibrary);
            this.showLoader = false;
            this.clearSearch();
          }

        }
      } else {
        this.logger.error(advancedSearchData.common.error.error.message, this.staticMsgs.seamsLibrary.ImageLibrary);
        this.getError(advancedSearchData.common.error);
        this.showLoader = false;
      }
    })
  }

  HandleError(err) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
    }
  }

  onRemove(index, dataItem) {
    Swal.fire({
      title: this.staticMsgs.seamsLibrary.ConfirmationtoDelete,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        // rows must all be closed while removing colorData
        this.closeAllRows();
        if (this.seamsViewMode === 'Shared') {
          let dataIndex = this.sharedGridData.indexOf(dataItem);
          // remove product and product form group

          this.sharedGridData = JSON.parse(JSON.stringify(this.sharedGridData));
          this.sharedGridData.splice(dataIndex, 1);
          this.fa.removeAt(dataIndex);

          this.filteredData = filterBy(this.sharedGridData, this.state.filter);
          this.success('Seams Deleted Successfully');
        } else {
          let dataIndex = this.seamsData.indexOf(dataItem);
          // remove product and product form group
          this.seamsData = JSON.parse(JSON.stringify(this.seamsData))
          this.seamsData.splice(dataIndex, 1);
          this.fa.removeAt(dataIndex);
          this.filteredData = filterBy(this.seamsData, this.state.filter);
          // this.success('Deleted Successfuuly');
        }
        if (this.SearchData != null && this.SearchData != "") {

          this.searchListData(this.SearchData);
        }
        if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
        // this.colorData = this.filteredData;
        //  this.gridData = this.filteredData;
        this.gridData = process(this.filteredData, this.state);
        // reset all rows back to edit mode
        this.editGridRows();
         this.success('Seams Deleted Successfully');
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });

  }
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.seamsLibrary.SessionExpired,
      html: this.staticMsgs.seamsLibrary.loginAgain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
      }
    })
  }

  dateChange(date: any, type: any) {
    switch (type) {
      case 'createdFromDate':
        this.createdStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdFromDate && this.createdFromDate !== undefined) {
          this.checkFieldVal(['val'], 'createdFromChild');
        }
        break;
      case 'createdToDate':
        this.createdEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdToDate && this.createdToDate !== undefined) {
          this.checkFieldVal(['val'], 'createdToChild')
        }
        break;
      case 'modifiedFromDate':
        this.modifiedStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedFromDate && this.modifiedFromDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedFromChild')
        }
        break;
      case 'modifiedToDate':
        this.modifiedEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedToDate && this.modifiedToDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedToChild')
        }
        break;
    }
  }
}
