import { Component, HostBinding, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, DatePipe } from '@angular/common';
import { LoaderComponent } from '../../../../../shared/shared-components/loader/loader.component';
import { StorageService } from '../../helpers/storage.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../helpers/user.service';
import { SharedService } from '../../services/shared.service';
import { DataService } from '../../services/data.service';
import { LogService } from '../../shared/log.service';
import Swal from 'sweetalert2';
import { AESDecryptService } from '../../helpers/AESDecrypt.service';
import { environment } from '../../../environments/environment';
import * as appStaticMsg from '../../../assets/appStaticMsg.json';

declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {
  @HostBinding('class') classNames = 'dt-root__inner';
  @ViewChild(LoaderComponent) child: LoaderComponent;

  activeNavDrawer: boolean;
  showLoader = false;
  recentActivities: any;
  recentActivitiesArray: any[] = [];
  recentFavourites: any;
  recentFavouritesArray: any[] = [];
  agentthumbnail: string = "../../../assets/images/suppliercard/agent.png";
  thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  userThumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";
  recentNotifications: any;
  totalNotifications: any;
  public notifyStartCount = 0;
  public notifyEndCount = 15;
  public notifyStartEndCount = "";
  public favStartCount = 0;
  public favEndCount = 15;
  public favStartEndCount = "";
  public activityStartCount = 0;
  public activityEndCount = 500;
  public activityStartEndCount = "";
  showMore = true;
  favouritesCount: any;
  recenetNotificationsArray: any[] = [];
  totalRecentActivities: any;
  activityTempArray: any[] = [];
  limitReached = false;
  recenetActivityNotFound = false;
  recentNotificationsNotFound = false;
  recentFavouritesNotFound = false;
  dontShow: any;
  staticMSgs:any;
  currentIndex: number = 0;
  chunkSize: number = 5;
  constructor(
    private auth: AuthService,
    private storageService: StorageService,
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService,
    private dataService: DataService,
    private logger: LogService,
    @Inject(DOCUMENT) private document: any,
    private userservice: UserService,
    private aesdecryptservice: AESDecryptService,
    public datepipe: DatePipe) {
    this.document.body.classList.add('dt-sidebar--fixed');
    this.document.body.classList.add('dt-layout--full-width');
    this.document.body.classList.add('dt-layout--modern');
    this.document.body.classList.add('dt-header--fixed');
  }

  ngOnInit(): void {
    this.staticMSgs=appStaticMsg.data[0];
    if(this.storageService.getToken()){
      this.document.getElementById('tab-pane1').addEventListener('scroll', this.loadFavourites, true);
      this.document.getElementById('notifications').addEventListener('scroll', this.loadNotifications, true);
      this.activeNavDrawer = false;
      this.sharedService.sendClickEvent();
      this.getFavourites();
      this.getNotifications();
      this.getRecentActivities();
    } else {
      this.router.navigate(['/auth/login']);
    }

    let dShow = localStorage.getItem('dontShow');
    if(dShow == 'yes'){
      $('#dashboard-modal').modal('hide');
        this.dontShow = false;
    }else{
      $('#dashboard-modal').modal('show');
      this.dontShow = true;
    }
  }

  loadFavourites = (event: any): void => {
    let tracker = event.target;
    let limit = (tracker.scrollHeight - tracker.clientHeight) - 2;
    if (event.target.scrollTop >= limit) {
      this.favStartCount = this.favStartCount + this.favEndCount;
      this.favEndCount = this.favStartCount + 15;
      if (this.favStartCount <= this.favouritesCount) {
        this.getFavourites();
      }
    }
  }

  loadNotifications = (event: any): void => {
    let tracker = event.target;
    let limit = (tracker.scrollHeight - tracker.clientHeight) - 2;
    if (event.target.scrollTop >= limit) {
      this.notifyStartCount = this.notifyStartCount + this.notifyEndCount;
      this.notifyEndCount = this.notifyStartCount + 15;
      if (this.notifyStartCount <= this.totalNotifications) {
        this.getNotifications();
      }
    }
  }


  /**
   * On click overlay
   */
  onClickOverlay() {
    this.activeNavDrawer = false;
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMSgs.leftBar.SessionExpired,
      html: this.staticMSgs.leftBar.loginAgain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        //this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
        return;
      }
    })

  }

  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title:this.staticMSgs.usermanagement.Oops,
      text: message
    })
  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader = false;
  }

  getActivityItems(type: any) {
    if (type === 'next' && !this.limitReached) {
      if(this.activityEndCount < this.totalRecentActivities){
      this.activityStartCount = this.activityEndCount;
      this.activityEndCount = this.activityStartCount + 5;
      this.limitReached = (this.activityEndCount == this.totalRecentActivities) ? true : false;
      if (this.activityStartCount < this.totalRecentActivities) {
        this.getRecentActivities();
      }
    }
    } else if (type == 'prev') {
      if ((this.activityEndCount - 5) !== 0 && (this.activityEndCount !==5)) {
        this.limitReached = false;
        this.activityStartCount = this.activityEndCount - 10;
        this.activityEndCount = this.activityEndCount - 5;
        if (this.activityStartCount <= this.totalRecentActivities) {
          this.getRecentActivities();
        }
      }
    }
  }

  getRecentActivities() {
    this.showLoader = true;
    this.activityStartEndCount = this.activityStartCount + '-' + this.activityEndCount;
    this.dataService.getRecentActivities(this.activityStartEndCount).subscribe(response => {
      if (response['body']['item2'] === 'Success') {
        if(response['body']['item3'] > 0){
          this.recenetActivityNotFound = false;
          this.recentActivitiesArray = [];
          this.recentActivities = response['body']['item1'];
          this.totalRecentActivities = response['body']['item3'];
          this.activityStartCount = 0;
          if (this.recentActivities.recentColours && this.recentActivities.recentColours.length > 0) {
            this.recentActivities.recentColours.forEach(x => {
              x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
              x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
              if (x.hexcode) {
                x.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, x.hexcode)
              } else {
                x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles)
              }
              x['library'] = 'Color'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          if (this.recentActivities.recentMoodboards && this.recentActivities.recentMoodboards.length > 0) {
            this.recentActivities.recentMoodboards.forEach(x => {
              x.title = this.aesdecryptservice.decryptData(this.dataService.Key, x.title)
              x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
              if (x.thumbnailFile) {
                x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFile.thumbnail)
              } else {
                x['image'] = this.thumbnail;
              }
              x['library'] = 'Moodboard'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          if (this.recentActivities.recentDocuments && this.recentActivities.recentDocuments.length > 0) {
            this.recentActivities.recentDocuments.forEach(x => {
              x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
              x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
              if (x.thumbnailFiles) {
                x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail)
              } else {
                x['image'] = this.thumbnail;
              }
              x['library'] = 'Document'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          if (this.recentActivities.recentMaterials && this.recentActivities.recentMaterials.length > 0) {
            this.recentActivities.recentMaterials.forEach(x => {
              x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
              x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
              if (x.thumbnailFiles) {
                x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail)
              } else {
                x['image'] = this.thumbnail;
              }
              x['library'] = 'Material'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          if (this.recentActivities.recentPalettes && this.recentActivities.recentPalettes.length > 0) {
            this.recentActivities.recentPalettes.forEach(x => {
              x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
              x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
              x.colourHexCodes = this.aesdecryptservice.decryptData(this.dataService.Key, x.colourHexCodes)
              x['library'] = 'Palette'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          if (this.recentActivities.recentSeams && this.recentActivities.recentSeams.length > 0) {
            this.recentActivities.recentSeams.forEach(x => {
              x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
              x.isO4916Number = this.aesdecryptservice.decryptData(this.dataService.Key, x.isO4916Number)
              if (x.seamDrawingFile) {
                x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.seamDrawingFile.thumbnail)
              } else {
                x['image'] = this.thumbnail;
              }
              x['library'] = 'Seams'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          if (this.recentActivities.recentStitches && this.recentActivities.recentStitches.length > 0) {
            this.recentActivities.recentStitches.forEach(x => {
              x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
              x.isO4915Number = this.aesdecryptservice.decryptData(this.dataService.Key, x.isO4915Number)
              if (x.topViewFile) {
                x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.topViewFile.thumbnail)
              } else {
                x['image'] = this.thumbnail;
              }
              x['library'] = 'Stitches'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          if (this.recentActivities.recentStyles && this.recentActivities.recentStyles.length > 0) {
            this.recentActivities.recentStyles.forEach(x => {
              x.styleNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleNumber)
              x.styleName = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleName)
              if (x.styleImageThumbnail) {
                x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleImageThumbnail.thumbnail)
              } else {
                x['image'] = this.thumbnail;
              }
              x['library'] = 'Style'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          if (this.recentActivities.recentSuppliers && this.recentActivities.recentSuppliers.length > 0) {
            this.recentActivities.recentSuppliers.forEach(x => {
              x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
              x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
              x.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, x.hexcode)
              x['library'] = 'Supplier'
              this.recentActivitiesArray.push(x);
              this.activityTempArray.push(x);
            });
          }
          this.recentActivitiesArray.sort((a, b) => a.createdOn.localeCompare(b.createdOn));
          this.showLoader = false;
        } else {
          this.recenetActivityNotFound = true;
          this.showLoader = false;
        }

      } else {
        this.showLoader = false;
        this.error(response['body']['item1']);
        this.logger.error(response['body']['item1'], "Dashboard");
      }
    }, err => {
      this.showLoader = false;
      this.logger.error(err.error.message, this.staticMSgs.dashBoard.dashboard);
      this.getError(err);
    });
  }

  getNotifications() {
    this.totalNotifications = 0;
    this.notifyStartEndCount = this.notifyStartCount + '-' + this.notifyEndCount;
    this.dataService.getDashboardNotifications(this.notifyStartEndCount).subscribe(response => {
      if (response['body']['item2'] == 'Success') {
        this.recentNotifications = response['body']['item1'];
        this.totalNotifications = response['body']['item3'];
        this.notifyStartCount = 0;
        if (this.recentNotifications && this.recentNotifications.length > 0) {
          this.recentNotificationsNotFound = false;
          this.recentNotifications.forEach(x => {
            x.recordName = this.aesdecryptservice.decryptData(this.dataService.Key, x.recordName);
            x.message = this.aesdecryptservice.decryptData(this.dataService.Key, x.message);
            x.textMessage = this.aesdecryptservice.decryptData(this.dataService.Key, x.textMessage);
            x.notificationType = this.aesdecryptservice.decryptData(this.dataService.Key, x.notificationType);
            x.moduleName = this.aesdecryptservice.decryptData(this.dataService.Key, x.moduleName);

            x["timestampAgo"] = this.dateFilter(x.createdOn);
            if (x.senderUserDTO[0]) {
              x.senderUserDTO[0].fullName = this.aesdecryptservice.decryptData(this.dataService.Key, x.senderUserDTO[0].fullName);
              x.senderUserDTO[0].firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.senderUserDTO[0].firstName);
              x.senderUserDTO[0].lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.senderUserDTO[0].lastName);
              if (x.senderUserDTO[0].pictureID) {
                x["userThumbnail"] = this.aesdecryptservice.decryptData(this.dataService.Key, x.senderUserDTO[0].picture.thumbnail);
              }
              else {
                x["userThumbnail"] = '';
              }


            }
            if (x.moduleName == "Styles") {
              if (x.notificationType == "Chat") {
                x["message"] = environment.notificationStyleChatMessage;
              }

              if (x.notificationType == "Share") {
                x["message"] = environment.notificationStyleSharedMessage;
              }

              if (x.notificationType == "UnShare") {
                x["message"] = environment.notificationStyleUnSharedMessage;
              }
            }
            this.recenetNotificationsArray.push(x);
            console.log('recenetNotificationsArray',this.recenetNotificationsArray);
          });
        } else {
          this.recentNotificationsNotFound = true;
        }
      } else {
        this.showLoader = false;
        this.error(response['body']['item1']);
        this.logger.error(response['body']['item1'],this.staticMSgs.dashBoard.Dashboard);
      }
    }, err => {
      this.showLoader = false;
      this.logger.error(err.error.message, this.staticMSgs.dashBoard.dashboard);
      this.getError(err);
    });
  }

  getFavourites() {
    this.favStartEndCount = this.favStartCount + '-' + this.favEndCount;
    this.dataService.getDashboardFavourites(this.favStartEndCount).subscribe(response => {
      if (response['body']['item2'] == 'Success') {
        this.recentFavourites = response['body']['item1'];
        this.favStartCount = 0;
        this.favouritesCount = response['body']['item3'];
        if(this.favouritesCount > 0){
          this.recentFavouritesNotFound = false;
        if (this.recentFavourites.favColours && this.recentFavourites.favColours.length > 0) {
          this.recentFavourites.favColours.forEach(x => {
            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
            if (x.hexcode) {
              x.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, x.hexcode)
            } else {
              x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles?.thumbnail)
            }
            x['library'] = 'Color'
            this.recentFavouritesArray.push(x);
          });
        }
        if (this.recentFavourites.favDocuments && this.recentFavourites.favDocuments.length > 0) {
          this.recentFavourites.favDocuments.forEach(x => {
            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
            if (x.thumbnailFiles) {
              x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles?.thumbnail)
            } else {
              x['image'] = this.thumbnail;
            }
            x['library'] = 'Document'
            this.recentFavouritesArray.push(x);
          });
        }
        if (this.recentFavourites.favMaterials && this.recentFavourites.favMaterials.length > 0) {
          this.recentFavourites.favMaterials.forEach(x => {
            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
            if (x.thumbnailFiles) {
              x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles?.thumbnail)
            } else {
              x['image'] = this.thumbnail;
            }
            x['library'] = 'Material'
            this.recentFavouritesArray.push(x);
          });
        }
        if (this.recentFavourites.favMoodboards && this.recentFavourites.favMoodboards.length > 0) {
          this.recentFavourites.favMoodboards.forEach(x => {
            x.title = this.aesdecryptservice.decryptData(this.dataService.Key, x.title)
            if (x.thumbnailFile) {
              x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFile?.thumbnail)
            } else {
              x['image'] = this.thumbnail;
            }
            x['library'] = 'Moodboard'
            this.recentFavouritesArray.push(x);
          });
        }
        if (this.recentFavourites.favPalettes && this.recentFavourites.favPalettes.length > 0) {
          this.recentFavourites.favPalettes.forEach(x => {
            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
            x.colourHexCodes = this.aesdecryptservice.decryptData(this.dataService.Key, x.colourHexCodes)
            x['library'] = 'Palette'
            this.recentFavouritesArray.push(x);
          });
        }
        if (this.recentFavourites.favSeams && this.recentFavourites.favSeams.length > 0) {
          this.recentFavourites.favSeams.forEach(x => {
            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
            if (x.seamDrawingFile) {
              x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.seamDrawingFile.thumbnail)
            } else {
              x['image'] = this.thumbnail;
            }
            x['library'] = 'Seams'
            this.recentFavouritesArray.push(x);
          });
        }
        if (this.recentFavourites.favStitches && this.recentFavourites.favStitches.length > 0) {
          this.recentFavourites.favStitches.forEach(x => {
            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
            if (x.topViewFile) {
              x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.topViewFile?.thumbnail)
            } else {
              x['image'] = this.thumbnail;
            }
            x['library'] = 'Stitches'
            this.recentFavouritesArray.push(x);
          });
        }
        if (this.recentFavourites.favStyles && this.recentFavourites.favStyles.length > 0) {
          this.recentFavourites.favStyles.forEach(x => {
            x.styleNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleNumber)
            x.styleName = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleName)
            if (x.styleImageThumbnail) {
              x['image'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.styleImageThumbnail?.thumbnail)
            } else {
              x['image'] = this.thumbnail;
            }
            x['library'] = 'Style'
            this.recentFavouritesArray.push(x);
          });
        }
        if (this.recentFavourites.favSuppliers && this.recentFavourites.favSuppliers.length > 0) {
          this.recentFavourites.favSuppliers.forEach(x => {
            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence)
            x.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, x.hexcode)
            x['library'] = 'Supplier'
            this.recentFavouritesArray.push(x);
          });
        }
        this.recentFavouritesArray.sort((a, b) => a.createdOn.localeCompare(b.createdOn));
        this.showLoader = false;
      } else {
        this.recentFavouritesNotFound = true;
      }
      } else {
        this.showLoader = false;
        this.error(response['body']['item1']);
        this.logger.error(response['body']['item1'], this.staticMSgs.dashBoard.Dashboard);
      }
    }, err => {
      this.showLoader = false;
      this.logger.error(err.error.message, this.staticMSgs.dashBoard.dashboard);
      this.getError(err);
    });
  }

  redirectTo(library: string, activity: any) {
    switch (library) {
      case 'Color':
        this.dataService.AddClientLog();
        this.storageService.storeEditedColorId(activity.id);
        this.router.navigate(['/colordetails']);
        break;
      case 'Moodboard':
        this.dataService.AddClientLog();
        this.storageService.storeEditedMoodBoard({ 'moodBoardId': activity.id, moodBoardTitle: activity.title });
        this.router.navigate(['/moodboard']);
        break;
      case 'Document':
        this.dataService.AddClientLog();
        this.router.navigate(['/imagedetails']);
        this.storageService.storeEditedImageId(activity.id);
        break;
      case 'Material':
        this.dataService.AddClientLog();
        this.storageService.storeEditedMaterialId(activity.id);
        this.router.navigate(['/materialdetails']);
        break;
      case 'Palette':
        this.storageService.storeEditPaletteId(activity.id);
        this.dataService.AddClientLog();
        this.router.navigate(['/palettedetails']);
        break;
      case 'Seams':
        this.dataService.AddClientLog();
        this.storageService.storeEditedSeamsId(activity.id);
        this.router.navigate(['/seamsdetails']);
        break;
      case 'Stitches':
        this.storageService.storeEditStitchesId(activity.id);
        this.dataService.AddClientLog()
        this.router.navigate(['/stitchesdetails']);
        break;
      case 'Style':
        this.storageService.storeEditedStyleId(activity.id);
        this.dataService.AddClientLog();
        this.router.navigate(['/styledetails']);
        break;
      case 'Supplier':
        this.dataService.AddClientLog();
        this.router.navigate(['/supplierdetails']);
        this.storageService.storeEditedSupplierId(activity.id);
        break;
      default:
        break;
    }
  }

  get currentItems() {
    const start = this.currentIndex;
    const end = start + this.chunkSize;
    return this.recentActivitiesArray.slice(start, end);
  }

  get hasPrev(): boolean {
    return this.currentIndex - this.chunkSize >= 0;
  }

  get hasNext(): boolean {
    return this.currentIndex + this.chunkSize < this.totalRecentActivities;

  }

  nextChunk(): void {
    if (this.hasNext) {
      this.currentIndex += this.chunkSize;
    }
  }

  prevChunk(): void {
    if (this.hasPrev) {
      this.currentIndex -= this.chunkSize;
    }
  }

  dateFilter(date: any) {
    return this.datepipe.transform(date, 'EEE, d MMM')
  }

  clickNavigation(item) {
    if (item.moduleName == "Styles") {
      this.storageService.storeEditedStyleId(item.recordId);
      if (item.notificationType == "Chat") {
        this.storageService.storeStyleIdNotification(item.recordId);
      }
      //this.router.navigate(['/styledetails']);
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }

      var role = this.storageService.getUserSubRole();
      if (role !== "Supplier") {
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/styledetails']);
      }
      else {
        this.error(this.staticMSgs.dashBoard.roleDosenotHaveAccess);
      }

    }
  }

  dontshow(){
    console.log('dontshow');
    $('#dashboard-modal').modal('hide');
    localStorage.setItem('dontShow',"yes");
  }

  clickBtn(){
    // this.dataService.insertDataForNewUser().subscribe(res=>{
    //   console.log('res',res);
    // },err=>{
    //   console.log('err',err);
    // });
  }

}
