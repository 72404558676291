import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as stitchesActions from "../actions/stitches.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";


@Injectable()

export class StitchesEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }

    getAllStitchesDetails(data) {
      console.log('getAllStitchesDetails',data);
        data.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, data.sequence);
        data.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.name);
        data.isO4915Number = this.aesdecryptservice.decryptData(this.dataService.Key, data.isO4915Number);
        data.description = this.aesdecryptservice.decryptData(this.dataService.Key, data.description);
        data.topviewComments = this.aesdecryptservice.decryptData(this.dataService.Key, data.topviewComments);
        data.bottomviewComments = this.aesdecryptservice.decryptData(this.dataService.Key, data.bottomviewComments);
        data.commonApplication = this.aesdecryptservice.decryptData(this.dataService.Key, data.commonApplication);
        data.requirements = this.aesdecryptservice.decryptData(this.dataService.Key, data.requirements);
        data.status = this.aesdecryptservice.decryptData(this.dataService.Key, data.status);
        if (data.createdBy) {
            data.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.firstName);
            data.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.lastName);
        }
        if (data.topViewFile) {
            data.topViewFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, data.topViewFile.thumbnail);
            data.topViewFile.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.topViewFile.name);
        }
        if (data.bottomViewFile) {
            data.bottomViewFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, data.bottomViewFile.thumbnail);
            data.bottomViewFile.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.bottomViewFile.name);
        }
        if (data.documents && Object.keys(data.documents).length !== 0) {
            data.documents.forEach(x => {
                x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                x.reportStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.reportStatus);
                x.labLocation = this.aesdecryptservice.decryptData(this.dataService.Key, x.labLocation);
                x.notes = this.aesdecryptservice.decryptData(this.dataService.Key, x.notes);
                if (x.files) {
                    x.files.forEach(y => {
                        y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name);
                        y.fileType = this.aesdecryptservice.decryptData(this.dataService.Key, y.fileType);
                        y.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnail);
                    });
                }

            });
        }
    }
    @Effect()
    loadStitchesLibrary: Observable<Action> = this.actions.pipe(
        ofType<stitchesActions.LoadStitchesLibrary>(
            stitchesActions.StitchesActionTypes.LOAD_STITCHES_LIBRARY
        ), mergeMap((action: stitchesActions.LoadStitchesLibrary) =>
            this.dataService.getAllStitches(action.payload1, action.payload2).pipe(
                map((stitchesLibrary: any[]) => {
                    if (stitchesLibrary['body']['item1'] != null && stitchesLibrary['body']['item1'].length > 0 && !stitchesLibrary['body']['item2'].toLowerCase().includes('error')) {
                        stitchesLibrary['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.isO4915Number = this.aesdecryptservice.decryptData(this.dataService.Key, x.isO4915Number);
                            x.commonApplication = this.aesdecryptservice.decryptData(this.dataService.Key, x.commonApplication);
                            x.requirements = this.aesdecryptservice.decryptData(this.dataService.Key, x.requirements);
                            x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            if (x.topViewFile) {
                                x.topViewFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.topViewFile.thumbnail);
                            }
                        });
                    }
                    return new stitchesActions.LoadStitchesLibrarySuccess(stitchesLibrary['body']['item1'], stitchesLibrary['body'])
                }),
                catchError(err => of(new stitchesActions.LoadStitchesLibraryFail(err)))
            )
        )
    );
    @Effect()
    loadStitches: Observable<Action> = this.actions.pipe(
        ofType<stitchesActions.LoadStitches>(
            stitchesActions.StitchesActionTypes.LOAD_STITCHES
        ), mergeMap((action: stitchesActions.LoadStitches) =>
            this.dataService.getStitchesById(action.payload).pipe(
                map((stitches: any) => {
                    if (!stitches['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllStitchesDetails(stitches['body']['item1'])
                    }
                    return new stitchesActions.LoadStitchesSuccess(stitches['body']['item1'], stitches['body'])
                }),
                catchError(err => of(new stitchesActions.LoadStitchesFail(err)))
            )
        )
    );
    @Effect()
    createStitches: Observable<any> = this.actions.pipe(
        ofType<stitchesActions.CreateStitches>(
            stitchesActions.StitchesActionTypes.CREATE_STITCHES
        ), map((action: stitchesActions.CreateStitches) => action.payload),
        mergeMap((stitches: any) =>
            this.dataService.addStitches(stitches).pipe(
                map((newStitches: any) =>{
                    if (!newStitches['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllStitchesDetails(newStitches['body']['item1'])
                    }
                   return new stitchesActions.CreateStitchesSuccess(newStitches['body']['item1'], newStitches['body'])
                }),
                catchError(err => of(new stitchesActions.CreateStitchesFail(err)))
            )
        )
    );

    @Effect()
    updateStitches: Observable<any> = this.actions.pipe(
        ofType<stitchesActions.UpdateStitches>(
            stitchesActions.StitchesActionTypes.UPDATE_STITCHES
        ), map((action: stitchesActions.UpdateStitches) => action.payload),
        mergeMap((stitches: any) =>
            this.dataService.updateStitchesById(stitches).pipe(
                map((updatedStitches: any) =>{
                    if (!updatedStitches['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllStitchesDetails(updatedStitches['body']['item1'])
                    }
                   return new stitchesActions.UpdateStitchesSuccess({
                        id: updatedStitches['body']['item1'].id,
                        changes: updatedStitches['body']['item1']
                    }, updatedStitches['body'])
                }),
                catchError(err => of(new stitchesActions.UpdateStitchesFail(err)))
            )
        )
    );

    @Effect()
    updateStitchesLibrary: Observable<any> = this.actions.pipe(
        ofType<stitchesActions.UpdateStitchesLibrary>(
            stitchesActions.StitchesActionTypes.UPDATE_STITCHES_LIBRARY
        ), map((action: stitchesActions.UpdateStitchesLibrary) => action.payload),
        mergeMap((stitchesIds: any[]) =>
            this.dataService.bulkUpdateStitches(stitchesIds).pipe(
                map((updatedStitches: any) =>
                    new stitchesActions.UpdateStitchesLibrarySuccess([{
                        id: updatedStitches['body']['item1'].id,
                        changes: updatedStitches['body']['item1']
                    }], updatedStitches['body'])
                ),
                catchError(err => of(new stitchesActions.UpdateStitchesLibraryFail(err)))
            )
        )
    );

    @Effect()
    deleteStitches: Observable<any> = this.actions.pipe(
        ofType<stitchesActions.DeleteStitchesLibrary>(
            stitchesActions.StitchesActionTypes.DELETE_STITCHES_LIBRARY
        ), map((action: stitchesActions.DeleteStitchesLibrary) => action.payload),
        mergeMap((stitchesIds: any[]) =>
            this.dataService.bulkDeleteStitches(stitchesIds).pipe(
                map((deletedStiches: any) =>
                    new stitchesActions.DeleteStitchesLibrarySuccess(stitchesIds, deletedStiches['body'])
                ),
                catchError(err => of(new stitchesActions.DeleteStitchesLibraryFail(err)))
            )
        )
    );

    @Effect()
    updateStitchesDocument: Observable<any> = this.actions.pipe(
        ofType<stitchesActions.UpdateStitchesDocument>(
            stitchesActions.StitchesActionTypes.UPDATE_STITCHES_DOCUMENT
        ), map((action: stitchesActions.UpdateStitchesDocument) => action.payload),
        mergeMap((stitchesdocument: any) =>
            this.dataService.updateStitchesDocuments(stitchesdocument).pipe(
                map((updatedStitchesDocument: any) =>{
                    if (!updatedStitchesDocument['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllStitchesDetails(updatedStitchesDocument['body']['item1'])
                    }
                   return new stitchesActions.UpdateStitchesDocumentSuccess({
                        id: updatedStitchesDocument['body']['item1'].id,
                        changes: updatedStitchesDocument['body']['item1']
                    }, updatedStitchesDocument['body'])
                }),
                catchError(err => of(new stitchesActions.UpdateStitchesDocumentFail(err)))
            )
        )
    );
}
