import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor() { }

  @Input() public showLoader: boolean;
  // showLoader = new Subject<boolean>();

  ngOnInit(): void {

  }

  // show() {
  //   this.showLoader.next(true);
  // }
  // hide() {
  //   this.showLoader.next(false);
  // }

}
