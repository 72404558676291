<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">
    <!-- Site Content -->
    <div class="dt-content">
      <div class="row">
        <!-- Page Header -->
        <div class="col-xl-4 col-sm-4 col-md-4">
          <div class="dt-page__header">
            <h3 class="dt-page__title" *ngIf="!isEditMode">
              {{ staticMsgs.matDetail.createMat }}
            </h3>
            <h3 class="dt-page__title" *ngIf="isEditMode">
              {{ staticMsgs.matDetail.matDetails }}
            </h3>
            <nav class="mb-0 breadcrumb">
              <a
                href="javascript:void(0)"
                class="breadcrumb-item"
                routerLink="/dashboard"
                ><i class="fa fa-home" aria-hidden="true"></i>
              </a>
              <a
                href="javascript:void(0)"
                class="breadcrumb-item"
                routerLink="/materiallibrary"
              >
                <span
                  class="active breadcrumb-item"
                  *ngIf="isEditMode || !isEditMode"
                  >{{ staticMsgs.matLib.matLib }}</span
                >
              </a>
              <span class="active breadcrumb-item" *ngIf="!isEditMode">{{
                staticMsgs.matDetail.createMat
              }}</span>
              <span class="active breadcrumb-item" *ngIf="isEditMode"
                >{{ staticMsgs.matDetail.matDetails }} / {{ sequenceNumber }}
              </span>
            </nav>
          </div>
        </div>
        <div
          class="col-xl-4 col-sm-4 col-md-4 d-flex justify-content-center"
          *ngIf="isEditMode"
        >
          <ul class="dt-nav">
            <button
              *ngIf="!isEditingMode"
              type="button"
              class="btn mr-3 btn-color-primary round"
              (click)="editMaterial()"
              ngbTooltip="Edit Material"
            >
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button
              *ngIf="isEditingMode"
              type="button"
              [ngClass]="isEditingMode ? 'icon-active' : 'btn-color-primary'"
              class="btn mr-3 round"
              (click)="cancelMaterialEdit()"
              ngbTooltip="Cancel Edit Material"
            >
              <i class="fa fa-times text-white" aria-hidden="true"></i>
            </button>
            <button
              *ngIf="!isEditingMode"
              type="button"
              class="btn mr-3 btn-color-primary round"
              (click)="onDeleteMaterial()"
              ngbTooltip="Delete"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
            <!-- <button type="button" class="btn mr-3 btn-color-primary round"
                    (click)="Materialdiscussion()"
                      *ngIf="!isEditingMode" ngbTooltip="Discussion">
                      <i class="fa fa-comments" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal"
                      data-target="#small-modal" ngbTooltip="Generate Techpack">
                      <i class="fas fa-tshirt" aria-hidden="true"></i>
                    </button> -->
          </ul>
        </div>
        <!-- <div class="col-xl-4 col-sm-4 col-md-4" *ngIf="isEditMode">
                  <ul class="dt-nav">
                    <div *ngFor="let arr of sharedUsers">
                      <div *ngIf="!arr.user[0].pictureID" class="icon-container">
                        <a class="dt-avatar round mr-2 size-30" placement="bottom" ngbTooltip="{{arr.user[0].fullName}}">
                          <img [src]="userThumbnail">
                        </a>
                        <div *ngIf="arr.isOnline" class='active-status-circle' ngbTooltip="Online">
                        </div>
                        <div *ngIf="!arr.isOnline" class='deactive-status-circle' ngbTooltip="Offline">
                        </div>
                      </div>
                      <div *ngIf="arr.user[0].pictureID" class="icon-container">
                        <a class="dt-avatar round mr-2 size-30" placement="bottom" ngbTooltip="{{arr.user[0].fullName}}">
                          <img [src]="arr.user[0].picture.thumbnail" />
                        </a>
                        <div *ngIf="arr.isOnline" class='active-status-circle' ngbTooltip="Online">
                        </div>
                        <div *ngIf="!arr.isOnline" class='deactive-status-circle' ngbTooltip="Offline">
                        </div>
                      </div>
                    </div>
                    <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal" data-target="#long-modal"
                      ngbTooltip="Share">
                      <i class="fa fa-user-plus" aria-hidden="true" ></i>
                    </button>
                  </ul>
              </div> -->
      </div>
      <!-- /page header -->

      <div class="profile-content">
        <!-- Material Info Div-->
        <div class="row">
          <!-- Grid Item -->

          <div class="wd-300" *ngIf="isViewingMode">
            <ng-image-slider
              class="sidebar_sliders sidebar_image"
              [images]="imageObject"
              *ngIf="imageObject.length > 0"
              [infinite]="false"
              [autoSlide]="1"
              slideImage="1"
              [imageSize]="{ width: '100%', height: 200 }"
            >
            </ng-image-slider>

            <div class="accordion mt-5" id="accordion-example">
              <div class="card" *ngIf="editedMaterialData">
                <div class="" id="headingOne">
                  <h5 class="mb-0">
                    <h4
                      class="card-header text-primary custom-cardheader"
                      data-toggle="collapse"
                      data-target="#collapse-one"
                      aria-expanded="false"
                      aria-controls="collapse-one"
                    >
                      {{ staticMsgs.matDetail.matInfo }}
                      <span class="float-right"
                        ><i class="fa fa-chevron-down"></i
                      ></span>
                    </h4>
                  </h5>
                </div>

                <div
                  id="collapse-one"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion-example"
                >
                  <div
                    class="card-body bg-white p-4"
                    *ngIf="editedMaterialData"
                  >
                    <div class="row">
                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{ staticMsgs.matDetail.name }}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{ editedMaterialData.name }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{ staticMsgs.matDetail.type }}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">
                          {{ editedMaterialData.classification }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="editedMaterialData">
                <div class="" id="headingTwo">
                  <h5 class="mb-0">
                    <h4
                      class="card-header text-primary custom-cardheader"
                      data-toggle="collapse"
                      data-target="#collapse-two"
                      aria-expanded="false"
                      aria-controls="collapse-one"
                    >
                      {{ staticMsgs.matDetail.userInfo }}
                      <span class="float-right"
                        ><i class="fa fa-chevron-down"></i
                      ></span>
                    </h4>
                  </h5>
                </div>
                <div
                  id="collapse-two"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion-example"
                >
                  <div
                    class="card-body bg-white p-4"
                    *ngIf="editedMaterialData"
                  >
                    <div class="row">
                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">
                          {{ staticMsgs.matDetail.createdby }}
                        </p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">
                          {{ editedMaterialData.createdBy?.firstName }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">
                          {{ staticMsgs.matDetail.createdon }}
                        </p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          {{
                            editedMaterialData.createdOn | date : "dd MMM yyyy"
                          }}
                        </p>
                        <p class="a-font">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                          {{ editedMaterialData.createdOn | date : "hh:mm a" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Grid Item -->
          <div
            [ngClass]="isViewingMode ? 'wdcacl-300' : 'w-100'"
            *ngIf="isViewingMode || isEditingMode"
          >
            <!-- Card -->
            <div class="card card card-shadow-none mr-3">
              <!-- Card Header -->
              <div
                class="card-header card-nav border-bottom d-sm-flex justify-content-sm-between"
                *ngIf="!isCreateMode"
              >
                <!-- <h3 class="mb-2 mb-sm-n5">About</h3> -->
                <ul class="card-header-links nav nav-underline" role="tablist">
                  <li class="nav-item">
                    <a
                      *ngIf="activeTab != 'materialDetailsTab'"
                      class="nav-link active"
                      data-toggle="tab"
                      href="#tab-pane1"
                      [ngClass]="{ active: activeTab === 'materialDetailsTab' }"
                      (click)="materialDetailsTab('materialDetailsTab')"
                      role="tab"
                      aria-controls="tab-pane1"
                      aria-selected="true"
                      >{{ staticMsgs.matDetail.matDetails }}</a
                    >
                    <a
                      *ngIf="activeTab == 'materialDetailsTab'"
                      class="nav-link active"
                      data-toggle="tab"
                      href="#tab-pane1"
                      [ngClass]="{ active: activeTab === 'materialDetailsTab' }"
                      role="tab"
                      aria-controls="tab-pane1"
                      aria-selected="true"
                      >{{ staticMsgs.matDetail.matDetails }}</a
                    >
                  </li>
                  <li class="nav-item" *ngIf="isEditMode">
                    <a
                      *ngIf="activeTab != 'colorTab'"
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-pane2"
                      [ngClass]="{ active: activeTab === 'colorTab' }"
                      (click)="colorTab('colorTab')"
                      role="tab"
                      aria-controls="tab-pane2"
                      aria-selected="true"
                      >{{ staticMsgs.matDetail.color }}</a
                    >
                    <a
                      *ngIf="activeTab == 'colorTab'"
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-pane2"
                      [ngClass]="{ active: activeTab === 'colorTab' }"
                      role="tab"
                      aria-controls="tab-pane2"
                      aria-selected="true"
                      >Color</a
                    >
                  </li>
                  <li class="nav-item" *ngIf="isEditMode">
                    <a
                      *ngIf="activeTab != 'documentTab'"
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-pane3"
                      [ngClass]="{ active: activeTab === 'documentTab' }"
                      (click)="documentTab('documentTab')"
                      role="tab"
                      aria-controls="tab-pane3"
                      aria-selected="true"
                      >{{ staticMsgs.matDetail.document }}</a
                    >
                    <a
                      *ngIf="activeTab == 'documentTab'"
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-pane3"
                      [ngClass]="{ active: activeTab === 'documentTab' }"
                      role="tab"
                      aria-controls="tab-pane3"
                      aria-selected="true"
                      >{{ staticMsgs.matDetail.document }}</a
                    >
                  </li>
                  <li class="nav-item" *ngIf="isEditMode">
                    <a
                      *ngIf="activeTab != 'supplierTab'"
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-pane4"
                      [ngClass]="{ active: activeTab === 'supplierTab' }"
                      (click)="supplierTab('supplierTab')"
                      role="tab"
                      aria-controls="tab-pane4"
                      aria-selected="true"
                      >{{ staticMsgs.matDetail.supplier }}</a
                    >
                    <a
                      *ngIf="activeTab == 'supplierTab'"
                      class="nav-link"
                      data-toggle="tab"
                      href="#tab-pane4"
                      [ngClass]="{ active: activeTab === 'supplierTab' }"
                      role="tab"
                      aria-controls="tab-pane4"
                      aria-selected="true"
                      >{{ staticMsgs.matDetail.supplier }}</a
                    >
                  </li>
                </ul>
                <div class="dropdown" *ngIf="!isViewingMode">
                  <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown"
                                      aria-haspopup="true" aria-expanded="false">
                                      <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                                  </a> -->
                </div>

                <div class="dropdown" *ngIf="isViewingMode">
                  <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown"
                                      aria-haspopup="true" aria-expanded="false">
                                      <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                                  </a> -->

                  <div class="dropdown-menu dropdown-menu-right">
                    <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="editMaterial()">
                                          <i class="icon icon-editors icon-fw"></i>
                                          Edit
                                      </a> -->
                    <!-- <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="icon icon-trash icon-fw"></i>
                                          Delete
                                      </a>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="icon icon-addnew icon-fw"></i>
                                          Create New
                                      </a>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="fa fa-files-o mr-1" aria-hidden="true"></i>
                                          Copy
                                      </a>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="fa fa-floppy-o mr-1" aria-hidden="true"></i>
                                          Save New Version
                                      </a>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="fa fa-thumb-tack mr-1" aria-hidden="true"></i>
                                          Mark As Basline Version
                                      </a> -->
                  </div>
                </div>
              </div>
              <!-- /card header -->
              <!-- Card Body -->
              <div class="pb-2 mt-3">
                <!-- Tab Content-->
                <div class="tab-content">
                  <!-- Tab panel -->
                  <div
                    id="tab-pane1"
                    class="tab-pane card-body card-body-shadow bg-white active"
                    [ngClass]="{ active: activeTab === 'materialDetailsTab' }"
                  >
                    <div *ngIf="isEditingMode">
                      <form [formGroup]="materialForm" (ngSubmit)="onSubmit()">
                        <!-- <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div> -->
                        <div class="form-row">
                          <div class="col-lg-3 col-sm-12">
                            <label for="materialclassification"
                              >{{ staticMsgs.matDetail.selectClass
                              }}<span class="red_icon">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="materialclassification"
                              formControlName="materialclassification"
                              placeholder="Classification"
                              required
                            />
                            <div
                              *ngIf="
                                (submitted &&
                                  this.f.materialclassification.errors) ||
                                (this.f.materialclassification.invalid &&
                                  (this.f.materialclassification.dirty ||
                                    this.f.materialclassification.touched))
                              "
                            >
                              <div
                                *ngIf="
                                  this.f.materialclassification.errors.required
                                "
                                class="red"
                              >
                                {{ staticMsgs.matDetail.classReq }}
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-3 mb-3" *ngIf="isEditMode">
                            <label for="materialseq">{{
                              staticMsgs.matDetail.material
                            }}</label>

                            <input
                              type="text"
                              class="form-control"
                              [readonly]="true"
                              id="materialseq"
                              formControlName="materialseq"
                              placeholder="Material Id"
                            />
                          </div>

                          <div class="col-sm-3 mb-3">
                            <label for="materialname"
                              >{{ staticMsgs.matDetail.name1
                              }}<span class="red_icon">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="materialname"
                              formControlName="materialname"
                              minlength="3"
                              maxlength="50"
                              placeholder="Material Name"
                              required
                              (keypress)="characterOnly($event)"
                            />
                            <div
                              *ngIf="
                                (submitted && this.f.materialname.errors) ||
                                (this.f.materialname.invalid &&
                                  (this.f.materialname.dirty ||
                                    this.f.materialname.touched))
                              "
                            >
                              <div
                                *ngIf="this.f.materialname.errors.required"
                                class="red"
                              >
                                {{ staticMsgs.matDetail.matNameReq }}
                              </div>
                              <div
                                *ngIf="this.f.materialname.errors.minlength"
                                class="red"
                              >
                                {{
                                  staticMsgs.common.minimum3charactersrequired
                                }}
                              </div>
                              <!-- <div class="red"
                                                              *ngIf="this.materialForm.controls.materialname.value && this.materialForm.controls.materialname.value.length > 50">
                                                              Material Name is allowed 50 characters only.
                                                          </div> -->
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-row row"
                          *ngIf="parentClassification === 'Fabric'"
                        >
                          <!-- <div class="col-sm-3 mt-3 mb-3">
                                                                                                    <label for="cuttablewidth">{{staticMsgs.matDetail.cutWidth}}</label>
                                                                                                    <input type="text" class="form-control" id="cuttablewidth" formControlName="cuttablewidth"
                                                                                                      (keypress)="numberOnly($event)" placeholder="Cuttable Width"
                                                                                                      (focusout)="addDecimal('cuttablewidth')" />
                                                                                                    <div class="red"
                                                                                                      *ngIf="this.materialForm.controls.cuttablewidth.errors && this.materialForm.controls.cuttablewidth.value && this.materialForm.controls.cuttablewidth.value.length > 20">
                                                                                                      {{staticMsgs.matDetail.cutWidthAllow}}
                                                                                                    </div>
                                                                                                    <div class="red" *ngIf="this.f.cuttablewidth.errors?.pattern">
                                                                                                      {{staticMsgs.matDetail.cutWidthInvalid}}
                                                                                                    </div>
                                                                                                  </div>
                                                                                                  <div class="col-sm-3 mt-3 mb-3">
                                                                                                    <label for="widthuom">{{staticMsgs.matDetail.wUOM}}</label>
                                                                                                    <select type="text" title="Select Width UOM" class="form-control selectpicker w-100"
                                                                                                      id="widthuom" formControlName="widthuom">
                                                                                                      <option value="Inch">{{staticMsgs.matDetail.inch}}</option>
                                                                                                      <option value="Meter">{{staticMsgs.matDetail.meter}}</option>
                                                                                                      <option value="cm">{{staticMsgs.matDetail.cm}}</option>
                                                                                                      <option value="mm">{{staticMsgs.matDetail.mm}}</option>
                                                                                                    </select>
                                                                                                  </div>
                                                                                                  <div class="col-sm-3 mt-3 mb-3">
                                                                                                    <label for="weightgsm">{{staticMsgs.matDetail.weightGSM}}</label>
                                                                                                    <input type="text" class="form-control" id="weightgsm" formControlName="weightgsm"
                                                                                                      placeholder="Weight/ GSM" (keypress)="numberOnly($event)"
                                                                                                      (focusout)="addDecimal('weight')" />
                                                                                                    <div class="red"
                                                                                                      *ngIf="this.materialForm.controls.weightgsm.errors && this.materialForm.controls.weightgsm.value && this.materialForm.controls.weightgsm.value.length > 20">
                                                                                                      {{staticMsgs.matDetail.gsmAllow}}
                                                                                                    </div>
                                                                                                    <div class="red" *ngIf="this.f.weightgsm.errors?.pattern">
                                                                                                      {{staticMsgs.matDetail.gsmInvalid}}
                                                                                                    </div>
                                                                                                  </div>
                                                                                                  <div class="col-sm-3 mt-3 mb-3">
                                                                                                    <label for="fabriccomp">{{staticMsgs.matDetail.fabComp}}<span
                                                                                                        class="red_icon">*</span></label>
                                                                                                    <input type="text" class="form-control" id="fabriccomp" formControlName="fabriccomp"
                                                                                                      placeholder="Fabric Composition" required />
                                                                                                    <div
                                                                                                      *ngIf="(submitted && this.f.fabriccomp.errors) || ( this.f.fabriccomp.invalid && (this.f.fabriccomp.dirty || this.f.fabriccomp.touched))">
                                                                                                      <div *ngIf="this.f.fabriccomp.errors.required" class="red">
                                                                                                        {{staticMsgs.matDetail.fabCompReq}}
                                                                                                      </div>
                                                                                                      <div class="red"
                                                                                                        *ngIf="this.materialForm.controls.fabriccomp.value && this.materialForm.controls.fabriccomp.value.length > 20">
                                                                                                        {{staticMsgs.matDetail.fabCompAllow}}
                                                                                                      </div>
                                                                                                    </div>
                                                                                                  </div> -->
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="materialTypeList">Type</label>
                            <div *ngIf="this.materialForm.get('materialclassification').value === 'Knit'">
                              <select
                              type="text"
                              title="Select Type"
                              class="form-control selectpicker w-100"
                              id="materialTypeList"
                              formControlName="fabricType"
                            >
                              <option
                                *ngFor="
                                  let option of fabricList.knitTypeList; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            </div>
                            <div *ngIf="this.materialForm.get('materialclassification').value === 'Woven'">
                              <select
                              type="text"
                              title="Select Type"
                              class="form-control selectpicker w-100"
                              id="materialTypeList"
                              formControlName="fabricType"
                            >
                              <option
                                *ngFor="
                                  let option of fabricList.wovenTypeList; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            </div>
                            <div *ngIf="this.materialForm.get('materialclassification').value === 'Combo'">
                              <select
                              type="text"
                              title="Select Type"
                              class="form-control selectpicker w-100"
                              id="materialTypeList"
                              formControlName="fabricType"
                            >
                              <option
                                *ngFor="
                                  let option of fabricList.materialTypeList; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            </div>
                            <div
                              *ngIf="
                                (submitted && this.f.fabricType.errors) ||
                                (this.f.fabricType.invalid &&
                                  (this.f.fabricType.dirty ||
                                    this.f.fabricType.touched))
                              "
                            >
                              <div
                                *ngIf="this.f.fabricType.errors?.required"
                                class="red"
                              >
                                Type is required
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="threadCount">Thread Count</label>
                            <select
                              type="text"
                              title="Select Thread Count"
                              class="form-control selectpicker w-100"
                              id="threadCount"
                              formControlName="threadCount"
                            >
                              <option
                                *ngFor="let option of fabricList.threadCount; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.threadCount.errors) ||
                                (this.f.threadCount.invalid &&
                                  this.f.threadCount.dirty &&
                                  this.f.threadCount.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.threadCount.errors?.required"
                                class="red"
                              >
                                Thread Count is required
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="gSM">Weight GSM</label>
                            <!-- <select
                              type="text"
                              title="Select Width GSM"
                              class="form-control selectpicker w-100"
                              id="gSM"
                              formControlName="gSM"
                            >
                              <option
                                *ngFor="let option of fabricList.gSM; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select> -->
                            <input type="number" formControlName="gSM" class="form-control" id="gSM" (input)="onInput('gSM')" valid-number />
                            <div
                              *ngIf="
                                (submitted && this.f.gSM.errors) ||
                                (this.f.gSM.invalid &&
                                  this.f.gSM.dirty &&
                                  this.f.gSM.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.gSM.errors?.required"
                                class="red"
                              >
                                Wieght GSM is required
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="ply">Ply</label>
                            <select
                              type="text"
                              title="Select Ply"
                              class="form-control selectpicker w-100"
                              id="ply"
                              formControlName="ply"
                            >
                              <option
                                *ngFor="let option of fabricList.ply; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.ply.errors) ||
                                (this.f.ply.invalid &&
                                  this.f.ply.dirty &&
                                  this.f.ply.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.ply.errors?.required"
                                class="red"
                              >
                                Ply is required
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="wash">Recommended care</label>
                            <select
                              type="text"
                              title="Select Wash"
                              class="form-control selectpicker w-100"
                              id="wash"
                              formControlName="wash"
                            >
                              <option
                                *ngFor="let option of fabricList.wash; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.wash.errors) ||
                                (this.f.wash.invalid &&
                                  this.f.wash.dirty &&
                                  this.f.wash.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.wash.errors?.required"
                                class="red"
                              >
                              Recommended care is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mb-3">
                            <label for="composition">Composition</label>
                            <input
                              type="text"
                              class="form-control"
                              id="composition"
                              formControlName="composition"
                              placeholder="Fabric Composition"
                            />
                          </div>
                          <div class="col-sm-3 mb-3">
                            <label for="finish">Finish</label>
                            <input
                              type="text"
                              class="form-control"
                              id="finish"
                              formControlName="finish"
                              placeholder="Finish"
                            />
                          </div>
                        </div>
                        <!---Trim classification Start-->
                        <div class="form-row" *ngIf="false">
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="subtype"
                              >{{ staticMsgs.matDetail.subType
                              }}<span class="red_icon">*</span></label
                            >
                            <select
                              type="text"
                              title="Select Sub Type"
                              data-live-search="true"
                              class="form-control selectpicker w-100"
                              id="subtype"
                              formControlName="subtype"
                              required
                            >
                              <!-- <option value="" [disabled]="true" selected>Select Sub Type
                                                                              </option> -->
                              <option value="Button">
                                {{ staticMsgs.matDetail.button }}
                              </option>
                              <option value="Hook">
                                {{ staticMsgs.matDetail.hook }}
                              </option>
                              <option value="Zipper">
                                {{ staticMsgs.matDetail.zipper }}
                              </option>
                              <option value="Lace">
                                {{ staticMsgs.matDetail.lace }}
                              </option>
                              <option value="Ribbon">
                                {{ staticMsgs.matDetail.ribbon }}
                              </option>
                              <option value="Elastic">
                                {{ staticMsgs.matDetail.elastic }}
                              </option>
                              <option value="Velcro">
                                {{ staticMsgs.matDetail.velcro }}
                              </option>
                              <option value="Eyelet">
                                {{ staticMsgs.matDetail.eyelet }}
                              </option>

                              <option value="Eyelet Tape">
                                {{ staticMsgs.matDetail.eyeletTape }}
                              </option>
                              <option value="Price Hangtag">
                                {{ staticMsgs.matDetail.price }}
                              </option>
                              <option value="Disclaimer">
                                {{ staticMsgs.matDetail.disclaimer }}
                              </option>
                              <option value="Information Tag">
                                {{ staticMsgs.matDetail.infoTag }}
                              </option>
                              <option value="Marketing Tag">
                                {{ staticMsgs.matDetail.marketTag }}
                              </option>
                              <option value="Branding Tag">
                                {{ staticMsgs.matDetail.brandTag }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.subtype.errors) ||
                                (this.f.subtype.invalid &&
                                  (this.f.subtype.dirty ||
                                    this.f.subtype.touched))
                              "
                            >
                              <div
                                *ngIf="this.f.subtype.errors.required"
                                class="red"
                              >
                                {{ staticMsgs.matDetail.subTypeReq }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-3 mt-3 mb-3"
                            *ngIf="parentClassification === 'Trims'"
                          >
                            <label for="content">{{
                              staticMsgs.matDetail.content
                            }}</label>
                            <select
                              type="text"
                              title="Select Content"
                              class="form-control selectpicker w-100"
                              id="content"
                              formControlName="content"
                            >
                              <option value="" [disabled]="true" selected>
                                {{ staticMsgs.matDetail.selectCont }}
                              </option>
                              <option value="Plastic">
                                {{ staticMsgs.matDetail.plastic }}
                              </option>
                              <option value="Steel">
                                {{ staticMsgs.matDetail.steel }}
                              </option>
                              <option value="Brass">
                                {{ staticMsgs.matDetail.brass }}
                              </option>
                              <option value="Polyster">
                                {{ staticMsgs.matDetail.polyster }}
                              </option>
                              <option value="Nylon">
                                {{ staticMsgs.matDetail.nylon }}
                              </option>
                              <option value="Silicon">
                                {{ staticMsgs.matDetail.silicon }}
                              </option>
                              <option value="SBS">
                                {{ staticMsgs.matDetail.sbs }}
                              </option>
                              <option value="PU">
                                {{ staticMsgs.matDetail.pu }}
                              </option>
                              <option value="TPU">
                                {{ staticMsgs.matDetail.tpu }}
                              </option>
                              <option value="Rubber">
                                {{ staticMsgs.matDetail.rubber }}
                              </option>
                            </select>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="sizeuom">{{
                              staticMsgs.matDetail.sizeUOM
                            }}</label>
                            <select
                              type="text"
                              title="Select Size UOM"
                              class="form-control selectpicker w-100"
                              id="sizeuom"
                              formControlName="sizeuom"
                            >
                              <!-- <option value="" [disabled]="true" selected>Select Size UOM
                                                                              </option> -->
                              <option value="Inch">
                                {{ staticMsgs.matDetail.inch }}
                              </option>
                              <option value="Meter">
                                {{ staticMsgs.matDetail.meter }}
                              </option>
                              <option value="cm">
                                {{ staticMsgs.matDetail.cm }}
                              </option>
                              <option value="mm">
                                {{ staticMsgs.matDetail.mm }}
                              </option>
                            </select>
                          </div>

                          <!--<div class="col-sm-4 mb-3">
                                                                          <label for="cost">Cost</label>
                                                                          <input type="text" class="form-control" id="trimcost"
                                                                              formControlName="trimcost" placeholder="Cost"/>
                                                                      </div>-->

                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="size">{{
                              staticMsgs.matDetail.size
                            }}</label>
                            <input
                              type="text"
                              class="form-control"
                              id="size"
                              formControlName="size"
                              placeholder="Size"
                              (keypress)="numberOnly($event)"
                              (focusout)="addDecimal('size')"
                            />

                            <div
                              class="red"
                              *ngIf="this.f.size.errors?.pattern"
                            >
                              {{ staticMsgs.matDetail.sizeInvalid }}
                            </div>
                          </div>

                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="width">{{
                              staticMsgs.matDetail.width
                            }}</label>
                            <input
                              type="text"
                              class="form-control"
                              id="width"
                              formControlName="width"
                              placeholder="Width"
                              (keypress)="numberOnly($event)"
                              (focusout)="addDecimal('width')"
                            />

                            <div
                              class="red"
                              *ngIf="this.f.width.errors?.pattern"
                            >
                              {{ staticMsgs.matDetail.widthInvalid }}
                            </div>
                          </div>
                        </div>
                        <!---Trim classification End-->

                        <!---Leather classification Start-->
                        <div
                          class="form-row"
                          *ngIf="parentClassification === 'Material'"
                        >
                          <!-- <div class="col-sm-3 mt-3 mb-3">
                                                                                                    <label for="substabce">{{staticMsgs.matDetail.substance}}</label>
                                                                                                    <input type="text" class="form-control" id="substabce" formControlName="substabce"
                                                                                                      placeholder="Substance" />
                                                                                                    <div class="red"
                                                                                                      *ngIf="this.materialForm.controls.substabce.errors && this.materialForm.controls.substabce.value && this.materialForm.controls.substabce.value.length > 20">
                                                                                                      {{staticMsgs.matDetail.subAllow}}
                                                                                                    </div>
                                                                                                    <div class="red" *ngIf="this.f.substabce.errors?.pattern">
                                                                                                      {{staticMsgs.matDetail.subInvalid}}
                                                                                                    </div>
                                                                                                  </div>

                                                                                                  <div class="col-sm-3 mt-3 mb-3">
                                                                                                    <label for="substanceuom">{{staticMsgs.matDetail.subUOM}}</label>
                                                                                                    <select type="text" title="Select Substance UOM" class="form-control selectpicker w-100"
                                                                                                      id="substanceuom" formControlName="substanceuom">
                                                                                                      <option value="Inch">Inch</option>
                                                                                                      <option value="cm">cm</option>
                                                                                                      <option value="mm">mm</option>
                                                                                                    </select>
                                                                                                  </div>

                                                                                                  <div class="col-sm-3  mt-3 mb-3">
                                                                                                    <label for="rawhide">{{staticMsgs.matDetail.rawHide}}</label>
                                                                                                    <select type="text" title="Select Raw Hide" class="form-control selectpicker w-100"
                                                                                                      id="rawhide" formControlName="rawhide">
                                                                                                      <option value="Cow">Cow</option>
                                                                                                      <option value="Camel">Camel</option>
                                                                                                      <option value="Horse">Horse</option>

                                                                                                    </select>
                                                                                                  </div>


                                                                                                  <div class="col-sm-3 mt-3 mb-3">
                                                                                                    <label for="cost">{{staticMsgs.matDetail.cost}}</label>
                                                                                                    <input type="text" class="form-control" id="cost" (keypress)="numberOnly($event)"
                                                                                                      formControlName="cost" placeholder="Cost" />
                                                                                                    <div class="red"
                                                                                                      *ngIf="this.materialForm.controls.cost.errors && this.materialForm.controls.cost.value && this.materialForm.controls.cost.value.length > 20">
                                                                                                      {{staticMsgs.matDetail.costAllow}}
                                                                                                    </div>
                                                                                                    <div class="red" *ngIf="this.f.cost.errors?.pattern">
                                                                                                      {{staticMsgs.matDetail.costInvalid}}
                                                                                                    </div>
                                                                                                  </div> -->
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="materialTypeList">Type</label>
                            <select
                              type="text"
                              title="Select Type"
                              class="form-control selectpicker w-100"
                              id="materialTypeList"
                              formControlName="materialTypeList"
                            >
                              <option
                                *ngFor="
                                  let option of leatherList.materialTypeList; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.materialTypeList.errors) ||
                                (this.f.materialTypeList.invalid &&
                                  this.f.materialTypeList.dirty &&
                                  this.f.materialTypeList.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.materialTypeList.errors?.required"
                                class="red"
                              >
                                Type is required
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="softness">Softness</label>
                            <select
                              type="text"
                              title="Select Softness"
                              class="form-control selectpicker w-100"
                              id="softness"
                              formControlName="softness"
                            >
                              <option
                                *ngFor="let option of leatherList.softness; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.softness.errors) ||
                                (this.f.softness.invalid &&
                                  this.f.softness.dirty &&
                                  this.f.softness.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.softness.errors?.required"
                                class="red"
                              >
                                Softness is required
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="durability">Durability</label>
                            <select
                              type="text"
                              title="Select Durability"
                              class="form-control selectpicker w-100"
                              id="durability"
                              formControlName="durability"
                            >
                              <option
                                *ngFor="let option of leatherList.durability; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.durability.errors) ||
                                (this.f.durability.invalid &&
                                  this.f.durability.dirty &&
                                  this.f.durability.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.durability.errors?.required"
                                class="red"
                              >
                                Durability is required
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="weight">Weight</label>
                            <select
                              type="text"
                              title="Select Weight"
                              class="form-control selectpicker w-100"
                              id="weight"
                              formControlName="weight"
                            >
                              <option
                                *ngFor="let option of leatherList.weight; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.weight.errors) ||
                                (this.f.weight.invalid &&
                                  this.f.weight.touched &&
                                  this.f.weight.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.weight.errors?.required"
                                class="red"
                              >
                                Weight is required
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3"
                          >
                            <label for="unitofMeasure">Unit Of Measure</label>
                            <select
                              type="text"
                              title="Select Width UOM"
                              class="form-control selectpicker w-100"
                              id="unitofMeasure"
                              formControlName="unitofMeasure"
                            >
                              <option
                                *ngFor="let option of leatherList.unitofMeasure; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.unitofMeasure.errors) ||
                                (this.f.unitofMeasure.invalid &&
                                  this.f.unitofMeasure.dirty &&
                                  this.f.unitofMeasure.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.unitofMeasure.errors?.required"
                                class="red"
                              >
                                UOM is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <!---Leather classification End-->
                        <!-- Packaging start -->
                        <!--- Sew in Label -->
                        <div
                          class="form-row"
                          *ngIf="
                            this.materialForm.get('materialclassification')
                              .value === 'Sew in Label'
                          "
                        >
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="sewLabelSize">Label Size</label>
                            <select
                              type="text"
                              title="Select Label Size"
                              class="form-control selectpicker w-100"
                              id="sewLabelSize"
                              formControlName="sewLabelSize"
                            >
                              <option
                                *ngFor="
                                  let option of packagingListSewinLabel?.LabelSize; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.sewLabelSize.errors) ||
                                (this.f.sewLabelSize.invalid &&
                                  this.f.sewLabelSize.dirty &&
                                  this.f.sewLabelSize.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.sewLabelSize.errors?.required"
                                class="red"
                              >
                                Label Size is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="sewFabric">Fabric</label>
                            <select
                              type="text"
                              title="Select Fabric"
                              class="form-control selectpicker w-100"
                              id="sewFabric"
                              formControlName="sewFabric"
                            >
                              <option
                                *ngFor="
                                  let option of packagingListSewinLabel?.Fabric; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.sewFabric.errors) ||
                                (this.f.sewFabric.invalid &&
                                  this.f.sewFabric.dirty &&
                                  this.f.sewFabric.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.sewFabric.errors?.required"
                                class="red"
                              >
                                Fabric is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--- Sew in Label End-->
                        <!-- Hang Tag -->
                        <div
                          class="form-row"
                          *ngIf="
                            this.materialForm.get('materialclassification')
                              .value === 'Hang Tag'
                          "
                        >
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="hangSize">Size</label>
                            <select
                              type="text"
                              title="Select Label Size"
                              class="form-control selectpicker w-100"
                              id="hangSize"
                              formControlName="hangSize"
                            >
                              <option
                                *ngFor="
                                  let option of packagingListHangTag?.Size; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.hangSize.errors) ||
                                (this.f.hangSize.invalid &&
                                  this.f.hangSize.dirty &&
                                  this.f.hangSize.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.hangSize.errors?.required"
                                class="red"
                              >
                                Size is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="hangColor">Color</label>
                            <select
                              type="text"
                              title="Select Color"
                              class="form-control selectpicker w-100"
                              id="hangColor"
                              formControlName="hangColor"
                            >
                              <option
                                *ngFor="
                                  let option of packagingListHangTag?.Color; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.hangColor.errors) ||
                                (this.f.hangColor.invalid &&
                                  this.f.hangColor.dirty &&
                                  this.f.hangColor.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.hangColor.errors?.required"
                                class="red"
                              >
                                Color is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="hangMaterial">Material</label>
                            <select
                              type="text"
                              title="Select Material"
                              class="form-control selectpicker w-100"
                              id="hangMaterial"
                              formControlName="hangMaterial"
                            >
                              <option
                                *ngFor="
                                  let option of packagingListHangTag?.Material; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.hangMaterial.errors) ||
                                (this.f.hangMaterial.invalid &&
                                  this.f.hangMaterial.dirty &&
                                  this.f.hangMaterial.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.hangMaterial.errors?.required"
                                class="red"
                              >
                                Material is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="hangWeightgsm">GSM</label>
                            <!-- <select
                              type="text"
                              title="Select GSM"
                              class="form-control selectpicker w-100"
                              id="hangWeightgsm"
                              formControlName="hangWeightgsm"
                            >
                              <option
                                *ngFor="let option of packagingListHangTag?.GSM; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select> -->
                            <input type="number" formControlName="hangWeightgsm" class="form-control" id="hangWeightgsm" (input)="onHangWeightgsm('hangWeightgsm')" valid-number />
                            <div
                              *ngIf="
                                (submitted && this.f.hangWeightgsm.errors) ||
                                (this.f.hangWeightgsm.invalid &&
                                  this.f.hangWeightgsm.dirty &&
                                  this.f.hangWeightgsm.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.hangWeightgsm.errors?.required"
                                class="red"
                              >
                                GSM is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Hang Tag End -->
                        <!-- Packaging End-->
                        <!-- Trims Zipper -->
                        <div
                          class="form-row"
                          *ngIf="
                            this.materialForm.get('materialclassification')
                              .value === 'Zipper'
                          "
                        >
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="zipType">Type</label>
                            <select
                              type="text"
                              title="Select Type"
                              class="form-control selectpicker w-100"
                              id="zipType"
                              formControlName="zipType"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.zipper?.materialType; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.zipType.errors) ||
                                (this.f.zipType.invalid &&
                                  this.f.zipType.dirty &&
                                  this.f.zipType.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.zipType.errors?.required"
                                class="red"
                              >
                                Type is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="zipFunctionality">Functionality</label>
                            <select
                              type="text"
                              title="Select Functionality"
                              class="form-control selectpicker w-100"
                              id="zipFunctionality"
                              formControlName="zipFunctionality"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.zipper?.Functionality; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.zipFunctionality.errors) ||
                                (this.f.zipFunctionality.invalid &&
                                  this.f.zipFunctionality.dirty &&
                                  this.f.zipFunctionality.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.zipFunctionality.errors?.required"
                                class="red"
                              >
                                Functionality is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="zipper">Two-way/ One-way Zippers</label>
                            <select
                              type="text"
                              title="Select Two-way/ One-way Zippers"
                              class="form-control selectpicker w-100"
                              id="zipper"
                              formControlName="zipper"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.zipper?.Zippers; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.zipper.errors) ||
                                (this.f.zipper.invalid &&
                                  this.f.zipper.dirty &&
                                  this.f.zipper.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.zipper.errors?.required"
                                class="red"
                              >
                                Zipper is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Trims Zipper End -->
                        <!-- Trims Button -->
                        <div
                          class="form-row"
                          *ngIf="
                            this.materialForm.get('materialclassification')
                              .value === 'Button'
                          "
                        >
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="buttonType">Type</label>
                            <select
                              type="text"
                              title="Select Type"
                              class="form-control selectpicker w-100"
                              id="buttonType"
                              formControlName="buttonType"
                            >
                              trimsList
                              <option
                                *ngFor="
                                  let option of trimsList?.button
                                    ?.materialTypeList; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.buttonType.errors) ||
                                (this.f.buttonType.invalid &&
                                  this.f.buttonType.dirty &&
                                  this.f.buttonType.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.buttonType.errors?.required"
                                class="red"
                              >
                                Type is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="buttonColor">Button Color</label>
                            <select
                              type="text"
                              title="Select Button Color"
                              class="form-control selectpicker w-100"
                              id="buttonColor"
                              formControlName="buttonColor"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.button?.buttonColor; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.buttonColor.errors) ||
                                (this.f.buttonColor.invalid &&
                                  this.f.buttonColor.dirty &&
                                  this.f.buttonColor.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.buttonColor.errors?.required"
                                class="red"
                              >
                                Button Color is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="buttonShape">Shape</label>
                            <select
                              type="text"
                              title="Select Shape"
                              class="form-control selectpicker w-100"
                              id="buttonShape"
                              formControlName="buttonShape"
                            >
                              <option
                                *ngFor="let option of trimsList?.button?.shape; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.buttonShape.errors) ||
                                (this.f.buttonShape.invalid &&
                                  this.f.buttonShape.dirty &&
                                  this.f.buttonShape.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.buttonShape.errors?.required"
                                class="red"
                              >
                                Shape is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="buttonSize"
                              >Button Size (diameter)</label
                            >
                            <select
                              type="text"
                              title="Select Button Size (diameter)"
                              class="form-control selectpicker w-100"
                              id="buttonSize"
                              formControlName="buttonSize"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.button?.buttonSize; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.buttonSize.errors) ||
                                (this.f.buttonSize.invalid &&
                                  this.f.buttonSize.dirty &&
                                  this.f.buttonSize.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.buttonSize.errors?.required"
                                class="red"
                              >
                                Button Size is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="buttonnuom">Unit of Measure</label>
                            <select
                              type="text"
                              title="Select UOM"
                              class="form-control selectpicker w-100"
                              id="buttonnuom"
                              formControlName="buttonnuom"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.button?.unitofMeasure; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.buttonnuom.errors) ||
                                (this.f.buttonnuom.invalid &&
                                  this.f.buttonnuom.dirty &&
                                  this.f.buttonnuom.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.buttonnuom.errors?.required"
                                class="red"
                              >
                                UOM is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Trims Button End -->
                        <!-- Trims Thread -->
                        <div
                          class="form-row"
                          *ngIf="
                            this.materialForm.get('materialclassification')
                              .value === 'Thread'
                          "
                        >
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="threadMot">Material of thread</label>
                            <select
                              type="text"
                              title="Select Material of thread"
                              class="form-control selectpicker w-100"
                              id="threadMot"
                              formControlName="threadMot"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.thread
                                    ?.materialofthread; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.threadMot.errors) ||
                                (this.f.threadMot.invalid &&
                                  this.f.threadMot.dirty &&
                                  this.f.threadMot.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.threadMot.errors?.required"
                                class="red"
                              >
                                Material of thread is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="threadColor">Color</label>
                            <select
                              type="text"
                              title="Select Color"
                              class="form-control selectpicker w-100"
                              id="threadColor"
                              formControlName="threadColor"
                            >
                              <option
                                *ngFor="let option of trimsList?.thread?.color; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.threadColor.errors) ||
                                (this.f.threadColor.invalid &&
                                  this.f.threadColor.dirty &&
                                  this.f.threadColor.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.threadColor.errors?.required"
                                class="red"
                              >
                                Color is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="threadPly">Ply</label>
                            <select
                              type="text"
                              title="Select Ply"
                              class="form-control selectpicker w-100"
                              id="threadPly"
                              formControlName="threadPly"
                            >
                              <option
                                *ngFor="let option of trimsList?.thread?.ply; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.threadPly.errors) ||
                                (this.f.threadPly.invalid &&
                                  this.f.threadPly.dirty &&
                                  this.f.threadPly.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.threadPly.errors?.required"
                                class="red"
                              >
                                Ply is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="threadLength"
                              >Length/Weight (Mtr/gm)</label
                            >
                            <select
                              type="text"
                              title="Select Length/Weight (Mtr/gm)"
                              class="form-control selectpicker w-100"
                              id="threadLength"
                              formControlName="threadLength"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.thread?.LengthWeight; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.threadLength.errors) ||
                                (this.f.threadLength.invalid &&
                                  this.f.threadLength.dirty &&
                                  this.f.threadLength.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.threadLength.errors?.required"
                                class="red"
                              >
                                Length/Weight is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Trims thread End -->
                        <!-- Trims Rivet -->
                        <div
                          class="form-row"
                          *ngIf="
                            this.materialForm.get('materialclassification')
                              .value === 'Rivet'
                          "
                        >
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="rivetMat">Rivet Material</label>
                            <select
                              type="text"
                              title="Select Rivet Material"
                              class="form-control selectpicker w-100"
                              id="rivetMat"
                              formControlName="rivetMat"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.rivet?.rivetMaterial; let i = index
                                "
                                [value]="option?.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.rivetMat.errors) ||
                                (this.f.rivetMat.invalid &&
                                  this.f.rivetMat.dirty &&
                                  this.f.rivetMat.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.rivetMat.errors?.required"
                                class="red"
                              >
                                Rivet Material is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="rivetShape">Shape</label>
                            <select
                              type="text"
                              title="Select Shape"
                              class="form-control selectpicker w-100"
                              id="rivetShape"
                              formControlName="rivetShape"
                            >
                              <option
                                *ngFor="let option of trimsList?.rivet?.shape; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.rivetShape.errors) ||
                                (this.f.rivetShape.invalid &&
                                  this.f.rivetShape.dirty &&
                                  this.f.rivetShape.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.rivetShape.errors?.required"
                                class="red"
                              >
                                Shape is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="rivetFinish">Finish</label>
                            <select
                              type="text"
                              title="Select Finish"
                              class="form-control selectpicker w-100"
                              id="rivetFinish"
                              formControlName="rivetFinish"
                            >
                              <option
                                *ngFor="let option of trimsList?.rivet?.finish; let i = index"
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.rivetFinish.errors) ||
                                (this.f.rivetFinish.invalid &&
                                  this.f.rivetFinish.dirty &&
                                  this.f.rivetFinish.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.rivetFinish.errors?.required"
                                class="red"
                              >
                                Finish is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="rivetCapDiameter">Cap Diameter</label>
                            <select
                              type="text"
                              title="Select Cap Diameter"
                              class="form-control selectpicker w-100"
                              id="rivetCapDiameter"
                              formControlName="rivetCapDiameter"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.rivet?.capDiameter; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.rivetCapDiameter.errors) ||
                                (this.f.rivetCapDiameter.invalid &&
                                  this.f.rivetCapDiameter.dirty &&
                                  this.f.rivetCapDiameter.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.rivetCapDiameter.errors?.required"
                                class="red"
                              >
                                Cap Diameter is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="rivetuom">Unit of Measure </label>
                            <select
                              type="text"
                              title="Select UOM"
                              class="form-control selectpicker w-100"
                              id="rivetuom"
                              formControlName="rivetuom"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.rivet?.unitsofMeasure; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.rivetuom.errors) ||
                                (this.f.rivetuom.invalid &&
                                  this.f.rivetuom.dirty &&
                                  this.f.rivetuom.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.rivetuom.errors?.required"
                                class="red"
                              >
                                UOM is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="rivetBaseDiameter">Base Diameter</label>
                            <select
                              type="text"
                              title="Select Base Diameter"
                              class="form-control selectpicker w-100"
                              id="rivetBaseDiameter"
                              formControlName="rivetBaseDiameter"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.rivet?.baseDiameter; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted &&
                                  this.f.rivetBaseDiameter.errors) ||
                                (this.f.rivetBaseDiameter.invalid &&
                                  this.f.rivetBaseDiameter.dirty &&
                                  this.f.rivetBaseDiameter.touched)
                              "
                            >
                              <div
                                *ngIf="
                                  this.f.rivetBaseDiameter.errors?.required
                                "
                                class="red"
                              >
                                Base Diameter is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-3 mb-3">
                            <label for="rivetBaseuom">Unit of Measure</label>
                            <select
                              type="text"
                              title="Select UOM"
                              class="form-control selectpicker w-100"
                              id="rivetBaseuom"
                              formControlName="rivetBaseuom"
                            >
                              <option
                                *ngFor="
                                  let option of trimsList?.rivet
                                    ?.unitsofBaseMeasure; let i = index
                                "
                                [value]="option.type"
                                [disabled]="i === 0"
                              >
                                {{ option?.value }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                (submitted && this.f.rivetBaseuom.errors) ||
                                (this.f.rivetBaseuom.invalid &&
                                  this.f.rivetBaseuom.dirty &&
                                  this.f.rivetBaseuom.touched)
                              "
                            >
                              <div
                                *ngIf="this.f.rivetBaseuom.errors?.required"
                                class="red"
                              >
                                UOM is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Trims Zipper End -->

                        <div *ngIf="materialclassificationValue">
                          <div class="form-row">
                            <div class="col-sm-12 mt-3 mb-3">
                              <label for="comments">{{
                                staticMsgs.matDetail.comment
                              }}</label>
                              <textarea
                                cols="2"
                                class="form-control"
                                formControlName="comments"
                                id="comments"
                                placeholder="Enter Comments"
                              ></textarea>
                              <div
                                class="red"
                                *ngIf="
                                  this.materialForm.controls.comments.errors &&
                                  this.materialForm.controls.comments.value &&
                                  this.materialForm.controls.comments.value
                                    .length > 500
                                "
                              >
                                {{ staticMsgs.matDetail.commentAllow }}
                              </div>
                            </div>
                          </div>

                          <div class="form-row mt-3 mb-3">
                            <div
                            class="col-xl-6 mt-6"
                            *ngIf="this.f.materialid.value"
                          >
                            <div
                              class="col-xl-6 mb-5"
                              *ngFor="
                                let editedThumbnail of editedThumbnails;
                                let j = index
                              "
                            >
                              <img
                                src="{{ editedThumbnail.thumbnail }}"
                                class="img-fluid img-fit" style="width: auto !important;"
                                *ngIf="isValidImageURL(editedThumbnail.name)"
                                (click)="openThumbnail(j)"
                              />
                              <button
                                type="button"
                                *ngIf="editedThumbnail.thumbnail"
                                class="close image-close"
                                (click)="
                                  removeThumbnailSection(editedThumbnail)
                                "
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                          </div>
                            <div class="col-xl-12">
                              <label for="thumbnail"
                                >{{ staticMsgs.matDetail.updImg
                                }}<span class="red_icon">*</span></label
                              >
                              <div
                                class="custom-dropzone"
                                id="thumbnail"
                                ngx-dropzone
                                [multiple]="false"
                                [accept]="'image/png,image/jpeg,image/jpg'"
                                (change)="onSelectThumbnail($event, 0)"
                              >
                                <ngx-dropzone-label>
                                  <div>
                                    <h5 class="text-light-gray mt-3">
                                      &nbsp;
                                      {{ staticMsgs.matDetail.dropImg }} &nbsp;
                                    </h5>
                                  </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview
                                  ngProjectAs="ngx-dropzone-preview"
                                  *ngFor="let f of fileData[0]"
                                  [file]="f"
                                  [removable]="true"
                                  (removed)="onRemoveThumbnail(f)"
                                >
                                  <ngx-dropzone-label
                                    >{{ f.name }} ({{ f.type }})
                                  </ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                              </div>
                              <div *ngIf="isFileError" class="red">
                                {{ staticMsgs.matDetail.imgReq }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-row mt-10 float-right">
                          <input
                            type="text"
                            class="form-control"
                            [hidden]="true"
                            id="materialid"
                            formControlName="materialid"
                            placeholder="Color Id"
                          />
                          <!-- <button type="button" *ngIf="!isEditMode" (click)="cancelMaterialCreate()" class="btn btn-outline-primary adv-search-btn mr-3">
                                                          Cancel
                                                        </button>
                                                  <button type="submit" *ngIf="!isEditMode"
                                                      class="btn btn-primary adv-search-btn">Create</button> -->

                          <div class="row mt-3">
                            <button
                              type="button"
                              *ngIf="!isEditMode"
                              (click)="cancelMaterialCreate()"
                              class="btn btn-outline-primary adv-search-btn"
                            >
                              {{ staticMsgs.matDetail.cancel }}
                            </button>
                            <button
                              type="submit"
                              *ngIf="!isEditMode"
                              class="btn btn-primary adv-search-btn"
                            >
                              {{ staticMsgs.matDetail.create }}
                            </button>

                            <button
                              type="submit"
                              *ngIf="isEditMode"
                              class="btn btn-primary adv-search-btn"
                            >
                              {{ staticMsgs.matDetail.update }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div *ngIf="isViewingMode">
                      <form>
                        <div class="form-row">
                          <div class="col-lg-12 col-sm-12">
                            <div class="form-row">
                              <div class="col-sm-3 mb-3">
                                <label
                                  class="label-view"
                                  for="this.f.materialseq.value"
                                  >{{ staticMsgs.matDetail.id }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.materialseq.value }}
                                </p>
                              </div>
                              <div class="col-sm-3 mb-3">
                                <label
                                  class="label-view"
                                  for="this.f.materialclassification.value"
                                  >{{
                                    staticMsgs.matDetail.classification
                                  }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.materialclassification.value }}
                                </p>
                              </div>
                              <div class="col-sm-3 mb-3">
                                <label
                                  class="label-view"
                                  for="this.f.materialname.value"
                                  >{{ staticMsgs.matDetail.name1 }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.materialname.value }}
                                </p>
                              </div>
                              <!-- <div class="col-sm-3 mb-3" *ngIf="this.f.subtype.value">
                                                              <label class="label-view" for="this.f.subtype.value">Sub
                                                                  Type</label>
                                                              <p class="mt-2 viewtext">{{this.f.subtype.value}} </p>
                                                          </div> -->

                              <!-- <div class="col-sm-12 form-row" > -->
                              <ng-container
                                *ngIf="parentClassification === 'Fabric'"
                              >
                              <div class="col-sm-3 mb-3">
                                <label class="label-view"
                                  >Type
                                </label>
                                <p class="mt-2 viewtext">
                                  {{ this.f.fabricType.value }}
                                </p>
                              </div>
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view"
                                    >Thread Count
                                  </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.threadCount.value }}
                                  </p>
                                </div>
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view">GSM </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.gSM.value }}
                                  </p>
                                </div>
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view">PLY </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.ply.value }}
                                  </p>
                                </div>
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view">Recommended care </label> 
                                  <p class="mt-2 viewtext">
                                    {{ this.f.wash.value }}
                                  </p>
                                </div>
                                <div class="col-sm-3 mb-3">
                                  <label
                                    class="label-view"
                                    for="this.f.fabriccomp.value"
                                    >{{ staticMsgs.matDetail.fabComp }}
                                  </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.fabriccomp.value }}
                                  </p>
                                </div>
                                <div class="col-sm-3 mb-3">
                                  <label
                                    class="label-view"
                                    for="this.f.finish.value"
                                    >Finish
                                  </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.finish.value }}
                                  </p>
                                </div>
                              </ng-container>
                              <!-- <div class="col-sm-3 mb-3" *ngIf="(parentClassification === 'Fabric')">

                                <label class="label-view"
                                  for="this.f.cuttablewidth.value">{{staticMsgs.matDetail.cutWidth}}
                                </label>
                                <p class="mt-2 viewtext">{{this.f.cuttablewidth.value}}
                                </p>
                              </div>
                              <div class="col-sm-3 mb-3" *ngIf="(parentClassification === 'Fabric')">
                                <label class="label-view"
                                  for="this.f.widthuom.value">{{staticMsgs.matDetail.wUOM}}</label>
                                <p class="mt-2 viewtext">{{this.f.widthuom.value}} </p>


                              </div>
                              <div class="col-sm-3 mb-3" *ngIf="(parentClassification === 'Fabric')">


                                <label class="label-view"
                                  for="this.f.weightgsm.value">{{staticMsgs.matDetail.weightGSM}}</label>
                                <p class="mt-2 viewtext">{{this.f.weightgsm.value}} </p>

                              </div>
                              <div class="col-sm-3  mb-3" *ngIf="(parentClassification === 'Fabric')">
                                <label class="label-view" for="this.f.fabriccomp.value">{{staticMsgs.matDetail.fabComp}}
                                </label>
                                <p class="mt-2 viewtext">{{this.f.fabriccomp.value}}
                                </p>

                              </div> -->

                              <!-- </div> -->
                              <!---Trim classification Start-->
                              <!-- <div  class="col-sm-12 form-row"
                                              *ngIf="((parentClassification === 'Trims')|| (parentClassification === 'Product Packaging'))"> -->
                              <!--<div class="col-sm-4 mb-3">
                                              <label for="cuttablewidth">Cuttable Width</label>
                                              <input type="text" class="form-control" id="cuttablewidth"
                                                  formControlName="cuttablewidth" placeholder="Cuttable Width"/>
                                          </div>-->

                                        <ng-container
                                        *ngIf="parentClassification === 'Material'"
                                      >
                                      <div class="col-sm-3 mb-3">
                                        <label class="label-view"
                                          >Type
                                        </label>
                                        <p class="mt-2 viewtext">
                                          {{ this.f.materialTypeList.value }}
                                        </p>
                                      </div>
                                        <div class="col-sm-3 mb-3">
                                          <label class="label-view"
                                            >Softness
                                          </label>
                                          <p class="mt-2 viewtext">
                                            {{ this.f.softness.value }}
                                          </p>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                          <label class="label-view">Durability </label>
                                          <p class="mt-2 viewtext">
                                            {{ this.f.durability.value }}
                                          </p>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                          <label class="label-view">Weight </label>
                                          <p class="mt-2 viewtext">
                                            {{ this.f.weight.value }}
                                          </p>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                          <label class="label-view">Unit Of Measure </label>
                                          <p class="mt-2 viewtext">
                                            {{ this.f.unitofMeasure.value }}
                                          </p>
                                        </div>
                                      </ng-container>
                                      <ng-container
                                      *ngIf="parentClassification === 'Product Packaging'"
                                    >
                                    <div class="col-sm-3 mb-3" *ngIf="this.materialForm.get('materialclassification').value === 'Sew in Label'">
                                      <label class="label-view"
                                        >Label Size
                                      </label>
                                      <p class="mt-2 viewtext">
                                        {{ this.f.sewLabelSize.value }}
                                      </p>
                                    </div>
                                      <div class="col-sm-3 mb-3" *ngIf="this.materialForm.get('materialclassification').value === 'Sew in Label'">
                                        <label class="label-view"
                                          >Fabric
                                        </label>
                                        <p class="mt-2 viewtext">
                                          {{ this.f.sewFabric.value }}
                                        </p>
                                      </div>
                                      <div class="col-sm-3 mb-3" *ngIf="this.materialForm.get('materialclassification').value === 'Hang Tag'">
                                        <label class="label-view">Size </label>
                                        <p class="mt-2 viewtext">
                                          {{ this.f.hangSize.value }}
                                        </p>
                                      </div>
                                      <div class="col-sm-3 mb-3" *ngIf="this.materialForm.get('materialclassification').value === 'Hang Tag'">
                                        <label class="label-view">Color </label>
                                        <p class="mt-2 viewtext">
                                          {{ this.f.hangColor.value }}
                                        </p>
                                      </div>
                                      <div class="col-sm-3 mb-3" *ngIf="this.materialForm.get('materialclassification').value === 'Hang Tag'">
                                        <label class="label-view">Material </label>
                                        <p class="mt-2 viewtext">
                                          {{ this.f.hangMaterial.value }}
                                        </p>
                                      </div>
                                      <div class="col-sm-3 mb-3" *ngIf="this.materialForm.get('materialclassification').value === 'Hang Tag'">
                                        <label
                                          class="label-view"
                                          for="this.f.fabriccomp.value"
                                          >GSM
                                        </label>
                                        <p class="mt-2 viewtext">
                                          {{ this.f.hangWeightgsm.value }}
                                        </p>
                                      </div>
                                    </ng-container>
                                  <ng-container
                                  *ngIf="
                                  this.materialForm.get('materialclassification').value === 'Zipper'"
                                >
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view"
                                    >Type
                                  </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.zipType.value }}
                                  </p>
                                </div>
                                  <div class="col-sm-3 mb-3">
                                    <label class="label-view"
                                      >Functionality
                                    </label>
                                    <p class="mt-2 viewtext">
                                      {{ this.f.zipFunctionality.value }}
                                    </p>
                                  </div>
                                  <div class="col-sm-3 mb-3">
                                    <label class="label-view">Two-way/ One-way Zippers </label>
                                    <p class="mt-2 viewtext">
                                      {{ this.f.zipper.value }}
                                    </p>
                                  </div>
                                </ng-container>
                                <ng-container
                                *ngIf="
                                this.materialForm.get('materialclassification').value === 'Button'"
                              >
                              <div class="col-sm-3 mb-3">
                                <label class="label-view"
                                  >Type
                                </label>
                                <p class="mt-2 viewtext">
                                  {{ this.f.buttonType.value }}
                                </p>
                              </div>
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view"
                                    >Button Color
                                  </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.buttonColor.value }}
                                  </p>
                                </div>
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view">Shape </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.buttonShape.value }}
                                  </p>
                                </div>
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view">Button Size (diameter) </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.buttonSize.value }}
                                  </p>
                                </div>
                                <div class="col-sm-3 mb-3">
                                  <label class="label-view">Unit of Measure </label>
                                  <p class="mt-2 viewtext">
                                    {{ this.f.buttonnuom.value }}
                                  </p>
                                </div>
                              </ng-container>
                              <ng-container
                              *ngIf="
                              this.materialForm.get('materialclassification').value === 'Thread'"
                            >
                            <div class="col-sm-3 mb-3">
                              <label class="label-view"
                                >Material of thread
                              </label>
                              <p class="mt-2 viewtext">
                                {{ this.f.threadMot.value }}
                              </p>
                            </div>
                              <div class="col-sm-3 mb-3">
                                <label class="label-view"
                                  >Color
                                </label>
                                <p class="mt-2 viewtext">
                                  {{ this.f.threadColor.value }}
                                </p>
                              </div>
                              <div class="col-sm-3 mb-3">
                                <label class="label-view">Ply </label>
                                <p class="mt-2 viewtext">
                                  {{ this.f.threadPly.value }}
                                </p>
                              </div>
                              <div class="col-sm-3 mb-3">
                                <label class="label-view">Length/Weight (Mtr/gm) </label>
                                <p class="mt-2 viewtext">
                                  {{ this.f.threadLength.value }}
                                </p>
                              </div>
                            </ng-container>
                            <ng-container
                            *ngIf="
                            this.materialForm.get('materialclassification').value === 'Rivet'"
                          >
                          <div class="col-sm-3 mb-3">
                            <label class="label-view"
                              >Rivet Material
                            </label>
                            <p class="mt-2 viewtext">
                              {{ this.f.rivetMat.value }}
                            </p>
                          </div>
                            <div class="col-sm-3 mb-3">
                              <label class="label-view"
                                >Shape
                              </label>
                              <p class="mt-2 viewtext">
                                {{ this.f.rivetShape.value }}
                              </p>
                            </div>
                            <div class="col-sm-3 mb-3">
                              <label class="label-view">Finish </label>
                              <p class="mt-2 viewtext">
                                {{ this.f.rivetFinish.value }}
                              </p>
                            </div>
                            <div class="col-sm-3 mb-3">
                              <label class="label-view">Cap Diameter </label>
                              <p class="mt-2 viewtext">
                                {{ this.f.rivetCapDiameter.value }}
                              </p>
                            </div>
                            <div class="col-sm-3 mb-3">
                              <label class="label-view">Unit of Measure </label>
                              <p class="mt-2 viewtext">
                                {{ this.f.rivetuom.value }}
                              </p>
                            </div>
                            <div class="col-sm-3 mb-3">
                              <label class="label-view">Base Diameter </label>
                              <p class="mt-2 viewtext">
                                {{ this.f.rivetBaseDiameter.value }}
                              </p>
                            </div>
                            <div class="col-sm-3 mb-3">
                              <label class="label-view">Unit of Measure </label>
                              <p class="mt-2 viewtext">
                                {{ this.f.rivetBaseuom.value }}
                              </p>
                            </div>
                          </ng-container>
                              <!-- <div
                                class="col-sm-3 mb-3"
                                *ngIf="parentClassification === 'Trims'"
                              >
                                <label
                                  class="label-view"
                                  for="this.f.subtype.value"
                                >
                                  {{ staticMsgs.matDetail.subType }}
                                </label>
                                <p class="mt-2 viewtext">
                                  {{ this.f.subtype.value }}
                                </p>
                              </div>
                              <div
                                class="col-sm-3 mb-3"
                                *ngIf="parentClassification === 'Trims'"
                              >
                                <label
                                  class="label-view"
                                  for="this.f.content.value"
                                  >{{ staticMsgs.matDetail.content }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.content.value }}
                                </p>
                              </div>
                              <div
                                class="col-sm-3 mb-3"
                                *ngIf="
                                  parentClassification === 'Trims' ||
                                  parentClassification === 'Product Packaging'
                                "
                              >
                                <label
                                  class="label-view"
                                  for="this.f.sizeuom.value"
                                  >{{ staticMsgs.matDetail.sizeUOM }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.sizeuom.value }}
                                </p>
                              </div> -->

                              <!--<div class="col-sm-4 mb-3">
                                              <label for="cost">Cost</label>
                                              <input type="text" class="form-control" id="trimcost"
                                                  formControlName="trimcost" placeholder="Cost"/>
                                          </div>-->

                              <!-- <div
                                class="col-sm-3 mt-3 mb-3"
                                *ngIf="
                                  parentClassification === 'Trims' ||
                                  parentClassification === 'Product Packaging'
                                "
                              >
                                <label
                                  class="label-view"
                                  for="this.f.size.value"
                                  >{{ staticMsgs.matDetail.size }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.size.value }}
                                </p>
                              </div> -->

                              <!-- <div
                                class="col-sm-3 mt-3 mb-3"
                                *ngIf="
                                  parentClassification === 'Trims' ||
                                  parentClassification === 'Product Packaging'
                                "
                              >
                                <label
                                  class="label-view"
                                  for="this.f.width.value"
                                  >{{ staticMsgs.matDetail.width }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.width.value }}
                                </p>
                              </div> -->

                              <!-- </div> -->
                              <!---Trim classification End-->

                              <!---Leather classification Start-->
                              <!-- <div class="col-sm-12 form-row" > -->

                              <!-- <div
                                class="col-sm-3 mb-3"
                                *ngIf="parentClassification === 'Material'"
                              >
                                <label
                                  class="label-view"
                                  for="this.f.substabce.value"
                                  >{{ staticMsgs.matDetail.substance }}
                                </label>
                                <p class="mt-2 viewtext">
                                  {{ this.f.substabce.value }}
                                </p>
                              </div> -->

                              <!-- <div
                                class="col-sm-3 mb-3"
                                *ngIf="parentClassification === 'Material'"
                              >
                                <label
                                  class="label-view"
                                  for="this.f.substanceuom.value"
                                >
                                  {{ staticMsgs.matDetail.subUOM }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.substanceuom.value }}
                                </p>
                              </div> -->

                              <!-- <div
                                class="col-sm-3 mt-3 mb-3"
                                *ngIf="parentClassification === 'Material'"
                              >
                                <label
                                  class="label-view"
                                  for="this.f.rawhide.value"
                                >
                                  {{ staticMsgs.matDetail.rawHide }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.rawhide.value }}
                                </p>
                              </div> -->

                              <!-- <div
                                class="col-sm-3 mb-3"
                                *ngIf="parentClassification === 'Material'"
                              >
                                <label
                                  class="label-view"
                                  for="this.f.cost.value"
                                  >{{ staticMsgs.matDetail.cost }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ this.f.cost.value }}
                                </p>
                              </div> -->

                              <!-- </div> -->
                              <!---Leather classification End-->
                              <!-- <div class="col-sm-4 mb-3">
                                                  <label for="this.f.comments.value">Comments</label>
                                                  <p class="mt-2 viewtext">{{this.f.comments.value}}	</p>
                                                </div> -->
                              <!-- <div class="col-sm-4 mb-3">
                                                  <label for="this.f.status.value">Status</label>
                                                  <p class="mt-2 viewtext">{{this.f.status.value}}</p>
                                                </div> -->
                            </div>
                          </div>
                        </div>

                        <div class="form-row mt-2">
                          <div class="col-lg-4 col-sm-3">
                            <div class="border-l-r" *ngIf="editedThumbnails">
                              <div
                                *ngFor="
                                  let editedThumbnail of editedThumbnails;
                                  let j = index
                                "
                              >
                                <img
                                  src="{{ editedThumbnail.thumbnail }}"
                                  class="card-view-img"
                                  *ngIf="isValidImageURL(editedThumbnail.name)"
                                  (click)="openThumbnail(j)"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-8 mb-3">
                            <label
                              class="label-view"
                              for="this.f.comments.value"
                              >{{ staticMsgs.matDetail.comment }}</label
                            >
                            <p class="mt-2 viewtext">
                              {{ this.f.comments.value }}
                            </p>
                          </div>
                        </div>

                        <!-- <div class="form-row mt-5">
                                              <div class="col-sm-4 mb-3">
                                                <label for="validationDefault02">Internal Ref #</label>
                                                <input type="text" class="form-control"  id="validationDefault02" placeholder="Last name" value="SEQ Col: 12345#" required="" disabled>
                                            </div>
                                              <div class="col-sm-8 mb-3">
                                                <label for="validationDefault01" >Description</label>
                                                <textarea cols="2" class="form-control" name="description" disabled>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate.</textarea>
                                            </div>
                                            </div> -->
                      </form>
                    </div>
                  </div>

                  <!-- /tab panel -->
                  <!-- Tab panel -->

                  <div
                    id="tab-pane2"
                    class="tab-pane card-body card-body-shadow bg-white"
                    [ngClass]="{ active: activeTab === 'colorTab' }"
                  >
                    <div
                      class="text-center"
                      *ngIf="matecolorData == null || matecolorData.length == 0"
                    >
                      <button
                        *ngIf="isEditingMode"
                        type="button"
                        class="btn btn-primary adv-search-btn mr-3 button-right"
                        data-toggle="modal"
                        data-target="#form-modalColor"
                        (click)="getAllColors('matecolor')"
                      >
                        <i class="icon icon-plus"></i>
                        {{ staticMsgs.matDetail.addColor }}
                      </button>

                      <h2>{{ staticMsgs.matDetail.noData }}</h2>
                      <img [src]="notfoundImg" class="img-fluid" /><br />
                    </div>
                    <kendo-grid
                      [resizable]="true"
                      #grid
                      [data]="gridData"
                      *ngIf="matecolorData != null && matecolorData.length > 0"
                      (pageChange)="onPageChangeMateColor($event)"
                      [(loading)]="matecolorloading"
                      [pageSize]="5"
                      [skip]="matecolorState.skip"
                      [sort]="matecolorState.sort"
                      [sortable]="true"
                      [pageable]="true"
                      [groupable]="true"
                      [filter]="matecolorState.filter"
                      [filterable]="true"
                      (dataStateChange)="dataStateChangeMateColor($event)"
                    >
                      <ng-template kendoGridToolbarTemplate>
                        <div class="row pd-t">
                          <div class="col-md-8">
                            <button
                              type="button"
                              *ngIf="isEditingMode"
                              class="btn btn-primary adv-search-btn mr-3"
                              data-toggle="modal"
                              data-target="#form-modalColor"
                              (click)="getAllColors('matecolor')"
                            >
                              <i class="icon icon-plus"></i>
                              {{ staticMsgs.matDetail.addColor }}
                            </button>
                          </div>
                          <div class="col-md-4">
                            <input
                              type="text"
                              class="form-control"
                              id=""
                              name="SearchD"
                              ([ngModel])="(SearchData)"
                              placeholder="Search"
                              #SearchD
                              (input)="searchListData(SearchD.value)"
                            />
                          </div>
                        </div>
                      </ng-template>

                      <kendo-grid-column title="Thumbnail" width="90">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div *ngIf="dataItem.hexcode">
                            <!-- <img class="w-100 border-l-r h-60"  [ngStyle]="{'background-color': dataItem.hexcode}" alt=""> -->
                            <img
                              *ngIf="dataItem.hexcode"
                              class="img"
                              [ngStyle]="{
                                'background-color': dataItem.hexcode
                              }"
                              alt=""
                            />
                          </div>
                          <div *ngIf="dataItem.thumbnailFiles">
                            <img
                              class="img"
                              alt=""
                              src="{{ dataItem.thumbnailFiles.thumbnail }}"
                            />
                          </div>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="name" title="Name">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="text-truncate">{{ dataItem.name }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <!--<kendo-grid-column *ngIf="!isEditMode" field="classification" title="Classification"></kendo-grid-column>
                                           <kendo-grid-column *ngIf="!isEditMode" field="colorStandard" title="Color Standard"></kendo-grid-column>-->

                      <!--<kendo-grid-column  field="classification" title="Classification" ></kendo-grid-column>-->
                      <kendo-grid-column
                        field="colorStandard"
                        title="Color Standard"
                      >
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="status"
                        title="Status"
                        width="150"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.status }}
                        </ng-template>
                        <ng-template
                          kendoGridEditTemplate
                          let-dataItem="dataItem"
                          let-rowIndex="rowIndex"
                        >
                          <kendo-dropdownlist
                            [data]="listItems"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [valuePrimitive]="true"
                          >
                          </kendo-dropdownlist>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column
                        *ngIf="!isEditMode"
                        field="CreatedbyName"
                        title="Created By"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.CreatedbyName }}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        *ngIf="isEditMode"
                        field="CreatedbyName"
                        title="Created By"
                        [editable]="false"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.CreatedbyName }}
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column *ngIf="isEditingMode">
                        <ng-template
                          kendoGridCellTemplate
                          let-dataItem
                          let-rowIndex="rowIndex"
                        >
                          <button
                            (click)="onColRemove(rowIndex, dataItem)"
                            class="k-button delete-icon"
                            title="Delete"
                          >
                            <i class="icon icon-trash f-16"></i>
                          </button>
                          <!-- <button (click)="onColRemove(rowIndex,dataItem)" class="k-button">Remove</button> -->
                        </ng-template>
                      </kendo-grid-column>
                    </kendo-grid>

                    <!-- /card -->
                  </div>
                  <!-- Color Tab End-->

                  <!-- Document Tab Start-->
                  <div
                    id="tab-pane3"
                    class="tab-pane card-body card-body-shadow bg-white"
                    [ngClass]="{ active: activeTab === 'documentTab' }"
                  >
                    <div
                      class="text-center"
                      *ngIf="materialDataCnt == 0 && !isEditingMode"
                    >
                      <h2>{{ staticMsgs.matDetail.noData }}</h2>
                      <img [src]="notfoundImg" class="img-fluid" />
                    </div>
                    <div *ngIf="isEditingMode">
                      <form
                        [formGroup]="documentsForm"
                        (ngSubmit)="onMaterialDocSubmit()"
                      >
                        <div formArrayName="documents">
                          <div
                            *ngFor="
                              let document of getControls();
                              let i = index
                            "
                          >
                            <div class="card p-5" [formGroupName]="i">
                              <div class="form-row">
                                <div class="col-sm-12 float-right">
                                  <button
                                    type="button"
                                    class="close"
                                    (click)="removeDoc(i)"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                              </div>

                              <div class="form-row">
                                <div class="col-lg-4 col-sm-12 mb-3">
                                  <label for="docclassification">
                                    {{ staticMsgs.matDetail.selectClass
                                    }}<span class="red_icon">*</span></label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="docclassification{{ i }}"
                                    formControlName="docclassification"
                                    placeholder="Classification"
                                    required
                                  />
                                  <div
                                    *ngIf="
                                      (submitted1 &&
                                        document.get('docclassification')
                                          .errors) ||
                                      (document.get('docclassification')
                                        .invalid &&
                                        (document.get('docclassification')
                                          .dirty ||
                                          document.get('docclassification')
                                            .touched))
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        document.get('docclassification').errors
                                          .required
                                      "
                                      class="red"
                                    >
                                      {{ staticMsgs.matDetail.classReq }}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-sm-4 mb-3"
                                  *ngIf="document.get('docid').value"
                                >
                                  <label for="docseq">{{
                                    staticMsgs.matDetail.document1
                                  }}</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    [readonly]="true"
                                    id="docseq"
                                    formControlName="docseq"
                                    placeholder="Document Id"
                                  />
                                </div>

                                <div class="col-lg-4 col-sm-12">
                                  <label for="docname"
                                    >{{ staticMsgs.matDetail.name1
                                    }}<span class="red_icon">*</span></label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="docname"
                                    formControlName="docname"
                                    minlength="3"
                                    maxlength="50"
                                    placeholder="Document Name"
                                    (keypress)="characterOnly($event)"
                                    required
                                  />
                                  <div
                                    *ngIf="
                                      (submitted1 &&
                                        document.get('docname').errors) ||
                                      (document.get('docname').invalid &&
                                        (document.get('docname').dirty ||
                                          document.get('docname').touched))
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        document.get('docname').errors.required
                                      "
                                      class="red"
                                    >
                                      {{ staticMsgs.matDetail.docName }}
                                    </div>

                                    <div
                                      *ngIf="
                                        document.get('docname').errors.minlength
                                      "
                                      class="red"
                                    >
                                      {{
                                        staticMsgs.common
                                          .minimum3charactersrequired
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="form-row mt-5">
                                <div class="col-sm-4 mb-3">
                                  <label for="docstatus"
                                    >{{ staticMsgs.matDetail.status
                                    }}<span class="red_icon">*</span></label
                                  >
                                  <select
                                    type="text"
                                    class="form-control selectpicker"
                                    id="docstatus"
                                    formControlName="docstatus"
                                    required
                                  >
                                    <option value="" [disabled]="true" selected>
                                      {{ staticMsgs.matDetail.selectStatus }}
                                    </option>
                                    <option value="Active">
                                      {{ staticMsgs.matDetail.active }}
                                    </option>
                                    <option value="Inactive">
                                      {{ staticMsgs.matDetail.inactive }}
                                    </option>
                                  </select>
                                  <div
                                    *ngIf="
                                      (submitted1 &&
                                        document.get('docstatus').errors) ||
                                      (document.get('docstatus').invalid &&
                                        (document.get('docstatus').dirty ||
                                          document.get('docstatus').touched))
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        document.get('docstatus').errors
                                          .required
                                      "
                                      class="red"
                                    >
                                      {{ staticMsgs.matDetail.statusReq }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-8 mb-3">
                                  <label for="docdescription"
                                    >{{ staticMsgs.matDetail.description
                                    }}<span class="red_icon">*</span></label
                                  >
                                  <textarea
                                    cols="2"
                                    class="form-control"
                                    id="docdescription"
                                    formControlName="docdescription"
                                    required
                                  ></textarea>
                                  <div
                                    *ngIf="
                                      (submitted1 &&
                                        document.get('docdescription')
                                          .errors) ||
                                      (document.get('docdescription').invalid &&
                                        (document.get('docdescription').dirty ||
                                          document.get('docdescription')
                                            .touched))
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        document.get('docdescription').errors
                                          .required
                                      "
                                      class="red"
                                    >
                                      {{ staticMsgs.matDetail.descReq }}
                                    </div>
                                    <div
                                      *ngIf="
                                        document.get('docdescription').value &&
                                        document.get('docdescription').value
                                          .length > 500
                                      "
                                      class="red"
                                    >
                                      {{ staticMsgs.matDetail.descAllow }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="dt-entry__header"
                                *ngIf="
                                  document.get('docclassification').value ===
                                  'Lab Reports'
                                "
                              >
                                <div class="dt-entry__heading">
                                  <h3 class="dt-entry__title">
                                    {{
                                      document.get("docclassification").value
                                    }}
                                  </h3>
                                </div>
                              </div>

                              <div
                                class="form-row"
                                *ngIf="
                                  document.get('docclassification').value ===
                                  'Lab Reports'
                                "
                              >
                                <div class="col-sm-4 mb-3">
                                  <label for="docreportstatus"
                                    >{{ staticMsgs.matDetail.report
                                    }}<span class="red_icon">*</span></label
                                  >
                                  <select
                                    type="text"
                                    title="Select
                                                              Report Status"
                                    class="form-control selectpicker w-100"
                                    id="docreportstatus"
                                    formControlName="docreportstatus"
                                    required
                                  >
                                    <option value="" [disabled]="true" selected>
                                      {{ staticMsgs.matDetail.selectReport }}
                                    </option>
                                    <option value="Pass">
                                      {{ staticMsgs.matDetail.pass }}
                                    </option>
                                    <option value="Fail">
                                      {{ staticMsgs.matDetail.fail }}
                                    </option>
                                  </select>
                                  <div
                                    *ngIf="
                                      (submitted1 &&
                                        document.get('docreportstatus')
                                          .errors) ||
                                      (document.get('docreportstatus')
                                        .invalid &&
                                        (document.get('docreportstatus')
                                          .dirty ||
                                          document.get('docreportstatus')
                                            .touched))
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        document.get('docreportstatus').errors
                                          .required
                                      "
                                      class="red"
                                    >
                                      {{ staticMsgs.matDetail.reportReq }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4 mb-3">
                                  <label for="doclablocation"
                                    >{{ staticMsgs.matDetail.labLoc
                                    }}<span class="red_icon">*</span></label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="doclablocation"
                                    formControlName="doclablocation"
                                    placeholder="Lab Location"
                                    required
                                  />
                                  <div
                                    *ngIf="
                                      (submitted1 &&
                                        document.get('doclablocation')
                                          .errors) ||
                                      (document.get('doclablocation').invalid &&
                                        (document.get('doclablocation').dirty ||
                                          document.get('doclablocation')
                                            .touched))
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        document.get('doclablocation').errors
                                          .required
                                      "
                                      class="red"
                                    >
                                      {{ staticMsgs.matDetail.labReq }}
                                    </div>
                                    <div
                                      *ngIf="
                                        document.get('doclablocation').value &&
                                        document.get('doclablocation').value
                                          .length > 50
                                      "
                                      class="red"
                                    >
                                      {{ staticMsgs.matDetail.labAllow }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="dt-entry__header">
                                <div class="dt-entry__heading">
                                  <h3 class="dt-entry__title">
                                    {{ staticMsgs.matDetail.document }}
                                  </h3>
                                </div>
                              </div>

                              <div class="form-row" [hidden]="true">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="docdeletedfileids"
                                  formControlName="docdeletedfileids"
                                  placeholder="Deleted File Ids"
                                />
                              </div>

                              <div class="form-row">
                                <div class="col-xl-12">
                                  <label for="document"
                                    >{{ staticMsgs.common.docname
                                    }}<span class="red_icon">*</span></label
                                  >
                                  <div
                                    class="custom-dropzone"
                                    id="docupload"
                                    ngx-dropzone
                                    (change)="onSelectDoc($event, i)"
                                  >
                                    <ngx-dropzone-label>
                                      <div>
                                        <h5 class="text-light-gray mt-3">
                                          {{ staticMsgs.matDetail.dropFile }}
                                        </h5>
                                      </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview
                                      ngProjectAs="ngx-dropzone-preview"
                                      *ngFor="let f of fileData[i]"
                                      [file]="f"
                                      [removable]="true"
                                      (removed)="onRemove(f, i)"
                                    >
                                      <ngx-dropzone-label
                                        >{{ f.name }}
                                        <!--  ({{ f.type }}) -->
                                      </ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                  </div>
                                  <div *ngIf="doc_Error[i]">
                                    <span class="red">
                                      <span>
                                        {{ doc_Error[i] }}
                                      </span></span
                                    >
                                  </div>
                                  <div
                                    class="col-xl-6 mt-6"
                                    *ngIf="document.get('docid').value"
                                  >
                                    <div
                                      class="col-xl-6 mb-5"
                                      *ngFor="
                                        let img of editedFileData[i];
                                        let j = index
                                      "
                                    >
                                      <img
                                        src="{{ img.thumbnail }}"
                                        class="img-fluid img-fit"
                                        *ngIf="isValidImageURL(img.name)"
                                        (click)="open(i, j)"
                                      />
                                      <a
                                        href="{{ img.thumbnail }}"
                                        target="_blank"
                                        download
                                        ><img
                                          src="{{ documentImage }}"
                                          class="img-fluid img-fit"
                                          *ngIf="!isValidImageURL(img.name)"
                                      /></a>
                                      <button
                                        type="button"
                                        class="close image-close"
                                        (click)="removeImgSection(img, i)"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="form-row mt-5"
                                *ngIf="
                                  fileData[i] && !isEmptyObject(fileData[i])
                                "
                              >
                                <div class="col-xl-12">
                                  <div class="card">
                                    <div class="dt-card__body p-2">
                                      <label for="docnotes">{{
                                        staticMsgs.matDetail.notes
                                      }}</label>
                                      <textarea
                                        cols="2"
                                        class="form-control"
                                        id="docnotes"
                                        formControlName="docnotes"
                                      ></textarea>
                                      <div
                                        *ngIf="
                                          document.get('docnotes').errors &&
                                          document.get('docnotes').value &&
                                          document.get('docnotes').value
                                            .length > 500
                                        "
                                        class="red"
                                      >
                                        {{ staticMsgs.matDetail.noteAllow }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <input
                                type="text"
                                class="form-control"
                                [hidden]="true"
                                id="docid"
                                formControlName="docid"
                                placeholder="Document Id"
                              />
                              <input
                                type="text"
                                class="form-control"
                                [hidden]="true"
                                formControlName="materialid"
                                placeholder="Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="form-row text-center mb-7">
                          <div class="col-sm-12">
                            <!-- <button type="button" class="btn btn-primary adv-search-btn">Select Existing</button> -->
                            <button
                              type="button"
                              class="btn btn-primary adv-search-btn"
                              (click)="addNewDoc()"
                            >
                              <i class="icon icon-plus"></i>
                              {{ staticMsgs.matDetail.addNew }}
                            </button>
                          </div>
                        </div>

                        <div
                          class="form-row text-center mb-7"
                          *ngIf="showButton"
                        >
                          <div class="col-sm-12">
                            <button
                              type="submit"
                              *ngIf="fileData.length > 0"
                              [disabled]="
                                documentsForm?.invalid || !isFilesAdded
                              "
                              class="btn btn-primary adv-search-btn"
                            >
                              {{ staticMsgs.matDetail.save }}
                            </button>

                            <button
                              type="button"
                              *ngIf="isEditMode"
                              (click)="cancelDocumentEdit()"
                              class="btn btn-primary adv-search-btn"
                            >
                              {{ staticMsgs.common.cancel }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div *ngIf="isViewingMode">
                      <!-- <div class="text-center" *ngIf="materialDataCnt == 0">
                                          <h3 class="mt-4">no data available</h3>
                                          <img [src]="notfoundImg" class="img-fluid w-40"><br>
                                        </div> -->
                      <div>
                        <div
                          *ngFor="let document of getControls(); let i = index"
                        >
                          <div class="dt-entry__header">
                            <!-- Entry Heading -->
                            <div class="dt-entry__heading">
                              <h3 class="dt-entry__title">
                                {{ document.get("docclassification").value }}
                              </h3>
                            </div>
                            <!-- /entry heading -->
                          </div>
                          <form>
                            <div class="form-row">
                              <div class="col-sm-4 mb-3">
                                <label
                                  class="label-view"
                                  for="{{ document.get('docseq').value }}"
                                  >{{ staticMsgs.matDetail.document1 }}
                                </label>
                                <p class="mt-2 viewtext">
                                  {{ document.get("docseq").value }}
                                </p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label
                                  class="label-view"
                                  for="document.get('docname').value"
                                  >{{ staticMsgs.matDetail.name1 }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ document.get("docname").value }}
                                </p>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label
                                  class="label-view"
                                  for="document.get('docstatus').value"
                                  >{{ staticMsgs.matDetail.status }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{
                                    document.get("docstatus").value | titlecase
                                  }}
                                </p>
                              </div>

                              <div
                                class="col-sm-4 mb-3"
                                *ngIf="
                                  document.get('docclassification').value ===
                                  'Lab Reports'
                                "
                              >
                                <label
                                  class="label-view"
                                  for="document.get('docreportstatus').value"
                                >
                                  {{ staticMsgs.matDetail.report }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ document.get("docreportstatus").value }}
                                </p>
                              </div>
                              <div
                                class="col-sm-4 mb-3"
                                *ngIf="
                                  document.get('docclassification').value ===
                                  'Lab Reports'
                                "
                              >
                                <label
                                  class="label-view"
                                  for="document.get('doclablocation').value"
                                >
                                  {{ staticMsgs.matDetail.labLoc }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ document.get("doclablocation").value }}
                                </p>
                              </div>

                              <div class="col-sm-8 mb-3">
                                <label
                                  class="label-view"
                                  for="document.get('docdescription').value"
                                  >{{ staticMsgs.matDetail.description }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ document.get("docdescription").value }}
                                </p>
                              </div>
                              <div
                                class="col-sm-8 mb-3"
                                *ngIf="document.get('docnotes').value"
                              >
                                <label
                                  class="label-view"
                                  for="document.get('docnotes').value"
                                  >{{ staticMsgs.matDetail.notes }}</label
                                >
                                <p class="mt-2 viewtext">
                                  {{ document.get("docnotes").value }}
                                </p>
                              </div>
                            </div>

                            <div class="form-row mt-2 ml-2 mr-2">
                              <div
                                class="col-xl-3 mb-5"
                                *ngFor="
                                  let img of editedFileData[i];
                                  let j = index
                                "
                              >
                                <img
                                  src="{{ img.thumbnail }}"
                                  class="img-fluid img-fit"
                                  *ngIf="isValidImageURL(img.name)"
                                  (click)="open(i, j)"
                                />
                                <a
                                  href="{{ img.thumbnail }}"
                                  target="_blank"
                                  download
                                  ><img
                                    src="{{ documentImage }}"
                                    class="img-fluid img-fit"
                                    *ngIf="!isValidImageURL(img.name)"
                                /></a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Document Tab End-->

                  <!-- Supplier Tab Start-->
                  <div
                    id="tab-pane4"
                    class="tab-pane card-body card-body-shadow bg-white"
                    [ngClass]="{ active: activeTab === 'supplierTab' }"
                  >
                    <div
                      class="text-center"
                      *ngIf="supplierData == null || supplierData.length == 0"
                    >
                      <button
                        *ngIf="isEditingMode"
                        type="button"
                        class="btn btn-primary adv-search-btn mr-3 button-right"
                        data-toggle="modal"
                        (click)="getAllSupplierData()"
                        data-target="#supplier-form-modal"
                      >
                        <i class="icon icon-plus"></i>
                        {{ staticMsgs.matDetail.addSupplier }}
                      </button>
                      <h2>{{ staticMsgs.matDetail.noData }}</h2>
                      <img [src]="notfoundImg" class="img-fluid" /><br />
                    </div>
                    <kendo-grid
                      #grid
                      [data]="gridSupData"
                      *ngIf="supplierData !== null && supplierData.length > 0"
                      (detailExpand)="onExpand($event)"
                      (pageChange)="onPageChangeMateSupplier($event)"
                      [(loading)]="matesupplierloading"
                      [pageSize]="5"
                      [skip]="matesupplierState.skip"
                      [sort]="matesupplierState.sort"
                      [reorderable]="true"
                      [resizable]="true"
                      [columnMenu]="{ filter: true }"
                      [sortable]="true"
                      [pageable]="true"
                      [groupable]="true"
                      [filter]="matesupplierState.filter"
                      [filterable]="true"
                      (dataStateChange)="dataStateChangeMateSupplier($event)"
                    >
                      <ng-template kendoGridToolbarTemplate>
                        <div class="row pd-t">
                          <div class="col-md-6" *ngIf="isEditingMode">
                            <button
                              type="button"
                              class="btn btn-primary adv-search-btn mr-3"
                              data-toggle="modal"
                              (click)="getAllSupplierData()"
                              data-target="#supplier-form-modal"
                            >
                              <i class="icon icon-plus"></i>
                              {{ staticMsgs.matDetail.addSupplier }}
                            </button>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="text"
                              class="form-control"
                              id="SearchD"
                              name="SearchD"
                              ([ngModel])="(SearchData)"
                              placeholder="Serach"
                              #SearchD
                              (input)="searchSupplierListData(SearchD.value)"
                            />
                          </div>
                        </div>
                      </ng-template>

                      <kendo-grid-column title="Thumbnail" width="90">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <img
                            *ngIf="!dataItem.thumbnail"
                            class="img"
                            [src]="thumbnail"
                            alt=""
                          />
                          <img
                            *ngIf="dataItem.thumbnail"
                            class="img"
                            [src]="dataItem.thumbnail"
                            alt=""
                          />
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="name" title="Name">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="text-truncate">{{ dataItem.name }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <!--<kendo-grid-column *ngIf="!isEditMode" field="classification" title="Classification"></kendo-grid-column>
                                          <kendo-grid-column *ngIf="!isEditMode" field="colorStandard" title="Color Standard"></kendo-grid-column>-->

                      <!--<kendo-grid-column  field="classification" title="Classification" ></kendo-grid-column>-->
                      <kendo-grid-column
                        field="description"
                        title="Description"
                      >
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="capacityUOM"
                        title="UOM"
                      ></kendo-grid-column>
                      <kendo-grid-column
                        field="materialSupplierPrice"
                        title="Price"
                      >
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="auditStatus"
                        title="Status"
                        width="150"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.auditStatus }}
                        </ng-template>
                        <ng-template
                          kendoGridEditTemplate
                          let-dataItem="dataItem"
                          let-rowIndex="rowIndex"
                        >
                          <kendo-dropdownlist
                            [data]="listItems"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [valuePrimitive]="true"
                          >
                          </kendo-dropdownlist>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column
                        *ngIf="!isEditMode"
                        field="CreatedbyName"
                        title="Created By"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.CreatedbyName }}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        *ngIf="isEditMode"
                        field="CreatedbyName"
                        title="Created By"
                        [editable]="false"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.CreatedbyName }}
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column
                        title="Supplier"
                        [editable]="false"
                        *ngIf="isEditingMode"
                      >
                        <ng-template
                          kendoGridCellTemplate
                          let-dataItem
                          let-rowIndex="rowIndex"
                        >
                          <button
                            type="button"
                            class="btn btn-primary btn-esm mr-3"
                            data-toggle="modal"
                            data-target="#form-modalColor"
                            (click)="getsupplierId(dataItem.id)"
                          >
                            <i class="icon icon-plus"></i>
                            {{ staticMsgs.matDetail.color }}
                          </button>
                          <button
                            (click)="onSupRemove(rowIndex, dataItem)"
                            class="k-button delete-icon"
                            title="Delete"
                          >
                            <i class="icon icon-trash f-16"></i>
                          </button>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-excel
                        fileName="ColorLibrary.xlsx"
                        [fetchData]="allSupData"
                      >
                      </kendo-grid-excel>
                      <kendo-grid-pdf
                        fileName="ColorLibrary.pdf"
                        [allPages]="true"
                      >
                        <kendo-grid-pdf-margin
                          top="0.5cm"
                          left="0.5cm"
                          right="0.5cm"
                          bottom="0.5cm"
                        ></kendo-grid-pdf-margin>
                      </kendo-grid-pdf>
                      <ng-template kendoGridDetailTemplate let-dataItem>
                        <kendo-grid
                          [resizable]="true"
                          [data]="dataItem.colours"
                        >
                          <kendo-grid-column field="name" title="Color Name">
                          </kendo-grid-column>
                          <kendo-grid-column
                            field="colorStandard"
                            title="Color Standard"
                          >
                          </kendo-grid-column>
                          <kendo-grid-column
                            field="status"
                            title="Status"
                            width="150"
                          >
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{ dataItem.status }}
                            </ng-template>
                            <ng-template
                              kendoGridEditTemplate
                              let-dataItem="dataItem"
                              let-rowIndex="rowIndex"
                            >
                              <kendo-dropdownlist
                                [data]="listItems"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [valuePrimitive]="true"
                              >
                              </kendo-dropdownlist>
                            </ng-template>
                          </kendo-grid-column>

                          <kendo-grid-column
                            field="CreatedbyName"
                            title="Created By"
                          >
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{ dataItem.CreatedbyName }}
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column *ngIf="isEditingMode" width="80">
                            <ng-template
                              kendoGridCellTemplate
                              let-dataItem
                              let-rowIndex="rowIndex"
                            >
                              <button
                                (click)="onSupColorRemove(rowIndex, dataItem)"
                                class="k-button delete-icon"
                                title="Delete"
                              >
                                <i class="icon icon-trash f-16"></i>
                              </button>
                            </ng-template>
                          </kendo-grid-column>
                        </kendo-grid>
                      </ng-template>
                    </kendo-grid>
                  </div>
                  <!-- Supplier Tab End-->
                </div>
              </div>
              <!-- /tab content-->
            </div>
            <!-- /card body -->
          </div>
          <!-- /card -->
        </div>
        <!-- /grid item -->
        <div class="dt-card__body">
          <!-- Button trigger modal -->

          <!-- Modal -->

          <div
            class="modal fade"
            id="form-modalColor"
            tabindex="-1"
            role="dialog"
            aria-labelledby="model-12"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <!-- Modal Content -->
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h3 class="modal-title" id="model-8">
                    {{ staticMsgs.matDetail.color }}
                  </h3>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- /modal header -->

                <!-- Modal Body -->
                <form [formGroup]="materialColorForm">
                  <div class="modal-body">
                    <!--<div class="form-group">
                                      <label for="recipient-name" class="col-form-label">Recipient:</label>
                                      <input class="form-control" id="recipient-name"
                                          type="text">
                                  </div>-->
                    <!--<input type="text" [hidden]="true"  formControlName="materialid" />-->
                    <div class="form-group">
                      <kendo-grid
                        #grid
                        [data]="gridDatacol"
                        (pageChange)="onPageChangeColor($event)"
                        [(loading)]="colorloading"
                        [pageSize]="5"
                        [skip]="colorState.skip"
                        [sort]="colorState.sort"
                        [reorderable]="true"
                        [resizable]="true"
                        [columnMenu]="{ filter: true }"
                        [sortable]="true"
                        [pageable]="true"
                        [groupable]="true"
                        [filter]="colorState.filter"
                        [filterable]="true"
                        (dataStateChange)="dataStateChangeColor($event)"
                      >
                        <!--<kendo-grid-checkbox-column title=""  width="50">
                                              </kendo-grid-checkbox-column>-->

                        <ng-template
                          *ngIf="!isEditMode"
                          kendoGridToolbarTemplate
                        >
                          <div class="row">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-primary adv-search-btn mr-3"
                                routerLink="/colordetails"
                              >
                                <i class="icon icon-plus"></i>
                                {{ staticMsgs.matDetail.createNew }}
                              </button>
                            </div>
                            <div class="col-md-3">
                              <!--<button kendoGridEditCommand>Select</button>-->
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                id=""
                                name="SearchD"
                                ([ngModel])="(SearchData)"
                                placeholder="Serach"
                                #SearchD
                                (input)="searchListData(SearchD.value)"
                              />
                            </div>
                          </div>
                        </ng-template>
                        <kendo-grid-column width="50">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <input
                              type="checkbox"
                              id="dataItem.id"
                              class="chkbxqcolor"
                              (change)="
                                onChange(dataItem.id, $event.target.checked)
                              "
                            />
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Thumbnail" width="90">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <div *ngIf="dataItem.hexcode">
                              <!-- <img class="w-100 border-l-r h-60"  [ngStyle]="{'background-color': dataItem.hexcode}" alt=""> -->
                              <img
                                *ngIf="dataItem.hexcode"
                                class="img"
                                [ngStyle]="{
                                  'background-color': dataItem.hexcode
                                }"
                                alt=""
                              />
                            </div>
                            <div *ngIf="dataItem.thumbnailFiles">
                              <img
                                class="img"
                                alt=""
                                src="{{ dataItem.thumbnailFiles.thumbnail }}"
                              />
                            </div>
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="name" title="Name">
                        </kendo-grid-column>
                        <!--<kendo-grid-column *ngIf="!isEditMode" field="classification" title="Classification"></kendo-grid-column>
                                                  <kendo-grid-column *ngIf="!isEditMode" field="colorStandard" title="Color Standard"></kendo-grid-column>-->

                        <!--<kendo-grid-column  field="classification" title="Classification" ></kendo-grid-column>-->
                        <kendo-grid-column
                          field="colorStandard"
                          title="Color Standard"
                        >
                        </kendo-grid-column>
                        <kendo-grid-column
                          field="status"
                          title="Status"
                          width="150"
                        >
                          <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.status }}
                          </ng-template>
                          <ng-template
                            kendoGridEditTemplate
                            let-dataItem="dataItem"
                            let-rowIndex="rowIndex"
                          >
                            <kendo-dropdownlist
                              [data]="listItems"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [valuePrimitive]="true"
                            >
                            </kendo-dropdownlist>
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column
                          *ngIf="!isEditMode"
                          field="CreatedbyName"
                          title="Created By"
                        >
                          <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.CreatedbyName }}
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                          *ngIf="isEditMode"
                          field="CreatedbyName"
                          title="Created By"
                          [editable]="false"
                        >
                          <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.CreatedbyName }}
                          </ng-template>
                        </kendo-grid-column>
                      </kendo-grid>
                    </div>
                  </div>
                  <!-- /modal body -->

                  <!-- Modal Footer -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                      data-dismiss="modal"
                    >
                      {{ staticMsgs.matDetail.close }}
                    </button>
                    <button
                      (click)="createColorRea()"
                      type="submit"
                      class="btn btn-primary adv-search-btn"
                      [disabled]="materialColorForm?.invalid"
                      data-dismiss="modal"
                    >
                      {{ staticMsgs.matDetail.addColor }}
                    </button>
                  </div>
                  <!-- /modal footer -->
                </form>
              </div>
              <!-- /modal content -->
            </div>
          </div>

          <!-- /modal -->
          <!-- Suppliermodal -->
          <div
            class="modal fade"
            id="supplier-form-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="model-12"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <!-- Modal Content -->
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h3 class="modal-title" id="model-8">
                    {{ staticMsgs.matDetail.supplier }}
                  </h3>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- /modal header -->

                <!-- Modal Body -->
                <form [formGroup]="materialForm">
                  <div class="modal-body">
                    <!--<div class="form-group">
                              <label for="recipient-name" class="col-form-label">Recipient:</label>
                              <input class="form-control" id="recipient-name"
                                  type="text">
                          </div>-->

                    <div class="form-group">
                      <kendo-grid
                        #grid
                        [data]="gridDatasup"
                        (pageChange)="onPageChangeSupplier($event)"
                        [(loading)]="supplierloading"
                        [pageSize]="5"
                        [skip]="supplierState.skip"
                        [sort]="supplierState.sort"
                        [reorderable]="true"
                        [resizable]="true"
                        [columnMenu]="{ filter: true }"
                        [sortable]="true"
                        [pageable]="true"
                        [groupable]="true"
                        [filter]="supplierState.filter"
                        [filterable]="true"
                        (dataStateChange)="dataStateChangeSupplier($event)"
                      >
                        <!-- <kendo-grid-checkbox-column title=""  width="50">
                                          <input type="checkbox" (change)="onChange(dataItem.id, $event.target.checked)" />
                                      </kendo-grid-checkbox-column>  -->
                        <kendo-grid-column
                          [filterable]="false"
                          field="id"
                          title=""
                          width="50"
                        >
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <input
                              type="checkbox"
                              class="chkbxqsupplier"
                              (change)="
                                onSupChange(dataItem.id, $event.target.checked)
                              "
                            />
                          </ng-template>
                        </kendo-grid-column>
                        <ng-template
                          *ngIf="!isEditMode"
                          kendoGridToolbarTemplate
                        >
                          <div class="row">
                            <div class="col-md-6">
                              <button
                                type="button"
                                class="btn btn-primary adv-search-btn mr-3"
                                routerLink="/supplierdetails"
                              >
                                <i class="icon icon-plus"></i>
                                {{ staticMsgs.matDetail.createNew }}
                              </button>
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                id="SearchD"
                                name="SearchD"
                                ([ngModel])="(SearchData)"
                                placeholder="Serach"
                                #SearchD
                                (input)="searchSupplierListData(SearchD.value)"
                              />
                            </div>
                          </div>
                        </ng-template>

                        <kendo-grid-column title="Thumbnail" width="90">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <img
                              *ngIf="!dataItem.thumbnail"
                              class="w-100 border-l-r h-60"
                              [src]="thumbnail"
                              alt=""
                            />
                            <img
                              *ngIf="dataItem.thumbnail"
                              class="w-100 border-l-r h-60"
                              [src]="dataItem.thumbnail"
                              alt=""
                            />
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="name" title="Name">
                        </kendo-grid-column>
                        <!--<kendo-grid-column *ngIf="!isEditMode" field="classification" title="Classification"></kendo-grid-column>
                                          <kendo-grid-column *ngIf="!isEditMode" field="colorStandard" title="Color Standard"></kendo-grid-column>-->

                        <!--<kendo-grid-column  field="classification" title="Classification" ></kendo-grid-column>-->
                        <kendo-grid-column field="phoneNo" title="Phone Number">
                        </kendo-grid-column>
                        <kendo-grid-column
                          field="auditStatus"
                          title="Status"
                          width="150"
                        >
                          <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.auditStatus }}
                          </ng-template>
                          <ng-template
                            kendoGridEditTemplate
                            let-dataItem="dataItem"
                            let-rowIndex="rowIndex"
                          >
                            <kendo-dropdownlist
                              [data]="listItems"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [valuePrimitive]="true"
                            >
                            </kendo-dropdownlist>
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column
                          *ngIf="!isEditMode"
                          field="CreatedbyName"
                          title="Created By"
                        >
                          <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.CreatedbyName }}
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                          *ngIf="isEditMode"
                          field="CreatedbyName"
                          title="Created By"
                          [editable]="false"
                        >
                          <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.CreatedbyName }}
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-excel
                          fileName="ColorLibrary.xlsx"
                          [fetchData]="allData"
                        >
                        </kendo-grid-excel>
                        <kendo-grid-pdf
                          fileName="ColorLibrary.pdf"
                          [allPages]="true"
                        >
                          <kendo-grid-pdf-margin
                            top="0.5cm"
                            left="0.5cm"
                            right="0.5cm"
                            bottom="0.5cm"
                          ></kendo-grid-pdf-margin>
                        </kendo-grid-pdf>
                      </kendo-grid>
                    </div>
                  </div>
                  <!-- /modal body -->

                  <!-- Modal Footer -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                      data-dismiss="modal"
                    >
                      {{ staticMsgs.matDetail.close }}
                    </button>
                    <button
                      (click)="createMatSupplier()"
                      class="btn btn-primary adv-search-btn"
                      [disabled]="materialForm?.invalid"
                      data-dismiss="modal"
                    >
                      {{ staticMsgs.matDetail.addSupplier }}
                    </button>
                  </div>
                  <!-- /modal footer -->
                </form>
              </div>
              <!-- /modal content -->
            </div>
          </div>
          <!-- /Suppliermodal -->
        </div>
        <!-- /card body -->
      </div>
      <!-- /grid -->
    </div>
  </div>
  <!-- /Site Content Wrapper-->
</main>
<!-- /site content -->

<!-- Footer -->
<app-footer></app-footer>
<!-- /footer -->
