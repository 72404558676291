<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">
    <!-- Site Content -->
    <div class="dt-content">
      <div class="row">
        <!-- Page Header -->
        <div class="col-xl-4 col-sm-4 col-md-4">
          <div class="dt-page__header">
            <h3 class="dt-page__title" *ngIf="!isEditMode">{{staticMsgs.imgDetail.createImg}}</h3>
            <h3 class="dt-page__title" *ngIf="isEditMode">{{staticMsgs.imgDetail.imgDoc}}</h3>
            <nav class="mb-0 breadcrumb">
              <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/dashboard"><i class="fa fa-home"
                  aria-hidden="true"></i> </a>
                  <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/imagelibrary">
                    <span class="active breadcrumb-item" *ngIf="isEditMode || !isEditMode">{{staticMsgs.imgLib.imgDocLib}}</span>
                  </a>
              <span class="active breadcrumb-item" *ngIf="!isEditMode">{{staticMsgs.imgDetail.createImg}}</span>
              <span class="active breadcrumb-item" *ngIf="isEditMode">{{staticMsgs.imgDetail.imgDoc}} / {{
                sequenceNumber }} </span>
            </nav>
          </div>
        </div>

        <div class="col-xl-4 col-sm-4 col-md-4 d-flex justify-content-center" *ngIf="isEditMode">
          <ul class="dt-nav">
            <button *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" (click)="editDoc()"
              ngbTooltip="Edit Image">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button *ngIf="isEditingMode" type="button" [ngClass]="isEditingMode ? 'icon-active' : 'btn-color-primary'"
              class="btn mr-3 round" (click)="cancelImageEdit()" ngbTooltip="Cancel Edit Image">
              <i class="fa fa-times text-white" aria-hidden="true"></i>
            </button>
            <button *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round"
              (click)="onDeleteImage()" ngbTooltip="Delete">
              <i class="fa fa-trash" aria-hidden="true"></i>kl
            </button>
            <!-- <button type="button" class="btn mr-3 btn-color-primary round"
  *ngIf="!isEditingMode" ngbTooltip="Discussion">
  <i class="fa fa-comments" aria-hidden="true"></i>
  </button>
  <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal"
  data-target="#small-modal" ngbTooltip="Generate Techpack">
  <i class="fas fa-tshirt" aria-hidden="true"></i>
  </button> -->
          </ul>
        </div>
        <!-- <div class="col-xl-4 col-sm-4 col-md-4" *ngIf="isEditMode">
  <ul class="dt-nav">
  <button *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" ngbTooltip="Edit Image">
  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
  </button>
  <button *ngIf="isEditingMode" type="button" [ngClass]="isEditingMode ? 'icon-active' : 'btn-color-primary'" class="btn mr-3 round" ngbTooltip="Cancel Edit Style">
  <i class="fa fa-times text-white" aria-hidden="true"></i>
  </button>
   <button type="button" class="btn mr-3 round favStar" ngbTooltip="Add to Favourite">
  <i class="fa fa-star-o" aria-hidden="true"></i>
  </button>
  <button type="button" class="btn mr-3 btn-color-primary round" *ngIf="!isEditingMode" ngbTooltip="Discussion">
  <i class="fa fa-comments" aria-hidden="true"></i>
  </button>
  <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal" data-target="#small-modal" ngbTooltip="Generate Techpack">
  <i class="fas fa-tshirt" aria-hidden="true"></i>
  </button>
  </ul>
  </div> -->

        <div class="col-xl-4 col-sm-4 col-md-4" *ngIf="isEditMode">
          <ul class="dt-nav">
            <!-- <div *ngFor="let arr of sharedUsers">
  <div *ngIf="!arr.user[0].pictureID" class="icon-container">
  <a class="dt-avatar round mr-2 size-30" placement="bottom" ngbTooltip="{{arr.user[0].fullName}}">
  <img [src]="userThumbnail">
  </a>
  <div *ngIf="arr.isOnline" class='active-status-circle' ngbTooltip="Online">
  </div>
  <div *ngIf="!arr.isOnline" class='deactive-status-circle' ngbTooltip="Offline">
  </div>
  </div>
  <div *ngIf="arr.user[0].pictureID" class="icon-container">
  <a class="dt-avatar round mr-2 size-30" placement="bottom" ngbTooltip="{{arr.user[0].fullName}}">
  <img [src]="arr.user[0].picture.thumbnail" />
  </a>
  <div *ngIf="arr.isOnline" class='active-status-circle' ngbTooltip="Online">
  </div>
  <div *ngIf="!arr.isOnline" class='deactive-status-circle' ngbTooltip="Offline">
  </div>
  </div>
  </div> -->

            <!-- <img src="{{userImg}}" class="img-fluid size-30 round mr-2">
  <img src="{{userImgO}}"  class="img-fluid size-30 round mr-2"> -->

            <!-- <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal" data-target="#long-modal" ngbTooltip="Share">
  <i class="fa fa-user-plus" aria-hidden="true"></i>
  </button>-->
          </ul>
        </div>
      </div>
      <!-- /page header -->

      <div class="profile-content">

        <div class="card" *ngIf="!isEditingMode && !isViewingMode">
          <div class="card-body bg-white">
            <div>
              <form [formGroup]="imageLibraryForm" (ngSubmit)="onImgSubmit()">
                <div class="form-row">
                  <div class="col-lg-4 col-sm-12 mb-3">
                    <label for="validationDefault01">{{staticMsgs.imgDetail.selectClass}}<span
                        class="red_icon">*</span></label>
                    <input type="text" class="form-control" id="imageclassification"
                      formControlName="imageclassification" placeholder="Classification" required />

                    <div
                      *ngIf="(submitted && this.f.imageclassification.errors) || ( this.f.imageclassification.invalid && (this.f.imageclassification.dirty || this.f.imageclassification.touched))">
                      <div *ngIf="this.f.imageclassification.errors.required" class="red">
                        {{staticMsgs.imgDetail.classReq}}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 mb-3">
                    <label for="validationDefault02">{{staticMsgs.imgDetail.name}}<span
                        class="red_icon">*</span></label>
                    <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter Name"
                     minlength="3" maxlength="50" value="" required="" (keypress)="characterOnly($event)" />
                    <div
                      *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
                      <div *ngIf="this.f.name.errors.required" class="red">
                        {{staticMsgs.imgDetail.nameReq}}
                      </div>
                      <div *ngIf="this.f.name.errors.minlength" class="red">
                        {{staticMsgs.common.minimum3charactersrequired}}
                       </div>
                    </div>
                  </div>
                  <div class="col-lg-4 mb-3 imageNo" *ngIf="imageclassificationParent === 'Images'">
                    <!--- <label for="validationDefault02">Image #</label>-->
                    <input type="hidden" class="form-control" id="sequence" formControlName="sequence"
                      placeholder="Enter Image #" value="" />
                    <input type="hidden" id="baseclassification" formControlName="baseclassification"
                      [(ngModel)]="imageclassificationParent" />
                  </div>

                  <div class="col-lg-4 mb-3" *ngIf="imageclassificationParent === 'Documents'">
                    <!--<label for="validationDefault02">Document #</label>-->
                    <input type="hidden" class="form-control" id="sequence" formControlName="sequence"
                      placeholder="Enter Document #" value="" />
                    <input type="hidden" id="baseclassification" formControlName="baseclassification"
                      [(ngModel)]="imageclassificationParent" />
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-sm-8 mt-3 mb-3">
                    <label for="validationDefault01">{{staticMsgs.imgDetail.description}}</label>
                    <textarea cols="2" class="form-control" id="description" formControlName="description"
                      placeholder="Enter Description"></textarea>
                    <!--<div  *ngIf="(submitted && this.f.description.errors) || ( this.f.description.invalid && (this.f.description.dirty || this.f.description.touched))">
  <div *ngIf="this.f.description.errors.required" class="red">
  Description is required.
  </div>
  </div>-->
                  </div>
                  <div class="col-sm-4 mt-3 mb-3">
                    <label for="validationDefault02">{{staticMsgs.imgDetail.status}}<span
                        class="red_icon">*</span></label>
                    <select title="Select Status" id="docstatus" formControlName="docstatus"
                      class="form-control selectpicker w-100">
                      <!--<option *ngFor="let status of status" [value]="status.value">{{ status.label }}</option>-->
                      <!-- <option value="" [disabled]="true" selected>Select Status
  </option> -->
                      <option value="Active">{{staticMsgs.imgDetail.active}}</option>
                      <option value="Inactive">{{staticMsgs.imgDetail.inactive}}</option>
                    </select>

                    <div
                      *ngIf="(submitted && this.f.docstatus.errors) || ( this.f.docstatus.invalid && (this.f.docstatus.dirty || this.f.docstatus.touched))">
                      <div *ngIf="this.f.docstatus.errors.required" class="red">
                        {{staticMsgs.imgDetail.statusReq}}
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="imageclassificationParent === 'Images'">
                  <div class="dt-entry__header mt-5">
                    <!-- Entry Heading -->
                    <div class="dt-entry__heading">
                      <h3 class="dt-entry__title">{{staticMsgs.imgDetail.img}}<span
                        class="red_icon">*</span></h3>
                    </div>
                    <!-- /entry heading -->
                  </div>

                  <div class="form-row">
                    <div class="col-sm-12 mb-3">
                      <label for="validationDefault01">{{staticMsgs.imgDetail.comment}}</label>
                      <textarea cols="2" class="form-control" id="notes" formControlName="notes"
                        placeholder="Enter Comments"></textarea>
                    </div>
                  </div>



                  <div class="form-row">
                    <div class="col-xl-12">
                      <div class="custom-dropzone" id="" formControlName="" ngx-dropzone [accept]="'image/*'"
                        (change)="onSelect($event)">
                        <ngx-dropzone-label>
                          <div>
                            <h5 class="text-light-gray mt-3">{{staticMsgs.imgDetail.dropFile}}</h5>
                          </div>
                        </ngx-dropzone-label>
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData"
                          [file]="f" [removable]="true" (removed)="onRemove(f)">
                          <ngx-dropzone-label>
                            {{ f.name }}
                            <!--({{ f.type }})--->
                          </ngx-dropzone-label>
                        </ngx-dropzone-image-preview>

                      </div>
                      <div class="red" *ngIf="isFileError&& this.submitted==true">
                        {{staticMsgs.imgDetail.imageisrequired}}
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 mt-6" *ngIf="imageLibraryForm.get('docid').value">
                    <div class="col-xl-6 mb-5" *ngFor="let img of editedFileData; let i=index">
                      <ng-container *ngIf="img.thumbnail">
                        <img
                          *ngIf="img.fileType===('.jpg') || img.fileType===('.jpeg') || img.fileType===('.tiff') || img.fileType===('.png') || img.fileType===('.gif') || img.fileType===('.bmp')"
                          [src]="(img.thumbnail !=null)?img.thumbnail: documentImage" class="img-fluid img-fit"
                          (click)="open(i)" title="{{img.name}}" />
                        <img
                          *ngIf="img.fileType!==('.jpg') && img.fileType!==('.jpeg') && img.fileType!==('.tiff') && img.fileType!==('.png') && img.fileType!==('.gif') && img.fileType!==('.bmp')"
                          src="../../../../assets/images/doccard/document.png" class="img-fluid img-fit"
                          (click)="downloadDoc(img)" title="{{img.name}}" />
                      </ng-container>
                      <button type="button" class="close image-close" (click)="removeImgSection(img.id)">
                        <span aria-hidden="true">×</span>
                      </button>
                      <input type="hidden" class="form-control" formControlName="filedelete" [(ngModel)]="imgId" />
                    </div>
                  </div>

                </div>
                <div *ngIf="imageclassificationValue === 'Lab Reports'">
                  <div class="dt-entry__header mt-5">
                    <!-- Entry Heading -->
                    <div class="dt-entry__heading">
                      <h3 class="dt-entry__title">{{staticMsgs.imgDetail.labReport}}</h3>
                    </div>
                    <!-- /entry heading -->
                  </div>
                </div>
                <div class="form-row" *ngIf="imageclassificationValue === 'Lab Reports'">
                  <div class="col-sm-4 mb-3">
                    <label for="validationDefault01">{{staticMsgs.imgDetail.selectReport}}</label>
                    <select id="reportStatus" formControlName="reportStatus" class="form-control selectpicker w-100">
                      <!--<option *ngFor="let status of reportstatus" [value]="status.value">{{ status.label }}</option>-->
                      <option value="" [disabled]="true" selected>{{staticMsgs.imgDetail.selectReport}} </option>
                      <option value="Pass">{{staticMsgs.imgDetail.pass}}</option>
                      <option value="Fail">{{staticMsgs.imgDetail.fail}}</option>
                    </select>

                    <!--<div  *ngIf="(submitted && this.f.reportStatus.errors) || ( this.f.reportStatus.invalid && (this.f.reportStatus.dirty || this.f.reportStatus.touched))">
  <div *ngIf="this.f.reportStatus.errors.required" class="red">
  ReportStatus is required.
  </div>
  </div>-->
                  </div>

                  <div class="col-sm-4 mb-3">
                    <label for="validationDefault01">{{staticMsgs.imgDetail.labLoc}}</label>
                    <input type="text" class="form-control" id="labLocation" formControlName="labLocation"
                      placeholder="" value="" />

                    <!--<div  *ngIf="(submitted && this.f.labLocation.errors) || ( this.f.labLocation.invalid && (this.f.labLocation.dirty || this.f.labLocation.touched))">
  <div *ngIf="this.f.labLocation.errors.required" class="red">
      LabLocation is required.
  </div>
  </div>-->
                  </div>

                  <!---<div class="col-sm-4 mb-3">
  <label for="validationDefault02">Select Type<span
                  class="red_icon">*</span></label>
  <select  title="Select Type"  id="type" formControlName="type" class="form-control w-100">
    <option *ngFor="let status of reportstatus" [value]="status.value">{{ status.label }}</option>
  </select>
  <div  *ngIf="(submitted && this.f.type.errors) || ( this.f.type.invalid && (this.f.type.dirty || this.f.type.touched))">
      <div *ngIf="this.f.type.errors.required" class="red">
          Type is required.
      </div>
  </div>
  </div>-->
                </div>
                <div *ngIf="imageclassificationParent === 'Documents'">
                  <div class="dt-entry__header mt-5">
                    <!-- Entry Heading -->
                    <div class="dt-entry__heading">
                      <h3 class="dt-entry__title">{{staticMsgs.imgDetail.uploadDoc}}<span
                        class="red_icon">*</span></h3>
                    </div>
                    <!-- /entry heading -->
                  </div>

                  <div class="form-row">
                    <div class="col-sm-12 mb-3">
                      <label for="validationDefault01">{{staticMsgs.imgDetail.comment}}</label>
                      <textarea cols="2" class="form-control" id="notes" formControlName="notes"
                        placeholder="Enter Comments"></textarea>
                    </div>
                  </div>



                  <div class="form-row">
                    <div class="col-xl-12">
                      <div class="custom-dropzone" id="docupload" ngx-dropzone (change)="onSelect($event)" >
                        <ngx-dropzone-label>
                          <div>
                            <h5 class="text-light-gray mt-3">{{staticMsgs.imgDetail.dropFile}}</h5>
                          </div>
                        </ngx-dropzone-label>
                        <!--<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData" [file]="f" [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-image-preview>-->
                        <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData" [file]="f"
                          [removable]="true" (removed)="onRemove(f)">
                          <ngx-dropzone-label>
                            {{ f.name }}
                            <!--  ({{ f.type }}) -->
                          </ngx-dropzone-label>
                        </ngx-dropzone-preview>
                      </div>
                      <div class="red" *ngIf="isFileError && this.submitted==true">
                        {{staticMsgs.imgDetail.documentisrequired}}
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 mt-6" *ngIf="imageLibraryForm.get('docid').value">
                    <div class="col-xl-6 mb-5" *ngFor="let img of editedFileData; let i=index">
                      <ng-container *ngIf="img.thumbnail">
                        <img
                          *ngIf="img.fileType===('.jpg') || img.fileType===('.jpeg') || img.fileType===('.tiff') || img.fileType===('.png') || img.fileType===('.gif') || img.fileType===('.bmp')"
                          [src]="(img.thumbnail !=null)?img.thumbnail: documentImage" class="img-fluid img-fit"
                          (click)="open(i)" title="{{img.name}}" />
                        <img
                          *ngIf="img.fileType!==('.jpg') && img.fileType!==('.jpeg') && img.fileType!==('.tiff') && img.fileType!==('.png') && img.fileType!==('.gif') && img.fileType!==('.bmp')"
                          src="../../../../assets/images/doccard/document.png" class="img-fluid img-fit"
                          title="{{img.name}}" (click)="downloadDoc(img)" />
                        <button type="button" class="close image-close" (click)="removeDocSection(img.id)">
                          <span aria-hidden="true">×</span>
                        </button>
                      </ng-container>
                    </div>
                    <input type="hidden" class="form-control" formControlName="filedelete" [(ngModel)]="imgId" />
                  </div>
                </div>

                <input type="text" class="form-control" [hidden]="true" id="docid" formControlName="docid"
                  placeholder="Document Id" />
              </form>
            </div>
          </div>
        </div>

        <div class="form-row float-right">
          <div class="col-sm-12">
            <button type="submit" *ngIf="!isEditMode" class="btn btn-primary adv-search-btn" [disabled]="imageLibraryForm?.invalid || !isThumbnailAdded"
              (click)="onImgSubmit()">{{staticMsgs.imgDetail.create}}</button>
            <!-- <button type="submit" *ngIf="isEditMode" class="btn btn-primary adv-search-btn" (click)="onImgSubmit()">Save</button> -->
          </div>
        </div>

        <!-- Grid -->
        <div class="row">
          <!-- Grid Item -->
          <div class="wd-300" *ngIf="isViewingMode">
            <!-- /* Written by : Himanshu */ -->
            <ng-image-slider class="sidebar_image" [images]="imageObject" *ngIf="imageObject.length>0" [infinite]="false" [autoSlide]="1"
              slideImage="1" [imageSize]="{width: '100%', height: 200}">
            </ng-image-slider>
            <div class="accordion mt-5" id="accordion-example">
              <div class="card" *ngIf="editedImageData">
                <div class="" id="headingOne">
                  <h5 class="mb-0">
                    <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                      data-target="#collapse-one" aria-expanded="false" aria-controls="collapse-one">
                      {{staticMsgs.imgDetail.imgInfo}}
                      <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                    </h4>
                  </h5>
                </div>

                <div id="collapse-one" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-example">
                  <div class="card-body bg-white p-4" *ngIf="editedImageData">
                    <div class="row">
                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.imgDetail.name1}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedImageData.name}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.imgDetail.type}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedImageData.classification}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="editedImageData">
                <div class="" id="headingTwo">
                  <h5 class="mb-0">
                    <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                      data-target="#collapse-two" aria-expanded="false" aria-controls="collapse-one">
                      {{staticMsgs.imgDetail.userInfo}}
                      <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                    </h4>
                  </h5>
                </div>
                <div id="collapse-two" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion-example">
                  <div class="card-body bg-white p-4" *ngIf="editedImageData">
                    <div class="row">
                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.imgDetail.createdBy}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedImageData.createdBy.firstName}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.imgDetail.createdOn}}</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          {{editedImageData.createdOn |date: "dd MMM yyyy"}}
                        </p>
                        <p class="a-font">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                          {{editedImageData.createdOn |date:"hh:mm a"}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /grid item -->

          <!-- Grid Item -->
          <div [ngClass]="isViewingMode ? 'wdcacl-300' : 'w-100'" *ngIf="isViewingMode || isEditingMode">
            <div class="order-xl-1">
              <!-- Card -->
              <div class="card-shadow-none mr-3">
                <!-- Card Header -->
                <div class="card-header card-nav border-bottom justify-content-sm-between" *ngIf="!isCreateMode">
                  <!-- <h3 class="mb-2 mb-sm-n5">About</h3> -->

                  <ul class="card-header-links nav nav-underline" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-toggle="tab" href="#tab-pane1" role="tab"
                        aria-controls="tab-pane1" aria-selected="true">{{staticMsgs.imgDetail.imgDoc}}</a>
                    </li>
                  </ul>
                </div>
                <!-- /card header -->

                <!-- Card Body -->
                <div class="mt-3 pb-2">
                  <!-- Tab Content-->
                  <div class="tab-content">
                    <!-- Tab panel -->
                    <div id="tab-pane1" class="tab-pane card-body card-body-shadow bg-white active">
                      <div *ngIf="isEditingMode"> <!-- isEditingMode-->
                        <form [formGroup]="imageLibraryForm" (ngSubmit)="onImgSubmit()">
                          <div class="form-row">
                            <div class="col-lg-3 col-sm-12 mb-3">
                              <!-- <label for="validationDefault01">{{staticMsgs.imgDetail.selectClass}}</label>
                              <input type="text" class="form-control" id="imageclassification"
                                formControlName="imageclassification" placeholder="Classification" /> -->
                              <label for="validationDefault01">{{staticMsgs.imgDetail.selectClass}}<span
                                  class="red_icon">*</span></label>
                              <input type="text" class="form-control" id="imageclassification"
                                formControlName="imageclassification" placeholder="Classification" required />

                              <div
                                *ngIf="(submitted && this.f.imageclassification.errors) || ( this.f.imageclassification.invalid && (this.f.imageclassification.dirty || this.f.imageclassification.touched))">
                                <div *ngIf="this.f.imageclassification.errors.required" class="red">
                                  {{staticMsgs.imgDetail.classReq}}
                                </div>
                              </div>
                              <!--<div
            *ngIf="(submitted && this.f.imageclassification.errors) || ( this.f.imageclassification.invalid && (this.f.imageclassification.dirty || this.f.imageclassification.touched))">
            <div *ngIf="this.f.imageclassification.errors.required"
                class="red">
                Classification is required.
            </div>
        </div>-->
                            </div>

                            <div class="col-lg-3 mb-3">
                              <label for="validationDefault02">{{staticMsgs.imgDetail.name}}<span
                                  class="red_icon">*</span></label>
                              <input type="text" class="form-control" id="name" formControlName="name"
                                placeholder="Enter Name" value="" required="" (keypress)="characterOnly($event)" />
                              <div
                                *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
                                <div *ngIf="this.f.name.errors?.required" class="red">
                                  {{staticMsgs.imgDetail.nameReq}}
                                </div>
                                <div *ngIf="this.f.name.errors?.pattern" class="red">
                                  {{staticMsgs.imgDetail.nameInvalid}}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-3 mb-3">
                              <label for="validationDefault02">{{staticMsgs.imgDetail.status}}<span
                                  class="red_icon">*</span></label>
                              <select title="Select Status" id="docstatus" formControlName="docstatus"
                                class="form-control selectpicker w-100">
                                <!--<option *ngFor="let status of status" [value]="status.value">{{ status.label }}</option>-->
                                <!-- <option value="" [disabled]="true" selected>Select Status
        </option> -->
                                <option value="Active">{{staticMsgs.imgDetail.active}}</option>
                                <option value="Inactive">{{staticMsgs.imgDetail.inactive}}</option>
                              </select>

                              <div
                                *ngIf="(submitted && this.f.docstatus.errors) || ( this.f.docstatus.invalid && (this.f.docstatus.dirty || this.f.docstatus.touched))">
                                <div *ngIf="this.f.docstatus.errors.required" class="red">
                                  {{staticMsgs.imgDetail.statusReq}}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 mb-3 imageNo" *ngIf="imageclassificationParent === 'Images'">
                              <!--- <label for="validationDefault02">Image #</label>-->
                              <input type="hidden" class="form-control" id="sequence" formControlName="sequence"
                                placeholder="Enter Image #" value="" />
                              <input type="hidden" id="baseclassification" formControlName="baseclassification"
                                [(ngModel)]="imageclassificationParent" />

                            </div>

                            <div class="col-lg-3 mb-3" *ngIf="imageclassificationParent === 'Documents'">
                              <!--<label for="validationDefault02">Document #</label>-->
                              <input type="hidden" class="form-control" id="sequence" formControlName="sequence"
                                placeholder="Enter Document #" value="" />
                              <input type="hidden" id="baseclassification" formControlName="baseclassification"
                                [(ngModel)]="imageclassificationParent" />
                            </div>
                          </div>

                          <div class="form-row">
                            <div class="col-sm-6 mt-3 mb-3">
                              <label for="validationDefault01">{{staticMsgs.imgDetail.description}}</label>
                              <textarea cols="2" class="form-control" id="description" formControlName="description"
                                placeholder="Enter Description"></textarea>
                              <!--<div  *ngIf="(submitted && this.f.description.errors) || ( this.f.description.invalid && (this.f.description.dirty || this.f.description.touched))">
              <div *ngIf="this.f.description.errors.required" class="red">
                  Description is required.
              </div>
          </div>-->
                            </div>
                          </div>
                          <div *ngIf="imageclassificationParent === 'Images'">
                            <div class="dt-entry__header mt-5">
                              <!-- Entry Heading -->
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">{{staticMsgs.imgDetail.img}}<span
                                  class="red_icon">*</span></h3>
                              </div>
                              <!-- /entry heading -->
                            </div>

                            <div class="form-row">
                              <div class="col-sm-12 mb-3">
                                <label for="validationDefault01">{{staticMsgs.imgDetail.comment}}</label>
                                <textarea cols="2" class="form-control" id="notes" formControlName="notes"
                                  placeholder="Enter Comments"></textarea>
                              </div>
                            </div>



                            <div class="form-row">
                              <div class="col-xl-12">
                                <div class="custom-dropzone" id="" formControlName="" ngx-dropzone [accept]="'image/*'"
                                  (change)="onSelect($event)">
                                  <ngx-dropzone-label>
                                    <div>
                                      <h5 class="text-light-gray mt-3">{{staticMsgs.imgDetail.dropFile}}</h5>
                                    </div>
                                  </ngx-dropzone-label>
                                  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngFor="let f of fileData" [file]="f" [removable]="true" (removed)="onRemove(f)">
                                    <ngx-dropzone-label>
                                      {{ f.name }}
                                      <!--({{ f.type }})--->
                                    </ngx-dropzone-label>
                                  </ngx-dropzone-image-preview>

                                </div>
                                <div class="red" *ngIf="isFileError && this.submitted==true">
                                  {{staticMsgs.imgDetail.imageisrequired}}
                                </div>
                              </div>
                            </div>

                            <div class="col-xl-6 mt-6" *ngIf="imageLibraryForm.get('docid').value">
                              <div class="col-xl-6 mb-5" *ngFor="let img of editedFileData; let i=index">
                                <ng-container *ngIf="img.thumbnail">
                                  <img
                                    *ngIf="img.fileType===('.jpg') || img.fileType===('.jpeg') || img.fileType===('.tiff') || img.fileType===('.png') || img.fileType===('.gif') || img.fileType===('.bmp')"
                                    [src]="(img.thumbnail !=null)?img.thumbnail: documentImage"
                                    class="img-fluid img-fit" (click)="open(i)" title="{{img.name}}" />
                                  <img
                                    *ngIf="img.fileType!==('.jpg') && img.fileType!==('.jpeg') && img.fileType!==('.tiff') && img.fileType!==('.png') && img.fileType!==('.gif') && img.fileType!==('.bmp')"
                                    src="../../../../assets/images/doccard/document.png" class="img-fluid img-fit"
                                    (click)="downloadDoc(img)" title="{{img.name}}" />
                                </ng-container>
                                <button type="button" class="close image-close" (click)="removeImgSection(img.id)">
                                  <span aria-hidden="true">×</span>
                                </button>
                                <!-- <input type="hidden" class="form-control" formControlName="filedelete"
                                  [(ngModel)]="imgId" /> -->
                              </div>
                            </div>
                          </div>
                          <div *ngIf="imageclassificationValue === 'Lab Reports'">
                            <div class="dt-entry__header mt-5">
                              <!-- Entry Heading -->
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">{{staticMsgs.imgDetail.labReport}}</h3>
                              </div>
                              <!-- /entry heading -->
                            </div>
                          </div>
                          <div class="form-row" *ngIf="imageclassificationValue === 'Lab Reports'">
                            <div class="col-sm-4 mb-3">
                              <label for="validationDefault01">{{staticMsgs.imgDetail.selectReport}}</label>
                              <select id="reportStatus" formControlName="reportStatus"
                                class="form-control selectpicker w-100">
                                <!--<option *ngFor="let status of reportstatus" [value]="status.value">{{ status.label }}</option>-->
                                <option value="" [disabled]="true" selected>{{staticMsgs.imgDetail.selectReport}}
                                </option>
                                <option value="Pass">{{staticMsgs.imgDetail.pass}}</option>
                                <option value="Fail">{{staticMsgs.imgDetail.fail}}</option>
                              </select>

                              <!--<div  *ngIf="(submitted && this.f.reportStatus.errors) || ( this.f.reportStatus.invalid && (this.f.reportStatus.dirty || this.f.reportStatus.touched))">
                    <div *ngIf="this.f.reportStatus.errors.required" class="red">
                        ReportStatus is required.
                    </div>
                </div>-->
                            </div>

                            <div class="col-sm-4 mb-3">
                              <label for="validationDefault01">{{staticMsgs.imgDetail.labLoc}}</label>
                              <input type="text" class="form-control" id="labLocation" formControlName="labLocation"
                                placeholder="" value="" />

                              <!--<div  *ngIf="(submitted && this.f.labLocation.errors) || ( this.f.labLocation.invalid && (this.f.labLocation.dirty || this.f.labLocation.touched))">
                        <div *ngIf="this.f.labLocation.errors.required" class="red">
                            LabLocation is required.
                        </div>
                    </div>-->
                            </div>

                            <!---<div class="col-sm-4 mb-3">
                              <label for="validationDefault02">Select Type<span
                                              class="red_icon">*</span></label>
                              <select  title="Select Type"  id="type" formControlName="type" class="form-control w-100">
                                <option *ngFor="let status of reportstatus" [value]="status.value">{{ status.label }}</option>
                              </select>
                              <div  *ngIf="(submitted && this.f.type.errors) || ( this.f.type.invalid && (this.f.type.dirty || this.f.type.touched))">
                                  <div *ngIf="this.f.type.errors.required" class="red">
                                      Type is required.
                                  </div>
                              </div>
                          </div>-->
                          </div>

                          <div *ngIf="imageclassificationParent === 'Documents'">
                            <div class="dt-entry__header mt-5">
                              <!-- Entry Heading -->
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">{{staticMsgs.imgDetail.uploadDoc}}<span
                                  class="red_icon">*</span></h3>
                              </div>
                              <!-- /entry heading -->
                            </div>

                            <div class="form-row">
                              <div class="col-sm-12 mb-3">
                                <label for="validationDefault01">{{staticMsgs.imgDetail.comment}}</label>
                                <textarea cols="2" class="form-control" id="notes" formControlName="notes"
                                  placeholder="Enter Comments"></textarea>
                              </div>
                            </div>



                            <div class="form-row">
                              <div class="col-xl-12">
                                <div class="custom-dropzone" id="docupload" ngx-dropzone (change)="onSelect($event)" >
                                  <ngx-dropzone-label>
                                    <div>
                                      <h5 class="text-light-gray mt-3">{{staticMsgs.imgDetail.dropFile}}</h5>
                                    </div>
                                  </ngx-dropzone-label>
                                  <!--<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData" [file]="f" [removable]="true" (removed)="onRemove(f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview>-->
                                  <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData"
                                    [file]="f" [removable]="true" (removed)="onRemove(f)">
                                    <ngx-dropzone-label>
                                      {{ f.name }}
                                      <!--  ({{ f.type }}) -->
                                    </ngx-dropzone-label>
                                  </ngx-dropzone-preview>

                                </div>
                                <div class="red" *ngIf="isFileError && this.submitted==true">
                                  {{staticMsgs.imgDetail.documentisrequired}}
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-6 mt-6" *ngIf="imageLibraryForm.get('docid').value">
                              <div class="col-xl-6 mb-5" *ngFor="let img of editedFileData; let i=index">
                                <ng-container *ngIf="img.thumbnail">
                                  <img
                                    *ngIf="img.fileType===('.jpg') || img.fileType===('.jpeg') || img.fileType===('.tiff') || img.fileType===('.png') || img.fileType===('.gif') || img.fileType===('.bmp')"
                                    [src]="(img.thumbnail !=null)?img.thumbnail: documentImage"
                                    class="img-fluid img-fit" (click)="open(i)" title="{{img.name}}" />
                                  <img
                                    *ngIf="img.fileType!==('.jpg') && img.fileType!==('.jpeg') && img.fileType!==('.tiff') && img.fileType!==('.png') && img.fileType!==('.gif') && img.fileType!==('.bmp')"
                                    src="../../../../assets/images/doccard/document.png" class="img-fluid img-fit"
                                    title="{{img.name}}" (click)="downloadDoc(img)" />
                                  <button type="button" class="close image-close" (click)="removeDocSection(img.id)">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </ng-container>
                              </div>
                              <input type="hidden" class="form-control" formControlName="filedelete"
                                [(ngModel)]="imgId" />
                            </div>
                          </div>

                          <div class="form-row mt-10 float-right">
                            <input type="text" class="form-control" [hidden]="true" id="docid" formControlName="docid"
                              placeholder="Document Id" />
                            <div class="row mt-3">
                              <button type="button" *ngIf="!isEditMode" (click)="cancelImageCreate()"
                                class="btn btn-outline-primary adv-search-btn mr-3">
                                {{staticMsgs.imgDetail.cancel}}
                              </button>
                              <button type="submit" *ngIf="!isEditMode"
                                class="btn btn-primary adv-search-btn"[disabled]="imageLibraryForm?.invalid || !isThumbnailAdded">{{staticMsgs.imgDetail.create}}</button>
                              <button type="submit" *ngIf="isEditMode"
                                class="btn btn-primary adv-search-btn"[disabled]="imageLibraryForm?.invalid || !isThumbnailAdded">{{staticMsgs.imgDetail.update}}</button>
                            </div>
                          </div>
                        </form>
                      </div>





  <div *ngIf="isViewingMode">
  <div class="dt-entry__header mt-5">
    <!-- Entry Heading -->
    <div class="dt-entry__heading">
        <h3 class="dt-entry__title">
            {{this.f.imageclassification.value}}
        </h3>
    </div>
    <!-- /entry heading -->
  </div>
  <form>
    <div class="form-row">
        <div class="col-sm-4 mb-3">
            <label class="label-view" for="this.f.sequence.value">{{staticMsgs.imgDetail.document}}</label>
            <p class="mt-2 viewtext">{{this.f.sequence.value}}</p>
        </div>
        <div class="col-sm-4 mb-3">
          <label class="label-view" for="this.f.name.value">{{staticMsgs.imgDetail.name}}</label>
          <p class="mt-2 viewtext">{{this.f.name.value}}</p>
        </div>
        <div class="col-sm-4 mb-3">
          <label class="label-view" for="this.f.docstatus.value">{{staticMsgs.imgDetail.status}}</label>
          <p class="mt-2 viewtext">{{this.f.docstatus.value | titlecase}}</p>
        </div>
        <div class="col-sm-4 mb-3" *ngIf="this.f.imageclassification.value === 'Lab Reports'">
          <label class="label-view" for="this.f.imageclassification.value">{{staticMsgs.imgDetail.report}}</label>
          <p class="mt-2 viewtext">
                {{this.f.reportStatus.value}}
            </p>
          <label class="label-view" for="this.f.labLocation.value">{{staticMsgs.imgDetail.labLoc}}</label>
          <p class="mt-2 viewtext">
                {{this.f.labLocation.value}}
            </p>
        </div>
        <div class="col-sm-8 mb-3">
          <label class="label-view" for="this.f.description.value">{{staticMsgs.imgDetail.description}}</label>
          <p class="mt-2 viewtext">
                {{this.f.description.value}}
            </p>
        </div>
        <div class="col-sm-8 mb-3" *ngIf="this.f.notes.value">
          <label class="label-view" for="this.f.notes.value">{{staticMsgs.imgDetail.comment}}</label>
          <p class="mt-2 viewtext">{{this.f.notes.value}}</p>
        </div>
    </div>

                          <div class="form-row" *ngIf="imageLibraryForm.get('docid').value">
                            <div class="col-xl-3 mb-5" *ngFor="let img of editedFileData; let i=index">
                              <ng-container *ngIf="img.thumbnail">
                                <img
                                  *ngIf="img.fileType===('.jpg') || img.fileType===('.jpeg') || img.fileType===('.tiff') || img.fileType===('.png') || img.fileType===('.gif') || img.fileType===('.bmp')"
                                  [src]="(img.thumbnail !=null)?img.thumbnail: documentImage" title="{{img.name}}" (click)="open(i)"
                                  class="img-fluid img-fit" />
                                <img
                                  *ngIf="img.fileType!==('.jpg') && img.fileType!==('.jpeg') && img.fileType!==('.tiff') && img.fileType!==('.png') && img.fileType!==('.gif') && img.fileType!==('.bmp')"
                                  src="../../../../assets/images/doccard/document.png" title="{{img.name}}" (click)="downloadDoc(img)"
                                  class="img-fluid img-fit" />
                              </ng-container>
                            </div>
                          </div>
                          <!-- <div class="form-row" *ngIf="imageLibraryForm.get('docid').value">

    <div class="col-xl-3 mb-5"
        *ngFor="let img of editedFileData; let i=index">

        <ng-container *ngIf="img.thumbnail">
            <img src="../../../../assets/images/doccard/document.png"
                class="img-fluid img-fit">
        </ng-container>


    </div>
  </div> -->
                        </form>
                      </div>
                    </div>
                    <!-- /tab panel -->

                    <!-- Tab panel -->

                    <!-- /tab panel -->

                    <!-- Tab panel -->

                    <!-- /tab panel -->
                  </div>
                  <!-- /tab content-->
                </div>
                <!-- /card body -->
              </div>
              <!-- /card -->
            </div>

            <!-- /grid item -->
          </div>
          <!-- /grid -->
        </div>
      </div>
    </div>
    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->
  </div>

  <!-- /Site Content Wrapper-->
</main>
<!-- Footer -->
<app-footer></app-footer>
<!-- /footer -->
