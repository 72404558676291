import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ColorEvent } from 'ngx-color';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AddEvent, GridDataResult, GridComponent, CellClickEvent, PageChangeEvent, DataStateChangeEvent, SelectableSettings, SelectableMode, EditEvent } from '@progress/kendo-angular-grid';
import { select, Store } from '@ngrx/store';
import { map, skip, take } from 'rxjs/operators';
import * as styleActionTypes from "../../../actions/styles.actions";
import { DataService } from '../../../services/data.service';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import { IColor, ColourDocuments, CalSubTotal, Favourite } from '../../../models/color';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import * as S3 from 'aws-sdk/clients/s3';
import { process, filterBy, orderBy, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Specialty } from 'aws-sdk/clients/transcribeservice';
import { JsonpClientBackend } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { stringify } from '@angular/compiler/src/util';
import { ExportExcelService } from '../../../services/exportexcel.service';
import { SharedService } from '../../../services/shared.service';
import { interval, Observable, Observer } from "rxjs";
import { invalid } from '@angular/compiler/src/render3/view/util';
import { APIService } from '../../../services/API.service';
import Amplify, { Auth, API } from 'aws-amplify';
import { Router } from '@angular/router';
import { DiscussionComponent } from '../../../components/discussion/discussion.component';
import { ImageObject } from '../../../models/document';
import { LogEntry, LogService } from '../../../shared/log.service';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { truncate } from 'fs';
import { ToastService } from '../../../services/toast.service';
import { MatDialog,  MatDialogRef } from '@angular/material/dialog';

import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { NgxNumToWordsService, SUPPORTED_LANGUAGE } from 'ngx-num-to-words';

import { DataBindingDirective } from "@progress/kendo-angular-grid";

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {Options} from '../style-edit/options'
import { StylesComponent } from '../styles/styles.component';
import { Output, EventEmitter } from '@angular/core';
declare var $: any;
@Component({
  providers:[StylesComponent ],
  selector: 'app-style-edit',
  templateUrl: './style-edit.component.html',
  styleUrls: ['./style-edit.component.css']
})
export class StyleEditComponent implements OnInit {
  @ViewChild(LoaderComponent) child: LoaderComponent;
  @ViewChild(DiscussionComponent) discussionComponent: DiscussionComponent;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  //@ViewChild('grid') grid: GridComponent;
  valueFromChild = {};
  showDiv = {
    previous: false,
    current: true,
    next: false
  }
  sizeCharts: any;
  lang: SUPPORTED_LANGUAGE = 'en';
  hovercontent: any = false;
  hoverimagecontent: any = true;
  imagePopupObject: any = [];
  currentIndex: any = -1;
  showFlag: any = false;
  hoverIndex:number = -1;
  modalReference: any;
  BOMformGroup: FormGroup;
  topView: File[] = [];
  bottomView: File[] = [];
  public filteredData: any[];
  editedtopViewfile: File[] = [];
  editedtopViewValue = '';
  imgfiledata: Array<any> = [];
  editedbottomViewfile: File[] = [];
  editedbottomViewValue = '';
  fileData: Array<any> = [];
  isedit: Array<boolean> = [];
  data: any[] = [];
  sharedGridData: any[] = [];
  styleViewMode = "Created";
  appStaticMsgs: any;
  userPermission: any = 'view';
  isFavourite: boolean;
  selected: any;
  select1: any;
  selectData: any = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL', 'OS'];
  selectData1: any = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 'OS 8', 'OS 10', 'OS 12'];
  public paginationStartcount = 0;
  public paginationEndcount = 10;
  public selectedValue = "";
  itemList = [];
  selectedItems = [];
  settings = {};
  editedSharedData = [];
  unReadCnt = 0;
  searchedKeyword: string;
  totalData: any[] = []
  public totalRecordCount: any;
  stylesData = [];
  public SearchData = "";
  public isPageChanged = false;
  sharedEndListCount = 10;
  sharedStartListCount = 0;
  public startCountList = 0;
  public endCountList = 10;
  public selectableSettings: SelectableSettings;
  public checkboxOnly = false;
  public mode: SelectableMode = "multiple";
  public filter: CompositeFilterDescriptor;
  sharedStartEndListCount: string;
  isDataLoading = false;
  sharedNotFound: boolean;
  loading: boolean;
  isSharedScrollDown = false;
  isTitleError = false;
  @ViewChild('closeModal') closeModal;

  @ViewChild(GridComponent) private grid: GridComponent;
  public isNew: boolean;
  private editedRowIndex: number;
  flag: any;
  exportData: any[];
  notfoundImg: string;
  classApplied = false;
  itemDetailsSection = false;
  timeLineSection = false;
  chatSection = false;
  LoggedinuserDetails: any;
  Loggedinuser: any;
  styleId: any;
  allChats: any = [];
  allUser: any;
  log: any[];
  editedFileData: Array<any> = [];
  removedDocIds = '';
  documentClassificationData: any[];
  cardsTop = [];
  cardsBottom = [];
  editedStyleData: any;
  UserDetails: any;
  isTopViewAdded: boolean;
  isBottomViewAdded: boolean;
  showLoader: boolean;
  showLoader1: boolean;
  subtotal: number;
  stylesNumber: any;
  Swal = require('sweetalert2');
  errors = '';
  errorDiv = false;
  alert = '';
  activeTab = 'styleDetailsTab';
  styleForm: FormGroup;
  createForm: FormGroup;
  stylecolorForm: FormGroup;
  styImgForm: FormGroup;
  isEditMode: boolean;
  isCreateMode: boolean;
  isEditingMode: boolean;
  isshared: boolean;
  isViewingMode: boolean;
  removedStyImgIds = '';
  submitted = false;
  submitted1 = false;
  colorways: any;
  styImg: [];
  isThumbnailAdded: boolean = false;
  colordata: any[] = [];
  images: any;
  files: File[] = [];
  cards = [];
  thumbNails: File[] = [];
  editedThumbnails: File[] = [];
  editedThumbnailValue = '';
  removedColorwaysIds = '';
  imageTypeSelected: any;
  gridContent = true;
  stichImage = "";
  materialData: any[] = [];
  vendorData = [];
  colorData = [];
  gridData: GridDataResult | any = [];
  BomData = [];
  specificationPoms: any;
  dbspecificationPoms: any[]
  stichesData: any;
  dbstichesData: any
  sticheslist = [];
  patternData: any;
  ConstructionData: any;
  fieldArray: any;
  newfieldArray: any;
  sharedUsers = [];
  onlineUsers: any;
  processingPoms: any;
  dbprocessingPoms: any[];
  processingSewn: any;
  dbprocessingSewn: any[];
  agegroup: any;
  isDisabled: boolean;
  createTitle = '';
  id: any;
  bom: any;
  popupDescription: any = '';
  popupStyleName: any = '';
  recordId: any;

  documentImage = "../../../../assets/images/doccard/document.png";
  userImg = "../tenants/main/assets/images/icon/amay.jpg";
  userImgO = "../tenants/main/assets/images/icon/jimmy-jo.jpg"
  thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  userThumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";

  seltemplate = [
    { value: 'design', label: 'Design' },
    { value: 'technical', label: 'Technical' },
    { value: 'pready', label: 'Production ready' },
  ];

  category = [
    { value: 'casualwear', label: 'Casual wear' },
    { value: 'promotional', label: 'Promotional' },
    { value: 'designer', label: 'Designer' },
    { value: 'sportswear', label: 'Sports wear' },
    { value: 'loungewear', label: 'Lounge wear' },
    { value: 'uniform', label: 'Uniform' },
    { value: 'other', label: 'Other' }
  ];

  subcategory = [
    { value: 'mens', label: 'Mens' },
    { value: 'ladies', label: 'Ladies' },
    { value: 'children', label: 'Children' },
    { value: 'baby', label: 'Baby' },
    { value: 'unisex', label: 'Unisex' },
    { value: 'other', label: 'Other' }
  ];

  season = [
    { value: 'autumn', label: 'Autumn' },
    { value: 'monsoon', label: 'Monsoon' },
    { value: 'spring', label: 'Spring' },
    { value: 'summer', label: 'Summer' },
    { value: 'winter', label: 'Winter' },
  ]
  gender = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];
  size = [
    { value: 'xs', label: 'XS' },
    { value: 's', label: 'S' },
    { value: 'm', label: 'M' },
    { value: 'l', label: 'L' },
    { value: 'xl', label: 'XL' },
    { value: '2XL', label: '2XL' },
  ];
  Size = [
    {
      value: '58/60 Inches', text: '58/60 Inches'
    },
    { value: '57/58 Inches', text: '57/58 Inches' },
    { value: '56/57 Inches', text: '56/57 Inches' }
  ];

  listItems: Array<{ text: string, value: string }> = [
    { text: "Active", value: "Active" },
    { text: "Inactive", value: "Inactive" }
  ];
  editData:any;
  dropdownList = [];
  selectedItemsnew = [];
  dropdownSettings = {};
  item: any;
  pomtab: boolean = false;
  dropdownList1: any = [
    { id: 1, itemName: 'Can View', selected: true },
    { id: 2, itemName: 'Can Edit' }
  ]
  dropdownSettings1 = {};
  selectedPermissions: any = [];
  sampleSize: any = [];
  patternImages: any;
  patternImageData: any;
  constData: any = [];
  data1: any;
  pomdata: any;
  isFilesAdded: boolean;
  isFileError = false;
  get f() { return this.styleForm.controls; }
  get f2() { return this.createForm.controls }
  @Input('options') options : Options[];
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  showModal: boolean;
  /* To call dataStateChange event for BOM grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */



  // image Hover Event
  StyleImageHover(i:number) {
      this.hoverIndex = i;
  }

  // Method to open the Role selection Modal pop-up
  Modalopen(content) {
    let ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
  }

  // Method to close the Role selection Pop-Up
  continue() {
    this.modalReference.close();
  }

  /* To call dataStateChange event for Construction grid data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */





  constructor(public dialog: MatDialog,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<StylesComponent>,
    private sharedService: SharedService,
    private router: Router,
    private apiService: APIService,
    private exportExcel: ExportExcelService,
    private storageservice: StorageService,
    private dataservice: DataService,
    private _lightbox: Lightbox,
    public userservice: UserService,
    private renderer: Renderer2,
    private store: Store<any>,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private toastService: ToastService,
    private modalService: NgbModal) {
    this.styImgForm = this.fb.group({
      styImg: this.fb.array([])

    }),

      this.stylecolorForm = this.fb.group({
        colorways: this.fb.array([])
      });
      this.sharedService.getItem().subscribe(x=>{
        console.log(x);
        this.editData=x;
        console.log(this.editData,'editedData');
        console.log(this.editData.id)
      })

  }

  /* To display success msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    )
  }

  /* To display error msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }

  warning(message: string) {
    Swal.fire({
      icon: 'warning',
      title: '',
      text: message
    })
  }

  /* To display session expired msg as sweet alert
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })

  }


  changeDiv() {
    this.showDiv.next = !this.showDiv.next;
    this.showDiv.previous = false;
    this.showDiv.current = false;
    this.loadSelectPicker();
  }

  /* To set properties initialization on load page
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */
  async ngOnInit() {
    this.getAllStyleSizeRange();
    this.appStaticMsgs = appStaticMsg.data[0];
    this.selectedItemsnew = [];
    this.dropdownSettings = {
      primaryKey: 'id',
      labelKey: 'itemName',
      showCheckbox: false,
      singleSelection: false,
      escapeToClose: true,
      text: this.appStaticMsgs.styledetails.selectCollaborators,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };

    this.dropdownSettings1 = {
      primaryKey: 'id',
      labelKey: 'itemName',
      singleSelection: true,
      escapeToClose: true,
      text: this.appStaticMsgs.styledetails.selectcolorwayname,
      classes: "myclass custom-class"
    };

    this.flag = 0;
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    //this.loadSelectPicker();
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    this.newfieldArray = [];
    this.fieldArray = [
      {
        "edit": false,
        "view": true,
        "id": "",
        "sharedTo": "",
        "user": ""
      }
    ];

    this.settings = {
      text: this.appStaticMsgs.styledetails.searchName,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      showCheckbox: false,
      singleSelection: true,
      classes: "myclass custom-class-example"
    };


    this.getOnlineUsers();
    this.dataservice.AddClientLog();

    // this.isThumbnailAdded = true;
    if (this.editData.id) {
      this.isCreateMode = false;
      if (this.editData.id) {
        this.storageservice.removeStyleDetailsId();
        //Edit mode
        this.isEditMode = false;
        this.isEditingMode = true;
        this.isViewingMode = false;
        this.editStyle();
      }
      else {
        //view mode
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.showLoader = true;
        this.getStyleDetails();
      }
    }
    else {
      //Create mode
      this.isCreateMode = true;
      this.isEditMode = false;
      this.isEditingMode = false;
      this.isViewingMode = false;
    }
    this.sampleSize;
    this.initForm();
    this.sharedService.sendClickEvent();
    this.loadSelectPicker();
  }
  userList = [
    {
      name: 'alexrobert@pluraltechnolgy.com',
      img: '../assets/images/icon/jimmy-jo.jpg'
    },
    {
      name: 'jimmyjo@pluraltechnolgy.com',
      img: '../assets/images/icon/amay.jpg'
    },
    {
      name: 'jonedoe@pluraltechnolgy.com',
      img: '../assets/images/icon/jimmy-jo.jpg'
    }
  ]
  onItemSelect(item: any) {
    let a = {};
    a['id'] = item.id;
    a['view'] = true;
    if (this.userPermission == 'edit') {
      a['edit'] = true;
    } else {
      a['edit'] = false;
    }

    this.selectedItems.push(a);
    //console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    //console.log(item);
    this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
  }
  onSelectAll(items: any) {
    ////console.log(items);
    items.forEach(x => {
      let a = {};
      a['id'] = x.id;
      a['view'] = true;
      if (this.userPermission == 'edit') {
        a['edit'] = true;
      } else {
        a['edit'] = false;
      }
      this.selectedItems.push(a);
    });
    ////console.log(this.selectedItems);
  }
  onDeSelectAll(items: any) {
    //console.log(items);
    this.selectedItems = [];
  }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove', 'selectAll');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Image Type' }, 'deselectAll');
    });
  }

  /* To set page properties
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021
  */
  initForm() {
    this.showLoader = true;
    this.styleForm = this.fb.group({
      styleName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      newStyleNum: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9- ]{1,50}$')]],
      season: [''],
      sizeChart: ['', [Validators.required]],
      sizeRange: ['', [Validators.required]],
      sampleSize: ['', Validators.required],
      ageGroup: ['', Validators.required],
      subCategory: [''],
      targetGender: ['', Validators.required],
      category: ['', Validators.required],
      styleNumber: [{ value: '', disabled: true }, Validators.pattern('^[a-zA-Z0-9- ]{1,50}$')],
      description: ['', [Validators.maxLength(500)]],
      status: ['', Validators.required],
      styleid: [{ value: '', disabled: true }],
    })
    this.createForm = this.fb.group({
      styleName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9- ]{3,50}$')]],
      description: [''],
      Colourways: [''],
      Images: [''],
      BOM: [''],
      Construction: [''],
      Pattern: [''],
      Specification: [''],
      Processing: ['']
    })
    this.showLoader = false;

    //this.loadSelectPicker();

  }

  getOnlineUsers() {
    this.onlineUsers = [];
    if (this.storageservice.getOnlineUsers()) {
      let result = this.storageservice.getOnlineUsers();
      this.onlineUsers = result.split(',');
    }
  }
  charactersOnly(e: any) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characterOnly(e: any) {
    var regex = new RegExp("^[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  copyStyle(style) {
    this.showModal = true;
    $('#myModal').modal('show');
    this.item = style;
    //console.log(this.item);
    //this.popupStyleName = 'Copy of '+style.styleName;
    //this.popupDescription = 'Copy of '+style.description;
    this.f2.styleName.setValue('Copy of ' + style.styleName);
    if (style.description != null) {
      this.f2.description.setValue('Copy of ' + style.description);
    }
    // $('#myModal').on('hidden.bs.modal', function (e) {
    //   //console.log('modal closed');
    //   this.createForm.reset(this.createForm.value);
    //   this.createForm.setErrors(null);
    // })
  }


  submit(e: PageChangeEvent): void {
    //this.showLoader = true;
    this.data = this.sharedGridData.filter((val) =>
      val.styleName.toLowerCase().includes(this.searchedKeyword)
    );
    //this.showLoader = false;
  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.userservice.logout();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
      this.showLoader = false;
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.appStaticMsgs.unResponsive.error);

    }
    this.showLoader = false;
  }

  decryptData(x) {
    x.styleNumber = this.aesdecryptservice.decryptData(this.dataservice.Key, x.styleNumber);
    x.styleName = this.aesdecryptservice.decryptData(this.dataservice.Key, x.styleName);
    x.newStyleNum = this.aesdecryptservice.decryptData(this.dataservice.Key, x.newStyleNum);
    x.category = this.aesdecryptservice.decryptData(this.dataservice.Key, x.category);
    x.status = this.aesdecryptservice.decryptData(this.dataservice.Key, x.status);
    x.subCategory = this.aesdecryptservice.decryptData(this.dataservice.Key, x.subCategory);
    x.targetGender = this.aesdecryptservice.decryptData(this.dataservice.Key, x.targetGender);

    if (x.createdBy != null) {
      x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, x.createdBy.firstName);
      x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, x.createdBy.lastName);
      x.CreatedbyName = x.createdBy.firstName;
      x.createdBylastName = x.createdBy.lastName;
      x.createdBy.imageUrl = this.aesdecryptservice.decryptData(this.dataservice.Key, x.createdBy.imageUrl);
    }
    if (x.styleImage) {
      x.styleImage.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, x.styleImage.thumbnail);
    }
  }

  loadSharedListData() {
    this.sharedStartEndListCount = this.sharedStartListCount + "-" + this.sharedEndListCount;
    this.dataservice.getAllStyles(this.sharedStartEndListCount, this.styleViewMode).subscribe(response => {
      //console.log(response,'res')
      if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
        this.isDataLoading = false;
        this.logger.info(this.appStaticMsgs.styledetails.allstylelistloadedsuccessfully, "Style");
        this.showLoader = false;
        this.sharedGridData = [];
        response['body']['item1'].forEach(x => {
          this.decryptData(x);
          this.sharedGridData.push(x);

        });
        this.data = this.sharedGridData;
        //console.log(this.data,'data');
        this.totalRecordCount = response['body']['item3'];
        if (this.sharedGridData.length > 0) {
          this.sharedNotFound = false;
        } else {
          this.sharedNotFound = true;
        }
        // this.sharedGridData.forEach((x, i) => {
        //     this.fa.push(this.updateFormGroup(x));
        //   });
        var gridView = {
          data: this.sharedGridData,
          total: this.totalRecordCount,
        };
        this.gridData = gridView;
        //console.log(this.gridData,'grid');
        this.loading = false;
      } else {
        this.isSharedScrollDown = true;
        if (response['body']['item2'] !== 'Success') {
          // this.error("Failed:" + response['body']['item1'])
          this.toastService.showError(response['body']['item1']);
          this.logger.error(response['body']['item1'], "Style");
        }
        this.showLoader = false;
        this.sharedNotFound = true;
      }
      this.sharedGridData = response.body.item1;
    }, err => {
      // this.getError(err);
      this.toastService.showError(err);
      this.logger.error(err.error.message, "Style");
      this.showLoader = false;
    })
  }

  closePopup() {
    this.createTitle = '';
    this.isTitleError = false;
  }
  /*To get style details data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async getStyleDetails() {
    this.showLoader1 = true;
    ////console.log(this.storageservice.getEditedStyleId())/
    this.dataservice.GetStyleDataByID(this.editData.id).subscribe((response) => {
       console.log(response,'styledata')
      if (response.body['item2'] === "Success") {
        this.logger.info(response.body['item1'][0]["styleName"] + " loaded successfully", "Style Details");
        this.getSharedData();
        ////console.log(JSON.stringify(response.body['item1']));
        this.editedStyleData = response.body['item1'];
        this.UserDetails = response.body['item1'];
        this.isshared = response.body['item1'][0]['isShared'];
        this.editedStyleData[0].createdBy.firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.editedStyleData[0].createdBy.firstName);
        this.editedStyleData[0].createdBy.lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.editedStyleData[0].createdBy.lastName);
        this.UserDetails[0].createdBy.firstName = this.editedStyleData[0].createdBy.firstName;
        this.UserDetails[0].createdBy.lastName = this.editedStyleData[0].createdBy.lastName;
        this.initForm();
        this.stylesNumber = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["styleNumber"]);
        this.styleForm.get('styleid').setValue(response.body['item1'][0]["id"]);
        this.styleForm.get('styleName').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["styleName"]));
        this.styleForm.get('newStyleNum').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["newStyleNum"]));
        this.styleForm.get('category').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["category"]));
        this.styleForm.get('styleNumber').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["styleNumber"]));
        this.styleForm.get('subCategory').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["subCategory"]));
        this.styleForm.get('description').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["description"]));
        this.styleForm.get('season').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["season"]));
        //this.styleForm.get('fabric').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["fabric"]));
        this.styleForm.get('ageGroup').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["ageGroup"]));
        this.styleForm.get('targetGender').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["targetGender"]));

        this.styleForm.get('sizeChart').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["sizeChart"]));
        //this.changeSizeRange('',this.styleForm.get('sizeRange').value);
        this?.changeSizeChart('', this?.styleForm.get('sizeChart')?.value);

        //this.styleForm.get('technicalPackage').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["technicalPackage"]));
        this.styleForm.get('status').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["status"]));
        this.styleForm.get('sampleSize').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["sampleSize"]));
        //console.log('this.styleForm',this.styleForm);
        this.styleForm.get('sizeRange').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][0]["sizeRange"]));
        console.log(this.styleForm)
        this.refreshSelect();
        this.recordId = response.body['item1'][0]["id"];
        this.showLoader1 = false;
        var styleId = this.editData.id;
        var styleIdNotification = this.storageservice.getstyleIdNotification();
        if (styleId == styleIdNotification) {
          this.storageservice.removeStyleIdNotification();
         // this.stylesdiscussion();
        }
      }
      else {
        this.showLoader1 = false;
        this.isEditMode = false;
        this.logger.error(response.body['item1'], "Style Details");
      }
    },
      err => {
        this.showLoader1 = false;
        this.logger.error(err.error.message, "Style Details");
        if (err.error.message === "Network error communicating with endpoint") {
          this.showLoader1 = false;
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.showLoader1 = false;
          this.sessionTimedOut();
          this.userservice.logout();
          this.toastService.showError("The token being used has expired. Please login again.");
        } else if (err.error.message === "Unauthorized") {
          this.showLoader1 = false;
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
        }
        else{
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
      })
  }

  /* To open style page in edit mode
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021*/
  editStyle() {
    this.pomtab = true;
    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;
      this.loadSelectPicker();
      this.isThumbnailAdded = true;
      if (this.editData.id) {
        this.getStyleDetails();
      }
      this.initForm();
    }

  /*To save style details data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  async onStyleSubmit() {
    console.log(this.styleForm, 'styleform');

    this.submitted = true;
    //console.log(this.styleForm.value);
    if (this.styleForm.invalid) {
      // this.error("Invalid Style Form")
      this.toastService.showError("Please enter all the mandatory fields.");

      return false;
    }
    this.showLoader = true;

    let styleData = {
      styleName: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.styleName.value),
      styleNumber: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.styleNumber.value),
      newStyleNum: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.newStyleNum.value),
      category: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.category.value),
      subCategory: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.subCategory.value),
      targetGender: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.targetGender.value),
      ageGroup: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.ageGroup.value),
      sampleSize: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.sampleSize.value),
      sizeRange: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.sizeRange.value.join()),
      sizeChart: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.sizeChart.value),

      //fabric: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.fabric.value),
      //technicalPackage: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.technicalPackage.value),
      description: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.description.value),
      // classification: this.f.classification.value,
      // org: '',
      season: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.season.value),
      status: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.status.value),
    };
    if (!this.editData.id) {
      this.dataservice.AddStyles(styleData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            ////console.log(JSON.stringify(response.body['item1']));
            this.stylesNumber = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["styleNumber"]);
            this.styleForm.get('styleid').setValue(response.body['item1']["id"]);
            this.styleForm.get('styleName').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["styleName"]));
            this.styleForm.get('newStyleNum').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["newStyleNum"]));
            this.styleForm.get('category').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["category"]));
            this.styleForm.get('styleNumber').setValue(this.stylesNumber);
            this.styleForm.get('subCategory').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["subCategory"]));
            this.styleForm.get('description').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["description"]));
            this.styleForm.get('season')?.setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response?.body['item1']["season"]));
            //this.styleForm.get('fabric').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["fabric"]));
            this.styleForm.get('ageGroup').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["ageGroup"]));
            this.styleForm.get('targetGender').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["targetGender"]));
            this.styleForm.get('sampleSize').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sampleSize"]));
            this.styleForm.get('sizeRange').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeRange"]));
            this.styleForm.get('sizeChart').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeChart"]));
            this.styleForm.get('status').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["status"]));
            this.refreshSelect();
            //this.sampleSize=[this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeRange"])];
            //this.success("Style Added Successfully!");
            // this.success(this.appStaticMsgs.styledetails.styleaddedsuccessfully)
            this.toastService.showSuccess(this.appStaticMsgs.styledetails.styleaddedsuccessfully);

            this.logger.info(this.appStaticMsgs.styledetails.styleaddedsuccessfully, "Style Details");
            this.storageservice.storeEditedStyleId(response.body['item1'].id);
            this.submitted = false;
            this.isCreateMode = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            //this.getStyleDetails();


            this.showLoader = false;
          } else {
            this.submitted = false;
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "Style Details");
            this.showLoader = false;
          }
        }
      }, err => {
        this.submitted = false;
        this.showLoader = false;
        this.logger.error(err.error.message, "Style Details");
        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout();
          this.toastService.showError("The token being used has expired. Please login again.");
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
          // this.sessionTimedOut();
          // this.userservice.logout()
        }
        else {
          this.submitted = false;
          this.logger.error(err.error.message, "Style Details");
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      });
    } else {
      let styleid = { id: this.editData.id };
      let updatedStyleData = { ...styleData, ...styleid };
      //console.log(JSON.stringify(updatedStyleData))
      this.dataservice.updateStylebyId(updatedStyleData).subscribe(response => {
        if (response.status === 200) {

          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            let stylesnumber = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["styleNumber"]);
            this.stylesNumber = this.aesdecryptservice.decryptData(this.dataservice.Key, stylesnumber);
            this.styleForm.get('styleid').setValue(response.body['item1']["id"]);
            this.styleForm.get('styleName').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["styleName"]));
            this.styleForm.get('newStyleNum').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["newStyleNum"]));
            this.styleForm.get('category').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["category"]));
            this.styleForm.get('styleNumber').setValue(this.stylesNumber);
            this.styleForm.get('subCategory').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["subCategory"]));
            this.styleForm.get('description').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["description"]));
            this.styleForm.get('season').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["season"]));
            //this.styleForm.get('fabric').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["fabric"]));
            this.styleForm.get('ageGroup').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["ageGroup"]));
            this.styleForm.get('targetGender').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["targetGender"]));
            this.styleForm.get('sampleSize').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sampleSize"]));
            this.styleForm.get('sizeRange').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeRange"]));
            this.styleForm.get('sizeChart').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeChart"]));
            this.styleForm.get('status').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["status"]));
            this.refreshSelect();
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.getStyleDetails();
            // this.stylesComponent.LoadDataList();
            // this.success(this.appStaticMsgs.styledetails.styleupdatedsuccessfully);
            this.toastService.showSuccess(this.appStaticMsgs.styledetails.styleupdatedsuccessfully);
            this.router.navigate(['./styles'])
            this.logger.info(this.appStaticMsgs.styledetails.styleupdatedsuccessfully, "Style Details");
            //this.getStyleDetails();
            this.showLoader = false;
          }
          else {
            ////console.log(response)
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "Style Details");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "Style Details");

        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout();
          this.toastService.showError("The token being used has expired. Please login again.");
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
        }
        else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          this.logger.error(err.message, "Style Details");

        }
        this.showLoader = false;
      });
    }
    this.dialogRef.close();
  }
  /*To cancel create mode and redirect to style list page
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  cancelStyleCreate() {
    this.router.navigate(['/styles']);

  }

  /*To get all user for share style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getAllUsers() {
    this.showLoader = true;
    let UserData = JSON.parse(localStorage.getItem('userFullName'));
    this.dataservice.getallUsers().subscribe(response => {
      if (response.body) {
        this.showLoader = false;
        this.logger.info(this.appStaticMsgs.styledetails.loadalluserdetails, "Style Details");
        var data = response.body['item1'];
        this.itemList = data.filter(x => this.aesdecryptservice.decryptData(this.dataservice.Key, x.eMail) !== UserData['eMail']);
        this.itemList.forEach((x) => {
          x['id'] = x.id;
          x['firstName'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.firstName);
          x['lastName'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.lastName);
          x['eMail'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.eMail);
          x['itemName'] = x['firstName'] + ' ' + x['lastName'] + ' - ' + x['eMail'];
          if (x.picture) {
            x['image'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.picture.thumbnail);
          }
        });

        this.dropdownList = this.itemList;

        console.log("USER DATA", this.itemList);
      }
      else {
        // this.error(response.body['item1']);
        this.toastService.showError(response.body['item1']);
        this.logger.error(response.body['item1'], "Style Details");
        this.showLoader = false;
      }
    }, err => {
      this.logger.error(err.error.message, "Style Details");
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.showLoader = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
        this.showLoader = false;
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
        this.showLoader = false;
      }
      else {
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        this.logger.error(err.error.message, "Style Details");


      }
      this.showLoader = false;
    });
  }


  /*To check existing user is added for shared style
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  checkValue(event) {
    if (!event.id)
      return;
    var data = this.editedSharedData.filter(x => x.id == event.id);
    if (data.length > 0) {
      let index = this.editedSharedData.findIndex(x => x.id == event.id);
      this.editedSharedData[index] = event;
    }
    else {
      this.editedSharedData.push(event);
    }
  }
  /*To get all shared user data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getSharedData() {
    this.getAllUsers();
    this.getAllsharedaccess();
    this.isedit[this.fieldArray.length] = true;

    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);

    var fields =
    {
      "edit": false,
      "view": true,
      "id": "",
      "sharedTo": "",
      "user": "",
    }
      ;
    this.fieldArray.push(fields);
    this.isedit = [false];
    // setTimeout(() => {
    // }, 100);
  }


  itemDetails() {
    this.discussionComponent.openitemDetails(this.editedStyleData);
  }
  /*To get all shared user acess data
    Written by : Kiran Nagargoje
    Date: 12-Oct-2021 */
  getAllsharedaccess() {

    if (this.editData.id) {
      this.showLoader = true;
      this.sharedUsers = [];
      this.dataservice.getsharedaccess(this.editData.id, 'Styles').subscribe(response => {
        if (response.status === 200) {

          //this.allUser = response.body;
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.logger.info(this.appStaticMsgs.styledetails.getshareddata, "Style Details");
            for (let i = 0; i < response.body['item1'].length; i++) {
              response.body['item1'][i].module = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].module);
              response.body['item1'][i].org = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].module);
              for (let j = 0; j < response.body['item1'][i].user.length; j++) {
                response.body['item1'][i].user[j].firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].firstName);
                response.body['item1'][i].user[j].lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].lastName);
                response.body['item1'][i].user[j].about = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].about);
                response.body['item1'][i].user[j].eMail = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].eMail);
                response.body['item1'][i].user[j].group = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].group);
                response.body['item1'][i].user[j].org = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].org);
                response.body['item1'][i].user[j].fullName = response.body['item1'][i].user[j].firstName + " " + response.body['item1'][i].user[j].lastName;
                if (response.body['item1'][i].user[j].picture) {
                  response.body['item1'][i].user[j].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].picture.thumbnail);
                  response.body['item1'][i].user[j].picture.name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].picture.name);
                }

                var isOnline = false;
                if (this.onlineUsers.length > 0) {
                  if (this.onlineUsers.indexOf(response.body['item1'][i].user[j].eMail) !== -1) {
                    isOnline = true;
                  }
                }
                let item = {
                  user: response.body['item1'][i].user,
                  isOnline: isOnline
                };
                this.sharedUsers.push(item);

              }
            }

            //this.isSharedView=true;
            this.fieldArray = response.body['item1'];
            this.newfieldArray = [];
            this.newfieldArray = response.body['item1'];

          } else {
            ////console.log(response)
            //this.alert = 'danger';
            //this.errorDiv = true;
            // this.errors = response.body['item2'];
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "Style Details");

            this.showLoader = false;
          }
        }
      }, err => {
        //this.alert = 'danger';
        //this.errorDiv = true;
        //this.errors = err.message;
        this.logger.error(err.error.message, "Style Details");

        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout();
          this.toastService.showError("The token being used has expired. Please login again.");
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
        }
        else {
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          this.logger.error(err.message, "Style Details");

        }
        this.showLoader = false;
      });
    }
  }
  onSelectGender(value) {

    this.agegroup = [
      { value: 'Infants & Toddlers (0 & 4 Years)', label: 'Infants & Toddlers (0 & 4 Years)' },
      { value: '5 to 10 Years', label: '5 to 10 Years' },
      { value: '10 to 19 Years', label: '10 to 19 Years' },
      { value: '19 to 29 Years', label: '19 to 29 Years' },
      { value: '29 to 49 Years', label: '29 to 49 Years' },
      { value: 'Above 50 Years"', label: 'Above 50 Years' },
    ];

  }
  changeSizeRange(event, data) {

    console.log('changeSizeRange', this.styleForm.get('sizeChart').value)

    this.sampleSize = [];
    if (event) {
      let selectedSize = this.styleForm.get('sizeRange').value;
      console.log(selectedSize);
      for (var i = 0; i < selectedSize.length; i++) {
        let data = { id: selectedSize[i] + "*", value: selectedSize[i] };
        this.sampleSize.push(data);
      }
    } else {
      let dropData = [];

      console.log('dropData', this.styleForm.get('sizeRange').value);

      let selectedSize = this.styleForm.get('sizeRange').value.split(',');

      for (var i = 0; i < selectedSize.length; i++) {
        let data = { id: selectedSize[i] + "*", value: selectedSize[i] };
        console.log(data);
        this.sampleSize.push(data);
        dropData.push(selectedSize[i]);
      }
      this.styleForm.get('sizeRange').setValue(dropData);

    }

    console.log(this.sampleSize, 'sampleSize');
    $('#sampleSize').selectpicker();
    this.refreshSelect();

    //this.getStylePomSpecifications();
  }
  filteredItems: any;

  changeSizeChart(event, data) {
    console.log(data,'sizechart')
    this.filteredItems = [];
    let selectedCat = this.styleForm.get('sizeChart').value;
    let filterCategory = this.unfilteredData?.filter(x => x.sizeCategory == selectedCat);
    console.log('sizeCategory', filterCategory);
    filterCategory.forEach(x => {
      this.filteredItems.push(x);
    })
    console.log('filterCategory', this.filteredItems);
    $('#sizeRange').selectpicker();
    this.refreshSelect();
    setTimeout(() => {
      this.changeSizeRange('', '');
    }, 2000);
  }
  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }

  sizeRanges: any;
  unfilteredData: any;
  public dropdown = [{ data: [] }];
  getAllStyleSizeRange() {
    this.dataservice.getStyleSizeRange().subscribe(response => {
      if (response['body']['item2'] == 'Success') {
        let Data = response['body']['item1'];
        Data.forEach(x => {
          x['id'] = x.id;
          x['size'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.size);
          x['sizeCategory'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.sizeCategory);
          x['columnName'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.columnName);
        });

        this.unfilteredData = Data;
        if (Data.length > 0) {
          Data.forEach(x => {
            if (x.sizeCategory) {
              if (this.dropdown[0].data.length > 0) {
                if (
                  !this.dropdown[0].data.find(
                    (y) => y.id == x.sizeCategory
                  )
                ) {
                  this.dropdown[0].data.push({
                    id: x.sizeCategory,
                    text: x.sizeCategory
                  });
                }
              } else {
                this.dropdown[0].data.push({
                  id: x.sizeCategory,
                  text: x.sizeCategory
                });
              }
            }
          })
        }
        this.sizeCharts = this.dropdown[0].data;
        console.log('filteredItems', this.dropdown[0].data);
        //this.sizeRanges = Data;
      }
    }, err => {
      console.log('getAllStyleSizeRange error', err);
    });
  }

  cancelStyleEdit() {
      this.dialogRef.close();
    }
  }
