import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
    transform(date: any, format: string = 'EEE, d MMM yyyy'): string {
        date = new Date(date);  // if orginal type was a string
        date.setDate(date.getDate());
        return new DatePipe('en-US').transform(date, format);
    }
}