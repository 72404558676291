import { Action } from "@ngrx/store";

export enum CommonActionTypes {
    LOAD_CLASSIFICATIONS = '[Common] Load Classifications',
    LOAD_CLASSIFICATIONS_SUCCESS = '[Common] Load Classifications Success',
    LOAD_CLASSIFICATIONS_FAIL = '[Common] Load Classifications Fail',
    LOAD_ADV_COLOR_SEARCH = '[Common] Load Advanced Color Search',
    LOAD_ADV_COLOR_SEARCH_SUCCESS = '[Common] Load Advanced Color Search Success',
    LOAD_ADV_COLOR_SEARCH_FAIL = '[Common] Load Advanced Color Search Fail',
    LOAD_ADV_IMAGE_SEARCH = '[Common] Load Advanced Image Search',
    LOAD_ADV_IMAGE_SEARCH_SUCCESS = '[Common] Load Advanced Image Search Success',
    LOAD_ADV_IMAGE_SEARCH_FAIL = '[Common] Load Advanced Image Search Fail',
    LOAD_ADV_STITCH_SEARCH = '[Common] Load Advanced Stitch Search',
    LOAD_ADV_STITCH_SEARCH_SUCCESS = '[Common] Load Advanced Stitch Search Success',
    LOAD_ADV_STITCH_SEARCH_FAIL = '[Common] Load Advanced Stitch Search Fail',
    LOAD_ADV_SUPPLIER_SEARCH = '[Common] Load Advanced Supplier Search',
    LOAD_ADV_SUPPLIER_SEARCH_SUCCESS = '[Common] Load Advanced Supplier Search Success',
    LOAD_ADV_SUPPLIER_SEARCH_FAIL = '[Common] Load Advanced Supplier Search Fail',
    LOAD_ADV_MATERIAL_SEARCH = '[Common] Load Advanced Material Search',
    LOAD_ADV_MATERIAL_SEARCH_SUCCESS = '[Common] Load Advanced Material Search Success',
    LOAD_ADV_MATERIAL_SEARCH_FAIL = '[Common] Load Advanced Material Search Fail',
    LOAD_ADV_SEAMS_SEARCH = '[Common] Load Advanced Seams Search',
    LOAD_ADV_SEAMS_SEARCH_SUCCESS = '[Common] Load Advanced Seams Search Success',
    LOAD_ADV_SEAMS_SEARCH_FAIL = '[Common] Load Advanced Seams Search Fail',
    LOAD_ADV_PALETTE_SEARCH = '[Common] Load Advanced Palette Search',
    LOAD_ADV_PALETTE_SEARCH_SUCCESS = '[Common] Load Advanced Palette Search Success',
    LOAD_ADV_PALETTE_SEARCH_FAIL = '[Common] Load Advanced Palette Search Fail'
}

export class LoadClassifications implements Action {
    readonly type = CommonActionTypes.LOAD_CLASSIFICATIONS
    constructor(public payload: string) { }
}

export class LoadClassificationsSuccess implements Action {
    readonly type = CommonActionTypes.LOAD_CLASSIFICATIONS_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadClassificationsFail implements Action {
    readonly type = CommonActionTypes.LOAD_CLASSIFICATIONS_FAIL

    constructor(public payload: string) { }
}

export class LoadAdvancedColorSearch implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_COLOR_SEARCH
    constructor(public payload: any) { }
}

export class LoadAdvancedColorSearchSuccess implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_COLOR_SEARCH_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadAdvancedColorSearchFail implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_COLOR_SEARCH_FAIL

    constructor(public payload: string) { }
}

export class LoadAdvancedImageSearch implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_IMAGE_SEARCH
    constructor(public payload: any) { }
}

export class LoadAdvancedImageSearchSuccess implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_IMAGE_SEARCH_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadAdvancedImageSearchFail implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_IMAGE_SEARCH_FAIL

    constructor(public payload: string) { }
}
export class LoadAdvancedStitchSearch implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_STITCH_SEARCH
    constructor(public payload: any) { }
}

export class LoadAdvancedStitchSearchSuccess implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_STITCH_SEARCH_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadAdvancedStitchSearchFail implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_STITCH_SEARCH_FAIL

    constructor(public payload: string) { }
}
export class LoadAdvancedSupplierSearch implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_SUPPLIER_SEARCH
    constructor(public payload: any) { }
}

export class LoadAdvancedSupplierSearchSuccess implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_SUPPLIER_SEARCH_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadAdvancedSupplierSearchFail implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_SUPPLIER_SEARCH_FAIL

    constructor(public payload: string) { }
}
export class LoadAdvancedMaterialSearch implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_MATERIAL_SEARCH
    constructor(public payload: any) { }
}

export class LoadAdvancedMaterialSearchSuccess implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_MATERIAL_SEARCH_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadAdvancedMaterialSearchFail implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_MATERIAL_SEARCH_FAIL

    constructor(public payload: string) { }
}
export class LoadAdvancedSeamsSearch implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_SEAMS_SEARCH
    constructor(public payload: any) { }
}

export class LoadAdvancedSeamsSearchSuccess implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_SEAMS_SEARCH_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadAdvancedSeamsSearchFail implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_SEAMS_SEARCH_FAIL

    constructor(public payload: string) { }
}
export class LoadAdvancedPaletteSearch implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_PALETTE_SEARCH
    constructor(public payload: any) { }
}

export class LoadAdvancedPaletteSearchSuccess implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_PALETTE_SEARCH_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadAdvancedPaletteSearchFail implements Action {
    readonly type = CommonActionTypes.LOAD_ADV_PALETTE_SEARCH_FAIL

    constructor(public payload: string) { }
}

export type commonActionTypes = LoadClassifications | LoadClassificationsSuccess | LoadClassificationsFail
    | LoadAdvancedColorSearch | LoadAdvancedColorSearchSuccess | LoadAdvancedColorSearchFail | LoadAdvancedImageSearch
    | LoadAdvancedImageSearchSuccess | LoadAdvancedImageSearchFail | LoadAdvancedStitchSearch
    | LoadAdvancedStitchSearchSuccess | LoadAdvancedStitchSearchFail | LoadAdvancedSupplierSearch
    | LoadAdvancedSupplierSearchSuccess | LoadAdvancedSupplierSearchFail | LoadAdvancedSeamsSearch
    | LoadAdvancedSeamsSearchSuccess | LoadAdvancedSeamsSearchFail | LoadAdvancedMaterialSearch
    | LoadAdvancedMaterialSearchSuccess | LoadAdvancedMaterialSearchFail | LoadAdvancedPaletteSearch
    | LoadAdvancedPaletteSearchSuccess | LoadAdvancedPaletteSearchFail;
