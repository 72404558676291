import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { environment } from '../../../../tenants/main/environments/environment';
import { StorageService } from '../helpers/storage.service';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable()
export class NotificationService {
    private http: HttpClient;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(
        private storage: StorageService,
        @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
    }

    deviceSubscription(sub: PushSubscription | null) {
        const content_ = JSON.stringify(sub);
        //console.log("deviceSubscription=== call"+ content_);
        const apiUrl = `${environment.deviceSubscription}`;
        return this.http.post(apiUrl, content_, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + this.storage.getToken()
            }),
            observe: 'response'
        })
    }

    deviceUnsubscription(unSub: UnSubscription | null) {
        const content_ = JSON.stringify(unSub);
        //console.log("deviceUnsubscription===>"+ content_);
        const apiUrl = `${environment.deviceUnsubscription}`;
        return this.http.post(apiUrl, content_, {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.storage.getToken()
        }),
        observe: 'response'
        }).pipe();
    }

    sendNotification(message: NotificationModel | null) {
        const content_ = JSON.stringify(message);
        console.log("sendNotification==="+ content_);
        const apiUrl = `${environment.sendNotification}`;
        return this.http.post(apiUrl, content_, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + this.storage.getToken()
            }),
            observe: 'response'
        }).pipe();
    }
}

export interface PushSubscription {
    name?: string | undefined;
    pushEndpoint?: string | undefined;
    pushP256DH?: string | undefined;
    pushAuth?: string | undefined;
    emailId?: string | undefined;
}
export interface UnSubscription {
    DeviceName?: string | undefined;
    EmailId?: string | undefined;
}
export interface NotificationModel {
    title?: string | undefined;
    message?: string | undefined;
    url?: string | undefined;
    email?: string | undefined;
    recordId?: string | undefined;
    documentId?: string | undefined;
    notifiationType?: string | undefined;
    moduleName?: string | undefined;
}

