import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
export enum ImageActionTypes {
    LOAD_IMAGE_LIBRARY = '[Image] Load ImageLibrary',
    LOAD_IMAGE_LIBRARY_SUCCESS = '[Image] Load ImageLibrary Success',
    LOAD_IMAGE_LIBRARY_FAIL = '[Image] Load ImageLibrary Fail',
    LOAD_IMAGE = '[Image] Load Image',
    LOAD_IMAGE_SUCCESS = '[Image] Load Image Success',
    LOAD_IMAGE_FAIL = '[Image] Load Image Fail',
    CREATE_IMAGE = '[Image] Create Image',
    CREATE_IMAGE_SUCCESS = '[Image] Create Image Success',
    CREATE_IMAGE_FAIL = '[Image] Create Image Fail',
    UPDATE_IMAGE = '[Image] Update Image',
    UPDATE_IMAGE_SUCCESS = '[Image] Update Image Success',
    UPDATE_IMAGE_FAIL = '[Image] Update Image Fail',
    UPDATE_IMAGE_LIBRARY = '[Image] Update Image Library',
    UPDATE_IMAGE_LIBRARY_SUCCESS = '[Image] Update Image Library Success',
    UPDATE_IMAGE_LIBRARY_FAIL = '[Image] Update Image Library Fail',
    DELETE_IMAGE_LIBRARY = '[Image] Delete Image Library',
    DELETE_IMAGE_LIBRARY_SUCCESS = '[Image] Delete Image Library Success',
    DELETE_IMAGE_LIBRARY_FAIL = '[Image] Delete Image Library Fail'
}

export class LoadImageLibrary implements Action {
    readonly type = ImageActionTypes.LOAD_IMAGE_LIBRARY
    constructor(public payload1: string,public payload2: string) { }
}

export class LoadImageLibrarySuccess implements Action {
    readonly type = ImageActionTypes.LOAD_IMAGE_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadImageLibraryFail implements Action {
    readonly type = ImageActionTypes.LOAD_IMAGE_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export class LoadImage implements Action {
    readonly type = ImageActionTypes.LOAD_IMAGE

    constructor(public payload: string) { }
}

export class LoadImageSuccess implements Action {
    readonly type = ImageActionTypes.LOAD_IMAGE_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadImageFail implements Action {
    readonly type = ImageActionTypes.LOAD_IMAGE_FAIL

    constructor(public payload: string) { }
}
export class CreateImage implements Action {
    readonly type = ImageActionTypes.CREATE_IMAGE

    constructor(public payload: any) { }
}

export class CreateImageSuccess implements Action {
    readonly type = ImageActionTypes.CREATE_IMAGE_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateImageFail implements Action {
    readonly type = ImageActionTypes.CREATE_IMAGE_FAIL

    constructor(public payload: string) { }
}
export class UpdateImage implements Action {
    readonly type = ImageActionTypes.UPDATE_IMAGE

    constructor(public payload: any) { }
}

export class UpdateImageSuccess implements Action {
    readonly type = ImageActionTypes.UPDATE_IMAGE_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateImageFail implements Action {
    readonly type = ImageActionTypes.UPDATE_IMAGE_FAIL

    constructor(public payload: string) { }
}

export class UpdateImageLibrary implements Action {
    readonly type = ImageActionTypes.UPDATE_IMAGE_LIBRARY

    constructor(public payload: any[]) { }
}

export class UpdateImageLibrarySuccess implements Action {
    readonly type = ImageActionTypes.UPDATE_IMAGE_LIBRARY_SUCCESS

    constructor(public payload1: Update<any>[],public payload2: any) { }
}

export class UpdateImageLibraryFail implements Action {
    readonly type = ImageActionTypes.UPDATE_IMAGE_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class DeleteImageLibrary implements Action {
    readonly type = ImageActionTypes.DELETE_IMAGE_LIBRARY

    constructor(public payload: any[]) { }
}

export class DeleteImageLibrarySuccess implements Action {
    readonly type = ImageActionTypes.DELETE_IMAGE_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class DeleteImageLibraryFail implements Action {
    readonly type = ImageActionTypes.DELETE_IMAGE_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export type imageActionTypes = LoadImageLibrary | LoadImageLibrarySuccess | LoadImageLibraryFail |
    LoadImage | LoadImageSuccess | LoadImageFail | CreateImage | CreateImageSuccess |
    CreateImageFail | UpdateImage | UpdateImageSuccess | UpdateImageFail | UpdateImageLibrary |
    UpdateImageLibrarySuccess | UpdateImageLibraryFail | DeleteImageLibrary
    | DeleteImageLibrarySuccess | DeleteImageLibraryFail;