import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StyledetailsComponent } from '../styledetails/styledetails.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { Router } from '@angular/router';
import { StorageService } from '../../../helpers/storage.service';
import { DataService } from '../../../services/data.service';
import { LogService } from '../../../shared/log.service';
import { UserService } from '../../../helpers/user.service';
import { SharedService } from '../../../services/shared.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-styleprosewn',
  templateUrl: './styleprosewn.component.html',
  styleUrls: ['./styleprosewn.component.css']
})
export class StyleprosewnComponent implements OnInit {
  ismodaldata:boolean=true;
  sewnForm: FormGroup;
  showProcPomNote = false;
  appStaticMsgs: any;
  editsewnData:any;
  showProcSewnNote = false;
  showLoader: boolean;
  public isNew: boolean;
  sewnData = [];
  processingSewn:any;
  formload : boolean;
  dbprocessingSewn: any[];
  public filteredData: any[];
  public processingSewnstartCountList = 0;
  public processingSewnendCountList = 10;
  public processingSewnstartEndCount = "";
  public processingSewnisPageChanged = false;
  public processingSewnRecordCount: any;
  public processingSewnloading: boolean;
  dbprocessingPoms: any[];
  processingPoms: any;
  public processingPomsstartCountList = 0;
  public processingPomsendCountList = 10;
  public processingPomsstartEndCount = "";
  public processingPomsisPageChanged = false;
  public processingPomsRecordCount: any;
  public processingPomsloading: boolean;

  constructor(private dialogRef: MatDialogRef<StyledetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private userservice:UserService,
    private storageservice:StorageService,
    private sharedService:SharedService,
    private router: Router,) {
      this.sharedService.getprocessingsewnData().subscribe(x=>{
        console.log(x);
        this.editsewnData=x;
        console.log(this.editsewnData,'editedData');
      })
    }

  ngOnInit(): void {
    this.appStaticMsgs = appStaticMsg.data[0];
    if(this.data?.isprocessingsewnedit == false){
    this.sewnForm = new FormGroup({
      'sewn': new FormControl({ value: 'SEWN', disabled: true }),
      'pc': new FormControl(),
      'poms': new FormControl(),
      'originalLength': new FormControl('', Validators.required),
      'originalWidth': new FormControl('', Validators.required),
      'afterwashLength': new FormControl('', Validators.required),
      'afterwashWidth': new FormControl('', Validators.required),
      'afterdryLength': new FormControl('', Validators.required),
      'afterdryWidth': new FormControl('', Validators.required),
      'id': new FormControl(),
    });
  }
  else if(this.data?.isprocessingsewnedit == true){
   this.sewnForm = new FormGroup({
      'sewn': new FormControl({ value: 'SEWN', disabled: true }),
      'id': new FormControl( this.editsewnData.id, Validators.required),
      'pc': new FormControl( this.editsewnData.pc),
      'poms': new FormControl( this.editsewnData.poms, Validators.required),
      'originalLength': new FormControl( this.editsewnData.originalLength, Validators.required),
      'originalWidth': new FormControl( this.editsewnData.originalWidth, Validators.required),
      'afterwashLength': new FormControl( this.editsewnData.afterwashLength, Validators.required),
      'afterwashWidth': new FormControl( this.editsewnData.afterwashWidth, Validators.required),
      'afterdryLength': new FormControl( this.editsewnData.afterdryLength, Validators.required),
      'afterdryWidth': new FormControl( this.editsewnData.afterdryWidth, Validators.required),
    });
  }
  }
  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }
  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    )
  }
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })
  }
  numberOnly(e: any) {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
  onsewnsubmit(){
    if (this.sewnForm.controls.pc.value.length > 100) {
      this.sewnForm.controls.pc.setErrors({ 'incorrect': true });
    }
    if (this.sewnForm.controls.poms.value.length > 100) {
      this.sewnForm.controls.poms.setErrors({ 'incorrect': true });
    }
    if (this.sewnForm.invalid) {
      this.showProcSewnNote = true;
      this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields)
      return;
    }
    this.formload = true;
    this.sewnData = this.sewnForm.value;
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('pscount', '1');
    if (this.data?.isprocessingsewnedit == false) {
      formData.append('pstag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add'));
    } else {
      formData.append('psid[0]', this.sewnData['id']);
      formData.append('pstag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update'));
    }
    formData.append('pc[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.sewnData['pc']));
    formData.append('poms[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.sewnData['poms']));
    formData.append('originallength[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.sewnData['originalLength']));
    formData.append('originalwidth[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.sewnData['originalWidth']));
    formData.append('afterwashlength[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.sewnData['afterwashLength']));
    formData.append('afterwashwidth[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.sewnData['afterwashWidth']));
    formData.append('afterdrylength[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.sewnData['afterdryLength']));
    formData.append('afterdrywidth[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, this.sewnData['afterdryWidth']));


    this.formload = true;
    this.dataservice.addupdatedeleteStyleprocessing(formData).subscribe(async response => {
      this.showProcSewnNote = false;
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.formload = false;
          this.success(this.appStaticMsgs.styledetails.processingsewnsavesuccessfully);

          this.logger.info(this.appStaticMsgs.styledetails.processingsewnsavesuccessfully, "Style Processing SEWN");
          this.getStyleSewnProcessing();
          this.dialogRef.close();
          //this.processingForm = undefined;
          //this.sewnForm = undefined;
          //await this.getProcessingbyId();
        }
        else {
          this.logger.error(response.body['item1'], "Style Processing SEWN");
          this.error(response.body['item1']);
          this.formload = false;
        }

      }
    }, err => {
      this.showProcSewnNote = false;
      this.logger.error(err.error.message, "Style Processing SEWN");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.logger.error(err.error.message, "Style Processing SEWN");
        this.error(err.message);
      }
      this.formload = false;
    });
    }

    public async getStyleSewnProcessing() {

      this.processingSewnloading = true;
      this.processingSewnstartEndCount = this.processingSewnstartCountList + "-" + this.processingSewnendCountList;
      this.dataservice.getStyleSewnProcessing(this.storageservice.getEditedStyleId(), this.processingSewnstartEndCount).subscribe(response => {
        if (response.body['item2'] === "Success") {
          this.processingSewnloading = false;
          this.filteredData = response.body['item1'];
          this.logger.info(this.appStaticMsgs.styledetails.allprocessingsewnloadedsuccessfully, "Style SewnProcessing");
          this.dbprocessingSewn = response.body['item1']['processingSewns'];
          this.processingSewnRecordCount = response.body['item3'];
          if (response.body['item1']['processingSewns']) {
            for (let i = 0; i < this.dbprocessingSewn.length; i++) {

              this.dbprocessingSewn[i]["sewn"] = "SEWN";
              this.dbprocessingSewn[i].pc = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingSewn[i].pc);
              this.dbprocessingSewn[i].poms = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingSewn[i].poms);
              this.dbprocessingSewn[i].originalLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingSewn[i].originalLength);
              this.dbprocessingSewn[i].originalWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingSewn[i].originalWidth);
              this.dbprocessingSewn[i].afterwashLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingSewn[i].afterwashLength);
              this.dbprocessingSewn[i].afterwashWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingSewn[i].afterwashWidth);
              this.dbprocessingSewn[i].afterdryLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingSewn[i].afterdryLength);
              this.dbprocessingSewn[i].afterdryWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingSewn[i].afterdryWidth);

            }

            var gridView = {
              data: this.dbprocessingSewn,
              total: this.processingSewnRecordCount,
            };
            this.processingSewn = gridView;
          }
          else {
            this.processingSewn = null;
          }
          //this.processingForm = undefined;
          //this.sewnForm = undefined;
        }
        else {
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "Style SewnProcessing");
          this.processingSewnloading = false;
        }
      }, err => {
        this.logger.error(err.error.message, "Style SewnProcessing");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.processingSewnloading = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.processingSewnloading = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.processingSewnloading = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.error.message, "Style SewnProcessing");
        }
        this.processingSewnloading = false;
      });

    }

    close(){
      this.dialogRef.close();
     }

     public async getProcessingbyId() {
      await this.getStylePomProcessing();
      await this.getStyleSewnProcessing();
    }

    public async getStylePomProcessing() {
      this.processingPomsloading = true;
      this.processingPomsstartEndCount = this.processingPomsstartCountList + "-" + this.processingPomsendCountList;
      this.dataservice.getStylePomProcessing(this.storageservice.getEditedStyleId(), this.processingPomsstartEndCount).subscribe(response => {
        if (response.body['item2'] === "Success") {
          this.processingPomsloading = false;
          this.logger.info(this.appStaticMsgs.styledetails.allprocessingpomloadedsuccessfully, "Style PomProcessing");

          this.dbprocessingPoms = response.body['item1']['processingPOMs'];
          this.processingPomsRecordCount = response.body['item3'];
          if (response.body['item1']['processingPOMs']) {
            for (let i = 0; i < this.dbprocessingPoms.length; i++) {
              this.dbprocessingPoms[i]["pom"] = "POM";

              this.dbprocessingPoms[i].pc = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].pc);
              this.dbprocessingPoms[i].poms = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].poms);
              this.dbprocessingPoms[i].originalLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].originalLength);
              this.dbprocessingPoms[i].originalWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].originalWidth);
              this.dbprocessingPoms[i].afterwashLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].afterwashLength);
              this.dbprocessingPoms[i].afterwashWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].afterwashWidth);
              this.dbprocessingPoms[i].afterdryLength = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].afterdryLength);
              this.dbprocessingPoms[i].afterdryWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.dbprocessingPoms[i].afterdryWidth);
            }

            var gridView = {
              data: this.dbprocessingPoms,
              total: this.processingPomsRecordCount,
            };

            this.processingPoms = gridView;
          }
          else {
            this.processingPoms = null;
          }
          //this.processingForm = undefined;
          //this.sewnForm = undefined;
        }
        else {
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "Style PomProcessing");
          this.processingPomsloading = false;
        }
      }, err => {
        this.logger.error(err.error.message, "Style PomProcessing");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.processingPomsloading = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.processingPomsloading = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.processingPomsloading = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.error.message, "Style PomProcessing");


        }
        this.processingPomsloading = false;
      });

    }

  }

