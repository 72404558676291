import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { DataService } from '../../../services/data.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import Swal from 'sweetalert2';
import { AddEvent, GridDataResult, PageChangeEvent, GridComponent, CellClickEvent, DataStateChangeEvent, FilterService } from '@progress/kendo-angular-grid';
import { process, State, CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { Favourite } from '../../../models/color';
import { Router } from '@angular/router'
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { LogService } from '../../../shared/log.service';
import { interval, of, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as colorReducer from "../../../reducers/color.reducer";
import * as colorActionTypes from "../../../actions/color.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take, tap } from 'rxjs/operators';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { data } from 'jquery';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { ToastService } from '../../../services/toast.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { MatDialog } from '@angular/material/dialog';
import { CustomColorComponent } from '../custom-color/custom-color.component';

declare var $: any;

@Component({
  selector: 'app-colorlibrary',
  templateUrl: './colorlibrary.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./colorlibrary.component.css']
})
export class ColorlibraryComponent implements OnInit {

  public listItems: Array<{ text: string, value: string }> = [
    { text: "Active", value: "Active" },
    { text: "Inactive", value: "Inactive" }
  ];

  selectedvalue='Active'
  statuslist = ["Active", "Inactive"];
  colorData = [];
  dbcolorData = [];
  originalcolorData = [];
  colorDataForm: FormGroup;
  isResetMode = false;
  advForm: FormGroup;
  log: any[];
  createdFromDate: any;
  createdToDate: any;
  modifiedFromDate: any;
  modifiedToDate: any;
  minDate = { year: 1985, month: 1, day: 1 };
  @ViewChild('createdFrom') createdFromChild: any;
  @ViewChild('createdTo') createdToChild: any;
  @ViewChild('modifiedFrom') modifiedFromChild: any;
  @ViewChild('modifiedTo') modifiedToChild: any;

  paginationStartcount = 0;
  paginationEndcount = 10;
  public startCount = 0;
  public endCount = 10;
  public startCountList = 0;
  public endCountList = 10;
  public startEndCount = "";
  public isPageChanged = false;
  public isScrollDown = false;
  public isGetAllRecords = false;
  public totalRecordCount: any;
  loading: boolean;

  public filteredData: any[];
  public filteredXcelData: any[];
  public gridData: any;
  createdStartDate: string;
  modifiedEndDate: string;
  modifiedStartDate: string;
  createdEndDate: string;
  advSearchInfo: Observable<any[]>;
  checkStatusData = false;
  checkSharedStatusData = false;
  msg: string;
  showNote = false;
  isMyColors = true;
  sharedCopyCard: any[] = [];
  sharedCards: any[] = [];
  sharedNotFound: boolean;
  isSharedGetAllRecords = false;
  isSharedScrollDown = false;
  totalSharedRecordCount: any;
  sharedMsg: string;
  sharedEndCount = 10;
  sharedStartCount = 0;
  sharedStartEndCount = "";
  staticMsgs: any;

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      this.filteredData = this.filteredXcelData;
      var gridView = {
        data: this.filteredData,
        total: this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total; //process(this.filteredData, this.state);
    }
    else {
      var gridView = {
        data: this.colorViewMode === 'Shared' ? this.sharedGridData : this.colorData,
        total: this.colorViewMode === 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total; // process(this.colorData, this.state);
    }
  }
  min: number;
  max: number;

  Swal = require('sweetalert2');
  showLoader: boolean;
  showLoader1: boolean;
  gridContent = true;
  HeadingText = "Card View";
  colorViewMode = "Created";
  mycolor: boolean = true;
  sharedcolor: boolean = false;
  tableContent = false;
  activeTab: boolean = false;
  selected: any;
  cards: Observable<any[]>;
  colorClassifications: Observable<any[]>;
  searchedKeyword: string;
  copyCard: Observable<any[]>;
  public filterQuery = "";
  idArray: string[];
  result: any;
  model: any = {};
  temp: any[];
  enableKeywordVal: string[];
  enableCreatedByVal: string[];
  enableCreatToVal: string[];
  enableCreatFromVal: string[];
  enableStatusVal: string[];
  enableClassVal: string[];
  listBtn: boolean = true;
  cardBtn: boolean = false;
  enableModifToVal: string[];
  enableModifFromVal: string[];
  colorsForm: FormGroup;
  isEditMode = false;
  notFound: boolean;
  notfoundImg: string;
  @ViewChild('grid') grid: GridComponent;
  public gridView: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10
  };

  public filter: CompositeFilterDescriptor;
  classApplied = false;

  public SearchData = "";

  clickedRowItem: any;
  colorclassificationValue: string;
  colorClassificationData: any[];
  btnstatus: boolean = false;
  btnstatus1: boolean = true;
  isDataLoading = false;

  sharedEndListCount = 10;
  sharedStartListCount = 0;
  sharedGridData: any[] = [];
  sharedStartEndListCount: string;
  startEndListCount: string;
  file: any;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(
    private sharedService: SharedService,
    private storageservice: StorageService,
    private dataService: DataService,
    public userservice: UserService,
    private formBuilder: FormBuilder,
    public aesdecryptservice: AESDecryptService,
    private router: Router,
    public filterService: FilterService,
    private logger: LogService,
    public dialog: MatDialog,
    private store: Store<any>,
    public toastService: ToastService) {
    this.allData = this.allData.bind(this);
  }

  public group: any[] = [{
    field: 'Name'
  }];

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredData = filterBy((this.colorViewMode === 'Shared') ? this.sharedGridData : this.colorData, filter);
  }

  classification = [
    { value: 'Custom', label: 'Custom' },
    { value: 'Print and Pattern', label: 'Print and Pattern' },
    { value: 'Solid', label: 'Solid' }
  ];

  options = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];


  isChecked = false;

  /* To navigate to color form */
  createColor() {
    this.dataService.AddClientLog();
    this.router.navigate(['/colordetails']);

  }

  /* In list view when cliked on page number */
  onPageChange(e: PageChangeEvent): void {
    this.isPageChanged = true;
    this.state.skip = e.skip;
    this.state.take = e.take;
    if (this.colorViewMode === 'Shared') {
      this.sharedStartListCount = e.skip;
      this.sharedEndListCount = e.skip + e.take;
      this.loadSharedListData();
    } else {
      this.startCountList = e.skip;
      this.endCountList = e.skip + e.take;
      this.loadDataList();
    }
  }

  /* Scroll event for lazy loading data */
  scrollEvent = (event: any): void => {
    if (this.gridContent && this.router.url.includes('colorlibrary') && !this.tableContent) {
      if (!this.isDataLoading) {
        if (event.target.scrollTop >= (event.target.scrollHeight - event.target.clientHeight - 2)) {
          if (this.colorViewMode == 'Created') {
            this.isDataLoading = true;
            if (!this.isGetAllRecords) {
              this.isScrollDown = false;
              this.startCount = this.startCount + this.paginationEndcount;
              this.endCount = this.endCount + this.paginationEndcount;
              this.loadData();
            }
          } else {
            this.isDataLoading = true;
            if (!this.isSharedGetAllRecords) {
              this.isScrollDown = false;
              this.sharedStartCount = this.sharedStartCount + this.paginationEndcount;
              this.sharedEndCount = this.sharedEndCount + this.paginationEndcount;
              this.loadSharedData();
            }
          }
        }
      }
    }
  }

  /* To show the color library in card view */
  colorView(color) {
    this.isMyColors = (color === 'Created') ? true : false
    this.cards = of([]);
    this.notFound = false;
    this.isEditMode = false;
    if (this.tableContent) {
      this.closeAllRows();
    }
    this.colorViewMode = color;
    if (this.colorViewMode == 'Shared') {
      this.mycolor = false;
      this.sharedcolor = true;
    }
    else {
      this.mycolor = true;
      this.sharedcolor = false;
    }

    if (this.gridContent) {
      this.HeadingText = "Card View";
      this.colorViewMode == 'Shared' ? this.loadSharedData() : this.loadData();
    }
    else {
      this.HeadingText = "List View";
      this.colorViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();
    }
  }

  /* To show the color library in card view */
  cardView() {
    this.gridContent = true;
    this.isMyColors = true;
    this.HeadingText = "Card View";
    this.tableContent = false;
    this.listBtn = true;
    this.showNote = false;
    this.cardBtn = false;
    this.btnstatus = false;
    this.btnstatus1 = true;

    if (this.colorViewMode === 'Shared') {
      this.isSharedGetAllRecords = false;
      this.sharedCards = [];
      this.sharedStartCount = this.paginationStartcount;
      this.sharedEndCount = this.paginationEndcount;
      this.loadSharedData();
    } else {
      this.isGetAllRecords = false;
      this.startCount = this.paginationStartcount;
      this.endCount = this.paginationEndcount;
      this.loadData();
    }


  }

  /* To show the color library in list view */
  tableView() {
    this.gridContent = false;
    this.tableContent = true;
    this.isMyColors = false;
    this.HeadingText = "List View";
    this.cardBtn = true;
    this.listBtn = false;
    this.btnstatus = true;
    this.btnstatus1 = false;
    if (this.colorViewMode === 'Shared') {
      if (this.isPageChanged) {
        this.sharedStartListCount = this.state.skip;
        this.sharedEndListCount = this.state.skip + this.state.take;
      }
      else {
        this.sharedStartListCount = this.paginationStartcount;
        this.sharedEndListCount = this.paginationEndcount;
      }
    } else {
      if (this.isPageChanged) {
        this.startCountList = this.state.skip;
        this.endCountList = this.state.skip + this.state.take;
      }
      else {
        this.startCountList = this.paginationStartcount;
        this.endCountList = this.paginationEndcount;
      }
    }

    this.colorViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();

  }

  ngOnInit() {
    this.staticMsgs = appStaticMsg.data[0];
    window.addEventListener('scroll', this.scrollEvent, true);
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    this.storageservice.storeEditedColorId("");
    $(document).ready(function () {
      $('.selectpicker').selectpicker('val', 'Active');
    });

    this.advForm = this.formBuilder.group({
      keyword: [''],
      createdBy: [''],
      classification: [''],
      status: [''],
      createdFrom: [''],
      createdTo: [''],
      modifiedFrom: [''],
      modifiedTo: ['']
    });

    this.colorDataForm = this.formBuilder.group({
      formArray: new FormArray([])
    });
    this.loadSelectPicker();
    this.loadData();
    // this.getColorClassification("Colours");
    this.sharedService.sendClickEvent();
  }

  // convenience getters for easy access to form fields
  get f() { return this.colorDataForm.controls; }
  get fa() { return this.f.formArray as FormArray; }

  /* To give the formcontrolname of status */
  fac(data) {
    if (this.colorViewMode === 'Shared') {
      return this.fa.controls[this.sharedGridData.indexOf(data)] as FormGroup;
    } else {
      return this.fa.controls[this.colorData.indexOf(data)] as FormGroup;
    }
  }

  /* If any error from api response */
  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
      this.loading = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.userservice.logout();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.showLoader = false;
      this.loading = false;
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
      this.showLoader = false;
      this.loading = false;
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.staticMsgs.unResponsive.error);

    }
    this.showLoader = false;
    this.loading = false;
  }

  /* To export listview data into excel */
  public allData(): ExcelExportData {
    this.filteredData = filterBy((this.colorViewMode === 'Shared') ? this.sharedGridData : this.colorData, this.state.filter);
    this.state.take = this.filteredData.length;

    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.filteredData = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    const result: ExcelExportData = {
      data: this.filteredData //process(this.filteredData, this.state).data,
    };
    //this.state.take = 5;
    return result;
  }

  /* To open advanced search panel */
  advanceSearchOpen() {
    this.classApplied = true;
    this.getClassificationData()
  }

  /* To close advanced search panel */
  advanceSearchClose() {
    this.classApplied = false;
  }

  /* To clear fields advanced search panel */
  clearSearch() {
    this.clearFields();
    this.loadData();
    this.loadDataList();
    this.isResetMode = false;
  }

  decryptData(x) {
    x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
    x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
    x.colorStandard = this.aesdecryptservice.decryptData(this.dataService.Key, x.colorStandard);
    if (x.hexcode) {
      x.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, x.hexcode);
    } else if (x.thumbnailFiles) {
      x.thumbnailFiles['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles['thumbnail']);
    }
    x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
    x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
    x.CreatedbyName = x.createdBy.firstName;
    x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
  }

  loadSharedListData() {
    this.sharedStartEndListCount = this.sharedStartListCount + "-" + this.sharedEndListCount;
    this.dataService.getColor(this.sharedStartEndListCount, this.colorViewMode).subscribe(response => {
      if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
        this.isDataLoading = false;
        this.logger.info(this.staticMsgs.colorLib.colorList, "ColorLibrary");
        this.showLoader = false;
        this.sharedGridData = [];
        response['body']['item1'].forEach(x => {
          this.decryptData(x);
          this.sharedGridData.push(x);
        });
        this.totalSharedRecordCount = response['body']['item3'];
        if (this.sharedGridData.length > 0) {
          this.sharedNotFound = false;
        } else {
          this.sharedNotFound = true;
        }
        this.sharedGridData.forEach((x, i) => {
          this.fa.push(this.createFormGroup(x));
        });
        var gridView = {
          data: this.sharedGridData,
          total: this.totalSharedRecordCount,
        };
        this.gridData = gridView;
        this.loading = false;
        this.getColorClassification("Colours");
      } else {
        this.isSharedScrollDown = true;
        if (response['body']['item2'] !== 'Success') {
          // this.error("Failed:" + response['body']['item1'])
          this.toastService.showError(response['body']['item1']);
          this.logger.error(response['body']['item1'], "ColorLibrary");
        }
        this.showLoader = false;
        this.sharedNotFound = true;
      }
    }, err => {
      this.getError(err);
      this.logger.error(err.error.message, "ColorLibrary");
      this.showLoader = false;
    })
  }

  loadSharedData() {
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', this.selectedvalue);
    }, 10);
    if (!this.isSharedGetAllRecords) {
      this.sharedStartEndCount = this.sharedStartCount + "-" + this.sharedEndCount;
      this.dataService.getColor(this.sharedStartEndCount, this.colorViewMode).subscribe(response => {
        if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
          this.isDataLoading = false;
          this.logger.info(this.staticMsgs.colorLib.colorList, "ColorLibrary");
          this.showLoader = false;
          response['body']['item1'].forEach(x => {
            this.decryptData(x);
            this.sharedCards.push(x);
            this.sharedCards = this.sharedCards.filter((item, index) => this.sharedCards.indexOf(item) === index)
          });
          this.sharedCopyCard = [];
          this.sharedCopyCard = this.sharedCards;
          this.totalSharedRecordCount = response['body']['item3'];
          if (this.sharedCards.length > 0) {
            this.sharedNotFound = false;
          } else {
            this.sharedNotFound = true;
          }
          if (this.sharedCards.length == this.totalSharedRecordCount) {
            this.isSharedGetAllRecords = true;
          }
          else {
            this.isSharedScrollDown = true;
          }
          this.getColorClassification("Colours");
          this.onSelect('Active', 'shared');
        } else {
          this.isSharedScrollDown = true;
          if (response['body']['item2'] !== 'Success') {
            // this.error("Failed:" + response['body']['item1'])
            this.toastService.showError(response['body']['item1']);
            this.logger.error(response['body']['item1'], "ColorLibrary");
          }
          this.showLoader = false;
          this.sharedNotFound = true;
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, "ColorLibrary");
        this.showLoader = false;
      })
    }
  }

  /* To load data for card view */
  loadData() {
    this.startEndCount = this.startCount + "-" + this.endCount;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', this.selectedvalue);
    }, 10);

    this.store.dispatch(new colorActionTypes.LoadColorLibrary(this.startEndCount, this.colorViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
      if (colorLibrary.color.error === "") {
        if (colorLibrary.color.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.colorLib.colorList, "ColorLibrary");
          this.showLoader1 = false;
          this.cards = this.store.pipe(select(colorReducer.getColorLibrary));
          this.copyCard = this.cards;
          this.fa.clear();
          this.totalRecordCount = colorLibrary.color.message['item3'];
          this.colorData = colorLibrary.color.message['item1'];
          if (this.colorData.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.dbcolorData = colorLibrary.color.message['item1'];
          if (colorLibrary.color.ids.length == this.totalRecordCount) {

            this.isGetAllRecords = true;
          }
          else {
            this.isScrollDown = true;
          }
          this.colorData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          //this.gridData = process(this.colorData, this.state);
          this.getColorClassification("Colours");
          this.onSelect(this.selectedvalue, 'mycolor');
        } else {
          this.showLoader1 = false;
          this.notFound = true;
          // this.error("Failed:" + colorLibrary.color.message['item1'])
          this.toastService.showError(colorLibrary.color.message['item1']);
          this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
        }

      } else {
        this.isScrollDown = true;
        this.logger.error(colorLibrary.color.error.error.message, "ColorLibrary");
        this.getError(colorLibrary.color.error);
        this.showLoader1 = false;
      }
    })
  }

  /* To load data for list view */
  loadDataList() {
    this.loading = true;
    this.startEndListCount = this.startCountList + "-" + this.endCountList;
    this.store.dispatch(new colorActionTypes.LoadColorLibrary(this.startEndListCount, this.colorViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
      if (colorLibrary.color.error === "") {
        if (colorLibrary.color.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.colorLib.colorList, "ColorLibrary");
          this.fa.clear();
          this.totalRecordCount = colorLibrary.color.message['item3']
          this.colorData = colorLibrary.color.message['item1'];
          if (this.colorData.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.dbcolorData = colorLibrary.color.message['item1'];
          this.colorData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          var gridView = {
            data: this.colorData,
            total: colorLibrary.color.message['item3'],
          };
          this.gridData = gridView; //process(this.stylesData, this.state);
          this.loading = false;
          //this.gridData = process(this.colorData, this.state);
          this.getColorClassification("Colours");
          this.onSelect(this.selectedvalue, 'mycolor');
        } else {
          this.notFound = true;
          // this.error("Failed:" + colorLibrary.color.message['item1'])
          this.toastService.showError(colorLibrary.color.message['item1']);
          this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.logger.error(colorLibrary.color.error.error.message, "ColorLibrary");
        this.getError(colorLibrary.color.error);
      }
    })
  }

  /* To edit list view  */
  onEdit() {
    // store copy of original colors in case cancelled
    this.originalcolorData = this.colorViewMode === 'Shared' ? [...this.sharedGridData] : [...this.colorData];
    var gridView = {
      data: this.originalcolorData,
      total: this.colorViewMode === 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
    };
    this.gridData = gridView;    // reset / initialise form fields
    this.resetForm();

    // set all rows to edit mode to display form fields
    this.editGridRows();
    this.isEditMode = true;
  }

  /* To remove an item in list view */
  onRemove(index, dataItem) {
    console.log('dataItem',dataItem);
    Swal.fire({
      title: this.staticMsgs.colorLib.deleteColor,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.colorLib.deleteit
    }).then((result) => {
      console.log('result', result);
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(dataItem.id);

        // this.store.dispatch(new colorActionTypes.DeleteColorLibrary(deleterecordIds));
        // this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
        //   if (colorLibrary.color.error === "") {
        //     if (colorLibrary.color.message['item2'] === 'Success') {
        //       this.showLoader = false;
        //       this.success(this.staticMsgs.colorLib.colorDeleted1);
        //       this.logger.info(this.staticMsgs.colorLib.colorDeleted, "ColorLibrary");
        //       this.loadDataList();
        //     } else {
        //       this.showLoader = false;
        //       this.error("Failed:" + colorLibrary.color.message['item1'])
        //       this.loadDataList();
        //       this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
        //     }
        //   } else {
        //     this.getError(colorLibrary.color.error);
        //     this.showLoader = false;
        //   }

        // })
        // rows must all be closed while removing colorData
        // this.closeAllRows();
        if (this.colorViewMode === 'Shared') {
          let dataIndex = this.sharedGridData.indexOf(dataItem);
          // remove product and product form group
          let deleterecordIds=[];
          deleterecordIds.push(dataItem.id);
          this.store.dispatch(new colorActionTypes.DeleteColorLibrary(deleterecordIds));
          this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
            if (colorLibrary.color.error === "") {
              if (colorLibrary.color.message['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(this.staticMsgs.colorLib.colorDeleted1);
                this.toastService.showSuccess(this.staticMsgs.colorLib.colorDeleted1);
                this.logger.info(this.staticMsgs.colorLib.colorDeleted, "ColorLibrary");

                this.loadDataList();
              } else {
                this.showLoader = false;
                // this.error("Failed:" + colorLibrary.color.message['item1'])
                this.toastService.showError(colorLibrary.color.message['item1']);
                this.loadDataList();
                this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
              }
            } else {
              this.getError(colorLibrary.color.error);
              this.showLoader = false;
            }

          })
          this.sharedGridData = JSON.parse(JSON.stringify(this.sharedGridData));
          this.sharedGridData.splice(dataIndex, 1);
          this.fa.removeAt(dataIndex);

          this.filteredData = filterBy(this.sharedGridData, this.state.filter);
        } else {
          let dataIndex = this.colorData.indexOf(dataItem);
          // remove product and product form group
          let deleterecordIds=[];
          deleterecordIds.push(dataItem.id);
          this.store.dispatch(new colorActionTypes.DeleteColorLibrary(deleterecordIds));
          this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
            if (colorLibrary.color.error === "") {
              if (colorLibrary.color.message['item2'] === 'Success') {
                this.showLoader = false;
                // this.success(this.staticMsgs.colorLib.colorDeleted1);
                this.toastService.showSuccess(this.staticMsgs.colorLib.colorDeleted1);
                this.logger.info(this.staticMsgs.colorLib.colorDeleted, "ColorLibrary");

                this.loadDataList();
              } else {
                this.showLoader = false;
                // this.error("Failed:" + colorLibrary.color.message['item1'])
                this.toastService.showError(colorLibrary.color.message['item1']);
                this.loadDataList();
                this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
              }
            } else {
              this.getError(colorLibrary.color.error);
              this.showLoader = false;
            }

          })
          this.colorData = JSON.parse(JSON.stringify(this.colorData));
          this.colorData.splice(dataIndex, 1);
          this.fa.removeAt(dataIndex);

          this.filteredData = filterBy(this.colorData, this.state.filter);
        }
        this.closeAllRows();
        if (this.SearchData != null && this.SearchData != "") {

          this.searchListData(this.SearchData);
        }
        if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
        var gridView = {
          data: this.filteredData,
          total: this.totalRecordCount,
        };
        this.gridData = gridView; // process(this.filteredData, this.state);
        // reset all rows back to edit mode
       // this.editGridRows();
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });

  }

  /* To compare the last data & after delete data */
  jsonDeleteCompare(element, index, array) {

    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  /* To compare two JSON data */
  jsonCompare(element, index, array) {
    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      Object.keys(element).forEach(key => {

        if (element[key] != dataRow[0][key]) {
          diff++;

        }
      })
    }
    if (diff > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  /* To bulk update or delete of color in list view */
  onSave() {
    // mark all fields as touched to highlight any invalid fields
    this.colorDataForm.markAllAsTouched();

    for (let i = 0; i < this.colorDataForm.value.formArray.length; i++) {
      if (this.colorDataForm.controls.formArray['controls'][i].controls.name.value.length > 50) {
        this.colorDataForm.controls.formArray['controls'][i].controls.name.setErrors({ 'incorrect': true });
      }
    }
    // stop here if form is invalid
    if (this.colorDataForm.invalid) {
      this.showNote = true;
      // this.error(this.staticMsgs.colorLib.checkField)
      //alert('FORM INVALID :(');
      this.toastService.showError("Please enter all the mandatory fields.");
      return;
    }

    if (this.colorDataForm.dirty) {
      // copy form data to colors array on success
      if (this.colorViewMode === 'Shared') {
        this.sharedGridData = this.fa.value;
      } else {
        this.colorData = this.fa.value;
      }
      this.colorData = this.fa.value;
      this.closeAllRows();
      let updColorList = this.colorViewMode === 'Shared' ? this.sharedGridData.filter((x, i) => this.jsonCompare(x, i, this.originalcolorData)) : this.colorData.filter((x, i) => this.jsonCompare(x, i, this.originalcolorData));
      if (updColorList.length > 0) {
        this.showLoader = true;
        for (let i = 0; i < updColorList.length; i++) {
          updColorList[i].classification = this.aesdecryptservice.encryptData(this.dataService.Key, updColorList[i].classification),
            updColorList[i].name = this.aesdecryptservice.encryptData(this.dataService.Key, updColorList[i].name),
            updColorList[i].status = this.aesdecryptservice.encryptData(this.dataService.Key, updColorList[i].status),
            updColorList[i].hexcode = this.aesdecryptservice.encryptData(this.dataService.Key, updColorList[i].hexcode),
            updColorList[i].colorStandard = this.aesdecryptservice.encryptData(this.dataService.Key, updColorList[i].colorStandard)
        }
        this.store.dispatch(new colorActionTypes.UpdateColorLibrary(updColorList));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
          if (colorLibrary.color.error === "") {
            if (colorLibrary.color.message['item2'] === 'Success') {
              this.showLoader = false;
              this.showNote = false;
              // this.success(this.staticMsgs.colorLib.colorUpd1);
              this.toastService.showSuccess(this.staticMsgs.colorLib.colorUpd1);
              this.logger.info(this.staticMsgs.colorLib.colorUpd, "ColorLibrary");
              this.colorViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
            } else {
              this.colorViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              // this.error("Failed:" + colorLibrary.color.message['item1'])
              this.toastService.showError(colorLibrary.color.message['item1']);
              this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
              this.showLoader = false;
            }
          } else {
            this.logger.error(colorLibrary.color.error.error.message, "ColorLibrary");
            this.getError(colorLibrary.color.error);
            this.showLoader = false;
          }

        })
      }

      if (this.originalcolorData.length != (this.colorViewMode == 'Shared' ? this.sharedGridData.length : this.colorData.length)) {
        this.showLoader = true;
        let deleterecordIds = [];
        var DelColorList;
        if (this.colorViewMode === 'Shared') {
          DelColorList = this.originalcolorData.filter((x, i) => this.jsonDeleteCompare(x, i, this.sharedGridData));
        } else {
          DelColorList = this.originalcolorData.filter((x, i) => this.jsonDeleteCompare(x, i, this.colorData));
        }
        if (DelColorList.length > 0) {
          DelColorList.forEach((x, i) => {
            deleterecordIds.push(x.id);
          });
          this.store.dispatch(new colorActionTypes.DeleteColorLibrary(deleterecordIds));
          this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
            if (colorLibrary.color.error === "") {
              if (colorLibrary.color.message['item2'] === 'Success') {
                this.showLoader = false;
                this.showNote = false;
                // this.success(this.staticMsgs.colorLib.deletedSuccess);
                this.toastService.showSuccess(this.staticMsgs.colorLib.deletedSuccess);
                this.logger.info(this.staticMsgs.colorLib.colorDeleted, "ColorLibrary");
                this.colorViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              } else {
                this.showLoader = false;
                // this.error("Failed:" + colorLibrary.color.message['item1'])
                this.toastService.showError(colorLibrary.color.message['item1']);
                this.colorViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
                this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
              }
            } else {
              this.getError(colorLibrary.color.error);
              this.showLoader = false;
            }

          })
        }
      }
    }
    this.isEditMode = false;
    this.loading = false;
  }

  /* To cancel to reset the updated or deleted data in list view */
  onCancel() {
    this.closeAllRows();
    this.showNote = false;
    // reset colors back to original data (before edit was clicked)
    this.colorViewMode === 'Shared' ? this.sharedGridData : this.colorData = this.originalcolorData;
    var gridView = {
      data: this.colorViewMode === 'Shared' ? this.sharedGridData : this.colorData,
      total: this.colorViewMode === 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
    };
    this.gridData = gridView;

    this.isEditMode = false;
  }

  /* Set all rows to edit mode to display form fields */
  private editGridRows() {
    if (this.colorViewMode === 'Shared') {
      this.filteredData = filterBy(this.sharedGridData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        if (x.isEdit) {
          var colorRow = this.fa.controls[this.sharedGridData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, colorRow);
        }
      });
    } else {
      this.filteredData = filterBy(this.colorData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        if (x.isEdit) {
          var colorRow = this.fa.controls[this.colorData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, colorRow);
        }
      });
    }
  }

  /* Close all rows to display readonly view of data */
  private closeAllRows() {
    if (this.colorViewMode === 'Shared') {
      // close all rows to display readonly view of data
      this.sharedGridData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    } else if (this.colorViewMode === 'Created') {
      // close all rows to display readonly view of data
      this.colorData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    }
  }

  /* Clear form array and create a new form group for each product */
  private resetForm() {
    this.fa.clear();
    if (this.colorViewMode === 'Shared') {
      this.sharedGridData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
    } else {
      this.colorData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
    }
  }

  /* Create a new form group containing controls and validators for a product */
  private createFormGroup(color: any = {}) {
    return this.formBuilder.group({
      name: [color.name], status: [color.status],
      hexcode: [color.hexcode],

      colorStandard: [color.colorStandard],
      classification: [color.classification],
      id: [color.id],
      isEdit: [color.isEdit],
      isDelete: [color.isDelete],
    })
  }

  /* To filter the data by using status */

  onSelect(val: any, type: any) {
    this.selectedvalue=val;
    if (type === 'mycolor') {
      if (val == "") {
        this.cards = this.copyCard;
      } else {
        this.cards = this.copyCard.pipe(
          map(data => data.filter(res => res.status.toLowerCase() == val.toLowerCase()))
        )
      }
      var data = this.colorData.filter(x => x.status.toLowerCase() == val.toLowerCase())

      if (data.length > 0) {
        this.checkStatusData = false;
      } else {
        this.msg = (val.toLowerCase() === 'active') ? 'No active records available' : 'No inactive records available';
        this.checkStatusData = true;
      }

    } else {
      if (val == "") {
        this.sharedCards = this.sharedCopyCard;
      } else {
        this.sharedCards = this.sharedCopyCard.filter(res => res.status.toLowerCase() == val.toLowerCase())
      }
      if (this.sharedCards.length > 0) {
        this.checkSharedStatusData = false;
      } else {
        this.sharedMsg = (val.toLowerCase() === 'active') ? 'No active shared records available' : 'No inactive shared records available';
        this.checkSharedStatusData = true;
      }

    }
  }


  /* To display session expired msg as sweet alert */
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.colorLib.session,
      html: this.staticMsgs.colorLib.sessExp,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        //console.log('Session Timedout.')
        this.userservice.logout();
      }
    })
  }

  /* To navigate to color details to view color form */
  onEditColorDetails(id) {
    console.log(id);
    this.dataService.AddClientLog();
    this.storageservice.storeEditedColorId(id);
    this.storageservice.storeEditedColorDetailsId(id);
    this.router.navigate(['/colordetails']);
  }

  /* To delete a colour from card view */
  onDeleteColor(id) {
console.log(id,'id');
    Swal.fire({
      title: this.staticMsgs.colorLib.deleteColor,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.colorLib.deleteit
    }).then((result) => {

      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(id);

        this.store.dispatch(new colorActionTypes.DeleteColorLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
          if (colorLibrary.color.error === "") {
            if (colorLibrary.color.message['item2'] === 'Success') {
              this.showLoader = false;
              // this.success(this.staticMsgs.colorLib.colorDeleted1);
              this.toastService.showSuccess(this.staticMsgs.colorLib.colorDeleted1);
              this.logger.info(this.staticMsgs.colorLib.colorDeleted, "ColorLibrary");

              this.loadDataList();
            } else {
              this.showLoader = false;
              // this.error("Failed:" + colorLibrary.color.message['item1']);
              this.toastService.showError(colorLibrary.color.message['item1']);

              this.loadDataList();
              this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
            }
          } else {
            this.getError(colorLibrary.color.error);
            this.showLoader = false;
          }

        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  /* To add a color as favourite */
  AddToFavorite(e) {
    this.showLoader = true;
    let selected = e.target.id.replace("checkbox", "");
    if (selected != '' && e.target.checked == true) {
      let data: Favourite[] = [];
      data.push(new Favourite(selected));
      this.dataService.bulkAddFavourites(data, "Colours").subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;

            // this.success(this.staticMsgs.colorLib.addedSuccess1);
            this.toastService.showSuccess(this.staticMsgs.colorLib.addedSuccess1);
            this.logger.info(this.staticMsgs.colorLib.addedSuccess, "ColorLibrary");
          }
          else {
            console.log(response)
            //alert(response.body['item2'])
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "ColorLibrary");
            this.showLoader = false;
          }
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, "ColorLibrary");
        this.showLoader = false;
      });
    }
    else if (selected != '' && e.target.checked == false) {
      let data: string[] = [];
      data.push(selected);
      this.dataService.bulkDeleteFavourites(data, "Colours").subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            // this.success(this.staticMsgs.colorLib.removed1);
            this.toastService.showSuccess(this.staticMsgs.colorLib.removed1);
            this.logger.info(this.staticMsgs.colorLib.removed, "ColorLibrary");
          }
          else {
            console.log(response)
            //alert(response.body['item2'])
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "ColorLibrary");
            this.showLoader = false;
          }
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, "ColorLibrary");
        this.showLoader = false;
      });
    }
  }

  /* To search the color data in list view */
  searchListData(val: string) {
    if (this.colorViewMode === 'Shared') {
      if (val !== "") {
        let arr = [];
        this.filterQuery = val;
        this.filteredData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) { return data; }
        });
      }
      else {
        this.filteredData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
      if (val !== "") {
        let arr = [];
        this.filterQuery = val;
        this.filteredData = this.colorData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) { return data; }
        });
      }
      else {
        this.filteredData = this.colorData;
        this.state.take = this.paginationEndcount;;
        //return
      }
    }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    //this.colorData = this.filteredData;

    var gridView = {
      data: this.filteredData,
      total: this.colorViewMode == 'Shared' ? this.totalSharedRecordCount : this.totalRecordCount,
    };
    this.gridData = gridView; // = process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(this.filteredData, this.state.filter);

  }

  /* To navigate to color details with data filled in color form */
  onEditColor(id) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditedColorId(id);
    this.router.navigate(['/colordetails']);
  }

   /* To load Dropdowns */
   loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Color Standard' });
    });
  }

  /* To edit particular row in list view */
  editClick(rowIndex, dataItem) {
    console.log(dataItem,'dataitem');
    this.dataService.AddClientLog();
    this.storageservice.storeEditedColorId(dataItem.id);
    this.storageservice.storeEditedColorDetailsId(dataItem.id);
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.loadSelectPicker();
    const dialogRef = this.dialog.open(CustomColorComponent,{
      disableClose:true
    });
    dialogRef.afterClosed().subscribe(result=>{
    this.loadDataList();
    })
  }


  /* To edit particular row in list view */
  dblClick(e) {
    if (this.clickedRowItem.dataItem.isEdit) {
      this.isEditMode = true;
      this.closeAllRows();
      let dataindx
      if (this.colorViewMode === 'Shared') {
        // store copy of original documents in case cancelled
        this.originalcolorData = [...this.sharedGridData];

        // set single row to edit mode to display form fields
        dataindx = this.sharedGridData.indexOf(this.clickedRowItem.dataItem);
      } else {
        // store copy of original documents in case cancelled
        this.originalcolorData = [...this.colorData];

        // set single row to edit mode to display form fields
        dataindx = this.colorData.indexOf(this.clickedRowItem.dataItem);
      }

      this.originalcolorData = [...this.colorData];

      // set single row to edit mode to display form fields

      var colorRow = this.fa.controls[dataindx] as FormGroup;
      this.grid.editRow(this.clickedRowItem.rowIndex, colorRow);
    }
    else {
      alert(this.staticMsgs.colorLib.noPermission);

    }

  }

  /* To display success msg as sweet alert */
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  /* To display error msg as sweet alert */
  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.colorLib.oops,
      text: message
    })
  }

  get f1() { return this.advForm.controls; }

  /* Load color data by taking inputs from advanced search */
  onSubmit(value) {
    this.showLoader = true;
    let createdFromChildStr = this.createdStartDate;
    let createdToChildStr = this.createdEndDate;
    let modifiedFromChildStr = this.modifiedStartDate;
    let modifiedToChildStr = this.modifiedEndDate;

    if ((createdFromChildStr !== null && createdToChildStr == null) && (createdFromChildStr !== undefined && createdToChildStr == undefined)) {
      // this.error(this.staticMsgs.colorLib.createToMand);
      this.toastService.showError(this.staticMsgs.colorLib.createToMand);
      this.logger.error(this.staticMsgs.colorLib.createToMand, "ColorLibrary");
      this.showLoader = false;
      return;
    }
    else if ((createdFromChildStr == null && createdToChildStr !== null) && (createdFromChildStr == undefined && createdToChildStr !== undefined)) {
      // this.error(this.staticMsgs.colorLib.createFromMand);
      this.toastService.showError(this.staticMsgs.colorLib.createFromMand);
      this.logger.error(this.staticMsgs.colorLib.createFromMand, "ColorLibrary");
      this.showLoader = false;
      return;
    }
    if ((modifiedFromChildStr !== null && modifiedToChildStr == null) && (modifiedFromChildStr !== undefined && modifiedToChildStr == undefined)) {
      // this.error(this.staticMsgs.colorLib.modifiedTo);
      this.toastService.showError(this.staticMsgs.colorLib.modifiedTo);
      this.logger.error(this.staticMsgs.colorLib.modifiedTo, "ColorLibrary");
      this.showLoader = false;
      return;
    }
    else if ((modifiedFromChildStr == null && modifiedToChildStr !== null) && (modifiedFromChildStr == undefined && modifiedToChildStr !== undefined)) {
      // this.error(this.staticMsgs.colorLib.modifiedFrom);
      this.toastService.showError(this.staticMsgs.colorLib.modifiedFrom);
      this.logger.error(this.staticMsgs.colorLib.modifiedFrom, "ColorLibrary");
      this.showLoader = false;
      return;
    }

    let adv_data = {
      keyword: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.keyword.value),
      createdBy: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.createdBy.value),
      classification: this.aesdecryptservice.encryptData(this.dataService.Key, this.colorclassificationValue),
      status: this.f1.status.value,
      createdFrom: createdFromChildStr,
      createdTo: createdToChildStr,
      modifiedFrom: modifiedFromChildStr,
      modifiedTo: modifiedToChildStr
    }
    //adv_data = this.aesdecryptservice.encryptData(this.dataService.Key,adv_data)
    //console.log(JSON.stringify(adv_data));
    this.store.dispatch(new commonActionTypes.LoadAdvancedColorSearch(adv_data));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((advancedSearchData) => {
      if (advancedSearchData.common.error === "") {
        if (advancedSearchData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.colorLib.advColorUpd, "ColorLibrary");
          this.cards = this.store.pipe(select(commonReducer.getCommon));
          this.colorData = advancedSearchData.common.message['item1'];
          var gridView = {
            data: this.colorData,
            total: advancedSearchData.common.message['item3'],
          };
          this.gridData = gridView; // = process(this.colorData, this.state);
          this.isResetMode = true;
          this.showLoader = false;

        } else {
          if (advancedSearchData.common.message['item1'].toLowerCase().includes("no records")) {
            // this.error(this.staticMsgs.colorLib.nodata);
            this.toastService.showError(this.staticMsgs.colorLib.nodata);
            this.logger.error(this.staticMsgs.colorLib.nodata, "ColorLibrary");
            this.colorData = [];
            this.cards = of([]);
            this.copyCard = of([]);
            var gridView = {
              data: this.colorData,
              total: advancedSearchData.common.message['item3'],
            };
            this.showLoader = false;
            this.gridData = gridView; // = process(this.colorData, this.state);
          } else {
            // this.error(advancedSearchData.common.message['item1'] + '!')
            this.toastService.showError(advancedSearchData.common.message['item1']);
            this.logger.error(advancedSearchData.common.message['item1'], "ColorLibrary");
            this.showLoader = false;
            this.clearSearch();
          }
        }
      } else {
        this.getError(advancedSearchData.common.error);
        this.showLoader = false;
      }
    })
    /* this.dataService.getAdvanceSearchColor(adv_data).subscribe(response => {
      let res = response.body;
      let data1 = [];
      if (response.status === 200) {
        if (res.item2.toLowerCase().includes("success")) {
          this.logger.info("Advance search color data loaded successfuly", "ColorLibrary");
          this.cards = res.item1;
          for (let i = 0; i <= this.cards.length; i++) {
            this.cards[i]['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, this.cards[i]['name']);
            this.cards[i]['classification'] = this.aesdecryptservice.decryptData(this.dataService.Key, this.cards[i]['classification']);
            if (this.cards[i]['hexcode']) {
              this.cards[i]['hexcode'] = this.aesdecryptservice.decryptData(this.dataService.Key, this.cards[i]['hexcode']);

            } else {
              this.cards[i]['thumbnailFiles']['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, this.cards[i]['thumbnailFiles']['thumbnail']);

            }
            this.cards[i]['createdBy'].firstName = this.aesdecryptservice.decryptData(this.dataService.Key, this.cards[i]['createdBy'].firstName);
            this.cards[i]['createdBy'].lastName = this.aesdecryptservice.decryptData(this.dataService.Key, this.cards[i]['createdBy'].lastName);

            // response.body['item1'][i]['name']= this.aesdecryptservice.decryptData(this.dataService.Key,response.body['item1'][i]['name']);
          }
          this.colorData = this.cards;
          var gridView = {
            data: this.colorData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView // = process(this.colorData, this.state);
          this.isResetMode = true;
        }
        else {
          if (res.item1.toLowerCase().includes("no records")) {
            this.error("No data Available!");
            this.logger.error("No data Available", "ColorLibrary");
            this.colorData = data1;

            this.cards = data1; this.copyCard = data1;
            var gridView = {
              data: this.colorData,
              total: this.totalRecordCount,
            };
            this.gridData = gridView // = process(this.colorData, this.state);
          } else {
            this.error(res.item1 + "!");
            this.logger.error(res.item1, "ColorLibrary");
            this.clearSearch();
          }
        }
      }

      this.showLoader = false;
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
      }
      this.showLoader = false;
    }); */
  }

  /* If the data is too long then the complete data will be shown in tooltip  */
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    if ((element.nodeName === 'TH') && (element.offsetWidth < element.scrollWidth)) {
      this.tooltipDir.toggle(element);
    }
    else if ((element.offsetWidth < element.scrollWidth) && (element.scrollWidth! < 937) && (this.isEditMode == false)) {
      this.tooltipDir.toggle(element);
    }
    else {
      this.tooltipDir.hide();
    }
  }


  /* setDate(event: any) {
    console.log(event.target.value);
    if (event.target.getAttribute("formControlName") == "createdFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      this.createdToChild.nativeElement.value = createdToChildStr;
    }
    else if (event.target.getAttribute("formControlName") == "modifiedFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      this.modifiedToChild.nativeElement.value = createdToChildStr;
    }
  }; */

  /* To clear the fields in advanced search */
  clearFields() {
    this.createdStartDate = "";
    this.createdEndDate = "";
    this.createdFromDate = "";
    this.createdToDate = "";
    this.modifiedFromDate = "";
    this.modifiedToDate = "";
    this.modifiedStartDate = "";
    this.modifiedEndDate = "";
    this.f1.keyword.patchValue("");
    this.f1.createdBy.patchValue("");
    this.f1.status.patchValue("");
    $("#statuslist").val('default').selectpicker("refresh");
    this.getClassificationData(); this.colorclassificationValue = "";
    this.enableCreatFromVal = null;
    this.enableCreatToVal = null;
    this.enableModifFromVal = null;
    this.enableModifToVal = null;
    this.enableKeywordVal = null;
    this.enableStatusVal = null;
    this.enableCreatedByVal = null;
    this.colorclassificationValue = "";
  }

  /* To clear the particular field in advanced search */
  clearfield(name) {
    switch (name) {
      case "createdFromChild": {
        this.createdStartDate = "";
        this.createdEndDate = "";
        this.createdFromDate = "";
        this.createdToDate = "";
        this.enableCreatFromVal = null;
        this.enableCreatToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "modifiedFromChild": {
        this.modifiedFromDate = "";
        this.modifiedToDate = "";
        this.modifiedStartDate = "";
        this.modifiedEndDate = "";
        this.enableModifFromVal = null;
        this.enableModifToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "keyword": { this.f1.keyword.patchValue(""); this.enableKeywordVal = null; this.checkAllFieldVal(); break; }
      case "createdBy": { this.f1.createdBy.patchValue(""); this.enableCreatedByVal = null; this.checkAllFieldVal(); break; }
      case "classification": { this.getClassificationData(); this.colorclassificationValue = ""; this.checkAllFieldVal(); break; }
      case "status": { this.f1.status.patchValue(""); $("#statuslist").val('default').selectpicker("refresh"); this.enableStatusVal = null; this.checkAllFieldVal(); break; }
    }
  }

  /* To check whether the search fields are empty or not before clearing the fields */
  checkAllFieldVal() {
    if ((this.f1.keyword.value == "") && (this.createdFromDate == "") && (this.modifiedFromDate == "") && (this.f1.createdBy.value == "") && ((this.f1.status.value == "") || (this.f1.status.value == undefined)) && (this.colorclassificationValue == "")) {
      this.clearSearch()
    }
  }

  /* Assigning the field values to global variable for search  */
  checkFieldVal(val: string[], val1: string) {
    switch (val1) {
      case "createdFromChild": { this.enableCreatFromVal = val; break; }
      case "createdToChild": { this.enableCreatToVal = val; break; }
      case "modifiedFromChild": { this.enableModifFromVal = val; break; }
      case "modifiedToChild": { this.enableModifToVal = val; break; }
      case "keyword": { this.enableKeywordVal = val; break; }
      case "createdBy": { this.enableCreatedByVal = val; break; }
      case "status": { this.enableStatusVal = val; break; }
    }
  }

  /* To get the classification data related to color */
  getColorClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.colorClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.logger.info(this.staticMsgs.colorLib.colorClass, "ColorLibrary");
          this.getClassificationData();
          this.showLoader = false;
        } else {
          this.getClassificationData();
          this.showLoader = false;
          // this.error("Failed:" + classificationData.common.message['item1'])
          this.toastService.showError(classificationData.common.message['item1']);
          this.logger.error(classificationData.common.message['item1'], "ColorLibrary");
        }
      } else {
        this.getClassificationData();
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })
    /*  this.dataService.getClassification(module).subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.colorClassificationData = response.body['item1'][0]["items"];
        this.logger.info("Color classification loaded successfuly", "ColorLibrary");
        //console.log(JSON.stringify(this.colorClassificationData ))
        this.getClassificationData();
        this.showLoader = false;
      }
      else {
        this.getClassificationData();
        this.showLoader = false;
      }
    }, err => {
      this.logger.error(err.error.message, "ColorLibrary");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.logger.error(err.message, "ColorLibrary");
        this.error(err.message);
      }
      this.showLoader = false;
    }); */
  }

  /* To assign the dropdown values of color classification */
  getClassificationData() {
    var data = this.colorClassificationData;
    var self = this;
    $(document).ready(function () {
      $("#colorclassification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        dataSource: data,
        filter: "startswith",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          self.colorclassificationValue = value["text"];
        }
      });
    });
  }

  /* When a date change event occurs in filter datechange method is triggered */
  dateChange(date: any, type: any) {
    switch (type) {
      case 'createdFromDate':
        this.createdStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdFromDate && this.createdFromDate !== undefined) {
          this.checkFieldVal(['val'], 'createdFromChild');
        }
        break;
      case 'createdToDate':
        this.createdEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdToDate && this.createdToDate !== undefined) {
          this.checkFieldVal(['val'], 'createdToChild')
        }
        break;
      case 'modifiedFromDate':
        this.modifiedStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedFromDate && this.modifiedFromDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedFromChild')
        }
        break;
      case 'modifiedToDate':
        this.modifiedEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedToDate && this.modifiedToDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedToChild')
        }
        break;
    }
  }

  onChange(event) {
    this.file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", this.file, this.file.name);
    this.showLoader = true;
    this.dataService.uploadColors(formData).subscribe(data => {
      console.log(data);
      if (data.body['item2'] == 'Success') {
        this.loadData();
        // this.success(this.staticMsgs.colorLib.colorUploaded)
        this.toastService.showSuccess(this.staticMsgs.colorLib.colorUploaded);

      }else{
        // this.error(data.body['item1']);
        this.toastService.showError(data.body['item1']);
      }
      this.showLoader = false;
    }, err => {
      // this.error(err.common.message);
      this.toastService.showError(err.common.message);
      this.showLoader = false;
    });
  }

  downloadTemplate() {
    window.open('../../../../assets/excels/SampleTemplate_Color.xlsx', '_blank');
  }

  uploadFile() {
    let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
    element.click();
  }

}
