import { Component, Input, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { DataService } from '../../../services/data.service';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { LogService } from '../../../shared/log.service';
import Swal from 'sweetalert2';
import { UserService } from '../../../helpers/user.service';
import { CalSubTotal } from '../../../models/color';
import { StorageService } from '../../../helpers/storage.service';
import { process, filterBy, orderBy, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StyledetailsComponent } from '../styledetails/styledetails.component';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../services/shared.service';
import { Router } from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
declare var $: any;

@Component({
  selector: 'app-construction-operation',
  templateUrl: './construction-operation.component.html',
  styleUrls: ['./construction-operation.component.css']
})
export class ConstructionOperationComponent implements OnInit {
  showLoader: boolean;
  operationform: FormGroup;
  editData: any;
  submitted = false;
  appStaticMsgs: any;
  constData: any = [];
  showConstructionNote = false;
  images: any;
  fileData: Array<any> = [];
  operation: any = [];
  public isConNew: boolean;
  public filteredData: any[];
  ConstructionData: any;
  selected: any;
  filteredXcelData: any[];
  styleViewMode = "Created";
  sharedGridData: any[] = [];
  totalSharedRecordCount: any;
  showFileInput: boolean = true;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  public constructiongridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  imgload = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn: any = [];
  showImage = false;
  modalReference: any;
  operationload = true;
  inputValue: number = 0;

  public constructionstartCountList = 0;
  public constructionendCountList = 10;
  public constructionstartEndCount = "";
  public constructionisPageChanged = false;
  public constructionRecordCount: any;
  public constructionloading: boolean;
  isThumbnailAdded: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<StyledetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private userservice: UserService,
    private storageservice: StorageService,
    private sharedService: SharedService,
    private router: Router,
    private toastService: ToastService,
    private modalService: NgbModal) {
    this.sharedService.getOperationData().subscribe(x => {
      this.editData = x;
    })
  }

  get f1() { return this.operationform.controls; }
  get fa() { return this.f1.formArray as FormArray; }

  ngOnInit(): void {
    console.log(this.data, 'data')
    this.appStaticMsgs = appStaticMsg.data[0];
    if (this.data?.isOperationedit == false) {
      this.operationform = new FormGroup({
        'operation': new FormControl('', Validators.required),
        'mC_Type': new FormControl('', Validators.required),
        'smv': new FormControl('', Validators.required),
        'imageid': new FormControl(''),
        'scid': new FormControl(''),
      });
    }
    else if (this.data?.isOperationedit == true) {
      this.operationform = new FormGroup({
        'operation': new FormControl(this.data.operationData.operation, Validators.required),
        'mC_Type': new FormControl(this.data.operationData.mC_Type, Validators.required),
        'smv': new FormControl(this.data.operationData.smv, Validators.required),
        'imageid': new FormControl(this.data.operationData.imageID),
        'scid': new FormControl(this.data.operationData.id),
      });
      this.showFileInput = false;
    }
    this.getAllSeams();

  }

  initSlectPicker(key) {
    $(document).ready(function () {
      $('#' + key).selectpicker();
    });
    this.operationload = false;
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessgetcolorwaysionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })

  }

  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    )
  }

  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove', 'selectAll');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Image Type' }, 'deselectAll');
    });
  }

  Modalopen(content) {
    console.log(content,'content')
    this.isThumbnailAdded = false;
    let ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    };

    this.modalReference = this.modalService.open(content, ngbModalOptions);
    console.log(this.modalReference,'this.modalRefe')
  }

  onChangeConstructionImg(event) {
    console.log(event.target.files,'onchange')
    if(event.target.files[0].type=='image/jpeg' || event.target.files[0].type=='image/png'){
      this.imgload = true;
      this.fileData = event.target.files;
      this.imageChangedEvent = event;
      this.isThumbnailAdded = true;
      this.fileToReturn='';
    }
  else if(event.target.files[0].type=='application/pdf' || event.target.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
    this.imgload = true;
    this.fileData = event.target.files;
    this.isThumbnailAdded = true;
    this.fileToReturn='';
    this.modalReference.close();
  }
  else{
    // this.error("File Not Supported.")
    this.toastService.showError("File Not Supported.");
    this.fileUploader.nativeElement.value = null;
    this.modalReference.close();

  }

  }

  imageCropped(event: any) {
    console.log(event,'imageevent')
    this.imgload = false;
    this.croppedImage = event.base64;
    console.log(event);
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
    console.log(this.fileToReturn, 'filetoreturn');
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    } return new File([u8arr], filename, { type: mime });
  }

  removeThumbnailConstructionSection(event) {
    console.log(event)
    this.images = '';
    this.croppedImage = [];
    this.fileToReturn='';
    this.data.operationData.image = [];
    this.showImage = false;
    this.showFileInput = true;

  }

  confirmOperation() {
    this.showImage = true;
    this.showFileInput = false;
    this.modalReference.close();
  }

  closeOperation(event){
    this.croppedImage = [];
    this.fileToReturn='';
    this.showImage = false;
    this.showFileInput = true;
    this.fileUploader.nativeElement.value = null;
    this.modalReference.close();
  }

  async onOperationSubmit() {
    if (this.operationform.controls.operation.value.length > 100) {
      this.operationform.controls.operation.setErrors({ 'incorrect': true });
    }
    if (this.operationform.controls.mC_Type.value.length > 100) {
      this.operationform.controls.mC_Type.setErrors({ 'incorrect': true });
    }
    if (this.operationform.controls.smv.value.length > 100) {
      this.operationform.controls.smv.setErrors({ 'incorrect': true });
    }

    if (this.operationform.invalid) {
      this.showConstructionNote = true;
      // this.error(this.appStaticMsgs.common.pleasechecktheinvalidfields)
      this.toastService.showError("Please enter all the mandatory fields.");
      return;
    }
    this.operationload = true;
    //this.closeEditor(sender,rowIndex);
    this.operation = this.operationform.value;
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('sccount', '1');
    if (this.data.isOperationedit == false) {
      formData.append('sctag', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add'));
      this.images = '';
    } else {
      formData.append('scid', this.operation['scid']);
      formData.append('sctag', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update'));
      if(this.images==undefined){
        this.images = this.data.operationData.image
      }
    }
    formData.append('operation', this.aesdecryptservice.encryptData(this.dataservice.Key, this.operation['operation']));
    formData.append('mctype', this.aesdecryptservice.encryptData(this.dataservice.Key, this.operation['mC_Type']));
    formData.append('smv', this.aesdecryptservice.encryptData(this.dataservice.Key, this.operation['smv']));

    if (!this.images) {
      if (this.fileData.length != 0) {
        for (let f of this.fileData) {
          if (JSON.stringify(f) != '[]') {
            let fileUploadedData = await this.uploadFile(f);
            if (fileUploadedData != "error") {
              formData.append('imageid', JSON.parse(fileUploadedData)["Key"]);
            }
            else {
              // this.error(fileUploadedData);
              this.toastService.showError(fileUploadedData);
              this.submitted = false;
              this.showLoader = false;
              return false;
            }
          }
        }
      }
      else
        formData.append('imageid', '')
    }
    // }

    this.operationload = true;
    this.dataservice.addupdatedeletestyleConstructions(formData).subscribe(async response => {
      this.showConstructionNote = false;
      this.isConNew = false;
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader = false;
          this.operationload = false;
          // this.success(this.appStaticMsgs.styledetails.constructionsavesuccessfully);

          this.logger.info(this.appStaticMsgs.styledetails.constructionsavesuccessfully, "Style Constructions");
          if(this.data.isOperationedit == false){
            this.toastService.showSuccess(this.appStaticMsgs.styledetails.constructionsavesuccessfully);
          }
          else{
            this.toastService.showSuccess('Operation Updated Successfully');
          }
          
          this.fileData = [];
          this.dialogRef.close();
          await this.getConstructionbyId();
          //this.constructionForm = undefined;
        }
        else {
          this.logger.error(response.body['item1'], "Style Constructions");
          // this.error(response.body['item1']);
          this.toastService.showError(response.body['item1']);
          this.showLoader = false;
          this.operationload = false;
        }
      }
    }, err => {
      this.showConstructionNote = false;
      this.logger.error(err.error.message, "Style Constructions");
      this.toastService.showError(err.error.message);
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
      }
      else {
        this.logger.error(err.error.message, "Style Constructions");
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.showLoader = false;
      this.operationload = false;
    });
  }

  public onConstructionChange(state: DataStateChangeEvent) {
    let cDat = [];
    if (this.selected?.length > 0) {
      cDat = this.selected;
    } else {
      cDat = this.filteredData;
    }
    this.constructiongridState = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      cDat = this.filteredXcelData;
      var gridView = {
        data: cDat,
        total: this.constructionRecordCount,
      };
      this.ConstructionData = process(gridView.data, this.constructiongridState)
      this.ConstructionData.total = gridView.total; //process(this.filteredData, this.state);
    }
    else {
      var gridView = {
        data: this.styleViewMode === 'Shared' ? this.sharedGridData : this.filteredData,
        total: this.styleViewMode === 'Shared' ? this.totalSharedRecordCount : this.constructionRecordCount,
      };
      this.ConstructionData = process(gridView.data, this.constructiongridState)
      this.ConstructionData.total = gridView.total; // process(this.stylesData, this.state);
    }

    /* this.constructiongridState = state;
    var gridView = {
      data: this.filteredData,
      total: this.constructionRecordCount,
    };
    this.ConstructionData = gridView; */
    //process(this.filteredData, this.gridState);
  }

  public async getConstructionbyId() {
    this.constructionloading = true;
    this.constructionstartEndCount = this.constructionstartCountList + "-" + this.constructionendCountList;
    this.dataservice.getstyleConstruction(this.storageservice.getEditedStyleId(), this.constructionstartEndCount).subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.logger.info(this.appStaticMsgs.styledetails.styleconstructiondataloadedsuccessfully, "Style Construction");
        this.constructionloading = false;
        this.filteredData = response.body['item1']['styleConstructions'];
        this.constructionRecordCount = response.body['item3'];
        if (response.body['item1']['styleConstructions']) {
          for (let i = 0; i < this.filteredData.length; i++) {
            this.filteredData[i].operation = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].operation);
            this.filteredData[i].mC_Type = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].mC_Type);
            if (this.filteredData[i].image) {
              this.filteredData[i].image.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].image.thumbnail);
            }
            this.filteredData[i].smv = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].smv);
          }

          var gridView = {
            data: this.filteredData,
            total: this.constructionRecordCount,
          };
          this.ConstructionData = gridView;
        }
        else {
          this.ConstructionData = null;
        }
        //this.constructionForm = undefined;
      }
      else {
        this.constructionloading = false;
        this.logger.error(response.body['item1'], "Style Construction");

        // this.error(response.body['item1']);
        this.toastService.showError(response.body['item1']);
      }
    }, err => {
      this.logger.error(err.error.message, "Style Construction");
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.constructionloading = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
        this.constructionloading = false;
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        this.constructionloading = false;
      }
      else {
        this.logger.error(err.error.message, "Style Construction");
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.constructionloading = false;
    });
  }


  async uploadFile(file): Promise<any> {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          //console.log('There was an error uploading your file: ', err);
          reject("error");
        }
        //console.log('Successfully uploaded file.', data);
        resolve(JSON.stringify(data));
      });
    });
  }

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getAllSeams() {
    this.dataservice.getSeams('0-100', 'Created').subscribe(res => {
      if (res.body['item2'] == 'Success') {
        let data: any = res.body['item1'];
        data.forEach(x => {
          if (x.name) {
            x['id'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.name);
            x['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.name);
          } else {
            x['id'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.sequence);
            x['name'] = this.aesdecryptservice.decryptData(this.dataservice.Key, x.sequence);
          }
        });
        this.constData = data;
        console.log(this.constData, 'constdata');
        setTimeout(() => {
          this.initSlectPicker('smv');
          $("#smv").attr('data-live-search', true);
        }, 5000);
      }
    }, err => {

    });
  }

  close() {
    this.dialogRef.close();
  }
}
