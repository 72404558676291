import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as imageActions from "../actions/image.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";

@Injectable()

export class ImageEffect {
    thumbnail = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }

    getAllImageDetails(data) {
        data.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.name)
        data.classification = this.aesdecryptservice.decryptData(this.dataService.Key, data.classification)
        data.baseClassification = this.aesdecryptservice.decryptData(this.dataService.Key, data.baseClassification)
        data.reportStatus = this.aesdecryptservice.decryptData(this.dataService.Key, data.reportStatus)
        data.status = this.aesdecryptservice.decryptData(this.dataService.Key, data.status)
        data.labLocation = this.aesdecryptservice.decryptData(this.dataService.Key, data.labLocation)
        data.description = this.aesdecryptservice.decryptData(this.dataService.Key, data.description)
        data.notes = this.aesdecryptservice.decryptData(this.dataService.Key, data.notes)
        data.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, data.sequence)
        if (data.createdBy) {
            data.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.firstName);
            data.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.lastName);
        }
        if (data.files) {
            data.files.forEach(x => {
                x.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnail)
                x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name)
                x.fileType = this.aesdecryptservice.decryptData(this.dataService.Key, x.fileType);
            });
        }
    }

    @Effect()
    loadImageLibrary: Observable<Action> = this.actions.pipe(
        ofType<imageActions.LoadImageLibrary>(
            imageActions.ImageActionTypes.LOAD_IMAGE_LIBRARY
        ), mergeMap((action: imageActions.LoadImageLibrary) =>
            this.dataService.getDocument(action.payload1, action.payload2).pipe(
                map((images: any[]) => {
                    if (images['body']['item1'].length > 0 && !images['body']['item2'].toLowerCase().includes('error')) {
                        images['body']['item1'].forEach(x => {
                            x.baseClassification = this.aesdecryptservice.decryptData(this.dataService.Key, x.baseClassification);
                            x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.org = this.aesdecryptservice.decryptData(this.dataService.Key, x.org);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                            x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
                            x.CreatedbyName= x.createdBy.firstName;
                            x.createdBy.email = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.email);
                            x.createdBy.imageUrl = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.imageUrl);
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            if (x.thumbnailFiles) {
                                x.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles.thumbnail);
                            }
                        });
                    }
                    return new imageActions.LoadImageLibrarySuccess(images['body']['item1'], images['body'])
                }
                ),
                catchError(err => of(new imageActions.LoadImageLibraryFail(err)))
            )
        )
    );
    @Effect()
    loadImage: Observable<Action> = this.actions.pipe(
        ofType<imageActions.LoadImage>(
            imageActions.ImageActionTypes.LOAD_IMAGE
        ), mergeMap((action: imageActions.LoadImage) =>
            this.dataService.getDocumentById(action.payload).pipe(
                map((image: any) => {
                    if (!image['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllImageDetails(image['body']['item1']);
                    }
                    return new imageActions.LoadImageSuccess(image['body']['item1'], image['body'])
                }
                ),
                catchError(err => of(new imageActions.LoadImageFail(err)))
            )
        )
    );
    @Effect()
    createImage: Observable<any> = this.actions.pipe(
        ofType<imageActions.CreateImage>(
            imageActions.ImageActionTypes.CREATE_IMAGE
        ), map((action: imageActions.CreateImage) => action.payload),
        mergeMap((image: any) =>
            this.dataService.createImageDocument(image).pipe(
                map((newImage: any) => {
                    if (!newImage['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllImageDetails(newImage['body']['item1']);
                    }
                    return new imageActions.CreateImageSuccess(newImage['body']['item1'], newImage['body'])
                }),
                catchError(err => of(new imageActions.CreateImageFail(err)))
            )
        )
    );

    @Effect()
    updateImage: Observable<any> = this.actions.pipe(
        ofType<imageActions.UpdateImage>(
            imageActions.ImageActionTypes.UPDATE_IMAGE
        ), map((action: imageActions.UpdateImage) => action.payload),
        mergeMap((image: any) =>
            this.dataService.createImageDocument(image).pipe(
                map((updatedImage: any) =>
                    new imageActions.UpdateImageSuccess({
                        id: updatedImage['body']['item1'].id,
                        changes: updatedImage['body']['item1']
                    }, updatedImage['body'])
                ),
                catchError(err => of(new imageActions.UpdateImageFail(err)))
            )
        )
    );

    @Effect()
    updateImageLibrary: Observable<any> = this.actions.pipe(
        ofType<imageActions.UpdateImageLibrary>(
            imageActions.ImageActionTypes.UPDATE_IMAGE_LIBRARY
        ), map((action: imageActions.UpdateImageLibrary) => action.payload),
        mergeMap((imagesData: any[]) =>
            this.dataService.bulkUpdateDocument(imagesData).pipe(
                map((updatedColor: any) =>
                    new imageActions.UpdateImageLibrarySuccess([{
                        id: updatedColor['body']['item1'].id,
                        changes: updatedColor['body']['item1']
                    }], updatedColor['body'])
                ),
                catchError(err => of(new imageActions.UpdateImageLibraryFail(err)))
            )
        )
    );

    @Effect()
    deleteImageLibrary: Observable<any> = this.actions.pipe(
        ofType<imageActions.DeleteImageLibrary>(
            imageActions.ImageActionTypes.DELETE_IMAGE_LIBRARY
        ), map((action: imageActions.DeleteImageLibrary) => action.payload),
        mergeMap((imageIds: any[]) =>
            this.dataService.bulkDeleteDocument(imageIds).pipe(
                map((deletedImage: any) =>
                    new imageActions.DeleteImageLibrarySuccess(imageIds, deletedImage['body'])
                ),
                catchError(err => of(new imageActions.DeleteImageLibraryFail(err)))
            )
        )
    );
}