<div>
  <button class="close" mat-button (click)="close()">
    <span aria-hidden="true">×</span>
  </button>

</div>

<mat-dialog-content class="mat-typography" *ngIf="!data">
  <div id="tab-pane">
    <div *ngIf="imageType">
      <img [src]="thumbnailData" style="width:100%;height:100%"  /> <br />
    </div>

    <div *ngIf="!imageType">
      <img src="assets/images/doccard/document.png"  />
    </div>

    <a (click)="downloadFile()">
      <span class="mr-2"><i class="fas fa-download"></i></span><span>{{uploadedFileName}}</span>
    </a>
  </div>
</mat-dialog-content>


<mat-dialog-content class="mat-typography" *ngIf="data">
  <div id="tab-pane">
    <form [formGroup]="patternFileForm">
      <input type="file" formControlName="patternFile" (change)="onSelectFile($event)" class="upload-input"
        name="file-input" id="file-input">
      <label class="upload-input-label" for="file-input">
        <span>Select File</span>
      </label>

      <div *ngIf="!(isSelectedImg && isImgLoaded)">
        <div *ngIf="imageType">
          <img [src]="thumbnailData"  />
        </div>

        <div *ngIf="!imageType">
          <img src="assets/images/doccard/document.png"  />
        </div>
      </div>

      <div *ngIf="isSelectedImg && isImgLoaded">
        <div *ngIf="imageType">
          <img [src]="url"  /> <br />
        </div>

        <div *ngIf="!imageType">
          <img src="assets/images/doccard/document.png"  />
        </div>

      </div>



    </form>
    <!-- <a (click)="downloadFile()"> -->
      <!-- <span class="mr-2"><i class="fas fa-download"></i></span> -->
      <span>{{uploadedFileName}}</span>
    <!-- </a> -->
  </div>


  <div class="footer" *ngIf="data">
    <div class="row mt-5 float-right mr-3">
      <button type="submit" class="btn btn-outline-primary mr-2" (click)="close()">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary btn-sm btn-sm" [disabled]="patternFileForm?.invalid"
        (click)="onPatternFileSubmit()">
        Save
      </button>
    </div>
  </div>
</mat-dialog-content>


<app-loader [showLoader]="showLoader"></app-loader>