import { ActionReducerMap, ActionReducer, INIT, MetaReducer } from "@ngrx/store";
import { MoodboardReducer, MoodboardState } from "../reducers/moodboard.reducer";
import { ColorReducer, ColorState } from "../reducers/color.reducer";
import { UserActionTypes } from "../actions/userActions.actions";

interface AppState {
    moodboardState: MoodboardState;
    colorState: ColorState;
  }
  
  export const reducers: ActionReducerMap<AppState> = {
    moodboardState: MoodboardReducer,
    colorState: ColorReducer
  };
  export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
      if ( action != null && action.type === UserActionTypes.LOGOUT) {
        return reducer( undefined, {type: INIT});
      }
      return reducer(state, action);
    };
  }
  
  export const metaReducers: MetaReducer[] = [ logout ];