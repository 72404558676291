import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

export enum PaletteActionTypes {
    LOAD_PALETTE_LIBRARY = '[Palette] Load Palette Library',
    LOAD_PALETTE_LIBRARY_SUCCESS = '[Palette] Load Palette Library Success',
    LOAD_PALETTE_LIBRARY_FAIL = '[Palette] Load Palette Library Fail',
    LOAD_PALETTE = '[Palette] Load Palette',
    LOAD_PALETTE_SUCCESS = '[Palette] Load Palette Success',
    LOAD_PALETTE_FAIL = '[Palette] Load Palette Fail',
    CREATE_PALETTE = '[Palette] Create Palette',
    CREATE_PALETTE_SUCCESS = '[Palette] Create Palette Success',
    CREATE_PALETTE_FAIL = '[Palette] Create Palette Fail',
    UPDATE_PALETTE = '[Palette] Update Palette',
    UPDATE_PALETTE_SUCCESS = '[Palette] Update Palette Success',
    UPDATE_PALETTE_FAIL = '[Palette] Update Palette Fail',
    UPDATE_PALETTE_LIBRARY = '[Palette] Update Palette Library',
    UPDATE_PALETTE_LIBRARY_SUCCESS = '[Palette] Update Palette Library Success',
    UPDATE_PALETTE_LIBRARY_FAIL = '[Palette] Update Palette Library Fail',
    DELETE_PALETTE_LIBRARY = '[Palette] Delete Palette Library',
    DELETE_PALETTE_LIBRARY_SUCCESS = '[Palette] Delete Palette Library Success',
    DELETE_PALETTE_LIBRARY_FAIL = '[Palette] Delete Palette Library Fail',
    UPDATE_PALETTE_DOCUMENT = '[Palette] Update Palette Document',
    UPDATE_PALETTE_DOCUMENT_SUCCESS = '[Palette] Update Palette Document Success',
    UPDATE_PALETTE_DOCUMENT_FAIL = '[Palette] Update Palette Document Fail',
}

export class LoadPaletteLibrary implements Action {
    readonly type = PaletteActionTypes.LOAD_PALETTE_LIBRARY
    constructor(public payload1: string,public payload2: string) { }
}

export class LoadPaletteLibrarySuccess implements Action {
    readonly type = PaletteActionTypes.LOAD_PALETTE_LIBRARY_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadPaletteLibraryFail implements Action {
    readonly type = PaletteActionTypes.LOAD_PALETTE_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export class LoadPalette implements Action {
    readonly type = PaletteActionTypes.LOAD_PALETTE

    constructor(public payload: string) { }
}

export class LoadPaletteSuccess implements Action {
    readonly type = PaletteActionTypes.LOAD_PALETTE_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class LoadPaletteFail implements Action {
    readonly type = PaletteActionTypes.LOAD_PALETTE_FAIL

    constructor(public payload: string) { }
}
export class CreatePalette implements Action {
    readonly type = PaletteActionTypes.CREATE_PALETTE

    constructor(public payload: any) { }
}

export class CreatePaletteSuccess implements Action {
    readonly type = PaletteActionTypes.CREATE_PALETTE_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class CreatePaletteFail implements Action {
    readonly type = PaletteActionTypes.CREATE_PALETTE_FAIL

    constructor(public payload: string) { }
}
export class UpdatePalette implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE

    constructor(public payload: any) { }
}

export class UpdatePaletteSuccess implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE_SUCCESS

    constructor(public payload1: Update<any>, public payload2: any) { }
}

export class UpdatePaletteFail implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE_FAIL

    constructor(public payload: string) { }
}

export class UpdatePaletteLibrary implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE_LIBRARY

    constructor(public payload: any[]) { }
}

export class UpdatePaletteLibrarySuccess implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE_LIBRARY_SUCCESS

    constructor(public payload1: Update<any>[], public payload2: any) { }
}

export class UpdatePaletteLibraryFail implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class DeletePaletteLibrary implements Action {
    readonly type = PaletteActionTypes.DELETE_PALETTE_LIBRARY

    constructor(public payload: string[]) { }
}

export class DeletePaletteLibrarySuccess implements Action {
    readonly type = PaletteActionTypes.DELETE_PALETTE_LIBRARY_SUCCESS

    constructor(public payload1: any, public payload2: any) { }
}

export class DeletePaletteLibraryFail implements Action {
    readonly type = PaletteActionTypes.DELETE_PALETTE_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class UpdatePaletteDocument implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE_DOCUMENT

    constructor(public payload: any) { }
}

export class UpdatePaletteDocumentSuccess implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE_DOCUMENT_SUCCESS

    constructor(public payload1: Update<any>, public payload2: any) { }
}

export class UpdatePaletteDocumentFail implements Action {
    readonly type = PaletteActionTypes.UPDATE_PALETTE_DOCUMENT_FAIL

    constructor(public payload: string) { }
}
export type paletteActionTypes = LoadPaletteLibrary | LoadPaletteLibrarySuccess | LoadPaletteLibraryFail |
    LoadPalette | LoadPaletteSuccess | LoadPaletteFail | CreatePalette | CreatePaletteSuccess |
    CreatePaletteFail | UpdatePalette | UpdatePaletteSuccess | UpdatePaletteFail | UpdatePaletteLibrary | UpdatePaletteLibrarySuccess
    | UpdatePaletteLibraryFail | DeletePaletteLibrary | DeletePaletteLibrarySuccess | DeletePaletteLibraryFail
    | UpdatePaletteDocument | UpdatePaletteDocumentSuccess | UpdatePaletteDocumentFail;