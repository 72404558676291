<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">
    <!-- Site Content -->
    <div class="dt-content">
      <div class="row">
        <!-- Page Header -->
        <div class="col-xl-4 col-sm-4 col-md-4">
          <div class="dt-page__header">
            <h3 class="dt-page__title" *ngIf="!isEditMode">{{staticMsgs.supplierDetails.CreateSupplier}}</h3>
            <h3 class="dt-page__title" *ngIf="isEditMode"> {{staticMsgs.supplierDetails.SupplierDetails}}</h3>
            <nav class="mb-0 breadcrumb">
              <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/dashboard"><i class="fa fa-home"
                  aria-hidden="true"></i> </a>
                  <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/supplierlibrary">
                    <span class="active breadcrumb-item" *ngIf="isEditMode || !isEditMode">{{staticMsgs.suppliersLibrary.SupplierLibrary}}</span>
                  </a>
              <span class="active breadcrumb-item"
                *ngIf="!isEditMode">{{staticMsgs.supplierDetails.CreateSupplier}}</span>
              <span class="active breadcrumb-item" *ngIf="isEditMode">{{staticMsgs.supplierDetails.SupplierDetails}} /
                {{ sequenceNumber }} </span>
            </nav>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-md-4 d-flex justify-content-center" *ngIf="isEditMode">
          <ul class="dt-nav">
            <button *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round"
              (click)="editSupplier()" ngbTooltip="Edit Supplier">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button *ngIf="isEditingMode" type="button" [ngClass]="isEditingMode ? 'icon-active' : 'btn-color-primary'"
              class="btn mr-3 round" (click)="cancelSupplierEdit()" ngbTooltip="Cancel Edit Supplier">
              <i class="fa fa-times text-white" aria-hidden="true"></i>
            </button>
            <button *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round"
              (click)="onDeleteSupplier()" ngbTooltip="Delete">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
            <!-- <button type="button" class="btn mr-3 btn-color-primary round"
                  (click)="Materialdiscussion()"
                    *ngIf="!isEditingMode" ngbTooltip="Discussion">
                    <i class="fa fa-comments" aria-hidden="true"></i>
                  </button>
                  <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal"
                    data-target="#small-modal" ngbTooltip="Generate Techpack">
                    <i class="fas fa-tshirt" aria-hidden="true"></i>
                  </button> -->
          </ul>
        </div>

      </div>
      <!-- /page header -->


      <div class="profile-content">



        <div class="card" *ngIf="isCreateMode">
          <div class="card-body bg-white">

            <div>
              <form [formGroup]="supplierLibraryForm" (ngSubmit)="onSubmit()">
                <div class="form-row">

                  <div class="col-lg-4 col-sm-12">
                    <label for="classification">{{staticMsgs.supplierDetails.SelectClassification}}<span
                        class="red_icon">*</span></label>

                    <input type="text" class="form-control" id="classification" formControlName="classification"
                      required />
                    <div
                      *ngIf="(submitted && this.f.classification.errors) || ( this.f.classification.invalid && (this.f.classification.dirty || this.f.classification.touched))">
                      <div *ngIf="this.f.classification.errors.required" class="red">
                        {{staticMsgs.supplierDetails.Classification+staticMsgs.common.required}}
                      </div>
                    </div>
                  </div>


                </div>

                <div *ngIf="agent">

                  <div class="dt-entry__header mt-5">
                    <!-- Entry Heading -->
                    <div class="dt-entry__heading">
                      <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Agent'">
                        {{staticMsgs.supplierDetails.Agent}}</h3>
                      <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Factory'">
                        {{staticMsgs.supplierDetails.Factory}}</h3>
                      <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Raw Material Supplier'">
                        {{staticMsgs.supplierDetails.RawMaterialSupplier}}</h3>
                    </div>
                    <!-- /entry heading -->
                  </div>

                  <div class="form-row mt-5">

                    <!--<div class="col-lg-4 mb-3">
                              <label for="validationDefault02">ID</label>
                              <input type="text" class="form-control" id="validationDefault02" placeholder="Enter ID" value="" >
                          </div>-->
                    <div class="col-lg-4 mb-3">
                      <label for="name">{{staticMsgs.supplierDetails.Name}}<span
                              class="red_icon">*</span></label>
                      <input type="text" class="form-control" id="name" minlength="3"
                         maxlength="50" formControlName="name" placeholder="Enter Name"
                          required (keypress)="characterOnly($event)" />
                      <div
                          *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
                          <div *ngIf="this.f.name.errors.required" class="red">
                           Name is required.
                          </div>
                          <div *ngIf="this.f.name.errors.minlength" class="red">
                            {{staticMsgs.common.minimum3charactersrequired}}
                         </div>
                      </div>
                  </div>
                    <div class="col-sm-4 mb-3">
                      <label for="phone_number_code">{{staticMsgs.supplierDetails.PhoneNo}}<span
                          class="red_icon">*</span></label>
                      <div class="form-row">
                        <div class="col-sm-5">
                          <!--<select [multiple]=false title="" id="" formControlName="" class="form-control selectpicker w-100" >
                                  <option *ngFor="let number of number" [value]="number.value">{{ number.label }}</option>
                                  </select>-->
                          <select formControlName="phone_number_code" id="phone_number_code"
                            class="form-control selectpicker w-100" data-live-search="true">
                            <option *ngFor="let countrycode of countrycodes" [value]="countrycode.value">
                              {{ countrycode.name }}</option>

                          </select>
                        </div>
                        <div class="col-sm-7">
                          <input type="number" class="form-control no-arrow" id="phoneNo" formControlName="phoneNo"
                            placeholder="Enter Phone Number " (keypress)="numberOnly($event)">
                          <!-- <div
                                *ngIf="(submitted && this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors) || ( this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].invalid && (this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].dirty || this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].touched))">
                                <div
                                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors.required"
                                  class="red">
                                  {{staticMsgs.supplierDetails.ContactNumber+staticMsgs.common.required}}
                                </div>
                                <div
                                *ngIf="(submitted && this.f.phoneNo.errors) || ( this.f.phoneNo.invalid && (this.f.phoneNo.dirty || this.f.phoneNo.touched))">
                                <div *ngIf="this.f.phoneNo.errors.pattern" class="red">
                                  {{staticMsgs.supplierDetails.validMobileNumber}}
                                  </div>
                                </div>
                              </div> -->
                          <div
                            *ngIf="(submitted && this.f.phoneNo.errors) || ( this.f.phoneNo.invalid && (this.f.phoneNo.dirty || this.f.phoneNo.touched))">
                            <div *ngIf="this.f.phoneNo.errors.required" class="red">
                              {{staticMsgs.supplierDetails.PhoneNo+staticMsgs.common.required}}
                            </div>
                            <div *ngIf="this.f.phoneNo.errors?.pattern" class="red">
                              {{staticMsgs.supplierDetails.validPhoneNumber}}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 mb-3">
                      <label for="email">{{staticMsgs.supplierDetails.Email}}<span class="red_icon">*</span></label>
                      <input type="text" class="form-control" id="email" formControlName="email"
                        placeholder="Enter Email">
                      <div
                        *ngIf="(submitted && this.f.email.errors) || ( this.f.email.invalid && (this.f.email.dirty || this.f.email.touched))">
                        <div *ngIf="this.f.email.errors.required" class="red">
                          {{staticMsgs.supplierDetails.Email+staticMsgs.common.required}}
                        </div>
                      </div>
                    </div>

                    <span class="col-lg-12"
                      *ngIf="supplierclassificationValue === 'Factory' || supplierclassificationValue === 'Raw Material Supplier'">
                      <span class="mr-12 ml-12">
                        <div class="form-row">
                          <!--<div class="col-lg-4 mb-3" >
                                  <label for="SupplierChainPartner">Supplier Chain Partner</label>
                                  <select title="Select Chain Partner" id="SupplierChainPartner" formControlName="SupplierChainPartner" class="form-control selectpicker w-100">
                                      <--<option *ngFor="let chain of chain" [value]="chain.value">{{ chain.label }}</option>-->
                          <!-- <option value="" [disabled]="true" selected>Select Chain Partner
                                  </option> --
                                  <option value="Agent">Agent</option>
                                  <option value="Factory">Factory</option>
                                  <option value="Good supplier">Finished Good Supplier</option>
                                  <option value="Raw material">Raw Material Supplier</option>
                                  </select>
                              </div>-->

                          <!-- <div class="col-lg-4 mb-3">
                            <label for="productType">{{staticMsgs.supplierDetails.ProductType}}</label>
                            <select title="Select Product Type" id="productType" formControlName="productType"
                              class="form-control selectpicker w-100">
                              <option value="" [disabled]="true" selected>
                                {{staticMsgs.supplierDetails.SelectProductType}}
                              <option *ngFor="let pOptions of productTypeOptions" [value]="pOptions.id">{{
                                pOptions.value }}</option>
                            </select>
                          </div> -->


                          <div class="col-lg-4 mb-3">
                            <label for="speciality">{{staticMsgs.supplierDetails.Speciality}}</label>
                            <input type="text" class="form-control" formControlName="speciality"
                              placeholder="Enter Speciality" (keypress)="characterOnly($event)">
                            <!-- <select  title="Select Speciality" formControlName="speciality" class="form-control selectpicker w-100">
                                  <option value="Knits">Knits</option>
                                  <option value="Wovan">Wovan</option>
                                  <option value="Trims">Trims</option>
                                  <option value="Packaging">Packaging</option>
                                  <option value="Leather">Leather</option>
                              </select> -->
                          </div>

                          <div class="col-lg-4 mb-3 mt-2" *ngIf="supplierclassificationValue=='Factory'">
                            <label for="moq">{{staticMsgs.supplierDetails.mOQ}}<span class="red_icon">*</span></label>
                            <input type="text" class="form-control" id="moq" formControlName="moq"
                              placeholder="Enter MOQ" (focusout)="addDecimal('moq')" (keypress)="numberOnly($event)" required>
                            <div
                              *ngIf="(submitted && this.f['moq'].errors) || ( this.f['moq'].invalid && (this.f['moq'].dirty || this.f['moq'].touched))">
                              <div *ngIf="this.f['moq'].errors.required" class="red">
                                {{staticMsgs.supplierDetails.mOQ+staticMsgs.common.required}}
                              </div>
                              <div *ngIf="this.f['moq'].errors?.pattern" class="red">
                                MOQ is invalid.
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-4 mb-3 mt-2">
                            <label for="moq">{{staticMsgs.supplierDetails.mOQUOM}}<span class="red_icon">*</span></label>
                            <select id="moqUOM" title="Select Capacity (UOM)" formControlName="moqUOM"
                              class="form-control selectpicker w-100">
                              <option value="Units">Units</option>
                              <option value="Sq.Yds">Sq.Yds</option>
                              <option value="Feet">Feet</option>
                              <option value="Inches">Inches</option>
                              <option value="Sq Ft">Sq Ft</option>
                              <option value="Lb">Lb</option>
                              <option value="Kg">Kg</option>
                              <option value="Oz">Oz</option>
                              <option value="Gm">Gm</option>
                            </select>
                            <div
                                        *ngIf="(submitted && this.f.moqUOM.errors) || ( this.f.moqUOM.invalid && (this.f.moqUOM.dirty || this.f.moqUOM.touched))">
                                        <div *ngIf="this.f.moqUOM.errors.required" class="red">
                                           {{staticMsgs.supplierDetails.moqUOM+staticMsgs.common.required}}
                                           <!-- MOQ(UOM) is required. -->
                                        </div>
                                    </div>
                          </div>

                          <div class="col-lg-4 mb-3 mt-2" *ngIf="supplierclassificationValue=='Factory'">
                            <label for="leadTime">{{staticMsgs.supplierDetails.LeadTime}}<span
                                class="red_icon">*</span></label>
                            <input type="text" class="form-control" id="leadTime" formControlName="leadTime"
                              placeholder="Enter Lead Time" (keypress)="numberOnly($event)" required>
                            <div
                              *ngIf="(submitted && this.f.leadTime.errors) || ( this.f.leadTime.invalid && (this.f.leadTime.dirty || this.f.leadTime.touched))">
                              <div *ngIf="this.f.leadTime.errors.required" class="red">
                                {{staticMsgs.supplierDetails.LeadTime+staticMsgs.common.required}}
                              </div>
                              <div *ngIf="this.f.leadTime.errors?.pattern" class="red">
                                Lead Time is invalid.
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-4 mb-3 mt-2">
                            <label for="capacity">{{staticMsgs.supplierDetails.Capacity}}</label>
                            <input type="number" class="form-control" id="capacity" formControlName="capacity"
                              placeholder="Enter Capacity"  min="0"
                              >
                            <div
                              *ngIf="(submitted && this.f.capacity.errors) || ( this.f.capacity.invalid && (this.f.capacity.dirty || this.f.capacity.touched))">
                              <div *ngIf="this.f.capacity.errors?.pattern" class="red">
                                Capacity is invalid.
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-4 mb-3 mt-2">
                            <label for="capacityUOM">{{staticMsgs.supplierDetails.CapacityUOM}}</label>
                            <select id="capacityUOM" title="Select Capacity (UOM)" formControlName="capacityUOM"
                              class="form-control selectpicker w-100">
                              <option value="Units">Units</option>
                              <option value="Sq.Yds">Sq.Yds</option>
                              <option value="Feet">Feet</option>
                              <option value="Inches">Inches</option>
                              <option value="Sq Ft">Sq Ft</option>
                              <option value="Lb">Lb</option>
                              <option value="Kg">Kg</option>
                              <option value="Oz">Oz</option>
                              <option value="Gm">Gm</option>
                            </select>
                          </div>
                        </div>
                      </span>
                    </span>
                  </div>

                  <div class="mt-3" *ngIf="supplierclassificationValue === 'Factory'">
                    <div class="row">
                      <div class="col-sm-7">
                        <div cdkDropListGroup class="row">
                          <div class="col-sm-5 example-container">
                            <label for="todo"> {{staticMsgs.supplierDetails.MachineType}}</label>
                            <div cdkDropList class="example-list" [cdkDropListData]="todo"
                              (cdkDropListDropped)="drop($event)">
                              <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>
                            </div>
                          </div>
                          <i class="icon icon-shuffle"></i>
                          <div class="col-sm-5 example-container">
                            <label for="done"></label>

                            <div cdkDropList class="example-list mt-1" [cdkDropListData]="done"
                              (cdkDropListDropped)="drop($event)">
                              <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="form-row mt-2">
                          <div class="col-sm-12 ">
                          <button type="button" class="btn btn-primary adv-search-btn " (click)="addMachine()">Confirm Machine Type</button>
                          </div>
                          </div> -->
                      </div>
                      <div class="col-sm-5">
                        <div class="form-row">
                          <div class="col-xl-6 mb-5" *ngFor="let item of done; let i=index">

                            <label>{{item}}</label>
                            <input type="number" class="form-control" id="machineTypes{{i}}"
                              formControlName="machineTypes{{i}}" placeholder="Enter Qty">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- <div class="card mt-5" id="accordion-example">
                    <div class="card-header card-header-acc" data-toggle="collapse" data-target="#collapse-one"
                      aria-expanded="true" aria-controls="collapse-one" id="headingOne">
                      <h5 class="mb-0">
                        <button class="btn btn-link" type="button">
                          {{staticMsgs.supplierDetails.AuditStatus}}
                        </button>
                        <i class="fa fa-angle-down down" aria-hidden="true"></i>
                      </h5>
                    </div>

                    <div id="collapse-one" class="collapse show" aria-labelledby="headingOne"
                      data-parent="#accordion-example">
                      <div class="card-body-acc">
                        <div class="form-row mt-5">

                          <div class="col-lg-4 mb-3">
                            <label for="lastSocialAudit"> {{staticMsgs.supplierDetails.LastAudit}}<span
                              class="red_icon">*</span>
                                </label>
                            <div class="input-group date">
                              <input id="lastSocialAudit" class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                formControlName="lastSocialAudit" ngbDatepicker #date="ngbDatepicker"
                                (dateSelect)="dateChange($event,'lastSocialAuditDate')" [minDate]="minDate"
                                [maxDate]="maxDate">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar"
                                  [ngClass]="{'disableDiv': showLoader1}" (click)="date.toggle()" type="button"><i
                                    class="icon icon-calendar"></i></button>
                              </div><br>
                            </div>
                            <div
                              *ngIf="(submitted && this.f.lastSocialAudit.errors) || ( this.f.lastSocialAudit.invalid && (this.f.lastSocialAudit.dirty || this.f.lastSocialAudit.touched))">
                              <div *ngIf="this.f.lastSocialAudit.errors.required" class="red">
                                {{staticMsgs.supplierDetails.LastSocialAudit+staticMsgs.common.required}}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-4 mb-3">
                            <label for="auditStatus"> {{staticMsgs.supplierDetails.AuditStatus}}<span class="red_icon">*</span></label>
                            <select id="auditStatus" title="Select Status" formControlName="auditStatus"
                              class="form-control selectpicker w-100">
                              <option value="Passed"> {{staticMsgs.supplierDetails.Passed}}</option>
                              <option value="Failed"> {{staticMsgs.supplierDetails.Failed}}</option>
                              <option value="Under Review"> {{staticMsgs.supplierDetails.UnderReview}}</option>
                            </select>
                          </div>

                          <div class="col-lg-4 mb-3">
                            <label for="description"> {{staticMsgs.supplierDetails.Comments}}</label>
                            <textarea cols="2" name="description" id="description" formControlName="description"
                              placeholder="Enter Comments" class="form-control"></textarea>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div formGroupName="Contactss">
                    <div class="card mt-4" id="accordion-example" formGroupName="contactOrder1">
                      <div class="card-header card-header-acc" id="headingtwo">
                        <h5 class="mb-0">
                          <button class="btn btn-link" type="button">
                            {{staticMsgs.supplierDetails.PrimaryContact}}
                          </button>
                          <i class="fa fa-angle-down down" data-toggle="collapse" data-target="#collapse-two"
                            aria-expanded="true" aria-controls="collapse-one" aria-hidden="true"></i>
                          <button class="btn btn-primary btn-esm float-right mr-3 mt-1" (click)="secondContact()"
                            *ngIf="secondcontact" type="button">
                            + {{staticMsgs.supplierDetails.SecondaryContact}}
                          </button>
                        </h5>

                      </div>

                      <div id="collapse-two" class="collapse show" aria-labelledby="headingtwo"
                        data-parent="#accordion-example">
                        <div class="card-body-acc">
                          <div class="form-row mt-5">
                            <div class="form-row" [hidden]="true">
                              <input type="text" class="form-control" id="" formControlName="contactOrder">
                            </div>
                            <div class="col-lg-4 mb-3">
                              <label for="fullName"> {{staticMsgs.supplierDetails.Name}}<span class="red_icon">*</span>
                              </label>
                              <input type="text" class="form-control" id="" formControlName="fullName"
                                placeholder="Enter Name" (keypress)="characterOnly($event)" required>
                              <div
                                *ngIf="(submitted && this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].errors) || ( this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].invalid && (this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].dirty || this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].touched))">
                                <div
                                *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].errors.required"
                                  class="red">
                                  {{staticMsgs.supplierDetails.Name+staticMsgs.common.required}}
                                </div>
                                <div
                                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['fullName'].errors?.pattern"
                                  class="red">
                                  Name is invalid.
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-4 mb-3">
                              <label for="officeNo"> {{staticMsgs.supplierDetails.OfficeNo}}</label>
                              <input type="text" class="form-control" id="officeNo" formControlName="officeNo"
                                placeholder="Enter Office No" (keypress)="numberOnly($event)">
                                <div
                                *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['officeNo'].errors?.pattern"
                                class="red">
                                officeNo is invalid.
                              </div>
                            </div>

                            <div class="col-lg-4 mb-3">
                              <label for="ext"> {{staticMsgs.supplierDetails.Ext}}</label>
                              <input type="text" class="form-control" id="ext" formControlName="ext"
                                placeholder="Enter Ext (+123)" (keypress)="extNumberOnly($event)">
                              <div
                                *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['ext'].errors?.pattern"
                                class="red">
                                ext is invalid.
                              </div>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="fax"> {{staticMsgs.supplierDetails.Fax}}</label>
                              <input type="text" class="form-control" id="fax" formControlName="fax"
                                placeholder="Enter Fax" (keypress)="faxNumberOnly($event)">
                                <div
                                    *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').dirty || supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').touched))">
                                      <div
                                        *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').errors.pattern"
                                          class="red">
                                          {{staticMsgs.supplierDetails.Fax+staticMsgs.common.invalid}}
                                      </div>
                                 </div>
                            </div>

                            <div class="col-sm-4 mt-3 mb-3">
                              <label for="contactNo">{{staticMsgs.supplierDetails.PhoneNo}}<span
                                  class="red_icon">*</span> </label>
                              <input type="text" class="form-control" id="" formControlName="contactNo"
                                placeholder="Enter Phone Number " maxlength="10" (keypress)="numberOnly($event)">
                              <div
                                *ngIf="(submitted && this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors) || ( this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].invalid && (this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].dirty || this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].touched))">
                                <div
                                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors.required"
                                  class="red">
                                  {{staticMsgs.supplierDetails.PhoneNo+staticMsgs.common.required}}
                                </div>
                                <div
                                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactNo'].errors?.pattern"
                                  class="red">
                                  {{staticMsgs.supplierDetails.validPhoneNumber}}
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="contactEmail">Email {{staticMsgs.supplierDetails.SecondaryContact}} <span
                                  class="red_icon">*</span></label>
                              <input type="text" class="form-control" id="" formControlName="contactEmail"
                                placeholder="Enter Email" required>
                              <div
                                *ngIf="(submitted && this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].errors) || ( this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].invalid && (this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].dirty || this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].touched))">
                                <div
                                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].errors.required"
                                  class="red">
                                  {{staticMsgs.supplierDetails.Email+staticMsgs.common.required}}
                                </div>
                                <div
                                  *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['contactEmail'].errors?.pattern"
                                  class="red">
                                  Email is invalid.
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="country"> {{staticMsgs.supplierDetails.Country}}</label>
                              <select [title]="staticMsgs.supplierDetails.SelectCountry" id="country" formControlName="country"
                                class="form-control selectpicker w-100" data-live-search="true"
                                (change)="changeCountry($event,'')">
                                <!-- <option value="" [disabled]="true" selected>
                                  {{staticMsgs.supplierDetails.SelectCountry}}
                                </option> -->
                                <option *ngFor="let country of country" [value]="country.country">{{ country.country }}
                                </option>

                              </select>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="state"> {{staticMsgs.supplierDetails.State}}</label>
                              <!-- <input type="text" class="form-control" id="" formControlName="state" placeholder="Enter State"> -->
                              <select [title]="staticMsgs.supplierDetails.SelectState" id="state" formControlName="state"
                                class="form-control selectpicker w-100" data-live-search="true">
                                <option *ngFor="let states of allStates" [value]="states.value">{{ states.label }}
                                </option>
                              </select>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="city"> {{staticMsgs.supplierDetails.City}}</label>
                              <input type="text" class="form-control" id="" formControlName="city"
                                placeholder="Enter City">
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="zipCode"> {{staticMsgs.supplierDetails.ZipCode}}</label>
                              <input type="text" class="form-control" id="" formControlName="zipCode"
                                placeholder="Enter Zip Code" (keypress)="numberOnly($event)">
                              <div
                                *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['zipCode'].errors?.pattern"
                                class="red">
                                zipCode is invalid.
                              </div>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="address"> {{staticMsgs.supplierDetails.Address}}</label>
                              <textarea cols="2" formControlName="address" placeholder="Enter Address"
                                class="form-control"></textarea>
                            </div>
                            <input type="hidden" class="form-control" id="" formControlName="id">


                          </div>
                        </div>
                      </div>


                    </div>

                    <div class="card mt-4" id="accordion-example" *ngIf="secondcontact2" formGroupName="contactOrder2">
                      <div class="card-header card-header-acc" id="headingtwo">
                        <h5 class="mb-0">
                          <button class="btn btn-link" type="button">
                            {{staticMsgs.supplierDetails.SecondaryContact}}
                          </button>
                          <i class="fa fa-angle-down down" data-toggle="collapse" data-target="#collapse-three"
                            aria-expanded="true" aria-controls="collapse-three" aria-hidden="true"></i>
                          <button class="btn btn-primary btn-esm float-right mr-3 mt-1" (click)="secondContact2()"
                            type="button">
                            - {{staticMsgs.supplierDetails.Remove}}
                          </button>
                        </h5>

                      </div>

                      <div id="collapse-three" class="collapsed" aria-labelledby="headingthree"
                        data-parent="#accordion-example">
                        <div class="card-body-acc">
                          <div class="form-row mt-5">
                            <div class="form-row" [hidden]="true">
                              <input type="text" class="form-control" value="" id="" formControlName="contactOrder">
                            </div>
                            <div class="col-lg-4 mb-3">
                              <label for="fullName">{{staticMsgs.supplierDetails.Name}}</label>
                              <input type="text" class="form-control" formControlName="fullName" id=""
                                placeholder="Enter Name">
                            </div>

                            <div class="col-lg-4 mb-3">
                              <label for="officeNo">{{staticMsgs.supplierDetails.OfficeNo}}</label>
                              <input type="text" class="form-control" formControlName="officeNo" id=""
                                placeholder="Enter Office No">
                            </div>

                            <div class="col-lg-4 mb-3">
                              <label for="ext">{{staticMsgs.supplierDetails.Ext}}</label>
                              <input type="text" class="form-control" formControlName="ext" id=""
                                placeholder="Enter Ext (+123)" (keypress)="extNumberOnly($event)">
                                <div
                                *ngIf="this.f['Contactss']['controls']['contactOrder2'].controls['ext'].errors?.pattern"
                                class="red">
                                ext is invalid.
                              </div>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="fax">{{staticMsgs.supplierDetails.Fax}}</label>
                              <input type="text" class="form-control" id="" formControlName="fax"
                                placeholder="Enter Fax" (keypress)="faxNumberOnly($event)">
                            </div>

                            <div class="col-sm-4 mt-3 mb-3">
                              <label for="contactNo">{{staticMsgs.supplierDetails.PhoneNo}}</label>

                              <input type="text" class="form-control" id="" formControlName="contactNo"
                                placeholder="Enter Phone Number " maxlength="10">
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="contactEmail">{{staticMsgs.supplierDetails.Email}}</label>
                              <input type="text" class="form-control" formControlName="contactEmail" id=""
                                placeholder="Enter Email">
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="country">{{staticMsgs.supplierDetails.Country}}</label>
                              <select title="Select Country" id="country" formControlName="country"
                                class="form-control selectpicker w-100" data-live-search="true"
                                (change)="changeCountry($event,'')">
                                <option value="" [disabled]="true" selected>{{staticMsgs.supplierDetails.SelectCountry}}
                                </option>
                                <option *ngFor="let country of country" [value]="country.country">{{ country.country }}
                                </option>

                              </select>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="state">{{staticMsgs.supplierDetails.State}}</label>
                              <select title="Select State" id="state" formControlName="state"
                                class="form-control selectpicker w-100" data-live-search="true">
                                <option value="" [disabled]="true" selected>{{staticMsgs.supplierDetails.SelectState}}
                                </option>
                                <option *ngFor="let states of allStates" [value]="states.value">{{ states.label }}
                                </option>

                              </select>
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="city">{{staticMsgs.supplierDetails.City}}</label>
                              <input type="text" class="form-control" id="" formControlName="city"
                                placeholder="Enter City">
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="zipCode">{{staticMsgs.supplierDetails.ZipCode}}</label>
                              <input type="text" class="form-control" id="" formControlName="zipCode"
                                placeholder="Enter Zip Code">
                            </div>

                            <div class="col-lg-4 mt-3 mb-3">
                              <label for="address">{{staticMsgs.supplierDetails.Address}}</label>
                              <textarea cols="2" formControlName="address" placeholder="Enter Description"
                                class="form-control"></textarea>
                            </div>
                            <input type="hidden" class="form-control" id="contactid" formControlName="id">
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                </div>


                <div class="form-row mt-5 float-right">
                  <input type="text" class="form-control" [hidden]="true" id="supplierid" formControlName="supplierid"
                    placeholder="Supplier Id" />

                  <button type="submit" *ngIf="!isEditMode"
                    class="btn btn-primary adv-search-btn">{{staticMsgs.supplierDetails.Create}}</button>
                  <button type="submit" *ngIf="isEditMode" 
                    class="btn btn-primary adv-search-btn">{{staticMsgs.supplierDetails.Update}}</button>
                </div>


              </form>
            </div>

          </div>
        </div>



        <!-- Grid -->
        <div class="row">

          <!-- Grid Item -->
          <div class="wd-300" *ngIf="isViewingMode">
            <ng-image-slider  class="sidebar_sliders sidebar_image" *ngIf="this.f.classification.value == 'Agent' && imageObject1.length>0"
              [images]="imageObject1" [imageSize]="{width: '100%', height: 200}"></ng-image-slider>
            <ng-image-slider  class="sidebar_sliders sidebar_image" *ngIf="this.f.classification.value == 'Factory' && imageObject2.length>0"
              [images]="imageObject2" [imageSize]="{width: '100%', height: 200}"></ng-image-slider>
            <ng-image-slider class="sidebar_sliders sidebar_image" *ngIf="this.f.classification.value == 'Raw Material Supplier' && imageObject3.length>0"
              [images]="imageObject3" [imageSize]="{width: '100%', height: 200}"></ng-image-slider>

            <div class="accordion mt-5" id="accordion-example">
              <div class="card" *ngIf="editedSupplierData">
                <div class="" id="headingOne">
                  <h5 class="mb-0">
                    <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                      data-target="#collapse-one" aria-expanded="false" aria-controls="collapse-one">
                      {{staticMsgs.supplierDetails.SupplierInfo}}
                      <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                    </h4>
                  </h5>
                </div>

                <div id="collapse-one" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-example">
                  <div class="card-body bg-white p-4" *ngIf="editedSupplierData">
                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.supplierDetails.Name}} :</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedSupplierData.name}} </p>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.supplierDetails.Type}} :</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedSupplierData.classification}} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="editedSupplierData">
                <div class="" id="headingTwo">
                  <h5 class="mb-0">
                    <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                      data-target="#collapse-two" aria-expanded="false" aria-controls="collapse-one">
                      {{staticMsgs.supplierDetails.UserInfo}}
                      <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                    </h4>
                  </h5>
                </div>
                <div id="collapse-two" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion-example">
                  <div class="card-body bg-white p-4" *ngIf="editedSupplierData">
                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.supplierDetails.CreatedBy}} :</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">{{editedSupplierData.createdBy?.firstName}}</p>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-5 col-sm-5 col-xl-5">
                        <p class="card_text">{{staticMsgs.supplierDetails.CreatedOn}} :</p>
                      </div>
                      <div class="col-md-7 col-sm-7 col-xl-7">
                        <p class="a-font">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          {{editedSupplierData.createdOn |date: "dd MMM yyyy"}}
                        </p>
                        <p class="a-font">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                          {{editedSupplierData.createdOn |date:"hh:mm a"}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /grid item -->

          <!-- Grid Item -->
          <div [ngClass]="isViewingMode ? 'wdcacl-300' : 'w-100'" *ngIf="isViewingMode || isEditingMode">
            <div class="order-xl-1">

              <!-- Card -->
              <div class="card-shadow-none">

                <!-- Card Header -->
                <div class="card-header card-nav border-bottom d-sm-flex justify-content-sm-between"
                  *ngIf="!isCreateMode">
                  <!-- <h3 class="mb-2 mb-sm-n5">About</h3> -->

                  <ul class="card-header-links nav nav-underline" role="tablist">
                    <li class="nav-item">
                      <a *ngIf="activeTab !='supplierDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane1"
                        [ngClass]="{ 'active':activeTab === 'supplierDetailsTab' }"
                        (click)="supplierDetailsTab('supplierDetailsTab'); activeTab = 'supplierDetailsTab'" role="tab"
                        aria-controls="tab-pane1"
                        aria-selected="true">{{staticMsgs.supplierDetails.SelectClassification}}</a>
                      <a *ngIf="activeTab =='supplierDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane1"
                        [ngClass]="{ 'active':activeTab === 'supplierDetailsTab' }" role="tab" aria-controls="tab-pane1"
                        aria-selected="true">{{staticMsgs.supplierDetails.SupplierDetails}}</a>
                    </li>
                    <li class="nav-item">
                      <a *ngIf="activeTab !='docDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane2"
                        [ngClass]="{ 'active':activeTab === 'docDetailsTab' }" (click)="docDetailsTab('docDetailsTab')"
                        role="tab" aria-controls="tab-pane2" 
                        aria-selected="true">{{staticMsgs.supplierDetails.Document}}</a>
                      <a *ngIf="activeTab =='docDetailsTab'" class="nav-link" data-toggle="tab" href="#tab-pane2"
                        [ngClass]="{ 'active':activeTab === 'docDetailsTab' }" role="tab" aria-controls="tab-pane2"
                        aria-selected="true">{{staticMsgs.supplierDetails.Document}}</a>
                    </li>

                  </ul>
                  <div class="dropdown" *ngIf="!isViewingMode">
                    <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                                    </a> -->
                  </div>

                  <div class="dropdown" *ngIf="isViewingMode">

                    <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                                    </a>

                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)" (click)="editSupplier()">
                                            <i class="icon icon-editors icon-fw"></i>
                                            Edit
                                        </a>

                                    </div> -->
                  </div>

                </div>
                <!-- /card header -->

                <!-- Card Body -->
                <div class="mt-3 pb-2">

                  <!-- Tab Content-->
                  <div class="tab-content">

                    <!-- Tab panel -->

                    <div id="tab-pane1" class="tab-pane card-body card-body-shadow bg-white active"
                      [ngClass]="{ 'active':activeTab==='supplierDetailsTab'}">

                      <div *ngIf="isEditingMode">
                        <form [formGroup]="supplierLibraryForm" (ngSubmit)="onSubmit()">
                          <div class="form-row">

                            <div class="col-lg-4 col-sm-12">
                              <label for="classification">{{staticMsgs.supplierDetails.SelectClassification}}<span
                                  class="red_icon">*</span></label>

                              <input type="text" class="form-control" id="classification"
                                formControlName="classification" required />
                              <div
                                *ngIf="(submitted && this.f.classification.errors) || ( this.f.classification.invalid && (this.f.classification.dirty || this.f.classification.touched))">
                                <div *ngIf="this.f.classification.errors.required" class="red">
                                  {{staticMsgs.supplierDetails.Classification+staticMsgs.common.required}}
                                </div>
                              </div>
                            </div>


                          </div>

                          <div *ngIf="supplierclassificationValue">

                            <div class="dt-entry__header mt-5">
                              <!-- Entry Heading -->
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Agent'">
                                  {{staticMsgs.supplierDetails.Agent}}</h3>
                                <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Factory'">
                                  {{staticMsgs.supplierDetails.Factory}}</h3>
                                <h3 class="dt-entry__title"
                                  *ngIf="supplierclassificationValue === 'Raw Material Supplier'">
                                  {{staticMsgs.supplierDetails.RawMaterialSupplier}}</h3>
                              </div>
                              <!-- /entry heading -->
                            </div>

                            <div class="form-row mt-5">

                              <!--<div class="col-lg-4 mb-3">
                                                    <label for="validationDefault02">ID</label>
                                                    <input type="text" class="form-control" id="validationDefault02" placeholder="Enter ID" value="" >
                                                </div>-->

                               <div class="col-lg-3 mb-3">
                                <label for="name">{{staticMsgs.supplierDetails.Name}}<span
                                    class="red_icon">*</span></label>
                                <input type="text" class="form-control" id="name" formControlName="name" minlength="3"
                                maxlength="50"   placeholder="Enter Name" (keypress)="characterOnly($event)" required="">
                                <div
                                  *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
                                  <div *ngIf="this.f.name.errors.required" class="red">
                                    {{staticMsgs.supplierDetails.Name+staticMsgs.common.required}}
                                  </div>
                                  <div *ngIf="this.f.name.errors.minlength" class="red">
                                    {{staticMsgs.common.minimum3charactersrequired}}
                                   </div>
                                </div>

                              </div>


                              <div class="col-sm-3 mb-3">
                                <label for="phone_number_code">{{staticMsgs.supplierDetails.PhoneNo}}<span
                                    class="red_icon">*</span></label>
                                <div class="form-row">
                                  <div class="col-sm-5">
                                    <!--<select [multiple]=false title="" id="" formControlName="" class="form-control selectpicker w-100" >
                                                        <option *ngFor="let number of number" [value]="number.value">{{ number.label }}</option>
                                                        </select>-->
                                    <select formControlName="phone_number_code" id="phone_number_code"
                                      class="form-control selectpicker w-100" data-live-search="true">
                                      <option *ngFor="let countrycode of countrycodes" [value]="countrycode.value">
                                        {{ countrycode.name }}</option>

                                    </select>
                                  </div>
                                  <div class="col-sm-7">
                                    <input type="text" class="form-control no-arrow" id="phoneNo"
                                      formControlName="phoneNo" placeholder="Enter Phone Number " maxlength="10"
                                      (keypress)="numberOnly($event)">
                                    <div
                                      *ngIf="(submitted && this.f.phoneNo.errors) || ( this.f.phoneNo.invalid && (this.f.phoneNo.dirty || this.f.phoneNo.touched))">
                                      <div *ngIf="this.f.phoneNo.errors.required" class="red">
                                        {{staticMsgs.supplierDetails.MobileNumber+staticMsgs.common.required}}
                                      </div>
                                      <div *ngIf="!this.f.phoneNo.errors.required && this.f.phoneNo.errors.pattern"
                                        class="red">
                                        {{staticMsgs.supplierDetails.MobileNumber+staticMsgs.common.invalid}}
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4 mb-3">
                                <label for="email">{{staticMsgs.supplierDetails.Email}}<span
                                    class="red_icon">*</span></label>
                                <input type="text" class="form-control" id="email" formControlName="email"
                                  placeholder="Enter Email">
                                <div
                                  *ngIf="(submitted && this.f.email.errors) || ( this.f.email.invalid && (this.f.email.dirty || this.f.email.touched))">
                                  <div *ngIf="this.f.email.errors.required" class="red">
                                    {{staticMsgs.supplierDetails.Email+staticMsgs.common.required}}
                                  </div>
                                  <div *ngIf="!this.f.email.errors.required && this.f.email.errors.pattern" class="red">
                                    {{staticMsgs.supplierDetails.Email+staticMsgs.common.invalid}}
                                  </div>
                                </div>
                              </div>

                              <span class=""
                                *ngIf="supplierclassificationValue === 'Factory' || supplierclassificationValue === 'Raw Material Supplier'">
                                <span class="row mr-12 ml-12">
                                  <div class="form-row">
                                    <!--<div class="col-lg-3 mb-3">
                                                                        <label for="SupplierChainPartner">Supplier Chain Partner</label>
                                                                        <select title="Select Chain Partner" id="SupplierChainPartner"
                                                                            formControlName="SupplierChainPartner" class="form-control selectpicker w-100">
                                                                            <option *ngFor="let chain of chain" [value]="chain.value">{{ chain.label }}</option> -->
                                    <!-- <option value="" [disabled]="true" selected>Select Chain Partner
                                                        </option> --
                                                                            <option value="Agent">Agent</option>
                                                                            <option value="Factory">Factory</option>
                                                                            <option value="Good supplier">Finished Good Supplier</option>
                                                                            <option value="Raw material">Raw Material Supplier</option>
                                                                        </select>
                                                                    </div>-->

                                    <div class="col-lg-3 mb-3">
                                      <label for="productType">{{staticMsgs.supplierDetails.ProductType}}<span class="red_icon">*</span></label>
                                      <select title="Select Product Type" id="productType" formControlName="productType"
                                        class="form-control selectpicker w-100">
                                        <option value="" [disabled]="true" selected>
                                          {{staticMsgs.supplierDetails.SelectProductType}}
                                        </option>
                                        <option *ngFor="let pOptions of productTypeOptions" [value]="pOptions.id">{{
                                          pOptions.value }}</option>
                                      </select>
                                      
                                  
                                    </div>


                                    <div class="col-lg-3 mb-3">
                                      <label for="speciality">{{staticMsgs.supplierDetails.Speciality}}</label>
                                      <input type="text" class="form-control" formControlName="speciality"
                                        placeholder="Enter Speciality">
                                      <!-- <select title="Select Speciality" formControlName="speciality"
                                                                            class="form-control selectpicker w-100">
                                                                            <option value="Knits">Knits</option>
                                                                            <option value="Wovan">Wovan</option>
                                                                            <option value="Trims">Trims</option>
                                                                            <option value="Packaging">Packaging</option>
                                                                            <option value="Leather">Leather</option>
                                                                        </select> -->
                                    </div>

                                    <div class="col-lg-3 mb-3 ">
                                      <label for="moq">{{staticMsgs.supplierDetails.mOQ}}<span class="red_icon">*</span></label>
                                      <input type="text" class="form-control" id="moq" formControlName="moq"
                                      (focusout)="addDecimal('moq')" placeholder="Enter MOQ" (keypress)="numberOnly($event)" required>
                                      <div
                                        *ngIf="(submitted && this.f.moq.errors) || ( this.f.moq.invalid && (this.f.moq.dirty || this.f.moq.touched))">
                                        <div *ngIf="this.f.moq.errors.required" class="red">
                                          {{staticMsgs.supplierDetails.mOQ+staticMsgs.common.required}}
                                        </div>
                                        <div
                                          *ngIf="this.supplierLibraryForm.controls.moq.value && this.supplierLibraryForm.controls.moq.errors && this.supplierLibraryForm.controls.moq.errors.max"
                                          class="red">
                                          {{staticMsgs.supplierDetails.moqAllows}}
                                        </div>
                                      </div>

                                    </div>

                                    <div class="col-lg-3 mb-3">
                                      <label for="moq">{{staticMsgs.supplierDetails.mOQUOM}}<span class="red_icon">*</span></label>
                                      <select id="moqUOM" title="Select Capacity (UOM)" formControlName="moqUOM"
                                        class="form-control selectpicker w-100">
                                        <option value="Units">Units</option>
                                        <option value="Sq.Yds">Sq.Yds</option>
                                        <option value="Feet">Feet</option>
                                        <option value="Inches">Inches</option>
                                        <option value="Sq Ft">Sq Ft</option>
                                        <option value="Lb">Lb</option>
                                        <option value="Kg">Kg</option>
                                        <option value="Oz">Oz</option>
                                        <option value="Gm">Gm</option>
                                      </select>
                                      <!-- <div
                                                                              *ngIf="(submitted && this.f.moqUOM.errors) || ( this.f.moqUOM.invalid && (this.f.moqUOM.dirty || this.f.moqUOM.touched))">
                                                                              <div *ngIf="this.f.moqUOM.errors.required" class="red">
                                                                                  MOQ (UOM)is required.
                                                                              </div>
                                                                          </div> -->
                                    </div>

                                    <div class="col-lg-3 mb-3 mt-2">
                                      <label for="leadTime"> {{staticMsgs.supplierDetails.LeadTime}}<span
                                          class="red_icon">*</span></label>
                                      <input type="number" class="form-control" id="leadTime" formControlName="leadTime"
                                        placeholder="Enter Lead Time" required>
                                      <div
                                        *ngIf="(submitted && this.f.leadTime.errors) || ( this.f.leadTime.invalid && (this.f.leadTime.dirty || this.f.leadTime.touched))">
                                        <div *ngIf="this.f.leadTime.errors.required" class="red">
                                          {{staticMsgs.supplierDetails.LeadTime+staticMsgs.common.required}}
                                        </div>
                                        <div
                                          *ngIf="this.supplierLibraryForm.controls.leadTime.value && this.supplierLibraryForm.controls.leadTime.errors && this.supplierLibraryForm.controls.leadTime.errors.max"
                                          class="red">
                                          {{staticMsgs.supplierDetails.LeadTimeValidations}}
                                        </div>
                                      </div>

                                    </div>

                                    <div class="col-lg-3 mb-3 mt-2">
                                      <label for="capacity">{{staticMsgs.supplierDetails.Capacity}}</label>
                                      <input type="number" class="form-control" id="capacity" formControlName="capacity"
                                        placeholder="Enter Capacity">
                                      <div
                                        *ngIf="this.supplierLibraryForm.controls.capacity.errors && this.supplierLibraryForm.controls.capacity.value && this.supplierLibraryForm.controls.capacity.errors && this.supplierLibraryForm.controls.capacity.errors.max"
                                        class="red">
                                        {{staticMsgs.supplierDetails.CapacityAllowCharecters}}
                                      </div>
                                    </div>

                                    <div class="col-lg-3 mb-3 mt-2">
                                      <label for="capacityUOM">{{staticMsgs.supplierDetails.CapacityUOM}}</label>
                                      <select id="capacityUOM" title="Select Capacity (UOM)"
                                        formControlName="capacityUOM" class="form-control selectpicker w-100">
                                        <!--<option *ngFor="let capacity of capacity" [value]="capacity.value">{{ capacity.label }}</option>-->
                                        <!-- <option value="" [disabled]="true" selected>Select Status
                                                        </option> -->
                                        <option value="Units">Units</option>
                                        <option value="Sq.Yds">Sq.Yds</option>
                                        <option value="Feet">Feet</option>
                                        <option value="Inches">Inches</option>
                                        <option value="Sq Ft">Sq Ft</option>
                                        <option value="Lb">Lb</option>
                                        <option value="Kg">Kg</option>
                                        <option value="Oz">Oz</option>
                                        <option value="Gm">Gm</option>
                                      </select>
                                     
                                    </div>
                                  </div>
                                </span>
                              </span>
                            </div>

                            <div class="mt-3" *ngIf="supplierclassificationValue === 'Factory'">
                              <div class="row">
                                <div class="col-sm-6">
                                  <div cdkDropListGroup class="row">
                                    <div class="col-sm-5 example-container">
                                      <label for="todo">{{staticMsgs.supplierDetails.MachineType}}</label>
                                      <div cdkDropList class="example-list" [cdkDropListData]="todo"
                                        (cdkDropListDropped)="drop($event)">
                                        <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>
                                      </div>
                                    </div>
                                    <i class="icon icon-shuffle"></i>
                                    <div class="col-sm-5 example-container">
                                      <label for="done"></label>

                                      <div cdkDropList class="example-list mt-1" [cdkDropListData]="done"
                                        (cdkDropListDropped)="drop($event)">
                                        <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div class="form-row mt-2">
                                                <div class="col-sm-12 ">
                                                <button type="button" class="btn btn-primary adv-search-btn " (click)="addMachine()">Confirm Machine Type</button>
                                                </div>
                                                </div> -->
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-row">
                                    <div class="col-xl-6 mb-5" *ngFor="let item of done; let i=index">

                                      <label>{{item}}</label>
                                      <input type="number" class="form-control" id="machineTypes{{i}}"
                                        formControlName="machineTypes{{i}}" placeholder="Enter Qty">

                                      <div
                                        *ngIf="i == 0 && this.supplierLibraryForm.controls.machineTypes0.value && this.supplierLibraryForm.controls.machineTypes0.value.length > 20"
                                        class="red">
                                        {{item}}{{staticMsgs.supplierDetails.itemAllowCharecters}}
                                      </div>
                                      <div
                                        *ngIf="i == 1 && this.supplierLibraryForm.controls.machineTypes1.value && this.supplierLibraryForm.controls.machineTypes1.value.length > 20"
                                        class="red">
                                        {{item}} {{staticMsgs.supplierDetails.itemAllowCharecters}}
                                      </div>
                                      <div
                                        *ngIf="i == 2 && this.supplierLibraryForm.controls.machineTypes2.value && this.supplierLibraryForm.controls.machineTypes2.value.length > 20"
                                        class="red">
                                        {{item}} {{staticMsgs.supplierDetails.itemAllowCharecters}}
                                      </div>
                                      <div
                                        *ngIf="i == 3 && this.supplierLibraryForm.controls.machineTypes3.value && this.supplierLibraryForm.controls.machineTypes3.value.length > 20"
                                        class="red">
                                        {{item}} {{staticMsgs.supplierDetails.itemAllowCharecters}}
                                      </div>
                                      <div
                                        *ngIf="i == 4 && this.supplierLibraryForm.controls.machineTypes4.value && this.supplierLibraryForm.controls.machineTypes4.value.length > 20"
                                        class="red">
                                        {{item}} {{staticMsgs.supplierDetails.itemAllowCharecters}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <!-- <div class="card mt-5" id="accordion-example">
                              <div class="card-header card-header-acc" data-toggle="collapse"
                                data-target="#collapse-one" aria-expanded="true" aria-controls="collapse-one"
                                id="headingOne">
                                <h5 class="mb-0">
                                  <button class="btn btn-link" type="button">
                                    {{staticMsgs.supplierDetails.AuditStatus}}
                                  </button>
                                  <i class="fa fa-angle-down down" aria-hidden="true"></i>
                                </h5>
                              </div>

                              <div id="collapse-one" class="collapse show" aria-labelledby="headingOne"
                                data-parent="#accordion-example">
                                <div class="card-body-acc">
                                  <div class="form-row mt-5">

                                    <div class="col-lg-4 mb-3">
                                      <label for="lastSocialAudit">{{staticMsgs.supplierDetails.LastAudit}}<span
                                          class="red_icon">*</span></label>
                                      <div class="input-group date">
                                        <input class="form-control" name="dp" [(ngModel)]="lastSocialAuditDate"
                                          ngbDatepicker readonly (click)="d.toggle()" #d="ngbDatepicker"
                                          formControlName="lastSocialAudit"
                                          (dateSelect)="dateChange($event,'lastSocialAudit')" [minDate]="minDate"
                                          [maxDate]="maxDate">
                                        <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar"
                                            [ngClass]="{'disableDiv': showLoader1}" (click)="d.toggle()"
                                            type="button"><i class="icon icon-calendar"></i></button>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-lg-4 mb-3">
                                      <label for="auditStatus">{{staticMsgs.supplierDetails.AuditStatus}}</label>
                                      <select id="auditStatus" title="Select Status" formControlName="auditStatus"
                                        class="form-control selectpicker w-100">
                                        <option value="Passed">{{staticMsgs.supplierDetails.Passed}}</option>
                                        <option value="Failed">{{staticMsgs.supplierDetails.Failed}}</option>
                                        <option value="Under Review">{{staticMsgs.supplierDetails.UnderReview}}</option>
                                      </select>
                                    </div>

                                    <div class="col-lg-4 mb-3">
                                      <label for="description">{{staticMsgs.supplierDetails.Comments}}</label>
                                      <textarea cols="2" name="description" id="description"
                                        formControlName="description" placeholder="Enter Comments"
                                        class="form-control"></textarea>
                                      <div
                                        *ngIf="this.supplierLibraryForm.controls.description.errors && this.supplierLibraryForm.controls.description.value && this.supplierLibraryForm.controls.description.value.length > 500"
                                        class="red">
                                        {{staticMsgs.supplierDetails.commentsAllowCharecters}}
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div> -->
                            <div formGroupName="Contactss">
                              <div class="card mt-4" id="accordion-example" formGroupName="contactOrder1">
                                <div class="card-header card-header-acc" id="headingtwo">
                                  <h5 class="mb-0">
                                    <button class="btn btn-link" type="button">
                                      {{staticMsgs.supplierDetails.PrimaryContact}}
                                    </button>
                                    <i class="fa fa-angle-down down" data-toggle="collapse" data-target="#collapse-two"
                                      aria-expanded="true" aria-controls="collapse-one" aria-hidden="true"></i>
                                    <button class="btn btn-primary btn-esm float-right mr-3 mt-1"
                                      (click)="secondContact()" *ngIf="secondcontact" type="button">
                                      + {{staticMsgs.supplierDetails.SecondaryContact}}
                                    </button>
                                  </h5>

                                </div>

                                <div id="collapse-two" class="collapse show" aria-labelledby="headingtwo"
                                  data-parent="#accordion-example">
                                  <div class="card-body-acc">
                                    <div class="form-row mt-5">
                                      <div class="form-row" [hidden]="true">
                                        <input type="text" class="form-control" id="" formControlName="contactOrder">
                                      </div>
                                      <div class="col-lg-3 mb-3">
                                        <label for="fullName">{{staticMsgs.supplierDetails.Name}} <span
                                            class="red_icon">*</span> </label>
                                        <input type="text" class="form-control" id="" formControlName="fullName"
                                          placeholder="Enter Name" required>
                                        <div
                                          *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder1').get('fullName').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder1').get('fullName').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder1').get('fullName').dirty || supplierLibraryForm.get('Contactss').get('contactOrder1').get('fullName').touched))">
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('fullName').errors.required"
                                            class="red">
                                            {{staticMsgs.supplierDetails.Name+staticMsgs.common.required}}
                                          </div>
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('fullName').value && supplierLibraryForm.get('Contactss').get('contactOrder1').get('fullName').value.length > 50"
                                            class="red">
                                            {{staticMsgs.supplierDetails.Namevalidation}}
                                          </div>
                                        </div>

                                      </div>

                                      <div class="col-lg-3 mb-3">
                                        <label for="officeNo">{{staticMsgs.supplierDetails.OfficeNo}}</label>
                                        <input type="text" class="form-control" id="officeNo" formControlName="officeNo"
                                          placeholder="Enter Office No" (keypress)="numberOnly($event)">
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('officeNo').errors && supplierLibraryForm.get('Contactss').get('contactOrder1').get('officeNo').value && supplierLibraryForm.get('Contactss').get('contactOrder1').get('officeNo').value.length > 10"
                                          class="red">
                                          {{staticMsgs.supplierDetails.officeNoAllowCharecters}} </div>
                                      </div>

                                      <div class="col-lg-3 mb-3">
                                        <label for="ext">{{staticMsgs.supplierDetails.Ext}}</label>
                                        <input type="text" class="form-control" id="ext" formControlName="ext"
                                          placeholder="Enter Ext (+123)" (keypress)="extNumberOnly($event)">
                                          <div
                                          *ngIf="this.f['Contactss']['controls']['contactOrder1'].controls['ext'].errors?.pattern"
                                          class="red">
                                          ext is invalid.
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mb-3">
                                        <label for="fax">{{staticMsgs.supplierDetails.Fax}}</label>
                                        <input type="text" class="form-control" id="fax" formControlName="fax"
                                          placeholder="Enter Fax" (keypress)="faxNumberOnly($event)">
                                          <div
                                          *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').dirty || supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').touched))">
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').errors.pattern"
                                            class="red">
                                            {{staticMsgs.supplierDetails.Fax+staticMsgs.common.invalid}}
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-3 mt-3 mb-3">
                                        <label for="contactNo">{{staticMsgs.supplierDetails.PhoneNo}}<span
                                            class="red_icon">*</span></label>
                                        <input type="text" class="form-control no-arrow" id=""
                                          formControlName="contactNo" placeholder="Enter Phone Number "
                                          (keypress)="numberOnly($event)">
                                        <!-- <input type="number" class="form-control no-arrow" id="contactNo" formControlName="contactNo" placeholder="Enter Phone Number" maxlength="10"> -->
                                        <div
                                          *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactNo').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactNo').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactNo').dirty || supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactNo').touched))">
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactNo').errors.required"
                                            class="red">
                                            {{staticMsgs.supplierDetails.MobileNumber+staticMsgs.common.required}}
                                          </div>
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactNo').errors.pattern"
                                            class="red">
                                            {{staticMsgs.supplierDetails.MobileNumber+staticMsgs.common.invalid}}
                                          </div>
                                        </div>

                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="contactEmail">{{staticMsgs.supplierDetails.Email}} <span
                                            class="red_icon">*</span></label>
                                        <input type="text" class="form-control" id="" formControlName="contactEmail"
                                          placeholder="Enter Email 5665656565654" required>
                                        <div
                                          *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactEmail').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactEmail').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactEmail').dirty || supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactEmail').touched))">
                                          <div class="red"
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactEmail').errors.required">
                                            {{staticMsgs.supplierDetails.Email+staticMsgs.common.required}}
                                          </div>
                                          <div class="red"
                                            *ngIf="!supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactEmail').errors.required && supplierLibraryForm.get('Contactss').get('contactOrder1').get('contactEmail').errors.pattern">
                                            {{staticMsgs.supplierDetails.Email+staticMsgs.common.invalid}}
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="country">{{staticMsgs.supplierDetails.Country}}</label>
                                        <select title="Select Country" id="country" formControlName="country"
                                          class="form-control selectpicker w-100" data-live-search="true"
                                          (change)="changeCountry($event,'')">
                                          <option value="" [disabled]="true" selected>
                                            {{staticMsgs.supplierDetails.SelectCountry}}
                                          </option>
                                          <option *ngFor="let country of country" [value]="country.country">{{
                                            country.country }}</option>

                                        </select>

                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="state">{{staticMsgs.supplierDetails.State}}</label>
                                        <select title="Select State" id="state" formControlName="state"
                                          class="form-control selectpicker w-100" data-live-search="true">
                                          <option value="" [disabled]="true" selected>
                                            {{staticMsgs.supplierDetails.SelectState}}</option>
                                          <option *ngFor="let states of allStates" [value]="states.value">{{
                                            states.label }}</option>

                                        </select>
                                        <!-- <div *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('state').errors && supplierLibraryForm.get('Contactss').get('contactOrder1').get('state').value && supplierLibraryForm.get('Contactss').get('contactOrder1').get('state').value.length > 50"
                                                                                class="red">
                                                                                State is allowed 50 characters only.
                                                                            </div> -->
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="city">{{staticMsgs.supplierDetails.City}}</label>
                                        <input type="text" class="form-control" id="" formControlName="city"
                                          placeholder="Enter City">
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('city').errors && supplierLibraryForm.get('Contactss').get('contactOrder1').get('city').value && supplierLibraryForm.get('Contactss').get('contactOrder1').get('city').value.length > 50"
                                          class="red">
                                          {{staticMsgs.supplierDetails.CityAllows}}
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="zipCode">{{staticMsgs.supplierDetails.ZipCode}}</label>
                                        <input type="text" class="form-control" id="" formControlName="zipCode"
                                          placeholder="Enter Zip Code" (keypress)="numberOnly($event)">
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('zipCode').errors && supplierLibraryForm.get('Contactss').get('contactOrder1').get('zipCode').value && supplierLibraryForm.get('Contactss').get('contactOrder1').get('zipCode').value.length > 6"
                                          class="red">
                                          {{staticMsgs.supplierDetails.ZipCodeAllow}}
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="address">{{staticMsgs.supplierDetails.Address}}</label>
                                        <textarea cols="2" formControlName="address" placeholder="Enter Address"
                                          class="form-control"></textarea>
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('address').errors && supplierLibraryForm.get('Contactss').get('contactOrder1').get('address').value && supplierLibraryForm.get('Contactss').get('contactOrder1').get('address').value.length > 500"
                                          class="red">
                                          {{staticMsgs.supplierDetails.AddressIsAllow}}
                                        </div>
                                      </div>
                                      <input type="hidden" class="form-control" id="" formControlName="id">


                                    </div>
                                  </div>
                                </div>


                              </div>

                              <div class="card mt-4" id="accordion-example" *ngIf="secondcontact2"
                                formGroupName="contactOrder2">
                                <div class="card-header card-header-acc" id="headingtwo">
                                  <h5 class="mb-0">
                                    <button class="btn btn-link" type="button">
                                      {{staticMsgs.supplierDetails.SecondaryContact}}
                                    </button>
                                    <i class="fa fa-angle-down down" data-toggle="collapse"
                                      data-target="#collapse-three" aria-expanded="true" aria-controls="collapse-three"
                                      aria-hidden="true"></i>
                                    <button class="btn btn-primary btn-esm float-right mr-3 mt-1"
                                      (click)="secondContact2()" type="button">
                                      -{{staticMsgs.supplierDetails.Remove}}
                                    </button>
                                  </h5>

                                </div>

                                <div id="collapse-three" class="collapsed" aria-labelledby="headingthree"
                                  data-parent="#accordion-example">
                                  <div class="card-body-acc">
                                    <div class="form-row mt-5">
                                      <div class="form-row" [hidden]="true">
                                        <input type="text" class="form-control" value="" id=""
                                          formControlName="contactOrder">
                                      </div>
                                      <div class="col-lg-3 mb-3">
                                        <label for="fullName">{{staticMsgs.supplierDetails.Name}}</label>
                                        <input type="text" class="form-control" formControlName="fullName" id="" minlength="3"
                                         maxlength="50" placeholder="Enter Name">
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('fullName').errors && supplierLibraryForm.get('Contactss').get('contactOrder2').get('fullName').value && supplierLibraryForm.get('Contactss').get('contactOrder2').get('fullName').value.length > 50"
                                          class="red">
                                          {{staticMsgs.supplierDetails.Namevalidation}}
                                        </div>
                                        <!-- <div *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('fullName').value.minlength"  class="red">
                                          {{staticMsgs.common.minimum3charactersrequired}}
                                        </div> -->
                                      </div>

                                      <div class="col-lg-3 mb-3">
                                        <label for="officeNo">{{staticMsgs.supplierDetails.OfficeNo}}</label>
                                        <input type="text" class="form-control" formControlName="officeNo" id=""
                                          placeholder="Enter Office No" (keypress)="numberOnly($event)">
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('officeNo').errors && supplierLibraryForm.get('Contactss').get('contactOrder2').get('officeNo').value && supplierLibraryForm.get('Contactss').get('contactOrder2').get('officeNo').value.length > 10"
                                          class="red">
                                          {{staticMsgs.supplierDetails.officeNoAllowCharecters}}
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mb-3">
                                        <label for="ext">{{staticMsgs.supplierDetails.Ext}}</label>
                                        <input type="text" class="form-control" formControlName="ext" id=""
                                          placeholder="Enter Ext (+123)" (keypress)="extNumberOnly($event)">
                                          <div
                                          *ngIf="this.f['Contactss']['controls']['contactOrder2'].controls['ext'].errors?.pattern"
                                          class="red">
                                          ext is invalid.
                                        </div>
                                      </div>

                                      <div class="col-lg-3  mb-3">
                                        <label for="fax">{{staticMsgs.supplierDetails.Fax}}</label>
                                        <input type="text" class="form-control" id="" formControlName="fax"
                                          placeholder="Enter Fax" (keypress)="faxNumberOnly($event)">
                                          <div
                                          *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').dirty || supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').touched))">
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder1').get('fax').errors.pattern"
                                            class="red">
                                            {{staticMsgs.supplierDetails.Fax+staticMsgs.common.invalid}}
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-3 mt-3 mb-3">
                                        <label for="contactNo">{{staticMsgs.supplierDetails.PhoneNo}}</label>

                                        <input type="text" class="form-control no-arrow" id=""
                                          formControlName="contactNo" placeholder="Enter Phone Number " maxlength="10"
                                          (keypress)="numberOnly($event)">
                                        <div
                                          *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactNo').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactNo').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactNo').dirty || supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactNo').touched))">
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactNo').errors.required"
                                            class="red">
                                            {{staticMsgs.supplierDetails.MobileNumber+staticMsgs.common.required}}
                                          </div>
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactNo').errors.pattern"
                                            class="red">
                                            {{staticMsgs.supplierDetails.MobileNumber+staticMsgs.common.invalid}}
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="contactEmail">{{staticMsgs.supplierDetails.Email}}</label>
                                        <input type="text" class="form-control" formControlName="contactEmail" id=""
                                          placeholder="Enter Email">
                                        <div
                                          *ngIf="(supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactEmail').errors) || ( supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactEmail').invalid && (supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactEmail').dirty || supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactEmail').touched))">
                                          <div
                                            *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('contactEmail').errors.pattern">
                                            {{staticMsgs.supplierDetails.Email+staticMsgs.common.invalid}}
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="country">{{staticMsgs.supplierDetails.Country}}</label>
                                        <select title="Select Country" id="country" formControlName="country"
                                          class="form-control selectpicker w-100" data-live-search="true"
                                          (change)="changeCountry($event,'')">
                                          <option value="" [disabled]="true" selected>
                                            {{staticMsgs.supplierDetails.SelectCountry}}
                                          </option>
                                          <option *ngFor="let country of country" [value]="country.country">{{
                                            country.country }}</option>

                                        </select>
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="state">{{staticMsgs.supplierDetails.State}}</label>
                                        <select title="Select State" id="state" formControlName="state"
                                          class="form-control selectpicker w-100" data-live-search="true">
                                          <option value="" [disabled]="true" selected>
                                            {{staticMsgs.supplierDetails.SelectState}}</option>
                                          <option *ngFor="let states of allStates" [value]="states.value">{{
                                            states.label }}</option>

                                        </select>
                                        <!-- <div *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('state').errors && supplierLibraryForm.get('Contactss').get('contactOrder2').get('state').value && supplierLibraryForm.get('Contactss').get('contactOrder2').get('state').value.length > 50"
                                                                                class="red">
                                                                                State is allowed 50 characters only.
                                                                            </div> -->
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="city">{{staticMsgs.supplierDetails.City}}</label>
                                        <input type="text" class="form-control" id="" formControlName="city"
                                          placeholder="Enter City">
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('city').errors && supplierLibraryForm.get('Contactss').get('contactOrder2').get('city').value && supplierLibraryForm.get('Contactss').get('contactOrder2').get('city').value.length > 50"
                                          class="red">
                                          {{staticMsgs.supplierDetails.CityAllows}}
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="zipCode">{{staticMsgs.supplierDetails.ZipCode}}</label>
                                        <input type="text" class="form-control" id="" formControlName="zipCode"
                                          placeholder="Enter Zip Code" (keypress)="numberOnly($event)">
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('zipCode').errors && supplierLibraryForm.get('Contactss').get('contactOrder2').get('zipCode').value && supplierLibraryForm.get('Contactss').get('contactOrder2').get('zipCode').value.length > 6"
                                          class="red">
                                          {{staticMsgs.supplierDetails.ZipCodeAllow}}
                                        </div>
                                      </div>

                                      <div class="col-lg-3 mt-3 mb-3">
                                        <label for="address">{{staticMsgs.supplierDetails.Address}}</label>
                                        <textarea cols="2" formControlName="address" placeholder="Enter Description"
                                          class="form-control"></textarea>
                                        <div
                                          *ngIf="supplierLibraryForm.get('Contactss').get('contactOrder2').get('address').errors && supplierLibraryForm.get('Contactss').get('contactOrder2').get('address').value && supplierLibraryForm.get('Contactss').get('contactOrder2').get('address').value.length > 500"
                                          class="red">
                                          {{staticMsgs.supplierDetails.AddressIsAllow}}
                                        </div>
                                      </div>
                                      <input type="hidden" class="form-control" id="contactid" formControlName="id">
                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>

                          </div>


                          <div class="form-row mt-5 float-right">
                            <input type="text" class="form-control" [hidden]="true" id="supplierid"
                              formControlName="supplierid" placeholder="Supplier Id" />


                            <div class="row mt-10">
                              <button type="button" *ngIf="!isEditMode" (click)="cancelSupplierCreate()"
                                class="btn btn-outline-primary adv-search-btn mr-3">
                                {{staticMsgs.supplierDetails.Cancel}}
                              </button>
                              <button type="submit" *ngIf="!isEditMode"
                                class="btn btn-primary adv-search-btn" [disabled]=" supplierLibraryForm?. invalid">
                                {{staticMsgs.supplierDetails.Create}}</button>
                              <button type="submit" *ngIf="isEditMode" [disabled]="supplierLibraryForm?.invalid"
                               class="btn btn-primary adv-search-btn">{{staticMsgs.supplierDetails.Update}}</button>
                            </div>

                            <!-- <button type="submit" *ngIf="!isEditMode" class="btn btn-primary adv-search-btn">Create</button>
                                                    <button type="submit" *ngIf="isEditMode" class="btn btn-primary adv-search-btn">Update</button> -->
                          </div>
                        </form>
                      </div>
                      <div *ngIf="isViewingMode">

                        <div id="tab-pane1" class="tab-pane active">

                          <div class="dt-entry__header mt-5">
                            <!-- Entry Heading -->
                            <div class="dt-entry__heading">
                              <h3 class="dt-entry__title">{{this.f.classification.value}}</h3>
                            </div>
                            <!-- /entry heading -->
                          </div>
                          <form>
                            <div class="form-row">

                              <div class="col-lg-12 col-sm-12 test">
                                <div class="form-row">
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="this.f.sequence.value">{{staticMsgs.supplierDetails.iD}}</label>
                                    <p class="mt-2 viewtext">{{this.f.sequence.value}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="this.f.name.value">{{staticMsgs.supplierDetails.Name}}</label>
                                    <p class="mt-2 viewtext">{{this.f.name.value}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="this.phone_number">{{staticMsgs.supplierDetails.PhoneNo}}</label>
                                    <p class="mt-2 viewtext">{{this.phone_number}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="this.f.email.value">{{staticMsgs.supplierDetails.Email}}</label>
                                    <p class="mt-2 viewtext">{{this.f.email.value}}</p>
                                  </div>
                                  <!-- <div class="col-sm-4 mb-3" *ngIf="this.f.SupplierChainPartner.value">
                                                      <label class="label-view" for="this.f.SupplierChainPartner.value">Supplier Chain Partner</label>
                                                      <p class="mt-2 viewtext">{{this.f.SupplierChainPartner.value}}</p>
                                                    </div> -->

                                  <div class="col-sm-12" *ngIf="this.f.classification.value!=='Agent'">
                                    <div class="row">
                                      <div class="col-sm-4 mb-3">
                                        <label class="label-view"
                                          for="this.f.productType.value">{{staticMsgs.supplierDetails.ProductType}}</label>
                                        <p class="mt-2 viewtext">{{this.f.productType.value}}</p>
                                      </div>
                                      <div class="col-sm-4 mb-3">
                                        <label class="label-view"
                                          for="{{this.f.speciality.value}}">{{staticMsgs.supplierDetails.Speciality}}</label>
                                        <p class="mt-2 viewtext">{{this.f.speciality.value}}</p>
                                      </div>
                                      <div class="col-sm-4 mb-3">
                                        <label class="label-view"
                                          for="{{this.moqee}}">{{staticMsgs.supplierDetails.mOQ}}</label>
                                        <p class="mt-2 viewtext">{{this.moqee}}</p>
                                      </div>

                                      <div class="col-sm-4 mb-3">
                                        <label class="label-view"
                                          for="{{this.f.moqUOM.value}}">{{staticMsgs.supplierDetails.mOQUOM}}</label>
                                        <p class="mt-2 viewtext">{{this.f.moqUOM.value}}</p>
                                      </div>

                                      <div class="col-sm-4 mb-3">
                                        <label class="label-view"
                                          for="{{this.f.leadTime.value}}">{{staticMsgs.supplierDetails.LeadTime}}</label>
                                        <p class="mt-2 viewtext">{{this.f.leadTime.value}}</p>
                                      </div>
                                      <div class="col-sm-4 mb-3">
                                        <label class="label-view"
                                          for="{{this.f.capacity.value}}">{{staticMsgs.supplierDetails.Capacity}}</label>
                                        <p class="mt-2 viewtext">{{this.f.capacity.value}}</p>
                                      </div>
                                      <div class="col-sm-4 mb-3">
                                        <label class="label-view"
                                          for="{{this.f.capacityUOM.value}}">{{staticMsgs.supplierDetails.CapacityUOM}}</label>
                                        <p class="mt-2 viewtext">{{this.f.capacityUOM.value}}</p>
                                      </div>
                                      <!-- <div class="col-sm-3">
                                                          <label for="validationDefault02"></label>
                                                          <p class="mt-3 mb-1 viewtext">CoverStitch- 3</p>
                                                          <p class="mt-1  viewtext">12</p>
                                                          </div> -->
                                    </div>
                                  </div>
                                  <div class="col-sm-12" *ngIf="this.f.classification.value == 'Factory'">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.MachineType}}</label>
                                    <div class="row">
                                    <div class="col-sm-4" *ngFor="let item of done; let i = index;">
                                      <label class="label-view" for="validationDefault02"></label>
                                      <p class="mt-3 mb-1  viewtext">{{item}}</p>
                                      <p class="mt-1 viewtext" *ngIf="i == 0">{{this.f.machineTypes0.value}}</p>
                                      <p class="mt-1 viewtext" *ngIf="i == 1">{{this.f.machineTypes1.value}}</p>
                                      <p class="mt-1 viewtext" *ngIf="i == 2">{{this.f.machineTypes2.value}}</p>
                                      <p class="mt-1 viewtext" *ngIf="i == 3">{{this.f.machineTypes3.value}}</p>
                                      <p class="mt-1 viewtext" *ngIf="i == 4">{{this.f.machineTypes4.value}}</p>
                                    </div>
                                  </div>
                                  </div>

                                </div>

                            </div>
                            </div>
                          </form>


                          <!-- <div class="dt-entry__header mt-5">
                            <div class="dt-entry__heading">
                              <h3 class="dt-entry__title">{{staticMsgs.supplierDetails.AuditStatus}}</h3>
                            </div>
                          </div>

                          <form>
                            <div class="form-row">

                              <div class="col-lg-12 col-sm-12">
                                <div class="form-row">
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="this.f.lastSocialAudit.value">{{staticMsgs.supplierDetails.LastAudit}}</label>
                                    <p class="mt-2 viewtext">{{this.f.lastSocialAudit.value}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="this.f.auditStatus.value">{{staticMsgs.supplierDetails.AuditStatus}}</label>
                                    <p class="mt-2 viewtext">{{this.f.auditStatus.value}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="{this.f.description.value">{{staticMsgs.supplierDetails.Comments}}</label>
                                    <p class="mt-2 viewtext">{{this.f.description.value}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </form> -->

                          <div class="dt-entry__header mt-5">
                            <!-- Entry Heading -->
                            <div class="dt-entry__heading">
                              <h3 class="dt-entry__title">{{staticMsgs.supplierDetails.PrimaryContact}}</h3>
                            </div>
                            <!-- /entry heading -->
                          </div>

                          <form *ngIf="contactTemp0">
                            <div class="form-row">

                              <div class="col-lg-12 col-sm-12">
                                <div class="form-row">

                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="contactTemp0.fullName">{{staticMsgs.supplierDetails.Name}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.fullName}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="contactTemp0.officeNo">{{staticMsgs.supplierDetails.OfficeNo}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.officeNo}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.Ext}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.ext}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.Fax}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.fax}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.PhoneNo}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.contactNo}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.Email}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.contactEmail}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.Country}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.country}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.State}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.state}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.City}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.city}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.Address}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.address}}</p>
                                  </div>
                                  <div class="col-sm-4 mb-3">
                                    <label class="label-view"
                                      for="validationDefault02">{{staticMsgs.supplierDetails.ZipCode}}</label>
                                    <p class="mt-2 viewtext">{{contactTemp0.zipCode}}</p>
                                  </div>
                                  <!-- <div class="col-sm-4 mb-3">
                                              <label for="validationDefault02">Description</label>
                                              <p class="mt-2 viewtext">Pick a color from swatch.</p>
                                            </div> -->
                                </div>
                              </div>
                            </div>
                          </form>

                          <div *ngIf="secondcontact2">
                            <div class="dt-entry__header mt-5">
                              <!-- Entry Heading -->
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">{{staticMsgs.supplierDetails.SecondaryContact}}</h3>
                              </div>
                              <!-- /entry heading -->
                            </div>

                            <form *ngIf="conta[1]">
                              <div class="form-row">

                                <div class="col-lg-12 col-sm-12">
                                  <div class="form-row">
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault01">{{staticMsgs.supplierDetails.Name}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.fullName}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault01">{{staticMsgs.supplierDetails.OfficeNo}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.officeNo}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.Ext}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.ext}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.Fax}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.fax}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.PhoneNo}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.contactNo}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.Email}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.contactEmail}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.Country}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.country}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.State}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.state}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.City}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.city}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.Address}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.address}}</p>
                                    </div>
                                    <div class="col-sm-4 mb-3">
                                      <label class="label-view"
                                        for="validationDefault02">{{staticMsgs.supplierDetails.ZipCode}}</label>
                                      <p class="mt-2 viewtext">{{contactTemp1.zipCode}}</p>
                                    </div>
                                    <!-- <div class="col-sm-4 mb-3">
                                              <label for="validationDefault02">Description</label>
                                              <p class="mt-2 viewtext">Pick a color from swatch.</p>
                                            </div> -->
                                  </div>
                                </div>
                              </div>


                            </form>
                          </div>
                        </div>

                      </div>
                    </div>

                    <!-- /tab panel -->

                    <!-- Tab panel -->

                    <div id="tab-pane2" class="tab-pane card-body card-body-shadow bg-white"
                      [ngClass]="{ 'active':activeTab === 'docDetailsTab'}">
                      <div *ngIf="isEditingMode">
                        <form [formGroup]="documentsForm" (ngSubmit)="onDocSubmit()">
                          <div formArrayName="documents">
                            <div *ngFor="let document of getControls(); let i=index">
                              <div class="card p-5" [formGroupName]="i">
                                <div class="form-row ">
                                  <div class="col-sm-12 float-right">
                                    <button type="button" class="close" (click)="removeDoc(i)"><span
                                        aria-hidden="true">x</span></button>
                                  </div>
                                </div>

                                <div class="form-row">

                                  <div class="col-lg-4 col-sm-12">
                                    <label
                                      for="docclassification">{{staticMsgs.supplierDetails.SelectClassification}}<span
                                        class="red_icon">*</span></label>
                                    <input type="text" class="form-control" id="docclassification{{i}}"
                                      formControlName="docclassification" placeholder="Classification" required />
                                    <div
                                      *ngIf="(submitted1 && document.get('docclassification').errors) || ( document.get('docclassification').invalid && (document.get('docclassification').dirty || document.get('docclassification').touched))">
                                      <div *ngIf="document.get('docclassification').errors.required" class="red">
                                        {{staticMsgs.supplierDetails.Classification+staticMsgs.common.required}}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-4 mb-3" *ngIf="document.get('docid').value">
                                    <label for="docseq"> {{staticMsgs.supplierDetails.Document}}#</label>
                                    <input type="text" class="form-control" [readonly]="true" id="docseq"
                                      formControlName="docseq" placeholder="Document Id" />
                                  </div>

                                  <div class="col-lg-4 col-sm-12">
                                    <label for="docname">{{staticMsgs.supplierDetails.Name}}<span
                                        class="red_icon">*</span></label>
                                    <input type="text" class="form-control" id="docname" formControlName="docname"
                                      placeholder="Document Name" (keypress)="characterOnly($event)" maxlength="50" minlength="3" required/>
                                    <div
                                      *ngIf="(submitted1 && document.get('docname').errors) || ( document.get('docname').invalid && (document.get('docname').dirty || document.get('docname').touched))">
                                      <div *ngIf="document.get('docname').errors.required" class="red">
                                        {{staticMsgs.supplierDetails.Status+staticMsgs.supplierDetails.Name+staticMsgs.common.required}}
                                      </div>
                                      <div *ngIf="document.get('docname').errors.minlength" class="red">
                                        {{staticMsgs.common.minimum3charactersrequired}}
                                       </div>
                                    </div>

                                  </div>

                                </div>

                                <div class="form-row mt-5">
                                  <div class="col-sm-4 mb-3">
                                    <label for="docstatus">{{staticMsgs.supplierDetails.Status}}<span
                                        class="red_icon">*</span></label>
                                    <select type="text" class="form-control selectpicker" id="docstatus"
                                      formControlName="docstatus" required>
                                      <!-- <option value="" [disabled]="true" selected>
                                        {{staticMsgs.supplierDetails.SelectStatus}}
                                      </option> -->
                                      <option value="Active">{{staticMsgs.supplierDetails.Active}}</option>
                                      <option value="Inactive">{{staticMsgs.supplierDetails.InActive}}</option>
                                    </select>
                                    <div
                                      *ngIf="(submitted1 && document.get('docstatus').errors) || ( document.get('docstatus').invalid && (document.get('docstatus').dirty || document.get('docstatus').touched))">
                                      <div *ngIf="document.get('docstatus').errors.required" class="red">
                                        {{staticMsgs.supplierDetails.Status+staticMsgs.common.required}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-8 mb-3">
                                    <label for="docdescription">{{staticMsgs.supplierDetails.Description}}<span
                                        class="red_icon">*</span></label>
                                    <textarea cols="2" class="form-control" id="docdescription"
                                      formControlName="docdescription" required></textarea>
                                    <div
                                      *ngIf="(submitted1 && document.get('docdescription').errors) || ( document.get('docdescription').invalid && (document.get('docdescription').dirty || document.get('docdescription').touched))">
                                      <div *ngIf="document.get('docdescription').errors.required" class="red">
                                        {{staticMsgs.supplierDetails.Description+staticMsgs.common.required}}
                                      </div>
                                      <div class="red"
                                        *ngIf="document.get('docdescription').value && document.get('docdescription').value.length > 500">
                                        {{staticMsgs.supplierDetails.DescriptionAllows}}
                                      </div>
                                    </div>

                                  </div>

                                </div>

                                <div class="dt-entry__header mt-5"
                                  *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                  <div class="dt-entry__heading">
                                    <h3 class="dt-entry__title">
                                      {{document.get('docclassification').value}}</h3>
                                  </div>
                                </div>

                                <div class="form-row" *ngIf="document.get('docclassification').value === 'Lab Reports'">

                                  <div class="col-sm-4 mb-3">
                                    <label for="docreportstatus">{{staticMsgs.supplierDetails.ReportStatus}}<span
                                        class="red_icon">*</span></label>
                                    <select type="text" class="form-control selectpicker" id="docreportstatus"
                                      formControlName="docreportstatus" required>
                                      <option value="" [disabled]="true" selected>
                                        {{staticMsgs.supplierDetails.SelectReportStatus}}
                                      </option>
                                      <option value="Pass">{{staticMsgs.supplierDetails.Pass}}</option>
                                      <option value="Fail">{{staticMsgs.supplierDetails.Fail}}</option>
                                    </select>
                                    <div
                                      *ngIf="(submitted1 && document.get('docreportstatus').errors) || ( document.get('docreportstatus').invalid && (document.get('docreportstatus').dirty || document.get('docreportstatus').touched))">
                                      <div *ngIf="document.get('docreportstatus').errors.required" class="red">
                                        {{staticMsgs.supplierDetails.ReportStatus+staticMsgs.common.required}}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-4 mb-3">
                                    <label for="doclablocation">{{staticMsgs.supplierDetails.LabLocation}}<span
                                        class="red_icon">*</span></label>
                                    <input type="text" class="form-control" id="doclablocation"
                                      formControlName="doclablocation" placeholder="Lab Location" required />
                                    <div
                                      *ngIf="(submitted1 && document.get('doclablocation').errors) || ( document.get('doclablocation').invalid && (document.get('doclablocation').dirty || document.get('doclablocation').touched))">
                                      <div *ngIf="document.get('doclablocation').errors.required" class="red">
                                        {{staticMsgs.supplierDetails.LabLocation+staticMsgs.common.required}}

                                      </div>
                                      <div class="red"
                                        *ngIf="document.get('doclablocation').value && document.get('doclablocation').value.length > 50">
                                        {{staticMsgs.supplierDetails.LabLocationAllow}}
                                      </div>
                                    </div>

                                  </div>

                                </div>

                                <div class="dt-entry__header mt-5">
                                  <div class="dt-entry__heading">
                                    <h3 class="dt-entry__title">{{staticMsgs.supplierDetails.Document}}</h3>
                                  </div>
                                </div>





                                <div class="form-row" [hidden]="true">
                                  <input type="text" class="form-control" id="docdeletedfileids"
                                    formControlName="docdeletedfileids" placeholder="Deleted File Ids">
                                </div>



                                <div class="form-row">
                                  <div class="col-xl-12">
                                    <label for="document">{{staticMsgs.common.docname}}<span
                                      class="red_icon">*</span></label>
                                    <div class="custom-dropzone" id="docupload" ngx-dropzone
                                      (change)="onSelectDoc($event,i)">
                                      <ngx-dropzone-label>
                                        <div>
                                          <h5 class="text-light-gray mt-3">
                                            {{staticMsgs.supplierDetails.DropFilesToUpload}}</h5>
                                        </div>
                                      </ngx-dropzone-label>
                                      <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview"
                                        *ngFor="let f of fileData[i]" [file]="f" [removable]="true"
                                        (removed)="onRemove(f,i)">
                                        <ngx-dropzone-label>{{ f.name }}
                                          <!--  ({{ f.type }}) -->
                                        </ngx-dropzone-label>
                                      </ngx-dropzone-preview>

                                    </div>
                                    <div *ngIf="doc_Error[i]">
                                      <span class="red">
                                        <span>
                                          {{doc_Error[i]}}
                                        </span></span>
                                    </div>
                                    <div class="col-xl-6 mt-5" *ngIf="document.get('docid').value">
                                      <div class="col-xl-6 mb-5" *ngFor="let img of editedFileData[i]; let j = index">
                                        <img src="{{img.thumbnail}}" class="img-fluid  img-fit "
                                          *ngIf="isValidImageURL(img.name)" (click)="open(i,j)">
                                        <a href="{{img.thumbnail}}" target="_blank" download><img src="{{documentImage}}"
                                            class="img-fluid  img-fit " *ngIf="!isValidImageURL(img.name)"></a>
                                        <button type="button" class="close image-close" (click)="removeImgSection(img,i)">
                                          <span aria-hidden="true">x</span>
                                        </button>
                                      </div>
                                    </div>


                                  </div>
                                </div>

                                <div class="form-row mt-5" *ngIf="(fileData[i]) && (!isEmptyObject(fileData[i]))">
                                  <div class="col-xl-12 ">
                                    <div class="card">
                                      <div class="dt-card__body p-2">
                                        <label for="docnotes">{{staticMsgs.supplierDetails.notes}}</label>
                                        <textarea cols="2" class="form-control" id="docnotes"
                                          formControlName="docnotes"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <input type="text" class="form-control" [hidden]="true" id="docid"
                                  formControlName="docid" placeholder="Document Id" />

                              </div>

                            </div>
                          </div>

                          <div class="form-row text-center mb-7">
                            <div class="col-sm-12">
                              <!-- <button type="button" class="btn btn-primary adv-search-btn">Select Existing</button> -->
                              <button type="button" class="btn btn-primary adv-search-btn" (click)="addNewDoc()"><i
                                  class="icon icon-plus"></i>{{staticMsgs.supplierDetails.AddNew}}</button>
                            </div>
                          </div>

                          <div class="form-row text-center mb-7" *ngIf='showButton'>
                            <div class="col-sm-12">
                              <button type="submit" [disabled]="documentsForm?.invalid || !isFilesAdded"
                                class="btn btn-primary adv-search-btn">{{staticMsgs.supplierDetails.Save}}</button>
                                <button type="button" *ngIf="isEditMode"
                                 (click)="cancelDocumentEdit()"
                                  class="btn btn-primary adv-search-btn">
                                 {{staticMsgs.common.cancel}}
                               </button>
                            </div>
                          </div>

                        </form>
                      </div>
                      <div *ngIf="isViewingMode">
                        <div class="text-center" *ngIf="supplierDataCnt == 0">
                          <h2>{{staticMsgs.common.nodataavailable}}</h2>
                          <img [src]="notfoundImg" class="img-fluid ">
                        </div>
                        <div *ngIf="supplierDataCnt!== 0">
                          <div *ngFor="let document of getControls(); let i=index">
                            <div class="dt-entry__header mt-5">
                              <!-- Entry Heading -->
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">
                                  {{document.get('docclassification').value}}</h3>
                              </div>
                              <!-- /entry heading -->
                            </div>
                            <form>
                              <div class="form-row">
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view"
                                    for="document.get('docseq').value}">{{staticMsgs.supplierDetails.Document}}
                                    #</label>
                                  <p class="mt-2 viewtext">{{document.get('docseq').value}}
                                  </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view"
                                    for="document.get('docname').value">{{staticMsgs.supplierDetails.Name}}</label>
                                  <p class="mt-2 viewtext">{{document.get('docname').value}}
                                  </p>
                                </div>
                                <div class="col-sm-4 mb-3">
                                  <label class="label-view"
                                    for="document.get('docstatus').value">{{staticMsgs.supplierDetails.Status}}</label>
                                  <p class="mt-2 viewtext">{{document.get('docstatus').value | titlecase}}
                                  </p>
                                </div>

                                <div class="col-sm-4 mb-3"
                                  *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                  <label class="label-view"
                                    for="document.get('docreportstatus').value">{{staticMsgs.supplierDetails.ReportStatus}}</label>
                                  <p class="mt-2 viewtext">
                                    {{document.get('docreportstatus').value}}</p>
                                </div>
                                <div class="col-sm-4 mb-3"
                                  *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                  <label class="label-view"
                                    for="document.get('doclablocation').value">{{staticMsgs.supplierDetails.LabLocation}}</label>
                                  <p class="mt-2 viewtext">
                                    {{document.get('doclablocation').value}}</p>
                                </div>

                                <div class="col-sm-8 mb-3">
                                  <label class="label-view"
                                    for="document.get('docdescription').value">{{staticMsgs.supplierDetails.Description}}</label>
                                  <p class="mt-2 viewtext">
                                    {{document.get('docdescription').value}}
                                  </p>
                                </div>
                                <div class="col-sm-8 mb-3" *ngIf="document.get('docnotes').value">
                                  <label class="label-view"
                                    for="document.get('docnotes').value">{{staticMsgs.supplierDetails.notes}}</label>
                                  <p class="mt-2 viewtext">{{document.get('docnotes').value}}
                                  </p>
                                </div>
                              </div>

                              <div class="form-row mt-2 ml-2 mr-2">
                                <div class="col-xl-3 mb-5" *ngFor="let img of editedFileData[i]; let j = index">
                                  <img src="{{img.thumbnail}}" class="img-fluid  img-fit "
                                    *ngIf="isValidImageURL(img.name)" (click)="open(i,j)">
                                  <a href="{{img.thumbnail}}" target="_blank" download><img src="{{documentImage}}"
                                      class="img-fluid  img-fit " *ngIf="!isValidImageURL(img.name)"></a>
                                </div>
                              </div>

                            </form>
                          </div>
                        </div>
                      </div>

                    </div>

                    <!-- /tab panel -->

                    <!-- Tab panel -->

                    <!-- /tab panel -->

                  </div>
                  <!-- /tab content-->

                </div>
                <!-- /card body -->

              </div>
              <!-- /card -->

            </div>
          </div>
          <!-- /grid item -->



        </div>
        <!-- /grid -->

      </div>


    </div>

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->

  </div>
  <!-- /Site Content Wrapper-->

</main>
