<div class="dt-page__header">
  <button class="close" mat-button (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="dt-page__title" *ngIf="!data.isPomedit">Create Specification</h3>
  <h3 class="dt-page__title" *ngIf="data.isPomedit">Edit Specification</h3>
</div>

<mat-dialog-content class="mat-typography">

  <svg *ngIf="pomload " viewBox="25 25 50 50">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>

  <div id="tab-pane" class="tab-pane bg-white active">
    <form [formGroup]="pomform" [ngClass]="pomload== true ? 'datahidden bom_modal_content' : 'bom_modal_content'">
      <div class="row">
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5" *ngFor="let item of pomdata; let i=index">
          <label for="validationDefault02">{{item}}</label>
          <input name="{{item}}" formControlName="{{item}}" (keypress)="numberOnly($event,item)" class="form-control fill"
            placeholder="Enter Operation">
        </div>
      </div>
      <div class="footer">
        <div class="row mt-5 float-right mr-3">
          <button type="submit" class="btn btn-outline-primary btn-sms mr-2" (click)="close()">
            Cancel
          </button>
          <button type="submit" [disabled]="pomform?.invalid" class="btn btn-primary btn-sm "
            (click)="pomSubmit()">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
