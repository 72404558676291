export class Moodboard {
    id: string | null;
    title: string | null;
    imageId: string | null;
    canvas: string | null;
    note: string | null;
    toDo: string | null;
    color: string | null;
    url: string | null;
    comment: string | null;
    highlight: string | null;
    status: string | null;
    deleteStatus: boolean | null;
    thumbnail: {} | null;
}