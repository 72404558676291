import * as imageActions from "../actions/image.actions";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface ImageState extends EntityState<any> {
    selectedImageId: string | null;
    loading: boolean;
    loaded: boolean;
    error: string;
    message: any | null;
}

export const imageAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const defaultImage: ImageState = {
    ids: [],
    entities: {},
    selectedImageId: null,
    loading: false,
    loaded: false,
    error: '',
    message: null
}

export const initialState = imageAdapter.getInitialState(defaultImage);

export function ImageReducer(state = initialState, action: imageActions.imageActionTypes): ImageState {
    switch (action.type) {
        case imageActions.ImageActionTypes.LOAD_IMAGE_LIBRARY_SUCCESS:
            return imageAdapter.addMany(action.payload1, {...state.message = action.payload2,
                ...state,
                error: '',
                loading: false,
                loaded: true
            })
            break;
        case imageActions.ImageActionTypes.LOAD_IMAGE_LIBRARY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }
            break;
        case imageActions.ImageActionTypes.LOAD_IMAGE_SUCCESS:
            return imageAdapter.setOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedImageId: action.payload1.id
            })
            break;
        case imageActions.ImageActionTypes.LOAD_IMAGE_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case imageActions.ImageActionTypes.CREATE_IMAGE_SUCCESS:
            return imageAdapter.addOne(action.payload1, {...state.message = action.payload2,
                ...state,
                selectedImageId: action.payload1.id
            })
            break;
        case imageActions.ImageActionTypes.CREATE_IMAGE_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case imageActions.ImageActionTypes.UPDATE_IMAGE_SUCCESS:
            return imageAdapter.updateOne(action.payload1, {...state.message = action.payload2, ...state })
            break;
        case imageActions.ImageActionTypes.UPDATE_IMAGE_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case imageActions.ImageActionTypes.UPDATE_IMAGE_LIBRARY_SUCCESS:
            return imageAdapter.updateMany(action.payload1, {...state.message = action.payload2,
                 ...state })
            break;
        case imageActions.ImageActionTypes.UPDATE_IMAGE_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        case imageActions.ImageActionTypes.DELETE_IMAGE_LIBRARY_SUCCESS:
            return imageAdapter.removeMany(action.payload1, {
                ...state.message = action.payload2, ...state })
            break;
        case imageActions.ImageActionTypes.DELETE_IMAGE_LIBRARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
            break;
        default: {
            return state;
        }
            break;
    }
}

const getImagesFeatureState = createFeatureSelector<ImageState>('image');

export const getImageLibarary = createSelector(
    getImagesFeatureState, imageAdapter.getSelectors().selectAll
)
export const getImagesLoading = createSelector(
    getImagesFeatureState, (state: ImageState) => state.loading
)
export const getImagesLoaded = createSelector(
    getImagesFeatureState, (state: ImageState) => state.loaded
)
export const getImagesError = createSelector(
    getImagesFeatureState, (state: ImageState) => state.error
)
export const getImagesMessage = createSelector(
    getImagesFeatureState, (state: ImageState) => state.message
)
export const getImageId = createSelector(
    getImagesFeatureState, (state: ImageState) => state.selectedImageId
)
export const getImage = createSelector(
    getImagesFeatureState, getImageId, state => state.entities[state.selectedImageId]
)