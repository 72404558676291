import { double } from "aws-sdk/clients/lightsail";

export interface IColor {

  name: string;
  internalRef: string;
  description: string;
  colorStandard: string;
  colourSwatch: string;
  r: string;
  g: string;
  b: string;
  pantoneCode: string;
  hexcode: string;
  thumbnail: string;
  id: string;
  createdOn: Date;
  modifiedOn: Date;
  classification: string;
  org: string;
  status: string;
  createdBy: string;
  modifiedBy: string
  isEdit: boolean;
  isDelete: boolean;

}

export class DeleteColor {
  recordIds: string[];
  constructor(recordIds: string[]) {
    this.recordIds = recordIds;
  }
}
export class Favourite {
  recordId: string;
  constructor(recordId: string) {
    this.recordId = recordId;
  }
}
export interface IColorResultDataModal {

  item1: IColor[];
  item2: string;

}
export class ColourDocuments {
  colourId: string;
  documentId: string;
  id: string;
  createdOn: Date;
  modifiedOn: Date;
  classification: string;
  org: string;
  status: string;
  createdBy: string;
  modifiedBy: string
}
export interface IResultDataModal {
  item1: string;
  item2: string;

}
export interface IFile {

  name: string;
  thumbnail: string;
  id: string;
  createdOn: Date;
  modifiedOn: Date;

}
export interface IFileResultDataModal {

  item1: IFile;
  item2: string;
  status: string; message: string;
}

export class DeleteItem {
  recordIds: string[];
  constructor(recordIds: string[]) {
    this.recordIds = recordIds;
  }
  
}
export class CalSubTotal {
price:number;
quantity:number;
}
