import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DataService } from '../../services/data.service';
import { LoaderComponent } from '../../../../../shared/shared-components/loader/loader.component';
import { NavigationService } from '../../../gaxon/components/navigation/navigation.service';
import { NavigationModel } from '../navigation/navigation.model';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../helpers/user.service';
import { StorageService } from '../../helpers/storage.service';
//import { Select2OptionData } from 'ng-Select2';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from '../../../../../aws-exports';
import { blob } from 'aws-sdk/clients/codecommit';
import * as S3 from 'aws-sdk/clients/s3';
import * as Crypto from 'crypto-js';

import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';
import { AESDecryptService } from '../../helpers/AESDecrypt.service';
import { parse } from 'path';
import { LogService } from '../../shared/log.service';
import { interval, of, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as userReducer from "../../reducers/user.reducer";
import * as userActionTypes from "../../actions/userActions.actions";
import * as commonActionTypes from "../../actions/common.actions";
import * as commonReducer from "../../reducers/common.reducer";
import { map, skip, take, tap } from 'rxjs/operators';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { timeZone } from '../Auth/register/timezone';
import * as appStaticMsg from '../../../assets/appStaticMsg.json';

declare var $: any;
Amplify.configure(awsconfig);

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    showLoader: boolean;
    showLoader1: boolean;
    profileForm: FormGroup;
    bussinessInfoForm: FormGroup;
    portfolioForm: FormGroup;
    activeTab = 'basicInfoTab';
    @ViewChild('lastSocialAudit') lastSocialAuditChild: any;
    // key = "01234567890123456789012345678901";
    submitted = false;
    submittedBusinessInfo = false;
    submittedPortfolio = false;
    isDateNull = false;
    agent = true;
    titleAgent = true;
    titlefactory = false;
    titleraw = false;
    chainPartner = false;
    ptype = false;
    factoryrawmaterial = false;
    machineType = false;
    selectvalue: any;
    isSupplierProfile = false;
    supplerId: any;
    supplierClassificationData: any[];
    supplierclassificationValue: string;
    isEditMode: boolean;
    editedFileData: Array<any> = [];
    editedSupplierData: any;
    secondcontact = true;
    secondcontact2 = false;
    secondarycontactId = "";
    files: File[] = [];
    viewfile: File[] = [];
    isThumbnailAdded: boolean;
    isDisabled: boolean = true;
    cards = [];
    pricontact: any[];
    moqee: any;
    capacityee: any;
    leadTimeee: any;
    errors = '';
    errorDiv = false;
    alert = '';
    machineTypearr = '';
    phone_number = '';
    //phone_number_code = '';
    public countrycodes: any[] = [];
    //public countrycodes: Array<Select2OptionData>;
    public roles: any[] = [];
    //public isDisabled: boolean = false;
    // public isprofileedit: boolean = true;
    public buttonShow: boolean = false;
    tabenableedit = true;
    tabdisableedit = false;
    // tabprofileedit = true;
    // tabprofiledisable = false;
    userImg = '';

    userImgfile: Blob;
    imageUploaded: boolean = false;
    conta: any;
    Swal = require('sweetalert2');
    public navigation: any[];
    log: any[];
    lastSocialAuditDate: NgbDate;
    minDate = { year: 1985, month: 1, day: 1 };

    currentUser = {
        firstname: '',
        lastname: '',
        email: '',
        userrole: '',
        phone_number: '',
        phone_number_code: '',
        thumb: '../../../assets/images/placeholder.jpg',
        usersubroleid: '',
        usersubrole: '',
        company: '',
        about: '',
        userid: '',
        timeZone: ''
    };

    portfolioDetails = {
        portfolioid: '',
        imageName: '',
        status: '',
        thumb: '../../../assets/images/placeholder.jpg',
    };
    TimeZone: any;
  staticMsgs: any;

    constructor(
        private sharedService: SharedService,
        private fb: FormBuilder,
        private router: Router,
        private navService: NavigationService,
        private dataservice: DataService,
        public userservice: UserService,
        public aesdecryptservice: AESDecryptService,
        private storage: StorageService,
        private logger: LogService,
        private auth: AuthService,
        private store: Store<any>) {
    }

    selectFile(event) {
        if (event.target.files) {
            this.userImgfile = event.target.files[0];
            this.imageUploaded = true;
            var reader = new FileReader()
            reader.readAsDataURL(this.userImgfile);
            reader.onload = (event: any) => {
                this.userImg = event.target.result;
            };
        }
        else {
            this.imageUploaded = false;
        }
    }

    tabenable() {
        this.isDisabled = false;
        this.tabenableedit = false;
        this.tabdisableedit = true;
        //this.profileform.enable();
        this.buttonShow = true;
        this.refreshSelect();

        if (this.activeTab === "basicInfoTab") {
            this.profileForm.enable();
            if (this.profileForm.controls['company'].value != "") {
                this.profileForm.controls['company'].disable();
            }
            //  if(this.storage.getUserSubRole() !== 'Administrator'){
            //     this.profileForm.controls['userrole'].disable();
            // }
        }
        else if (this.activeTab === "bussinessInfoTab") {
            this.bussinessInfoForm.enable();

        }
        else if (this.activeTab === "portfolioTab") {
            this.portfolioForm.enable();
        }
        else if (this.activeTab === "aboutTab") {
            this.profileForm.enable();
        }
    }

    tabdisable() {
        this.isDisabled = true;
        this.tabenableedit = true;
        this.tabdisableedit = false;
        //this.profileForm.disable();
        //this.initForm();
        this.submitted = false;
        this.buttonShow = false;
        this.userImg = this.currentUser.thumb;
        this.imageUploaded = false;
        this.errors = '';
        this.errorDiv = false;
        this.alert = '';

        this.submittedBusinessInfo = false;
        this.submittedPortfolio = false;

        if (this.activeTab === "basicInfoTab") {
            this.profileForm.disable();

            this.basicInfoDetailsTab(this.activeTab);
        }
        else if (this.activeTab === "bussinessInfoTab") {
            this.bussinessInfoForm.disable();
        }
        else if (this.activeTab === "portfolioTab") {
            this.portfolioForm.disable();
        }
        else if (this.activeTab === "aboutTab") {
            this.profileForm.disable();
            //this.initForm();
        }
    }

    //  profileenable() {
    //   // this.isDisabled = false;
    //   this.tabprofileedit = false;
    //   this.tabprofiledisable = true;
    //   this.isprofileedit = false;

    //  }

    //  profiledisable() {
    //   this.isprofileedit = true;
    //   this.tabprofileedit = true;
    //   this.tabprofiledisable = false;
    //  }

    ngOnInit(): void {
     this.staticMsgs=appStaticMsg.data[0];
      this.TimeZone = timeZone;
        this.showLoader = true;

        this.dataservice.AddClientLog();
        const source = interval(1.2e+8);
        source.subscribe(val => this.dataservice.AddClientLog());

        $(document).ready(function () {
            // $('.selectpicker').selectpicker();
            $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
            $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
            $("[data-toggle='popover']").popover({ html: true });
        });

        this.showLoader = false;
        this.getSupplierClassification("Suppliers");
        this.getUserDetails();
        this.initForm();
        this.refreshSelect();
        this.sharedService.sendClickEvent();
    }
    // addClientLog(){
    //     this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
    //     if(this.log!=null){
    //       this.dataservice.addClientLog(this.log).subscribe();;
    //       this.log=[];
    //       this.logger.clear();
    //     }

    //   }
    async getCountrycodes() {
        const data = await this.dataservice.getCountrycodes();
        this.countrycodes = this.countrycodes.concat(data['countrycodes']);
        this.logger.info(this.staticMsgs.profile.countryCode, "Profile");
        this.refreshSelect();
        // this.countrycodes = data['countrycodes'];
        // this.countrycodes = [
        // {id: 1, name: "+1", value: "+1"},
        // {id: 2, name: "+91", value: "+91"},
        // {id: 3, name: "+65", value: "+65"},
        // {id: 4, name: "+134", value: "+134"}
        // ]
    }

    getError(err: any) {
        if (err.error.message === "Network error communicating with endpoint") {
            this.error(err.error.message);
            this.showLoader = false;
        } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
            this.showLoader = false;
        } else if (err.error.message === "Unauthorized") {
            this.userservice.error();
            this.showLoader = false;
        }
        else {
            this.error(err.message);
        }
        this.showLoader = false;
    }

    getRolesList() {
        this.showLoader = true;
        this.store.dispatch(new userActionTypes.LoadUserRoles());
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userRoles) => {
            if (userRoles.user.error === "") {
                if (userRoles.user.message['item2'] === 'Success') {
                  this.logger.info(this.currentUser.firstname + this.staticMsgs.profile.detailSucc, "Profile");
                    this.roles = userRoles.user.message['item1'];
                    this.showLoader = false;
                    this.refreshSelect();
                } else {
                    this.roles = [];
                    this.showLoader = false;
                    this.error("Failed:" + userRoles.user.message['item1'])
                    this.logger.error(userRoles.user.message['item1'], "Profile");
                }
            } else {
                this.logger.error(userRoles.user.error.error.message, "Profile");
                this.getError(userRoles.user.error);
                this.showLoader = false;
            }
        })
        /* this.dataservice.getRolesList().subscribe((response) => {
            if (response.status === 200) {
                if (response.body['item2'] === "Success") {
                    if (response.body['item1'] != null && response.body['item1'].length > 0 && !response.body['item2'].toLowerCase().includes('error')) {
                        response.body['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataservice.Key, x.name)
                        });
                    }
                    this.roles = response.body['item1'];
                    this.showLoader = false;
                    this.logger.info("All role list loaded successfully", "Profile");
                    this.refreshSelect();
                }
                else {
                    this.roles = [];
                    this.showLoader = false;
                }

            }
            else {
                this.roles = [];
                this.showLoader = false;
            }
        }, err => {
            this.logger.error(err.error.message, "Profile");
            if (err.error.message === "Network error communicating with endpoint") {
                this.error(err.error.message);
                this.showLoader = false;
            } else if (err.error.message === "The incoming token has expired") {
                this.sessionTimedOut();
                this.showLoader = false;
            } else if (err.error.message === "Unauthorized") {
                this.userservice.error();
                this.showLoader = false;
            }
            else {
                this.error(err.message);
                this.logger.error(err.message, "Profile");

            }
            this.showLoader = false;
        }); */

    }

    async getUserDetails() {
        this.isSupplierProfile = false;
        this.showLoader1 = true;
        //let user = await Auth.currentUserInfo();
        let user = await this.userservice.getUserDetails();

        if (user['attributes'] === undefined) {
            this.sessionTimedOut();
            this.showLoader1 = false;
        }

        if (user['attributes']['phone_number']) {
            const phone = user['attributes']['phone_number'];
            this.currentUser.phone_number = phone.substr(phone.length - 10);
            this.currentUser.phone_number_code = phone.replace(this.currentUser.phone_number, "");
        }
        else {
            this.currentUser.phone_number = "";
            this.currentUser.phone_number_code = "+1";
        }

        this.store.dispatch(new userActionTypes.LoadUserDetails());
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userDetails) => {
            if (userDetails.user.error === "") {
                if (userDetails.user.message['item2'] === 'Success') {
                    setTimeout(() => {
                        $('.selectpicker').selectpicker();
                    }, 10);
                    //console.log(response.body)
                    this.getCountrycodes();
                    this.getRolesList();
                    this.showLoader1 = false;
                    this.currentUser.firstname = userDetails.user.message['item1']['firstName'];
                    this.currentUser.lastname = userDetails.user.message['item1']['lastName'];
                    this.currentUser.company = userDetails.user.message['item1']['org'];
                    //this.currentUser.company = userDetails.user.message['item1']['org'];
                    this.currentUser.email = userDetails.user.message['item1']['eMail'];
                    this.currentUser.about = userDetails.user.message['item1']['about'];
                    this.currentUser.timeZone = this.aesdecryptservice.decryptData(this.dataservice.Key,userDetails.user.message['item1']['timeZone']);
                    console.log('this.currentUser.timeZone',this.currentUser);
                    // this.currentUser.userid = this.aesdecryptservice.decryptData(this.dataservice.Key,userDetails.user.message['item1']['id']);

                    this.currentUser.userid = userDetails.user.message['item1']['id'];
                    if (userDetails.user.message['item1']['pictureID']) {
                        // this.currentUser.thumb = this.aesdecryptservice.decryptData(this.dataservice.Key,"uFG0zBBlggy/yTrtquFA+c7DTrE6h4P0j5vFMd0uEipJ+HBhV4Wt4aKz0vblq29N3NoIOWEGUzrtfUOtwUYn14UFm+Ncyl134bTotYBsNJVOcHsGXJ7URE6AQGm/Bow7nUNa3yaT3N5iTrq1FByshBnbMBMPN8MQae+cUGpIDIT3x9lOwVSvL9yHymXEFkWDGw/L3/dTbIynIwD/xfOKQR1jba4Xo8VHVZqidFNPtlE1xa4f0zvhWOfnZGFXB/9C")
                        this.currentUser.thumb = userDetails.user.message['item1']['picture']['thumbnail'];
                        this.userImg = this.currentUser.thumb;
                    }
                    this.currentUser.userrole = userDetails.user.message['item1']['group'];
                    // this.aesdecryptservice.decryptData(this.dataservice.Key,"ZDdX7i+Wz+DNj0JvczCH3Q==");
                    this.logger.info(this.currentUser.firstname + " details loaded successfully", "Profile");

                    this.refreshSelect();
                    if (userDetails.user.message['item1']['role']) {
                        if (userDetails.user.message['item1']['role'][0]) {

                            this.currentUser.usersubroleid = userDetails.user.message['item1']['role'][0]['role']['id'];
                            //this.currentUser.usersubrole =this.aesdecryptservice.decryptData(this.dataservice.Key,"fZ616lBDekOe0e1vfmgclQ==");
                            this.currentUser.usersubrole = userDetails.user.message['item1']['role'][0]['role']['name'];
                            //this.storage.storeUserSubRole(this.aesdecryptservice.decryptData(this.dataservice.Key,"fZ616lBDekOe0e1vfmgclQ=="))
                            this.storage.storeUserSubRole(userDetails.user.message['item1']['role'][0]['role']['name']);
                            if (this.currentUser.usersubrole == "Supplier") {
                                this.isSupplierProfile = true;
                            }
                        }
                    }
                    //portfolios
                    if (userDetails.user.message['item1']['portfolios']) {
                        if (userDetails.user.message['item1']['portfolios'][0]) {
                            this.portfolioDetails.portfolioid = userDetails.user.message['item1']['portfolios'][0]['id'];
                            this.portfolioDetails.imageName = userDetails.user.message['item1']['portfolios'][0]['name'];
                            this.portfolioDetails.status = userDetails.user.message['item1']['portfolios'][0]['status'];
                            this.viewfile = [];
                            this.viewfile.push(userDetails.user.message['item1']['portfolios'][0]['image']);
                            this.portfolioDetails.thumb = userDetails.user.message['item1']['portfolios'][0]['image']['thumbnail'];
                        }
                    }
                    this.initForm();
                    //Business info
                    if (userDetails.user.message['item1']['supplier']) {
                        if (userDetails.user.message['item1']['supplier'][0]) {

                            setTimeout(() => {
                                $('.selectpicker').selectpicker();
                            }, 10);
                            let supp = userDetails.user.message['item1']['supplier'][0];

                            //this.initForm();
                            this.bussinessInfoForm.get('classification').setValue(supp["classification"]);
                            this.refreshTree();

                            this.supplierclassificationValue = supp["classification"];
                            if (supp["classification"] === "Factory" || supp["classification"] === "Raw Material Supplier") {
                                this.bussinessInfoForm.get('productType').enable();
                                this.bussinessInfoForm.get('speciality').enable();
                                this.bussinessInfoForm.get('moq').enable();
                                this.bussinessInfoForm.get('leadTime').enable();
                                this.bussinessInfoForm.get('capacity').enable();
                                this.bussinessInfoForm.get('capacityUOM').enable();
                                setTimeout(() => {
                                    $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
                                }, 10);
                            } else {

                                this.bussinessInfoForm.get('productType').disable();
                                this.bussinessInfoForm.get('speciality').disable();
                                this.bussinessInfoForm.get('moq').disable();
                                this.bussinessInfoForm.get('leadTime').disable();
                                this.bussinessInfoForm.get('capacity').disable();
                                this.bussinessInfoForm.get('capacityUOM').disable();
                                setTimeout(() => {
                                    $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
                                }, 10);
                            }
                            if (supp["machineTypes"]) {
                                this.done = [];
                                this.machineTypearr = JSON.parse(supp["machineTypes"]);
                                let i = 0;
                                for (const [key, value] of Object.entries(this.machineTypearr)) {
                                    if (value['value']) {
                                        this.done.push(value['key']);
                                        this.todo = this.todo.filter(x => x != value['key']);
                                        this.bussinessInfoForm.get('machineTypes' + i).patchValue(value['value']);
                                        i++;
                                    }
                                }
                            }
                            //this.bussinessInfoForm.get('supplierid').setValue(supp["id"]);
                            this.supplerId = supp["id"];
                            //this.supplerId = response.body['item1']['role'][0]['role']['id'];
                            this.bussinessInfoForm.get('name').setValue(supp["name"]);
                            if (supp["phoneNumber"]) {
                                const phone = supp["phoneNumber"];
                                const phone1 = phone.substr(phone.length - 10);
                                const phonecode = phone.replace(phone1, "");
                                this.bussinessInfoForm.get('phoneNo').setValue(phone1);
                                this.bussinessInfoForm.get('phone_number_code').setValue(phonecode);
                            }
                            else {
                                this.bussinessInfoForm.get('phoneNo').setValue('');
                                this.bussinessInfoForm.get('phone_number_code').setValue('+1');
                            }

                            //this.bussinessInfoForm.get('phoneNo').setValue(supp["phoneNumber"]);
                            this.bussinessInfoForm.get('email').setValue(supp["email"]);
                            let lastSocialAudit = supp["lastSocialAudit"].includes('T') ? supp["lastSocialAudit"].split('T')[0] : supp["lastSocialAudit"];
                            this.bussinessInfoForm.get('lastSocialAudit').setValue(lastSocialAudit);
                            this.lastSocialAuditDate = new NgbDate((lastSocialAudit.split('-')[0] - 0), (lastSocialAudit.split('-')[1] - 0), (lastSocialAudit.split('-')[2] - 0));
                            this.bussinessInfoForm.get('lastSocialAudit').setValue(supp["lastSocialAudit"]);
                            this.bussinessInfoForm.get('auditStatus').setValue(supp["auditStatus"]);
                            this.bussinessInfoForm.get('description').setValue(supp["description"]);
                            this.bussinessInfoForm.get('productType').setValue(supp["productType"]);
                            this.bussinessInfoForm.get('SupplierChainPartner').setValue(supp["supplierChainPartner"]);
                            this.bussinessInfoForm.get('speciality').setValue(supp["speciality"]);
                            this.bussinessInfoForm.get('moq').setValue(supp["moq"]);
                            this.bussinessInfoForm.get('leadTime').setValue(supp["leadTime"]);
                            this.bussinessInfoForm.get('capacity').setValue(supp["capacity"]);
                            this.bussinessInfoForm.get('capacityUOM').setValue(supp["capacityUOM"]);
                            //this.bussinessInfoForm.get('machineTypes').setValue(supp["machineTypes"]);
                            if (supp["contact"] && supp["contact"].length > 0) {
                                this.conta = supp["contact"];
                                const contactForm = (this.bussinessInfoForm.controls['Contactss'] as FormGroup);
                                const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
                                const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
                                if (contact1) {

                                    contact1.patchValue({
                                        fullName: this.conta[0]["fullName"],
                                        officeNo: this.conta[0]["officeNo"],
                                        ext: this.conta[0]["ext"],
                                        fax: this.conta[0]["fax"],
                                        contactNo: this.conta[0]["contactNo"],
                                        contactEmail: this.conta[0]["contactEmail"],
                                        address: this.conta[0]["address"],
                                        country: this.conta[0]["country"],
                                        state: this.conta[0]["state"],
                                        city: this.conta[0]["city"],
                                        contactOrder: this.conta[0]["contactOrder"],
                                        zipCode: this.conta[0]["zipCode"],
                                        id: this.conta[0]["id"]
                                    });
                                }
                                if (contact2) {

                                    contact2.patchValue({

                                        fullName: this.conta[1]["fullName"],
                                        officeNo: this.conta[1]["officeNo"],
                                        ext: this.conta[1]["ext"],
                                        fax: this.conta[1]["fax"],
                                        contactNo: this.conta[1]["contactNo"],
                                        contactEmail: this.conta[1]["contactEmail"],
                                        address: this.conta[1]["address"],
                                        country: this.conta[1]["country"],
                                        state: this.conta[1]["state"],
                                        city: this.conta[1]["city"],
                                        contactOrder: this.conta[1]["contactOrder"],
                                        zipCode: this.conta[1]["zipCode"],
                                        id: this.conta[1]["id"]
                                    });
                                    this.secondContact();
                                }
                            }
                            this.bussinessInfoForm.disable();
                        }
                    } else {
                        this.refreshTree();
                    }
                } else {
                    this.showLoader1 = false;
                    this.error("Failed:" + userDetails.user.message['item1'])
                    this.logger.error(userDetails.user.message['item1'], "Profile");
                }
            } else {
                this.logger.error(userDetails.user.error.error.message, "Profile");
                this.getError(userDetails.user.error);
                this.showLoader1 = false;
            }
        })

        /* this.dataservice.getUserDetails().subscribe((response) => {
            if (response.status === 200) {
                if (response.body['item2'] === "Success") {
                    setTimeout(() => {
                        $('.selectpicker').selectpicker();
                    }, 10);
                    //console.log(response.body)
                    this.getCountrycodes();
                    this.getRolesList();

                    this.showLoader1 = false;
                    this.currentUser.firstname = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['firstName']);
                    this.currentUser.lastname = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['lastName']);
                    this.currentUser.company = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['org']);
                    //this.currentUser.company = response.body['item1']['org'];
                    this.currentUser.email = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['eMail']);
                    this.currentUser.about = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['about']);
                    // this.currentUser.userid = this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']['id']);

                    this.currentUser.userid = response.body['item1']['id'];
                    if (response.body['item1']['pictureID']) {
                        // this.currentUser.thumb = this.aesdecryptservice.decryptData(this.dataservice.Key,"uFG0zBBlggy/yTrtquFA+c7DTrE6h4P0j5vFMd0uEipJ+HBhV4Wt4aKz0vblq29N3NoIOWEGUzrtfUOtwUYn14UFm+Ncyl134bTotYBsNJVOcHsGXJ7URE6AQGm/Bow7nUNa3yaT3N5iTrq1FByshBnbMBMPN8MQae+cUGpIDIT3x9lOwVSvL9yHymXEFkWDGw/L3/dTbIynIwD/xfOKQR1jba4Xo8VHVZqidFNPtlE1xa4f0zvhWOfnZGFXB/9C")
                        this.currentUser.thumb = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['picture']['thumbnail']);
                        this.userImg = this.currentUser.thumb;
                    }
                    this.currentUser.userrole = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['group']);
                    // this.aesdecryptservice.decryptData(this.dataservice.Key,"ZDdX7i+Wz+DNj0JvczCH3Q==");
                    this.logger.info(this.currentUser.firstname + " details loaded successfully", "Profile");

                    this.refreshSelect();
                    if (response.body['item1']['role']) {
                        if (response.body['item1']['role'][0]) {

                            this.currentUser.usersubroleid = response.body['item1']['role'][0]['role']['id'];
                            //this.currentUser.usersubrole =this.aesdecryptservice.decryptData(this.dataservice.Key,"fZ616lBDekOe0e1vfmgclQ==");
                            this.currentUser.usersubrole = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['role'][0]['role']['name']);
                            //this.storage.storeUserSubRole(this.aesdecryptservice.decryptData(this.dataservice.Key,"fZ616lBDekOe0e1vfmgclQ=="))
                            this.storage.storeUserSubRole(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']['role'][0]['role']['name']));
                            if (this.currentUser.usersubrole == "Supplier") {
                                this.isSupplierProfile = true;
                            }
                        }
                    }
                    //portfolios
                    if (response.body['item1']['portfolios']) {
                        if (response.body['item1']['portfolios'][0]) {
                            this.portfolioDetails.portfolioid = response.body['item1']['portfolios'][0]['id'];
                            this.portfolioDetails.imageName = response.body['item1']['portfolios'][0]['name'];
                            this.portfolioDetails.status = response.body['item1']['portfolios'][0]['status'];
                            this.viewfile = [];
                            this.viewfile = response.body['item1']['portfolios'][0]['image'];
                            this.portfolioDetails.thumb = response.body['item1']['portfolios'][0]['image']['thumbnail'];
                        }
                    }
                    this.initForm();
                    //Business info
                    if (response.body['item1']['supplier']) {
                        if (response.body['item1']['supplier'][0]) {

                            setTimeout(() => {
                                $('.selectpicker').selectpicker();
                            }, 10);
                            let supp = response.body['item1']['supplier'][0];

                            //this.initForm();
                            this.bussinessInfoForm.get('classification').setValue(supp["classification"]);
                            this.refreshTree();

                            this.supplierclassificationValue = supp["classification"];
                            if (supp["classification"] === "Factory" || supp["classification"] === "Raw Material Supplier") {
                                this.bussinessInfoForm.get('productType').enable();
                                this.bussinessInfoForm.get('speciality').enable();
                                this.bussinessInfoForm.get('moq').enable();
                                this.bussinessInfoForm.get('leadTime').enable();
                                this.bussinessInfoForm.get('capacity').enable();
                                this.bussinessInfoForm.get('capacityUOM').enable();
                                setTimeout(() => {
                                    $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
                                }, 10);
                            } else {

                                this.bussinessInfoForm.get('productType').disable();
                                this.bussinessInfoForm.get('speciality').disable();
                                this.bussinessInfoForm.get('moq').disable();
                                this.bussinessInfoForm.get('leadTime').disable();
                                this.bussinessInfoForm.get('capacity').disable();
                                this.bussinessInfoForm.get('capacityUOM').disable();
                                setTimeout(() => {
                                    $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
                                }, 10);
                            }
                            if (supp["machineTypes"]) {
                                this.done = [];
                                this.machineTypearr = JSON.parse(supp["machineTypes"]);
                                let i = 0;
                                for (const [key, value] of Object.entries(this.machineTypearr)) {
                                    if (value['value']) {
                                        this.done.push(value['key']);
                                        this.todo = this.todo.filter(x => x != value['key']);
                                        this.bussinessInfoForm.get('machineTypes' + i).patchValue(value['value']);
                                        i++;
                                    }
                                }
                            }
                            //this.bussinessInfoForm.get('supplierid').setValue(supp["id"]);
                            this.supplerId = supp["id"];
                            //this.supplerId = response.body['item1']['role'][0]['role']['id'];
                            this.bussinessInfoForm.get('name').setValue(supp["name"]);
                            if (supp["phoneNumber"]) {
                                const phone = supp["phoneNumber"];
                                const phone1 = phone.substr(phone.length - 10);
                                const phonecode = phone.replace(phone1, "");
                                this.bussinessInfoForm.get('phoneNo').setValue(phone1);
                                this.bussinessInfoForm.get('phone_number_code').setValue(phonecode);
                            }
                            else {
                                this.bussinessInfoForm.get('phoneNo').setValue('');
                                this.bussinessInfoForm.get('phone_number_code').setValue('+1');
                            }

                            //this.bussinessInfoForm.get('phoneNo').setValue(supp["phoneNumber"]);
                            this.bussinessInfoForm.get('email').setValue(supp["email"]);
                            this.bussinessInfoForm.get('lastSocialAudit').setValue(supp["lastSocialAudit"]);
                            this.bussinessInfoForm.get('auditStatus').setValue(supp["auditStatus"]);
                            this.bussinessInfoForm.get('description').setValue(supp["description"]);
                            this.bussinessInfoForm.get('productType').setValue(supp["productType"]);
                            this.bussinessInfoForm.get('SupplierChainPartner').setValue(supp["supplierChainPartner"]);
                            this.bussinessInfoForm.get('speciality').setValue(supp["speciality"]);
                            this.bussinessInfoForm.get('moq').setValue(supp["moq"]);
                            this.bussinessInfoForm.get('leadTime').setValue(supp["leadTime"]);
                            this.bussinessInfoForm.get('capacity').setValue(supp["capacity"]);
                            this.bussinessInfoForm.get('capacityUOM').setValue(supp["capacityUOM"]);
                            //this.bussinessInfoForm.get('machineTypes').setValue(supp["machineTypes"]);
                            if (supp["contacts"].length > 0) {
                                this.conta = supp["contacts"];
                                const contactForm = (this.bussinessInfoForm.controls['Contactss'] as FormGroup);
                                const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
                                const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
                                if (contact1) {

                                    contact1.patchValue({
                                        fullName: this.conta[0]["fullName"],
                                        officeNo: this.conta[0]["officeNo"],
                                        ext: this.conta[0]["ext"],
                                        fax: this.conta[0]["fax"],
                                        contactNo: this.conta[0]["contactNo"],
                                        contactEmail: this.conta[0]["contactEmail"],
                                        address: this.conta[0]["address"],
                                        country: this.conta[0]["country"],
                                        state: this.conta[0]["state"],
                                        city: this.conta[0]["city"],
                                        contactOrder: this.conta[0]["contactOrder"],
                                        zipCode: this.conta[0]["zipCode"],
                                        id: this.conta[0]["id"]
                                    });
                                }
                                if (contact2) {

                                    contact2.patchValue({

                                        fullName: this.conta[1]["fullName"],
                                        officeNo: this.conta[1]["officeNo"],
                                        ext: this.conta[1]["ext"],
                                        fax: this.conta[1]["fax"],
                                        contactNo: this.conta[1]["contactNo"],
                                        contactEmail: this.conta[1]["contactEmail"],
                                        address: this.conta[1]["address"],
                                        country: this.conta[1]["country"],
                                        state: this.conta[1]["state"],
                                        city: this.conta[1]["city"],
                                        contactOrder: this.conta[1]["contactOrder"],
                                        zipCode: this.conta[1]["zipCode"],
                                        id: this.conta[1]["id"]
                                    });
                                    this.secondContact();
                                }
                            }
                            this.bussinessInfoForm.disable();
                        }
                    }

                }
                else {
                    this.showLoader1 = false;
                }
            }
            else {
                this.showLoader1 = false;
            }
        }, err => {
            this.logger.error(err.error.message, "Profile");
            if (err.error.message === "Network error communicating with endpoint") {
                this.error(err.error.message);
                this.showLoader = false;
            } else if (err.error.message === "The incoming token has expired") {
                this.sessionTimedOut();
                this.userservice.logout();
                this.showLoader = false;
            } else if (err.error.message === "Unauthorized") {
                this.userservice.error();
                this.showLoader = false;
            }
            else {
                this.error(err.message);
                this.logger.error(err.message, "Profile");

            }
            this.showLoader = false;
        }); */

    }

    // Method to initialise the Registration form.
    async initForm() {
        this.showLoader = true;

        if (this.isSupplierProfile) {
            this.bindprofileForm();
            this.bindbussinessInfoForm();
            this.bindPortfolioForm();
        }
        else {
            this.bindprofileForm();
        }
        this.showLoader = false;
        this.refreshSelect();
        //this.select_2cssupdate();
    }

    get f() { return this.profileForm.controls; }

    async onSubmit() {
        this.errors = '';
        this.errorDiv = false;
        this.alert = '';
        this.submitted = true;
        if (this.profileForm.invalid) {
          this.error(this.staticMsgs.profile.profileValid)
            return;
        }

        this.showLoader = true;

        if (this.f.phone_number.value) {
            this.phone_number = this.f.phone_number_code.value + this.f.phone_number.value;
        }

        const firstname = this.f.firstname.value,
            lastname = this.f.lastname.value,
            role = this.f.userrole.value,
            phone_number_value = this.f.phone_number.value,
            phone_number_code = this.f.phone_number_code.value,
            phone_number = phone_number_code + phone_number_value,
            company = this.f.company.value,
            timeZone = this.f.timeZone.value;

        const formData = new FormData();
        if (this.imageUploaded) {
            formData.append('file', this.userImgfile);
        }
        formData.append('firstname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.firstname.value));
        formData.append('lastname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.lastname.value));
        formData.append('email', this.aesdecryptservice.encryptData(this.dataservice.Key, this.currentUser.email));
        formData.append('role', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.userrole.value));
        formData.append('org', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.company.value));
        formData.append('about', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.about.value));
        formData.append('timeZone', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.timeZone.value));

        const rolesString = this.storage.getRoles();
        var userroles = rolesString.split(",");
        let UserOldCompany: string = "";

        for (let i = 0; i < userroles.length; i++) {
            if ((userroles[i] != "Maker") && (userroles[i] != "Collaborator")) {
                UserOldCompany = userroles[i];
            }
        }

        if (!UserOldCompany) {
            UserOldCompany = this.currentUser.company;
        }

        var userattributes;

        if (this.phone_number) {
            userattributes = {
                phone_number,
                name: firstname + " " + lastname
            };
        }
        else {
            userattributes = {
                phone_number: "",
                name: firstname + " " + lastname
            };

        }

        if (company) {

            if (UserOldCompany) {

                if (company != UserOldCompany) {
                    var params = {
                        GroupName: UserOldCompany, /* required */
                        UserPoolId: awsconfig.aws_user_pools_id, /* required */
                        Username: this.currentUser.email /* required */
                    };
                    try {
                        const result = await this.auth.callremoveUserFromGroup(params);
                        if (Object.keys(result).length === 0) {
                            var params1 = {
                                GroupName: company,
                                UserPoolId: awsconfig.aws_user_pools_id
                            };

                            try {
                                const result: AWS.CognitoIdentityServiceProvider.CreateGroupResponse = await this.auth.callCreateGroup(params1);
                                if (result.Group.GroupName == company) {
                                    let Loggeduser = await Auth.currentAuthenticatedUser();
                                    const updateResponse = await Auth.updateUserAttributes(Loggeduser, userattributes);
                                    if (updateResponse === "SUCCESS") {
                                        var params2 = {
                                            GroupName: company, /* required */
                                            UserPoolId: awsconfig.aws_user_pools_id, /* required */
                                            Username: this.currentUser.email /* required */
                                        };

                                        try {
                                            const result1 = await this.auth.calladdUsertoGroup(params2);

                                            if ((Object.keys(result1).length === 0)) {
                                                var administratorId;
                                                this.roles.find(function (item, i) {
                                                  if (item.name == "Administrator") {
                                                    administratorId = item.id;
                                                    return;
                                                  }
                                                });
                                                const formData1 = new FormData();
                                                if (this.imageUploaded) {
                                                    formData1.append('file', this.userImgfile);
                                                }
                                                formData1.append('firstname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.firstname.value));
                                                formData1.append('lastname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.lastname.value));
                                                formData1.append('email', this.aesdecryptservice.encryptData(this.dataservice.Key, this.currentUser.email));
                                                formData1.append('role', this.aesdecryptservice.encryptData(this.dataservice.Key, administratorId));
                                                formData1.append('org', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.company.value));
                                                formData1.append('about', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.about.value));
                                                formData1.append('timeZone', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.timeZone.value));
                                                this.store.dispatch(new userActionTypes.UpdateUser(formData1));
                                                this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedUserData) => {
                                                    if (updatedUserData.user.error === "") {
                                                        if (updatedUserData.user.message['item2'] === 'Success') {
                                                            this.showLoader = false;
                                                            this.success(this.staticMsgs.profile.profileUpd);
                                                            this.logger.info(this.staticMsgs.profile.profileUpd, "Profile");
                                                            const rolesString = this.storage.getRoles();
                                                            var userroles = rolesString.split(",");
                                                            userroles.push(company);
                                                            let updatedrolesString = userroles.join(",")
                                                            this.storage.storeUserRoles(updatedrolesString);
                                                            //console.log(updatedrolesString)
                                                            this.getUpdatedUserDetails(updatedUserData.user.message['item1']);
                                                            this.basicInfoDetailsTab(this.activeTab);
                                                        } else {
                                                            this.alert = 'danger';
                                                            this.errorDiv = true;
                                                            this.errors = updatedUserData.user.message['item1'];
                                                            this.showLoader = false;
                                                            this.logger.error(this.errors, "Profile");
                                                        }
                                                    } else {
                                                        this.logger.error(updatedUserData.user.error.error.message, "Profile");
                                                        this.getError(updatedUserData.user.error);
                                                        this.showLoader = false;
                                                    }
                                                })
                                                /* this.dataservice.updateUserDetails(formData).subscribe((response) => {
                                                    if (response.status === 200) {
                                                        if (response.body['item2'] === "Success") {
                                                            //this.alert = 'success';
                                                            //this.errorDiv = true;
                                                            //this.errors = 'Profile Updated Sucessfully.';
                                                            this.success("Profile Updated Successfully");
                                                            this.logger.info("Profile Updated Successfully", "Profile");
                                                            const rolesString = this.storage.getRoles();
                                                            const updatedrolesString = rolesString.replace(UserOldCompany, company)
                                                            this.storage.storeUserRoles(updatedrolesString);
                                                            //console.log(updatedrolesString)
                                                            this.getUpdatedUserDetails(response);
                                                            this.basicInfoDetailsTab(this.activeTab);
                                                            this.showLoader = false;
                                                        }
                                                        else {
                                                            //console.log(response)
                                                            this.alert = 'danger';
                                                            this.errorDiv = true;
                                                            this.errors = response.body['item2'];
                                                            this.logger.error(response.body['item2'], "Profile");
                                                            this.showLoader = false;
                                                        }
                                                    } else {
                                                        //console.log(response)
                                                        this.alert = 'danger';
                                                        this.errorDiv = true;
                                                        this.errors = response.err;
                                                        this.logger.error(response.err, "Profile");
                                                        this.showLoader = false;
                                                    }
                                                }, err => {
                                                    this.logger.error(err.error.message, "Profile");
                                                    if (err.error.message === "Network error communicating with endpoint") {
                                                        this.error(err.error.message);
                                                        this.showLoader = false;
                                                    } else if (err.error.message === "The incoming token has expired") {
                                                        this.sessionTimedOut();
                                                        this.showLoader = false;
                                                    } else if (err.error.message === "Unauthorized") {
                                                        this.userservice.error();
                                                        this.showLoader = false;
                                                    }
                                                    else {
                                                        this.error(err.message);
                                                        this.logger.error(err.error.message, "Profile");

                                                    }
                                                    this.showLoader = false;
                                                }); */
                                            }
                                            else {
                                                this.alert = 'danger';
                                                this.errorDiv = true;
                                                this.errors = this.staticMsgs.profile.errorOcc;
                                                this.logger.error(this.staticMsgs.profile.errorOcc, "Profile");
                                                this.showLoader = false;
                                            }
                                        } catch (err1) {
                                            //console.error("ERROR:" + err1);
                                            if (err1.code == "ResourceNotFoundException") {
                                                this.alert = 'danger';
                                                this.errorDiv = true;
                                                this.errors = this.staticMsgs.profile.compExist;
                                                this.logger.error(this.staticMsgs.profile.compExist, "Profile");
                                            }
                                            else {
                                                this.alert = 'danger';
                                                this.errorDiv = true;
                                                this.errors = err1.message;
                                                this.logger.error(err1.message, "Profile");
                                            }
                                            this.showLoader = false;
                                        }
                                    }
                                    else {
                                        this.alert = 'danger';
                                        this.errorDiv = true;
                                        this.errors = this.staticMsgs.profile.unableUpd;
                                        this.showLoader = false;
                                    }

                                }
                            } catch (err) {
                                //console.error("ERROR:" + err);
                                if (err.code == "GroupExistsException") {
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    this.errors = this.staticMsgs.profile.companyExist;
                                    this.logger.error(this.staticMsgs.profile.companyExist, "Profile");

                                }
                                else {
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    this.errors = err.message;
                                    this.logger.error(err.message, "Profile");

                                }
                                this.showLoader = false;
                            }
                        }
                    } catch (err1) {
                        //console.error("ERROR:" + err1);
                        if (err1.code == "ResourceNotFoundException") {
                            this.alert = 'danger';
                            this.errorDiv = true;
                            this.errors = this.staticMsgs.profile.compError;
                        }
                        else {
                            this.alert = 'danger';
                            this.errorDiv = true;
                            this.errors = err1.message;
                            this.logger.error(err1.message, "Profile");

                        }
                        this.showLoader = false;
                    }
                }
                else {
                    try {
                        let Loggeduser = await Auth.currentAuthenticatedUser();
                        const updateResponse = await Auth.updateUserAttributes(Loggeduser, userattributes);
                        if (updateResponse === "SUCCESS") {
                            this.store.dispatch(new userActionTypes.UpdateUser(formData));
                            this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedUserData) => {
                                if (updatedUserData.user.error === "") {
                                    if (updatedUserData.user.message['item2'] === 'Success') {
                                        this.showLoader = false;
                                        this.success(this.staticMsgs.profile.profileUpd);
                                        this.logger.info(this.staticMsgs.profile.profileUpd, "Profile");
                                        this.getUpdatedUserDetails(updatedUserData.user.message['item1']);
                                        this.basicInfoDetailsTab(this.activeTab);
                                    } else {
                                        this.alert = 'danger';
                                        this.errorDiv = true;
                                        this.errors = updatedUserData.user.message['item1'];
                                        this.showLoader = false;
                                        this.logger.error(this.errors, "Profile");
                                    }
                                } else {
                                    this.logger.error(updatedUserData.user.error.error.message, "Profile");
                                    this.getError(updatedUserData.user.error);
                                    this.showLoader = false;
                                }
                            })

                            /* this.dataservice.updateUserDetails(formData).subscribe((response) => {
                                if (response.status === 200) {
                                    if (response.body['item2'] === "Success") {
                                        //this.alert = 'success';
                                        //this.errorDiv = true;
                                        //this.errors = 'Profile Updated Sucessfully.';
                                        this.success("Profile Updated Successfully");
                                        this.logger.info("Profile Updated Successfully", "Profile");
                                        this.getUpdatedUserDetails(response);
                                        this.basicInfoDetailsTab(this.activeTab);
                                        this.showLoader = false;
                                    }
                                    else {
                                        //console.log(response)
                                        this.alert = 'danger';
                                        this.errorDiv = true;
                                        this.errors = response.body['item2'];
                                        this.logger.error(response.body['item2'], "Profile");
                                        this.showLoader = false;
                                    }
                                } else {
                                    //console.log(response)
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    this.errors = response.err;
                                    this.logger.error(response.err, "Profile");
                                    this.showLoader = false;
                                }
                            },
                                err => {
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    //console.log(err)
                                    this.logger.error(err.error.message, "Profile");
                                    if (err.error.message === "Network error communicating with endpoint") {
                                        this.errors = err.error.message;
                                    } else if (err.error.message === "The incoming token has expired") {
                                        this.errors = err.error.message;
                                        this.sessionTimedOut();
                                    } else if (err.error.message === "Unauthorized") {
                                        this.errors = err.error.message;
                                        this.userservice.error();
                                    }
                                    else {
                                        this.errors = err.message;
                                        this.logger.error(err.message, "Profile");
                                    }
                                    this.showLoader = false;
                                }) */

                        }
                        else {
                            this.alert = 'danger';
                            this.errorDiv = true;
                            this.errors = this.staticMsgs.profile.unableUpd;
                            this.logger.error(this.errors, "Profile");
                            this.showLoader = false;
                        }
                    } catch (err) {
                        this.alert = 'danger';
                        this.errorDiv = true;
                        this.errors = err.message;
                        this.logger.error(err.message, "Profile");
                        this.showLoader = false;
                    }
                }

            }
            else {
                var params1 = {
                    GroupName: company,
                    UserPoolId: awsconfig.aws_user_pools_id
                };

                try {
                    const result: AWS.CognitoIdentityServiceProvider.CreateGroupResponse = await this.auth.callCreateGroup(params1);
                    if (result.Group.GroupName == company) {
                        let Loggeduser = await Auth.currentAuthenticatedUser();
                        const updateResponse = await Auth.updateUserAttributes(Loggeduser, userattributes);
                        if (updateResponse === "SUCCESS") {
                            var params2 = {
                                GroupName: company, /* required */
                                UserPoolId: awsconfig.aws_user_pools_id, /* required */
                                Username: this.currentUser.email /* required */
                            };

                            try {
                                const result1 = await this.auth.calladdUsertoGroup(params2);

                                if ((Object.keys(result1).length === 0)) {
                                    var administratorId;
                                    this.roles.find(function (item, i) {
                                      if (item.name == "Administrator") {
                                        administratorId = item.id;
                                        return;
                                      }
                                    });
                                    const formData1 = new FormData();
                                    if (this.imageUploaded) {
                                        formData1.append('file', this.userImgfile);
                                    }
                                    formData1.append('firstname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.firstname.value));
                                    formData1.append('lastname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.lastname.value));
                                    formData1.append('email', this.aesdecryptservice.encryptData(this.dataservice.Key, this.currentUser.email));
                                    formData1.append('role', this.aesdecryptservice.encryptData(this.dataservice.Key, administratorId));
                                    formData1.append('org', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.company.value));
                                    formData1.append('about', this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.about.value));
                                    this.store.dispatch(new userActionTypes.UpdateUser(formData1));
                                    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedUserData) => {
                                        if (updatedUserData.user.error === "") {
                                            if (updatedUserData.user.message['item2'] === 'Success') {
                                                this.showLoader = false;
                                                this.logger.info(this.staticMsgs.profile.profileUpd, "Profile");
                                                const rolesString = this.storage.getRoles();
                                                var userroles = rolesString.split(",");
                                                userroles.push(company);
                                                let updatedrolesString = userroles.join(",")
                                                this.storage.storeUserRoles(updatedrolesString);
                                                this.getUpdatedUserDetails(updatedUserData.user.message['item1']);
                                                this.basicInfoDetailsTab(this.activeTab);
                                            } else {
                                                this.alert = 'danger';
                                                this.errorDiv = true;
                                                this.errors = updatedUserData.user.message['item1'];
                                                this.showLoader = false;
                                                this.logger.error(this.errors, "Profile");
                                            }
                                        } else {
                                            this.logger.error(updatedUserData.user.error.error.message, "Profile");
                                            this.getError(updatedUserData.user.error);
                                            this.showLoader = false;
                                        }
                                    })

                                    /* this.dataservice.updateUserDetails(formData).subscribe((response) => {
                                        if (response.status === 200) {
                                            if (response.body['item2'] === "Success") {
                                                //this.alert = 'success';
                                                //this.errorDiv = true;
                                                //this.errors = 'Profile Updated Sucessfully.';
                                                this.success("Profile Updated Successfully");
                                                this.logger.info("Profile Updated Successfully", "Profile");
                                                const rolesString = this.storage.getRoles();
                                                const updatedrolesString = rolesString.replace(UserOldCompany, company + ",")
                                                this.storage.storeUserRoles(updatedrolesString);
                                                //console.log(updatedrolesString)
                                                this.getUpdatedUserDetails(response);
                                                this.basicInfoDetailsTab(this.activeTab);
                                                this.showLoader = false;
                                            }
                                            else {
                                                // console.log(response)
                                                this.alert = 'danger';
                                                this.errorDiv = true;
                                                this.errors = response.body['item2'];
                                                this.logger.error(response.body['item2'], "Profile");
                                                this.showLoader = false;
                                            }
                                        } else {
                                            //console.log(response)
                                            this.alert = 'danger';
                                            this.errorDiv = true;
                                            this.errors = response.err;
                                            this.logger.error(response.err, "Profile");
                                            this.showLoader = false;
                                        }
                                    },
                                        err => {
                                            this.alert = 'danger';
                                            this.errorDiv = true;
                                            this.logger.error(err.error.message, "Profile");
                                            //console.log(err)
                                            if (err.error.message === "Network error communicating with endpoint") {
                                                this.errors = err.error.message;
                                            } else if (err.error.message === "The incoming token has expired") {
                                                this.errors = err.error.message;
                                                this.sessionTimedOut();
                                            } else if (err.error.message === "Unauthorized") {
                                                this.errors = err.error.message;
                                                this.userservice.error();
                                            }
                                            else {
                                                this.errors = err.message;
                                            }
                                            this.showLoader = false;
                                        }) */
                                }
                                else {
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    this.errors = this.staticMsgs.profile.errorOcc;
                                    this.logger.error(this.errors, "Profile");
                                    this.showLoader = false;
                                }
                            } catch (err1) {
                                //console.error("ERROR:" + err1);
                                if (err1.code == "ResourceNotFoundException") {
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    this.errors = this.staticMsgs.profile.compExist;
                                    this.logger.error(this.errors, "Profile");
                                }
                                else {
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    this.errors = err1.message;
                                    this.logger.error(this.errors, "Profile");
                                }
                                this.showLoader = false;
                            }
                        }
                        else {
                            this.alert = 'danger';
                            this.errorDiv = true;
                            this.errors = this.staticMsgs.profile.unableUpd;
                            this.logger.error(this.errors, "Profile");
                            this.showLoader = false;
                        }
                    }
                } catch (err) {
                    //console.error("ERROR:" + err);
                    if (err.code == "GroupExistsException") {
                        this.alert = 'danger';
                        this.errorDiv = true;
                        this.errors = this.staticMsgs.profile.companyExist;
                        this.logger.error(this.errors, "Profile");
                    }
                    else {
                        this.alert = 'danger';
                        this.errorDiv = true;
                        this.errors = err.message;
                        this.logger.error(err.message, "Profile");
                    }
                    this.showLoader = false;
                }
            }
        }
        else {
            if (UserOldCompany) {
                var params = {
                    GroupName: UserOldCompany, /* required */
                    UserPoolId: awsconfig.aws_user_pools_id, /* required */
                    Username: this.currentUser.email /* required */
                };
                try {
                    const result = await this.auth.callremoveUserFromGroup(params);
                    if (Object.keys(result).length === 0) {
                        let Loggeduser = await Auth.currentAuthenticatedUser();
                        const updateResponse = await Auth.updateUserAttributes(Loggeduser, userattributes);
                        if (updateResponse === "SUCCESS") {
                            this.store.dispatch(new userActionTypes.UpdateUser(formData));
                            this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedUserData) => {
                                if (updatedUserData.user.error === "") {
                                    if (updatedUserData.user.message['item2'] === 'Success') {
                                        this.showLoader = false;
                                        this.success(this.staticMsgs.profile.profileUpd);
                                        this.logger.info(this.staticMsgs.profile.profileUpd, "Profile");
                                        this.storage.storeUserRoles(this.storage.getUserMainRole());
                                        this.getUpdatedUserDetails(updatedUserData.user.message['item1']);
                                        this.basicInfoDetailsTab(this.activeTab);
                                    } else {
                                        this.alert = 'danger';
                                        this.errorDiv = true;
                                        this.errors = updatedUserData.user.message['item1'];
                                        this.showLoader = false;
                                        this.logger.error(this.errors, "Profile");
                                    }
                                } else {
                                    this.logger.error(updatedUserData.user.error.error.message, "Profile");
                                    this.getError(updatedUserData.user.error);
                                    this.showLoader = false;
                                }
                            })
                            /* this.dataservice.updateUserDetails(formData).subscribe((response) => {
                                if (response.status === 200) {
                                    if (response.body['item2'] === "Success") {
                                        //this.alert = 'success';
                                        //this.errorDiv = true;
                                        //this.errors = 'Profile Updated Sucessfully.';
                                        this.success("Profile Updated Successfully");
                                        this.logger.info("Profile Updated Successfully", "Profile");
                                        this.storage.storeUserRoles(this.storage.getUserMainRole());
                                        // console.log(this.storage.getRoles());
                                        this.getUpdatedUserDetails(response);
                                        this.basicInfoDetailsTab(this.activeTab);
                                        this.showLoader = false;
                                    }
                                    else {
                                        //console.log(response)
                                        this.alert = 'danger';
                                        this.errorDiv = true;
                                        this.errors = response.body['item2'];
                                        this.logger.error(response.body['item2'], "Profile");
                                        this.showLoader = false;
                                    }
                                } else {
                                    //console.log(response)
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    this.errors = response.err;
                                    this.logger.error(response.err, "Profile");
                                    this.showLoader = false;
                                }
                            },
                                err => {
                                    this.alert = 'danger';
                                    this.errorDiv = true;
                                    this.logger.error(err.error.message, "Profile");
                                    //console.log(err)
                                    if (err.error.message === "Network error communicating with endpoint") {
                                        this.errors = err.error.message;
                                    } else if (err.error.message === "The incoming token has expired") {
                                        this.errors = err.error.message;
                                        this.sessionTimedOut();
                                    } else if (err.error.message === "Unauthorized") {
                                        this.errors = err.error.message;
                                        this.userservice.error();
                                    }
                                    else {
                                        this.errors = err.message;
                                        this.logger.error(err.message, "Profile");
                                    }
                                    this.showLoader = false;
                                }) */

                        }
                        else {
                            this.alert = 'danger';
                            this.errorDiv = true;
                            this.errors = this.staticMsgs.profile.unableUpd;
                            this.logger.error(this.errors, "Profile");
                            this.showLoader = false;
                        }
                    }
                } catch (err1) {
                    //console.error("ERROR:" + err1);
                    if (err1.code == "ResourceNotFoundException") {
                        this.alert = 'danger';
                        this.errorDiv = true;
                        this.errors = this.staticMsgs.profile.compError;
                        this.logger.error(this.errors, "Profile");
                    }
                    else {
                        this.alert = 'danger';
                        this.errorDiv = true;
                        this.errors = err1.message;
                        this.logger.error(err1.message, "Profile");
                    }
                    this.showLoader = false;
                }
            }
            else {
                let Loggeduser = await Auth.currentAuthenticatedUser();
                const updateResponse = await Auth.updateUserAttributes(Loggeduser, userattributes);
                if (updateResponse === "SUCCESS") {
                    this.store.dispatch(new userActionTypes.UpdateUser(formData));
                    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedUserData) => {
                        if (updatedUserData.user.error === "") {
                            if (updatedUserData.user.message['item2'] === 'Success') {
                                this.showLoader = false;
                                this.success(this.staticMsgs.profile.profileUpd);
                                this.logger.info(this.staticMsgs.profile.profileUpd, "Profile");
                                this.getUpdatedUserDetails(updatedUserData.user.message['item1']);
                                this.basicInfoDetailsTab(this.activeTab);
                            } else {
                                this.alert = 'danger';
                                this.errorDiv = true;
                                this.errors = updatedUserData.user.message['item1'];
                                this.showLoader = false;
                                this.logger.error(this.errors, "Profile");
                            }
                        } else {
                            this.logger.error(updatedUserData.user.error.error.message, "Profile");
                            this.getError(updatedUserData.user.error);
                            this.showLoader = false;
                        }
                    })

                    /* this.dataservice.updateUserDetails(formData).subscribe((response) => {
                        if (response.status === 200) {
                            if (response.body['item2'] === "Success") {
                                //this.alert = 'success';
                                //this.errorDiv = true;
                                //this.errors = 'Profile Updated Sucessfully.';
                                this.success("Profile Updated Successfully");
                                this.logger.info("Profile Updated Successfully", "Profile");
                                this.getUpdatedUserDetails(response);
                                this.basicInfoDetailsTab(this.activeTab);
                                this.showLoader = false;
                            }
                            else {
                                // console.log(response)
                                this.alert = 'danger';
                                this.errorDiv = true;
                                this.errors = response.body['item2'];
                                this.logger.error(response.body['item2'], "Profile");
                                this.showLoader = false;
                            }
                        } else {
                            //console.log(response)
                            this.alert = 'danger';
                            this.errorDiv = true;
                            this.errors = response.err;
                            this.logger.error(response.err, "Profile");
                            this.showLoader = false;
                        }
                    },
                        err => {
                            this.alert = 'danger';
                            this.errorDiv = true;
                            this.logger.error(err.error.message, "Profile");
                            //console.log(err)
                            if (err.error.message === "Network error communicating with endpoint") {
                                this.errors = err.error.message;
                            } else if (err.error.message === "The incoming token has expired") {
                                this.errors = err.error.message;
                                this.sessionTimedOut();
                            } else if (err.error.message === "Unauthorized") {
                                this.errors = err.error.message;
                                this.userservice.error();
                            }
                            else {
                                this.errors = err.message;
                                this.logger.error(err.message, "Profile");
                            }
                            this.showLoader = false;
                        }) */

                }
                else {
                    this.alert = 'danger';
                    this.errorDiv = true;
                    this.errors = this.staticMsgs.profile.unableUpd;
                    this.logger.error(this.errors, "Profile");
                    this.showLoader = false;
                }
            }

        }


    }


    async getUpdatedUserDetails(response) {
        //console.log(response)
        this.showLoader1 = true;
        let updateduser = await this.userservice.getUserDetails();
        this.storage.storeUser(updateduser['attributes']['name']);
        this.userservice.currentUser.user = this.storage.getUser();

        if (updateduser['attributes'] === undefined) {
            this.sessionTimedOut();
            this.showLoader1 = false;
        }

        if (updateduser['attributes']['phone_number']) {
            const phone = updateduser['attributes']['phone_number'];
            this.currentUser.phone_number = phone.substr(phone.length - 10);
            this.currentUser.phone_number_code = phone.replace(this.currentUser.phone_number, "");
        }
        else {
            this.currentUser.phone_number = "";
            this.currentUser.phone_number_code = "+1";
        }

        this.currentUser.firstname = this.aesdecryptservice.decryptData(this.dataservice.Key, response['firstName']);
        this.currentUser.lastname = this.aesdecryptservice.decryptData(this.dataservice.Key, response['lastName']);
        this.currentUser.company = this.aesdecryptservice.decryptData(this.dataservice.Key, response['org']);
        this.currentUser.timeZone = this.aesdecryptservice.decryptData(this.dataservice.Key, response['timeZone']);

        console.log('this.currentUser',this.currentUser);

        //this.currentUser.company = response['org'];
        if (response['pictureID']) {
            this.currentUser.thumb = this.aesdecryptservice.decryptData(this.dataservice.Key, response['picture']['thumbnail']);
            this.storage.storeUserImage(this.aesdecryptservice.decryptData(this.dataservice.Key, response['picture']['thumbnail']));
            this.userservice.currentUser.thumb = this.aesdecryptservice.decryptData(this.dataservice.Key, response['picture']['thumbnail']);
            this.userImg = this.currentUser.thumb;
            this.imageUploaded = false;
        }
        this.currentUser.userrole = response['group'];
        if (response['role']) {
            if (response['role'][0]) {
                this.currentUser.usersubroleid = response['role'][0]['role']['id'];
                this.currentUser.usersubrole = this.aesdecryptservice.decryptData(this.dataservice.Key, response['role'][0]['role']['name']);
                this.storage.storeUserSubRole(this.aesdecryptservice.decryptData(this.dataservice.Key, response['role'][0]['role']['name']));
            }
        }

        this.initForm();
        this.dataservice.getMenuItems().subscribe((response) => {
            if (response.status === 200) {
                if (response.body['item2'] === "Success") {
                  this.logger.info(this.staticMsgs.profile.menuLoad, "Profile");
                    for (let i = 0; i < response.body['item1'].length; i++) {
                        response.body['item1'][i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['name']);
                        response.body['item1'][i].title = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['title']);
                        response.body['item1'][i].type = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['type']);
                        response.body['item1'][i].url = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['url']);
                        response.body['item1'][i].icon = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i]['icon']);
                        if (response.body['item1'][i].children.length > 0) {
                            for (let j = 0; j < response.body['item1'][i].children.length; j++) {
                                response.body['item1'][i].children[j].name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['name']);
                                response.body['item1'][i].children[j].title = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['title']);
                                response.body['item1'][i].children[j].type = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['type']);
                                response.body['item1'][i].children[j].url = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['url']);
                                response.body['item1'][i].children[j].icon = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].children[j]['icon']);

                                let construction = response.body['item1'][i].children[j].children;
                                if (construction.length > 0) {
                                    for (let k = 0; k < construction.length; k++) {
                                        construction[k].name = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['name']);
                                        construction[k].title = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['title']);
                                        construction[k].type = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['type']);
                                        construction[k].url = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['url']);
                                        construction[k].icon = this.aesdecryptservice.decryptData(this.dataservice.Key, construction[k]['icon']);
                                    }
                                }
                            }
                        }
                        //	response.body['item1'][i].children= this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1'][i]['icon']);

                    }
                    //console.log(response.body)
                    this.navService.setNavigationModel(new NavigationModel(response.body['item1']));
                    this.storage.storeMenu(JSON.stringify(response.body['item1']));
                    this.showLoader1 = false;
                }
                else {
                    this.navigation = [{
                        id: 'No Menu Items',
                        title: this.staticMsgs.profile.noMenu,
                        type: 'item',
                        icon: '',
                        url: '/'
                    }];
                    this.navService.setNavigationModel(new NavigationModel(this.navigation));
                    this.showLoader1 = false;
                }
            }
            else {
                this.navigation = [{
                    id: 'No Menu Items',
                    title: this.staticMsgs.profile.noMenu,
                    type: 'item',
                    icon: '',
                    url: '/'
                }];
                this.navService.setNavigationModel(new NavigationModel(this.navigation));
                this.showLoader1 = false;
            }
        },
            err => {
                //console.log(err)
                this.logger.error(err.error.message, "Profile");
                if (err.error.message === "Network error communicating with endpoint") {
                    this.navigation = [{
                        id: 'Network Issue',
                        title: this.staticMsgs.profile.network,
                        type: 'item',
                        icon: '',
                        url: '/'
                    }];
                    this.navService.setNavigationModel(new NavigationModel(this.navigation));
                } else if ((err.error.message === "Unauthorized") || (err.error.message === "The incoming token has expired")) {
                    this.navigation = [{
                        id: 'Session Expired',
                        title: this.staticMsgs.profile.session,
                        type: 'item',
                        icon: '',
                        url: '/'
                    }];
                    this.navService.setNavigationModel(new NavigationModel(this.navigation));
                }
                this.showLoader1 = false;
            })

    }

    sessionTimedOut() {
        let timerInterval
        Swal.fire({
          title: this.staticMsgs.profile.session,
          html: this.staticMsgs.profile.sessExp,
            timer: 1000,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading()
                timerInterval = setInterval(() => {
                    const content = Swal.getContent()
                }, 100)
            },
            onClose: () => {
                clearInterval(timerInterval)
                this.userservice.logout();
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                this.userservice.logout();
                //console.log('Session Timedout.')
            }
        })

    }

    refreshSelect() {
        $(document).ready(function () {
            $('.selectpicker').selectpicker('refresh');
        });
    }

    // select_2cssupdate() {
    //   $(document).ready(function () {
    //     $('.select2-container .select2-selection--single').css('height', 'calc(1em + 2.12rem + 2px)');
    //     $('.select2-selection__rendered').css('padding-top', '3px');
    //     $('.select2-selection__arrow').css('top', '5px');
    //     $('.select2-search__field').css('border', '1px solid ff9100');
    //   });
    // }


    getSupplierClassification(module: string) {
        this.store.dispatch(new commonActionTypes.LoadClassifications(module));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
            if (classificationData.common.error === "") {
                if (classificationData.common.message['item2'] === 'Success') {
                  this.logger.info(this.staticMsgs.profile.imgLoad, "Moodboard");
                  this.supplierClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
                  this.logger.info(this.staticMsgs.profile.suppClass, "Profile");
                    ////console.log(JSON.stringify(this.supplierClassificationData ))
                    this.refreshTree();
                    this.showLoader = false;
                } else {
                    this.showLoader = false;
                    this.refreshTree();
                    this.error("Failed:" + classificationData.common.message?.item1)
                    this.logger.error(classificationData.common.message?.item1, "Moodboard");
                }
            } else {
                this.logger.error(classificationData.common.error.error.message, "Moodboard");
                this.getError(classificationData.common.error);
                this.showLoader = false;
            }
        })
        /* await this.dataservice.getClassification(module).subscribe(response => {
            if (response.body['item2'] === "Success") {
                let parentclass = response.body['item1'][0]["items"];
                for (let i = 0; i < parentclass.length; i++) {
                    parentclass[i].parentName = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i]['parentName']);
                    parentclass[i].text = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i]['text']);

                    if (parentclass[i].items.length > 0) {
                        for (let j = 0; j < parentclass[i].items.length; j++) {
                            parentclass[i].items[j].parentName = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i].items[j]['parentName']);
                            parentclass[i].items[j].text = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i].items[j]['text']);
                        }
                    }
                }
                this.supplierClassificationData = parentclass;
                this.logger.info("Supplier classification loaded successfully", "Profile");
                ////console.log(JSON.stringify(this.supplierClassificationData ))
                this.refreshTree();
                this.showLoader = false;
            }
            else {
                this.refreshTree();
                this.showLoader = false;
            }
        }, err => {
            this.logger.error(err.error.message, "Profile");
            if (err.error.message === "Network error communicating with endpoint") {
                this.error(err.error.message);
                this.showLoader = false;
            } else if (err.error.message === "The incoming token has expired") {
                this.sessionTimedOut();
                this.showLoader = false;
            } else if (err.error.message === "Unauthorized") {
                this.userservice.error();
                this.showLoader = false;
            }
            else {
                this.error(err.message);
                this.logger.error(err.message, "Profile");

            }
            this.showLoader = false;
        }); */
    }

    /*** Classification data display Start */
  refreshTree() {
    var data = this.supplierClassificationData;
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      var treeview = $("#classification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        dataSource: data,
        filter: "startswith",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          if (value) {
            self.supplierclassificationValue = value["text"];
            self.bussinessInfoForm.get('classification').setValue(value["text"]);
            if (value["text"] === "Factory" || value["text"] === "Raw Material Supplier") {
              self.bussinessInfoForm.get('productType').enable();
              self.bussinessInfoForm.get('speciality').enable();
              self.bussinessInfoForm.get('moq').enable();
              self.bussinessInfoForm.get('leadTime').enable();
              self.bussinessInfoForm.get('capacity').enable();
              self.bussinessInfoForm.get('capacityUOM').enable();
              // this.bussinessInfoForm.get('machineTypes').enable();

              setTimeout(() => {
                $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
              }, 10);
            } else {
              self.bussinessInfoForm.get('productType').disable();
              self.bussinessInfoForm.get('speciality').disable();
              self.bussinessInfoForm.get('moq').disable();
              self.bussinessInfoForm.get('leadTime').disable();
              self.bussinessInfoForm.get('capacity').disable();
              self.bussinessInfoForm.get('capacityUOM').disable();
            }

          }
          else {
            self.supplierclassificationValue = "";
            self.bussinessInfoForm.get('classification').setValue("");
          }
        }
      });

    });
  }
  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select supplier Standard' });
    });
  }

    basicInfoDetailsTab(activeTab) {
        setTimeout(() => {
            $('.selectpicker').selectpicker();
        }, 10);
        this.activeTab = activeTab;
        this.profileForm.disable();
        this.isDisabled = true;
        this.tabenableedit = true;
        this.tabdisableedit = false;
        this.buttonShow = false;

        this.submitted = false;
        this.submittedBusinessInfo = false;
        this.submittedPortfolio = false;
        // this.initForm();
        // this.loadSelectPicker();
        this.getUserDetails();
    }
    bussinessInfoDetailsTab(activeTab) {
        setTimeout(() => {
            $('.selectpicker').selectpicker();
        }, 10);
        this.activeTab = activeTab;
        this.bussinessInfoForm.disable();
        this.isDisabled = true;
        this.tabenableedit = true;
        this.tabdisableedit = false;
        this.buttonShow = false;

        this.submitted = false;
        this.submittedBusinessInfo = false;
        this.submittedPortfolio = false;



    }
    portfolioDetailsTab(activeTab) {
        this.activeTab = activeTab;
        this.portfolioForm.disable();
        this.isDisabled = true;
        this.tabenableedit = true;
        this.tabdisableedit = false;
        this.buttonShow = false;

        this.submitted = false;
        this.submittedBusinessInfo = false;
        this.submittedPortfolio = false;
    }
    aboutDetailsTab(activeTab) {
        this.profileForm.disable();
        this.activeTab = activeTab;
        this.isDisabled = true;
        this.tabenableedit = true;
        this.tabdisableedit = false;
        this.buttonShow = false;

        this.submitted = false;
        this.submittedBusinessInfo = false;
        this.submittedPortfolio = false;
    }

    success(message: string) {
        Swal.fire(
            'Success',
            message,
            'success'
        )
    }

    error(message: string) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message
        })
    }


    secondContact() {
        this.secondcontact2 = true;
        this.secondcontact = false;
        setTimeout(() => {
            $('.selectpicker').selectpicker();
        }, 10);
    }

    secondContact2() {
        this.secondarycontactId = this.fs.Contactss.value['contactOrder2']['id'];
        const contactForm = (this.bussinessInfoForm.controls['Contactss'] as FormGroup);
        //const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
        const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
        contact2.setValue({
            fullName: "",
            officeNo: "",
            ext: "",
            fax: "",
            contactNo: "",
            contactEmail: "",
            address: "",
            country: "",
            state: "",
            city: "",
            contactOrder: "",
            zipCode: "",
            id: ""

        })

        this.secondcontact2 = false;
        this.secondcontact = true;

        setTimeout(() => {
            $('.selectpicker').selectpicker();
        }, 10);
    }

    setDate(event: any) {

        this.isDateNull = false;
        let now = new Date();
        let lastSocialStr = this.dataservice.dateFromFormat(now);
        //this.lastSocialAuditChild.nativeElement.value = lastSocialStr;

    };

    onSelectImage(event) {
        this.files = [];
        this.files.push(...event.addedFiles);
        if (this.files.length === 1) {
            this.isThumbnailAdded = true;
        }
        else {
            this.isThumbnailAdded = false;
        }
    }

    onRemoveImage(event) {
        this.files.splice(this.files.indexOf(event), 1);
        if (this.files.length === 1) {
            this.isThumbnailAdded = true;
        }
        else {
            this.isThumbnailAdded = false;
        }
    }

    classification = [
        { value: 'agent', label: 'Agent' },
        { value: 'factory', label: 'Factory' },
        { value: 'rawmaterial', label: 'Raw Material Supplier' }
    ];

    number = [
        { value: '91', label: '+91' },
        { value: '265', label: '+265' },
        { value: '222', label: '+222' }
    ];

    auditstatus = [
        { value: 'Passed', label: 'Passed' },
        { value: 'Failed', label: 'Failed' },
        { value: 'UnderReview', label: 'UnderReview' }
    ];

    country = [
        { value: 'India', label: 'India' },
        { value: 'Indonesia', label: 'Indonesia' },
        { value: 'Iran', label: 'Iran' },
    ];

    state = [
        { value: 'Maharashtra', label: 'Maharashtra' },
        { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    ];

    city = [
        { value: 'Pune', label: 'Pune' },
        { value: 'Mumbai', label: 'Mumbai' },
    ];

    chain = [
        { value: 'agent', label: 'Agent' },
        { value: 'factory', label: 'Factory' },
        { value: 'goodsupplier', label: 'Finished Good Supplier' },
        { value: 'rawmaterial', label: 'Raw Material Supplier' }
    ];


    product = [
        { value: 'Mixed', label: 'Mixed' },
        { value: 'Wovan', label: 'Wovan' },
        { value: 'NonWovan', label: 'Non Wovan' },
        { value: 'Leather', label: 'Leather' }
    ];

    speciality = [
        { value: 'Knits', label: 'Knits' },
        { value: 'Wovan', label: 'Wovan' },
        { value: 'Trims', label: 'Trims' },
        { value: 'Packaging', label: 'Packaging' },
        { value: 'Leather', label: 'Leather' }

    ];

    capacity = [
        { value: 'Units', label: 'Units' },
        { value: 'Sq.Yds', label: 'Sq.Yds' }
    ];

    mechine() {
        const arr1 = ["Overlock- 3", "1 Needle Lockstitch- 5", "CoverStitch- 2", "ZigZag- 2", "1 Needle Lockstitch- 14"];
        const arr = [0, 1, 2, 3, 4];
        let self = this;
        let newArr = arr.map(function (val, index) {
            const controlValue = self.bussinessInfoForm.controls['machineTypes' + val];
            if (controlValue.value) {
                return {
                    "key": self.done[index],
                    "value": controlValue.value
                }
            }
        });
        return newArr;
    }

    onOptionsSelected(value: string) {
        this.selectvalue = value;
        if (this.selectvalue == 'agent') {
            this.agent = true;
            this.titleAgent = true;
            this.chainPartner = false;
            this.titlefactory = false;
            this.ptype = false;
            this.titleraw = false;
            this.factoryrawmaterial = false;
            this.machineType = false;
            setTimeout(() => {
                $('.selectpicker').selectpicker();
            }, 10);
        }
        else if ((this.selectvalue == 'factory')) {
            this.factoryrawmaterial = true;
            this.titleAgent = false;
            this.titleraw = false;
            this.titlefactory = true;
            this.chainPartner = true;
            this.ptype = true;
            this.machineType = true;
            //  this.agent = false;
            setTimeout(() => {
                $('.selectpicker').selectpicker();
            }, 10);
        }
        else if ((this.selectvalue == 'rawmaterial')) {
            this.factoryrawmaterial = true;
            this.titleraw = true;
            this.titleAgent = false;
            this.titlefactory = false;
            this.chainPartner = true;
            this.ptype = true;
            this.machineType = true;
            //  this.agent = false;
            setTimeout(() => {
                $('.selectpicker').selectpicker();
            }, 10);
        }
    }

    todo = [
        '1 Needle Lockstitch- 14',
        '1 Needle Lockstitch- 5',
        'ZigZag- 2',
        'CoverStitch- 2',
        'Overlock- 3'
    ];

    done = [
    ];

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    selectedRole(e) {
        // var supplierSelected = false;
        // this.roles.find(function (item, i) {
        //   if (item.id == e.target.value) {
        //     if (item.name == "Supplier") {
        //           supplierSelected = true;
        //     }
        //     return;
        //   }
        // });
        // if(supplierSelected)
        // {
        //   //
        //   this.isSupplierProfile = true;
        //   //this.getSupplierClassification("Suppliers");
        //   this.bindbussinessInfoForm();
        //   this.bindPortfolioForm();
        //   this.getBusinessDetails(this.currentUser.userid);
        // }
        // else
        // {
        //   this.isSupplierProfile = false;
        // }
    }

    get fs() { return this.bussinessInfoForm.controls; }
    get fP() { return this.portfolioForm.controls; }
    get contact1() {

        const contactForm = (this.bussinessInfoForm.controls['Contactss'] as FormGroup);
        const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
        return contact1.controls;
    }
    get contact2() {
        const contactForm = (this.bussinessInfoForm.controls['Contactss'] as FormGroup);
        const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
        return contact2.controls;
    }

    //profile
    bindprofileForm() {
        this.profileForm = this.fb.group({
            firstname: [{ value: this.currentUser.firstname, disabled: true }, [Validators.required, Validators.maxLength(20)]],
            lastname: [{ value: this.currentUser.lastname, disabled: true }, [Validators.required, Validators.maxLength(25)]],
            userrole: [{ value: this.currentUser.usersubroleid, disabled: true }, [Validators.required]],
            phone_number: [{ value: this.currentUser.phone_number, disabled: true }, [Validators.pattern("^[1-9][0-9]{9}$")]],
            phone_number_code: [{ value: this.currentUser.phone_number_code, disabled: true }],
            company: [{ value: this.currentUser.company, disabled: true }, [Validators.pattern('^[a-zA-Z0-9._-]+$'), Validators.maxLength(100)]],
            about: [{ value: this.currentUser.about, disabled: true }, [Validators.maxLength(500)]],
            timeZone:[{value: this.currentUser.timeZone,disabled: true}]
        });

        console.log('this.profileForm',this.profileForm)
    }

    //Portfolio
    bindPortfolioForm() {
        this.portfolioForm = this.fb.group({
            portfolioid: [{ value: this.portfolioDetails.portfolioid, disabled: true }],
            userid: [{ value: this.currentUser.userid, disabled: true }, [Validators.required]],
            imageName: [{ value: this.portfolioDetails.imageName, disabled: true }, [Validators.required, Validators.maxLength(50)]],
            status: [{ value: this.portfolioDetails.status, disabled: true }, [Validators.required]],
            thumbnail: [{ value: this.portfolioDetails.thumb, disabled: true }],
        });
    }



    bindbussinessInfoForm() {
        this.bussinessInfoForm = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(50)]],
            supplierid: [{ value: '', disabled: true }],
            classification: ['', [Validators.required]],
            auditStatus: ['', [Validators.required]],
            phoneNo: ['', [Validators.pattern("^[1-9][0-9]{9}$")]],
            phone_number_code: ['+1'],
            email: ['', [Validators.required, Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$')]],
            moq: ['', [Validators.required, Validators.max(99999)]],
            leadTime: ['', [Validators.required, Validators.max(99999)]],
            productType: [''],
            speciality: [''],
            capacity: ['', Validators.max(99999)],
            capacityUOM: [''],
            lastSocialAudit: [''],
            sequence: [''],
            // lastSocialAudit: ['', [Validators.required]],
            description: ['', Validators.maxLength(500)],
            SupplierChainPartner: [''],

            machineTypes0: ['', Validators.maxLength(20)],
            machineTypes1: ['', Validators.maxLength(20)],
            machineTypes2: ['', Validators.maxLength(20)],
            machineTypes3: ['', Validators.maxLength(20)],
            machineTypes4: ['', Validators.maxLength(20)],
            // zipCode: [''=
            Contactss: new FormGroup({
                contactOrder1: new FormGroup({
                    fullName: new FormControl('', [Validators.maxLength(50)]),
                    officeNo: new FormControl('', Validators.maxLength(10)),
                    ext: new FormControl('', Validators.maxLength(4)),
                    fax: new FormControl('', Validators.maxLength(10)),
                    contactNo: new FormControl('', [Validators.pattern("^[1-9][0-9]{9}$")]),
                    contactEmail: new FormControl('', [Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$')]),
                    address: new FormControl('', Validators.maxLength(500)),
                    country: new FormControl(''),
                    state: new FormControl('', Validators.maxLength(50)),
                    city: new FormControl('', Validators.maxLength(50)),
                    contactOrder: new FormControl('1'),
                    zipCode: new FormControl('', Validators.maxLength(6)),
                    id: new FormControl('')

                }),
                contactOrder2: new FormGroup({
                    fullName: new FormControl('', Validators.maxLength(50)),
                    officeNo: new FormControl('', Validators.maxLength(10)),
                    ext: new FormControl('', Validators.maxLength(4)),
                    fax: new FormControl('', Validators.maxLength(10)),
                    contactNo: new FormControl('', [Validators.pattern("^[1-9][0-9]{9}$")]),
                    contactEmail: new FormControl('', [Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$')]),
                    address: new FormControl('', Validators.maxLength(500)),
                    country: new FormControl(''),
                    state: new FormControl('', Validators.maxLength(50)),
                    city: new FormControl('', Validators.maxLength(50)),
                    contactOrder: new FormControl('2'),
                    zipCode: new FormControl('', Validators.maxLength(6)),
                    id: new FormControl('')
                })
            })
        });

    }


    getBusinessDetails(id) {
        this.showLoader1 = true;
        this.store.dispatch(new userActionTypes.LoadUserSupplier(id));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userSupllier) => {
            if (userSupllier.user.error === "") {
                if (userSupllier.user.message['item2'] === 'Success') {
                    this.logger.info("All users list loaded successfully", "Profile");
                    setTimeout(() => {
                        $('.selectpicker').selectpicker();
                    }, 10);
                    if (userSupllier.user.message['item1']['supplier']) {
                        if (userSupllier.user.message['item1']['supplier'][0]) {
                            let supp = userSupllier.user.message['item1']['supplier'][0];
                            this.logger.info(supp["name"] + " loaded successfully", "Profile");
                            this.refreshTree();
                            this.bussinessInfoForm.get('classification').setValue(supp["classification"]);
                            this.supplierclassificationValue = supp["classification"];
                            if (supp["classification"] === "Factory" || supp["classification"] === "Raw Material Supplier") {
                                this.bussinessInfoForm.get('productType').enable();
                                this.bussinessInfoForm.get('speciality').enable();
                                this.bussinessInfoForm.get('moq').enable();
                                this.bussinessInfoForm.get('leadTime').enable();
                                this.bussinessInfoForm.get('capacity').enable();
                                this.bussinessInfoForm.get('capacityUOM').enable();
                                setTimeout(() => {
                                    $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
                                }, 10);
                            } else {
                                this.refreshTree();
                                this.bussinessInfoForm.get('productType').disable();
                                this.bussinessInfoForm.get('speciality').disable();
                                this.bussinessInfoForm.get('moq').disable();
                                this.bussinessInfoForm.get('leadTime').disable();
                                this.bussinessInfoForm.get('capacity').disable();
                                this.bussinessInfoForm.get('capacityUOM').disable();
                            }
                            if (supp["machineTypes"]) {
                                this.done = [];
                                this.machineTypearr = JSON.parse(supp["machineTypes"]);
                                let i = 0;
                                for (const [key, value] of Object.entries(this.machineTypearr)) {
                                    if (value['value']) {
                                        this.done.push(value['key']);
                                        this.todo = this.todo.filter(x => x != value['key']);
                                        this.bussinessInfoForm.get('machineTypes' + i).patchValue(value['value']);
                                        i++;
                                    }
                                }
                            }
                            this.supplerId = supp["id"];
                            this.bussinessInfoForm.get('name').setValue(supp["name"]);
                            if (supp["phoneNumber"]) {
                                const phone = supp["phoneNumber"];
                                const phone1 = phone.substr(phone.length - 10);
                                const phonecode = phone.replace(phone1, "");
                                this.bussinessInfoForm.get('phoneNo').setValue(phone1);
                                this.bussinessInfoForm.get('phone_number_code').setValue(phonecode);
                            }
                            else {
                                this.bussinessInfoForm.get('phoneNo').setValue('');
                                this.bussinessInfoForm.get('phone_number_code').setValue('+1');
                            }

                            //this.bussinessInfoForm.get('phoneNo').setValue(supp["phoneNumber"]);
                            this.bussinessInfoForm.get('email').setValue(supp["email"]);
                            this.bussinessInfoForm.get('lastSocialAudit').setValue(supp["lastSocialAudit"]);
                            this.bussinessInfoForm.get('auditStatus').setValue(supp["auditStatus"]);
                            this.bussinessInfoForm.get('description').setValue(supp["description"]);
                            this.bussinessInfoForm.get('productType').setValue(supp["productType"]);
                            this.bussinessInfoForm.get('SupplierChainPartner').setValue(supp["supplierChainPartner"]);
                            this.bussinessInfoForm.get('speciality').setValue(supp["speciality"]);
                            this.bussinessInfoForm.get('moq').setValue(supp["moq"]);
                            this.bussinessInfoForm.get('leadTime').setValue(supp["leadTime"]);
                            this.bussinessInfoForm.get('capacity').setValue(supp["capacity"]);
                            this.bussinessInfoForm.get('capacityUOM').setValue(supp["capacityUOM"]);
                            //this.bussinessInfoForm.get('machineTypes').setValue(supp["machineTypes"]);
                            if (supp["contact"] && supp["contact"].length > 0) {
                                this.conta = supp["contact"];
                                const contactForm = (this.bussinessInfoForm.controls['Contactss'] as FormGroup);
                                const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
                                const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
                                if (contact1) {

                                    contact1.patchValue({
                                        fullName: this.conta[0]["fullName"],
                                        officeNo: this.conta[0]["officeNo"],
                                        ext: this.conta[0]["ext"],
                                        fax: this.conta[0]["fax"],
                                        contactNo: this.conta[0]["contactNo"],
                                        contactEmail: this.conta[0]["contactEmail"],
                                        address: this.conta[0]["address"],
                                        country: this.conta[0]["country"],
                                        state: this.conta[0]["state"],
                                        city: this.conta[0]["city"],
                                        contactOrder: this.conta[0]["contactOrder"],
                                        zipCode: this.conta[0]["zipCode"],
                                        id: this.conta[0]["id"]
                                    });
                                }
                                if (contact2) {

                                    contact2.patchValue({

                                        fullName: this.conta[1]["fullName"],
                                        officeNo: this.conta[1]["officeNo"],
                                        ext: this.conta[1]["ext"],
                                        fax: this.conta[1]["fax"],
                                        contactNo: this.conta[1]["contactNo"],
                                        contactEmail: this.conta[1]["contactEmail"],
                                        address: this.conta[1]["address"],
                                        country: this.conta[1]["country"],
                                        state: this.conta[1]["state"],
                                        city: this.conta[1]["city"],
                                        contactOrder: this.conta[1]["contactOrder"],
                                        zipCode: this.conta[1]["zipCode"],
                                        id: this.conta[1]["id"]
                                    });
                                    this.secondContact();
                                }
                            }
                            this.bussinessInfoForm.disable();
                        }
                    }
                    this.showLoader1 = false;
                } else {
                    this.showLoader1 = false;
                    this.error("Failed:" + userSupllier.user.message['item1'])
                    this.logger.error(userSupllier.user.message['item1'], "Profile");
                }

            } else {
                this.showLoader1 = false;
                this.logger.error(userSupllier.user.error.error.message, "Profile");
                this.getError(userSupllier.user.error);
            }
        })

        /* this.dataservice.getUserSupplier(id).subscribe((response) => {
            if (response.status === 200) {
                if (response.body['item2'] === "Success") {
                    setTimeout(() => {
                        $('.selectpicker').selectpicker();
                    }, 10);
                    if (response.body['item1']['supplier']) {
                        if (response.body['item1']['supplier'][0]) {
                            let supp = response.body['item1']['supplier'][0];
                            this.logger.info(supp["name"] + " loaded successfully", "Profile");
                            this.refreshTree();
                            this.bussinessInfoForm.get('classification').setValue(supp["classification"]);
                            this.supplierclassificationValue = supp["classification"];
                            if (supp["classification"] === "Factory" || supp["classification"] === "Raw Material Supplier") {
                                this.bussinessInfoForm.get('productType').enable();
                                this.bussinessInfoForm.get('speciality').enable();
                                this.bussinessInfoForm.get('moq').enable();
                                this.bussinessInfoForm.get('leadTime').enable();
                                this.bussinessInfoForm.get('capacity').enable();
                                this.bussinessInfoForm.get('capacityUOM').enable();
                                setTimeout(() => {
                                    $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
                                }, 10);
                            } else {
                                this.refreshTree();
                                this.bussinessInfoForm.get('productType').disable();
                                this.bussinessInfoForm.get('speciality').disable();
                                this.bussinessInfoForm.get('moq').disable();
                                this.bussinessInfoForm.get('leadTime').disable();
                                this.bussinessInfoForm.get('capacity').disable();
                                this.bussinessInfoForm.get('capacityUOM').disable();
                            }
                            if (supp["machineTypes"]) {
                                this.done = [];
                                this.machineTypearr = JSON.parse(supp["machineTypes"]);
                                let i = 0;
                                for (const [key, value] of Object.entries(this.machineTypearr)) {
                                    if (value['value']) {
                                        this.done.push(value['key']);
                                        this.todo = this.todo.filter(x => x != value['key']);
                                        this.bussinessInfoForm.get('machineTypes' + i).patchValue(value['value']);
                                        i++;
                                    }
                                }
                            }
                            //this.bussinessInfoForm.get('supplierid').setValue(supp["id"]);
                            this.supplerId = supp["id"];
                            //this.supplerId = response.body['item1']['role'][0]['role']['id'];
                            this.bussinessInfoForm.get('name').setValue(supp["name"]);
                            if (supp["phoneNumber"]) {
                                const phone = supp["phoneNumber"];
                                const phone1 = phone.substr(phone.length - 10);
                                const phonecode = phone.replace(phone1, "");
                                this.bussinessInfoForm.get('phoneNo').setValue(phone1);
                                this.bussinessInfoForm.get('phone_number_code').setValue(phonecode);
                            }
                            else {
                                this.bussinessInfoForm.get('phoneNo').setValue('');
                                this.bussinessInfoForm.get('phone_number_code').setValue('+1');
                            }

                            //this.bussinessInfoForm.get('phoneNo').setValue(supp["phoneNumber"]);
                            this.bussinessInfoForm.get('email').setValue(supp["email"]);
                            this.bussinessInfoForm.get('lastSocialAudit').setValue(supp["lastSocialAudit"]);
                            this.bussinessInfoForm.get('auditStatus').setValue(supp["auditStatus"]);
                            this.bussinessInfoForm.get('description').setValue(supp["description"]);
                            this.bussinessInfoForm.get('productType').setValue(supp["productType"]);
                            this.bussinessInfoForm.get('SupplierChainPartner').setValue(supp["supplierChainPartner"]);
                            this.bussinessInfoForm.get('speciality').setValue(supp["speciality"]);
                            this.bussinessInfoForm.get('moq').setValue(supp["moq"]);
                            this.bussinessInfoForm.get('leadTime').setValue(supp["leadTime"]);
                            this.bussinessInfoForm.get('capacity').setValue(supp["capacity"]);
                            this.bussinessInfoForm.get('capacityUOM').setValue(supp["capacityUOM"]);
                            //this.bussinessInfoForm.get('machineTypes').setValue(supp["machineTypes"]);
                            if (supp["contacts"].length > 0) {
                                this.conta = supp["contacts"];
                                const contactForm = (this.bussinessInfoForm.controls['Contactss'] as FormGroup);
                                const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
                                const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
                                if (contact1) {

                                    contact1.patchValue({
                                        fullName: this.conta[0]["fullName"],
                                        officeNo: this.conta[0]["officeNo"],
                                        ext: this.conta[0]["ext"],
                                        fax: this.conta[0]["fax"],
                                        contactNo: this.conta[0]["contactNo"],
                                        contactEmail: this.conta[0]["contactEmail"],
                                        address: this.conta[0]["address"],
                                        country: this.conta[0]["country"],
                                        state: this.conta[0]["state"],
                                        city: this.conta[0]["city"],
                                        contactOrder: this.conta[0]["contactOrder"],
                                        zipCode: this.conta[0]["zipCode"],
                                        id: this.conta[0]["id"]
                                    });
                                }
                                if (contact2) {

                                    contact2.patchValue({

                                        fullName: this.conta[1]["fullName"],
                                        officeNo: this.conta[1]["officeNo"],
                                        ext: this.conta[1]["ext"],
                                        fax: this.conta[1]["fax"],
                                        contactNo: this.conta[1]["contactNo"],
                                        contactEmail: this.conta[1]["contactEmail"],
                                        address: this.conta[1]["address"],
                                        country: this.conta[1]["country"],
                                        state: this.conta[1]["state"],
                                        city: this.conta[1]["city"],
                                        contactOrder: this.conta[1]["contactOrder"],
                                        zipCode: this.conta[1]["zipCode"],
                                        id: this.conta[1]["id"]
                                    });
                                    this.secondContact();
                                }
                            }


                            // if (supp["contacts"].length > 0) {
                            //     let conta = supp["contacts"];
                            //     const contactForm = (this.bussinessInfoForm.controls['Contactss'] as FormGroup);
                            //     const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
                            //     const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
                            //     if (conta[0]) {
                            //         contact1.patchValue({
                            //             fullName: conta[0]["fullName"],
                            //             officeNo: conta[0]["officeNo"],
                            //             ext: conta[0]["ext"],
                            //             fax: conta[0]["fax"],
                            //             contactNo: conta[0]["contactNo"],
                            //             contactEmail: conta[0]["contactEmail"],
                            //             address: conta[0]["address"],
                            //             country: conta[0]["country"],
                            //             state: conta[0]["state"],
                            //             city: conta[0]["city"],
                            //             contactOrder: conta[0]["contactOrder"],
                            //             zipCode: conta[0]["zipCode"],
                            //             id: conta[0]["id"]
                            //         });
                            //     }
                            //     if (contact2) {
                            //         if (conta[1]) {
                            //             contact2.patchValue({
                            //                 fullName: conta[1]["fullName"],
                            //                 officeNo: conta[1]["officeNo"],
                            //                 ext: conta[1]["ext"],
                            //                 fax: conta[1]["fax"],
                            //                 contactNo: conta[1]["contactNo"],
                            //                 contactEmail: conta[1]["contactEmail"],
                            //                 address: conta[1]["address"],
                            //                 country: conta[1]["country"],
                            //                 state: conta[1]["state"],
                            //                 city: conta[1]["city"],
                            //                 contactOrder: conta[1]["contactOrder"],
                            //                 zipCode: conta[1]["zipCode"],
                            //                 id: conta[1]["id"]
                            //             });
                            //         }
                            //         this.secondContact();
                            //     }
                            // }
                            this.bussinessInfoForm.disable();
                        }
                    }
                }
                else {
                    this.showLoader1 = false;
                }
            }
            else {
                this.showLoader1 = false;
            }
        }, err => {
            this.logger.error(err.error.message, "Profile");
            if (err.error.message === "Network error communicating with endpoint") {
                this.error(err.error.message);
                this.showLoader = false;
            } else if (err.error.message === "The incoming token has expired") {
                this.sessionTimedOut();
                this.showLoader = false;
            } else if (err.error.message === "Unauthorized") {
                this.userservice.error();
                this.showLoader = false;
            }
            else {
                this.error(err.message);
                this.logger.error(err.message, "Profile");

            }
            this.showLoader = false;
        });
 */
    }

    //submit Bussiness Info
    async onSubmitBussinessInfo() {
        this.submittedBusinessInfo = true;
        this.isDateNull = true;
        if (!this.bussinessInfoForm.valid) {
          this.error(this.staticMsgs.profile.bussinessInvalid)
            return false;
        }
        let lastSocialStr = this.lastSocialAuditDate.year + '-' + this.lastSocialAuditDate.month + '-' + this.lastSocialAuditDate.day;
        if (lastSocialStr == null) {
            this.isDateNull = true;
            return false;
        }
        else {
            this.isDateNull = false;
        }

        this.showLoader = true;
        let jsonArrayObject = [];
        jsonArrayObject = this.fs.Contactss.value;
        if (jsonArrayObject['contactOrder1']['fullName']) {
            jsonArrayObject['contactOrder1'].fullName = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["fullName"]),
                jsonArrayObject['contactOrder1'].officeNo = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["officeNo"]),
                jsonArrayObject['contactOrder1'].ext = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["ext"]),
                jsonArrayObject['contactOrder1'].fax = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["fax"]),
                jsonArrayObject['contactOrder1']["contactNo"] = jsonArrayObject['contactOrder1']["contactNo"].toString();
            jsonArrayObject['contactOrder1'].contactNo = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["contactNo"]),
                jsonArrayObject['contactOrder1'].contactEmail = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["contactEmail"]),
                jsonArrayObject['contactOrder1'].address = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["address"]),
                jsonArrayObject['contactOrder1'].country = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["country"]),
                jsonArrayObject['contactOrder1'].state = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["state"]),
                jsonArrayObject['contactOrder1'].city = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["city"]),
                jsonArrayObject['contactOrder1'].contactOrder = 1,
                jsonArrayObject['contactOrder1'].zipCode = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["zipCode"])
        }
        if (jsonArrayObject['contactOrder2']['fullName']) {
            jsonArrayObject['contactOrder2'].fullName = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["fullName"]),
                jsonArrayObject['contactOrder2'].officeNo = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["officeNo"]),
                jsonArrayObject['contactOrder2'].ext = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["ext"]),
                jsonArrayObject['contactOrder2'].fax = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["fax"]),
                jsonArrayObject['contactOrder2']["contactNo"] = jsonArrayObject['contactOrder2']["contactNo"].toString();
            jsonArrayObject['contactOrder2'].contactNo = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["contactNo"]),
                jsonArrayObject['contactOrder2'].contactEmail = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["contactEmail"]),
                jsonArrayObject['contactOrder2'].address = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["address"]),
                jsonArrayObject['contactOrder2'].country = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["country"]),
                jsonArrayObject['contactOrder2'].state = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["state"]),
                jsonArrayObject['contactOrder2'].city = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["city"]),
                jsonArrayObject['contactOrder2'].contactOrder = 2,
                jsonArrayObject['contactOrder2'].zipCode = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["zipCode"])
        }


        if (jsonArrayObject['contactOrder1']['fullName'] && jsonArrayObject['contactOrder2']['fullName']) {
            this.pricontact = [jsonArrayObject['contactOrder1'], jsonArrayObject['contactOrder2']];
        } else if (jsonArrayObject['contactOrder1']['fullName']) {
            this.pricontact = [jsonArrayObject['contactOrder1']];
        }
        else if (jsonArrayObject['contactOrder2']['fullName']) {
            this.pricontact = [jsonArrayObject['contactOrder2']];
        }

        let sam = this.mechine();
        // console.log(sam)
        let mechinetype = JSON.stringify(Object.assign({}, sam));;
        if (this.fs.phoneNo.value) {
            this.phone_number = this.fs.phone_number_code.value + this.fs.phoneNo.value;
        }

        if (!this.fs.leadTime.value) {
            this.leadTimeee = 0;
        } else {
            this.leadTimeee = this.fs.leadTime.value;
        }

        if (!this.fs.moq.value) {
            this.moqee = 0;
        } else {
            this.moqee = this.fs.moq.value;
        }

        if (!this.fs.capacity.value) {
            this.capacityee = 0;
        } else {
            this.capacityee = this.fs.capacity.value;
        }

        let supplierData = {
            userid: this.currentUser.userid,
            classification: this.aesdecryptservice.encryptData(this.dataservice.Key, this.supplierclassificationValue),
            name: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fs.name.value),
            auditStatus: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fs.auditStatus.value),
            phoneNo: this.aesdecryptservice.encryptData(this.dataservice.Key, this.phone_number),
            email: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fs.email.value),
            productType: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fs.productType.value),
            speciality: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fs.speciality.value),
            moq: this.moqee,
            SupplierChainPartner: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fs.SupplierChainPartner.value),
            leadTime: this.leadTimeee,
            capacity: this.capacityee,
            capacityUOM: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fs.capacityUOM.value),
            lastSocialAudit: lastSocialStr,
            description: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fs.description.value),
            Contact: this.pricontact,
            machineTypes: this.aesdecryptservice.encryptData(this.dataservice.Key, mechinetype),
            Secondarycontact: this.secondarycontactId
        };
        if (!this.supplerId) {
            this.store.dispatch(new userActionTypes.CreateUserSupplier(supplierData));
            this.store.pipe(select(state => state), take(2), skip(1)).subscribe((newsupplierData) => {
                if (newsupplierData.user.error === "") {
                    if (newsupplierData.user.message['item2'] === 'Success') {
                        this.showLoader = false;
                        this.submittedBusinessInfo = false;
                        this.isEditMode = false;
                        this.success(this.staticMsgs.profile.bussinesInfo);
                        this.logger.info(this.staticMsgs.profile.bussinesInfo, "Profile");
                        this.getUserDetails();
                        this.bussinessInfoDetailsTab(this.activeTab);
                    } else {
                        this.showLoader = false;
                        this.showLoader1 = false;
                        this.error("Failed:" + newsupplierData.user.message['item1'])
                        this.logger.error(newsupplierData.user.message['item1'], "Profile");
                    }
                } else {
                    this.logger.error(newsupplierData.user.error.error.message, "Profile");
                    this.getError(newsupplierData.user.error);
                    this.showLoader = false;
                    this.showLoader1 = false;
                }
            })


            //console.log(JSON.stringify(colorData))
            /* this.dataservice.addUserSupplier(supplierData).subscribe(response => {
                if (response.status === 200) {
                    if (response.body['item2'] === "Success") {
                        //console.log(JSON.stringify(response.body['item1']));
                        this.showLoader = false;
                        this.submittedBusinessInfo = false;
                        this.isEditMode = true;
                        //this.editedSupplierData = response.body['item1'];
                        //this.router.navigate(['/supplierlibrary']);
                        this.success("Business Info Created Successfully.");
                        this.logger.info("Business Info Created Successfully", "Profile");

                        this.bussinessInfoDetailsTab(this.activeTab);
                    }
                    else {
                        this.error(response.body['item2']);
                        this.logger.error(response.body['item2'], "Profile");
                        this.showLoader = false;
                    }

                }
            }, err => {
                this.logger.error(err.error.message, "Profile");
                if (err.error.message === "Network error communicating with endpoint") {
                    this.error(err.error.message);
                } else if (err.error.message === "The incoming token has expired") {
                    this.sessionTimedOut();
                    this.userservice.logout()
                } else if (err.error.message === "Unauthorized") {
                    this.userservice.error();
                }
                else {
                    this.error(err.message);
                    this.logger.error(err.message, "Profile");
                }
                this.showLoader = false;
            }); */

        } else {
            let supplierrrid = { id: this.supplerId };
            let updatedSupplierData = { ...supplierrrid, ...supplierData };
            console.log(JSON.stringify(updatedSupplierData))
            this.store.dispatch(new userActionTypes.UpdateUserSupplier(updatedSupplierData));
            this.store.pipe(select(state => state), take(2), skip(1)).subscribe((supplierData) => {
                if (supplierData.user.error === "") {
                    if (supplierData.user.message['item2'] === 'Success') {
                        this.showLoader = false;
                        this.submittedBusinessInfo = false;
                        this.isEditMode = true;
                        this.success(this.staticMsgs.profile.bussinesInfo);
                        this.logger.info(this.staticMsgs.profile.bussinesInfo, "Profile");
                        this.getUserDetails();
                        this.bussinessInfoDetailsTab(this.activeTab);
                    } else {
                        this.showLoader = false;
                        this.showLoader1 = false;
                        this.error("Failed:" + supplierData.user.message['item1'])
                        this.logger.error(supplierData.user.message['item1'], "Profile");
                    }
                } else {
                    this.logger.error(supplierData.user.error.error.message, "Profile");
                    this.getError(supplierData.user.error);
                    this.showLoader = false;
                    this.showLoader1 = false;
                }
            })


            /* this.dataservice.updateUserSupplier(updatedSupplierData).subscribe(response => {
                if (response.status === 200) {
                    if (response.body['item2'] === "Success") {
                        //console.log(JSON.stringify(response.body['item1']));
                        this.showLoader = false;
                        this.submittedBusinessInfo = false;
                        this.isEditMode = true;
                        this.success("Business Info Updated Successfully.");
                        this.logger.info("Business Info Updated Successfully", "Profile");
                        this.bussinessInfoDetailsTab(this.activeTab);
                    }
                    else {
                        console.log(response)
                        this.error(response.body['item2']);
                        this.logger.error(response.body['item2'], "Profile");
                        this.showLoader = false;
                    }

                }
            }, err => {
                this.logger.error(err.error.message, "Profile");
                if (err.error.message === "Network error communicating with endpoint") {
                    this.error(err.error.message);
                } else if (err.error.message === "The incoming token has expired") {
                    this.sessionTimedOut();
                    this.userservice.logout()
                } else if (err.error.message === "Unauthorized") {
                    this.userservice.error();
                }
                else {
                    this.error(err.message);
                    this.logger.error(err.message, "Profile");
                }
                this.showLoader = false;
            }); */
        }

    }


    //submit portfolio
    async onSubmitportfolio() {

        this.submittedPortfolio = true;
        var fileid = null;
        var filename = null;

        if (!this.portfolioDetails.portfolioid) {
            if (this.files.length === 1) {
                this.isThumbnailAdded = true;
            }
            else {
                this.isThumbnailAdded = false;
            }
        }
        else {
            if (this.viewfile.length === 1) {
                this.isThumbnailAdded = true;
            }
            else {
                if (this.files.length === 1) {
                    this.isThumbnailAdded = true;
                }
                else {
                    this.isThumbnailAdded = false;
                }
            }
        }


        if (!this.portfolioForm.valid) {
          this.error(this.staticMsgs.profile.portfolioInvalid)
          return false;
        } else if (!this.isThumbnailAdded) {
            return false;
        }

        this.showLoader = true;
        if (this.isThumbnailAdded) {
            if (this.files.length === 1) {
                let uploaded = await this.uploadFile(this.files[0]);
                if (uploaded != "error") {
                    fileid = JSON.parse(uploaded)["Key"];
                    filename = this.files[0].name;
                }
                else {
                    this.error(uploaded);
                    this.submitted = false;
                    this.showLoader = false;
                    return false;
                }
            }
        }

        const formData = new FormData();
        formData.append('userid', this.fP.userid.value);
        formData.append('name', this.aesdecryptservice.encryptData(this.dataservice.Key, this.fP.imageName.value));
        formData.append('status', this.aesdecryptservice.encryptData(this.dataservice.Key, this.fP.status.value));
        if (this.isThumbnailAdded) {
            formData.append('thumbnail', this.aesdecryptservice.encryptData(this.dataservice.Key, fileid));
        }

        //Add Portfolio
        if (!this.portfolioDetails.portfolioid) {
            this.store.dispatch(new userActionTypes.CreateUserPortfolio(formData));
            this.store.pipe(select(state => state), take(2), skip(1)).subscribe((newUserPortfolio) => {
                if (newUserPortfolio.user.error === "") {
                    if (newUserPortfolio.user.message['item2'] === 'Success') {
                        this.showLoader = false;
                        this.submittedPortfolio = false;
                        this.files = [];
                        this.success(this.staticMsgs.profile.portfolioUpd);
                        this.logger.info(this.staticMsgs.profile.portfolioUpd, "Profile");
                        this.getUserDetails();
                        this.portfolioDetailsTab(this.activeTab);
                    } else {
                        this.showLoader = false;
                        this.showLoader1 = false;
                        this.error("Failed:" + newUserPortfolio.user.message['item1'])
                        this.logger.error(newUserPortfolio.user.message['item1'], "Profile");
                    }
                } else {
                    this.logger.error(newUserPortfolio.user.error.error.message, "Profile");
                    this.getError(newUserPortfolio.user.error);
                    this.showLoader = false;
                    this.showLoader1 = false;
                }
            })


            /* this.dataservice.addUserPortfolio(formData).subscribe(response => {
                if (response.status === 200) {
                    if (response.body['item2'] === "Success") {
                        this.showLoader = false;
                        this.submittedPortfolio = false;
                        this.files = [];
                        this.success("Portfolio Created Successfully");
                        this.logger.info("Portfolio Created Successfully", "Profile");
                        this.portfolioDetailsTab(this.activeTab);
                    }
                    else {
                        this.error(response.body['item1']);
                        this.logger.error(response.body['item1'], "Profile");
                        this.showLoader = false;
                    }
                }
            }, err => {
                this.logger.error(err.error.message, "Profile");
                if (err.error.message === "Network error communicating with endpoint") {
                    this.error(err.error.message);
                } else if (err.error.message === "The incoming token has expired") {
                    this.sessionTimedOut();
                    this.userservice.logout()
                } else if (err.error.message === "Unauthorized") {
                    this.userservice.error();
                }
                else {
                    this.error(err.message);
                    this.logger.error(err.message, "Profile");
                }
                this.showLoader = false;

            }); */
        }
        else {
            //Update Portfolio
            let updatedate = {
                id: this.portfolioDetails.portfolioid,
                userid: this.currentUser.userid,
                name: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fP.imageName.value),
                status: this.aesdecryptservice.encryptData(this.dataservice.Key, this.fP.status.value),
                thumbnail: fileid,
            };

            this.store.dispatch(new userActionTypes.UpdateUserPortfolio(updatedate));
            this.store.pipe(select(state => state), take(2), skip(1)).subscribe((userPortfolio) => {
                if (userPortfolio.user.error === "") {
                    if (userPortfolio.user.message['item2'] === 'Success') {
                        this.showLoader = false;
                        this.submittedPortfolio = false;
                        this.files = [];
                        this.success(this.staticMsgs.profile.portfolioUpd);
                        this.logger.info(this.staticMsgs.profile.portfolioUpd, "Profile");
                        this.getUserDetails();
                        this.portfolioDetailsTab(this.activeTab);
                    } else {
                        this.showLoader = false;
                        this.showLoader1 = false;
                        this.error("Failed:" + userPortfolio.user.message['item1'])
                        this.logger.error(userPortfolio.user.message['item1'], "Profile");
                    }
                } else {
                    this.logger.error(userPortfolio.user.error.error.message, "Profile");
                    this.getError(userPortfolio.user.error);
                    this.showLoader = false;
                    this.showLoader1 = false;
                }
            })
            /* this.dataservice.updateUserPortfolio(updatedate).subscribe(response => {
                if (response.status === 200) {
                    if (response.body['item2'] === "Success") {
                        //console.log(JSON.stringify(response.body['item1']));
                        this.showLoader = false;
                        this.submittedPortfolio = false;
                        this.files = [];
                        this.success("Portfolio Updated Successfully");
                        this.logger.info("Portfolio Updated Successfully", "Profile");
                        this.portfolioDetailsTab(this.activeTab);
                    }
                    else {
                        console.log(response)
                        this.error(response.body['item1']);
                        this.logger.error(response.body['item1'], "Profile");
                        this.showLoader = false;
                    }

                }
            }, err => {
                this.logger.error(err.error.message, "Profile");
                if (err.error.message === "Network error communicating with endpoint") {
                    this.error(err.error.message);
                } else if (err.error.message === "The incoming token has expired") {
                    this.sessionTimedOut();
                    this.userservice.logout()
                } else if (err.error.message === "Unauthorized") {
                    this.userservice.error();
                }
                else {
                    this.error(err.message);
                    this.logger.error(err.error.message, "Profile");
                }
                this.showLoader = false;
            }); */
        }
        this.getBusinessDetails(this.currentUser.userid);
    }

    async uploadFile(file): Promise<any> {

        const contentType = file.type;
        const bucket = new S3(
            {
                accessKeyId: `${environment.BucketAccessKeyId}`,
                secretAccessKey: `${environment.BucketSecretAccessKey}`,
                region: `${environment.BucketRegion}`
            }
        );

        const params = {
            Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
            Key: file.name,
            Body: file,
            //ACL: 'public-read',
            ContentType: contentType
        };

        return new Promise((resolve, reject) => {
            bucket.upload(params, function (err, data) {
                if (err) {
                    reject("error");
                }
                resolve(JSON.stringify(data));
            });
        });
    };


    createUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    removeSection(event) {
        this.viewfile.splice(this.viewfile.indexOf(event), 1);
    }

    isValidImageURL(str) {
        str = str.toLowerCase();
        str = str.split('?')[0];
        //moving on, split the uri into parts that had dots before them
        var parts = str.split('.');
        //get the last part ( should be the extension )
        var extension = parts[parts.length - 1];
        //define some image types to test against
        var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
        //check if the extension matches anything in the list.
        if (imageTypes.indexOf(extension) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    dateChange(date: any, type: any) {
        if (type === 'lastSocialAudit') {
            this.lastSocialAuditDate = new NgbDate(date.year, date.month, date.day);
        }
    }
}
