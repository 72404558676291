import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

export enum SeamsActionTypes {
    LOAD_SEAMS_LIBRARY = '[Seams] Load Seams Library',
    LOAD_SEAMS_LIBRARY_SUCCESS = '[Seams] Load Seams Library Success',
    LOAD_SEAMS_LIBRARY_FAIL = '[Seams] Load Seams Library Fail',
    LOAD_SEAMS = '[Seams] Load Seams',
    LOAD_SEAMS_SUCCESS = '[Seams] Load Seams Success',
    LOAD_SEAMS_FAIL = '[Seams] Load Seams Fail',
    CREATE_SEAMS = '[Seams] Create Seams',
    CREATE_SEAMS_SUCCESS = '[Seams] Create Seams Success',
    CREATE_SEAMS_FAIL = '[Seams] Create Seams Fail',
    UPDATE_SEAMS = '[Seams] Update Seams',
    UPDATE_SEAMS_SUCCESS = '[Seams] Update Seams Success',
    UPDATE_SEAMS_FAIL = '[Seams] Update Seams Fail',
    UPDATE_SEAMS_LIBRARY = '[Seams] Update Seams Library',
    UPDATE_SEAMS_LIBRARY_SUCCESS = '[Seams] Update Seams Library Success',
    UPDATE_SEAMS_LIBRARY_FAIL = '[Seams] Update Seams Library Fail',
    DELETE_SEAMS_LIBRARY = '[Seams] Delete Seams Library',
    DELETE_SEAMS_LIBRARY_SUCCESS = '[Seams] Delete Seams Library Success',
    DELETE_SEAMS_LIBRARY_FAIL = '[Seams] Delete Seams Library Fail',
    UPDATE_SEAMS_DOCUMENT = '[Seams] Update Seams Document',
    UPDATE_SEAMS_DOCUMENT_SUCCESS = '[Seams] Update Seams Document Success',
    UPDATE_SEAMS_DOCUMENT_FAIL = '[Seams] Update Seams Document Fail',
}

export class LoadSeamsLibrary implements Action {
    readonly type = SeamsActionTypes.LOAD_SEAMS_LIBRARY
    constructor(public payload1: string,public payload2: string) { }
}

export class LoadSeamsLibrarySuccess implements Action {
    readonly type = SeamsActionTypes.LOAD_SEAMS_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadSeamsLibraryFail implements Action {
    readonly type = SeamsActionTypes.LOAD_SEAMS_LIBRARY_FAIL

    constructor(public payload: string) { }
}

export class LoadSeams implements Action {
    readonly type = SeamsActionTypes.LOAD_SEAMS

    constructor(public payload: string) { }
}

export class LoadSeamsSuccess implements Action {
    readonly type = SeamsActionTypes.LOAD_SEAMS_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class LoadSeamsFail implements Action {
    readonly type = SeamsActionTypes.LOAD_SEAMS_FAIL

    constructor(public payload: string) { }
}
export class CreateSeams implements Action {
    readonly type = SeamsActionTypes.CREATE_SEAMS

    constructor(public payload: any) { }
}

export class CreateSeamsSuccess implements Action {
    readonly type = SeamsActionTypes.CREATE_SEAMS_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class CreateSeamsFail implements Action {
    readonly type = SeamsActionTypes.CREATE_SEAMS_FAIL

    constructor(public payload: string) { }
}
export class UpdateSeams implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS

    constructor(public payload: any) { }
}

export class UpdateSeamsSuccess implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateSeamsFail implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS_FAIL

    constructor(public payload: string) { }
}

export class UpdateSeamsLibrary implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS_LIBRARY

    constructor(public payload: any[]) { }
}

export class UpdateSeamsLibrarySuccess implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS_LIBRARY_SUCCESS

    constructor(public payload1: Update<any>[],public payload2: any) { }
}

export class UpdateSeamsLibraryFail implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class DeleteSeamsLibrary implements Action {
    readonly type = SeamsActionTypes.DELETE_SEAMS_LIBRARY

    constructor(public payload: string[]) { }
}

export class DeleteSeamsLibrarySuccess implements Action {
    readonly type = SeamsActionTypes.DELETE_SEAMS_LIBRARY_SUCCESS

    constructor(public payload1: any,public payload2: any) { }
}

export class DeleteSeamsLibraryFail implements Action {
    readonly type = SeamsActionTypes.DELETE_SEAMS_LIBRARY_FAIL

    constructor(public payload: string) { }
}
export class UpdateSeamsDocument implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS_DOCUMENT

    constructor(public payload: any) { }
}

export class UpdateSeamsDocumentSuccess implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS_DOCUMENT_SUCCESS

    constructor(public payload1: Update<any>,public payload2: any) { }
}

export class UpdateSeamsDocumentFail implements Action {
    readonly type = SeamsActionTypes.UPDATE_SEAMS_DOCUMENT_FAIL

    constructor(public payload: string) { }
}
export type seamsActionTypes = LoadSeamsLibrary | LoadSeamsLibrarySuccess | LoadSeamsLibraryFail |
    LoadSeams | LoadSeamsSuccess | LoadSeamsFail | CreateSeams | CreateSeamsSuccess |
    CreateSeamsFail | UpdateSeams | UpdateSeamsSuccess | UpdateSeamsFail | UpdateSeamsLibrary | UpdateSeamsLibrarySuccess
    | UpdateSeamsLibraryFail | DeleteSeamsLibrary | DeleteSeamsLibrarySuccess | DeleteSeamsLibraryFail
    | UpdateSeamsDocument | UpdateSeamsDocumentSuccess | UpdateSeamsDocumentFail;