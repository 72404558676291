<div data-toggle="modal">
  <div class="dt-page__header">
    <button class="close" mat-button (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
    <h3 class="dt-page__title" *ngIf="!data.isprocessingsewnedit">Create Processing SEWN</h3>
    <h3 class="dt-page__title" *ngIf="data.isprocessingsewnedit">Edit Processing SEWN</h3>
  </div>
  <mat-dialog-content class="mat-typography">
    <svg *ngIf="formload " viewBox="25 25 50 50">
      <circle r="20" cy="50" cx="50"></circle>
    </svg>
    <div id="tab-pane" class="tab-pane bg-white active">
      <form [formGroup]="sewnForm">
        <div class="form-row">
          <div class="col-lg-4 col-sm-4 col-md-4 mb-5">
            <label for="validationDefault02">SEWN</label>
            <input name="sewn" formControlName="sewn" class="form-control fill" placeholder="Enter sewn">
          </div>
          <div class="col-lg-4 col-sm-4 col-md-4  mb-5">
            <label for="validationDefault02">PC</label>
            <input name="pc" formControlName="pc" class="form-control fill" placeholder="Enter pc">
          </div>
          <div class="col-lg-4 col-sm-4 col-md-4  mb-5">
            <label for="validationDefault02">POMS</label>
            <input name="poms" formControlName="poms" class="form-control fill" placeholder="Enter poms">
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 col-md-4 col-sm-4 mb-5">
            <div class="dt-entry__header mt-5">
              <!-- Entry Heading -->
              <div class="dt-entry__heading">
                <h3 class="dt-entry__title">Original</h3>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-4 col-sm-4 mb-5">
            <div class="dt-entry__header mt-5">
              <!-- Entry Heading -->
              <div class="dt-entry__heading">
                <h3 class="dt-entry__title">After Wash</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 col-sm-6 col-md-6 mb-5">
            <div class="form-row">
              <div class="col-sm-4 mb-3">
                <label for="validationDefault01">Length</label>
                <input name="originalLength" width="80" (keypress)="numberOnly($event)" formControlName="originalLength" class="form-control fill"
                  placeholder="Enter originalLength">
              </div>
              <div class="col-sm-4 mb-3">
                <label for="validationDefault01">Width</label>
                <input name="originalWidth" width="80" (keypress)="numberOnly($event)" formControlName="originalWidth" class="form-control fill"
                  placeholder="Enter originalWidth">
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-md-6 mb-5">
            <div class="form-row">
              <div class="col-sm-4 mb-3">
                <label for="validationDefault01">Length</label>
                <input name="afterwashLength" width="80" (keypress)="numberOnly($event)" formControlName="afterwashLength" class="form-control fill"
                  placeholder="Enter afterwashLength">
              </div>
              <div class="col-sm-4 mb-3">
                <label for="validationDefault01">Width</label>
                <input name="afterwashWidth" width="80" (keypress)="numberOnly($event)" formControlName="afterwashWidth" class="form-control fill"
                  placeholder="Enter afterwashWidth">
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 col-md-4 col-sm-4 mb-5">
            <div class="dt-entry__header mt-5">
              <!-- Entry Heading -->
              <div class="dt-entry__heading">
                <h3 class="dt-entry__title">After Dry</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 col-sm-6 col-md-6 mb-5">
            <div class="form-row">
              <div class="col-sm-4 mb-3">
                <label for="validationDefault01">Length</label>
                <input name="afterdryLength" width="80" (keypress)="numberOnly($event)" formControlName="afterdryLength" class="form-control fill"
                  placeholder="Enter afterdryLength">
              </div>
              <div class="col-sm-4 mb-3">
                <label for="validationDefault01">Width</label>
                <input name="afterdryWidth" width="80" (keypress)="numberOnly($event)" formControlName="afterdryWidth" class="form-control fill"
                  placeholder="Enter afterdryWidth">
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row mt-5 float-right mr-3">
            <button type="submit" class="btn btn-outline-primary btn-sm" (click)="close()">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary btn-sm mr-2" [disabled]="sewnForm.invalid"
             (click)="onsewnsubmit()">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</div>
