import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as supplierActions from "../actions/supplier.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";


@Injectable()

export class SupplierEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }

    getAllSupplierDetails(data) {
        data.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.name);
        data.classification = this.aesdecryptservice.decryptData(this.dataService.Key, data.classification);
        data.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, data.sequence);
        if (data.phoneNumber) {
            data.phoneNumber = this.aesdecryptservice.decryptData(this.dataService.Key, data.phoneNumber);
        }
        if (data.phoneNo) {
            data.phoneNo = this.aesdecryptservice.decryptData(this.dataService.Key, data.phoneNo);
        }
        data.email = this.aesdecryptservice.decryptData(this.dataService.Key, data.email);
        //data.lastSocialAudit = this.aesdecryptservice.decryptData(this.dataService.Key, data.lastSocialAudit);
        data.auditStatus = this.aesdecryptservice.decryptData(this.dataService.Key, data.auditStatus);
        data.description = this.aesdecryptservice.decryptData(this.dataService.Key, data.description);
        data.productType = this.aesdecryptservice.decryptData(this.dataService.Key, data.productType);
        data.supplierChainPartner = this.aesdecryptservice.decryptData(this.dataService.Key, data.supplierChainPartner);
        data.speciality = this.aesdecryptservice.decryptData(this.dataService.Key, data.speciality);
        //data.moq = this.aesdecryptservice.decryptData(this.dataService.Key, data.moq);
        data.moqUOM = this.aesdecryptservice.decryptData(this.dataService.Key, data.moqUOM);
        data.capacityUOM = this.aesdecryptservice.decryptData(this.dataService.Key, data.capacityUOM);
        data.machineTypes = this.aesdecryptservice.decryptData(this.dataService.Key, data.machineTypes);
        if (data.createdBy) {
            data.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.firstName);
            data.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.lastName);
        }
        if (data.contact && Object.keys(data.contact).length !== 0) {
            data.contact.forEach(x => {
                x.fullName = this.aesdecryptservice.decryptData(this.dataService.Key, x.fullName);
                x.officeNo = this.aesdecryptservice.decryptData(this.dataService.Key, x.officeNo);
                x.ext = this.aesdecryptservice.decryptData(this.dataService.Key, x.ext);
                x.fax = this.aesdecryptservice.decryptData(this.dataService.Key, x.fax);
                x.contactNo = this.aesdecryptservice.decryptData(this.dataService.Key, x.contactNo);
                x.contactEmail = this.aesdecryptservice.decryptData(this.dataService.Key, x.contactEmail);
                x.address = this.aesdecryptservice.decryptData(this.dataService.Key, x.address);
                x.country = this.aesdecryptservice.decryptData(this.dataService.Key, x.country);
                x.state = this.aesdecryptservice.decryptData(this.dataService.Key, x.state);
                x.city = this.aesdecryptservice.decryptData(this.dataService.Key, x.city);
                x.contactOrder = this.aesdecryptservice.decryptData(this.dataService.Key, x.contactOrder);
                x.zipCode = this.aesdecryptservice.decryptData(this.dataService.Key, x.zipCode);
            });
        }
        if (data.topViewFile) {
            data.topViewFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, data.topViewFile.thumbnail);
            data.topViewFile.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.topViewFile.name);
        }
        if (data.bottomViewFile) {
            data.bottomViewFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, data.bottomViewFile.thumbnail);
            data.bottomViewFile.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.bottomViewFile.name);
        }
        if (data.documents && Object.keys(data.documents).length !== 0) {
            data.documents.forEach(x => {
                x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                x.reportStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.reportStatus);
                x.labLocation = this.aesdecryptservice.decryptData(this.dataService.Key, x.labLocation);
                x.notes = this.aesdecryptservice.decryptData(this.dataService.Key, x.notes);
                if (x.files) {
                    x.files.forEach(y => {
                        y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name);
                        y.fileType = this.aesdecryptservice.decryptData(this.dataService.Key, y.fileType);
                        y.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnail);
                    });
                }

            });
        }
        console.log(data);
    }


    @Effect()
    loadSupplierLibrary: Observable<Action> = this.actions.pipe(
        ofType<supplierActions.LoadSupplierLibrary>(
            supplierActions.SupplierActionTypes.LOAD_SUPPLIER_LIBRARY
        ), mergeMap((action: supplierActions.LoadSupplierLibrary) =>
            this.dataService.getSuppliers(action.payload1, action.payload2).pipe(
                map((supplierLibrary: any[]) => {
                    if (supplierLibrary['body']['item1'].length > 0 && !supplierLibrary['body']['item2'].toLowerCase().includes('error')) {
                        supplierLibrary['body']['item1'].forEach(x => {
                            if (x.contact) {
                                x.contact.forEach(y => {
                                    y.country = this.aesdecryptservice.decryptData(this.dataService.Key, y.country);
                                    y.state = this.aesdecryptservice.decryptData(this.dataService.Key, y.state);
                                });
                            }
                            x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.phoneNo = this.aesdecryptservice.decryptData(this.dataService.Key, x.phoneNo);
                            x.phoneNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.phoneNumber);
                            x.email = this.aesdecryptservice.decryptData(this.dataService.Key, x.email);
                            x.auditStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.auditStatus);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            if (x.createdBy != null) {
                                x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                                x.CreatedbyName= x.createdBy.firstName;
                            }
                        });
                    }
                    return new supplierActions.LoadSupplierLibrarySuccess(supplierLibrary['body']['item1'], supplierLibrary['body'])
                }),
                catchError(err => of(new supplierActions.LoadSupplierLibraryFail(err)))
            )
        )
    );
    @Effect()
    loadSupplier: Observable<Action> = this.actions.pipe(
        ofType<supplierActions.LoadSupplier>(
            supplierActions.SupplierActionTypes.LOAD_SUPPLIER
        ), mergeMap((action: supplierActions.LoadSupplier) =>
            this.dataService.getSupplierById(action.payload).pipe(
                map((supplier: any) => {
                    if (!supplier['body']['item2'].toLowerCase().includes('error')) {

                        this.getAllSupplierDetails(supplier['body']['item1'][0])
                    }
                    return new supplierActions.LoadSupplierSuccess(supplier['body']['item1'][0], supplier['body'])
                }),
                catchError(err => of(new supplierActions.LoadSupplierFail(err)))
            )
        )
    );
    @Effect()
    createSupplier: Observable<any> = this.actions.pipe(
        ofType<supplierActions.CreateSupplier>(
            supplierActions.SupplierActionTypes.CREATE_SUPPLIER
        ), map((action: supplierActions.CreateSupplier) => action.payload),
        mergeMap((supplier: any) =>
            this.dataService.createSupplier(supplier).pipe(
                map((newSupplier: any) =>{
                    if (!newSupplier['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllSupplierDetails(newSupplier['body']['item1'])
                    }
                   return new supplierActions.CreateSupplierSuccess(newSupplier['body']['item1'], newSupplier['body'])
                }),
                catchError(err => of(new supplierActions.CreateSupplierFail(err)))
            )
        )
    );

    @Effect()
    updateSupplier: Observable<any> = this.actions.pipe(
        ofType<supplierActions.UpdateSupplier>(
            supplierActions.SupplierActionTypes.UPDATE_SUPPLIER
        ), map((action: supplierActions.UpdateSupplier) => action.payload),
        mergeMap((supplier: any) =>
            this.dataService.updateUserSupplier(supplier).pipe(
                map((updatedSupplier: any) =>{
                    if (!updatedSupplier['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllSupplierDetails(updatedSupplier['body']['item1'][0])
                    }
                   return new supplierActions.UpdateSupplierSuccess({
                        id: updatedSupplier['body']['item1'].id,
                        changes: updatedSupplier['body']['item1']
                    }, updatedSupplier['body'])
                }),
                catchError(err => of(new supplierActions.UpdateSupplierFail(err)))
            )
        )
    );

    @Effect()
    updateSupplierLibrary: Observable<any> = this.actions.pipe(
        ofType<supplierActions.UpdateSupplierLibrary>(
            supplierActions.SupplierActionTypes.UPDATE_SUPPLIER_LIBRARY
        ), map((action: supplierActions.UpdateSupplierLibrary) => action.payload),
        mergeMap((supplierIds: any[]) =>
            this.dataService.BulkUpdateSupplier(supplierIds).pipe(
                map((updatedSupplier: any) =>
                    new supplierActions.UpdateSupplierLibrarySuccess([{
                        id: updatedSupplier['body']['item1'].id,
                        changes: updatedSupplier['body']['item1']
                    }], updatedSupplier['body'])
                ),
                catchError(err => of(new supplierActions.UpdateSupplierLibraryFail(err)))
            )
        )
    );

    @Effect()
    deleteSupplier: Observable<any> = this.actions.pipe(
        ofType<supplierActions.DeleteSupplierLibrary>(
            supplierActions.SupplierActionTypes.DELETE_SUPPLIER_LIBRARY
        ), map((action: supplierActions.DeleteSupplierLibrary) => action.payload),
        mergeMap((supplierIds: any[]) =>
            this.dataService.BulkDeleteSupplier(supplierIds).pipe(
                map((deletedSupplier: any) =>
                    new supplierActions.DeleteSupplierLibrarySuccess(supplierIds, deletedSupplier['body'])
                ),
                catchError(err => of(new supplierActions.DeleteSupplierLibraryFail(err)))
            )
        )
    );

    @Effect()
    updateSupplierDocument: Observable<any> = this.actions.pipe(
        ofType<supplierActions.UpdateSupplierDocument>(
            supplierActions.SupplierActionTypes.UPDATE_SUPPLIER_DOCUMENT
        ), map((action: supplierActions.UpdateSupplierDocument) => action.payload),
        mergeMap((supplierdocument: any) =>
            this.dataService.updateSupplierDocuments(supplierdocument).pipe(
                map((updatedSupplierDocument: any) =>{
                    if (!updatedSupplierDocument['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllSupplierDetails(updatedSupplierDocument['body']['item1'])
                    }
                    return new supplierActions.UpdateSupplierDocumentSuccess({
                        id: updatedSupplierDocument['body']['item1'].id,
                        changes: updatedSupplierDocument['body']['item1']
                    }, updatedSupplierDocument['body'])
                }),
                catchError(err => of(new supplierActions.UpdateSupplierDocumentFail(err)))
            )
        )
    );
}
