<div class="dt-page__header">
  <button class="close" mat-button (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="dt-page__title" *ngIf="!data.isStitchesedit">Create Stitches</h3>
  <h3 class="dt-page__title" *ngIf="data.isStitchesedit">Edit Stitches</h3>
</div>
<mat-dialog-content class="mat-typography">
  <svg *ngIf="imgload" viewBox="25 25 50 50">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
  <div id="tab-pane" class="tab-pane bg-white active">
    <form
      [formGroup]="stitchesform"
      [ngClass]="
        imgload == true ? 'datahidden bom_modal_content' : 'bom_modal_content'
      "
    >
      <div class="form-row">
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Stitch</label>
          <select
            formControlName="stitchid"
            id="stitchid"
            class="form-control"
            title="Select Stitch"
            (change)="selectionStiches($event)"
          >
            <option *ngFor="let item of sticheslistArr" [value]="item?.id">
              {{ item?.name }}
            </option>
          </select>
          <div
            *ngIf="
              (submitted && this.f1.stitchid.errors) ||
              (this.f1.stitchid.invalid &&
                (this.f1.stitchid.dirty || this.f1.stitchid.touched))
            "
          >
            <div *ngIf="this.f1.stitchid.errors.required" class="red">
              Stitches is required
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Placement</label>
          <input
            name="Placement"
            formControlName="placement"
            class="form-control fill"
            placeholder="Enter Placement"
          />
          <div
            *ngIf="
              (submitted && this.f1.placement.errors) ||
              (this.f1.placement.invalid &&
                (this.f1.placement.dirty || this.f1.placement.touched))
            "
          >
            <div *ngIf="this.f1.placement.errors.required" class="red">
              Placement is required
            </div>
          </div>
        </div>
        <br /><br />
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <label for="validationDefault02">Notes</label>
          <input
            name="notes"
            formControlName="notes"
            class="form-control fill"
          />
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4 mb-5">
          <div *ngIf="(data.isStitchesedit == false && changemode)">
            <label for="validationDefault02">Image</label>
            <img
              id="{{ data.stitchData?.stitchID }}"
              class="img"
              [src]="stichImage || 'assets/images/color-card/1.png'"
            />
          </div>
          <div *ngIf="data.isStitchesedit">
            <label for="validationDefault02">Image</label>
            <img
              id="{{ data.stitchData?.stichID }}"
              class="img"
               [src]="data.stitchData?.stitch.topViewFile?.thumbnail || 'assets/images/color-card/1.png'"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="footer">
    <div class="row mt-5 float-right mr-3">
      <button
        type="submit"
        class="btn btn-outline-primary mr-2"
        (click)="close()"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-primary btn-sm btn-sm"
        [disabled]="stitchesform.invalid"
        (click)="onStitchSubmit()"
      >
        Save
      </button>
    </div>
  </div>
</mat-dialog-content>
