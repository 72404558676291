import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as seamsActions from "../actions/seams.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";


@Injectable()

export class SeamsEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }

    getAllSeamDetails(data){
        data.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, data.sequence);
        data.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.name);
        data.seamType = this.aesdecryptservice.decryptData(this.dataService.Key, data.seamType);
        data.seam751aNumber = this.aesdecryptservice.decryptData(this.dataService.Key, data.seam751aNumber);
        data.isO4916Number = this.aesdecryptservice.decryptData(this.dataService.Key, data.isO4916Number);
        data.commonApplication = this.aesdecryptservice.decryptData(this.dataService.Key, data.commonApplication);
        data.requirements = this.aesdecryptservice.decryptData(this.dataService.Key, data.requirements);
        data.comments = this.aesdecryptservice.decryptData(this.dataService.Key, data.comments);
        data.status = this.aesdecryptservice.decryptData(this.dataService.Key, data.status);
        if (data.createdBy) {
            data.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.firstName);
            data.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.lastName);
        }
        if (data.seamDrawingFile) {
            data.seamDrawingFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, data.seamDrawingFile.thumbnail);
            data.seamDrawingFile.name = this.aesdecryptservice.decryptData(this.dataService.Key, data.seamDrawingFile.name);
        }
        if (data.documents && Object.keys(data.documents).length !== 0) {
            data.documents.forEach(x => {
                x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
                x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                x.reportStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.reportStatus);
                x.labLocation = this.aesdecryptservice.decryptData(this.dataService.Key, x.labLocation);
                x.notes = this.aesdecryptservice.decryptData(this.dataService.Key, x.notes);
                if (x.files) {
                    x.files.forEach(y => {
                        y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name);
                        y.fileType = this.aesdecryptservice.decryptData(this.dataService.Key, y.fileType);
                        y.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnail);
                    });
                }

            });
        }
    }

    @Effect()
    loadSeamsLibrary: Observable<Action> = this.actions.pipe(
        ofType<seamsActions.LoadSeamsLibrary>(
            seamsActions.SeamsActionTypes.LOAD_SEAMS_LIBRARY
        ), mergeMap((action: seamsActions.LoadSeamsLibrary) =>
            this.dataService.getSeams(action.payload1, action.payload2).pipe(
                map((seamsLibrary: any[]) => {
                    if (seamsLibrary['body']['item1'] != null && seamsLibrary['body']['item1'].length > 0 && !seamsLibrary['body']['item2'].toLowerCase().includes('error')) {
                        seamsLibrary['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
                            x.seam751aNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.seam751aNumber);
                            x.isO4916Number = this.aesdecryptservice.decryptData(this.dataService.Key, x.isO4916Number);
                            x.commonApplication = this.aesdecryptservice.decryptData(this.dataService.Key, x.commonApplication);
                            x.requirements = this.aesdecryptservice.decryptData(this.dataService.Key, x.requirements);
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                            if (x.seamDrawingFile) {
                                x.seamDrawingFile.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, x.seamDrawingFile.thumbnail);
                            }
                        });
                    }
                    return new seamsActions.LoadSeamsLibrarySuccess(seamsLibrary['body']['item1'], seamsLibrary['body'])
                }),
                catchError(err => of(new seamsActions.LoadSeamsLibraryFail(err)))
            )
        )
    );
    @Effect()
    loadSeams: Observable<Action> = this.actions.pipe(
        ofType<seamsActions.LoadSeams>(
            seamsActions.SeamsActionTypes.LOAD_SEAMS
        ), mergeMap((action: seamsActions.LoadSeams) =>
            this.dataService.getSeamsById(action.payload).pipe(
                map((seams: any) => {
                    if (!seams['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllSeamDetails(seams['body']['item1'])
                    }
                    return new seamsActions.LoadSeamsSuccess(seams['body']['item1'], seams['body'])
                }),
                catchError(err => of(new seamsActions.LoadSeamsFail(err)))
            )
        )
    );
    @Effect()
    createSeams: Observable<any> = this.actions.pipe(
        ofType<seamsActions.CreateSeams>(
            seamsActions.SeamsActionTypes.CREATE_SEAMS
        ), map((action: seamsActions.CreateSeams) => action.payload),
        mergeMap((seams: any) =>
            this.dataService.addSeams(seams).pipe(
                map((newSeams: any) => {
                    if (!newSeams['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllSeamDetails(newSeams['body']['item1'])
                    }
                    return new seamsActions.CreateSeamsSuccess(newSeams['body']['item1'], newSeams['body'])
                }),
                catchError(err => of(new seamsActions.CreateSeamsFail(err)))
            )
        )
    );

    @Effect()
    updateSeams: Observable<any> = this.actions.pipe(
        ofType<seamsActions.UpdateSeams>(
            seamsActions.SeamsActionTypes.UPDATE_SEAMS
        ), map((action: seamsActions.UpdateSeams) => action.payload),
        mergeMap((seams: any) =>
            this.dataService.updateSeamsById(seams).pipe(
                map((updatedSeams: any) => {
                    if (!updatedSeams['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllSeamDetails(updatedSeams['body']['item1'])
                    }
                    return new seamsActions.UpdateSeamsSuccess({
                        id: updatedSeams['body']['item1'].id,
                        changes: updatedSeams['body']['item1']
                    }, updatedSeams['body'])
                }),
                catchError(err => of(new seamsActions.UpdateSeamsFail(err)))
            )
        )
    );

    @Effect()
    updateSeamsLibrary: Observable<any> = this.actions.pipe(
        ofType<seamsActions.UpdateSeamsLibrary>(
            seamsActions.SeamsActionTypes.UPDATE_SEAMS_LIBRARY
        ), map((action: seamsActions.UpdateSeamsLibrary) => action.payload),
        mergeMap((seamsIds: any[]) =>
            this.dataService.bulkUpdateSeams(seamsIds).pipe(
                map((updatedSeams: any) =>
                    new seamsActions.UpdateSeamsLibrarySuccess([{
                        id: updatedSeams['body']['item1'].id,
                        changes: updatedSeams['body']['item1']
                    }], updatedSeams['body'])
                ),
                catchError(err => of(new seamsActions.UpdateSeamsLibraryFail(err)))
            )
        )
    );

    @Effect()
    deleteSeams: Observable<any> = this.actions.pipe(
        ofType<seamsActions.DeleteSeamsLibrary>(
            seamsActions.SeamsActionTypes.DELETE_SEAMS_LIBRARY
        ), map((action: seamsActions.DeleteSeamsLibrary) => action.payload),
        mergeMap((seamsIds: any[]) =>
            this.dataService.bulkDeleteSeams(seamsIds).pipe(
                map((deletedSeams: any) =>
                    new seamsActions.DeleteSeamsLibrarySuccess(seamsIds, deletedSeams['body'])
                ),
                catchError(err => of(new seamsActions.DeleteSeamsLibraryFail(err)))
            )
        )
    );

    @Effect()
    updateSeamsDocument: Observable<any> = this.actions.pipe(
        ofType<seamsActions.UpdateSeamsDocument>(
            seamsActions.SeamsActionTypes.UPDATE_SEAMS_DOCUMENT
        ), map((action: seamsActions.UpdateSeamsDocument) => action.payload),
        mergeMap((seamsdocument: any) =>
            this.dataService.updateSeamsDocuments(seamsdocument).pipe(
                map((updatedSeamsDocument: any) => {
                    if (!updatedSeamsDocument['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllSeamDetails(updatedSeamsDocument['body']['item1'])
                    }
                    return new seamsActions.UpdateSeamsDocumentSuccess({
                        id: updatedSeamsDocument['body']['item1'].id,
                        changes: updatedSeamsDocument['body']['item1']
                    }, updatedSeamsDocument['body'])
                }),
                catchError(err => of(new seamsActions.UpdateSeamsDocumentFail(err)))
            )
        )
    );
}
