import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastr:ToastrService) { }

  showSuccess(message: string) {
    console.log(message)
    this.toastr.success(message);
  }

  showError(message: string) {

    this.toastr.error(message);
  }

  showInfo(message: string, title: string) {
    this.toastr.info(message, title);
  }

  showWarning(message: string , title: string) {
    this.toastr.warning(message, title);
  }
}
