import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Lightbox } from 'ngx-lightbox';
import { DataTableDirective } from 'angular-datatables';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../helpers/user.service';
import { SharedService } from '../../../services/shared.service';
//import {apiGridData } from './apiGridData';
import Swal from 'sweetalert2';
import { process, State, CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult, DataStateChangeEvent, PageChangeEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { Favourite } from '../../../models/color';
declare var $: any;
//import { IDocument } from '../../../models/document';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Router } from '@angular/router';
import { StorageService } from '../../../helpers/storage.service';
import { bool } from 'aws-sdk/clients/signer';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as supplierReducer from "../../../reducers/supplier.reducer";
import * as supplierActionTypes from "../../../actions/supplier.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { ToastService } from '../../../services/toast.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { allCountries } from './country';
import { SupplierEditComponent } from '../supplier-edit/supplier-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-supplierlibrary',
  templateUrl: './supplierlibrary.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./supplierlibrary.component.css']
})

export class SupplierlibraryComponent implements OnInit {
  public listItems: Array<{ text: string, value: string }> = [
    { text: "Active", value: "Active" },
    { text: "Inactive", value: "Inactive" }
  ];
  productTypeOptions:any = [
    {id: "Knit Fabric",value: "Knit Fabric"},
    {id: "Woven Fabric",value: "Woven Fabric"},
    {id: "Combo Fabric",value: "Combo Fabric"},
    {id: "Leather Fabric",value: "Leather Fabric"},
    {id: "Packaging",value: "Packaging"},
    {id: "Sew in Label",value: "Sew in Label"},
    {id: "Price Ticketing",value: "Price Ticketing"},
    {id: "Hang Tag",value: "Hang Tag"},
    {id: "Box",value: "Box"},
    {id: "RFID / Security Tag",value: "RFID / Security Tag"},
    {id: "Sticker",value: "Sticker"},
    {id: "Poly Bag",value: "Poly Bag"},
    {id: "Hanger",value: "Hanger"},
    {id: "Hook",value: "Hook"},
    {id: "Pad",value: "Pad"},
    {id: "MigrationTest",value: "MigrationTest"},
    {id: "Embroidery",value: "Embroidery"},
    {id: "Eyelet",value: "Eyelet"},
    {id: "Bow",value: "Bow"},
    {id: "Buckle",value: "Buckle"},
    {id: "Felt",value: "Felt"},
    {id: "Rivet",value: "Rivet"},
    {id: "Toggle / Stopper",value: "Toggle / Stopper"},
    {id: "Tape",value: "Tape"},
    {id: "Hook & Eye",value: "Hook & Eye"},
    {id: "Puller",value: "Puller"},
    {id: "Hook & Bar",value: "Hook & Bar"},
    {id: "Lace",value: "Lace"},
    {id: "Fill",value: "Fill"},
    {id: "Press Fastner",value: "Press Fastner"},
    {id: "Zipper",value: "Zipper"},
    {id: "Wire",value: "Wire"},
    {id: "Button",value: "Button"},
    {id: "Thread",value: "Thread"},
    {id: "Interlining",value: "Interlining"},
    {id: "Stud",value: "Stud"},
    {id: "Elastic",value: "Elastic"},
    {id: "Velcro",value: "Velcro"},
    {id: "Ring & Slide",value: "Ring & Slide"},
    {id: "Badge",value: "Badge"},
    {id: "Hanger Loop",value: "Hanger Loop"},
  ]
  //thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  thumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";
  agentthumbnail: string = "../../../../assets/images/suppliercard/agent.png";
  factorythumbnail: string = "../../../../assets/images/suppliercard/factory.png";
  rawthumbnail: string = "../../../../assets/images/suppliercard/raw.png";
  apiGridData = [];
  apiData = [];
  notFound: boolean;
  notfoundImg: string;
  originalData = [];
  gridDataForm: FormGroup;
  isEditMode = false;
  supplierViewMode = "Created";
  mysupplier: boolean = true;
  sharedsupplier: boolean = false;
  sharedEndListCount = 10;
  sharedStartListCount = 0;
  sharedGridData: any[] = [];
  sharedStartEndListCount: string;
  startEndListCount: string;
  @ViewChild('grid') grid: GridComponent;

  public gridData: GridDataResult;

  public filteredData: any[];
  public filteredXcelData: any[];
  isRowEditMode: boolean;
  result: string;
  isDataLoading = false;
  showNote = false;
  file:any;
  //
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      this.filteredData = this.filteredXcelData;
      var gridView = {
        data: this.filteredData,
        total: this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total;// process(this.filteredData, this.state);
    }
    else {
      var gridView = {
        data: this.supplierViewMode === 'Shared'? this.sharedGridData : this.apiGridData,
        total: this.supplierViewMode === 'Shared'? this.totalSharedRecordCount : this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total;// process(this.apiGridData, this.state);
    }

  }
  //@ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  min: number;
  max: number;
  Swal = require('sweetalert2');
  showLoader: boolean;
  showLoader1: boolean;
  gridContent = true;
  tableContent = false;
  activeTab: boolean = false;
  selected: any;
  cards: Observable<any[]>;
  searchedKeyword: string;
  copyCard: Observable<any[]>;
  public filterQuery = "";
  idArray: string[];
  suplierclassificationValue: string;
  suplierLibraryClassificationData: any[];

  public filter: CompositeFilterDescriptor;
  classApplied = false;

  paginationStartcount = 0;
  paginationEndcount = 10;
  public startCount = 0;
  public endCount = 10;
  public startCountList = 0;
  public endCountList = 10;
  public startEndCount = "";
  public isPageChanged = false;
  public isScrollDown = false;
  public isGetAllRecords = false;
  public totalRecordCount: any;
  loading: boolean;
  checkStatusData = false;
  msg: string;

  statusval: string;
  enableKeywordVal: string[];
  enableCreatedByVal: string[];
  enableCreatToVal: string[];
  enableCreatFromVal: string[];
  enableStatusVal: string[];
  enableClassVal: string[];
  ///listBtn: boolean = true;
  ///cardBtn: boolean = false;
  enableModifToVal: string[];
  enableModifFromVal: string[];
  enableProductTypeVal: string[];
  enableLastSocAuditVal: string[];
  HeadingText = "Card View";
  btnstatus: boolean = false;
  btnstatus1: boolean = true;
  log: any[];
  createdFromDate: any;
  createdToDate: any;
  modifiedFromDate: any;
  modifiedToDate: any;
  minDate :NgbDateStruct= { year: 1985, month: 1, day: 1 };
  createdStartDate: string;
  modifiedEndDate: string;
  modifiedStartDate: string;
  createdEndDate: string;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public SearchData = "";
  selectedvalue='Passed';
  sharedCopyCard: any[] = [];
  sharedCards: any[] = [];
  isMySuppliers = true;
  sharedNotFound: boolean;
  isSharedGetAllRecords = false;
  isSharedScrollDown = false;
  totalSharedRecordCount: any;
  sharedMsg: string;
  sharedEndCount = 10;
  sharedStartCount = 0;
  sharedStartEndCount = "";
  checkSharedStatusData = false;
    staticMsgs:any;
  //public pageSizes = true;
  constructor(
    private sharedService: SharedService,
    private storageservice: StorageService,
    private dataService: DataService,
    public userservice: UserService,
    private logger: LogService,
    private formBuilder: FormBuilder,
    public aesdecryptservice: AESDecryptService,
    public  toastService: ToastService,
    private router: Router,
    private store: Store<any>,
    public dialog:MatDialog,
  ) {
    this.allData = this.allData.bind(this);
  }
  // public listDataCopy: any[] = apiGridData;
  public group: any[] = [{
    field: 'name'
  }];
  public state: State = {
    skip: 0,
    take: 10
  };
  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter,'filter')
    this.filter = filter;
    this.filteredData = filterBy(this.apiGridData, filter);
    console.log(this.filteredData,'filteredData')
  }

  options = [
    //{ value: 'all', label: 'All' },
    { value: 'Passed', label: 'Passed' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Under Review', label: 'Under Review' }
  ];
  public selectedValue: string = "Passed";
  //public gridData: any[] = apiGridData;

  isChecked = false;
  checkuncheckall() {
    if (this.isChecked === true) {
      this.isChecked = false;
    } else {
      this.isChecked = true;
    }
  }

  createSupplier() {
    this.dataService.AddClientLog();
    this.router.navigate(['/supplierdetails']);
  }

  onEditSupplier(id) {
    this.dataService.AddClientLog();
    this.router.navigate(['/supplierdetails']);
    this.storageservice.storeEditedSupplierId(id);

  }

  onEditSupplierDetails(id) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditedSupplierId(id);
    this.storageservice.storeEditedSupplierDetailsId(id);
    this.router.navigate(['/supplierdetails']);
  }

  onDeleteSupplier(id) {

    Swal.fire({
      title: this.staticMsgs.suppliersLibrary.ConfirmationtoDelete,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader1 = true;
        let deleterecordIds = [];
        deleterecordIds.push(id);
        this.store.dispatch(new supplierActionTypes.DeleteSupplierLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedSupplierLibrary) => {
          if (deletedSupplierLibrary.supplier.error === "") {
            if (deletedSupplierLibrary.supplier.message?.item2 === 'Success') {
              this.success(this.staticMsgs.suppliersLibrary.SupplierDeleteSuccess);
              this.logger.info(this.staticMsgs.suppliersLibrary.SupplierDeleteSuccess, this.staticMsgs.suppliersLibrary.SupplierLibrary);
              this.onSelect(this.selectedvalue, 'mysupplier')
              this.loadDataList();
              this.showLoader1 = false;
            } else {
              this.showLoader1 = false;
              this.error("Failed:" + deletedSupplierLibrary.supplier.message?.item1)
              this.logger.error(deletedSupplierLibrary.supplier.message?.item1, this.staticMsgs.suppliersLibrary.SupplierLibrary);
            }

          } else {
            this.logger.error(deletedSupplierLibrary.supplier.error.error.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);
            this.getError(deletedSupplierLibrary.supplier.error);
            this.showLoader1 = false;
          }
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  onPageChange(e: PageChangeEvent): void {
    this.isPageChanged = true;
    this.state.skip = e.skip;
    this.state.take = e.take;
    if (this.supplierViewMode === 'Shared') {
      this.sharedStartListCount = e.skip;
      this.sharedEndListCount = e.skip + e.take;
      this.loadSharedListData();
    } else {
      this.startCountList = e.skip;
      this.endCountList = e.skip + e.take;
      this.loadDataList();
    }
  }

  scrollEvent = (event: any): void => {
    if (this.gridContent && this.router.url.includes('supplierlibrary') && !this.tableContent) {
      if (!this.isDataLoading) {
        if (event.target.scrollTop >= (event.target.scrollHeight - event.target.clientHeight - 2)) {
          if (this.supplierViewMode == 'Created') {
            this.isDataLoading = true;
            if (!this.isGetAllRecords) {
              this.isScrollDown = false;
              this.startCount = this.startCount + this.paginationEndcount;
              this.endCount = this.endCount + this.paginationEndcount;
              this.loadData();
            }
          } else {
            this.isDataLoading = true;
            if (!this.isSharedGetAllRecords) {
              this.isScrollDown = false;
              this.sharedStartCount = this.sharedStartCount + this.paginationEndcount;
              this.sharedEndCount = this.sharedEndCount + this.paginationEndcount;
              this.loadSharedData();
            }
          }

        }
      }
    }
  }


  supplierView(supplier) {
    this.isMySuppliers = (supplier === 'Created') ? true : false
    this.cards = null;
    this.notFound = false;
    this.isEditMode = false;
    if(this.tableContent){
      this.closeAllRows();
    }
    this.supplierViewMode = supplier;
    if (this.supplierViewMode == 'Shared') {
      this.mysupplier = false;
      this.sharedsupplier = true;
    }
    else {
      this.mysupplier = true;
      this.sharedsupplier = false;
    }
    if (this.gridContent) {
      this.HeadingText = "Card View";
      this.supplierViewMode == 'Shared' ? this.loadSharedData() : this.loadData();
    }
    else {
      this.HeadingText = "List View";
      this.supplierViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();
    }

  }

  cardView() {
    this.gridContent = true;
    this.tableContent = false;
    this.isMySuppliers = true;
    this.showNote = false;
    this.HeadingText = "Card View";
    this.btnstatus = false;
    this.btnstatus1 = true;
    ///this.listBtn= true;
    ///this.cardBtn = false;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', 'Passed');
    }, 10);
    if (this.supplierViewMode === 'Shared') {
      this.isSharedGetAllRecords = false;
      this.sharedCards = [];
      this.sharedStartCount = this.paginationStartcount;
      this.sharedEndCount = this.paginationEndcount;
      this.loadSharedData();
    } else {
      this.isGetAllRecords = false;
      this.startCount = this.paginationStartcount;
      this.endCount = this.paginationEndcount;
      this.loadData();
    }
  }

  tableView() {
    this.gridContent = false;
    this.tableContent = true;
    this.isMySuppliers = false;
    this.HeadingText = "List View";
    this.btnstatus = true;
    this.btnstatus1 = false;
    ///this.cardBtn = true;
    ///this.listBtn= false;
    this.searchListData('');
    //this.loadData();
    // this.isActive = true;
    if(this.supplierViewMode === 'Shared'){
      if (this.isPageChanged) {
        this.sharedStartListCount = this.state.skip;
        this.sharedEndListCount = this.state.skip + this.state.take;
      }
      else {
        this.sharedStartListCount = this.paginationStartcount;
        this.sharedEndListCount = this.paginationEndcount;
      }
    } else {
    if (this.isPageChanged) {
      this.startCountList = this.state.skip;
      this.endCountList = this.state.skip + this.state.take;
    }
    else {
      this.startCountList = this.paginationStartcount;
      this.endCountList = this.paginationEndcount;
    }
  }
  this.supplierViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();

  }

  ngOnInit() {
    this.staticMsgs=appStaticMsg.data[0];
    window.addEventListener('scroll', this.scrollEvent, true);
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    this.storageservice.storeEditedSupplierId("");
    $(document).ready(function () {
      $('.selectpicker').selectpicker('val', 'Passed');
    });

    this.advImageForm = this.formBuilder.group({
      keyword: [''],
      createdBy: [''],
      productType: [''],
      lastSocialAudit: [''],
      classifications: [''],
      status: [''],
      createdFrom: [''],
      createdTo: [''],
      modifiedFrom: [''],
      modifiedTo: ['']
    });
    this.gridDataForm = this.formBuilder.group({
      formArray: new FormArray([])
    });

    this.loadData();
    // initialise apiGridData form with empty form array

    //this.getColorClassification("Suppliers");
    this.sharedService.sendClickEvent();

  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader = false;
  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataService.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  ngAfterViewInit() {

  }
  public allData(): ExcelExportData {
    this.filteredData = filterBy((this.supplierViewMode === 'Shared') ? this.sharedGridData : this.apiGridData, this.state.filter);
    //this.state.take = this.filteredData.length;

    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.filteredData = this.filteredXcelData;
      //this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    const result: ExcelExportData = {
      data: this.filteredData //process(this.filteredData, this.state).data,
    };
    //this.state.take = 10;
    return result;
  }

  get f() { return this.gridDataForm.controls; }
  get fa() { return this.f.formArray as FormArray; }
  fac(data) {
    if(this.supplierViewMode === 'Shared'){
      return this.fa.controls[this.sharedGridData.indexOf(data)] as FormGroup;
    } else {
      return this.fa.controls[this.apiGridData.indexOf(data)] as FormGroup;
    }
    //return


    //this.fa.controls[index] as FormGroup;
  }
  onEdit() {

    // store copy of original apiGridData in case cancelled
    this.originalData = this.supplierViewMode === 'Shared'? [...this.sharedGridData] :[...this.apiGridData];
    var gridView = {
      data: this.originalData,
      total: this.supplierViewMode === 'Shared'? this.totalSharedRecordCount :this.totalRecordCount,
    };
    this.gridData = gridView;
    // reset / initialise form fields
    this.resetForm();

    // set all rows to edit mode to display form fields
    this.editGridRows();
    this.isEditMode = true;

  }

  onAdd() {
    // add item to apiGridData array
    this.apiGridData.push({});

    // add new form group to form array
    const formGroup = this.createFormGroup();
    this.fa.push(formGroup);

    // set new row to edit mode in kendo grid
    this.grid.editRow(this.apiGridData.length - 1, formGroup);
  }

  jsonDeleteCompare(element, index, array) {

    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }
  jsonCompare(element, index, array) {

    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      Object.keys(element).forEach(key => {

        if (element[key] != dataRow[0][key]) {
          diff++;

        }
      })
    }
    if (diff > 0) {
      return true;
    }
    else {
      return false;
    }

  }

  validateMobile(number: any) {
    var phoneno = /^([0|+[0-9]{1,5})?([6-9][0-9]{9})$/;
    return phoneno.test(String(number).toLowerCase());
  }

  validateEmail(email: any) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onSave() {
    // mark all fields as touched to highlight any invalid fields
    this.gridDataForm.markAllAsTouched();
    var validMobile;
    var validEmail;
    for (let i = 0; i < this.gridDataForm.value.formArray.length; i++) {
      validMobile = this.validateMobile(this.gridDataForm.value.formArray[i].phoneNo);
      validEmail = this.validateEmail(this.gridDataForm.value.formArray[i].email);
      if (!validEmail) {
        this.gridDataForm.controls.formArray['controls'][i].controls.email.setErrors({ 'incorrect': true });
      }
      if (!validMobile) {
        this.gridDataForm.controls.formArray['controls'][i].controls.phoneNo.setErrors({ 'incorrect': true });
      }
      if (this.gridDataForm.controls.formArray['controls'][i].controls.name.value.length > 50) {
        this.gridDataForm.controls.formArray['controls'][i].controls.name.setErrors({ 'incorrect': true });
      }
    }
    // stop here if form is invalid
     if (this.gridDataForm.invalid) {
      this.showNote = true;
      this.error(this.staticMsgs.suppliersLibrary.checkInvalidFields)
      // alert('FORM INVALID :(');
      return;
    }
    /*console.log(this.gridDataForm) */

      // copy form data to apiGridData array on success
      if(this.supplierViewMode === 'Shared'){
        this.sharedGridData = this.fa.value;
      } else {
        this.apiGridData = this.fa.value;
      }
     this.closeAllRows();
     let updDocumentList = this.apiGridData.filter((x, i) => this.jsonCompare(x, i, this.originalData));

     for (let i = 0; i < updDocumentList.length; i++) {
       for (let j = 0; j < this.originalData.length; j++) {
         if (this.originalData[j].id === updDocumentList[i].id) {
           if (this.originalData[j].contact[0]) {
             updDocumentList[i].contact = [
               {
                 "country": this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].country),
                 "state": this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].state),
                 "id": this.originalData[j].contact[0].id,
               }
             ];
             // console.log("Contactid"+ this.originalData[j].contacts[0].id)
           }
           else {
             updDocumentList[i].contact = [
               {
                 "country": this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].country),
                 "state": this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].state),
                 "id": "",
               }
             ];
           }
         }
       }
     }


     if (updDocumentList.length > 0) {
       this.showLoader1 = true;
       for (let i = 0; i < updDocumentList.length; i++) {
         updDocumentList[i].name = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].name);
         updDocumentList[i].phoneNo = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].phoneNo);
         updDocumentList[i].email = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].email);
         updDocumentList[i].auditStatus = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].auditStatus);
         updDocumentList[i].productType = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].productType);
         updDocumentList[i].lastSocialAudit = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].lastSocialAudit);
         updDocumentList[i].status = this.aesdecryptservice.encryptData(this.dataService.Key, updDocumentList[i].status);
       }

       this.store.dispatch(new supplierActionTypes.UpdateSupplierLibrary(updDocumentList));
       this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedSupplierLibrary) => {
         if (updatedSupplierLibrary.supplier.error === "") {
           if (updatedSupplierLibrary.supplier.message?.item2 === 'Success') {
            this.showNote = false;
             this.success(this.staticMsgs.suppliersLibrary.SupplierUpdated);
             this.logger.info(this.staticMsgs.suppliersLibrary.SupplierUpdated, this.staticMsgs.suppliersLibrary.SupplierLibrary);
             this.supplierViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
             this.showLoader1 = false;
           } else {
             this.showLoader1 = false;
             this.error("Failed:" + updatedSupplierLibrary.supplier.message?.item1)
             this.supplierViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
             this.logger.error(updatedSupplierLibrary.supplier.message?.item1, this.staticMsgs.suppliersLibrary.SupplierLibrary);
           }
         } else {
           this.logger.error(updatedSupplierLibrary.supplier.error.error.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);
           this.getError(updatedSupplierLibrary.supplier.error);
           this.showLoader1 = false;
         }
       })
    }


     if (this.originalData.length != (this.supplierViewMode == 'Shared' ? this.sharedGridData.length : this.apiGridData.length)) {
      this.showLoader1 = true;
      let deleterecordIds = [];
      var DelDocumentList;
        if(this.supplierViewMode === 'Shared'){
          DelDocumentList = this.originalData.filter((x, i) => this.jsonDeleteCompare(x, i, this.sharedGridData));
        } else {
          DelDocumentList = this.originalData.filter((x, i) => this.jsonDeleteCompare(x, i, this.apiGridData));
        }
      //console.log(DelDocumentList);
      if (DelDocumentList.length > 0) {
        DelDocumentList.forEach((x, i) => {
          deleterecordIds.push(x.id);

        });
        //console.log(deleterecordIds);
        // alert('deete');
        // return;
        this.store.dispatch(new supplierActionTypes.DeleteSupplierLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedSupplierLibrary) => {
          if (deletedSupplierLibrary.supplier.error === "") {
            if (deletedSupplierLibrary.supplier.message?.item2 === 'Success') {
              this.showNote = false;
              this.success(this.staticMsgs.suppliersLibrary.SupplierDeleteSuccess);
              this.logger.info(this.staticMsgs.suppliersLibrary.SupplierDeleteSuccess, this.staticMsgs.suppliersLibrary.SupplierLibrary);
              this.supplierViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              this.showLoader1 = false;
            } else {
              this.showLoader1 = false;
              this.error("Failed:" + deletedSupplierLibrary.supplier.message?.item1)
              this.supplierViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              this.logger.error(deletedSupplierLibrary.supplier.message?.item1, this.staticMsgs.suppliersLibrary.SupplierLibrary);
            }

          } else {
            this.logger.error(deletedSupplierLibrary.supplier.error.error.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);
            this.getError(deletedSupplierLibrary.supplier.error);
            this.showLoader1 = false;
          }
        })
    }
   }
    //console.log(updArry);

      this.isEditMode = false;
     this.isRowEditMode = false;
     this.showLoader1 = false;
     this.loading = false;
  }

  onCancel() {
    this.closeAllRows();
    this.showNote = false;
    // reset apiGridData back to original data (before edit was clicked)
    this.supplierViewMode === 'Shared' ? this.sharedGridData :this.apiGridData = this.originalData;
    var gridView = {
      data: this.supplierViewMode === 'Shared' ? this.sharedGridData :this.apiGridData,
      total: this.supplierViewMode === 'Shared' ? this.totalSharedRecordCount :this.totalRecordCount,
    };
    this.gridData = gridView;
    this.isEditMode = false;
  }

  // helper methods

  private editAllRows() {

    this.apiGridData.forEach((x, i) => {
      var documentRow = this.fa.controls[i] as FormGroup;
      this.grid.editRow(i, documentRow);
    });
  }
  private editGridRows() {

    if(this.supplierViewMode ==='Shared'){
      this.filteredData = filterBy(this.sharedGridData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        if (x.isEdit) {
          var documentRow = this.fa.controls[this.sharedGridData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, documentRow);
        }
      });
    } else {
    this.filteredData = filterBy(this.apiGridData, this.state.filter);
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    this.filteredData.forEach((x, i) => {
      if (x.isEdit) {
        var documentRow = this.fa.controls[this.apiGridData.indexOf(x)] as FormGroup;
        var rowIndex = this.state.skip + i;
        this.grid.editRow(rowIndex, documentRow);
      }
    });
  }
  }
  private closeAllRows() {
    // close all rows to display readonly view of data
    if (this.supplierViewMode === 'Shared') {
      // close all rows to display readonly view of data
      this.sharedGridData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    } else {
      this.apiGridData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    }
  }

  private resetForm() {
    // clear form array and create a new form group for each product
    this.fa.clear();
    if (this.supplierViewMode === 'Shared') {
      this.sharedGridData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
    } else {
      this.apiGridData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
    }
  }

  private createFormGroup(data1: any = {}) {
    // create a new form group containing controls and validators for a product
    //console.log("test: "+JSON.stringify(data1));
    let country = "";
    let primarystate = "";

    if (data1.contact[0]) {
      country = data1.contact[0].country;
      primarystate = data1.contact[0].state;
    }
    return this.formBuilder.group({

      name: data1.name,//, Validators.required],
      phoneNo: data1.phoneNo,
      email: data1.email,
      // contacts:[data1.contacts],

      lastSocialAudit:data1.lastSocialAudit,
      productType: data1.productType,
      auditStatus: data1.auditStatus,
      createdBy: data1.createdBy,
      status: data1.status,

      id: data1.id,
      isEdit: data1.isEdit,
      isDelete: data1.isDelete,

    })
  }

  onSelect(val: any, type: any) {
    this.selectedvalue=val
    if (type === 'mysupplier') {
      if (val == "") {
        this.cards = this.copyCard;
      } else {
        this.cards = this.copyCard.pipe(
          map(data => data.filter(res => res.auditStatus.toLowerCase() == val.toLowerCase()))
        )
      }
      var data = this.apiGridData.filter(x => x.auditStatus.toLowerCase() == val.toLowerCase())

      if (data.length > 0) {
        this.checkStatusData = false;
      } else {
        this.msg = (val.toLowerCase() === 'passed') ? 'No passed records available' : (val.toLowerCase() === 'failed') ? 'No failed records available' : 'No under-review records available';
        this.checkStatusData = true;
      }

    } else {
      if (val == "") {
        this.sharedCards = this.sharedCopyCard;
      } else {
        this.sharedCards = this.sharedCopyCard.filter(res => res.auditStatus.toLowerCase() == val.toLowerCase())
      }
      if (this.sharedCards.length > 0) {
        this.checkSharedStatusData = false;
      } else {
        this.sharedMsg = (val.toLowerCase() === 'passed') ? 'No passed shared records available' : (val.toLowerCase() === 'failed') ? 'No failed shared records available' : 'No under-review shared records available';
        this.checkSharedStatusData = true;
      }

    }
  }

  searchListData(val: string) {
    if(this.supplierViewMode === 'Shared'){
      if (val !== "") {
        let arr = [];
        this.filterQuery = val;
        this.filteredData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) { return data; }
        });
      }
      else {
        this.filteredData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
    if (val !== "") {
      let arr = [];
      this.filterQuery = val;
      this.filteredData = this.apiGridData.filter((data) => {
        var search = this.filterQuery.toString().toLowerCase();
        var values = Object.values(data);
        var indicator = false;
        values.forEach((vl) => {
          if (String(vl).toLowerCase().indexOf(search) > -1) {
            indicator = true;
            return;
          }
        });
        if (indicator) { return data; }
      });
    }
    else {
      this.filteredData = this.apiGridData;
      this.state.take = 10;
      //return
    }
  }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    //this.apiGridData = this.filteredData;

    var gridView = {
      data: this.filteredData,
      total: this.supplierViewMode=='Shared' ? this.totalSharedRecordCount: this.totalRecordCount,
    };
    this.gridData = gridView;// process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(this.filteredData, this.state.filter);
  }


  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  decryptData(x){
    if (x.contact) {
      x.contact.forEach(y => {
        y.country = this.aesdecryptservice.decryptData(this.dataService.Key, y.country);
        y.state = this.aesdecryptservice.decryptData(this.dataService.Key, y.state);
      });
    }
    x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
    x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
    x.phoneNo = this.aesdecryptservice.decryptData(this.dataService.Key, x.phoneNo);
    x.phoneNumber = this.aesdecryptservice.decryptData(this.dataService.Key, x.phoneNumber);
    x.email = this.aesdecryptservice.decryptData(this.dataService.Key, x.email);
    x.auditStatus = this.aesdecryptservice.decryptData(this.dataService.Key, x.auditStatus);
    x.productType = this.aesdecryptservice.decryptData(this.dataService.Key, x.productType);
    x.lastSocialAudit = this.aesdecryptservice.decryptData(this.dataService.Key, x.lastSocialAudit);
    x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
    if (x.createdBy != null) {
      x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
      x.CreatedbyName = x.createdBy.firstName;
    }
  }

  loadSharedListData(){
    this.sharedStartEndListCount = this.sharedStartListCount + "-" + this.sharedEndListCount;
    this.dataService.getSuppliers(this.sharedStartEndListCount, this.supplierViewMode).subscribe(response => {
      if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
        this.isDataLoading = false;
        this.logger.info(this.staticMsgs.suppliersLibrary.AllSupplierslodedSuccess, this.staticMsgs.suppliersLibrary.SupplierLibrary);
        this.showLoader = false;
        this.sharedGridData = [];
        response['body']['item1'].forEach(x => {
          this.decryptData(x);
          this.sharedGridData.push(x);
        });
        this.totalSharedRecordCount = response['body']['item3'];
        if (this.sharedGridData.length > 0) {
          this.sharedNotFound = false;
        } else {
          this.sharedNotFound = true;
        }
        this.sharedGridData.forEach((x, i) => {
          this.fa.push(this.createFormGroup(x));
        });
        var gridView = {
          data: this.sharedGridData,
          total: this.totalSharedRecordCount,
        };
        this.gridData = gridView;
        this.loading = false;
      } else {
        this.isSharedScrollDown = true;
        if (response['body']['item2'] !== 'Success') {
          this.error("Failed:" + response['body']['item1'])
          this.logger.error(response['body']['item1'], this.staticMsgs.suppliersLibrary.SupplierLibrary);
        }
        this.showLoader = false;
        this.sharedNotFound = true;
      }
    }, err => {
      this.getError(err);
      this.logger.error(err.error.message,this.staticMsgs.suppliersLibrary.SupplierLibrary);
      this.showLoader = false;
    })
}

  loadSharedData() {
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', this.selectedvalue);
    }, 10);
    if (!this.isSharedGetAllRecords) {
      this.sharedStartEndCount = this.sharedStartCount + "-" + this.sharedEndCount;
      this.dataService.getSuppliers(this.sharedStartEndCount, this.supplierViewMode).subscribe(response => {
        if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
          this.isDataLoading = false;
          this.logger.info(this.staticMsgs.suppliersLibrary.AllSupplierslodedSuccess,this.staticMsgs.suppliersLibrary.SupplierLibrary);
          this.showLoader = false;
          response['body']['item1'].forEach(x => {
            this.decryptData(x);
            this.sharedCards.push(x);
            this.sharedCards = this.sharedCards.filter((item, index) => this.sharedCards.indexOf(item) === index)
          });
          this.sharedCopyCard = [];
          this.sharedCopyCard = this.sharedCards;
          this.totalSharedRecordCount = response['body']['item3'];
          if (this.sharedCards.length > 0) {
            this.sharedNotFound = false;
          } else {
            this.sharedNotFound = true;
          }
          if (this.sharedCards.length == this.totalSharedRecordCount) {
            this.isSharedGetAllRecords = true;
          }
          else {
            this.isSharedScrollDown = true;
          }
          this.onSelect(this.selectedvalue, 'shared')
        } else {
          this.isSharedScrollDown = true;
          if (response['body']['item2'] !== 'Success') {
            this.error("Failed:" + response['body']['item1'])
            this.logger.error(response['body']['item1'], this.staticMsgs.suppliersLibrary.SupplierLibrary);
          }
          this.showLoader = false;
          this.sharedNotFound = true;
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);
        this.showLoader = false;
      })
    }
  }

  loadData() {
    this.startEndCount = this.startCount + "-" + this.endCount;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val',this.selectedvalue);
    }, 10);
    this.store.dispatch(new supplierActionTypes.LoadSupplierLibrary(this.startEndCount, this.supplierViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((supplierLibrary) => {
      if (supplierLibrary.supplier.error === "") {
        if (supplierLibrary.supplier.message?.item2 === 'Success') {
          this.showLoader1 = false;
          this.totalRecordCount = supplierLibrary.supplier.message?.item3;
          this.cards = this.store.pipe(select(supplierReducer.getSupplierLibrary));
          console.log("cards-->",this.cards);
          this.copyCard = this.store.pipe(select(supplierReducer.getSupplierLibrary));
          this.logger.info(this.staticMsgs.suppliersLibrary.AllSupplierslodedSuccess, this.staticMsgs.suppliersLibrary.SupplierLibrary);
          this.fa.clear();
          this.apiGridData = supplierLibrary.supplier.message?.item1;
          this.apiData = supplierLibrary.supplier.message?.item1;
          if (this.apiGridData.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.apiGridData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          if (supplierLibrary.supplier.ids.length == this.totalRecordCount) {
            this.isGetAllRecords = true;
          }
          else {
            this.isScrollDown = true;
          }
          this.onSelect(this.selectedvalue, 'mysupplier')
        } else {
          this.showLoader1 = false;
          this.notFound = true;
          this.error("Failed:" + supplierLibrary.supplier.message?.item1)
          this.logger.error(supplierLibrary.supplier.message?.item1, this.staticMsgs.suppliersLibrary.StichesLibrary);
        }
      } else {
        this.isScrollDown = true;
        this.isGetAllRecords = true;
        this.logger.error(supplierLibrary.supplier.error.error.message, this.staticMsgs.suppliersLibrary.StichesLibrary);
        this.getError(supplierLibrary.supplier.error);
        this.showLoader1 = false;
      }
    })

  }

  loadDataList() {
    this.loading = true;
    this.startEndListCount = this.startCountList + "-" + this.endCountList;
    this.store.dispatch(new supplierActionTypes.LoadSupplierLibrary(this.startEndListCount, this.supplierViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((supplierLibrary) => {
      console.log(supplierLibrary,'supplierLibrary')
      if (supplierLibrary.supplier.error === "") {
        if (supplierLibrary.supplier.message?.item2 === 'Success') {
          this.totalRecordCount = supplierLibrary.supplier.message?.item3;
          this.cards = this.store.pipe(select(supplierReducer.getSupplierLibrary));
          this.copyCard = this.store.pipe(select(supplierReducer.getSupplierLibrary));
          this.logger.info(this.staticMsgs.suppliersLibrary.AllSupplierslodedSuccess, this.staticMsgs.suppliersLibrary.SupplierLibrary);
          this.fa.clear();
          this.apiGridData = supplierLibrary.supplier.message?.item1;
          this.apiData = supplierLibrary.supplier.message?.item1;
          if (this.apiGridData.length > 0) {
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.apiGridData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          var gridView = {
            data: this.apiGridData,
            total: this.totalRecordCount,
          };
          console.log(this.apiGridData,'apiGridData')
          this.gridData = gridView;
          this.loading = false;
          this.onSelect(this.selectedvalue, 'mysupplier')
        } else {
          this.loading = false;
          this.notFound = true;
          this.error("Failed:" + supplierLibrary.supplier.message?.item1)
          this.logger.error(supplierLibrary.supplier.message?.item1, this.staticMsgs.suppliersLibrary.SupplierLibrary);
        }

      } else {
        this.logger.error(supplierLibrary.supplier.error.error.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);
        this.getError(supplierLibrary.supplier.error);
        this.loading = false;
      }
    })
  }

  editClick(rowIndex, dataItem) {
    console.log(dataItem,'dataitem');
    this.dataService.AddClientLog();
    this.storageservice.storeEditedSupplierId(dataItem.id);
    this.storageservice.storeEditedSupplierDetailsId(dataItem.id);
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    this.loadSelectPicker();
    const dialogRef = this.dialog.open(SupplierEditComponent);
    dialogRef.afterClosed().subscribe(result=>{
      this.loadDataList()
      console.log(result,'result')
    })
  }


  clickedRowItem: any;
  dblClick(e) {

    if (this.clickedRowItem.dataItem.isEdit) {
      this.isEditMode = true;
      // this.isRowEditMode = true;
      this.closeAllRows();
      var dataindx;
      if (this.supplierViewMode === 'Shared') {
        // store copy of original documents in case cancelled
        this.originalData = [...this.sharedGridData];

        // set single row to edit mode to display form fields
        dataindx = this.sharedGridData.indexOf(this.clickedRowItem.dataItem);
      } else {
        // store copy of original documents in case cancelled
        this.originalData = [...this.apiGridData];

        // set single row to edit mode to display form fields
        dataindx = this.apiGridData.indexOf(this.clickedRowItem.dataItem);
      }
      var documentRow = this.fa.controls[dataindx] as FormGroup;

      this.grid.editRow(this.clickedRowItem.rowIndex, documentRow);
    }
    else {
      alert(this.staticMsgs.suppliersLibrary.NoPermissionToEdit);

    }

  }
  AddToFavorite(e) {

    this.showLoader = true;

    let selected = e.target.id.replace("checkbox", "");
    if (selected != '' && e.target.checked == true) {
      let data: Favourite[] = [];
      data.push(new Favourite(selected));
      this.dataService.bulkAddFavourites(data, "Suppliers").subscribe(response => {
        let res = response.body;


        this.showLoader = false;
        if (response.status === 200) {
          if (res.item2.toLowerCase().includes("success")) {
            this.logger.info(this.staticMsgs.suppliersLibrary.supplierAddToFv, this.staticMsgs.suppliersLibrary.SupplierLibrary);
            this.success(this.staticMsgs.suppliersLibrary.supplierAddToFv);
          }
          else {
            this.error(res.item1);
            this.logger.error(res.item1, this.staticMsgs.suppliersLibrary.SupplierLibrary);
          }
        }

      }, err => {
        this.logger.error(err.error.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.showLoader = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.showLoader = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.showLoader = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);

        }
        this.showLoader = false;
      });



    }
    else if (selected != '' && e.target.checked == false) {
      let data: string[] = [];
      data.push(selected);
      this.dataService.bulkDeleteFavourites(data, "Suppliers").subscribe(response => {
        let res = response.body;

        this.showLoader = false;
        if (response.status === 200) {
          if (res.item2.toLowerCase().includes("success")) {
            this.success(this.staticMsgs.suppliersLibrary.RemoveSuppliersFromFv);
            this.logger.info(this.staticMsgs.suppliersLibrary.RemoveSuppliersFromFv, this.staticMsgs.suppliersLibrary.SupplierLibrary);

          }
          else {
            this.error(res.item1);
            this.logger.error(res.item1, this.staticMsgs.suppliersLibrary.SupplierLibrary);
          }
        }

      }, err => {
        this.logger.error(err.error.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.showLoader = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.showLoader = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.showLoader = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);

        }
        this.showLoader = false;
      });



    }

  }
  onRemove(index, dataItem) {
    console.log(dataItem ,'dataItem');
    console.log(index,'index')
    Swal.fire({
      title: this.staticMsgs.suppliersLibrary.ConfirmationtoDelete,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
          this.showLoader1 = true;
          let deleterecordIds = [];
          deleterecordIds.push(dataItem.id);
        // rows must all be closed while removing apiGridData
        this.closeAllRows();
        // if (this.supplierViewMode === 'Shared') {
        //   let dataIndex = this.sharedGridData.indexOf(dataItem);
        //   // remove product and product form group

        //   this.sharedGridData = JSON.parse(JSON.stringify(this.sharedGridData));
        //   this.sharedGridData.splice(dataIndex, 1);
        //   this.fa.removeAt(dataIndex);

        //   this.filteredData = filterBy(this.sharedGridData, this.state.filter);
        // }
        this.store.dispatch(new supplierActionTypes.DeleteSupplierLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedSupplierLibrary) => {
          if (deletedSupplierLibrary.supplier.error === "") {
            if (deletedSupplierLibrary.supplier.message?.item2 === 'Success') {
              this.success(this.staticMsgs.suppliersLibrary.SupplierDeleteSuccess);
              this.logger.info(this.staticMsgs.suppliersLibrary.SupplierDeleteSuccess, this.staticMsgs.suppliersLibrary.SupplierLibrary);
              this.onSelect(this.selectedvalue, 'mysupplier')
              this.loadDataList();
              this.showLoader1 = false;
            }
        else {
          let dataIndex = this.apiGridData.indexOf(dataItem);
          // remove product and product form group
          this.apiGridData = JSON.parse(JSON.stringify(this.apiGridData))
          this.apiGridData.splice(dataIndex, 1);

          this.fa.removeAt(dataIndex);

          this.filteredData = filterBy(this.apiGridData, this.state.filter);
        }
        if (this.SearchData != null && this.SearchData != "") {

          this.searchListData(this.SearchData);
        }
        if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
        // this.apiGridData = this.filteredData;
        //  this.gridData = this.filteredData;
        var gridView = {
          data: this.filteredData,
          total: this.totalRecordCount,
        };
        this.gridData = gridView;// process(this.filteredData, this.state);
        // reset all rows back to edit mode
        this.editGridRows();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      })
    }
    });

  }
  advImageForm: FormGroup;

  isResetMode = false;
  // @ViewChild('keywordData') keywordDataChild: any;
  /* @ViewChild('createdFrom') createdFromChild: any;
  @ViewChild('createdTo') createdToChild: any;
  @ViewChild('modifiedFrom') modifiedFromChild: any;
  @ViewChild('modifiedTo') modifiedToChild: any; */
  get f1() { return this.advImageForm.controls; }

  onSubmit(value) {
    this.showLoader = true;
    let createdFromChildStr = this.createdStartDate;
    let createdToChildStr = this.createdEndDate;
    let modifiedFromChildStr = this.modifiedStartDate;
    let modifiedToChildStr = this.modifiedEndDate;

    if ((createdFromChildStr !== null && createdToChildStr == null) && (createdFromChildStr !== undefined && createdToChildStr == undefined)) {
      this.error(this.staticMsgs.suppliersLibrary.CreatedToMandatory);
      this.logger.error(this.staticMsgs.suppliersLibrary.CreatedToMandatory, this.staticMsgs.suppliersLibrary.PaletteLibrary);

      this.showLoader = false;
      return;
    }
    else if ((createdFromChildStr == null && createdToChildStr !== null) && (createdFromChildStr == undefined && createdToChildStr !== undefined)) {
      this.error(this.staticMsgs.suppliersLibrary.CreatedFromIsMandatory);
      this.logger.error(this.staticMsgs.suppliersLibrary.CreatedFromIsMandatory, this.staticMsgs.suppliersLibrary.PaletteLibrary);

      this.showLoader = false;
      return;
    }
    if ((modifiedFromChildStr !== null && modifiedToChildStr == null) && (modifiedFromChildStr !== undefined && modifiedToChildStr == undefined)) {
      this.error(this.staticMsgs.suppliersLibrary.ModifiedToIsMandatory);
      this.logger.error(this.staticMsgs.suppliersLibrary.ModifiedToIsMandatory, this.staticMsgs.supplierLibrary.PaletteLibrary);

      this.showLoader = false;
      return;
    }
    else if ((modifiedFromChildStr == null && modifiedToChildStr !== null) && (modifiedFromChildStr == undefined && modifiedToChildStr !== undefined)) {
      this.error(this.staticMsgs.suppliersLibrary.ModifiedToIsMandatory);
      this.logger.error(this.staticMsgs.suppliersLibrary.ModifiedToIsMandatory, this.staticMsgs.suppliersLibrary.PaletteLibrary);

      this.showLoader = false;
      return;
    }
    if (this.f1.status.value == undefined) {
      this.f1.status.patchValue("");
    }
    if (this.suplierclassificationValue == undefined) {
      this.suplierclassificationValue = "";
    }

    let adv_data = {
      keyword: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.keyword.value),
      /// keyword: this.keywordDataChild.nativeElement.value,
      createdBy: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.createdBy.value),
      lastSocialAudit: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.lastSocialAudit.value),
      productType: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.productType.value),
      classification: this.aesdecryptservice.encryptData(this.dataService.Key, this.suplierclassificationValue),
      status: this.f1.status.value,
      createdFrom: createdFromChildStr,
      createdTo: createdToChildStr,
      modifiedFrom: modifiedFromChildStr,
      modifiedTo: modifiedToChildStr
    }
    //console.log(adv_data);
    this.store.dispatch(new commonActionTypes.LoadAdvancedSupplierSearch(adv_data));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((advancedSearchData) => {
      if (advancedSearchData.common.error === "") {
        if (advancedSearchData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.suppliersLibrary.AdvancesearchLodedSuccess,this.staticMsgs.suppliersLibrary.SupplierLibrary);
          this.cards = this.store.pipe(select(commonReducer.getCommon));
          this.copyCard = this.store.pipe(select(commonReducer.getCommon));
          this.apiGridData = advancedSearchData.common.message['item1'];
          this.apiData = advancedSearchData.common.message['item1'];
          this.isResetMode = true;
          this.showLoader = false;
          var gridView = {
            data: this.apiGridData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView;
        } else {
          if (advancedSearchData.common.message['item1'].toLowerCase().includes("no records")) {
            this.error(this.staticMsgs.suppliersLibrary.NoDataAvailable);
            this.logger.error(this.staticMsgs.suppliersLibrary.NoDataAvailable, this.staticMsgs.suppliersLibrary.SupplierLibrary);
            var gridView = {
              data: this.apiGridData,
              total: this.totalRecordCount,
            };
            this.apiGridData = [];
            this.apiData = [];
            this.cards = of([]);
            this.copyCard = of([]);
            this.gridData = gridView;
            this.showLoader = false;
          } else {
            this.error("Failed:" + advancedSearchData.common.message['item1'])
            this.logger.error(advancedSearchData.common.message['item1'], this.staticMsgs.suppliersLibrary.SupplierLibrary);
            this.showLoader = false;
            this.clearSearch();
          }

        }
      } else {
        this.logger.error(advancedSearchData.common.error.error.message, this.staticMsgs.suppliersLibrary.SupplierLibrary);
        this.getError(advancedSearchData.common.error);
        this.showLoader = false;
      }
    })
}
  clearSearch() {
    this.clearFields();
    this.loadData();
    this.loadDataList()
    this.isResetMode = false;
  }
  advanceSearchOpen() {
    this.classApplied = true;
    this.getColorClassification("Suppliers");
    //this.getClassificationData()
  }

  advanceSearchClose() {
    this.classApplied = false;

  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    if ((element.nodeName === 'TH') && (element.offsetWidth < element.scrollWidth)) {
      this.tooltipDir.toggle(element);
    }
    else if ((element.offsetWidth < element.scrollWidth) && (element.scrollWidth! < 937) && (this.isEditMode == false)) {
      this.tooltipDir.toggle(element);
    }
    else {
      this.tooltipDir.hide();
    }
  }
  success(message: string) {
    Swal.fire(
      'Success',
    // 'Supplier deleted successfully',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.suppliersLibrary.Oops,
      text: message
    })
  }
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.suppliersLibrary.SessionExpired,
      html: this.staticMsgs.suppliersLibrary.loginAgain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout();
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
      }
    })

  }
  /*  createColor() {
      this.router.navigate(['/colordetails']);
    }
    onEditColor(id) {
      this.storageservice.storeEditedColorId(id);
      this.router.navigate(['/colordetails']);
    }*/
  HandleError(err) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
    }
    else {
      this.error(err.message);
    }
  }

  setDate(event: any) {

    //console.log(event.target.value);
    if (event.target.getAttribute("formControlName") == "createdFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      //this.createdToChild.nativeElement.value = createdToChildStr;
    }
    else if (event.target.getAttribute("formControlName") == "modifiedFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      //this.modifiedToChild.nativeElement.value = createdToChildStr;
    }
  };
  clearFields() {
    this.createdFromDate = "";
    this.createdToDate = "";
    this.modifiedFromDate = "";
    this.modifiedToDate = "";
    this.f1.keyword.patchValue("");
    this.f1.createdBy.patchValue("");
    this.f1.classifications.patchValue("");
    this.f1.status.patchValue("");
    this.f1.productType.patchValue("");
    this.f1.lastSocialAudit.patchValue("");
    $("#statuslist").val('default').selectpicker("refresh");
    //$("#classificationlist").val('default').selectpicker("refresh");
    this.getClassificationData();
    this.suplierclassificationValue = "";
    this.enableCreatFromVal = null;
    this.enableCreatToVal = null;
    this.enableModifFromVal = null;
    this.enableModifToVal = null;
    this.enableKeywordVal = null;
    this.enableStatusVal = null;
    this.enableCreatedByVal = null;
    this.enableProductTypeVal = null;
    this.enableLastSocAuditVal = null;

  }
  clearfield(name) {
    switch (name) {
      case "createdFromChild": {
        this.createdFromDate = "";
        this.createdToDate = "";

        this.enableCreatFromVal = null;
        this.enableCreatToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "modifiedFromChild": {
        this.modifiedFromDate = "";
        this.modifiedToDate = "";
        this.enableModifFromVal = null;
        this.enableModifToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "keyword": { this.f1.keyword.patchValue(""); this.enableKeywordVal = null; this.checkAllFieldVal(); break; }
      case "createdBy": { this.f1.createdBy.patchValue(""); this.enableCreatedByVal = null; this.checkAllFieldVal(); break; }
      case "productType": { this.f1.productType.patchValue(""); this.enableProductTypeVal = null; this.checkAllFieldVal(); break; }
      case "lastSocialAudit": { this.f1.state.patchValue(""); this.enableLastSocAuditVal = null; this.checkAllFieldVal(); break; }
      //case "classification": { this.f1.classifications.patchValue(""); $("#classificationlist").val('default').selectpicker("refresh"); break; }
      case "classification": { this.getClassificationData(); this.suplierclassificationValue = ""; this.checkAllFieldVal(); break; }
      case "status": { this.f1.status.patchValue(""); $("#statuslist").val('default').selectpicker("refresh"); this.checkAllFieldVal(); break; }
    }
  }

  checkAllFieldVal() {
    if ((this.f1.keyword.value == "") && (this.createdFromDate == "") && (this.modifiedFromDate == "") && (this.f1.createdBy.value == "") && ((this.f1.status.value == "") || (this.f1.status.value == undefined))
      && (this.suplierclassificationValue == "" || this.suplierclassificationValue == undefined) && (this.f1.productType.value == "") || (this.f1.productType.value == undefined) && (this.f1.lastSocialAudit.value == "")) {
      this.clearSearch()
    }
  }

  checkFieldVal(val: string[], val1: string) {
    switch (val1) {
      case "createdFromChild": { this.enableCreatFromVal = val; break; }
      case "createdToChild": { this.enableCreatToVal = val; break; }
      case "modifiedFromChild": { this.enableModifFromVal = val; break; }
      case "modifiedToChild": { this.enableModifToVal = val; break; }
      case "keyword": { this.enableKeywordVal = val; break; }
      case "createdBy": { this.enableCreatedByVal = val; break; }
      case "status": { this.enableStatusVal = val; break; }
      case "productType": { this.enableProductTypeVal = val; break; }
      case "lastSocialAudit": { this.enableLastSocAuditVal = val; break; }
    }
  }

  getColorClassification(module: string) {
    this.showLoader = true;
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.suppliersLibrary.ColorLodedSuccess, this.staticMsgs.suppliersLibrary.SupplierLibrary);
          this.suplierLibraryClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.getClassificationData();
          this.showLoader = false;
        } else {
          this.getClassificationData();
          this.showLoader = false;
          this.error("Failed:" + classificationData.common.message?.item1)
          this.logger.error(classificationData.common.message?.item1, this.staticMsgs.suppliersLibrary.SupplierLibrary);
        }
      } else {
        this.logger.error(classificationData.common.error.error.message,this.staticMsgs.suppliersLibrary.SupplierLibrary);
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })
    /*  this.dataService.getClassification(module).subscribe(response => {
      // console.log(response)
      if (response.body['item2'] === "Success") {
        this.suplierLibraryClassificationData = response.body['item1'][0]["items"];
        this.logger.info("Color classification loaded successfully", "SupplierLibrary");
        ///console.log(JSON.stringify(this.suplierLibraryClassificationData ))
        // this.refreshTree();
        this.getClassificationData();
        this.showLoader = false;
      }
      else {
        //  this.refreshTree();
        this.getClassificationData();
        this.showLoader = false;
      }
    }, err => {
      this.logger.error(err.error.message, "SupplierLibrary");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader = false;
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "SupplierLibrary");
      }
      this.showLoader = false;
    }); */
  }

  getClassificationData() {
    var data = this.suplierLibraryClassificationData;
    var self = this;
    $(document).ready(function () {
      $("#suplierclassification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "200px",
        filter: "startswith",
        dataSource: data,
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          self.suplierclassificationValue = value["text"];
        }
      });

    });
  }

  dateChange(date: any, type: any) {
    switch (type) {
      case 'createdFromDate':
        this.createdStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdFromDate && this.createdFromDate !== undefined) {
          this.checkFieldVal(['val'], 'createdFromChild');
        }
        break;
      case 'createdToDate':
        this.createdEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : this.createdFromDate?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdToDate && this.createdToDate !== undefined) {
          this.checkFieldVal(['val'], 'createdToChild')
        }
        break;
      case 'modifiedFromDate':
        this.modifiedStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedFromDate && this.modifiedFromDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedFromChild')
        }
        break;
      case 'modifiedToDate':
        this.modifiedEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedToDate && this.modifiedToDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedToChild')
        }
        break;
    }
  }

  onChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", this.file, this.file.name);
      this.showLoader = true;
      this.dataService.uploadSuppliers(formData).subscribe(data=>{
        console.log(data);
        if(data.body['item2'] == 'Success'){
          this.loadData();
          this.success(this.staticMsgs.suppliersLibrary.DataUpload)

        }else{
          this.error(data.body['item1']);
        }
        this.showLoader = false;
      },err=>{
        this.error(err.common.message);
        this.showLoader = false;
      });
    }
  }

  downloadTemplate(){
    window.open('../../../../assets/excels/SampleTemplate_Suppliers.xlsx','_blank');
  }

  uploadFile(){
    let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
    element.click();
  }
  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select supplier Standard' });
    });
  }
  country = allCountries;

  /*
  .forEach(x=>{
    console.log('x',x.country[0]);
      x['id'] =x.country[0]
      x['value'] =x.country[0];
      x['text'] =x.country[0];
  });
  */

  allCountry:any=[];
  allStates: any = [];
  changeCountry(event,data){
    console.log('states',event,this.country);
    this.allStates = [];
    this.allCountry=[];
    let newData;
    if(data){
        newData = data;
    }else{
      newData = event.country[0];
    }
    let countries=this.country.filter(x=>(x.country == newData));
    console.log('countries',countries[0].country)
    let states = this.country.filter(x=>(x.country == newData));
    console.log('states',states[0].states);
    if(states[0].states.length > 0){
      states[0].states.forEach(y=>{
        this.allStates.push({
          state: y
        });
      });
      console.log(this.allStates,'allStates')
      this.refreshSelect();
      this.loadSelectPicker();
    }

    //console.log('this.allStates',this.allStates);
  }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }
}
