import { Component, Input, OnInit, Inject, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { DataService } from '../../../services/data.service';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { LogService } from '../../../shared/log.service';
import Swal from 'sweetalert2';
import { UserService } from '../../../helpers/user.service';
import { CalSubTotal } from '../../../models/color';
import { StorageService } from '../../../helpers/storage.service';
import { process, filterBy, orderBy, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StyledetailsComponent } from '../styledetails/styledetails.component';
import { select, Store } from '@ngrx/store';
import { datalist } from './datalist';
import { SharedService } from '../../../services/shared.service';
import { Router } from '@angular/router';
import { StylesComponent } from '../styles/styles.component';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { ToastService } from '../../../services/toast.service';
declare var $: any;
@Component({
  providers: [StylesComponent],
  selector: 'app-stylebom',
  templateUrl: './stylebom.component.html',
  styleUrls: ['./stylebom.component.css']
})
export class StylebomComponent implements OnInit {
  BOMformGroup: FormGroup;
  isloading: boolean = false;
  MaterialName: Array<string>;
  BomData = [];
  vendorData = [];
  colorData = [];
  submitted = false;
  showBomNote = false;
  appStaticMsgs: any;
  public colorCode: any;
  selected: any;
  sharedGridData: any[] = [];
  sticheslist = [];
  materialData: any[] = [];
  materialDataArr: any = [];
  sticheslistArr: any = [];
  vendorDataArr: any = [];
  colorDataArr: any = [];
  public filteredData: any[];
  showLoader: boolean;
  // showLoader1: boolean;
  calsubTotal = new CalSubTotal();
  Swal = require('sweetalert2');
  isEditMode: boolean;
  isCreateMode: boolean;
  isEditingMode: boolean;
  isshared: boolean;
  subtotal: number;
  isViewingMode: boolean;
  styleViewMode = "Created";
  totalSharedRecordCount: any;
  editData: any;
  public isNew: boolean;
  public bomViewData: any;
  public materialstartCountList = 0;
  public materialendCountList = 10;
  public materialstartEndCount = "";
  public bomstartCountList = 0;
  public bomendCountList = 10;
  public bomstartEndCount = "";
  public bomisPageChanged = false;
  public bomRecordCount: any;
  public bomloading: boolean;
  private editedRowIndex: number;
  totalMaterialCount: any;
  selectmaterial = true;
  selectmaterial2 = false;
  isbomedit: boolean;
  ismodaldata: boolean = true;
  uomDrodownList=[
    { id: 1, name: 'Yard' },
    { id: 2, name: 'Meter' },
    { id: 3, name: 'Square Foot' },
    { id: 4, name: 'Square Meter' },
    { id: 5, name: 'Piece' },
    { id: 6, name: 'Gross' },
    { id: 7, name: 'Kilogram' },
    { id: 8, name: 'Pound' },
    { id: 9, name: 'Linear Meter' },
    { id: 10, name: 'Linear Yard' },
    { id: 11, name: 'Roll' },
    { id: 12, name: 'Skein' }
  ];
  @Input('options') options: datalist[];
  public bomgridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  startEndCount: any;
  startCount: number=0;
  endCount: number=100;
  supplierDropdownList: any;
  colorDropdownList: any;

  constructor(
    private dialogRef: MatDialogRef<StyledetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private userservice: UserService,
    private storageservice: StorageService,
    private toastService: ToastService,
    private sharedService: SharedService,
    private router: Router,
  ) {
    this.sharedService.getBomData().subscribe(x => {
      console.log(x);
      this.editData = x;
      console.log(this.editData, 'editedData');
    })
    console.log(this.data.bomData, 'popupdata')
  }
  get f1() { return this.BOMformGroup.controls; }
  get fa() { return this.f1.formArray as FormArray; }
  fac(data) {
    return this.fa.controls[this.BomData.indexOf(data)] as FormGroup;
  }
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }


  colorName: any;
  supplierName: any;
  colourCode: any;

  ngOnInit(): void {
    console.log('popup data', this.data.bomData, this.data.isbomedit);
    this.getSupplierList();
    this.getColoursList();
    this.loadMaterialData();
    this.appStaticMsgs = appStaticMsg.data[0];
    if (this.data?.isbomedit == false) {
      console.log(this.data?.isbomedit, 'create')
      this.BOMformGroup = new FormGroup({
        stylebomid: new FormControl(),
        styleid: new FormControl(),
        component: new FormControl(''),
        //componentType: new FormControl(''),
        materialid: new FormControl(''),
        materialType: new FormControl(''),
        materialname: new FormControl(''),
        itemNo: new FormControl(''),
        supplierId: new FormControl(''),
        colourid: new FormControl(''),
        colourname: new FormControl(''),
        quantity: new FormControl(1),
        size: new FormControl(''),
        // colorCode:new FormControl(),
        price: new FormControl(1),
        subTotal: new FormControl(),
        uom: new FormControl(''),
        colourcode: new FormControl(''),
        notes: new FormControl('')
      });
      this.isNew = true
    }
    else if (this.data?.isbomedit == true) {
      this.colorName = this.data.bomData.colourID;
      this.supplierName = this.data.bomData.supplier?.id;
      this.colourCode = this.data.bomData.colour?.hexcode,
        console.log(this.data?.isbomedit, 'edit',this.data.bomData)
      console.log(this.colourCode, '')
      this.BOMformGroup = new FormGroup({
        stylebomid: new FormControl(this.data.bomData.id),
        styleid: new FormControl(this.data.bomData.stylesDTOID),
        component: new FormControl(this.data.bomData.component),
        materialid: new FormControl(this.data.bomData.materialID),
        materialType: new FormControl(this.data.bomData?.materialType),
        materialname: new FormControl(this.data.bomData?.materialName),
        itemNo: new FormControl(this.data.bomData.itemNo),
        supplierId: new FormControl(this.data.bomData.supplierId),
        colourid: new FormControl(this.data.bomData.colourID),
        suppliername: new FormControl(this.data.bomData.supplierName),
        colourname: new FormControl(this.data.bomData.colourName),
        quantity: new FormControl(this.data.bomData.quantity),
        size: new FormControl(this.data.bomData.sizeOrWidth),
        price: new FormControl(this.data.bomData.price),
        subTotal: new FormControl(this.data.bomData.subTotal),
        uom: new FormControl(this.data.bomData.uom),
        colourcode: new FormControl(this.data.bomData.colour?.hexcode),
        notes: new FormControl(this.data.bomData.notes)
      });

      console.log(this.BOMformGroup, 'edit');
      this.ismodaldata=false;
    }
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessgetcolorwaysionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })
  }
  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.userservice.logout();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
      this.showLoader = false;
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.appStaticMsgs.unResponsive.error);

    }
    this.showLoader = false;
  }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  initSlectPicker(key) {
    $(document).ready(function () {
      $('#' + key).selectpicker();
    });
  }
  loadMaterialData() {
    this.showLoader = true;
    this.materialstartEndCount = this.materialstartCountList + "-" + this.materialendCountList;
    this.dataservice.getDetailsByModuleName('Materials', this.materialstartEndCount).subscribe(response1 => {
      if (response1.body['item2'] === "Success") {
        this.materialendCountList = response1.body['item3']
        this.materialstartEndCount = this.materialstartCountList + "-" + this.materialendCountList;
        this.dataservice.getDetailsByModuleName('Materials', this.materialstartEndCount).subscribe(response => {
          console.log(response, 'material data')
          if (response.body['item2'] === "Success") {
            response.body['item1'].forEach(x => {
              x.name = this.aesdecryptservice.decryptData(this.dataservice.Key, x.name);
              this.materialData.push(x)
              this.materialDataArr = this.materialData.slice();
            });

            this.totalMaterialCount = response.body['item3'];
            setTimeout(() => {
              if (this.data?.isbomedit == true) {
                this.selectionChangeMaterial('', this.data.bomData.materialID);
                this.BOMformGroup.controls['materialname'].setValue(this.data.bomData.materialID);
              }
              console.log('refreshSelect')
              this.initSlectPicker('materialname');
              $("#materialname").attr('data-live-search', true);
            }, 2000);
            if (this.data?.isbomedit == false) {
              this.ismodaldata = false
            }
            //$("#materialname").selectpicker();
            this.logger.info(this.appStaticMsgs.styledetails.allmaterialdataloadedsuccessfully, "Style Details");
            this.showLoader = false;
            //$('.selectpicker').selectpicker();
          }
          else {
            this.showLoader = false;
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "Style Details");
          }
        }, err => {
          this.logger.error(err.error.message, "Style Details");
          if (err.error.message === "Network error communicating with endpoint") {
            // this.error(err.error.message);
            this.toastService.showError("Oops!! Something went wrong. Please try again.");
            this.showLoader = false;
          } else if (err.error.message === "The incoming token has expired") {
            this.sessionTimedOut();
            this.userservice.logout();
            this.toastService.showError("The token being used has expired. Please login again.");
            this.showLoader = false;
          } else if (err.error.message === "Unauthorized") {
            // this.userservice.error();
            this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
            this.showLoader = false;
          }
          else {
            this.logger.error(err.message, "Style Details");
            // this.error(err.message);
            this.toastService.showError(this.appStaticMsgs.unResponsive.error);
          }
          this.showLoader = false;
        })
      }
    });
  }


  selectionChangeMaterial(event, id) {
    console.log(id, 'id')
    let eveId;
    if (event) {
      eveId = event.target.value;
      console.log(eveId)
    } else {
      eveId = id;
    }
    this.showLoader = true;
    console.log('selectionChangeMaterial', event);
    this.dataservice.getMaterialById(eveId).subscribe((response) => {
      console.log(response, 'materialId')
      if (response.body['item2'] === "Success") {
        this.logger.info(response.body['item1']["name"] + " material loaded successfully", "Style Details");
        this.showLoader = false;
        this.calsubTotal.price = parseFloat(response.body['item1']["cost"]);
        // this.BOMformGroup.controls.colourid.setValue(undefined);
        // this.BOMformGroup.controls.supplierId.setValue(undefined);
        // for (let i = 0; i < response.body['item1']["suppliers"].length; i++) {
        //   response.body['item1']["suppliers"][i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["suppliers"][i].name);
        // }
        // for (let i = 0; i < response.body['item1']["colours"].length; i++) {
        //   response.body['item1']["colours"][i].name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["colours"][i].name);
        // }
        // this.vendorData = response.body['item1']["suppliers"];
        // this.colorData = response.body['item1']["colours"];
        // this.vendorDataArr = this.vendorData.slice();
        // this.colorDataArr = this.colorData.slice();

        this.initSlectPicker('supplierId');
        //           $("#supplierId").attr('data-live-search',true);

        this.initSlectPicker('colourid');
        //           $("#colourid").attr('data-live-search',true);
        // console.log('vendorData', this.vendorData)
        // console.log('colorData', this.colorData);
        this.BOMformGroup.get('styleid').setValue(this.storageservice.getEditedStyleId());
        this.BOMformGroup.get('materialType').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["classification"]));
        this.BOMformGroup.get('itemNo').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sequence"]));
        this.BOMformGroup.get('materialid').setValue(response.body['item1']["id"]);
        this.BOMformGroup.get('materialname').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["name"]));
        // this.BOMformGroup.get('price').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["cost"]));
        //this.BOMformGroup.get('uom').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sizeUOM"]));
        // this.BOMformGroup.get('subTotal').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["subTotal"]));

        console.log(this.BOMformGroup, 'Bomform')
        //var sId = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["supplier"]['name']);
        //var cId = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["colour"]['name']);

        setTimeout(() => {
          if (this.data?.isbomedit == true) {
            this.BOMformGroup.controls['supplierId'].setValue(this.supplierName);
            this.BOMformGroup.controls['colourid'].setValue(this.colorName);
          }
          console.log('refreshSelect 1')
          // this.selectionChangeColor('',this.data.bomData.colour.hexcode);
          this.selectionChangeColor('', this.BOMformGroup.controls.colourid.value);
          //this.showLoader1 = false;
          this.initSlectPicker('supplierId');
          this.initSlectPicker('colourid');
          $("#supplierId").attr('data-live-search', true);
          $("#colourid").attr('data-live-search', true);
        }, 2000);
        this.ismodaldata = false;
      }
      else {
        this.showLoader = false;
        this.logger.error(response.body['item1'], "Style Details");
      }
    },
      err => {
        ////console.log(err)
        this.logger.error(err.error.message, "Style Details");
        if (err.error.message === "Network error communicating with endpoint") {
          this.showLoader = false;
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.showLoader = false;
          this.sessionTimedOut();
          this.userservice.logout()
          this.toastService.showError("The token being used has expired. Please login again.");
        } else if (err.error.message === "Unauthorized") {
          this.showLoader = false;
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        }
        else{
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
      });
  }

  HandleError(err) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.userservice.logout();
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
    }
    else{
      this.toastService.showError(this.appStaticMsgs.unResponsive.error);
    }
  }

  public getBomDetails() {
    this.bomloading = true;
    this.bomstartEndCount = this.bomstartCountList + "-" + this.bomendCountList;
    this.dataservice.getdetailsForBOM(this.storageservice.getEditedStyleId(), this.bomstartEndCount).subscribe(response => {
      console.log(response, 'bomdetails')
      if (response.body['item2'] === "Success") {
        this.logger.info(this.appStaticMsgs.styledetails.bomdetailsloadedsuccsessfully, "Style Details");
        this.filteredData = response.body['item1']['boMs'];
        this.bomRecordCount = response.body['item3'];
        if (this.filteredData) {
          for (let i = 0; this.filteredData != null && i < this.filteredData.length; i++) {
            this.filteredData[i].component = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].component);
            this.filteredData[i].materialName = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].materialName);
            this.filteredData[i].materialType = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].materialType);
            this.filteredData[i].itemNo = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].itemNo);
            if (this.filteredData[i].supplier) {
              this.filteredData[i].supplier.name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].supplier.name);
            }
            if (this.filteredData[i].colour) {
              this.filteredData[i].colour.name = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].colour.name);
            }
            this.filteredData[i].sizeOrWidth = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].sizeOrWidth);
            this.filteredData[i].uom = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].uom);
            this.filteredData[i].notes = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].notes);
            this.filteredData[i].price = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].price);
            // this.filteredData[i].colourChip=this.aesdecryptservice.decryptData(this.dataservice.Key,this.filteredData[i].colourChip);
            if (this.filteredData[i].colour) {
              //this.filteredData[i].colour.thumbnailFiles.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].colour?.thumbnailFiles.thumbnail);
              this.filteredData[i].colour.hexcode = this.aesdecryptservice.decryptData(this.dataservice.Key, this.filteredData[i].colour?.hexcode);
            }
          }
          var gridView = {
            data: this.filteredData,
            total: this.bomRecordCount,
          };
          this.bomViewData = gridView;
        }
        else {
          this.bomViewData = null;
        }
        this.bomloading = false;
      }
      else {
        this.bomloading = false;
        this.error(response.body['item1']);
        this.logger.error(response.body['item1'], "Style Details");

      }
    }, err => {
      this.logger.error(err.error.message, "Style Details");

      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.bomloading = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.bomloading = false;
        this.toastService.showError("The token being used has expired. Please login again.");
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        this.bomloading = false;
      }
      else {
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        this.logger.error(err.message, "Style Details");
      }
      this.bomloading = false;
    });
  }

  onKeyUpPrice(event) {
    this.calsubTotal.price = parseFloat(event.target.value);
    this.subtotal = this.BOMformGroup.get('quantity').value * this.calsubTotal.price
    this.BOMformGroup.get('subTotal').setValue(this.subtotal.toFixed(2));
  }

  onInput(id:string) {
    const Value = this.BOMformGroup.get(id).value;
    if (isNaN(Value) || Value <= 0) {
      this.BOMformGroup.get(id).setValue('');
    }
  }


  onKeyUpQuantity(event) {
    if(event.target.value <= 0){
      return;
    }
    this.calsubTotal.quantity = parseFloat(event.target.value);
    this.subtotal = this.calsubTotal.quantity * this.BOMformGroup.get('price').value;
    this.BOMformGroup.get('subTotal').setValue(this.subtotal.toFixed(2));
    console.log('this.subtotal', this.calsubTotal.quantity,
      this.BOMformGroup.get('price').value, this.subtotal);

  }

  onBomSubmit() {
    this.showLoader = true;
    console.log('first', this.BOMformGroup);
    if (this.BOMformGroup.invalid) {
      this.submitted = false;
      this.showLoader = false;
      this.toastService.showError("Please enter all the mandatory fields.");
    }
    this.BomData = this.BOMformGroup.value;
    console.log(this.BomData, 'BomData')
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    // formData.append('colourid', this.BomData['colourid']);
    if (this.BomData['colourid']) {
      formData.append('colourid', this.BomData['colourid']);
    }
    if (this.BomData['materialid']) {
      formData.append('materialid', this.BomData['materialid']);
    }
    if (this.BomData['stylebomid']) {
      formData.append('stylebomid', this.BomData['stylebomid']);
      formData.append('tag', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Update'));
    } else { formData.append('tag', this.aesdecryptservice.encryptData(this.dataservice.Key, 'Add')); }

    if (this.BomData['colourname']) {
      formData.append('colourname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['colourname']));
    }
    if (this.BomData['materialType']) {
      formData.append('materialtype', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['materialType']));
    }
    if (this.BomData['materialname']) {
      formData.append('materialname', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['materialname']));
    }
    if (this.BomData['component']) {
      formData.append('component', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['component']));
    }
    if (this.BomData['notes']) {
      formData.append('notes', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['notes']));
    }
    if (this.BomData['itemNo']) {
      formData.append('item', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['itemNo']));
    }
    if (this.BomData['quantity']) {
      formData.append('quantity', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['quantity'].toString()));
    }
    if (this.BomData['uom']) {
      formData.append('uom', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['uom']));
    }

    if (this.BomData['subTotal']) {
      formData.append('subtotal', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['subTotal'].toString()));
    }
    if (this.BomData['price']) {
      formData.append('price', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['price'].toString()));
    }
    if (this.BomData['size']) {
      formData.append('size', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['size'].toString()));
    }
    if (this.BomData['supplierId']) {
      formData.append('supplierid', this.BomData['supplierId']);
    }
    if (this.BomData['colourcode']) {
      formData.append('colourcode', this.aesdecryptservice.encryptData(this.dataservice.Key, this.BomData['colourcode']));
    }
    this.showLoader = true;
    console.log(formData, 'formData')
    if (this.data?.isbomedit === false) {
      console.log(this.data?.isbomedit, 'create')
      this.dataservice.addupdatedeleteStyleBOMS(formData).subscribe(async response => {
        console.log(response, 'response')
        this.showBomNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.logger.info("BOM Save Successfully", "Style BomDetails");
            this.showLoader = false;
            this.filteredData = response.body['item1']['boMs'];
            //this.BOMformGroup = undefined;
            // this.success(this.appStaticMsgs.styledetails.bomsavesuccessfully);
            this.toastService.showSuccess('Added Successfully');
            await this.getBomDetails();
            this.logger.info(this.appStaticMsgs.styledetails.bomsavesuccessfully, 'Style Details')
            this.dialogRef.close();
          }
          else {
            ////console.log(response)
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "Style BomDetails");
            this.showLoader = false;
          }
        }
      }, err => {
        this.showBomNote = false;
        this.logger.error(err.error.message, "Style BomDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout();
          this.toastService.showError("The token being used has expired. Please login again.");
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        }
        else {
          this.logger.error(err.error.message, "Style BomDetails");
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      });
    }
    else if (this.data?.isbomedit == true) {
      console.log(this.data?.isbomedit, 'edit');
      this.dataservice.addupdatedeleteStyleBOMS(formData).subscribe(async response => {
        console.log(response, 'response')
        this.showBomNote = false;
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.logger.info("BOM Save Successfully", "Style BomDetails");
            this.showLoader = false;
            this.filteredData = response.body['item1']['boMs'];
            //this.BOMformGroup = undefined;
            // this.success("BOM Updated Successfully");
            this.toastService.showSuccess('Updated Successfully');
            await this.getBomDetails();
            this.logger.info(this.appStaticMsgs.styledetails.bomsavesuccessfully, 'Style Details')
            this.dialogRef.close();
          }
          else {
            ////console.log(response)
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "Style BomDetails");
            this.showLoader = false;
          }
        }
      }, err => {
        this.showBomNote = false;
        this.logger.error(err.error.message, "Style BomDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout();
          this.toastService.showError("The token being used has expired. Please login again.");
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
          this.sessionTimedOut();
          this.userservice.logout();
        }
        else {
          this.logger.error(err.error.message, "Style BomDetails");
          // this.error(err.message);
          this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        }
        this.showLoader = false;
      });
    }
  }
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }
  selectionChangeColor(event, id) {
    console.log(id, 'colorid')
    let colorid;
    if (event) {
      colorid = event.target.value;
    } else {
      colorid = id;
    }
    for (let i = 0; i <= this.colorDropdownList.length; i++) {
      if ((colorid != null && colorid != undefined)  && colorid == this.colorDropdownList[i]?.id) {
        this.BOMformGroup.get('colourname').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, this.colorDropdownList[i]["name"]));
       // this.BOMformGroup.get('colourname').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, this.colorDropdownList[i]["name"]));
        // this.colorData[i]["classification"] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.colorData[i]["classification"]);
        // this.colorData[i]["hexcode"] = this.aesdecryptservice.decryptData(this.dataservice.Key, this.colorData[i]["hexcode"]);
        // this.BOMformGroup.get('colourcode').setValue(this.colorData[i]["hexcode"]);
        this.BOMformGroup.get('colourcode').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, this.colorDropdownList[i]["hexcode"]))
        console.log('000', this.BOMformGroup.get('colourcode').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, this.colorDropdownList[i]["hexcode"])));
        this.colorCode = this.BOMformGroup.controls.colourcode.value;
        this.showLoader= false
      }

    }
    /* if (this.isbomedit == true) {
      this.BOMformGroup.get('colourcode').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, this.colorCode))
    } */
    // this.colorData.forEach(element => {
    //   console.log(element);
    //     this.BOMformGroup.get('colourname').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, element["name"]));
    //     element["classification"] = this.aesdecryptservice.decryptData(this.dataservice.Key, element["classification"]);
    //     element["hexcode"] = this.aesdecryptservice.decryptData(this.dataservice.Key, element["hexcode"]);
    //    this.BOMformGroup.get('colourcode').setValue(element["hexcode"]);
    //    this.colorCode = element;
    // console.log(this.colorCode);
    // });
  }

  close() {
    this.dialogRef.close();
  }
  async getSupplierList() {
    this.startEndCount = this.startCount + '-' + this.endCount;
    this.dataservice
      .getDetailsByModuleName('Suppliers', this.startEndCount)
      .subscribe((res: any) => {
        console.log(res, 'supplierList');
        if (res.body['item2'] == 'Success') {
          this.supplierDropdownList = res.body['item1'];
          // const getExistedSuppliers=localStorage.getItem('addedSuppliers')
          // if(getExistedSuppliers.length!==0){
          //   this.supplierDropdownList = this.supplierDropdownList?.filter(
          //     (item: any) => !getExistedSuppliers?.includes(item.id)
          //   );
          //   console.log(getExistedSuppliers);
          // }
         console.log(this.supplierDropdownList);
          for (let i = 0; i < this.supplierDropdownList.length; i++) {
            res.body['item1'][i].name =
            this.aesdecryptservice.decryptData(this.dataservice.Key,
                res.body['item1'][i].name
              );
          }
        }
      });
  }

  getColoursList() {
    this.startEndCount = this.startCount + '-' + this.endCount;
    this.dataservice
      .getDetailsByModuleName('Colours', this.startEndCount)
      .subscribe((res: any) => {
        if (res.body['item2'] == 'Success') {
          this.colorDropdownList = res.body['item1'];
          // const getExistedColors=localStorage.getItem('addedColors')
          // if(getExistedColors.length!==0){
          //   this.colorDropdownList = this.colorDropdownList?.filter(
          //     (item: any) => !getExistedColors?.includes(item.id)
          //   );
          //   console.log(getExistedColors);
          // }
         console.log(this.colorDropdownList);
          for (let i = 0; i < this.colorDropdownList.length; i++) {
            res.body['item1'][i].name =
            this.aesdecryptservice.decryptData(this.dataservice.Key,
                res.body['item1'][i].name
              );
          }
          console.log(this.colorDropdownList, 'colocrs');
        }
      });
  }
}
