// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  socketIoServerUrl: 'https://fitosapi.pluraltechnology.com:7443/',
  applicationServerPublicKey:
    'BPqqZEMn0PuH7MLXbovraSTRm-E9S8BbeTr_Lj8W4ECD0ynbcJwGHj9F9mmqz24DCEEtafN057bXzqTpMBC-BqQ',
  deviceSubscription:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/pushnotification/devicesubscription',
  deviceUnsubscription:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/pushnotification/deviceunsubscription',
  sendNotification:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/pushnotification/sendnotification',
  getNotificationbyUser:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/pushnotification/getnotificationbysubscriber',
  getExistingDevice:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/pushnotification/getexistingdevicebydevicename',
  readNotifications:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/pushnotification/markasreadnotifications',
  deleteNotifications:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/pushnotification/deletenotifications',

  notificationStyleSharedMessage: ' has shared StyleName with you.',
  notificationStyleUnSharedMessage: ' has removed StyleName access with you.',
  notificationStyleChatMessage: ' has commented on StyleName.',
  notificationStyleEditAccessMessage: ' has given edit access to StyleName.',
  notificationStyleViewAccessMessage:
    ' has changed your access to view mode for StyleName.',

  server:
    'http://ptplfitoslibraryapi-390599410.us-west-2.elb.amazonaws.com/api/v1/',
  reg_user:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/adduser',
  menu_items:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/menu/getmenuinfo',
  get_roles:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/roles/getroleslist',
  get_user_details:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/getuser',
  update_user_details:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/updateuserprofile',
  getuserbyid:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/getuserbyid',
  sendInvite:
    ' https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/sendemail',

  /* Portfolio Profile */
  addUserPortfolio:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/adduserportfolios',
  updateUserPortfolio:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/updateuserportfolios',
  getUserPortfolios:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/getuserportfolios',
  deleteUserPortfolio:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/deleteuserportfolios',

  /* Supplier Profile */
  addUserSupplier:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/addusersuppliers',
  updateUserSupplier:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/updateusersuppliers',
  getUserSupplier:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/getusersuppliers',
  deleteUserSupplier:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/deleteusersuppliers',

  /* Color URLs Start */
  AddColour:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/addcolour',
  GetAllSharedColours:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/getallsharedcolours',
  UpdateColorById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/updatecolourbyid',
  UpdateColorDocuments:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/updatecolourdocuments',
  BulkUpdateColour:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/updatecolour',
  GetAllColours:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/getallcolours',
  BulkDeleteColour:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/deletecolour',
  GetDocumentsByColourId:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/getdocumentsbycolour',
  GetColourByID:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/getcolourbyid',
  ColorAdvancedsearch:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/advancedsearch',
  /* Color URLs End */

  /* Stitches URLs Start */
  AddStitches:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/addstitches',
  UpdateStitchesById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/updatestitchbyid',
  UpdateStitchesDocuments:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/updatestitchdocuments',
  BulkUpdateStitches:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/bulkupdatestitches',
  GetAllStitches:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/getallstitches',
  GetAllSharedStitches:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/getallsharedstitches',
  BulkDeleteStitches:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/bulkdeletestitches',
  GetDocumentsByStitchesId:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/getstitchdocuments',
  GetStitchesByID:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/getstitchbyid',
  StitchesAdvancedsearch:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/stitches/advancedsearch',
  /* Stitches URLs End */

  /* Palettes URLs Start */
  GetAllPalettes:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/getallpalettes',
  GetAllSharedPalettes:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/getallsharedpalettes',
  BulkUPdatePalettes:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/bulkupdatepalettes',
  BulkDeletePalettes:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/bulkdeletepalettes',
  PalettesAdvancedSearch:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/advancedsearch',
  GetPaletteByID:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/getpalettebyid',
  AddPalette:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/addpalettes',
  UpdatePaletteById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/updatepalettebyid',
  UpdatePaletteDocuments:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/updatepalettedocuments',

  DeletePaletteById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/deletepalettebyid',
  UpdatePaletteColours:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/updatepalettecolours',
  GetPaletteColours:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/getpalettecolours',
  GetPaletteDocuments:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/palettes/getpalettedocuments',

  /* Palettes URLs End */

  /* Document URL Starts */
  AddDocument:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/documentadd',
  GetAllSharedDocuments:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/getallshareddocuments',
  BulkUpdateDocument:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/documentupdate',
  GetDocumentByID:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/getdocumentbyid',
  GetAllDocuments:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/getalldocuments',
  DeleteBulkDocumentByIDs:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/documentdelete',
  AddDocumentFile:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/documentfileadd',
  GetDocumentFileByDocumentID:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/getdocumentfiles',
  DeleteBulkDocumentFileByIDs:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/deletedocumentfiles',
  DocumentAdvancedsearch:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/advancedsearch',
  /* Document URL End */

  /*Supplier URL Start */
  CreateSupplier:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/addsupplier',
  GetAllSuppliers:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/getallsuppliers',
  GetAllSharedSuppliers:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/getallsharedsuppliers',
  SupplierAdvancedSearch:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/advancedsearch',
  BulkUpdateSupplier:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/updatesuppliers',
  BulkDeleteSupplier:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/deletesuppliers',
  GetSupplierByID:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/getsupplierbyid',
  UpdateSupplierDocuments:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/updatesupplierdocuments',
  GetSupplierFiles:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/getsupplierdocuments',
  /*Supplier URL End */

  /** Image Library */
  CreateImageDocument:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/documentadd',

  BulkAddFavourites:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/favourites/addtofavourite',
  BulkDeleteFavourites:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/favourites/deletefavourite',

  GetClassification:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/classifications/getclassification',
  Bucketname: 'fitoshrishifileupload',
  BucketAccessKeyId: 'AKIAW7NQSUNQQNSQWMHO',
  BucketSecretAccessKey: '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',
  BucketRegion: 'us-east-1',
  /*Material Library URL Start */
  GetAllMaterial:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/getallmaterials',
  GetAllSharedMaterials:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/getallsharedmaterials',
  MaterialAdvancedsearch:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/advancedsearch',
  BulkUpdateMaterial:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/updatematerial',
  GetMaterialClassification:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/classifications/getclassification',
  BulkDeleteMaterial:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/deletematerial',
  AddMaterial:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/addmaterial',
  GetMaterialById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/getmaterialbyid',
  UpdateMaterialById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/updatematerialbyid',
  AddMaterialRelation:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/updatematerialbyid',
  AddMaterialSupplier:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/materialsuppliers',
  GetMeterialData:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/getmaterialdata',
  AddSupplierColor:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/suppliercolours',
  AddMaterialColor:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/materialcolour',
  AddupdateMaterialDoc:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/materialdocuments',
  Getmaterialcolours:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/getmaterialcolours',
  Getmaterialsuppliers:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/getmaterialsuppliers',
  GetDetailsByModuleName:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/library/getdropdownlibraryitems',
  uploadMaterials:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/materials/uploadmaterials',
  uploadColors:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/colours/uploadcolours',
  uploadSuppliers:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/suppliers/uploadsuppliers',
  uploadStyles:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/uploadstyles',

  /*Material Library URL End */

  /*Seams Library URL Start */
  GetAllSeams:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/getallseams',
  GetAllSharedSeams:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/getallsharedseams',
  BulkUpdateSeams:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/bulkupdateseams',
  BulkDeleteSeams:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/bulkdeleteseams',
  SeamsAdvanceSearch:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/advancedsearch',
  AddSeams:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/addseams',
  GetSeamsById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/getseambyid',
  UpdateSeamsById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/updateseambyid',
  UpdateSeamDocument:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/updateseamdocuments',
  GetDocumentBySeamId:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/seams/getseamdocuments',
  /*Seams Library URL Start */

  /** Styles Library URL Start */
  AddStyles:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/addstyle',
  AddStyleColorway:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/addstylecolourway',
  GetStyleByID:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylebyid',
  UpdateStyleByID:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/updatestyle',
  GetMaterialBOM:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getmaterialforbom',
  GetColorBOM:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getcolourforbom',
  GetAllStyles:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getallstyles',
  GetAllSharedStyles:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getallsharedstyles',
  AddUpdateDeleteStyleBOMS:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/updatestyleboms',
  StyleAdvancesearch:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/advancedsearch',
  AddUpdateDeleteStyleImages:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/updatestyleimages',
  GetStyleImages:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstyleimages',
  GetStyleDocuments:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstyledocuments',
  UpdateStyleColourways:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/updatestylecolourways',
  GetDetailsForBOM:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstyleboms',
  GetStyleById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylebyid',
  AddUpdateDeleteStylePatterns:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/updatestylepatterns',
  Getstylepatterns:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylepatterns',
  AddUpdateDeleteStyleSpecifications:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/updatestylespecifications',
  GetStyleStitcheSpecifications:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylespecifications',
  GetStylePomSpecifications:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylepomspecifications',
  GetUserRelatedOrganisationInfo:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/ShareOrg/GetAdditionalOrgById',
  SaveUserOrg:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/ShareOrg/AddAdditionalOrg',
  AddUpdateDeleteStyleProcessing:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/updatestyleprocessing',
  GetStyleSewnProcessing:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstyleprocessing',
  GetStylePomProcessing:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylepomprocessing',
  GetStyleConstruction:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstyleconstruction',
  AddUpdateDeleteStyleConstructions:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/updatestyleconstruction',
  BulkDeleteStyles:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/bulkdeletestyles',
  BulkUpdateStyles:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/bulkupdatestyles',
  GetStyleDataById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylefromid',
  GetStyleColourways:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylecolourways',
  AddUpdateStyleSustainability:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/addupdatesustainability',
  styleClone:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/styleclone',
  styleSizeRnage:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/library/getdropdownlibraryitems?Module=Styles&RecordCount=0-10',
  getStylePOMTemplate:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylepomtemplate',

  getStylePOMTemplateBasedOnAI: 'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/GetStylePomNames',
  /** Styles Library URL End */
  /** Add colabrator URL Start */
  GetAllusers:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/getallusers',
  AddUpdateSharedaccess:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/sharedaccess/addupdatesharedaccess',
  GetSharedAccess:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/sharedaccess/getsharedaccess',
  GetAllUserMang:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/users/getuserslistforusermanagement',
  /** Styles Library URL End */

  /**MoodBoard Library URL Start */
  AddMoodBoard:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/moodbaord/addmoodboard',
  GetAllMoodBoards:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/moodbaord/getallmoodboards',
  GetAllSharedMoodboards:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/moodbaord/getallsharedmoodboards',
  GetMoodBoardById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/moodbaord/getmoodboardbyid',
  DeleteMoodBoardById:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/moodbaord/deletemoodboardbyid',
  GetImageURLSs:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/getimageurls',
  GetImageByURL:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/document/getimagebase64url',
  /**MoodBoard Library URL End */

  /**Logging Library URL Start */
  AddClientLog:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/logger/addclientlogs',
  /**MoodBoard Library URL End */
  /* Dashboard URL Start */
  GetRecentActivities:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/recentactivity/getrecentactivities',
  GetDashboardNotifications:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/pushnotification/getallnotification',
  GetDashboardFavourites:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/favourites/getuserfavourites',
  /* Dashboard URL End */


  // Style AI APIS
  DecsUsingAI: 'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/FitosAI/ideas',
  ConstructionListAI: 'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/FitosAI/Construction_Steps',
  BomAI: 'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/FitosAI/BOM',
  POMAITemplates: 'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/styles/getstylepomnames',
  POMAIList: 'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/FitosAI/POM',


  /* Calling dummy data url */
  DataInsertForNewUser:
    'https://yi7pcud006.execute-api.ap-south-1.amazonaws.com/test/library/adddefaultitems',
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
