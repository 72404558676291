<app-loader [showLoader]="showLoader"></app-loader>
<app-loader [showLoader]="showLoader1"></app-loader>
<mat-dialog-content class="mat-typography">
  <div class="dt-page__header">
    <h3 class="dt-page__title">Edit Material
      <button type="button" class="close" aria-label="Close" (click)="cancelMaterialCreate()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
  </div>
  <form [formGroup]="materialForm" (ngSubmit)="onSubmit()">
    <!-- <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div> -->
    <div class="form-row">

      <div class="col-lg-4 col-sm-4">
        <label for="materialclassification">{{staticMsgs.matDetail.selectClass}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="materialclassification" formControlName="materialclassification"
          placeholder="Classification" required />
        <div
          *ngIf="(submitted && this.f.materialclassification.errors) || ( this.f.materialclassification.invalid && (this.f.materialclassification.dirty || this.f.materialclassification.touched))">
          <div *ngIf="this.f.materialclassification.errors.required" class="red">
            {{staticMsgs.matDetail.classReq}}
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-4 mb-3" *ngIf="isEditMode">
        <label for="materialseq">{{staticMsgs.matDetail.material}}</label>

        <input type="text" class="form-control" [readonly]="true" id="materialseq" formControlName="materialseq"
          placeholder="Material I d" />
      </div>

      <div class="col-lg-4 col-sm-4 mb-3">
        <label for="materialname">{{staticMsgs.matDetail.name1}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="materialname" formControlName="materialname" minlength="3"
          maxlength="50" placeholder="Material Name" required (keypress)="characterOnly($event)" />
        <div
          *ngIf="(submitted && this.f.materialname.errors) || ( this.f.materialname.invalid && (this.f.materialname.dirty || this.f.materialname.touched))">
          <div *ngIf="this.f.materialname.errors.required" class="red">
            {{staticMsgs.matDetail.matNameReq}}
          </div>
          <div *ngIf="this.f.materialname.errors.minlength" class="red">
            {{staticMsgs.common.minimum3charactersrequired}}
          </div>
          <!-- <div class="red"
                                        *ngIf="this.materialForm.controls.materialname.value && this.materialForm.controls.materialname.value.length > 50">
                                        Material Name is allowed 50 characters only.
                                    </div> -->

        </div>
      </div>

    </div>
    <div class="form-row" *ngIf="(parentClassification === 'Fabric')">
      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="cuttablewidth">{{staticMsgs.matDetail.cutWidth}}</label>
        <input type="text" class="form-control" id="cuttablewidth" formControlName="cuttablewidth"
          (keypress)="numberOnly($event)" placeholder="Cuttable Width" (focusout)="addDecimal('cuttablewidth')" />
        <div class="red"
          *ngIf="this.materialForm.controls.cuttablewidth.errors && this.materialForm.controls.cuttablewidth.value && this.materialForm.controls.cuttablewidth.value.length > 20">
          {{staticMsgs.matDetail.cutWidthAllow}}
        </div>
        <div class="red" *ngIf="this.f.cuttablewidth.errors?.pattern">
          {{staticMsgs.matDetail.cutWidthInvalid}}
        </div>
      </div>
      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="widthuom">{{staticMsgs.matDetail.wUOM}}</label>
        <select type="text" title="Select Width UOM" class="form-control selectpicker w-100" id="widthuom"
          formControlName="widthuom">
          <!-- <option value="" [disabled]="true" selected>Select Width UOM
                                </option> -->
          <option value="Inch">{{staticMsgs.matDetail.inch}}</option>
          <option value="Meter">{{staticMsgs.matDetail.meter}}</option>
          <option value="cm">{{staticMsgs.matDetail.cm}}</option>
          <option value="mm">{{staticMsgs.matDetail.mm}}</option>
        </select>
      </div>
      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="weightgsm">{{staticMsgs.matDetail.weightGSM}}</label>
        <input type="text" class="form-control" id="weightgsm" formControlName="weightgsm" placeholder="Weight/ GSM"
          (keypress)="numberOnly($event)" (focusout)="addDecimal('weight')" />
        <div class="red"
          *ngIf="this.materialForm.controls.weightgsm.errors && this.materialForm.controls.weightgsm.value && this.materialForm.controls.weightgsm.value.length > 20">
          {{staticMsgs.matDetail.gsmAllow}}
        </div>
        <div class="red" *ngIf="this.f.weightgsm.errors?.pattern">
          {{staticMsgs.matDetail.gsmInvalid}}
        </div>
      </div>
      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="fabriccomp">{{staticMsgs.matDetail.fabComp}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="fabriccomp" formControlName="fabriccomp"
          placeholder="Fabric Composition" required />
        <div
          *ngIf="(submitted && this.f.fabriccomp.errors) || ( this.f.fabriccomp.invalid && (this.f.fabriccomp.dirty || this.f.fabriccomp.touched))">
          <div *ngIf="this.f.fabriccomp.errors.required" class="red">
            {{staticMsgs.matDetail.fabCompReq}}
          </div>
          <div class="red"
            *ngIf="this.materialForm.controls.fabriccomp.value && this.materialForm.controls.fabriccomp.value.length > 20">
            {{staticMsgs.matDetail.fabCompAllow}}
          </div>
        </div>
      </div>

    </div>
    <!---Trim classification Start-->
    <div class="form-row" *ngIf="((parentClassification === 'Trims')|| (parentClassification === 'Product Packaging'))">
      <!--<div class="col-sm-4 mb-3">
                            <label for="cuttablewidth">Cuttable Width</label>
                            <input type="text" class="form-control" id="cuttablewidth"
                                formControlName="cuttablewidth" placeholder="Cuttable Width"/>
                        </div>-->
      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="subtype">{{staticMsgs.matDetail.subType}}<span class="red_icon">*</span></label>
        <select type="text" title="Select Sub Type" data-live-search="true" class="form-control selectpicker w-100"
          id="subtype" formControlName="subtype" required>
          <!-- <option value="" [disabled]="true" selected>Select Sub Type
                                </option> -->
          <option value="Button">{{staticMsgs.matDetail.button}}</option>
          <option value="Hook">{{staticMsgs.matDetail.hook}}</option>
          <option value="Zipper">{{staticMsgs.matDetail.zipper}}</option>
          <option value="Lace">{{staticMsgs.matDetail.lace}}</option>
          <option value="Ribbon">{{staticMsgs.matDetail.ribbon}}</option>
          <option value="Elastic">{{staticMsgs.matDetail.elastic}}</option>
          <option value="Velcro">{{staticMsgs.matDetail.velcro}}</option>
          <option value="Eyelet">{{staticMsgs.matDetail.eyelet}}</option>

          <option value="Eyelet Tape">{{staticMsgs.matDetail.eyeletTape}}</option>
          <option value="Price Hangtag">{{staticMsgs.matDetail.price}}</option>
          <option value="Disclaimer">{{staticMsgs.matDetail.disclaimer}}</option>
          <option value="Information Tag">{{staticMsgs.matDetail.infoTag}}</option>
          <option value="Marketing Tag">{{staticMsgs.matDetail.marketTag}}</option>
          <option value="Branding Tag">{{staticMsgs.matDetail.brandTag}}</option>
        </select>
        <div
          *ngIf="(submitted && this.f.subtype.errors) || ( this.f.subtype.invalid && (this.f.subtype.dirty || this.f.subtype.touched))">
          <div *ngIf="this.f.subtype.errors.required" class="red">
            {{staticMsgs.matDetail.subTypeReq}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-4 mt-3 mb-3" *ngIf="(parentClassification === 'Trims')">
        <label for="content">{{staticMsgs.matDetail.content}}</label>
        <select type="text" title="Select Content" class="form-control selectpicker w-100" id="content"
          formControlName="content">
          <option value="" [disabled]="true" selected>{{staticMsgs.matDetail.selectCont}}
          </option>
          <option value="Plastic">{{staticMsgs.matDetail.plastic}}</option>
          <option value="Steel">{{staticMsgs.matDetail.steel}}</option>
          <option value="Brass">{{staticMsgs.matDetail.brass}}</option>
          <option value="Polyster">{{staticMsgs.matDetail.polyster}}</option>
          <option value="Nylon">{{staticMsgs.matDetail.nylon}}</option>
          <option value="Silicon">{{staticMsgs.matDetail.silicon}}</option>
          <option value="SBS">{{staticMsgs.matDetail.sbs}}</option>
          <option value="PU">{{staticMsgs.matDetail.pu}}</option>
          <option value="TPU">{{staticMsgs.matDetail.tpu}}</option>
          <option value="Rubber">{{staticMsgs.matDetail.rubber}}</option>
        </select>
      </div>
      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="sizeuom">{{staticMsgs.matDetail.sizeUOM}}</label>
        <select type="text" title="Select Size UOM" class="form-control selectpicker w-100" id="sizeuom"
          formControlName="sizeuom">
          <!-- <option value="" [disabled]="true" selected>Select Size UOM
                                </option> -->
          <option value="Inch">{{staticMsgs.matDetail.inch}}</option>
          <option value="Meter">{{staticMsgs.matDetail.meter}}</option>
          <option value="cm">{{staticMsgs.matDetail.cm}}</option>
          <option value="mm">{{staticMsgs.matDetail.mm}}</option>
        </select>
      </div>

      <!--<div class="col-sm-4 mb-3">
                            <label for="cost">Cost</label>
                            <input type="text" class="form-control" id="trimcost"
                                formControlName="trimcost" placeholder="Cost"/>
                        </div>-->

      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="size">{{staticMsgs.matDetail.size}}</label>
        <input type="text" class="form-control" id="size" formControlName="size" placeholder="Size"
          (keypress)="numberOnly($event)" (focusout)="addDecimal('size')" />

        <div class="red" *ngIf="this.f.size.errors?.pattern ">
          {{staticMsgs.matDetail.sizeInvalid}}
        </div>
      </div>

      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="width">{{staticMsgs.matDetail.width}}</label>
        <input type="text" class="form-control" id="width" formControlName="width" placeholder="Width"
          (keypress)="numberOnly($event)" (focusout)="addDecimal('width')" />

        <div class="red" *ngIf="this.f.width.errors?.pattern">
          {{staticMsgs.matDetail.widthInvalid}}
        </div>
      </div>

    </div>
    <!---Trim classification End-->

    <!---Leather classification Start-->
    <div class="form-row" *ngIf="(parentClassification === 'Material')">


      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="substabce">{{staticMsgs.matDetail.substance}}</label>
        <input type="text" class="form-control" id="substabce" formControlName="substabce" placeholder="Substance" />
        <div class="red"
          *ngIf="this.materialForm.controls.substabce.errors && this.materialForm.controls.substabce.value && this.materialForm.controls.substabce.value.length > 20">
          {{staticMsgs.matDetail.subAllow}}
        </div>
        <div class="red" *ngIf="this.f.substabce.errors?.pattern">
          {{staticMsgs.matDetail.subInvalid}}
        </div>
      </div>

      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="substanceuom">{{staticMsgs.matDetail.subUOM}}</label>
        <select type="text" title="Select Substance UOM" class="form-control selectpicker w-100" id="substanceuom"
          formControlName="substanceuom">
          <!-- <option value="" [disabled]="true" selected>Select Substance UOM
                                </option> -->
          <option value="Inch">Inch</option>
          <option value="cm">cm</option>
          <option value="mm">mm</option>
        </select>
      </div>

      <div class="col-lg-4 col-sm-4  mt-3 mb-3">
        <label for="rawhide">{{staticMsgs.matDetail.rawHide}}</label>
        <select type="text" title="Select Raw Hide" class="form-control selectpicker w-100" id="rawhide"
          formControlName="rawhide">
          <!-- <option value="" [disabled]="true" selected>Select Raw Hide
                                </option> -->
          <option value="Cow">Cow</option>
          <option value="Camel">Camel</option>
          <option value="Horse">Horse</option>

        </select>
      </div>


      <div class="col-lg-4 col-sm-4 mt-3 mb-3">
        <label for="cost">{{staticMsgs.matDetail.cost}}</label>
        <input type="text" class="form-control" id="cost" (keypress)="numberOnly($event)" formControlName="cost"
          placeholder="Cost" />
        <div class="red"
          *ngIf="this.materialForm.controls.cost.errors && this.materialForm.controls.cost.value && this.materialForm.controls.cost.value.length > 20">
          {{staticMsgs.matDetail.costAllow}}
        </div>
        <div class="red" *ngIf="this.f.cost.errors?.pattern">
          {{staticMsgs.matDetail.costInvalid}}
        </div>
      </div>

    </div>
    <!---Leather classification End-->


    <div *ngIf="materialclassificationValue">

      <div class="form-row">
        <div class="col-sm-12 mt-3 mb-3">
          <label for="comments">{{staticMsgs.matDetail.comment}}</label>
          <textarea cols="2" class="form-control" formControlName="comments" id="comments"
            placeholder="Enter Comments"></textarea>
          <div class="red"
            *ngIf="this.materialForm.controls.comments.errors && this.materialForm.controls.comments.value && this.materialForm.controls.comments.value.length > 500">
            {{staticMsgs.matDetail.commentAllow}}
          </div>
        </div>
      </div>

      <div class="form-row mt-3 mb-3">
        <div class="col-xl-12">
          <label for="thumbnail">{{staticMsgs.matDetail.updImg}}<span class="red_icon">*</span></label>
          <div class="custom-dropzone" id="thumbnail" ngx-dropzone [multiple]="false"
            [accept]="'image/png,image/jpeg,image/jpg'" (change)="onSelectThumbnail($event,0)">
            <ngx-dropzone-label>
              <div>
                <h5 class="text-light-gray mt-3">&nbsp;
                  {{staticMsgs.matDetail.dropImg}} &nbsp; </h5>

              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileData[0]" [file]="f"
              [removable]="true" (removed)="onRemoveThumbnail(f)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
              </ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </div>
          <div *ngIf="isFileError" class="red">
            {{staticMsgs.matDetail.imgReq}}
          </div>
        </div>

        <div class="col-xl-6 mt-6" *ngIf="this.f.materialid.value">
          <div *ngFor="let editedThumbnail of editedThumbnails; let j=index">
            <img src="{{editedThumbnail.thumbnail}}" width="250px" *ngIf="isValidImageURL(editedThumbnail.name)"
              (click)="openThumbnail(j)">
            <button type="button" *ngIf="editedThumbnail.thumbnail" class="close image-close"
              (click)="removeThumbnailSection(editedThumbnail)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>

    </div>

  

  <div class="d-flex justify-content-end mt-4">
    <input type="text" class="form-control" [hidden]="true" id="materialid" formControlName="materialid"
      placeholder="Color Id" />
    <!-- <button type="button" *ngIf="!isEditMode" (click)="cancelMaterialCreate()" class="btn btn-outline-primary adv-search-btn mr-3">
                                  Cancel
                                </button>
                          <button type="submit" *ngIf="!isEditMode"
                              class="btn btn-primary adv-search-btn">Create</button> -->

    <div class="row mt-3">
      <button type="button" *ngIf="isEditMode" (click)="cancelMaterialCreate()"
        class="btn btn-outline-primary adv-search-btn">
        {{staticMsgs.matDetail.cancel}}

      </button>
      <button type="submit" *ngIf="!isEditMode" [disabled]="materialForm?.invalid || !isThumbnailAdded"
        class="btn btn-primary adv-search-btn">{{staticMsgs.matDetail.create}}</button>

      <button type="submit" *ngIf="isEditMode" [disabled]="materialForm?.invalid || !isThumbnailAdded"
        class="btn btn-primary adv-search-btn mr-3">{{staticMsgs.matDetail.update}}</button>
    </div>
  </div>
</form>
</mat-dialog-content>