import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnyMxRecord } from 'dns';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {

  constructor(private http: HttpClient,) { }

  oAuthGoogleAlbumPhotos(oauthToken: any){
    const apiUrl = 'https://photoslibrary.googleapis.com/v1/albums'
    return this.http.get(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauthToken
      }),
      observe: 'response'
    }).pipe(
      catchError(err => {
        return throwError(err)
      })
    );
  }
  
  oAuthGoogleMediaPhotos(oauthToken: any){
    const apiUrl = 'https://photoslibrary.googleapis.com/v1/mediaItems'
    return this.http.get(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauthToken
      }),
      observe: 'response'
    }).pipe(
      catchError(err => {
        return throwError(err)
      })
    );
  }

  oAuthGoogleAlbumMediaPhotos(oauthToken: any, albumId: any){
    const apiUrl = `https://photoslibrary.googleapis.com/v1/mediaItems:search`
    return this.http.post(apiUrl,{'albumId':albumId}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauthToken
        
      }),
      observe: 'response'
    }).pipe(
      catchError(err => {
        return throwError(err)
      })
    );
  }
 
}
