<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">
      <!-- Site Content -->
      <div class="dt-content">
          <div class="row">
              <!-- Page Header -->
              <div class="col-xl-4 col-sm-4 col-md-4">
                  <div class="dt-page__header">
                      <h3 class="dt-page__title" *ngIf="!isEditMode">{{staticMsgs.colorDetail.createColor}}</h3>
                      <h3 class="dt-page__title" *ngIf="isEditMode">{{staticMsgs.colorDetail.colorDetail}}</h3>
                      <nav class="mb-0 breadcrumb">
                          <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/dashboard"><i class="fa fa-home"></i></a>
                          <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/colorlibrary">
                            <span class="active breadcrumb-item" *ngIf="isEditMode || !isEditMode">{{staticMsgs.colorLib.colLib}}</span>
                          </a>
                          <span class="active breadcrumb-item" *ngIf="!isEditMode"> {{staticMsgs.colorDetail.createColor}}</span>
                          <span class="active breadcrumb-item" *ngIf="isEditMode"> {{staticMsgs.colorDetail.colorDetail}} / {{ sequenceNumber}}</span>
                      </nav>
                  </div>
              </div>
              <div class="col-xl-4 col-sm-4 col-md-4 d-flex justify-content-center" *ngIf="isEditMode">
                  <ul class="dt-nav">
                    <button  *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" (click)="editColour()"
                      ngbTooltip="Edit Color">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                    <button  *ngIf="isEditingMode" type="button" [ngClass]="isEditingMode ? 'icon-active' : 'btn-color-primary'" class="btn mr-3 round" (click)="cancelColourEdit()"
                      ngbTooltip="Cancel Edit Color">
                      <i class="fa fa-times text-white" aria-hidden="true"></i>
                    </button>
                    <button  *ngIf="!isEditingMode" type="button" class="btn mr-3 btn-color-primary round" (click)="onDeleteColor()"
                    ngbTooltip="Delete">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                    <!-- <button type="button" class="btn mr-3 btn-color-primary round"
                      *ngIf="!isEditingMode" ngbTooltip="Discussion">
                      <i class="fa fa-comments" aria-hidden="true"></i>
                    </button> -->
                    <!-- <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal"
                      data-target="#small-modal" ngbTooltip="Generate Techpack">
                      <i class="fas fa-tshirt" aria-hidden="true"></i>
                    </button> -->
                  </ul>
                </div>
                <div class="col-xl-4 col-sm-4 col-md-4" *ngIf="isEditMode">
                  <ul class="dt-nav">
                      <!-- <li class="dt-nav__item">
                          <i class="fa fa-pencil-square-o ul-icon" aria-hidden="true"></i>
                      </li>
                      <li class="dt-nav__item">
                          <i class="fa fa-star-o ul-icon" aria-hidden="true"></i>
                      </li>
                      <li class="dt-nav__item">
                          <i class="fa fa-share-alt ul-icon" aria-hidden="true"></i>
                      </li>
                      <li class="dt-nav__item">
                          <i class="fa fa-trash-o ul-icon" aria-hidden="true"></i>
                      </li> -->
                  </ul>
                </div>

                <!-- <div class="col-xl-4 col-sm-4 col-md-4" *ngIf="isEditMode">
                  <ul class="dt-nav">


                    <button type="button" class="btn mr-3 btn-color-primary round" data-toggle="modal" data-target="#long-modal"
                      ngbTooltip="Share">
                      <i class="fa fa-user-plus" aria-hidden="true" ></i>
                    </button>

                  </ul>
                </div> -->
          </div>
          <!-- /page header -->


          <div class="profile-content">
              <!-- Create Color Div End -->
              <!-- Grid -->
              <div class="row">
                   <!-- Grid Item -->

                   <div class="wd-300" *ngIf="isViewingMode">
                      <!-- <ng-image-slider [images]="imageObject" >
                        </ng-image-slider> -->

                        <div class="">
                          <!-- Card -->
                          <div class="card border-l-r">
                              <div *ngIf="this.f.colorhexacode.value && (colorclassificationValue === 'Solid' || colorclassificationValue === 'Custom' )"
                                  class="card-view-img"
                                  [ngStyle]="{'background-color': this.f.colorhexacode.value}"
                                  alt="">
                                  <!-- <p>{{this.f.colorhexacode.value}}</p> -->
                              </div>
                              <img *ngIf="!this.f.colorhexacode.value && (colorclassificationValue === 'Print and Pattern') && editedThumbnailValue"
                                  class="card-view-img" alt=""
                                  src={{editedThumbnailValue}}>
                              <img *ngIf="!this.f.colorhexacode.value  && (colorclassificationValue != 'Print and Pattern')"
                                  class="card-view-img" alt="">
                              <!-- Card Body -->
                              <div class="">
                              </div>
                          </div>
                      </div>
                        <div class="accordion mt-5" id="accordion-example">
                          <div class="card" *ngIf="editedColorData">
                            <div class="" id="headingOne">
                              <h5 class="mb-0">
                                <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                                  data-target="#collapse-one" aria-expanded="false" aria-controls="collapse-one">
                                  {{staticMsgs.colorDetail.colorInfor}}
                                  <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                                </h4>
                              </h5>
                            </div>

                            <div id="collapse-one" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-example">
                              <div class="card-body bg-white p-4" *ngIf="editedColorData">
                                <div class="row">

                                  <div class="col-md-5 col-sm-5 col-xl-5">
                                    <p class="card_text">{{staticMsgs.colorDetail.name}}</p>
                                  </div>
                                  <div class="col-md-7 col-sm-7 col-xl-7">
                                    <p class="a-font">{{editedColorData.name}} </p>
                                  </div>
                                </div>
                                <div class="row">

                                  <div class="col-md-5 col-sm-5 col-xl-5">
                                    <p class="card_text">{{staticMsgs.colorDetail.type}}</p>
                                  </div>
                                  <div class="col-md-7 col-sm-7 col-xl-7">
                                    <p class="a-font">{{editedColorData.classification}} </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                           <div class="card" *ngIf="editedColorData">
                            <div class="" id="headingTwo">
                              <h5 class="mb-0">
                                <h4 class="card-header text-primary custom-cardheader" data-toggle="collapse"
                                  data-target="#collapse-two" aria-expanded="false" aria-controls="collapse-one">
                                  {{staticMsgs.colorDetail.userInfo}}
                                  <span class="float-right"><i class="fa fa-chevron-down"></i></span>
                                </h4>
                              </h5>
                            </div>
                            <div id="collapse-two" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion-example">
                              <div class="card-body bg-white p-4" *ngIf="editedColorData.createdBy">
                                <div class="row">

                                  <div class="col-md-5 col-sm-5 col-xl-5">
                                    <p class="card_text">{{staticMsgs.colorDetail.createdby}}:</p>
                                  </div>
                                  <div class="col-md-7 col-sm-7 col-xl-7">
                                    <p class="a-font">{{editedColorData.createdBy.firstName}}</p>
                                  </div>
                                </div>
                                <div class="row">

                                  <div class="col-md-5 col-sm-5 col-xl-5">
                                    <p class="card_text">{{staticMsgs.colorDetail.createdon}}</p>
                                  </div>
                                  <div class="col-md-7 col-sm-7 col-xl-7">
                                    <p class="a-font">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                      {{editedColorData.createdOn |date: "dd MMM yyyy"}}
                                    </p>
                                    <p class="a-font">
                                      <i class="fa fa-clock-o" aria-hidden="true"></i>
                                      {{editedColorData.createdOn |date:"hh:mm a"}}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                   <!-- Gridk Item -->
                  <!-- Grid Item -->
                  <div [ngClass]="isViewingMode ? 'wdcacl-300' : 'w-100'" *ngIf="isViewingMode || isEditingMode">
                   <div class="order-xl-1">
                      <!-- Card -->
                      <div class="card-shadow-none mr-3">
                          <!-- Card Header -->
                          <div class="card-header card-nav border-bottom d-sm-flex justify-content-sm-between" *ngIf="!isCreateMode">
                              <!-- <h3 class="mb-2 mb-sm-n5">About</h3> -->
                              <ul class="card-header-links nav nav-underline" role="tablist">
                                  <li class="nav-item">
                                      <a *ngIf="activeTab !='colorDetailsTab'" class="nav-link active"
                                          data-toggle="tab" href="#tab-pane1"
                                          [ngClass]="{ 'active':activeTab === 'colorDetailsTab' }"
                                          (click)="colorDetailsTab('colorDetailsTab')" role="tab"
                                          aria-controls="tab-pane1" aria-selected="true">{{staticMsgs.colorDetail.colorDetail}}</a>
                                      <a *ngIf="activeTab =='colorDetailsTab'" class="nav-link active"
                                          data-toggle="tab" href="#tab-pane1"
                                          [ngClass]="{ 'active':activeTab === 'colorDetailsTab' }" role="tab"
                                          aria-controls="tab-pane1" aria-selected="true">{{staticMsgs.colorDetail.colorDetail}}</a>
                                  </li>
                                  <li class="nav-item" *ngIf="isEditMode">
                                      <a *ngIf="activeTab !='docDetailsTab'" class="nav-link" data-toggle="tab"
                                          href="#tab-pane2" [ngClass]="{ 'active':activeTab === 'docDetailsTab' }"
                                          (click)="docDetailsTab('docDetailsTab')" role="tab"
                                          aria-controls="tab-pane2" aria-selected="true">{{staticMsgs.colorDetail.document}}</a>
                                      <a *ngIf="activeTab =='docDetailsTab'" class="nav-link" data-toggle="tab"
                                          href="#tab-pane2" [ngClass]="{ 'active':activeTab === 'docDetailsTab' }"
                                          role="tab" aria-controls="tab-pane2" aria-selected="true">{{staticMsgs.colorDetail.document}}</a>
                                  </li>
                              </ul>

                              <div class="dropdown" *ngIf="!isViewingMode">
                                  <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown"
                                      aria-haspopup="true" aria-expanded="false">
                                      <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                                  </a> -->
                              </div>

                              <div class="dropdown" *ngIf="isViewingMode">

                                  <!-- <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown"
                                      aria-haspopup="true" aria-expanded="false">
                                      <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                                  </a> -->

                                  <div class="dropdown-menu dropdown-menu-right">
                                      <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="editColour()">
                                          <i class="icon icon-editors icon-fw"></i>
                                          Edit
                                      </a> -->
                                      <!-- <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="icon icon-trash icon-fw"></i>
                                          Delete
                                      </a>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="icon icon-addnew icon-fw"></i>
                                          Create New
                                      </a>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="fa fa-files-o mr-1" aria-hidden="true"></i>
                                          Copy
                                      </a>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="fa fa-floppy-o mr-1" aria-hidden="true"></i>
                                          Save New Version
                                      </a>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                          <i class="fa fa-thumb-tack mr-1" aria-hidden="true"></i>
                                          Mark As Basline Version
                                      </a> -->
                                  </div>
                              </div>
                          </div>
                          <!-- /card header -->
                          <!-- Card Body -->
                          <div class="pb-2 mt-3">
                              <!-- Tab Content-->
                              <div class="tab-content">
                                  <!-- Tab panel -->
                                  <div id="tab-pane1" class="tab-pane card-body card-body-shadow bg-white active"
                                      [ngClass]="{ 'active':activeTab==='colorDetailsTab'}">
                                      <div *ngIf="isEditingMode">
                                          <form [formGroup]="colorForm" (ngSubmit)="onSubmit()">
                                              <!-- <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div> -->
                                              <div class="form-row">

                                                  <div class="col-lg-3 col-sm-12">
                                                      <label for="colorclassification">{{staticMsgs.colorDetail.selectClass}}<span
                                                              class="red_icon">*</span></label>
                                                      <input type="text" class="form-control" id="colorclassification"
                                                          formControlName="colorclassification"
                                                          placeholder="Classification" required />
                                                      <div
                                                          *ngIf="(submitted && this.f.colorclassification.errors) || ( this.f.colorclassification.invalid && (this.f.colorclassification.dirty || this.f.colorclassification.touched))">
                                                          <div *ngIf="this.f.colorclassification.errors.required"
                                                              class="red">
                                                              {{staticMsgs.colorDetail.classReq}}
                                                          </div>
                                                      </div>
                                                  </div>

                                                  <div class="col-sm-3 mb-3" *ngIf="isEditMode">
                                                      <label for="colorseq">{{staticMsgs.colorDetail.color1}}</label>

                                                      <input type="text" class="form-control" [readonly]="true"
                                                          id="colorseq" formControlName="colorseq"
                                                          placeholder="Color Id" />
                                                  </div>

                                                <div class="col-sm-3 mb-3">
                                                    <label for="colorname">{{staticMsgs.colorDetail.name1}}<span
                                                            class="red_icon">*</span></label>
                                                    <input type="text" class="form-control" id="colorname" minlength="3"
                                                       maxlength="50" formControlName="colorname" placeholder="Color Name"
                                                        required (keypress)="characterOnly($event)" />
                                                    <div
                                                        *ngIf="(submitted && this.f.colorname.errors) || ( this.f.colorname.invalid && (this.f.colorname.dirty || this.f.colorname.touched))">
                                                        <div *ngIf="this.f.colorname.errors.required" class="red">
                                                          {{staticMsgs.colorDetail.colorReq}}
                                                        </div>
                                                        <div *ngIf="this.f.colorname.errors.minlength" class="red">
                                                          {{staticMsgs.common.minimum3charactersrequired}}
                                                           </div>

                                                    </div>
                                                </div>
                                                <div class="col-sm-3 mb-3">
                                                    <label for="status">{{staticMsgs.colorDetail.status}}<span
                                                            class="red_icon">*</span></label>
                                                    <select type="text" title="Select Status"
                                                        class="form-control selectpicker w-100" id="status"
                                                        formControlName="status" required>
                                                        <!-- <option value="" [disabled]="true" selected>Select Status -->
                                                        <!-- </option> -->
                                                        <option value="Active">{{staticMsgs.colorDetail.active}}</option>
                                                        <option value="Inactive">{{staticMsgs.colorDetail.inactive}}</option>
                                                    </select>

                                                      <!-- <label for="status">Status<span
                                                          class="red_icon">*</span></label>
                                                  <select type="text" title="Select Status" class="form-control"
                                                      id="status" formControlName="status"
                                                      required>

                                                      <option value="Active">Active</option>
                                                      <option value="Inactive">Inactive</option>
                                                  </select> -->
                                                      <div
                                                          *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
                                                          <div *ngIf="this.f.status.errors.required" class="red">
                                                            {{staticMsgs.colorDetail.statusReq}}
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-3 mb-3">
                                                      <label for="internalref">{{staticMsgs.colorDetail.internal}}<span
                                                        class="red_icon">*</span></label>
                                                      <input type="text" class="form-control" id="internalref"
                                                          formControlName="internalref" placeholder="Internal Ref #"
                                                          required />
                                                      <div
                                                          *ngIf="(submitted && this.f.internalref.errors) || ( this.f.internalref.invalid && (this.f.internalref.dirty || this.f.internalref.touched))">
                                                          <div *ngIf="this.f.internalref.errors.required" class="red">
                                                            {{staticMsgs.colorDetail.internalReq}}
                                                          </div>
                                                          <div *ngIf="this.f.internalref.errors.pattern" class="red">
                                                            {{staticMsgs.colorDetail.internalValid}}
                                                          </div>
                                                          <div *ngIf="this.colorForm.controls.internalref.errors.required && this.colorForm.controls.internalref.value.length > 20"
                                                              class="red">
                                                              {{staticMsgs.colorDetail.internalAllow}}
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-3 mb-3">
                                                    <label for="description">{{staticMsgs.colorDetail.desc}}<span
                                                            class="red_icon">*</span></label>
                                                    <textarea cols="2" class="form-control" id="description"
                                                        formControlName="description" required></textarea>
                                                    <div
                                                        *ngIf="(submitted && this.f.description.errors) || ( this.f.description.invalid && (this.f.description.dirty || this.f.description.touched))">
                                                        <div *ngIf="this.f.description.errors.required" class="red">
                                                          {{staticMsgs.colorDetail.decReq}}
                                                        </div>
                                                        <div *ngIf="this.colorForm.controls.description.value && this.colorForm.controls.description.value.length > 500"
                                                            class="red" >
                                                            {{staticMsgs.colorDetail.descAllow}}
                                                        </div>
                                                    </div>
                                                </div>

                                              </div>

                                              <div class="dt-entry__header mt-5" *ngIf="colorclassificationValue">
                                                  <!-- Entry Heading -->
                                                  <div class="dt-entry__heading">
                                                      <h3 class="dt-entry__title">{{colorclassificationValue}}</h3>
                                                  </div>
                                                  <!-- /entry heading -->
                                              </div>

                                              <div class="form-row mt-2" *ngIf="colorclassificationValue">
                                                  <div class="col-sm-3 mb-3"
                                                      *ngIf="colorclassificationValue != 'Print and Pattern'">
                                                      <color-sketch [color]="color" (onChange)="handleChange($event)">
                                                      </color-sketch>
                                                  </div>

                                                  <div class="col-sm-3 mb-3"
                                                      *ngIf="colorclassificationValue != 'Print and Pattern'">
                                                      <div>
                                                          <label for="colorhexacode">{{staticMsgs.colorDetail.hexcode}}<span
                                                                  class="red_icon">*</span></label>
                                                          <input type="text" class="form-control" [readonly]="true"
                                                              id="colorhexacode" formControlName="colorhexacode"
                                                              placeholder="Color Hexcode" required />
                                                          <div
                                                              *ngIf="(submitted && this.f.colorhexacode.errors) || ( this.f.colorhexacode.invalid && (this.f.colorhexacode.dirty || this.f.colorhexacode.touched))">
                                                              <div *ngIf="this.f.colorhexacode.errors.required"
                                                                  class="red">
                                                                  {{staticMsgs.colorDetail.hexcodeReq}}
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <div class="color-section text-center mt-4"
                                                          [ngStyle]="{'background-color': colorbg}">
                                                          <p>{{colorHex}}</p>
                                                      </div>
                                                  </div>

                                                  <div class="col-sm-3 mb-3"
                                                      *ngIf="colorclassificationValue === 'Solid'">
                                                      <label for="colorstandard">{{staticMsgs.colorDetail.colorStd}}<span
                                                              class="red_icon">*</span></label>
                                                      <select title="Select Color Standard"
                                                          formControlName="colorstandard"
                                                          class="form-control selectpicker w-100"
                                                          (ngModelChange)="onSelect(selected)" data-max-options="1">
                                                          <option *ngFor="let color of options" [value]="color.value">
                                                              {{ color.label }}</option>
                                                      </select>
                                                      <div
                                                          *ngIf="(submitted && this.f.colorstandard.errors) || ( this.f.colorstandard.invalid && (this.f.colorstandard.dirty || this.f.colorstandard.touched))">
                                                          <div *ngIf="this.f.colorstandard.errors.required"
                                                              class="red">
                                                              {{staticMsgs.colorDetail.colorStdReq}}
                                                          </div>
                                                      </div>

                                                      <div class="mt-4">
                                                          <label for="pantone">{{staticMsgs.colorDetail.pantone}}</label>
                                                          <input type="text" class="form-control" id="pantone"
                                                              formControlName="pantone" placeholder="Pantone"
                                                               />
                                                          <!-- <div
                                                              *ngIf="(submitted && this.f.pantone.errors) || ( this.f.pantone.invalid && (this.f.pantone.dirty || this.f.pantone.touched))">
                                                              <div *ngIf="this.f.pantone.errors.required" class="red">
                                                                {{staticMsgs.colorDetail.pantoneReq}}
                                                              </div>
                                                              <div *ngIf="this.f.pantone.errors.pattern" class="red">
                                                                {{staticMsgs.colorDetail.pantoneInvalid}}
                                                              </div>
                                                          </div> -->
                                                      </div>
                                                  </div>

                                                  <!-- <div class="col-sm-4 mb-3" *ngIf="colorclassificationValue === 'Print and Pattern'">
                                                  <label for="thumbnail">Thumbnail<span
                                                      class="red_icon">*</span></label>
                                              </div> -->


                                              </div>

                                              <div class="form-row mt-2"
                                                  *ngIf="(colorclassificationValue &&(colorclassificationValue === 'Print and Pattern'))">
                                                  <div class="col-xl-12">
                                                      <label for="thumbnail">{{staticMsgs.colorDetail.uploadImg}}<span
                                                              class="red_icon">*</span></label>
                                                      <div class="custom-dropzone" id="thumbnail" ngx-dropzone
                                                          [multiple]="false"
                                                          [accept]="'image/png,image/jpeg,image/jpg'"
                                                          (change)="onSelectThumbnail($event)">
                                                          <ngx-dropzone-label>
                                                              <div>
                                                                  <h5 class="text-light-gray mt-3">&nbsp;
                                                                    {{staticMsgs.colorDetail.dropThumb}}
                                                                   &nbsp; </h5>
                                                              </div>
                                                          </ngx-dropzone-label>
                                                          <ngx-dropzone-image-preview
                                                              ngProjectAs="ngx-dropzone-preview"
                                                              *ngFor="let f of thumbNails" [file]="f"
                                                              [removable]="true" (removed)="onRemoveThumbnail(f)">
                                                              <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                                                          </ngx-dropzone-image-preview>

                                                      </div>
                                                      <div *ngIf="!isThumbnailAdded" class="red">
                                                        {{staticMsgs.colorDetail.imgReq}}
                                                      </div>
                                                  </div>

                                                  <div class="col-xl-6 mt-6" *ngIf="this.f.colorid.value">
                                                      <div class="col-xl-6 mb-5"
                                                          *ngFor="let editedThumbnail of editedThumbnails; let j=index">
                                                          <img src="{{editedThumbnail.thumbnail}}"
                                                              class="img-fluid  img-fit "
                                                              *ngIf="isValidImageURL(editedThumbnail.name)"
                                                              (click)="openThumbnail(j)">
                                                          <button type="button" class="close image-close"
                                                              (click)="removeThumbnailSection(editedThumbnail)">
                                                              <span aria-hidden="true">×</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="form-row mt-10 float-right">
                                                  <div class="row mt-3">
                                                  <input type="text" class="form-control" [hidden]="true" id="colorid"
                                                      formControlName="colorid" placeholder="Color Id" />
                                                      <button type="button" *ngIf="!isEditMode"
                                                      (click)="cancelColorCreate()"
                                                     class="btn btn-outline-primary adv-search-btn mr-3">{{staticMsgs.colorDetail.cancel}}</button>
                                                  <button type="submit" *ngIf="!isEditMode" [disabled]="colorForm?.invalid"
                                                      class="btn btn-primary adv-search-btn">{{staticMsgs.colorDetail.create}}</button>
                                                  <button type="submit" *ngIf="isEditMode"
                                                      class="btn btn-primary adv-search-btn">{{staticMsgs.colorDetail.update}}</button>
                                                  </div>
                                              </div>

                                          </form>
                                      </div>

                                      <div *ngIf="isViewingMode">
                                          <div class="dt-entry__header mt-5">
                                              <!-- Entry Heading -->
                                              <div class="dt-entry__heading">
                                                  <h3 class="dt-entry__title">{{colorclassificationValue}}</h3>
                                              </div>
                                              <!-- /entry heading -->
                                          </div>

                                          <form>
                                              <div class="form-row">
                                                  <div class="col-lg-3 col-sm-3">
                                                      <!-- Card -->
                                                      <div class="card border-l-r">
                                                          <div *ngIf="this.f.colorhexacode.value && (colorclassificationValue === 'Solid' || colorclassificationValue === 'Custom' )"
                                                              class="card-view-img"
                                                              [ngStyle]="{'background-color': this.f.colorhexacode.value}"
                                                              alt="">
                                                              <!-- <p>{{this.f.colorhexacode.value}}</p> -->
                                                          </div>
                                                          <img *ngIf="!this.f.colorhexacode.value && (colorclassificationValue === 'Print and Pattern') && editedThumbnailValue"
                                                              class="card-view-img" alt=""
                                                              src={{editedThumbnailValue}}>
                                                          <img *ngIf="!this.f.colorhexacode.value  && (colorclassificationValue != 'Print and Pattern')"
                                                              class="card-view-img" alt="">
                                                          <!-- Card Body -->
                                                          <div class="">
                                                          </div>
                                                      </div>
                                                  </div>

                                                  <div class="col-lg-9 col-sm-9">
                                                      <div class="form-row pl-5">
                                                          <div class="col-sm-3 mb-3">
                                                              <label for="this.f.colorseq.value"
                                                                  class="label-view">{{staticMsgs.colorDetail.color1}}</label>
                                                              <p class="mt-2 viewtext">{{this.f.colorseq.value}}</p>
                                                          </div>

                                                          <div class="col-sm-3 mb-3">
                                                              <label for="this.f.colorname.value"
                                                                  class="label-view">{{staticMsgs.colorDetail.name1}}</label>
                                                              <p class="mt-2 viewtext">{{this.f.colorname.value}}</p>
                                                          </div>

                                                          <!-- <div class="col-sm-4 mb-3">
                                                              <label
                                                                  for="colorclassificationValue">Classification</label>
                                                              <p class="mt-2 viewtext">{{colorclassificationValue}}
                                                              </p>
                                                          </div> -->

                                                          <div class="col-sm-3 mb-3">
                                                              <label for="this.f.internalref.value"
                                                                  class="label-view">{{staticMsgs.colorDetail.internal}}
                                                                  </label>
                                                              <p class="mt-2 viewtext">{{this.f.internalref.value}}
                                                              </p>
                                                          </div>

                                                          <div class="col-sm-3 mb-3">
                                                              <label for="this.f.status.value"
                                                                  class="label-view">{{staticMsgs.colorDetail.status}}</label>
                                                              <p class="mt-2 viewtext">{{this.f.status.value |
                                                                  titlecase}}</p>
                                                          </div>

                                                          <div class="col-sm-3 mb-3"
                                                              *ngIf="colorclassificationValue != 'Print and Pattern'">
                                                              <label for="this.f.colorhexacode.value"
                                                                  class="label-view">{{staticMsgs.colorDetail.hexcode}}</label>
                                                              <p class="mt-2 viewtext">{{this.f.colorhexacode.value}}
                                                              </p>
                                                          </div>

                                                          <div class="col-sm-3 mb-3"
                                                              *ngIf="colorclassificationValue === 'Solid'">
                                                              <label for="this.f.colorstandard.value"
                                                                  class="label-view">{{staticMsgs.colorDetail.colorStd}}</label>
                                                              <p class="mt-2 viewtext">{{this.f.colorstandard.value}}
                                                              </p>
                                                          </div>

                                                          <div class="col-sm-3 mb-3"
                                                              *ngIf="colorclassificationValue === 'Solid'">
                                                              <label for="this.f.pantone.value"
                                                                  class="label-view">{{staticMsgs.colorDetail.pantone}}</label>
                                                              <p class="mt-2 viewtext">{{this.f.pantone.value}}</p>
                                                          </div>

                                                          <div class="col-sm-8 mb-3">
                                                              <label for="this.f.description.value"
                                                                  class="label-view">{{staticMsgs.colorDetail.desc}}</label>
                                                              <p class="mt-2 viewtext">{{this.f.description.value}}
                                                              </p>
                                                          </div>

                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                                  </div>

                                  <!-- /tab panel -->
                                  <!-- Tab panel -->
                                  <div id="tab-pane2" class="tab-pane card-body card-body-shadow bg-white"
                                      [ngClass]="{ 'active':activeTab === 'docDetailsTab'}">
                                      <div *ngIf="isEditingMode">
                                          <form [formGroup]="documentsForm" (ngSubmit)="onDocSubmit()">
                                              <div formArrayName="documents">
                                                  <div *ngFor="let document of getControls(); let i=index">
                                                      <div class="card p-5" [formGroupName]="i">
                                                          <div class="form-row ">
                                                              <div class="col-sm-12 float-right">
                                                                  <button type="button" class="close"
                                                                      (click)="removeDoc(i)"><span
                                                                          aria-hidden="true">×</span></button>
                                                              </div>
                                                          </div>

                                                          <div class="form-row">

                                                              <div class="col-lg-4 col-sm-12 mb-3">
                                                                  <label for="docclassification">{{staticMsgs.colorDetail.selectClass}}<span
                                                                          class="red_icon">*</span></label>
                                                                  <input type="text" class="form-control"
                                                                      id="docclassification{{i}}"
                                                                      formControlName="docclassification"
                                                                      placeholder="Classification" required />
                                                                  <div
                                                                      *ngIf="(submitted1 && document.get('docclassification').errors) || ( document.get('docclassification').invalid && (document.get('docclassification').dirty || document.get('docclassification').touched))">
                                                                      <div *ngIf="document.get('docclassification').errors.required"
                                                                          class="red">
                                                                          {{staticMsgs.colorDetail.classReq}}
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div class="col-sm-4 mb-3"
                                                                  *ngIf="document.get('docid').value">
                                                                  <label for="docseq">{{staticMsgs.colorDetail.document1}}</label>
                                                                  <input type="text" class="form-control"
                                                                      [readonly]="true" id="docseq"
                                                                      formControlName="docseq"
                                                                      placeholder="Document Id" />
                                                              </div>

                                                              <div class="col-lg-4 col-sm-12">
                                                                  <label for="docname">{{staticMsgs.colorDetail.name1}}<span
                                                                          class="red_icon">*</span></label>
                                                                  <input type="text" class="form-control" id="docname"
                                                                      formControlName="docname" maxlength="50" minlength="3"
                                                                      placeholder="Document Name" (keypress)="characterOnly($event)" required />
                                                                  <div
                                                                      *ngIf="(submitted1 && document.get('docname').errors) || ( document.get('docname').invalid && (document.get('docname').dirty || document.get('docname').touched))">
                                                                      <div *ngIf="document.get('docname').errors.required"
                                                                          class="red">
                                                                          {{staticMsgs.colorDetail.colorReq}}
                                                                      </div>
                                                                      <div *ngIf="document.get('docname').errors.minlength" class="red">
                                                                        {{staticMsgs.common.minimum3charactersrequired}}
                                                                       </div>
                                                                  </div>
                                                              </div>

                                                          </div>

                                                          <div class="form-row">
                                                              <div class="col-sm-4 mt-3 mb-3">
                                                                  <label for="docstatus">{{staticMsgs.colorDetail.status}}<span
                                                                          class="red_icon">*</span></label>
                                                                  <select type="text"
                                                                      class="form-control selectpicker" id="docstatus"
                                                                      formControlName="docstatus" required>
                                                                      <!-- <option value="" [disabled]="true" selected>
                                                                        {{staticMsgs.colorDetail.selectStatus}}
                                                                      </option> -->
                                                                      <option value="Active">{{staticMsgs.colorDetail.active}}</option>
                                                                      <option value="Inactive">{{staticMsgs.colorDetail.inactive}}</option>
                                                                  </select>
                                                                  <div
                                                                      *ngIf="(submitted1 && document.get('docstatus').errors) || ( document.get('docstatus').invalid && (document.get('docstatus').dirty || document.get('docstatus').touched))">
                                                                      <div *ngIf="document.get('docstatus').errors.required"
                                                                          class="red">
                                                                          {{staticMsgs.colorDetail.statusReq}}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              <div class="col-sm-4 mt-3 mb-3">
                                                                  <label for="docdescription">{{staticMsgs.colorDetail.desc}}<span
                                                                          class="red_icon">*</span></label>
                                                                  <textarea cols="2" class="form-control"
                                                                      id="docdescription"
                                                                      formControlName="docdescription"
                                                                      required></textarea>
                                                                  <div
                                                                      *ngIf="(submitted1 && document.get('docdescription').errors) || ( document.get('docdescription').invalid && (document.get('docdescription').dirty || document.get('docdescription').touched))">
                                                                      <div *ngIf="document.get('docdescription').errors.required"
                                                                          class="red">
                                                                          {{staticMsgs.colorDetail.decReq}}
                                                                      </div>
                                                                      <div *ngIf="document.get('docdescription').value && document.get('docdescription').value.length > 500"
                                                                          class="red">
                                                                          {{staticMsgs.colorDetail.descAllow}}
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                          </div>

                                                          <div class="dt-entry__header mt-5"
                                                              *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                                              <div class="dt-entry__heading">
                                                                  <h3 class="dt-entry__title">
                                                                      {{document.get('docclassification').value}}</h3>
                                                              </div>
                                                          </div>

                                                          <div class="form-row"
                                                              *ngIf="document.get('docclassification').value === 'Lab Reports'">

                                                              <div class="col-sm-4 mb-3">
                                                                  <label for="docreportstatus">{{staticMsgs.colorDetail.reportStatus}}<span
                                                                          class="red_icon">*</span></label>
                                                                  <select type="text"
                                                                      class="form-control selectpicker"
                                                                      id="docreportstatus"
                                                                      formControlName="docreportstatus" required>
                                                                      <option value="" [disabled]="true" selected>
                                                                        {{staticMsgs.colorDetail.selectReport}}
                                                                      </option>
                                                                      <option value="Pass">{{staticMsgs.colorDetail.pass}}</option>
                                                                      <option value="Fail">{{staticMsgs.colorDetail.fail}}</option>
                                                                  </select>
                                                                  <div
                                                                      *ngIf="(submitted1 && document.get('docreportstatus').errors) || ( document.get('docreportstatus').invalid && (document.get('docreportstatus').dirty || document.get('docreportstatus').touched))">
                                                                      <div *ngIf="document.get('docreportstatus').errors.required"
                                                                          class="red">
                                                                          {{staticMsgs.colorDetail.reportReq}}
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div class="col-sm-4 mb-3">
                                                                  <label for="doclablocation">{{staticMsgs.colorDetail.labLoc}}<span
                                                                          class="red_icon">*</span></label>
                                                                  <input type="text" class="form-control"
                                                                      id="doclablocation"
                                                                      formControlName="doclablocation"
                                                                      placeholder="Lab Location" maxlength="25" required />
                                                                  <div
                                                                      *ngIf="(submitted1 && document.get('doclablocation').errors) || ( document.get('doclablocation').invalid && (document.get('doclablocation').dirty || document.get('doclablocation').touched))">
                                                                      <div *ngIf="document.get('doclablocation').errors.required"
                                                                          class="red">
                                                                         Lab location is required
                                                                      </div>
                                                                      <div *ngIf="this.documentsForm.controls.doclablocation.value && this.documentsForm.controls.doclablocation.value.length > 50"
                                                                          class="red">
                                                                          {{staticMsgs.colorDetail.labAllow}}
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                          </div>

                                                          <div class="dt-entry__header mt-5">
                                                              <div class="dt-entry__heading">
                                                                  <h3 class="dt-entry__title">{{staticMsgs.colorDetail.document}}</h3>
                                                              </div>
                                                          </div>


                                                          <div class="form-row" [hidden]="true">
                                                              <input type="text" class="form-control"
                                                                  id="docdeletedfileids"
                                                                  formControlName="docdeletedfileids"
                                                                  placeholder="Deleted File Ids">
                                                          </div>

                                                          <div class="form-row">
                                                              <div class="col-xl-12"><label for="document">{{staticMsgs.common.docname}}<span
                                                                class="red_icon">*</span></label>
                                                                  <div class="custom-dropzone" id="docupload"
                                                                  [multiple]="false"
                                                                  [accept]="'image/png,image/jpeg,image/jpg'"
                                                                      ngx-dropzone (change)="onSelectDoc($event,i)">
                                                                      <ngx-dropzone-label>
                                                                          <div >
                                                                              <h5 class="text-light-gray mt-3">
                                                                                {{staticMsgs.colorDetail.dropFile}}
                                                                                  </h5>
                                                                          </div>
                                                                      </ngx-dropzone-label>
                                                                      <ngx-dropzone-preview
                                                                          ngProjectAs="ngx-dropzone-preview"
                                                                          *ngFor="let f of fileData[i]" [file]="f"
                                                                          [removable]="true"
                                                                          (removed)="onRemove(f,i)">
                                                                          <ngx-dropzone-label>{{ f.name }}
                                                                              <!--  ({{ f.type }}) -->
                                                                          </ngx-dropzone-label>
                                                                      </ngx-dropzone-preview>

                                                                  </div>
                                                                  <div *ngIf="doc_Error[i]">
                                                                    <span class="red">
                                                                        <span>
                                                                            {{doc_Error[i]}}
                                                                        </span></span>
                                                                </div>
                                                                  <div class="col-xl-6 mt-6" *ngIf="document.get('docid').value">
                                                                    <div class="col-xl-6 mb-5"
                                                                        *ngFor="let img of editedFileData[i]; let j = index">
                                                                        <img src="{{img.thumbnail}}"
                                                                            class="img-fluid  img-fit "
                                                                            *ngIf="isValidImageURL(img.name)"
                                                                            (click)="open(i,j)">
                                                                        <a href="{{img.thumbnail}}" target="_blank"
                                                                            download><img src="{{documentImage}}"
                                                                                class="img-fluid  img-fit "
                                                                                *ngIf="!isValidImageURL(img.name)"></a>
                                                                        <button type="button" class="close image-close"
                                                                            (click)="removeImgSection(img,i)">
                                                                            <span aria-hidden="true">×</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                          </div>

                                                          <div class="form-row mt-5"
                                                              *ngIf="(fileData[i]) && (!isEmptyObject(fileData[i]))">
                                                              <div class="col-xl-12 ">
                                                                  <div class="card">
                                                                      <div class="dt-card__body p-2">
                                                                          <label for="docnotes">{{staticMsgs.colorDetail.note}}</label>
                                                                          <textarea cols="2" class="form-control"
                                                                              id="docnotes"
                                                                              formControlName="docnotes"></textarea>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>

                                                          <input type="text" class="form-control" [hidden]="true"
                                                              id="docid" formControlName="docid"
                                                              placeholder="Document Id" />

                                                      </div>

                                                  </div>
                                              </div>

                                              <div class="form-row text-center mb-7">
                                                  <div class="col-sm-12">
                                                      <!-- <button type="button" class="btn btn-primary adv-search-btn">Select Existing</button> -->
                                                      <button type="button" class="btn btn-primary adv-search-btn"
                                                          (click)="addNewDoc()"><i class="icon icon-plus"></i>{{staticMsgs.colorDetail.addnew}}</button>
                                                  </div>
                                              </div>

                                              <div class="form-row text-center mb-7" *ngIf="showButton">
                                                <div class="col-sm-12" >


                                                  <button type="submit" *ngIf="fileData.length>0" class="btn btn-primary adv-search-btn"
                                                    [disabled]="documentsForm?.invalid || !isFilesAdded">
                                                          {{staticMsgs.paletteDetail.save}}
                                                  </button>
                                                  <button type="button" *ngIf="isEditMode"
                                                        (click)="cancelDocumentEdit()"
                                                   class="btn btn-primary adv-search-btn">
                                                 {{staticMsgs.common.cancel}}
                                                  </button>
                                                </div>
                                              </div>


                                          </form>
                                      </div>

                                      <div *ngIf="isViewingMode">
                                         <div class="text-center" *ngIf="colorDataCnt == 0">
                                          <h2>{{staticMsgs.colorLib.nodata}}</h2>
                                          <img [src]="notfoundImg" class="img-fluid ">
                                        </div>
                                          <div *ngFor="let document of getControls(); let i=index">
                                              <div class="dt-entry__header mt-5">
                                                  <!-- Entry Heading -->
                                                  <div class="dt-entry__heading">
                                                      <h3 class="dt-entry__title">
                                                          {{document.get('docclassification').value}}</h3>
                                                  </div>
                                                  <!-- /entry heading -->
                                              </div>
                                              <form>
                                                  <div class="form-row">
                                                      <div class="col-sm-4 mb-3">
                                                          <label for="document.get('docseq').value"
                                                              class="label-view">{{staticMsgs.colorDetail.document1}}</label>
                                                          <p class="mt-2 viewtext">{{document.get('docseq').value}}
                                                          </p>
                                                      </div>
                                                      <div class="col-sm-4 mb-3">
                                                          <label for="document.get('docname').value"
                                                              class="label-view">{{staticMsgs.colorDetail.name1}}</label>
                                                          <p class="mt-2 viewtext">{{document.get('docname').value}}
                                                          </p>
                                                      </div>
                                                      <div class="col-sm-4 mb-3">
                                                          <label for="document.get('docstatus').value"
                                                              class="label-view">{{staticMsgs.colorDetail.status}}</label>
                                                          <p class="mt-2 viewtext">{{document.get('docstatus').value |
                                                              titlecase}}
                                                          </p>
                                                      </div>

                                                      <div class="col-sm-4 mb-3"
                                                          *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                                          <label for="document.get('docreportstatus').value"
                                                              class="label-view">{{staticMsgs.colorDetail.reportStatus}}</label>
                                                          <p class="mt-2 viewtext">
                                                              {{document.get('docreportstatus').value}}</p>
                                                      </div>
                                                      <div class="col-sm-4 mb-3"
                                                          *ngIf="document.get('docclassification').value === 'Lab Reports'">
                                                          <label for="document.get('doclablocation').value"
                                                              class="label-view">{{staticMsgs.colorDetail.labLoc}}
                                                              </label>
                                                          <p class="mt-2 viewtext">
                                                              {{document.get('doclablocation').value}}</p>
                                                      </div>

                                                      <div class="col-sm-8 mb-3">
                                                          <label for="document.get('docdescription').value"
                                                              class="label-view">{{staticMsgs.colorDetail.desc}}</label>
                                                          <p class="mt-2 viewtext">
                                                              {{document.get('docdescription').value}}
                                                          </p>
                                                      </div>
                                                      <div class="col-sm-8 mb-3"
                                                          *ngIf="document.get('docnotes').value">
                                                          <label for="document.get('docnotes').value"
                                                              class="label-view">{{staticMsgs.colorDetail.docNote}}</label>
                                                          <p class="mt-2 viewtext">{{document.get('docnotes').value}}
                                                          </p>
                                                      </div>

                                                  </div>


                                                  <div class="form-row mt-2">
                                                      <div class="col-xl-3 mb-5"
                                                          *ngFor="let img of editedFileData[i]; let j = index">
                                                          <img src="{{img.thumbnail}}" class="img-fluid  img-fit "
                                                              *ngIf="isValidImageURL(img.name)" (click)="open(i,j)">
                                                          <a href="{{img.thumbnail}}" target="_blank" download><img
                                                                  src="{{documentImage}}" class="img-fluid  img-fit "
                                                                  *ngIf="!isValidImageURL(img.name)"></a>
                                                      </div>
                                                  </div>

                                              </form>
                                          </div>
                                      </div>


                                  </div>
                                  <!-- /tab panel -->
                              </div>
                          </div>
                          <!-- /tab content-->

                      </div>
                      <!-- /card body -->

                  </div>
                  </div>
                  <!-- /card -->

              </div>
              <!-- /grid item -->



          </div>
          <!-- /grid -->

      </div>

      <!-- Footer -->
      <app-footer></app-footer>
      <!-- /footer -->

  </div>
  <!-- /Site Content Wrapper-->

</main>
<!-- /site content -->
