import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Lightbox } from 'ngx-lightbox';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import { SharedService } from '../../../services/shared.service';
import Swal from 'sweetalert2';
import { AddEvent, GridDataResult, GridComponent, CellClickEvent, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State, CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { Favourite } from '../../../models/color';
import { Router } from '@angular/router'
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as paletteReducer from "../../../reducers/palette.reducer";
import * as paletteActionTypes from "../../../actions/palette.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { PaletteCustomComponent } from '../palette-custom/palette-custom.component';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;

@Component({
  selector: 'app-palettelibrary',
  templateUrl: './palettelibrary.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./palettelibrary.component.css']
})
export class PalettelibraryComponent implements OnInit {

  public listItems: Array<{ text: string, value: string }> = [

    { text: 'Approved', value: 'Approved' },
    { text: 'In Review', value: 'In Review' },
    { text: 'Dropped', value: 'Dropped' }
  ];

  public selectedValue: string = "Approved";
  statuslist = ["Approved", "In Review", "Dropped"];
  paletteData = [];
  dbpaletteData = [];
  originalpaletteData = [];
  paletteDataForm: FormGroup;
  isResetMode = false;
  advForm: FormGroup;
  notFound: boolean;
  notfoundImg: string;
  createdFromDate: any;
  createdToDate: any;
  modifiedFromDate: any;
  modifiedToDate: any;
  minDate = { year: 1985, month: 1, day: 1 };
  createdStartDate: string;
  modifiedEndDate: string;
  modifiedStartDate: string;
  createdEndDate: string;
  checkStatusData = false;
  msg: string;
  paginationStartcount = 0;
  paginationEndcount = 10;
  public startCount = 0;
  public endCount = 10;
  public startCountList = 0;
  public endCountList = 10;
  public startEndCount = "";
  public isPageChanged = false;
  public isScrollDown = false;
  public isGetAllRecords = false;
  public totalRecordCount: any;
  loading: boolean;
  setValue='Approved';

  public filteredData: any[];
  public filteredXcelData: any[];
  public gridData: any;
  showNote = false;
  staticMsgs: any;
  dataRefresher: NodeJS.Timeout;
  select: boolean=false;

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      this.filteredData = this.filteredXcelData;
      var gridView = {
        data: this.filteredData,
        total: this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total;
    }
    else {
      var gridView = {
        data: this.paletteViewMode === 'Shared'? this.sharedGridData : this.paletteData,
        total: this.paletteViewMode === 'Shared'? this.totalSharedRecordCount : this.totalRecordCount,
      };
      this.gridData = process(gridView.data, this.state)
      this.gridData.total = gridView.total;
    }

  }
  min: number;
  max: number;

  Swal = require('sweetalert2');
  showLoader: boolean;
  showLoader1: boolean;
  gridContent = true;
  HeadingText = "Card View";
  tableContent = false;
  activeTab: boolean = false;
  selected: any;
  cards: Observable<any[]>;
  searchedKeyword: string;
  copyCard: Observable<any[]>;
  public filterQuery = "";
  idArray: string[];
  result: any;
  model: any = {};
  temp: any[];
  enableKeywordVal: string[];
  enableCreatedByVal: string[];
  enableCreatToVal: string[];
  enableCreatFromVal: string[];
  enableStatusVal: string[];
  enableClassVal: string[];
  listBtn: boolean = true;
  cardBtn: boolean = false;
  enableModifToVal: string[];
  enableModifFromVal: string[];
  enableNameVal: string[];
  enableDescriptionVal: string[];
  isEditMode = false;
  @ViewChild('grid') grid: GridComponent;
  public gridView: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10
  };

  public filter: CompositeFilterDescriptor;
  classApplied = false;
  public skip = 0;
  public SearchData = "";
  log: any[];
  clickedRowItem: any;
  btnstatus: boolean = false;
  btnstatus1: boolean = true;
  paletteViewMode = "Created";
  mypalette: boolean = true;
  sharedpalette: boolean = false;
  isDataLoading = false;

  sharedCopyCard: any[] = [];
  sharedCards: any[] = [];
  isMyPalettes = true;
  sharedNotFound: boolean;
  isSharedGetAllRecords = false;
  isSharedScrollDown = false;
  totalSharedRecordCount: any;
  sharedMsg: string;
  sharedEndCount = 10;
  sharedStartCount = 0;
  sharedStartEndCount = "";
  checkSharedStatusData = false;
  sharedEndListCount = 10;
  sharedStartListCount = 0;
  sharedGridData: any[] = [];
  sharedStartEndListCount: string;
  startEndListCount: string;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(
    private sharedService: SharedService,
    private storageservice: StorageService,
    private dataService: DataService,
    public userservice: UserService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private router: Router,
    private store: Store<any>) {
    this.allData = this.allData.bind(this);
  }

  public group: any[] = [{
    field: 'Name'
  }];

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredData = filterBy((this.paletteViewMode === 'Shared') ? this.sharedGridData : this.paletteData, filter);
  }

  options = [
    { value: 'Approved', label: 'Approved' },
    { value: 'In Review', label: 'In Review' },
    { value: 'Dropped', label: 'Dropped' },
  ];


  isChecked = false;
  checkuncheckall() {
    if (this.isChecked === true) {
      this.isChecked = false;
    } else {
      this.isChecked = true;
    }
  }

  createPalette() {
    this.dataService.AddClientLog();
    this.router.navigate(['/palettedetails']);
  }

  onPageChange(e: PageChangeEvent): void {
    this.isPageChanged = true;
    this.state.skip = e.skip;
    this.state.take = e.take;
    if (this.paletteViewMode === 'Shared') {
      this.sharedStartListCount = e.skip;
      this.sharedEndListCount = e.skip + e.take;
      this.loadSharedListData();
    } else {
      this.startCountList = e.skip;
      this.endCountList = e.skip + e.take;
      this.loadDataList();
    }
  }

  scrollEvent = (event: any): void => {
    if (this.gridContent && this.router.url.includes('palettelibrary') && !this.tableContent) {
      if (!this.isDataLoading) {
        if (event.target.scrollTop >= (event.target.scrollHeight - event.target.clientHeight - 2)) {
          if (this.paletteViewMode == 'Created') {
            this.isDataLoading = true;
            if (!this.isGetAllRecords) {
              this.isScrollDown = false;
              this.startCount = this.startCount + this.paginationEndcount;
              this.endCount = this.endCount + this.paginationEndcount;
              this.loadData();
            }
          } else {
            this.isDataLoading = true;
            if (!this.isSharedGetAllRecords) {
              this.isScrollDown = false;
              this.sharedStartCount = this.sharedStartCount + this.paginationEndcount;
              this.sharedEndCount = this.sharedEndCount + this.paginationEndcount;
              this.loadSharedData();
            }
          }

        }
      }
    }
  }

  paletteView(palette) {
    this.isMyPalettes = (palette === 'Created') ? true : false
    this.cards = null;
    this.notFound = false;
    this.isEditMode = false;
    if (this.tableContent) {
      this.closeAllRows();
    }
    this.paletteViewMode = palette;
    if (this.paletteViewMode == 'Shared') {
      this.mypalette = false;
      this.sharedpalette = true;
    }
    else {
      this.mypalette = true;
      this.sharedpalette = false;
    }

    if (this.gridContent) {
      this.HeadingText = "Card View";
      this.paletteViewMode == 'Shared' ? this.loadSharedData() : this.loadData();
    }
    else {
      this.HeadingText = "List View";
      this.paletteViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();
    }
  }

  cardView() {
    this.gridContent = true;
    this.HeadingText = "Card View";
    this.tableContent = false;
    this.isMyPalettes = true;
    this.showNote = false;
    this.listBtn = true;
    this.cardBtn = false;
    this.btnstatus = false;
    this.btnstatus1 = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', 'Active');
    }, 10);
    if (this.paletteViewMode === 'Shared') {
      this.isSharedGetAllRecords = false;
      this.sharedCards = [];
      this.sharedStartCount = this.paginationStartcount;
      this.sharedEndCount = this.paginationEndcount;
      this.loadSharedData();
    } else {
      this.isGetAllRecords = false;
      this.startCount = this.paginationStartcount;
      this.endCount = this.paginationEndcount;
      this.loadData();
    }
  }

  tableView() {
    this.gridContent = false;
    this.tableContent = true;
    this.isMyPalettes = false;
    this.HeadingText = "List View";
    this.cardBtn = true;
    this.listBtn = false;
    this.btnstatus = true;
    this.btnstatus1 = false;
    if(this.paletteViewMode === 'Shared'){
      if (this.isPageChanged) {
        this.sharedStartListCount = this.state.skip;
        this.sharedEndListCount = this.state.skip + this.state.take;
      }
      else {
        this.sharedStartListCount = this.paginationStartcount;
        this.sharedEndListCount = this.paginationEndcount;
      }
    } else {
    if (this.isPageChanged) {
      this.startCountList = this.state.skip;
      this.endCountList = this.state.skip + this.state.take;
    }
    else {
      this.startCountList = this.paginationStartcount;
      this.endCountList = this.paginationEndcount;
    }
  }
  this.paletteViewMode == 'Shared' ? this.loadSharedListData() : this.loadDataList();
  }

  ngOnInit() {
    this.staticMsgs=appStaticMsg.data[0];
    window.addEventListener('scroll', this.scrollEvent, true);
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";

    this.storageservice.storeEditPaletteId("");
    $(document).ready(function () {
      $('.selectpicker').selectpicker('val', 'Approved');
    });

    this.advForm = this.formBuilder.group({
      name: [''],
      description: [''],
      keyword: [''],
      status: [''],
      createdBy: [''],
      createdFrom: [''],
      createdTo: [''],
      modifiedFrom: [''],
      modifiedTo: ['']
    });

    this.paletteDataForm = this.formBuilder.group({
      formArray: new FormArray([])
    });

    this.loadData();
    this.sharedService.sendClickEvent();

  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataService.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  // refreshData(){
  //   this.dataRefresher =
  //     setInterval(() => {
  //      this.sharedCards.filter((x,i)=>{
  //       this.sharedCards[i]['isFavourite']=false;
  //      })
  //       //Passing the false flag would prevent page reset to 1 and hinder user interaction
  //     },);
  // }
  // convenience getters for easy access to form fields
  get f() { return this.paletteDataForm.controls; }
  get fa() { return this.f.formArray as FormArray; }
  fac(data) {
    if (this.paletteViewMode === 'Shared') {
      return this.fa.controls[this.sharedGridData.indexOf(data)] as FormGroup;
    } else {
      return this.fa.controls[this.paletteData.indexOf(data)] as FormGroup;
    }
  }

  public allData(): ExcelExportData {
    this.filteredData = filterBy((this.paletteViewMode === 'Shared') ? this.sharedGridData : this.paletteData, this.state.filter);
    //this.state.take = this.filteredData.length;
    if (this.filteredXcelData != null && this.filteredXcelData.length > 0) {
      //this.searchListData(this.SearchData);
      this.filteredData = this.filteredXcelData;
      this.state.take = this.filteredXcelData.length;
    }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    const result: ExcelExportData = {
      data: this.filteredData//process(this.filteredData, this.state).data,
    };
    //this.state.take = 5;
    return result;
  }

  advanceSearchOpen() {
    this.classApplied = true;

  }

  advanceSearchClose() {
    this.classApplied = false;
    //this.isResetMode = false;
  }

  clearSearch() {
    this.clearFields();
    this.loadData();
    this.loadDataList();
    this.isResetMode = false;
  }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader = false;
  }

decryptData(x){
  x.colourHexCodes = this.aesdecryptservice.decryptData(this.dataService.Key, x.colourHexCodes);
  x.description = this.aesdecryptservice.decryptData(this.dataService.Key, x.description);
  x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
  x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
  x.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, x.sequence);
}

loadSharedListData(){
  this.sharedStartEndListCount = this.sharedStartListCount + "-" + this.sharedEndListCount;
  this.dataService.getAllPalettes(this.sharedStartEndListCount, this.paletteViewMode).subscribe(response => {
    if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
      this.isDataLoading = false;
      this.logger.info(this.staticMsgs.paletteLib.docLoaded, "ImageLibrary");
      this.showLoader = false;
      this.sharedGridData = [];
      response['body']['item1'].forEach(x => {
        this.decryptData(x);
        this.sharedGridData.push(x);
      });
      this.totalSharedRecordCount = response['body']['item3'];
      if (this.sharedGridData.length > 0) {
        this.sharedNotFound = false;
      } else {
        this.sharedNotFound = true;
      }
      this.sharedGridData.forEach((x, i) => {
        this.fa.push(this.createFormGroup(x));
      });
      var gridView = {
        data: this.sharedGridData,
        total: this.totalSharedRecordCount,
      };
      this.gridData = gridView;
      this.loading = false;
    } else {
      this.isSharedScrollDown = true;
      if (response['body']['item2'] !== 'Success') {
        this.error("Failed:" + response['body']['item1'])
        this.logger.error(response['body']['item1'], "ImageLibrary");
      }
      this.showLoader = false;
      this.sharedNotFound = true;
    }
  }, err => {
    this.getError(err);
    this.logger.error(err.error.message, "ImageLibrary");
    this.showLoader = false;
  })
}

  loadSharedData() {
    setTimeout(() => {
      $('.selectpicker').selectpicker('val', this.setValue);
    }, 10);
    if (!this.isSharedGetAllRecords) {
      this.sharedStartEndCount = this.sharedStartCount + "-" + this.sharedEndCount;
      this.dataService.getAllPalettes(this.sharedStartEndCount,this.paletteViewMode).subscribe(response => {
        if (response['body']['item2'] === 'Success' && response['body']['item1'].length > 0) {
          this.isDataLoading = false;
          this.logger.info(this.staticMsgs.paletteLib.paletteListLoaded, "PaletteLibrary");
          this.showLoader = false;
          response['body']['item1'].forEach(x => {
           this.decryptData(x);
            this.sharedCards.push(x);
            this.sharedCards = this.sharedCards.filter((item, index) => this.sharedCards.indexOf(item) === index)
          });
          console.log(this.sharedCards,'sharedCards');
          this.sharedCopyCard = [];
          this.sharedCopyCard = this.sharedCards;
          this.totalSharedRecordCount = response['body']['item3'];
          if (this.sharedCards.length > 0) {
            this.sharedNotFound = false;
          } else {
            this.sharedNotFound = true;
          }
          if (this.sharedCards.length == this.totalSharedRecordCount) {
            this.isSharedGetAllRecords = true;
          }
          else {
            this.isSharedScrollDown = true;
          }
          this.onSelect(this.setValue,'shared')
        } else {
          this.isSharedScrollDown = true;
          if (response['body']['item2'] !== 'Success') {
            this.error("Failed:" + response['body']['item1'])
            this.logger.error(response['body']['item1'], "PaletteLibrary");
          }
          this.showLoader = false;
          this.sharedNotFound = true;
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message, "PaletteLibrary");
        this.showLoader = false;
      })
    }
  }

  loadData() {
    this.startEndCount = this.startCount + "-" + this.endCount;
    setTimeout(() => {
      $('.selectpicker').selectpicker('val',this.setValue);
    }, 10);
    this.store.dispatch(new paletteActionTypes.LoadPaletteLibrary(this.startEndCount,this.paletteViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((paletteLibrary) => {
      console.log(paletteLibrary,'paletteLibrary');
      if (paletteLibrary.palette.error === "") {
        if (paletteLibrary.palette.message?.item2 === 'Success') {
          this.showLoader1 = false;
          this.totalRecordCount = paletteLibrary.palette.message?.item3;
          this.cards = this.store.pipe(select(paletteReducer.getPaletteLibrary));
          this.copyCard = this.store.pipe(select(paletteReducer.getPaletteLibrary));
          this.logger.info(this.staticMsgs.paletteLib.paletteListLoaded, "PaletteLibrary");
          this.fa.clear();
          this.paletteData = paletteLibrary.palette.message?.item1;
          this.dbpaletteData = paletteLibrary.palette.message?.item1;
          console.log(this.cards,'cards');
          if(this.paletteData.length > 0){
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.paletteData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          if (paletteLibrary.palette.ids.length == this.totalRecordCount) {
            this.isGetAllRecords = true;
          }
          else {
            this.isScrollDown = true;
          }

          this.onSelect(this.setValue,'mypalette')
          //this.gridData = process(this.paletteData, this.state);
          //this.("Material");
        } else {
          this.showLoader1 = false;
          this.notFound = true;
          this.error("Failed:" + paletteLibrary.palette.message?.item1)
          this.logger.error(paletteLibrary.palette.message?.item1, "PaletteLibrary");
        }

      } else {
        this.isScrollDown = true;
        this.isGetAllRecords = true;
        this.logger.error(paletteLibrary.palette.error.error.message, "PaletteLibrary");
        this.getError(paletteLibrary.palette.error);
        this.showLoader1 = false;
      }
    })
}

  loadDataList() {
    this.loading = true;
    this.startEndListCount = this.startCountList + "-" + this.endCountList;
    this.store.dispatch(new paletteActionTypes.LoadPaletteLibrary(this.startEndListCount,this.paletteViewMode));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((paletteLibrary) => {
      if (paletteLibrary.palette.error === "") {
        if (paletteLibrary.palette.message?.item2 === 'Success') {
          this.totalRecordCount = paletteLibrary.palette.message?.item3;
          this.cards = this.store.pipe(select(paletteReducer.getPaletteLibrary));
          this.copyCard = this.store.pipe(select(paletteReducer.getPaletteLibrary));
          this.logger.info(this.staticMsgs.paletteLib.paletteListLoaded, "PaletteLibrary");
          this.fa.clear();
          this.paletteData = paletteLibrary.palette.message?.item1;
          this.dbpaletteData = paletteLibrary.palette.message?.item1;
          if(this.paletteData.length > 0){
            this.notFound = false;
          } else {
            this.notFound = true;
          }
          this.paletteData.forEach((x, i) => {
            this.fa.push(this.createFormGroup(x));
          });
          var gridView = {
            data: this.paletteData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView;
          this.loading = false;
          this.onSelect(this.setValue,'mypalette')
          //this.gridData = process(this.paletteData, this.state);
          //this.("Material");
        } else {
          this.loading = false;
          this.notFound = true;
          this.error("Failed:" + paletteLibrary.palette.message?.item1)
          this.logger.error(paletteLibrary.palette.message?.item1, "PaletteLibrary");
        }

      } else {
        this.logger.error(paletteLibrary.palette.error.error.message, "PaletteLibrary");
        this.getError(paletteLibrary.palette.error);
        this.loading = false;
      }
    })
    }

  onEdit() {
        // store copy of original palette in case cancelled
    this.originalpaletteData = this.paletteViewMode === 'Shared'? [...this.sharedGridData] :[...this.paletteData];
    var gridView = {
      data: this.originalpaletteData,
      total: this.paletteViewMode === 'Shared'? this.totalSharedRecordCount :this.totalRecordCount,
    };
    this.gridData = gridView;
    // reset / initialise form fields
    this.resetForm();

    // set all rows to edit mode to display form fields
    this.editGridRows();
    this.isEditMode = true;

  }
  onRemove(index, dataItem) {
    Swal.fire({
      title: this.staticMsgs.paletteLib.deletePalette,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.paletteLib.deleteit
    }).then((result) => {
      if (result.value) {
        // rows must all be closed while removing
        this.closeAllRows();
        if (this.paletteViewMode === 'Shared') {
          let dataIndex = this.sharedGridData.indexOf(dataItem);
          // remove product and product form group

          this.sharedGridData = JSON.parse(JSON.stringify(this.sharedGridData));
          this.sharedGridData.splice(dataIndex, 1);
          this.fa.removeAt(dataIndex);

          this.filteredData = filterBy(this.sharedGridData, this.state.filter);
        } else {
        let dataIndex = this.paletteData.indexOf(dataItem);
        // remove product and product form group
        this.paletteData = JSON.parse(JSON.stringify(this.paletteData))
        this.paletteData.splice(dataIndex, 1);
        this.fa.removeAt(dataIndex);
        this.filteredData = filterBy(this.paletteData, this.state.filter);
        }
        if (this.SearchData != null && this.SearchData != "") {

          this.searchListData(this.SearchData);
        }
        if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
        // this.paletteData = this.filteredData;
        //  this.gridData = this.filteredData;
        var gridView = {
          data: this.filteredData,
          total: this.totalRecordCount,
        };
        this.gridData = gridView; // process(this.filteredData, this.state);
        // reset all rows back to edit mode
        this.editGridRows();
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });

  }


  jsonDeleteCompare(element, index, array) {

    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  jsonCompare(element, index, array) {
    let dataRow = [];
    let diff: number = 0;
    dataRow = array.filter((x, i) => x.id === element.id);
    if (dataRow.length > 0) {
      Object.keys(element).forEach(key => {

        if (element[key] != dataRow[0][key]) {
          diff++;

        }
      })
    }
    if (diff > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  onSave() {

    // mark all fields as touched to highlight any invalid fields
    this.paletteDataForm.markAllAsTouched();
    for (let i = 0; i < this.paletteDataForm.value.formArray.length; i++) {
      if (this.paletteDataForm.controls.formArray['controls'][i].controls.name.value.length > 50) {
        this.paletteDataForm.controls.formArray['controls'][i].controls.name.setErrors({ 'incorrect': true });
      }
      if (this.paletteDataForm.controls.formArray['controls'][i].controls.description.value.length > 500) {
        this.paletteDataForm.controls.formArray['controls'][i].controls.description.setErrors({ 'incorrect': true });
      }
    }
    // stop here if form is invalid
    if (this.paletteDataForm.invalid) {
      this.showNote = true;
      this.error(this.staticMsgs.paletteLib.checkField)
      //alert('FORM INVALID :(');
      return;
    }

    // copy form data to palette array on success
    this.paletteData = this.fa.value;
    if(this.paletteViewMode === 'Shared'){
      this.sharedGridData = this.fa.value;
    } else {
      this.paletteData = this.fa.value;
    }
    this.closeAllRows();
    let updList = this.paletteViewMode === 'Shared' ? this.sharedGridData.filter((x, i) => this.jsonCompare(x, i, this.originalpaletteData)) : this.paletteData.filter((x, i) => this.jsonCompare(x, i, this.originalpaletteData));
    //console.log(updList);
    if (updList.length > 0) {
      //alert('call');
      //this.showLoader = true;
      this.showLoader1 = true;
      for (let i = 0; i < updList.length; i++) {
        updList[i].description = this.aesdecryptservice.encryptData(this.dataService.Key, updList[i].description);
        // updList[i].sequence=this.aesdecryptservice.encryptData(this.dataService.Key,updList[i].sequence);
        updList[i].name = this.aesdecryptservice.encryptData(this.dataService.Key, updList[i].name);
        updList[i].status = this.aesdecryptservice.encryptData(this.dataService.Key, updList[i].status);
      }

      this.store.dispatch(new paletteActionTypes.UpdatePaletteLibrary(updList));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedPaletteLibrary) => {
        /*  if (colorLibrary.moodboardState.error === "") {
           if(colorLibrary.moodboardState.ids[0]!== undefined){
           } */
        if (updatedPaletteLibrary.palette.error === "") {
          if (updatedPaletteLibrary.palette.message?.item2 === 'Success') {
            this.showNote = false;
            this.success(this.staticMsgs.paletteLib.paletteUpd1);
            this.logger.info(this.staticMsgs.paletteLib.paletteUpd, "PaletteLibrary");
            this.paletteViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
            this.showLoader1 = false;
          } else {
            this.showLoader1 = false;
            this.paletteViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
            this.error("Failed:" + updatedPaletteLibrary.palette.message?.item1)
            this.logger.error(updatedPaletteLibrary.palette.message?.item1, "PaletteLibrary");
          }

        } else {
          this.logger.error(updatedPaletteLibrary.palette.error.error.message, "PaletteLibrary");
          this.getError(updatedPaletteLibrary.palette.error);
          this.showLoader1 = false;
        }
      })
      }
    if (this.originalpaletteData.length != (this.paletteViewMode == 'Shared' ? this.sharedGridData.length : this.paletteData.length)) {
      this.showLoader1 = true;
      let deleterecordIds = [];
      let DelpaletteList;
      if(this.paletteViewMode === 'Shared'){
        DelpaletteList = this.originalpaletteData.filter((x, i) => this.jsonDeleteCompare(x, i, this.sharedGridData));
      } else {
        DelpaletteList = this.originalpaletteData.filter((x, i) => this.jsonDeleteCompare(x, i, this.paletteData));
      }
      //console.log(DelpaletteList);
      if (DelpaletteList.length > 0) {
        DelpaletteList.forEach((x, i) => {
          deleterecordIds.push(x.id);

        });

        this.store.dispatch(new paletteActionTypes.DeletePaletteLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedPaletteLibrary) => {
          /*  if (colorLibrary.moodboardState.error === "") {
             if(colorLibrary.moodboardState.ids[0]!== undefined){
             } */
          if (deletedPaletteLibrary.palette.error === "") {
            if (deletedPaletteLibrary.palette.message?.item2 === 'Success') {
              this.showNote = false;
              this.success(this.staticMsgs.paletteLib.paletteDeleted1);
              this.logger.info(this.staticMsgs.paletteLib.paletteDeleted, "PaletteLibrary");
              this.paletteViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              this.showLoader1 = false;
            } else {
              this.showLoader1 = false;
              this.paletteViewMode === 'Shared' ? this.loadSharedListData() : this.loadDataList();
              this.error("Failed:" + deletedPaletteLibrary.palette.message?.item1)
              this.logger.error(deletedPaletteLibrary.palette.message?.item1, "PaletteLibrary");
            }

          } else {
            this.logger.error(deletedPaletteLibrary.palette.error.error.message, "PaletteLibrary");
            this.getError(deletedPaletteLibrary.palette.error);
            this.showLoader1 = false;
          }
        })
      }
    }
    this.showLoader = false;
    //console.log(updArry);
    this.isEditMode = false;
    this.loading = false;
  }

  onCancel() {
    this.closeAllRows();
    this.showNote = false;
    // reset palette back to original data (before edit was clicked)
    this.paletteViewMode === 'Shared' ? this.sharedGridData :this.paletteData = this.originalpaletteData;
    var gridView = {
      data: this.paletteViewMode === 'Shared' ? this.sharedGridData :this.paletteData,
      total: this.paletteViewMode === 'Shared' ? this.totalSharedRecordCount :this.totalRecordCount,
    };
    this.gridData = gridView;
    this.isEditMode = false;
  }

  // helper methods

  private editAllRows() {
    // set all rows to edit mode to display form fields
    this.paletteData.forEach((x, i) => {
      var Row = this.fa.controls[i] as FormGroup;
      this.grid.editRow(i, Row);
    });
  }

  private editGridRows() {
    if (this.paletteViewMode === 'Shared') {
      this.filteredData = filterBy(this.sharedGridData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        if (x.isEdit) {
          var materialRow = this.fa.controls[this.sharedGridData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, materialRow);
        }
      });
    } else {
      this.filteredData = filterBy(this.paletteData, this.state.filter);
      if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
      this.filteredData.forEach((x, i) => {
        //alert(this.materialData.filter(x).keys);
        //   this.fa.controls[i].patchValue(x);

        if (x.isEdit) {
          var materialRow = this.fa.controls[this.paletteData.indexOf(x)] as FormGroup;
          var rowIndex = this.state.skip + i;
          this.grid.editRow(rowIndex, materialRow);
        }
      });
    }
  //   if(this.paletteViewMode ==='Shared'){
  //     this.filteredData = filterBy(this.sharedGridData, this.state.filter);
  //     if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
  //     this.filteredData.forEach((x, i) => {
  //       if (x.isEdit) {
  //         var Row = this.fa.controls[this.sharedGridData.indexOf(x)] as FormGroup;
  //         var rowIndex = this.state.skip + i;
  //         this.grid.editRow(rowIndex, Row);
  //       }
  //     });
  //   } else {
  //   this.filteredData = filterBy(this.paletteData, this.state.filter);
  //   if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
  //   this.filteredData.forEach((x, i) => {
  //     if (x.isEdit) {
  //       var Row = this.fa.controls[this.paletteData.indexOf(x)] as FormGroup;
  //       var rowIndex = this.state.skip + i;
  //       this.grid.editRow(rowIndex, Row);
  //     }
  //   });
  // }
  }

  private closeAllRows() {
    // close all rows to display readonly view of data
    if (this.paletteViewMode === 'Shared') {
      // close all rows to display readonly view of data
      this.sharedGridData.forEach((x, i) => {
        var rowIndex = this.state.skip + i;
        this.grid.closeRow(rowIndex);
      });
    } else {
    this.paletteData.forEach((x, i) => {
      var rowIndex = this.state.skip + i;
      this.grid.closeRow(rowIndex);
    });
  }
  }

  private resetForm() {
    // clear form array and create a new form group for each product
      // clear form array and create a new form group for each product
      this.fa.clear();
      if (this.paletteViewMode === 'Shared') {
        this.sharedGridData.forEach((x, i) => {
          this.fa.push(this.createFormGroup(x));
        });
      } else {
        this.paletteData.forEach((x, i) => {
          this.fa.push(this.createFormGroup(x));
        });
      }
  }

  private createFormGroup(palette: any = {}) {
    // create a new form group containing controls and validators for a product
    return this.formBuilder.group({
      sequence: [palette.sequence],
      name: [palette.name],
      description: [palette.description],
      status: [palette.status],
      isEdit: [palette.isEdit],
      id: [palette.id],
      isDelete: [palette.isDelete],
    })
  }

  ngAfterViewInit() {
  }


  onSelect(val: any, type: any) {
    this.setValue = val;
    console.log('val');
    if (type === 'mypalette') {
      console.log(type,'val');
      if (val == "") {
        this.cards = this.copyCard;
        console.log(this.cards,'cards');
      } else {
        this.cards = this.copyCard.pipe(
          map(data => data.filter(res => res.status.toLowerCase() == val.toLowerCase()))
        )
      }
      var data = this.paletteData.filter(x => x.status.toLowerCase() == val.toLowerCase())

      if (data.length > 0) {
        this.checkStatusData = false;
      } else {
        this.msg = (val.toLowerCase() === 'approved') ? 'No approved records available' : (val.toLowerCase() === 'dropped') ?  'No dropped records available': 'No in-review records available';
        this.checkStatusData = true;
      }
      console.log(this.cards,'val2');

    } else {
      console.log( type,'val1');
      if (val == "") {
        this.sharedCards = this.sharedCopyCard;
      } else {
        this.sharedCards = this.sharedCopyCard.filter(res => res.status.toLowerCase() == val.toLowerCase())
      }
      if (this.sharedCards.length > 0) {
        this.checkSharedStatusData = false;
      } else {
        this.sharedMsg = (val.toLowerCase() === 'approved') ? 'No approved shared records available' : (val.toLowerCase() === 'dropped') ?  'No dropped shared records available': 'No in-review shared records available';
        this.checkSharedStatusData = true;
      }

   }
  }
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.paletteLib.session,
      html: this.staticMsgs.paletteLib.sessExp,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
      }
    })
  }

  AddToFavorite(e) {
    this.showLoader = true;

    //console.log("card",card);

    let selected = e.target.id.replace("checkbox", "");
    console.log(selected,'selected');
    if (selected != '' && e.target.checked == true) {
      let data: Favourite[] = [];
      // console.log(selected,'selected');
      data.push(new Favourite(selected));
      this.dataService.bulkAddFavourites(data, "Palettes").subscribe(response => {
        console.log(response,'response');
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.success(this.staticMsgs.paletteLib.addedSuccess1);

            this.logger.info(this.staticMsgs.paletteLib.addedSuccess, "PaletteLibrary");
          }
          else {
            //console.log(response)
            //alert(response.body['item2'])
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "PaletteLibrary");
            this.showLoader = false;
          }
        }
      }, err => {
        this.logger.error(err.error.message, "PaletteLibrary");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "PaletteLibrary");
        }
        this.showLoader = false;
      });
    }
    else if (selected != '' && e.target.checked == false) {
      let data: string[] = [];
      data.push(selected);
      this.dataService.bulkDeleteFavourites(data, "Palettes").subscribe(response => {
        console.log(response,'response');
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.success(this.staticMsgs.paletteLib.remFav);
            this.logger.info(this.staticMsgs.paletteLib.paletteRem, "PaletteLibrary");
          }
          else {
            //console.log(response)
            //alert(response.body['item2'])
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "PaletteLibrary");
            this.showLoader = false;
          }
        }
      }, err => {
        this.logger.error(err.error.message, "PaletteLibrary");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "PaletteLibrary");
        }
        this.showLoader = false;
      });
    }
  }

  searchListData(val: string) {
    if(this.paletteViewMode === 'Shared'){
      if (val !== "") {
        let arr = [];
        this.filterQuery = val;
        this.filteredData = this.sharedGridData.filter((data) => {
          var search = this.filterQuery.toString().toLowerCase();
          var values = Object.values(data);
          var indicator = false;
          values.forEach((vl) => {
            if (String(vl).toLowerCase().indexOf(search) > -1) {
              indicator = true;
              return;
            }
          });
          if (indicator) { return data; }
        });
      }
      else {
        this.filteredData = this.sharedGridData;
        this.state.take = 10;
        //return
      }
    } else {
    if (val !== "") {
      let arr = [];
      this.filterQuery = val;
      this.filteredData = this.paletteData.filter((data) => {
        var search = this.filterQuery.toString().toLowerCase();
        var values = Object.values(data);
        var indicator = false;
        values.forEach((vl) => {
          if (String(vl).toLowerCase().indexOf(search) > -1) {
            indicator = true;
            return;
          }
        });
        if (indicator) { return data; }
      });
    }
    else {
      this.filteredData = this.paletteData;
      this.state.take = 10;
      //return
    }
  }
    if (this.state.sort) this.filteredData = orderBy(this.filteredData, this.state.sort);
    //this.paletteData = this.filteredData;

    var gridView = {
      data: this.filteredData,
      total: this.totalRecordCount,
    };
    this.gridData = gridView;  //process(this.filteredData, this.state);
    this.filteredXcelData = filterBy(this.filteredData, this.state.filter);

  }

  onEditPalette(id) {
    this.storageservice.storeEditPaletteId(id);
    this.dataService.AddClientLog();
    this.router.navigate(['/palettedetails']);
  }

  onEditPaletteDetails(id) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditPaletteId(id);
    this.storageservice.storeEditPaletteIdDetailsId(id);
    this.router.navigate(['/palettedetails']);
  }

  onDeletePalette(id){

    Swal.fire({
      title: this.staticMsgs.paletteLib.deletePalette,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.paletteLib.deleteit
    }).then((result) => {
      if (result.value) {
        this.showLoader=true;
        let deleterecordIds = [];
        deleterecordIds.push(id);
        this.store.dispatch(new paletteActionTypes.DeletePaletteLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedPaletteLibrary) => {
          /*  if (colorLibrary.moodboardState.error === "") {
             if(colorLibrary.moodboardState.ids[0]!== undefined){
             } */
          if (deletedPaletteLibrary.palette.error === "") {
            if (deletedPaletteLibrary.palette.message?.item2 === 'Success') {
              this.success(this.staticMsgs.paletteLib.paletteDeleted1);
              this.logger.info(this.staticMsgs.paletteLib.paletteDeleted, "PaletteLibrary");

                this.onSelect(this.setValue,'myseams')

              this.loadDataList();
              this.showLoader = false;
            } else {
              this.showLoader = false;
              this.error("Failed:" + deletedPaletteLibrary.palette.message?.item1)
              this.logger.error(deletedPaletteLibrary.palette.message?.item1, "PaletteLibrary");
            }

          } else {
            this.logger.error(deletedPaletteLibrary.palette.error.error.message, "PaletteLibrary");
            this.getError(deletedPaletteLibrary.palette.error);
            this.showLoader = false;
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }


  editClick(rowIndex, dataItem ) {
    this.dataService.AddClientLog();
    this.storageservice.storeEditPaletteId(dataItem.id);
    this.storageservice.storeEditPaletteIdDetailsId(dataItem.id);
    this.clickedRowItem = { rowIndex: rowIndex, dataItem: dataItem };
    const dialogRef = this.dialog.open(PaletteCustomComponent,{
      disableClose:true,
    });
    dialogRef.afterClosed().subscribe(result=>{
    this.loadDataList();
    })
  }

  dblClick(e) {
    if (this.clickedRowItem.dataItem.isEdit) {
      this.isEditMode = true;
      var dataindx
      this.closeAllRows();
      if (this.paletteViewMode === 'Shared') {
        // store copy of original documents in case cancelled
        this.originalpaletteData = [...this.sharedGridData];

        // set single row to edit mode to display form fields
        dataindx = this.sharedGridData.indexOf(this.clickedRowItem.dataItem);
      } else {
        this.originalpaletteData = [...this.paletteData];

        // set single row to edit mode to display form fields
        dataindx = this.paletteData.indexOf(this.clickedRowItem.dataItem);
      }
      var Row = this.fa.controls[dataindx] as FormGroup;
      this.grid.editRow(this.clickedRowItem.rowIndex, Row);
    }
    else {
      alert(this.staticMsgs.paletteLib.noPermission);

    }

  }

  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.paletteLib.oops,
      text: message
    })
  }

  get f1() { return this.advForm.controls; }
  onSubmit(value) {
    this.showLoader = true;
    let createdFromChildStr = this.createdStartDate;
    let createdToChildStr = this.createdEndDate;
    let modifiedFromChildStr = this.modifiedStartDate;
    let modifiedToChildStr = this.modifiedEndDate;

    if ((createdFromChildStr !== null && createdToChildStr == null) && (createdFromChildStr !== undefined && createdToChildStr == undefined)) {
      this.error(this.staticMsgs.paletteLib.createTo);
      this.logger.error(this.staticMsgs.paletteLib.createTo, "PaletteLibrary");
      this.showLoader = false;
      return;
    }
    else if ((createdFromChildStr == null && createdToChildStr !== null) && (createdFromChildStr == undefined && createdToChildStr !== undefined)) {
      this.error(this.staticMsgs.paletteLib.createForm);
      this.logger.error(this.staticMsgs.paletteLib.createForm, "PaletteLibrary");

      this.showLoader = false;
      return;
    }
    if ((modifiedFromChildStr !== null && modifiedToChildStr == null) && (modifiedFromChildStr !== undefined && modifiedToChildStr == undefined)) {
      this.error(this.staticMsgs.paletteLib.modifiedTo);
      this.logger.error(this.staticMsgs.paletteLib.modifiedTo, "PaletteLibrary");
      this.showLoader = false;
      return;
    }
    else if ((modifiedFromChildStr == null && modifiedToChildStr !== null) && (modifiedFromChildStr == undefined && modifiedToChildStr !== undefined)) {
      this.error(this.staticMsgs.paletteLib.modifiedFrom);
      this.logger.error(this.staticMsgs.paletteLib.modifiedFrom, "PaletteLibrary");
      this.showLoader = false;
      return;
    }

    let adv_data = {
      name: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.name.value),
      description: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.description.value),
      keyword: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.keyword.value),
      status: this.f1.status.value,
      createdBy: this.aesdecryptservice.encryptData(this.dataService.Key, this.f1.createdBy.value),
      createdFrom: createdFromChildStr,
      createdTo: createdToChildStr,
      modifiedFrom: modifiedFromChildStr,
      modifiedTo: modifiedToChildStr
    }
    //console.log(JSON.stringify(adv_data));
    this.store.dispatch(new commonActionTypes.LoadAdvancedPaletteSearch(adv_data));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((advancedSearchData) => {
      if (advancedSearchData.common.error === "") {
        if (advancedSearchData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.paletteLib.advPaletteLoad, "PaletteLibrary");
          this.cards = this.store.pipe(select(commonReducer.getCommon));
          this.copyCard = this.store.pipe(select(commonReducer.getCommon));
          this.paletteData = advancedSearchData.common.message['item1'];
          this.isResetMode = true;
          this.showLoader = false;
          var gridView = {
            data: this.paletteData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView;
        } else {
          if (advancedSearchData.common.message['item1'].toLowerCase().includes("no records")) {
            this.error(this.staticMsgs.paletteLib.noData1);
            this.logger.error(this.staticMsgs.paletteLib.noData, "PaletteLibrary");
            var gridView = {
              data: this.paletteData,
              total: this.totalRecordCount,
            };
            this.paletteData = [];
            this.cards = of([]);
            this.copyCard = of([]);
            this.gridData = gridView;
            this.showLoader = false;
          } else {
            this.error("Failed:" + advancedSearchData.common.message['item1'])
            this.logger.error(advancedSearchData.common.message['item1'], "PaletteLibrary");
            this.showLoader = false;
            this.clearSearch();
          }

        }
      } else {
        this.logger.error(advancedSearchData.common.error.error.message, "PaletteLibrary");
        this.getError(advancedSearchData.common.error);
        this.showLoader = false;
      }
    })


    /* this.dataService.getAdvanceSearchPalettes(adv_data).subscribe(response => {
      let res = response.body.item1;

      for (let i = 0; i < this.cards.length; i++) {
        res[i].colourHexCodes = this.aesdecryptservice.decryptData(this.dataService.Key, res[i].colourHexCodes);
        res[i].description = this.aesdecryptservice.decryptData(this.dataService.Key, res[i].description);
        res[i].name = this.aesdecryptservice.decryptData(this.dataService.Key, res[i].name);
        res[i].status = this.aesdecryptservice.decryptData(this.dataService.Key, res[i].status);
        res[i].sequence = this.aesdecryptservice.decryptData(this.dataService.Key, res[i].sequence);

      }
      let data1 = [];
      //console.log(JSON.stringify(res.item1));
      if (response.status === 200) {

        if (res.item2.toLowerCase().includes("success")) {
          this.logger.info("advance search Palette data loaded successfully", "PaletteLibrary");
          this.cards = res.item1;
          this.paletteData = res.item1;
          var gridView = {
            data: this.paletteData,
            total: this.totalRecordCount,
          };
          this.gridData = gridView;  //process(this.paletteData, this.state);
          this.isResetMode = true;
          //console.log(JSON.stringify(res.item1));
        }
        else {

          if (res.item1.toLowerCase().includes("no records")) {
            this.error("No data Available!");
            this.paletteData = data1;
            this.logger.error("No data available", "PaletteLibrary");
            this.cards = data1; this.copyCard = data1;
            var gridView = {
              data: this.paletteData,
              total: this.totalRecordCount,
            };
            this.gridData = gridView; //process(this.paletteData, this.state);
          } else {
            this.error(res.item1 + "!");
            this.clearSearch();
          }
        }
      }

      this.showLoader = false;
    }, err => {
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader = false;
      }
      else {
        this.error(err.message);

      }
      this.showLoader = false;
    }); */
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    if ((element.nodeName === 'TH') && (element.offsetWidth < element.scrollWidth)) {
      this.tooltipDir.toggle(element);
    }
    else if ((element.offsetWidth < element.scrollWidth) && (element.scrollWidth! < 937) && (this.isEditMode == false)) {
      this.tooltipDir.toggle(element);
    }
    else {
      this.tooltipDir.hide();
    }
  }

  HandleError(err) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
    }
  }

  setDate(event: any) {
    //console.log(event.target.value);
    if (event.target.getAttribute("formControlName") == "createdFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      //this.createdToChild.nativeElement.value = createdToChildStr;
    }
    else if (event.target.getAttribute("formControlName") == "modifiedFrom") {
      let now = new Date();
      let createdToChildStr = this.dataService.dateFromFormat(now);
      //this.modifiedToChild.nativeElement.value = createdToChildStr;
    }
  };
  clearFields() {
    this.createdStartDate = "";
    this.createdEndDate = "";
    this.createdFromDate = "";
    this.createdToDate = "";
    this.modifiedFromDate = "";
    this.modifiedToDate = "";
    this.modifiedStartDate = "";
    this.modifiedEndDate = "";
    this.f1.name.patchValue("");
    this.f1.description.patchValue("");
    this.f1.keyword.patchValue("");
    this.f1.status.patchValue("");
    this.f1.createdBy.patchValue("");


    $("#statuslist").val('default').selectpicker("refresh");
    this.enableCreatFromVal = null;
    this.enableCreatToVal = null;
    this.enableModifFromVal = null;
    this.enableModifToVal = null;
    this.enableKeywordVal = null;
    this.enableStatusVal = null;
    this.enableCreatedByVal = null;
    this.enableNameVal = null;
    this.enableDescriptionVal = null;
  }
  clearfield(name) {
    switch (name) {
      case "createdFromChild": {
        this.createdStartDate = "";
        this.createdEndDate = "";
        this.createdFromDate = "";
        this.createdToDate = "";
        this.enableCreatFromVal = null;
        this.enableCreatToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "modifiedFromChild": {
        this.modifiedFromDate = "";
        this.modifiedToDate = "";
        this.modifiedStartDate = "";
        this.modifiedEndDate = "";
        this.enableModifFromVal = null;
        this.enableModifToVal = null;
        this.checkAllFieldVal();
        break;
      }
      case "name": { this.f1.name.patchValue(""); this.enableNameVal = null; this.checkAllFieldVal(); break; }
      case "description": { this.f1.description.patchValue(""); this.enableDescriptionVal = null; this.checkAllFieldVal(); break; }
      case "keyword": { this.f1.keyword.patchValue(""); this.enableKeywordVal = null; this.checkAllFieldVal(); break; }
      case "status": { this.f1.status.patchValue(""); $("#statuslist").val('default').selectpicker("refresh"); this.enableStatusVal = null; this.checkAllFieldVal(); break; }
      case "createdBy": { this.f1.createdBy.patchValue(""); this.enableCreatedByVal = null; this.checkAllFieldVal(); break; }

    }
  }

  checkAllFieldVal() {
    if ((this.f1.keyword.value == "") && (this.f1.description.value == "") && (this.f1.name.value == "") && (this.createdFromDate == "") && (this.modifiedFromDate == "") && (this.f1.createdBy.value == "") && ((this.f1.status.value == "") || (this.f1.status.value == undefined))) {
      this.clearSearch()
    }
  }

  checkFieldVal(val: string[], val1: string) {
    switch (val1) {
      case "name": { this.enableNameVal = val; break; }
      case "description": { this.enableDescriptionVal = val; break; }
      case "keyword": { this.enableKeywordVal = val; break; }
      case "status": { this.enableStatusVal = val; break; }
      case "createdFromChild": { this.enableCreatFromVal = val; break; }
      case "createdToChild": { this.enableCreatToVal = val; break; }
      case "modifiedFromChild": { this.enableModifFromVal = val; break; }
      case "modifiedToChild": { this.enableModifToVal = val; break; }
      case "createdBy": { this.enableCreatedByVal = val; break; }


    }
  }

  dateChange(date: any, type: any) {
    switch (type) {
      case 'createdFromDate':
        this.createdStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdFromDate && this.createdFromDate !== undefined) {
          this.checkFieldVal(['val'], 'createdFromChild');
        }
        break;
      case 'createdToDate':
        this.createdEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.createdToDate && this.createdToDate !== undefined) {
          this.checkFieldVal(['val'], 'createdToChild')
        }
        break;
      case 'modifiedFromDate':
        this.modifiedStartDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedFromDate && this.modifiedFromDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedFromChild')
        }
        break;
      case 'modifiedToDate':
        this.modifiedEndDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day);
        if (this.modifiedToDate && this.modifiedToDate !== undefined) {
          this.checkFieldVal(['val'], 'modifiedToChild')
        }
        break;
    }
  }
}
