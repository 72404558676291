import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { StorageService } from '../../../helpers/storage.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../helpers/user.service';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { S3 } from 'aws-sdk';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as seamsReducer from "../../../reducers/seams.reducer";
import * as seamsActionTypes from "../../../actions/seams.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { Router } from '@angular/router';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';

// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
// import {ItemDetailsService} from '../itemdetails/itemdetails.service';
declare var $: any;

@Component({
  selector: 'app-seamsdetails',
  templateUrl: './seamsdetails.component.html',
  styleUrls: ['./seamsdetails.component.css']
})
export class SeamsdetailsComponent implements OnInit {

  // constructor() { }

  public status = [
    { value: 'Approved', label: 'Approved' },
    { value: 'In Review', label: 'In Review' },
    { value: 'Dropped', label: 'Dropped' },
  ];
  thumbnailId: any;
  isFileError = false;
  isFilesAdded:boolean = false;

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  @ViewChild(LoaderComponent) child: LoaderComponent;
  showButton:boolean=false;
  Swal = require('sweetalert2');
  showLoader: boolean;
  showLoader1: boolean;
  thumbNails: File[] = [];
  editedThumbnails: File[] = [];
  isThumbnailAdded: boolean =false;
  activeTab = 'seamsDetailsTab';
  submitted = false;
  submitted1 = false;
  seamsForm: FormGroup;
  notfoundImg: string;
  fileData: Array<any> = [];
  documentsForm: FormGroup;
  editedFileData: Array<any> = [];
  removedDocIds = '';
 seamsDataCnt=0;
  //removedFileData: Array<any> = [];
  editedSeamsData: any;
  files: File[] = [];
  updSeamsList: any[];
  documentClassificationData: any[];
  errors = '';
  errorDiv = false;
  alert = '';
  staticMsgs:any;
  isEditMode: boolean;
  isEditingMode: boolean;
  isViewingMode: boolean;
  isCreateMode: boolean;
  isIDVisible = false;
  selected: any;
  treeview: any;
  editedThumbnailValue = '';
  log: any[];
  documentImage = "../../../../assets/images/doccard/document.png";
  thumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";

  cards = [];

  isSeamsButton: boolean;
  sequenceNumber = ''
  /* Written by : Himanshu */
  imageObject: Array<object> = [];

//   imageObject = [{
//     image: '../../../../assets/images/color-card/1.png',
//     thumbImage: '../../../../assets/images/color-card/1.png',
// }
// ];
  constructor(
    private router:Router,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private dataservice: DataService,
    private storageservice: StorageService,
    public userservice: UserService,
    private logger: LogService,
    private aesdecryptservice: AESDecryptService,
    private _lightbox: Lightbox,
    private store: Store<any>) {
    this.documentsForm = this.fb.group({
      documents: this.fb.array([])
    })

    //this.setDocuments();
  }

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";
    this.dataservice.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    // this.isThumbnailAdded = true;
    if (this.storageservice.getSeamsId()) {
      this.isCreateMode = false;
      if (this.storageservice.getEditedSeamsDetailsId()) {
        this.storageservice.removeSeamsDetailsId();
        this.editSeams();
      }
      else {
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.isIDVisible = true;
        this.getSeamsDetails();
      }
    }
    else {
      this.getDocumentClassification("Documents");
      this.isCreateMode = true;
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isIDVisible = false;
      this.isViewingMode = false;
    }
    setTimeout(() => {
      $('.selectpicker').selectpicker();
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
    }, 10);
    this.initForm();
    this.sharedService.sendClickEvent();
  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataservice.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }
  /*** Seams display table End */
  // Method to initialise the Seams form.
  initForm() {
    this.showLoader = true;
    // const seamsid = sessionStorage.getItem('seamsid');
    // if (seamsid) {

    // }


    this.seamsForm = this.fb.group({
      // seamsclassification: ['', [Validators.required]],
      id: [{ value: '', disabled: true }],
      classification: [''],
      name: ['',[Validators.required,Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      seamType: [''],
      seam751aNumber: ['',  [Validators.pattern('^[a-zA-Z0-9. ]{1,20}$')]],
      isO4916Number: ['', [Validators.pattern('^[a-zA-Z0-9. ]{1,20}$')]],
      commonApplication: ['',[Validators.maxLength(500)]],
      requirements: ['',[Validators.maxLength(500)]],
      seamDrawing: [''],
      sequence: [{ value: '', disabled: true }],
      comments: ['',[Validators.maxLength(500)]],
      status: ['', Validators.required]
    });
    this.showLoader = false;
  }

  numberOnly(e: any) {
    var regex = new RegExp("^[0-9.]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characternameOnly(e: any) {
    var regex = new RegExp("^[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }


  get f() { return this.seamsForm.controls; }

  //get d() { return (this.documentsForm.get('documents') as FormArray).controls; }

  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader = false;
  }

  getSeamsDetails() {
    this.showLoader1 = true;
    //console.log(this.storageservice.getEditedSeamsId())
    this.store.dispatch(new seamsActionTypes.LoadSeams(this.storageservice.getSeamsId()));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((seamsData) => {
      if (seamsData.seams.error === "") {
        if (seamsData.seams.message['item2'] === 'Success') {
          this.showLoader1 = false;
          //console.log(JSON.stringify(data[0]));
          this.editedSeamsData = seamsData.seams.entities[this.storageservice.getSeamsId()];
          this.logger.info(seamsData.seams.message['item1']["seam751aNumber"] + " loaded successfully", "SeamsDetails");
          this.initForm();
          this.getDocumentClassification("Documents");
          this.sequenceNumber = seamsData.seams.message['item1']["sequence"];
          this.reloadDocumentFormData(this.editedSeamsData.documents);
          this.seamsForm.get('id').setValue(seamsData.seams.message['item1']["id"]);
          this.seamsForm.get('name').setValue(seamsData.seams.message['item1']["name"]);
          this.seamsForm.get('seamType').setValue(seamsData.seams.message['item1']["seamType"]);
          this.seamsForm.get('seam751aNumber').setValue(seamsData.seams.message['item1']["seam751aNumber"]);
          this.seamsForm.get('isO4916Number').setValue(seamsData.seams.message['item1']["isO4916Number"]);

          this.seamsForm.get('commonApplication').setValue(seamsData.seams.message['item1']["commonApplication"]);
          this.seamsForm.get('requirements').setValue(seamsData.seams.message['item1']["requirements"]);
          // this.seamsForm.get('seamDrawing').setValue(seamsData.seams.message['item1']["seamDrawingFile"];
          this.seamsForm.get('sequence').setValue(seamsData.seams.message['item1']["sequence"]);
          this.seamsForm.get('comments').setValue(seamsData.seams.message['item1']["comments"]);


          this.seamsForm.get('status').setValue(seamsData.seams.message['item1']["status"]);
          this.refreshSelect();
          /* Written by : Himanshu */
          if (seamsData.seams.message['item1']["seamDrawingFile"]) {
            this.editedThumbnails[0] = seamsData.seams.message['item1']["seamDrawingFile"];
            this.editedThumbnailValue = seamsData.seams.message['item1']["seamDrawingFile"]["thumbnail"];
            this.editedThumbnails = [];
            this.editedThumbnails.push(seamsData.seams.message['item1']["seamDrawingFile"]);
            if(this.editedThumbnails.length > 0){
              this.imageObject=[];
              this.editedThumbnails.forEach((x,idx,array)=>{
                const temp = {
                  'image':x['thumbnail'],
                  'thumbImage':x['thumbnail']
                }
                this.imageObject.push(temp);
              })
            }else{
              const temp = {
                'image':this.thumbnail,
                'thumbImage':this.thumbnail
              }
              this.imageObject.push(temp);
            }

          }
        } else {
          this.showLoader1 = false;
          this.error("Failed:" + seamsData.seams.message['item1'])
          this.logger.error(seamsData.seams.message['item1'], "PaletteDetails");
        }
      } else {
        this.logger.error(seamsData.seams.error.error.message, "PaletteDetails");
        this.getError(seamsData.seams.error);
        this.showLoader1 = false;
      }
    })

    /* this.dataservice.getSeamsById(this.storageservice.getSeamsId()).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.showLoader1 = false;
        //console.log(JSON.stringify(data[0]));
        this.editedSeamsData = response.body['item1'];
        this.logger.info(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["seam751aNumber"])+" loaded successfully","SeamsDetails");
        console.log(JSON.stringify(this.editedSeamsData));
        this.initForm();
        this.reloadDocumentFormData(this. editedSeamsData.documents);
        // this.seamsForm.get('seamsclassification').setValue(response.body['item1']["classification"]);
        //console.log(response.body['item1']["classification"]);
        //this.refreshTree();

      //  this.parentClassification = response.body['item1']["classification"];

        this.seamsForm.get('id').setValue(response.body['item1']["id"]);
        this.seamsForm.get('name').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["name"]));
        this.seamsForm.get('seamType').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["seamType"]));
        this.seamsForm.get('seam751aNumber').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["seam751aNumber"]));
        this.seamsForm.get('isO4916Number').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["isO4916Number"]));

        this.seamsForm.get('commonApplication').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["commonApplication"]));
        this.seamsForm.get('requirements').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["requirements"]));
       // this.seamsForm.get('seamDrawing').setValue(response.body['item1']["seamDrawingFile"]);
        this.seamsForm.get('sequence').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["sequence"]));
        this.seamsForm.get('comments').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["comments"]));


        this.seamsForm.get('status').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["status"]));
        this.refreshSelect();
        if(response.body['item1']["seamDrawingFile"])
        {
          response.body['item1']["seamDrawingFile"].thumbnail=this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["seamDrawingFile"].thumbnail);
          response.body['item1']["seamDrawingFile"].name=this.aesdecryptservice.decryptData(this.dataservice.Key,response.body['item1']["seamDrawingFile"].name);

        this.editedThumbnails[0] =response.body['item1']["seamDrawingFile"];
       this.editedThumbnailValue = response.body['item1']["seamDrawingFile"]["thumbnail"];
        }

      }
      else {
        this.showLoader1 = false;
      }
    },err => {
      this.logger.error(err.error.message,"SeamsDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
        this.showLoader = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.showLoader = false;
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
        this.showLoader = false;
      }
      else {
        this.error(err.message);
        this.logger.error(err.message,"SeamsDetails");

      }
      this.showLoader = false;
    }); */
  }


  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select seams Standard' });
    });
  }


  seamsDetailsTab(activeTab) {
    this.activeTab = activeTab;
    this.isThumbnailAdded = true;
    if (this.storageservice.getSeamsId()) {
      this.getSeamsDetails();
    }

    this.initForm();

    this.loadSelectPicker();
  }

  docDetailsTab(activeTab) {
    this.activeTab = activeTab;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    this.activeTab = activeTab;
    this.showLoader = true;
    this.isFilesAdded=true;
    if (this.editedSeamsData.documents) {

      if (this.editedSeamsData.documents.length > 0) {

        this.editedFileData = [];
        this.removedDocIds = "";

        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }

        let editeddata = JSON.stringify(this.editedSeamsData.documents);
        let data = JSON.parse(editeddata);

        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {

        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }

  getDocumentClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.seamDetail.docLoad, "SeamsDetails");
          this.documentClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.showLoader = false;
        } else {
          this.showLoader = false;
          this.error("Failed:" + classificationData.common.message?.item1)
          this.logger.error(classificationData.common.message?.item1, "SeamsDetails");
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, "SeamsDetails");
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })

    /* this.dataservice.getClassification(module).subscribe(response => {
     if (response.body['item2'] === "Success") {
       let parentclass = response.body['item1'][0]["items"];
       for (let i = 0; i < parentclass.length; i++) {
         parentclass[i].parentName = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i]['parentName']);
         parentclass[i].text = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i]['text']);

         if (parentclass[i].items.length > 0) {
           for (let j = 0; j < parentclass[i].items.length; j++) {
             parentclass[i].items[j].parentName = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i].items[j]['parentName']);
             parentclass[i].items[j].text = this.aesdecryptservice.decryptData(this.dataservice.Key, parentclass[i].items[j]['text']);
           }
         }
       }
       this.documentClassificationData = parentclass;
       this.logger.info("Document classification loaded successfully", "SeamsDetails");
       //this.refreshDocTree();
       this.showLoader = false;
     }
     else {
       this.error(response.body['item1']);
       this.logger.error(response.body['item1'], "SeamsDetails");
       this.showLoader = false;
     }
   }, err => {
     this.logger.error(err.error.message, "SeamsDetails");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
       this.showLoader = false;
     } else if (err.error.message === "The incoming token has expired") {
       this.sessionTimedOut();
       this.showLoader = false;
     } else if (err.error.message === "Unauthorized") {
       this.userservice.error();
       this.showLoader = false;
     }
     else {
       this.error(err.message);
       this.logger.error(err.message, "SeamsDetails");

     }
     this.showLoader = false;
   }); */
  }
  editSeams() {
    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;
    if (this.activeTab === "seamsDetailsTab") {
      this.isThumbnailAdded = true;
      if (this.storageservice.getSeamsId()) {
        this.getSeamsDetails();
      }

      this.initForm();

      this.loadSelectPicker();
    }
    else if (this.activeTab === "docDetailsTab") {

      this.showLoader = true;
      this.showButton=false;
      this.isFilesAdded=true;
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
      if (this.editedSeamsData.documents) {
        if (this.editedSeamsData.documents.length > 0) {
          this.editedFileData = [];
          this.removedDocIds = "";
          //this.removedFileData = [];

          let control = <FormArray>this.documentsForm.controls.documents;
          if (control.length > 0) {
            this.documentsForm = this.fb.group({
              documents: this.fb.array([])
            })
          }
          let editeddata = JSON.stringify(this.editedSeamsData.documents);
          let data = JSON.parse(editeddata);
          this.reloadDocumentFormData(data);
          this.showLoader = false;
        }
        else {
          this.showLoader = false;
        }

      }
      else {
        this.showLoader = false;
      }
    }


  }

  cancelColourEdit() {
    this.isThumbnailAdded = true;
    if (this.storageservice.getSeamsId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getSeamsDetails();
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.initForm();

    this.loadSelectPicker();
  }

  cancelDocumentEdit() {
    this.showButton=false;
    (this.documentsForm.get('documents')as FormArray).clear();
    this.isThumbnailAdded = true;
    if (this.storageservice.getSeamsId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.showLoader = true;
    if (this.editedSeamsData.documents) {
      if (this.editedSeamsData.documents.length > 0) {
        this.editedFileData = [];
        this.removedDocIds = "";
        //this.removedFileData = [];
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        this.isFilesAdded=true;
        let editeddata = JSON.stringify(this.editedSeamsData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }
    }
    else {
      this.showLoader = false;
    }
  }
  // reloadDocumentFormData(data) {
  //   //console.log(JSON.stringify(data));
  //   this.refreshSelect();
  //   for (let i = 0; i < data.length; i++) {
  //     this.addNewDoc();
  //     this.refreshDocTree(i);
  //   }
  //   //console.log(JSON.stringify(data))
  //   let documentControl = this.getControls();
  //   for (let i = 0; i < data.length; i++) {
  //     documentControl[i].get('docid').setValue(data[i]["id"]);
  //     documentControl[i].get('docseq').setValue(data[i]["sequence"]);
  //     this.editedFileData.push(data[i]["files"]);
  //     console.log("editedFileData"+JSON.stringify(data[i]));
  //     this.refreshDocTree(i);
  //     if (this.isEditingMode) {
  //       //console.log("Coming")
  //       this.setDocumentTreeValue(i, data[i]["classification"]);
  //     }
  //     documentControl[i].get('docclassification').setValue(data[i]["classification"]);
  //     documentControl[i].get('docname').setValue(data[i]["name"]);
  //     documentControl[i].get('docstatus').setValue(data[i]["status"]);
  //     documentControl[i].get('docdescription').setValue(data[i]["description"]);
  //     documentControl[i].get('docreportstatus').setValue(data[i]["reportStatus"]);
  //     documentControl[i].get('doclablocation').setValue(data[i]["labLocation"]);
  //     this.refreshSelect();
  //     if (data[i]["classification"] === "Lab Reports") {
  //       documentControl[i].get('docreportstatus').enable();
  //       documentControl[i].get('doclablocation').enable();
  //     }
  //     else {
  //       documentControl[i].get('docreportstatus').disable();
  //       documentControl[i].get('doclablocation').disable();
  //     }
  //     documentControl[i].get('docnotes').setValue(data[i]["notes"]);

  //   }
  // }
  reloadDocumentFormData(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.addNewDoc();
        this.refreshDocTree(i);
      }
      let documentControl = this.getControls();
      for (let i = 0; i < data.length; i++) {
        documentControl[i].get('docid').setValue(data[i]["id"]);
        documentControl[i].get('docseq').setValue(data[i]["sequence"]);
        this.refreshDocTree(i);
        if (this.isEditingMode) {
          this.setDocumentTreeValue(i, data[i]["classification"]);
        }
        documentControl[i].get('docclassification').setValue(data[i]["classification"]);
        documentControl[i].get('docname').setValue(data[i]["name"]);
        documentControl[i].get('docstatus').setValue(data[i]["status"]);
        documentControl[i].get('docdescription').setValue(data[i]["description"]);
        documentControl[i].get('docreportstatus').setValue(data[i]["reportStatus"]);
        documentControl[i].get('doclablocation').setValue(data[i]["labLocation"]);
        if (data[i]["classification"] === "Lab Reports") {
          documentControl[i].get('docreportstatus').enable();
          documentControl[i].get('doclablocation').enable();
        }
        else {
          documentControl[i].get('docreportstatus').disable();
          documentControl[i].get('doclablocation').disable();
        }
        documentControl[i].get('docnotes').setValue(data[i]["notes"]);

        // if (data[i]["files"][0]) {
        //   // this.editedFileData=data[i]["files"];
        //   data[i]["files"][0]['thumbnail'] = data[i]["files"][0]['thumbnail'];
        //   data[i]["files"][0]['name'] = data[i]["files"][0]['name'];
        // }
        this.editedFileData.push(data[i]["files"]);
        this.seamsDataCnt = this.fileData[i].length+1;
        this.isFilesAdded=true;
      }
    }
    /* Written by : Himanshu */
    const temp = {
      'image':this.thumbnail,
      'thumbImage':this.thumbnail
    }
    // this.imageObject.push(temp);
    if (data["seamDrawingFile"]) {
      this.editedThumbnails = [];
      this.editedThumbnails.push(data["seamDrawingFile"]);
    }
  }


  setDocumentTreeValue(index, value) {
    $(document).ready(function () {
      $("#docclassification" + index).data('kendoDropDownTree').value(value);
    })
  }
  addNewDoc() {
    let control = <FormArray>this.documentsForm.controls.documents;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    //alert(control.length)
    control.push(
      this.fb.group({
        docclassification: ['', Validators.required],
        docid: [''],
        docseq: [''],
        docname: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
        docstatus: ['', Validators.required],
        docdescription: ['', [Validators.required, Validators.maxLength(500)]],
        docreportstatus: ['', Validators.required],
        doclablocation: ['', [Validators.required, Validators.maxLength(50)]],
        docnotes: [''],
        docdeletedfileids: ['']
      })
    )
    this.files = [];
    this.fileData[control.length - 1] = this.files;
    this.showButton=true;
    this.refreshDocTree(control.length - 1);
  }

  removeDoc(index) {

    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    if(this.editedFileData.length !== 1){
    if (this.removedDocIds) {
      this.removedDocIds = this.removedDocIds + "," + documentControl[index].get('docid').value
    }
    else {
      this.removedDocIds = documentControl[index].get('docid').value
    }
  }
  else {
    alert('Minimum 1 document is required');
    return false;
  }
    control.removeAt(index)
    this.fileData.splice(index, 1);
    //console.log(this.fileData);
    this.editedFileData.splice(index, 1);
    //this.removedFileData[index].push([]);
  }
  removeImgSection(event, i) {
    this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    //console.log(this.editedFileData);
    if(this.editedFileData[i].length == 0){
      this.isFilesAdded=false;
    }
    let documentControl = this.getControls();
    let docdeltedFiles = documentControl[i].get('docdeletedfileids').value;
    if (docdeltedFiles) {
      documentControl[i].get('docdeletedfileids').setValue(docdeltedFiles + "," + event.id);
    }
    else {
      documentControl[i].get('docdeletedfileids').setValue(event.id);
    }
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }
  // onSelectDoc(event, i) {
  //   this.files = [];
  //   this.files.push(...event.addedFiles);
  //   this.fileData[i] = this.files;
  //   //this[`files${i}`].push(...event.addedFiles);
  //   //console.log(this.fileData);
  // }
  onSelectDoc(event, i) {
    this.files = [];
    let imgext = "png,jpeg,jpg,xlsx,pdf,txt,docx,zip,pptx"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.files.push(...event.addedFiles);
      this.isFilesAdded = true;
      this.doc_Error[i] = '';
    }
    else {
     this.isFilesAdded = false;
      this.error("File Not Supported.")
      return false;
    }
     this.fileData[i] = this.files;
  }

  refreshTree() {
    var data = this.documentClassificationData;
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      var treeview = $("#docclassificationData").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        dataSource: data,
        filter: "startswith",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          //alert(JSON.stringify(value.text))

        }
      });

    });
  }


  getControls() {
    return (this.documentsForm.get('documents') as FormArray).controls;
  }
  refreshDocTree(index: number) {
    var data = this.documentClassificationData;
    var currentDocument = this.getControls();
    //console.log(currentDocument)
    //console.log(currentDocument[index].get('docclassification'))
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML
      //$('input[name="docclassification"'+index+']')
      var treeview = $("#docclassification" + index).kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        filter: "startswith",
        dataSource: data,
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          //alert(value);
          if (value) {
            //self.documentclassificationValue = value["text"];
            //console.log(value["text"])
            currentDocument[index].get('docclassification').setValue(value["text"]);
            if (value["text"] === "Lab Reports") {
              currentDocument[index].get('docreportstatus').enable();
              currentDocument[index].get('doclablocation').enable();
            }
            else {
              currentDocument[index].get('docreportstatus').disable();
              currentDocument[index].get('doclablocation').disable();
            }
          }
          else {
            //self.documentclassificationValue = "";
            currentDocument[index].get('docclassification').setValue("");
          }
        }
      });

    });
  }
  async onSubmit() {

    this.showLoader = true;
    //this.showLoader1=true;
    this.thumbnailId = null;
    var thumbnailName = "";
    //console.log(this.dynamicseamsForm.value);
    this.submitted = true;
    if (!this.seamsForm.valid) {
      this.error(this.staticMsgs.seamDetail.invalidForm)
      this.showLoader = false;
      if(this.thumbNails.length == 0){
        this.isFileError=true;
        }
      return false;

    }
    else if (!this.isThumbnailAdded) {
      this.showLoader = false;
      return false;
    }

    // let re = /\"/gi;
    // this.colourSwatch = this.colourSwatch.replace(re,"'");

    if (this.thumbNails.length === 1) {
      let thumbnailuploaded = await this.uploadFile(this.thumbNails[0]);
      if (thumbnailuploaded != "error") {
        this.thumbnailId = JSON.parse(thumbnailuploaded)["Key"];
        thumbnailName = this.thumbNails[0].name;
      }
      else {
        this.error(thumbnailuploaded);
        this.submitted = false;
        this.showLoader = false;
        return false;
      }
    }
    if (this.isThumbnailAdded) {

      if (this.thumbNails.length === 1) {
        let thumbnailuploaded = await this.uploadFile(this.thumbNails[0]);
        if (thumbnailuploaded != "error") {
          this.thumbnailId = JSON.parse(thumbnailuploaded)["Key"];
          thumbnailName = this.thumbNails[0].name;
        }
        else {
          this.error(thumbnailuploaded);
          this.submitted = false;
          this.showLoader = false;
          return false;
        }
      }

    }
    if (this.thumbnailId == null && this.editedThumbnails.length <= 0) {
      this.isFileError = true;
      this.showLoader = false;
      return false;
    }

    let seamsData = {
      classification: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.classification.value),
      name: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.name.value),
      seamType: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.seamType.value),
      seam751aNumber: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.seam751aNumber.value),
      isO4916Number: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.isO4916Number.value),
      commonApplication: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.commonApplication.value),
      requirements: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.requirements.value),
      sequence: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.sequence.value),
      status: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.status.value),
      id: this.f.id.value,
      comments: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.comments.value),
      seamDrawing: this.thumbnailId
      // trimcost: this.f.trimcost.value
    };



    if (!this.f.id.value) {
      if (this.thumbNails.length === 1) {
        this.isThumbnailAdded = true;
      }
      else {
        this.isThumbnailAdded = false;
      }
      this.store.dispatch(new seamsActionTypes.CreateSeams(seamsData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((newSeamsData) => {
        if (newSeamsData.seams.error === "") {
          if (newSeamsData.seams.message['item2'] === 'Success') {
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.editedSeamsData = newSeamsData.seams.message['item1'];
            this.seamsForm.get('sequence').setValue(newSeamsData.seams.message['item1']["sequence"]);
            if (newSeamsData.seams.message['item1']["seamDrawingFile"]) {
              this.editedThumbnails[0] = newSeamsData.seams.message['item1']["seamDrawingFile"];
              this.editedThumbnailValue = newSeamsData.seams.message['item1']["seamDrawingFile"]["thumbnail"];
            }
            this.storageservice.storeEditedSeamsId(newSeamsData.seams.message['item1'].id);
            //console.log(this.editedSeamsData);
            // this.reloadseamsFormData(response.body['item1'])
            this.logger.info(this.staticMsgs.seamDetail.seamCreated, "SeamsDetails");
            this.success(this.staticMsgs.seamDetail.seamCreated1);
            this.thumbNails = [];
            this.isViewingMode = true;
            this.isEditingMode = false;
            this.getSeamsDetails();
          } else {
            this.showLoader = false;
            this.error("Failed:" + newSeamsData.seams.message['item1'])
            this.logger.error(newSeamsData.seams.message['item1'], "SeamsDetails");
          }
        } else {
          this.logger.error(newSeamsData.seams.error.error.message, "SeamsDetails");
          this.getError(newSeamsData.seams.error);
          this.showLoader = false;
        }
      })

      /* this.dataservice.addSeams(seamsData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader = false;
            //this.alert = 'success';
            // this.errorDiv = true;
            // this.errors = 'Seams 3q Sucessfully.';
            this.submitted = false;
            this.isEditMode = true;
            this.editedSeamsData = response.body['item1'];
            this.seamsForm.get('sequence').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sequence"]));

            // this.seamsForm.get('seamDrawing').setValue(response.body['item1']["seamDrawingFile"]);
            if (response.body['item1']["seamDrawingFile"]) {
              response.body['item1']["seamDrawingFile"].thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["seamDrawingFile"].thumbnail);
              response.body['item1']["seamDrawingFile"].name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["seamDrawingFile"].name);

              this.editedThumbnails[0] = response.body['item1']["seamDrawingFile"];
              this.editedThumbnailValue = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["seamDrawingFile"]["thumbnail"]);
            }
            this.storageservice.storeEditedSeamsId(response.body['item1'].id);
            //console.log(this.editedSeamsData);
            // this.reloadseamsFormData(response.body['item1'])
            this.logger.info("Seams Created Successfully", "SeamsDetails");
            this.success("Seams Created Successfully!");
            this.isViewingMode = true;
            this.isEditingMode = false;
          }
          else {
            console.log(response)
            //this.alert = 'danger';
            //this.errorDiv = true;
            // this.errors = response.body['item2'];
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "SeamsDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "SeamsDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
          this.showLoader = false;
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.showLoader = false;
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
          this.showLoader = false;
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "SeamsDetails");

        }
        this.showLoader = false;
      }); */

    }

    else {

      if (this.editedThumbnails.length === 1) {
        this.isThumbnailAdded = true;
      }
      else {
        if (this.thumbNails.length === 1) {
          this.isThumbnailAdded = true;
        }
        else {
          this.isThumbnailAdded = false;
        }
      }

      let seamsid = { id: this.storageservice.getSeamsId() };
      let updatedSeamsData = { ...seamsData, ...seamsid };
      this.store.dispatch(new seamsActionTypes.UpdateSeams(updatedSeamsData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((seamsData) => {
        if (seamsData.seams.error === "") {
          if (seamsData.seams.message['item2'] === 'Success') {
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            //   this.isEditingMode = false;
            // this.isViewingMode = true;
            this.editedSeamsData = seamsData.seams.message['item1'];
            this.seamsForm.get('sequence').setValue(seamsData.seams.message['item1']["sequence"]);

            // this.seamsForm.get('seamDrawing').setValue(seamsData.seams.message['item1']["seamDrawingFile"]);
            if (seamsData.seams.message['item1']["seamDrawingFile"]) {
              this.editedThumbnails[0] = seamsData.seams.message['item1']["seamDrawingFile"];
              this.editedThumbnailValue = seamsData.seams.message['item1']["seamDrawingFile"]["thumbnail"];
            }
            // this.reloadseamsFormData(response.body['item1'])
            this.getSeamsDetails();
            this.logger.info(this.staticMsgs.seamDetail.seamUpd, "SeamsDetails");
            this.success(this.staticMsgs.seamDetail.seamUpd1);
            this.thumbNails = [];
            this.isViewingMode = true;
            this.isEditingMode = false;

          } else {
            this.showLoader = false;
            this.error("Failed:" + seamsData.seams.message['item1'])
            this.logger.error(seamsData.seams.message['item1'], "SeamsDetails");
          }
        } else {
          this.logger.error(seamsData.seams.error.error.message, "SeamsDetails");
          this.getError(seamsData.seams.error);
          this.showLoader = false;
        }
      })

      /* this.dataservice.updateSeamsById(updatedSeamsData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            //   this.isEditingMode = false;
            // this.isViewingMode = true;
            this.editedSeamsData = response.body['item1'];
            console.log(this.editedSeamsData);
            this.seamsForm.get('sequence').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["sequence"]));

            // this.seamsForm.get('seamDrawing').setValue(response.body['item1']["seamDrawingFile"]);
            if (response.body['item1']["seamDrawingFile"]) {
              response.body['item1']["seamDrawingFile"].thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["seamDrawingFile"].thumbnail);
              response.body['item1']["seamDrawingFile"].name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["seamDrawingFile"].name);

              this.editedThumbnails[0] = response.body['item1']["seamDrawingFile"];
              this.editedThumbnailValue = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1']["seamDrawingFile"]["thumbnail"]);
            }
            //this.reloadseamsFormData(response.body['item1'])
            this.logger.info("Seams Updated Successfully", "SeamsDetails");
            this.success("Seams Updated Successfully!");
            this.isViewingMode = true;
            this.isEditingMode = false;
          }
          else {
            console.log(response)
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "SeamsDetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "SeamsDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "SeamsDetails");
        }
        this.showLoader = false;
      }); */
    }

  }

  doc_Error = [];
  statusForm: boolean = false;
  async documentUploadCheck(documentControl) {
    this.doc_Error = [];
    console.log(this.editedFileData);

   // if (this.editedFileData.length > 0) {
      for (let i = 0; i < documentControl.length; i++) {
        if (this.editedFileData.length > 0) {
          if (i+1 <= documentControl?.length) {
            if (this.editedFileData[i]?.length > 0) {
              this.doc_Error.push('');
            } else {
              if (this.fileData[i].length > 0) {
                this.doc_Error.push('');
              } else {
                this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired);
              }
            }
          } else {
            if (this.fileData.length > 0) {
              if (this.fileData[i].length > 0) {
                this.doc_Error.push('');
              } else {
                this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired)
              }
            } else {
              this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired)
            }
          }

        } else {
          if (this.fileData.length > 0) {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired)
            }
          } else {
            this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired)
          }

        }
      }
   // }

    if (this.doc_Error.length > 0) {
      for (let i = 0; i < this.doc_Error.length; i++) {
        if (this.doc_Error[i] == this.staticMsgs.colorDetail.documentisrequired) {
          this.statusForm = true;
          break;
        } else {
          this.statusForm = false;
        }
      }
    } else {
      this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired);
      this.statusForm = true;
    }
    return this.statusForm;
  }

  async onDocSubmit() {
    this.submitted1 = true;
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();

    //alert(this.findInvalidControlsRecursive(this.documentsForm))
    if (!this.documentsForm.valid) {
      this.error(this.staticMsgs.seamDetail.invalidDocForm);
      return false;
    }

    let statusUploadDoc = await this.documentUploadCheck(documentControl);
    if (statusUploadDoc == true) {
      return false
    }

     this.showLoader = true;
    // this.sharedService.show();

    const formData = new FormData();
    formData.append('seamid', this.storageservice.getSeamsId());
    formData.append('doccount', control.length.toString());
    if (this.removedDocIds) {
      formData.append('docdelete', this.removedDocIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (documentControl[i].get('docid').value) {
        formData.append('docid' + '[' + i + ']', documentControl[i].get('docid').value);
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
      }
      else {
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
      }

      if (documentControl[i].get('docdeletedfileids').value) {
        formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
      }

      formData.append('baseclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Documents"));
      formData.append('docclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docclassification').value));
      formData.append('docname' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docname').value));
      formData.append('docstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docstatus').value));
      formData.append('docdescription' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docdescription').value));
      formData.append('docreportstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docreportstatus').value));
      formData.append('doclablocation' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('doclablocation').value));
      formData.append('docnotes' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docnotes').value));
      formData.append('docfilecount' + '[' + i + ']', this.fileData[i].length);

      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '[]') {
          //console.log (f.name);
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != "error") {
            formData.append('file' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, JSON.parse(fileUploadedData)["Key"]));
            formData.append('filename' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
          }
          else {
            this.error(fileUploadedData);
            this.submitted1 = false;
            this.showLoader = false;
            return false;
          }
        }
        a = a + 1;
      }
    }

    this.test(formData);
    this.store.dispatch(new seamsActionTypes.UpdateSeamsDocument(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((seamsDocData) => {
      if (seamsDocData.seams.error === "") {
        if (seamsDocData.seams.message['item2'] === 'Success') {
          this.showLoader = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedFileData = [];
          this.removedDocIds = "";
          this.editedSeamsData = seamsDocData.seams.entities[this.storageservice.getSeamsId()];
          if (this.editedSeamsData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            let editeddata = JSON.stringify(this.editedSeamsData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          // this.docDetailsTab('docDetailsTab');
          this.showLoader = false;
          this.success(this.staticMsgs.seamDetail.docUpd1);
          this.showLoader = false;
          this.logger.info(this.staticMsgs.seamDetail.docUpd, "SeamsDetails");

        } else {
          this.showLoader = false;
          this.error("Failed:" + seamsDocData.seams.message['item1'])
          this.logger.error(seamsDocData.seams.message['item1'], "SeamsDetails");
        }
      } else {
        this.logger.error(seamsDocData.seams.error.error.message, "SeamsDetails");
        this.getError(seamsDocData.seams.error);
        this.showLoader = false;
      }
    })


    /* this.dataservice.updateSeamsDocuments(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          //console.log(JSON.stringify(response.body['item1']));
          this.showLoader = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedFileData = [];
          this.removedDocIds = "";
          this.editedSeamsData = response.body['item1'];
          //console.log(this.editedColorData);
          if (this.editedSeamsData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            let editeddata = JSON.stringify(this.editedSeamsData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          //this.docDetailsTab('docDetailsTab');
          this.logger.info("Documents Updated Successfully", "SeamsDetails");
          this.success("Documents Updated Successfully!");
        }
        else {
          //console.log(response)
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "SeamsDetails");
          this.showLoader = false;
        }

      }
    }, err => {
      this.logger.error(err.error.message, "SeamsDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "SeamsDetails");
      }
      this.showLoader = false;
    }); */

  }


  isEmptyObject(obj) {
    return JSON.stringify(obj) === '[]';
  }

  openThumbnail(imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedThumbnails.length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedThumbnails[i - 1]["name"])) {
        src = this.editedThumbnails[i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedThumbnails[i - 1]["thumbnail"];
      }
      else {
        src = this.editedThumbnails[i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedThumbnails[i - 1]["thumbnail"];
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  test(formData) {
    for (var key of formData.entries()) {
    }
  }
  open(index: number, imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedFileData[index].length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedFileData[index][i - 1]["name"])) {
        src = this.editedFileData[index][i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedFileData[index][i - 1]["thumbnail"];
      }
      else {
        src = this.editedThumbnails[i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedThumbnails[i - 1]["thumbnail"];
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.seamDetail.oops,
      text: message
    })
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.seamDetail.session,
      html: this.staticMsgs.seamDetail.sessExp,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
        return;
      }
    })

  }
  onSelect(event,i) {
    this.thumbNails = [];
    let imgext = "png,jpeg,jpg"
    var str = event.addedFiles[0].name.split('.');
    this.thumbNails.push(...event.addedFiles);
    if (this.thumbNails.length === 1 && imgext.includes(str[1])){
      this.fileData[i] = this.thumbNails;
      this.isThumbnailAdded = true;
    }
    else {
      this.isThumbnailAdded = false;
    }
    if (this.thumbNails && this.thumbNails.length > 0) {
      this.isFileError = false;
    }
  }
  onRemove(event, i) {
    //this.fileData[i].splice(this.fileData[i].indexOf(event), 1);
    this.fileData.splice(this.fileData.indexOf(event), 1);
    this.isThumbnailAdded=false;
    //console.log(this.fileData);
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }
  onRemoveImg(event) {
    this.thumbNails.splice(this.thumbNails.indexOf(event), 1);
    if (this.thumbNails.length === 1) {
      this.isThumbnailAdded = true;
    }
    else {
      this.isFileError = true;
      this.isThumbnailAdded = false;
    }
  }
  removeThumbnailSection(event) {
    this.editedThumbnails.splice(this.editedThumbnails.indexOf(event), 1);
    this.isThumbnailAdded=false;
  }
  async uploadFile(file): Promise<any> {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          reject("error");
        }

        resolve(JSON.stringify(data));
      });
    });
    //for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
  };

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  isValidImageURL(str) {
    str = str.toLowerCase();
    str = str.split('?')[0];
    //moving on, split the uri into parts that had dots before them
    var parts = str.split('.');
    //get the last part ( should be the extension )
    var extension = parts[parts.length - 1];
    //define some image types to test against
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    //check if the extension matches anything in the list.
    if (imageTypes.indexOf(extension) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  mandatoryError(message: string) {
    Swal.fire({
      icon: 'error',
      title: '',
      text: message
    })
  }
  cancelSeamsEdit() {
    (this.documentsForm.get('documents')as FormArray).clear();
    if (this.storageservice.getSeamsId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getSeamsDetails();
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }
    this.initForm();
    ///this.loadSelectPicker();
  }
  cancelSeamsCreate() {
    this.router.navigate(['/seamslibrary']);
  }
  onDeleteSeams() {

    Swal.fire({
      title: this.staticMsgs.seamDetail.deleteSeam,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.seamDetail.deleteit
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getSeamsId());
        this.store.dispatch(new seamsActionTypes.DeleteSeamsLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedSeamsLibrary) => {
          /*  if (colorLibrary.moodboardState.error === "") {
             if(colorLibrary.moodboardState.ids[0]!== undefined){
             } */
          if (deletedSeamsLibrary.seams.error === "") {
            if (deletedSeamsLibrary.seams.message?.item2 === 'Success') {
              this.success(this.staticMsgs.seamDetail.seamDeleted1);
              this.logger.info(this.staticMsgs.seamDetail.seamDeleted, "SeamsLibrary");
              this.router.navigate(["/seamslibrary"]);
              this.showLoader = false;
            } else {
              this.showLoader = false;
              this.error("Failed:" + deletedSeamsLibrary.seams.message?.item1)
              this.logger.error(deletedSeamsLibrary.seams.message?.item1, "SeamsLibrary");
            }

          } else {
            this.logger.error(deletedSeamsLibrary.seams.error.error.message, "SeamsLibrary");
            this.getError(deletedSeamsLibrary.seams.error);
            this.showLoader = false;
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }
}
