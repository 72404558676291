import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DataService } from '../../../services/data.service';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import { SharedService } from '../../../services/shared.service';
import { IColor, ColourDocuments } from '../../../models/color';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Lightbox } from 'ngx-lightbox';
import * as S3 from 'aws-sdk/clients/s3';
import { LogService } from '../../../shared/log.service';
import { select, Store } from '@ngrx/store';
import * as supplierReducer from "../../../reducers/supplier.reducer";
import * as supplierActionTypes from "../../../actions/supplier.actions";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { map, skip, take } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';

declare var $: any;
import { allCountries } from './country';

@Component({
  selector: 'app-supplierdetails',
  templateUrl: './supplierdetails.component.html',
  styleUrls: ['./supplierdetails.component.css'],
  providers: [
  ]
})

export class SupplierdetailsComponent implements OnInit {

  thumbnail: string = "https://autouplinkse.com/wp-content/uploads/2018/11/Facebook-Marketplace-Customer-Icon.png";

  imageObject1 = [{
    image: '../../../../assets/images/suppliercard/agent.png',
    thumbImage: '../../../../assets/images/suppliercard/agent.png',
  }]

  imageObject2 = [{
    image: '../../../../assets/images/suppliercard/factory.png',
    thumbImage: '../../../../assets/images/suppliercard/factory.png',
  }]

  imageObject3 = [{
    image: '../../../../assets/images/suppliercard/raw.png',
    thumbImage: '../../../../assets/images/suppliercard/raw.png',
  }]

  activeTab = 'supplierDetailsTab';
  showLoader: boolean;
  showLoader1: boolean;
  agent = true;
  titleAgent = true;
  titlefactory = false;
  titleraw = false;
  rawmaterial = false;
  secondcontact = true;
  secondcontact2 = false;
  factoryrawmaterial = false;
  chainPartner = false;
  ptype = false;
  DocLab = false;
  machineType = false;
  selectvalue: any;
  Contact: any[];
  pricontact: any[];
  moqee: any;
  capacityee: any;
  leadTimeee: any;
  files: File[] = [];
  public countrycodes: any[] = [];
  supplierDocForm: FormGroup;
  submitted = false;
  submitted1 = false;
  isEditMode: boolean;
  editedFileData: Array<any> = [];
  editedSupplierData: any;
  isCreateMode: boolean;
  documentClassificationData: any[];
  supplierClassificationData: any[];
  isEditingMode: boolean;
  isViewingMode: boolean;
  supplierclassificationValue: string;
  cards = [];
  conta: any;
  secondarycontactId = "";
  //removedFileData: Array<any> = [];
  removedDocIds = '';
  fileData: Array<any> = [];
  isThumbnailAdded: boolean;
  dropdownEnabled = true;
  documentsForm: FormGroup;
  items: TreeviewItem[];
  phone_number = '';
  values: number[];
  imageLibraryForm: FormGroup;
  supplierLibraryForm: FormGroup;
  documentImage = "../../../../assets/images/doccard/document.png";
  treeview: any;
  machineTypearr = '';
  sequenceNumber = '';
  startDate: any = null;
  endDate: any = null;
  datePickerDate: any = '';
  alwaysShowCalendars: boolean;
  public supplierDataCnt=0;
  notfoundImg:string;
  showButton:boolean=false;
  @Input()
  maxlength: string | number | null
  @ViewChild('lastSocialAudit') lastSocialAuditChild: any;

  productTypeOptions:any = [
    {id: "Knit Fabric",value: "Knit Fabric"},
    {id: "Woven Fabric",value: "Woven Fabric"},
    {id: "Combo Fabric",value: "Combo Fabric"},
    {id: "Leather Fabric",value: "Leather Fabric"},
    {id: "Packaging",value: "Packaging"},
    {id: "Sew in Label",value: "Sew in Label"},
    {id: "Price Ticketing",value: "Price Ticketing"},
    {id: "Hang Tag",value: "Hang Tag"},
    {id: "Box",value: "Box"},
    {id: "RFID / Security Tag",value: "RFID / Security Tag"},
    {id: "Sticker",value: "Sticker"},
    {id: "Poly Bag",value: "Poly Bag"},
    {id: "Hanger",value: "Hanger"},
    {id: "Hook",value: "Hook"},
    {id: "Pad",value: "Pad"},
    {id: "MigrationTest",value: "MigrationTest"},
    {id: "Embroidery",value: "Embroidery"},
    {id: "Eyelet",value: "Eyelet"},
    {id: "Bow",value: "Bow"},
    {id: "Buckle",value: "Buckle"},
    {id: "Felt",value: "Felt"},
    {id: "Rivet",value: "Rivet"},
    {id: "Toggle / Stopper",value: "Toggle / Stopper"},
    {id: "Tape",value: "Tape"},
    {id: "Hook & Eye",value: "Hook & Eye"},
    {id: "Puller",value: "Puller"},
    {id: "Hook & Bar",value: "Hook & Bar"},
    {id: "Lace",value: "Lace"},
    {id: "Fill",value: "Fill"},
    {id: "Press Fastner",value: "Press Fastner"},
    {id: "Zipper",value: "Zipper"},
    {id: "Wire",value: "Wire"},
    {id: "Button",value: "Button"},
    {id: "Thread",value: "Thread"},
    {id: "Interlining",value: "Interlining"},
    {id: "Stud",value: "Stud"},
    {id: "Elastic",value: "Elastic"},
    {id: "Velcro",value: "Velcro"},
    {id: "Ring & Slide",value: "Ring & Slide"},
    {id: "Badge",value: "Badge"},
    {id: "Hanger Loop",value: "Hanger Loop"},
  ]

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400,
  });

  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark'
  ];
  buttonClass = this.buttonClasses[0];

  imageObject = [{
    image: '../../../../assets/images/color-card/1.png',
    thumbImage: '../../../../assets/images/color-card/1.png',
}
];

  classification = [
    { value: 'agent', label: 'Agent' },
    { value: 'factory', label: 'Factory' },
    { value: 'rawmaterial', label: 'Raw Material Supplier' }
  ];

  number = [
    { value: '91', label: '+91' },
    { value: '265', label: '+265' },
    { value: '222', label: '+222' }
  ];

  auditstatus = [
    { value: 'passed', label: 'Passed' },
    { value: 'failed', label: 'Failed' },
    { value: 'underreview', label: 'UnderReview' }
  ];

  country = allCountries;
  allStates: any = [];

  state = [
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
  ];

  city = [
    { value: 'Pune', label: 'Pune' },
    { value: 'Mumbai', label: 'Mumbai' },
  ];

  chain = [
    { value: 'agent', label: 'Agent' },
    { value: 'factory', label: 'Factory' },
    { value: 'goodsupplier', label: 'Finished Good Supplier' },
    { value: 'rawmaterial', label: 'Raw Material Supplier' }
  ];


  product = [
    { value: 'Mixed', label: 'Mixed' },
    { value: 'Wovan', label: 'Wovan' },
    { value: 'NonWovan', label: 'Non Wovan' },
    { value: 'Leather', label: 'Leather' }
  ];

  speciality = [
    { value: 'Knits', label: 'Knits' },
    { value: 'Wovan', label: 'Wovan' },
    { value: 'Trims', label: 'Trims' },
    { value: 'Packaging', label: 'Packaging' },
    { value: 'Leather', label: 'Leather' }

  ];

  capacity = [
    { value: 'Units', label: 'Units' },
    { value: 'Sq.Yds', label: 'Sq.Yds' }
  ];

  minDate = { year: 1985, month: 1, day: 1 };
  // maxDate= new Date();
  public today = new Date();
  public maxDate = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };

  lastSocialAuditDate: NgbDate;
  contactTemp0: any;
  contactTemp1: any;
  lastAudit: any;
  isFilesAdded: boolean=false;

  secondContact() {
    this.secondcontact2 = true;
    this.secondcontact = false;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
  }


  secondContact2() {
    this.secondarycontactId = this.f.Contactss.value['contactOrder2']['id'];
    const contactForm = (this.supplierLibraryForm.controls['Contactss'] as FormGroup);
    //const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
    const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
    contact2.setValue({
      fullName: "",
      officeNo: "",
      ext: "",
      fax: "",
      contactNo: "",
      contactEmail: "",
      address: "",
      country: "",
      state: "",
      city: "",
      contactOrder: "",
      zipCode: "",
      id: ""

    })

    this.secondcontact2 = false;
    this.secondcontact = true;

    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
  }




  onOptionsSelected(value: string) {
    this.selectvalue = value;
    if (this.selectvalue == 'agent') {
      this.agent = true;
      this.titleAgent = true;
      this.chainPartner = false;
      this.titlefactory = false;
      this.ptype = false;
      this.titleraw = false;
      this.factoryrawmaterial = false;
      this.machineType = false;
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
    }
    else if ((this.selectvalue == 'factory')) {
      this.factoryrawmaterial = true;
      this.titleAgent = false;
      this.titleraw = false;
      this.titlefactory = true;
      this.chainPartner = true;
      this.ptype = true;
      this.machineType = true;
      //  this.agent = false;
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
    }
    else if ((this.selectvalue == 'rawmaterial')) {
      this.factoryrawmaterial = true;
      this.titleraw = true;
      this.titleAgent = false;
      this.titlefactory = false;
      this.chainPartner = true;
      this.ptype = true;
      this.machineType = true;
      //  this.agent = false;
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
    }
  }

  todo = [
    '1 Needle Lockstitch- 14',
    '1 Needle Lockstitch- 5',
    'ZigZag- 2',
    'CoverStitch- 2',
    'Overlock- 3'
  ];

  done = [

  ];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  isEmptyObject(obj) {
    return JSON.stringify(obj) === '[]';
  }
  onFilterChange(value: string): void {
    console.log('filter:', value);
  }

  onSelectedChange(value: string): void {
    console.log('filter:', value);
    if (value == '23') {
      this.DocLab = true;
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
    }
    else {
      this.DocLab = false;
    }
  }
   staticMsgs:any;
  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    public userservice: UserService,
    private dataservice: DataService,
    private storageservice: StorageService,
    private router: Router,
    private logger: LogService,
    public aesdecryptservice: AESDecryptService,
    private _lightbox: Lightbox,

    private store: Store<any>) {
    this.documentsForm = this.fb.group({
      documents: this.fb.array([])
    })

    //this.setDocuments();
  }

  ngOnInit(): void {
    this.notfoundImg = "../../../../assets/images/notfound/NotFound.png";

    this.staticMsgs=appStaticMsg.data[0];
    console.log(this.maxDate,'today');
    this.dataservice.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    $(document).ready(function () {
      $('#date-time-picker-1').datetimepicker({
        format: 'L'
        //icons: calIcons
      });
    });

    this.getCountrycodes();
    this.createEmployeeLunchEntryForm();
    if (this.storageservice.getEditedSupplierId()) {
      if (this.storageservice.getEditedSupplierDetailsId()) {
        console.log('console 1')
        this.storageservice.removeSupplierDetailsId();
        this.editSupplier();
      }else {
        console.log('console 3')
        this.isCreateMode = false;
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.getSupplierDetails();
      }
    }else {
      console.log('console 4')
      this.getSupplierClassification("Suppliers");
      this.isCreateMode = true;
      this.isEditMode = false;
      this.isEditingMode = false;
      this.isViewingMode = false;
    }
    this.initForm();
    $('.selectpicker').selectpicker();
    this.sharedService.sendClickEvent();

  };
  currentDateObj:any;
  createEmployeeLunchEntryForm() {
    const currentDate = new Date();
     this.currentDateObj = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
      day: currentDate.getDay()
    };
    // console.log(this.currentDateObj,'date');
  }
  async getCountrycodes() {
    const data = await this.dataservice.getCountrycodes();
    this.countrycodes = this.countrycodes.concat(data['countrycodes']);
    this.refreshSelect();

  }

  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }
  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select supplier Standard' });
    });
  }
  editSupplier() {
    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;
    this.isCreateMode = false;
    if (this.activeTab === "supplierDetailsTab") {
      this.isThumbnailAdded = true;
      if (this.storageservice.getEditedSupplierId()) {
        this.getSupplierDetails();
      }

      this.initForm();

      this.loadSelectPicker();
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
    }
    else if (this.activeTab === "docDetailsTab") {
      this.getDocumentClassification("Documents");
      this.showLoader1 = true;
      this.showButton=false;
      this.loadSelectPicker();
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 10);
      if (this.editedSupplierData.documents) {
        if (this.editedSupplierData.documents.length > 0) {
          this.editedFileData = [];
          this.removedDocIds = "";
          //this.removedFileData = [];
          this.isFilesAdded = true;
          let control = <FormArray>this.documentsForm.controls.documents;
          if (control.length > 0) {
            this.documentsForm = this.fb.group({
              documents: this.fb.array([])
            })
          }
          let editeddata = JSON.stringify(this.editedSupplierData.documents);
          let data = JSON.parse(editeddata);
          this.reloadDocumentFormData(data);
          this.showLoader1 = false;
        }
        else {
          this.showLoader1 = false;
        }

      }
      else {
        this.showLoader1 = false;
      }
    }


  }

  supplierDetailsTab(activeTab) {
    this.activeTab = activeTab;
    if (this.storageservice.getEditedSupplierId()) {
      this.getSupplierDetails();
    }
    this.initForm();
    this.loadSelectPicker();
  }
  docDetailsTab(activeTab) {
    this.activeTab = activeTab;
    this.getDocumentClassification("Documents");
    this.loadSelectPicker();
    this.showLoader1 = true;
    if (this.editedSupplierData.documents) {
      if (this.editedSupplierData.documents.length > 0) {
        this.editedFileData = [];
        this.removedDocIds = "";
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        this.isFilesAdded = true;
        let editeddata = JSON.stringify(this.editedSupplierData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader1 = false;
      }
      else {
        this.showLoader1 = false;
      }
    }
    else if (this.editedSupplierData.documents) {
      this.showLoader1 = false;
    }
  }


  getDocumentClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.logger.info( this.staticMsgs.supplierDetails.DocumnetClassificationLoadSuccess,  this.staticMsgs.supplierDetails.supplierdetails);
          this.documentClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.showLoader1 = false;
        } else {
          this.showLoader1 = false;
          this.error("Failed:" + classificationData.common.message?.item1)
          this.logger.error(classificationData.common.message?.item1,  this.staticMsgs.supplierDetails.supplierdetails);
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, this.staticMsgs.supplierDetails.supplierdetails);
        this.getError(classificationData.common.error);
        this.showLoader1 = false;
      }
    })

    /* this.dataservice.getClassification(module).subscribe(response => {
     if (response.body['item2'] === "Success") {
       let parentclass=response.body['item1'][0]["items"];
       for(let i=0;i<parentclass.length;i++){
         parentclass[i].parentName= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i]['parentName']);
         parentclass[i].text= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i]['text']);

         if(parentclass[i].items.length>0){
             for(let j=0;j<parentclass[i].items.length;j++){
               parentclass[i].items[j].parentName= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i].items[j]['parentName']);
               parentclass[i].items[j].text= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i].items[j]['text']);
             }
           }
         }
       this.documentClassificationData = parentclass;
       this.logger.info("Document classification loaded successfully","SupplierDetails");
       //this.refreshDocTree();
       this.showLoader1 = false;
     }
     else {
       this.showLoader1 = false;
       this.error(response.body['item1']);
       this.logger.error(response.body['item1'],"SupplierDetails");
     }
   }, err => {
     //console.log(err)
     this.logger.error(err.error.message,"SupplierDetails");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
       this.showLoader1 = false;
     } else if (err.error.message === "The incoming token has expired") {
       this.error(err.error.message);
       this.showLoader1 = false;
       this.sessionTimedOut();
       this.userservice.logout()
     } else if (err.error.message === "Unauthorized") {
       this.showLoader1 = false;
       this.userservice.error();
     }
     else {
       this.error(err.error.message);
       this.logger.error(err.error.message,"SupplierDetails");
       this.showLoader1 = false;
       this.userservice.error();
     }
   }) */
  }

  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.supplierDetails.SessionExpired,
      html: this.staticMsgs.supplierDetails.loginAgain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        //this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        //console.log('Session Timedout.')
        return;
      }
    })

  }

  success(message: string) {
    Swal.fire(
      'Success',
      //'Supplier deleted successfully',
      message,
      this.staticMsgs.supplierDetails.success
    )
  }

  error(message: string) {
    Swal.fire({
      icon: this.staticMsgs.supplierDetails.error,
      title: this.staticMsgs.supplierDetails.Oops,
      text: message
    })
  }
  setDocumentTreeValue(index, value) {
    $(document).ready(function () {
      $("#docclassification" + index).data('kendoDropDownTree').value(value);
    })
  }
  reloadDocumentFormData(data) {
    for (let i = 0; i < data.length; i++) {

      this.addNewDoc();
      this.refreshDocTree(i);
    }
    //console.log(JSON.stringify(data))
    let documentControl = this.getControls();
    for (let i = 0; i < data.length; i++) {
      documentControl[i].get('docid').setValue(data[i]["id"]);
      documentControl[i].get('docseq').setValue(data[i]["sequence"]);
      this.refreshDocTree(i);
      if (this.isEditingMode) {
        this.setDocumentTreeValue(i, data[i]["classification"]);
      }
      documentControl[i].get('docclassification').setValue(data[i]["classification"]);
      documentControl[i].get('docname').setValue(data[i]["name"]);
      documentControl[i].get('docstatus').setValue(data[i]["status"]);
      documentControl[i].get('docdescription').setValue(data[i]["description"]);
      documentControl[i].get('docreportstatus').setValue(data[i]["reportStatus"]);
      documentControl[i].get('doclablocation').setValue(data[i]["labLocation"]);
      if (data[i]["classification"] === "Lab Reports") {
        documentControl[i].get('docreportstatus').enable();
        documentControl[i].get('doclablocation').enable();
      }
      else {
        documentControl[i].get('docreportstatus').disable();
        documentControl[i].get('doclablocation').disable();
      }
      documentControl[i].get('docnotes').setValue(data[i]["notes"]);
      this.editedFileData.push(data[i]["files"]);
      this.supplierDataCnt = this.fileData[i].length+1;
      this.isFilesAdded = true;
    }
  }


  doc_Error = [];
  statusForm: boolean = false;
  async documentUploadCheck(documentControl) {
    this.doc_Error = []
    console.log(this.editedFileData);

    // if (this.editedFileData.length > 0) {
    for (let i = 0; i < documentControl.length; i++) {
      if (this.editedFileData.length > 0) {
        if (i + 1 <= documentControl?.length) {
          if (this.editedFileData[i]?.length > 0) {
            this.doc_Error.push('');
          } else {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.supplierDetails.documentisrequired);
            }
          }
        } else {
          if (this.fileData.length > 0) {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.supplierDetails.documentisrequired)
            }
          } else {
            this.doc_Error.push(this.staticMsgs.supplierDetails.documentisrequired)
          }
        }

      } else {
        if (this.fileData.length > 0) {
          if (this.fileData[i].length > 0) {
            this.doc_Error.push('');
          } else {
            this.doc_Error.push(this.staticMsgs.supplierDetails.documentisrequired)
          }
        } else {
          this.doc_Error.push(this.staticMsgs.supplierDetails.documentisrequired)
        }

      }
    }
    // }

    if (this.doc_Error.length > 0) {
      for (let i = 0; i < this.doc_Error.length; i++) {
        if (this.doc_Error[i] == this.staticMsgs.supplierDetails.documentisrequired) {
          this.statusForm = true;
          break;
        } else {
          this.statusForm = false;
        }
      }
    } else {
      this.doc_Error.push(this.staticMsgs.supplierDetails.documentisrequired);
      this.statusForm = true;
    }
    return this.statusForm;
  }

  async onDocSubmit() {
    this.submitted1 = true;
    if (!this.documentsForm.valid) {
      this.error("Invalid Document Form");
      return false;
    }

    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();

    let statusUploadDoc = await this.documentUploadCheck(documentControl);
    if (statusUploadDoc == true) {
      return false
    }
    this.showLoader1 = true;
    const formData = new FormData();
    formData.append('supplierid', this.storageservice.getEditedSupplierId());
    formData.append('doccount', control.length.toString());
    if (this.removedDocIds) {
      formData.append('docdelete', this.removedDocIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (documentControl[i].get('docid').value) {
        formData.append('docid' + '[' + i + ']', documentControl[i].get('docid').value);
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
      }
      else {
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
      }

      if (documentControl[i].get('docdeletedfileids').value) {
        formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
      }

      formData.append('baseclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Documents"));
      formData.append('docclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docclassification').value));
      formData.append('docname' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docname').value));
      formData.append('docstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docstatus').value));
      formData.append('docdescription' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docdescription').value));
      formData.append('docreportstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docreportstatus').value));
      formData.append('doclablocation' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('doclablocation').value));
      formData.append('docnotes' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docnotes').value));

      if (this.fileData[i] == 'null') {
        for (let i = 0; i < this.fileData.length; i++) {
          if (this.fileData[i] = 'null') {
            this.fileData[i] = [];
          }
        }
      }

      formData.append('docfilecount' + '[' + i + ']', this.fileData[i].length);

      this.supplierDataCnt = this.fileData[i].length + 1;
      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '[]') {
          //console.log (f.name);
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != "error") {
            formData.append('file' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, JSON.parse(fileUploadedData)["Key"]));
            formData.append('filename' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
          }
          else {
            this.error(fileUploadedData);
            this.submitted1 = false;
            this.showLoader1 = false;
            return false;
          }
        }
        a = a + 1;
      }
    }

    //console.log(formData);
    // this.test(formData);
    this.store.dispatch(new supplierActionTypes.UpdateSupplierDocument(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((supplierDocData) => {
      if (supplierDocData.supplier.error === "") {
        if (supplierDocData.supplier.message['item2'] === 'Success') {
          this.loadSelectPicker();
          // setTimeout(() => {
          // $('.selectpicker').selectpicker();
          // }, 10);
          //console.log(JSON.stringify(response.body['item1']));
          this.showLoader1 = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode=false;
          this.isViewingMode=true;
          this.documentsForm.reset();
          this.editedFileData = [];
          this.removedDocIds = "";
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedSupplierData = supplierDocData.supplier.message['item1'];
          console.log(this.editedSupplierData);
          if (this.editedSupplierData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            this.isFilesAdded = true;
            let editeddata = JSON.stringify(this.editedSupplierData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          // this.docDetailsTab('docDetailsTab');
          this.success(this.staticMsgs.supplierDetails.DocuentUpdated);
          this.logger.info(this.staticMsgs.supplierDetails.DocuentUpdated, this.staticMsgs.supplierDetails.supplierdetails);
          console.log(this.isEditMode,this.isEditingMode,this.isViewingMode , this.isCreateMode,'data')

        } else {
          console.log(this.isEditMode,this.isEditingMode,this.isViewingMode , this.isCreateMode,'data')

          this.showLoader1 = false;
          this.error("Failed:" + supplierDocData.supplier.message['item1'])
          this.logger.error(supplierDocData.supplier.message['item1'], this.staticMsgs.supplierDetails.StichesDetails);
        }
      } else {
        console.log(this.isEditMode,this.isEditingMode,this.isViewingMode , this.isCreateMode,'data')

        this.logger.error(supplierDocData.supplier.error.error.message, this.staticMsgs.supplierDetails.StichesDetails);
        this.getError(supplierDocData.supplier.error);
        this.showLoader1 = false;
      }
    })

    /* this.dataservice.updateSupplierDocuments(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.loadSelectPicker();
          // setTimeout(() => {
          // $('.selectpicker').selectpicker();
          // }, 10);
          //console.log(JSON.stringify(response.body['item1']));
          this.showLoader1 = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.documentsForm.reset();
          this.editedFileData = [];
          this.removedDocIds = "";
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedSupplierData = response.body['item1'];
          console.log(this.editedSupplierData);
          if (this.editedSupplierData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            let editeddata = JSON.stringify(this.editedSupplierData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          //this.docDetailsTab('docDetailsTab');
          this.success("Documents Updated Successfully!");
        this.logger.info("Documents Updated Successfully","SupplierDetails");

        }
        else {
          console.log(response)
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'],"SupplierDetails");
          this.showLoader1 = false;
        }

      }
    }, err => {
      this.logger.error(err.error.message,"SupplierDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message,"SupplierDetails");
      }
      this.showLoader1 = false;
    }); */

  }

  test(formData) {
    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1])
    }
  }

  async uploadFile(file): Promise<any> {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          //console.log('There was an error uploading your file: ', err);
          reject("error");
        }
        //console.log('Successfully uploaded file.', data);
        resolve(JSON.stringify(data));
      });
    });
  };

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  open(index: number, imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedFileData[index].length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedFileData[index][i - 1]["name"])) {
        src = this.editedFileData[index][i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedFileData[index][i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  isValidImageURL(str) {
    str = str.toLowerCase();
    str = str.split('?')[0];
    //moving on, split the uri into parts that had dots before them
    var parts = str.split('.');
    //get the last part ( should be the extension )
    var extension = parts[parts.length - 1];
    //define some image types to test against
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    //check if the extension matches anything in the list.
    if (imageTypes.indexOf(extension) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  getControls() {
    return (this.documentsForm.get('documents') as FormArray).controls;
  }

  addNewDoc() {
    let control = <FormArray>this.documentsForm.controls.documents;
    //alert(control.length)
    this.loadSelectPicker();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    control.push(
      this.fb.group({
        docclassification: ['', Validators.required],
        docid: [''],
        docseq: [''],
        docname: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
        docstatus: ['', Validators.required],
        docdescription: ['', Validators.required],
        docreportstatus: ['', Validators.required],
        doclablocation: ['', Validators.required],
        docnotes: [''],
        docdeletedfileids: ['']
      })
    )
    this.files = [];
    this.fileData[control.length - 1] = this.files;
    this.showButton=true;
    this.refreshDocTree(control.length - 1);
  }

  removeDoc(index) {
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    if (this.removedDocIds) {
      this.removedDocIds = this.removedDocIds + "," + documentControl[index].get('docid').value
    }
    else {
      this.removedDocIds = documentControl[index].get('docid').value
    }

    control.removeAt(index)
    this.fileData.splice(index, 1);
    this.supplierDataCnt=this.fileData.length;
    //console.log(this.fileData);
    this.editedFileData.splice(index, 1);

    //this.removedFileData[index].push([]);
  }

  onSelectDoc(event, i) {
    this.files = [];
    let imgext = "png,jpeg,jpg,xlsx,pdf,txt,docx,zip,pptx"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.files.push(...event.addedFiles);
      this.isFilesAdded = true;
    }
    else {
      this.isFilesAdded = false;
      this.error("File Not Supported.")
      return false;
    }
     console.log(event.addedFiles,'event');
     this.fileData[i] = this.files;
  }

  onRemove(event, i) {
    this.fileData[i].splice(this.fileData[i].indexOf(event), 1);
    if(this.fileData.length==0){
      this.isFilesAdded=false;
    }

  }

  refreshDocTree(index: number) {
    var data = this.documentClassificationData;
    var currentDocument = this.getControls();
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      var treeview = $("#docclassification" + index).kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        dataSource: data,
        filter: "startswith",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          //alert(value);
          if (value) {
            //self.documentclassificationValue = value["text"];
            console.log(value["text"])
            currentDocument[index].get('docclassification').setValue(value["text"]);
            if (value["text"] === "Lab Reports") {
              currentDocument[index].get('docreportstatus').enable();
              currentDocument[index].get('doclablocation').enable();
            }
            else {
              currentDocument[index].get('docreportstatus').disable();
              currentDocument[index].get('doclablocation').disable();
            }
          }
          else {
            //self.documentclassificationValue = "";
            currentDocument[index].get('docclassification').setValue("");
          }
        }
      });

    });
  }



  removeImgSection(event, i) {
    this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    //console.log(this.editedFileData);
    if(this.editedFileData[i].length == 0){
      this.isFilesAdded = false;
    }
    let documentControl = this.getControls();
    let docdeltedFiles = documentControl[i].get('docdeletedfileids').value;
    if (docdeltedFiles) {
      documentControl[i].get('docdeletedfileids').setValue(docdeltedFiles + "," + event.id);
    }
    else {
      documentControl[i].get('docdeletedfileids').setValue(event.id);
    }
  }

  /*** Dropdown List Api start */

  getSupplierClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.supplierDetails.SupplierClassificationloadSuccess, this.staticMsgs.supplierDetails.supplierdetails);
          this.supplierClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.showLoader1 = false;
          this.refreshTree();
        } else {
          this.showLoader1 = false;
          this.refreshTree();
          this.error("Failed:" + classificationData.common.message?.item1)
          this.logger.error(classificationData.common.message?.item1, this.staticMsgs.supplierDetails.supplierdetails);
        }
      } else {
        this.logger.error(classificationData.common.error.error.message,this.staticMsgs.supplierDetails.supplierdetails);
        this.getError(classificationData.common.error);
        this.showLoader1 = false;
      }
    })

    /* this.dataservice.getClassification(module).subscribe(response => {
     if (response.body['item2'] === "Success") {
       let parentclass=response.body['item1'][0]["items"];
       for(let i=0;i<parentclass.length;i++){
         parentclass[i].parentName= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i]['parentName']);
         parentclass[i].text= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i]['text']);

         if(parentclass[i].items.length>0){
             for(let j=0;j<parentclass[i].items.length;j++){
               parentclass[i].items[j].parentName= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i].items[j]['parentName']);
               parentclass[i].items[j].text= this.aesdecryptservice.decryptData(this.dataservice.Key,parentclass[i].items[j]['text']);
             }
           }
         }
       this.supplierClassificationData =parentclass;
       this.logger.info("Supplier classification loaded successfully","SupplierDetails");

       ////console.log(JSON.stringify(this.supplierClassificationData ))
       this.refreshTree();
       this.showLoader1 = false;
     }
     else {
       this.refreshTree();
       this.error(response.body['item1']);
       this.logger.error(response.body['item1'],"SupplierDetails");
       this.showLoader1 = false;
     }
   }, err => {
     //console.log(err)
     this.logger.error(err.error.message,"SupplierDetails");
     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
       this.showLoader1 = false;
     } else if (err.error.message === "The incoming token has expired") {
       this.showLoader1 = false;
       this.error(err.error.message);
       this.sessionTimedOut();
       this.userservice.logout()
     } else if (err.error.message === "Unauthorized") {
       this.showLoader1 = false;
       this.userservice.error();
     }
     else {
       this.logger.error(err.error.message,"SupplierDetails");
       this.error(err.error.message);
       this.showLoader1 = false;
       this.userservice.error();
     }
   }) */
  }


  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader1 = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader1 = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader1 = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader1 = false;
  }

  /** Set Supplier values */

  getSupplierDetails() {
    this.showLoader1 = true;
    this.store.dispatch(new supplierActionTypes.LoadSupplier(this.storageservice.getEditedSupplierId()));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((supplierData) => {
      console.log(supplierData,'supplierData')
      if (supplierData.supplier.error === "") {
        if (supplierData.supplier.message['item2'] === 'Success') {
          this.loadSelectPicker();
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          this.editedSupplierData = supplierData.supplier.entities[this.storageservice.getEditedSupplierId()];
          console.log('editedSupplierData',this.editedSupplierData);
          ////console.log(JSON.stringify(this.editedSupplierData));
          let supp = this.editedSupplierData;
          this.logger.info(supp["name"] + this.staticMsgs.supplierDetails.LoadSuccess, this.staticMsgs.supplierDetails.supplierdetails);
          this.initForm();
          this.getSupplierClassification("Suppliers");
          this.supplierLibraryForm.get('classification').setValue(supp["classification"]);
          //this.refreshTree();
          this.supplierclassificationValue = supp["classification"];

          if (this.supplierclassificationValue === "Factory" || this.supplierclassificationValue === "Raw Material Supplier") {
            this.supplierLibraryForm.get('productType').enable();
            this.supplierLibraryForm.get('speciality').enable();
            this.supplierLibraryForm.get('moq').enable();
            this.supplierLibraryForm.get('moqUOM').enable();
            this.supplierLibraryForm.get('leadTime').enable();
            this.supplierLibraryForm.get('capacity').enable();
            this.supplierLibraryForm.get('capacityUOM').enable();
            setTimeout(() => {
              $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
            }, 10);

          } else {

            this.supplierLibraryForm.get('productType').disable();
            this.supplierLibraryForm.get('speciality').disable();
            this.supplierLibraryForm.get('moq').disable();
            this.supplierLibraryForm.get('moqUOM').disable();
            this.supplierLibraryForm.get('leadTime').disable();
            this.supplierLibraryForm.get('capacity').disable();
            this.supplierLibraryForm.get('capacityUOM').disable();
            setTimeout(() => {
              $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
            }, 10);
          }

          if (supp["machineTypes"]) {
            this.machineTypearr = JSON.parse(supp["machineTypes"]);
            //console.log(this.machineTypearr);
            //this.machineTypearr = supp["machineTypes"];
            // let sam =  this.machineTypearr;
            let i = 0;
            this.done = [];
            for (const [key, value] of Object.entries(this.machineTypearr)) {
              //console.log(`${key}: ${value}`);
              if (value['value']) {

                this.done.push(value['key']);
                this.todo = this.todo.filter(x => x != value['key']);
                this.supplierLibraryForm.get('machineTypes' + parseInt(key)).patchValue(value['value']);
                //i++;
              }

            }
          }
          this.sequenceNumber = supp["sequence"];
          this.supplierLibraryForm.get('supplierid').setValue(supp["id"]);
          //this.supplierLibraryForm.get('classification').setValue(supp["classification"]);
          this.supplierLibraryForm.get('name').setValue(supp["name"]);
          this.supplierLibraryForm.get('sequence').setValue(supp["sequence"]);
          if (supp["phoneNumber"]) {
            const phone = supp["phoneNumber"];
            const phone1 = phone.substr(phone.length - 10);
            const phonecode = phone.replace(phone1, "");
            this.supplierLibraryForm.get('phoneNo').setValue(phone1);
            this.supplierLibraryForm.get('phone_number_code').setValue(phonecode);
          }
          else {
            this.supplierLibraryForm.get('phoneNo').setValue('');
            this.supplierLibraryForm.get('phone_number_code').setValue('+1');
          }
          // this.supplierLibraryForm.get('phoneNo').setValue(supp["phoneNumber"];
          this.supplierLibraryForm.get('email').setValue(supp["email"]);
          let lastSocialAudit = supp["lastSocialAudit"].includes('T') ? supp["lastSocialAudit"].split('T')[0] : supp["lastSocialAudit"];
          this.supplierLibraryForm.get('lastSocialAudit').setValue(lastSocialAudit);
          this.lastSocialAuditDate = new NgbDate((lastSocialAudit.split('-')[0] - 0), (lastSocialAudit.split('-')[1] - 0), (lastSocialAudit.split('-')[2] - 0));
          this.supplierLibraryForm.get('auditStatus').setValue(supp["auditStatus"]);
          this.supplierLibraryForm.get('description').setValue(supp["description"]);
          // this.supplierLibraryForm.get('sequence').setValue(supp["sequence"];
          this.supplierLibraryForm.get('productType').setValue(supp["productType"]);
          this.supplierLibraryForm.get('SupplierChainPartner').setValue(supp["supplierChainPartner"]);
          this.supplierLibraryForm.get('speciality').setValue(supp["speciality"]);
          this.supplierLibraryForm.get('moq').setValue(supp["moq"]);
          this.supplierLibraryForm.get('moqUOM').setValue(supp["moqUOM"]);
          this.supplierLibraryForm.get('leadTime').setValue(supp["leadTime"]);
          this.supplierLibraryForm.get('capacity').setValue(supp["capacity"]);
          this.supplierLibraryForm.get('capacityUOM').setValue(supp["capacityUOM"]);
          this.refreshSelect();
          // this.supplierLibraryForm.get('Contactss').setValue(supp["contacts"];
          //this.supplierLibraryForm.get('machineTypes').setValue(supp["machineTypes"];
          if (supp["contact"].length > 0) {
            this.conta = supp["contact"];
            const contactForm = (this.supplierLibraryForm.controls['Contactss'] as FormGroup);
            const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
            const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
            if (contact1) {
              if(this.conta[0]["country"]){
                this.changeCountry('',this.conta[0]["country"]);
              }

              contact1.patchValue({
                fullName: this.conta[0]["fullName"],
                officeNo: this.conta[0]["officeNo"],
                ext: this.conta[0]["ext"],
                fax: this.conta[0]["fax"],
                contactNo: this.conta[0]["contactNo"],
                contactEmail: this.conta[0]["contactEmail"],
                address: this.conta[0]["address"],
                country: this.conta[0]["country"],
                state: this.conta[0]["state"],
                city: this.conta[0]["city"],
                contactOrder: this.conta[0]["contactOrder"],
                zipCode: this.conta[0]["zipCode"],
                id: this.conta[0]["id"]
              });
              this.contactTemp0 = contact1.value
              //this.conta[0] = contact1.value;
              console.log(this.contactTemp0,'contactTempo');
            }
            if (contact2 && this.conta.length > 1) {
              if(this.conta[1]["country"]){
                this.changeCountry('',this.conta[1]["country"]);
              }
              contact2.patchValue({

                fullName: this.conta[1]["fullName"],
                officeNo: this.conta[1]["officeNo"],
                ext: this.conta[1]["ext"],
                fax: this.conta[1]["fax"],
                contactNo: this.conta[1]["contactNo"],
                contactEmail: this.conta[1]["contactEmail"],
                address: this.conta[1]["address"],
                country: this.conta[1]["country"],
                state: this.conta[1]["state"],
                city: this.conta[1]["city"],
                contactOrder: this.conta[1]["contactOrder"],
                zipCode: this.conta[1]["zipCode"],
                id: this.conta[1]["id"]
              });
              this.contactTemp1 = contact2.value
              this.secondContact();

            }
          }
          this.showLoader1 = false;
          this.refreshSelect();
        } else {
          this.showLoader1 = false;
          this.error("Failed:" + supplierData.supplier.message['item1'])
          this.logger.error(supplierData.supplier.message['item1'], this.staticMsgs.supplierDetails.PaletteDetails);
        }
      } else {
        this.logger.error(supplierData.supplier.error.error.message,this.staticMsgs.supplierDetails.PaletteDetails);
        this.getError(supplierData.supplier.error);
        this.showLoader1 = false;
      }
    })

    /* this.dataservice.getSupplierById(this.storageservice.getEditedSupplierId()).subscribe((response) => {
      if (response.body['item2'] === "Success") {
        this.loadSelectPicker();
        setTimeout(() => {
        $('.selectpicker').selectpicker();
        }, 10);
        this.editedSupplierData = response.body['item1'];

        //console.log(this.editedSupplierData);
        ////console.log(JSON.stringify(this.editedSupplierData));
        let supp = this.editedSupplierData[0];
        this.logger.info(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["name"])+" loaded successfully","SupplierDetails");
        this.initForm();
        this.supplierLibraryForm.get('classification').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["classification"]));
        this.refreshTree();
        this.supplierclassificationValue = this.aesdecryptservice.decryptData(this.dataservice.Key,supp["classification"]);

        if (this.supplierclassificationValue === "Factory" || this.supplierclassificationValue === "Raw Material Supplier") {
          this.supplierLibraryForm.get('productType').enable();
          this.supplierLibraryForm.get('speciality').enable();
          this.supplierLibraryForm.get('moq').enable();
          this.supplierLibraryForm.get('leadTime').enable();
          this.supplierLibraryForm.get('capacity').enable();
          this.supplierLibraryForm.get('capacityUOM').enable();
          setTimeout(() => {
              $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
            }, 10);

        }else{

               this.supplierLibraryForm.get('productType').disable();
              this.supplierLibraryForm.get('speciality').disable();
              this.supplierLibraryForm.get('moq').disable();
              this.supplierLibraryForm.get('leadTime').disable();
              this.supplierLibraryForm.get('capacity').disable();
              this.supplierLibraryForm.get('capacityUOM').disable();
              setTimeout(() => {
                $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
              }, 10);
        }

        if(supp["machineTypes"]){
          this.machineTypearr = JSON.parse(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["machineTypes"]));
          //console.log(this.machineTypearr);
          //this.machineTypearr = supp["machineTypes"];
        // let sam =  this.machineTypearr;
          let i=0;

          for (const [key, value] of Object.entries(this.machineTypearr)) {
            //console.log(`${key}: ${value}`);
            console.log(key, value);
            if(value['value']){

              this.done.push(value['key']);
              this.todo = this.todo.filter(x => x != value['key']);
              this.supplierLibraryForm.get('machineTypes'+i).patchValue(value['value']);
              i++;
            }

          }
        }

        this.supplierLibraryForm.get('supplierid').setValue(supp["id"]);
        //this.supplierLibraryForm.get('classification').setValue(supp["classification"]);
        this.supplierLibraryForm.get('name').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["name"]));
        this.supplierLibraryForm.get('sequence').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["sequence"]));
         if (this.aesdecryptservice.decryptData(this.dataservice.Key,supp["phoneNumber"]) ){
            const phone = this.aesdecryptservice.decryptData(this.dataservice.Key,supp["phoneNumber"]);
           const phone1 = phone.substr(phone.length - 10);
            const phonecode = phone.replace(phone1, "");
            this.supplierLibraryForm.get('phoneNo').setValue(phone1);
            this.supplierLibraryForm.get('phone_number_code').setValue(phonecode);
          }
          else {
             this.supplierLibraryForm.get('phoneNo').setValue('');
            this.supplierLibraryForm.get('phone_number_code').setValue('+1');
          }
       // this.supplierLibraryForm.get('phoneNo').setValue(supp["phoneNumber"]);
        this.supplierLibraryForm.get('email').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["email"]));
        let  lastSocialAudit = supp["lastSocialAudit"].includes('T')? supp["lastSocialAudit"].split('T')[0] : supp["lastSocialAudit"];
        this.supplierLibraryForm.get('lastSocialAudit').setValue(lastSocialAudit);
        this.lastSocialAuditDate = new NgbDate((lastSocialAudit.split('-')[0]-0), (lastSocialAudit.split('-')[1]-0), (lastSocialAudit.split('-')[2]-0));
        this.supplierLibraryForm.get('auditStatus').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["auditStatus"]));
        this.supplierLibraryForm.get('description').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["description"]));
        // this.supplierLibraryForm.get('sequence').setValue(supp["sequence"]);
        this.supplierLibraryForm.get('productType').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["productType"]));
        this.supplierLibraryForm.get('SupplierChainPartner').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["supplierChainPartner"]));
        this.supplierLibraryForm.get('speciality').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["speciality"]));
        this.supplierLibraryForm.get('moq').setValue(supp["moq"]);
        this.supplierLibraryForm.get('leadTime').setValue(supp["leadTime"]);
        this.supplierLibraryForm.get('capacity').setValue(supp["capacity"]);
        this.supplierLibraryForm.get('capacityUOM').setValue(this.aesdecryptservice.decryptData(this.dataservice.Key,supp["capacityUOM"]));
        this.refreshSelect();
        // this.supplierLibraryForm.get('Contactss').setValue(supp["contacts"]);
        this.showLoader1 = false;
        //this.supplierLibraryForm.get('machineTypes').setValue(supp["machineTypes"]);
         if(supp["contact"].length > 0){
              this.conta = supp["contact"];
              const contactForm = (this.supplierLibraryForm.controls['Contactss'] as FormGroup);
              const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
              const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);
              if(contact1){

              contact1.patchValue({
                  fullName: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["fullName"]),
                  officeNo: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["officeNo"]),
                  ext: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["ext"]),
                  fax: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["fax"]),
                  contactNo:this.aesdecryptservice.decryptData(this.dataservice.Key, this.conta[0]["contactNo"]),
                  contactEmail: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["contactEmail"]),
                  address:this.aesdecryptservice.decryptData(this.dataservice.Key, this.conta[0]["address"]),
                  country: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["country"]),
                  state: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["state"]),
                  city: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["city"]),
                  contactOrder:this.aesdecryptservice.decryptData(this.dataservice.Key, this.conta[0]["contactOrder"]),
                  zipCode: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[0]["zipCode"]),
                  id: this.conta[0]["id"]
              });
              this.conta[0]=contact1.value;
            }
              if (contact2){

                contact2.patchValue({

                    fullName: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["fullName"]),
                    officeNo: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["officeNo"]),
                    ext: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["ext"]),
                    fax: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["fax"]),
                    contactNo: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["contactNo"]),
                    contactEmail: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["contactEmail"]),
                    address: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["address"]),
                    country:this.aesdecryptservice.decryptData(this.dataservice.Key, this.conta[1]["country"]),
                    state: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["state"]),
                    city: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["city"]),
                    contactOrder: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["contactOrder"]),
                    zipCode: this.aesdecryptservice.decryptData(this.dataservice.Key,this.conta[1]["zipCode"]),
                    id: this.conta[1]["id"]
                });
                this.conta[1]=contact2.value;
                 this.secondContact();
              }
            }
           this.refreshSelect();
      }
      else {
        this.error(response.body['item1']);
        this.logger.error(response.body['item1'],"SupplierDetails");
        this.showLoader1 = false;
      }
    }, err => {
      this.logger.error(err.error.message,"SupplierDetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);

      } else if (err.error.message === "The incoming token has expired") {
        this.error(err.error.message);
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.error.message,"SupplierDetails");
      }
      this.showLoader1 = false;
    }); */
  }

  /*** Classification data display Start */
  refreshTree() {
    var data = this.supplierClassificationData;
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      var treeview = $("#classification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        dataSource: data,
        filter: "startswith",
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          if (value) {
            self.supplierclassificationValue = value["text"];
            self.supplierLibraryForm.get('classification').setValue(value["text"]);
            if (value["text"] === "Factory" || value["text"] === "Raw Material Supplier") {
              self.supplierLibraryForm.get('productType').enable();
              self.supplierLibraryForm.get('speciality').enable();
              self.supplierLibraryForm.get('moq').enable();
              self.supplierLibraryForm.get('moqUOM').enable();
              self.supplierLibraryForm.get('leadTime').enable();
              self.supplierLibraryForm.get('capacity').enable();
              self.supplierLibraryForm.get('capacityUOM').enable();
              // this.supplierLibraryForm.get('machineTypes').enable();

              setTimeout(() => {
                $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
              }, 10);
            } else {
              self.supplierLibraryForm.get('productType').disable();
              self.supplierLibraryForm.get('speciality').disable();
              self.supplierLibraryForm.get('moq').disable();
              self.supplierLibraryForm.get('moqUOM').disable();
              self.supplierLibraryForm.get('leadTime').disable();
              self.supplierLibraryForm.get('capacity').disable();
              self.supplierLibraryForm.get('capacityUOM').disable();
            }

          }
          else {
            self.supplierclassificationValue = "";
            self.supplierLibraryForm.get('classification').setValue("");
          }
        }
      });

    });
  }


  initForm() {

    this.showLoader1 = true;

    this.supplierLibraryForm = this.fb.group({
      name: ['',  [Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      supplierid: [{ value: '', disabled: true }],
      classification: ['', [Validators.required]],
      auditStatus: ['',Validators.required],
      phoneNo: ['', [Validators.required,Validators.maxLength(10),Validators.pattern('^[1-9][0-9]{9}$')]],
      phone_number_code: ['+1'],
      email: ['', [Validators.required, Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$')]],
      moq: [0.0,Validators.required],
      moqUOM: ['',Validators.required],
      leadTime: [''],
      productType: ['',Validators.required],
      speciality: [''],
      capacity: ['',[Validators.pattern('^[0-9]{1,10}')]],
      capacityUOM: [''],
    //  lastSocialAudit: [''],
      sequence: [''],
      lastSocialAudit: ['', [Validators.required]],
      description: ['', Validators.maxLength(500)],
      SupplierChainPartner: [''],
      machineTypes0: ['', Validators.maxLength(20)],
      machineTypes1: ['', Validators.maxLength(20)],
      machineTypes2: ['', Validators.maxLength(20)],
      machineTypes3: ['', Validators.maxLength(20)],
      machineTypes4: ['', Validators.maxLength(20)],
      // zipCode: [''=
      Contactss: new FormGroup({
        contactOrder1: new FormGroup({
          fullName: new FormControl('', [Validators.pattern('^[a-zA-Z0-9 ]{1,50}$')]),
          officeNo: new FormControl('', Validators.pattern('[0-9]{1,20}')),
          ext: new FormControl('', Validators.pattern('[+][0-9]{1,3}')),
          fax: new FormControl('', Validators.pattern('[0-9]{10}')),
          contactNo: new FormControl('', [Validators.required,Validators.pattern("^[1-9][0-9]{9}$")]),
          contactEmail: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}$')]),
          address: new FormControl('', Validators.maxLength(500)),
          country: new FormControl(''),
          state: new FormControl('', Validators.maxLength(50)),
          city: new FormControl('', Validators.maxLength(50)),
          contactOrder: new FormControl('1'),
          zipCode: new FormControl('', Validators.pattern('[0-9]{6}')),
          id: new FormControl('')

        }),
        contactOrder2: new FormGroup({
          fullName: new FormControl('',  [Validators.pattern('^[a-zA-Z0-9 ]{1,50}$')]),
          officeNo: new FormControl('', Validators.pattern('[0-9]{1,20}')),
          ext: new FormControl('', Validators.pattern('[+][0-9]{1,3}')),
          fax: new FormControl('', Validators.pattern('[0-9]{10}')),
          contactNo: new FormControl('', [Validators.pattern("^[1-9][0-9]{9}$")]),
          contactEmail: new FormControl('', [Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$')]),
          address: new FormControl('', Validators.maxLength(500)),
          country: new FormControl(''),
          state: new FormControl('', Validators.maxLength(50)),
          city: new FormControl('', Validators.maxLength(50)),
          contactOrder: new FormControl('2'),
          zipCode: new FormControl('', Validators.maxLength(6)),
          id: new FormControl('')
        })
      })


    });
    this.showLoader1 = false;
  }

  get f() { return this.supplierLibraryForm.controls; }

  addDecimal(value:any)
  {
    switch (value){
 case 'moq':
  if(this.f['moq'].value.includes('.') ||this.f['moq'].value.length <= 0){

  }else{
    this.f['moq'].setValue(this.f['moq'].value + '.00');
  }
  break;

    }
  }
  cancelDocumentEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents')as FormArray).clear();
    this.isFilesAdded = true;
    if (this.storageservice.getEditedSupplierId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.showLoader = true;
    if (this.editedSupplierData.documents) {
      if (this.editedSupplierData.documents.length > 0) {
        this.editedFileData = [];
        this.removedDocIds = "";
        //this.removedFileData = [];
        this.isFilesAdded=true;
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        let editeddata = JSON.stringify(this.editedSupplierData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }
  numberOnly(e: any) {
    var regex = new RegExp("^[0-9.]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  extNumberOnly(e: any) {
    var regex = new RegExp("^[0-9+]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  faxNumberOnly(e: any) {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characterOnly(e: any) {
    var regex = new RegExp("^[[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  mechine() {
    const arr1 = ["Overlock- 3", "1 Needle Lockstitch- 5", "CoverStitch- 2", "ZigZag- 2", "1 Needle Lockstitch- 14"];
    const arr = [0, 1, 2, 3, 4];
    let self = this;
    let newArr = arr.map(function (val, index) {
      const controlValue = self.supplierLibraryForm.controls['machineTypes' + val];
      if (controlValue.value) {
        return {
          "key": self.done[index],
          "value": controlValue.value
        }
      }

    });
    return newArr;
  }

  setDate(event: any) {
    let now = new Date();
    let lastSocialStr = this.dataservice.dateFromFormat(now);
    // this.lastSocialAuditChild.nativeElement.value = lastSocialStr;

  };
  dateChange(date: any, type: any) {
    console.log('dateChange',date, 'type',type)
    if (type === 'lastSocialAuditDate') {

      //  this.lastSocialAuditDate = new NgbDate((date.split('-')[0]), (date.split('-')[1]), (date.split('-')[2]));
          this.lastSocialAuditDate = new NgbDate(date.year,date.month,date.day);
        console.log(this.lastSocialAuditDate,'lastaudit');
//
    }
  }
  onSubmit() {
console.log(this.supplierLibraryForm.value,'control');
    this.submitted = true;
     if(this.supplierclassificationValue=='Agent'){
      this.f['moq'].clearValidators();
      this.f['leadTime'].clearValidators();
      this.f['productType'].clearValidators();
      this.f['moqUOM'].clearValidators();

     }else if(this.supplierclassificationValue=='Factory'){
      this.f['productType'].setValidators([Validators.required])
      this.f['moq'].setValidators([Validators.required, Validators.max(99999)]);
      this.f['moqUOM'].setValidators([Validators.required]);

      this.f['leadTime'].setValidators([Validators.required, Validators.max(99999)]);
     }else if(this.supplierclassificationValue=='Raw Material Supplier'){
      this.f['productType'].updateValueAndValidity();
     this.f['moq'].updateValueAndValidity();
     this.f['moqUOM'].updateValueAndValidity();

     this.f['leadTime'].updateValueAndValidity();
     }
     console.log(this.supplierLibraryForm.valid,'valid');
      // if (!this.supplierLibraryForm.valid) {
      // this.error("Invalid Supplier Form");

      // return false;
    // }
  //}
    this.showLoader1 = true;
console.log(this.lastSocialAuditDate,'date5');
    let lastSocialStr = this.lastSocialAuditDate ? this.lastSocialAuditDate.year + '-' + this.lastSocialAuditDate.month + '-' + this.lastSocialAuditDate.day : '';
    let jsonArrayObject = [];
    jsonArrayObject = this.f.Contactss.value;

    let mob1;
    if(jsonArrayObject['contactOrder1']["contactNo"]){
        mob1 = jsonArrayObject['contactOrder1']["contactNo"].toString();
    }else{
      mob1 = '';
    }


    if (jsonArrayObject['contactOrder1']['fullName']) {
      jsonArrayObject['contactOrder1'].fullName = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["fullName"]),
        jsonArrayObject['contactOrder1'].officeNo = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["officeNo"]),
        jsonArrayObject['contactOrder1'].ext = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["ext"]),
        jsonArrayObject['contactOrder1'].fax = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["fax"]),
        jsonArrayObject['contactOrder1']["contactNo"] = mob1;
      jsonArrayObject['contactOrder1'].contactNo = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["contactNo"]),
        jsonArrayObject['contactOrder1'].contactEmail = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["contactEmail"]),
        jsonArrayObject['contactOrder1'].address = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["address"]),
        jsonArrayObject['contactOrder1'].country = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["country"]),
        jsonArrayObject['contactOrder1'].state = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["state"]),
        jsonArrayObject['contactOrder1'].city = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["city"]),
        jsonArrayObject['contactOrder1'].contactOrder = 1,
        jsonArrayObject['contactOrder1'].zipCode = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["zipCode"])
        //this.changeCountry('',this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder1']["country"]));
    }

    let mob2;
    if(jsonArrayObject['contactOrder2']["contactNo"]){
        mob2 = jsonArrayObject['contactOrder2']["contactNo"].toString();
    }else{
      mob2 = '';
    }

    if (jsonArrayObject['contactOrder2']['fullName']) {
      jsonArrayObject['contactOrder2'].fullName = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["fullName"]),
        jsonArrayObject['contactOrder2'].officeNo = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["officeNo"]),
        jsonArrayObject['contactOrder2'].ext = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["ext"]),
        jsonArrayObject['contactOrder2'].fax = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["fax"]),
        jsonArrayObject['contactOrder2']["contactNo"] = mob2;
      jsonArrayObject['contactOrder2'].contactNo = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["contactNo"]),
        jsonArrayObject['contactOrder2'].contactEmail = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["contactEmail"]),
        jsonArrayObject['contactOrder2'].address = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["address"]),
        jsonArrayObject['contactOrder2'].country = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["country"]),
        jsonArrayObject['contactOrder2'].state = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["state"]),
        jsonArrayObject['contactOrder2'].city = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["city"]),
        jsonArrayObject['contactOrder2'].contactOrder = 2,
        jsonArrayObject['contactOrder2'].zipCode = this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["zipCode"])
        //this.changeCountry('',this.aesdecryptservice.encryptData(this.dataservice.Key, jsonArrayObject['contactOrder2']["country"]));
    }



    if (jsonArrayObject['contactOrder1']['fullName'] && jsonArrayObject['contactOrder2']['fullName']) {
      this.pricontact = [jsonArrayObject['contactOrder1'], jsonArrayObject['contactOrder2']];
    } else if (jsonArrayObject['contactOrder1']['fullName']) {
      this.pricontact = [jsonArrayObject['contactOrder1']];
    }
    else if (jsonArrayObject['contactOrder2']['fullName']) {
      this.pricontact = [jsonArrayObject['contactOrder2']];
    }

    // if(jsonArrayObject['contactOrder1']['id'] && jsonArrayObject['contactOrder2']['id']){
    //   this.pricontact = [ jsonArrayObject['contactOrder1'],jsonArrayObject['contactOrder2'] ];
    // }
    // if(jsonArrayObject['contactOrder1']['id']){
    //   this.pricontact = [ jsonArrayObject['contactOrder1'] ];
    // }
    // if(jsonArrayObject['contactOrder2']['id']){
    //   this.pricontact = [ jsonArrayObject['contactOrder2'] ];
    // }

    // if(jsonArrayObject['contactOrder1']){

    //   if(jsonArrayObject['contactOrder1']['id']){

    //      if(jsonArrayObject['contactOrder2']['id']){
    //         this.pricontact = [ jsonArrayObject['contactOrder1'],jsonArrayObject['contactOrder2'] ];
    //      }
    //      else if(jsonArrayObject['contactOrder2'] && jsonArrayObject['contactOrder2']['fullName'] ){
    //         this.pricontact = [ jsonArrayObject['contactOrder2'] ];
    //      }
    //      else{
    //       this.pricontact = [ jsonArrayObject['contactOrder1'] ];
    //      }
    //   }else{
    //     this.pricontact = [ jsonArrayObject['contactOrder1'] ];
    //   }
    // }
    // else{
    //    if(jsonArrayObject['contactOrder1']['id'] && jsonArrayObject['contactOrder2']['id']){
    //     this.pricontact = [ jsonArrayObject['contactOrder1'],jsonArrayObject['contactOrder2'] ];
    //   }else{
    //     this.pricontact = [ jsonArrayObject['contactOrder1'],jsonArrayObject['contactOrder2'] ];
    //   }
    // }

    let sam = this.mechine();
    // console.log(sam)
    let mechinetype = JSON.stringify(Object.assign({}, sam));;
    if (this.f.phoneNo.value) {
      this.phone_number = this.f.phone_number_code.value + this.f.phoneNo.value;
    }

    if (!this.f.leadTime.value) {
      this.leadTimeee = 0;
    } else {
      this.leadTimeee = this.f.leadTime.value;
    }

    if (!this.f.moq.value) {

      this.moqee = 0;
    } else {

      this.moqee = this.f.moq.value;

    }

    if (!this.f.capacity.value) {
      this.capacityee = 0;
    } else {
      this.capacityee = this.f.capacity.value;
    }


    console.log(this.moqee,'hi');

    let supplierData = {
      classification: this.aesdecryptservice.encryptData(this.dataservice.Key, this.supplierclassificationValue),
      name: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.name.value),
      auditStatus: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.auditStatus.value),
      phoneNo: this.aesdecryptservice.encryptData(this.dataservice.Key, this.phone_number),
      email: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.email.value),
      productType: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.productType.value),
      speciality: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.speciality.value),
      moq: this.f.moq.value,
      moqUOM: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.moqUOM.value),
      SupplierChainPartner: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.SupplierChainPartner.value),
      leadTime: this.leadTimeee,
      capacity: this.capacityee,
      capacityUOM: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.capacityUOM.value),
      lastSocialAudit: new Date(),
      description: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.description.value),
      Contact: this.pricontact,
      machineTypes: this.aesdecryptservice.encryptData(this.dataservice.Key, mechinetype),
      Secondarycontact: this.secondarycontactId

    };
console.log(supplierData,'data')
    if (!this.f.supplierid.value) {
      //console.log(JSON.stringify(colorData))
      this.store.dispatch(new supplierActionTypes.CreateSupplier(supplierData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe(newsupplierData => {
        console.log(newsupplierData,'newsuppliers');
        if (newsupplierData.supplier.error === "") {
          if (newsupplierData.supplier.message['item2'] === 'Success') {
            this.showLoader1 = false;
            this.submitted = false;[]
            this.isEditMode = true;
            this.isViewingMode = true;
            this.isEditingMode = false;
            this.isCreateMode = false;
            console.log(newsupplierData,'cdata');
            this.editedSupplierData = newsupplierData.supplier.message['item1'];
            console.log(this.editedSupplierData,'data2');
            this.conta = this.editedSupplierData["contact"];
            this.storageservice.storeEditedSupplierId(newsupplierData.supplier.message['item1'].id);
            this.reloadSupplierFormData(this.editedSupplierData)
            this.success(this.staticMsgs.supplierDetails.SupplierCreatedSuccess);
            this.logger.info(this.staticMsgs.supplierDetails.SupplierCreatedSuccess, this.staticMsgs.supplierDetails.supplierdetails);
             this.getSupplierDetails();
          } else {
             this.submitted = false;
            this.showLoader1 = false;
            this.error("Failed:" + newsupplierData.supplier.message['item1'])
            this.logger.error(newsupplierData.supplier.message['item1'], this.staticMsgs.supplierDetails.supplierdetails);
          }
        } else {
          this.logger.error(newsupplierData.supplier.error.error.message, this.staticMsgs.supplierDetails.supplierdetails);
          this.getError(newsupplierData.supplier.error);
          this.showLoader1 = false;
           this.submitted = false;
        }
      })

      /* this.dataservice.createSupplier(supplierData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader1 = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isViewingMode=true;
            this.isEditingMode=false;
            this.editedSupplierData = response.body['item1'];
            this.conta=this.editedSupplierData["contact"];
            this.storageservice.storeEditedSupplierId(response.body['item1'].id);

            this.success("Supplier Created Successfully!");
            this.logger.info("Supplier Created Successfully","SupplierDetails");
            this.getSupplierDetails();

          }
          else {
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'],"SupplierDetails");
            this.showLoader1 = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message,"SupplierDetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);

        } else if (err.error.message === "The incoming token has expired") {
          this.error(err.error.message);
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.error.message,"SupplierDetails");
        }
        this.showLoader1 = false;
      }); */

    } else {
      console.log(this.storageservice.getEditedSupplierId(),'id1');
      let supplierrrid = { id: this.storageservice.getEditedSupplierId() };
      let updatedSupplierData = [{ ...supplierrrid, ...supplierData }];
     console.log(JSON.stringify(updatedSupplierData))
      this.store.dispatch(new supplierActionTypes.UpdateSupplierLibrary(updatedSupplierData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedSupplierDetails) => {
        console.log(updatedSupplierDetails,'details');
        if (updatedSupplierDetails.supplier.error === "") {
          if (updatedSupplierDetails.supplier.message?.item2 === 'Success') {
            this.showLoader1 = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isViewingMode = true;
            this.isEditingMode = false;
            this.editedSupplierData = updatedSupplierDetails.supplier.message['item1'];
          //  this.reloadSupplierFormData(this.editedSupplierData);
            this.getSupplierDetails();
            this.success(this.staticMsgs.supplierDetails.supplierUpdateSuccess);
             this.logger.info(this.staticMsgs.supplierDetails.supplierUpdateSuccess, "SupplierDetails");
          } else {
            this.showLoader1 = false;
            this.error("Failed:" + updatedSupplierDetails.supplier.message?.item1)
            this.logger.error(updatedSupplierDetails.supplier.message?.item1, this.staticMsgs.supplierDetails.supplierdetails);
          }
        } else {
          this.logger.error(updatedSupplierDetails.supplier.error.error.message, this.staticMsgs.supplierDetails.supplierdetails);
          this.getError(updatedSupplierDetails.supplier.error);
          this.showLoader1 = false;
           this.submitted = false;
        }
      })

       /* this.dataservice.BulkUpdateSupplier(updatedSupplierData).subscribe(response => {

         if (response.status === 200) {
           if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
             this.showLoader1 = false;
             this.submitted = false;
             //this.isEditMode = true;
             this.isViewingMode = true;
            this.isEditingMode = false;
             this.editedSupplierData =newsupplierData.supplier.message['item1'];
             this.logger.info("Supplier Updated Successfully", "SupplierDetails");
              //console.log(this.editedSupplierData);
              //this.reloadSupplierFormData(response.body['item1'])
               //this.router.navigate(['/supplierlibrary']);
             this.reloadSupplierFormData(this.editedSupplierData)
             this.getSupplierDetails();
             this.success("Supplier Updated Successfully!");
           }
           else {

             this.error(response.body['item1']);
             this.logger.error(response.body['item1'], "SupplierDetails");
             this.showLoader1 = false;
           }

         }
       }, err => {
         this.logger.error(err.error.message, "SupplierDetails");
         if (err.error.message === "Network error communicating with endpoint") {
           this.error(err.error.message);
         } else if (err.error.message === "The incoming token has expired") {
           this.sessionTimedOut();
           this.userservice.logout()
         } else if (err.error.message === "Unauthorized") {
           this.userservice.error();
         }
         else {
           this.error(err.message);
           this.logger.error(err.error.message, "SupplierDetails");
         }
         this.showLoader1 = false;
       }); */
    }

  }

  reloadSupplierFormData(data) {
    let supp = data;
    this.logger.info(supp["name"] + this.staticMsgs.supplierDetails.LoadSuccess,  this.staticMsgs.supplierDetails.supplierdetails);
    this.initForm();
    this.getSupplierClassification("Suppliers");
    this.supplierLibraryForm.get('classification').setValue(supp["classification"]);
    //this.refreshTree();
    this.supplierclassificationValue = supp["classification"];

    if (this.supplierclassificationValue === "Factory" || this.supplierclassificationValue === "Raw Material Supplier") {
      this.supplierLibraryForm.get('productType').enable();
      this.supplierLibraryForm.get('speciality').enable();
      this.supplierLibraryForm.get('moq').enable();
      this.supplierLibraryForm.get('moqUOM').enable();
      this.supplierLibraryForm.get('leadTime').enable();
      this.supplierLibraryForm.get('capacity').enable();
      this.supplierLibraryForm.get('capacityUOM').enable();
      setTimeout(() => {
        $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
      }, 10);

    } else {

      this.supplierLibraryForm.get('productType').disable();
      this.supplierLibraryForm.get('speciality').disable();
      this.supplierLibraryForm.get('moq').disable();
      this.supplierLibraryForm.get('moqUOM').disable();
      this.supplierLibraryForm.get('leadTime').disable();
      this.supplierLibraryForm.get('capacity').disable();
      this.supplierLibraryForm.get('capacityUOM').disable();
      setTimeout(() => {
        $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
      }, 10);
    }

    if (supp["machineTypes"]) {
      this.machineTypearr = JSON.parse(supp["machineTypes"]);
      //console.log(this.machineTypearr);
      //this.machineTypearr = supp["machineTypes"];
      // let sam =  this.machineTypearr;
      let i = 0;
      this.done = [];
      for (const [key, value] of Object.entries(this.machineTypearr)) {
        //console.log(`${key}: ${value}`);
        if (value['value']) {

          this.done.push(value['key']);
          this.todo = this.todo.filter(x => x != value['key']);
          this.supplierLibraryForm.get('machineTypes' + parseInt(key)).patchValue(value['value']);
          //i++;
        }

      }
    }
    this.sequenceNumber = supp["sequence"];
    this.supplierLibraryForm.get('supplierid').setValue(supp["id"]);
    //this.supplierLibraryForm.get('classification').setValue(supp["classification"]);
    this.supplierLibraryForm.get('name').setValue(supp["name"]);
    this.supplierLibraryForm.get('sequence').setValue(supp["sequence"]);
    if (supp["phoneNo"]) {
      const phone = supp["phoneNo"];
      const phone1 = phone.substr(phone.length - 10);
      const phonecode = phone.replace(phone1, "");
      this.supplierLibraryForm.get('phoneNo').setValue(phone1);
      this.supplierLibraryForm.get('phone_number_code').setValue(phonecode);
    }
    else {
      this.supplierLibraryForm.get('phoneNo').setValue('');
      this.supplierLibraryForm.get('phone_number_code').setValue('+1');
    }
    // this.supplierLibraryForm.get('phoneNo').setValue(supp["phoneNumber"];
    this.supplierLibraryForm.get('email').setValue(supp["email"]);
    let lastSocialAudit = supp["lastSocialAudit"].includes('T') ? supp["lastSocialAudit"].split('T')[0] : supp["lastSocialAudit"];
    this.supplierLibraryForm.get('lastSocialAudit').setValue(lastSocialAudit);
    this.lastSocialAuditDate = new NgbDate((lastSocialAudit.split('-')[0] - 0), (lastSocialAudit.split('-')[1] - 0), (lastSocialAudit.split('-')[2] - 0));
    this.supplierLibraryForm.get('auditStatus').setValue(supp["auditStatus"]);
    this.supplierLibraryForm.get('description').setValue(supp["description"]);
    // this.supplierLibraryForm.get('sequence').setValue(supp["sequence"];
    this.supplierLibraryForm.get('productType').setValue(supp["productType"]);
    this.supplierLibraryForm.get('SupplierChainPartner').setValue(supp["supplierChainPartner"]);
    this.supplierLibraryForm.get('speciality').setValue(supp["speciality"]);
    this.supplierLibraryForm.get('moq').setValue(supp["moq"]);
    this.supplierLibraryForm.get('moqUOM').setValue(supp["moqUOM"]);
    this.supplierLibraryForm.get('leadTime').setValue(supp["leadTime"]);
    this.supplierLibraryForm.get('capacity').setValue(supp["capacity"]);
    this.supplierLibraryForm.get('capacityUOM').setValue(supp["capacityUOM"]);

    this.refreshSelect();
    // this.supplierLibraryForm.get('Contactss').setValue(supp["contacts"];
    //this.supplierLibraryForm.get('machineTypes').setValue(supp["machineTypes"];
    if (supp["contact"].length > 0) {
      this.conta = supp["contact"];
      const contactForm = (this.supplierLibraryForm.controls['Contactss'] as FormGroup);
      const contact1 = (contactForm.controls['contactOrder1'] as FormGroup);
      const contact2 = (contactForm.controls['contactOrder2'] as FormGroup);

      if (contact1) {

        if(this.conta[0]["country"]){
          this.changeCountry('',this.conta[0]["country"]);
        }

        contact1.patchValue({
          fullName: this.conta[0]["fullName"],
          officeNo: this.conta[0]["officeNo"],
          ext: this.conta[0]["ext"],
          fax: this.conta[0]["fax"],
          contactNo: this.conta[0]["contactNo"],
          contactEmail: this.conta[0]["contactEmail"],
          address: this.conta[0]["address"],
          country: this.conta[0]["country"],
          state: this.conta[0]["state"],
          city: this.conta[0]["city"],
          contactOrder: this.conta[0]["contactOrder"],
          zipCode: this.conta[0]["zipCode"],
          id: this.conta[0]["id"]
        });
        this.contactTemp0 = contact1.value
        //this.conta[0] = contact1.value;
      }
      if (contact2 && this.conta.length > 1) {

        if(this.conta[1]["country"]){
          this.changeCountry('',this.conta[1]["country"]);
        }

        contact2.patchValue({

          fullName: this.conta[1]["fullName"],
          officeNo: this.conta[1]["officeNo"],
          ext: this.conta[1]["ext"],
          fax: this.conta[1]["fax"],
          contactNo: this.conta[1]["contactNo"],
          contactEmail: this.conta[1]["contactEmail"],
          address: this.conta[1]["address"],
          country: this.conta[1]["country"],
          state: this.conta[1]["state"],
          city: this.conta[1]["city"],
          contactOrder: this.conta[1]["contactOrder"],
          zipCode: this.conta[1]["zipCode"],
          id: this.conta[1]["id"]
        });
        this.contactTemp1 = contact2.value
        this.secondContact();


      }
    }
    this.showLoader1 = false;
    this.refreshSelect();

    // this.supplierLibraryForm.get('classification').setValue(data["classification"]);
    // this.refreshTree();
    // this.supplierclassificationValue = data["classification"];
    // this.supplierLibraryForm.get('supplierid').setValue(data["id"]);
    // this.supplierLibraryForm.get('SupplierChainPartner').setValue(data["supplierChainPartner"]);
    // this.storageservice.storeEditedSupplierId(data["id"]);
    // this.supplierLibraryForm.get('name').setValue(data["name"]);
    // this.supplierLibraryForm.get('sequence').setValue(data["sequence"]);
    // this.supplierLibraryForm.get('phoneNo').setValue(data["phoneNumber"]);
    // this.supplierLibraryForm.get('email').setValue(data["email"]);

    // this.supplierLibraryForm.get('lastSocialAudit').setValue(data["lastSocialAudit"]);
    // this.supplierLibraryForm.get('auditStatus').setValue(data["auditStatus"]);
    // this.supplierLibraryForm.get('description').setValue(data["description"]);
    // if (data["classification"] === "Factory" || data["classification"] === "Raw Material Supplier") {
    //   this.supplierLibraryForm.get('productType').enable();
    //   this.supplierLibraryForm.get('speciality').enable();
    //   this.supplierLibraryForm.get('moq').enable();
    //   this.supplierLibraryForm.get('leadTime').enable();
    //   this.supplierLibraryForm.get('capacity').enable();
    //   this.supplierLibraryForm.get('capacityUOM').enable();
    //   this.supplierLibraryForm.get('machineTypes').enable();

    //   setTimeout(() => {
    //     $('.selectpicker').selectpicker({ noneSelectedText: 'Select classification' });
    //   }, 10);
    //   this.refreshSelect();
    // }
    // else if (data["classification"] === "Agent") {

    //   this.supplierLibraryForm.get('productType').disable();
    //   this.supplierLibraryForm.get('speciality').disable();
    //   this.supplierLibraryForm.get('moq').disable();
    //   this.supplierLibraryForm.get('leadTime').disable();
    //   this.supplierLibraryForm.get('capacity').disable();
    //   this.supplierLibraryForm.get('capacityUOM').disable();
    //   this.supplierLibraryForm.get('machineTypes').disable();
    //   this.refreshTree();
    // }
  }



  cancelSupplierEdit() {
    if (this.storageservice.getEditedSupplierId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.isCreateMode = false;
      this.getSupplierDetails();
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }
    this.initForm();
    ///this.loadSelectPicker();
  }
  cancelSupplierCreate() {
    this.router.navigate(['/supplierlibrary']);
  }

  onDeleteSupplier() {

    Swal.fire({
      title: this.staticMsgs.supplierDetails.ConfirmationtoDelete,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.showLoader1 = true;
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getEditedSupplierId());
        this.store.dispatch(new supplierActionTypes.DeleteSupplierLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((deletedSupplierLibrary) => {
          if (deletedSupplierLibrary.supplier.error === "") {
            if (deletedSupplierLibrary.supplier.message?.item2 === 'Success') {
              this.success(this.staticMsgs.supplierDetails.SupplierDeleteSuccess);
              this.logger.info(this.staticMsgs.supplierDetails.SupplierDeleteSuccess, this.staticMsgs.supplierDetails.SupplierLibrary);
              this.router.navigate(['/supplierlibrary']);
              this.showLoader1 = false;
            } else {
              this.showLoader1 = false;
              this.error("Failed:" + deletedSupplierLibrary.supplier.message?.item1)
              this.logger.error(deletedSupplierLibrary.supplier.message?.item1,  this.staticMsgs.supplierDetails.SupplierLibrary);
            }

          } else {
            this.logger.error(deletedSupplierLibrary.supplier.error.error.message, this.staticMsgs.supplierDetails.SupplierLibrary);
            this.getError(deletedSupplierLibrary.supplier.error);
            this.showLoader1 = false;
          }
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  changeCountry(event,data){
    console.log('states',event,data);
    this.allStates = [];
    let newData;
    if(data){
        newData = data;
    }else{
      newData = event.target.value;
    }
    console.log(event.target.value,'newData')
    let states = this.country.filter(x=>(x.country == newData));
    console.log('states',states[0].states);
    if(states[0].states.length > 0){
      states[0].states.forEach(y=>{
        this.allStates.push({value: y,label: y});
      });
      this.refreshSelect();
      this.loadSelectPicker();
    }

    //console.log('this.allStates',this.allStates);
  }
}
