<!-- Site Content Wrapper -->
<main class="dt-main">
    <app-loader [showLoader]="showLoader"></app-loader>
    <app-loader [showLoader]="showLoader1"></app-loader>
    <!-- NAVBAR: Left -->
    <app-leftbar></app-leftbar>

    <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
    <!-- / NAVBAR: Left -->
    <div class="dt-content-wrapper">

      <!-- Site Content -->
      <div class="dt-content">

          <!-- Page Header -->
          <div class="dt-page__header">
              <div class="row">
                  <div class="col-xl-6 ">
                      <h3 class="dt-page__title m-auto">{{staticMsgs.moodboardLibrary.MoodboardLibrary}}</h3>
                  </div>
                  <div class="col-xl-6" *ngIf="!notFound || !sharedNotFound">
                      <span class="float-right">
                          <button type="button" class="btn btn-outline-primary adv-search-btn mr-3"
                              data-toggle="modal" data-target="#small-modal"><i class="icon icon-plus"></i>{{staticMsgs.moodboardLibrary.CreateNew}}</button>
                          <!-- <button type="button" class="btn btn-primary adv-search-btn">Advance
                              Search</button> -->
                      </span>
                  </div>

              </div>
          </div>
          <div class="row">
              <div class="col-md-12 col-xl-12 col-sm-12">
                  <p>
                      <a href="javascript:void(0)" [ngClass]="mymoodboard ? 'data_active' : 'text-muted'"
                          (click)="moodboardView('Created')">
                      {{staticMsgs.moodboardLibrary.MyMoodboards}}

                      </a>
                      <span>|</span>
                      <a href="javascript:void(0)" [ngClass]="sharedmoodboard ? 'data_active' : 'text-muted'"
                          (click)="moodboardView('Shared')">
                          {{staticMsgs.moodboardLibrary.Sharedwithme}}
                      </a>
                  </p>
              </div>
          </div>
          <!-- /page header -->
          <ng-container *ngIf="this.moodboardViewMode == 'Shared'">
              <div class="text-center" *ngIf="sharedNotFound">
                  <h2>{{staticMsgs.moodboardLibrary.NoSharedDataAvailable}}</h2>
                  <img [src]="notfoundImg" class="img-fluid "><br>
                  <span class="float-right">
                      <button type="button" class="btn btn-primary adv-search-btn mr-3" data-toggle="modal"
                          data-target="#small-modal"><i class="icon icon-plus"></i> {{staticMsgs.moodboardLibrary.CreateNew}}</button>
                  </span>
              </div>

              <div class="grid-content" *ngIf="!sharedNotFound">

                  <div class="row">


                      <div class="col-sm-5">
                          <i class="fa fa-search search-button"></i>
                          <!-- <tag-input [(ngModel)]='items' class="" [maxItems]='5'></tag-input> -->

                          <input type="text" class="form-control searchBox" value=""
                              placeholder="Enter Keywords to filter results" [(ngModel)]="searchedKeyword" />
                          <!-- -<input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords" [(ngModel)]="searchedKeyword" /> -->
                      </div>

                      <!-- <div class="col-sm-3">
                          <select title="Select Status" class="form-control selectpicker w-100">
                              <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
                          </select>
                      </div> -->

                  </div>

                  <!-- Grid -->
                  <div class="row mt-5" *ngIf="sharedCards">

                      <!-- Grid Item -->
                      <div class="col-xl-12 order-xl-1">

                          <!-- Card -->
                          <div class="row">

                              <!-- Grid Item -->
                              <div class="col-xl-3 col-md-4 col-sm-6 col-12"
                                  *ngFor="let card of sharedCards | filter:searchedKeyword">

                                  <!-- Card -->
                                  <div class="card border-l-r">

                                      <!-- Card Body -->
                                      <div class="">
                                          <img class="border-l-r h-260" [src]='card.thumbnail?.thumbnail' alt="Img">

                                          <!-- <img *ngIf="card.hexcode && (card.classification === 'Solid' || card.classification === 'Custom' )" class="w-100 border-l-r h-260" [ngStyle]="{'background-color': card.hexcode}"
                          alt="">
                        <img *ngIf="!card.hexcode && (card.classification === 'Print and Pattern') && card.thumbnailFiles" class="w-100 border-l-r h-260" alt="" src={{card.thumbnailFiles.thumbnail}}>
                        <img *ngIf="!card.hexcode  && (card.classification != 'Print and Pattern')" class="w-100 border-l-r h-260" alt=""> -->
                                      </div>

                                      <div class="card-body color-card">
                                          <div class="text-eli">
                                              <span (click)="onEditMood(card.id)" class="mb-1 mb-sm-0 f-13 text-truncate">{{card.title}}</span>
                                          </div>
                                          <div class="row ">
                                              <div class="col-sm-8 col-xl-8">
                                                  <span
                                                      class="d-block text-light-gray mb-3 mt-1 f-12 text-truncate">{{card.title}}</span>
                                              </div>
                                              <div class="col-sm-3 col-xl-3 ml-2">
                                                  <div class="float-right d-web">
                                                      <!-- <i *ngIf="card.status==='Active'" class="icon icon-dot d-green"></i>-->
                                                      <!--<span class="text-light-gray mb-3 f-12 d-active">{{card.status}}</span>-->
                                                      <span class="text-light-gray mb-3 f-12 d-active"
                                                          *ngIf="card.status=='Active'"><i
                                                              class="icon icon-dot d-green"></i>{{card.status}}</span>
                                                      <span class="text-light-gray mb-3 f-12 d-active"
                                                          *ngIf="card.status=='Inactive'"><i
                                                              class="icon icon-dot d-red"></i>{{card.status}}</span>
                                                      <!-- Dropdown -->
                                                      <div class="dropdown">
                                                          <a class="dropdown-toggle no-arrow" href="#"
                                                              data-toggle="dropdown" aria-haspopup="true"
                                                              aria-expanded="false">
                                                              <i
                                                                  class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                                                          </a>

                                                          <div class="dropdown-menu dropdown-menu-right">
                                                              <a class="dropdown-item" href="javascript:void(0)"
                                                                  (click)="editMoodBoard(card.id)">
                                                                  <i class="icon icon-editors icon-fw"></i>
                                                                  {{staticMsgs.moodboardLibrary.Edit}}
                                                              </a>
                                                              <a class="dropdown-item" href="javascript:void(0)"
                                                                  (click)="delete(card.id)">
                                                                  <i class="icon icon-trash icon-fw"></i>
                                                                  {{staticMsgs.moodboardLibrary.Delete}}
                                                              </a>
                                                              <!---<a class="dropdown-item" href="javascript:void(0)">
                                    <i class="icon icon-contacts icon-fw"></i>
                                    Contact List</a>
                                  <a class="dropdown-item" href="javascript:void(0)">
                                    <i class="icon icon-heart icon-fw"></i>
                                    Favourite List
                                  </a>--->
                                                          </div>
                                                      </div>
                                                      <!-- /dropdown -->
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div class="action-tools">
                                          <!-- Checkbox -->
                                          <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only">
                                              <input id="checkbox{{card.id}}" type="checkbox"
                                                  [(ngModel)]="card.isFavourite" (click)="AddToFavorite($event)"  ngbTooltip="Add to favorite">
                                              <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                                                  <span class="unchecked"><i
                                                          class="icon icon-star-o text-white icon-lg"></i></span>
                                                  <span class="checked"><i
                                                          class="icon icon-star-fill text-secondary icon-lg"></i></span>
                                              </label>
                                          </div>
                                          <!-- /checkbox -->

                                      </div>


                                      <!-- /card body -->

                                  </div>
                                  <!-- /card -->

                              </div>
                              <!-- /grid item -->

                          </div>
                          <!-- /card -->


                      </div>
                      <!-- /grid item -->

                  </div>
                  <!-- /grid -->

                  <div *ngIf="!isSharedGetAllRecords" class="row mt-5">
                      <div *ngIf="!isSharedScrollDown" class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
                          <div class="text-center w-100">
                              <div class="loadersmall">
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </ng-container>
          <ng-container *ngIf="this.moodboardViewMode == 'Created'">
              <div class="text-center" *ngIf="notFound">
                  <h2>{{staticMsgs.moodboardLibrary.NoDataAvailable}}</h2>
                  <img [src]="notfoundImg" class="img-fluid "><br>
                  <span class="float-right">
                      <button type="button" class="btn btn-primary adv-search-btn mr-3" data-toggle="modal"
                          data-target="#small-modal"><i class="icon icon-plus"></i> {{staticMsgs.moodboardLibrary.CreateNew}}</button>
                  </span>
              </div>

              <div class="grid-content" *ngIf="!notFound">

                  <div class="row">

                      <div class="col-sm-5">
                          <i class="fa fa-search search-button"></i>
                          <!-- <tag-input [(ngModel)]='items' class="" [maxItems]='5'></tag-input> -->

                          <input type="text" class="form-control searchBox" value=""
                              placeholder="Enter Keywords to filter results" [(ngModel)]="searchedKeyword" />
                          <!-- -<input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords" [(ngModel)]="searchedKeyword" /> -->
                      </div>

                      <!-- <div class="col-sm-3">
                      <select title="Select Status" class="form-control selectpicker w-100">
                          <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
                      </select>
                  </div> -->

                  </div>

                  <!-- Grid -->
                  <div class="row mt-5" *ngIf="moodboardCards">

                      <!-- Grid Item -->
                      <div class="col-xl-12 order-xl-1">

                          <!-- Card -->
                          <div class="row">

                              <!-- Grid Item -->
                              <div class="col-xl-3 col-md-4 col-sm-6 col-12"
                                  *ngFor="let card of (moodboardCards | async) | filter:searchedKeyword">

                                  <!-- Card -->
                                  <div class="card border-l-r">

                                      <!-- Card Body -->
                                      <div class="">
                                          <img class="border-l-r h-260" [src]='card.thumbnail?.thumbnail' alt="Img">

                                          <!-- <img *ngIf="card.hexcode && (card.classification === 'Solid' || card.classification === 'Custom' )" class="w-100 border-l-r h-260" [ngStyle]="{'background-color': card.hexcode}"
                      alt="">
                    <img *ngIf="!card.hexcode && (card.classification === 'Print and Pattern') && card.thumbnailFiles" class="w-100 border-l-r h-260" alt="" src={{card.thumbnailFiles.thumbnail}}>
                    <img *ngIf="!card.hexcode  && (card.classification != 'Print and Pattern')" class="w-100 border-l-r h-260" alt=""> -->
                                      </div>

                                      <div class="card-body color-card">
                                          <div class="text-eli">
                                              <span (click)="onEditMood(card.id)" class="mb-1 mb-sm-0 f-13 text-truncate">{{card.title}}</span>
                                          </div>
                                          <div class="row ">
                                              <div class="col-sm-8 col-xl-8">
                                                  <span
                                                      class="d-block text-light-gray mb-3 mt-1 f-12 text-truncate">{{card.title}}</span>
                                              </div>
                                              <div class="col-sm-3 col-xl-3 ml-2">
                                                  <div class="float-right d-web">
                                                      <!-- <i *ngIf="card.status==='Active'" class="icon icon-dot d-green"></i>-->
                                                      <!--<span class="text-light-gray mb-3 f-12 d-active">{{card.status}}</span>-->
                                                      <span class="text-light-gray mb-3 f-12 d-active"
                                                          *ngIf="card.status=='Active'"><i
                                                              class="icon icon-dot d-green"></i>{{card.status}}</span>
                                                      <span class="text-light-gray mb-3 f-12 d-active"
                                                          *ngIf="card.status=='Inactive'"><i
                                                              class="icon icon-dot d-red"></i>{{card.status}}</span>
                                                      <!-- Dropdown -->
                                                      <div class="dropdown">
                                                          <a class="dropdown-toggle no-arrow" href="#"
                                                              data-toggle="dropdown" aria-haspopup="true"
                                                              aria-expanded="false">
                                                              <i
                                                                  class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                                                          </a>

                                                          <div class="dropdown-menu dropdown-menu-right">
                                                              <a class="dropdown-item" href="javascript:void(0)"
                                                                  (click)="editMoodBoard(card.id)">
                                                                  <i class="icon icon-editors icon-fw"></i>
                                                                  {{staticMsgs.moodboardLibrary.Edit}}
                                                              </a>
                                                              <a class="dropdown-item" href="javascript:void(0)"
                                                                  (click)="delete(card.id)">
                                                                  <i class="icon icon-trash icon-fw"></i>
                                                                  {{staticMsgs.moodboardLibrary.Delete}}
                                                              </a>
                                                              <!---<a class="dropdown-item" href="javascript:void(0)">
                                <i class="icon icon-contacts icon-fw"></i>
                                Contact List</a>
                              <a class="dropdown-item" href="javascript:void(0)">
                                <i class="icon icon-heart icon-fw"></i>
                                Favourite List
                              </a>--->
                                                          </div>
                                                      </div>
                                                      <!-- /dropdown -->
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div class="action-tools">
                                          <!-- Checkbox -->
                                          <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only">
                                              <input id="checkbox{{card.id}}" type="checkbox"
                                                  [(ngModel)]="card.isFavourite" (click)="AddToFavorite($event)"  ngbTooltip="Add to Favourite">
                                              <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                                                  <span class="unchecked"><i
                                                          class="icon icon-star-o text-white icon-lg"></i></span>
                                                  <span class="checked"><i
                                                          class="icon icon-star-fill text-secondary icon-lg"></i></span>
                                              </label>
                                          </div>
                                          <!-- /checkbox -->

                                      </div>


                                      <!-- /card body -->

                                  </div>
                                  <!-- /card -->

                              </div>
                              <!-- /grid item -->

                          </div>
                          <!-- /card -->


                      </div>
                      <!-- /grid item -->

                  </div>
                  <!-- /grid -->

                  <div *ngIf="!isGetAllRecords" class="row mt-5">
                      <div *ngIf="!isScrollDown" class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
                          <div class="text-center w-100">
                              <div class="loadersmall">
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </ng-container>

      </div>

  </div>
  <!-- /Site Content Wrapper-->

</main>
<!-- /site content -->

<!-- Footer -->
<app-footer></app-footer>
<!-- /footer -->

<!-- Create User Modal -->
<div class="modal fade" id="small-modal" tabindex="-1" role="dialog" aria-labelledby="model-2" aria-hidden="true"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog" role="document">
      <form>
          <!-- Modal Content -->
          <div class="modal-content">
              <!-- Modal Header -->
              <div class="modal-header">
                  <h3 class="modal-title" id="model-2"> <i aria-hidden="true" class="fa fa-user ul-icon ml-3"></i> {{staticMsgs.moodboardLibrary.CreateMoodboard}} </h3>
                  <button type="button" class="close" data-dismiss="modal" (click)="closeTitle()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <!-- /modal header -->
              <!-- Modal Body -->
              <div class="modal-body">
                  <div class="container-fluid">
                      <div class="row">
                          <div class="col-md-12 mb-2 mt-2">
                              <div class="col-sm-12 mb-3">
                                  <label for="name">{{staticMsgs.moodboardLibrary.Name}} *</label>
                                  <input type="text" class="form-control" name="title" placeholder="Enter name"
                                      [(ngModel)]="moodBoardTitle" required (focusout)="titleError()">
                                  <div class="red" *ngIf="isTitleError && !(moodBoardTitle.length > 0)">
                                      {{staticMsgs.moodboardLibrary.EnterMoodboardName}}
                                  </div>
                                  <div class="red" *ngIf="moodBoardTitle.length > 50">
                                     {{staticMsgs.moodboardLibrary.NameValidations}}
                                  </div>
                              </div>
                              <div class="col-sm-12 mt-3 mb-3">
                                  <label for="description">{{staticMsgs.moodboardLibrary.Description}}</label>
                                  <textarea type="text" class="form-control" placeholder="Enter description" value=""
                                      required=""></textarea>
                              </div>
                              <div class="col-sm-12 mt-3 mb-3">
                                  <label for="description">{{staticMsgs.moodboardLibrary.Tags}}

                                      <span>
                                          <a href="javascript:void(0)" data-toggle="popover" data-trigger="focus"
                                              title="" data-placement="right"
                                              data-content="Add words which makes you inspired for this moodboard">
                                              <i class="icon icon-info icon-fw icon-sm secondary-color"></i></a>
                                      </span>
                                  </label>
                                  <!-- <i class="fa fa-search search-button"></i> -->
                                  <!-- <tag-input [(ngModel)]='items' class="" [maxItems]='5'></tag-input> -->
                              </div>

                          </div>
                      </div>

                  </div>
              </div>
              <!-- /modal body -->
              <!-- Modal Footer -->
              <div class="modal-footer">
                  <button type="button" #closeModal class="btn btn-outline-primary btn-sm" data-dismiss="modal"
                      (click)="closeTitle()">
                       {{staticMsgs.moodboardLibrary.Cancel}}
                  </button>
                  <!-- <button type="button"  (click)="sendInvite()"  class="btn btn-primary btn-sm"
             data-dismiss="modal">Send Invite
     </button> -->
                  <button type="submit" class="btn btn-primary btn-sm" (click)="saveTitle()">
                      {{staticMsgs.moodboardLibrary.Create}}
                  </button>
                  <!-- <button type="button" class="btn btn-primary btn-sm" [routerLink]="['/auth/userregister']" [queryParams]="{email:'kiran.army619@gmail.com',company: 'PTPL'}"
             data-dismiss="modal">Send Invite
     </button> -->

              </div>
              <!-- /modal footer -->

          </div>
          <!-- /modal content -->
      </form>
  </div>
</div>
<!-- </div> -->
<!-- /Create User Modal End -->


<!-- Root -->
