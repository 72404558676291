<!-- Site Content Wrapper -->
<main class="dt-main">
  <!-- <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader> -->
  <!-- NAVBAR: Left -->
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-leftbar></app-leftbar>

  <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">

    <!-- Site Content -->
    <div class="dt-content">
      <div class="row">
        <!-- Page Header -->
        <div class="col-xl-6 col-lg-6 col-md-6">
          <div class="dt-page__header">
            <h3 class="dt-page__title">{{staticMsgs.usermanagement.MyTeam}}</h3>
            <nav class="mb-0 breadcrumb">
              <!-- <a href="javascript:void(0)" class="breadcrumb-item">Home</a>
              <span class="active breadcrumb-item">User Management</span> -->
            </nav>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6">
          <button class="btn btn-primary adv-search-btn mb-3 float-right" data-target="#long-modal" data-toggle="modal"
            type="button"><i class="icon icon-plus"></i>{{staticMsgs.usermanagement.InviteteaMembers}}</button>
        </div>
      </div>
      <!-- /page header -->


      <div class="profile-content teams_data">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-xl-6">
            <i class="fa fa-search search-button"></i>
                <input type="text" class="form-control searchBox" value="" placeholder="Search Your Team Member..!"
                  [(ngModel)]="filterUsers" />

            <!-- <input class="form-control p-5" type="search" placeholder="Search Your Team Member..!" value="Search Your Team Member" id="example-search-input"
              [(ngModel)]="filterUsers">
            <span class="input-group-append">
              <button class="btn btn-outline-secondary" type="button">
                <i class="fa fa-search"></i>
              </button>
            </span> -->
          </div>
          <!-- <button class="btn btn-primary" type="button" (click)="modifiedSearch()">Advancce Search <i
                          class="fa fa-sliders ml-2" aria-hidden="true"></i>
                  </button> -->
        </div>

        <div class="row">
          <div class="col-lg-12 col-xl-12 col-md-12 pt-5">
            <div class=" card-shadow-none card-header card-nav d-sm-flex justify-content-sm-between">
              <ul class="nav nav-pills card-header-links nav nav-underline">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="pill" href="#MyTeam">{{staticMsgs.usermanagement.MyTeam}}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="pill" href="#InvitationStatus"><span class="alpha">{{staticMsgs.usermanagement.alpha}}</span>{{staticMsgs.usermanagement.InvitationStatus}}</a>
                </li>
              </ul>
            </div>
              <!-- Tab panes -->
              <div class="tab-content mt-3">
                <div id="MyTeam" class="tab-pane active">
                  <div class="row users-card">
                    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-6" *ngFor="let arr of teammates | filter: filterUsers">
                      <div class="card rounded-card user-card">
                        <div class="card-block p-5">
                          <div class="img-hover">
                            <div *ngIf="arr.image">
                              <div class="img-fluid img-radius" [style.background-image]="'url('+arr.image+')'"
                                alt="round-img"></div>
                            </div>

                            <!-- {{ arr.picture | json}} -->
                            <!-- <div *ngIf="arr.image">
                              <img src="{{ arr.image }}" class="img-fluid img-radius"
                                alt="round-img">
                            </div> -->
                            <div *ngIf="arr.image==undefined" #else>
                              <ngx-avatar class="img-fluid flag-icon-circle image"
                                name="{{arr.firstName+' '+arr.lastName}}"></ngx-avatar>
                            </div>
                            <div class="img-overlay img-radius" style="display:none">

                            </div>
                          </div>
                          <div class="user-content" >
                            <p class="text-primary f-16 mb-2 text-truncate">{{arr.fullName}}</p>
                            <p class="f-14 text-muted">{{arr.userrole}}
                              <span>
                                <span class="dropdown float-right">
                                  <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i class="icon icon-vertical-more icon-fw icon-lg"></i>
                                  </a>

                                  <span class="dropdown-menu dropdown-menu-right" style="min-width:16rem">

                                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" (click)="editUser(arr.id)"
                                    data-target="#edit-modal" aria-hidden="true">
                                      <i class="fa fa-pencil-square-o mr-1 text-primary"></i>
                                     {{staticMsgs.usermanagement.EditUser}}
                                    </a>
                                    <a class="dropdown-item" href="javascript:void(0)">
                                      <i class="fa fa-trash mr-1 text-primary"></i>
                                      {{staticMsgs.usermanagement.Delete}}
                                    </a>
                                  </span>
                                </span>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div id="InvitationStatus" class="tab-pane card-body card-body-shadow bg-white fade">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">

                      <ul class="list-group text-dark" *ngFor="let user of userList">
                        <li class="list-group-item border-0 justify-content-between align-items-center d-flex mb-2 bg-list-light-gray">
                          <span><img src={{user.img}} class="size-40 dt-avatar mr-2"> {{
                            user.name}}</span>
                          <span class="badge badge-success" *ngIf="user.status=='Accepted'">{{user.status}}</span>
                          <span class="badge badge-warning text-white"
                            *ngIf="user.status=='Pending'">{{user.status}}</span>
                          <span class="badge badge-danger" *ngIf="user.status=='Decline'">{{user.status}}</span>
                          <label>{{staticMsgs.usermanagement.InvitedOn}} <strong>{{user.invitedon}}</strong></label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

          </div>
        </div>


      </div>


    </div>
  </div>
  <!-- /Site Content Wrapper-->
  <!-- Customizer Sidebar -->
  <aside class="dt-customizer dt-drawer position-right" [class.open]="classApplied">
    <!-- Item Detail Section -->
    <div class="dt-customizer__inner">

      <!-- Customizer Header -->
      <div class="dt-customizer__header">

        <!-- Customizer Title -->
        <div class="dt-customizer__title">
          <h4 class="mb-0">{{staticMsgs.usermanagement.ModifySearch}}</h4>
        </div>
        <!-- /customizer title -->
        <!-- Close Button -->
        <button type="button" class="close" (click)="modifiedClose()">
          <span aria-hidden="true">&times;</span>
        </button>

        <!-- /close button -->

      </div>
      <!-- /customizer header -->
      <!-- Customizer Body -->
      <div class="dt-customizer__body ps-custom-scrollbar">
        <!-- Customizer Body Inner  -->
        <div class="dt-customizer__body-inner">
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                  aria-controls="collapseOne">
                  <button class="btn btn-link font-weight-bold">
                    {{staticMsgs.usermanagement.Profession}}
                  </button><span><i class="fa fa-chevron-down"></i></span>
                </h5>
              </div>

              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">FashionDesigner{{staticMsgs.usermanagement.FashionDesigner}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.TechnicalDesigners}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.EnterEmailAddress}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.FashionEnterpreneur}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13 text-break">{{staticMsgs.usermanagement.FabricSupplier}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13"> {{staticMsgs.usermanagement.TrimSupplier}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.Factory}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13"> {{staticMsgs.usermanagement.CertificationTestingAgency}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                  aria-controls="collapseTwo">
                  <button class="btn btn-link collapsed font-weight-bold">
                    {{staticMsgs.usermanagement.SkillsSelected}}
                  </button>
                  <span class=""><i class="fa fa-chevron-down"></i></span>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.FashionDesigner}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.TechnicalDesigners}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.PatternMakers}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.FashionEnterpreneur}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13 text-break">{{staticMsgs.usermanagement.FabricSupplier}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13"> {{staticMsgs.usermanagement.TrimSupplier}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.Factory}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13"> {{staticMsgs.usermanagement.CertificationTestingvAgency}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                  aria-controls="collapseThree">
                  <button class="btn btn-link collapsed font-weight-bold">
                    {{staticMsgs.usermanagement.SkillLevel}}</button><span><i class="fa fa-chevron-down"></i></span>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.FashionDesigner}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.TechnicalDesigners}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.PatternMakers}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.FashionEnterpreneur}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13 text-break">{{staticMsgs.usermanagement.FabricSupplier}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13"> {{staticMsgs.usermanagement.TrimSupplier}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13">{{staticMsgs.usermanagement.Factory}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label f-13"> {{staticMsgs.usermanagement.CertificationTestingAgency}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /section -->
        </div>
        <!-- /customizer body inner -->
      </div>
      <!-- /customizer body -->

    </div>
    <!-- Item Details Section End-->

  </aside>
  <!-- /customizer sidebar -->
  <div class="modal fade" id="long-modal" tabindex="-1" role="dialog" aria-labelledby="model-2" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">

      <!-- Modal Content -->
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h3 class="modal-title" id="model-2"> <i aria-hidden="true" class=" mr-3 fa fa-group ul-icon"></i>{{staticMsgs.usermanagement.SendInvite}}
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- /modal header -->
        <!-- Modal Body -->
        <div class="modal-body">
          <div class="container-fluid teams-input-select">
            <div class="row">
            <form [formGroup]="inviteForm" (ngSubmit)="sendInvite()" class="col-md-12">
              <div class="col-md-12 p-5">
                <div class="input-group mb-3">
                  <label class="">{{staticMsgs.usermanagement.EnterEmailAddress}}<span class="red">*</span></label>

                  <div class="input-group-prepend w-100">
                    <select formControlName="emailId" id="emailId" type="text" multiple="multiple"
                      class="form-control js-example-basic-single" placeholder="Enter email id's"></select>
                  </div>
                  <div>
                    <div
                      *ngIf="(submitted && this.invitef.emailId.errors) || ( this.invitef.emailId.invalid && (this.invitef.emailId.dirty || this.invitef.emailId.touched))">
                      <div *ngIf="this.invitef.emailId.errors.required" class="red">
                        {{staticMsgs.usermanagement.EmailAddress+staticMsgs.common.required}}

                      </div>
                      <div *ngIf="!this.invitef.emailId.errors.required && this.invitef.emailId.errors.pattern"
                        class="red">
                        {{staticMsgs.usermanagement.EmailAddress+staticMsgs.common.invalid}}
                      </div>
                    </div>


                  </div>

                </div>
                <div class="text-right mt-10">
                  <button class="btn btn-outline-primary" type="button" aria-expanded="false" (click)="sendInvite()">{{staticMsgs.usermanagement.SendInvite}}</button>
                </div>
              </div>
            </form>
            </div>

          </div>
        </div>
        <!-- /modal body -->
        <!-- Modal Footer -->
      </div>
      <!-- /modal content -->

    </div>
  </div>



  <!-- /Create User Modal End -->

<div class="modal fade" id="edit-modal" tabindex="-1" role="dialog" aria-labelledby="model-2" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <!-- Modal Content -->
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title" id="model-2"> <i aria-hidden="true" class="mr-3 fa fa-user ul-icon"></i> {{staticMsgs.usermanagement.EditUser}} </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- /modal header -->
      <!-- Modal Body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 mb-2 mt-2">
              <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-sm-6 mb-3">
                    <label for="validationDefault02">{{staticMsgs.registration.FirstName}}</label>
                    <!-- <input type="text" class="form-control" id="validationDefault02" placeholder="Enter first name" value="" required=""> -->
                    <input type="text" formControlName="firstname" id="firstname" class="form-control" minlength="3"
                      maxlength="40" required />
                  </div>
                  <div
                    *ngIf="( this.f.firstname.errors) ||(this.f.firstname.invalid && (this.f.firstname.dirty || this.f.firstname.touched))">
                    <div *ngIf="this.f.firstname.errors.required" class="red">
                      {{staticMsgs.registration.FirstName+staticMsgs.common.required}}
                    </div>
                    <div *ngIf="this.f.firstname.errors.minlength" class="red">
                      {{staticMsgs.registration.FirstName+staticMsgs.registration.mustbe+staticMsgs.registration.charecters3}}
                    </div>
                    <div *ngIf="this.f.firstname.errors.maxlength" class="red">
                      {{staticMsgs.registration.FirstName+staticMsgs.registration.shouldNot+staticMsgs.registration.Exceed}}
                    </div>
                  </div>
                  <div class="col-sm-6 mb-3">
                    <label for="validationDefault02">{{staticMsgs.registration.LastName}}</label>
                    <!-- <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Last Name" value="" required=""> -->
                    <input type="text" formControlName="lastname" id="lastname" class="form-control" minlength="1"
                      maxlength="15" required />
                    <div
                      *ngIf="(this.f.lastname.errors) || ( this.f.lastname.invalid && (this.f.lastname.dirty || this.f.lastname.touched))">
                      <div *ngIf="this.f.lastname.errors.required" class="red">
                        {{staticMsgs.registration.LastName+staticMsgs.common.required}}
                      </div>
                      <div *ngIf="this.f.lastname.errors.minlength" class="red">
                        {{staticMsgs.registration.LastName+staticMsgs.registration.mustbe+staticMsgs.registration.characterslong}}
                      </div>
                      <div *ngIf="this.f.lastname.errors.maxlength" class="red">
                        {{staticMsgs.registration.LastName+staticMsgs.registration.shouldNot+staticMsgs.registration.charecters15}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 mb-3">
                    <label for="userrole">{{staticMsgs.usermanagement.role}}</label>
                    <select title="Select Role" formControlName="userrole" id="userrole" class="form-control" required>
                      <!-- <option value="">--Select Role--</option> -->
                      <option *ngFor="let role of roles" [value]="role.id">
                        {{ role.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="( this.f.userrole.errors) || (this.f.userrole.invalid && (this.f.userrole.dirty || this.f.userrole.touched))">
                      <div *ngIf="this.f.userrole.errors.required" class="red">
                        {{staticMsgs.usermanagement.role+staticMsgs.common.required}}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 ">
                    <label for="validationDefault04">{{staticMsgs.common.email}}</label>
                    <div class="form-row">
                      <input type="text" formControlName="email" id="email" class="form-control" maxlength="10" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mb-3">
                    <label for="company">
                      {{staticMsgs.usermanagement.company}}
                      <span>
                        <a href="javascript:void(0)" data-toggle="popover" data-trigger="focus" title=""
                          data-placement="bottom"
                          data-content="<p>Enter unique name under which your company/organisation is registered. In case the system doesn’t accept the name, Please try the suggested names given by System. Still if you are facing any issue please contact FitOs Support Team.</p>">
                          <i class="icon icon-question-circle icon-fw icon-sm"></i>
                        </a>
                      </span>
                    </label>
                    <input type="text" formControlName="company" id="company" class="form-control" maxlength="100">
                    <div
                      *ngIf="(this.f.company.errors) || (this.f.company.invalid && (this.f.company.dirty || this.f.company.touched))">
                      <div *ngIf="this.f.company.errors.pattern" class="red">
                        {{staticMsgs.registration.nameValidations}}
                      </div>
                      <div *ngIf="this.f.company.errors.maxlength" class="red">
                        {{staticMsgs.registration.Name+staticMsgs.registration.shouldNot+staticMsgs.registration.Charecters}}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>

        </div>
      </div>
      <!-- /modal body -->
      <!-- Modal Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm" data-dismiss="modal">
          {{staticMsgs.usermanagement.Cancel}}
        </button>
        <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" (click)="onSubmit()">
          {{staticMsgs.usermanagement.Update}}
        </button>
      </div>
      <!-- /modal footer -->

    </div>
    <!-- /modal content -->
  </div>
</div>


</main>
<!-- /site content -->


