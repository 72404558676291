import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ColorEvent } from 'ngx-color';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { SharedService } from '../../../services/shared.service';
import { environment } from '../../../../../../tenants/main/environments/environment';
import { UserService } from '../../../helpers/user.service';
import { StorageService } from '../../../helpers/storage.service';
import { IColor, ColourDocuments } from '../../../models/color';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import * as S3 from 'aws-sdk/clients/s3';
import { LogService } from '../../../shared/log.service';
import { interval, of, Observable } from 'rxjs';
import * as colorActions from "../../../actions/color.actions";
import { Store, select } from "@ngrx/store";
import { skip, take, tap } from 'rxjs/operators';
import * as commonActionTypes from "../../../actions/common.actions";
import * as colorActionTypes from "../../../actions/color.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { Router } from '@angular/router';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { ToastService } from '../../../services/toast.service';
declare var $: any;

@Component({
  selector: 'app-colordetails',
  templateUrl: './colordetails.component.html',
  styleUrls: ['./colordetails.component.css']
})
export class ColordetailsComponent implements OnInit {

  @ViewChild(LoaderComponent) child: LoaderComponent;

  Swal = require('sweetalert2');
  showLoader: boolean;
  showLoader1: boolean;
  color: Object = { "hsl": { "h": 37.42857142857143, "s": 0.9130434782608696, "l": 0.5490196078431373, "a": 1 }, "hex": "#7ED321", "rgb": { "r": 25, "g": 166, "b": 35, "a": 1 }, "hsv": { "h": 37.42857142857143, "s": 0.8571428571428571, "v": 0.9607843137254902, "a": 1 }, "oldHue": 271.6363636363636, "source": "hex" };
  activeTab = 'colorDetailsTab';
  public colorDataCnt=0;
  submitted = false;
  submitted1 = false;
  colorForm: FormGroup;
  isCreateMode: boolean;
  documentsForm: FormGroup;
  colorClassificationData: any[];
  documentClassificationData: any[];
  colorHex = '';
  colorbg = '';
  colourSwatch = '';
  R = "";
  G = "";
  B = "";
  fileData: Array<any> = [];
  editedFileData: Array<any> = [];
  thumbnail: string = "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&w=1000&q=8";
  notfoundImg: string = "../../../../assets/images/notfound/NotFound.png";
  sequenceNumber = '';
  //removedFileData: Array<any> = [];
  removedDocIds = '';
  files: File[] = [];
  thumbNails: File[] = [];
  editedThumbnails: File[] = [];
  editedThumbnailValue = '';
  isFilesAdded: boolean = false;
  isedit: Array<boolean> = [];
  fieldArray: any;
  itemList = [];
  dropdownList = [];
  sharedUsers = [];
  onlineUsers: any;
  newfieldArray: any;
  //colorseqValue = '';
  //colornameValue = '';
  // internalrefValue = '';
  // statusValue = '';
  // colorhexacodeValue = '';
  // colorstandardValue = '';
  // pantoneValue = '';
  // descriptionValue = '';
  isThumbnailAdded: boolean;
  updColorList: any[];
  colordocument: any;
  errors = '';
  errorDiv = false;
  alert = '';
  isEditMode: boolean;
  isEditingMode: boolean;
  isViewingMode: boolean;
  editedColorData: any;
  colorclassificationValue: string;
  imageObject: Array<object> = [];
  selected: any;
  colorObject: IColor;
  treeview: any;
  log: any[];
  documentImage = "../../../../assets/images/doccard/document.png";
  cards = [];
  staticMsgs:any;
  showButton:boolean=false;
  options = [
    { value: 'Pantone', label: 'Pantone' }
  ];
  docClassifications: Observable<any[]>;
  colClassifications: Observable<any[]>;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private dataservice: DataService,
    private storageservice: StorageService,
    public userservice: UserService,
    private _lightbox: Lightbox,
    private aesdecryptservice: AESDecryptService,
    private toastService: ToastService,
    private logger: LogService,
    private store: Store<any>) {
    this.documentsForm = this.fb.group({
      documents: this.fb.array([])
    })
    this.loadSelectPicker();

  }

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    this.isThumbnailAdded = true;
    this.dataservice.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    if (this.storageservice.getEditedColorId()) {
      this.isCreateMode = false;
      if (this.storageservice.getEditedColorDetailsId()) {
        this.storageservice.removeColorDetailsId();

        this.isEditMode = true;
        this.isEditingMode = true;
        this.isViewingMode = false;
        this.editColour();
      }
      else {
        this.isEditMode = true;
        this.isEditingMode = false;
        this.isViewingMode = true;
        this.getColorDetails();
      }
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
      this.isCreateMode = true;
      this.getColorClassification("Colours");
    }

    this.initForm();

    this.loadSelectPicker();
    this.sharedService.sendClickEvent();
  }

  /* To load Dropdowns */
  loadSelectPicker() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $('.selectpicker').selectpicker({ noneSelectedText: 'Select Color Standard' });
    });
  }

  // Method to initialise the Color form.
  initForm() {
    this.showLoader = true;
    this.colorForm = this.fb.group({
      colorclassification: ['', [Validators.required]],
      colorid: [{ value: '', disabled: true }],
      colorseq: [''],
      colorname: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
      internalref: ['',[Validators.required,Validators.pattern('^[a-zA-Z0-9# ]{1,20}$')]],
      status: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(500)]],
      colorhexacode: ['', [Validators.required]],
      colorstandard: ['', [Validators.required]],
      pantone: ['']

    });
    this.showLoader = false;
  }

  numberOnly(e: any) {
    var regex = new RegExp("^[0-9.]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  characterOnly(e: any) {
    var regex = new RegExp("^[[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }


  get f() { return this.colorForm.controls; }

  /* To edit colour */
  editColour() {
    this.isEditMode = true;
    this.isEditingMode = true;
    this.isViewingMode = false;
    if (this.storageservice.getEditedColorId()) {
      this.getColorDetails();
    }
    if (this.activeTab === "colorDetailsTab") {
      this.isThumbnailAdded = true;
      this.initForm();

      this.loadSelectPicker();
      setTimeout(() => {
        $('select[name=docstatus]').selectpicker('refresh');

        // $('.selectpicker').selectpicker('refresh');
      });
    }
    else if (this.activeTab === "docDetailsTab") {
      this.getDocumentClassification("Documents")
      this.showLoader = true;
      this.showButton=false;
      if (this.editedColorData.documents) {
        if (this.editedColorData.documents.length > 0) {
          this.editedFileData = [];
          this.removedDocIds = "";
          this.isFilesAdded = true;
          //this.removedFileData = [];
          let control = <FormArray>this.documentsForm.controls.documents;
          $('select[name=docstatus]').selectpicker('refresh');

          if (control.length > 0) {
            this.documentsForm = this.fb.group({
              documents: this.fb.array([])
            })
          }
          let editeddata = JSON.stringify(this.editedColorData.documents);
          let data = JSON.parse(editeddata);
          this.reloadDocumentFormData(data);
          this.showLoader = false;
        }
        else {
          this.showLoader = false;
        }

      }
      else {
        this.showLoader = false;
      }
    }
  }

  /* To update the UI to match the new state */
  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  /* Cancelling the edited color to reset form */
  cancelColourEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents')as FormArray).clear();
    this.isThumbnailAdded = true;
    if (this.storageservice.getEditedColorId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
      this.getColorDetails();
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.initForm();

    this.loadSelectPicker();
  }

  /* Cancelling the edited document to reset form */
  cancelDocumentEdit() {
    /* Written by : Himanshu */
    (this.documentsForm.get('documents')as FormArray).clear();
    this.isThumbnailAdded = true;
    if (this.storageservice.getEditedColorId()) {
      this.isEditMode = true;
      this.isEditingMode = false;
      this.isViewingMode = true;
    }
    else {
      this.isEditMode = false;
      this.isEditingMode = true;
      this.isViewingMode = false;
    }

    this.showLoader = true;
    if (this.editedColorData.documents) {
      if (this.editedColorData.documents.length > 0) {
        this.editedFileData = [];
        this.isFilesAdded = true;
        this.removedDocIds = "";
        //this.removedFileData = [];
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        let editeddata = JSON.stringify(this.editedColorData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }

  /* If any error from api response */
  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      // this.error(err.error.message);
      this.toastService.showError("Oops!! Something went wrong. Please try again.");
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.userservice.logout();
      this.toastService.showError("The token being used has expired. Please login again.");
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      // this.userservice.error();
      this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
      this.showLoader = false;
    }
    else {
      // this.error(err.message);
      this.toastService.showError(this.staticMsgs.unResponsive.error);

    }
    this.showLoader = false;
  }

  /* To get classification related to color */
  getColorClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationColData) => {
      if (classificationColData.common.error === "") {
        if (classificationColData.common.message['item2'] === 'Success') {
          this.colorClassificationData = classificationColData.common.entities[classificationColData.common.ids[0]].items;
          this.logger.info(this.staticMsgs.colorDetail.colorClass, "Colordetails");
          this.refreshTree();
          if (this.isEditingMode && this.editedColorData) {
            this.setColourTreeValue(this.editedColorData["classification"]);
          }
          this.showLoader = false;
        } else {
          this.colorClassificationData = [];
          // this.error("Failed:" + classificationColData.common.message['item1'])
          this.toastService.showError(classificationColData.common.message['item1']);
          this.logger.error(classificationColData.common.message['item1'], "Colordetails");
          this.showLoader = false;
        }
      } else {
        this.logger.error(classificationColData.common.error.error.message, "Colordetails");
        // this.getError(classificationColData.common.error);
        this.toastService.showError(classificationColData.common.error);
        this.showLoader = false;
      }
    })


    /*  this.dataservice.getClassification(module).subscribe(response => {
       if (response.body['item2'] === "Success") {
         this.logger.info("Color classification loaded successfuly", "Colordetails");

         this.colorClassificationData = response.body['item1'][0]["items"];
         //console.log(JSON.stringify(this.colorClassificationData ))
         this.refreshTree();
         this.showLoader = false;
       }
       else {
         this.refreshTree();
         this.showLoader = false;
       }
     }, err => {
       //console.log(err)
       this.logger.error(err.error.message, "Colordetails");
       if (err.error.message === "Network error communicating with endpoint") {
         this.error(err.error.message);
         this.showLoader = false;
       } else if (err.error.message === "The incoming token has expired") {
         this.showLoader = false;
         this.sessionTimedOut();
         this.userservice.logout()
       } else if (err.error.message === "Unauthorized") {
         this.showLoader = false;
         this.userservice.error();
       }
       else {
         this.logger.error(err.error.message, "Colordetails");
         this.error(err.error.message);
         this.showLoader = false;
         this.userservice.error();
       }
     }) */
  }

  /* To get classification related to color */
  getDocumentClassification(module: string) {
    this.store.dispatch(new commonActionTypes.LoadClassifications(module));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationDocData) => {
      if (classificationDocData.common.error === "") {
        if (classificationDocData.common.message['item2'] === 'Success') {
          this.documentClassificationData = classificationDocData.common.entities[classificationDocData.common.ids[0]].items;
          this.logger.info(this.staticMsgs.colorDetail.colorClass, "Colordetails");
          this.showLoader = false;
          if (this.editedColorData.documents) {
            if (this.editedColorData.documents.length > 0) {
              this.editedFileData = [];
              this.isFilesAdded = true;
              this.removedDocIds = "";
              //this.removedFileData = [];
              let control = <FormArray>this.documentsForm.controls.documents;
              if (control.length > 0) {
                this.documentsForm = this.fb.group({
                  documents: this.fb.array([])
                })
              }
              let editeddata = JSON.stringify(this.editedColorData.documents);
              let data = JSON.parse(editeddata);
              this.reloadDocumentFormData(data);
              this.showLoader = false;
            }
            else {
              this.showLoader = false;
            }
          }
          else {
            this.showLoader = false;
          }
        } else {
          // this.error("Failed:" + classificationDocData.common.message['item1'])
          this.toastService.showError(classificationDocData.common.message['item1']);
          this.logger.error(classificationDocData.common.message['item1'], "Colordetails");
          this.showLoader = false;
        }
      } else {
        this.logger.error(classificationDocData.common.error.error.message, "Colordetails");
        // this.getError(classificationDocData.common.error);
        this.toastService.showError(classificationDocData.common.error);
        this.showLoader = false;
      }
    })

    /* this.dataservice.getClassification(module).subscribe(response => {
     if (response.body['item2'] === "Success") {
       this.documentClassificationData = response.body['item1'][0]["items"];
       this.logger.info("Document classification loaded successfuly", "Colordetails");
       //console.log(JSON.stringify(this.documentClassificationData ))
       //this.refreshDocTree();
       this.showLoader = false;
     }
     else {
       this.showLoader = false;
     }
   }, err => {
     this.logger.error(err.error.message, "Colordetails");

     if (err.error.message === "Network error communicating with endpoint") {
       this.error(err.error.message);
       this.showLoader = false;
     } else if (err.error.message === "The incoming token has expired") {
       this.sessionTimedOut();
       this.showLoader = false;
     } else if (err.error.message === "Unauthorized") {
       this.userservice.error();
       this.showLoader = false;
     }
     else {
       this.error(err.message);
       this.logger.error(err.error.message, "Colordetails");


     }
     this.showLoader = false;
   }); */
  }

   getSharedData() {
    console.log('getSharedData')
    // this.storageservice.storeEditedColorId(id);
    // this.storageservice.storeEditedColorDetailsId(id);
    // this.dataservice.AddClientLog();
    this.getAllUsers();
    this.getAllsharedaccess();
    this.isedit[this.fieldArray?.length] = true;
    console.log('edit',this.fieldArray)

    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);

    var fields =
    {
      "edit": false,
      "view": true,
      "id": "",
      "sharedTo": "",
      "user": "",
    }
      ;
    this.fieldArray?.push(fields);
    this.isedit = [false];

  }
  getAllsharedaccess() {

    if (this.storageservice.getEditedColorId()) {
      this.showLoader = true;
      this.sharedUsers = [];
      this.dataservice.getsharedaccess(this.storageservice.getEditedColorId(), 'Colors').subscribe(response => {
        if (response.status === 200) {

          //this.allUser = response.body;
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            this.logger.info(this.staticMsgs.colorDetail.getShare, "Color Details");
            for (let i = 0; i < response.body['item1'].length; i++) {
              response.body['item1'][i].module = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].module);
              response.body['item1'][i].org = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].module);
              for (let j = 0; j < response.body['item1'][i].user.length; j++) {
                response.body['item1'][i].user[j].firstName = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].firstName);
                response.body['item1'][i].user[j].lastName = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].lastName);
                response.body['item1'][i].user[j].about = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].about);
                response.body['item1'][i].user[j].eMail = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].eMail);
                response.body['item1'][i].user[j].group = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].group);
                response.body['item1'][i].user[j].org = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].org);
                response.body['item1'][i].user[j].fullName = response.body['item1'][i].user[j].firstName + " " + response.body['item1'][i].user[j].lastName;
                if (response.body['item1'][i].user[j].picture) {
                  response.body['item1'][i].user[j].picture.thumbnail = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].picture.thumbnail);
                  response.body['item1'][i].user[j].picture.name = this.aesdecryptservice.decryptData(this.dataservice.Key, response.body['item1'][i].user[j].picture.name);
                }

                var isOnline = false;
                if (this.onlineUsers?.length > 0) {
                  if (this.onlineUsers.indexOf(response.body['item1'][i].user[j].eMail) !== -1) {
                    isOnline = true;
                  }
                }
                let item = {
                  user: response.body['item1'][i].user,
                  isOnline: isOnline
                };
                this.sharedUsers.push(item);

              }
            }

            //this.isSharedView=true;
            this.fieldArray = response.body['item1'];
            this.newfieldArray = [];
            this.newfieldArray = response.body['item1'];

          } else {
            //console.log(response)
            //this.alert = 'danger';
            //this.errorDiv = true;
            // this.errors = response.body['item2'];
            // this.error(response.body['item1']);
            this.toastService.showError(response.body['item1']);
            this.logger.error(response.body['item1'], "Color Details");

            this.showLoader = false;
          }
        }
      }, err => {
        //this.alert = 'danger';
        //this.errorDiv = true;
        //this.errors = err.message;
        this.logger.error(err.error.message, "Color Details");

        if (err.error.message === "Network error communicating with endpoint") {
          // this.error(err.error.message);
          this.toastService.showError("Oops!! Something went wrong. Please try again.");
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout();
          this.toastService.showError("The token being used has expired. Please login again.");
        } else if (err.error.message === "Unauthorized") {
          // this.userservice.error();
          this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
        }
        else {
          // this.error(err.message);
          this.toastService.showError(this.staticMsgs.unResponsive.error);
          this.logger.error(err.message, "Color Details");

        }
        this.showLoader = false;
      });
    }
  }

  getAllUsers() {
    this.showLoader = true;
    let UserData = JSON.parse(localStorage.getItem('userFullName'));
    this.dataservice.getallUsers().subscribe(response => {
      if (response.body) {
        this.showLoader = false;
        this.logger.info(this.staticMsgs.colorDetail.loadAll, "Color Details");
        var data = response.body['item1'];
        this.itemList = data.filter(x => this.aesdecryptservice.decryptData(this.dataservice.Key,x.eMail) !== UserData['eMail']);

        this.itemList.forEach((x)=>{
          x['id'] = x.id;
          x['itemName'] = this.aesdecryptservice.decryptData(this.dataservice.Key,x.firstName)+' '+this.aesdecryptservice.decryptData(this.dataservice.Key,x.lastName)+' - '+this.aesdecryptservice.decryptData(this.dataservice.Key,x.eMail);
        });

        this.dropdownList = this.itemList;

        //console.log("USER DATA",this.itemList);


      }
      else {
        // this.error(response.body['item1']);
        this.toastService.showError(response.body['item1']);
        this.logger.error(response.body['item1'], "Color Details");
        this.showLoader = false;
      }
    }, err => {
      this.logger.error(err.error.message, "Color Details");
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.showLoader = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
        this.showLoader = false;
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
        this.showLoader = false;
      }
      else {
        // this.error(err.message);
        this.toastService.showError(this.staticMsgs.unResponsive.error);
        this.logger.error(err.error.message, "Color Details");


      }
      this.showLoader = false;
    });
  }
  /* To get color details */
  getColorDetails() {
    this.showLoader1 = true;
    this.store.dispatch(new colorActions.LoadColor(this.storageservice.getEditedColorId()));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorData) => {
      if (colorData.color.error === "") {
        if (colorData.color.message['item2'] === 'Success') {
          this.editedColorData = colorData.color.entities[this.storageservice.getEditedColorId()];
          this.initForm();
          // this.getSharedData();
          console.log(this.editedColorData,'editedcolor');
          this.getColorClassification("Colours");
          this.logger.info(this.editedColorData["name"] + " loaded successfuly", "Colordetails");
          this.showLoader1 = false;
          this.colorForm.get('colorclassification').setValue(this.editedColorData["classification"]);
          const temp = {
            'image': this.thumbnail,
            'thumbImage': this.thumbnail
          }
          this.imageObject.push(temp);
          this.colorclassificationValue = this.editedColorData["classification"];
          if (this.editedColorData["classification"] === "Solid") {
            this.colorForm.get('colorstandard').enable();
            this.colorForm.get('pantone').enable();
            this.colorForm.get('colorhexacode').enable();
            setTimeout(() => {
              this.loadSelectPicker();
            }, 10);
          } else if (this.editedColorData["classification"] === "Print and Pattern") {
            this.colorForm.get('colorstandard').disable();
            this.colorForm.get('pantone').disable();
            this.colorForm.get('colorhexacode').disable();
            this.colorForm.get('colorstandard').setValue("");
            this.colorForm.get('pantone').setValue("");
            this.colorForm.get('colorhexacode').setValue("");
            this.thumbNails = [];
          }
          else {
            this.colorForm.get('colorstandard').disable();
            this.colorForm.get('pantone').disable();
            this.colorForm.get('colorhexacode').enable();
            this.colorForm.get('colorstandard').setValue("");
            this.colorForm.get('pantone').setValue("");
          }
          this.colorForm.get('colorid').setValue(this.editedColorData["id"]);
          this.colorForm.get('colorseq').setValue(this.editedColorData["sequence"]);
          this.sequenceNumber = this.editedColorData["sequence"];
          this.colorForm.get('colorname').setValue(this.editedColorData["name"]);
          this.colorForm.get('internalref').setValue(this.editedColorData["internalRef"]);
          this.colorForm.get('status').setValue(this.editedColorData["status"]);
          this.colorForm.get('description').setValue(this.editedColorData["description"]);
          if (this.editedColorData["colourSwatch"]) {
            this.color = JSON.parse(this.editedColorData["colourSwatch"]);
            this.colourSwatch = this.editedColorData["colourSwatch"];
          }
          this.colorForm.get('colorhexacode').setValue(this.editedColorData["hexcode"]);
          this.colorbg = this.editedColorData["hexcode"];
          this.colorHex = this.editedColorData["hexcode"];
          if (this.editedColorData["colorStandard"]) {
            this.colorForm.get('colorstandard').setValue(this.editedColorData["colorStandard"]);
          }
          this.colorForm.get('pantone').setValue(this.editedColorData["pantoneCode"]);
          //this.pantoneValue = this.editedColorData["pantoneCode"];
          if (this.editedColorData["thumbnailFiles"]) {
            this.editedThumbnails = [];
            this.editedThumbnails.push(this.editedColorData["thumbnailFiles"]);
            this.editedThumbnailValue = (this.editedColorData["thumbnailFiles"].length > 0) ? this.editedColorData["thumbnailFiles"][0]["thumbnail"] : this.editedColorData["thumbnailFiles"]["thumbnail"];
            console.log(this.editedThumbnailValue,'editthumb');
          }
          this.refreshSelect();
        } else {
          // this.error("Failed:" + colorData.color.message['item1'])
          this.toastService.showError(colorData.color.message['item1']);
          this.logger.error(colorData.color.message['item1'], "Colordetails");
          this.showLoader1 = false
        }
      } else {
        this.logger.error(colorData.color.error.error.message, "Colordetails");
        // this.getError(colorData.color.error);
        this.toastService.showError(colorData.color.error);
        this.showLoader1 = false;
      }
    })
  }

  /* Reset the dropdown & add the dropdown values by using data for color */
  refreshTree() {
    var data = this.colorClassificationData;
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML element
      $("#colorclassification").kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        filter: "startswith",
        dataSource: data,
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          //alert(JSON.stringify(value.text))
          if (value) {
            self.colorclassificationValue = value["text"];
            self.colorForm.get('colorclassification').setValue(value["text"]);
            if (value["text"] === "Solid") {
              self.colorForm.get('colorstandard').enable();
              self.colorForm.get('pantone').enable();
              self.colorForm.get('colorhexacode').enable();
              setTimeout(() => {
                self.loadSelectPicker();
              }, 10);
            } else if (value["text"] === "Print and Pattern") {
              self.colorForm.get('colorstandard').disable();
              self.colorForm.get('pantone').disable();
              self.colorForm.get('colorhexacode').disable();
            }
            else {
              self.colorForm.get('colorstandard').disable();
              self.colorForm.get('pantone').disable();
              self.colorForm.get('colorhexacode').enable();
            }
          }
          else {
            self.colorclassificationValue = "";
            self.colorForm.get('colorclassification').setValue("");
          }
        }
      });

    });
  }

  /* To set the value to color dropdown */
  setColourTreeValue(value) {
    $(document).ready(function () {
      // console.log(value)
      $("#colorclassification").data('kendoDropDownTree').value(value);
    })
  }

  /* Reset the dropdown & add the dropdown values by using data for document */
  refreshDocTree(index: number) {
    var data = this.documentClassificationData;
    var currentDocument = this.getControls();
    var self = this;
    $(document).ready(function () {
      // create kendoDropDownTree from input HTML
      var treeview = $("#docclassification" + index).kendoDropDownTree({
        placeholder: "Select Classification",
        height: "auto",
        filter: "startswith",
        dataSource: data,
        treeview: {
          select: function (e) {
            if (e.sender.dataItem(e.node).hasChildren) {
              e.preventDefault()
            }
          }
        },
        change: function (e) {
          var value = this.value();
          //alert(value);
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 10);
          if (value) {
            //self.documentclassificationValue = value["text"];
            //console.log(value["text"])
            currentDocument[index].get('docclassification').setValue(value["text"]);
            if (value["text"] === "Lab Reports") {
              currentDocument[index].get('docreportstatus').enable();
              currentDocument[index].get('doclablocation').enable();
            }
            else {
              currentDocument[index].get('docreportstatus').disable();
              currentDocument[index].get('doclablocation').disable();
            }
          }
          else {
            //self.documentclassificationValue = "";
            currentDocument[index].get('docclassification').setValue("");
          }
        }
      });

    });
  }

  /* To set the value to document dropdown */
  setDocumentTreeValue(index, value) {
    $(document).ready(function () {
      $("#docclassification" + index).data('kendoDropDownTree').value(value);
    })
  }

  /* Clicked on color tab to get color details */
  colorDetailsTab(activeTab) {
    this.activeTab = activeTab;
    this.isThumbnailAdded = true;
    if (this.storageservice.getEditedColorId()) {
      this.getColorDetails();
    }
    this.initForm();
    this.loadSelectPicker();
  }

  /* Clicked on document tab to get document details */
  docDetailsTab(activeTab) {
    this.activeTab = activeTab;
    this.showLoader = true;
    this.loadSelectPicker();
    this.getDocumentClassification("Documents");
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
    if (this.editedColorData.documents) {
      if (this.editedColorData.documents.length > 0) {
        this.editedFileData = [];
        this.isFilesAdded = true;
        this.removedDocIds = "";
        let control = <FormArray>this.documentsForm.controls.documents;
        if (control.length > 0) {
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
        }
        let editeddata = JSON.stringify(this.editedColorData.documents);
        let data = JSON.parse(editeddata);
        this.reloadDocumentFormData(data);
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
      }

    }
    else {
      this.showLoader = false;
    }
  }

  /* To get document form controls */
  getControls() {
    return (this.documentsForm.get('documents') as FormArray).controls;
  }

  /* To add new document form */
  addNewDoc() {
    let control = <FormArray>this.documentsForm.controls.documents;
    control.push(
      this.fb.group({
        docclassification: ['', Validators.required],
        docid: [''],
        docseq: [''],
        docname: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9-_ ]{3,50}$')]],
        docstatus: ['', Validators.required],
        docdescription: ['', [Validators.required, Validators.maxLength(500)]],
        docreportstatus: ['', Validators.required],
        doclablocation: ['', [Validators.required, Validators.maxLength(25)]],
        docnotes: [''],
        docdeletedfileids: ['']
      })
    )
    this.files = [];
    this.fileData[control.length - 1] = this.files;
    this.showButton = true;
    this.refreshSelect();
    this.refreshDocTree(control.length - 1);
  }


   /* To drag the documents from folder */
   onSelectDoc(event, i) {
    this.files = [];
    let imgext = "png,jpeg,jpg,xlsx,pdf,txt,docx,pptx"
    var str = event.addedFiles[0]?.name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.files.push(...event.addedFiles);
      this.isFilesAdded = true;
      this.doc_Error[i] = '';

    }
    else {
      this.isFilesAdded = false;
      // this.error("File Not Supported.")
      this.toastService.showError("File Not Supported.");
      return false;
    }
     console.log(event.addedFiles,'event');
     this.fileData[i] = this.files;
  }


  /* To cancel the document form */
  removeDoc(index) {
    console.log(index);
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();
    if(this.editedFileData.length !== 1) {
      if (this.removedDocIds) {
        this.removedDocIds = this.removedDocIds + "," + documentControl[index].get('docid').value
      }
      else {
        this.removedDocIds = documentControl[index].get('docid').value
      }    }
    else {
      alert('Minimum 1 document is required');
      return false;
    }
    // if (this.removedDocIds) {
    //   this.removedDocIds = this.removedDocIds + "," + documentControl[index].get('docid').value
    // }
    // else {
    //   this.removedDocIds = documentControl[index].get('docid').value
    // }
    control.removeAt(index)
    this.fileData.splice(index, 1);
    this.editedFileData.splice(index, 1);
  }


  /* To remove document file */
  onRemove(event, i) {
      this.fileData[i].splice(this.fileData[i].indexOf(event), 1);
  }

  /* To select the documents from folder */
  onSelectThumbnail(event) {
    this.thumbNails = [];
    console.log(event,'event');
    let imgext = "png,jpeg,jpg"
    var str = event.addedFiles[0].name.split('.');
    if (event.addedFiles.length === 1 && imgext.includes(str[1])) {
      this.thumbNails.push(...event.addedFiles);
      this.isThumbnailAdded = true;
      console.log(this.thumbNails);
    }
    else {
      this.isThumbnailAdded = false;
      // this.error(this.staticMsgs.colorDetail.unsupport)
      this.toastService.showError(this.staticMsgs.colorDetail.unsupport);
      return false;
    }
  }

  /* To remove document file */
  onRemoveThumbnail(event) {
    this.thumbNails.splice(this.thumbNails.indexOf(event), 1);
    if (this.thumbNails.length === 1) {
      this.isThumbnailAdded = true;
    }
    else {
      this.isThumbnailAdded = false;
    }
  }

  /* To remove image from document fom */
  removeImgSection(event, i) {
    this.editedFileData[i].splice(this.editedFileData[i].indexOf(event), 1);
    console.log(this.editedFileData);

    if(this.editedFileData[i].length == 0){
      this.isFilesAdded = false;
    }
    let documentControl = this.getControls();
    let docdeltedFiles = documentControl[i].get('docdeletedfileids').value;
    if (docdeltedFiles) {
      documentControl[i].get('docdeletedfileids').setValue(docdeltedFiles + "," + event.id);
    }
    else {
      documentControl[i].get('docdeletedfileids').setValue(event.id);
    }
    //this["files"+i].splice(this["files"+i].indexOf(event), 1);
  }

  /* To remove image from color fom */
  removeThumbnailSection(event) {
    this.editedThumbnails.splice(this.editedThumbnails.indexOf(event), 1);
  }

  /* To change in color hexcode */
  handleChange($event: ColorEvent) {
    this.colorbg = ($event.color.hex);
    this.colorHex = ($event.color.hex);
    this.colourSwatch = JSON.stringify($event.color);
    this.colorForm.get('colorhexacode').setValue($event.color.hex);
    this.R = $event.color.rgb.r.toString();
    this.G = $event.color.rgb.g.toString();
    this.B = $event.color.rgb.b.toString();
  }

  /* To filter the data by using color standard */
  onSelect(val: string[]) {
  }

  /* To save the color form */
  async onSubmit() {
    this.submitted = true;
    var thumbnailId = null;
    var thumbnailName = null;
    if (this.colorForm.invalid) {
      // this.error("Invalid Color Form")
      this.toastService.showError("Please enter all the mandatory fields.");
      if(this.thumbNails.length == 0){
        this.isThumbnailAdded=false;
        }
      return false;
    }

    if (!this.f.colorid.value) {
      if (this.colorclassificationValue === "Print and Pattern") {
        if (this.thumbNails.length === 1) {
          this.isThumbnailAdded = true;
        }
        else {
          this.isThumbnailAdded = false;
        }
      }
      else {
        this.isThumbnailAdded = true;
      }
    }
    else {
      if (this.colorclassificationValue === "Print and Pattern") {
        if (this.editedThumbnails.length === 1) {
          this.isThumbnailAdded = true;
        }
        else {
          if (this.thumbNails.length === 1) {
            this.isThumbnailAdded = true;
          }
          else {
            this.isThumbnailAdded = false;
          }
        }
      }
      else {
        this.isThumbnailAdded = true;
      }
    }



    if (!this.colorForm.valid) {
      return false;
    } else if (!this.isThumbnailAdded) {
      return false;
    }
    this.showLoader = true;
    if (this.isThumbnailAdded) {
      if (this.colorclassificationValue === "Print and Pattern") {
        if (this.thumbNails.length === 1) {
          let thumbnailuploaded = await this.uploadFile(this.thumbNails[0]);
          if (thumbnailuploaded != "error") {
            thumbnailId = JSON.parse(thumbnailuploaded)["Key"];
            thumbnailName = this.thumbNails[0].name;
          }
          else {
            // this.error(thumbnailuploaded);
            this.toastService.showError(thumbnailuploaded);
            this.submitted = false;
            this.showLoader = false;
            return false;
          }
        }
      }
    }

    let colorData = {
      classification: this.aesdecryptservice.encryptData(this.dataservice.Key, this.colorclassificationValue),
      name: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.colorname.value),
      internalRef: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.internalref.value),
      status: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.status.value),
      description: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.description.value),
      colourSwatch: this.aesdecryptservice.encryptData(this.dataservice.Key, this.colourSwatch),
      hexcode: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.colorhexacode.value),
      colorStandard: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.colorstandard.value.toString()),
      pantoneCode: this.aesdecryptservice.encryptData(this.dataservice.Key, this.f.pantone.value),
      r: this.aesdecryptservice.encryptData(this.dataservice.Key, this.R),
      g: this.aesdecryptservice.encryptData(this.dataservice.Key, this.G),
      b: this.aesdecryptservice.encryptData(this.dataservice.Key, this.B),
      thumbnail: thumbnailId,
      thumbnailName: this.aesdecryptservice.encryptData(this.dataservice.Key, thumbnailName)
    };

    if (this.colorclassificationValue === "Print and Pattern") {
      colorData.colorStandard = "";
      colorData.pantoneCode = "";
      colorData.r = "";
      colorData.g = "";
      colorData.b = "";
      colorData.colourSwatch = "";
      colorData.hexcode = "";
    }
    else if (this.colorclassificationValue === "Custom") {
      colorData.colorStandard = "";
      colorData.pantoneCode = "";
    }

    if (!this.f.colorid.value) {
      this.store.dispatch(new colorActions.CreateColor(colorData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((addedColor) => {
        if (addedColor.color.error === "") {
          if (addedColor.color.message['item2'] === 'Success') {
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedColorData = addedColor.color.entities[addedColor.color.selectedColorId];
            this.thumbNails = [];
            this.editedThumbnails = [];
            this.editedThumbnailValue = "";
            this.reloadColorFormData(addedColor.color.entities[addedColor.color.selectedColorId]);
            // this.success(this.staticMsgs.colorDetail.colorCreate);
            this.toastService.showSuccess(this.staticMsgs.colorDetail.colorCreate);
            this.logger.info(this.staticMsgs.colorDetail.colorCreate1, "Colordetails");
          } else {
            this.showLoader = false;
             this.submitted = false;
            // this.error("Failed:" + addedColor.color.message['item1'])
            this.toastService.showError(addedColor.color.message['item1']);
            this.logger.error(addedColor.color.message['item1'], "Colordetails");
          }
        } else {
          this.logger.error(addedColor.color.error.error.message, "Colordetails");
          // this.getError(addedColor.color.error);
          this.toastService.showError(addedColor.color.error);
          this.showLoader = false;
        }
      })

      //console.log(JSON.stringify(colorData))
      /* this.dataservice.addColor(colorData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            this.showLoader = false;
            //console.log(JSON.stringify(response.body['item1']));
            //this.alert = 'success';
            // this.errorDiv = true;
            // this.errors = 'Color Created Sucessfully.';
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedColorData = response.body['item1'];
            //console.log(this.editedColorData);
            this.thumbNails = [];
            this.editedThumbnails = [];
            this.editedThumbnailValue = "";
            this.reloadColorFormData(response.body['item1']);
            this.success("Color Created Successfully!");
            this.logger.info("Color Created Successfully", "Colordetails");

          }
          else {
            //console.log(response)
            //this.alert = 'danger';
            //this.errorDiv = true;
            // this.errors = response.body['item2'];
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "Colordetails");

            this.showLoader = false;
          }

        }
      }, err => {
        //this.alert = 'danger';
        //this.errorDiv = true;
        //this.errors = err.message;
        this.logger.error(err.error.message, "Colordetails");

        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.logger.error(err.message, "Colordetails");

          this.error(err.message);
        }
        this.showLoader = false;
      }); */

    }

    else {
      let colourid = { id: this.storageservice.getEditedColorId() };
      let updatedColorData = { ...colorData, ...colourid };
      this.store.dispatch(new colorActions.UpdateColor(updatedColorData));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((updatedColor) => {
        if (updatedColor.color.error === "") {
          if (updatedColor.color.message['item2'] === 'Success') {
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedColorData = updatedColor.color.entities[updatedColor.color.selectedColorId];
            this.thumbNails = [];
            this.editedThumbnails = [];
            this.editedThumbnailValue = "";
            this.reloadColorFormData(updatedColor.color.entities[updatedColor.color.selectedColorId]);
            // this.success(this.staticMsgs.colorDetail.colorUpd);
            this.toastService.showSuccess(this.staticMsgs.colorDetail.colorUpd);
            this.logger.info(this.staticMsgs.colorDetail.colorUpd1, "Colordetails");
          } else {
            this.showLoader = false;
            // this.error("Failed:" + updatedColor.color.message['item1'])
            this.toastService.showError(updatedColor.color.message['item1']);
            this.logger.error(updatedColor.color.message['item1'], "Colordetails");
          }
        } else {
          this.logger.error(updatedColor.color.error.error.message, "Colordetails");
           this.submitted = false;
          // this.getError(updatedColor.color.error);
          this.toastService.showError(updatedColor.color.error);
          this.showLoader = false;
        }
      })


      //console.log(JSON.stringify(updatedColorData))
      /* this.dataservice.updateColorById(updatedColorData).subscribe(response => {
        if (response.status === 200) {
          if (response.body['item2'] === "Success") {
            //console.log(JSON.stringify(response.body['item1']));
            this.showLoader = false;
            this.submitted = false;
            this.isEditMode = true;
            this.isEditingMode = false;
            this.isViewingMode = true;
            this.editedColorData = response.body['item1'];
            //console.log(this.editedColorData);
            this.thumbNails = [];
            this.editedThumbnails = [];
            this.editedThumbnailValue = "";
            this.reloadColorFormData(response.body['item1'])
            this.success("Color Updated Successfully!");
            this.logger.info("Color Updated Successfully", "Colordetails");

          }
          else {
            console.log(response)
            this.error(response.body['item1']);
            this.logger.error(response.body['item1'], "Colordetails");
            this.showLoader = false;
          }

        }
      }, err => {
        this.logger.error(err.error.message, "Colordetails");
        if (err.error.message === "Network error communicating with endpoint") {
          this.error(err.error.message);
        } else if (err.error.message === "The incoming token has expired") {
          this.sessionTimedOut();
          this.userservice.logout()
        } else if (err.error.message === "Unauthorized") {
          this.userservice.error();
        }
        else {
          this.error(err.message);
          this.logger.error(err.message, "Colordetails");

        }
        this.showLoader = false;
      }); */
    }

  }

  /* To load the color form with the data */
  reloadColorFormData(data) {
    this.initForm();
    //console.log(JSON.stringify(data))
    this.colorForm.get('colorclassification').setValue(data["classification"]);
    //this.refreshTree();
    if (this.isEditingMode) {
      this.setColourTreeValue(data["classification"]);
    }
    this.colorclassificationValue = data["classification"];
    if (data["classification"] === "Solid") {
      this.colorForm.get('colorstandard').enable();
      this.colorForm.get('pantone').enable();
      this.colorForm.get('colorhexacode').enable();
      setTimeout(() => {
        this.loadSelectPicker();
      }, 10);
    } else if (data["classification"] === "Print and Pattern") {
      this.colorForm.get('colorstandard').disable();
      this.colorForm.get('pantone').disable();
      this.colorForm.get('colorhexacode').disable();
    }
    else {
      //this.refreshTree();
      this.colorForm.get('colorstandard').disable();
      this.colorForm.get('pantone').disable();
      this.colorForm.get('colorhexacode').enable();
    }
    this.colorForm.get('colorid').setValue(data["id"]);
    this.storageservice.storeEditedColorId(data["id"]);
    this.colorForm.get('colorseq').setValue(data["sequence"]);
    //this.colorseqValue = data["sequence"];
    this.colorForm.get('colorname').setValue(data["name"]);
    //this.colornameValue = data["name"];
    this.colorForm.get('internalref').setValue(data["internalRef"]);
    //this.internalrefValue = data["internalRef"];
    this.colorForm.get('status').setValue(data["status"]);
    //this.statusValue = data["status"];
    this.colorForm.get('description').setValue(data["description"]);
    //this.descriptionValue = data["description"];
    if (data["colourSwatch"]) {
      this.color = JSON.parse(data["colourSwatch"]);
      this.colourSwatch = data["colourSwatch"];
    }
    this.colorForm.get('colorhexacode').setValue(data["hexcode"]);
    this.colorbg = data["hexcode"];
    this.colorHex = data["hexcode"];
    //this.colorhexacodeValue = data["hexcode"];
    if (data["colorStandard"]) {
      this.colorForm.get('colorstandard').setValue(JSON.parse("[\"" + data["colorStandard"] + "\"]"));
      //this.colorstandardValue = JSON.parse("[\"" + data["colorStandard"] + "\"]");
    }
    this.colorForm.get('pantone').setValue(data["pantoneCode"]);
    //this.pantoneValue = data["pantoneCode"];
    const temp = {
      'image': this.thumbnail,
      'thumbImage': this.thumbnail
    }
    this.imageObject.push(temp);
    if (data["thumbnailFiles"]) {
      this.editedThumbnails = [];
      this.editedThumbnails.push(data["thumbnailFiles"]);
      this.editedThumbnailValue = (data["thumbnailFiles"].length > 0) ? data["thumbnailFiles"][0]["thumbnail"] : data["thumbnailFiles"]["thumbnail"];
    }

  }
  doc_Error = [];
  statusForm: boolean = false;
  async documentUploadCheck(documentControl) {
    this.doc_Error = [];
    console.log(this.editedFileData);

   // if (this.editedFileData.length > 0) {
      for (let i = 0; i < documentControl.length; i++) {
        if (this.editedFileData.length > 0) {
          if (i+1 <= documentControl?.length) {
            if (this.editedFileData[i]?.length > 0) {
              this.doc_Error.push('');
            } else {
              if (this.fileData[i].length > 0) {
                this.doc_Error.push('');
              } else {
                this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired);
              }
            }
          } else {
            if (this.fileData.length > 0) {
              if (this.fileData[i].length > 0) {
                this.doc_Error.push('');
              } else {
                this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired)
              }
            } else {
              this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired)
            }
          }

        } else {
          if (this.fileData.length > 0) {
            if (this.fileData[i].length > 0) {
              this.doc_Error.push('');
            } else {
              this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired)
            }
          } else {
            this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired)
          }

        }
      }
   // }

    if (this.doc_Error.length > 0) {
      for (let i = 0; i < this.doc_Error.length; i++) {
        if (this.doc_Error[i] == this.staticMsgs.colorDetail.documentisrequired) {
          this.statusForm = true;
          break;
        } else {
          this.statusForm = false;
        }
      }
    } else {
      this.doc_Error.push(this.staticMsgs.colorDetail.documentisrequired);
      this.statusForm = true;
    }
    return this.statusForm;
  }



  /* To save the document form */
  async onDocSubmit() {
    this.submitted1 = true;
    let control = <FormArray>this.documentsForm.controls.documents;
    let documentControl = this.getControls();

    //alert(this.findInvalidControlsRecursive(this.documentsForm))
    if (!this.documentsForm.valid) {
      // this.error(this.staticMsgs.colorDetail.invalidDoc)
      this.toastService.showError(this.staticMsgs.colorDetail.invalidDoc);
      return false;
    }
    let statusUploadDoc = await this.documentUploadCheck(documentControl);
    if (statusUploadDoc == true) {
      return false
    }
    this.showLoader = true;


    const formData = new FormData();
    formData.append('colorid', this.storageservice.getEditedColorId());
    formData.append('doccount', control.length.toString());
    if (this.removedDocIds) {
      formData.append('docdelete', this.removedDocIds);
    }
    for (let i = 0; i < control.length; i++) {
      if (documentControl[i].get('docid').value) {
        formData.append('docid' + '[' + i + ']', documentControl[i].get('docid').value);
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));
      }
      else {
        formData.append('tag' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
      }

      if (documentControl[i].get('docdeletedfileids').value) {
        formData.append('filedelete' + '[' + i + ']', documentControl[i].get('docdeletedfileids').value);
      }

      formData.append('baseclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, "Documents"));
      formData.append('docclassification' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docclassification').value));
      formData.append('docname' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docname').value));
      formData.append('docstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docstatus').value));
      formData.append('docdescription' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docdescription').value));
      formData.append('docreportstatus' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docreportstatus').value));
      formData.append('doclablocation' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('doclablocation').value));
      formData.append('docnotes' + '[' + i + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, documentControl[i].get('docnotes').value));
      formData.append('docfilecount' + '[' + i + ']', this.fileData[i].length);

      let a: number = 0;
      for (let f of this.fileData[i]) {
        if (JSON.stringify(f) != '[]') {
          //console.log (f.name);
          let fileUploadedData = await this.uploadFile(f);
          if (fileUploadedData != "error") {
            formData.append('file' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, JSON.parse(fileUploadedData)["Key"]));
            formData.append('filename' + i + '[' + a + ']', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
          }
          else {
            // this.error(fileUploadedData);
            this.toastService.showError(fileUploadedData);
            this.submitted1 = false;
            this.showLoader = false;
            return false;
          }
        }
        a = a + 1;
      }
    }

    //console.log(formData);
    //this.test(formData);
    this.store.dispatch(new colorActions.UpdateColorDocument(formData));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorDocData) => {
      if (colorDocData.color.error === "") {
        if (colorDocData.color.message['item2'] === 'Success') {
          this.showLoader = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedColorData = colorDocData.color.entities[this.storageservice.getEditedColorId()];
          if (this.editedColorData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            this.isFilesAdded = true;
            let editeddata = JSON.stringify(this.editedColorData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          //this.docDetailsTab('docDetailsTab');
          // this.success(this.staticMsgs.colorDetail.docUpd);
          this.toastService.showSuccess(this.staticMsgs.colorDetail.docUpd);
          this.logger.info(this.staticMsgs.colorDetail.docUpd1, "Colordetails");
        } else {
          this.showLoader = false;
          // this.error("Failed:" + colorDocData.color.message['item1'])
          this.toastService.showError(colorDocData.color.message['item1']);
          this.logger.error(colorDocData.color.message['item1'], "Colordetails");
        }
      } else {
        this.logger.error(colorDocData.color.error.error.message, "Colordetails");
        // this.getError(colorDocData.color.error);
        this.toastService.showError(colorDocData.color.error);
        this.showLoader1 = false;
      }
    })


    /* this.dataservice.updateColorDocuments(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          //console.log(JSON.stringify(response.body['item1']));
          this.showLoader = false;
          this.submitted1 = false;
          this.isEditMode = true;
          this.isEditingMode = false;
          this.isViewingMode = true;
          this.documentsForm.reset();
          this.editedFileData = [];
          this.removedDocIds = "";
          this.documentsForm = this.fb.group({
            documents: this.fb.array([])
          })
          this.editedColorData = response.body['item1'];
          //console.log(this.editedColorData);
          if (this.editedColorData.documents.length > 0) {
            this.editedFileData = [];
            this.removedDocIds = "";
            let editeddata = JSON.stringify(this.editedColorData.documents);
            let data = JSON.parse(editeddata);
            this.reloadDocumentFormData(data);
          }
          //this.docDetailsTab('docDetailsTab');
          this.logger.info("Documents Updated Successfully", "Colordetails");

          this.success("Documents Updated Successfully!");
        }
        else {
          //console.log(response)
          this.error(response.body['item1']);
          this.logger.error(response.body['item1'], "Colordetails");

          this.showLoader = false;
        }

      }
    }, err => {
      this.logger.error(err.error.message, "Colordetails");
      if (err.error.message === "Network error communicating with endpoint") {
        this.error(err.error.message);
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout()
      } else if (err.error.message === "Unauthorized") {
        this.userservice.error();
      }
      else {
        this.error(err.message);
        this.logger.error(err.message, "Colordetails");

      }
      this.showLoader = false;
    }); */

  }

  /* To load the document form with the data */
  reloadDocumentFormData(data) {
    //console.log(JSON.stringify(data));
    for (let i = 0; i < data.length; i++) {
      this.addNewDoc();
      this.refreshDocTree(i);
    }
    //console.log(JSON.stringify(data))
    let documentControl = this.getControls();
    for (let i = 0; i < data.length; i++) {
      documentControl[i].get('docid').setValue(data[i]["id"]);
      documentControl[i].get('docseq').setValue(data[i]["sequence"]);
      this.refreshDocTree(i);
      if (this.isEditingMode) {
        //console.log("Coming")
        this.setDocumentTreeValue(i, data[i]["classification"]);
      }
      documentControl[i].get('docclassification').setValue(data[i]["classification"]);
      documentControl[i].get('docname').setValue(data[i]["name"]);
      documentControl[i].get('docstatus').setValue(data[i]["status"]);
      $('select[name=docstatus]').selectpicker('refresh');

      // this.documentsForm.get('docstatus').setValue(data[i]["status"]);

      documentControl[i].get('docdescription').setValue(data[i]["description"]);
      documentControl[i].get('docreportstatus').setValue(data[i]["reportStatus"]);
      documentControl[i].get('doclablocation').setValue(data[i]["labLocation"]);
      if (data[i]["classification"] === "Lab Reports") {
        documentControl[i].get('docreportstatus').enable();
        documentControl[i].get('doclablocation').enable();
      }
      else {
        documentControl[i].get('docreportstatus').disable();
        documentControl[i].get('doclablocation').disable();
      }
      documentControl[i].get('docnotes').setValue(data[i]["notes"]);
      this.editedFileData.push(data[i]["files"]);
      /* Written by : Himanshu */
      this.colorDataCnt = this.fileData[i].length+1;

    }
    this.refreshSelect();
  }

  /* To check whether files are empty or not */
  isEmptyObject(obj) {
    return JSON.stringify(obj) === '[]';
  }

  /* To open the image in documnet form */
  open(index: number, imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedFileData[index].length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedFileData[index][i - 1]["name"])) {
        src = this.editedFileData[index][i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedFileData[index][i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  /* To open the image in color form */
  openThumbnail(imageindex: number): void {
    this.cards = [];
    for (let i = 1; i <= this.editedThumbnails.length; i++) {
      let src = "";
      let thumb = "";
      if (this.isValidImageURL(this.editedThumbnails[i - 1]["name"])) {
        src = this.editedThumbnails[i - 1]["thumbnail"];
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.editedThumbnails[i - 1]["thumbnail"];
      }
      else {
        src = this.documentImage;
        // const caption = 'Image ' + i + ' caption here';
        thumb = this.documentImage;
      }
      const album = {
        src: src,
        //  caption: caption,
        thumb: thumb
      };
      this.cards.push(album);
    }
    // open lightbox
    this._lightbox.open(this.cards, imageindex);
  }

  /* To close the opened image */
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  /* To display success msg as sweet alert */
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  /* To display error msg as sweet alert */
  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.colorDetail.oops,
      text: message
    })
  }

  /* To display session expired msg as sweet alert */
  sessionTimedOut() {
    let timerInterval
    this.logger.error(this.staticMsgs.colorDetail.sessionExp, "Colordetails");

    Swal.fire({
      title: this.staticMsgs.colorDetail.sessionExp,
      html: this.staticMsgs.colorDetail.sessionLogin,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        //console.log('Session Timedout.')
        this.userservice.logout();
        return;
      }
    })

  }

  /* To upload file into AWS bucket */
  async uploadFile(file): Promise<any> {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          reject("error");
        }
        resolve(JSON.stringify(data));
      });
    });
  };

  /* To create an unique id which will send to AWS bucket */
  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /* To validate image type */
  isValidImageURL(str) {
    str = str.toLowerCase();
    str = str.split('?')[0];
    //moving on, split the uri into parts that had dots before them
    var parts = str.split('.');
    //get the last part ( should be the extension )
    var extension = parts[parts.length - 1];
    //define some image types to test against
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    //check if the extension matches anything in the list.
    if (imageTypes.indexOf(extension) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  /* To cancel the color form */
  cancelColorCreate() {
    this.router.navigate(['/colorlibrary']);
  }

/* To delete the color */
  onDeleteColor() {
    Swal.fire({
      title:this.staticMsgs.colorDetail.deleteColor,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.staticMsgs.colorDetail.deleteit
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        let deleterecordIds = [];
        deleterecordIds.push(this.storageservice.getEditedColorId());
        this.store.dispatch(new colorActionTypes.DeleteColorLibrary(deleterecordIds));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((colorLibrary) => {
          if (colorLibrary.color.error === "") {
            if (colorLibrary.color.message['item2'] === 'Success') {
              this.showLoader = false;
              // this.success(this.staticMsgs.colorDetail.colorDeleted);
              this.toastService.showSuccess(this.staticMsgs.colorDetail.colorDeleted);
              this.logger.info(this.staticMsgs.colorDetail.colorDeleted1, "ColorLibrary");
              this.router.navigate(['/colorlibrary']);
            } else {
              this.showLoader = false;
              // this.error("Failed:" + colorLibrary.color.message['item1'])
              this.toastService.showError(colorLibrary.color.message['item1']);
              this.router.navigate(['/colorlibrary']);
              this.logger.error(colorLibrary.color.message['item1'], "ColorLibrary");
            }
          } else {
            // this.getError(colorLibrary.color.error);
            this.toastService.showError(colorLibrary.color.error);
            this.showLoader = false;
          }

        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

}
