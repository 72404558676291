<app-loader [showLoader]="showLoader"></app-loader>
<!-- Root -->


<div class="dt-root" *ngIf="fpassword">


  <div class="dt-root__inner">
    <div class="dt-login--container">

      <!-- Login Content -->
      <div class="dt-login__content-wrapper">

        <!-- Login Background Section -->
        <div class="dt-login__bg-section">

          <div class="dt-login__bg-content">


            <!-- Login Title -->
            <h1 class="dt-login__title f-13">{{staticMsgs.forgotpassword.Fp}}</h1>
            <!-- /login title -->
          </div>


          <!-- Brand logo -->
          <div class="dt-login__logo text-center">
            <a class="dt-brand__logo-link" href="javascript:void(0)">
              <img class="dt-brand__logo-img" alt="Fitos">
            </a>
          </div>
          <!-- /brand logo -->

        </div>
        <!-- /login background section -->

        <!-- Login Content Section -->
        <div class="dt-login__content">

          <!-- Login Content Inner -->
          <div class="dt-login__content-inner">

            <!-- Form -->
            <form [formGroup]="forgotPassword" (ngSubmit)="onSubmit()">

              <p class="f-13 mb-10" *ngIf="errorDiv" [innerHTML]="errors"></p>

              <!-- Form Group -->
              <div class="form-group mb-5">
                <input class="form-control" type="email" formControlName="username" id="username"
                  placeholder="Enter email" required />
                <div
                  *ngIf="(submitted && this.f.username.errors) || (this.f.username.invalid && (this.f.username.dirty || this.f.username.touched))">
                  <div *ngIf="this.f.username.errors.required" class="red">
                  {{staticMsgs.common.email+staticMsgs.common.required}}
                  </div>
                  <div *ngIf="this.f.username.errors.email" class="red">
                  {{staticMsgs.forgotpassword.invalidEmail}}
                  </div>
                </div>
              </div>
              <!-- /form group -->

              <div class="form-group">
                <button type="submit" class="btn btn-primary">{{staticMsgs.forgotpassword.newpassword}}</button>
              </div>

            </form>
            <!-- /form -->

          </div>
          <!-- /login content inner -->

          <!-- Login Content Footer -->
          <div class="dt-login__content-footer">
            <label>{{staticMsgs.forgotpassword.backTo}} </label>&nbsp;<a [routerLink]="['/auth/login']" routerLinkActive="router-link-active"
              class="d-inline-block font-weight-500 mr-2" href> {{staticMsgs.login.Login}}</a>
          </div>
          <!-- /login content footer -->

        </div>
        <!-- /login content section -->

      </div>
      <!-- /login content -->

    </div>
  </div>
</div>
<!-- /root -->

<!-- Root -->
<div class="dt-root" *ngIf="!fpassword">

  <div class="dt-root__inner">
    <div class="dt-login--container">

      <!-- Login Content -->
      <div class="dt-login__content-wrapper">

        <!-- Login Background Section -->
        <div class="dt-login__bg-section">

          <div class="dt-login__bg-content">
            <!-- Login Title -->
            <h1 class="dt-login__title f-13">{{staticMsgs.forgotpassword.rsPassword}}</h1>
            <!-- /login title -->
          </div>


          <!-- Brand logo -->
          <div class="dt-login__logo text-center">
            <a class="dt-brand__logo-link" href="javascript:void(0)">
              <img class="dt-brand__logo-img" alt="Fitos">
            </a>
          </div>
          <!-- /brand logo -->

        </div>
        <!-- /login background section -->

        <!-- Login Content Section -->
        <div class="dt-login__content">

          <!-- Login Content Inner -->
          <div class="dt-login__content-inner">

            <!-- Form -->
            <form [formGroup]="resetPassword" (ngSubmit)="verifyPasswordSubmit()">

              <p class="f-13 mb-10" *ngIf="codeExpired">{{staticMsgs.OTPexpired}} <a href="javascript:;" (click)="resendOTP()">{{staticMsgs.forgotpassword.ClickHere}}</a>{{staticMsgs.forgotpassword.resend}}</p>

              <p class="f-13 mb-10" *ngIf="errorDiv1" [innerHTML]="errors1"></p>

              <!-- Form Group -->
              <div class="form-row mb-5">
                <div class="col-sm-12">
                <label for="code">{{staticMsgs.forgotpassword.enterCode}}<span class="red_icon">*</span></label>
                <input type="text" formControlName="code" id="code" class="form-control" required />
                <div
                  *ngIf="(submitted && this.a.code.errors) || (this.a.code.invalid && (this.a.code.dirty || this.a.code.touched))">
                  <div *ngIf="this.a.code.errors.required" class="red">
                  {{staticMsgs.forgotpassword.verificationCode+staticMsgs.common.required}}
                  </div>
                </div>
              </div>
              </div>
              <!-- /form group -->

              <!-- Form Group -->
              <div class="form-row mb-5">
                <div class="col-sm-12">
                <label for="password">{{staticMsgs.forgotpassword.enterNewPassword}}<span class="red_icon">*</span></label>
                <input type="password" formControlName="password" id="password" class="form-control" minlength="8"
                  maxlength="15" required />
                <span toggle="#password" class="fa field-icon toggle-password fa-eye-slash"></span>
                <div
                  *ngIf="(submitted && this.a.password.errors) || (this.a.password.invalid && (this.a.password.dirty || this.a.password.touched))">
                  <div *ngIf="this.a.password.errors.required" class="red">
                    {{staticMsgs.common.password+staticMsgs.common.required}}
                  </div>

                  <div
                    *ngIf="(this.a.password.errors.minlength) || (this.a.password.errors.maxlength) || (this.a.password.errors.hasNumber) || (this.a.password.errors.hasCapitalCase) || (this.a.password.errors.hasSmallCase) || (this.a.password.errors.hasSpecialCharacters)"
                    class="red">
                    {{staticMsgs.forgotpassword.InvalidPasswordFormat}}
                  </div>
                </div>

                <div class="" id="password-validation-box" style="display: none;">
                  <form>
                    <fieldset class="fieldset-password">
                      <div id="password-info">
                        <ul>
                          <li id="length" class="invalid clearfix">
                            <span class="icon-container">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </span>
                          {{staticMsgs.forgotpassword.Atleast8Characters}}
                          </li>
                          <li id="capital" class="invalid clearfix">
                            <span class="icon-container">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </span>
                       {{staticMsgs.forgotpassword.Atleast1UppercaseLetter}}
                          </li>
                          <li id="lowercase" class="invalid clearfix">
                            <span class="icon-container">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </span>
                         {{staticMsgs.forgotpassword.Atleast1LowercaseLetter}}
                          </li>
                          <li id="number-special" class="invalid clearfix">
                            <span class="icon-container">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </span>
                            <span title="" class="special-characters tip"
                              data-original-title="! @ #  \ $ % ^ &amp; * ">{{staticMsgs.forgotpassword.specialCharecter}}</span>
                          </li>
                          <li id="number-special1" class="invalid clearfix">
                            <span class="icon-container">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </span>
                          {{staticMsgs.forgotpassword.Atleast1Number}}
                          </li>
                        </ul>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
              </div>
              <!-- /form group -->

              <!-- Form Group -->
              <div class="form-row mb-5">
                <div class="col-sm-12">
                <label for="confirmpassword">{{staticMsgs.forgotpassword.ConfirmPassword}}<span class="red_icon">*</span></label>
                <input type="password" formControlName="confirmpassword" id="confirmpassword" class="form-control"
                  required />
                <span toggle="#confirmpassword" class="fa field-icon toggle-password fa-eye-slash"></span>
                <div
                  *ngIf="(submitted && this.a.confirmpassword.errors) || (this.a.confirmpassword.invalid && (this.a.confirmpassword.dirty || this.a.confirmpassword.touched))">
                  <div *ngIf="this.a.confirmpassword.errors.required" class="red">
                    {{staticMsgs.forgotpassword.ConfirmPassword+staticMsgs.common.required}}
                  </div>
                  <div *ngIf="this.a.confirmpassword.errors.confirmedValidator" class="red">
                  {{staticMsgs.forgotpassword.doesnotMatch}}
                  </div>
                </div>
              </div>
              </div>
              <!-- /form group -->

              <div class="form-group">
                <button type="submit" class="btn btn-primary">{{staticMsgs.forgotpassword.rsPassword}}</button>
              </div>
              <div class="form-group">
                <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div>
              </div>
            </form>
            <!-- /form -->

          </div>
          <!-- /login content inner -->

          <!-- Login Content Footer -->
          <div class="dt-login__content-footer">
            <label>{{staticMsgs.forgotpassword.DidnotReceiveCode}}</label> &nbsp;<a href="javascript:;" (click)="backtoPage()"
            class="d-inline-block font-weight-500 mr-1">{{staticMsgs.forgotpassword.ClickHere}}</a>{{staticMsgs.forgotpassword.resend}}
          </div>
          <!-- /login content footer -->

        </div>
        <!-- /login content section -->

      </div>
      <!-- /login content -->

    </div>
  </div>
</div>
<!-- /root -->
