import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StyledetailsComponent } from '../styledetails/styledetails.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { StorageService } from '../../../helpers/storage.service';
import { UserService } from '../../../helpers/user.service';
import { LogService } from '../../../shared/log.service';
import { DataService } from '../../../services/data.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-pattern-file-upload',
  templateUrl: './pattern-file-upload.component.html',
  styleUrls: ['./pattern-file-upload.component.css']
})
export class PatternFileUploadComponent implements OnInit {
  url: any = '';
  patternFileForm: FormGroup;
  uploadedFile: any;
  showLoader: boolean;
  appStaticMsgs: any;
  styleImagesCnt: any;
  fileData: any = [];
  imgfiledata: any[];
  editedFileData: any;
  imageObject: any;
  patternId: any;
  fileToReturn: string;
  files: any[];
  fileUploadedRes: any;
  fileName: any;
  uploadedFileName: any;
  thumbnailData: void;
  selectedFileName: any;
  isSelectedImg: boolean = false;
  isImgLoaded: boolean = false;
  imageType: boolean = false;
  constructor(public dialogRef: MatDialogRef<StyledetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private dataservice: DataService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private userservice: UserService,
    private storageservice: StorageService,
    private toastService: ToastService
  ) {
    this.data = data.isEditingMode;
  }

  ngOnInit(): void {
    this.getPatternDetails()
    this.patternFileForm = this.fb.group({
      patternFile: [''],
      id: ['']
    })
    this.isImgLoaded = false;
  }





  onSelectFile(event) {
    this.fileData = event.target.files;
    this.isSelectedImg = true;
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.uploadedFileName = event.target.files[0].name;
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        this.isImgLoaded = true;
        if (this.fileData[0].type.startsWith('image/')) {
          this.imageType = true;
        } else {
          this.imageType = false;
        }
      }
    }
  }



  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.appStaticMsgs.common.sessionexpired,
      html: this.appStaticMsgs.common.yoursessionhasexpiredpleaseloginagain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        ////console.log('Session Timedout.')
        return;
      }
    })

  }


  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    })
  }


  async uploadFile(file): Promise<any> {
    console.log(file, 'file')
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );
    const params = {
      Bucket: `${environment.Bucketname}` + `/` + this.createUUID(),
      Key: file.name,
      Body: file,
      //ACL: 'public-read',
      ContentType: contentType
    };

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          reject("error");
        }
        resolve(JSON.stringify(data));
      });
    });
  };

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  public getPatternDetails() {
    this.showLoader = true;
    this.dataservice.GetStylePatterns(this.storageservice.getEditedStyleId()).subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.logger.info(this.appStaticMsgs?.styledetails?.imagedetailsloadedsuccsessfully, "Style Details");
        this.showLoader = false;
        this.styleImagesCnt = response.body['item3'];
        if (response.body['item1'].styleImages) {
          if (response.body['item1'].styleImages.length > 0) {
            this.showLoader = false;
            // this.isThumbnailAdded = true;
            let control = this.patternFileForm.controls;
            if (control) {
              this.patternFileForm = this.fb.group({
                patternFile: [''],
                id: ['']
              })
            }
            let editeddata = JSON.stringify(response.body['item1'].styleImages);
            let data = JSON.parse(editeddata);
            this.patternId = data[0].id;
            this.fileName = data[0].imageID + '/' + this.aesdecryptservice.decryptData(this.dataservice.Key, data[0]["image"].name);
            console.log(this.aesdecryptservice.decryptData(this.dataservice.Key, data[0]["image"].name));

            this.uploadedFileName = this.aesdecryptservice.decryptData(this.dataservice.Key, data[0]['image'].name)
            this.thumbnailData = this.aesdecryptservice.decryptData(this.dataservice.Key, data[0]["image"].thumbnail);
            console.log(this.thumbnailData);
            console.log(this.uploadedFileName);



            if (this.uploadedFileName !== null) {
              const fileExt = this.uploadedFileName.split('.').pop();
              if(fileExt && (fileExt === 'png' || fileExt === 'jpeg' || fileExt === 'jpg' || fileExt === 'tiff' || fileExt === 'gif' || fileExt === 'bmp')){
                this.imageType = true;
              }else{
                this.imageType = false;
              }
            }


            // this.reloadImageFormData(data);

          }
        }
        else {
          // if (this.imageObject.length == 0) {
          //   const temp = {
          //     'image': this.thumbnail,
          //     'thumbImage': this.thumbnail
          //   }
          //   this.imageObject.push(temp);
          //   console.log(this.imageObject);
          // }
        }
      }
      else {
        this.showLoader = false;
        this.error(response.body['item1']);
        this.logger.error(response.body['item1'], "Style Details");
      }
    }, err => {
      this.logger.error(err.error.message, "Style Details");

      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
        this.showLoader = false;
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
        this.showLoader = false;
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
        this.showLoader = false;
      }
      else {
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
        this.logger.error(err.message, "Style Details");


      }
      this.showLoader = false;
    });
  }

  success(message: string) {
    Swal.fire(
      'Success',

      message,
      'success'
    )
  }

  getStyleImgControls() {
    return this.patternFileForm.controls;
  }

  async onPatternFileSubmit() {
    // if (this.patternFileForm.invalid) {
    //   if (this.thumbNails.length == 0) {
    //     this.isFileError = true;
    //   }
    //   return false;
    // } else if (!this.isThumbnailAdded) {
    //   return false;
    // }

    this.showLoader = true;
    let control = this.patternFileForm.controls.patternFile;
    let styImgControl = this.getStyleImgControls();
    const formData = new FormData();
    formData.append('styleid', this.storageservice.getEditedStyleId());
    formData.append('imagecount', '1');

    // for (let i = 0; i < control.length; i++) {
    if (this.patternId) {
      formData.append('id[0]', this.patternId);
      formData.append('imagestag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, "Update"));

    }
    else {
      formData.append('imagestag[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, "Add"));
      // if (this.fileToReturn.name!= null) {
      //   this.isThumbnailAdded = true;
      // }
      // else {
      //   this.isThumbnailAdded = false;
      //   this.showLoader = false;
      //   return false;

      // }
    }

    formData.append('comments[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, ''));
    formData.append('imagetype[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, 'PatternImages'));
    let a: number = 0;
    for (let f of this.fileData) {
      if (JSON.stringify(f) != '') {
        console.log(f);
        this.fileUploadedRes = f;
        let fileUploadedData = await this.uploadFile(f);
        if (fileUploadedData != "error") {
          formData.append('imageid[0]', JSON.parse(fileUploadedData)["Key"]);
          formData.append('filename[0]', this.aesdecryptservice.encryptData(this.dataservice.Key, f.name));
        }
        else {
          this.error(fileUploadedData);
          this.showLoader = false;
          return false;
        }
      }
    }
    a = a + 1;

    this.dataservice.AddUpdateDeleteStyImages(formData).subscribe(response => {
      if (response.status === 200) {
        if (response.body['item2'] === "Success") {
          this.showLoader = false;
          this.dialogRef.close();
          this.logger.info(this.appStaticMsgs.styledetails.imagesupdatedsuccessfully, "Style Images");
          // this.success(this.appStaticMsgs.styledetails.imagesupdatedsuccessfully);
          this.toastService.showSuccess('Added Successfully;')
          this.showLoader = false;
          this.patternFileForm.reset();
          if (response.body['item1'].styleImages) {
            if (response.body['item1'].styleImages.length > 0) {
              this.getPatternDetails();
            }
          }
        }
        else {

          this.logger.error(response.body['item1'], "Style Images");
          // this.error(response.body['item1']);
          this.toastService.showError(response.body['item1']);
          this.showLoader = false;
        }

      }
    }, err => {
     this.logger.error(err.error.message, "Style Images");
      this.toastService.showError(err.error.message);
      if (err.error.message === "Network error communicating with endpoint") {
        // this.error(err.error.message);
        this.toastService.showError("Oops!! Something went wrong. Please try again.");
      } else if (err.error.message === "The incoming token has expired") {
        this.sessionTimedOut();
        this.userservice.logout();
        this.toastService.showError("The token being used has expired. Please login again.");
      } else if (err.error.message === "Unauthorized") {
        // this.userservice.error();
        this.toastService.showError("You don't have permission to access this resource. Please login again.")
        this.sessionTimedOut();
        this.userservice.logout();
      }
      else {
         this.logger.error(err.error.message, "Style Images");
        // this.error(err.message);
        this.toastService.showError(this.appStaticMsgs.unResponsive.error);
      }
      this.showLoader = false;
    });


  }
  close() {
    this.dialogRef.close();
  }

  downloadFile(): void {
    const bucketName = `${environment.Bucketname}`;
    const fileKey = this.fileName;
    this.dataservice.downloadFileFromS3(bucketName, fileKey);
  }
}
