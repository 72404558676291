

<app-loader [showLoader]="showLoader"></app-loader>
<app-loader [showLoader]="showLoader1"></app-loader>

<div class="dt-page__header">
  <button class="close" mat-button (click)="cancelStyleEdit()">X</button>
  <h3 class="dt-page__title">Edit Style</h3>
  </div>
  <mat-dialog-content class="mat-typography">
    <div id="tab-pane" class="tab-pane card-body card-body-shadow bg-white active">
      <form [formGroup]="styleForm" (ngSubmit)="onStyleSubmit()">
        <div class="form-row">
          <!-- <input type="hidden" class="form-control" id="styleNumber" formControlName="styleNumber"
          placeholder="Enter style number" value=""> -->

          <div class="col-lg-3 col-sm-3 col-md-3 mb-3" >
            <label for="styleNumber">{{appStaticMsgs.common.id1}}<span class="red_icon">*</span></label>
            <input type="text" class="form-control" id="styleNumber" formControlName="styleNumber"
              placeholder="Enter ID" value="">
          </div>

          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.styleNumber}}<span
                class="red_icon">*</span></label>
            <input type="text" class="form-control" id="styleName" formControlName="newStyleNum"
              placeholder="Enter style number" value="" (keypress)="charactersOnly($event)" required="">
            <div
              *ngIf="(submitted && this.f.newStyleNum.errors) || ( this.f.newStyleNum.invalid && (this.f.newStyleNum.dirty || this.f.newStyleNum.touched))">
              <div *ngIf="this.f.newStyleNum.errors.required" class="red">
                {{appStaticMsgs.styledetails.stylenumberisrequired}}
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.styleName}}<span
                class="red_icon">*</span></label>
            <input type="text" class="form-control" id="styleName" formControlName="styleName"
              minlength="3" maxlength="50" placeholder="Enter style name" value=""
              (keypress)="characterOnly($event)" required="">
            <div
              *ngIf="(submitted && this.f.styleName.errors) || ( this.f.styleName.invalid && (this.f.styleName.dirty || this.f.styleName.touched))">
              <div *ngIf="this.f.styleName.errors.required" class="red">
                Style Name is required.
              </div>
              <!-- <div
                *ngIf="this.styleForm.controls.styleName.value && this.styleForm.controls.styleName.value.length > 50"
                class="red">
                {{appStaticMsgs.styledetails.styleNameisallowed50charactersonly}}.
              </div> -->
              <div *ngIf="this.f.styleName.errors.minlength" class="red">
                Style Name must be 3 Characters long.
              </div>
              <!-- <div *ngIf="this.f.firstname.errors.maxlength" class="red">
                   maximum length required
                 </div> -->
            </div>
          </div>



          <div class="col-lg-3 col-sm-3 col-md-3 mb-5">
            <label for="validationDefault02">{{appStaticMsgs?.styledetails?.season}}</label>
            <select formControlName="season" title="Select Season"
              class="form-control selectpicker w-100" data-live-search="true">
              <option value="" disabled>Select Season</option>
              <option value="Autumn">Autumn</option>
              <option value="Monsoon">Monsoon</option>
              <option value="Spring">Spring</option>
              <option value="Summer">Summer</option>
              <option value="Winter">Winter</option>
              <option value="everyday">Everyday</option>
            </select>
          </div>
          <div class="col-lg-3 col-sm-3 col-md-3 mb-3">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.category}}<span
                class="red_icon">*</span></label>
            <select formControlName="category" title="Select Category"
              class="form-control selectpicker w-100" data-live-search="true">
              <option value="" disabled>Select Season</option>
              <option value="Casual wear">Casual wear</option>
              <option value="Promotional">Promotional</option>
              <option value="Designer">Designer</option>
              <option value="Sports wear">Sports wear</option>
              <option value="Lounge wear">Lounge wear</option>
              <option value="Uniform">Uniform</option>
              <option value="Athleisure">Athleisure</option>
              <option value="Formal wear">Formal wear</option>
              <option value="Luxury Apparel">Luxury Apparel</option>
              <option value="Other">Other</option>
            </select>
            <div
              *ngIf="(submitted && this.f.category.errors) || ( this.f.category.invalid && (this.f.category.dirty || this.f.category.touched))">
              <div *ngIf="this.f.category.errors.required" class="red">
                {{appStaticMsgs.styledetails.categoryisrequired}}
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-3 col-md-3 mt-3 mb-3">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.subcategory}}<span
                class="red_icon">*</span></label>
            <select title="Select sub category" formControlName="subCategory"
              class="form-control selectpicker w-100" data-live-search="true">
              <option value="" disabled>Select Season</option>
              <option value="Dresses & Skirts">Dresses & Skirts</option>
              <option value="Trousers">Trousers</option>
              <option value="Blouses">Blouses</option>
              <option value="Jerseys, Sweatshirts & Pullovers">Jerseys, Sweatshirts & Pullovers</option>
              <option value="Coats & Jackets">Coats & Jackets</option>
              <option value="Suits & Ensembles">Suits & Ensembles</option>
              <option value="Sports And Swimwear">Sports And Swimwear</option>
              <option value="Night & Underwear">Night & Underwear</option>
              <option value="T Shirts And Singlets">T Shirts And Singlets</option>
              <option value="Blazers">Blazers</option>
              <option value="Others">Others</option>
            </select>
            <div
              *ngIf="(submitted && this.f.subCategory.errors) || ( this.f.subCategory.invalid && (this.f.subCategory.dirty || this.f.subCategory.touched))">
              <div *ngIf="this.f.subCategory.errors.required" class="red">
                {{appStaticMsgs.styledetails.subcategoryisrequired}}
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-3 col-md-3 mt-3 mb-3">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.targetGender}}<span
                class="red_icon">*</span></label>
            <select title="Select target gender" formControlName="targetGender"
              class="form-control selectpicker w-100" data-live-search="true">
              <option value="" disabled>Select Season</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Unisex">Unisex</option>
              <option value="Genderless">Genderless</option>
              <option value="N/A">N/A</option>
            </select>
            <div
              *ngIf="(submitted && this.f.targetGender.errors) || ( this.f.targetGender.invalid && (this.f.targetGender.dirty || this.f.targetGender.touched))">
              <div *ngIf="this.f.targetGender.errors.required" class="red">
                {{appStaticMsgs.styledetails.targetGenderisrequired}}
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-3 col-md-3 mt-3 mb-3">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.ageGroup}}<span
                class="red_icon">*</span></label>
            <select title="Select age group" formControlName="ageGroup"
              class="form-control selectpicker w-100" data-live-search="true">
              <option value="" disabled>Select Season</option>
              <option value="Infants & Toddlers">Infants & Toddlers</option>
              <option value="Young Kids">Young Kids</option>
              <option value="Teens">Teens</option>
              <option value="Adult">Adult</option>
              <option value="Seniors">Seniors</option>
            </select>
            <div
              *ngIf="(submitted && this.f.ageGroup.errors) || ( this.f.ageGroup.invalid && (this.f.ageGroup.dirty || this.f.ageGroup.touched))">
              <div *ngIf="this.f.ageGroup.errors.required" class="red">
                {{appStaticMsgs.styledetails.ageGroupisrequired}}
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-3 col-sm-3 col-md-3 mt-3 mb-3">
            <label for="validationDefault02">Sample Size<span class="red_icon">*</span></label>
            <select title="Select sample size" formControlName="sampleSize"
              class="form-control selectpicker w-100" data-live-search="true">
              <option value="XS">XS</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="2XL">2XL</option>

            </select>
            <div
              *ngIf="(submitted && this.f.sampleSize.errors) || ( this.f.sampleSize.invalid && (this.f.sampleSize.dirty || this.f.sampleSize.touched))">
              <div *ngIf="this.f.sampleSize.errors.required" class="red">
                SampleSize is required.
              </div>
            </div>
          </div> -->

          <div class="col-lg-3 col-sm-3 col-md-3 mb-3">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.sizechart}}<span
                class="red_icon">*</span></label>
            <select title="Select size chart" formControlName="sizeChart"
              class="form-control selectpicker w-100" data-live-search="true"
              (change)="changeSizeChart($event,'')" id="sizeChart">
              <!---- <option *ngFor="let subcategory of subcategory" [value]="subcategory.value">{{ subcategory.label }}</option>-->
              <!-- <option value="" [disabled]="true" selected>
              Select Sub Category
            </option> -->
              <option *ngFor="let sChart of sizeCharts" [value]="sChart.id">{{sChart.text}}</option>
            </select>
            <div
              *ngIf="(submitted && this.f.sizeChart.errors) || ( this.f.sizeChart.invalid && (this.f.sizeChart.dirty || this.f.sizeChart.touched))">
              <div *ngIf="this.f.sizeChart.errors.required" class="red">
                {{appStaticMsgs.common.sizeChartisrequired}}
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-3 col-md-3 mt-3 mb-3">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.sizeRange}}<span
                class="red_icon">*</span></label>
            <select title="Select Size Range" formControlName="sizeRange"
              class="form-control selectpicker w-100" data-live-search="true"
              (change)="changeSizeRange($event,'')" multiple id="sizeRange">
              <!---- <option *ngFor="let subcategory of subcategory" [value]="subcategory.value">{{ subcategory.label }}</option>-->
              <!-- <option value="" [disabled]="true" selected>
              Select Sub Category
            </option> -->
              <!-- <option value="XS, S, …..6XL, OS">XS, S, …..6XL, OS</option>
              <option value="0, 2, .…24, OS 8-12">0, 2, .…24, OS 8-12</option> -->

              <option *ngFor="let gItem of filteredItems" [value]="gItem.size">{{gItem.size}}
              </option>
            </select>
            <div
              *ngIf="(submitted && this.f.sizeRange.errors) || ( this.f.sizeRange.invalid && (this.f.sizeRange.dirty || this.f.sizeRange.touched))">
              <div *ngIf="this.f.sizeRange.errors.required" class="red">
                {{appStaticMsgs.styledetails.sizeRangetisrequired}}
              </div>
            </div>
          </div>



          <!-- <div class="col-sm-4 mt-3 mb-3">
            <label for="validationDefault02">Technical Package<span class="red_icon">*</span></label>
            <select title="Select technical package" formControlName="technicalPackage"
              class="form-control selectpicker w-100" data-live-search="true">
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="complex">Complex</option>
            </select>
            <div
              *ngIf="(submitted && this.f.technicalPackage.errors) || ( this.f.technicalPackage.invalid && (this.f.technicalPackage.dirty || this.f.technicalPackage.touched))">
              <div *ngIf="this.f.technicalPackage.errors.required" class="red">
                TechnicalPackage is required.
              </div>
            </div>
          </div> -->


          <div class="col-lg-3 col-sm-3 col-md-3 mt-3 mb-3">
            <label for="validationDefault02">{{appStaticMsgs.styledetails.sampleSize}}<span
                class="red_icon">*</span></label>
            <select title="Select Sample Size" formControlName="sampleSize"
              class="form-control selectpicker w-100" data-live-search="true" id="sampleSize">
              <option *ngFor="let select of sampleSize" [value]="select.id.replace('','')">
                {{select?.value}}</option>
            </select>
            <div
              *ngIf="(submitted && this.f.sampleSize.errors) || ( this.f.sampleSize.invalid && (this.f.sampleSize.dirty || this.f.sampleSize.touched))">
              <div *ngIf="this.f.sampleSize.errors.required" class="red">
                {{appStaticMsgs.styledetails.samplesizeisrequired}}
              </div>
            </div>


            <!-- <div
              *ngIf="(submitted && this.f.subCategory.errors) || ( this.f.subCategory.invalid && (this.f.subCategory.dirty || this.f.subCategory.touched))">
              <div *ngIf="this.f.subCategory.errors.required" class="red">
                SubCategory is required.
              </div>
            </div> -->
          </div>
          <!-- <div class="col-lg-3 col-sm-3 col-md-3 mt-3 mb-3">
            <label for="validationDefault02">{{appStaticMsgs.common.status}}<span
                class="red_icon">*</span></label>
            <select type="text" title=" Select Status" class="form-control selectpicker w-100"
              id="status" formControlName="status">
              <option value="Active">{{appStaticMsgs.common.active}}</option>
              <option value="Inactive">{{appStaticMsgs.common.inActive}}</option>
            </select>
            <div
              *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
              <div *ngIf="this.f.status.errors.required" class="red">
                {{appStaticMsgs.common.statusisrequired}}
              </div>
            </div>
          </div> -->

         

        </div>

        <div class="row mt-10 float-right">
          <div class="">
            <input type="hidden" class="form-control" [hidden]="true" id="styleid"
              formControlName="styleid" placeholder="Style Id" />
            <div>
              <!-- <button type="button" class="btn btn-outline-primary btn-sm" data-dismiss="modal">
                {{appStaticMsgs.common.cancel}}
              </button> -->
              <button  type="button"
              class="btn btn-outline-primary btn-sm" (click)="cancelStyleEdit()">
              {{appStaticMsgs.common.cancel}}
            </button>
              <button type="submit"  class="btn btn-primary adv-search-btn">
                {{appStaticMsgs.common.update}}
              </button>
            </div>
          </div>

        </div>
      </form>

  </div>
</mat-dialog-content>


