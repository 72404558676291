import { Component, OnInit, ViewChild } from '@angular/core';
import { fabric } from "fabric";
import { Router } from "@angular/router";
import { DataService } from '../../../services/data.service';
import { StorageService } from '../../../helpers/storage.service';
import { OAuthService } from '../../../services/o-auth.service';
import html2canvas from 'html2canvas';
import { SharedService } from '../../../services/shared.service';
import Swal from 'sweetalert2';
import { UserService } from '../../../helpers/user.service';
import { environment } from '../../../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { LogService } from '../../../shared/log.service';
import { interval } from 'rxjs';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import * as moodBoardActions from "../../../actions/moodboard.actions";
import { Moodboard } from "../../../models/moodboard";
import { Store, select } from "@ngrx/store";
import * as commonActionTypes from "../../../actions/common.actions";
import * as commonReducer from "../../../reducers/common.reducer";
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';

import { skip, take } from 'rxjs/operators';
declare var $: any;
declare const gapi: any;

@Component({
  selector: 'app-moodboard',
  templateUrl: './moodboard.component.html',
  styleUrls: [
    './moodboard.component.css'
  ]

})
export class MoodBoardComponent implements OnInit {
  canvas: any;
  activeID: any;
  todoCount = 0;
  notesCount = 0;
  urlCount = 0;
  top = 50;
  left = 50;
  notesObject = {
    notesCount: 0,
    notesArray: []
  };
  todoListObject = {
    todoCount: 0,
    trashCount: 0,
    todoArray: []
  };
  colorListObject = {
    colorCount: 0,
    colorArray: []
  };
  urlListObject = {
    urlCount: 0,
    urlArray: []
  }
  noteObject = {
    noteDiv: '',
    top: 50,
    left: 50,
    background: '',
    value: ''
  };
  todoObject = {
    todoDiv: '',
    top: 50,
    left: 50,
    background: '',
    title: 'Title'
  };
  colorObject = {
    colorDiv: '',
    top: 50,
    left: 50,
    value: '',
    background: ''
  };
  urlObject = {
    urlDiv: '',
    top: 50,
    left: 50,
    value: '',
    background: ''
  }
  canvasObject = {
    title: '',
    imageId: '',
    canvas: '',
    note: '',
    toDo: '',
    color: '',
    url: '',
    comment: '',
    highlight: '',
    status: ''
  }
  trashCount = 0;
  showLoader = true;
  colorCount = 0;
  activeColorID: any;
  activeCode: any;
  fromDate: any;
  toDate: any;
  minDate = { year: 1985, month: 1, day: 1 };
  files: File[] = [];
  exploreFitos = false;
  explorefitos = false;
  uploadImgLibrary = false;
  classAppliedImage = false;
  uploadimageLibrary = false;
  classificationType: string;
  statusType: string;
  moodBoardObject: Moodboard;
  status = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' }
  ]
  googlePhotoLibrary = false;
  googlePhotosLibrary = false;
  imgLibraryClassificationData: any;
  recomImgData: any;
  imagesType: any;
  copyGPhotos: any[];
  filteredImgData: any[];
  searchName: any;
  startDate: string;
  endDate: string;

  private clientId: string = '258095703683-5i4gfq8q1k9jspqsgk2tm4js84kdua37.apps.googleusercontent.com';

  private scope = [
    "https://www.googleapis.com/auth/photoslibrary",
    'https://www.googleapis.com/auth/photoslibrary.readonly.appcreateddata'
  ].join(' ');

  public auth2: any;
  googleMediaPhotosData: any;
  googleAlbumPhotosData: any;
  gPhotosBaseUrl: any[] = [];
  showLocalImages = false;
  showGooglePhotos = false;
  recommendedImgData: any[] = [];
  showRecommendedImages = false;
  classApplied = false;
  textSection = false;
  imageSection = false;
  shapeSection = false;
  backgroundSection = false;
  text = false;
  image = false;
  shape = false;
  background = false;
  colorbg = '';
  color: Object = { "hsl": { "h": 37.42857142857143, "s": 0.9130434782608696, "l": 0.5490196078431373, "a": 1 }, "hex": "#7ED321", "rgb": { "r": 25, "g": 166, "b": 35, "a": 1 }, "hsv": { "h": 37.42857142857143, "s": 0.8571428571428571, "v": 0.9607843137254902, "a": 1 }, "oldHue": 271.6363636363636, "source": "hex" };
  liitems = -1;
  selectedColor = '';
  textValue = false;
  highlightSection = false;
  highlight = false;
  colorPanel = false;
  colorPanelSection = false;
  isHighlightDisable = true;
  moodBoardData: any;
  log: any[];
  staticMsgs:any;

  bold = "../../../../assets/images/mood_img/bold.png";
  italic = "../../../../assets/images/mood_img/italic.png";
  underline = "../../../../assets/images/mood_img/underline.png";
  strikethrough = "../../../../assets/images/mood_img/strikethrough.png";
  rect = "../../../assets/images/mood_img/rect.png";
  triangle = "../../../assets/images/mood_img/triangle.png";
  circle = "../../../assets/images/mood_img/circle.png";

  items = [
    {
      id: 1,
      text: "Text",
      img: "../../../../assets/images/mood_img/text.png"
    },
    {
      id: 2,
      text: "Images",
      img: "../../../../assets/images/mood_img/img.png"
    },
    {
      id: 3,
      text: "Shapes",
      img: "../../../../assets/images/mood_img/shape.png"
    },
    {
      id: 4,
      text: "Note",
      img: "../../../../assets/images/mood_img/note.png"
    },
    {
      id: 5,
      text: "Todo",
      img: "../../../../assets/images/mood_img/todo.png"
    },
    {
      id: 6,
      text: "Color",
      img: "../../../../assets/images/mood_img/color.png"
    },
    {
      id: 7,
      text: "URL",
      img: "../../../../assets/images/mood_img/url.png"
    },
    {
      id: 8,
      text: "Highlight",
      img: "../../../../assets/images/mood_img/highlighter.png"
    },
    {
      id: 9,
      text: "Delete",
      img: "../../../../assets/images/mood_img/delete.png"
    }/* , {
      id: 10,
      text: "Background",
      img: "../../../../assets/images/mood_img/background.png"
    }, */
  ]
  isSaved = false;

  constructor(private router: Router,
    private store: Store<any>,
    private dataService: DataService,
    private storageService: StorageService,
    private oAuthService: OAuthService,
    private sharedService: SharedService,
    private userservice: UserService,
    private aesdecryptservice: AESDecryptService,
    private logger: LogService
  ) {
    this.moodBoardObject = new Moodboard();
  }

  ngOnInit() {
    this.staticMsgs=appStaticMsg.data[0];
    this.dataService.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataService.AddClientLog());
    this.showLoader = true;
    $(document).ready(function () {
      $('.selectpicker').selectpicker();
    });
    this.sharedService.sendClickEvent();
    this.canvas = new fabric.Canvas('c', {
      hoverCursor: 'pointer',
      selectionBorderColor: 'blue',
      backgroundColor: 'white'
    });
    $('#canvasContainer').find('.newbox.activeDiv').removeClass('activeDiv');
    this.moodBoardData = JSON.parse(this.storageService.getEditedMoodBoardId());
    if (this.moodBoardData === null) {
      this.router.navigate(['/moodboardlibrary']);
    } else {
      this.moodBoardObject.title = this.moodBoardData.moodBoardTitle;
      this.getMoodBoardById(this.moodBoardData);
    }
  }

  /*---------------------------- MoodBoard Text & Shapes Start ----------------------------*/

  /* Filling the color to text in moodboard */
  showTextColor(event: any) {
    this.canvas.getActiveObject().styles = [];
    this.canvas.getActiveObject().set({ fill: event.color.hex });
    this.canvas.renderAll();
  }

  /* Changing the styles to text in moodboard */
  showFontType(id: any) {
    this.canvas.getActiveObject().styles = [];
    this.textValue = !this.textValue;

    if (this.textValue == true) {
      if (id == "text-cmd-bold") {
        this.canvas.getActiveObject().set("fontWeight", "bold");
      }
      if (id == "text-cmd-italic") {
        this.canvas.getActiveObject().set("fontStyle", "italic");
      }
      if (id == "text-cmd-underline") {
        this.canvas.getActiveObject().set({ underline: true });
      }
      if (id == "text-cmd-linethrough") {
        this.canvas.getActiveObject().set({ linethrough: true });
      }
      if (id == "text-cmd-overline") {
        this.canvas.getActiveObject().set({ overline: true });
      }
    } else {

      if (id == "text-cmd-bold") {
        this.canvas.getActiveObject().set("fontWeight", "");
      }
      if (id == "text-cmd-italic") {
        this.canvas.getActiveObject().set("fontStyle", "");
      }
      if (id == "text-cmd-underline") {
        this.canvas.getActiveObject().set({ underline: false });
      }
      if (id == "text-cmd-linethrough") {
        this.canvas.getActiveObject().set({ linethrough: false });
      }
      if (id == "text-cmd-overline") {
        this.canvas.getActiveObject().set({ overline: false });
      }
    }
    this.canvas.renderAll();
  }

  /* Adding canvas elements to moodboard  */
  addCanvasElement(element: any) {
    switch (element) {
      case 'rect':
        this.canvas.add(new fabric.Rect({
          left: 100,
          top: 100,
          fill: 'rgba(0, 0, 200, 0.0)',
          stroke: '#000',
          strokeWidth: 2,
          width: 100,
          height: 100
        }));
        break;
      case 'circle':
        this.canvas.add(new fabric.Circle({
          left: 40,
          top: 40,
          radius: 50,
          fill: 'transparent',
          stroke: '#000',
          strokeWidth: 2,
        }));
        break;
      case 'tri':
        this.canvas.add(new fabric.Triangle({
          left: 80,
          top: 80,
          fill: 'rgba(0, 0, 200, 0.0)',
          stroke: '#000',
          strokeWidth: 2,
          width: 100,
          height: 100
        }));
        break;
      case 'text':
        this.canvas.add(new fabric.IText('Tap and Type', {
          left: 50,
          top: 100,
          fontFamily: 'helvetica neue',
          fill: '#000',
          stroke: '#fff',
          // lockRotation: true,
          strokeWidth: .1,
          fontSize: 45
        }));
        break;
    }
  }

  /* Setting color to shapes in moodboard */
  shapeColorChange(event: any) {
    this.canvas.getActiveObject().set({ stroke: event.color.hex });
    this.canvas.renderAll();
  }

  /*---------------------------- MoodBoard Text & Shapes End ----------------------------*/

  /*---------------------------- MoodBoard Images Start ----------------------------*/

  /* Rendering the image into moodboard */
  renderCanvasImage(data: any) {
    var dataURL;
    var parentThis = this;
    fabric.Image.fromURL(data, function (img) {
      var oImg = img.set({
        left: 50,
        top: 50,
        height: 1000,
        width: 1000,
        border: '#000',
        stroke: '#ffffff',
        strokeWidth: 10
      }).scale(0.2);
      parentThis.canvas.add(oImg);
      parentThis.canvas.renderAll();
      parentThis.showLoader = false;
      //var a = canvas.setActiveObject(oImg);
      dataURL = parentThis.canvas.toDataURL({
        format: 'png',
        quality: 1
      });
    });
  }

  /* Uploading an image into moodboard from user system */
  selectImage(event: any) {
    this.showLoader = true;
    var parentThis = this;
    var file = event.addedFiles[0];
    var dataURL;
    var reader = new FileReader();
    reader.onload = function (f) {
      var data = f.target.result;
      parentThis.renderCanvasImage(data);
    };
    reader.readAsDataURL(file);
    this.classApplied = false;
    this.showLocalImages = false;
    this.files = [];
  }

  /* Uploading google photos image into mooadboard */
  selectGoogleImage(imgData: any) {
    this.showLoader = true;
    this.dataService.getImageByURL(imgData).subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.renderCanvasImage(response.body['item1']);
        this.logger.info(this.staticMsgs.moodBoard.ImageUrlloadSuccess, this.staticMsgs.moodBoard.moodboard);
        this.gPhotosBaseUrl = [];
        this.showLoader = false;
      } else {
        this.showLoader = false;;
      }
    }, err => {
      this.getError(err);
      this.logger.error(err.error.message, this.staticMsgs.moodBoard.moodboard);

      this.showLoader = false;
    })
    this.classApplied = false;
    this.gPhotosBaseUrl = [];
    this.classAppliedImage = false;
  }

  /* Selecting the images from user system */
  localImages() {
    this.showLocalImages = true;
    this.showGooglePhotos = false;
    this.showRecommendedImages = false;
  }

  /* To get google signin page */
  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.clientId,
        cookiepolicy: 'single_host_origin',
        scope: this.scope
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  /* Getting the images from google photos media items & album media items  */
  public attachSignin(element) {
    this.gPhotosBaseUrl = [];
    this.imagesType = 'gPhotos';
    this.recommendedImgData = [];
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        this.showGooglePhotos = false;
        if (this.gPhotosBaseUrl.length <= 0) {
          this.oAuthService.oAuthGoogleMediaPhotos(googleUser.getAuthResponse().access_token).subscribe(response => {
            if (response.body['mediaItems'] !== null) {
              this.googleMediaPhotosData = response.body['mediaItems'].filter(x => x.mimeType.includes('image'));
              this.googleMediaPhotosData.forEach(element => {
                this.gPhotosBaseUrl.push({ 'baseUrl': element.baseUrl, 'height': element.mediaMetadata.height, 'width': element.mediaMetadata.width, 'name': element.filename.split('.')[0] })
                this.copyGPhotos = this.gPhotosBaseUrl;
              });
            }
          }, err => {
            this.getError(err);
            this.showLoader = false;
          });
          this.oAuthService.oAuthGoogleAlbumPhotos(googleUser.getAuthResponse().access_token).subscribe(response => {
            if (response.body['mediaItems'] !== null) {
              response.body['albums'].forEach(element => {
                this.oAuthService.oAuthGoogleAlbumMediaPhotos(googleUser.getAuthResponse().access_token, element.id).subscribe(response => {
                  if (response.body['mediaItems'] != null) {
                    response.body['mediaItems'].forEach(element => {
                      this.gPhotosBaseUrl.push({ 'baseUrl': element.baseUrl, 'height': element.mediaMetadata.height, 'width': element.mediaMetadata.width, 'name': element.filename.split('.')[0] })
                      this.copyGPhotos = this.gPhotosBaseUrl;
                    });
                  }
                });
              });
            }
          }, err => {
            this.getError(err);
            this.showLoader = false;
          });
          this.classAppliedImage = true;
          this.showGooglePhotos = true;
          this.googlePhotoLibrary = true;
          this.googlePhotosLibrary = true;
          this.showLocalImages = false;
          this.uploadImgLibrary = false;
          this.uploadimageLibrary = false;
          this.showRecommendedImages = false;
        }
      });
  }

  /* Getting images from image library */
  recommendedImages() {
    this.imagesType = 'recommended';
    this.gPhotosBaseUrl = [];
    this.recommendedImgData = [];
    this.classAppliedImage = true;
    this.uploadImgLibrary = true;
    this.uploadimageLibrary = true;
    this.googlePhotoLibrary = false;
    this.googlePhotosLibrary = false;
    this.exploreFitos = false;
    this.explorefitos = false;
    this.dataService.getImageLibraryIntoMoodBoard().subscribe(response => {
      if (response.body['item2'] === "Success") {
        this.logger.info(this.staticMsgs.moodBoard.ImageLibrary, this.staticMsgs.moodBoard.moodboard);
        response.body['item1'].forEach(x => {
          x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
          x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
          x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
          if (x.imageurl != null) {
            x.imageurl[0] = this.aesdecryptservice.decryptData(this.dataService.Key, x.imageurl[0]);
          }
        });
        this.recommendedImgData = response.body['item1'].filter(x => x.classification == this.classificationType && x.status == this.statusType);
        this.recomImgData = response.body['item1'].filter(x => x.classification == this.classificationType && x.status == this.statusType);
        this.filteredImgData = response.body['item1'].filter(x => x.classification == this.classificationType && x.status == this.statusType);
        this.showRecommendedImages = true;
        this.showGooglePhotos = false;
        this.showLocalImages = false;
      } else {
        this.showLoader = false;
      }
    }, err => {
      this.getError(err);
      this.logger.error(err.error.message, this.staticMsgs.moodBoard.moodboard);
      this.showLoader = false;
    })
  }

  /* Rendering an image from image library to moodboard */
  selectRecomImg(imageurl: any) {
    this.showLoader = true;
    if (parseInt(imageurl[1]) <= 20971520) {
      this.dataService.getImageByURL(imageurl[0]).subscribe(response => {
        if (response.body['item2'] === "Success") {
          this.renderCanvasImage(response.body['item1']);
          this.logger.info(this.staticMsgs.moodBoard.ImageUrlloadSuccess,this.staticMsgs.moodBoard.moodboard);

          this.showLoader = false;
        } else {
          this.showLoader = false;
        }
      }, err => {
        this.getError(err);
        this.logger.error(err.error.message,  this.staticMsgs.moodBoard.moodboard);
        this.showLoader = false;
      })
      this.classApplied = false;
      this.classAppliedImage = false;
      this.showRecommendedImages = false;
    } else {
      this.showLoader = false;
      this.error(this.staticMsgs.moodBoard.error);
      this.logger.error(this.staticMsgs.moodBoard.error, this.staticMsgs.moodBoard.moodboard);
    }
  }

  /*---------------------------- MoodBoard Images End ----------------------------*/

  /*---------------------------- MoodBoard Note Start ----------------------------*/

  /* Adding notes to moodboard */
  notes(type: any) {
    if (type === 'new') {
      this.notesCount = this.notesCount + 1;
      this.noteObject.top = 50;
      this.noteObject.left = 50;
      this.noteObject.value = '';
      this.noteObject.background = '';
    }
    var comment = '<div class="ui-widget-content newbox noteDiv noteDiv' + this.notesCount + '" style="top:' + this.noteObject.top + 'px; left: ' + this.noteObject.left + 'px;"><div class="NoteStrip' + this.notesCount + ' Strip" style="background: ' + this.noteObject.background + ';margin-left: -15px;height: 5px;width: 100%;top: 0px;position: absolute;" id="NoteStrip' + this.notesCount + '"></div><textarea class="commentNote" id="NoteId' + this.notesCount + '" autocomplete="off" Placeholder="Write note..">' + this.noteObject.value + '</textarea> <p id="NoteText' + this.notesCount + '" class="noteText"></p></div>'
    $('#canvasContainer').append(comment);
    this.dragElements();
  }

  /*---------------------------- MoodBoard Note End ----------------------------*/

  /*---------------------------- MoodBoard Todo Start ----------------------------*/

  /* Adding todo list to moodboard */
  todo(type: any) {
    if (type === 'new') {
      this.todoCount = this.todoCount + 1;
      this.todoObject.top = 50;
      this.todoObject.left = 50;
      this.todoObject.background = '';
      this.todoObject.title = 'Title';
    }

    var ToDoListItem = '<div class="ui-widget-content newbox todoList listId' + this.todoCount + '" style="top:' + this.todoObject.top + 'px; left: ' + this.todoObject.left + 'px;"><div class="TodoStrip' + this.todoCount + ' Strip" style="background:' + this.todoObject.background + ';margin-left: -15px;height: 5px;width: 100%;top: 0px;position: absolute;" id="TodoStrip' + this.todoCount + '"></div><input type="text" value="' + this.todoObject.title + '" placeholder="Title"  name="Title" id="Title' + this.todoCount + '" class="Todo_title"><form name="toDoList" class="form-material" ><span class="form-group form-default"><input type="text" class="listinput listinput' + this.todoCount + ' form-control" placeholder="Insert Text" autocomplete="off" name="ListItem"/><span class="form-bar"></span></span></form><ul id="listBox' + this.todoCount + '" class="todo_Ul"></ul></div>';
    $('#canvasContainer').append(ToDoListItem);
    this.dragElements();
  }

  /* To delete an item in list*/
  deleteTodo(event) {
    event.preventDefault();
    if (event.target.offsetParent.className !== undefined) {
      for (let i = 1; i <= this.todoCount; i++) {
        if (event.target.offsetParent.className.includes('listId' + i)) {
          for (let j = 1; j <= this.trashCount; j++) {
            if (event.target.id === 'todo_trash' + j) {
              $('#listBox' + i).find('#li' + j).remove();
              break;
            }
          }
        }
      }
    }
  }

  /*---------------------------- MoodBoard Todo End ----------------------------*/

  /*---------------------------- MoodBoard Color Panel Start ----------------------------*/

  /* Adding color panel to moodboard */
  Color_OpenPanel(type: any) {
    if (type === 'new') {
      this.colorCount = this.colorCount + 1;
      this.colorObject.left = 50;
      this.colorObject.top = 50;
      this.colorObject.value = '';
      this.colorObject.background = '';
    }
    var ColorPaletteDiv = '<div class="ui-widget-content newbox colorPanel' + this.colorCount + ' colorPanel" style="top:' + this.colorObject.top + 'px; left: ' + this.colorObject.left + 'px;"><div class="ColorStrip' + this.colorCount + ' Strip" id="ColorStrip' + this.colorCount + '" style="background: ' + this.colorObject.background + ';margin-left: -15px;height: 5px;width: 100%;top: 0px;position: absolute;"></div><div class="color-output' + this.colorCount + ' color-output" id="colorDiv' + this.colorCount + '"></div><span></span><span class="code' + this.colorCount + ' colorCode" style="margin: 0px 0px 0px 54px;" id="colorCode' + this.colorCount + '"></span></div>'
    $('#canvasContainer').append(ColorPaletteDiv);
    this.dragElements();
  }

  /* Changing the color for color panel */
  colorChange(event: any) {
    if (this.backgroundSection) {
      //this.colorbg = (event.color.hex);
    } else if (this.highlightSection) {
      this.colorPanelSection = false;
      this.colorPanel = false;
      var color = event.color.hex;
      $("#" + this.activeID).css("background", color);
    } else if (this.colorPanelSection) {
      var color = event.color.hex;
      $('#' + this.activeColorID).css("background", color);
      $('#' + this.activeCode).html(color);
    }
  }

  /*---------------------------- MoodBoard Color Panel End ----------------------------*/

  /*---------------------------- MoodBoard Url Start ----------------------------*/

  /* Adding url to moodboard */
  Add_url(type: any) {
    let count;
    let activeID;
    if (type === 'new') {
      this.urlCount = this.urlCount + 1;
      this.urlObject.top = 50;
      this.urlObject.left = 50;
      this.urlObject.value = '';
      this.urlObject.background = '';
    }
    var Url = '<div class="ui-widget-content newbox urlDiv urlDiv' + this.urlCount + '" style="top:' + this.urlObject.top + 'px; left: ' + this.urlObject.left + 'px;"><div style="background:' + this.urlObject.background + ';margin-left: -15px;height: 5px;width: 100%;top: 0px;position: absolute;" class="urlStrip' + this.urlCount + ' Strip" id="UrlStrip' + this.urlCount + '"></div><i class="icofont icofont-link" style="position: absolute;top: 19px;"></i><input type="text" class="urlInput urlInput' + this.urlCount + '" id="UrlId' + this.urlCount + '" autocomplete="off" Placeholder="Enter a URL..." value="' + this.urlObject.value + '"><div id="StatusId' + this.urlCount + '" class="urlStatus"></div></div>';
    $('#canvasContainer').append(Url);
    if (type === 'old') {
      this.selectURL(this.urlCount, this.urlObject.value);
    }
    this.dragElements();
  }

  /* Validating url and returning the error if any */
  validateURL(url) {
    var urlregex = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
    return urlregex.test(url);
  }

  /* Redirecting to the url site when user clicks on url */
  selectURL(urlCount: any, url: any) {
    $('#UrlId' + urlCount).siblings('.urlStatus').hide();
    $('#UrlId' + urlCount).css('color', '#0000EE');
    $('#UrlId' + urlCount).css('cursor', 'pointer');
    $('#UrlId' + urlCount).click(function (event) {
      window.open(url, '_blank');
    });
  }

  /*---------------------------- MoodBoard Url End ----------------------------*/

  /*---------------------------- MoodBoard Save Start ----------------------------*/

  /* Saving the entire moodboard into json format & screenshot of moodboard for thumbnail in moodboard library */
  saveJsonCanvas() {
    $('#canvasContainer').find('.newbox.activeDiv').removeClass('activeDiv');
    $("#canvasContainer").prop('disabled', true);
    var img = document.getElementById("canvasContainer");

    if (this.notesCount >= 1) {
      this.notesObject.notesArray = [];
      this.notesObject.notesCount = 0;
      for (let i = 1; i <= this.notesCount; i++) {
        if ($('#canvasContainer').find('.noteDiv' + i)[0]) {
          this.noteObject.value = $('#canvasContainer').find('#NoteId' + i).val();
          this.noteObject.noteDiv = '';
          this.noteObject.left = $('#canvasContainer').find('.noteDiv' + i).position().left;
          this.noteObject.top = $('#canvasContainer').find('.noteDiv' + i).position().top;
          this.noteObject.background = $('#canvasContainer').find('.NoteStrip' + i).css('background-color');
          this.notesObject.notesArray.push({ 'noteValue': this.noteObject.value, 'noteDiv': this.noteObject.noteDiv, 'left': this.noteObject.left, 'top': this.noteObject.top, 'background': this.noteObject.background });
        }
      }
      this.notesObject.notesCount = this.notesCount;
    }

    if (this.todoCount >= 1) {
      this.todoListObject.todoCount = 0;
      this.todoListObject.todoArray = [];
      for (let i = 1; i <= this.todoCount; i++) {
        if ($('#canvasContainer').find('.listId' + i)[0]) {
          this.todoObject.title = $('#canvasContainer').find('#Title' + i).val();
          //this.todoObject.title = $('#canvasContainer').find('#Title' + i).val();
          this.todoObject.todoDiv = $('#canvasContainer').find('.listId' + i)[0] ? $('#canvasContainer').find('.listId' + i)[0].outerHTML : '';
          this.todoObject.left = $('#canvasContainer').find('.listId' + i).position().left;
          this.todoObject.top = $('#canvasContainer').find('.listId' + i).position().top;
          this.todoObject.background = $('#canvasContainer').find('.TodoStrip' + i).css('background-color');
          this.todoListObject.todoArray.push({ 'title': this.todoObject.title, 'todoDiv': this.todoObject.todoDiv, 'left': this.todoObject.left, 'top': this.todoObject.top, 'background': this.todoObject.background });
        }
      }
      this.todoListObject.trashCount = this.trashCount;
      this.todoListObject.todoCount = this.todoCount;

    }

    if (this.colorCount >= 1) {
      this.colorListObject.colorCount = 0;
      this.colorListObject.colorArray = [];
      for (let i = 1; i <= this.colorCount; i++) {
        if ($('#canvasContainer').find('.colorPanel' + i)[0]) {
          this.colorObject.value = $('#canvasContainer').find('#listid' + i).val();
          this.colorObject.colorDiv = $('#canvasContainer').find('.colorPanel' + i)[0] ? $('#canvasContainer').find('.colorPanel' + i)[0].outerHTML : '';
          this.colorObject.left = $('#canvasContainer').find('.colorPanel' + i).position().left;
          this.colorObject.top = $('#canvasContainer').find('.colorPanel' + i).position().top;
          //this.colorObject.background = $('#canvasContainer').find('.TodoStrip' + i).css('background-color');
          this.colorListObject.colorArray.push({ 'colorDiv': this.colorObject.colorDiv, 'left': this.colorObject.left, 'top': this.colorObject.top });
        }
      }
      this.colorListObject.colorCount = this.colorCount;
    }

    if (this.urlCount >= 1) {
      this.urlListObject.urlCount = 0;
      this.urlListObject.urlArray = [];
      for (let i = 1; i <= this.urlCount; i++) {
        if ($('#canvasContainer').find('.urlDiv' + i)[0]) {
          this.urlObject.value = $('#canvasContainer').find('#UrlId' + i).val();
          this.urlObject.urlDiv = '';
          this.urlObject.left = $('#canvasContainer').find('.urlDiv' + i).position().left;
          this.urlObject.top = $('#canvasContainer').find('.urlDiv' + i).position().top;
          this.urlObject.background = $('#canvasContainer').find('.urlStrip' + i).css('background-color');
          this.urlListObject.urlArray.push({ 'urlValue': this.urlObject.value, 'urlDiv': this.urlObject.urlDiv, 'left': this.urlObject.left, 'top': this.urlObject.top, 'background': this.urlObject.background });
        }
      }
      this.urlListObject.urlCount = this.urlCount;

    }

    this.showLoader = true;
    html2canvas(img).then(async response => {
      /// document.body.appendChild(canvas);
      var img = response.toDataURL();
      var blobData = this.dataURItoBlob(img);
      let imageS3 = await this.uploadImagetoS3(blobData);
      this.moodBoardObject = new Moodboard();
      this.moodBoardObject.id = this.moodBoardData.moodBoardId !== 'new' ? this.moodBoardData.moodBoardId : null;
      this.moodBoardObject.title = this.aesdecryptservice.encryptData(this.dataService.Key, this.moodBoardData.moodBoardTitle);
      this.moodBoardObject.imageId = JSON.parse(imageS3)["Key"];
      this.moodBoardObject.canvas = this.aesdecryptservice.encryptData(this.dataService.Key, JSON.stringify(this.canvas));
      this.moodBoardObject.note = this.aesdecryptservice.encryptData(this.dataService.Key, JSON.stringify(this.notesObject));
      this.moodBoardObject.toDo = this.aesdecryptservice.encryptData(this.dataService.Key, JSON.stringify(this.todoListObject));
      this.moodBoardObject.color = this.aesdecryptservice.encryptData(this.dataService.Key, JSON.stringify(this.colorListObject));
      this.moodBoardObject.url = this.aesdecryptservice.encryptData(this.dataService.Key, JSON.stringify(this.urlListObject));
      this.moodBoardObject.comment = '';

      if (this.moodBoardData.moodBoardId !== 'new') {
        this.store.dispatch(new moodBoardActions.UpdateMoodBoard(this.moodBoardObject));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((moodBoardData) => {
          if (moodBoardData.moodboard.error === "") {
            if (moodBoardData.moodboard.message['item2'] === 'Success') {
              this.success(this.staticMsgs.moodBoard.MoodboardSuccess);
              this.getStore(moodBoardData.moodboard)
            } else {
              this.showLoader = false;
              this.getStore(moodBoardData.moodboard)
              this.error(moodBoardData.moodboard.message['item1']);
            }
          } else {
            this.getError(moodBoardData.moodboard.error);
            this.showLoader = false;
          }
        })
      } else {
        this.showLoader = true;
        this.store.dispatch(new moodBoardActions.CreateMoodBoard(this.moodBoardObject));
        this.store.pipe(select(state => state), take(2), skip(1)).subscribe((moodBoardData) => {
          if (moodBoardData.moodboard.error === "") {
            if (moodBoardData.moodboard.message['item2'] === 'Success') {
              if(this.isSaved){
                this.success(this.staticMsgs.moodBoard.MoodboardSuccess);
              } else {
                this.isSaved = true;
                this.success(this.staticMsgs.moodBoard.MoodboardSaved);
              }
              this.showLoader = false;
              this.getStore(moodBoardData.moodboard)
            } else {
              this.showLoader = false;
              this.getStore(moodBoardData.moodboard)
              this.error(moodBoardData.moodboard.message['item1']);
            }
          } else {
            this.getError(moodBoardData.moodboard.error);
            this.showLoader = false;
          }
        })
      }
    });
  }

  /* To get the details of moodboard from api */
  getStore(moodBoardData: any) {
    const title = moodBoardData.entities[moodBoardData.selectedMoodboardId].title;
    this.storageService.storeEditedMoodBoard({ 'moodBoardId': moodBoardData.selectedMoodboardId, moodBoardTitle: title });
    this.moodBoardData = JSON.parse(this.storageService.getEditedMoodBoardId());
    $("#canvasContainer").prop('disabled', false)
    this.showLoader = false;
    setTimeout(() => {
      this.moodBoardObject = moodBoardData.entities[this.moodBoardData.moodBoardId];
      this.renderToMoodBoard();
    }, 10);
  }

  /* Uploading Image into S3 Bucket*/
  async uploadImagetoS3(blobData): Promise<any> {
    var params = { Bucket: `${environment.Bucketname}` + `/` + this.createUUID(), Key: "thumbnail", Body: blobData, ContentType: "image/png" };
    const bucket = new S3(
      {
        accessKeyId: `${environment.BucketAccessKeyId}`,
        secretAccessKey: `${environment.BucketSecretAccessKey}`,
        region: `${environment.BucketRegion}`
      }
    );

    return new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          reject("error");
        }
        resolve(JSON.stringify(data));

      });
    });
  }

  /* Creating random key to pass & to connect with S3 Bucket */
  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /* To convert Base64 data into blob data */
  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' });
  }

  /*---------------------------- MoodBoard Save End ----------------------------*/

  /*---------------------------- MoodBoard Render Start ----------------------------*/

  /* Getting moodboard details by mooddboardid */
  getMoodBoardById(data: any) {
    if (data.moodBoardId !== 'new') {
      this.store.dispatch(new moodBoardActions.LoadMoodBoard(data.moodBoardId));
      this.store.pipe(select(state => state), take(2), skip(1)).subscribe((moodBoardData) => {
        if (moodBoardData.moodboardState.error === "") {
          this.moodBoardObject = moodBoardData.moodboardState.entities[this.moodBoardData.moodBoardId];
          this.getClassifications();
          this.renderToMoodBoard();
          this.showLoader = false;
        } else {
          this.getError(moodBoardData.moodboardState.error);
          this.showLoader = false;
        }
      })
    } else {
      this.saveJsonCanvas();
      this.showLoader = false;
    }
  }

  /* Rendering the data into moodboard after getting details of moodboard by moodboardid */
  renderToMoodBoard() {
    if (this.moodBoardObject.canvas !== null || this.moodBoardObject.canvas !== "") {
      this.canvas.loadFromJSON(this.moodBoardObject.canvas, this.canvas.renderAll.bind(this.canvas));
    }

    if (this.moodBoardObject.note !== null && this.moodBoardObject.note !== undefined && this.moodBoardObject.note !== "") {
      this.notesObject = JSON.parse(this.moodBoardObject.note);
      if (this.notesObject.notesArray.length > 0) {
        for (let i = 1; i <= this.notesObject.notesArray.length; i++) {
          this.noteObject.value = this.notesObject.notesArray[i - 1].noteValue;
          this.noteObject.top = this.notesObject.notesArray[i - 1].top;
          this.noteObject.left = this.notesObject.notesArray[i - 1].left;
          this.notesCount = i;
          this.noteObject.background = this.notesObject.notesArray[i - 1].background;
          this.notes('old');
        }
      }
    }

    if (this.moodBoardObject.toDo !== null && this.moodBoardObject.toDo !== undefined && this.moodBoardObject.toDo !== "") {
      this.todoListObject = JSON.parse(this.moodBoardObject.toDo);
      if (this.todoListObject.todoArray.length > 0) {
        for (let i = 1; i <= this.todoListObject.todoArray.length; i++) {
          this.todoObject.title = this.todoListObject.todoArray[i - 1].title;
          this.todoObject.todoDiv = this.todoListObject.todoArray[i - 1].todoDiv;
          this.todoObject.top = this.todoListObject.todoArray[i - 1].top;
          this.todoObject.left = this.todoListObject.todoArray[i - 1].left;
          this.todoObject.background = this.todoListObject.todoArray[i - 1].background;
          this.trashCount = this.todoListObject.trashCount;
          $('#canvasContainer').append(this.todoObject.todoDiv);
        }
        this.todoCount = this.todoListObject.todoCount;
      }
    }

    if (this.moodBoardObject.color !== null && this.moodBoardObject.color !== undefined && this.moodBoardObject.color !== "") {
      this.colorListObject = JSON.parse(this.moodBoardObject.color);
      if (this.colorListObject.colorArray.length > 0) {
        for (let i = 1; i <= this.colorListObject.colorArray.length; i++) {
          this.colorObject.top = this.colorListObject.colorArray[i - 1].top;
          this.colorObject.left = this.colorListObject.colorArray[i - 1].left;
          this.colorObject.value = this.colorListObject.colorArray[i - 1].value;
          this.colorObject.colorDiv = this.colorListObject.colorArray[i - 1].colorDiv;
          $('#canvasContainer').append(this.colorObject.colorDiv);
        }
        this.colorCount = this.colorListObject.colorCount;
      }
    }

    if (this.moodBoardObject.url !== null && this.moodBoardObject.url !== undefined && this.moodBoardObject.url !== "") {
      this.urlListObject = JSON.parse(this.moodBoardObject.url);
      if (this.urlListObject.urlArray.length > 0) {
        for (let i = 1; i <= this.urlListObject.urlArray.length; i++) {
          this.urlObject.top = this.urlListObject.urlArray[i - 1].top;
          this.urlObject.left = this.urlListObject.urlArray[i - 1].left;
          this.urlObject.value = this.urlListObject.urlArray[i - 1].urlValue;
          this.urlObject.background = this.urlListObject.urlArray[i - 1].background;
          this.urlCount = i;
          this.Add_url('old');
        }
      }
    }
  }

  /*---------------------------- MoodBoard Render End ----------------------------*/

  /*---------------------------- MoodBoard Delete Start ----------------------------*/

  /* Deleting an element from moodboard */
  deleteObject() {
    $('.newbox.activeDiv').remove();
    this.canvas.remove(this.canvas.getActiveObject());
  }

  /* Confirmation alert before deleting the moodboard */
  delete() {
    Swal.fire({
      title: this.staticMsgs.moodBoard.deleteMoodboard,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.deleteMoodBoard(this.moodBoardData.moodBoardId);
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  /* Api call to delete the moodboard */
  deleteMoodBoard(id: any) {
    this.store.dispatch(new moodBoardActions.DeleteMoodBoard(id));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((moodBoardData) => {
      if (moodBoardData.moodboard.error === "") {
        this.success(this.staticMsgs.mooadBoard.deleteSuccess);
        this.router.navigate(['/moodboardlibrary'])
        this.showLoader = false;
      } else {
        this.getError(moodBoardData.moodboard.error);
        this.showLoader = false;
      }
    })
  }
  /*---------------------------- MoodBoard Delete End ----------------------------*/

  /*---------------------------- MoodBoard Common Methods Start ----------------------------*/

  /* Selecting side bar of moodboard elements */
  selectedItem(i: any, type?: any) {
    const elements = [3, 4, 5, 6];
    if (elements.includes(i)) {
      this.canvas.discardActiveObject();
      this.canvas.renderAll();
    }
    switch (i) {
      case 0:
        this.getNonCanvasElements();
        this.classApplied = true;
        this.text = true;
        this.textSection = true;
        if (type == 'new') {
          this.addCanvasElement('text');
        }
        setTimeout(() => {
          $('.selectpicker').selectpicker();
        }, 10);
        break;
      case 1:
        this.getNonCanvasElements();
        this.classApplied = true;
        this.image = true;
        this.imageSection = true;
        break;
      case 2:
        this.classApplied = true;
        this.text = false;
        this.image = false;
        this.shape = true;
        this.shapeSection = true;
        this.textSection = false;
        this.imageSection = false;
        this.backgroundSection = false;
        this.background = false;
        this.highlight = false;
        this.highlightSection = false;
        break;
      case 3:
        this.getNonCanvasElements();
        if (type == 'new') {
          this.notes('new');
        }
        break;
      case 4:
        this.getNonCanvasElements();
        if (type == 'new') {
          this.todo('new');
        }
        break;
      case 5:
        this.getNonCanvasElements();
        this.classApplied = true;
        this.colorPanelSection = true;
        this.colorPanel = true;
        if (type == 'new') {
          this.Color_OpenPanel('new');
        }
        break;
      case 6:
        this.getNonCanvasElements();
        if (type == 'new') {
          this.Add_url('new');
        }
        break;
      case 7:
        if (!this.isHighlightDisable) {
          this.getNonCanvasElements();
          this.classApplied = true;
          this.highlight = true;
          this.highlightSection = true;
        }
        break;
      case 8:
        this.getNonCanvasElements();
        this.deleteObject();
        break;
      case 9:
        this.getNonCanvasElements();
        this.classApplied = true;
        this.backgroundSection = true;
        this.background = true;
        break;
      default: this.classApplied = false;
        break;
    }
    this.liitems = i;
  }

  /* To disable the elements */
  getNonCanvasElements() {
    this.classApplied = false;
    this.text = false;
    this.image = false;
    this.shape = false;
    this.shapeSection = false;
    this.textSection = false;
    this.imageSection = false;
    this.backgroundSection = false;
    this.background = false;
    this.colorPanel = false;
    this.colorPanelSection = false;
    this.highlightSection = false;
    this.highlight = false;
  }

  /* To close the side panel */
  itemDetailsClose() {
    this.classApplied = false;
    this.showLocalImages = false;
    this.showRecommendedImages = false;
    this.showGooglePhotos = false;
  }

  /* If any error from api response */
  getError(err: any) {
    if (err.error.message === "Network error communicating with endpoint") {
      this.error(err.error.message);
      this.showLoader = false;
    } else if (err.error.message === "The incoming token has expired") {
      this.sessionTimedOut();
      this.showLoader = false;
    } else if (err.error.message === "Unauthorized") {
      this.userservice.error();
      this.showLoader = false;
    }
    else {
      this.error(err.message);

    }
    this.showLoader = false;
  }

  /* To display success msg as sweet alert */
  success(message: string) {
    Swal.fire(
      'Success',
      message,
      'success'
    )
  }

  /* To display error msg as sweet alert */
  error(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.staticMsgs.userregister.Oops,
      text: message
    })
  }

  /* To display session expired msg as sweet alert */
  sessionTimedOut() {
    let timerInterval
    Swal.fire({
      title: this.staticMsgs.userregister.SessionExpired,
      html: this.staticMsgs.userregister.loginAgain,
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        this.userservice.logout()
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.userservice.logout();
        return;
      }
    })
  }

  /* Key enter event on list & url in moodboard */
  enterTodoListandUrl(event: any) {
    if (event.path[0].className.includes('listinput')) {
      var todoCount = 0;
      var a = event.target.value;
      // alert(a);
      if (event.which === 13) {
        for (let i = 1; i <= this.todoCount; i++) {
          if (event.path[0].className.includes('listinput' + i)) {
            todoCount = i;
          }
        }
        if (a == '') {
          event.preventDefault();
        }
        else {
          // this.count++;
          this.trashCount = this.trashCount + 1;
          event.preventDefault();

          $('#listBox' + todoCount).append("<li id='li" + this.trashCount + "'>" + '<div class="chk-option"  style="display:flex; word-break: break-all;"> </label></div>' + event.target.value + '<a href="#" id="todo_trash' + this.trashCount + '" class="fa fa-trash-o btn blue-violate ordersubmitbytable"><i class="todo_trash" id="todo_trash' + this.trashCount + '"></i></li></div>'); // append this.value          event.target.value = "";  // reset the value field
          event.target.value = ''
        }
      }
    } else {
      var urlCount = 0;
      if (event.which === 13) {
        if (event.target.className.includes('urlDiv') || event.target.className.includes('urlInput')) {
          for (let i = 1; i <= this.urlCount; i++) {
            if (event.target.className.includes('urlDiv' + i) || event.target.className.includes('urlInput' + i)) {
              urlCount = i;
            }
          }
        }
        var url = $('#UrlId' + urlCount).val();


        if (!this.validateURL(url)) {
          // $('#StatusId'+count).find()
          $('#UrlId' + urlCount).siblings('.urlStatus').html("Not a Valid URL");
          $('#UrlId' + urlCount).siblings('.urlStatus').show();
        }
        else {
          this.selectURL(urlCount, url);
        }
      }
    }
  }

  /* To drag the elemets in canvas */
  dragElements() {
    $(".newbox").draggable({
      containment: "#canvasContainer",
      cursor: 'move',
    });
  }

  /* To append active class for the non-canvas elements like Notes, List, Color, URL */
  showTextMenu(event: any) {
    this.dragElements();
    var m
    var c;
    var parentThis = this;
    if (event.target.className.includes('todo_trash')) {
      event.preventDefault();
      this.deleteTodo(event);
    }
    $('.ordersubmitbytable').click(function (event) {
      event.preventDefault();
      parentThis.deleteTodo(event);
    });

    $('.newbox').click(function (event) {
      parentThis.canvas.discardActiveObject();
      parentThis.canvas.renderAll();
      event.preventDefault();
      if (event.target.className.includes('noteDiv')) {
        parentThis.selectedItem(3);
        for (let i = 1; i <= parentThis.notesCount; i++) {
          if (event.target.className.includes('noteDiv' + i)) {
            var t = $('#canvasContainer').find('.newbox').find('.NoteStrip' + i).attr('id');
            $('.newbox.activeDiv').removeClass('activeDiv');
            $('.newbox.noteDiv' + i).addClass('activeDiv');
          }
        }
      } else if (event.target.className.includes('listId')) {
        parentThis.selectedItem(4);
        for (let i = 1; i <= parentThis.todoCount; i++) {
          if (event.target.className.includes('listId' + i)) {
            var t = $('#canvasContainer').find('.newbox').find('.TodoStrip' + i).attr('id');
            $('.newbox.activeDiv').removeClass('activeDiv');
            $('.newbox.listId' + i).addClass('activeDiv');
          }
        }
      } else if (event.target.className.includes('urlDiv')) {
        parentThis.selectedItem(6);
        for (let i = 1; i <= parentThis.urlCount; i++) {
          if (event.target.className.includes('urlDiv' + i)) {
            var t = $('#canvasContainer').find('.newbox').find('.urlStrip' + i).attr('id');
            $('.newbox.activeDiv').removeClass('activeDiv');
            $('.newbox.urlDiv' + i).addClass('activeDiv');
          }
        }
      } else if (event.target.className.includes('color-output')) {
        parentThis.selectedItem(5);
        for (let i = 1; i <= parentThis.colorCount; i++) {
          if (event.target.className.includes('color-output' + i)) {
            var t = $('#canvasContainer').find('.newbox').find('.ColorStrip' + i).attr('id');
            m = $('.newbox').find('.color-output' + i).attr('id');
            c = $('.newbox').find('.code' + i).attr('id');
            $('.newbox.activeDiv').removeClass('activeDiv');
            $('.newbox.colorPanel' + i).addClass('activeDiv');
          }
        }
      }
      if (t == undefined) {

      } else {
        parentThis.activeID = t;
        parentThis.activeColorID = m;
        parentThis.activeCode = c;
      }
    });

    if (this.canvas.getActiveObject() !== undefined && this.canvas.getActiveObject() !== null) {
      this.isHighlightDisable = true;
      this.getNonCanvasElements();
      if (this.canvas.getActiveObject().get('type') == 'i-text') {
        this.selectedItem(0);
        $(".newbox").removeClass("active");
      } else if (this.canvas.getActiveObject().get('type') == 'circle' || this.canvas.getActiveObject().get('type') == 'rect' || this.canvas.getActiveObject().get('type') == 'triangle') {
        this.selectedItem(2);
        $('.newbox.activeDiv').removeClass('activeDiv');
      } else if (this.canvas.getActiveObject().get('type') == 'image') {
        $('.newbox.activeDiv').removeClass('activeDiv');
        this.selectedItem(1)
        this.classApplied = false;
      }
    } else {
      this.isHighlightDisable = false;
    }
  }

  /* To get classification related to moodboard */
  getClassifications() {
    this.showLoader = true;
    this.store.dispatch(new commonActionTypes.LoadClassifications('Images'));
    this.store.pipe(select(state => state), take(2), skip(1)).subscribe((classificationData) => {
      if (classificationData.common.error === "") {
        if (classificationData.common.message['item2'] === 'Success') {
          this.logger.info(this.staticMsgs.mooadBoard.Imageclassification, this.staticMsgs.moodBoard.moodboard);
          this.imgLibraryClassificationData = classificationData.common.entities[classificationData.common.ids[0]].items;
          this.statusType = this.status[0].value;
          this.classificationType = this.imgLibraryClassificationData[0].text;
          this.showLoader = false;
        } else {
          this.showLoader = false;
          this.error("Failed:" + classificationData.common.message?.item1)
          this.logger.error(classificationData.common.message?.item1, this.staticMsgs.moodBoard.moodboard);
        }
      } else {
        this.logger.error(classificationData.common.error.error.message, this.staticMsgs.moodBoard.moodboard);
        this.getError(classificationData.common.error);
        this.showLoader = false;
      }
    })
  }

  /* To remove the images which were drag & dropped while selecting them */
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  /* To enable fitos stock button */
  fitosStock() {
    this.classAppliedImage = true;
    this.classApplied = false;
    this.exploreFitos = true;
    this.explorefitos = true;
    this.uploadImgLibrary = false;
    this.uploadimageLibrary = false;
  }

  /* uploadimgLibrary() {
    this.classAppliedImage = true;
    this.uploadImgLibrary = true;
    this.uploadimageLibrary = true;
    this.exploreFitos = false;
    this.explorefitos = false;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
  } */

  /* To close the details panel in the right side of page */
  itemDetailsImageClose() {
    this.classAppliedImage = false;
    this.classApplied = true;
    this.exploreFitos = false;
    this.explorefitos = false;
    this.showRecommendedImages = false;
    this.resetFilter();
  }
  /* To open the details panel in the right */
  openPanel() {
    $('.bgC').toggleClass('changeHeight', 1000, 'easeInQuad');
    $(".rotate").toggleClass("down");
  }

  /* To get imagelibrary data based on search */
  searchImages() {
    if ((this.startDate === null || this.startDate === '') && (this.endDate !== null || this.endDate !== '')) {
      this.recommendedImgData = this.filteredImgData.filter(x => x.classification == this.classificationType && x.status == this.statusType && x.createdon <= this.endDate);
    } else if ((this.startDate !== null || this.startDate !== '') && (this.endDate === null || this.endDate === '')) {
      this.recommendedImgData = this.filteredImgData.filter(x => x.classification == this.classificationType && x.status == this.statusType && x.createdon >= this.startDate);
    }
    else if ((this.startDate === null || this.startDate === '') && (this.endDate === null || this.endDate === '')) {
      this.recommendedImgData = this.filteredImgData.filter(x => x.classification == this.classificationType && x.status == this.statusType);
    } else {
      this.recommendedImgData = this.filteredImgData.filter(x => x.classification == this.classificationType && x.status == this.statusType && (x.createdon >= this.startDate && x.createdon <= this.endDate));
    }
  }

  /* To get imagelibrary images based on name */
  searchByName(name: any) {
    if (this.imagesType == 'recommended') {
      if (name !== "") {
        this.recommendedImgData = this.recomImgData.filter(x => x.name.includes(name));
        this.filteredImgData = this.recommendedImgData;
      } else {
        this.recommendedImgData = this.recomImgData;
        this.filteredImgData = this.recommendedImgData;
      }
    } else if (this.imagesType == 'gPhotos') {
      if (name !== "") {
        this.gPhotosBaseUrl = this.copyGPhotos.filter(x => x.name.includes(name));
      } else {
        this.gPhotosBaseUrl = this.copyGPhotos;
      }
    }
  }

  /* When a date change event occurs in filter datechange method is triggered */
  dateChange(date: any, type: any) {
    if (type === 'from') {
      this.startDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day) + 'T00:00:00.000000';
    } else if (type === 'to') {
      this.endDate = date?.year + '-' + (date?.month < 10 ? '0' + date?.month : date?.month) + '-' + (date?.day < 10 ? '0' + date?.day : date?.day) + 'T23:59:59.999999';
    }
  }

  /* To reset the filter fields data into intial state */
  resetFilter() {
    this.recommendedImgData = this.recomImgData;
    this.fromDate = '';
    this.toDate = '';
    this.startDate = '';
    this.endDate = '';
    this.searchName = '';
    this.statusType = this.status[0].value;
    this.classificationType = this.imgLibraryClassificationData[0].text;
  }
  /*---------------------------- MoodBoard Common Methods End ----------------------------*/

 }
