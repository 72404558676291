<!-- Site Content Wrapper -->
<main class="dt-main">
  <app-loader [showLoader]="showLoader"></app-loader>
  <app-loader [showLoader]="showLoader1"></app-loader>
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>

  <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->

  <!-- Site Content -->
  <div class="dt-content-wrapper">

    <!-- Site Content -->
    <div class="dt-content">

      <!-- Page Header -->
      <div class="dt-page__header">
        <div class="row">
          <div class="col-xl-6 ">
            <h3 class="dt-page__title m-auto">{{staticMsgs.paletteLib.paletteLib}} {{notFound ? '':': '+ HeadingText}}</h3>
          </div>
          <div class="col-xl-6 ">
            <span class="float-right">

              <button type="button" class="btn mr-5" [ngClass]="btnstatus1 ? 'icon-active' : 'btn-color-primary'"
                placement="bottom" ngbTooltip="Card View" (click)="cardView()">
                <i class="fa fa-th" aria-hidden="true"></i></button>
                <button type="button" class="btn mr-3" [ngClass]="btnstatus ? 'icon-active' : 'btn-color-primary'"
                placement="bottom" ngbTooltip="List View" (click)="tableView()">
                <i class="fa fa-bars" aria-hidden="true"></i></button>
              <button type="button" class="btn btn-outline-primary adv-search-btn mr-3" (click)="createPalette()"><i
                  class="icon icon-plus"></i>
                  {{staticMsgs.paletteLib.createNew}}</button>
                   <!-- <button type="button" class="btn btn-primary adv-search-btn"  *ngIf="!notFound" (click)="advanceSearchOpen()">Advance
                Search</button> -->
            </span>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-12 col-sm-12">
          <p>
            <a href="javascript:void(0)" [ngClass]="mypalette ? 'data_active' : 'text-muted'"
              (click)="paletteView('Created')">
              {{staticMsgs.paletteLib.myPalette}}
            </a>
            <span>|</span>
            <a href="javascript:void(0)" [ngClass]="sharedpalette ? 'data_active' : 'text-muted'"
              (click)="paletteView('Shared')">
              {{staticMsgs.paletteLib.shareWithMe}}
            </a>
          </p>
        </div>
      </div>
      <!-- /page header -->
      <ng-container *ngIf="this.paletteViewMode=='Shared'">
        <div class="text-center" *ngIf="sharedNotFound">
          <h2>{{staticMsgs.paletteLib.noSharedData}}</h2>
          <img [src]="notfoundImg" class="img-fluid "><br>
          <!-- <button type="button" class="btn btn-primary adv-search-btn mr-3" (click)="createPalette()"><i
              class="icon icon-plus"></i> Create
            New</button> -->
        </div>
        <div class="grid-content" *ngIf="gridContent && !sharedNotFound">

          <div class="row">
            <div class="col-sm-10">
              <div  *ngIf="!checkSharedStatusData">
              <i class="fa fa-search search-button"></i>
              <input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords"
                placeholder="Enter Keyword for filter result" [(ngModel)]="searchedKeyword" />
              <!---<input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords" [(ngModel)]="searchedKeyword" />-->
            </div>
            </div>

            <div class="col-sm-2">
              <select placeholder="Select Status" class="form-control selectpicker w-100" [(ngModel)]="selected"
                (ngModelChange)="onSelect(selected,'shared')">
                <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
              </select>
            </div>

          </div>
          <div class="text-center" *ngIf="checkSharedStatusData">
            <h2>{{sharedMsg}}</h2>
            <img [src]="notfoundImg" class="img-fluid ">
          </div>
          <!-- Grid -->
          <div class="row mt-5">

            <!-- Grid Item -->
            <div class="col-xl-12 order-xl-1">

              <!-- Card -->
              <div class="row">

                <!-- Grid Item -->
                <div class="col-xl-3 col-md-4 col-sm-6 col-12"
                  *ngFor="let card of sharedCards | filter:searchedKeyword">

                  <!-- Card -->
                  <div class="card border-l-r">
                    <!-- <div class="">
                      <img *ngIf="card.thumbnailFile" class="w-100 border-l-r h-260" alt="" src={{card.thumbnailFile.thumbnail}}>
                    </div> -->

                    <div class="outer h-260">
                      <div class="palette ">
                        <div *ngFor="let hexCode of card.colourHexCodes?.split(','); let first = first; let last = last" class="innerColor"
                        [ngStyle]="{'background-color': hexCode}"
                        [ngClass]="card.colourHexCodes.length === 7 ? 'rounded' : first ? 'rounded-left' : last ? 'rounded-right' : ''">
                        </div>
                      </div>
                    </div>
                    <!-- <div class="">
                        <div class="palette">
                            <div class="h-260 color-div" style="background-color: #7f6be4;"></div>
                            <div class="h-260 color-div" style="background-color: #7f6be4;"></div>
                            <div class="h-260 color-div" style="background-color: #504298;"></div>
                            <div class="h-260 color-div" style="background-color: #8f8e96;"></div>
                            <div class="h-260 color-div" style="background-color: red;"></div>
                            <div class="h-260 color-div" style="background-color: black;"></div>
                        </div>
                    </div> -->

                    <div class="card-body main-card">
                      <div class="text-eli">
                        <p class="mb-1 mb-sm-0 f-13 text-truncate sequence">
                          <a href="javascript:void(0)" (click)="onEditPalette(card.id)">
                            {{card.sequence}}
                          </a>
                        </p>
                      </div>
                      <div class="row ">
                        <div class="col-sm-7 col-xl-7">
                          <p class="mb-1 mb-sm-0 f-13 text-truncate name">
                            <a href="javascript:void(0)" (click)="onEditPalette(card.id)">
                              {{card.name}}
                            </a>
                          </p>
                        </div>
                        <div class="col-sm-5 col-xl-5">
                          <div class="float-right d-web">
                            <!-- <i *ngIf="card.status==='Active'" class="icon icon-dot d-green"></i>-->
                            <!--<span class="text-light-gray mb-3 f-12 d-active">{{card.status}}</span>-->
                            <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='Approved'"><i
                                class="icon icon-dot d-green"></i>{{card.status}}</span>
                            <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='In Review'"><i
                                class="icon icon-dot d-green"></i>{{card.status}}</span>
                            <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='Dropped'"><i
                                class="icon icon-dot d-red"></i>{{card.status}}</span>
                            <!-- Dropdown -->
                            <div class="dropdown">
                              <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"
                                  (click)="onEditPaletteDetails(card.id)">
                                  <i class="icon icon-editors icon-fw"></i>
                                  {{staticMsgs.paletteLib.edit}}
                                </a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="onDeletePalette(card.id)">
                                  <i class="icon icon-trash icon-fw"></i>
                                  {{staticMsgs.paletteLib.delete}}
                                </a>
                                <!-- <a class="dropdown-item" href="javascript:void(0)">
                                  <i class="icon icon-contacts icon-fw"></i>
                                  Contact List</a>
                                <a class="dropdown-item" href="javascript:void(0)">
                                  <i class="icon icon-heart icon-fw"></i>
                                  Favourite List
                                </a> -->
                              </div>
                            </div>
                            <!-- /dropdown -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="action-tools">
                      <!-- Checkbox -->
                      <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only">
                        <input id="checkbox{{card.id}}" type="checkbox" [(ngModel)]="card.isFavourite"
                          (click)="AddToFavorite($event)"  ngbTooltip="Add to favorite">
                        <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                          <span class="unchecked"><i class="icon icon-star-o text-white icon-lg"></i></span>
                          <span class="checked"><i class="icon icon-star-fill text-secondary icon-lg"></i></span>
                        </label>
                      </div>
                      <!-- /checkbox -->

                    </div>


                    <!-- /card body -->

                  </div>
                  <!-- /card -->

                </div>
                <!-- /grid item -->

              </div>
              <!-- /card -->

            </div>
            <!-- /grid item -->

          </div>
          <!-- /grid -->

          <div *ngIf="!isSharedGetAllRecords" class="row mt-5">
            <div *ngIf="!isSharedScrollDown" class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
              <div class="text-center w-100">
                <div class="loadersmall">
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
      <ng-container *ngIf="this.paletteViewMode=='Created'">
        <div class="text-center" *ngIf="notFound">
          <h2>{{staticMsgs.paletteLib.noData}}</h2>
          <img [src]="notfoundImg" class="img-fluid "><br>
          <!-- <button type="button" class="btn btn-primary adv-search-btn mr-3" (click)="createPalette()"><i
              class="icon icon-plus"></i> Create
            New</button> -->
        </div>
        <div class="grid-content" *ngIf="gridContent && !notFound">

          <div class="row">
            <div class="col-sm-9">
              <div  *ngIf="!checkStatusData">
              <i class="fa fa-search search-button"></i>
              <input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords"
                placeholder="Enter Keyword for filter result" [(ngModel)]="searchedKeyword" />
              <!---<input type="text" class="form-control searchBox" value="" placeholder="Enter Keywords" [(ngModel)]="searchedKeyword" />-->
            </div>
            </div>

            <div class="col-sm-3">
              <select placeholder="Select Status" class="form-control selectpicker w-100" [(ngModel)]="selected"
                (ngModelChange)="onSelect(selected,'mypalette')">
                <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
              </select>
            </div>

          </div>
          <div class="text-center" *ngIf="checkStatusData">
            <h2>{{msg}}</h2>
            <img [src]="notfoundImg" class="img-fluid ">
          </div>
          <!-- Grid -->
          <div class="row mt-5">

            <!-- Grid Item -->
            <div class="col-xl-12 order-xl-1">

              <!-- Card -->
              <div class="row">

                <!-- Grid Item -->
                <div class="col-xl-3 col-md-4 col-sm-6 col-12"
                  *ngFor="let card of (cards | async) | filter:searchedKeyword">

                  <!-- Card -->
                  <div class="card border-l-r">
                    <!-- <div class="">
                      <img *ngIf="card.thumbnailFile" class="w-100 border-l-r h-260" alt="" src={{card.thumbnailFile.thumbnail}}>
                    </div> -->

                    <div class="outer h-260">
                      <div class="palette ">
                        <div *ngFor="let hexCode of card.colourHexCodes?.split(','); let first = first; let last = last " class=
                        " innerColor" [ngStyle]="{'background-color': hexCode}"
                        [ngClass]="card.colourHexCodes.length === 7 ? 'rounded' : first ? 'rounded-left' : last ? 'rounded-right' : ''">
                        </div>
                      </div>
                    </div>
                    <!-- <div class="">
                        <div class="palette">
                            <div class="h-260 color-div" style="background-color: #7f6be4;"></div>
                            <div class="h-260 color-div" style="background-color: #7f6be4;"></div>
                            <div class="h-260 color-div" style="background-color: #504298;"></div>
                            <div class="h-260 color-div" style="background-color: #8f8e96;"></div>
                            <div class="h-260 color-div" style="background-color: red;"></div>
                            <div class="h-260 color-div" style="background-color: black;"></div>
                        </div>
                    </div> -->

                    <div class="card-body main-card">
                      <div class="text-eli">
                        <p class="mb-1 mb-sm-0 f-13 text-truncate sequence">
                          <a href="javascript:void(0)" (click)="onEditPalette(card.id)">
                            {{card.sequence}}
                          </a>
                        </p>
                      </div>
                      <div class="row ">
                        <div class="col-sm-7 col-xl-7">
                          <p class="mb-1 mb-sm-0 f-13 text-truncate name">
                            <a href="javascript:void(0)" (click)="onEditPalette(card.id)">
                              {{card.name}}
                            </a>
                          </p>
                        </div>
                        <div class="col-sm-5 col-xl-5">
                          <div class="float-right d-web">
                            <!-- <i *ngIf="card.status==='Active'" class="icon icon-dot d-green"></i>-->
                            <!--<span class="text-light-gray mb-3 f-12 d-active">{{card.status}}</span>-->
                            <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='Approved'"><i
                                class="icon icon-dot d-green"></i>{{card.status}}</span>
                            <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='In Review'"><i
                                class="icon icon-dot d-green"></i>{{card.status}}</span>
                            <span class="text-light-gray mb-3 f-12 d-active" *ngIf="card.status=='Dropped'"><i
                                class="icon icon-dot d-red"></i>{{card.status}}</span>
                            <!-- Dropdown -->
                            <div class="dropdown">
                              <a class="dropdown-toggle no-arrow" href="#" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i class="icon icon-vertical-more icon-fw icon-lg text-light-gray"></i>
                              </a>

                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"
                                  (click)="onEditPaletteDetails(card.id)">
                                  <i class="icon icon-editors icon-fw"></i>
                                  {{staticMsgs.paletteLib.edit}}
                                </a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="onDeletePalette(card.id)">
                                  <i class="icon icon-trash icon-fw"></i>
                                  {{staticMsgs.paletteLib.delete}}
                                </a>
                                <!-- <a class="dropdown-item" href="javascript:void(0)">
                                  <i class="icon icon-contacts icon-fw"></i>
                                  Contact List</a>
                                <a class="dropdown-item" href="javascript:void(0)">
                                  <i class="icon icon-heart icon-fw"></i>
                                  Favourite List
                                </a> -->
                              </div>
                            </div>
                            <!-- /dropdown -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="action-tools">
                      <!-- Checkbox -->



                      <div class="dt-checkbox dt-checkbox-icon dt-checkbox-only"
                      >
                        <input id="checkbox{{card.id}}" type="checkbox" [(ngModel)]="card.isFavourite"
                          (click)="AddToFavorite($event)"  [ngbTooltip]="card.isFavourite ? 'Remove From favorite' : 'Add to favorite'">
                        <label class="font-weight-light dt-checkbox-content" for="checkbox-1">
                          <span class="unchecked"><i class="icon icon-star-o text-white icon-lg"></i></span>
                          <span class="checked"><i class="icon icon-star-fill text-secondary icon-lg"></i></span>
                        </label>
                      </div>

                      <!-- /checkbox -->

                    </div>


                    <!-- /card body -->

                  </div>
                  <!-- /card -->

                </div>
                <!-- /grid item -->

              </div>
              <!-- /card -->

            </div>
            <!-- /grid item -->

          </div>
          <!-- /grid -->

          <div *ngIf="!isGetAllRecords" class="row mt-5">
            <div *ngIf="!isScrollDown" class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
              <div class="text-center w-100">
                <div class="loadersmall">
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>


      <div class="grid-content custom-table" *ngIf="tableContent && !notFound">

        <!-- Grid Item -->
        <div class="">


          <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td th"
            (mouseover)="showTooltip($event)">
            <kendo-grid #grid [data]="gridData" [pageable]="true" [sortable]="true" [groupable]="true"
              [reorderable]="true" [resizable]="true" [pageSize]="10" [height]="605" [(loading)]="loading"
              (pageChange)="onPageChange($event)" [skip]="state.skip" filterable="menu" (filterChange)="filterChange($event)"
              [sort]="state.sort" [selectable]="{enabled: true, checkboxOnly: true}" [filter] = "filter"
              (dataStateChange)="dataStateChange($event)" (dblclick)="dblClick($event)">

              <kendo-grid-checkbox-column [width]="60" [showSelectAll]="true" [resizable]="false"
              [locked]="true"></kendo-grid-checkbox-column>

              <ng-template kendoGridToolbarTemplate>

                    <!-- <input type="text" class="form-control" id="SearchD" name="SearchD" ([ngModel])="SearchData"
                    placeholder="Search in all columns..."  #SearchD (input)="searchListData(SearchD.value)"> -->
                    <input placeholder="Search in all columns..." kendoTextBox (input)="searchListData($event.target.value)"
                    class="kendoSearch" />
                      <div class="float-right">
                        <!-- <button kendoGridExcelCommand type="button" class="mr-3" icon="file-excel">
                          Export to Excel
                        </button>
                      <button kendoGridPDFCommand class="mr-3" icon="file-pdf">Export to PDF</button> -->
                      <div
                                class="kendo-button-icon mr-3"
                                kendoGridExcelCommand
                              >
                                <i
                                  class="fa fa-file-excel-o"
                                  aria-hidden="true"
                                ></i>
                              </div>



<div
                                kendoGridPDFCommand
                                class="kendo-button-icon mr-3"
                              >
                                <i
                                  class="fa fa-file-pdf-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                      </div>


              </ng-template>

              <kendo-grid-column field="sequence" [filterable]="false" [sortable]="false" title="ID #" width="300" [editable]="false">
              </kendo-grid-column>
              <kendo-grid-column field="name" title="Name" width="300">
              </kendo-grid-column>
              <!-- <kendo-grid-column field="commonApplication" title="Common Application" width="120" >
              </kendo-grid-column> -->
              <!-- <kendo-grid-column field="requirements" title="Requirements" width="120" >
              </kendo-grid-column> -->
              <kendo-grid-column field="description" [filterable]="false" [sortable]="false" title="Description" width="350">
              </kendo-grid-column>
              <kendo-grid-column field="status" title="Status" width="300">
                <ng-template kendoGridFilterMenuTemplate let-column="column"
                let-filter="filter"
                let-filterService="filterService" let-dataItem="dataItem" let-rowIndex="rowIndex">
                <app-dropdownlist-filter [data]="options" [textField]="'label'" [valueField]="'value'"
                [isPrimitive]="true" [field]="column.field" [currentFilter]="filter"
                 [filterService]="filterService">

                </app-dropdownlist-filter>

                </ng-template>

                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                  <span *ngIf="dataItem.status=='Approved'"><i
                      class="icon icon-dot d-green"></i>{{dataItem.status}}</span>
                  <span *ngIf="dataItem.status=='In Review'"><i
                      class="icon icon-dot d-green"></i>{{dataItem.status}}</span>
                  <span *ngIf="dataItem.status=='Dropped'"><i class="icon icon-dot d-red"></i>{{dataItem.status}}</span>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <kendo-dropdownlist [data]="listItems" [textField]="'text'" [valueField]="'value'"
                    [valuePrimitive]="true" [formControl]="fac(dataItem).controls.status">
                  </kendo-dropdownlist>
                </ng-template> -->
              </kendo-grid-column>

              <kendo-grid-column title="Action" width="450">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <button (click)="editClick(rowIndex,dataItem)" title="Edit" class="k-button k-primary mr-3">
                    Edit
                    <!-- <i class="icon icon-editors f-17"></i> -->
                  </button>
                  <button *ngIf="dataItem.isDelete" (click)="onRemove(rowIndex,dataItem)" class="k-button k-grid-remove-command"
                  title="Delete">Delete
                  <!-- <i class="icon icon-trash f-16"></i> -->
                </button>
                  <!-- <button *ngIf="dataItem.isDelete"  (click)="onRemove(rowIndex,dataItem)" class="k-button mr-3" title="Delete"> Delete
                  </button> -->
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-excel fileName="PaletteLibrary.xlsx" [fetchData]="allData"></kendo-grid-excel>
              <kendo-grid-pdf fileName="PaletteLibrary.pdf" [allPages]="true">
                <kendo-grid-pdf-margin top="0.5cm" left="0.5cm" right="0.5cm" bottom="0.5cm"></kendo-grid-pdf-margin>
              </kendo-grid-pdf>

              <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-info></kendo-pager-info>
              </ng-template>
              <ng-template #CustomColorComponent>
                <mat-dialog-content >
                  <app-custom-color></app-custom-color>
                </mat-dialog-content>
                </ng-template>
            </kendo-grid>
            <!-- /card -->

          </div>
        </div>
        <!-- /grid item -->

      </div>
      <span *ngIf="this.showNote" class="red">{{staticMsgs.paletteLib.nameFieldAlloe}}</span>

    </div>

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->
  </div>
  <!-- /Site Content Wrapper-->

</main>
<!-- /site content -->


<!-- Customizer Sidebar -->
<aside class="dt-customizer dt-drawer position-right" [class.open]="classApplied">
  <!-- Item Detail Section -->
  <div class="dt-customizer__inner">

    <!-- Customizer Header -->
    <div class="dt-customizer__header">

      <!-- Customizer Title -->
      <div class="dt-customizer__title">
        <h4 class="mb-0">{{staticMsgs.paletteLib.advSearch}}</h4>
      </div>
      <!-- /customizer title -->
      <!-- Close Button -->
      <button type="button" class="close" (click)="advanceSearchClose()">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- /close button -->

    </div>
    <!-- /customizer header -->
    <!-- Customizer Body -->
    <div class="dt-customizer__body ps-custom-scrollbar">
      <!-- Customizer Body Inner  -->
      <div class="dt-customizer__body-inner">
        <form [formGroup]="advForm" (ngSubmit)="onSubmit(advForm.value)">
          <!--<input type="text" formControlName="keywords" id="keywords" class="form-control" />
                        <button>Submit</button>
                      </form>-->
          <!-- Section -->
          <section>
            <div class="col-sm-12">

              <!--Accordion wrapper-->
              <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                <div class="col-sm-12 mb-4 text-center">
                  <button type="button" class="btn btn-primary adv-clear-btn" *ngIf="isResetMode"
                    (click)="clearSearch()">{{staticMsgs.paletteLib.clrSearch}}</button>
                </div>


                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne1" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.paletteLib.name}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableNameVal">
                        <button type="button" class="close image-close ml-5" (click)="this.clearfield('name');"><span
                            aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>
                  <!-- Card body -->
                  <div id="collapseOne1" class="collapse show" role="tabpanel" aria-labelledby="headingOne1"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto w-100 color-card">
                      <input type="text" class="form-control" value="" formControlName="name" placeholder="Enter Name"
                        name="SearchName" #SearchName (input)="checkFieldVal(SearchName.value, 'name')" />
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne1" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapse0" aria-expanded="true" aria-controls="collapse0">
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.paletteLib.description}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableDescriptionVal">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('description');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>
                  <!-- Card body -->
                  <div id="collapse0" class="collapse" role="tabpanel" aria-labelledby="headingOne1"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto w-100 color-card">
                      <input type="text" class="form-control" value="" formControlName="description"
                        placeholder="Enter Description" name="SearchDescription" #SearchDescription
                        (input)="checkFieldVal(SearchDescription.value, 'description')" />
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">
                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne1" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.paletteLib.keyword}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableKeywordVal">
                        <button type="button" class="close image-close ml-5" (click)="this.clearfield('keyword');"><span
                            aria-hidden="true">×</span></button>

                      </div>
                    </div>
                  </div>


                  <!-- Card body -->
                  <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne1"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto w-100 color-card">
                      <input type="text" class="form-control" formControlName="keyword" #keywordData
                        placeholder="Enter Keywords" name="SearchD" #SearchD
                        (input)="checkFieldVal(SearchD.value, 'keyword')" />

                    </div>
                  </div>

                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingTwo2" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapseTwo2" aria-expanded="true" aria-controls="collapseTwo2">
                    <!-- <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
                      aria-expanded="false" aria-controls="collapseTwo2">
                      <h5 class="mb-0">
                        Created By <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.paletteLib.createdby}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableCreatedByVal">
                        <button type="button" class="close image-close ml-5" ([ngModel])="enableCreatedByVal"
                          (click)="this.clearfield('createdBy');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapseTwo2" class="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <!---<select title="Select Created By" multiple formControlName="createdBy" class="form-control selectpicker w-100" >
                                    <option *ngFor="let status of options" [value]="status.value">{{ status.label }}</option>
                                  </select>--->
                      <input type="text" class="form-control" value="" formControlName="createdBy"
                        placeholder="Created By" name="SearchD1" #SearchD1
                        (input)="checkFieldVal(SearchD1.value, 'createdBy')" />
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingThree3" data-toggle="collapse"
                    data-parent="#accordionEx" href="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapsefour"
                      aria-expanded="false" aria-controls="collapseThree3">
                      <h5 class="mb-0">
                        Status <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.paletteLib.status}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableStatusVal">
                        <button type="button" class="close image-close ml-5" (click)="this.clearfield('status');"><span
                            aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapsefour" class="collapse" role="tabpanel" aria-labelledby="headingThree3"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <select id="statuslist" title="Select Status" multiple formControlName="status"
                        class="form-control selectpicker w-100" [(ngModel)]="enableStatusVal"
                        (ngModelChange)="checkFieldVal(enableStatusVal, 'status')">
                        <option *ngFor="let status of options" [value]="status.value">{{ status.value }}</option>
                      </select>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingFive" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                    <!--<a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapsefive"
                      aria-expanded="false" aria-controls="collapseThree3">
                      <h5 class="mb-0">
                        Created On <i class="fa fa-angle-down rotate-icon float-right"></i>
                      </h5>
                    </a>-->
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.paletteLib.createdon}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableCreatToVal||enableCreatFromVal">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('createdFromChild');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>

                  <!-- Card body -->
                  <div id="collapsefive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <div class="form-group">
                        <label>{{staticMsgs.paletteLib.from}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-1" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-1"
                            formControlName="createdFrom" #createdFrom (change)="setDate($event)" name="createdFrom" />
                          <div class="input-group-append" data-target="#date-time-picker-1" data-toggle="datetimepicker"
                            (click)="checkFieldVal(['val'],'createdFromChild')">
                            <div class="input-group-text">
                              <i class="icon icon-calendar"></i>
                            </div>
                          </div>
                        </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="createdFromDate" ngbDatepicker readonly
                            (click)="d1.toggle()" #d1="ngbDatepicker" formControlName="createdFrom"
                            (dateSelect)="dateChange($event,'createdFromDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                        <br>
                        <label>{{staticMsgs.paletteLib.to}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-2" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-2"
                            formControlName="createdTo" #createdTo />
                          <div class="input-group-append" data-target="#date-time-picker-2" data-toggle="datetimepicker"
                            (click)="checkFieldVal(['val'],'createdToChild')">
                            <div class="input-group-text">
                              <i class="icon icon-calendar"></i>
                            </div>
                          </div>
                        </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="createdToDate" ngbDatepicker readonly
                            (click)="d2.toggle()" #d2="ngbDatepicker" formControlName="createdTo"
                            (dateSelect)="dateChange($event,'createdToDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">


                  <div class="card-header" role="tab" id="headingSix" data-toggle="collapse" data-parent="#accordionEx"
                    href="#collapsesix" aria-expanded="true" aria-controls="collapsesix">
                    <div class="row">
                      <div class="col-sm-9">
                        <h5 class="mb-0">
                          {{staticMsgs.paletteLib.modifiedon}}
                        </h5>
                      </div>
                      <div class="col-sm-1 m-auto">
                        <i class="fa fa-angle-down rotate-icon"></i>
                      </div>
                      <div class="col-sm-1" *ngIf="enableModifToVal||enableModifFromVal">
                        <button type="button" class="close image-close ml-5"
                          (click)="this.clearfield('modifiedFromChild');"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div>


                  <div id="collapsesix" class="collapse" role="tabpanel" aria-labelledby="headingSix"
                    data-parent="#accordionEx">
                    <div class="card-body h-auto color-card">
                      <div class="form-group">
                        <label>{{staticMsgs.paletteLib.from}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-3" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-3"
                            #modifiedFrom formControlName="modifiedFrom" (change)="setDate($event)" />
                            <div class="input-group-append" data-target="#date-time-picker-3" data-toggle="datetimepicker"
                            (click)="checkFieldVal(['val'],'modifiedFromChild')">
                            <div class="input-group-text">
                              <i class="icon icon-calendar"></i>
                            </div>
                          </div>
                        </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="modifiedFromDate" ngbDatepicker readonly
                            (click)="d3.toggle()" #d3="ngbDatepicker" formControlName="modifiedFrom"
                            (dateSelect)="dateChange($event,'modifiedFromDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                        <br>
                        <label>{{staticMsgs.paletteLib.to}}</label>
                        <!-- <div class="input-group date" id="date-time-picker-4" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#date-time-picker-4"
                            formControlName="modifiedTo" #modifiedTo />
                          <div class="input-group-append" data-target="#date-time-picker-4" data-toggle="datetimepicker"
                            (click)="checkFieldVal(['val'],'modifiedToChild')">
                            <div class="input-group-text">
                              <i class="icon icon-calendar"></i>
                            </div>
                          </div>
                        </div> -->
                        <div class="input-group date">
                          <input class="form-control" name="dp" [(ngModel)]="modifiedToDate" ngbDatepicker readonly
                            (click)="d4.toggle()" #d4="ngbDatepicker" formControlName="modifiedTo"
                            (dateSelect)="dateChange($event,'modifiedToDate')" [minDate]="minDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i
                                class="icon icon-calendar"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- Accordion card -->



              </div>
              <!-- Accordion wrapper -->

            </div>

            <div class="add-comment-box">
              <div class="d-flex">
                <div class="col-sm-6">
                  <button type="submit" class="btn btn-primary adv-search-btn w-100">
                    {{staticMsgs.paletteLib.apply}}</button>
                </div>
                <div class="col-sm-6">
                  <button type="button" class="btn btn-primary adv-search-btn w-100 " (click)="clearSearch()">
                    {{staticMsgs.paletteLib.clearAll}}</button>
                </div>
              </div>
            </div>
          </section>
        </form>

        <!-- /section -->



      </div>
      <!-- /customizer body inner -->
    </div>
    <!-- /customizer body -->

  </div>
  <!-- Item Details Section End-->

</aside>
<!-- /customizer sidebar -->

<!-- Root -->
