import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterService } from "@progress/kendo-angular-grid";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { SupplierEditComponent } from '../../../tenants/main/app/components/libraries/supplier-edit/supplier-edit.component';
@Component({
  providers:[SupplierEditComponent],
  selector: 'app-dropdownlist-filter',
  templateUrl: './dropdownlist-filter.component.html',
  styleUrls: ['./dropdownlist-filter.component.css']
})
export class DropdownlistFilterComponent implements OnInit, AfterViewInit  {

  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: any;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: any;
  public showFilter = true;
  public value: string;

  constructor() { }
  public onValueChange(value1: any): void {
    console.log('efgh',value1);
    //this.valueChange.emit(value1);
    this.filterService.filter({
      filters: [{ field: this.field, operator: "eq", value: value1 }],
      logic: "and"
    });


  }

 ngAfterViewInit(): void {
    this.currentData = this.data;
    console.log('Current Data: ', this.currentData);
    const currentColumnFilter: FilterDescriptor =
      this.currentFilter.filters.find(
        (filter: FilterDescriptor) => filter.field === this.field
      );
    if (currentColumnFilter) {
      this.value = currentColumnFilter.value;
      console.log('Value: ', this.value);
    }
  }

 public ngOnInit(): void {
    console.log('abcd');
  }
}
