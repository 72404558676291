import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { DataService } from '../../../services/data.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
//import { first } from 'rxjs/operators';
import { CustomValidators } from './custom-validators';
import { LoaderComponent } from '../../../../../../shared/shared-components/loader/loader.component';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from '../../../../../../aws-exports';
import { AESDecryptService } from '../../../helpers/AESDecrypt.service';
import { LogService } from '../../../shared/log.service';
import { interval } from 'rxjs';
import { timeZone } from '../register/timezone';
import * as appStaticMsg from '../../../../assets/appStaticMsg.json';
import { StorageService } from '../../../helpers/storage.service';

//import { Select2OptionData } from 'ng-Select2';
declare var $: any;
Amplify.configure(awsconfig);

@Component({
  selector: 'app-register',
  templateUrl: './userregister.component.html',
  styleUrls: ['./userregister.component.css']
})
export class UserRegisterComponent implements OnInit {

  @ViewChild(LoaderComponent) child: LoaderComponent;
  showLoader: boolean;
  @ViewChild('content') content: any;
  regForm: FormGroup;
  loading = false;
  submitted = false;
  errors = '';
  errorDiv = false;
  alert = '';
  flag: any;
  modalReference: any;
  email = '';
  company = '';
  phone_number = '';
  log:any[];
  public countrycodes: any[] = [];
  // public countrycodes: Array<Select2OptionData>;
  backarrow: boolean = true;
  registrationdiv: boolean = false;
  registrationtext: boolean = true;
  isDisabled: boolean = true;

  staticMsgs:any;

  makerImg = '../../../../assets/images/register-img/maker.jpg';
  collaboratorImg = '../../../../assets/images/register-img/collaborator.jpg';
  TimeZone: any;

  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dataservice: DataService,
    private auth: AuthService,
    public aesdecryptservice: AESDecryptService,
    private logger: LogService,
    private storageService: StorageService,
    private modalService: NgbModal) {
    if (sessionStorage.getItem('flag')) {
      this.flag = sessionStorage.getItem('flag');
    }
    else {
      this.flag = "Collaborator";
    }

  }

  ngOnInit(): void {
    this.staticMsgs=appStaticMsg.data[0];
    this.dataservice.AddClientLog();
    const source = interval(1.2e+8);
    source.subscribe(val => this.dataservice.AddClientLog());
    this.showLoader = true;
    $(document).ready(function () {
      //$('.selectpicker').selectpicker();
      $('.selectpicker').selectpicker('setStyle', 'dropdown-toggle', 'remove');
      $('.selectpicker').selectpicker('setStyle', 'select-field', 'add');
      $("[data-toggle='popover']").popover({ html: true });

      if (!('ontouchstart' in window)) {
        $('.tip').tooltip();
      }

      $(function passwordValidation() {

        var pwdInput = $('#password');
        var pwdValid = false;

        function validatePwdStrength() {

          var pwdValue = $(this).val(); // This works because when it's called it's called from the pwdInput, see end

          // Validate the length
          if (pwdValue.length > 7) {
            $('#length').removeClass('invalid').addClass('valid');
            pwdValid = true;
          } else {
            $('#length').removeClass('valid').addClass('invalid');
          }

          // Validate capital letter
          if (pwdValue.match(/[A-Z]/)) {
            $('#capital').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#capital').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // Validate lowercase letter
          if (pwdValue.match(/[a-z]/)) {
            $('#lowercase').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#lowercase').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // special character
          if (pwdValue.match(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            $('#number-special').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#number-special').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }

          // Validate number
          if (pwdValue.match(/[\d\\]/)) {
            $('#number-special1').removeClass('invalid').addClass('valid');
            pwdValid = pwdValid && true;
          } else {
            $('#number-special1').removeClass('valid').addClass('invalid');
            pwdValid = false;
          }
        }

        pwdInput.bind('change keyup input', validatePwdStrength); // Keyup is a bit unpredictable

      }); // END passwordValidation()

      $('#password').focusin(function () {
        $('#password-validation-box').show();
      }).focusout(function () {
        $('#password-validation-box').hide();
      });

      $('.toggle-password').click(function () {

        $(this).toggleClass('fa-eye fa-eye-slash');
        const input = $($(this).attr('toggle'));
        if (input.attr('type') === 'password') {
          input.attr('type', 'text');
        } else {
          input.attr('type', 'password');
        }
      });

    });

    this.showLoader = false;
    this.initForm();
    this.getCountrycodes();

    this.TimeZone = timeZone;
  }
  // addClientLog(){
  //   this.log =JSON.parse(localStorage.getItem( new Date().toLocaleDateString()))
  //   if(this.log!=null){
  //     this.dataservice.addClientLog(this.log).subscribe();;
  //     this.log=[];
  //     this.logger.clear();
  //   }

  // }
  async getCountrycodes() {
    const data = await this.dataservice.getCountrycodes();
    this.countrycodes = this.countrycodes.concat(data['countrycodes']);
    this.refreshSelect();
    // this.countrycodes = data['countrycodes'];
    // this.countrycodes = [
    // {id: 1, name: "+1", value: "+1"},
    // {id: 2, name: "+91", value: "+91"},
    // {id: 3, name: "+65", value: "+65"},
    // {id: 4, name: "+134", value: "+134"}
    // ]
  }


  // Method to initialise the Registration form.
  initForm() {

    this.showLoader = true;
    const loaded_flag = sessionStorage.getItem('loaded_flag');
    if (loaded_flag != '1') {
      this.route.queryParams.subscribe(
        params => {
          var decodeEmail = window.decodeURIComponent(params['email']);
          var decodeCompany = window.decodeURIComponent(params['company']);
          this.email = this.aesdecryptservice.decryptData(this.dataservice.Key,decodeEmail);
          this.company = this.aesdecryptservice.decryptData(this.dataservice.Key,decodeCompany);
        }
      )

      sessionStorage.setItem('loaded_flag', '1');
      sessionStorage.setItem('email', this.email);
      sessionStorage.setItem('company', this.company);
    }
    else
    {
      this.email = sessionStorage.getItem('email');
      this.company = sessionStorage.getItem('company');
    }

    console.log('email',this.email);
    console.log('company',this.company);

    this.regForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      lastname: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(15)]],
      email: [{ value: this.email, disabled: true }, [Validators.required, Validators.email]],
      password: ['', [Validators.required,
      // check whether the entered password has a number
      CustomValidators.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(8), Validators.maxLength(15)]],
      confirmpassword: ['', [Validators.required]],
      phone_number: ['', [Validators.pattern("^[1-9][0-9]{9}$")]],
      phone_number_code: ['+1'],
      company: [{ value: this.company, disabled: true }, [Validators.required,Validators.pattern('^[a-zA-Z0-9._-]+$'), Validators.maxLength(100)]],
      timeZone: [Intl.DateTimeFormat().resolvedOptions().timeZone],
      terms_and_conditions: [false, [Validators.requiredTrue]]
    }, {
        // validator: ConfirmedValidator('password', 'confirmpassword')
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator(this.staticMsgs.common.password, this.staticMsgs.userregister.confirmPassword)
      });
      this.dataservice.AddClientLog();
    this.router.navigate(['auth/userregister']);
    this.showLoader = false;
  }

  get f() { return this.regForm.controls; }

  // Method to submit the Registration form to AWS cognito Sign-Up
  async onSubmit() {
    console.log('auth-Register');

    this.submitted = true;
    if (this.regForm.invalid) {
      return;
    }
    this.showLoader = true;

    localStorage.setItem('verify_user', '1');

    if (this.f.phone_number.value) {
      this.phone_number = this.f.phone_number_code.value + this.f.phone_number.value;
    }


    const firstname = this.f.firstname.value,
      lastname = this.f.lastname.value,
      email = this.f.email.value,
      password = this.f.password.value,
      confirmpassword = this.f.confirmpassword.value,
      phone_number_value = this.f.phone_number.value,
      phone_number_code = this.f.phone_number_code.value,
      phone_number = phone_number_code + phone_number_value,
      company = this.f.company.value;

    let user_data = {
      firstName: this.f.firstname.value,
      lastName: this.f.lastname.value,
      group: this.flag,
      eMail: this.f.email.value,
      org: this.f.company.value,
      status: ""
    }

    //var params4 = {
    //  Username: email,
    //  UserPoolId: awsconfig.aws_user_pools_id
    //};

    if (company) {
      var params1 = {
        GroupName: company,
        UserPoolId: awsconfig.aws_user_pools_id
      };

      try {
        try {
          var signupparams;

          if (this.phone_number) {
            signupparams = {
              username: email,
              password,
              attributes: {
                email,
                phone_number,
                name: firstname + " " + lastname
              }
            };
          }
          else {
            signupparams = {
              username: email,
              password,
              attributes: {
                email,
                name: firstname + " " + lastname
              }
            };

          }

          const user = await Auth.signUp(signupparams);
          //console.log({ user });
          if (user.user) {
            var params2 = {
              GroupName: this.flag, /* required */
              UserPoolId: awsconfig.aws_user_pools_id, /* required */
              Username: email /* required */
            };

            // var params3 = {
            //   GroupName: company, /* required */
            //   UserPoolId: awsconfig.aws_user_pools_id, /* required */
            //   Username: email /* required */
            // };

            try {


              const result1 = await this.auth.calladdUsertoGroup(params2);
              //const result2 = await this.auth.calladdUsertoGroup(params3);

              //if ((Object.keys(result1).length === 0) && (Object.keys(result2).length === 0)) {
              if ((Object.keys(result1).length === 0)) {
                //console.log(user_data)
                this.dataservice.registerUserDetails(user_data).subscribe((response) => {

                  if (response.status === 200) {
                    if (response.body['item2'] === "Success") {
                      localStorage.setItem('register_username', email);

                      this.showLoader = false;
                      this.alert = this.staticMsgs.registration.success;
                      this.errorDiv = true;
                      this.logger.info(this.staticMsgs.userregister.userRegistration,this.staticMsgs.userregister.UserRegister);
                      this.errors = this.staticMsgs.userregister.registrationSucess ,this.staticMsgs.userregister.verifyAccount;
                      // setTimeout(() => {
                      //   this.router.navigate(['auth/verifyuser']);
                      // }, 2000);
                      this.router.navigate(['auth/verifyuser']);
                      sessionStorage.removeItem('flag_filled');
                      sessionStorage.removeItem('flag');
                      sessionStorage.removeItem('loaded_flag');
                      sessionStorage.removeItem('email');
                      sessionStorage.removeItem('company');
                      this.dataservice.AddClientLog();
                    }
                    else {
                      console.log(response)
                      this.alert =this.staticMsgs.registration.danger;
                      this.errorDiv = true;
                      this.errors = response.body['item2'];
                      this.logger.error(response.body['item2'],this.staticMsgs.userregister.UserRegister);

                      this.deleteGroup(params1);
                      //this.deleteUser(params4);
                      this.showLoader = false;
                    }
                  } else {
                    console.log(response)
                    this.alert = this.staticMsgs.registration.danger;
                    this.errorDiv = true;
                    this.errors = response.err;
                    this.logger.error(response.err,this.staticMsgs.userregister.UserRegister);

                    this.deleteGroup(params1);
                    //this.deleteUser(params4);
                    this.showLoader = false;
                  }
                },
                  err => {
                    this.alert =this.staticMsgs.registration.danger;
                    this.errorDiv = true;
                    this.errors = err.message;
                    this.logger.error(err.Message,this.staticMsgs.userregister.UserRegister);

                    this.deleteGroup(params1);
                    //this.deleteUser(params4);
                    this.showLoader = false;
                  })

              }

            } catch (err1) {
              //console.error("ERROR:" + err1);
              if (err1.code == "ResourceNotFoundException") {
                this.alert = this.staticMsgs.registration.danger;
                this.errorDiv = true;
                this.errors = this.staticMsgs.register.role;
                this.logger.error(this.staticMsgs.register.role,this.staticMsgs.userregister.UserRegister);

              }
              else {
                this.alert = this.staticMsgs.registration.danger;
                this.errorDiv = true;
                this.errors = err1.message;
                this.logger.error(err1.Message,this.staticMsgs.userregister.UserRegister);

              }
              this.showLoader = false;
            }

          }


        } catch (error) {
          // console.log('error signing up:', error);
          if (error.code == "UsernameExistsException") {
            this.showLoader = false;
            this.alert = 'User Already Exists';
            this.errorDiv = true;
            this.errors = error.message;
            this.logger.error(error.Message, 'User Already Exists');
            setTimeout(() => {
              this.storageService.storeInvitedUserInfo(user_data);
              this.router.navigate(['auth/login']);
            }, 5000);

          }
          else{
            this.showLoader = false;
            this.alert = this.staticMsgs.registration.danger;
            this.errorDiv = true;
            this.errors = error.message;
            this.logger.error(error.Message, this.staticMsgs.userregister.UserRegister);
          }


        }

      } catch (err) {
        //console.error("ERROR:" + err);
        if (err.code == "GroupExistsException") {
          this.alert = this.staticMsgs.registration.danger;
          this.errorDiv = true;
          this.errors = this.staticMsgs.userregister.companyExists+ this.f.company.value + "-1\" or \"" + this.f.company.value + "-2\".";
        }
        else {
          this.alert = this.staticMsgs.registration.danger;
          this.errorDiv = true;
          this.errors = err.message;
        }
        this.showLoader = false;
      }
    }
    else {
      try {
        const user = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            phone_number: this.phone_number,
            name: firstname + " " + lastname
          }
        });
        //console.log({ user });
        if (user.user) {
          var params2 = {
            GroupName: this.flag, /* required */
            UserPoolId: awsconfig.aws_user_pools_id, /* required */
            Username: email /* required */
          };

          try {
            const result1 = await this.auth.calladdUsertoGroup(params2);

            if (Object.keys(result1).length === 0) {
              this.dataservice.registerUserDetails(user_data).subscribe((response) => {
                if (response.status === 200) {
                  if (response.body['item2'] === "Record Inserted Or Updated successfully.") {
                    this.logger.info(this.staticMsgs.userregister.recordInserted,this.staticMsgs.userregister.UserRegister);

                    localStorage.setItem('register_username', email);
                    this.showLoader = false;
                    this.alert = this.staticMsgs.registration.sucess;
                    this.errorDiv = true;
                    this.errors = this.staticMsgs.userregister.registrationSucess ,this.staticMsgs.userregister.verifyAccount;
                    this.logger.info(this.staticMsgs.userregister.registrationSucess,this.staticMsgs.userregister.UserRegister);

                    // setTimeout(() => {
                    //   this.router.navigate(['auth/verifyuser']);
                    // }, 2000);
                    this.dataservice.AddClientLog();
                    this.router.navigate(['auth/verifyuser']);
                    sessionStorage.removeItem('flag_filled');
                    sessionStorage.removeItem('flag');
                    sessionStorage.removeItem('loaded_flag');
                    sessionStorage.removeItem('email');
                    sessionStorage.removeItem('company');
                  }
                  else {
                    this.alert = this.staticMsgs.registration.danger;
                    this.errorDiv = true;
                    this.errors = response.body['item2'];
                    this.logger.error(response.body['item2'],this.staticMsgs.userregister.UserRegister);

                   // this.deleteUser(params4);
                    this.showLoader = false;
                  }
                } else {
                  this.alert = this.staticMsgs.registration.danger;
                  this.errorDiv = true;
                  this.errors = response.err;
                  this.logger.error(response.err,this.staticMsgs.userregister.UserRegister);

                  //this.deleteUser(params4);
                  this.showLoader = false;
                }
              },
                err => {
                  this.alert = this.staticMsgs.registration.danger;
                  this.errorDiv = true;
                  this.errors = err.message;
                  this.logger.error(err.Message,this.staticMsgs.userregister.UserRegister);

                  //this.deleteUser(params4);
                  this.showLoader = false;
                })
            }

          } catch (err1) {
            //console.error("ERROR:" + err1);
            if (err1.code == "ResourceNotFoundException") {
              this.alert = this.staticMsgs.registration.danger;
              this.errorDiv = true;
              this.errors = this.staticMsgs.userregister.role;
            }
            else {
              this.alert = this.staticMsgs.registration.danger;
              this.errorDiv = true;
              this.errors = err1.message;
              this.logger.error(err1.Message,this.staticMsgs.userregister.UserRegister);

            }
            this.showLoader = false;
          }

        }


      } catch (error) {
        //console.log('error signing up:', error);
        this.showLoader = false;
        this.alert = this.staticMsgs.registration.danger;
        this.errorDiv = true;
        this.errors = error.message;
        this.logger.error(error.Message,this.staticMsgs.userregister.UserRegister);

      }
    }



  }
  numberOnly(e: any) {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }


  backarraowclick() {
    sessionStorage.setItem('flag_filled', '0');
    sessionStorage.removeItem('flag');
    this.dataservice.AddClientLog();
    this.router.navigate(['auth/login']);
  }

  // Method to open the Role selection Modal pop-up
  open(content) {
    let ngbModalOptions: NgbModalOptions = {
      // backdrop: 'static',
      // keyboard: false
    };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
  }



  // Method to close the Role selection Pop-Up
  continue() {
    this.modalReference.close();
  }

  async deleteGroup(params) {
    return await this.auth.callDeleteGroup(params);
  }

  async deleteUser(params) {
    return await this.auth.callDeleteUser(params);
  }

  refreshSelect() {
    $(document).ready(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }
}


