import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  storeAIDescription(description) {
    return localStorage.setItem('aiDesc', description);
  }

  storeToken(token) {
    return localStorage.setItem('token', token);
  }

  storeAcessJwtToken(accesstoken) {
    return localStorage.setItem('accesstoken', accesstoken);
  }
  storeInvitedUserInfo(inviteduserInfo) {
    return localStorage.setItem('inviteduserInfo', JSON.stringify(inviteduserInfo));
  }
  storeUser(user) {
    return localStorage.setItem('username', user);
  }

  storeEmail(email) {
    return localStorage.setItem('email', email);
  }

  storeUserRoles(roles) {
    return localStorage.setItem('roles', roles);
  }

  storeMenu(menu) {
    return localStorage.setItem('menu', menu);
  }

  storeUserImage(image) {
    return localStorage.setItem('image', image);
  }

  storeUserMainRole(usermainrole) {
    return localStorage.setItem('usermainrole', usermainrole);
  }

  storeUserSubRole(usersubrole) {
    return localStorage.setItem('usersubrole', usersubrole);
  }

  storeEditedColorId(editedcolorid) {
    return localStorage.setItem('editedcolorid', editedcolorid);
  }

  storeEditedColorDetailsId(editedcolordetailid) {
    return localStorage.setItem('editedcolordetailid', editedcolordetailid);
  }
  storeEditStitchesId(editedstitchesid) {
    return localStorage.setItem('editedstitchesid', editedstitchesid);
  }
  storeEditedStichesDetailId(editedstitchesid) {
    return localStorage.setItem('editedstitchesdetailid', editedstitchesid);
  }

  storeEditPaletteId(editedpaletteid) {
    return localStorage.setItem('editedpaletteid', editedpaletteid);
  }
  storeEditPaletteIdDetailsId(editedpaletteid) {
    return localStorage.setItem('editedpalettedetailid', editedpaletteid);
  }

  storeEditedImageId(editedimageid) {
    return localStorage.setItem('editedimageid', editedimageid);
  }
  storeEditedImageDetailsId(editedimageid) {
    return localStorage.setItem('editedimagedetailid', editedimageid);
  }
  storeEditedSeamsId(editedseamsid) {
    return localStorage.setItem('editedseamsid', editedseamsid);
  }
  storeEditedSeamsDetailsId(editedseamsid) {
    return localStorage.setItem('editedseamsdetailid', editedseamsid);
  }

  storeEditedMaterialId(editedmaterialid) {
    return localStorage.setItem('editedmaterialid', editedmaterialid);
  }
  storeEditedMaterialDetailsId(editedmaterialid) {
    return localStorage.setItem('editedmaterialdetailid', editedmaterialid);
  }
  storeEditedSupplierId(editedsupplierid) {
    return localStorage.setItem('editedsupplierid', editedsupplierid);
  }
  storeEditedSupplierDetailsId(editedsupplierid) {
    return localStorage.setItem('editedsupplierdetailid', editedsupplierid);
  }
  storeEditedStyleId(editedstyleid) {
    return localStorage.setItem('editedstyleid', editedstyleid);
  }

  storeEditedStyleDetailsId(editedstyledetailsid) {
    return localStorage.setItem('editedstyledetailsid', editedstyledetailsid);
  }

  storeStyleIdNotification(styleIdNotification) {
    return localStorage.setItem('styleIdNotification', styleIdNotification);
  }

  storeEditedStyleImageId(editedstyleimageid) {
    return localStorage.setItem('editedstyleimageid', editedstyleimageid);
  }

  storeOnlineUser(onlineUsers) {
    return localStorage.setItem('onlineUsers', onlineUsers);
  }

  storeEditedMoodBoard(moodBoardId: any) {
    return localStorage.setItem(
      'editedMoodBoardId',
      JSON.stringify(moodBoardId)
    );
  }

  Store(user, email, token, accesstoken, roles) {
    this.storeToken(token);
    this.storeAcessJwtToken(accesstoken);
    this.storeUser(user);
    this.storeEmail(email);
    this.storeUserRoles(roles);
  }

  clear() {
    localStorage.removeItem('token');
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('roles');
    localStorage.removeItem('menu');
    localStorage.removeItem('image');
    localStorage.removeItem('usermainrole');
    localStorage.removeItem('usersubrole');
    localStorage.removeItem('editedcolorid');
    localStorage.removeItem('editedstitchesid');
    localStorage.removeItem('editedpaletteid');
    localStorage.removeItem('editedimageid');
    localStorage.removeItem('editedsupplierid');
    localStorage.removeItem('editedmaterialid');
    localStorage.removeItem('editedseamsid');
    localStorage.removeItem('editedstyleid');
    localStorage.removeItem('editedstyledetailsid');
    localStorage.removeItem('styleIdNotification');
    localStorage.removeItem('editedstyleimageid');
    localStorage.removeItem('onlineUsers');
    localStorage.removeItem('editedMoodBoardId');
    localStorage.removeItem('userFullName');
    localStorage.removeItem('inviteduserInfo');
  }

  removeAIDescription() {
    return localStorage.removeItem('aiDesc');
  }

  removeStyleIdNotification() {
    localStorage.removeItem('styleIdNotification');
  }
  removeinvitedUserInfo(){
    localStorage.removeItem('inviteduserInfo');
  }


  removeStyleDetailsId() {
    localStorage.removeItem('editedstyledetailsid');
  }

  getAIDescription() {
    return localStorage.getItem('aiDesc');
  }


  getToken() {
    return localStorage.getItem('token');
  }

  getAcessJwtToken() {
    return localStorage.getItem('accesstoken');
  }

  getUser() {
    return localStorage.getItem('username');
  }

  getUserData() {
    return localStorage.getItem('userFullName');
  }

  getEmail() {
    return localStorage.getItem('email');
  }

  getRoles() {
    return localStorage.getItem('roles');
  }

  getMenu() {
    return localStorage.getItem('menu');
  }

  getUserImage() {
    return localStorage.getItem('image');
  }
  getinvitedUserInfo() {
    return localStorage.getItem('inviteduserInfo');
  }
  getUserMainRole() {
    return localStorage.getItem('usermainrole');
  }

  getUserSubRole() {
    return localStorage.getItem('usersubrole');
  }

  getEditedColorId() {
    return localStorage.getItem('editedcolorid');
  }
  getEditedColorDetailsId() {
    return localStorage.getItem('editedcolordetailid');
  }

  getEditedStitchesId() {
    return localStorage.getItem('editedstitchesid');
  }
  getEditedStichesDetailsId() {
    return localStorage.getItem('editedstitchesdetailid');
  }
  getMaterialId() {
    return localStorage.getItem('editedmaterialid');
  }
  getEditedMaterialDetailsId() {
    return localStorage.getItem('editedmaterialdetailid');
  }
  getEditedSupplierId() {
    return localStorage.getItem('editedsupplierid');
  }
  getEditedSupplierDetailsId() {
    return localStorage.getItem('editedsupplierdetailid');
  }
  getEditedStyleId() {
    return localStorage.getItem('editedstyleid');
  }
  getEditedStyleDetailsId() {
    return localStorage.getItem('editedstyledetailsid');
  }
  getstyleIdNotification() {
    return localStorage.getItem('styleIdNotification');
  }

  getEditedImageId() {
    return localStorage.getItem('editedimageid');
  }
  getEditedImageDetailsId() {
    return localStorage.getItem('editedimagedetailid');
  }

  getSeamsId() {
    return localStorage.getItem('editedseamsid');
  }
  getEditedSeamsDetailsId() {
    return localStorage.getItem('editedseamsdetailid');
  }
  getEditedStyleImageId() {
    return localStorage.getItem('editedstyleimageid');
  }

  getEditedPaletteId() {
    return localStorage.getItem('editedpaletteid');
  }
  getEditedPaletteDetailsId() {
    return localStorage.getItem('editedpalettedetailid');
  }
  getOnlineUsers() {
    return localStorage.getItem('onlineUsers');
  }

  getEditedMoodBoardId() {
    return localStorage.getItem('editedMoodBoardId');
  }

  removeColorDetailsId() {
    localStorage.removeItem('editedcolordetailid');
  }
  removeImageDetailsId() {
    localStorage.removeItem('editedimagedetailid');
  }
  removeMaterialDetailsId() {
    localStorage.removeItem('editedmaterialdetailid');
  }
  removePaletteDetailsId() {
    localStorage.removeItem('editedpalettedetailid');
  }
  removeSeamsDetailsId() {
    localStorage.removeItem('editedseamsdetailid');
  }
  removeStichesDetailsId() {
    localStorage.removeItem('editedstitchesdetailid');
  }
  removeSupplierDetailsId() {
    localStorage.removeItem('editedsupplierdetailid');
  }
  hasRoles(role) {
    //const selectedRole = localStorage.getItem('selected_role');
    if (this.hasAnyRole(role)) {
      return true;
    }
    return false;
  }

  hasAnyRole(role) {
    const roles = this.getRoles().split(',');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] === role) {
        return true;
      }
      return false;
    }
  }
}
