<app-loader [showLoader]="showLoader"></app-loader>
<app-loader [showLoader]="showLoader1"></app-loader>

<mat-dialog-content class="mat-typography">
  <div class="dt-page__header">
    <h3 class="dt-page__title">Edit Stitches
      <button type="button" class="close" aria-label="Close" (click)="cancelStitchesCreate()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
  </div>

  <form [formGroup]="stitchesForm" (ngSubmit)="onStitchesSubmit()">
    <div class="form-row">
      <div class="col-lg-4 col-sm-12 mb-3" *ngIf="isIDVisible">
        <label for="sequence">{{appStaticMsgs.common.id1}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="sequence" formControlName="sequence" placeholder="Enter ID"
          value="">
        <!-- <div *ngIf="(submitted && this.f.sequence.errors) || ( this.f.sequence.invalid && (this.f.sequence.dirty || this.f.sequence.touched))">
        <div *ngIf="this.f.ID.errors.required" class="red">
          ID # is required.
        </div>
      </div> -->
      </div>

      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="name">{{appStaticMsgs.stitches.stitchname}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" id="name" (keypress)="characternameOnly($event)" formControlName="name"
          placeholder="Enter Stitch Name" value="" minlength="3" maxlength="50">
        <div
          *ngIf="(submitted && this.f.name.errors) || ( this.f.name.invalid && (this.f.name.dirty || this.f.name.touched))">
          <div *ngIf="this.f.name.errors.required" class="red">
            {{appStaticMsgs.stitches.stitchnameisrequired}}
          </div>
          <div *ngIf="this.f.name.errors.minlength" class="red">
            {{appStaticMsgs.common.minimum3charactersrequired}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="isO4915Number">{{appStaticMsgs.stitches.iso4915number}}<span class="red_icon">*</span></label>
        <input type="text" class="form-control" (keypress)="characterOnly($event)" id="isO4915Number"
          formControlName="isO4915Number" placeholder="Enter ISO 4915 Number" value="" required>
        <div
          *ngIf="(submitted && this.f.isO4915Number.errors) || ( this.f.isO4915Number.invalid && (this.f.isO4915Number.dirty || this.f.isO4915Number.touched))">
          <div *ngIf="this.f.isO4915Number.errors.required" class="red">
            {{appStaticMsgs.stitches.iso4915numberisrequired}}
          </div>
          <div
            *ngIf="this.stitchesForm.controls.isO4915Number.value && this.stitchesForm.controls.isO4915Number.value.length > 20"
            class="red">
            {{appStaticMsgs.stitches.iso4915isallowed20charactersonly}}
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="status"> {{appStaticMsgs.common.status}}<span class="red_icon">*</span></label>
        <select id="status" formControlName="status" class="form-control selectpicker"
          [title]="appStaticMsgs.common.selectstatus">
          <option value="Approved">Approved</option>
          <option value="In Review">In Review</option>
          <option value="Dropped">Dropped</option>
        </select>
        <div
          *ngIf="(submitted && this.f.status.errors) || ( this.f.status.invalid && (this.f.status.dirty || this.f.status.touched))">
          <div *ngIf="this.f.status.errors.required" class="red">
            {{appStaticMsgs.common.statusisrequired}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="commonApplication">{{appStaticMsgs.stitches.commonapplication}}<span
            class="red_icon">*</span></label>
        <textarea cols="2" formControlName="commonApplication" name="commonApplication"
          placeholder="Enter Common Application" class="form-control" required></textarea>
        <div
          *ngIf="(submitted && this.f.commonApplication.errors) || ( this.f.commonApplication.invalid && (this.f.commonApplication.dirty || this.f.commonApplication.touched))">
          <div *ngIf="this.f.commonApplication.errors.required" class="red">
            {{appStaticMsgs.stitches.commonapplicationisrequired}}
          </div>
          <div
            *ngIf="this.stitchesForm.controls.commonApplication.value && this.stitchesForm.controls.commonApplication.value.length > 500"
            class="red">
            {{appStaticMsgs.stitches.commonapplicationisallowed500charactersonly}}
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="requirements">{{appStaticMsgs.common.requirements}}</label>
        <textarea cols="2" formControlName="requirements" name="requirements" placeholder="Enter Requirements"
          class="form-control"></textarea>
        <div
          *ngIf="this.stitchesForm.controls.requirements.value && this.stitchesForm.controls.requirements.value.length > 500"
          class="red">
          {{appStaticMsgs.stitches.requirementsisallowed500charactersonly}}
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 mb-3">
        <label for="description">{{appStaticMsgs.common.description}}</label>
        <textarea cols="2" formControlName="description" name="description" placeholder="Enter Description"
          class="form-control"></textarea>
       
      </div>

      <!-- <div class="col-lg-4 mb-3">
          <label for="validationDefault02">Image #</label>
          <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Image #" value="" required="">
      </div>
      <div class="col-lg-4 mb-3">
        <label for="validationDefault02">Name</label>
        <input type="text" class="form-control" id="validationDefault02" placeholder="Enter Name" value="" required="">
    </div> -->
    </div>
    <div>
      <div class="dt-entry__header mt-7">
        <!-- Entry Heading -->
        <div class="dt-entry__heading">
          <h3 class="dt-entry__title">{{appStaticMsgs.stitches.uploadtopviewassewn}}</h3>
        </div>
        <!-- /entry heading -->
      </div>
      <div class="form-row">
        <div class="col-sm-12 mb-3">
          <label for="topviewComments">{{appStaticMsgs.common.comments}}</label>
          <textarea cols="2" class="form-control" formControlName="topviewComments" name="topviewComments"
            placeholder="Enter Comments"></textarea>
          <div
            *ngIf="this.stitchesForm.controls.topviewComments.errors && this.stitchesForm.controls.topviewComments.value && this.stitchesForm.controls.topviewComments.value.length > 500"
            class="red">
            {{appStaticMsgs.stitches.commentsisallowed500charactersonly}}
          </div>
        </div>

        <div class="col-xl-12">
          <label>{{appStaticMsgs.stitches.uploadtopviewfile}}<span class="red_icon">*</span></label>
          <div class="custom-dropzone" id="topView" ngx-dropzone [multiple]="false" (change)="onSelect($event)">
            <ngx-dropzone-label>
              <div>
                <h5 class="text-light-gray mt-3">{{appStaticMsgs.common.dropfilesheretoupload}}</h5>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of topView" [file]="f"
              [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>
                {{ f.name }}
                <!-- ({{ f.type }}) -->
              </ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </div>
          <div *ngIf="(submitted && isTopFileError)" class="red">
            {{appStaticMsgs.stitches.uploadtopviewsewnisrequired}}
          </div>
        </div>
        <div class="col-xl-6 mt-6" *ngIf="this.f.sequence.value">
          <div class="mb-5" *ngFor="let editedThumbnail of editedtopViewfile; let j=index">
            <img src="{{editedThumbnail.thumbnail}}" width="250px" *ngIf="isValidImageURL(editedThumbnail.name)"
              (click)="openTopFiles(j)">
            <button type="button" class="close image-close" (click)="removeTopSection(editedThumbnail)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="dt-entry__header mt-7 mt-3">
        <!-- Entry Heading -->
        <div class="dt-entry__heading">
          <h3 class="dt-entry__title">{{appStaticMsgs.stitches.uploadbottomviewassewn}}</h3>
        </div>
        <!-- /entry heading -->
      </div>
      <div class="form-row">
        <div class="col-sm-12 mb-3">
          <label for="bottomviewComments">{{appStaticMsgs.common.comments}}</label>
          <textarea cols="2" class="form-control" formControlName="bottomviewComments" name="bottomviewComments"
            placeholder="Enter Comments"></textarea>
          <div
            *ngIf="this.stitchesForm.controls.bottomviewComments.errors && this.stitchesForm.controls.bottomviewComments.value && this.stitchesForm.controls.bottomviewComments.value.length > 500"
            class="red">
            {{appStaticMsgs.stitches.commentsisallowed500charactersonly}}
          </div>
        </div>
        <div class="col-xl-12">
          <label>{{appStaticMsgs.stitches.uploadbottomviewfile}}<span class="red_icon">*</span></label>
          <div class="custom-dropzone" id="bottomView" ngx-dropzone [multiple]="false"
            (change)="onSelectBottomSewn($event)">
            <ngx-dropzone-label>
              <div>
                <h5 class="text-light-gray mt-3">{{appStaticMsgs.common.dropfilesheretoupload}}</h5>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of bottomView" [file]="f"
              [removable]="true" (removed)="onRemoveBottomSewn(f)">
              <ngx-dropzone-label>
                {{ f.name }}
                <!-- ({{ f.type }}) -->
              </ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </div>
          <div *ngIf="(submitted && isBottomFileError)" class="red">
            {{appStaticMsgs.stitches.uploadbottomviewsewnisrequired}}
          </div>
        </div>
        <div class="col-xl-6 mt-6" *ngIf="this.f.sequence.value">
          <div class="mb-5" *ngFor="let editedThumbnail of editedbottomViewfile; let j=index">
            <img src="{{editedThumbnail.thumbnail}}" width="250px" *ngIf="isValidImageURL(editedThumbnail.name)"
              (click)="openBottomFiles(j)">
            <button type="button" class="close image-close" (click)="removeBottomSection(editedThumbnail)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="form-row mt-10 float-right">
    <div class="row mt-3">
      <button type="button" *ngIf="!isEditMode" (click)="cancelStitchesCreate()" class="btn btn-outline-primary adv-search-btn mr-3">
        Cancel
      </button>
      <button type="submit" *ngIf="!isEditMode"
      class="btn btn-primary adv-search-btn">Create</button>
      <button type="submit" *ngIf="isEditMode"
      class="btn btn-primary adv-search-btn">Update</button>
    </div>

  </div> -->

    <div class="d-flex justify-content-end mt-4">
      <div class="row mt-3">
        <button type="button" *ngIf="isEditMode" (click)="cancelStitchesCreate()"
          class="btn btn-outline-primary adv-search-btn mr-1">{{appStaticMsgs.common.cancel}}</button>
        <button type="submit" *ngIf="!isEditMode" class="btn btn-primary adv-search-btn"
          [disabled]="stitchesForm?.invalid || !isTopViewAdded || !isBottomViewAdded">
          {{appStaticMsgs.common.create}}</button>
        <button type="submit" *ngIf="isEditMode" class="btn btn-primary adv-search-btn mr-3"
          [disabled]="stitchesForm?.invalid || !isTopViewAdded || !isBottomViewAdded">
          {{appStaticMsgs.common.update}}</button>

      </div>
    </div>
  </form>
</mat-dialog-content>
