import { Injectable } from '@angular/core';
import awsconfig from '../../../../aws-exports';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  isValid(token){
    const payload = this.payload(token);
    if (payload){
        return payload.iss == "https://cognito-idp.ap-south-1.amazonaws.com/"+awsconfig.aws_user_pools_id ? true : false;
    }
    return false;
}

isExpired(token){
  const payload = this.payload(token);
  const currentUnixTime = new Date().getTime() / 1000
    if (currentUnixTime > payload.exp) {
      return true;
      }
      else {
        return false;
      }      

}

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }
  decode(payload) {
    if (this.isBase64(payload)){
      return JSON.parse(atob(payload));
  }
    return false;
  }
  isBase64(str) {
    try{
      // tslint:disable-next-line: triple-equals
      return btoa(atob(str)).replace(/=/g,'') == str;
  }
  catch(err){
      return false;
  }
  }
}
