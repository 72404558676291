import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { DataService } from "../services/data.service";
import * as colorActions from "../actions/color.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AESDecryptService } from "../helpers/AESDecrypt.service";


@Injectable()

export class ColorEffect {

    constructor(private actions: Actions,
        private dataService: DataService,
        private aesdecryptservice: AESDecryptService,
    ) { }
    
    getAllColorDetails(data) {
        data.classification = this.aesdecryptservice.decryptData(this.dataService.Key, data["classification"]);
        data.sequence = this.aesdecryptservice.decryptData(this.dataService.Key, data["sequence"]);
        data.name = this.aesdecryptservice.decryptData(this.dataService.Key, data["name"]);
        data.internalRef = this.aesdecryptservice.decryptData(this.dataService.Key, data["internalRef"]);
        data.status = this.aesdecryptservice.decryptData(this.dataService.Key, data["status"]);
        data.description = this.aesdecryptservice.decryptData(this.dataService.Key, data["description"]);
        data.colourSwatch = this.aesdecryptservice.decryptData(this.dataService.Key, data["colourSwatch"]);
        data.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, data["hexcode"]);
        data.colorStandard = this.aesdecryptservice.decryptData(this.dataService.Key, data["colorStandard"]);
        data.pantoneCode = this.aesdecryptservice.decryptData(this.dataService.Key, data["pantoneCode"]);
        if (data.createdBy) {
            data.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, data.createdBy.firstName);
            data.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key,data.createdBy.lastName);
        }
        if (data["thumbnailFiles"]) {
            data["thumbnailFiles"].thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, data["thumbnailFiles"].thumbnail);
            data["thumbnailFiles"].name = this.aesdecryptservice.decryptData(this.dataService.Key, data["thumbnailFiles"].name);
        }
        if (data["documents"] != null && data["documents"].length > 0) {
            data["documents"].forEach(x => {
                x['sequence'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['sequence']);
                x['classification'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['classification']);
                x['status'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['status']);
                x['description'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['description']);
                x['reportStatus'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['reportStatus']);
                x['labLocation'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['labLocation']);
                x['notes'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['notes']);
                x['name'] = this.aesdecryptservice.decryptData(this.dataService.Key, x['name']);
                if (x.files != null) {
                    x.files.forEach(y => {
                        y.name = this.aesdecryptservice.decryptData(this.dataService.Key, y.name);
                        y.thumbnail = this.aesdecryptservice.decryptData(this.dataService.Key, y.thumbnail);
                        y.fileType = this.aesdecryptservice.decryptData(this.dataService.Key, y.fileType);
                        y.org = this.aesdecryptservice.decryptData(this.dataService.Key, y.org);
                    });
                }
            });
        }
    }

    @Effect()
    loadColorLibrary: Observable<Action> = this.actions.pipe(
        ofType<colorActions.LoadColorLibrary>(
            colorActions.ColorActionTypes.LOAD_COLOR_LIBRARY
        ), mergeMap((action: colorActions.LoadColorLibrary) =>
            this.dataService.getColor(action.payload1, action.payload2).pipe(
                map((colorLibrary: any[]) => {
                    if (colorLibrary['body']['item1'].length > 0 && !colorLibrary['body']['item2'].toLowerCase().includes('error')) {
                        colorLibrary['body']['item1'].forEach(x => {
                            x.name = this.aesdecryptservice.decryptData(this.dataService.Key, x.name);
                            x.classification = this.aesdecryptservice.decryptData(this.dataService.Key, x.classification);
                            x.colorStandard = this.aesdecryptservice.decryptData(this.dataService.Key, x.colorStandard);

                            if (x.hexcode) {
                                x.hexcode = this.aesdecryptservice.decryptData(this.dataService.Key, x.hexcode);

                            } else if (x.thumbnailFiles) {
                                x.thumbnailFiles['thumbnail'] = this.aesdecryptservice.decryptData(this.dataService.Key, x.thumbnailFiles['thumbnail']);

                            }
                            x.createdBy.firstName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.firstName);
                            x.createdBy.lastName = this.aesdecryptservice.decryptData(this.dataService.Key, x.createdBy.lastName);
                            x.CreatedbyName= x.createdBy.firstName; 
                            x.status = this.aesdecryptservice.decryptData(this.dataService.Key, x.status);
                        });
                    }
                    return new colorActions.LoadColorLibrarySuccess(colorLibrary['body']['item1'], colorLibrary['body'])
                }),
                catchError(err => of(new colorActions.LoadColorLibraryFail(err)))
            )
        )
    );
    @Effect()
    loadColor: Observable<Action> = this.actions.pipe(
        ofType<colorActions.LoadColor>(
            colorActions.ColorActionTypes.LOAD_COLOR
        ), mergeMap((action: colorActions.LoadColor) =>
            this.dataService.getColorById(action.payload).pipe(
                map((color: any) => {
                    if (!color['body']['item2'].toLowerCase().includes('error')) {
                    this.getAllColorDetails(color['body']['item1']);
                    }
                    return new colorActions.LoadColorSuccess(color['body']['item1'], color['body'])
                }
                ),
                catchError(err => of(new colorActions.LoadColorFail(err)))
            )
        )
    );
    @Effect()
    createColor: Observable<any> = this.actions.pipe(
        ofType<colorActions.CreateColor>(
            colorActions.ColorActionTypes.CREATE_COLOR
        ), map((action: colorActions.CreateColor) => action.payload),
        mergeMap((color: any) =>
            this.dataService.addColor(color).pipe(
                map((newColor: any) => {
                    if (!newColor['body']['item2'].toLowerCase().includes('error')) {
                    this.getAllColorDetails(newColor['body']['item1']);
                    }
                    return new colorActions.CreateColorSuccess(newColor['body']['item1'], newColor['body'])
                }),
                catchError(err => of(new colorActions.CreateColorFail(err)))
            )
        )
    );

    @Effect()
    updateColor: Observable<any> = this.actions.pipe(
        ofType<colorActions.UpdateColor>(
            colorActions.ColorActionTypes.UPDATE_COLOR
        ), map((action: colorActions.UpdateColor) => action.payload),
        mergeMap((color: any) =>
            this.dataService.updateColorById(color).pipe(
                map((updatedColor: any) => {
                    this.getAllColorDetails(updatedColor['body']['item1']);
                    return new colorActions.UpdateColorSuccess({
                        id: updatedColor['body']['item1'].id,
                        changes: updatedColor['body']['item1']
                    }, updatedColor['body'])
                }

                ),
                catchError(err => of(new colorActions.UpdateColorFail(err)))
            )
        )
    );

    @Effect()
    updateColorLibrary: Observable<any> = this.actions.pipe(
        ofType<colorActions.UpdateColorLibrary>(
            colorActions.ColorActionTypes.UPDATE_COLOR_LIBRARY
        ), map((action: colorActions.UpdateColorLibrary) => action.payload),
        mergeMap((colorIds: any[]) =>
            this.dataService.bulkUpdateColor(colorIds).pipe(
                map((updatedColor: any) =>
                    new colorActions.UpdateColorLibrarySuccess([{
                        id: updatedColor['body']['item1'].id,
                        changes: updatedColor['body']['item1']
                    }], updatedColor['body'])
                ),
                catchError(err => of(new colorActions.UpdateColorLibraryFail(err)))
            )
        )
    );

    @Effect()
    deleteColor: Observable<any> = this.actions.pipe(
        ofType<colorActions.DeleteColorLibrary>(
            colorActions.ColorActionTypes.DELETE_COLOR_LIBRARY
        ), map((action: colorActions.DeleteColorLibrary) => action.payload),
        mergeMap((colorIds: any[]) =>
            this.dataService.bulkDeleteColour(colorIds).pipe(
                map((deletedColor: any) =>
                    new colorActions.DeleteColorLibrarySuccess(colorIds, deletedColor['body'])
                ),
                catchError(err => of(new colorActions.DeleteColorLibraryFail(err)))
            )
        )
    );

    @Effect()
    updateColorDocument: Observable<any> = this.actions.pipe(
        ofType<colorActions.UpdateColorDocument>(
            colorActions.ColorActionTypes.UPDATE_COLOR_DOCUMENT
        ), map((action: colorActions.UpdateColorDocument) => action.payload),
        mergeMap((colordocument: any) =>
            this.dataService.updateColorDocuments(colordocument).pipe(
                map((updatedColorDocument: any) => {
                    if (!updatedColorDocument['body']['item2'].toLowerCase().includes('error')) {
                        this.getAllColorDetails(updatedColorDocument['body']['item1']);
                    }
                    return new colorActions.UpdateColorDocumentSuccess({
                        id: updatedColorDocument['body']['item1'].id,
                        changes: updatedColorDocument['body']['item1']
                    }, updatedColorDocument['body'])
                }

                ),
                catchError(err => of(new colorActions.UpdateColorDocumentFail(err)))
            )
        )
    );
}