<!-- Site Content Wrapper -->
<app-loader [showLoader]="showLoader"></app-loader>
<app-loader [showLoader]="showLoader1"></app-loader>
<main class="dt-main">
  <!-- NAVBAR: Left -->
  <app-leftbar></app-leftbar>
  <!-- <div *ngIf="activeNavDrawer" class="dt-backdrop" (click)="onClickOverlay()"></div> -->
  <!-- / NAVBAR: Left -->
  <!-- Site Content -->
  <div class="dt-content-wrapper">
    <!-- Site Content -->
    <div class="dt-content">

      <!-- Page Header -->
      <div class="dt-page__header">
        <h1 class="dt-page__title">{{staticMsgs.profile.profileInfo}}</h1>
        <nav class="mb-0 breadcrumb">
          <a href="javascript:void(0)" class="breadcrumb-item" routerLink="/dashboard">{{staticMsgs.profile.Home}}</a>
          <span class="active breadcrumb-item">{{staticMsgs.profile.profileDetails}}</span>
        </nav>
      </div>
      <!-- /page header -->
      <div class="profile-content">
        <!-- Grid -->
        <div class="row">
          <!-- Grid Item -->
          <div class="col-xl-4">
          </div>
          <!-- /grid item -->
          <!-- Grid Item -->
          <div class="col-xl-12">
            <!-- Card -->
            <div class="card card-shadow-none">
              <!-- Card Header -->
              <div class="card-header card-nav border-bottom d-sm-flex justify-content-sm-between">
                <!-- <h3 class="mb-2 mb-sm-n5">About</h3> -->
                <ul class="card-header-links nav nav-underline" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#tab-pane1" role="tab"
                      [ngClass]="{ 'active':activeTab === 'basicInfoTab' }"
                      (click)="basicInfoDetailsTab('basicInfoTab')" aria-controls="tab-pane1" aria-selected="true">Basic
                      Info</a>
                  </li>
                  <li class="nav-item" *ngIf="isSupplierProfile">
                    <a class="nav-link" data-toggle="tab" href="#tab-pane2" role="tab"
                      [ngClass]="{ 'active':activeTab === 'bussinessInfoTab' }"
                      (click)="bussinessInfoDetailsTab('bussinessInfoTab')" aria-controls="tab-pane2"
                      aria-selected="true">{{staticMsgs.profile.bussinessInfo}}</a>
                  </li>
                  <li class="nav-item" *ngIf="isSupplierProfile">
                    <a class="nav-link" data-toggle="tab" href="#tab-pane3" role="tab"
                      [ngClass]="{ 'active':activeTab === 'portfolioTab' }"
                      (click)="portfolioDetailsTab('portfolioTab')" aria-controls="tab-pane3"
                      aria-selected="true">{{staticMsgs.profile.portfolio}}</a>
                  </li>
                  <li class="nav-item" *ngIf="isSupplierProfile">
                    <a class="nav-link" data-toggle="tab" href="#tab-pane4" role="tab"
                      [ngClass]="{ 'active':activeTab === 'aboutTab' }" (click)="aboutDetailsTab('aboutTab')"
                      aria-controls="tab-pane4" aria-selected="true">{{staticMsgs.profile.about}}</a>
                  </li>
                </ul>

                <button type="button" class="btn mr-3 btn-color-primary round" (click)="tabenable()"
                  *ngIf='tabenableedit' ngbTooltip="Edit Profile">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>

                <button type="button" class="btn mr-3 btn-color-primary round" (click)="tabdisable()"
                  *ngIf='tabdisableedit' ngbTooltip="Cancel Edit">
                  <i class="fa fa-close" aria-hidden="true"></i>
                </button>

                <!-- <span class="edit_icon" (click)="tabenable()" *ngIf='tabenableedit'>
                  <i class="fa fa-edit btn btn-color-primary round "></i>
                </span>
                <span class="edit_icon" (click)="tabdisable()" *ngIf='tabdisableedit'>
                  <i class="fa fa-close btn btn-color-primary round"></i>
                </span> -->
              </div>
              <!-- /card header -->
              <!-- Card Body -->
              <div class="mt-3 pb-2">
                <!-- Tab Content-->
                <div class="tab-content">
                  <!-- Tab panel Basic Info -->
                  <div id="tab-pane1" class="tab-pane  card-body card-body-shadow bg-white active"
                    [ngClass]="{ 'active':activeTab === 'basicInfoTab'}">
                    <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div>
                    <div class="row">
                      <div class="col-md-8">

                        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                          <div class="form-row">
                            <div class="col-sm-6 mb-3">
                              <label for="firstname">{{staticMsgs.profile.Fname}}<span class="red_icon">*</span></label>
                              <input type="text" formControlName="firstname" id="firstname" class="form-control"
                                required />
                              <div
                                *ngIf="(submitted && this.f.firstname.errors) ||(this.f.firstname.invalid && (this.f.firstname.dirty || this.f.firstname.touched))">
                                <div *ngIf="this.f.firstname.errors.required" class="red">
                                  {{staticMsgs.profile.FnameReq}}
                                </div>
                                <div
                                  *ngIf="this.profileForm.controls.firstname.value && this.profileForm.controls.firstname.value.length > 25"
                                  class="red">
                                  {{staticMsgs.profile.FnameAllow}}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6 mb-3">
                              <label for="lastname">{{staticMsgs.profile.Lname}}<span class="red_icon">*</span></label>
                              <input type="text" formControlName="lastname" id="lastname" class="form-control"
                                required />
                              <div
                                *ngIf="(submitted && this.f.lastname.errors) || ( this.f.lastname.invalid && (this.f.lastname.dirty || this.f.lastname.touched))">
                                <div *ngIf="this.f.lastname.errors.required" class="red">
                                  {{staticMsgs.profile.LnameReq}}
                                </div>
                                <div
                                  *ngIf="this.profileForm.controls.lastname.value && this.profileForm.controls.lastname.value.length > 25"
                                  class="red">
                                  {{staticMsgs.profile.LnameAllow}}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-sm-6 mb-3">
                              <label for="userrole">{{staticMsgs.profile.role}}<span class="red_icon">*</span></label>
                              <select (change)="selectedRole($event)" title="Select Role" formControlName="userrole"
                                id="userrole" class="form-control selectpicker" required>
                                <!-- <option value="">--Select Role--</option> -->
                                <option *ngFor="let role of roles" [value]="role.id">
                                  {{ role.name }}
                                </option>
                              </select>
                              <div
                                *ngIf="(submitted && this.f.userrole.errors) || (this.f.userrole.invalid && (this.f.userrole.dirty || this.f.userrole.touched))">
                                <div *ngIf="this.f.userrole.errors.required" class="red">
                                  {{staticMsgs.profile.roleReq}}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6 mb-3">
                              <label for="phone_number_code">{{staticMsgs.profile.contact}}</label>
                              <div class="form-row">
                                <div class="col-sm-5">
                                  <select formControlName="phone_number_code" id="phone_number_code"
                                    class="form-control dt-contact-select selectpicker" data-live-search="true">
                                    <option *ngFor="let countrycode of countrycodes" [value]="countrycode.value">
                                      {{ countrycode.name }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-sm-7">
                                  <input type="phone" formControlName="phone_number" id="phone_number"
                                    class="form-control" />
                                </div>
                                <div class="ml-3"
                                  *ngIf="(submitted && this.f.phone_number.errors) || (this.f.phone_number.invalid && (this.f.phone_number.dirty || this.f.phone_number.touched))">
                                  <div *ngIf="this.f.phone_number.errors.pattern" class="red">
                                    {{staticMsgs.profile.mobValid}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-sm-6 mb-3">
                              <label for="company">
                                {{staticMsgs.profile.company}}
                                <span>
                                  <a href="javascript:void(0)" data-toggle="popover" data-trigger="focus" title=""
                                    data-placement="bottom"
                                    data-content=" <p>Enter unique name under which your company/organization is registered. In case the system doesn’t accept the name, Please try the suggested names given by System. Still if you are facing any issue please contact FitOs Support Team.</p>">
                                    <i class="icon icon-question-circle icon-fw icon-sm"></i>
                                  </a>
                                </span>
                              </label>
                              <input type="text" formControlName="company" id="company" class="form-control">
                              <div
                                *ngIf="(submitted && this.f.company.errors) || (this.f.company.invalid && (this.f.company.dirty || this.f.company.touched))">
                                <div *ngIf="this.f.company.errors.pattern" class="red">
                                  {{staticMsgs.profile.namePattern}}
                                </div>
                                <div *ngIf="this.f.company.errors.maxlength" class="red">
                                  {{staticMsgs.profile.nameChar}}
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 mb-6">
                              <label>{{staticMsgs.profile.timezone}}</label>
                              <select formControlName="timeZone" id="timeZone"
                                    class="form-control dt-contact-select selectpicker" data-live-search="true">
                                      <option *ngFor="let time of TimeZone"  [value]="time.value">{{time.value}}</option>
                                      <!-- <optgroup *ngFor="let time of TimeZone" [label]="time.value">
                                        <option *ngFor="let utc of time.utc;let i=index"  [value]="utc">{{utc}}</option>
                                      </optgroup> -->
                                  </select>
                            </div>

                            <!-- <div class="col-sm-6">
                              <label for="company">
                                Timezone
                              </label>
                              <select formControlName="phone_number_code" id="phone_number_code"
                                    class="form-control dt-contact-select selectpicker" data-live-search="true">
                                    <option *ngFor="let countrycode of countrycodes" [value]="countrycode.value">
                                      {{ countrycode.name }}
                                    </option>
                                  </select>
                            </div> -->
                          </div>
                          <div class="form-row mt-10 float-right">
                            <button type="submit" *ngIf="buttonShow" class="btn btn-primary">{{staticMsgs.profile.Save}}</button>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-4">
                        <!-- Card -->
                        <div class="dt-card dt-card__full-height">
                          <!-- Card Header -->
                          <div class="dt-card__header pt-2">
                            <!-- Card Heading -->
                            <div class="dt-card__heading text-center">
                              <!-- <h3 class="dt-card__title">Contact</h3> -->
                              <span class="imgUser">
                                <img src={{userImg}} class="img-fluid flag-icon-circle userImg" *ngIf="userImg">
                                <img src="../../../assets/images/usericon.png" alt="User Profile" *ngIf="!userImg" size="150" />
                                    <div class="userimg_edit image-upload" *ngIf="buttonShow">
                                  <i class="icon icon-editors icon-lg icon-fw text-white pb-2px" for="file"></i>
                                  <input #file type="file" id="file-input" accept='image/*' (change)="selectFile($event)" />
                                </div>
                              </span>
                            </div>
                            <!-- /card heading -->
                            <!-- <span class="edit_icon mb-140" (click)="profileenable()" *ngIf='tabprofileedit'>
                        <i class="icon icon-editors icon-lg icon-fw text-white pb-2px"></i>
                    </span>

                    <span class="edit_icon mb-140" (click)="profiledisable()"
                        *ngIf='tabprofiledisable'>
                        <i class="icon icon-remove icon-lg icon-fw text-white pb-2px"></i>
                    </span> -->
                          </div>
                          <!-- /card header -->
                          <h4 class="text-center mb-0">{{currentUser.firstname}} {{currentUser.lastname}}</h4>
                          <!-- Card Body -->
                          <div class="dt-card__body ">
                            <!-- Media -->
                            <gx-media-icon-card class="mb-5" label="Mail">
                              <gx-icon name="mail" size="xl" class="mr-5" icon></gx-icon>
                              <a href="javascript:void(0)" text class="w-b">{{currentUser.email}}</a>
                            </gx-media-icon-card>
                            <!-- /media -->
                            <!-- Media -->
                            <!-- <gx-media-icon-card class="mb-5" label="Web Page">
                        <gx-icon name="link" size="xl" class="mr-5" icon></gx-icon>
                        <a href="javascript:void(0)" text>{{contactDetail.website}}</a>
                    </gx-media-icon-card> -->
                            <!-- /media -->
                            <!-- Media -->
                            <gx-media-icon-card label="Phone" *ngIf="currentUser.phone_number">
                              <gx-icon name="phone-o" size="xl" class="mr-5" icon></gx-icon>
                              <span class="h5"
                                text>{{currentUser.phone_number_code}} &nbsp; {{currentUser.phone_number}}</span>
                            </gx-media-icon-card>
                          </div>
                          <!-- /card body -->
                        </div>
                        <!-- /card -->
                      </div>
                    </div>

                  </div>

                  <!-- tab panel Business info -->
                  <div id="tab-pane2" class="tab-pane  card-body card-body-shadow bg-white" *ngIf="isSupplierProfile"
                    [ngClass]="{ 'active':activeTab === 'bussinessInfoTab'}">
                    <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div>
                    <form [formGroup]="bussinessInfoForm" (ngSubmit)="onSubmitBussinessInfo()">
                      <div class="form-row">
                        <div class="col-lg-6 col-sm-12" [ngClass]="{'disableDiv': isDisabled}">
                          <label for="classification">{{staticMsgs.profile.selectClass}}<span class="red_icon">*</span></label>
                          <input type="text" class="form-control" id="classification" formControlName="classification"
                            required />
                          <div
                            *ngIf="(submittedBusinessInfo && this.fs.classification.errors) || ( this.fs.classification.invalid && (this.fs.classification.dirty || this.fs.classification.touched))">
                            <div *ngIf="this.fs.classification.errors.required" class="red">
                              {{staticMsgs.profile.classReq}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="agent">
                        <div class="dt-entry__header mt-5">
                          <div class="dt-entry__heading">
                            <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Agent'">{{staticMsgs.profile.agent}}</h3>
                            <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Factory'">{{staticMsgs.profile.factory}}</h3>
                            <h3 class="dt-entry__title" *ngIf="supplierclassificationValue === 'Raw Material Supplier'">
                              {{staticMsgs.profile.raw}}</h3>
                          </div>
                        </div>

                        <div class="form-row mt-5">
                          <div class="col-lg-6 mb-3">
                            <label for="name">{{staticMsgs.profile.name}}<span class="red_icon">*</span></label>
                            <input type="text" class="form-control" id="name" formControlName="name"
                              placeholder="Enter Name" minlength="3" maxlength="55" required />
                            <div
                              *ngIf="(submittedBusinessInfo && this.fs.name.errors) || ( this.fs.name.invalid && (this.fs.name.dirty || this.fs.name.touched))">
                              <div *ngIf="this.fs.name.errors.required" class="red">
                                {{staticMsgs.profile.nameReq}}
                              </div>
                              <div
                                *ngIf="this.bussinessInfoForm.controls.name.value && this.bussinessInfoForm.controls.name.value.length > 50"
                                class="red">
                                {{staticMsgs.profile.nameAllow}}
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 mb-3">
                            <label for="phone_number_code">{{staticMsgs.profile.PhoneNo}}<span class="red_icon">*</span></label>
                            <div class="form-row">
                              <div class="col-sm-5" [ngClass]="{'disableDiv': isDisabled}">
                                <select formControlName="phone_number_code" id="phone_number_code"
                                  class="form-control dt-contact-select selectpicker" data-live-search="true">
                                  <option *ngFor="let countrycode of countrycodes" [value]="countrycode.value">
                                    {{ countrycode.name }}
                                  </option>
                                </select>
                              </div>
                              <div class="col-sm-7">
                                <input type="number" class="form-control" id="phoneNo" formControlName="phoneNo"
                                  placeholder="Enter Phone Number" maxlength="10">
                                <div
                                  *ngIf="(submittedBusinessInfo && this.fs.phoneNo.errors) || ( this.fs.phoneNo.invalid && (this.fs.phoneNo.dirty || this.fs.phoneNo.touched))">
                                  <div *ngIf="this.fs.phoneNo.errors.required" class="red">
                                    {{staticMsgs.profile.mobReq}}
                                  </div>
                                  <div *ngIf="!this.fs.phoneNo.errors.required && this.fs.phoneNo.errors.pattern"
                                    class="red">
                                    {{staticMsgs.profile.mobValid}}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                            <label for="email">{{staticMsgs.profile.email}}<span class="red_icon">*</span></label>
                            <input type="text" class="form-control" id="email" formControlName="email"
                              placeholder="Enter Email">
                            <div
                              *ngIf="(submittedBusinessInfo && this.fs.email.errors) || ( this.fs.email.invalid && (this.fs.email.dirty || this.fs.email.touched))">
                              <div *ngIf="this.fs.email.errors.required" class="red">
                                {{staticMsgs.profile.emailReq}}
                              </div>
                              <div *ngIf="!this.fs.email.errors.required && this.fs.email.errors.pattern" class="red">
                                {{staticMsgs.profile.emailValid}}
                              </div>
                            </div>
                          </div>

                          <span class="col-lg-12"
                            *ngIf="supplierclassificationValue === 'Factory' || supplierclassificationValue === 'Raw Material Supplier'">
                            <span class="mr-12 ml-12">
                              <div class="form-row">
                                <div class="col-lg-6 mb-3">
                                  <label for="SupplierChainPartner">{{staticMsgs.profile.supplyChain}}</label>
                                  <select title="Select Chain Partner" id="SupplierChainPartner"
                                    formControlName="SupplierChainPartner" class="form-control selectpicker">
                                    <option value="" [disabled]="true" selected>
                                      {{staticMsgs.profile.selectChain}}
                                    </option>
                                    <option value="agent">{{staticMsgs.profile.agent}}</option>
                                    <option value="factory">{{staticMsgs.profile.factory}}</option>
                                    <option value="goodsupplier">{{staticMsgs.profile.finishSupp}}</option>
                                    <option value="rawmaterial">{{staticMsgs.profile.raw}}</option>
                                  </select>
                                </div>

                                <div class="col-lg-6 mb-3">
                                  <label for="productType">{{staticMsgs.profile.product}}</label>
                                  <select title="Select Product Type" id="productType" formControlName="productType"
                                    class="form-control selectpicker">
                                    <option value="" [disabled]="true" selected>
                                      {{staticMsgs.profile.selectProduct}}
                                    </option>
                                    <option value="Mixed">{{staticMsgs.profile.mixed}}</option>
                                    <option value="Wovan">{{staticMsgs.profile.wovan}}</option>
                                    <option value="NonWovan">{{staticMsgs.profile.nonWovan}}</option>
                                    <option value="Leather">{{staticMsgs.profile.leather}}</option>
                                  </select>
                                </div>

                                <div class="col-lg-6 mb-3">
                                  <label for="speciality">{{staticMsgs.profile.speciality}}</label>
                                  <select title="Select Speciality" formControlName="speciality"
                                    class="form-control selectpicker">
                                    <option value="" [disabled]="true" selected>
                                      {{staticMsgs.profile.selectSpec}}
                                    </option>
                                    <option value="Knits">{{staticMsgs.profile.knits}}</option>
                                    <option value="Wovan">{{staticMsgs.profile.wovan}}</option>
                                    <option value="Trims">{{staticMsgs.profile.trims}}</option>
                                    <option value="Packaging">{{staticMsgs.profile.packaging}}</option>
                                    <option value="Leather">{{staticMsgs.profile.leather}}</option>
                                  </select>
                                </div>

                                <div class="col-lg-6 mb-3">
                                  <label for="moq">{{staticMsgs.profile.moq}}<span class="red_icon">*</span></label>
                                  <input type="number" class="form-control" id="moq" formControlName="moq"
                                    placeholder="Enter MOQ">
                                  <div
                                    *ngIf="(submittedBusinessInfo && this.fs.moq.errors) || ( this.fs.moq.invalid && (this.fs.moq.dirty || this.fs.moq.touched))">
                                    <div *ngIf="this.fs.moq.errors.required" class="red">
                                      {{staticMsgs.profile.moqReq}}
                                    </div>
                                    <div
                                      *ngIf="this.bussinessInfoForm.controls.moq.value && this.bussinessInfoForm.controls.moq.errors && this.bussinessInfoForm.controls.moq.errors.max"
                                      class="red">
                                      {{staticMsgs.profile.moqAllow}}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-6 mb-3">
                                  <label for="leadTime">{{staticMsgs.profile.leadTime}}<span class="red_icon">*</span></label>
                                  <input type="number" class="form-control" id="leadTime" formControlName="leadTime"
                                    placeholder="Enter Lead Time">
                                  <div
                                    *ngIf="(submittedBusinessInfo && this.fs.leadTime.errors) || ( this.fs.leadTime.invalid && (this.fs.leadTime.dirty || this.fs.leadTime.touched))">
                                    <div *ngIf="this.fs.leadTime.errors.required" class="red">
                                      {{staticMsgs.profile.leadReq}}
                                    </div>
                                    <div
                                      *ngIf="this.bussinessInfoForm.controls.leadTime.value && this.bussinessInfoForm.controls.leadTime.errors && this.bussinessInfoForm.controls.leadTime.errors.max"
                                      class="red">
                                      {{staticMsgs.profile.leadAllow}}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-6 mb-3">
                                  <label for="capacity">{{staticMsgs.profile.capacity}}</label>
                                  <input type="number" class="form-control" id="capacity" formControlName="capacity"
                                    placeholder="Enter Capacity">
                                  <div
                                    *ngIf="this.bussinessInfoForm.controls.capacity.errors && this.bussinessInfoForm.controls.capacity.value && this.bussinessInfoForm.controls.capacity.errors && this.bussinessInfoForm.controls.capacity.errors.max"
                                    class="red">
                                    {{staticMsgs.profile.capAllow}}
                                  </div>
                                </div>

                                <div class="col-lg-6 mb-3">
                                  <label for="capacityUOM">{{staticMsgs.profile.CapUOM}}</label>
                                  <select id="capacityUOM" formControlName="capacityUOM"
                                    class="form-control selectpicker">
                                    <option value="" [disabled]="true" selected>
                                      {{staticMsgs.profile.selectCap}}
                                    </option>
                                    <option value="Units">{{staticMsgs.profile.unitd}}</option>
                                    <option value="Sq.Yds">{{staticMsgs.profile.sqyds}}</option>
                                  </select>
                                </div>
                              </div>
                            </span>
                          </span>
                        </div>

                        <div class="mt-3"
                          *ngIf="supplierclassificationValue === 'Factory' || supplierclassificationValue === 'Raw Material Supplier'">
                          <div class="row">
                            <div class="col-sm-8">
                              <div cdkDropListGroup class="row">
                                <div class="col-sm-5 example-container">
                                  <label for="todo">{{staticMsgs.profile.machine}}</label>
                                  <div [ngClass]="{'disableDiv': isDisabled}" cdkDropList class="example-list"
                                    [cdkDropListData]="todo" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>
                                  </div>
                                </div>
                                <i class="icon icon-shuffle"></i>
                                <div class="col-sm-5 example-container">
                                  <label for="done"></label>
                                  <div [ngClass]="{'disableDiv': isDisabled}" cdkDropList class="example-list mt-1"
                                    [cdkDropListData]="done" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-row">
                                <div [ngClass]="{'disableDiv': isDisabled}" class="col-xl-12 mb-5"
                                  *ngFor="let item of done; let i=index">
                                  <label>{{item}}</label>
                                  <input [disabled]='isDisabled' type="text" class="form-control" id="machineTypes{{i}}"
                                    formControlName="machineTypes{{i}}" placeholder="Enter Qty">
                                  <div
                                    *ngIf="i == 0 && this.bussinessInfoForm.controls.machineTypes0.value && this.bussinessInfoForm.controls.machineTypes0.value.length > 20"
                                    class="red">
                                    {{item}} {{staticMsgs.profile.isAllow}}
                                  </div>
                                  <div
                                    *ngIf="i == 1 && this.bussinessInfoForm.controls.machineTypes1.value && this.bussinessInfoForm.controls.machineTypes1.value.length > 20"
                                    class="red">
                                    {{item}} {{staticMsgs.profile.isAllow}}
                                  </div>
                                  <div
                                    *ngIf="i == 2 && this.bussinessInfoForm.controls.machineTypes2.value && this.bussinessInfoForm.controls.machineTypes2.value.length > 20"
                                    class="red">
                                    {{item}} {{staticMsgs.profile.isAllow}}
                                  </div>
                                  <div
                                    *ngIf="i == 3 && this.bussinessInfoForm.controls.machineTypes3.value && this.bussinessInfoForm.controls.machineTypes3.value.length > 20"
                                    class="red">
                                    {{item}} {{staticMsgs.profile.isAllow}}
                                  </div>
                                  <div
                                    *ngIf="i == 4 && this.bussinessInfoForm.controls.machineTypes4.value && this.bussinessInfoForm.controls.machineTypes4.value.length > 20"
                                    class="red">
                                    {{item}} {{staticMsgs.profile.isAllow}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-5">
                          <div class="dt-entry__header mt-5">
                            <div class="dt-entry__heading">
                              <h3 class="dt-entry__title">
                                {{staticMsgs.profile.audit}}
                              </h3>
                            </div>
                          </div>
                          <div class="form-row mt-5">
                            <div class="col-lg-6 mb-3">
                              <label for="lastSocialAudit">{{staticMsgs.profile.lastAudit}}<span class="red_icon">*</span></label>
                              <div class="input-group date">
                                <input class="form-control" name="dp" [(ngModel)]="lastSocialAuditDate" ngbDatepicker
                                  readonly (click)="d.toggle()" #d="ngbDatepicker" formControlName="lastSocialAudit"
                                  (dateSelect)="dateChange($event,'lastSocialAudit')" [minDate]="minDate">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar"
                                    [ngClass]="{'disableDiv': showLoader1}" (click)="d.toggle()" type="button"><i
                                      class="icon icon-calendar"></i></button>
                                </div>
                              </div>
                              <!-- <div *ngIf="(submittedBusinessInfo && lastSocialAuditDate)">
                                <div class="red">
                                  Last Social Audit is required.
                                </div>
                              </div> -->
                            </div>

                            <div class="col-lg-6 mb-3" [ngClass]="{'disableDiv': isDisabled}">
                              <label for="auditStatus">{{staticMsgs.profile.audit}}<span class="red_icon">*</span></label>
                              <select id="auditStatus" title="Select Status" formControlName="auditStatus"
                                class="form-control selectpicker">
                                <option value="" [disabled]="true" selected>
                                  {{staticMsgs.profile.selectStatus}}
                                </option>
                                <option *ngFor="let status of auditstatus" [value]="status.value">{{ status.label }}
                                </option>

                                <!-- <option value="Passed">Passed</option>
                                <option value="Failed">Failed</option>
                                <option value="UnderReview">UnderReview</option> -->
                              </select>
                              <div
                                *ngIf="(submittedBusinessInfo && this.fs.auditStatus.errors) || ( this.fs.auditStatus.invalid && (this.fs.auditStatus.dirty || this.fs.auditStatus.touched))">
                                <div *ngIf="this.fs.auditStatus.errors.required" class="red">
                                  {{staticMsgs.profile.auditReq}}
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-6 mb-3">
                              <label for="description">{{staticMsgs.profile.desc}}</label>
                              <textarea cols="2" name="description" id="description" formControlName="description"
                                placeholder="Enter Description" class="form-control"></textarea>
                              <div
                                *ngIf="this.bussinessInfoForm.controls.description.errors && this.bussinessInfoForm.controls.description.value && this.bussinessInfoForm.controls.description.value.length > 500"
                                class="red">
                                {{staticMsgs.profile.descAllow}}
                              </div>
                            </div>

                          </div>
                        </div>

                        <div formGroupName="Contactss">
                          <div class="mt-4" formGroupName="contactOrder1">
                            <div class="dt-entry__header mt-5" *ngIf="isDisabled">
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">
                                  {{staticMsgs.profile.pContact}}
                                </h3>
                              </div>
                            </div>

                            <div class="card-header card-header-acc" id="headingtwo" *ngIf="!isDisabled">
                              <h5 class="mb-0">
                                <button class="btn btn-link" type="button">
                                  {{staticMsgs.profile.pContact}}
                                </button>
                                <i class="fa fa-angle-down down" data-toggle="collapse" data-target="#collapse-two"
                                  aria-expanded="true" aria-controls="collapse-one" aria-hidden="true"></i>
                                <button class="btn btn-primary btn-esm float-right mr-3 mt-1" (click)="secondContact()"
                                  *ngIf="secondcontact" type="button">
                                  {{staticMsgs.profile.sContact}}
                                </button>
                              </h5>

                            </div>
                            <div class="form-row mt-5">
                              <div class="form-row" [hidden]="true">
                                <input type="text" class="form-control" id="" formControlName="contactOrder">
                              </div>
                              <div class="col-lg-6 mb-3">
                                <label for="fullName">{{staticMsgs.profile.name}}</label>
                                <input type="text" class="form-control" id="" formControlName="fullName"
                                  placeholder="Enter Name">
                                <div
                                  *ngIf="(submittedBusinessInfo && this.contact1.fullName.errors) || ( this.contact1.fullName.invalid && (this.contact1.fullName.dirty || this.contact1.fullName.touched))">
                                  <div
                                    *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('fullName').value && bussinessInfoForm.get('Contactss').get('contactOrder1').get('fullName').value.length > 50"
                                    class="red">
                                    {{staticMsgs.profile.nameAllow}}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="officeNo">{{staticMsgs.profile.ofcNo}}</label>
                                <input type="text" class="form-control" id="officeNo" formControlName="officeNo"
                                  placeholder="Enter Office No">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('officeNo').errors && bussinessInfoForm.get('Contactss').get('contactOrder1').get('officeNo').value && bussinessInfoForm.get('Contactss').get('contactOrder1').get('officeNo').value.length > 10"
                                  class="red">
                                  {{staticMsgs.profile.ofcAllow}} </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="ext">{{staticMsgs.profile.ext}}</label>
                                <input type="text" class="form-control" id="ext" formControlName="ext"
                                  placeholder="Enter Ext">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('ext').errors && bussinessInfoForm.get('Contactss').get('contactOrder1').get('ext').value && bussinessInfoForm.get('Contactss').get('contactOrder1').get('ext').value.length > 4"
                                  class="red">
                                  {{staticMsgs.profile.extAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="fax">{{staticMsgs.profile.fax}}</label>
                                <input type="text" class="form-control" id="fax" formControlName="fax"
                                  placeholder="Enter Fax">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('fax').errors && bussinessInfoForm.get('Contactss').get('contactOrder1').get('fax').value && bussinessInfoForm.get('Contactss').get('contactOrder1').get('fax').value.length > 10"
                                  class="red">
                                  {{staticMsgs.profile.faxAllow}}
                                </div>
                              </div>

                              <div class="col-sm-6 mb-3">
                                <label for="contactNo">{{staticMsgs.profile.phoneNo}}</label>
                                <input type="number" class="form-control" id="" formControlName="contactNo"
                                  placeholder="Enter Phone Number">
                                <div
                                  *ngIf="(submittedBusinessInfo && this.contact1.contactNo.errors) || ( this.contact1.contactNo.invalid && (this.contact1.contactNo.dirty || this.contact1.contactNo.touched))">
                                  <div
                                    *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('contactNo').errors.pattern"
                                    class="red">
                                    {{staticMsgs.profile.mobValid}}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="contactEmail">{{staticMsgs.profile.email}}</label>
                                <input type="text" class="form-control" id="" formControlName="contactEmail"
                                  placeholder="Enter Email">
                                <div
                                  *ngIf="(submittedBusinessInfo && this.contact1.contactEmail.errors) || ( this.contact1.contactEmail.invalid && (this.contact1.contactEmail.dirty || this.contact1.contactEmail.touched))">
                                  <div class="red"
                                    *ngIf="!bussinessInfoForm.get('Contactss').get('contactOrder1').get('contactEmail').errors.required && bussinessInfoForm.get('Contactss').get('contactOrder1').get('contactEmail').errors.pattern">
                                    {{staticMsgs.profile.emailValid}}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3" [ngClass]="{'disableDiv': isDisabled}">
                                <label for="country">{{staticMsgs.profile.country}}</label>
                                <select title="Select Country" [disabled]="!isDisabled" id="country"
                                  formControlName="country" class="form-control selectpicker w-100">
                                  <!-- <option *ngFor="let country of country" [value]="country.value">{{ country.label }}</option>-->
                                  <option value="" [disabled]="true" selected>Select
                                    {{staticMsgs.profile.selectCoun}}
                                  </option>
                                  <option value="India">{{staticMsgs.profile.india}}</option>
                                  <option value="Indonesia">{{staticMsgs.profile.indonesia}}</option>
                                  <option value="Iran">{{staticMsgs.profile.iran}}</option>
                                </select>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="state">{{staticMsgs.profile.state}}</label>
                                <input type="text" class="form-control" id="" formControlName="state"
                                  placeholder="Enter State">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('state').errors && bussinessInfoForm.get('Contactss').get('contactOrder1').get('state').value && bussinessInfoForm.get('Contactss').get('contactOrder1').get('state').value.length > 50"
                                  class="red">
                                  {{staticMsgs.profile.stateAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="city">{{staticMsgs.profile.city}}</label>
                                <input type="text" class="form-control" id="" formControlName="city"
                                  placeholder="Enter City">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('city').errors && bussinessInfoForm.get('Contactss').get('contactOrder1').get('city').value && bussinessInfoForm.get('Contactss').get('contactOrder1').get('city').value.length > 50"
                                  class="red">
                                  {{staticMsgs.profile.cityAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="zipCode">{{staticMsgs.profile.zip}}</label>
                                <input type="text" class="form-control" id="" formControlName="zipCode"
                                  placeholder="Enter Zip Code">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('zipCode').errors && bussinessInfoForm.get('Contactss').get('contactOrder1').get('zipCode').value && bussinessInfoForm.get('Contactss').get('contactOrder1').get('zipCode').value.length > 6"
                                  class="red">
                                  {{staticMsgs.profile.zipAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="address">{{staticMsgs.profile.address}}</label>
                                <textarea cols="2" formControlName="address" placeholder="Enter Description"
                                  class="form-control"></textarea>
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder1').get('address').errors && bussinessInfoForm.get('Contactss').get('contactOrder1').get('address').value && bussinessInfoForm.get('Contactss').get('contactOrder1').get('address').value.length > 500"
                                  class="red">
                                  {{staticMsgs.profile.addAllow}}
                                </div>
                              </div>
                              <input type="hidden" class="form-control" id="" formControlName="id">
                            </div>
                          </div>
                          <div *ngIf="secondcontact2" class="mt-4" formGroupName="contactOrder2">
                            <div class="dt-entry__header mt-5" *ngIf="isDisabled">
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">
                                  {{staticMsgs.profile.scontact}}
                                </h3>
                              </div>
                            </div>
                            <div class="card-header card-header-acc" id="headingtwo" *ngIf="!isDisabled">
                              <h5 class="mb-0">
                                <button class="btn btn-link" type="button">
                                  {{staticMsgs.profile.scontact}}
                                </button>
                                <i class="fa fa-angle-down down" data-toggle="collapse" data-target="#collapse-three"
                                  aria-expanded="true" aria-controls="collapse-three" aria-hidden="true"></i>
                                <button class="btn btn-primary btn-esm float-right mr-3 mt-1" (click)="secondContact2()"
                                  type="button">
                                  {{staticMsgs.profile.remove}}
                                </button>
                              </h5>

                            </div>
                            <div class="form-row mt-5">
                              <div class="form-row" [hidden]="true">
                                <input type="text" class="form-control" value="" id="" formControlName="contactOrder">
                              </div>
                              <div class="col-lg-6 mb-3">
                                <label for="fullName">{{staticMsgs.profile.name}}</label>
                                <input type="text" class="form-control" formControlName="fullName" id=""
                                  placeholder="Enter Name">
                                <div
                                  *ngIf="(submittedBusinessInfo && this.contact2.fullName.errors) || ( this.contact2.fullName.invalid && (this.contact2.fullName.dirty || this.contact2.fullName.touched))">
                                  <div
                                    *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('fullName').errors && bussinessInfoForm.get('Contactss').get('contactOrder2').get('fullName').value && bussinessInfoForm.get('Contactss').get('contactOrder2').get('fullName').value.length > 50"
                                    class="red">
                                    {{staticMsgs.profile.nameAllow}}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="officeNo">{{staticMsgs.profile.ofcNo}}</label>
                                <input type="text" class="form-control" formControlName="officeNo" id=""
                                  placeholder="Enter Office No">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('officeNo').errors && bussinessInfoForm.get('Contactss').get('contactOrder2').get('officeNo').value && bussinessInfoForm.get('Contactss').get('contactOrder2').get('officeNo').value.length > 10"
                                  class="red">
                                  {{staticMsgs.profile.ofcAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="ext">{{staticMsgs.profile.ext}}</label>
                                <input type="text" class="form-control" formControlName="ext" id=""
                                  placeholder="Enter Ext">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('ext').errors && bussinessInfoForm.get('Contactss').get('contactOrder2').get('ext').value && bussinessInfoForm.get('Contactss').get('contactOrder2').get('ext').value.length > 4"
                                  class="red">
                                  {{staticMsgs.profile.extAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="fax">{{staticMsgs.profile.fax}}</label>
                                <input type="text" class="form-control" id="" formControlName="fax"
                                  placeholder="Enter Fax">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('fax').errors && bussinessInfoForm.get('Contactss').get('contactOrder2').get('fax').value && bussinessInfoForm.get('Contactss').get('contactOrder2').get('fax').value.length > 10"
                                  class="red">
                                  {{staticMsgs.profile.faxAllow}}
                                </div>
                              </div>

                              <div class="col-sm-6 mb-3">
                                <label for="contactNo">{{staticMsgs.profile.phone}}</label>
                                <input type="number" class="form-control" id="" formControlName="contactNo"
                                  placeholder="Enter Phone Number">
                                <div
                                  *ngIf="(submittedBusinessInfo && this.contact2.contactNo.errors) || ( this.contact2.contactNo.invalid && (this.contact2.contactNo.dirty || this.contact2.contactNo.touched))">
                                  <div
                                    *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('contactNo').errors.pattern"
                                    class="red">
                                    <label for="contactNo">{{staticMsgs.profile.phone}}</label>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="contactEmail">{{staticMsgs.profile.email}}</label>
                                <input type="text" class="form-control" formControlName="contactEmail" id=""
                                  placeholder="Enter Email">
                                <div
                                  *ngIf="(submittedBusinessInfo && this.contact2.contactEmail.errors) || ( this.contact2.contactEmail.invalid && (this.contact2.contactEmail.dirty || this.contact2.contactEmail.touched))">
                                  <div
                                    *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('contactEmail').errors.pattern">
                                    {{staticMsgs.profile.emailValid}}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3" [ngClass]="{'disableDiv': isDisabled}">
                                <label for="country">{{staticMsgs.profile.country}}</label>
                                <select title="Select Country" [disabled]="!isDisabled" id="country"
                                  formControlName="country" class="form-control selectpicker w-100">
                                  <!-- <option *ngFor="let country of country" [value]="country.value">{{ country.label }}</option>-->
                                  <option value="" [disabled]="true" selected>Select
                                    {{staticMsgs.profile.selectCoun}}
                                  </option>
                                  <option value="India">{{staticMsgs.profile.india}}</option>
                                  <option value="Indonesia">{{staticMsgs.profile.indonesia}}</option>
                                  <option value="Iran">{{staticMsgs.profile.iran}}</option>
                                </select>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="state">{{staticMsgs.profile.state}}</label>
                                <input type="text" class="form-control" id="" formControlName="state"
                                  placeholder="Enter State">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('state').errors && bussinessInfoForm.get('Contactss').get('contactOrder2').get('state').value && bussinessInfoForm.get('Contactss').get('contactOrder2').get('state').value.length > 50"
                                  class="red">
                                  {{staticMsgs.profile.stateAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="city">{{staticMsgs.profile.city}}</label>
                                <input type="text" class="form-control" id="" formControlName="city"
                                  placeholder="Enter City">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('city').errors && bussinessInfoForm.get('Contactss').get('contactOrder2').get('city').value && bussinessInfoForm.get('Contactss').get('contactOrder2').get('city').value.length > 50"
                                  class="red">
                                  {{staticMsgs.profile.cityAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="zipCode">Zip Code</label>
                                <input type="text" class="form-control" id="" formControlName="zipCode"
                                  placeholder="Enter Zip Code">
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('zipCode').errors && bussinessInfoForm.get('Contactss').get('contactOrder2').get('zipCode').value && bussinessInfoForm.get('Contactss').get('contactOrder2').get('zipCode').value.length > 6"
                                  class="red">
                                  {{staticMsgs.profile.zipAllow}}
                                </div>
                              </div>

                              <div class="col-lg-6 mb-3">
                                <label for="address">{{staticMsgs.profile.address}}</label>
                                <textarea cols="2" formControlName="address" placeholder="Enter Description"
                                  class="form-control"></textarea>
                                <div
                                  *ngIf="bussinessInfoForm.get('Contactss').get('contactOrder2').get('address').errors && bussinessInfoForm.get('Contactss').get('contactOrder2').get('address').value && bussinessInfoForm.get('Contactss').get('contactOrder2').get('address').value.length > 500"
                                  class="red">
                                  {{staticMsgs.profile.addAllow}}
                                </div>
                              </div>
                              <input type="hidden" class="form-control" id="contactid" formControlName="id">
                            </div>
                          </div>

                        </div>

                      </div>
                      <div class="form-row mt-5 float-right">
                        <input type="text" class="form-control" [hidden]="true" id="supplierid"
                          formControlName="supplierid" placeholder="Supplier Id" />

                        <!-- <button type="submit" *ngIf="!isEditMode"
                                class="btn btn-primary adv-search-btn">
                          Create
                        </button> -->
                        <button type="submit" *ngIf="buttonShow" class="btn btn-primary adv-search-btn">
                          {{staticMsgs.profile.Save}}
                        </button>
                      </div>
                    </form>
                  </div>

                  <!-- Tab panel Portfolio -->
                  <div id="tab-pane3" class="tab-pane  card-body card-body-shadow bg-white" *ngIf="isSupplierProfile"
                    [ngClass]="{ 'active':activeTab === 'portfolioTab'}">
                    <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div>
                    <form [formGroup]="portfolioForm" (ngSubmit)="onSubmitportfolio()">
                      <div class="row">
                        <div class="col-md-7 mt-3 mb-3" *ngIf="this.fP.portfolioid.value">
                          <div *ngFor="let viewf of viewfile; let j=index">
                            <img src="{{viewf.thumbnail}}" class="img-fluid  img-fit "
                              *ngIf="isValidImageURL(viewf.name)">
                            <button type="button" class="close image-close" [disabled]='isDisabled'
                              (click)="removeSection(viewf)">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-xl-12 order-xl-1">
                          <div class="form-row">
                            <div class="dt-entry__header mt-5 col-md-12">
                              <div class="dt-entry__heading">
                                <h3 class="dt-entry__title">{{staticMsgs.profile.uploadImg}}</h3>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-7 mt-3 mb-3">
                              <div class="custom-dropzone" [disabled]='isDisabled' name="upload" ngx-dropzone
                                [multiple]="false" [accept]="'image/png,image/jpeg,image/jpg'"
                                (change)="onSelectImage($event)">
                                <ngx-dropzone-label>
                                  <div>
                                    <h5 class="text-light-gray mt-3">{{staticMsgs.profile.dropFile}}</h5>
                                  </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files"
                                  [file]="f" [removable]="true" (removed)="onRemoveImage(f)">
                                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                              </div>
                              <div *ngIf="(submittedPortfolio && !isThumbnailAdded)" class="red">
                                {{staticMsgs.profile.ImgReq}}
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="col-sm-12 mb-3">
                                <label for="imageName">{{staticMsgs.profile.name}}</label>
                                <input type="text" class="form-control" [disabled]="isDisabled"
                                  formControlName="imageName" id="imageName" placeholder="Enter name" value=""
                                  required="">
                                <div
                                  *ngIf="(submittedPortfolio && this.fP.imageName.errors) ||(this.fP.imageName.invalid && (this.fP.imageName.dirty || this.fP.imageName.touched))">
                                  <div *ngIf="this.fP.imageName.errors.required" class="red">
                                    {{staticMsgs.profile.nameReq}}
                                  </div>
                                  <div
                                    *ngIf="this.portfolioForm.controls.imageName.value && this.portfolioForm.controls.imageName.value.length > 50"
                                    class="red">
                                    {{staticMsgs.profile.nameAllow}}
                                  </div>
                                </div>

                              </div>
                              <div class="col-sm-12 mb-3 mt-3">
                                <label for="status">{{staticMsgs.profile.status}}</label>
                                <br>
                                <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" id="Active" value="Active" name="status" formControlName="status"
                                    class="custom-control-input" checked [disabled]="isDisabled">
                                  <label class="custom-control-label" for="Active">
                                    {{staticMsgs.profile.active}}
                                  </label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" id="Inactive" value="Inactive" name="status"
                                    formControlName="status" class="custom-control-input" [disabled]="isDisabled">
                                  <label class="custom-control-label" for="Inactive">
                                    {{staticMsgs.profile.inactive}}
                                  </label>
                                </div>
                                <div *ngIf="(submittedPortfolio && this.fP.status.invalid)" class="red">
                                  <div *ngIf="this.fP.status.errors.required">{{staticMsgs.profile.statusReq}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-row mt-10 float-right">
                            <button type="submit" *ngIf="buttonShow" class="btn btn-primary">{{staticMsgs.profile.Save}}</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <!-- Tab panel About-->
                  <div id="tab-pane4" class="tab-pane  card-body card-body-shadow bg-white" *ngIf="isSupplierProfile"
                    [ngClass]="{ 'active':activeTab === 'aboutTab'}">
                    <div class="alert alert-{{alert}}" *ngIf="errorDiv">{{errors}}</div>
                    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                      <div class="dt-entry__header">
                        <div class="dt-entry__heading">
                          <h3 class="dt-entry__title">
                            {{staticMsgs.profile.intro}}
                          </h3>
                        </div>
                      </div>
                      <div class="form-row">
                        <textarea class="form-control border-0 shadow-none bg-focus" formControlName="about" id="about"
                          [disabled]='isDisabled' rows="15" placeholder="Enter Introduction">
                        </textarea>
                        <div
                          *ngIf="this.profileForm.controls.about.errors && this.profileForm.controls.about.value && this.profileForm.controls.about.value.length > 500"
                          class="red">
                          {{staticMsgs.profile.introReq}}
                        </div>
                      </div>
                      <div class="form-row mt-10 float-right">
                        <button type="submit" *ngIf="buttonShow" class="btn btn-primary">{{staticMsgs.profile.Save}}</button>
                      </div>
                    </form>
                  </div>

                </div>
                <!-- /tab content-->
              </div>
              <!-- /card body -->
            </div>
            <!-- /card -->
          </div>
          <!-- /grid item -->
        </div>
        <!-- /grid -->
      </div>

    </div>
    <!-- Footer -->
    <app-footer></app-footer>
    <!-- /footer -->
  </div>
  <!-- /Site Content Wrapper-->
</main>
<!-- /site content -->
<!-- Root -->
